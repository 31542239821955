<h1>Transfer Clients From Agent to Another</h1>
Select Type:
<br />
<mat-form-field appearance="fill">
  <mat-label>Agent Type</mat-label>
  <mat-select
    [(ngModel)]="selectedAgentType"
    (selectionChange)="onSelectedAgentTypeChanged($event)"
  >
    <mat-option [value]="1"> Sales Agent </mat-option>
    <mat-option [value]="2"> Nutritionist </mat-option>
    <mat-option [value]="3"> Follow Up Agent </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="selectedAgentType > 0">Select Agent to Tranfer from:</div>

<mat-form-field appearance="fill" *ngIf="selectedAgentType > 0">
  <mat-label>Select Agent</mat-label>
  <mat-select [(ngModel)]="selectedAgentFromId">
    <mat-option *ngFor="let agent of filterAgents" [value]="agent.agentId">
      {{ agent.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="selectedAgentType > 0">Select Agent to Tranfer to:</div>
<mat-form-field appearance="fill" *ngIf="selectedAgentFromId > 0">
  <mat-label>Agent Type</mat-label>
  <mat-select [(ngModel)]="selectedAgentToId">
    <mat-option *ngFor="let agent of filterAgents" [value]="agent.agentId">
      {{ agent.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<br />
<mat-checkbox
  *ngIf="selectedAgentToId > 0 && selectedAgentToId != selectedAgentFromId"
  class="example-margin"
  [(ngModel)]="isTransferTasks"
  >Tranfer Tasks</mat-checkbox
>
<br />
<button
  (click)="performTranster()"
  *ngIf="selectedAgentToId > 0 && selectedAgentToId != selectedAgentFromId"
  mat-raised-button
  color="primary"
>
  perform Transfer
</button>
<div
  *ngIf="selectedAgentToId > 0 && selectedAgentToId == selectedAgentFromId"
  style="color: red"
>
  please Choose different Agents
</div>
