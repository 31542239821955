<form>
  <mat-accordion>
    <div *ngFor="let moduleMinForConvertion of moduleMinForConvertions">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ moduleMinForConvertion.appModuleName }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <table
          style="width: 100%"
          mat-table
          [dataSource]="moduleMinForConvertion.actionConvertions"
          class="mat-elevation-z8"
        >
          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="module">
            <th mat-header-cell *matHeaderCellDef>Module</th>
            <td mat-cell *matCellDef="let element">
              {{ element.appModule.appModuleName }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              {{ element.moduleAction.moduleActionName }}
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="rule">
            <th mat-header-cell *matHeaderCellDef>Rule</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.permissionRule &&
                  element.permissionRule.permissionRuleName
              }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <input
                (change)="onConvertionNameChanged(element)"
                [(ngModel)]="element.name"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="deleteConvertion(element)"
            >
              <span class="material-icons" style="cursor: pointer">
                delete
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

  <div style="display: flex">
    <mat-form-field
      class="example-full-width round-select"
      style="width: 220px"
    >
      <mat-select
        style="background-color: darkgray; width: 180px"
        class="lead-status-select"
        matNativeControl
        disableOptionCentering
        [(ngModel)]="newAppModuleId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let appModule of appModules"
          [value]="appModule.appModuleId"
          class="trigger-status"
          style="text-align: center"
        >
          {{ appModule.appModuleName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="example-full-width round-select"
      style="width: 220px"
    >
      <mat-select
        style="background-color: darkgray; width: 180px"
        class="lead-status-select"
        matNativeControl
        disableOptionCentering
        [(ngModel)]="newModuleActionId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let moduleAction of moduleActions"
          [value]="moduleAction.moduleActionId"
          class="trigger-status"
          style="text-align: center"
        >
          {{ moduleAction.moduleActionName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="example-full-width round-select"
      style="width: 220px"
    >
      <mat-select
        style="background-color: darkgray; width: 180px"
        class="lead-status-select"
        matNativeControl
        disableOptionCentering
        [(ngModel)]="newPermissionRuleId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          [value]="-1"
          class="trigger-status"
          style="text-align: center"
        >
          null
        </mat-option>
        <mat-option
          *ngFor="let permissionRule of permissionRules"
          [value]="permissionRule.permissionRuleId"
          class="trigger-status"
          style="text-align: center"
        >
          {{ permissionRule.permissionRuleName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <input
      style="height: 30px; margin-top: 15px"
      [(ngModel)]="newConvertionName"
      [ngModelOptions]="{ standalone: true }"
    />

    <button (click)="addNewConvertion()" class="add-button">Add</button>
  </div>
</form>
