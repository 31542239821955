<div style="display: flex" *ngIf="headFilter">
  <div
    *ngFor="let tab of tabsList"
    class="requestTab"
    [ngClass]="{
      selected: headFilter.tabId == tab.id
    }"
    (click)="changeTab(tab.id)"
  >
    {{ tab.name }}
  </div>
</div>
<hr
  *ngIf="headFilter"
  style="border: 1px solid #119c99; margin-block-start: 0"
/>

<div style="padding: 0 1rem" *ngIf="isLoading">
  <ngx-skeleton-loader
    [theme]="{
      height: '50px',
      'margin-bottom': '10px',
      'background-color': 'rgba(27, 63, 78,0.15)'
    }"
    count="10"
  ></ngx-skeleton-loader>
</div>
<mat-list class="clientsTable" role="list">
  <mat-list-item
    *ngIf="headFilter"
    class="clientsTable__header"
    role="listitem"
  >
    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let item of filteredHeader">
        <span
          class="clickble"
          [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
          [ngStyle]="{ width: item.width }"
        >
          <span (click)="changeSortBy(item.val)">{{ item.name }} </span>
          <ng-container *ngIf="headFilter.orderBy == item.val">
            <img
              src="../../../assets/sort_arrows.svg"
              *ngIf="headFilter.acending == true"
              (click)="changeSortOrder()"
            />
            <img
              class="rotate180"
              src="../../../assets/sort_arrows.svg"
              *ngIf="headFilter.acending == false"
              (click)="changeSortOrder()"
            />
          </ng-container>
        </span>
      </ng-container>
    </ng-container>
  </mat-list-item>

  <mat-card class="clientsTable__item" *ngFor="let request of serviceRequests">
    <mat-list-item role="listitem">
      <span style="width: 3%"></span>
      <span class="d-flex-center">
        <div
          *ngIf="isClickbleCancelationRequest(request)"
          (click)="editRequest($event, request)"
          style="
            color: #00aeee;
            text-decoration: underline;
            cursor: pointer;
            width: 100%;
          "
        >
          {{ request.clientName }}
        </div>
        <div *ngIf="!isClickbleCancelationRequest(request)">
          {{ request.clientName }}
        </div>
        &nbsp;
      </span>
      <span *ngIf="this.headFilter.tabId == 4">
        {{ request.requestDate | date: "dd-MM-yyyy" }}
      </span>
      <span
        *ngIf="
          this.headFilter.tabId == 1 ||
          this.headFilter.tabId == 2 ||
          this.headFilter.tabId == 3 ||
          this.headFilter.tabId == 5
        "
      >
        {{ request.paymentMethod }}
      </span>
      <span *ngIf="this.headFilter.tabId == 5">{{ request.refundMethod }}</span>
      <span *ngIf="this.headFilter.tabId == 1 || this.headFilter.tabId == 3">
        {{ request.decision && request.decision.name }}
      </span>

      <span
        *ngIf="
          this.headFilter.tabId == 1 ||
          this.headFilter.tabId == 2 ||
          this.headFilter.tabId == 3
        "
        style="text-align: left"
      >
        <app-autocomplete-agent
          (selectedValue)="saveAgentForCancelation($event, request)"
          [data]="agents"
          [initialValue]="
            request.conservationAgent && request.conservationAgent.name
          "
          [widthExp]="150"
          [label]="'Select Agent'"
        ></app-autocomplete-agent>
        <!-- <app-autocomplete-agent
          class="cons-agent"
          style="width: 100%"
          (selectedAgent)="saveAgentForCancelation($event, request)"
          [agentId]="
            request.conservationAgent && request.conservationAgent.name
          "
          [agents]="agents"
        ></app-autocomplete-agent> -->
      </span>
      <span> {{ request.amount }} &nbsp; </span>

      <span *ngIf="this.headFilter.tabId !== 4" style="display: flex">
        <div
          class="round-indicator"
          [ngStyle]="{
            'background-color': request.status && request.status.color
          }"
          style="min-width: 150px; max-width: 150px; text-align: center"
        >
          {{ getStatus(this.headFilter.tabId, request.status) }} &nbsp;
        </div>
      </span>
      <span *ngIf="this.headFilter.tabId == 4" style="display: flex">
        <div *ngIf="request.statusId == 131" class="coupoe-wating">
          Waiting &nbsp;
        </div>
        <div *ngIf="request.statusId !== 131" class="coupoe-sent">
          Coupon sent &nbsp;
        </div>
      </span>
      <span *ngIf="this.headFilter.tabId == 1 || this.headFilter.tabId == 3">{{
        request.refundMethod
      }}</span>
      <div *ngIf="this.headFilter.tabId == 4" style="width: 12%">
        <button
          *ngIf="request.statusId == 131"
          mat-button
          class="open-cupone-dialog"
          (click)="openSendCouponDialog(request)"
        >
          Send Coupon
        </button>
      </div>
      <!--
      <span *ngIf="classAttr == 'refundsAttr'">
        {{ request.accountantAgentName }}
      </span>
      -->
      <span *ngIf="this.headFilter.tabId == 4">
        {{ request.transactionDate | date: "dd-MM-yyyy" }} &nbsp;
      </span>
      <span
        *ngIf="
          this.headFilter.tabId == 1 ||
          this.headFilter.tabId == 2 ||
          this.headFilter.tabId == 3 ||
          this.headFilter.tabId == 5
        "
      >
        {{ request.requestDate | date: "dd-MM-yyyy" }}
        &nbsp;
      </span>
      <!--
      <span *ngIf="classAttr == 'refundsAttr'">
        {{ request.last4Digits }} &nbsp;
      </span>
      -->
      <!--
      <span>{{ request.updateDate | date: "dd-MM-yyyy" }}</span>
      -->
    </mat-list-item>
  </mat-card>
</mat-list>
