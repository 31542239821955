<div class="container" style="direction: rtl">
  <div style="margin-right: auto" (click)="goBack()">חזרה ></div>

  <div
    style="
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 40px;
      margin: auto;
    "
  >
    <img
      *ngIf="categoryId != -1"
      src="../../../assets/categoryThumb_{{ categoryId }}.svg"
    />
    <span style="font-size: 30px; font-weight: bolder; margin-right: 30px">
      <ng-container *ngIf="categoryId != -1">
        {{
          results &&
            results[0].subCategoryResult.subCategory.category.categoryName
        }}
      </ng-container>
      <ng-container> ממצאים בעלי משמעות </ng-container>
    </span>
  </div>
  <div *ngIf="categoryId != -1">
    <span
      (click)="changeRankTab(3)"
      class="rankTab"
      [ngClass]="{ selectedRed: this.rankInx == 3 }"
      style="margin-left: 30px"
      >ממצאים בעלי נטייה גבוהה</span
    >
    <span
      (click)="changeRankTab(2)"
      class="rankTab"
      [ngClass]="{ selectedYellow: this.rankInx == 2 }"
      style="margin-left: 30px"
      >ממצאים בעלי נטייה בינונית</span
    >
    <span
      (click)="changeRankTab(1)"
      class="rankTab"
      [ngClass]="{ selectedGreen: this.rankInx == 1 }"
      style="margin-left: 30px"
      >ממצאים תקינים</span
    >
  </div>
  <hr class="line" />

  <mat-accordion>
    <mat-expansion-panel *ngFor="let result of rankResults">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span style="width: 300px; color: #119c99; font-weight: bolder">
            {{ result.subCategoryResult.subCategory.subCategoryName }}
          </span>
          <app-tendeny-thumb [rank]="result.subCategoryResult.order">
          </app-tendeny-thumb>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!--<span [innerHtml]="result.subCategoryResult.name"> </span>-->
      <span class="title-underlined"> סיכום: </span>
      <div
        style="padding-top: 20px; padding-bottom: 20px; color: gray"
        [innerHtml]="result.subCategoryResult.description"
      ></div>
      <span class="title-underlined"> המלצות: </span>
      <div
        class="recomend"
        style=""
        [innerHtml]="colorNumbers(result.subCategoryResult.recomendations)"
      ></div>
    </mat-expansion-panel>
  </mat-accordion>
  <!--
      <div *ngFor="let result of rankOneResults">
        <span [innerHtml]="result.subCategoryResult.name"> </span>
        <div>
          סיכום:
        </div>
        <div [innerHtml]="result.subCategoryResult.description"></div>
        <div>
          המלצות:
        </div>
        <div [innerHtml]="result.subCategoryResult.recomendations"></div>
      </div>
    -->
  <span class="changes-title">רשימת השינויים והגנים שהתקבלו</span>
  <table
    style="direction: ltr"
    mat-table
    [dataSource]="tubeTestResults"
    class="mat-elevation-z8"
  >
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="geneName">
      <th
        class="table-header"
        mat-header-cell
        *matHeaderCellDef
        style="text-align: center"
      >
        שם הגן הנבדק
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.snp.geneName }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="snpNumber">
      <th
        class="table-header"
        mat-header-cell
        *matHeaderCellDef
        style="text-align: center"
      >
        RS NO.
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        rs{{ element.snpNumber }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="result">
      <th
        class="table-header"
        mat-header-cell
        *matHeaderCellDef
        style="text-align: center"
      >
        הגנוטיפ שהתקבל
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.snpResult.result }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="info">
      <th class="table-header" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div
          (mouseenter)="showToolTip(element)"
          (mouseleave)="hideToolTip(element)"
          matTooltip="{{ element.snpResult.description }}"
        >
          <span class="info-circle">i</span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
