<button
  type="button"
  class="btn-menu"
  mat-button
  [matMenuTriggerFor]="paramTypes"
>
  <span>{{ selectedParam?.fgParamName || "Type" }}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #paramTypes="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="nutrientParam"
    *ngIf="hasParams(0)"
  >
    {{ data[0].fgParamName }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="alertParam" *ngIf="hasParams(1)">
    {{ data[1].fgParamName }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="staticParam" *ngIf="hasParams(2)">
    {{ data[2].fgParamName }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="userDnaResult"
    *ngIf="hasParams(3)"
  >
    {{ data[3].fgParamName }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="maxCoefficient"
    *ngIf="hasParams(4)"
  >
    {{ data[4].fgParamName }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="result" *ngIf="hasParams(5)">
    {{ data[5].fgParamName }}
  </button>
  <!--   <button
    mat-menu-item
    [matMenuTriggerFor]="alertTag"
    *ngIf="data[6].params && data[6].params.length > 0"
  >
    {{ data[6].fgParamName }}
  </button> -->
  <button mat-menu-item [matMenuTriggerFor]="cumeDist" *ngIf="hasParams(6)">
    {{ data[6].fgParamName }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="bloodTest" *ngIf="hasParams(7)">
    {{ data[7].fgParamName }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="coefficientMin"
    *ngIf="hasParams(8)"
  >
    {{ data[8].fgParamName }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="coefficientMax"
    *ngIf="hasParams(9)"
  >
    {{ data[9].fgParamName }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="minCoefficient"
    *ngIf="hasParams(10)"
  >
    {{ data[10].fgParamName }}
  </button>
</mat-menu>
<mat-menu #nutrientParam="matMenu">
  <button
    *ngFor="let item of data[0]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #alertParam="matMenu">
  <button
    *ngFor="let item of data[1]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #staticParam="matMenu">
  <button
    *ngFor="let item of data[2]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #userDnaResult="matMenu">
  <button
    *ngFor="let item of data[3]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #maxCoefficient="matMenu">
  <button
    *ngFor="let item of data[4]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #result="matMenu">
  <button
    *ngFor="let item of data[5]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<!-- <mat-menu #alertTag="matMenu">
  <button
    *ngFor="let item of data[6].params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu> -->
<mat-menu #cumeDist="matMenu">
  <button
    *ngFor="let item of data[6]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #bloodTest="matMenu">
  <button
    *ngFor="let item of data[7]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #coefficientMin="matMenu">
  <button
    *ngFor="let item of data[8]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #coefficientMax="matMenu">
  <button
    *ngFor="let item of data[9]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
<mat-menu #minCoefficient="matMenu">
  <button
    *ngFor="let item of data[10]?.params"
    mat-menu-item
    (click)="onParamClick(item)"
  >
    {{ item.fgParamName }}
  </button>
</mat-menu>
