import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class RuleToModuleActionsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<NameAndId[]>(
      `${environment.baseUrl}RuleToModuleActions/`
    );
  }
}
