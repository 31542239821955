import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { CancelationRequestForTableMin } from 'src/app/data/CancelationRequestForTableMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  CancellationDecisionEnum,
  CancellationRequestEnum,
} from 'src/app/Enums/StatusesEnum';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-cancelation-table',
  templateUrl: './cancelation-table.component.html',
  styleUrls: ['./cancelation-table.component.scss'],
})
export class CancelationTableComponent implements OnInit {
  @Input() serviceRequests: CancelationRequestForTableMin[];
  @Input() headFilter: HeadFilter;
  @Input() agents: AgentMin[];
  @Input() agentUser: RegularUser;
  @Input() requestsStatuses: Status[];
  @Input() classAttr: string;
  @Output('filterChanged') filterChanged: EventEmitter<HeadFilter> =
    new EventEmitter();
  @Input() isLoading: boolean;
  request: CancelationRequestForTableMin;
  filteredHeader: {
    name: string;
    val: string;
    width: string;
    mode: string[];
  }[];
  statuses: Status[];
  @Input() tabsList: any[];
  //agentForAC: string[];

  constructor(
    private router: Router,
    private popupHandlerService: PopupHandlerService,
    private cancellationRequestsService: CancelationRequestService,
    private statusesService: StatusesService
  ) {}

  headers = [
    {
      name: '',
      val: '',
      width: '3%',
      mode: ['all', 'new', 'manager', 'coupon', 'refund'],
    },
    {
      name: 'Client Name',
      val: 'ClientName',
      width: '12%',
      mode: ['all', 'new', 'manager', 'coupon', 'refund'],
    },
    {
      name: 'Date of Request',
      val: 'RequestDate',
      width: '12%',
      mode: ['coupon'],
    },
    {
      name: 'Payment',
      val: 'PaymentMethod',
      width: '12%',
      mode: ['all', 'new', 'manager', 'refund'],
    },
    {
      name: 'Decision',
      val: 'Decision',
      width: '12%',
      mode: ['all', 'manager'],
    },
    {
      name: 'Agent',
      val: 'PreservationAgent',
      width: '12%',
      mode: ['all', 'manager'],
    },
    {
      name: 'Preservation Agent',
      val: 'PreservationAgent',
      width: '12%',
      mode: ['new'],
    },
    {
      name: 'Refund Method',
      val: 'RefundMethod',
      width: '12%',
      mode: ['refund'],
    },
    {
      name: 'Acountent',
      val: 'AcountentAgent',
      width: '12%',
      mode: [],
    },
    {
      name: 'Amount of Coupon',
      val: 'AmountOfCoupon',
      width: '12%',
      mode: ['coupon'],
    },
    {
      name: 'Coupon Status',
      val: 'CouponStatus',
      width: '12%',
      mode: ['coupon'],
    },
    {
      name: '',
      val: 'CouponEmpty',
      width: '12%',
      mode: ['coupon'],
    },
    {
      name: 'Sent Date',
      val: 'SentDate',
      width: '12%',
      mode: ['coupon'],
    },
    {
      name: 'Amount to refund',
      val: 'AmountToRefund',
      width: '12%',
      mode: ['new'],
    },
    {
      name: 'Amount',
      val: 'AmountToRefund',
      width: '12%',
      mode: ['all', 'manager', 'refund'],
    },
    {
      name: 'last 4 digits',
      val: 'last4Digits',
      width: '12%',
      mode: [],
    },
    {
      name: 'Transaction Date',
      val: 'TransactionDate',
      width: '12%',
      mode: [],
    },

    { name: 'Sum', val: 'Sum', width: '12%', mode: [] },
    { name: 'Agent', val: 'conservationAgent', width: '12%', mode: ['cancel'] },
    { name: 'Status', val: 'Status', width: '12%', mode: ['new'] },
    {
      name: 'Refund Status',
      val: 'Status',
      width: '12%',
      mode: ['all', 'manager', 'refund'],
    },
    {
      name: 'Type of Refund',
      val: 'TypeofRefund',
      width: '12%',
      mode: ['all', 'manager'],
    },
    { name: 'Updated At', val: 'UpdatedAt', width: '12%', mode: ['cancel'] },
    {
      name: 'Date Of Refund',
      val: 'refundDate',
      width: '12%',
      mode: [],
    },
    {
      name: 'Date of Request',
      val: 'RequestDate',
      width: '12%',
      mode: ['all', 'new', 'manager', 'refund'],
    },
  ];
  ngOnInit(): void {
    this.showHead();
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
    });
    //this.agentForAC = this.agents.map((agent) => agent.name);
  }
  isUserHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.agentUser);
  }

  showHead() {
    switch (this.headFilter.tabId) {
      case 1:
        this.filteredHeader = this.headers.filter((x) =>
          x.mode.includes('all')
        );
        break;
      case 2:
        this.filteredHeader = this.headers.filter((x) =>
          x.mode.includes('new')
        );
        break;
      case 3:
        this.filteredHeader = this.headers.filter((x) =>
          x.mode.includes('manager')
        );
        break;
      case 4:
        this.filteredHeader = this.headers.filter((x) =>
          x.mode.includes('coupon')
        );
        break;
      case 5:
        this.filteredHeader = this.headers.filter((x) =>
          x.mode.includes('refund')
        );
        break;
    }
  }
  changeTab(tabId) {
    this.headFilter.tabId = tabId;
    this.showHead();
    //this.headFilter.onlyManager = onlyManager;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }

  stopPropegation(event) {
    event.stopPropagation();
  }

  moveToEditScreen(request: CancelationRequestForTableMin) {
    let url = '';
    let link = '';
    if (this.headFilter.tabId == 1) {
      switch (request.statusId) {
        case CancellationRequestEnum.NewRequest:
          link = 'cancelations/conservation';
          break;
        case CancellationRequestEnum.ManagerApprove:
          link = 'cancelations/manager';
          break;
        case CancellationRequestEnum.CouponApprove:
          link = 'cancelations/manager';
          break;
        case CancellationRequestEnum.RefundApprove:
        case CancellationRequestEnum.RefundClosed:
          link = 'refunds/edit';
          break;
        case CancellationRequestEnum.Closed:
        case CancellationRequestEnum.CouponClosed:
          link = 'cancelations/closed';
          break;
        default:
          link = 'cancelations/closed';
      }

      url = this.router.serializeUrl(
        this.router.createUrlTree([`/${link}/`], {
          queryParams: { cancelRequestId: request.cancelationRequestId },
        })
      );
    } else if (this.headFilter.tabId == 2 || this.headFilter.tabId == 4) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['/cancelations/conservation/'], {
          queryParams: { cancelRequestId: request.cancelationRequestId },
        })
      );
    } else if (this.headFilter.tabId == 3) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['/cancelations/manager/'], {
          queryParams: { cancelRequestId: request.cancelationRequestId },
        })
      );
    } else if (this.headFilter.tabId == 5) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['/refunds/edit/'], {
          queryParams: { cancelRequestId: request.cancelationRequestId },
        })
      );
    }
    this.router.navigateByUrl(url);
    //    window.open(url, '_blank');
  }
  editRequest(event, request: CancelationRequestForTableMin) {
    event.stopPropagation();
    this.moveToEditScreen(request);
  }

  onSearchBtnClick() {
    this.filterChanged.emit(this.headFilter);
  }
  onSearchFieldInput() {}
  searchTerm;
  userHasPermisions(a) {}
  openSendCouponDialog(request: CancelationRequestForTableMin) {
    this.popupHandlerService.openCouponDialog(request);
    this.popupHandlerService.confirmCouponSubject
      .pipe(take(1))
      .subscribe((list) => {
        if (list) {
          var { companyId, codeCoupon } = list;
          this.cancellationRequestsService
            .addCoupon(request.cancelationRequestId, companyId, codeCoupon)
            .subscribe(() => {
              request.statusId = CancellationRequestEnum.Closed;
              request.status = this.statuses.find(
                (status) => status.statusId == request.statusId
              );
            });
        }
      });
  }

  saveAgentForCancelation(event, request: CancelationRequestForTableMin) {
    /*  const selectedAgent = this.agents.find((agent) => agent.name === event)
      .agentId; */
    request.conservationAgent.agentId = event;

    request.conservationAgent.name = this.agents.find(
      (x) => x.agentId == request.conservationAgent.agentId
    ).name;
    this.cancellationRequestsService
      .changeConservationAgent(
        request.cancelationRequestId,
        request.conservationAgent.agentId
      )
      .subscribe(() => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.agentSaved
        );
      });
  }
  /*   saveAgentForRequest(event, request: RequestForTableMin) {
    if (!request) return;
    request.assignedAgent.agentId = Number(event.value);
    //lead.agent = null;

    setTimeout(() => {
      this.serviceRequestsPusherService.putMin(request);
      this.openConfirmChangDialog(null, 'requestAgent');
    }, 10);
  } */

  getStatus(tabId, status) {
    if (!status) return '';
    if (tabId == 5) {
      if (status.statusId == CancellationRequestEnum.Closed) return 'Refunded';
      else if (status.statusId == CancellationRequestEnum.RefundApprove)
        return 'Wating to refund';
    }
    return status.name;
  }
  isClickbleCancelationRequest(request: CancelationRequestForTableMin) {
    return true;
    /* if (
      request.decisionId == CancellationDecisionEnum.SavedWithCoupon &&
      this.isUserHasPermisions(['Product_GetPage'])
    ) {
      return false;
    } else return true; */
  }
}
