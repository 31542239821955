import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { SubCategoryResultLangMin } from '../data/SubCategoryResultLangMin';
import { SubCategoryResult } from '../data/SubCategoryResult';

@Injectable({
  providedIn: 'root',
})
export class SubCategoryResultsService {
  setWebImageId(subCategoryResultId: any, webImageId: any) {
    return this.http.get(
      `${environment.baseUrl}SubCategoryResults/SetImage/${webImageId}/${subCategoryResultId}`
    );
  }
  constructor(private http: HttpClient) {}
  putLangMin(item: SubCategoryResultLangMin) {
    return this.http.put(
      `${environment.baseUrl}SubCategoryResultLangs/Min/${item.subCategoryResultLangId}`,
      item
    );
  }

  put(item: SubCategoryResult) {
    return this.http.put(
      `${environment.baseUrl}SubCategoryResult/${item.subCategoryResultId}`,
      item
    );
  }
  changeMinMax(subCategoryResultId, vesionId, min, max) {
    return this.http.get(
      `${environment.baseUrl}SubCategoryResults/changeMinMaxV2/${subCategoryResultId}/${vesionId}/${min}/${max}`
    );
  }
  changeMinMaxOld(subCategoryResultId, min, max) {
    return this.http.get(
      `${environment.baseUrl}SubCategoryResults/changeMinMax/${subCategoryResultId}/${min}/${max}`
    );
  }
  setSubCatNumResults(subCategoryId, num) {
    return this.http.get(
      `${environment.baseUrl}SubCategoryResults/setSubCatNumResults/${subCategoryId}/${num}`
    );
  }
}
