import { Component, OnInit } from '@angular/core';
import { Logging } from 'src/app/data/Logging';

import { LoggingsService } from 'src/app/Services/loggings.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-logs',
  templateUrl: './patient-logs.component.html',
  styleUrls: ['./patient-logs.component.css'],
})
export class PatientLogsComponent implements OnInit {
  loggings: Logging[];
  clientName:string;
  queryProductId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loggingsService: LoggingsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryProductId = params['productId'];
      this.loggingsService
        .getByProductId(this.queryProductId)
        .subscribe((data) => {
          this.loggings = data.list;
          this.clientName=data.clientName;
        });
    });
  }
  goBackToPatient() {
    this.router.navigate(['/tests/edit/'], {
      queryParams: { productId: this.queryProductId },
    });
  }
}
