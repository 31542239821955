import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';
import { MessageStatus, TemplateType } from 'src/app/Enums/ChatEnum';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { ComplexMessageComponent } from '../complex-message/complex-message.component';
import { DynamicTypedMessageComponent } from '../dynamic-typed-message/dynamic-typed-message.component';
import { ReviewMessageComponent } from '../review-message/review-message.component';

@Component({
  selector: 'app-answer-message',
  templateUrl: './answer-message.component.html',
  styleUrls: ['./answer-message.component.scss'],
})
export class AnswerMessageComponent implements OnInit, AfterViewInit {
  @Input() langId: number;
  @Input() message: ChatMessage;
  @ViewChild('dynamicBubble', { read: ViewContainerRef })
  dynamicBubble: ViewContainerRef;
  componentRef: ComponentRef<any>;
  componentMap = {
    [TemplateType.Review]: ReviewMessageComponent,
    [TemplateType.DynamicTyped]: DynamicTypedMessageComponent,
    [TemplateType.Complex]: ComplexMessageComponent,
  };
  MessageStatus = MessageStatus;
  templateType: number;
  constructor(private firebaseService: FirebaseChatIndicatorService) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.templateType = this.message?.template?.templateTypeId;
    setTimeout(() => {
      if (
        this.templateType in this.componentMap &&
        !this.message.directionToUser
      ) {
        this.loadComponent();
      }
    }, 100);
  }

  loadComponent(): void {
    this.dynamicBubble.clear();
    this.componentRef = this.dynamicBubble.createComponent(
      this.componentMap[this.templateType]
    );
    this.componentRef.instance.message = this.message;
    this.componentRef.instance.langId = this.langId;

    if (this.message.statusId !== MessageStatus.Readed) {
      this.firebaseService.readMessage(this.message.chatMessageId, this.langId);
    }
  }
}
