import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from 'src/app/data/Client';
import { ProductIsFor } from 'src/app/data/ProductIsFor';
import { Product } from 'src/app/data/Product';

@Component({
  selector: 'app-client-edit-dialog-wraper',
  templateUrl: './client-edit-dialog-wraper.component.html',
  styleUrls: ['./client-edit-dialog-wraper.component.css'],
})
export class ClientEditDialogWraperComponent implements OnInit {
  @Output('saveForm') saveForm: EventEmitter<Client> = new EventEmitter();
  @Output('saveFormProduct') saveFormProduct: EventEmitter<Product> =
    new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ClientEditDialogWraperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }
  clientTypes;
  leadSources;
  agents;
  client;
  product;
  classAttr;
  isEditMode;
  productIsFors;

  ngOnInit(): void {
    if (this.data != null) {
      this.client = this.data.client;
      this.product = this.data.product;
      this.leadSources = this.data.leadSources;
      this.clientTypes = this.data.clientTypes;
      this.classAttr = this.data.classAttr;
      this.agents = this.data.agents;
      this.productIsFors = this.data.productIsFors;
    }
  }
  onChildFormValidated(client: Client) {
    this.dialogRef.close({ event: 'close', data: client });
    //  this.saveForm.emit(client);
  }
  onChildProductFormValidated(product: Product) {
    this.dialogRef.close({ event: 'close', data: product });
    // this.saveFormProduct.emit(product);
  }
  onCancelForm() {
    this.dialogRef.close({ event: 'close', data: null });
  }
}
