<mat-card [ngClass]="getlevelClass(question.level)" style="margin: 20px">
  <div style="border: 1px black solid">
    <!--
    <span>next question text:</span>
    <input
      (change)="valuechange($event)"
      [(ngModel)]="question.nextQuestionText"
    />
    <br />
    -->

    <span>answer text:</span>
    <input
      (change)="valuechange($event)"
      [(ngModel)]="question.lifestyleQuestionLangs[0].answerText"
    />
    <br />
    <span>is Red:</span>
    <mat-checkbox
      (change)="valuechange($event)"
      [(ngModel)]="question.isRed"
    ></mat-checkbox>
    <br />
    <span>design Type:</span>
    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.designTypeId"
      name="designTypeSelect"
    >
      <option
        [value]="designType.statusId"
        *ngFor="let designType of designTypes"
      >
        {{ designType.name }}
      </option>
    </select>
    <br />
    <span> level:</span>
    <span>{{ question.level }}</span>
    <br />
    <button (click)="deleteCurrentNode()">delete</button>
    <br />
    <button (click)="accordion.openAll()">Expand All</button>
    <button (click)="accordion.closeAll()">Collapse All</button>
    <mat-accordion [multi]="true" #accordion="matAccordion">
      <div *ngFor="let childQuestion of question.childQuestions">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ childQuestion.answerText }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-lifestyle-question-node
            [question]="childQuestion"
            [designTypes]="designTypes"
            (addNewQuest)="onAddNewQuestion($event)"
            (deleteQuest)="onDeleteQuestion($event)"
          >
          </app-lifestyle-question-node>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  <button (click)="addNewQuestion()">Add</button>
</mat-card>
