import { Agent } from './Agent';
import { Status } from './Status';

export class AgentInteraction {
  agentInteractionId: number;
  agentId: number;
  agent: Agent;
  statusId: number;
  status: Status;
  startTime: string;
  endTime: string | null;
}
