import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StatusesService } from 'src/app/Services/statuses.service';
import { Status } from 'src/app/data/Status';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FoodReportRequest } from 'src/app/data/food-genes/FoodReportRequest';
import { FoodClientRequestService } from 'src/app/Services/food-client-request.service';

@Component({
  selector: 'app-request-edit',
  templateUrl: './request-edit.component.html',
  styleUrls: ['./request-edit.component.css'],
})
export class RequestEditComponent implements OnInit {
  @Output('cancelForm') cancelForm: EventEmitter<any> = new EventEmitter();
  @Output('saveForm') saveForm: EventEmitter<
    ServiceRequest | FoodReportRequest
  > = new EventEmitter();
  requestForm: FormGroup;

  subjects: Status[];
  subjectToChange: Status;
  @Input() serviceRequest: ServiceRequest | FoodReportRequest;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  langId: number = 1;
  constructor(
    private statusesService: StatusesService,
    private foodClientRequestService: FoodClientRequestService
  ) {}

  ngOnInit(): void {
    this.createRequestFormGroup();
    const sr = this.serviceRequest as ServiceRequest;
    if (sr.serviceRequestId) this.getRequestSubject();
    else this.getAppReportsSubjects();
  }
  getRequestSubject() {
    this.statusesService.getAll().subscribe((statuses) => {
      this.subjects = statuses.filter(
        (status) => status.category === 'RequestSubject'
      );

      this.getSubjectName();
    });
  }
  getAppReportsSubjects() {
    this.foodClientRequestService
      .getFoodRequestSubject(this.langId)
      .subscribe((data) => {
        this.subjects = data.map((x) => {
          let subject = new Status();
          subject.statusId = x.clientRequestSubjectId;
          subject.name = x.name;
          subject.category = 'appRequestSubject';
          return subject;
        });
        this.getSubjectName();
      });
  }
  onCancelForm(e) {
    this.cancelForm.emit();
  }
  createRequestFormGroup() {
    if (!this.serviceRequest) return;
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;
    const desc = sr.serviceRequestId ? sr.desc : cr.requestContent;
    this.requestForm = new FormGroup({
      subject: new FormControl(this.serviceRequest.subjectId, [
        Validators.required,
      ]),
      desc: new FormControl(desc),
    });
  }
  getSubjectName(e = null) {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;
    if (sr.serviceRequestId) {
      var name = e ? e.value : this.requestForm.value.subject;
      this.statusesService.getAll().subscribe((statuses) => {
        this.subjectToChange = statuses.find(
          (status) => status.statusId == name
        );
      });
    } else {
      this.subjectToChange = this.subjects.find(
        (x) => x.statusId == this.requestForm.value.subject
      );
    }
  }
  onSaveForm() {
    if (this.requestForm.valid) {
      const sr = this.serviceRequest as ServiceRequest;
      const cr = this.serviceRequest as FoodReportRequest;

      this.serviceRequest.subjectId = this.requestForm.value.subject;

      if (sr.serviceRequestId) {
        sr.desc = this.requestForm.value.desc;
        sr.subject.name = this.subjectToChange.name;
        sr.categoryId = this.subjectToChange.parentId;
        this.saveForm.emit(sr);
      } else {
        cr.requestContent = this.requestForm.value.desc;
        cr.requestSubject.name = this.subjectToChange.name;
        this.saveForm.emit(cr);
      }
    }
  }
}
