<div
  class="txtEditor"
  [dir]="dir"
  [ngStyle]="{ 'text-align': dir === 'rtl' ? 'right' : 'left' }"
>
  <h1>{{ translatedTitle() }}:</h1>
  <angular-editor
    [config]="editorConfig"
    [placeholder]="'Enter text here...'"
    [(ngModel)]="name"
    >{{ name }}</angular-editor
  >
  <h1>{{ translatedShortDescription() }}:</h1>
  <angular-editor
  [config]="editorConfig"
    [placeholder]="'Enter text here...'"
    [(ngModel)]="shortDescription"
    >{{shortDescription}}</angular-editor
  >
  <h1>{{ translatedDescription() }}:</h1>
  <angular-editor
  [config]="editorConfig"
    [placeholder]="'Enter text here...'"
    [(ngModel)]="description"
    >{{ description }}</angular-editor
  >
  
  <h1>{{ translatedRecomendartions() }}:</h1>
  <angular-editor
  [config]="editorConfig"
    [placeholder]="'Enter text here...'"
    [(ngModel)]="recomendations"
    >{{ recomendations }}</angular-editor
  >
  <!--<quill-editor>{{textToEdit}}</quill-editor>-->
  <!--
<textarea dir="rtl" style="width: 100%;height: 400px;" >
    {{textToEdit}}
    </textarea>
-->
  <!--<ckeditor  class="cke_rtl" #editor [config]="{ contentsLangDirection : 'rtl' }" [editor]="Editor" data="{{textToEdit}}"
ID="CKEdMainPageDescriptionWebsiteMobileappAr" BasePath="ckeditor/" runat="server" Width="100%" Toolbar="Basic"  ContentsLangDirection="Rtl"
                                        ToolbarBasic="|Bold|Italic|Underline|Strike|-|NumberedList|BulletedList|Outdent|Indent|-|JustifyLeft|JustifyCenter|JustifyRight|JustifyBlock|
                                            |Styles|-|Format|-|Font|-|FontSize|
                                          /
                                        |Link|Unlink|-|TextColor|-|Undo|Redo|Cut|Copy|Paste|PasteText|PasteFromWord|"
></ckeditor>-->
  <br />
  <button (click)="saveText()">{{ translatedBtn() }}</button>
</div>
