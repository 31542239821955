import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegularUser } from '../data/RegularUser';
import { FIREBASE_REFERENCES } from '../firebase.module';
import { FirebaseAnonimousAuthService } from './firebase-anonimous-auth.service';
import { RegularUsersService } from './regular-users.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseCallsService {
  regularUser: RegularUser;
  isListend: boolean = false;
  callsSubject = new BehaviorSubject<any>(null);
  readonly callsSubject$ = this.callsSubject.asObservable();
  dashboardStatisticsSubject = new BehaviorSubject<any>(null);
  readonly dashboardStatisticsSubject$ =
    this.dashboardStatisticsSubject.asObservable();

  constructor(
    private anonAuthService: FirebaseAnonimousAuthService,
    @Inject(FIREBASE_REFERENCES.CALLS_FIRESTORE)
    private readonly fireStoreCalls: AngularFirestore
  ) {
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (data) {
        if (this.isListend) return;
        this.isListend = true;
      }
    });
  }

  getCallIndicator() {
    //CallIndicator
    return this.fireStoreCalls
      .collection('CallIndicator')
      .snapshotChanges(['added', 'removed'])
      .pipe(
        map((res) => {
          var temp = [];
          res.forEach((x) => {
            temp[x.payload.doc.id] = x.payload.doc.data();
          });
          return temp;
        })
      );
  }

  getDashboardStatistics() {
    //DashboardStatistics
    return this.fireStoreCalls
      .collection('DashboardStatistics')
      .snapshotChanges(['modified', 'added'])
      .pipe(
        map((res) => {
          var temp = [];
          res.forEach((x) => {
            temp[x.payload.doc.id] = x.payload.doc.data();
          });
          return temp;
        })
      );
    /*.subscribe((res) => {
        var temp = [];
        res.forEach((x) => {
          temp[x.payload.doc.id] = x.payload.doc.data();
        });
        this.dashboardStatisticsSubject.next(temp);
      }); */
  }
}
