import { environment } from 'src/environments/environment';
import { AppImage } from '../data/algorithm/AppImage';

export function replaceWebPath(image: string) {
  return image.replace('{picdomain}', environment.picsDomain);
}
export function getImage(image: any) {
  if (!image?.icon) return;
  const imagePath = `${image.icon.webPath}/${image.icon.fileName}`;
  return this.replaceWebPath(imagePath);
}

export function getAppImage(image: AppImage) {
  if (!image) return;
  const imagePath = `${image.webPath}/${image.fileName}`;
  return imagePath.replace('{picdomain}', environment.picsDomain);
}
