<div class="lab">
  <button (click)="location.back()" class="lab__back" mat-button>< Back</button>

  <mat-card class="lab__card">
    <mat-card-header class="lab__card__header">
      <mat-card-title class="lab__card__header__title">
        <mat-icon>biotech</mat-icon>
        Lab DNA results
      </mat-card-title>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="lab__table">
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">
            File name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.originalFileName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lab">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">Lab</th>
          <td mat-cell *matCellDef="let element">
            {{ element.labName | uppercase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">Version</th>
          <td mat-cell *matCellDef="let element">{{ element.versionName }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date: "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <button
              mat-icon-button
              class="lab__table__action"
              (click)="recalcVersion(element.packageId)"
              matTooltip="Recalculate Results"
              matTooltipPosition="right"
              [disabled]="!userCanRecalc()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
  <app-table-paginator
    [minWidth]="true"
    [sticky]="true"
    *ngIf="metaData"
    [metaData]="metaData"
    [headFilter]="headFilter"
    (filterChanged)="getPaginatorData($event)"
  >
  </app-table-paginator>
</div>
