import { Agent } from './Agent';
import { CancelationRequest } from './CancelationRequest';
import { Client } from './Client';
import { Patient } from './Patients';
import { ServiceRequest } from './ServiceRequest';
import { Status } from './Status';

export class Note {
  noteId: number;
  dateCreated: string | null;
  lastUpdated: string | null;
  statusId: number | null;
  status: Status;
  clientId: number | null;
  client: Client;
  patientId: number | null;
  patient: Patient;
  assigningAgentId: number;
  assigningAgent: Agent;
  assignedAgentId: number | null;
  assignedAgent: Agent;
  title: string;
  desc: string;
  noteTypeId: number;
  noteType: any;
  noteCategoryId: number | null;
  noteCategory: Status;
  repeatble: boolean;
  dueDate: string | null;
  serviceRequestId: number | null;
  serviceRequest: ServiceRequest;
  cancelationRequestId: number | null;
  cancelationRequest: CancelationRequest;
  rating: number | null;
  ratingComment: string;
  productId: number | null;
  dateCreatedObj: Date;
  dueDateObj: Date;
  imageUrl: string;
  constructor() {}
}
