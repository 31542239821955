<div
  matRipple
  class="chat-list__row"
  *ngFor="let item of chatList"
  (click)="onRowClick(item)"
>
  <div class="chat-list__row__avatar">
    <img
      src="../../../assets/avatar-{{ item.avatarClientId }}.svg"
      alt="avatar"
    />
  </div>
  <div class="chat-list__row__data">
    <div class="chat-list__row__data__msg">
      <div class="chat-list__row__data__msg__name">
        {{ item.displayName }} {{ item.isActive ? " - מחובר" : "" }}
      </div>
      <div class="chat-list__row__data__msg__last-msg">
        <span class="v-mark" [innerHtml]="getIsSeen(item)"></span>
        <span #msgText>{{ truncateTxt(item.msg, msgText) }}</span>
      </div>
    </div>
    <div class="chat-list__row__data__meta">
      <div
        [innerHtml]="getLatestDate(item.createdDate)"
        class="chat-list__row__data__meta__date"
      ></div>
      <div style="display: flex">
        <div
          class="chat-list__row__data__meta__type"
          [ngStyle]="{
            backgroundColor: getSubjectFromEnum(item.subjectId)?.color
          }"
        >
          {{ getSubjectFromEnum(item.subjectId)?.letter }}
        </div>
        <div
          *ngIf="item.countCrm > 0"
          class="chat-list__row__data__meta__indicator"
          style="margin-right: 5px"
        >
          {{ item.countCrm }}
        </div>
      </div>
    </div>
  </div>
</div>
