<div>
  <div class="title">
    Subject&nbsp;&nbsp;
    <small>* In the SMS subject will not appear in the message content</small>
  </div>
  <mat-form-field appearance="fill" class="w-100">
    <input
      matInput
      placeholder="subject"
      [(ngModel)]="emailTemplateLangs[langId].subject"
    />
  </mat-form-field>
</div>
<div class="mb-2"></div>
<div class="message-content">
  <div class="title">Message content</div>
  <mat-form-field appearance="fill" class="w-100">
    <textarea
      matTextareaAutosize
      matAutosizeMinRows="8"
      matInput
      #textEmailField
      [(ngModel)]="emailTemplateLangs[langId].textEmail"
      class="message-field"
    ></textarea>
  </mat-form-field>
</div>
