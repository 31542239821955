import { Component, OnInit } from '@angular/core';
import { Patient } from 'src/app/data/Patients';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.css'],
})
export class CreateNewUserComponent implements OnInit {
  model: RegularUser;
  constructor(private regularUsersService: RegularUsersService) {}

  ngOnInit(): void {
    this.model = new RegularUser();
    this.model.patient = new Patient();
  }
  onSubmit() {
   
    this.regularUsersService.registerPatient(this.model).subscribe();
  }
}
