import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginPageComponent } from '../Components/login-page/login-page.component';
import { EnterTokenComponent } from '../Components/enter-token/enter-token.component';
import { MyGenesMaterialModule } from '@mygenes/my-genes-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [LoginPageComponent, EnterTokenComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    MyGenesMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ],
  exports: [],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
})
export class AuthenticationModule {}
