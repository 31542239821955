import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FoodGenesChangeLog } from 'src/app/data/food-genes/FoodGenesChangeLog';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import {
  FoodGenesService,
  HistoryPaginationObject,
} from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-history',
  templateUrl: './food-genes-history.component.html',
  styleUrls: ['./food-genes-history.component.scss'],
})
export class FoodGenesHistoryComponent implements OnInit {
  @Input() langId: boolean;
  @Input() versionId: number;
  displayedColumns: string[] = ['date', 'agent', 'log', 'objectName'];
  dataSource: MatTableDataSource<FoodGenesChangeLog> = new MatTableDataSource(
    []
  );
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  history: FoodGenesChangeLog[];
  selectedTypeId: number;
  loggerTypes: BaseResponce[];
  constructor(
    private foodGenesService: FoodGenesService,
    private filterMemoryService: FilterMemoryService,
    private _snackBar: MatSnackBar
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.snps);
  }

  ngOnInit(): void {
    this.getLogerTypes();
  }
  setDataSource(data: FoodGenesChangeLog[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  getLogerTypes() {
    this.foodGenesService.getFoodGenesLoggerTypes().subscribe((data) => {
      this.loggerTypes = data;
      this.selectedTypeId = this.loggerTypes[0].id;
      this.getHistory();
    });
  }
  getHistory() {
    this.foodGenesService
      .getFoodGenesHistory(
        this.selectedTypeId,
        this.versionId,
        this.headFilter.pageNumber - 1,
        this.headFilter.pageSize
      )
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this._snackBar.open((data as ErrorMessage).message, 'Close');
          this.history = [];
          this.setDataSource(this.history);
        } else {
          const responce = data as HistoryPaginationObject;
          this.history = responce.list;
          this.metaData = responce.metadata;
          this.setDataSource(this.history);
          this._snackBar.dismiss();
        }
      });
  }

  getPaginatorData(event: HeadFilter) {
    this.headFilter = event;
    this.getHistory();
  }
}
