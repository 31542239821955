import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Agent } from '../data/Agent';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentMin } from '../data/AgentMin';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  private agents: AgentMin[];
  constructor(private http: HttpClient) {}
  /*
  getAll() 
  {
      if (this.agents)
      {
        return of(this.agents);  // return from cache
      } else
      {
          return this.http.get<Agent[]>(`${environment.baseUrl}Agents/`).pipe(map(res => (this.agents=res)));
      }
    //return this.http.get<Agent[]>(`${environment.baseUrl}Agents/`);
  }
  */
  getAll() {
    /* if (this.agents) {
      return of(this.agents); // return from cache
    } else { */
    return this.http
      .get<AgentMin[]>(`${environment.baseUrl}Agents/AllMinV2`)
      .pipe(map((res) => (this.agents = res)));
    //}
    //return this.http.get<Agent[]>(`${environment.baseUrl}Agents/`);
  }
  getById(agentId) {
    return this.http.get<Agent>(`${environment.baseUrl}Agents/${agentId}`);
  }
  /*
  put(agent) {
    return this.http.put(
      `${environment.baseUrl}Agents/${agent.agentId}`,
      agent
    );
  }
  post(agent) {
    return this.http.post<Agent>(`${environment.baseUrl}Agents/`, agent);
  }
  delete(agent) {
    return this.http.delete(`${environment.baseUrl}Agents/${agent.agentId}`);
  }
  */
  getHomePageStats(agentId) {
    return this.http.get(
      `${environment.baseUrl}Agents/HomePageStats/${agentId}`
    );
  }
  transferAgentsClients(type, fromAgentId, toAgentId, isTasks) {
    return this.http.get(
      `${environment.baseUrl}Agents/transferAgentsClients/${type}/${fromAgentId}/${toAgentId}/{isTasks}`
    );
  }
}
