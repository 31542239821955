import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PrototypeSnp } from 'src/app/data/algorithm/PrototypeSnp';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';

@Component({
  selector: 'app-all-snps',
  templateUrl: './all-snps.component.html',
  styleUrls: ['./all-snps.component.scss'],
})
export class AllSnpsComponent implements OnInit {
  headFilter: HeadFilter;
  prototypeSnp: PrototypeSnp[];
  pagination: PaginationMetadata;
  constructor(
    private filterMemoryService: FilterMemoryService,
    private algorithmService: AlgorithmService,
    public location: Location
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.snps);
  }

  ngOnInit(): void {
    this.getSnpsWithPaging();
  }

  getSnpsWithPaging() {
    this.algorithmService
      .getSnpsWithPaging(this.headFilter)
      .subscribe((data) => {
        this.prototypeSnp = data.snps;
        this.pagination = data.pagination;
      });
  }
  searchSnpsWithPaging(fromPaging: boolean = false) {
    if (!fromPaging) {
      this.headFilter.pageNumber = 1;
    }
    this.algorithmService
      .searchSnpsWithPaging(this.headFilter)
      .subscribe((data) => {
        this.prototypeSnp = data.snps;
        this.pagination = data.pagination;
      });
  }

  onSearchBtnClick() {
    if (this.headFilter.searchTerm.length > 0) {
      this.searchSnpsWithPaging();
    } else {
      this.getSnpsWithPaging();
    }
  }
  getPaginatorData(event: HeadFilter) {
    this.headFilter = event;
    if (this.headFilter.searchTerm.length > 0) {
      this.searchSnpsWithPaging(true);
    } else {
      this.getSnpsWithPaging();
    }
  }
}
