import { Agent } from './Agent';
import { CancelationRequest } from './CancelationRequest';
import { Client } from './Client';
import { Deal } from './Deal';
import { DealForTableMin } from './DealForTableMin';
import { ProductForDealBundle } from './ProductForDealBundle';
import { Status } from './Status';

export class DealBundle {
  dealBundleId: number;
  clientId: number;
  client: Client;
  agentId: number;
  agent: Agent;
  dateCreated: string;
  dateUpdated: string;
  isCancelDeal: boolean;
  deals: Deal[];
  productForDealBundles: ProductForDealBundle[];
  dealsMin: DealForTableMin[];
  amount: number;
  leftToPay: number;
  statusId: number | null;
  status: Status;
  originalCancelationRequests: CancelationRequest[];
  cancelCancelationRequests: CancelationRequest[];
}
