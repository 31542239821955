<table mat-table [dataSource]="clientProducts">
  <ng-container matColumnDef="RegisteredDate">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>
      Registered Date
    </th>
    <td mat-cell *matCellDef="let product">
      {{
        product &&
          product.patientId &&
          product.patientCreatedDate &&
          product.patientCreatedDate | date: "dd-MM-yyyy"
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="testType">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>Test Type</th>
    <td mat-cell *matCellDef="let product">
      {{ product.productType }}
    </td>
  </ng-container>

  <ng-container matColumnDef="tubeId">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>Tube ID</th>
    <td mat-cell *matCellDef="let product">
      <span class="d-flex-center">
        <mat-form-field appearance="fill" class="editTubeId">
          <input
            [disabled]="!userHasPermisionsWithRule(['Products_Update'])"
            matInput
            [(ngModel)]="product.tubeId"
          />
        </mat-form-field>
        <button
          [disabled]="!userHasPermisionsWithRule(['Products_Update'])"
          mat-button
          (click)="requestChangeBarcode(product)"
        >
          change
        </button>
        <button
          [disabled]="!userHasPermisionsWithRule(['Products_Update'])"
          *ngIf="product.tubeId"
          mat-button
          (click)="requestResetBarcode(product)"
        >
          reset
        </button>
      </span>
      <!--
       *ngIf="isDeveloper()"
      <span>
        {{ product.bar ? product.tubeId : "-----------" }}
      </span>
    </td>
    --></td>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>Patient Name</th>
    <td
      mat-cell
      *matCellDef="let product"
      (click)="showProductLink() && goToPatient(product)"
    >
      <span [class.link]="showProductLink()">{{ product.patientName }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="patientId">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>Patient ID</th>
    <td mat-cell *matCellDef="let product">
      {{ product.socialSecurityNum }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let product">
      <span
        (click)="changeStatusToSentToCustomer(product)"
        class="round-indicator"
        style="white-space: nowrap"
        [ngStyle]="{ 'background-color': product.status.color }"
      >
        {{ product.status && product.status.name }}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="theProductIsFor">
    <th style="width: 14.2%" mat-header-cell *matHeaderCellDef>
      The product is for
    </th>
    <td mat-cell *matCellDef="let product" style="display: flex">
      <form>
        <mat-form-field class="example-full-width round-select">
          <mat-select
            [disabled]="checkStatusDisabled(product)"
            (selectionChange)="changeProductPatient($event, product)"
            [value]="getProductForVal(product)"
            disableOptionCentering
            style="width: 100px; font-size: 12px"
            name="productIsForDropdown"
          >
            <mat-option [value]="1" style="font-size: 12px"> Me </mat-option>
            <mat-option [value]="2" style="font-size: 12px">
              New Patient
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <!-- formControlName="agentId"-->
      <img
        (click)="editPatientDetails(product)"
        src="../../../assets/lead-edit.svg"
        style="cursor: pointer"
      />
    </td>
  </ng-container>
  <!--
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let product">
      {{ product.deal.agent.regularUser.Name }}
    </td>
  </ng-container>
-->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
