<perfect-scrollbar style="height: calc(100vh - 66px)">
  <div class="container">
    <div [ngSwitch]="mode">
      <div *ngSwitchCase="AlgoMainBoardEnum.snp">
        <app-edit-rs
          [snp]="snp"
          [subCategory]="subCategory"
          [langId]="langId"
          [canEdit]="canEdit"
          (loadRs)="onLoadRs($event)"
        ></app-edit-rs>
      </div>
      <div *ngSwitchCase="AlgoMainBoardEnum.summary">
        <app-edit-summary
          [subCatResultTypes]="subCatResultTypes"
          [subCategory]="subCategory"
          [canEdit]="canEdit"
          [langId]="langId"
          [versionId]="versionId"
          (loadResultTypes)="onLoadResultTypes($event)"
        ></app-edit-summary>
      </div>
      <div *ngSwitchCase="AlgoMainBoardEnum.search">
        <app-search-snp
          [results]="results"
          (selectResult)="onSelectResult($event)"
        ></app-search-snp>
      </div>
      <div *ngSwitchCase="AlgoMainBoardEnum.addCategory">
        <app-add-category
          [langId]="langId"
          [versionId]="versionId"
          [canEdit]="canEdit"
          (loadCategories)="onLoadCategories($event)"
          (closeMainBoard)="onCloseMainBoard($event)"
        ></app-add-category>
      </div>
      <div *ngSwitchCase="AlgoMainBoardEnum.category">
        <app-edit-category
          [canEdit]="canEdit"
          [langId]="langId"
          [category]="category"
          [categoryId]="category?.categoryId"
          (loadCategories)="onLoadCategories($event)"
          (closeMainBoard)="onCloseMainBoard($event)"
        ></app-edit-category>
      </div>
      <div *ngSwitchCase="AlgoMainBoardEnum.subCategory">
        <app-edit-sub-category
          *ngIf="category"
          [canEdit]="canEdit"
          [categoryId]="category?.categoryId"
          [langId]="langId"
          [versionId]="versionId"
          [subCategory]="subCategory"
          (loadCategories)="onLoadCategories($event)"
          (closeMainBoard)="onCloseMainBoard($event)"
        ></app-edit-sub-category>
      </div>
    </div>
  </div>
</perfect-scrollbar>
