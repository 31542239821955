import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-agent-schedule',
  templateUrl: './agent-schedule.component.html',
  styleUrls: ['./agent-schedule.component.scss'],
})
export class AgentScheduleComponent implements OnInit {
  agentId: string;
  lunchBreakActive: boolean;
  spacialCondintionActive: boolean;
  workMinutsStart: string;
  workHoursStart: string;
  workMinutsEnd: string;
  workHoursEnd: string;
  breakHoursStart: any;
  breakMinutsStart: any;
  breakHoursEnd: any;
  breakMinutsEnd: any;
  constructor(public location: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.agentId = params['agentId'];
      }
    });
  }

  onWorkHoursChange(event: MatSelectChange, position: 'start' | 'end') {
    if (event.value == 18) {
      position === 'start'
        ? (this.workMinutsStart = '00')
        : (this.workMinutsEnd = '00');
    }
  }

  onHoursStartChange($event, type: 'work'|'break'|'spacial') {
    switch (type) {
      case 'work':
        this.workHoursStart = $event;
        break;
      case 'break':
        this.workHoursStart = $event;
        break;
      case 'spacial':
        this.workHoursStart = $event;
        break;
    }
    
  }
  onMinutsStartChange($event, type: string) {
    this.workMinutsStart = $event;
  }
  onHoursEndChange($event, type: string) {
    this.workHoursEnd = $event;
  }
  onMinutsEndChange($event, type: string) {
    this.workMinutsEnd = $event;
  }
}
