import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sugartest-report-view',
  templateUrl: './sugartest-report-view.component.html',
  styleUrls: ['./sugartest-report-view.component.css']
})
export class SugartestReportViewComponent implements OnInit {
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  onEditClick() {
    this.onEdit.emit();
  }
}
