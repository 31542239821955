import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from '../data/Menu';
import { MenuForSite } from '../data/MenuForSite';
import { MenuForTable } from '../data/MenuForTable';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class NutriMenuService {
  getMenuSubject$: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getMenu() {
    this.getMenuSubject$.next(true);
  }

  getAll() {
    return this.http.get<NameAndId[]>(`${environment.baseUrl}Menus/Templates/`);
  }

  getByMenuIdForHtml(menuId, productId, langId) {
    return this.http.get<MenuForSite>(
      `${environment.baseUrl}Meals/ForMenuSite/${menuId}/${productId}/${langId}`
    );
  }

  sendToMail(menuId: number, productId: number, html: any) {
    return this.http.post<any>(
      `${environment.baseUrl}menus/SendToMail/${menuId}/${productId}`,
      { html }
    );
  }

  createFromTeplate(menuId: number, productId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Menus/CreateFromTemplate/${menuId}/${productId}`
    );
  }

  getByMenuId(menuId) {
    return this.http.get<Menu>(`${environment.baseUrl}Menus/${menuId}`);
  }

  getMenusByProductId(productId: number, isSite: boolean = false) {
    return this.http.get<MenuForTable[]>(
      `${environment.baseUrl}Menus/ForProduct/${productId}/${isSite}`
    );
  }

  getTemplatesList() {
    return this.http.get<MenuForTable[]>(
      `${environment.baseUrl}Menus/TemplatesForEdit/`
    );
  }

  getTemplatesDropdown() {
    return this.http.get<NameAndId[]>(`${environment.baseUrl}Menus/Templates`);
  }

  put(menu: Menu) {
    return this.http.put<Menu>(
      `${environment.baseUrl}Menus/${menu.menuId}`,
      menu
    );
  }

  post(menu: Menu) {
    return this.http.post<Menu>(`${environment.baseUrl}Menus/`, menu);
  }

  delete(menuId: number) {
    return this.http.delete<Menu>(`${environment.baseUrl}Menus/${menuId}`);
  }
}
