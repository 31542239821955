import { Component, Input, OnInit } from '@angular/core';
import { FoodNutrientsZameret, NutrientType } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-nutritional-values',
  templateUrl: './nutritional-values.component.html',
  styleUrls: ['./nutritional-values.component.css'],
})
export class NutritionalValuesComponent implements OnInit {
  constructor() {}
  showCaloriesPie: boolean = false;
  @Input() originalNutrients: any[];
  objNutrients: any[];
  objVitamins: any[];
  objCalories: any;
  objGoalCalories: any;

  paiData: any[] = [];
  colorScheme = {
    domain: ['#1b3f4e', '#119c99'],
  };
  ngOnInit(): void {
    this.getMenuCalories();
    this.proccessData();
    this.setPaiData();
  }
  getMenuCalories() {
    const menuToNutrients = this.originalNutrients.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
    if (menuToNutrients) {
      this.objCalories = { value: menuToNutrients.amount, color: '#BB0404' };
      this.objGoalCalories = {
        value: menuToNutrients.dailyAmount,
        color: '#D3247E',
      };
    }
  }
  proccessData() {
    this.objNutrients = this.originalNutrients.filter(
      (x) => x.foodNutrient.nutrientTypeId == NutrientType.nutrients
    );
    this.objVitamins = this.originalNutrients.filter(
      (x) =>
        x.foodNutrient.nutrientTypeId == NutrientType.vitamins && x.amount >= 1
    );
  }
  isMenuCaloriesInRange() {
    if (!this.objCalories || !this.objGoalCalories) return false;
    var diff = Math.abs(this.objCalories.value - this.objGoalCalories.value);
    if (diff <= 150) return true;
    return false;
  }

  getBarData() {
    const menuItem = this.objNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
    return {
      goal: Math.round(menuItem?.dailyAmount),
      menu: Math.round(menuItem?.amount),
    };
  }

  setPaiData() {
    const menuItem = this.objNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
    if (!menuItem) return;
    const dailyAmount = Math.round(menuItem.dailyAmount);
    const amount = Math.round(menuItem.amount);
    this.paiData.push([
      {
        value: dailyAmount,
        name: 'Goal Calories',
        color: '#1b3f4e',
      },
      {
        value: amount,
        name: 'Menu Calories',
        color: '#119c99',
      },
    ]);
  }
}
