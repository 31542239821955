import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlgoResultType } from 'src/app/data/algorithm/AlgoResultType';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { AlgoSubCategoryResultType } from 'src/app/data/algorithm/AlgoSubCategoryResultType';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { PrototypeSubCategory } from 'src/app/data/algorithm/PrototypeSubCategory';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-edit-sub-category',
  templateUrl: './edit-sub-category.component.html',
  styleUrls: ['./edit-sub-category.component.scss'],
})
export class EditSubCategoryComponent implements OnInit, OnChanges {
  //#region Properties
  @Input() subCategory: AlgoSubCategory;
  @Input() versionId: number;
  @Input() langId: number;
  @Input() categoryId: number;
  @Input() canEdit: boolean;
  @Output() loadCategories: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMainBoard: EventEmitter<boolean> = new EventEmitter<boolean>();
  addState: 'addFromList' | 'addFromInput';
  subCategories: PrototypeSubCategory[];
  resultTypes: AlgoResultType[];
  errorMessage: string;
  AlgoImageType = AlgoImageType;
  //#endregion
  constructor(
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subCategory && !changes.subCategory?.currentValue) {
      //this.subCategory = changes.subCategory.currentValue;
      this.init();
    }
  }
  ngOnInit(): void {
    this.init();
  }
  init() {
    if (!this.subCategory) {
      this.subCategory = new AlgoSubCategory();
      this.subCategory.resultTypes = [];
      this.subCategory.subCategoryId = 0;
      this.getAvailableSubCategories();
      this.getAvailableResultTypes();
    } else {
      this.chooseState('addFromInput');
    }
  }
  chooseState(state: 'addFromList' | 'addFromInput') {
    this.addState = state;
  }
  onSubCatChange(e: number) {
    const prototype = this.subCategories.find((x) => x.subCategoryId == e);
    this.subCategory.files = prototype.files;
    this.subCategory.rangeStart = prototype.rangeStart;
    this.subCategory.rangeEnd = prototype.rangeEnd;
    this.subCategory.orderBy = prototype.orderBy;
    //this.subCategory.resultTypes = prototype.;
  }
  getAvailableSubCategories() {
    this.algorithmService
      .getAvailableSubCategories(this.versionId, this.categoryId, this.langId)
      .subscribe((res) => {
        this.subCategories = res;
        if (this.subCategories.length == 0) {
          this.addState = 'addFromInput';
        } else {
          this.addState = null;
        }
      });
  }
  onSelectResultTypes(e: MatSelectChange) {
    this.subCategory.resultTypes = e.value.map((x: AlgoResultType) => {
      const type = new AlgoSubCategoryResultType();
      type.resultTypeId = x.algoResultTypeId;
      return type;
    });
  }
  getAvailableResultTypes() {
    const subCatId = this.subCategory.subCategoryId
      ? this.subCategory.subCategoryId
      : 0;
    this.algorithmService
      .getAvailableResultTypes(subCatId, this.langId)
      .subscribe((res) => {
        this.resultTypes = res;
      });
  }
  onUploadFinished(event: AppImage) {
    if (event) {
      const finded = this.subCategory.files.find(
        (x) => x.fileType == event.fileType
      );
      if (finded) {
        this.subCategory.files = this.subCategory.files.filter(
          (x) => x.fileType != event.fileType
        );
      }
      this.subCategory.files.push(event);
    }
  }
  getImage(imageType: number) {
    const image = this.subCategory.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.subCategory.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }

  handleErrorOrSuccess(res: ErrorMessage | any, snackbarMassege: string) {
    if ((res as ErrorMessage)?.message) {
      const error = res as ErrorMessage;
      this.errorMessage = error.message;
    } else {
      this._snackBar
        .open(`Sub category ${snackbarMassege}`, 'Close', {})
        ._dismissAfter(2000);
      this.loadCategories.emit(true);
      this.closeMainBoard.emit(true);
    }
  }
  save(subCatform: NgForm) {
    if (subCatform.invalid) {
      return;
    }
    this.subCategory.labVersionId = this.versionId;
    this.subCategory.langId = this.langId;
    this.subCategory.parentCategoryId = this.categoryId;
    if (this.subCategory.subCategoryId == 0) {
      this.algorithmService
        .addSubCategory(this.subCategory)
        .subscribe((res) => {
          this.handleErrorOrSuccess(res, 'added');
        });
    } else if (this.subCategory.subCategoryId > 0) {
      this.algorithmService
        .setSubCategory(this.subCategory, this.langId)
        .subscribe((res) => {
          this.handleErrorOrSuccess(res, 'updated');
        });
    }
  }
}
