<div style="display: flex; flex-direction: column; align-items: space-between">
  <ng-container style="list-style-type: none" *ngFor="let option of data.list">
    <button
      style="margin-bottom: 40px"
      class="btn-rect btn-rect--client"
      (click)="onOptionSelected(option.val)"
    >
      {{ option.name }}
    </button>
  </ng-container>
</div>
