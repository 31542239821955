<!--<app-top-nav></app-top-nav> -->
<app-navbar
  [regularUser]="regularUser"
  [isDashboard]="isDashboard()"
  [agentInteraction]="agentInteraction"
  [chatAlerts]="chatAlerts"
  (changeInteraction)="onChangeAgentInteraction($event)"
  [chatAvailable]="agentSettings?.isChatAvailable"
></app-navbar>
<div class="clear-space" [ngClass]="getClassName()">
  <div style="height: 65px"></div>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
