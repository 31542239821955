import { AlgoBullet } from './AlgoBullet';
import { Status } from './Status';
import { SubCategory } from './SubCategory';

export class BulletAlgoCrossing {
  bulletAlgoCrossingId: number;
  algoBulletId: number;
  algoBullet: AlgoBullet;
  subCategoryId: number;
  subCategory: SubCategory;
  conditionId: number;
  condition: Status;
  valueId: number;
  value: Status;

  constructor(subCategoryId = null, conditionId = null, valueId = null) {
    if (subCategoryId && conditionId && valueId) {
      this.subCategoryId = subCategoryId;
      this.conditionId = conditionId;
      this.valueId = valueId;
    }
  }
}
