<!-- <div class="answer-msg">
  <div class="answer-msg__bot">{{ message.template.context }}:</div>
  <div class="answer-msg__user">
    {{ message.template.segments[0].context }}
  </div>
</div>
<div class="meta-data">
  <ng-container *ngIf="message.createdDate">
    {{ message.createdDate | date : "d/M/yy HH:mm" }}</ng-container
  >
  &nbsp;<mat-icon
    class="read-mark"
    [class.readed]="message.statusId == MessageStatus.Readed"
    >{{
      message.statusId == MessageStatus.Readed ? "done_all" : "done"
    }}</mat-icon
  >
</div> -->
<ng-container *ngIf="message">
  <ng-template #dynamicBubble></ng-template>
</ng-container>
