import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { Laboratory } from 'src/app/data/algorithm/Laboratory';
import { Language } from 'src/app/data/Language';
import { AlgoMainBoardEnum } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-sidenav-cat',
  templateUrl: './sidenav-cat.component.html',
  styleUrls: ['./sidenav-cat.component.scss'],
})
export class SideNavCatComponent implements OnInit {
  //#region Class Variables
  @ViewChildren('panelsCategory') panelsCategory: QueryList<MatExpansionPanel>;
  @ViewChild('accordion') accordion: MatAccordion;
  @Input() isLoading: boolean;
  @Input() categories: AlgoCategory[];
  @Input() sidenavSnp: MatSidenav;
  @Input() languages: Language[];
  @Input() langId: number;
  @Input() versionId: number;
  @Input() algoVersions: Laboratory[];
  @Input() dir: string;
  @Input() indexExpanded: number;
  @Input() selectedCategory: AlgoCategory;
  @Input() selectedSubCategory: AlgoSubCategory;
  @Input() canEdit: boolean;
  @Input() canSearch: boolean;
  @Output('selected')
  selected: EventEmitter<{
    subCategory?: AlgoSubCategory;
    category?: AlgoCategory;
    isSummarySelected?: boolean;
  }> = new EventEmitter();
  @Output('selectSummary') selectSummary: EventEmitter<boolean> =
    new EventEmitter();
  @Output('closeMainBoard') closeMainBoard: EventEmitter<boolean> =
    new EventEmitter();
  @Output('searchSnp') searchSnp: EventEmitter<string> = new EventEmitter();
  @Output('changeLanguage') changeLanguage: EventEmitter<number> =
    new EventEmitter();
  @Output('changeVersion') changeVersion: EventEmitter<string> =
    new EventEmitter();
  @Output() loadCategories = new EventEmitter<boolean>();
  @Output() openMainBoardEvent: EventEmitter<{
    mode: AlgoMainBoardEnum;
    category?: AlgoCategory;
    subCategory?: AlgoSubCategory;
  }> = new EventEmitter<null>();
  searchTerm: string;
  //#endregion
  constructor() {}
  ngOnInit(): void {}
  onSelectCategory(category: AlgoCategory, inx: number) {
    if (this.selectedCategory?.categoryId === category.categoryId) {
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.sidenavSnp.close();
      this.closeMainBoard.emit(true);
    } else {
      this.selectedCategory = category;
    }
    // setTimeout(() => {
    //   this.indexExpanded = inx;
    // }, 300);
  }
  onSelectSubCategory(subCategory: AlgoSubCategory) {
    //this.selectedSubCategory = subCategory;
    if (this.selectedSubCategory?.subCategoryId === subCategory.subCategoryId) {
      //if select the same subcategory then close the sidenav
      this.selectedSubCategory = null;
      this.selected.emit(null);
      this.closeMainBoard.emit(true);
      if (this.sidenavSnp.opened) {
        this.sidenavSnp.close();
      }
    } else {
      //if subcategory selected for the first time programtically load the summary
      this.selectedSubCategory = subCategory;
      this.selected.emit({
        subCategory: this.selectedSubCategory,
        category: this.selectedCategory,
        isSummarySelected: true,
      });
      this.sidenavSnp.open();
    }
  }
  onSearchBtnClick() {
    if (this.searchTerm.length > 0) {
      this.searchSnp.emit(this.searchTerm);
    }
  }
  onChangeLang(langId: number) {
    this.changeLanguage.emit(langId);
    // reopen the sidenav with current index
    setTimeout(() => {
      if (this.indexExpanded > -1) {
        this.panelsCategory.toArray()[this.indexExpanded].open();
      }
    }, 1000);
  }
  onChangeVersion(event: MatSelectChange) {
    this.changeVersion.emit(event.value);
  }
  onSave(event) {
    if (event) {
      console.log('save SideNavCatComponent');
      this.loadCategories.emit(true);
    }
  }
  addCategory() {
    this.openMainBoardEvent.emit({ mode: AlgoMainBoardEnum.addCategory });
  }
}
