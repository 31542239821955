import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Translation } from '../data/Translation';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Translation[]>(`${environment.baseUrl}Translations/`);
  }
  getById(agentId) {
    return this.http.get<Translation>(
      `${environment.baseUrl}Translations/${agentId}`
    );
  }

  put(translation: Translation) {
    return this.http.put(
      `${environment.baseUrl}Translations/${translation.translationId}`,
      translation
    );
  }
  post(translation: Translation) {
    return this.http.post<Translation>(
      `${environment.baseUrl}Translations/`,
      translation
    );
  }
  delete(translation: Translation) {
    return this.http.delete(
      `${environment.baseUrl}Translations/${translation.translationId}`
    );
  }
}
