<input
  [(ngModel)]="DisableBarDeliveriesDelivery"
  (change)="onDisableDeliversChange()"
  type="checkbox"
/>
disableBarDeliveriesDeliver
<button style="margin-left: 40px" (click)="releaseDelivers()">
  release All Delivers
</button>
<br />
<br />
<input
  [(ngModel)]="DisableBarDeliveriesPickup"
  (change)="onDisablePickupsChange()"
  type="checkbox"
/>
disableBarDeliveriesPickup
<button style="margin-left: 40px" (click)="releasePickups()">
  release All Pickups
</button>
