<app-head-filter
  *ngIf="agents"
  [agents]="agents"
  [requestsSubjects]="requestsSubjects"
  [leadStatuses]="requestsStatuses"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event, true)"
  [classAttr]="'requestsAttr'"
></app-head-filter>
<div class="TableClearFix"></div>
<app-service-request-table
  *ngIf="true"
  [agents]="agents"
  [leadStatuses]="leadStatusesTable"
  [leadSources]="leadSources"
  [requestsStatuses]="requestsStatuses"
  [serviceRequests]="serviceRequests"
  (filterChanged)="getAllWithFilter($event)"
  (appReportClicked)="onAppReportClicked($event)"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  [isLoading]="isLoading"
  (checkedIdsChanged)="changeCheckedIds($event)"
>
</app-service-request-table>
<app-table-paginator
  [agents]="agents"
  [leadStatuses]="statuses"
  *ngIf="serviceRequests && metaData && statuses && agents"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'leadsAttr'"
>
</app-table-paginator>

<!-- (checkedIdsChanged)="onCheckedIdsChanged($event)"-->
