import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageAgentsService } from 'src/app/Services/manage-agents.service';
import { Agent } from 'src/app/data/Agent';
import { RegularUser } from 'src/app/data/RegularUser';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SendMailService } from 'src/app/Services/send-mail.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { UserPhone } from 'src/app/data/UserPhone';
import { plainToClass } from 'class-transformer';
import { OfficePhoneExtension } from 'src/app/data/OfficePhoneExtension';
import { OfficePhoneExtensionsService } from 'src/app/Services/office-phone-extensions.service';
import { UserRolesService } from 'src/app/Services/user-roles.service';
import { NameAndId } from 'src/app/data/NameAndId';
import { ErrorMessage } from 'src/app/data/ErrorMessage';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.css'],
})
export class EditAgentComponent implements OnInit {
  agentForm: FormGroup = null;
  //@ViewChild('f') form: any;

  agent: Agent;
  queryAgentId: number;
  //roleTypes: RoleType[];
  userRoles: NameAndId[];
  submited: boolean = false;
  officePhoneExtensions: OfficePhoneExtension[];
  isLoading: boolean;

  constructor(
    private router: Router,
    private manageAgentsService: ManageAgentsService,
    private route: ActivatedRoute,
    //private roleTypesService: RoleTypesService,
    private sendMailService: SendMailService,
    private popupHandlerService: PopupHandlerService,
    private regularUsersService: RegularUsersService,
    private officePhoneExtensionsService: OfficePhoneExtensionsService,
    private userRolesService: UserRolesService
  ) {}
  createFormGroup() {
    this.agentForm = new FormGroup({
      firstName: new FormControl(this.agent.regularUser.firstName, [
        Validators.required,
      ]),

      phone: new FormControl(this.agent.regularUser.getUserPhone(), [
        Validators.required,
      ]),
      email: new FormControl(this.agent.regularUser.email, [
        Validators.required,
      ]),
      userRoleId: new FormControl(this.agent.regularUser.userRoleId, [
        Validators.required,
      ]),
      isActive: new FormControl(this.agent.regularUser.isActive, [
        Validators.required,
      ]),
      gender: new FormControl(this.agent.regularUser.gender, [
        Validators.required,
      ]),
      /* officePhoneExtension: new FormControl(
        this.agent.officePhoneExtensionId
          ? this.agent.officePhoneExtensionId
          : '',
        [Validators.required]
      ), */
      password: new FormControl(''),
    });
  }
  getExtensions() {
    this.officePhoneExtensionsService.getAll().subscribe((data) => {
      this.officePhoneExtensions = data;
    });
  }
  getFalse() {
    return false;
  }
  ngOnInit(): void {
    this.userRolesService.getMin().subscribe((data) => {
      this.userRoles = data;
    });
    //this.getExtensions();
    this.route.queryParams.subscribe((params) => {
      this.queryAgentId = params['agentId'];
      if (this.queryAgentId != -1) {
        this.manageAgentsService
          .getById(this.queryAgentId)
          .subscribe((agent) => {
            this.agent = plainToClass(Agent, agent);
            this.agent.regularUser = plainToClass(
              RegularUser,
              agent.regularUser
            );
            if (!this.agent.regularUser.userPhones) {
              this.agent.regularUser.userPhones = [];
            }
            this.createFormGroup();
          });
      } else {
        this.agent = new Agent();
        this.agent.regularUser = new RegularUser();
        this.createFormGroup();
      }
    });
  }
  goBackToAgents() {
    this.router.navigate(['/agents']);
  }
  onSubmit() {
    this.submited = true;
    this.isLoading = true;
    if (this.agentForm.valid) {
      if (this.queryAgentId == -1) {
        this.agent = new Agent();
        this.agent.regularUser = new RegularUser();
      }
      if (!this.agent.regularUser.userPhones) {
        this.agent.regularUser.userPhones = [];
      }
      this.agent.regularUser.userPhones = this.agent.regularUser.userPhones.map(
        (x) => {
          x.isActive = false;
          return x;
        }
      );
      var phoneExist = this.agent.regularUser.userPhones.find(
        (x) => x.phoneNumber == this.agentForm.value.phone
      );
      if (phoneExist) {
        this.agent.regularUser.userPhones =
          this.agent.regularUser.userPhones.map((x) => {
            if (x.phoneNumber == phoneExist.phoneNumber) {
              x.isActive = true;
              return x;
            } else {
              return x;
            }
          });
      } else {
        let userPhone = new UserPhone();
        userPhone.phoneNumber = this.agentForm.value.phone;
        userPhone.isActive = true;
        this.agent.regularUser.userPhones.push(userPhone);
      }

      this.agent.regularUser.firstName = this.agentForm.value.firstName;
      this.agent.regularUser.email = this.agentForm.value.email;
      this.agent.regularUser.userName = this.agentForm.value.email;
      this.agent.regularUser.userRoleId = this.agentForm.value.userRoleId;
      this.agent.regularUser.isActive = this.agentForm.value.isActive;
      this.agent.regularUser.gender = this.agentForm.value.gender;
      /* this.agent.officePhoneExtensionId =
        this.agentForm.value.officePhoneExtension; */
      if (this.queryAgentId == -1) {
        this.manageAgentsService.post(this.agent).subscribe(
          (agent) => {
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.agentCreated
            );
            this.isLoading = false;
            this.router.navigate(['/agents/edit/'], {
              queryParams: { agentId: agent.agentId },
            });
          },
          (err) => {
            this.isLoading = false;
          }
        );
      } else {
        this.manageAgentsService.put(this.agent).subscribe(
          (agent) => {
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.agentSaved
            );
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
          }
        );
      }
    } else {
      this.isLoading = false;
      const invalid = [];
      const controls = this.agentForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a1 = invalid;
    }
  }
  sendResetPasswordMail() {
    this.isLoading = true;
    this.sendMailService.sendResetMail(this.agent.regularUserId).subscribe(
      (ans) => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.resetMailSent
        );
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  setPasswordHack() {
    if (!this.agentForm.value.password) return;
    this.isLoading = true;
    this.agent.regularUser.password = this.agentForm.value.password;
    this.regularUsersService
      .resetPasswordHack(this.agent.regularUser)
      .subscribe(
        (res) => {
          if ((res as ErrorMessage)?.message) {
            this.popupHandlerService.openDisapearingAlertDialog(
              (res as ErrorMessage).message
            );
          } else {
            this.agentForm.controls['password'].setValue('');
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.newPassword
            );
            this.isLoading = false;
          }
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  is8Length() {
    return this.password.value && this.password.value.length >= 8;
  }
  hasLetters() {
    return (
      this.password.value &&
      /[a-z]/.test(this.password.value) &&
      /[A-Z]/.test(this.password.value)
    );
  }
  hasNumbers() {
    return this.password.value && /[0-9]/.test(this.password.value);
  }
  get firstName() {
    return this.agentForm.get('firstName');
  }
  get phone() {
    return this.agentForm.get('phone');
  }
  get email() {
    return this.agentForm.get('email');
  }
  get gender() {
    return this.agentForm.get('gender');
  }
  get officePhoneExtension() {
    return this.agentForm.get('officePhoneExtension');
  }
  get password() {
    return this.agentForm.get('password');
  }
}
