import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoBulletLink } from '../data/AlgoBulletLink';

@Injectable({
  providedIn: 'root',
})
export class AlgoBulletLinksService {
  constructor(private http: HttpClient) {}

  getAlgoBulletLink(linkId: number, langId: number) {
    return this.http.get<AlgoBulletLink>(
      `${environment.baseUrl}AlgoBulletLinks/GetAlgoBulletLink/${linkId}/${langId}`
    );
  }

  getAll(langId: number) {
    return this.http.get<any[]>(
      `${environment.baseUrl}AlgoBulletLinks/GetAlgoBulletLinks/${langId}`
    );
  }

  post(algoBulletLink: AlgoBulletLink) {
    return this.http.post<any>(
      `${environment.baseUrl}AlgoBulletLinks/`,
      algoBulletLink
    );
  }

  put(algoBulletLink: AlgoBulletLink) {
    return this.http.put<any>(
      `${environment.baseUrl}AlgoBulletLinks/${algoBulletLink.algoBulletLinkId}`,
      algoBulletLink
    );
  }

  delete(id: number) {
    return this.http.delete<any>(`${environment.baseUrl}AlgoBulletLinks/${id}`);
  }
}
