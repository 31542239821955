<div style="display: flex" *ngIf="headFilter && serviceRequests">
  <div
    class="requestTab"
    [ngClass]="{
      selected:
        headFilter.categoryId == ServiceRequestTabs.All &&
        !headFilter.onlyManager
    }"
    (click)="changeTab(ServiceRequestTabs.All, false)"
  >
    All
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.Customer
    }"
    (click)="changeTab(ServiceRequestTabs.Customer, false)"
  >
    Customer
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.Finance
    }"
    (click)="changeTab(ServiceRequestTabs.Finance, false)"
  >
    Finance
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.Nutrition
    }"
    (click)="changeTab(ServiceRequestTabs.Nutrition, false)"
  >
    Nutrition
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.Manager
    }"
    (click)="changeTab(ServiceRequestTabs.Manager, true)"
  >
    Manager
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.AppReports
    }"
    (click)="changeTab(ServiceRequestTabs.AppReports, false)"
  >
    App reports
  </div>
  <div
    class="requestTab"
    [ngClass]="{
      selected: headFilter.categoryId == ServiceRequestTabs.AppCustomerRequests
    }"
    (click)="changeTab(ServiceRequestTabs.AppCustomerRequests, false)"
  >
    App Customer Requests
  </div>
</div>
<hr
  *ngIf="headFilter"
  style="border: 1px solid #119c99; margin-block-start: 0"
/>
<mat-list class="clientsTable" role="list">
  <mat-list-item
    *ngIf="headFilter"
    class="clientsTable__header"
    role="listitem"
  >
    <ng-container *ngFor="let item of headers">
      <span
        *ngIf="item.showInAppReports"
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
        [ngStyle]="{ width: item.width }"
      >
        <span (click)="changeSortBy(item.val)">
          {{ item.name }}
        </span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
  </mat-list-item>
  <mat-list-item
    *ngIf="!headFilter"
    class="clientsTable__header"
    role="listitem"
  >
    <ng-container *ngFor="let item of internalHeaders">
      <span [ngStyle]="{ width: item.width }">
        <span>{{ item.name }} </span>
      </span>
    </ng-container>
  </mat-list-item>

  <div style="padding: 0 1rem" *ngIf="isLoading" class="skeleton-loader">
    <ngx-skeleton-loader
      class="skeleton-loader"
      [theme]="{
        height: '50px',
        'margin-bottom': '10px',
        'background-color': 'rgba(27, 63, 78,0.15)'
      }"
      count="10"
    ></ngx-skeleton-loader>
  </div>
  <ng-container *ngIf="!isLoading">
    <mat-card
      class="clientsTable__item"
      *ngFor="let request of serviceRequests"
    >
      <mat-list-item role="listitem">
        <span
          >{{ request?.createdDate | date : "dd-MM-yyyy  HH:mm" }} &nbsp;</span
        >
        <span class="d-flex-center">
          <div
            (click)="editRequest($event, request)"
            style="color: #00aeee; text-decoration: underline; cursor: pointer"
          >
            {{
              request?.fullName ||
                request.clientFirstName + " " + request.clientSecondName
            }}
          </div>
          &nbsp;
        </span>
        <span *ngIf="headFilter" class="d-flex-center">
          {{ request?.phone || request.clientPhone }} &nbsp;</span
        >

        <span class="d-flex-center">
          {{
            request.requestSubject
              ? request.requestSubject.name
              : getRequestSubject(request)
          }}
        </span>
        &nbsp;
        <span
          *ngIf="
            headFilter &&
            headFilter.categoryId !== ServiceRequestTabs.AppReports &&
            headFilter.categoryId !== ServiceRequestTabs.AppCustomerRequests
          "
          class="d-flex-center"
        >
          {{ request?.assigningAgent?.name }} &nbsp;</span
        >

        <!-- <span
          *ngIf="headFilter && false"
          class="d-flex-center"
          style="width: 7%; cursor: pointer"
          (click)="editRequest($event, request)"
        >
          <mat-icon
            [class.activeComment]="getNumComments(request.serviceRequestId) > 0"
            class="comment"
            [matBadgeHidden]="getNumComments(request.serviceRequestId) == 0"
            matBadge="{{ getNumComments(request.serviceRequestId) }}"
            matBadgeColor="warn"
            >comment
          </mat-icon>
          &nbsp;
        </span> -->

        <span class="d-flex-center">
          <app-autocomplete-agent
            (selectedValue)="selectedAgent($event, request)"
            [data]="agents"
            [initialValue]="getAssignedAgentName(request)"
            [widthExp]="150"
            [label]="'Select Agent'"
          ></app-autocomplete-agent>
          &nbsp;
        </span>
        <span *ngIf="headFilter" class="d-flex-center">
          &nbsp;&nbsp;
          <ul class="roundAttempts">
            <li
              (click)="setAttempt(request)"
              [class.active]="request.numAttempts >= 1"
            >
              1
            </li>
            &nbsp;
            <li
              (click)="setAttempt(request)"
              [class.active]="request.numAttempts >= 2"
              [class.disabled]="request.numAttempts == 0"
            >
              2
            </li>
            &nbsp;
            <li
              (click)="setAttempt(request)"
              [class.active]="request.numAttempts >= 3"
              [class.disabled]="
                request.numAttempts == 0 || request.numAttempts == 1
              "
            >
              3
            </li>
          </ul>
          &nbsp;
        </span>

        <span *ngIf="true" class="d-flex-center" style="width: 16%">
          <mat-form-field
            class="example-full-width round-select"
            style="width: 130px"
          >
            <mat-select
              matNativeControl
              disableOptionCentering
              (click)="stopPropegation($event)"
              (selectionChange)="saveStatusForRequest($event, request)"
              [(ngModel)]="request.statusId"
            >
              <mat-option
                *ngFor="let status of requestsStatuses"
                style="text-align: center"
                [value]="status.statusId"
              >
                {{ status?.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          &nbsp;
          <!-- headFilter -->
        </span>
        <span *ngIf="false">
          <span class="round-indicator" style="background: #119c99">
            {{ request.status.name }}
          </span>
          &nbsp;
        </span>

        <div style="text-align: center; width: 10%; min-width: 10%">
          <ng-container
            *ngIf="
              headFilter &&
              headFilter?.categoryId !== ServiceRequestTabs.AppReports &&
              headFilter?.categoryId !== ServiceRequestTabs.AppCustomerRequests
            "
          >
            <button
              *ngIf="!request.forManager"
              mat-button
              class="managerBtn"
              (click)="sendToManager(request)"
            >
              Send To Manager
            </button>
            <span
              *ngIf="request.forManager"
              class="forManager"
              style="text-align: center; width: 100%"
            >
              For Manager
            </span>
          </ng-container>
        </div>

        <span
          *ngIf="headFilter"
          class="d-flex-center"
          style="width: 7%; cursor: pointer"
          (click)="editRequest($event, request)"
        >
          <mat-icon
            [class.activeComment]="getNoteCount(request) > 0"
            class="comment"
            [matBadgeHidden]="getNoteCount(request) == 0"
            matBadge="{{ getNoteCount(request) }}"
            matBadgeColor="warn"
            >comment
          </mat-icon>
          &nbsp;
        </span>
      </mat-list-item>
    </mat-card>
  </ng-container>
</mat-list>
