import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Deal } from 'src/app/data/Deal';
import { Product } from 'src/app/data/Product';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseDetailsDialogComponent } from '../purchase-details-dialog/purchase-details-dialog.component';
import { DealsService } from 'src/app/Services/deals.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DealForClientMin } from 'src/app/data/DealForClientMin';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  CancellationRequestEnum,
  DealEnum,
  PaymentMethodEnum,
} from 'src/app/Enums/StatusesEnum';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { Router, RouterModule } from '@angular/router';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';
import { StatusesService } from 'src/app/Services/statuses.service';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';

@Component({
  selector: 'app-client-purchases',
  templateUrl: './client-purchases.component.html',
  styleUrls: ['./client-purchases.component.scss'],
})
export class ClientPurchasesComponent implements OnInit, OnChanges {
  dealsDisplayedColumns: string[] = [
    'active',
    'date',
    'product',
    'sum',
    'status',
    'pMethod',
  ];
  // regularUser: RegularUser;
  statuses: Status[];
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  @Input() agentUser: RegularUser;
  @Input() assignedAgentId;
  @Input() inCancellation;
  @Input() clientDealBundles: DealBundleForTable[];
  @Output('openNewPurchaseEmitter')
  openNewPurchaseEmitter: EventEmitter<any> = new EventEmitter();
  @Output('openContinueDealEmitter')
  openContinueDealEmitter: EventEmitter<Deal> = new EventEmitter();
  isOnRowClicked: boolean;
  constructor(
    public dialog: MatDialog,
    private dealsService: DealsService,
    private popupHandler: PopupHandlerService,
    private router: Router,
    private cancelationRequestService: CancelationRequestService,
    private statusesService: StatusesService
  ) {
    this.popupHandler.dealDetailsDialogSubject.subscribe((ans) => {
      if (ans == 'showDuplicateRequestAlert') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has Open Cancelation Request'
        );
      } else if (ans == 'DealHasReachedMaximumrefund') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has Reached Maximum refund'
        );
      } else if (ans == 'DealHasNoProductsLeft') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has No Products Left'
        );
      } else if ((ans as Deal)?.dealBundle?.clientId) {
        //msg from popup  is getContinueDeal
        this.openContinueDealEmitter.emit(ans);
      }
    });
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}
  openNewPurchase() {
    this.openNewPurchaseEmitter.emit(true);
  }
  getProductsStr(products: string[]) {
    var prods = '';
    if (!products) return prods;
    var prodsDict = {};
    products.forEach((product) => {
      prodsDict[product] = 1;
    });
    for (const property in prodsDict) {
      prods += property + ' ';
    }
    return prods;
  }
  getDealLeftToPay(deal: DealForClientMin) {
    if (deal.statusId == DealEnum.Uncompleted) {
      return deal.leftToPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return false;
    }
  }
  getDealStatusColor(deal: DealForClientMin) {
    return (
      this.statuses &&
      this.statuses?.find((x) => x.statusId == deal.statusId)?.color
    );
  }
  getDealStatus(deal: DealForClientMin) {
    return (
      this.statuses &&
      this.statuses.find((x) => x.statusId == deal.statusId)?.name
    );
    //return deal.status.name;
    /*  if (!deal) return '';
    if (!deal.dealHistories) return '';
    if (deal.dealHistories.length == 0) return '';
    if (!deal.dealHistories[deal.dealHistories.length - 1]) return '';
    if (
      !deal.dealHistories[deal.dealHistories.length - 1]
        .tranzillaTransactionResponse
    )
      return '';
    if (
      !deal.dealHistories[deal.dealHistories.length - 1]
        .tranzillaTransactionResponse.bankResponseCode
    )
      return ''; */
    /*
        || !deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse
        || !deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse.bankResponseCode) return "";
        */
    /*
    var a = deal.dealHistories[deal.dealHistories.length - 1];
    var a1 = a.tranzillaTransactionResponse;
    var a2 = a1.bankResponseCode;
    var a3 = a2.name;
    */
    //return deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse.bankResponseCode.name
  }
  //this.statuses.push(PaymentMethodEnum.CreditCard, PaymentMethodEnum.ManualCreditCard, PaymentMethodEnum.)
  onRowClick(row: DealForClientMin) {
    if (this.isOnRowClicked) return;
    //if (row.status.statusId == DealEnum.Done) {
    //if (row.paymentMethodId == PaymentMethodEnum.CreditCard) {
    let url = '';

    if (row.isCancelDeal) {
      this.cancelationRequestService
        .getByCancelDealId(row.dealBundleId)
        .subscribe((request) => {
          var shpwNoPermisionPopup = false;

          switch (request.statusId) {
            case CancellationRequestEnum.NewRequest:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_ConservationTab'],
                this.agentUser
              );
              break;
            case CancellationRequestEnum.ManagerApprove:
            case CancellationRequestEnum.CouponApprove:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_ManagerTab'],
                this.agentUser
              );
              break;
            case CancellationRequestEnum.RefundApprove:
            case CancellationRequestEnum.RefundClosed:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_RefundTab'],
                this.agentUser
              );
              break;
            case CancellationRequestEnum.Closed:
            case CancellationRequestEnum.CouponClosed:
              shpwNoPermisionPopup = !userHasPermisions(
                [
                  'Cancelations_ConservationTab',
                  'Cancelations_ManagerTab',
                  'Cancelations_RefundTab',
                ],
                this.agentUser
              );
              break;
            default:
              break;
          }
          if (shpwNoPermisionPopup) {
            this.popupHandler.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.costumMessage,
              '',
              '',
              'you have no permisions to view cancelation request'
            );
          } else {
            switch (request.statusId) {
              case CancellationRequestEnum.NewRequest:
              case CancellationRequestEnum.ManagerApprove:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/cancelations/new/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
                break;
              case CancellationRequestEnum.ManagerApprove:
              case CancellationRequestEnum.CouponApprove:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/cancelations/manager/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
                break;
              case CancellationRequestEnum.Closed:
              case CancellationRequestEnum.CouponClosed:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/cancelations/closed/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
                break;
              case CancellationRequestEnum.RefundApprove:
              case CancellationRequestEnum.RefundClosed:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/refunds/edit/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
            }
            if (url != '') window.open(url, '_blank');
          }
        });
    } else {
      this.dealsService.getTransactionsReports(row.dealId).subscribe((deal) => {
        this.popupHandler.openDealDetailsDialog(deal);
      });
    }
    this.isOnRowClicked = true;
    setTimeout(() => {
      this.isOnRowClicked = false;
    }, 1000);
    //}
  }

  showPurchaseBtn() {
    if (
      userHasPermisionsWithRule(['Deals_Create'], this.agentUser) ||
      (this.agentUser.agent.agentId == this.assignedAgentId &&
        userHasPermisionsWithRule(
          ['Deals_Create_IsTargetAgent'],
          this.agentUser
        ))
    ) {
      return true;
    }
    return false;
  }
  showBundleExpansionPanel(bundle: DealBundleForTable) {
    if (!bundle.deals || bundle.deals.length == 0) return false;
    if (bundle.isCancelDeal) return false;
    if (bundle.deals.length > 1) return true;

    if (
      bundle.deals[0].statusId == DealEnum.Done &&
      bundle.deals[0].amount == bundle.amount
    ) {
      return false;
    }
    if (bundle.deals[0].statusId == DealEnum.Rejected) return false;
    return true;
  }
}
