import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-profile-edit',
  templateUrl: './patient-profile-edit.component.html',
  styleUrls: ['./patient-profile-edit.component.css'],
})
export class PatientProfileEditComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
