import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-verification-token-page',
  templateUrl: './verification-token-page.component.html',
  styleUrls: ['./verification-token-page.component.css'],
})
export class VerificationTokenPageComponent implements OnInit {
  userName: string;
  constructor(
    private regularUsersService: RegularUsersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.userName = params['userName'];
    });
  }
  requestVerificationCode() {
    this.regularUsersService
      .requestLockedVerificationCode(this.userName)
      .subscribe((res) => {});
  }
}
