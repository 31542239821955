<ng-container *ngIf="languages">
  <button class="selected-lang" mat-button [matMenuTriggerFor]="langMenu">
    <img src="../../assets/lang-icon-{{ selectedLangId }}.png" />
    {{ getSelectedLangName() }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <mat-menu #langMenu="matMenu">
    <button
      (click)="onChangeLang(lang)"
      class="menu-lang"
      mat-menu-item
      *ngFor="let lang of languages"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <img src="../../assets/lang-icon-{{ lang.languageId }}.png" />
        {{ lang.name }}
      </div>
    </button>
  </mat-menu>
</ng-container>
