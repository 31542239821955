<div class="menus">
  <div class="mb-2"></div>
  <ngx-skeleton-loader
    *ngIf="isLoading"
    [theme]="{ height: '50px' }"
    count="5"
  ></ngx-skeleton-loader>
</div>
<div class="menus" *ngIf="menuList && !isLoading">
  <div class="mb-2"></div>
  <ng-container *ngFor="let item of menuList; let i = index">
    <div class="d-flex-between" style="align-items: flex-start">
      <div class="d-flex-between" style="flex: 1; align-items: flex-start">
        <div class="menus__details">
          <div class="menus__details__name">{{ item.name }}</div>
          <div class="menus__details__status">
            <span>Status: </span>
            <span
              [ngStyle]="{ color: !item.isComplete ? '#E80505' : '#20B90E' }"
            >
              {{ item.isComplete ? "Completed" : "Draft" }}
            </span>
          </div>
          <div class="menus__details__updated">
            <span>Last update: </span
            >{{ item.dateUpdated | date: "dd/MM/yyyy" }} | By:
            {{ item.updatedByAgentName }}
          </div>
          <div class="menus__details__created">
            <span>Created date: </span
            >{{ item.dateCreated | date: "dd/MM/yyyy" }} | By:
            {{ item.createdByAgentName }}
          </div>
          <div class="menus__values">
            <button
              class="menus__values__link"
              mat-button
              [matMenuTriggerFor]="menuValues"
            >
              Nutritional values
            </button>
            <mat-menu #menuValues="matMenu" class="menus__genetic-rate">
              <div class="menus__genetic-rate__row">
                <span>Calories:</span>
                {{ getCalories(item.menuToNutrients) | number: "1.0-0" }}
              </div>
              <div class="menus__genetic-rate__row">
                <span>Proteins: </span
                >{{ getProteins(item.menuToNutrients) | number: "1.0-0" }}
              </div>
              <div class="menus__genetic-rate__row">
                <span>Carbohydrates: </span
                >{{ getCarbs(item.menuToNutrients) | number: "1.0-0" }}
              </div>
              <div class="menus__genetic-rate__row">
                <span>Fats: </span
                >{{ getFats(item.menuToNutrients) | number: "1.0-0" }}
              </div>
              <div class="menus__genetic-rate__row">
                <span>Vitemins: </span>{{ getVitemins(item.menuToNutrients) }}
              </div>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="productId" class="menus__genetic-bar">
          <div class="menus__genetic-bar__title">Genetic rate:</div>
          <div class="menus__genetic-bar__progress">
            <mat-progress-bar
              [value]="getGeneticRate(item.menuToNutrients) | number: '1.0-0'"
            >
            </mat-progress-bar>
            <div
              class="menus__genetic-bar__progress__mask"
              [ngStyle]="{
                left: getGeneticRate(item.menuToNutrients) + '%',
                width: getGeneticRate(item.menuToNutrients)
                  ? 'calc(100% - ' + getGeneticRate(item.menuToNutrients) + '%)'
                  : '100%'
              }"
            ></div>
            <div
              class="menus__genetic-bar__progress__value"
              [ngStyle]="{
                right:
                  'calc(' +
                  setGeneticRateOffset(getGeneticRate(item.menuToNutrients)) +
                  ' - ' +
                  (getGeneticRate(item.menuToNutrients) | number: '1.0-0') +
                  '%)'
              }"
            >
              {{ getGeneticRate(item.menuToNutrients) | number: "1.0-0" }}%
            </div>
          </div>
        </div>
      </div>
      <div class="menus__pie" *ngIf="!isTemplate">
        <app-menu-calories-bar
          [data]="getBarData(item)"
        ></app-menu-calories-bar>
        <!-- <div
          class="menus__pie__wrapper"
          [ngStyle]="{ visibility: showCaloriesPie ? 'visible' : 'hidden' }"
        >
          <ngx-charts-pie-chart
            [animations]="false"
            [scheme]="colorScheme"
            [results]="paiData[i]"
            [doughnut]="true"
          >
          </ngx-charts-pie-chart>
        </div>
        <div class="menus__pie__text" *ngFor="let item of paiData[i]">
          <span class="menus__pie__text__name">
            <mat-icon *ngIf="item?.icon">{{ item?.icon }}</mat-icon>
            <span [ngStyle]="{ color: item.color }"
              >{{ item.name }}:&nbsp;</span
            >
          </span>

          <span [ngStyle]="{ color: item.colorValue }">
            {{ item.value }} Cal
          </span>
        </div> -->
      </div>
      <div class="menus__actions">
        <div>
          <button (click)="getMenuForEdit(item.menuId)" mat-icon-button>
            <mat-icon>{{
              canEditMenu || isTemplate ? "border_color" : "visibility"
            }}</mat-icon>
          </button>
          <button (click)="deleteMenu(item.menuId)" mat-icon-button>
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="productId">
          <div>
            <!--(click)="sendToMail(item.menuId)"-->
            <button
              (click)="generateHtmlMenu(item.menuId)"
              [disabled]="!item.isComplete"
              mat-icon-button
            >
              <mat-icon>share</mat-icon>
            </button>
            <button
              [disabled]="!item.isComplete"
              (click)="printAsPdf(item.menuId)"
              mat-icon-button
            >
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <app-menu-html
      style="display: none"
      *ngIf="menu && menu.menuId == item.menuId"
      [menu]="menu"
      (htmlReady)="onHtmlReady($event)"
    ></app-menu-html>
    <div>
      <div class="mb-2"></div>
      <hr class="hr gray" style="width: 100%" />
      <div class="mb-2"></div>
    </div>
  </ng-container>
</div>
