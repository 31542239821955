import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-work-time-select',
  templateUrl: './work-time-select.component.html',
  styleUrls: ['./work-time-select.component.scss'],
})
export class WorkTimeSelectComponent implements OnInit {
  minutsList: any[] = [];
  hoursList: any[] = [];
  @Input() value: any;

  @Output() hoursStartChange: EventEmitter<any> = new EventEmitter();
  @Output() minutsStartChange: EventEmitter<any> = new EventEmitter();
  @Output() hoursEndChange: EventEmitter<any> = new EventEmitter();
  @Output() minutsEndChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    for (let i = 9; i <= 18; i++) {
      if (i < 10) {
        this.hoursList.push(`0${i}`);
      } else {
        this.hoursList.push(i);
      }
    }

    for (let i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.minutsList.push(`0${i}`);
      } else {
        this.minutsList.push(i);
      }
    }
  }

  onHoursStartChange({ value }: MatSelectChange) {
    this.hoursStartChange.emit(value);
  }
  onMinutsStartChange({ value }: MatSelectChange) {
    this.hoursEndChange.emit(value);
  }
  onHoursEndChange({ value }: MatSelectChange) {
    this.minutsStartChange.emit(value);
  }
  onMinutsEndChange({ value }: MatSelectChange) {
    this.minutsEndChange.emit(value);
  }
}
