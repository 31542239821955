import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Client } from '../data/Client';
import { ProductSummery } from '../data/ProductSummery';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { DatePipe } from '@angular/common';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { ErrorMessage } from '../data/ErrorMessage';
import { NameAndPhone } from '../data/NameAndPhone';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  search(searchTerm: string) {
    return this.http.get<any>(
      `${environment.baseUrl}Clients/Search/${searchTerm}`
    );
  }
  searchV2(
    searchTerm: string,
    orderBy = 'date',
    acending = true,
    pageSize,
    pageNumber
  ) {
    return this.http.get<any>(
      `${environment.baseUrl}Clients/SearchV2/${searchTerm}/${orderBy}/${acending}/${pageSize}/${pageNumber}`
    );
  }
  getAll() {
    return this.http.get<Client[]>(`${environment.baseUrl}Clients/`);
  }
  checkIfExistWithPhone(phone: string) {
    return this.http.get<Client>(
      `${environment.baseUrl}Clients/ExistWithPhone/${phone}`
    );
  }
  getAllByFilter(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }Clients/Filter/${this.httpFilterHelper.createPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getById(clientId) {
    return this.http.get<Client | ErrorMessage>(
      `${environment.baseUrl}Clients/${clientId}`
    );
  }
  put(client: Client, overrideLeadStatus = 'false') {
    return this.http.put<Client | ErrorMessage>(
      `${environment.baseUrl}Clients/${client.clientId}/${overrideLeadStatus}`,
      client
    );
  }
  post(client: Client) {
    return this.http.post<Client>(`${environment.baseUrl}Clients/`, client);
  }
  delete(client: Client) {
    return this.http.delete(`${environment.baseUrl}Clients/${client.clientId}`);
  }
  transferAgentDeals(client) {
    return this.http.get<Client>(
      `${environment.baseUrl}Clients/TransferAgentDeals/${client.clientId}`,
      client
    );
  }
  getPatientPhones(clientId: number) {
    return this.http.get<NameAndPhone[]>(
      `${environment.baseUrl}Clients/PatientPhones/${clientId}`
    );
  }
  /*
  addProductsSummery(client: Client): Client {
    client.clientProductsSummery = [];
    var dict = {};
    client.products.forEach((product) => {
      if (!dict[product.productType.productName])
        dict[product.productType.productName] = 0;
      dict[product.productType.productName]++;
    });
    for (let key in dict) {
      var summery = new ProductSummery();
      summery.amount = dict[key];
      summery.name = key;

      client.clientProductsSummery.push(summery);
    }
    return client;
  }
  */
}
