import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslationCategory } from '../data/TranslationCategory';

@Injectable({
  providedIn: 'root',
})
export class TranslationCategoriesService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<TranslationCategory[]>(
      `${environment.baseUrl}TranslationCategories/`
    );
  }
  getById(agentId) {
    return this.http.get<TranslationCategory>(
      `${environment.baseUrl}TranslationCategories/${agentId}`
    );
  }

  put(translationCategory: TranslationCategory) {
    return this.http.put(
      `${environment.baseUrl}TranslationCategories/${translationCategory.translationCategoryId}`,
      translationCategory
    );
  }
  post(triggerEventObjectType: TranslationCategory) {
    return this.http.post<TranslationCategory>(
      `${environment.baseUrl}TranslationCategories/`,
      triggerEventObjectType
    );
  }
  delete(translationCategory: TranslationCategory) {
    return this.http.delete(
      `${environment.baseUrl}TranslationCategories/${translationCategory.translationCategoryId}`
    );
  }
}
