import { EmailTemplateDynamicAttachment } from './EmailTemplateDynamicAttachment';
import { EmailTemplateLang } from './EmailTemplateLang';
import { EventAction } from './EventAction';

export class EmailTemplate {
  emailTemplateId: number;
  name: string;
  isAutoSender: boolean;
  sendSms: boolean;
  sendMail: boolean;
  displayName: string;
  actionName: string;
  maxSendCount: number;
  minDaySendInterval: number;
  senderMail: string;
  senderPhone: string;
  senderMailName: string;
  senderPhoneName: string;
  emailTemplateLangs: EmailTemplateLang[];
  eventActions: EventAction[];
  emailTemplateDynamicAttachments: EmailTemplateDynamicAttachment[];
}
