import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserTokenMin } from '../data/UserTokenMin';

@Injectable({
  providedIn: 'root',
})
export class UserTokenService {
  constructor(private http: HttpClient) {}

  userTokens() {
    return this.http.get<UserTokenMin>(`${environment.baseUrl}UserTokens`);
  }
}
