import { Country } from './Country';
import { RegularUser } from './RegularUser';

export class UserPhone {
  phoneNumberId: number;
  phoneNumber: string;
  regularUserId: number;
  regularUser: RegularUser;
  isActive: boolean;
  isMobile: boolean;
  isValid: boolean;
  countryId: number | null;
  country: Country;
}
