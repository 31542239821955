<mat-card class="snp">
  <form #allelForm="ngForm">
    <mat-card-header>
      <mat-card-title> Edit SNP </mat-card-title>
      <div
        *ngIf="canEdit"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
      >
        <button
          type="button"
          (click)="toggleEditMode()"
          mat-button
          class="btn-fill"
        >
          <mat-icon *ngIf="!editMode">edit</mat-icon>
          <mat-icon *ngIf="editMode">cancel</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
        style="border-bottom: 1px solid #6a818b; padding-bottom: 2rem"
      >
        <div
          fxFlex="33"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <div fxFlex="34" style="font-weight: 500">Genotype name:</div>
          <mat-form-field class="field" appearance="fill">
            <input
              matInput
              [(ngModel)]="snp.fgsnp.gene"
              name="geneName"
              required
              [disabled]="true"
            />
          </mat-form-field>
        </div>
        <div
          fxFlex="33"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <div style="font-weight: 500">Coordinate:</div>
          <mat-form-field class="field" appearance="fill">
            <input
              matInput
              [(ngModel)]="snp.fgsnp.coordinate"
              name="coordinate"
              required
              [disabled]="true"
            />
          </mat-form-field>
        </div>
        <div
          fxFlex="33"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <div style="font-weight: 500">Chromosome:</div>
          <mat-form-field class="field" appearance="fill">
            <input
              matInput
              [(ngModel)]="snp.fgsnp.chromosome"
              name="chromosome"
              required
              [disabled]="true"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
      >
        <div fxFlex="10" style="font-weight: 500">Title</div>
        <mat-form-field
          *ngFor="let allel of snp.alleles; let i = index; trackBy: trackByFn"
          class="field"
          appearance="fill"
        >
          <input
            matInput
            [(ngModel)]="allel.alleleName"
            name="name-{{ i }}"
            required
            [disabled]="!editMode"
          />
        </mat-form-field>
      </div>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
      >
        <div fxFlex="10" style="font-weight: 500">Value</div>
        <mat-form-field
          *ngFor="let allel of snp.alleles; let i = index; trackBy: trackByFn"
          class="field"
          appearance="fill"
        >
          <input
            matInput
            [(ngModel)]="allel.value"
            name="value-{{ i }}"
            required
            [disabled]="!editMode"
            type="number"
          />
        </mat-form-field>
      </div>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
      >
        <div fxFlex="10" style="font-weight: 500">Alelle combination</div>
        <mat-form-field
          *ngFor="let allel of snp.alleles; let i = index; trackBy: trackByFn"
          class="field"
          appearance="fill"
        >
          <input
            matInput
            [(ngModel)]="allel.letters"
            name="letters-{{ i }}"
            required
            [disabled]="!editMode"
          />
        </mat-form-field>
      </div>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
      >
        <div fxFlex="10" style="font-weight: 500">SNP description</div>
        <mat-form-field class="field" appearance="fill">
          <textarea
            [(ngModel)]="snp.description"
            matInput
            name="letters"
            [disabled]="!editMode"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="action" *ngIf="editMode">
        <button type="submit" (click)="save()" mat-button class="btn-save">
          Save
        </button>
      </div>
    </mat-card-content>
  </form>
</mat-card>
