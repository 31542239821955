<div class="h-100-center versions-history">
  <mat-card class="w-500 ma-0">
    <mat-card-title class="text-center" style="padding-bottom: 8px"
      >Versions History</mat-card-title
    >
    <mat-divider></mat-divider>
    <div class="mb-2"></div>
    <mat-card-body>
      <div>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>System ID</mat-label>
          <mat-select [(ngModel)]="selectedSystemId">
            <mat-option
              *ngFor="let system of systemNames"
              [value]="system.id"
              >{{ system.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <!--  <div>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Choose a date</mat-label>
          <input [(ngModel)]="createDate" matInput [matDatepicker]="picker" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div> -->

      <div>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Version number</mat-label>
          <input [(ngModel)]="versionNumber" placeholder="1.1" matInput />
        </mat-form-field>
      </div>
      <div class="text-center">
        <button class="w-100" color="primary" (click)="post()" mat-flat-button>
          Save
        </button>
      </div>
    </mat-card-body>
  </mat-card>
  <div class="mb-2"></div>
  <table mat-table [dataSource]="versionHistories">
    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <!-- Name Column -->
    <ng-container matColumnDef="systemName">
      <th mat-header-cell *matHeaderCellDef>System Name</th>
      <td mat-cell *matCellDef="let version">
        {{ getSystemName(version.systemId) }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef>Create Date</th>
      <td mat-cell *matCellDef="let version">
        {{ version.createDate | date: "dd-MM-yyyy HH:mm" }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Number</th>
      <td mat-cell *matCellDef="let version">
        {{ version.number }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
