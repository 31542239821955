import { Component, OnInit, Input } from '@angular/core';
import { Logging } from 'src/app/data/Logging';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.css'],
})
export class LogsTableComponent implements OnInit {
  constructor(private router: Router) {}
  @Input() loggings: Logging[];
  @Input() clientName: Logging[];
  displayedColumns: string[] = ['action', 'modifiedBy', 'dateTime'];
  ngOnInit(): void {}
  getProfileName() {
    if (this.loggings.length == 0) return '';
    return this.clientName;
  }
  getProfileType() {
    var url = this.router.url.split('?')[0];
    switch (url) {
      case '/tests/edit/log':
        return 'Patient';
      case '/clients/edit/log':
        return 'Client';
      case '/leads/edit/log':
        return 'Lead';
    }
  }
}
