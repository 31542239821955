<div class="chat-wrapper" (click)="onWrapperClick()">
  <div class="chat-list">
    <div class="chat-list__header">
      <ng-container *ngIf="agent">
        <div class="chat-list__header__img">
          <img [src]="getAvatarImage()" alt="avatar" />
        </div>
        <div class="chat-list__header__name">
          {{ agent.firstName + "" + agent.lastName }}
        </div>
        <div class="chat-list__header__clear-filter">
          <button
            [matTooltip]="!filterOpened ? 'Open filter' : 'Close filter'"
            mat-mini-fab
            (click)="toggleFilters()"
          >
            <mat-icon>
              {{ filterOpened ? "filter_list_off" : "filter_list" }}
            </mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
    <div #chatFilterWrapper>
      <app-chat-filter
        *ngIf="filterOpened"
        [chatFilter]="chatFilter"
        [agents]="agents"
        [agentId]="agent && agent.agentId"
        [departmentId]="agent && agent.departmentId"
        (applyFilters)="getChatByFilter($event)"
      ></app-chat-filter>
    </div>
    <perfect-scrollbar
      [ngStyle]="{ height: 'calc(100vh - ' + chatScrollerHeight + 'px )' }"
    >
      <app-waiting-list
        (openSession)="onOpenSession($event)"
        *ngIf="!filterOpened"
        [resetSelected]="resetSelectedWaitingList"
      ></app-waiting-list>
      <app-agents-list
        *ngIf="chatRoomAgent && !filterOpened"
        [agent]="agent"
        [resetSelected]="resetSelectedAgentList"
        (selectedRowEvent)="onAgentListSelected($event)"
      ></app-agents-list>
      <app-filtered-results
        *ngIf="chatFilterResult"
        [agent]="agent"
        (selectedRow)="getChatRoomForAgent($event)"
      ></app-filtered-results>
    </perfect-scrollbar>
  </div>
  <div class="chat-room">
    <app-chat-messages
      *ngIf="agent"
      [branchId]="branchId"
      [langId]="langId"
      [agentId]="agent.agentId"
      (closeSessionEvent)="closeSession($event)"
      (takeOverEvent)="onAgentInterseption($event)"
      (startChatEvent)="onStartChat($event)"
    ></app-chat-messages>
  </div>
  <div class="chat-info">
    <div class="chat-info__header">Client info</div>
    <div class="chat-info__content" *ngIf="chatRoom">
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label"> Full name: </span>
        <span
          (click)="openInNewTab(chatRoom.testLink)"
          class="chat-info__content__item__link"
        >
          {{ chatRoom.sessions[0].conversationUserName }}
        </span>
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Phone:</span>
        {{ chatRoom.phone }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Email:</span>
        {{ chatRoom.email }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Age:</span>
        {{ chatRoom.age }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Purpose:</span>
        <span
          style="text-align: right; direction: rtl; word-break: break-word"
          >{{ chatRoom.purpose }}</span
        >
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Purchase date:</span>
        {{ chatRoom.purchaseDate | date : "dd/MM/yyyy" }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Results date:</span>
        {{ chatRoom.resultDate | date : "dd/MM/yyyy" }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Procces days:</span>
        {{ chatRoom.daysInProcess }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Starting weight:</span>
        {{ chatRoom.startWeight }}
      </div>
      <div class="chat-info__content__item">
        <span class="chat-info__content__item__label">Current weight:</span>
        {{ chatRoom.currentWeight }}
      </div>
      <div
        class="chat-info__content__item"
        (click)="openInNewTab(chatRoom.resultLink)"
      >
        <span class="chat-info__content__item__link">Genetic test results</span>
      </div>
    </div>
  </div>
  <div class="new-sessions" *ngIf="newSessions && false">
    <perfect-scrollbar style="height: calc(100vh - 80px)">
      <mat-card
        class="new-sessions__item"
        *ngFor="let item of newSessions"
        (click)="changeAgent(item)"
      >
        <div>New message from {{ item.firstName }}</div>
        <button mat-button (click)="changeAgent(item)">Accept the chat</button>
      </mat-card>
    </perfect-scrollbar>
    <!-- <mat-card class="new-sessions__item" *ngFor="let item of newSessions">
    <div>New message from {{ item.firstName }}</div>
    <button mat-button (click)="changeAgent(item)">Accept the chat</button>
  </mat-card> -->
  </div>
</div>
