export class SendInfo {
  clientId: number | null;
  productId: number | null;
  //patientId: number | null;
  title: string;
  text: string;
  mail: string;
  phone: string;

}

