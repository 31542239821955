import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Status } from 'src/app/data/Status';

@Component({
  selector: 'app-actions-selection',
  templateUrl: './actions-selection.component.html',
  styleUrls: ['./actions-selection.component.css'],
})
export class ActionsSelectionComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let a: Status[];
  }
  closeAfterSelection() {
    this.dialogRef.close(this.data);
  }
  ngOnInit(): void {}
}
