import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Deal } from 'src/app/data/Deal';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';
import { DealForClientMin } from 'src/app/data/DealForClientMin';
import { DealForTableMin } from 'src/app/data/DealForTableMin';
import { DealsSummery } from 'src/app/data/DealsSummery';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  CancellationRequestEnum,
  DealEnum,
  PaymentMethodEnum,
} from 'src/app/Enums/StatusesEnum';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { DealsPusherService } from 'src/app/Services/deals-pusher.service';
import { DealsService } from 'src/app/Services/deals.service';
import { NumberUtilsService } from 'src/app/Services/number-utils.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss'],
})
export class TransactionsTableComponent implements OnInit {
  @Input() showLoadingCircle: boolean = false;
  @Input() checkedAll: boolean = false;
  @Input() checkedIds: any;
  @Input() transactionData: DealForTableMin[];
  @Input() statuses: Status[];
  headFilter: any;

  @Input() dealsSummery: DealsSummery;
  regularUser: RegularUser;
  isOnRowClicked: any;
  expandedBundles: any = {};
  constructor(
    private dealsService: DealsService,
    private dealsPusherService: DealsPusherService,
    public datepipe: DatePipe,
    private popupHandler: PopupHandlerService,
    public numberUtilsService: NumberUtilsService,
    private regularUsersService: RegularUsersService,
    private cancelationRequestService: CancelationRequestService,
    private router: Router
  ) {
    this.checkedIds = {};
  }

  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe(
      (user) => (this.regularUser = user)
    );
    this.dealsPusherService.onCancelResponse.subscribe((res) => {
      this.showLoadingCircle = false;
    });
  }
  getDealStatus(deal: DealForTableMin) {
    if (!deal.statusId) return new Status();
    const status = this.statuses.find((x) => x.statusId == deal.statusId); //?? new Status()
    return status;
  }

  ifIncompletePayment(deal: DealForTableMin) {
    if (deal.statusId == DealEnum.Uncompleted) return true;
    return false;
  }
  /* ifCreditCard(){
    return deal.paymentMethodId == PaymentMethodEnum.CreditCard
  } */
  cancelDeal(deal: DealForTableMin) {
    try {
      if (deal.paymentMethodId == PaymentMethodEnum.CreditCard) {
        this.popupHandler.confirmDialogSubject
          .pipe(take(1))
          .subscribe((res) => {
            if (res.ans == 'yes') {
              this.showLoadingCircle = true;
              this.dealsPusherService.cancel(deal);

              //this.dealsService.getAll();
            }
          });
        this.popupHandler.openConfirmationDialog('', 'transactions');
        /*
     
   */
      } else {
        this.popupHandler.confirmDialogSubject
          .pipe(take(1))
          .subscribe((res) => {
            if (res.ans == 'yes') {
              deal.statusId = DealEnum.Canceled;
              /*  deal.status = this.statuses.find(
                (x) => x.statusId == deal.status.statusId
              ); */
              this.dealsPusherService.changeStatus(deal);
            }
          });
        this.popupHandler.openConfirmationDialog('', 'transactions');
      }
    } catch (e) {
      //console.log('error' + e);
    }
  }
  approveDeal(event, deal: DealForTableMin) {
    //deal.status = this.statuses.find((x) => x.statusId == DealEnum.Done);
    this.dealsPusherService.changeStatus(deal);
    this.getDealStatus(deal);
    //event.stopPropagation();
  }
  isRefund(date: Date) {
    return false;
  }
  stopProp(event) {
    event.stopPropagation();
  }
  isChecked({ dealId, checked }) {
    // isChecked(event, el, inx) {
    if (checked) {
      this.checkedIds[dealId] = true;
    } else {
      delete this.checkedIds[dealId];
    }

    //event.stopPropagation();
  }

  getNumChecked() {
    if (this.dealsSummery) return this.dealsSummery.amount;
  }
  getAllWithFilter(e) {
    this.headFilter = e;

    //this.tr.getAll(this.headFilter);
  }
  checkAll(event) {
    const isChecked = event.checked;

    this.transactionData.forEach((deal) => {
      if (isChecked) {
        this.checkedIds[deal.dealId] = true;
        deal.isChecked = true;
      } else {
        delete this.checkedIds[deal.dealId];
        deal.isChecked = false;
      }
    });
  }
  getAllAgents() {
    if (!this.transactionData || !this.dealsSummery) return '';
    let retAgents = '';
    for (let agent of this.dealsSummery.agents) {
      retAgents += agent + '  ';
    }

    /*
    var retAgents = "";
    var agentsObj = {};

    this.transactionData.forEach(deal => {
      if (deal.isChecked) {
        agentsObj[deal.agent] = true;
      }
    });

    for (let agent in agentsObj) {
      retAgents += agent + "  ";
    }
    
    return retAgents;
    */
    return retAgents;
  }
  getDateRange() {
    if (this.getNumChecked() == 0 || !this.dealsSummery) return '';
    const minDate = this.datepipe.transform(
      this.dealsSummery.startDate,
      'dd-MM-yyyy'
    );
    const maxDate = this.datepipe.transform(
      this.dealsSummery.endDate,
      'dd-MM-yyyy'
    );

    /*
        this.transactionData.forEach(deal => {
          if (deal.isChecked) {
            if (!deal.dateObj) deal.dateObj = new Date(Date.parse(deal.dateUpdated));
            minDate = (!minDate) ? deal.dateObj : new Date(Math.min(minDate.getTime(), deal.dateObj.getTime()));
            maxDate = (!maxDate) ? deal.dateObj : new Date(Math.max(maxDate.getTime(), deal.dateObj.getTime()));
          }
        });
        */
    // return this.datepipe.transform(minDate, 'dd-MM-yyyy') + " - " + this.datepipe.transform(maxDate, 'dd-MM-yyyy')
    return minDate + ' - ' + maxDate;
  }
  getDealsSum() {
    if (!this.transactionData || !this.dealsSummery) return '0';
    return this.dealsSummery.sum;
    /*
    var sum: number = 0;
    this.transactionData.forEach(deal => {
      if (deal.isChecked && deal.status.statusId == 3) {
        sum += deal.amount;
      }
    });
    return this.numberUtilsService.roundToNumDigits(sum, 2);
    */
  }
  getProductsStatuses() {
    if (!this.transactionData || !this.dealsSummery) return '';

    let statusesStr = '';
    /*
    var statusesArr = {};
    this.transactionData.forEach(deal => {
      if (deal.isChecked) {
        deal.productsStatuses.forEach(product => {
          if (!statusesArr[product]) statusesArr[product] = 0;
          statusesArr[product]++;

        });
      }
    });
    */
    for (let status of this.dealsSummery.statuses) {
      if (status.amount > 0)
        statusesStr += status.name + '  ' + status.amount + '<br>';
    }
    return statusesStr;
  }
  onRowClick(row: DealForClientMin) {
    if (this.isOnRowClicked) return;
    //if (row.status.statusId == DealEnum.Done) {
    //if (row.paymentMethodId == PaymentMethodEnum.CreditCard) {
    let url = '';

    if (row.isCancelDeal) {
      this.cancelationRequestService
        .getByCancelDealId(row.dealBundleId)
        .subscribe((request) => {
          var shpwNoPermisionPopup = false;

          switch (request.statusId) {
            case CancellationRequestEnum.NewRequest:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_ConservationTab'],
                this.regularUser
              );
              break;
            case CancellationRequestEnum.ManagerApprove:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_ManagerTab'],
                this.regularUser
              );
              break;
            case CancellationRequestEnum.RefundApprove:
            case CancellationRequestEnum.RefundClosed:
              shpwNoPermisionPopup = !userHasPermisions(
                ['Cancelations_RefundTab'],
                this.regularUser
              );
              break;
            case CancellationRequestEnum.Closed:
              shpwNoPermisionPopup = !userHasPermisions(
                [
                  'Cancelations_ConservationTab',
                  'Cancelations_ManagerTab',
                  'Cancelations_RefundTab',
                ],
                this.regularUser
              );
            default:
              break;
          }
          if (shpwNoPermisionPopup) {
            this.popupHandler.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.costumMessage,
              '',
              '',
              'you have no permisions to view cancelation request'
            );
          } else {
            switch (request.statusId) {
              case CancellationRequestEnum.NewRequest:
              case CancellationRequestEnum.ManagerApprove:
              case CancellationRequestEnum.Closed:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/cancelations/new/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
                break;
              case CancellationRequestEnum.RefundApprove:
              case CancellationRequestEnum.RefundClosed:
                url = this.router.serializeUrl(
                  this.router.createUrlTree(['/refunds/edit/'], {
                    queryParams: {
                      cancelRequestId: request.cancelationRequestId,
                    },
                  })
                );
            }
            if (url != '') window.open(url, '_blank');
          }
        });
    } else {
      this.dealsService.getTransactionsReports(row.dealId).subscribe((deal) => {
        this.popupHandler.openDealDetailsDialog(deal);
      });
    }
    this.isOnRowClicked = true;
    setTimeout(() => {
      this.isOnRowClicked = false;
    }, 1000);
    //}
  }
  hasPermmisions() {
    return userHasPermisions(['All Transactions'], this.regularUser);
  }
  stopPropegation(e) {}
  handleApproval(deal: DealForTableMin) {
    this.dealsPusherService.changeStatus(deal);
    this.getDealStatus(deal);
  }
  handlePanelStateChange(key: string, isOpen: boolean) {
    this.expandedBundles = { ...this.expandedBundles, [key]: isOpen };
  }
  showBundleExpansionPanel(bundle: DealBundleForTable) {
    if (!bundle.deals || bundle.deals.length == 0) return false;
    if (bundle.isCancelDeal) return false;
    if (bundle.deals.length > 1) return true;

    if (
      bundle.deals[0].statusId == DealEnum.Done &&
      bundle.deals[0].amount == bundle.amount
    )
      return false;
    if (bundle.deals[0].statusId == DealEnum.Rejected) return false;
    return true;
  }
}
