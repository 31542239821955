import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CategoryForFood } from 'src/app/data/CategoryForFood';
import { Food } from 'src/app/data/Food';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { FoodRest } from 'src/app/data/food-genes/FoodRest';
import { MakeEffort } from 'src/app/data/food-genes/MakeEffort';
import { RecipeDirection } from 'src/app/data/food-genes/RecipeDirection';
import { FoodLang } from 'src/app/data/FoodLang';
import { FoodNutrient } from 'src/app/data/FoodNutrient';
import { FoodNutrientLang } from 'src/app/data/FoodNutrientLang';
import { FoodScale } from 'src/app/data/FoodScale';
import { FoodScaleLang } from 'src/app/data/FoodScaleLang';
import { FoodScaleValue } from 'src/app/data/FoodScaleValue';
import { FoodToNutrient } from 'src/app/data/FoodToNutrient';
import { Language } from 'src/app/data/Language';
import { NameAndId } from 'src/app/data/NameAndId';
import { RecipeBullet } from 'src/app/data/RecipeBullet';
import { RecipeBulletLang } from 'src/app/data/RecipeBulletLang';
import { RecipeHeader } from 'src/app/data/RecipeHeader';
import { RecipeHeaderLang } from 'src/app/data/RecipeHeaderLang';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  FoodGenesCatTypeEnum,
  FoodNutrientsZameret,
  FoodPublished,
  FoodWithRecipes,
  NutrientType,
  permissionModuleAndAction,
} from 'src/app/Enums/StatusesEnum';
import { EditFoodsService } from 'src/app/Services/edit-foods.service';
import { FoodCategoryLangsService } from 'src/app/Services/food-category-langs.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { FoodLangsService } from 'src/app/Services/food-langs.service';
import { FoodNutrientLangsService } from 'src/app/Services/food-nutrient-langs.service';
import { FoodScaleLangsService } from 'src/app/Services/food-scale-langs.service';
import { FoodScaleValuesService } from 'src/app/Services/food-scale-values.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { autocomplete } from 'src/app/Utils/autocomplete';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { environment } from 'src/environments/environment';

export class Direction {
  value: string;
}

@Component({
  selector: 'app-edit-food',
  templateUrl: './edit-food.component.html',
  styleUrls: ['./edit-food.component.scss'],
})
export class EditFoodComponent implements OnInit {
  @ViewChild('autocomplteEl') autocomplteEl: QueryList<ElementRef>;
  @ViewChild('foodScaleSelect') foodScaleSelect: QueryList<MatSelect>;
  foodId: number;
  langId: number;
  sourceKey: string;
  food: Food;
  isRecipe: boolean;
  foodScales: NameAndId[];
  bulletScaleValues: any[] = [];
  foodCategories: FoodCat[];
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
  };
  foodScaleIdInc = 0;
  searchTermFood$ = new BehaviorSubject<string>('');
  foodsResults$: Observable<Food[]> = this.searchTermFood$.pipe(
    autocomplete(800, (term: string) => {
      return this.foodLangsService.SearchByName(
        term,
        this.langId,
        environment.foodSourceKey,
        FoodWithRecipes.Both,
        FoodPublished.PublishedBoth
      );
    })
  );
  searchTermCategories$ = new BehaviorSubject<string>('');
  categoriesResults$: Observable<NameAndId[]> = this.searchTermCategories$.pipe(
    autocomplete(800, (term: string) => {
      return this.foodCategoryLangsService.SearchByName(term, this.langId);
    })
  );
  autoCompleteActiveFoodId: number;
  autoCompleteActiveCounter = 1;
  validUnits: boolean = true;
  nutrients: FoodToNutrient[];
  vitamins: FoodToNutrient[];
  allergens: FoodToNutrient[];
  hasAutoCompleteError: boolean;
  tempFormData: FormData;
  tempImgName: string;
  tempImg: any;
  imgError: string;
  langs: Language[];
  selectedLang: number;
  foodCategoriesToShow: any;
  categoryForFoodTemp: CategoryForFood[];
  nutrientsRequiredError: boolean;
  validWeightUnits: boolean = true;
  validFoodAmount: boolean = true;
  recipeBullets: RecipeBullet[];
  catIdToRemove: number;
  calories: any;
  directions: RecipeDirection[];
  difficulties: MakeEffort[];
  rests: FoodRest[];
  regularUser: RegularUser;
  selectedCat: FoodCat;
  categoryType: FoodGenesCatTypeEnum;
  constructor(
    private route: ActivatedRoute,
    private editFoodsService: EditFoodsService,
    private foodScaleLangs: FoodScaleLangsService,
    private foodLangsService: FoodLangsService,
    private foodCategoryLangsService: FoodCategoryLangsService,
    private popupHandlerService: PopupHandlerService,
    private foodScaleValuesService: FoodScaleValuesService,
    private foodNutrientLangsService: FoodNutrientLangsService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private languagesService: LanguagesService,
    private foodGenesService: FoodGenesService,
    regularUsersService: RegularUsersService
  ) {
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params) {
        this.foodId = params['foodId'];
        this.langId = params['langId'];
        this.sourceKey = params['sourceKey'];
        this.isRecipe = params['isRecipe'] == 'true' ? true : false;
        this.categoryType = this.isRecipe
          ? FoodGenesCatTypeEnum.Recipe
          : FoodGenesCatTypeEnum.Food;
        this.selectedLang = +this.langId;
        this.getAllFoodScales();

        this.getDifficulties();
        this.getLangs();

        if (+this.foodId !== -1) {
          this.getFood();
        } else {
          this.getAllNutrients();
          this.food = new Food();
          this.food.isRecipe = this.isRecipe;
          this.food.foodSourceKeyId = +this.sourceKey;
          this.food.foodLangs = [new FoodLang()];
          this.food.foodLangs[0].languageId = this.langId;
          this.food.foodScaleValues = [];
          this.food.isNutrientsLock = this.isRecipe;
          this.addUnit();

          if (this.isRecipe) {
            this.addSection();
            this.autoCompleteActiveFoodId = 0;
          }
        }
      }
    });
  }
  getSelectedCategoryRecursively(
    categoryId: number,
    array: FoodCat[]
  ): FoodCat {
    let res: FoodCat = null;
    if (!array || array?.length == 0) return null;
    for (let index = 0; index < array.length; index++) {
      let element = array[index];
      if (element.categoryId == categoryId) {
        return element;
      }
    }
    for (let index = 0; index < array?.length; index++) {
      let subArray = array[index].subCategories;
      res = this.getSelectedCategoryRecursively(categoryId, subArray);
      if (res) return res;
    }
    return null;
  }
  onSelectCat(cat: FoodCat) {
    this.selectedCat = cat;
    this.food.foodCategoryId = this.selectedCat.categoryId;
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.regularUser);
  }
  getDifficulties() {
    this.foodGenesService.getDifficulties(this.langId).subscribe((res) => {
      this.difficulties = res;
    });
  }
  getRests() {
    this.foodGenesService.getRests(this.langId).subscribe((res) => {
      this.rests = res;
    });
  }
  getFoodScaleName(foodScaleId: number, values: FoodScaleValue[]) {
    return values?.find((x) => x.foodScaleId == foodScaleId)?.foodScale
      ?.foodScaleLangs[0].name;
  }
  getLangs() {
    this.languagesService.getAll().subscribe((data) => {
      this.langs = data;
    });
  }
  changeLangId() {
    this.router.navigate(['foods/edit'], {
      queryParams: {
        foodId: this.foodId,
        langId: this.selectedLang,
        isRecipe: this.isRecipe,
        sourceKey: this.sourceKey,
      },
    });
  }
  cancel(foodForm: NgForm) {
    if (foodForm.form.dirty || this.tempFormData) {
      this.popupHandlerService.openConfirmationDialog(
        null,
        'You about cancel without save'
      );

      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((result) => {
          if (!result) return;
          if (result.ans === 'yes') {
            this.router.navigate([`${this.isRecipe ? 'recipes' : 'foods'}`]);
          }
        });
    } else {
      this.router.navigate([`${this.isRecipe ? 'recipes' : 'foods'}`]);
    }
  }
  getAllNutrients() {
    this.foodNutrientLangsService.getAll(this.langId).subscribe((data) => {
      this.food.foodToNutrients = [new FoodToNutrient()];
      data.forEach((x) => {
        var foodToNutrient = new FoodToNutrient();
        foodToNutrient.foodNutrient = new FoodNutrient();

        foodToNutrient.foodNutrient.foodNutrientLangs = [
          new FoodNutrientLang(),
        ];
        foodToNutrient.foodNutrient.foodNutrientLangs[0] = x;
        foodToNutrient.amount = null;
        foodToNutrient.foodNutrientId = x.foodNutrient.foodNutrientId;
        foodToNutrient.food = new Food();
        foodToNutrient.food.foodSourceKeyId = +this.sourceKey;
        foodToNutrient.foodNutrient.nutrientTypeId =
          x.foodNutrient.nutrientTypeId;
        foodToNutrient.foodNutrient.nutrientTypeId =
          x.foodNutrient.foodNutrientId;
        this.food.foodToNutrients.push(foodToNutrient);
      });
      this.nutrients = this.food?.foodToNutrients?.filter(
        (x) =>
          x.foodNutrient?.foodNutrientLangs[0].foodNutrient.nutrientTypeId ==
          NutrientType.nutrients
      );

      this.vitamins = this.food?.foodToNutrients?.filter(
        (x) =>
          x.foodNutrient?.foodNutrientLangs[0].foodNutrient.nutrientTypeId ==
          NutrientType.vitamins
      );
      this.allergens = this.food?.foodToNutrients?.filter(
        (x) =>
          x.foodNutrient?.foodNutrientLangs[0].foodNutrient.nutrientTypeId ==
          NutrientType.allergens
      );
    });
  }
  setAutoCompleteActive(ingredientFoodId: number) {
    this.autoCompleteActiveFoodId = ingredientFoodId;
  }
  setErrorToAutoComplete() {
    this.food.recipeHeaders.forEach((x) => {
      var found = x.recipeBullets.find(
        (y) => y.ingredientFoodId == this.autoCompleteActiveFoodId
      );
      if (found) {
        this.hasAutoCompleteError = true;
      }
    });
  }
  getNutrients() {
    this.nutrients = this.food?.foodToNutrients?.filter(
      (x) => x.foodNutrient.nutrientTypeId == NutrientType.nutrients
    );
    this.nutrients = this.nutrients.map((x) => {
      x.amount = Math.round((x.amount + Number.EPSILON) * 100) / 100;
      return x;
    });
    this.calories = this.nutrients.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    )?.amount;
  }
  getVitamins() {
    this.vitamins = this.food?.foodToNutrients?.filter(
      (x) => x.foodNutrient.nutrientTypeId == NutrientType.vitamins
    );
    this.vitamins = this.vitamins.map((x) => {
      x.amount = Math.round((x.amount + Number.EPSILON) * 100) / 100;
      return x;
    });
  }
  getAllergens() {
    this.allergens = this.food?.foodToNutrients?.filter(
      (x) => x.foodNutrient.nutrientTypeId == NutrientType.allergens
    );
  }
  getFoodCategories() {
    this.foodGenesService
      .getFoodCategories(this.langId, this.categoryType)
      .subscribe((data) => {
        this.foodCategories = data;
        this.selectedCat = this.getSelectedCategoryRecursively(
          this.food.foodCategoryId,
          this.foodCategories
        );
      });
  }
  getCatsAndRests() {
    if (
      this.userHasPermisions([permissionModuleAndAction.FoodCategories_GetList])
    ) {
      this.getFoodCategories();
      this.getRests();
    }
  }
  getFood() {
    if (!this.isRecipe) {
      this.editFoodsService
        .getSingleFood(this.foodId, this.langId)
        .subscribe((res) => {
          this.food = res;

          this.food.isNutrientsLock = false;
          this.categoryForFoodTemp = [...this.food.categoryForFood];
          this.getCatsAndRests();
          this.getNutrients();
          this.getVitamins();
          this.getAllergens();
        });
    } else {
      this.editFoodsService
        .getSingleRecipe(this.foodId, this.langId)
        .subscribe((res) => {
          this.food = res;
          this.getCatsAndRests();
          this.categoryForFoodTemp = [...this.food.categoryForFood];
          this.food.recipeHeaders = this.food.recipeHeaders.map((x) => {
            x.recipeBullets = x.recipeBullets.map((y) => {
              if (!y.recipeBulletLangs || y.recipeBulletLangs.length == 0) {
                var recipeBulletLang = new RecipeBulletLang();
                recipeBulletLang.languageId = +this.langId;
                recipeBulletLang.recipeBulletId = y.recipeBulletId;
                y.recipeBulletLangs = [recipeBulletLang];
              }
              return y;
            });
            return x;
          });
          if (
            !this.food?.recipeDirections ||
            this.food?.recipeDirections?.length == 0
          ) {
            this.food.recipeDirections = [];
            this.addDirection();
          }
          this.getNutrients();
          this.getVitamins();
          this.getAllergens();
          if (!this.food.recipeHeaders || this.food.recipeHeaders.length == 0) {
            this.addSection();
          }
          if (this.food) {
            this.food.recipeHeaders.map((x) =>
              x.recipeBullets.map((y) =>
                this.getUnitScaleValues(y.foodScaleId, y.ingredientFoodId)
              )
            );
          }
        });
    }
  }
  onFoodScaleChange({ value: foodScaleId }, index: number) {
    const foodScale = this.foodScales.find((x) => x.id == foodScaleId);
    const foodScaleItem = this.food.foodScaleValues[index].foodScale;
    foodScaleItem.foodScaleId = foodScaleId;
    foodScaleItem.foodScaleLangs[0].name = foodScale.name;
    foodScaleItem.foodScaleLangs[0].foodScaleId = foodScale.id;
    foodScaleItem.foodScaleLangs[0].languageId = +this.langId;
    foodScaleItem.foodScaleLangs[0].foodScaleLangId = foodScale.id;
  }
  getAllFoodScales() {
    this.foodScaleLangs.getAll(this.langId).subscribe((data) => {
      this.foodScales = data;
    });
  }
  getRandomArbitrary(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  findFooScaleName(id: number) {
    return this.foodScales?.find((x) => x.id == id);
  }
  addBarcode() {
    let item = [''];
    this.food.foodBarcode.push(...item);
  }
  addUnit() {
    let foodScaleValue = new FoodScaleValue();
    foodScaleValue.foodId = +this.foodId;
    foodScaleValue.foodScaleId = this.getRandomArbitrary(-10000, -1);
    foodScaleValue.amount = null;
    foodScaleValue.weight = null;
    foodScaleValue.foodScale = new FoodScale();
    let foodSclaeLang = new FoodScaleLang();
    foodSclaeLang.languageId = +this.langId;
    foodScaleValue.foodScale.foodScaleLangs = [];
    foodScaleValue.foodScale.foodScaleLangs.push(foodSclaeLang);
    this.food.foodScaleValues.push(foodScaleValue);
  }
  clear() {
    this.foodGenesService
      .getFoodCategories(this.langId, this.categoryType)
      .subscribe((data) => {
        this.foodCategories = data;
        this.selectedCat = this.foodCategories[-1];
        this.food.foodCategoryId = -1;
      });
  }
  getUnitScaleValues(foodScaleId: number, ingredientFoodId: number) {
    this.foodScales.map((x) => {
      if (x.id == foodScaleId) {
        this.bulletScaleValues[ingredientFoodId] = x;
      }
    });
  }
  removeBarcodeUnit(unitIndex: number, foodForm: NgForm, text: string) {
    if (text.trim() === '') {
      foodForm.form.removeControl('barcode-' + unitIndex);
      this.food.foodBarcode.splice(unitIndex, 1);
      return;
    }
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes' || text.trim() === '') {
          foodForm.form.removeControl('barcode-' + unitIndex);
          this.food.foodBarcode.splice(unitIndex, 1);
        }
      });
  }
  removeUnit(unitIndex: number, foodForm: NgForm) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          foodForm.form.removeControl('weight-' + unitIndex);
          this.food.foodScaleValues.splice(unitIndex, 1);
          //dont use this cause it dont fully remove the control
          /* this.food.foodScaleValues = this.food.foodScaleValues.map(
            (x, inx) => {
              if (inx == unitIndex) {
                x.hidden = true;
              }
              return x;
            }
          ); */
        }
      });
  }
  isLastUnit(item: FoodScaleValue) {
    const arr = this.food.foodScaleValues.filter((x) => !x.hidden);
    return arr[arr.length - 1].foodScaleValueId == item.foodScaleValueId;
  }
  removeBullet(headerIndex: number, bulletIndex: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.food.recipeHeaders[headerIndex].recipeBullets =
            this.food.recipeHeaders[headerIndex].recipeBullets.filter(
              (item, index) => index !== bulletIndex
            );
          if (this.food.recipeHeaders[headerIndex].recipeBullets.length == 0) {
            this.food.recipeHeaders.splice(headerIndex, 1);
          }
        }
      });
  }
  removeRecipeHeader(headerIndex: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          /*this.food.recipeHeaders = this.food.recipeHeaders.filter(
            (item, index) => index !== headerIndex
          ); */
          this.food.recipeHeaders.splice(headerIndex, 1);
          if (this.food.recipeHeaders.length == 0) {
            this.addSection();
          }
        }
      });
  }
  disableRemoveBullet(headerIndex: number) {
    const bullet = this.food?.recipeHeaders[headerIndex]?.recipeBullets;
    if (bullet.length == 1) return true;
    //if (this.food.recipeHeaders.length == 1) return true;
  }
  addBullet(index: number) {
    var recipeBullet = new RecipeBullet();
    recipeBullet.parentFoodId = this.foodId;

    var recipeBulletLang = new RecipeBulletLang();
    recipeBulletLang.languageId = +this.langId;
    recipeBulletLang.recipeBulletId = 0;
    recipeBullet.recipeBulletLangs = [recipeBulletLang];

    recipeBullet.ingredientFood = new Food();
    recipeBullet.ingredientFoodId = --this.autoCompleteActiveCounter;
    recipeBullet.ingredientFood.foodLangs = [new FoodLang()];

    this.food.recipeHeaders[index].recipeBullets.push(recipeBullet);
  }
  addSection() {
    var recipeHeader = new RecipeHeader();
    recipeHeader.foodId = +this.foodId;

    var recipeBullet = new RecipeBullet();
    recipeBullet.parentFoodId = this.foodId;

    var recipeBulletLang = new RecipeBulletLang();
    recipeBulletLang.languageId = +this.langId;
    recipeBulletLang.recipeBulletId = 0;
    recipeBullet.recipeBulletLangs = [recipeBulletLang];

    recipeBullet.ingredientFood = new Food();
    recipeBullet.ingredientFoodId = --this.autoCompleteActiveCounter;
    recipeBullet.ingredientFood.foodLangs = [new FoodLang()];
    recipeHeader.recipeBullets = [recipeBullet];

    var recipeHeaderLang = new RecipeHeaderLang();
    recipeHeaderLang.languageId = +this.langId;
    recipeHeader.recipeHeaderLangs = [recipeHeaderLang];

    this.food.recipeHeaders = this.food.recipeHeaders
      ? [...this.food.recipeHeaders]
      : [];

    this.food.recipeHeaders.push(recipeHeader);
  }
  trackByFn(index: number, item: any) {
    return index; // or item.id
  }
  trackByFnBarcode(index: number, item: any) {
    return index; // or item.id
  }
  getRecipeHeaderLangName(item: RecipeHeader) {
    return item.recipeHeaderLangs[0].name;
  }
  sanitizePreviewSrc(src: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(src);
  }
  uploadImg(files: any) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/tiff'];
    this.imgError = '';
    if (!allowTypes.includes(fileToUpload.type)) {
      this.imgError = 'Please upload valid Image';
      return;
    }
    formData.append('file', fileToUpload, fileToUpload.name);

    if (+this.foodId !== -1) {
      this.editFoodsService
        .uploadImg(this.foodId, formData)
        .subscribe((data) => {
          this.food.imageUrl = data.filePath;
        });
    } else {
      this.tempFormData = formData;
      this.tempImgName = fileToUpload.name;

      this.tempImg = URL.createObjectURL(fileToUpload);
    }
  }
  validateAmountValue(event: KeyboardEvent) {
    var re = new RegExp('^[a-zA-z]|[א-ת]$');
    if (re.test(event.key)) {
      event.stopPropagation();
    }
  }
  formatNumber(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  /*  onCategoriesSelected(selectedCategories: number[]) {
    const categories = selectedCategories?.map((id) => {
      return this.categoryLangs?.find((category) => category.id == id);
    });
    this.food.categoryForFood = [];
    categories.map((category) => {
      if (category) {
        const categoryForFoodId = this.categoryForFoodTemp?.find(
          (x) => x.foodCategoryId == category?.id
        )?.categoryForFoodId;
        let categoryForFood = new CategoryForFood();
        categoryForFood.categoryForFoodId = categoryForFoodId
          ? categoryForFoodId
          : 0;
        categoryForFood.foodId = this.food.foodId;
        categoryForFood.foodCategoryId = category.id;

        let foodCategory = new FoodCategory();
        foodCategory.foodCategoryId = category.id;

        let foodCategoryLangs = new FoodCategoryLang();
        foodCategoryLangs.foodCategoryId = category.id;
        foodCategoryLangs.name = category.name;
        foodCategory.foodCategoryLangs = [foodCategoryLangs];

        categoryForFood.foodCategory = foodCategory;

        this.food.categoryForFood.push(categoryForFood);
      }
    });
  } */
  removeCat(foodCategoryId: number) {
    this.catIdToRemove = null;
    this.popupHandlerService.openConfirmationDialog(null, 'Remove Category');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.food.categoryForFood = this.food.categoryForFood.filter(
            (x) => x.foodCategoryId !== foodCategoryId
          );
          this.catIdToRemove = foodCategoryId;
        }
      });
  }
  onFoodSelected(
    ingredientFood: any,
    bulletIndex: number,
    optionIndex: number
  ) {
    this.food.recipeHeaders[bulletIndex].recipeBullets[
      optionIndex
    ].ingredientFoodId = ingredientFood.id;

    this.food.recipeHeaders[bulletIndex].recipeBullets[
      optionIndex
    ].ingredientFood.foodLangs[0].name = ingredientFood.name;

    this.foodScaleValuesService
      .getFoodScaleValues(ingredientFood.id, this.langId)
      .subscribe((data) => {
        this.food.recipeHeaders[bulletIndex].recipeBullets[
          optionIndex
        ].ingredientFood.foodScaleValues = [];
        data.forEach((x) => {
          let foodScaleValue = new FoodScaleValue();
          foodScaleValue.foodScaleId = x.id;
          foodScaleValue.foodScale = new FoodScale();
          foodScaleValue.foodScale.foodScaleLangs = [new FoodScaleLang()];
          foodScaleValue.foodScale.foodScaleLangs[0].name = x.name;
          this.food.recipeHeaders[bulletIndex].recipeBullets[
            optionIndex
          ].ingredientFood.foodScaleValues.push(foodScaleValue);
        });
      });
  }
  checkboxChange(event: MatCheckboxChange, idx: number) {
    if (event.checked) {
      this.allergens[idx].amount = 1;
    } else {
      this.allergens[idx].amount = 0;
    }
  }
  redirectAfterPost(foodId: number) {
    this.router.navigate(['foods/edit'], {
      queryParams: {
        foodId: foodId,
        langId: this.langId,
        isRecipe: this.isRecipe,
        sourceKey: this.sourceKey,
      },
    });
  }
  getFoodLangName(foodLangs: FoodLang[]) {
    return foodLangs.find((x) => x.languageId == +this.langId)?.name;
  }
  onSubmit(foodForm: NgForm) {
    this.food.foodScaleValues = this.food.foodScaleValues.filter(
      (x) => !x.hidden
    );
    let foodScaleIdArr = this.food.foodScaleValues.map((x) => x.foodScaleId);
    let isDuplicate = foodScaleIdArr.some((x, idx) => {
      return foodScaleIdArr.indexOf(x) != idx;
    });
    let foodScalesBelowZero = this.food.foodScaleValues.some(
      (x) => x.weight < 0
    );

    if (isDuplicate) {
      this.validUnits = false;
    } else {
      this.validUnits = true;
    }

    if (foodScalesBelowZero) {
      this.validWeightUnits = false;
    } else {
      this.validWeightUnits = true;
    }

    //Remove hidden wight units
    this.food.foodScaleValues = this.food.foodScaleValues.filter(
      (x) => !x.hidden
    );

    if (this.isRecipe) {
      let recipeBullets = [];
      this.food.recipeHeaders.forEach((x) => {
        recipeBullets = recipeBullets.concat(x.recipeBullets);
      });
      let foodAmountBelowZero = recipeBullets.some(
        (x) => x.quantity && x.quantity < 0
      );

      if (foodAmountBelowZero) {
        this.validFoodAmount = false;
      } else {
        this.validFoodAmount = true;
      }

      this.food.recipeHeaders.forEach((x) => {
        this.hasAutoCompleteError = x.recipeBullets.some(
          (y) => y.ingredientFoodId <= 0
        );
      });
    }

    for (let index = 0; index < this.nutrients.length; index++) {
      const errors = foodForm.controls[`nutAmount-${index}`].errors;
      if (errors) {
        this.nutrientsRequiredError = true;
        return;
      }
      this.nutrientsRequiredError = false;
    }
    this.allergens = this.allergens.map((x) => {
      if (!x.amount) {
        x.amount = 0;
      }
      return x;
    });
    this.vitamins = this.vitamins.map((x) => {
      if (!x.amount) {
        x.amount = 0;
      }
      return x;
    });
    this.nutrients = this.nutrients.map((x) => {
      if (!x.amount) {
        x.amount = 0;
      }
      return x;
    });

    if (
      foodForm.form.valid &&
      this.validUnits &&
      this.validWeightUnits &&
      this.validFoodAmount
    ) {
      let nutConcat = this.vitamins.concat(this.nutrients, this.allergens);
      this.food.foodToNutrients = nutConcat;

      if (!this.isRecipe) {
        if (+this.foodId == -1) {
          this.editFoodsService
            .postFood(this.food, this.langId)
            .subscribe((data: any) => {
              if (!data.errorId) {
                this.popupHandlerService.openDisapearingAlertDialog('success');
                setTimeout(() => {
                  foodForm.reset();
                  if (this.tempFormData) {
                    this.editFoodsService
                      .uploadImg(data.foodId, this.tempFormData)
                      .subscribe((_data) => {
                        this.tempFormData = null;
                        this.tempImgName = null;
                        this.tempImg = null;
                        this.redirectAfterPost(data.foodId);
                      });
                  } else {
                    this.redirectAfterPost(data.foodId);
                  }
                }, 500);
              }
            });
        } else {
          this.editFoodsService
            .putFood(this.foodId, this.food)
            .subscribe((data: any) => {
              if (!data.errorId) {
                foodForm.reset();
                this.getFood();
                this.popupHandlerService.openDisapearingAlertDialog('success');
              }
            });
        }
      } else {
        if (+this.foodId == -1) {
          this.editFoodsService
            .postRecipe(this.food, this.langId)
            .subscribe((data: any) => {
              if (!data.errorId) {
                this.popupHandlerService.openDisapearingAlertDialog('success');

                setTimeout(() => {
                  if (this.tempFormData) {
                    foodForm.reset();
                    this.editFoodsService
                      .uploadImg(data.foodId, this.tempFormData)
                      .subscribe((_data) => {
                        this.tempFormData = null;
                        this.tempImgName = null;
                        this.tempImg = null;

                        this.redirectAfterPost(data.foodId);
                      });
                  } else {
                    this.redirectAfterPost(data.foodId);
                  }
                }, 500);
              }
            });
        } else {
          this.editFoodsService
            .putRecipe(this.foodId, this.food)
            .subscribe((data: any) => {
              if (!data.errorId) {
                foodForm.reset();
                this.getFood();
                this.popupHandlerService.openDisapearingAlertDialog('success');
              }
            });
        }
      }
    } else {
      console.log(foodForm);
    }
  }
  onIsPublishedChange(event: any) {
    this.food.isPublished = event.checked;
  }
  onIsCalcRecommendationChange(event: any) {
    this.food.isCalcRecommendation = event.checked;
  }
  onIsBasicChange(event: any) {
    this.food.isBasic = event.checked ? 1 : 0;
  }
  addDirection() {
    const newDirection = new RecipeDirection();
    newDirection.recipeDirectionId = 0;
    newDirection.langId = +this.langId;
    newDirection.foodId = +this.foodId;
    newDirection.orderBy = this.food.recipeDirections.length + 1;
    this.food.recipeDirections.push(newDirection);
  }
  removeDirection(index: number) {
    if (this.food.recipeDirections[index]?.content?.length > 0) {
      this.popupHandlerService.openConfirmationDialog('', 'Delete');
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((data) => {
          if (data.ans === 'yes') {
            this.food.recipeDirections.splice(index, 1);
          }
        });
    } else {
      this.food.recipeDirections.splice(index, 1);
    }
  }
}
