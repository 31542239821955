<div style="display: flex; height: calc(100vh - 65px)">
  <div class="manage-roles__modules">
    <div class="manage-roles__modules__title">Modules</div>
    <perfect-scrollbar
      style="height: calc(100% - 60px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__modules__wrapper"
        *ngFor="let module of appModules"
      >
        <div
          class="manage-roles__modules__item"
          (click)="onModuleClick(module.appModuleId)"
          matRipple
          [class.active]="selectedModuleId == module.appModuleId"
        >
          {{ module.appModuleName }}
          <mat-icon
            class="manage-roles__modules__item__right-icon"
            *ngIf="selectedModuleId == module.appModuleId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <mat-card class="manage-roles__actions" *ngIf="selectedModuleId">
    <div class="manage-roles__actions__title">Actions</div>
    <perfect-scrollbar
      style="height: calc(100% - 60px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__actions__item"
        *ngFor="let action of showedActions"
        matRipple
        [class.active]="selectedActionId == action.moduleActionId"
      >
        <mat-checkbox
          [checked]="action.checked == true"
          (click)="onActionClick(action)"
        ></mat-checkbox>
        {{ action.moduleActionName }}
        <mat-icon *ngIf="selectedActionId == action.moduleActionId"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </perfect-scrollbar>
  </mat-card>
  <div class="manage-roles__permissions" *ngIf="selectedActionId">
    <div class="manage-roles__permissions__title">Permissions</div>
    <perfect-scrollbar
      style="height: calc(100% - 37px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__permissions__item"
        *ngFor="let rule of showedRules"
        (click)="onPermissionClick(rule.permissionRuleId)"
        matRipple
      >
        <mat-checkbox>{{ rule.permissionRuleName }}</mat-checkbox>
      </div>
    </perfect-scrollbar>
  </div>
</div>
