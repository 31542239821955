import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { AppImage } from 'src/app/data/algorithm/AppImage';

@Component({
  selector: 'app-files-manager',
  templateUrl: './files-manager.component.html',
  styleUrls: ['./files-manager.component.scss'],
})
export class FilesManagerComponent implements OnInit {
  images: any[];
  imgError: string;
  newSelectedImg: any;
  constructor(
    public dialogRef: MatDialogRef<FilesManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    console.log('data', this.data);
  }

  close() {
    this.dialogRef.close({ event: 'close' });
  }

  getImageById(imgId: number) {
    let finded = this.data.images.find(
      (img: AppImage) => img.fileId === imgId.toString()
    );
    if (finded) {
      return finded;
    } else {
      finded = this.data.images.find(
        (img: any) => img.algoBulletImageId === imgId
      );
      if (finded) {
        return finded;
      }
    }
  }

  onSelectImage(event: MatRadioChange) {
    this.newSelectedImg = this.getImageById(event.value);
  }

  addImage() {
    this.dialogRef.close({ event: 'add', selectedImg: this.newSelectedImg });
  }

  getImageUrl(image: any) {
    if ((image as AppImage).fileId) {
      return image.webPath + '/' + image.fileName;
    } else {
      return image.imageUrl;
    }
  }

  ifImageChecked(image: any) {
    if ((image as AppImage).fileId) {
      return image.fileId === this.data.selectedImageId;
    } else {
      return image.algoBulletImageId === this.data.selectedImageId;
    }
  }
  getImageValue(image: any) {
    if ((image as AppImage).fileId) {
      return image.fileId;
    } else {
      return image.algoBulletImageId;
    }
  }

  disableCurrentImageSelected(image: any) {
    return (
      this.data.selectedImageId === this.getImageValue(image) ||
      this.data.selectedImageId === null
    );
  }

  uploadImg(files) {
    if (files.length === 0) {
      return;
    }
    //const fileToUpload = files[0] as File;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    //formData.append('file', fileToUpload, fileToUpload.name);
    this.dialogRef.close({
      event: 'upload',
      selectedImg: this.newSelectedImg,
      formData,
    });
    /* const allowTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/tiff',
      'image/gif',
      'image/svg',
      'image/svg+xml',
    ];
    this.imgError = ''; 
    if (!allowTypes.includes(fileToUpload.type)) {
      this.imgError = 'Please upload valid Image!';
    } else {
      this.imgError = '';
      
    }*/
  }
}
