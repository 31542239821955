<!-- <button class="control" (click)="toggle()">
  <span class="pause-icon" *ngIf="!paused"></span>
  <span class="play-icon" *ngIf="paused"></span>
</button> -->
<ng-lottie
  class="confettiOverlay"
  [options]="options"
  (animationCreated)="animationCreated($event)"
>
</ng-lottie>
<mat-card class="card">
  <div>
    <p><strong>שם סוכן:</strong></p>
    <p>{{ newDeal.dealBundle.agent.regularUser.firstName }}</p>
  </div>
  <div>
    <p><strong>סכום עסקה: </strong></p>
    <p>₪{{ newDeal.dealBundle.amount }}</p>
  </div>
  <div *ngFor="let product of newDeal.dealBundle.agent.products">
    <p><strong>תיאור מוצר: </strong></p>
    <p>{{ product.productType.desc }}</p>
  </div>
</mat-card>
