import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogTypeEnum } from 'src/app/Enums/AlertDialogTypeEnum';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  title: string;
  type: AlertDialogTypeEnum;
  AlertDialogTypeEnum = AlertDialogTypeEnum;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //console.log(data);
    //this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.type = this.data.type;
  }
}
