import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MeetingSetting } from '../data/MeetingSetting';

@Injectable({
  providedIn: 'root',
})
export class MeetingSettingsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<MeetingSetting[]>(
      `${environment.baseUrl}MeetingSettings`
    );
  }

  post(meetingSettings: MeetingSetting) {
    return this.http.post<MeetingSetting>(
      `${environment.baseUrl}MeetingSettings`,
      meetingSettings
    );
  }

  put(meetingSettings: MeetingSetting) {
    return this.http.put<MeetingSetting>(
      `${environment.baseUrl}MeetingSettings/${meetingSettings.meetingSettingId}`,
      meetingSettings
    );
  }

  delete(meetingSettings: MeetingSetting) {
    return this.http.delete<any>(
      `${environment.baseUrl}MeetingSettings/${meetingSettings.meetingSettingId}`
    );
  }
}
