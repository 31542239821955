import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AgentMin } from 'src/app/data/AgentMin';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-all-phone-verification-setting',
  templateUrl: './all-phone-verification-setting.component.html',
  styleUrls: ['./all-phone-verification-setting.component.scss'],
})
export class AllPhoneVerificationSettingComponent implements OnInit {
  phoneVerificationSettings: MatTableDataSource<PhoneVerificationSetting> =
    new MatTableDataSource([]);
  agents: AgentMin[];
  displayedColumns: string[] = ['country', 'countryCode', 'action'];
  constructor(
    private phoneVerificationSettingsService: PhoneVerificationSettingsService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.phoneVerificationSettingsService.getAll().subscribe((data) => {
      this.phoneVerificationSettings.data = data;
    });
  }

  delete(id: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data.ans === 'yes') {
        this.phoneVerificationSettingsService.delete(id).subscribe(() => {
          this.getAll();
        });
      }
    });
  }
}
