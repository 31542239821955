import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UploadResponse } from '@kolkov/angular-editor';
import firebase from 'firebase/app';
import { BehaviorSubject, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppImage } from '../data/algorithm/AppImage';
import { ChatMsg } from '../data/chat-msg';
import { ChatFilter } from '../data/chat/ChatFilter';
import { ChatMessage } from '../data/chat/ChatMessage';
import { ChatRoomAgent } from '../data/chat/ChatRoomAgent';
import { ChatSession } from '../data/chat/ChatSession';
import { GeneralAgent } from '../data/chat/GeneralAgent';
import { NewSession } from '../data/chat/NewSession';
import { ErrorMessage } from '../data/ErrorMessage';
import { ChatFilterResult } from '../data/food-genes/ChatFilterResult';
import { IndicatorMsg } from '../data/indicator-msg';
import { RegularUser } from '../data/RegularUser';
import { StatementType } from '../Enums/ChatEnum';
import { FIREBASE_REFERENCES } from '../firebase.module';
import { lowercaseKeysRecursive } from '../Utils/general-helper';
import { userHasPermisions } from '../Utils/user-helper';
import { FirebaseAnonimousAuthService } from './firebase-anonimous-auth.service';
import { RegularUsersService } from './regular-users.service';
import { UnreadMessage } from '../data/chat/UnreadMessage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseChatIndicatorService {
  chatIndicatorCrm = new BehaviorSubject<any>(null);
  readonly chatIndicatorCrm$ = this.chatIndicatorCrm.asObservable();
  regularUser: RegularUser;
  isListend: boolean = false;
  closed: any;
  chatRoomAgent$: BehaviorSubject<ChatRoomAgent[]> = new BehaviorSubject<
    ChatRoomAgent[]
  >([]);
  chatFilterResult$: BehaviorSubject<ChatFilterResult[]> = new BehaviorSubject<
    ChatFilterResult[]
  >([]);
  chatRoom$: BehaviorSubject<{
    chatRoom: ChatRoomAgent;
    isNewSession?: boolean;
  }> = new BehaviorSubject({
    chatRoom: null,
    isNewSession: false,
  });
  chatRoom2$: BehaviorSubject<ChatFilterResult> =
    new BehaviorSubject<ChatFilterResult>(null);
  waitingList$: BehaviorSubject<NewSession[]> = new BehaviorSubject<
    NewSession[]
  >([]);
  unreadMessages$: BehaviorSubject<UnreadMessage[]> = new BehaviorSubject<
    UnreadMessage[]
  >([]);
  unreadMessagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  emojisState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  DocIdFieldPath = firebase.firestore.FieldPath.documentId();

  constructor(
    @Inject(FIREBASE_REFERENCES.CHAT_FIRESTORE)
    private readonly firestore: AngularFirestore,
    private anonAuthService: FirebaseAnonimousAuthService,
    private regularUsersService: RegularUsersService,
    private http: HttpClient
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
    // this.anonAuthService.isFireLogged$.subscribe((data) => {
    //   if (data && userHasPermisions(['Chat_GetIndicator'], this.regularUser)) {
    //     //console.log('enterd userHasPermisions');
    //     if (this.isListend) return;
    //     this.isListend = true;
    //     this.firestore
    //       .collection('chatIndicator')
    //       .snapshotChanges()
    //       .subscribe((res) => {
    //         this.chatIndicatorCrm.next(res);
    //       });
    //   }
    // });
  }
  //#region Api
  getAgentById(agentId: number, langId: number) {
    return this.http.get<GeneralAgent>(
      `${environment.baseUrl}Branch/GetAgent/${agentId}/${langId}`
    );
  }
  getAgentChatFull(agentId: number) {
    return this.http.get<ChatRoomAgent[]>(
      `${environment.baseUrl}Chat/GetAgentChatFull/${agentId}`
    );
  }
  getChatByFilter(chatFilter: ChatFilter) {
    return this.http.get<ChatFilterResult[]>(
      `${environment.baseUrl}Chat/GetByFilter/${chatFilter.departmentId}/${chatFilter.agentId}/${chatFilter.isWaiting}/${chatFilter.isOpen}/${chatFilter.branchId}/${chatFilter.langId}/${chatFilter.searchTerm}`
    );
  }
  changeAgent(
    branchId: number,
    langId: number,
    agentId: number,
    chatSession: ChatSession
  ) {
    return this.http.put<ChatRoomAgent | ErrorMessage>(
      `${environment.baseUrl}Chat/ChangeAgent/${branchId}/${langId}/${agentId}`,
      chatSession
    );
  }
  getSingleRoom(agentId: number, sessionId: number) {
    return this.http.get<ChatRoomAgent | ErrorMessage>(
      `${environment.baseUrl}Chat/GetSingleRoom/${agentId}/${sessionId}`
    );
  }
  getChatRoomForAgent(agentId: number, roomId: number) {
    return this.http.get<ChatRoomAgent | ErrorMessage>(
      `${environment.baseUrl}Chat/ChatRoomForAgentGet/${agentId}/${roomId}`
    );
  }
  uploadChatAttachment(file: File) {
    return this.http.post<AppImage | ErrorMessage>(
      `${environment.baseUrl}Uploader/UploadChatAttachmentFile`,
      file
    );
  }
  closeSession(branchId: number, langId: number, chatSession: ChatSession) {
    return this.http.post<any>(
      `${environment.baseUrl}Chat/CloseSession/${branchId}/${langId}`,
      chatSession
    );
  }
  readMessage(messageId: number, langId: number) {
    return this.http
      .get<any>(`${environment.baseUrl}Chat/ReadMessage/${messageId}/${langId}`)
      .subscribe();
  }
  getAgents(langId: number) {
    return this.http.get<GeneralAgent[]>(
      `${environment.baseUrl}Branch/GetAgentsForChat/${langId}`
    );
  }
  getAvatarDefaultImage() {
    return this.http.get<any>(
      `${environment.baseUrl}Chat/GetChatDefaultAvatar`
    );
  }
  // AgentinterseptionSession/{branchId}/{langId}/{agentId}
  agentInterseptionSession(
    branchId: number,
    langId: number,
    agentId: number,
    chatSession: ChatSession
  ) {
    return this.http.put<ChatRoomAgent | ErrorMessage>(
      `${environment.baseUrl}Chat/AgentinterseptionSession/${branchId}/${langId}/${agentId}`,
      chatSession
    );
  }
  //#endregion

  //#region Firebase
  listenToUnreadMessages(agentId: number) {
    return this.firestore
      .collection('UnreadMessages', (ref) =>
        ref
          .where(this.DocIdFieldPath, '>=', `Agent_${agentId}_`)
          .where(this.DocIdFieldPath, '<=', `Agent_${agentId}_999999999`)
      )
      .snapshotChanges()
      .pipe(
        map((res) => {
          let items = res.map((x) => {
            const item = x.payload.doc.data() as any;
            return { ...item };
          });

          return lowercaseKeysRecursive(items);
        })
      );
  }
  listenToChatRoomAgent(sessionId: number) {
    return this.firestore
      .collection('Chat', (ref) =>
        ref
          .where(this.DocIdFieldPath, '>=', `Session_${sessionId}_Message_0`)
          .where(
            this.DocIdFieldPath,
            '<=',
            `Session_${sessionId}_Message_9999999999`
          )
      )
      .snapshotChanges()
      .pipe(
        map((res) => {
          const items = res.map((x) => {
            const item = x.payload.doc.data() as any;
            return { ...item };
          });
          const filterd = items.filter(
            (x) =>
              x.Template == null ||
              x.Template.StatementType == StatementType.SystemMessage ||
              x.Template.StatementType == StatementType.ConversationMessage ||
              x.Template.StatementType == StatementType.AnswerMessage
          );
          return lowercaseKeysRecursive(filterd);
        })
      );
  }
  lisetnToNewSession(agentId: number) {
    return this.firestore
      .collection('Chat', (ref) =>
        ref
          .where(this.DocIdFieldPath, '>=', `NewSession_0`)
          .where(this.DocIdFieldPath, '<=', `NewSession_9999999999999999`)
          .where('AgentId', '==', agentId)
      )
      .snapshotChanges()
      .pipe(
        map((res) => {
          console.log('lisetnToNewSessionServise: ', res);
          console.log('agentId: ', agentId);

          const items = res.map((x) => {
            const item = x.payload.doc.data() as any;
            return { ...item };
          });
          return lowercaseKeysRecursive(items);
        })
      );
  }
  listenToSessionUpdated(sessionId: number) {
    return this.firestore
      .collection('Chat')
      .doc(`Session_${sessionId}_Updated`)
      .snapshotChanges()
      .pipe(
        map((res) => {
          const item = res.payload.data() as any;
          return lowercaseKeysRecursive(item);
        })
      );
  }
  sendChatMsg(chatMsg: ChatMessage, branchId: number, langId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Chat/SendMessage/${branchId}/${langId}`,
      chatMsg
    );
  }
  setAgentTyping(sessionId: number, isTyping: boolean) {
    return this.firestore
      .collection('ChatTyping')
      .doc(`Session_${sessionId}_Agent`)
      .set({ isTyping: isTyping });
  }
  listenToUserTyping(sessionId: number) {
    return this.firestore
      .collection('ChatTyping')
      .doc(`Session_${sessionId}_User`)
      .snapshotChanges()
      .pipe(
        map((res) => {
          const item = res.payload.data() as any;
          return lowercaseKeysRecursive(item);
        })
      );
  }
  //#endregion

  //#region old code
  closeRequest(collectionName: string, docId: string) {
    this.firestore
      .collection(collectionName)
      .doc(docId)
      .update({ isClosed: true });
  }
  getClosedPagination(pageNum, pageSize) {
    return this.firestore
      .collection('chatIndicator', (ref) =>
        ref
          .where('isClosed', '==', true)
          .orderBy('createdDate')
          .startAfter(pageNum * pageSize)
          .limit(pageSize)
      )
      .snapshotChanges()
      .pipe(
        first(),
        map((res) => (this.closed = res))
      );
  }
  getClosed() {
    if (this.closed) {
      return of(this.closed); // return from cache
    } else {
      return this.firestore
        .collection('chatIndicator', (ref) => ref.where('isClosed', '==', true))
        .snapshotChanges()
        .pipe(
          first(),
          map((res) => (this.closed = res))
        );
    }
  }
  getOpened() {
    return this.firestore
      .collection('chatIndicator', (ref) => ref.where('isClosed', '==', false))
      .snapshotChanges()
      .pipe(map((res) => res));
  }
  setChatIndicator(
    collectionName: string,
    docId: string,
    indicator: Partial<IndicatorMsg>
  ) {
    this.firestore.collection(collectionName).doc(docId).update(indicator);
  }
  sendMessage(collectionName: string, message: ChatMsg) {
    this.firestore.collection(collectionName).add(message);
  }
  //#endregion
}
