import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-snps-version',
  templateUrl: './snps-version.component.html',
  styleUrls: ['./snps-version.component.scss'],
})
export class SnpsVersionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
