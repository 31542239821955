import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelationRequest } from 'src/app/data/CancelationRequest';
import { Client } from 'src/app/data/Client';
import { Product } from 'src/app/data/Product';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { ProductsService } from 'src/app/Services/products.service';
import {
  CancellationRequestEnum,
  NoteCategoryEnum,
  PaymentMethod,
  DealEnum,
  AdressTypeEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Note } from 'src/app/data/Note';
import { RegularUser } from 'src/app/data/RegularUser';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { AgentsService } from 'src/app/Services/agents.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { Agent } from 'src/app/data/Agent';
import { TranzillaTransaction } from 'src/app/data/TranzillaTransaction';
import { WiredTransfer } from 'src/app/data/WiredTransfer';
import { TranzillaTransactionResponse } from 'src/app/data/TranzillaTransactionResponse';
import { DealHistory } from 'src/app/data/DealHistory';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { ManualCreditCard } from 'src/app/data/ManualCreditCard';
import { DealsService } from 'src/app/Services/deals.service';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { AgentMin } from 'src/app/data/AgentMin';
import { take } from 'rxjs/operators';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { plainToClass } from 'class-transformer';
import { Deal } from 'src/app/data/Deal';
import { DealBundle } from 'src/app/data/DealBundle';

@Component({
  selector: 'app-edit-refund',
  templateUrl: './edit-refund.component.html',
  styleUrls: ['./edit-refund.component.scss'],
})
export class EditRefundComponent implements OnInit {
  @ViewChild('refundFormRef') refundFormRef: FormGroupDirective;

  agents: AgentMin[];
  refund: CancelationRequest;
  client: Client;
  product: Product;
  noteToEdit;
  isNoteEditMode: boolean = false;
  refundForm: FormGroup;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  refundsNotes: NoteForCallMin[];
  agentUser: RegularUser;
  srcResult;
  refundMethod: string;

  uploadInvoiceError: boolean;
  phoneSettings: PhoneVerificationSetting;
  originDealsBundle: DealBundle;
  cancelDealsBundle: DealBundle;
  // fileUploads: any[];
  //fileUploadId: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cancellationService: CancelationRequestService,
    private productsService: ProductsService,
    private agentService: AgentsService,
    private notesPusherService: NotesPusherService,
    private regularUsersService: RegularUsersService,
    private datepipe: DatePipe,
    private popupHandlerService: PopupHandlerService,
    private dealsService: DealsService,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService
  ) {}

  ngOnInit(): void {
    var cancelRequestId =
      this.route.snapshot.queryParamMap.get('cancelRequestId');
    //GET LOGGED USER
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
    //LISTEN TO NOTES PUSHER
    this.notesPusherService.notes$.subscribe((data) => {
      this.refundsNotes = data.sort((a, b) =>
        a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime() ? 1 : -1
      );
    });
    //GET AGENTS
    this.agentService.getAll().subscribe((data) => {
      this.agents = data.filter((agent: AgentMin) => {
        return (
          agent.roleTypeId == UserRoleEmum.Finance ||
          agent.roleTypeId == UserRoleEmum.Manager ||
          agent.roleTypeId == UserRoleEmum.Developer
        );
      });
    });
    this.cancellationService.getById(cancelRequestId).subscribe((data) => {
      this.refund = data;
      this.client = data.originalDealBundle.client;
      this.originDealsBundle =
        this.refund.originalDealBundle.deals && this.refund.originalDealBundle; //.deals[0];
      this.cancelDealsBundle =
        this.refund.cancelDealBundle.deals && this.refund.cancelDealBundle; //.deals[0];

      this.createForm();
      this.disableForm();
      this.getNotes(this.refund.cancelationRequestId);
    });
  }
  goBackToCancelations() {
    this.router.navigate(['cancelations']);
  }
  getPhoneSettings() {
    var regularUser = plainToClass(RegularUser, this.client.regularUser);
    var address = regularUser.getAdressObj(AdressTypeEnum.Main);

    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
      });
  }
  getUserPhone() {
    var regularUser = plainToClass(RegularUser, this.client.regularUser);
    return regularUser.getUserPhone();
  }
  downloadInvoice() {
    var cancelDealId = this.refund.cancelDealBundle.deals[0].dealId;
    var url = this.dealsService.downloadInovice(cancelDealId);
    window.open(url, '_blank');
  }
  createForm() {
    var date = new Date().toLocaleDateString();
    var actualRefundDateObj = new Date(
      Date.parse(this.refund.actualRefundDate)
    );
    var dateUpdatedObj = new Date(
      Date.parse(this.refund.cancelDealBundle.deals[0].dateUpdated)
    );
    this.refundForm = new FormGroup({
      accountant: new FormControl(
        this.refund.accountantAgentId,
        Validators.required
      ),
      paymentMethod: new FormControl(
        {
          value: this.originDealsBundle?.deals[0].paymentMethod.name,
          disabled: true,
        },
        Validators.required
      ),

      refundMethodId: new FormControl(
        this.cancelDealsBundle?.deals[0].paymentMethodId
          ? this.cancelDealsBundle?.deals[0].paymentMethodId.toString()
          : null,
        Validators.required
      ),
      digits: new FormControl(
        this.cancelDealsBundle?.deals[0].manualCreditCard &&
        this.cancelDealsBundle?.deals[0].manualCreditCard.last4Digits
          ? this.cancelDealsBundle?.deals[0].manualCreditCard.last4Digits
          : ''
      ),
      validity: new FormControl(
        this.cancelDealsBundle?.deals[0].manualCreditCard &&
        this.cancelDealsBundle?.deals[0].manualCreditCard.validity
          ? this.cancelDealsBundle?.deals[0].manualCreditCard.validity
          : ''
      ),

      bankName: new FormControl(
        this.cancelDealsBundle?.deals[0].wiredTransfer &&
          this.cancelDealsBundle?.deals[0].wiredTransfer.bankName
      ),
      branchNumber: new FormControl(
        this.cancelDealsBundle?.deals[0].wiredTransfer &&
          this.cancelDealsBundle?.deals[0].wiredTransfer.branchNumber
      ),
      accountNumber: new FormControl(
        this.cancelDealsBundle?.deals[0].wiredTransfer &&
          this.cancelDealsBundle?.deals[0].wiredTransfer.accountNumber
      ),

      amount: new FormControl(
        this.refund.amount,

        Validators.compose([
          Validators.required,
          EditRefundComponent.positiveNumberValidator,
        ])
      ),
      dateUpdated: new FormControl(
        this.cancelDealsBundle?.deals[0].dateUpdated &&
        dateUpdatedObj.getFullYear() > 2000
          ? dateUpdatedObj
          : null,
        Validators.required
      ),

      confirmationNumber: new FormControl(
        this.cancelDealsBundle?.deals[0].confirmationNumber
      ),
      actualRefundDate: new FormControl(
        this.refund.actualRefundDate && actualRefundDateObj.getFullYear() > 2000
          ? actualRefundDateObj
          : null
      ),
    });
  }
  onChangeMethod(e) {
    var value = Number(e.value);
    if (value == PaymentMethod.CreditCard) this.refundMethod = 'card';
    else if (value == PaymentMethod.WiredTransfer) this.refundMethod = 'wire';
  }
  onUploadFinished(e) {
    if (e) {
      this.refund.cancelDealBundle.deals[0].fileUploads = [];
      this.refund.cancelDealBundle.deals[0]?.fileUploads.push(new Object());
    }
  }
  //SUBMIT FORM
  submitFormProgrammatically(forceSave: boolean = false) {
    // if (!forceSave)
    this.refundFormRef.onSubmit(undefined);
    if (this.refundForm.valid || forceSave) {
      this.refund.accountantAgentId = this.refundForm.value.accountant;
      this.refund.cancelDealBundle.deals[0].paymentMethodId =
        +this.refundForm.value.refundMethodId;
      if (
        this.refund.cancelDealBundle.deals[0].paymentMethodId ==
        PaymentMethod.CreditCard
      ) {
        if (!this.refund.cancelDealBundle.deals[0].manualCreditCard)
          this.refund.cancelDealBundle.deals[0].manualCreditCard =
            new ManualCreditCard();
        this.refund.cancelDealBundle.deals[0].manualCreditCard.dealId =
          this.refund.cancelDealBundle.deals[0].dealId;
        this.refund.cancelDealBundle.deals[0].manualCreditCard.last4Digits =
          this.refundForm.value.digits;
        this.refund.cancelDealBundle.deals[0].manualCreditCard.validity =
          this.refundForm.value.validity;
      } else if (
        this.refund.cancelDealBundle.deals[0].paymentMethodId ==
        PaymentMethod.WiredTransfer
      ) {
        if (!this.refund.cancelDealBundle.deals[0].wiredTransfer)
          this.refund.cancelDealBundle.deals[0].wiredTransfer =
            new WiredTransfer();
        this.refund.cancelDealBundle.deals[0].wiredTransfer.dealId =
          this.refund.cancelDealBundle.deals[0].dealId;
        this.refund.cancelDealBundle.deals[0].wiredTransfer.bankName =
          this.refundForm.value.bankName;
        this.refund.cancelDealBundle.deals[0].wiredTransfer.branchNumber =
          this.refundForm.value.branchNumber;
        this.refund.cancelDealBundle.deals[0].wiredTransfer.accountNumber =
          this.refundForm.value.accountNumber;
      }
      this.refund.amount = this.refundForm.value.amount;
      this.refund.cancelDealBundle.deals[0].amount =
        -this.refundForm.value.amount;

      if (this.refundForm.value.actualRefundDate) {
        if (this.refundForm.value.dateUpdated instanceof moment) {
          this.refund.actualRefundDate = this.datepipe.transform(
            this.refundForm.value.dateUpdated.toDate(),
            'yyyy-MM-dd hh:mm'
          );
        } else {
          this.refund.actualRefundDate = this.datepipe.transform(
            this.refundForm.value.dateUpdated,
            'yyyy-MM-dd hh:mm'
          );
        }
      }
      this.refund.cancelDealBundle.deals[0].confirmationNumber =
        this.refundForm.value.confirmationNumber;
      if (forceSave) {
        this.saveRefund();
      } else {
        this.closeRefund();
      }
    } else {
      const invalid = [];
      const controls = this.refundForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a1 = invalid;
    }
  }
  saveRefund() {
    //  if (!this.refund.cancelDeal.dateUpdated)
    //    this.refund.updateDate = new Date().toDateString();
    this.cancellationService.saveRefund(this.refund).subscribe((data) => {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.refundSaved
      );
    });
  }
  closeRefund() {
    this.cancellationService.putRefund(this.refund).subscribe((data) => {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.refundSaved
      );
      this.refund = data as CancelationRequest;
      this.createForm();
      setTimeout(() => {
        this.disableForm();
      }, 100);
    });
  }

  goToClient() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/clients/edit/'], {
        queryParams: { clientId: this.client.clientId },
      })
    );
    window.open(url, '_blank');
  }

  getFullName(user: RegularUser) {
    if (!user.lastName) user.firstName;

    return user.firstName + ' ' + user.lastName;
  }

  //NOTES
  getNotes(id) {
    this.notesPusherService.setMode('cancelationRequest');
    this.notesPusherService.getAllNotes(id);
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
    this.notesPusherService.getAllNotes();
  }
  createNewNote(note) {
    this.noteToEdit = note;
    this.isNoteEditMode = true;
  }
  saveNote(note: Note) {
    note.cancelationRequestId = this.refund.cancelationRequestId;
    note.clientId = this.refund.originalDealBundle.clientId;
    note.noteCategoryId = NoteCategoryEnum.refund;
    note.assigningAgentId = this.agentUser.agent.agentId;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      if (this.refund.cancelationRequestId) this.notesPusherService.post(note);
    }

    this.isNoteEditMode = false;
  }
  onReloadNotes() {
    this.notesPusherService.getAllNotes(this.refund.cancelationRequestId);
  }
  //FILE UPLOAD
  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };

      this.refundForm.controls['creditInvoice'].setValue(
        reader.readAsArrayBuffer(inputNode.files[0])
      );
    }
  }
  onReturnToManager() {
    this.popupHandlerService.openConfirmationDialog('', 'Cancel Refund');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((_data) => {
        if (_data.ans === 'yes') {
          this.refund.statusId = CancellationRequestEnum.ManagerApprove;
          this.cancellationService.putRefund(this.refund).subscribe((data) => {
            this.router.navigate(['/cancelations/manager'], {
              queryParams: {
                cancelRequestId: this.refund.cancelationRequestId,
              },
            });
          });
        }
      });
  }
  onCloseRefund() {
    if (
      this.refundForm.valid &&
      this.refund.cancelDealBundle.deals[0].fileUploads &&
      this.refund.cancelDealBundle.deals[0].fileUploads.length !== 0
    ) {
      this.refund.statusId = CancellationRequestEnum.RefundClosed;
      this.submitFormProgrammatically();
    } else if (
      !this.refund.cancelDealBundle.deals[0].fileUploads ||
      this.refund.cancelDealBundle.deals[0].fileUploads.length == 0
    ) {
      //this.uploadInvoice = false;
      this.uploadInvoiceError = true;
    } else {
      const invalid = [];
      const controls = this.refundForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a1 = invalid;
    }
  }
  showCloseRefundBtn() {
    return (
      this.refund.statusId != CancellationRequestEnum.Closed &&
      this.refund.statusId != CancellationRequestEnum.RefundClosed
    );
  }
  isClosed() {
    return (
      this.refund.statusId == CancellationRequestEnum.Closed ||
      this.refund.statusId == CancellationRequestEnum.RefundClosed
    );
  }
  disableForm() {
    if (this.refund.statusId == CancellationRequestEnum.RefundClosed)
      this.refundForm.disable();
  }
  static positiveNumberValidator(control): { [key: string]: any } {
    if (Number(control.value) < 0) {
      return { nonZero: true };
    } else {
      return null;
    }
  }
  showSaveBtn() {
    return this.refund.statusId != CancellationRequestEnum.RefundClosed;
  }
}
