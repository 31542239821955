import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeneralAgent } from 'src/app/data/chat/GeneralAgent';
import { ChatFilterResult } from 'src/app/data/food-genes/ChatFilterResult';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { replaceWebPath } from 'src/app/Utils/files-helper';

@Component({
  selector: 'app-filtered-results',
  templateUrl: './filtered-results.component.html',
  styleUrls: ['./filtered-results.component.scss'],
})
export class FilteredResultsComponent implements OnInit {
  filterResults: ChatFilterResult[];
  selectedResult: ChatFilterResult;
  @Input() agent: GeneralAgent;
  @Output() selectedRow: EventEmitter<number> = new EventEmitter();
  constructor(private firebaseService: FirebaseChatIndicatorService) {}

  ngOnInit(): void {
    this.firebaseService.chatFilterResult$.subscribe((data) => {
      if (data) {
        this.filterResults = data;
      }
    });
  }
  onRowClicked(item: ChatFilterResult) {
    this.selectedResult = item;
    this.selectedRow.emit(item.chatRoomId);
  }

  getAvatarImage(item: ChatFilterResult) {
    let imagePath = '';
    if (item.userAvatar) {
      imagePath = replaceWebPath(item.userAvatar);
    } else {
      imagePath = '../../assets/man.svg';
    }
    return imagePath;
  }
}
