import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CategoryForFood } from 'src/app/data/CategoryForFood';

@Component({
  selector: 'app-autocomplete-food-categories',
  templateUrl: './autocomplete-food-categories.component.html',
  styleUrls: ['./autocomplete-food-categories.component.scss'],
})
export class AutocompleteFoodCategoriesComponent implements OnInit, OnChanges {
  @ViewChild('value') valueInput: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @Input() data: any[];
  @Input() selectedCats: CategoryForFood[];
  @Input() catIdToRemove: number;
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  filteredValuesOptions: any[];
  selection = new SelectionModel<number>(true, []);
  selected: number[];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.catIdToRemove && this.catIdToRemove) {
      this.data?.forEach((cat) => {
        if (cat.id == this.catIdToRemove) {
          this.selection.deselect(cat.id);
        }
      });
    }
  }
  ngOnInit(): void {
    this.selection.changed.subscribe((value) => {
      this.selected = value.source.selected;
      this.selectedValue.emit(this.selected);
    });
    this.selectedCats?.forEach((cat) => {
      this.selection.select(cat.foodCategoryId);
    });
  }
  onChangeSelection(e, id) {
    return e ? this.selection.toggle(id) : null;
  }
  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;

    var valuesDup = [...this.data];
    this.filteredValuesOptions = valuesDup.filter((val) => {
      return val.name.indexOf(search) != -1;
    });
  }
  focusInput() {
    this.inputField.nativeElement.focus();
  }
  onValueSelected(selectedValue: number) {
    this.selectedValue.emit(selectedValue);
  }
}
