<div class="wrapper">
  <div class="patient-details">
    <div>
      <strong>Full Name: </strong>
      <span>{{ data?.fullName }}</span>
    </div>
    <div>
      <strong>Patient ID: </strong>
      <span>{{ data?.socialSecurityNum }}</span>
    </div>
  </div>
  <div class="table">
    <div class="table__title">
      Weight {{ data.isGoal ? "Goal" : "" }} History
    </div>
    <div class="table__header">
      <div>Weight</div>
      <div>Updated date</div>
    </div>
    <div class="table__body">
      <div *ngFor="let item of data?.list" class="table__body__row">
        <div>{{ item.value }} <small>kg</small></div>
        <div>{{ item.dateUpdated | date: "dd-MM-yyyy" }}</div>
      </div>
    </div>
  </div>
  <div class="button-wrapper">
    <button mat-button (click)="dialogRef.close()">Close</button>
  </div>
</div>
