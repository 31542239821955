<div class="chat-messages">
  <div class="chat-messages__header">
    <ng-container *ngIf="sessions">
      <div class="chat-messages__header__img">
        <img [src]="getAvatarImage()" alt="avatar" />
      </div>
      <div>
        <div class="chat-messages__header__name">
          {{ sessions[0].conversationUserName }}
        </div>
        <div class="chat-messages__header__typing">
          {{ chatRoom.isTyping ? "Typing..." : "" }}
        </div>
      </div>
      <button
        class="chat-messages__header__close-request"
        mat-raised-button
        (click)="closeSession()"
        *ngIf="!isSessionClosed && isSessionRelatedToAgent"
      >
        Close seesion
      </button>
    </ng-container>
  </div>
  <div
    class="chat-messages__board"
    *ngIf="sessions"
    [style.pointer-events]="(loading$ | async) ? 'none' : 'auto'"
  >
    <div
      *ngIf="loading$ | async"
      class="loading"
      style="
        height: calc(100vh - 189px - 4.7rem);
        position: absolute;
        width: 100%;
      "
    >
      <mat-spinner
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 99999;
        "
      ></mat-spinner>
    </div>
    <perfect-scrollbar
      #perfectScroll
      [config]="psConfig"
      [scrollIndicators]="true"
      style="height: calc(100vh - 189px - 4.7rem); padding: 0 50px"
      [style.opacity]="(loading$ | async) ? 0.5 : 1"
    >
      <div *ngFor="let session of sessions">
        <div class="date-log">
          <span class="date-log__text">
            {{ session.createdDate | date : "dd/MM/yyyy" }}
          </span>
        </div>
        <div
          class="chat-messages__board__message"
          *ngFor="let message of session.messages; trackBy: trackByFn"
          [ngStyle]="{ direction: message.directionToUser ? 'ltr' : 'rtl' }"
        >
          <app-main-chat-bubble
            *ngIf="showMessage(message)"
            [message]="message"
            [langId]="langId"
          ></app-main-chat-bubble>
        </div>
      </div>
      <div style="height: 50px"></div>
    </perfect-scrollbar>
  </div>
  <ng-container *ngIf="!isSessionClosed">
    <ng-container *ngIf="sessions">
      <app-chat-input
        *ngIf="isSessionRelatedToAgent && !isNewSession"
        [resetInput]="resetInput"
        (resetInputEvent)="resetInput = $event"
        (sendMessageEvent)="sendMessage($event)"
        (uploadImageEvent)="uploadImg($event)"
        (onStartTypingEvent)="setAgentTyping($event)"
      ></app-chat-input>
      <div
        *ngIf="!isSessionRelatedToAgent && !isNewSession"
        class="agent-interseption"
      >
        <button mat-button (click)="takeOver()">
          <span>Take over the chat</span>
        </button>
      </div>
    </ng-container>
    <div *ngIf="isNewSession" class="agent-interseption">
      <button mat-button (click)="startChat()">
        <span>Start chat</span>
      </button>
    </div>
  </ng-container>
</div>
