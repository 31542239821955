import { ChatAttachment } from './ChatAttachment';
import { ChatTemplateSended } from './ChatTemplateSended';

export class ChatMessage {
  chatMessageId: number;
  sessionId: number;
  statusId: number;
  context: string;
  templateId: number | null;
  templateSegmentId: number | null;
  userId: number;
  agentId: number | null;
  directionToUser: boolean;
  departmentId: number | null;
  createdDate: string;
  attachments: ChatAttachment[];
  template: ChatTemplateSended;
  senderAvatar: string;
  senderName: string;
  senderDescription: string;
}
