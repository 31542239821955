<mat-card class="foods">
  <div class="wrapper-filters">
    <div class="d-flex">
      <div class="foods__title">
        <ng-container *ngIf="isRecipe; else isFood">
          <mat-icon>restaurant_menu</mat-icon>
          View Recipes
        </ng-container>
        <ng-template #isFood>
          <mat-icon>fastfood</mat-icon>
          View Foods
        </ng-template>
      </div>
      <div class="foods__filters">
        <div class="foods__filters__search">
          <mat-form-field appearance="fill">
            <input
              [(ngModel)]="searchTerm"
              #search
              (keyup)="onSearch($event)"
              matInput
              placeholder="Search"
            />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
        <div class="foods__filters__trans">
          <button
            [disabled]="isEmpty()"
            mat-icon-button
            (click)="openTranslations()"
          >
            <mat-icon>translate</mat-icon>
          </button>
        </div>
        <div class="foods__filters__lang">
          <mat-form-field appearance="fill">
            <mat-icon matPrefix
              ><img
                style="width: 18px"
                src="../../../../assets/flags/{{ selectedLang }}.png"
                alt=""
            /></mat-icon>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

            <mat-select
              [(ngModel)]="selectedLang"
              (selectionChange)="onSelectionChange()"
              disableOptionCentering
              panelClass="lang-select"
            >
              <mat-option *ngFor="let lang of langs" [value]="lang.languageId">
                <div style="display: flex; align-items: center">
                  <img
                    style="width: 15px; margin-right: 1rem"
                    src="../../../../assets/flags/{{ lang.languageId }}.png"
                    alt=""
                  />
                  {{ lang.name }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="foods__filters__source">
          <mat-form-field appearance="fill" [matTooltip]="sourceKeysLabel">
            <!-- <mat-label>Source</mat-label> -->
            <mat-icon matPrefix>filter_list</mat-icon>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            <mat-select
              (selectionChange)="onSelectionChange()"
              [(ngModel)]="selectedSource"
              multiple
            >
              <mat-option *ngFor="let item of sourceKeys" [value]="item.id">{{
                item.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="foods__filters__category">
          <app-food-categories-selection-panel
            *ngIf="foodCategories"
            [fromAllFoodComponent]="true"
            [fromPaginatorComponent]="false"
            [categories]="foodCategories"
            [selectedCat]="selectedCat"
            (selectCat)="onSelectCat($event)"
          ></app-food-categories-selection-panel>
        </div>
      </div>
    </div>
    <hr class="hr" />
  </div>
  <!-- [perfectScrollbar]="config" -->
  <perfect-scrollbar style="height: calc(100vh - 220px)">
    <div class="foods__table">
      <ngx-skeleton-loader
        *ngIf="isLoading"
        [theme]="{ height: '30px' }"
        count="15"
      ></ngx-skeleton-loader>
      <div style="position: relative" *ngIf="!isLoading">
        <app-food-table
          *ngIf="data"
          [data]="data.list"
          [isRecipe]="isRecipe"
          [sourceKey]="selectedSource"
          [checkedIds]="foodsCheckList"
          (checkedList)="onFoodCheckListChanged($event)"
          (getData)="getFoodsForTable()"
        ></app-food-table>
      </div>
    </div>
  </perfect-scrollbar>
  <app-table-paginator
    *ngIf="data"
    [isRecipe]="isRecipe"
    [langId]="selectedLang"
    [sticky]="true"
    [metaData]="data?.metaData"
    [classAttr]="'foods'"
    [headFilter]="headFilter"
    [checkedIds]="foodsCheckList"
    [foodCategories]="foodCategories"
    (filterChanged)="getPaginatorData($event)"
    (checkedIdsChanged)="onFoodCheckListChanged($event)"
  >
  </app-table-paginator>
</mat-card>
