import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ChatMsg } from 'src/app/data/chat-msg';
import { IndicatorMsg } from 'src/app/data/indicator-msg';
import { ServiceRequestSubjectEnum } from 'src/app/Enums/StatusesEnum';
import { padWithZeros } from 'src/app/Utils/dateUtils';

@Component({
  selector: 'app-chat-list-data',
  templateUrl: './chat-list-data.component.html',
  styleUrls: ['./chat-list-data.component.scss'],
})
export class ChatListDataComponent implements OnInit {
  @Input() chatList: IndicatorMsg;
  @Output('rowClick') rowClick: EventEmitter<IndicatorMsg> = new EventEmitter();

  row: any;
  constructor() {}

  ngOnInit(): void {}
  onRowClick(item: IndicatorMsg) {
    this.rowClick.emit(item);
  }

  getIsSeen(chat: IndicatorMsg) {
    if (chat.sentFrom == 2) {
      if (!chat.seenBy.includes(1)) {
        // not seen
        return '<img src="../../../../assets/v-not-seen.svg" alt="" />';
      } else {
        return '<img src="../../../../assets/vv.svg" alt="" />';
      }
    }
  }

  getLatestDate(date) {
    var now = moment(new Date(), 'DD/MM/YYYY HH:mm:ss');
    var dateMsg = moment(date, 'DD/MM/YYYY HH:mm:ss');
    var createdDate = moment(date, 'DD/MM/YYYY HH:mm:ss');

    if (now.isAfter(dateMsg, 'D')) {
      var formatDate = createdDate.format('DD/MM/YYYY');
      var formatTime = createdDate.format('HH:mm');
      return `<div>${formatDate}</div><div>${formatTime}</div>`;
    } else {
      var hours = createdDate.hours();
      var minutes = createdDate.minutes();
      return `${hours}:${padWithZeros(minutes, 2)}`;
    }
  }

  truncateTxt(txt, el: HTMLElement) {
    return txt.length > 35 ? `${txt.substring(0, 35)}...` : txt;
  }

  getSubjectFromEnum(subjectId) {
    switch (subjectId) {
      case ServiceRequestSubjectEnum.CustomerService:
      case ServiceRequestSubjectEnum.UserNameOrPassword:
        return {
          letter: 'S',
          color: '#D3247E',
        };
      case ServiceRequestSubjectEnum.Finance:
        return {
          letter: 'F',
          color: '#8700D4',
        };
      case ServiceRequestSubjectEnum.Logistics:
        return {
          letter: 'L',
          color: '#FF6F00',
        };
      case ServiceRequestSubjectEnum.Nutrition:
        return {
          letter: 'N',
          color: '#00AEEE',
        };
    }
  }
}
