import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { LeadSource } from '../data/LeadSource';
import { environment } from 'src/environments/environment';
import { LeadStatus } from '../data/LeadStatus';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LeadSourcesService {
  sources: LeadSource[];
  constructor(private http: HttpClient) {}

  getAll() {
    if (this.sources) {
      return of(this.sources); // return from cache
    } else {
      return this.http
        .get<LeadSource[]>(`${environment.baseUrl}LeadSources/`)
        .pipe(map((res) => (this.sources = res)));
    }
  }
  /*

   // return this.http.get<LeadSource[]>(`${environment.baseUrl}LeadSources/`);
  }
  getById(agentId) {
    return this.http.get<LeadSource>(
      `${environment.baseUrl}LeadSources/${agentId}`
    );
  }
  put(agent) {
    return this.http.put(
      `${environment.baseUrl}LeadSources/${agent.agentId}`,
      agent
    );
  }
  post(agent) {
    return this.http.post<LeadSource>(`${environment.baseUrl}LeadSources/`, agent);
  }
  delete(agent) {
    return this.http.delete(`${environment.baseUrl}LeadSources/${agent.agentId}`);
  }
  */
}
