import { Component, OnInit } from '@angular/core';
import { AgentsService } from '../../../Services/agents.service';
import { Router } from '@angular/router';
import { RoleTypesService } from 'src/app/Services/role-types.service';
import { RoleType } from 'src/app/data/RoleType';
import { ManageAgentsService } from 'src/app/Services/manage-agents.service';
import { UserRole } from 'src/app/data/UserRole';
import { UserRolesService } from 'src/app/Services/user-roles.service';
import { NameAndId } from 'src/app/data/NameAndId';

@Component({
  selector: 'app-all-agents',
  templateUrl: './all-agents.component.html',
  styleUrls: ['./all-agents.component.css'],
})
export class AllAgentsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  agentsData;
  //roleTypes: RoleType[];
  userRoles: NameAndId[];
  selectedRoleTypeId = -1;
  constructor(
    private agentsService: ManageAgentsService,
    private router: Router,
    private roleTypesService: RoleTypesService,
    private userRolesService: UserRolesService
  ) {}
  ngOnInit(): void {
    this.userRolesService.getMin().subscribe((data) => {
      this.userRoles = data;
    });
    /*
    this.roleTypesService.getAll().subscribe((data) => {
      this.roleTypes = data;
    });
    */
    this.getAgents();
  }
  getAgents() {
    this.agentsService
      .getbyFilter(this.selectedRoleTypeId)
      .subscribe((data) => {
        this.agentsData = data;
      });
  }
  addNewAgent() {
    this.router.navigate(['/agents/edit'], { queryParams: { id: 0 } });
  }
  getAllWithFilter(e) {}
  onRoleFilterChange(e) {
    this.getAgents();
  }
  metaData;
  headFilter;
}
