import { Injectable } from '@angular/core';
import { ExternalDeal } from '../data/ExternalDeal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalDealsService {

  constructor(private http: HttpClient) { }
  post(deal: ExternalDeal) {
    return this.http.post<any>(`${environment.baseUrl}ExternalDeal/`, deal);
  }
  createDeal(deal) {
    return this.http.post<any>(`${environment.baseUrl}ExternalDeal/CreateDeal`, deal);
  }
  TransactionResponse(deal) {
    return this.http.post<any>(`${environment.baseUrl}ExternalDeal/TransactionResponse`, deal);
  }
}
