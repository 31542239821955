<mat-card class="add-cat" (click)="stopPropagation($event)">
  <div class="title">
    קטגוריה
    <ng-container *ngIf="addState === 'addFromInput'">חדשה</ng-container>
    <ng-container *ngIf="addState === 'addFromList'">מתוך רשימה</ng-container>
  </div>
  <div class="body">
    <div
      *ngIf="!addState"
      fxLayout="row"
      fxLayoutAlign="center"
      fxLayoutGap="10px"
      class="from-actions"
    >
      <button class="input" mat-button (click)="chooseState('addFromInput')">
        חדשה
      </button>
      <button class="list" mat-button (click)="chooseState('addFromList')">
        מתוך רשימה
      </button>
    </div>
    <form #catForm="ngForm">
      <ng-container *ngIf="addState">
        <mat-form-field
          *ngIf="addState === 'addFromInput'"
          appearance="fill"
          class="field"
        >
          <input
            matInput
            type="text"
            placeholder="הכנס שם קטגוריה"
            [(ngModel)]="category.name"
            name="nameInput"
            required
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="addState === 'addFromList'"
          appearance="fill"
          class="field"
        >
          <mat-select
            placeholder="בחר קטגוריה"
            [(ngModel)]="category.prototypeCategoryId"
            (ngModelChange)="onCatChange($event)"
            required
            name="prototypeCategoryId"
          >
            <mat-option
              *ngFor="let category of categories"
              [value]="category.categoryId"
            >
              {{ category.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="mb-1_5"></div>
        <div>
          <input
            type="color"
            required
            name="color"
            [(ngModel)]="category.color"
          />
          &nbsp;
          <span>בחר צבע לקטגוריה</span>
        </div>
        <div class="mb-1_5"></div>
        <div>
          <mat-checkbox
            class="checkbox"
            name="isPremium"
            [(ngModel)]="category.isPremium"
            >פרימיום</mat-checkbox
          >
        </div>
        <div class="mb-1_5"></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field class="field" fxFlex="7" appearance="fill">
            <input
              matInput
              type="number"
              class="order"
              name="orderBy"
              required
              [(ngModel)]="category.orderBy"
            />
          </mat-form-field>
          <div class="label" fxFlex="93">מיקום הקטגוריה</div>
        </div>
        <div class="mb-1_5"></div>
        <hr />
        <div class="mb-1_5"></div>
        <div
          class="mb-2"
          fxLayout="row"
          fxLayoutAlign="center"
          fxLayoutGap="10px"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
            <div fxLayout="column" fxLayoutAlign="center" fxFlex="45">
              <strong>PDF אייקון קטן</strong>
              <app-algo-image
                [langId]="langId"
                [algoImageType]="AlgoImageType.AlgoCatSmallIcon"
                (onUploadFinished)="onUploadFinished($event)"
                [selectedImg]="getImage(AlgoImageType.AlgoCatSmallIcon)"
              ></app-algo-image>
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="center"
              fxFlex="45"
              style="margin-right: 20px"
            >
              <strong>DNA REPORT אייקון</strong>
              <app-algo-image
                [langId]="langId"
                [algoImageType]="AlgoImageType.AlgoCatDnaReport"
                (onUploadFinished)="onUploadFinished($event)"
                [selectedImg]="getImage(AlgoImageType.AlgoCatDnaReport)"
                [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatDnaReport)"
              ></app-algo-image>
            </div>
          </div>

          <div
            fxFlex="30"
            fxLayout="column"
            fxLayoutAlign="center"
            fxLayoutGap="15px"
            style="margin-right: 20px"
          >
            <div fxLayout="column" fxLayoutAlign="center">
              <strong>מסגרת חלק תחתון</strong>
              <app-algo-image
                isRectangle="true"
                textBox="מסגרת חלק תחתון"
                [langId]="langId"
                [algoImageType]="AlgoImageType.AlgoCatPdfBorderBottom"
                (onUploadFinished)="onUploadFinished($event)"
                [selectedImg]="getImage(AlgoImageType.AlgoCatPdfBorderBottom)"
                [checkedImgId]="
                  getCheckedImage(AlgoImageType.AlgoCatPdfBorderBottom)
                "
              ></app-algo-image>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">
              <strong>מסגרת חלק אמצע</strong>
              <app-algo-image
                isRectangle="true"
                textBox="מסגרת חלק אמצע"
                [langId]="langId"
                [algoImageType]="AlgoImageType.AlgoCatPdfBorderMiddle"
                (onUploadFinished)="onUploadFinished($event)"
                [algoImageType]="AlgoImageType.AlgoCatPdfBorderMiddle"
                [checkedImgId]="
                  getCheckedImage(AlgoImageType.AlgoCatPdfBorderMiddle)
                "
              ></app-algo-image>
            </div>
          </div>
        </div>
        <div class="mb-1_5"></div>
        <div fxLayout="row">
          <div
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="30"
            style="margin-left: 20px"
          >
            <strong>Desktop אייקון</strong>
            <app-algo-image
              [langId]="langId"
              [algoImageType]="AlgoImageType.AlgoCatBigIcon"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getImage(AlgoImageType.AlgoCatBigIcon)"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatBigIcon)"
            ></app-algo-image>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
            <strong>Desktop תמונת כותרת</strong>
            <app-algo-image
              isBigRectangle="true"
              [langId]="langId"
              textBox="תמונת כותרת"
              [selectedImg]="getImage(AlgoImageType.AlgoPageTitleImage)"
              (onUploadFinished)="onUploadFinished($event)"
              [algoImageType]="AlgoImageType.AlgoPageTitleImage"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoPageTitleImage)"
            ></app-algo-image>
          </div>
        </div>
        <div class="mb-1_5"></div>
        <div fxLayout="row">
          <div
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="30"
            style="margin-left: 20px"
          >
            <strong>App אייקון</strong>
            <app-algo-image
              [langId]="langId"
              [algoImageType]="AlgoImageType.AlgoAppCatIcon"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getImage(AlgoImageType.AlgoAppCatIcon)"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoAppCatIcon)"
            ></app-algo-image>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
            <strong>App תמונת כותרת</strong>
            <app-algo-image
              isBigRectangle="true"
              [langId]="langId"
              textBox="App תמונת כותרת"
              [selectedImg]="getImage(AlgoImageType.AlgoAppPageTitleImage)"
              (onUploadFinished)="onUploadFinished($event)"
              [algoImageType]="AlgoImageType.AlgoAppPageTitleImage"
              [checkedImgId]="
                getCheckedImage(AlgoImageType.AlgoAppPageTitleImage)
              "
            ></app-algo-image>
          </div>
        </div>
        <div
          class="actions"
          fxLayout="row"
          fxLayoutAlign="center"
          fxLayoutGap="10px"
        >
          <button class="save" (click)="save(catForm)" type="submit" mat-button>
            <span>שמירה</span>
          </button>
          <button
            class="cancel"
            (click)="closeMainBoard.emit(true)"
            type="button"
            mat-button
          >
            <span>ביטול</span>
          </button>
        </div>
      </ng-container>
    </form>
  </div>
</mat-card>
<!-- </mat-menu> -->
