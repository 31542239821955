import { Component, OnInit } from '@angular/core';
import { DealEnum } from 'src/app/Enums/StatusesEnum';
import { DeliveryResponsesService } from 'src/app/Services/delivery-responses.service';
import { ActivatedRoute } from '@angular/router';
import { DeliveryResponse } from 'src/app/data/DeliveryResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-deliveries-responses',
  templateUrl: './all-deliveries-responses.component.html',
  styleUrls: ['./all-deliveries-responses.component.css'],
})
export class AllDeliveriesResponsesComponent implements OnInit {
  deliveryResponses: DeliveryResponse;

  constructor(
    private deliveryResponsesService: DeliveryResponsesService,
    private route: ActivatedRoute
  ) {}
  displayedColumns: string[] = [
    'patientId',
    'patientName',
    'deliveryType',
    //'patientStatus',
    'createDate',
    'responseCode',
    'response',
  ];
  ngOnInit(): void {
    var deliveryId = this.route.snapshot.queryParamMap.get('deliveryId');
    if (!deliveryId) {
      this.deliveryResponsesService
        .getAll()
        .subscribe((data) => (this.deliveryResponses = data));
    } else {
      this.deliveryResponsesService
        .getByPatientDeliveryId(+deliveryId)
        .subscribe((data) => (this.deliveryResponses = data));
    }
  }
  goToPatient(respose) {}
}
