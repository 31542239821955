<div class="snap-slider">
  <div class="snap-slider__values">
    <div>1</div>
    <div>2</div>
    <div>3</div>
    <div>4</div>
    <div>5</div>
  </div>
  <mat-slider
    [disabled]="isPreview"
    [max]="5"
    [min]="1"
    [step]="1"
    [thumbLabel]="false"
    [(ngModel)]="value"
    aria-labelledby="example-name-label"
    [invert]="true"
    (change)="onSliderChange($event)"
  ></mat-slider>
  <div class="snap-slider__desc">
    <div>לא רעב</div>
    <div>רעב מאד</div>
  </div>
</div>
