<div class="role">
  <div class="role__title">Add new role</div>
  <div class="role__label">Role name</div>
  <input type="text" placeholder="Insert role name" [(ngModel)]="roleName" />
  <div class="role__actions">
    <button
      class="save"
      mat-button
      (click)="save()"
      [disabled]="!roleName || roleName == ''"
    >
      Save
    </button>
    <button class="cancel" mat-button mat-dialog-close>Cancel</button>
  </div>
</div>
