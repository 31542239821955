import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModuleAction } from '../data/ModuleAction';

@Injectable({
  providedIn: 'root',
})
export class ModuleActionsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<ModuleAction[]>(
      `${environment.baseUrl}ModuleActions/`
    );
  }
}
