<h1>Make New Follow</h1>
<div style="display:flex">
 <mat-select>
     <option>
        7 Days
     </option>
     <option>
        10 Days
     </option>
     <option>
       14 Days
     </option>
     <option>
       other
     </option>
    </mat-select>
    <div style="margin-left: 0; margin-right: 20px" class="datepicker">
        <mat-form-field>
          <input
            (click)="triggerToggleClick()"
            [value]="date"
            formControlName="date"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            id="toggle"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      
      </div>
    <div>
      <input type="time">
    </div>
</div>
<div>
<button (click)="onDoneClicked()">
    Done
</button>
<button (click)="onCancelClicked()">
    Done
</button>
</div>
