import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSnp } from 'src/app/data/algorithm/AlgoSnp';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { PrototypeSnp } from 'src/app/data/algorithm/PrototypeSnp';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-sidenav-snp',
  templateUrl: './sidenav-snp.component.html',
  styleUrls: ['./sidenav-snp.component.scss'],
})
export class SideNavSnpComponent implements OnInit, OnChanges {
  //#region Properties
  @Input() algoSnps: AlgoSnp[];
  @Input() langId: number;
  @Input() selectedSubCategory: AlgoSubCategory;
  @Input() selectedCategory: AlgoCategory;
  @Input() sidenavSnp: MatSidenav;
  @Input() selectedSnp: AlgoSnp;
  @Input() canEdit: boolean;
  @Input() availableCatSnps: PrototypeSnp[];
  @Output('selectSnp') selectSnp: EventEmitter<AlgoSnp> = new EventEmitter();
  @Output('selectSummary') selectSummary: EventEmitter<boolean> =
    new EventEmitter();
  @Output('closeMainBoard') closeMainBoard: EventEmitter<boolean> =
    new EventEmitter();
  @Output('loadSnps') loadSnps: EventEmitter<boolean> = new EventEmitter();
  isSummarySelected: boolean;
  //#endregion
  constructor(
    private algorithmService: AlgorithmService,
    private popupHandlerService: PopupHandlerService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSummarySelected?.currentValue) {
      this.selectSummary.emit(true);
    }
  }
  ngOnInit(): void {
    this.sidenavSnp.openedChange.subscribe((opened) => {
      if (!opened) {
        this.selectedSnp = null;
        this.isSummarySelected = false;
        this.selectSnp.emit(null);
      } else {
        if (!this.selectedSnp) {
          this.isSummarySelected = true;
          this.selectSummary.emit(true);
        }
      }
    });
  }
  stopPropagation(e: any) {
    e.stopPropagation();
  }
  removeSnp(snp: AlgoSnp, e: any) {
    this.stopPropagation(e);
    this.popupHandlerService.openConfirmationDialog('', 'Delete Snp');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes') {
          this.algorithmService
            .removeSubCatSnp(
              snp.algoSnpNum,
              this.selectedSubCategory.subCategoryId
            )
            .subscribe((res) => {
              if ((res as ErrorMessage)?.message) {
                this._snackBar.open((res as ErrorMessage).message, '', {
                  duration: 4000,
                });
              } else {
                this.loadSnps.emit(true);
                if (this.selectedSnp?.algoSnpNum === snp.algoSnpNum) {
                  this.selectedSnp = null;
                  this.selectSnp.emit(null);
                }
              }
            });
        }
      });
  }
  addSnp() {
    this.popupHandlerService.openAddNewCatSnp(this.availableCatSnps);
    this.popupHandlerService.addNewCatSnpSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.type === 'add') {
          this.algorithmService
            .addSnpToSubCat(
              data.fgSnp.snpNumber,
              this.selectedSubCategory.subCategoryId,
              this.langId,
              data.firstLetter,
              data.secondLetter
            )
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._snackBar.open((data as ErrorMessage).message, '', {
                  duration: 4000,
                });
              } else {
                this.loadSnps.emit(true);
                this._snackBar.open('Snp added successfully', 'Close', {
                  duration: 2000,
                  panelClass: 'added-success',
                });
              }
            });
        }
      });
  }
  onSelectSnp(snp: AlgoSnp) {
    if (this.selectedSnp?.algoSnpNum === snp.algoSnpNum) {
      this.selectedSnp = null;
      this.closeMainBoard.emit(true);
    } else {
      this.selectedSnp = snp;
      this.isSummarySelected = false;
      this.selectSnp.emit(this.selectedSnp);
    }
  }
  onSelectSummary() {
    this.selectedSnp = null;
    this.isSummarySelected = true;
    this.selectSnp.emit(null);
    this.selectSummary.emit(true);
  }
}
