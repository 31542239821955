import { Component, OnInit } from '@angular/core';
import { TubePackagesPusherService } from 'src/app/Services/tube-packages-pusher.service';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { TubePackageEnum, ProductEnum } from 'src/app/Enums/StatusesEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';

@Component({
  selector: 'app-all-tube-packages-for-lab',
  templateUrl: './all-tube-packages-for-lab.component.html',
  styleUrls: ['./all-tube-packages-for-lab.component.css'],
})
export class AllTubePackagesForLabComponent implements OnInit {
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  tubesPackages: TubesPackage[];
  constructor(private tubesPackagesService: TubePackagesPusherService,
    private filterMemoryService: FilterMemoryService,) {}
  ngOnInit(): void {
    this.headFilter = this.filterMemoryService.getDefaultFilter();
    this.headFilter.statuseIds =  [TubePackageEnum.Closed, TubePackageEnum.Final];

    this.tubesPackagesService.tubesPackages$.subscribe((data) => {
      this.tubesPackages = data.list;
      /*
      this.tubesPackages = this.tubesPackages.filter(
        (tubesPackage) =>
          tubesPackage.statusId == TubePackageEnum.Final ||
          tubesPackage.statusId == TubePackageEnum.Closed
      );*/
      this.metaData = data.metaData;
    });
    this.tubesPackagesService.getAll(this.headFilter);
  }
  uploadResultsFile() {}
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    /*
    if (isFromHead) {
      this.checkedIds = {};
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }
    if (isFormPaginator) {
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }*/
    this.headFilter = e;
    this.tubesPackagesService.getAll(this.headFilter);
  }
}
