import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserTokenMin } from 'src/app/data/UserTokenMin';
import { UserTokenService } from 'src/app/Services/user-token.service';

@Component({
  selector: 'app-far-login-history',
  templateUrl: './far-login-history.component.html',
  styleUrls: ['./far-login-history.component.css'],
})
export class FarLoginHistoryComponent implements OnInit {
  usersList: UserTokenMin;
  displayedColumns: string[] = ['agentName', 'startDate', 'endDate'];
  constructor(
    private userTokensService: UserTokenService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userTokensService.userTokens().subscribe((data) => {
      this.usersList = data;
     // console.table(this.usersList);
    });
  }
  goTo(id: number) {
    this.router.navigate(['/agents/edit'], { queryParams: { agentId: id } });
  }
}
