import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Product } from 'src/app/data/Product';
import { ProductForTableMin } from 'src/app/data/ProductForTableMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  permissionModuleAndAction,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from '../../Utils/user-helper';

@Component({
  selector: 'app-tests-table',
  templateUrl: './tests-table.component.html',
  styleUrls: ['./tests-table.component.scss'],
})
export class TestsTableComponent implements OnInit {
  @Input() productsData: ProductForTableMin[];
  @Input() statuses: Status[];
  @Input() leadSources: any;
  @Input() agents: AgentMin[];
  @Input() followUpAgents: AgentMin[];
  @Input() headFilter: HeadFilter;
  @Input() checkedIds: any;
  @Input() checkedAll: boolean = false;
  @Input() isLoading;
  isSearchPage: boolean;
  regularUser: RegularUser;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  isFollowUpAgent: boolean;
  headersShow = [];
  headers = [
    {
      name: 'Tube ID',
      val: 'TubeId',
      width: '140px',
      show: true,
      followUp: false,
    },
    {
      name: '',
      val: '',
      width: '140px',
      show: true,
      showOnlyFollowUp: true,
      followUp: true,
    },
    {
      name: 'Registered Date',
      val: 'RegisteredDate',
      width: '160px',
      show: true,
      followUp: true,
    },
    {
      name: 'Patient Name',
      val: 'Name',
      width: '140px',
      show: true,
      followUp: true,
    },
    /* {
      name: 'Patient ID',
      val: 'PatientId',
      width: '140px',
      show: true,
      followUp: true,
    }, */
    {
      name: 'Client ID',
      val: 'ClientId',
      width: '140px',
      show: false,
      followUp: true,
    },
    {
      name: 'Patient Phone',
      val: 'PatientPhone',
      width: '140px',
      show: true,
      followUp: true,
    },
    {
      name: 'Status',
      val: 'Status',
      width: '160px',
      show: true,
      followUp: false,
    },
    {
      name: 'Nutritionist',
      val: 'Nutritionist',
      width: '170px',
      show: true,
      followUp: true,
    },
    {
      name: 'Follow up agent',
      val: 'FollowUp',
      width: '170px',
      show: true,
      followUp: true,
    },
    {
      name: 'LifeStyle Report',
      val: 'lifeStyleReport',
      width: '140px',
      show: true,
      followUp: false,
    },
    {
      name: 'First call',
      val: 'firstCall',
      width: '100px',
      show: true,
      followUp: false,
    },
    {
      name: 'On Follow',
      val: 'onFollow',
      width: '100px',
      show: true,
      followUp: true,
    },
    {
      name: '',
      val: 'edit',
      width: '80px',
      show: true,
      followUp: true,
    },
  ];
  nutrisionists: AgentMin[];
  //agentForAC: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private popupHandlerService: PopupHandlerService,
    private regularUsersService: RegularUsersService,
    private popupHandler: PopupHandlerService,
    public dialog: MatDialog
  ) {
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      this.isFollowUpAgent =
        this.regularUser.userRoleId == UserRoleEmum.followUpAgent;
    });
    var isSearchPage = this.route.snapshot.queryParamMap.get('searchTerm');
    this.isSearchPage = isSearchPage ? true : false;
  }

  ngOnInit(): void {
    this.headersShow = this.headers.filter((x) => {
      if (x.show) {
        if (!this.isFollowUpAgent) {
          return !x.showOnlyFollowUp;
        } else {
          return x.followUp;
        }
      }
    });

    this.followUpAgents = this.followUpAgents.filter((x) => x.agentId > 0);
    //this.agentForAC = this.agents.map((agent) => agent.name);
  }
  ngOnChanges(changes: SimpleChanges): void {
    /*
    if (!this.nutrisionists && this.agents)
      this.nutrisionists = this.agents.filter((agent) => {
        return agent.regularUser.roleTypeId == AgentRollsEnum.nutritionist;
      });
      */
    this.nutrisionists = this.agents;
    if (this.isSearchPage) return;
    // var a = this.checkedAll;
    if (this.productsData)
      this.productsData.forEach((product) => {
        if (this.checkedIds[product.productId]) product.isChecked = true;
      });
  }

  getAgentNameById(agentId) {
    if (!agentId || !this.nutrisionists) return;
    return this.nutrisionists.find((a) => a.agentId === agentId)?.name;
  }
  getFollowUpAgentNameById(agentId) {
    if (!agentId || !this.followUpAgents) return;
    return this.followUpAgents.find((a) => a.agentId === agentId)?.name;
  }
  editPatient(event, patientItem) {}

  triggerToggleClick() {}
  date;

  editTest(event, productItem: Product) {
    this.moveToEditScreen(productItem.productId);
  }
  isChecked(event, productId, checked) {
    if (checked) {
      this.checkedIds[productId] = true;
    } else {
      delete this.checkedIds[productId];
    }
  }
  getFirstName(name) {
    if (name) return name.split(' ')[0];
  }
  showAgentFilter() {
    return userHasPermisions(
      [permissionModuleAndAction.Products_ChangeAgent],
      this.regularUser
    );
  }
  userHasPermision(permission) {
    return userHasPermisions(permission, this.regularUser);
  }

  moveToEditScreen(productId = -1) {
    this.router.navigate(['/tests/edit/'], {
      queryParams: { productId: productId },
    });
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveStatusForProduct(event, product: ProductForTableMin) {
    var val = event.value;
    product.statusId = Number(event.value);
    product.status = this.statuses.filter((x) => {
      return x.statusId == event.value;
    })[0];
    //lead.status = null;

    this.productsService
      .changeStatus(product.productId, product.statusId)
      .subscribe((obj) => {
        if (obj.isProblems) {
          var errorsHtml =
            "status wasn't Changed:<br/>" +
            obj.list.map((x) => x.name + '<br>').join();

          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.errorFromServer,
            '',
            '',
            errorsHtml,
            4000
          );
          product.statusId = obj.oldStatusId;
          product.status = this.statuses.find((x) => {
            return x.statusId == obj.oldStatusId;
          });
        } else {
          if (obj.actionsList.length > 0) {
            this.popupHandlerService.openPerformStatusChangeDialog(
              obj.actionsList,
              product.productId
            );
          } else {
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.status
            );
          }
        }
      });
  }
  checkAll(event) {
    var isChecked = event.checked;

    this.productsData.forEach((product) => {
      if (isChecked) {
        this.checkedIds[product.productId] = true;
        product.isChecked = true;
      } else {
        delete this.checkedIds[product.productId];
        product.isChecked = false;
      }
    });
  }
  getCheckedCount() {
    var cnt = 0;
    for (var inx in this.checkedIds) {
      cnt++;
    }
    return cnt;
  }
  checkObjLength(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  changeBulkAgent(event) {
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });
    /* this.leadsService.bulkChangeAgent(checkedIdsArr, agentId);
    this.openConfirmChangDialog(null, 'agent'); */

    this.checkedIds = [];
    this.checkedAll = false;
  }

  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }
  saveAgentForProduct(event, product: Product) {
    if (!product) return;

    product.agentId = event;
    //lead.agent = null;

    setTimeout(() => {
      this.productsService
        .changeAgent(product.productId, product.agentId)
        .subscribe((res) => {});
      this.openConfirmChangDialog(null, DisapearingTextDialogEnum.nutAgent);
    }, 10);
  }
  saveAgent2ForProduct(event, product: Product) {
    if (!product) return;

    product.agent2Id = event;
    //lead.agent = null;

    setTimeout(() => {
      this.productsService
        .changeAgent2(product.productId, product.agent2Id)
        .subscribe((res) => {});
      this.openConfirmChangDialog(
        null,
        DisapearingTextDialogEnum.followUpAgent
      );
    }, 10);
  }
  openConfirmChangDialog(
    product: Product = null,
    type = '',
    from = '',
    to = ''
  ) {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: product,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      var a = result;
    });
  }
  canChangeStatus() {
    return userHasPermisions(
      [permissionModuleAndAction.Products_ChangeStatus],
      this.regularUser
    );
  }
  lifestyleReportDone(product: ProductForTableMin) {
    return product.lifeStyleReportComplete;
  }
  firstCallDone(product: ProductForTableMin) {
    return product.firstCall;
  }
  changeFirstCallToTrue(product: ProductForTableMin) {
    this.popupHandlerService.openConfirmationDialog();
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.productsService
            .changeFirstCall(product.productId, true)
            .subscribe((res) => {
              product.firstCall = true;
            });
        }
      });
  }
  changeFirstCallToFalse(product: ProductForTableMin) {
    this.popupHandlerService.openConfirmationDialog();
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.productsService
            .changeFirstCall(product.productId, false)
            .subscribe((res) => {
              product.firstCall = false;
            });
        }
      });
  }
  changeOnFollowToTrue(product: ProductForTableMin) {
    this.popupHandlerService.openConfirmationDialog();
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.productsService
            .changeOnFollow(product.productId, true)
            .subscribe((res) => {
              product.onFollow = true;
            });
        }
      });
  }
  changeOnFollowToFalse(product: ProductForTableMin) {
    this.popupHandlerService.openConfirmationDialog();
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.productsService
            .changeOnFollow(product.productId, false)
            .subscribe((res) => {
              product.onFollow = false;
            });
        }
      });
  }
  canEdit(agentId = null) {
    return (
      agentId == this.regularUser.agent.agentId ||
      userHasPermisionsWithRule(['Products_Update'], this.regularUser)
    );
  }
}
