import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadsService {
  constructor(private http: HttpClient) {}

  delete(fileUploadId) {
    return this.http.delete<any>(
      `${environment.baseUrl}FileUploads/${fileUploadId}`
    );
  }
  download(fileUploadId) {
    return `${environment.baseUrl}FileUploads/Download/${fileUploadId}`;
    /*
    return this.http.get<any>(
      `${environment.baseUrl}FileUploads/Download/${fileUploadId}`
    );
    */
  }
}
