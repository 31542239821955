import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-edit-version',
  templateUrl: './food-genes-edit-version.component.html',
  styleUrls: ['./food-genes-edit-version.component.scss'],
})
export class FoodGenesEditVersionComponent implements OnInit {
  formulas: BaseResponce[];
  labVersions: any[];
  fgVersion: FgVersion;
  isDuplicate: boolean = false;
  duplicateId: number;
  isLoading: boolean;
  constructor(
    private foodGenesService: FoodGenesService,
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FoodGenesEditVersionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fgVersion: FgVersion;
      fgVersions: FgVersion[];
      langId: number;
      isDuplicate: boolean;
    }
  ) {}
  ngOnInit(): void {
    const a = this.data;
    if (!this.data.fgVersion?.fgVersionId) {
      this.fgVersion = new FgVersion();
      this.fgVersion.fgVersionId = 0;
    } else {
      this.getFormula();
      this.fgVersion = { ...this.data.fgVersion };
    }
    this.getLabWithVersions();
  }
  getFormula() {
    this.foodGenesService
      .getBaseResponceFormulasByVersion(this.data.fgVersion.fgVersionId)
      .subscribe((data) => {
        this.formulas = data;
      });
  }
  getLabWithVersions() {
    this.algorithmService
      .getLabWithVersions(this.data.langId, 0)
      .subscribe((data) => {
        this.labVersions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.labVersions.push(y);
          });
        });
      });
  }
  onDuplicateChange(event: MatSelectChange) {
    this.duplicateId = event.value;
  }
  save() {
    this.isLoading = true;
    const isDuplicate = !this.isDuplicate ? 0 : this.duplicateId;
    if (this.fgVersion.fgVersionId == 0) {
      this.foodGenesService
        .createFgVersion(
          this.fgVersion.labVersionId,
          this.fgVersion.fgVersionName,
          isDuplicate
        )
        .subscribe((data) => {
          if ((data as ErrorMessage)?.message) {
            if ((data as ErrorMessage)?.isSuccess) {
              this._snackBar.open('Success', 'Close', {
                duration: 2000,
              });
              this.dialogRef.close({ type: 'save' });
            } else {
              this._snackBar.open((data as ErrorMessage).message, 'Close');
              this.isLoading = false;
            }
          } else {
            this._snackBar.open('Success', 'Close', {
              duration: 2000,
            });
            this.dialogRef.close({ type: 'save' });
          }
        });
    } else if (this.fgVersion.fgVersionId > 0 && this.data.isDuplicate) {
      this.foodGenesService
        .duplicateFgVersion(this.fgVersion.fgVersionId, this.duplicateId)
        .subscribe((data) => {
          if ((data as ErrorMessage)?.message) {
            if ((data as ErrorMessage)?.isSuccess) {
              this._snackBar.open('Success', 'Close', {
                duration: 2000,
              });
              this.dialogRef.close({ type: 'save' });
            } else {
              this._snackBar.open((data as ErrorMessage).message, 'Close');
              this.isLoading = false;
            }
          } else {
            this._snackBar.open('Success', 'Close', {
              duration: 2000,
            });
            this.dialogRef.close({ type: 'save' });
          }
        });
    } else {
      this.foodGenesService
        .updateFgVersion(
          this.fgVersion.fgVersionId,
          this.fgVersion.fgVersionName,
          this.fgVersion.versionResultParamId
        )
        .subscribe((data) => {
          this._snackBar.open('Success', 'Close');
          this.dialogRef.close({ type: 'save' });
        });
    }
  }
}
