import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { ChatFilter } from 'src/app/data/chat/ChatFilter';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';
import { ChatRoomAgent } from 'src/app/data/chat/ChatRoomAgent';
import { ChatSession } from 'src/app/data/chat/ChatSession';
import { GeneralAgent } from 'src/app/data/chat/GeneralAgent';
import { NewSession } from 'src/app/data/chat/NewSession';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { ChatFilterResult } from 'src/app/data/food-genes/ChatFilterResult';
import {
  DepartmentType,
  MessageStatus,
  SessionState,
  SessionStatus,
} from 'src/app/Enums/ChatEnum';
import { FirebaseAnonimousAuthService } from 'src/app/Services/firebase-anonimous-auth.service';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { getAppImage, replaceWebPath } from 'src/app/Utils/files-helper';

type FilterEnum = {
  id: number;
  name: string;
  shortName?: string;
};

@Component({
  selector: 'app-main-chat-board',
  templateUrl: './main-chat-board.component.html',
  styleUrls: ['./main-chat-board.component.scss'],
})
export class MainChatBoardComponent implements OnInit, OnDestroy {
  @ViewChild('chatFilterWrapper') chatFilterWrapper: ElementRef;
  branchId: number = 1;
  langId: number = 1;
  chatListSubscription: any;
  chatRoomAgent: ChatRoomAgent[];
  showTakeOver: boolean = false;
  newSessions: NewSession[];
  tookOverSession: ChatSession;
  chatRoom: ChatRoomAgent;
  agent: GeneralAgent;
  activeSessionIds = [];
  updatedSubscriber: { [key: number]: Subscription } = {};
  unreadMessagesSubscriber: Subscription;
  SessionStatus = SessionStatus;
  DepartmentType = DepartmentType;
  departments: FilterEnum[] = [];
  selectedFilterAgentId: number = 1;
  chatFilter: ChatFilter;
  chatFilterResult: ChatFilterResult[];
  agents: GeneralAgent[];
  filterOpened: boolean = false;
  chatScrollerHeight: any;
  resetSelectedWaitingList: boolean;
  resetSelectedAgentList: boolean;
  chatRoomSubscriber: Subscription;
  defaultAgentAvater: string;
  // chatRoomSubscriber: { [key: number]: Subscription } = {};

  constructor(
    private anonAuthService: FirebaseAnonimousAuthService,
    private regularUsersService: RegularUsersService,
    public firebaseService: FirebaseChatIndicatorService,
    private _matSnackBar: MatSnackBar,
    private router: Router
  ) {
    this.resetFilter();
  }

  resetFilter() {
    this.chatFilter = new ChatFilter();
    this.chatFilter.departmentId = 0;
    this.chatFilter.agentId = 0;
    this.chatFilter.isWaiting = false;
    this.chatFilter.isOpen = true;
    this.chatFilter.branchId = this.branchId;
    this.chatFilter.langId = this.langId;
    this.chatFilter.searchTerm = '';
  }
  toggleFilters() {
    this.filterOpened = !this.filterOpened;
    this.calcChatScrollerHeight();
    this.resetFilter();
    this.chatFilterResult = null;
  }

  calcChatScrollerHeight() {
    let offset = 159;
    setTimeout(() => {
      this.chatScrollerHeight =
        offset + this.chatFilterWrapper.nativeElement.offsetHeight;
    }, 500);
  }

  ngOnDestroy(): void {
    this.unSubscribeChatRoom();
    this.unSubscribeUpdatedSubscriber();
    this.unSubscribeUnreadMessagesSubscriber();
  }

  ngOnInit(): void {
    this.getLoggedUser();
    this.calcChatScrollerHeight();
  }

  getActiveSession(chatRoomAgent: ChatRoomAgent) {
    const activeSession = chatRoomAgent.sessions.find(
      (x) => x.statusId === SessionStatus.Active
    );
    return activeSession;
  }

  unSubscribeChatRoom() {
    if (this.chatRoomSubscriber) {
      this.chatRoomSubscriber.unsubscribe();
    }
  }
  unSubscribeUpdatedSubscriber() {
    Object.keys(this.updatedSubscriber).forEach((key) => {
      this.updatedSubscriber[key].unsubscribe();
    });
  }
  unSubscribeUnreadMessagesSubscriber() {
    if (this.unreadMessagesSubscriber) {
      this.unreadMessagesSubscriber.unsubscribe();
    }
  }

  // Get the agent user and get the list of sessions that the agent is in
  getLoggedUser() {
    this.regularUsersService.currentUser.subscribe((data) => {
      this.getAgent(data.agent.agentId);
    });
  }

  // Login to firebase anonymously and listen to new sessions and chat room agent
  loginToFirebase() {
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (data) {
        this.listenToChatRoom();
      }
    });
  }

  listenToUnreadMessages() {
    this.firebaseService.unreadMessages$.subscribe((unreadList) => {
      if (unreadList) {
        this.chatRoomAgent = this.chatRoomAgent.map((agent) => {
          const unread = unreadList.find(
            (x) => x.chatRoomId === agent.chatRoomId
          );
          if (unread) {
            agent.notReadMsgCount = unread.messagesCount;
          }
          return agent;
        });
        this.firebaseService.chatRoomAgent$.next(this.chatRoomAgent);
      }
    });
  }

  // Get the agent details
  getAgent(agentId: number) {
    this.firebaseService
      .getAgentById(agentId, this.langId)
      .subscribe((data) => {
        this.agent = data;
        if (!this.agent?.avatar) {
          this.getAvatarDefaultImage();
        }
        this.chatFilter.agentId = this.agent.agentId;
        this.getAgentChatFull(this.agent.agentId);
        this.getAgents();
        // this.getChatByFilter();
      });
  }

  getAgents() {
    this.firebaseService.getAgents(this.langId).subscribe((data) => {
      this.agents = data;
    });
  }

  // Listen to the chat room active
  listenToChatRoom() {
    this.unSubscribeChatRoom();
    this.chatRoomSubscriber = this.firebaseService.chatRoom$.subscribe(
      (data) => {
        if (data?.chatRoom) {
          this.chatRoom = data.chatRoom;
        }
      }
    );
  }

  // Open info link in new tab
  openInNewTab(urlStr: string) {
    if (!urlStr) return;
    const urlSplited = this.splitUrl(urlStr);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([urlSplited.baseUrl], {
        queryParams: { ...urlSplited.queryParamsObj },
      })
    );
    window.open(url, '_blank');
  }
  // Split the url to get the base url and the query params
  splitUrl(url: string) {
    const urlSplited = url.split('?');
    const baseUrl = urlSplited[0];
    const queryParams = urlSplited[1];
    const queryParamsSplited = queryParams.split('&');
    const queryParamsObj = {};
    queryParamsSplited.forEach((item) => {
      const itemSplited = item.split('=');
      queryParamsObj[itemSplited[0]] = itemSplited[1];
    });
    return { baseUrl, queryParamsObj };
  }

  // Get the list of sessions that the agent is in
  getAgentChatFull(agentId: number) {
    this.firebaseService.getAgentChatFull(agentId).subscribe((data) => {
      this.chatRoomAgent = data;
      this.loginToFirebase();
      this.activeSessionIds = this.chatRoomAgent.map(
        (x) => x.sessions[x.sessions.length - 1].chatSessionId
      );
      this.lisetnToUserTyping();
      this.firebaseService.chatRoomAgent$.next(data);
      this.listenToUnreadMessages();
    });
  }

  getAgentChatFullAfterSessionClosed(agentId: number, sessionId: number) {
    this.firebaseService.getAgentChatFull(agentId).subscribe((data) => {
      this.chatRoomAgent = data;
      this.firebaseService.chatRoomAgent$.next(data);
      this.firebaseService.chatRoom$.next(null);
      //this.getSingleRoom(this.agent.agentId, sessionId, false);
      console.log('data: ', data);
    });
  }

  getChatByFilter(chatFilter: ChatFilter) {
    this.firebaseService.getChatByFilter(chatFilter).subscribe((data) => {
      this.chatFilterResult = data;
      // sort by isWaiting
      this.chatFilterResult.sort((a, b) => {
        if (a.isWaiting && !b.isWaiting) return -1;
        if (!a.isWaiting && b.isWaiting) return 1;
        return 0;
      });
      this.firebaseService.chatFilterResult$.next(this.chatFilterResult);
    });
  }

  // Listen to user typing
  lisetnToUserTyping() {
    this.activeSessionIds.map((sessionId) => {
      this.firebaseService.listenToUserTyping(sessionId).subscribe((data) => {
        if (data) {
          this.chatRoomAgent.find(
            (x) => x.sessions[x.sessions.length - 1].chatSessionId === sessionId
          ).isTyping = data.isTyping;
          this.firebaseService.chatRoomAgent$.next(this.chatRoomAgent);
        }
      });
    });
  }

  // Listen to session updated
  listenToSessionUpdated(sessionId: number) {
    if (this.updatedSubscriber[sessionId]) return;
    this.updatedSubscriber[sessionId] = this.firebaseService
      .listenToSessionUpdated(sessionId)
      .subscribe((updated) => {
        if (updated) {
          // console.log(`listenToSessionUpdated ${sessionId}: `, updated);
          if (
            this.chatRoom &&
            this.chatRoom.sessions[this.chatRoom.sessions.length - 1]
              .chatSessionId === sessionId
          ) {
            const chatRoomSessionsLastInx = this.chatRoom.sessions.length - 1;
            const messages = [
              ...this.chatRoom.sessions[chatRoomSessionsLastInx].messages,
            ];
            updated.messages = messages;
            this.chatRoom.sessions[chatRoomSessionsLastInx] = updated;
            this.firebaseService.chatRoom$.next({ chatRoom: this.chatRoom });
          }
        }
      });
  }

  // Get the session details and add it to the chat list
  getSingleRoom(agentId: number, sessionId: number, toggleFilters = true) {
    this.firebaseService.getSingleRoom(agentId, sessionId).subscribe((data) => {
      if ((data as ErrorMessage)?.message) {
        this._matSnackBar
          .open((data as ErrorMessage).message, 'Close')
          ._dismissAfter(4000);
      } else {
        const newRoom = data as ChatRoomAgent;
        const roomExistIndex = this.chatRoomAgent.findIndex(
          (x) => x.chatRoomId === newRoom.chatRoomId
        );
        if (roomExistIndex > -1) {
          this.chatRoomAgent.splice(roomExistIndex, 1);
        }
        this.chatRoomAgent.unshift(newRoom);
        this.firebaseService.chatRoomAgent$.next(this.chatRoomAgent);
        this.firebaseService.chatRoom$.next({ chatRoom: newRoom });
        if (toggleFilters && this.filterOpened) {
          this.toggleFilters();
        }
      }
    });
  }

  onAgentListSelected(chatRoomId: number) {
    this.resetSelectedWaitingList = true;
    this.resetSelectedAgentList = false;
    this.getChatRoomForAgent(chatRoomId);
  }

  onWrapperClick() {
    this.firebaseService.emojisState$.next(false);
  }

  getChatRoomForAgent(chatRoomId: number) {
    this.firebaseService
      .getChatRoomForAgent(this.agent.agentId, chatRoomId)
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this._matSnackBar.open((data as ErrorMessage).message, 'Close');
        } else {
          const newRoom = data as ChatRoomAgent;
          console.log('getChatRoomForAgent: ', newRoom);

          const activeSession = newRoom.sessions[newRoom.sessions.length - 1];
          // const agentOnSameDepartment =
          //   activeSession.departmentId === this.agent.departmentId &&
          //   (activeSession.currentAgent === this.agent.agentId ||
          //     activeSession.currentAgent == null);
          const isNewSession = activeSession.statusId == SessionStatus.Waiting;

          this.firebaseService.chatRoom$.next({
            chatRoom: newRoom,
            isNewSession,
          });
        }
      });
  }

  // After accepting the session, the agent will be added to the session
  changeAgent(item: NewSession) {
    const newSession = new ChatSession();
    newSession.chatSessionId = item.sessionId;
    newSession.userId = item.userId;
    this.firebaseService
      .changeAgent(this.branchId, this.langId, this.agent.agentId, newSession)
      .subscribe((data) => {
        //this.getSingleRoom(this.agent.agentId, item.sessionId);
      });
  }

  onStartChat(newSession: ChatSession) {
    this.firebaseService
      .changeAgent(this.branchId, this.langId, this.agent.agentId, newSession)
      .subscribe((data) => {
        this.getSingleRoom(this.agent.agentId, newSession.chatSessionId);
        //this.toggleFilters();
      });
  }

  onOpenSession(item: NewSession) {
    this.resetSelectedAgentList = true;
    this.resetSelectedWaitingList = false;
    this.firebaseService
      .getSingleRoom(this.agent.agentId, item.sessionId)
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this._matSnackBar.open((data as ErrorMessage).message, 'Close');
        } else {
          const newRoom = data as ChatRoomAgent;
          this.firebaseService.chatRoom$.next({
            chatRoom: newRoom,
            isNewSession: true,
          });
        }
      });
  }

  // take the session - the agent will be added to the session
  onAgentInterseption(item: ChatSession) {
    this.firebaseService
      .agentInterseptionSession(
        this.branchId,
        this.langId,
        this.agent.agentId,
        item
      )
      .subscribe((data) => {
        this.getSingleRoom(this.agent.agentId, item.chatSessionId);
        this.getAgentChatFull(this.agent.agentId);
      });
  }

  // Close the session
  closeSession(session: ChatSession) {
    if (session) {
      this.firebaseService
        .closeSession(this.branchId, this.langId, session)
        .subscribe((data) => {
          this._matSnackBar.open('Session closed', 'Close')._dismissAfter(2000);

          this.getAgentChatFullAfterSessionClosed(
            this.agent.agentId,
            session.chatSessionId
          );
        });
    }
  }

  getAvatarDefaultImage() {
    this.firebaseService.getAvatarDefaultImage().subscribe((data) => {
      this.defaultAgentAvater = replaceWebPath(data.url);
    });
  }

  // Get the agent avatar
  getAvatarImage() {
    if (!this.agent?.avatar) {
      return this.defaultAgentAvater;
    }
    return getAppImage(this.agent.avatar);
  }
}
