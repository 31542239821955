import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { filter, take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { CancelationPutResponse } from 'src/app/data/CancelationPutResponse';
import { CancelationRequest } from 'src/app/data/CancelationRequest';
import { Client } from 'src/app/data/Client';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { Product } from 'src/app/data/Product';
import { ProductForDealBundle } from 'src/app/data/ProductForDealBundle';
import { ProductType } from 'src/app/data/ProductType';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { CancelModeEnum } from 'src/app/Enums/PageTypeEnum';
import {
  CancellationDecisionEnum,
  CancellationRequestEnum,
  DealEnum,
  NoteCategoryEnum,
  ProductTypeEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { DealsService } from 'src/app/Services/deals.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestNotesService } from 'src/app/Services/service-request-notes.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-edit-cancelation-request',
  templateUrl: './edit-cancelation-request.component.html',
  styleUrls: ['./edit-cancelation-request.component.scss'],
})
export class EditCancelationRequestComponent implements OnInit {
  changeProdStatues = [
    CancellationDecisionEnum.DowngradedWithRefund,
    CancellationDecisionEnum.Upgraded,
    CancellationDecisionEnum.DowngradedWithCoupon,
  ];
  @ViewChild('cancelationFormRef') cancelationFormRef: FormGroupDirective;
  agentUser: RegularUser;
  noteToEdit;
  isNoteEditMode: boolean = false;
  cancelationRequest: CancelationRequest;
  agents: AgentMin[];
  reasonStatuses: Status[];
  statuses: Status[];
  client: Client;
  //products: Product[];
  cancelationForm: FormGroup;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  productsWithNoPatient: any;
  allProductsChecked;
  checkedProducts: number[];
  cancellationRequestNotes: NoteForCallMin[];
  cancellationRequestTasks: NoteForCallMin[];
  cancelationMode: string;
  CancelModeEnum = CancelModeEnum;
  decisions: Status[];
  isTaskEditMode: boolean = false;
  activityToEdit: Note;
  cancelProductsIds: number[];
  cancelProducts: Product[];
  changeToProducts: ProductType[];
  prodArr: any[] = [];
  clientDealBundles: DealBundleForTable[];
  hideRejectApproveBtns: boolean = false;
  allAgents: AgentMin[];
  previousUrl: string;
  isNavigated: boolean = false;

  showCancelError: boolean = false;
  constructor(
    private canselationService: CancelationRequestService,
    private agentService: AgentsService,
    private statusesService: StatusesService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private serviceRequestNotesService: ServiceRequestNotesService,
    private notesPusherService: NotesPusherService,
    private regularUsersService: RegularUsersService,
    private popupHandlerService: PopupHandlerService,
    private formBuilder: FormBuilder,
    private productTypesService: ProductTypesService,
    private dealsService: DealsService
  ) {
    /// TODO : can't get the referal URL , allways showing Back to Cancelation link.need to show back to client when comming from client
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        //console.log('prev:', event.url + ' isNavigated ' + this.isNavigated);
        this.isNavigated = true;
        this.previousUrl = event.url;
      });
  }

  ngOnInit(): void {
    // SUBSCRIBE TO ROUTE URL TO SET THE CANCELLATION MODE
    this.route.url.subscribe((params) => {
      switch (params[1].path) {
        default:
        case CancelModeEnum.newRequest:
          this.cancelationMode = CancelModeEnum.newRequest;
          break;
        case CancelModeEnum.conservation:
          this.cancelationMode = CancelModeEnum.conservation;
          break;
        case CancelModeEnum.manager:
          this.cancelationMode = CancelModeEnum.manager;
          break;
        case CancelModeEnum.closed:
          this.cancelationMode = CancelModeEnum.closed;
      }
    });

    // GET THE ID FROM URL
    var cancelRequestId =
      this.route.snapshot.queryParamMap.get('cancelRequestId');

    // GET LOGGED USER
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });

    // LISTEN TO NOTES PUSHER
    this.notesPusherService.notes$.subscribe((data) => {
      //notes
      this.cancellationRequestNotes = data.sort((a, b) =>
        a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime() ? 1 : -1
      );
      //tasks
      this.cancellationRequestTasks = data.filter((item) => {
        return item.noteTypeId == 2;
      });
      this.cancellationRequestTasks = this.cancellationRequestTasks.sort(
        (a, b) => (a.dueDateObj.getTime() < b.dueDateObj.getTime() ? 1 : -1)
      );
    });

    // GET CANCELLATION REQUESTS
    this.canselationService.getById(cancelRequestId).subscribe((data) => {
      this.cancelationRequest = data;
      console.log('cancelationRequest', this.cancelationRequest);

      this.client = data.originalDealBundle.client;
      // this.products = data.originalDeal.products;
      // this.cancelProductsIds = data.cancelDealBundle.productsIds;
      if (
        data.cancelDealBundle &&
        data.cancelDealBundle.productForDealBundles &&
        data.originalDealBundle &&
        data.originalDealBundle.productForDealBundles
      )
        this.cancelProducts = data.originalDealBundle.productForDealBundles
          .map((x) => {
            x.product.isCanceled =
              data.cancelDealBundle.productForDealBundles.filter(
                (x) => x.productId == x.productId
              ).length > 0
                ? true
                : false;
            return x.product;
          })
          .filter((x) => x.patientId != null);

      //INITIALIZE FORM
      this.createForm();

      // GET CANCEL DEALS HISTORY
      this.dealsService
        .getByClientId(this.client.clientId)
        .subscribe((data) => {
          this.clientDealBundles = data;
          this.clientDealBundles.filter((deal) => {
            return deal.isCancelDeal && deal.statusId == DealEnum.Done;
          });
        });

      // HIDE APPROVE AND REJECT BTNS IF DESICION WAS MADE
      if (
        this.cancelationRequest.statusId !==
        CancellationRequestEnum.ManagerApprove
      ) {
        this.hideRejectApproveBtns = true;
      }

      // DISABLE THE FORM IF IS MANAGER MODE AND STATUS NOT MANAGER APPROVE (REJECT|APPROVE)
      this.disableForm();

      //GET NOTES
      this.getNotes(this.cancelationRequest.cancelationRequestId);

      //on init check if all products are checked and set the column main checkbox to checked
      /*
      this.productsWithNoPatient = this.products.filter(
        (product) => product.isChecked
      );*/
      /*
      if (this.productsWithNoPatient.length == this.products.length) {
        this.allProductsChecked = true;
      } else {
        this.allProductsChecked = false;
      }
*/
      // CHECK IF HAS CANCEL PRODUCTS
      /*
      this.products.forEach((product) => {
        this.cancelProductsIds.forEach((cancel) => {
          if (product.productId == cancel) product.isChecked = true;
        });
      });
      */
    });

    // GET AGENTS
    this.agentService.getAll().subscribe((data) => {
      this.allAgents = data;
      this.agents = data.filter((agent: AgentMin) => {
        return (
          agent.roleTypeId == UserRoleEmum.Support ||
          agent.roleTypeId == UserRoleEmum.SupportManager ||
          agent.roleTypeId == UserRoleEmum.Manager ||
          agent.roleTypeId == UserRoleEmum.SalesManager ||
          agent.roleTypeId == UserRoleEmum.SalesTeamLeader
        );
      });
      /*if (this.cancelationRequest) {
        this.agents.push(
          this.agents.find(
            (agent) =>
              agent.agentId == this.cancelationRequest.originalDeal.agentId
          )
        );
      } */
    });

    // GET STATUSES
    this.statusesService.getAll().subscribe((data) => {
      this.reasonStatuses = data.filter((status) => {
        return status.category === 'CancelationRequestReason';
      });
      this.decisions = data.filter((status) => {
        return status.category === 'CancelationDecision';
      });
    });

    // GET ALL PRODUCTS
    this.productTypesService.getAll().subscribe((data) => {
      this.changeToProducts = data.filter(
        (product) => product.productGroupId == 1 && product.productTypeId != 7
      );
    });
  }

  userCanClientClick() {
    return (
      userHasPermisionsWithRule(['Clients_GetSingle'], this.agentUser) ||
      (this.agentUser.agent.agentId == this.client.agentId &&
        userHasPermisionsWithRule(
          ['Clients_GetSingle_IsTargetAgent'],
          this.agentUser
        ))
    );
  }

  // selectionChange in products table
  productChangeTo(productId, value) {
    this.cancelProducts
      .filter((x) => x.productId == productId)
      .forEach((y) => (y.newProductTypeId = value));
  }

  canChangeProduct(product: Product) {
    if (
      product.productTypeId == ProductTypeEnum.NutriPlus &&
      this.cancelationForm.value.decisionId == CancellationDecisionEnum.Upgraded
    ) {
      //upgraded
      //console.log('upgraded false');

      return false;
    }
    if (
      product.productTypeId == ProductTypeEnum.NutriBasic &&
      (this.cancelationForm.value.decisionId ==
        CancellationDecisionEnum.DowngradedWithRefund ||
        this.cancelationForm.value.decisionId ==
          CancellationDecisionEnum.DowngradedWithCoupon)
    ) {
      //downgrades
      //console.log('downgrades false');
      return false;
    }
    //console.log('other true');
    return true;
  }

  // CREATE THE DEFAULT FORM
  createForm() {
    this.showChangeTo =
      this.cancelationRequest.decisionId &&
      this.changeProdStatues.includes(this.cancelationRequest.decisionId);
    var date = new Date().toLocaleDateString();

    this.cancelationForm = this.formBuilder.group({
      createDate: new FormControl(
        { value: date, disabled: true },
        Validators.required
      ),
      approvalReason: new FormControl(
        this.cancelationRequest.approvalReason,
        Validators.required
      ),
      reason: new FormControl(
        this.cancelationRequest.requestReasonId,
        Validators.required
      ),
      amount: new FormControl(
        this.cancelationRequest.cancelDealBundle.amount,
        Validators.required
      ),
    });
    //ADD FIELDS TO THE FORM BY THE MODE
    if (
      this.cancelationMode === CancelModeEnum.conservation ||
      this.cancelationMode === CancelModeEnum.manager ||
      this.cancelationMode === CancelModeEnum.closed
    )
      this.addConservationFields();

    // DISABLE THE FORM IF IS MANAGER MODE AND STATUS NOT MANAGER APPROVE (REJECT|APPROVE)
    if (
      (this.cancelationRequest.statusId !==
        CancellationRequestEnum.ManagerApprove &&
        CancelModeEnum.manager === this.cancelationMode) ||
      this.cancelationRequest.statusId == CancellationRequestEnum.Closed ||
      this.cancelationRequest.statusId == CancellationRequestEnum.CouponApprove
    ) {
      this.cancelationForm.disable();
    }
  }

  // ADD FORM CONTROLS
  addConservationFields() {
    this.cancelationForm.addControl(
      'decisionId',
      new FormControl(this.cancelationRequest.decisionId, Validators.required)
    );
    this.cancelationForm.addControl(
      'conservationAgentId',
      new FormControl(
        this.cancelationRequest.conservationAgentId,
        Validators.required
      )
    );
  }

  // DISABLE THE FORM IF IS MANAGER MODE AND STATUS NOT MANAGER APPROVE (REJECT|APPROVE)
  disableForm() {
    if (
      (this.cancelationRequest.statusId !==
        CancellationRequestEnum.ManagerApprove &&
        CancelModeEnum.manager === this.cancelationMode) ||
      this.cancelationRequest.statusId == CancellationRequestEnum.Closed ||
      this.cancelationRequest.statusId == CancellationRequestEnum.CouponClosed
    ) {
      this.cancelationForm.disable();
    }
  }
  onSaveBtnClick() {
    this.cancelationRequest.isManagerApprove = false;
    this.submitFormProgrammatically();
  }

  // ONCLICK SAVE
  submitFormProgrammatically() {
    this.cancelationFormRef.onSubmit(undefined);
    /*
    var checkedProducts = this.products
      .filter((product) => product.isChecked)
      .map((product) => product.productId);
*/
    console.log('submitFormProgrammatically', this.cancelationRequest);

    var isCanceled = this.cancelProducts.some((item) => item.isCanceled);
    /* this.cancelationRequest.cancelDealBundle.productForDealBundles =
      this.cancelationRequest.cancelDealBundle.productForDealBundles
        .map((x) => {
          if (x.product.isCanceled) return x;
        })
        .filter((x) => x) || []; */
    if (this.decisionId == CancellationDecisionEnum.Saved) isCanceled = true;
    if (!isCanceled) {
      this.showCancelError = true;
    } else {
      this.showCancelError = false;
    }
    if (this.cancelationForm.valid && isCanceled) {
      // NEW REQUEST
      this.cancelationRequest.approvalReason =
        this.cancelationForm.value.approvalReason;
      this.cancelationRequest.requestReasonId =
        this.cancelationForm.value.reason;
      this.cancelationRequest.amount = this.cancelationForm.value.amount;
      this.cancelationRequest.cancelDealBundle.amount =
        this.cancelationForm.value.amount;

      // CONSEVATION
      // this.cancelationRequest.cancelDealBundle.productForDealBundles =
      //    this.cancelProducts;

      if (this.cancelationForm.value.conservationAgentId)
        this.cancelationRequest.conservationAgentId =
          this.cancelationForm.value.conservationAgentId;

      if (this.cancelationForm.value.decisionId)
        this.cancelationRequest.decisionId =
          this.cancelationForm.value.decisionId;

      // PRODUCTS DEALS
      // this.cancelationRequest.cancelDeal.productsIds = checkedProducts;
      if (
        this.cancelationRequest.statusId == CancellationRequestEnum.NotSaved
      ) {
        this.cancelationRequest.statusId = CancellationRequestEnum.NewRequest;
      }
      //   if (!this.cancelationRequest.cancelDealBundle.productForDealBundles)
      this.cancelationRequest.cancelDealBundle.productForDealBundles = [];
      this.cancelProducts.forEach((cancelProduct) => {
        /*
        var filtered =
          this.cancelationRequest.cancelDealBundle.productForDealBundles.filter(
            (y) => y.productId == cancelProduct.productId
          );
        if (
          this.cancelationRequest.cancelDealBundle.productForDealBundles.filter(
            (y) => y.productId == cancelProduct.productId
          ).length < 1
        ) {
          var productForDealBundle = new ProductForDealBundle();
          productForDealBundle.productId = cancelProduct.productId;
          productForDealBundle.dealBundleId =
            this.cancelationRequest.cancelDealBundle.dealBundleId;
          productForDealBundle.product = cancelProduct;
          this.cancelationRequest.cancelDealBundle.productForDealBundles.push(
            productForDealBundle
          );
        } else 
        if (!cancelProduct.isCanceled) {
          const index =
            this.cancelationRequest.cancelDealBundle.productForDealBundles.findIndex(
              (x) => x.productId == cancelProduct.productId
            );
          this.cancelationRequest.cancelDealBundle.productForDealBundles.splice(
            index,
            1
          );
        }
        */
        if (cancelProduct.isCanceled) {
          var productForDealBundle = new ProductForDealBundle();
          productForDealBundle.productId = cancelProduct.productId;
          productForDealBundle.dealBundleId =
            this.cancelationRequest.cancelDealBundle.dealBundleId;
          productForDealBundle.product = cancelProduct;
          this.cancelationRequest.cancelDealBundle.productForDealBundles.push(
            productForDealBundle
          );
        }
      });

      // SAVE REQUEST
      this.saveCancelationRequest();
    }
  }
  showSaveBtn() {
    if (
      this.cancelationRequest.statusId == CancellationRequestEnum.Closed ||
      this.cancelationRequest.statusId ==
        CancellationRequestEnum.CouponClosed ||
      this.cancelationRequest.statusId ==
        CancellationRequestEnum.RefundClosed ||
      this.cancelationRequest.statusId == CancellationRequestEnum.CouponApprove
    )
      return false;
    return true;
  }
  // SUBMIT FORM
  saveCancelationRequest() {
    this.canselationService
      .put(this.cancelationRequest)
      .subscribe((data: CancelationPutResponse) => {
        this.cancelationRequest = data.cancelationRequest;
        console.log('saveCancelationRequest', this.cancelationRequest);

        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.cancelationSaved
        );

        this.createForm();
        setTimeout(() => {
          this.disableForm();
        }, 100);

        var { cancelationRequestId, statusId } = this.cancelationRequest;
        if (statusId !== CancellationRequestEnum.ManagerApprove) {
          this.hideRejectApproveBtns = true;
        }
        // REDIRECT AFTER SAVE
        switch (statusId) {
          case CancellationRequestEnum.NewRequest:
            if (
              userHasPermisions(
                ['Cancelations_ConservationTab'],
                this.agentUser
              )
            ) {
              this.router.navigate(['/cancelations/conservation'], {
                queryParams: { cancelRequestId: cancelationRequestId },
              });
            }
            break;
          case CancellationRequestEnum.ManagerApprove:
            if (
              userHasPermisions(['Cancelations_ManagerTab'], this.agentUser)
            ) {
              this.router.navigate(['/cancelations/manager'], {
                queryParams: { cancelRequestId: cancelationRequestId },
              });
            }
            break;
          case CancellationRequestEnum.RefundApprove:
            if (userHasPermisions(['Cancelations_RefundTab'], this.agentUser)) {
              this.router.navigate(['/refunds/edit'], {
                queryParams: { cancelRequestId: cancelationRequestId },
              });
            }
            break;
        }
      });
  }

  // SEND REQUEST TO MANAGER FOR APPROVAL
  sendToManager() {
    if (this.cancelationForm.valid) {
      this.cancelationRequest.statusId = CancellationRequestEnum.ManagerApprove;
      this.cancelationRequest.isManagerApprove = false;
    }
    this.submitFormProgrammatically();
  }
  coservationAgentSaved() {
    if (this.cancelationForm.valid) {
      this.cancelationRequest.statusId = CancellationRequestEnum.Closed;
      this.cancelationRequest.isManagerApprove = false;
    }
    this.submitFormProgrammatically();
  }
  // CHANGE CREDIT STATUS
  managerRejectRequest() {
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.cancelationRequest.statusId = CancellationRequestEnum.Closed;
          this.saveCancelationRequest();
        }
      });
    this.popupHandlerService.openConfirmationDialog(
      '',
      'Are you about to to reject the refund request'
    );
    //'Are you sure you want to reject the refund request'
  }
  getPatientName(product: Product) {
    var lastName = '';
    if (product.patient.regularUser.lastName) {
      lastName = product.patient.regularUser.lastName;
    }
    return product.patient.regularUser.firstName + ' ' + lastName;
  }
  manageAproveRequest() {
    if (this.cancelationForm.valid) {
      switch (this.decisionId) {
        case CancellationDecisionEnum.DowngradedWithRefund:
        case CancellationDecisionEnum.ProductCanceled:
        case CancellationDecisionEnum.SavedWithRefund:
          this.cancelationRequest.statusId =
            CancellationRequestEnum.RefundApprove;
          break;
        case CancellationDecisionEnum.DowngradedWithCoupon:
        case CancellationDecisionEnum.SavedWithCoupon:
          this.cancelationRequest.statusId =
            CancellationRequestEnum.CouponApprove;
          break;
        case CancellationDecisionEnum.Upgraded:
        case CancellationDecisionEnum.Saved:
        case CancellationDecisionEnum.Rejected:
          this.cancelationRequest.statusId = CancellationRequestEnum.Closed;
          break;
      }
      this.cancelationRequest.isManagerApprove = true;
    }
    this.submitFormProgrammatically();
  }
  changeStatusToSaved() {
    if (this.cancelationMode == CancelModeEnum.newRequest) {
      this.cancelationRequest.statusId = CancellationRequestEnum.NewRequest;
    }
    //this.cancelationRequest.statusId = CancellationRequest.;
    this.saveCancelationRequest();
  }

  // REDIRECT TO CLIENT
  goToClient() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/clients/edit/'], {
        queryParams: { clientId: this.client.clientId },
      })
    );
    window.open(url, '_blank');
  }

  // CONCATINATE THE NAME
  getFullName(user: RegularUser) {
    if (!user.lastName) user.firstName;

    return user.firstName + ' ' + user.lastName;
  }

  // if all products checkboxes are not checked, unchecked the main checkbox column
  onCheckboxChange() {
    //   var prod = this.products.filter((product) => product.isChecked);
    //  if (prod.length == 0) this.allProductsChecked = false;
  }
  // bulk products checkboxes
  CheckAllOptions() {
    if (this.isInClosedStatus()) return;
    if (this.cancelProducts.every((val) => val.isCanceled == true))
      this.cancelProducts.forEach((val) => {
        val.isCanceled = false;
      });
    else
      this.cancelProducts.forEach((val) => {
        val.isCanceled = true;
      });
  }

  //NOTES
  getNotes(id) {
    this.notesPusherService.setMode('cancelationRequest');
    this.notesPusherService.getAllNotes(id);
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
    this.notesPusherService.getAllNotes();
  }
  createNewNote(note) {
    this.noteToEdit = note;
    this.isNoteEditMode = true;
  }
  saveNote(note: Note) {
    note.cancelationRequestId = this.cancelationRequest.cancelationRequestId;
    note.clientId = this.cancelationRequest.originalDealBundle.clientId;
    note.noteCategoryId = NoteCategoryEnum.cancelation;
    note.assigningAgentId = this.agentUser.agent.agentId;

    if (note.noteId > 0) this.notesPusherService.put(note);
    else if (this.cancelationRequest.cancelationRequestId)
      this.notesPusherService.post(note);

    this.isNoteEditMode = false;
  }
  onReloadNotes() {
    this.notesPusherService.getAllNotes(
      this.cancelationRequest.cancelationRequestId
    );
  }

  //TASKS
  closeNewTaskWindow() {
    this.isTaskEditMode = false;
  }
  saveTask(note: Note) {
    note.cancelationRequestId = this.cancelationRequest.cancelationRequestId;
    note.clientId = this.cancelationRequest.originalDealBundle.clientId;
    note.noteCategoryId = NoteCategoryEnum.cancelation;
    note.assigningAgentId = this.agentUser.agent.agentId;

    //note.dueDate = null;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      this.notesPusherService.post(note);
    }
    this.isTaskEditMode = false;
  }
  createNewTask(note: Note) {
    if (!note) note = new Note();
    if (!note.assignedAgentId && this.cancelationRequest.conservationAgentId)
      note.assignedAgentId = this.cancelationRequest.conservationAgentId;

    this.activityToEdit = note;
    this.isTaskEditMode = true;
  }
  onDecisionChanged(event) {
    if (this.changeProdStatues.includes(event.value)) {
      this.showChangeTo = true;
    } else this.showChangeTo = false;
  }
  goBackToCancelations() {
    this.router.navigate(['cancelations']);
  }
  goBackToClient() {}
  //showChangeTo: boolean = false;

  get decisionId() {
    return (
      this.cancelationForm.get('decisionId') &&
      this.cancelationForm.get('decisionId').value
    );
  }
  showRefundAmount() {
    return (
      this.cancelationMode === CancelModeEnum.conservation ||
      this.cancelationMode === CancelModeEnum.manager ||
      this.cancelationMode === CancelModeEnum.closed
      /* &&
      (this.decisionId == CancellationDecisionEnum.DowngradedWithCoupon ||
        this.decisionId == CancellationDecisionEnum.DowngradedWithRefund ||
        this.decisionId == CancellationDecisionEnum.ProductCanceled ||
        this.decisionId == CancellationDecisionEnum.SavedWithRefund ||
        this.decisionId == CancellationDecisionEnum.AllProductCanceled)
        */
    );
  }
  showChangeTo: boolean = false;

  isInClosedStatus() {
    return (
      this.cancelationRequest.statusId == CancellationRequestEnum.Closed ||
      this.cancelationRequest.statusId ==
        CancellationRequestEnum.CouponClosed ||
      this.cancelationRequest.statusId == CancellationRequestEnum.RefundClosed
    );
  }
}
