import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CreditType } from '../data/CreditType';

@Injectable({
  providedIn: 'root',
})
export class CreditTypesService {
  creditTypes: CreditType[];
  constructor(private http: HttpClient) {}
  getAll() {
    if (this.creditTypes) {
      return of(this.creditTypes); // return from cache
    } else {
      return this.http
        .get<CreditType[]>(`${environment.baseUrl}CreditTypes/`)
        .pipe(map((res) => (this.creditTypes = res)));
    }
    //  return this.http.get(`${environment.baseUrl}CreditTypes/`);
  }
}
