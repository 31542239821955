import { Component, OnInit } from '@angular/core';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  passwordValidator,
  matchOtherValidator,
} from 'src/app/Utils/validatorsUtils';

/*
export function passwordFormValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
*/
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  email: string;
  token: any;
  regularUser: RegularUser;
  isSubmitted: boolean = false;
  constructor(
    private regularUsersService: RegularUsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.regularUsersService.logout();
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.token = params['token'];
      this.regularUsersService
        .getUserFromResetParams(this.email, this.token)
        .subscribe((user) => {
          this.regularUser = user;
          this.createFormGroup();
        });
    });
  }

  createFormGroup() {
    var userName = null;
    if (this.regularUser) {
      userName = this.regularUser.userName
        ? this.regularUser.userName
        : this.regularUser.email;
    }
    this.resetForm = new FormGroup({
      userName: new FormControl(userName, [Validators.required]),
      password: new FormControl(null, [Validators.required, passwordValidator]),
      retypePassword: new FormControl(null, [
        Validators.required,
        matchOtherValidator('password'),
      ]),
    });
  }

  getFalse() {}
  doResetPassword() {
    this.isSubmitted = true;
    if (!this.resetForm.value) return;
    if (this.resetForm.value.password != this.resetForm.value.retypePassword)
      return;
    this.regularUser.userName = this.resetForm.value.userName;
    this.regularUser.password = this.resetForm.value.password;
    this.regularUsersService
      .resetPasword(this.regularUser)
      .subscribe((data) => {
   
        this.router.navigate(['login']);
      });
  }
  get password() {
    return this.resetForm.get('password');
  }
  get retypePassword() {
    return this.resetForm.get('retypePassword');
  }
  is8Length() {
    return this.password.value && this.password.value.length >= 8;
  }
  hasLetters() {
    return (
      this.password.value &&
      /[a-z]/.test(this.password.value) &&
      /[A-Z]/.test(this.password.value)
    );
  }
  hasNumbers() {
    return this.password.value && /[0-9]/.test(this.password.value);
  }
}
