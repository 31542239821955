import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FoodForBulkEdit } from 'src/app/data/FoodForBulkEdit';
import { Language } from 'src/app/data/Language';
import { EditFoodsService } from 'src/app/Services/edit-foods.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-bulk-edit-food',
  templateUrl: './bulk-edit-food.component.html',
  styleUrls: ['./bulk-edit-food.component.scss'],
})
export class BulkEditFoodComponent implements OnInit {
  foodIds: string;
  langId: number;
  targetLangId: number;
  foods: FoodForBulkEdit[];
  missingLangs: any[];
  translateFrom;
  translateTo;
  langs: Language[];
  langsTabs: any[];
  isRecipe: any;
  constructor(
    private route: ActivatedRoute,
    private editFoodsService: EditFoodsService,
    private languagesService: LanguagesService,
    private popupHandlerService: PopupHandlerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.foodIds = params['foodIds'];
        this.langId = params['langId'];
        this.isRecipe = params['isRecipe'];
        this.getEditBulk();
      }
    });
  }

  getEditBulk() {
    var ids = this.foodIds.split(',');
    this.editFoodsService
      .getFoodsBulkForEdit(ids, this.langId)
      .subscribe((res) => {
        this.foods = res.foodForBulkEdit;
        this.missingLangs = res.missingLangs;
        this.getLanguages();
        /*
          this.foods.forEach(x=>{
            var a=x;
            var b=x.foodLangForBulk;
            b.forEach(y=>{
              y.name
            });
          })
          */
      });
  }

  getLanguages() {
    this.languagesService.getAll().subscribe((res) => {
      this.langsTabs = res.filter((x) => x.languageId !== +this.langId);
      this.targetLangId = this.langsTabs[0].languageId;
      this.langsTabs.forEach((x) => {
        this.missingLangs.forEach((y) => {
          if (x.languageId == y.langId) {
            x.isMissing = y.isMissing;
          }
        });
      });
      this.langs = res;
    });
  }

  changeTargetLangId(lang: Language) {
    this.targetLangId = lang.languageId;
  }
  removeFood(foodIndex: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.foods = this.foods.filter((x, idx) => idx !== foodIndex);
        }
      });
  }
  disableRemove() {
    if (this.foods.length > 1) {
      return false;
    }
    if (this.foods.length == 1) {
      return true;
    }
  }

  cancel(foodForm: NgForm) {
    if (foodForm.form.dirty) {
      this.popupHandlerService.openConfirmationDialog(
        null,
        'You about cancel without save'
      );

      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((result) => {
          if (!result) return;
          if (result.ans === 'yes') {
            this.router.navigate(['foods']);
          }
        });
    } else {
      this.router.navigate(['foods']);
    }
  }

  saveAll(bulkForm: NgForm) {
    this.editFoodsService.saveFoodBulk(this.foods).subscribe((res) => {
      this.popupHandlerService.openDisapearingAlertDialog('success');
      this.getEditBulk();
      bulkForm.reset();
    });
  }
}
