import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, skip, switchMap, takeUntil } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { BarCity } from 'src/app/data/BarCity';
import { BarStreet } from 'src/app/data/BarStreet';
import { Client } from 'src/app/data/Client';
import { Country } from 'src/app/data/Country';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FutureProduct } from 'src/app/data/FutureProduct';
import { LeadSource } from 'src/app/data/LeadSource';
import { Patient } from 'src/app/data/Patients';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { Product } from 'src/app/data/Product';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { ProductIsFor } from 'src/app/data/ProductIsFor';
import { ProductType } from 'src/app/data/ProductType';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserAdress } from 'src/app/data/UserAdress';
import { UserPhone } from 'src/app/data/UserPhone';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  AdressTypeEnum,
  ProductEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { CitiesService } from 'src/app/Services/cities.service';
import { CountriesService } from 'src/app/Services/countries.service';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { SendMailService } from 'src/app/Services/send-mail.service';
import { StreetsService } from 'src/app/Services/streets.service';
import {
  getWeightUtil,
  userHasPermisionsWithRule,
} from '../../Utils/user-helper';

const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args: any[]) =>
      selector(...args).pipe(takeUntil(source$.pipe(skip(1))))
    )
  );
@Component({
  selector: 'app-client-profile-edit',
  templateUrl: './client-profile-edit.component.html',
  styleUrls: ['./client-profile-edit.component.scss'],
})
export class ClientProfileEditComponent implements OnInit {
  @Input() clientProducts: ProductForClientMin[];
  displayMailBtn: boolean = true;
  leadForm: FormGroup = null;
  @Input() leadStatuses;
  @Input() isDuplicate;
  @Input() client: Client;
  @Input() product: Product;
  @Input() leadSources: LeadSource[];
  @Input() productIsFors: ProductIsFor[];
  leadFormReady;
  @Input() clientTypes;
  @Input() classAttr;
  @Input() agents: AgentMin[];
  @Output('cancelForm') cancelForm: EventEmitter<any> = new EventEmitter();
  @Output('saveForm') saveForm: EventEmitter<Client> = new EventEmitter();
  @Output('saveFormProduct')
  saveFormProduct: EventEmitter<Product> = new EventEmitter();
  options: string[] = ['One', 'Two', 'Three'];
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  cityAutoComplete: MatAutocompleteTrigger;

  filteredCitiesOptions: BarCity[];

  filteredStreetsOptions: BarStreet[];
  isFormDisabled;
  productTypes: ProductType[];
  //myProducts = [];
  childForms: FormGroup[] = [];
  remainingProductTypes: any = [];
  isCitySelected: boolean;
  isStreetSelected: boolean;
  isSubmitedOnce: boolean = false;
  submitError: ErrorMessage;
  regularUser: RegularUser;
  isSupportAgent: boolean = false;
  citiesForAC: string[];
  streetsForAC: string[];
  cityName: string;
  streetName: string;
  agentForAC: string[];
  cityCode: number;
  searchTermCity$ = new BehaviorSubject<string>('');
  citiesResults$: Observable<BarCity[]> = this.searchTermCity$.pipe(
    autocomplete(800, (term: string) => {
      return this.citiesService.search(term);
    })
  );
  searchTermStreet$ = new BehaviorSubject<string>('');
  streetsResults$: Observable<BarStreet[]> = this.searchTermStreet$.pipe(
    autocomplete(800, (term: string) => {
      if (!this.mainCityCode && (this.client || this.product)) {
        if (this.client) {
          this.mainCityCode =
            this.client.regularUser?.userAdresses[0].barCityCode;
        } else if (this.product) {
          this.mainCityCode =
            this.product.patient.regularUser?.userAdresses[0].barCityCode;
        }
      }
      return this.streetsService.search(this.mainCityCode, term);
    })
  );
  searchTermShippingCity$ = new BehaviorSubject<string>('');
  shippingCitiesResults$: Observable<BarCity[]> =
    this.searchTermShippingCity$.pipe(
      autocomplete(800, (term: string) => {
        return this.citiesService.search(term);
      })
    );
  searchTermShippingStreet$ = new BehaviorSubject<string>('');
  shippingStreetsResults$: Observable<BarStreet[]> =
    this.searchTermShippingStreet$.pipe(
      autocomplete(800, (term: string) => {
        if (!this.shippingCityCode && (this.client || this.product)) {
          if (this.client) {
            this.shippingCityCode =
              this.client.regularUser.userAdresses[1].barCityCode;
          } else if (this.product) {
            this.shippingCityCode =
              this.product.patient.regularUser.userAdresses[1]?.barCityCode;
          }
        }
        return this.streetsService.search(this.shippingCityCode, term);
      })
    );

  searchTermCountries$ = new BehaviorSubject<string>('');
  CountriesResults$: Observable<BarCity[]> = this.searchTermCountries$.pipe(
    autocomplete(800, (term: string) => {
      return this.countries.filter((x) => x.countryCode == term);
    })
  );

  agentNotActive: boolean = false;
  minBirthDate = new Date(1905, 0, 1);
  savedBarStreet: BarStreet;
  savedBarCity: BarCity;
  savedMainBarCity: BarCity;
  mainCityCode: number;
  savedShippingBarCity: BarCity;
  shippingCityCode: number;
  phoneSettings: PhoneVerificationSetting;
  countries: Country[];
  shippingCheckbox: boolean;
  isLoading: boolean;
  //selectedCountryFlag;
  constructor(
    private productTypesService: ProductTypesService,
    private citiesService: CitiesService,
    private streetsService: StreetsService,
    private popupHandler: PopupHandlerService,
    private productsService: ProductsService,
    private sendMailService: SendMailService,
    private popupHandlerService: PopupHandlerService,
    private regularUsersService: RegularUsersService,
    private route: ActivatedRoute,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService,
    private countriesService: CountriesService
  ) {}
  cities: BarCity[];
  streets: BarStreet[];
  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;

      if (this.regularUser.userRoleId == UserRoleEmum.Support) {
        this.isSupportAgent = true;
      }
      if (this.product && !this.product.patient) {
        this.product.patient = new Patient();
        this.product.patient.regularUser = new RegularUser();
      }
      if (
        this.product &&
        this.product.patient &&
        (!this.product.patient.regularUser.userAdresses ||
          this.product.patient.regularUser.userAdresses.length == 0)
      ) {
        let userAddress = new UserAdress();
        userAddress.adressTypeId = AdressTypeEnum.Main;
        userAddress.barStreet = new BarStreet();
        userAddress.barCity = new BarCity();
        this.product.patient.regularUser.userAdresses = [userAddress];
      }

      if (this.client) {
        if (!this.client.regularUser?.userPhones) {
          this.client.regularUser.userPhones = [];
        }
      }
      if (this.product) {
        if (!this.product.patient.regularUser?.userPhones) {
          this.product.patient.regularUser.userPhones = [];
        }
      }

      if (this.product) {
        var { statusId } = this.product.status;
        if (
          statusId !== ProductEnum.Missingdetails &&
          statusId !== ProductEnum.NotRegistered
        ) {
        }
      }
      this.getCountries();
    });

    var a = this.client;

    this.productTypesService.getAll().subscribe((data) => {
      this.productTypes = data.filter((product) => product.productTypeId != 7);
    });

    //this.agentForAC = this.agents;
  }

  getCountries() {
    this.countriesService.getAll().subscribe((data) => {
      this.countries = data;
      this.createLeadFormGroup();
      if (this.product) {
        var mainAddress = this.product.patient.regularUser.getAdressObj(
          AdressTypeEnum.Main
        );

        var shippingAddress = this.product.patient.regularUser.getAdressObj(
          AdressTypeEnum.Shipping
        );
        if (!shippingAddress) {
          this.shippingCheckbox = true;
          this.copyShippingAdressFromMain();
        } else {
          if (
            mainAddress.barCityCode == shippingAddress.barCityCode &&
            mainAddress.barStreetCode == shippingAddress.barStreetCode &&
            mainAddress.entrance == shippingAddress.entrance &&
            mainAddress.apartment == shippingAddress.apartment &&
            mainAddress.shipNotes == shippingAddress.shipNotes
          ) {
            this.shippingCheckbox = true;
          }
        }
      }
    });
  }
  changeShippingCheckbox(event: MatCheckboxChange) {
    if (event.checked) {
      this.copyShippingAdressFromMain();
    }
  }
  getPhoneSettings() {
    if (this.product) {
      var regularUser = plainToClass(
        RegularUser,
        this.product.patient.regularUser
      );
      var address = regularUser.getAdressObj(AdressTypeEnum.Main);
    }
    if (this.client) {
      var regularUser = plainToClass(RegularUser, this.client?.regularUser);
      var address = regularUser?.getAdressObj(AdressTypeEnum.Main);
    }

    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
        this.getCountries();
      });
  }
  getUserPhone() {
    if (this.product) {
      var regularUser = plainToClass(
        RegularUser,
        this.product.patient.regularUser
      );
      return regularUser.getUserPhone();
    }
    if (this.client) {
      var regularUser = plainToClass(RegularUser, this.client?.regularUser);
      return regularUser.getUserPhone();
    }
  }
  getUserCounrtyId() {
    if (this.product) {
      var regularUser = plainToClass(
        RegularUser,
        this.product.patient.regularUser
      );
      return regularUser.getUserCounrtyId();
    }
    if (this.client) {
      var regularUser = plainToClass(RegularUser, this.client?.regularUser);
      return regularUser.getUserCounrtyId();
    }
  }
  customNameValidator(control: FormControl) {
    const value = control.value;

    // Remove spaces from the input value and check its length
    const trimmedValueLength = value?.trim();

    // Check if the trimmed value has at least 1 characters
    if (trimmedValueLength < 1) {
      return { minLength: true };
    }

    return null;
  }
  mapCities(results: BarCity[]) {
    if (!results) return [];
    if (results.length > 0) {
      this.cities = results;
      return this.cities.map((city: BarCity) => city.name);
    }
    //  return results
    /* var res = this.cities.map((city: BarCity): NameAndId => {
      return { name: city.name, id: city.cityCode };
    });
    if (res.length > 0) {
      console.log(res);
      return res;
    } */
  }
  mapStreets(results: BarStreet[]) {
    if (!results) return [];
    if (results.length > 0) {
      this.streets = results;
      return this.streets.map((street: BarStreet) => street.name);
    }
  }

  showAgentSelect(agentId = null) {
    //return true;
    return (
      (agentId == this.regularUser.agent.agentId &&
        userHasPermisionsWithRule(
          ['Leads_ChangeAgent_IsTargetAgent'],
          this.regularUser
        )) ||
      userHasPermisionsWithRule(['Leads_ChangeAgent'], this.regularUser)
    );
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.cityAutoComplete.openPanel();
  }

  saveAgentForLead(event) {
    /* const selectedAgent = this.agents.find((agent) => agent.name === event[0])
      .agentId; */
    this.client.agentId = event;
    this.leadForm.controls['agentId'].setValue(this.client.agentId);
  }
  saveCountries(event) {
    //this.client = event;
    this.leadForm.controls['countryId'].setValue(event);
  }
  getAgentNameById(agentId) {
    var agent = this.agents.find((a) => a.agentId == agentId);
    if (agent) return agent.name;
    else {
      this.agentNotActive = true;
      return 'manager';
    }
  }
  onCitiesAutocompleteChanged(event) {
    this.isCitySelected = false;
    var a = event.target.value;
    var citiesDup = [...this.cities];
    this.filteredCitiesOptions = citiesDup.filter((city) => {
      return city.name.indexOf(a) != -1;
    });
  }

  getCities(term: string) {
    this.citiesService.search(term).subscribe((res) => {
      this.cities = res;
      this.citiesForAC = this.cities.map((city) => city.name);
    });
  }
  onCitySelected(cityName: string, addressType: 'Main' | 'Shipping') {
    this.isCitySelected = true;
    var citiesDup = [...this.cities];
    var city = citiesDup.filter((city) => {
      return city.name == cityName;
    })[0];

    if (addressType === 'Main') {
      this.savedMainBarCity = city;
      this.mainCityCode = city.cityCode;
      this.leadForm.controls['city'].setValue(city.name);
      this.leadForm.controls['cityCode'].setValue(city.cityCode);
      this.leadForm.controls['street'].setValue('');
      this.leadForm.controls['streetCode'].setValue(null);
    } else if (addressType === 'Shipping') {
      this.savedShippingBarCity = city;
      this.shippingCityCode = city.cityCode;
      this.leadForm.controls['shippingCity'].setValue(city.name);
      this.leadForm.controls['shippingCityCode'].setValue(city.cityCode);
      this.leadForm.controls['shippingStreet'].setValue('');
      this.leadForm.controls['shippingStreetCode'].setValue(null);
    }
  }
  getSelectedStreetCode(streetName) {
    var streetsDup = [...this.streets];
    var savedBarStreet = streetsDup.filter((street) => {
      return street.name == streetName;
    })[0];
  }
  onStreetSelected(streetName: string, addressType: 'Main' | 'Shipping') {
    this.isStreetSelected = true;
    var streetsDup = [...this.streets];
    this.savedBarStreet = streetsDup.filter((street) => {
      return street.name == streetName;
    })[0];
    if (addressType === 'Main') {
      this.leadForm.controls['street'].setValue(this.savedBarStreet.name);
      this.leadForm.controls['streetCode'].setValue(
        this.savedBarStreet.streetCode
      );
    } else if (addressType === 'Shipping') {
      this.leadForm.controls['shippingStreet'].setValue(
        this.savedBarStreet.name
      );
      this.leadForm.controls['shippingStreetCode'].setValue(
        this.savedBarStreet.streetCode
      );
    }
  }

  /*onStreetsAutocompleteChanged(event) {
    this.isStreetSelected = false;
    if (!this.streets) return;
    var a = event.target.value;
    this.filteredStreetsOptions = this.streets.filter((street) => {
      return street.name.indexOf(a) != -1;
    });
  }
  
    private _filter(value: string): City[] {
      const filterValue = value.toLowerCase();
  
      return this.cities && this.cities.filter(
        (city) => city.name.toLowerCase().indexOf(filterValue) === 0
      );
    }*/

  get formLeadSourceId() {
    return this.leadForm.get('leadSourceId');
  }
  get formClientTypeId() {
    return this.leadForm.get('clientTypeId');
  }
  get formSocialSecurityNum() {
    return this.leadForm.get('socialSecurityNum');
  }
  get formFirstName() {
    return this.leadForm.get('firstName');
  }
  get formLastName() {
    return this.leadForm.get('lastName');
  }
  /* get barcode() {
    return this.leadForm.get('barcode');
  } */
  get formEmail() {
    return this.leadForm.get('email');
  }
  get formPhone() {
    return this.leadForm.get('phone');
  }
  get formAddress() {
    return this.leadForm.get('address');
  }
  get formStreet() {
    return this.leadForm.get('street');
  }
  get formStreetCode() {
    return this.leadForm.get('streetCode');
  }
  get formShippingStreet() {
    return this.leadForm.get('shippingStreet');
  }
  get formShippingStreetCode() {
    return this.leadForm.get('shippingStreetCode');
  }
  get formsStreetNum() {
    return this.leadForm.get('streetNum');
  }
  get formShippingStreetNum() {
    return this.leadForm.get('shippingStreetNum');
  }
  get formCity() {
    return this.leadForm.get('city');
  }
  get formCityCode() {
    return this.leadForm.get('cityCode');
  }
  get formShippingCity() {
    return this.leadForm.get('shippingCity');
  }
  get formShippingCityCode() {
    return this.leadForm.get('shippingCityCode');
  }
  get formShipNotes() {
    return this.leadForm.get('shipNotes');
  }
  get formShippingShipNotes() {
    return this.leadForm.get('shippingShipNotes');
  }
  get formCountry() {
    return this.leadForm.get('country');
  }
  get formGender() {
    return this.leadForm.get('gender');
  }
  get formBirthDate() {
    return this.leadForm.get('birthDate');
  }
  get formProductFor() {
    return this.leadForm.get('productFor');
  }
  get formProductType() {
    return this.leadForm.get('ProductType');
  }
  get formProductIsForId() {
    return this.leadForm.get('productIsForId');
  }

  get height() {
    return this.leadForm.get('height');
  }
  get countryId() {
    return this.leadForm.get('countryId');
  }

  getSelectedCountryFlag() {
    return this.countries.find((x) => x.countryId == this.countryId.value)
      .nameCode;
  }

  getAdressObj(type = AdressTypeEnum.Main) {
    return this.product.patient.regularUser.getAdressObj(type);
  }

  moveToEditMode() {}

  getWeight(isGoal = false) {
    return getWeightUtil(isGoal, this.product);
  }

  saveLead() {
    this.isSubmitedOnce = true;

    //var client = new Client('client');
    //if has product
    if (this.product != null) {
      if (this.leadForm.valid) {
        /*
        if (!this.isCitySelected || !this.isStreetSelected) {
          return;
        }
        */
        //this.product.patient.regularUser.regularUserId = this.product.patient.regularUser.regularUserId;
        if (
          (this.product.patient &&
            !this.product.patient.regularUser.userAdresses) ||
          this.product.patient.regularUser.userAdresses.length < 1
        ) {
          this.product.patient.regularUser.userAdresses = [new UserAdress()];
          this.product.patient.regularUser.userAdresses[0].regularUserId =
            this.product.patient.regularUser.regularUserId;
          this.product.patient.regularUser.userAdresses[0].adressTypeId = 1;
          this.product.patient.regularUser.userAdresses[0].barCity =
            new BarCity();
          this.product.patient.regularUser.userAdresses[0].barStreet =
            new BarStreet();
        } else {
          if (!this.product.patient.regularUser.userAdresses[0].barCity)
            this.product.patient.regularUser.userAdresses[0].barCity =
              new BarCity();
          if (!this.product.patient.regularUser.userAdresses[0].barStreet)
            this.product.patient.regularUser.userAdresses[0].barStreet =
              new BarStreet();
        }
        if (this.product.patient.regularUser.userAdresses.length < 2) {
          this.product.patient.regularUser.userAdresses.push(new UserAdress());
          this.product.patient.regularUser.userAdresses[1].regularUserId =
            this.product.patient.regularUser.regularUserId;
          this.product.patient.regularUser.userAdresses[1].adressTypeId =
            AdressTypeEnum.Shipping;
          this.product.patient.regularUser.userAdresses[1].barCity =
            new BarCity();
          this.product.patient.regularUser.userAdresses[1].barStreet =
            new BarStreet();
        } else {
          if (!this.product.patient.regularUser.userAdresses[1].barCity)
            this.product.patient.regularUser.userAdresses[1].barCity =
              new BarCity();
          if (!this.product.patient.regularUser.userAdresses[1].barStreet)
            this.product.patient.regularUser.userAdresses[1].barStreet =
              new BarStreet();
        }

        this.product.patient.regularUser.firstName =
          this.leadForm.value.firstName;
        this.product.patient.regularUser.lastName =
          this.leadForm.value.lastName;
        if (this.leadForm.value.socialSecurityNum != undefined)
          this.product.patient.regularUser.socialSecurityNum =
            this.leadForm.value.socialSecurityNum;
        if (this.leadForm.value.email != undefined)
          this.product.patient.regularUser.email = this.leadForm.value.email;
        if (this.leadForm.value.phone != undefined) {
          let userPhone = new UserPhone();
          userPhone.phoneNumber = this.leadForm.value.phone;
          userPhone.countryId = this.leadForm.value.countryId;
          userPhone.isActive = true;
          userPhone.isValid = false;
          userPhone.isMobile = false;
          userPhone.regularUserId =
            this.product.patient.regularUser.regularUserId;
          userPhone.phoneNumberId = 0;
          if (!this.product.patient.regularUser.userPhones) {
            this.product.patient.regularUser.userPhones = [];
          }
          if (
            this.product.patient.regularUser.getUserPhone() !==
            this.leadForm.value.phone
          ) {
            if (
              this.product.patient.regularUser.userPhones.filter(
                (x) => x.phoneNumber == this.leadForm.value.phone
              ).length > 0
            ) {
              this.product.patient.regularUser.userPhones.map((x) => {
                x.isActive = false;
                return x;
              });
              this.product.patient.regularUser.userPhones.find(
                (x) => x.phoneNumber == this.leadForm.value.phone
              ).isActive = true;
            } else {
              this.product.patient.regularUser.userPhones =
                this.product.patient.regularUser.userPhones.map((x) => {
                  x.isActive = false;
                  return x;
                });
              let userPhone = new UserPhone();
              userPhone.phoneNumber = this.leadForm.value.phone;
              userPhone.countryId = this.leadForm.value.countryId;
              userPhone.isActive = true;
              userPhone.isValid = false;
              userPhone.isMobile = false;
              userPhone.regularUserId =
                this.product.patient.regularUser.regularUserId;
              userPhone.phoneNumberId = 0;

              this.product.patient.regularUser.userPhones.push(userPhone);
            }
          }
          var regularUser = plainToClass(
            RegularUser,
            this.product.patient.regularUser
          );
          if (
            regularUser.getUserCounrtyId() !== this.leadForm.value.countryId
          ) {
            this.product.patient.regularUser.userPhones.find(
              (x) => x.phoneNumber == this.leadForm.value.phone
            ).countryId = this.leadForm.value.countryId;
          }
        }
        //// -----------------------------------adresses ------------------------------
        if (this.leadForm.value.street != undefined) {
          this.product.patient.regularUser.userAdresses[0].barStreet.name =
            this.leadForm.value.street;
          this.product.patient.regularUser.userAdresses[0].barStreet.streetCode =
            this.leadForm.value.streetCode;
          this.product.patient.regularUser.userAdresses[0].barStreet.cityCode =
            this.leadForm.value.cityCode;
          this.product.patient.regularUser.userAdresses[0].barStreetCode =
            this.leadForm.value.streetCode;
        }
        if (this.leadForm.value.streetNum != undefined)
          this.product.patient.regularUser.userAdresses[0].streetNum =
            this.leadForm.value.streetNum;
        if (this.leadForm.value.city != undefined) {
          this.product.patient.regularUser.userAdresses[0].barCity.name =
            this.leadForm.value.city;
          this.product.patient.regularUser.userAdresses[0].barCity.cityCode =
            this.leadForm.value.cityCode;
          this.product.patient.regularUser.userAdresses[0].barCityCode =
            this.leadForm.value.cityCode;
        }

        this.product.patient.regularUser.userAdresses[0].shipNotes =
          this.leadForm.value.shipNotes;
        this.product.patient.regularUser.userAdresses[0].entrance =
          this.leadForm.value.entrance;
        this.product.patient.regularUser.userAdresses[0].apartment =
          this.leadForm.value.apartment;

        if (this.leadForm.value.shippingStreet != '') {
          this.product.patient.regularUser.userAdresses[1].barStreet.name =
            this.leadForm.value.shippingStreet;
          this.product.patient.regularUser.userAdresses[1].barStreet.streetCode =
            this.leadForm.value.shippingStreetCode;
          this.product.patient.regularUser.userAdresses[1].barStreet.cityCode =
            this.leadForm.value.shippingCityCode;
          this.product.patient.regularUser.userAdresses[1].barStreetCode =
            this.leadForm.value.shippingStreetCode;
        }

        if (this.leadForm.value.shippingStreetNum != '')
          this.product.patient.regularUser.userAdresses[1].streetNum =
            this.leadForm.value.shippingStreetNum;
        if (this.leadForm.value.shippingCity != '') {
          this.product.patient.regularUser.userAdresses[1].barCity.name =
            this.leadForm.value.shippingCity;
          this.product.patient.regularUser.userAdresses[1].barCity.cityCode =
            this.leadForm.value.shippingCityCode;
          this.product.patient.regularUser.userAdresses[1].barCityCode =
            this.leadForm.value.shippingCityCode;

          this.product.patient.regularUser.userAdresses[1].shipNotes =
            this.leadForm.value.shippingShipNotes;
          this.product.patient.regularUser.userAdresses[1].entrance =
            this.leadForm.value.shippingEntrance;
          this.product.patient.regularUser.userAdresses[1].apartment =
            this.leadForm.value.shippingApartment;
        }

        if (
          !this.product.patient.regularUser.userAdresses[1].barStreetCode &&
          !this.product.patient.regularUser.userAdresses[1].barCityCode
        ) {
          this.product.patient.regularUser.userAdresses.splice(1, 1);
        }

        this.product.patient.regularUser.height = this.leadForm.value.height;
        this.product.patient.regularUser.weight = this.leadForm.value.weight;
        this.product.patient.regularUser.age = this.leadForm.value.age;
        this.product.patient.regularUser.gender = this.leadForm.value.gender;

        var birthDate = moment(this.leadForm.value.birthDate);
        birthDate.set({ hour: 4 });
        this.product.patient.regularUser.birthDate = birthDate.format(
          'YYYY-MM-DDTHH:mm:ss'
        );
        if (this.product.patient.regularUser.birthDate == 'Invalid date')
          this.product.patient.regularUser.birthDate = null;
        this.isLoading = true;
        //   this.product.patient.regularUser.birthDate = this.leadForm.value.birthDate;
        this.productsService.put(this.product).subscribe((res) => {
          if ((res as Product).productId) {
            var product = res as Product;
            this.submitError = null;
            this.isLoading = false;
            /* let patientWeightHistory = new PatientWeightHistory();
            patientWeightHistory.isGoal = false;
            patientWeightHistory.patientWeightHistoryId = 0;
            patientWeightHistory.patientId = product.patientId;
            patientWeightHistory.value = product.patient.regularUser.weight;
            patientWeightHistory.dateUpdated = moment().format('yyyy-MM-DD');
            this.weightHistoriesService
              .post(patientWeightHistory)
              .subscribe((data) => {
                this.isEditMode = false;
                this.loadProduct();
              }); */
            this.saveFormProduct.emit(product);
          } else {
            var error = res as ErrorMessage;
            this.submitError = error;
            this.isLoading = false;
          }
        });

        // this.saveFormProduct.emit(this.product);
      } else {
        const invalid = [];
        const controls = this.leadForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        var a1 = invalid;
      }
    } else {
      if (this.leadForm.valid) {
        this.client.regularUser.socialSecurityNum =
          this.leadForm.value.socialSecurityNum;
        this.client.clientTypeId = this.leadForm.value.clientTypeId;
        //this.client.clientRoleId = 1;
        //this.client.clientTypeId = this.leadForm.value.clientTypeId;
        //this.client.statusId = this.leadForm.value.clientTypeId;
        this.client.leadStatusId = this.leadForm.value.leadStatusId;
        this.client.agentId = this.leadForm.value.agentId;
        if (this.client.agent)
          this.client.agent.agentId = this.leadForm.value.agentId;
        this.client.leadSourceId = this.leadForm.value.leadSourceId;
        this.client.regularUser.firstName = this.leadForm.value.firstName;
        this.client.regularUser.lastName = this.leadForm.value.lastName;
        this.client.regularUser.email = this.leadForm.value.email;

        if (!this.client.regularUser.userPhones) {
          this.client.regularUser.userPhones = [];
        }

        var regularUser = plainToClass(RegularUser, this.client?.regularUser);
        if (regularUser.getUserPhone() !== this.leadForm.value.phone) {
          if (
            this.client.regularUser.userPhones.filter(
              (x) => x.phoneNumber == this.leadForm.value.phone
            ).length > 0
          ) {
            this.client.regularUser.userPhones.map((x) => {
              x.isActive = false;
              return x;
            });
            this.client.regularUser.userPhones.find(
              (x) => x.phoneNumber == this.leadForm.value.phone
            ).isActive = true;
          } else {
            this.client.regularUser.userPhones =
              this.client.regularUser.userPhones.map((x) => {
                x.isActive = false;
                return x;
              });
            let userPhone = new UserPhone();
            userPhone.phoneNumber = this.leadForm.value.phone;
            userPhone.countryId = this.leadForm.value.countryId;
            userPhone.isActive = true;
            userPhone.isValid = false;
            userPhone.isMobile = false;
            userPhone.regularUserId = this.client.regularUser.regularUserId;
            userPhone.phoneNumberId = 0;

            this.client.regularUser.userPhones.push(userPhone);
          }
        }
        var regularUser = plainToClass(RegularUser, this.client.regularUser);
        if (regularUser.getUserCounrtyId() !== this.leadForm.value.countryId) {
          this.client.regularUser.userPhones.find(
            (x) => x.phoneNumber == this.leadForm.value.phone
          ).countryId = this.leadForm.value.countryId;
        }

        if (
          this.classAttr === 'clientAttr' ||
          this.classAttr === 'convertToClient'
        ) {
          //this.client.regularUser = this.client.regularUser as RegularUser;
          (this.client.regularUser as RegularUser).getAdressObj(
            AdressTypeEnum.Main
          ).barStreetCode = this.leadForm.value.streetCode;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barStreet =
            this.savedBarStreet;
          // this.client.regularUser.street = this.leadForm.value.street;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).streetNum =
            this.leadForm.value.streetNum;
          this.client.regularUser.getAdressObj(
            AdressTypeEnum.Main
          ).barCityCode = this.leadForm.value.cityCode;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barCity =
            this.savedBarCity;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).countryId =
            this.leadForm.value.country;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).entrance =
            this.leadForm.value.entrance;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).apartment =
            this.leadForm.value.apartment;
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).shipNotes =
            this.leadForm.value.shipNotes;
        }
        if (this.client.futureProducts) {
          for (var i = 0; i < this.client.futureProducts.length; i++) {
            if (!this.client.futureProducts[i].productTypeId) {
              this.popupHandler.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.chooseFutureProduces
              );
              return;
            }
          }
        }
        this.client.productIsForId = this.leadForm.value.productIsForId;
        this.saveForm.emit(this.client);
      } else {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.fillAllFields
        );
      }
    }
  }
  createLeadFormGroup() {
    var phoneRegEx = '^[0-9]{1,10}$';
    phoneRegEx =
      '(?+[0-9]{1,3})? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?';
    phoneRegEx = '[0-9]{9,12}';
    /*
    this.leadForm = this.formBuilder.group({
      leadSourceId: ['', Validators.required],
      clientTypeId: ['', Validators.required],
      fullName: ['', Validators.required],
      email: [this.lead.regularUser.email, Validators.required],
      phone: [
        this.lead.regularUser.phone,
        [Validators.required, Validators.email],
      ],
      theProductIsFor: ['', [Validators.required]],
      ProductType: ['', [Validators.required]],
    });
    */
    if (this.product != null) {
      if (this.product.patient == null) {
        this.product.patient = new Patient();
        this.product.patient.regularUser = new RegularUser();
      }
      if (
        !this.product.patient.regularUser.userAdresses ||
        this.product.patient.regularUser.userAdresses.length == 0
      ) {
        var userAddress = new UserAdress();
        this.client.regularUser.userAdresses = [];
        userAddress.adressTypeId = AdressTypeEnum.Main;
        userAddress.barStreet = new BarStreet();
        userAddress.barCity = new BarCity();
        this.client.regularUser.userAdresses.push(userAddress);
      }
      /* if (
        this.product.patient.regularUser.userAdresses[0].barCityCode &&
        this.product.patient.regularUser.userAdresses[0].barStreetCode
      ) {
        this.isCitySelected = this.isStreetSelected = true;
      } */

      var ph = this.getUserPhone();

      this.leadForm = new FormGroup({
        agentId: new FormControl(null),
        leadSourceId: new FormControl(null),
        clientTypeId: new FormControl(null),
        productFor: new FormControl(null),
        firstName: new FormControl(this.product.patient.regularUser.firstName, [
          Validators.required,
        ]),
        lastName: new FormControl(this.product.patient.regularUser.lastName, [
          Validators.required,
        ]),

        socialSecurityNum: new FormControl(
          this.product.patient.regularUser.socialSecurityNum,
          [Validators.required, Validators.minLength(9)]
        ),
        email: new FormControl(this.product.patient.regularUser.email, [
          Validators.required,
          Validators.email,
        ]),
        phone: new FormControl(this.getUserPhone(), [
          Validators.required,
          Validators.pattern(phoneRegEx),
        ]),
        countryId: new FormControl(this.getUserCounrtyId(), [
          Validators.required,
        ]),

        //country: new FormControl(this.product.patient.regularUser.country),
        height: new FormControl(
          this.product.patient.regularUser.height,
          Validators.pattern('^(1[0-9]{2}|(2[0-5]0)|2[0-4][0-9])$')
        ),
        weight: new FormControl(this.getWeight()),
        /*
        age: new FormControl(this.product.patient.regularUser.age, [
          Validators.required,
        ]),
        */
        gender: new FormControl(this.product.patient.regularUser.gender),
        birthDate: new FormControl(this.product.patient.regularUser.birthDate),
        street: new FormControl(
          this.getAdressObj() ? this.getAdressObj().getStreetName() : '',
          [Validators.required]
        ),
        streetCode: new FormControl(
          this.getAdressObj() ? this.getAdressObj().barStreetCode : '',
          [Validators.required]
        ),
        streetNum: new FormControl(
          this.getAdressObj() ? this.getAdressObj().streetNum : '',
          [Validators.required]
        ),
        city: new FormControl(
          this.getAdressObj() ? this.getAdressObj().getCityName() : '',
          [Validators.required]
        ),
        cityCode: new FormControl(
          this.getAdressObj() ? this.getAdressObj().barCityCode : '',
          [Validators.required]
        ),
        entrance: new FormControl(
          this.getAdressObj() ? this.getAdressObj().entrance : ''
        ),
        shipNotes: new FormControl(
          this.getAdressObj() ? this.getAdressObj().shipNotes : ''
        ),
        apartment: new FormControl(
          this.getAdressObj() ? this.getAdressObj().apartment : ''
        ),

        shippingStreet: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).getStreetName()
            : ''
        ),
        shippingStreetCode: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).barStreetCode
            : ''
        ),
        shippingStreetNum: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).streetNum
            : ''
        ),
        shippingCity: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).getCityName()
            : ''
        ),
        shippingCityCode: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).barCityCode
            : ''
        ),
        shippingEntrance: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).entrance
            : ''
        ),
        shippingShipNotes: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).shipNotes
            : ''
        ),
        shippingApartment: new FormControl(
          this.getAdressObj(AdressTypeEnum.Shipping)
            ? this.getAdressObj(AdressTypeEnum.Shipping).apartment
            : ''
        ),
      });
    } else if (this.classAttr == 'leadAttr') {
      if (
        !this.client.regularUser.userAdresses ||
        this.client.regularUser.userAdresses.length == 0
      ) {
        this.client.regularUser.userAdresses = [];
        var userAddress = new UserAdress();
        userAddress.adressTypeId = AdressTypeEnum.Main;
        userAddress.barStreet = new BarStreet();
        userAddress.barCity = new BarCity();
        this.client.regularUser.userAdresses.push(userAddress);
      }
      this.leadForm = new FormGroup({
        /*
        socialSecurityNum: new FormControl(
          this.client.regularUser.socialSecurityNum,
          [Validators.required]
        ),*/
        leadStatusId: new FormControl(this.client.leadStatusId, [
          Validators.required,
        ]),
        agentId: new FormControl(this.client.agentId, [Validators.required]),
        leadSourceId: new FormControl(
          this.client.leadSourceId == 0 ? null : this.client.leadSourceId,
          [Validators.required]
        ),
        clientTypeId: new FormControl(
          this.client.clientTypeId == 0 ? null : this.client.clientTypeId,
          [Validators.required]
        ),
        firstName: new FormControl(this.client.regularUser.firstName, [
          Validators.required,
          this.customNameValidator,
        ]),
        lastName: new FormControl(this.client.regularUser.lastName, [
          Validators.required,
          this.customNameValidator,
        ]),
        email: new FormControl(this.client.regularUser.email, [
          Validators.required,
          Validators.email,
          //Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z+]{2,4}$/gi),
        ]),
        phone: new FormControl(this.getUserPhone(), [
          Validators.required,
          Validators.pattern('[0-9]{9,12}'),
        ]),
        countryId: new FormControl(this.getUserCounrtyId(), [
          Validators.required,
        ]),
        productIsForId: new FormControl(this.client.productIsForId, [
          Validators.required,
        ]),
        ProductType: new FormControl(this.client.clientRole.name, [
          Validators.required,
        ]),
      });
    } else {
      //client

      if (
        !this.client.regularUser.userAdresses ||
        this.client.regularUser.userAdresses.length == 0
      ) {
        this.client.regularUser.userAdresses = [];
        var userAddress = new UserAdress();
        userAddress.adressTypeId = AdressTypeEnum.Main;
        userAddress.barStreet = new BarStreet();
        userAddress.barCity = new BarCity();
        this.client.regularUser.userAdresses.push(userAddress);
      }
      this.leadForm = new FormGroup({
        socialSecurityNum: new FormControl(
          this.client.regularUser.socialSecurityNum,
          [Validators.required, Validators.minLength(9)]
        ),
        agentId: new FormControl(this.client.agentId, [Validators.required]),
        leadStatusId: new FormControl(this.client.leadStatusId),
        leadSourceId: new FormControl(this.client.leadSourceId, [
          Validators.required,
        ]),
        clientTypeId: new FormControl(this.client.clientTypeId, [
          Validators.required,
        ]),
        firstName: new FormControl(this.client.regularUser.firstName, [
          Validators.required,
        ]),
        lastName: new FormControl(this.client.regularUser.lastName, [
          Validators.required,
        ]),
        email: new FormControl(this.client.regularUser.email, [
          Validators.required,
          Validators.email,
          //Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z+]{2,4}$'),
        ]),
        phone: new FormControl(this.getUserPhone(), [
          Validators.required,
          Validators.pattern(phoneRegEx),
        ]),
        countryId: new FormControl(this.getUserCounrtyId(), [
          Validators.required,
        ]),
        street: new FormControl(
          this.client.regularUser
            .getAdressObj(AdressTypeEnum.Main)
            .getStreetName(),
          [Validators.required]
        ),
        streetCode: new FormControl(
          this.client.regularUser.getAdressObj(
            AdressTypeEnum.Main
          ).barStreetCode,
          [Validators.required]
        ),
        streetNum: new FormControl(
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).streetNum,
          [Validators.required]
        ),
        city: new FormControl(
          this.client.regularUser
            .getAdressObj(AdressTypeEnum.Main)
            .getCityName(),
          [Validators.required]
        ),
        cityCode: new FormControl(
          this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barCityCode,
          [Validators.required]
        ),
        entrance: new FormControl(
          this.client.regularUser.userAdresses[0].entrance
        ),
        apartment: new FormControl(
          this.client.regularUser.userAdresses[0].apartment
        ),
        shipNotes: new FormControl(
          this.client.regularUser.userAdresses[0].shipNotes
        ),
        /*
        country: new FormControl(this.client.regularUser.country, [
          Validators.required,
        ]),
        */
        productIsForId: new FormControl(this.client.productIsForId, [
          Validators.required,
        ]),
        ProductType: new FormControl(this.client.clientRole.name, [
          Validators.required,
        ]),
      });
    }

    setTimeout(() => {
      this.leadFormReady = true;
    }, 100);
  }
  addNewProduct() {
    if (!this.client.futureProducts) this.client.futureProducts = [];
    if (this.client.futureProducts.length < this.productTypes.length) {
      var futureProduct = new FutureProduct({
        clientId: this.client.clientId,
        amount: 1,
        productTypeId: -1,
      });

      if (this.client.futureProducts.length == 0) {
        this.remainingProductTypes.push([...this.productTypes]);
        this.client.futureProducts.push(new FutureProduct({ amount: 1 }));
      } else {
        this.remainingProductTypes.push([...this.productTypes]);
        this.client.futureProducts.push(new FutureProduct({ amount: 1 }));
      }
    }
  }
  conertFormProductToFutureProduct(futureProduct, formProduct) {
    futureProduct.clientId = this.client.clientId;
    futureProduct.amount = formProduct.amount;
    futureProduct.productTypeId = formProduct.productTypeId;
    return futureProduct;
  }
  updateProductData(event) {
    if (event.value.amount == 0) {
      this.client.futureProducts.splice(event.value.index, 1);
    } else {
      this.client.futureProducts[event.value.index] =
        this.conertFormProductToFutureProduct(
          this.client.futureProducts[event.value.index],
          event.value
        );
      this.childForms[event.value.index] = event;
      if (this.childForms.length > 1) {
      }
    }
  }
  onStatusChange(event) {
    this.client.leadStatus = this.leadStatuses.filter((x) => {
      return x.leadStatusId == event.value;
    })[0];
  }
  phoneValidator(control: FormControl) {
    let phone = control.value;

    return null;
  }
  cancel() {
    this.cancelForm.emit();
  }
  triggerToggleClick() {
    /*  let inputElement: HTMLElement = this.toggleDatepicker
      .nativeElement as HTMLElement;
    inputElement.click(); */
    let element: HTMLElement = document.querySelector(
      '#toggle button'
    ) as HTMLElement;

    element.click();
  }
  sendMail() {
    this.sendMailService
      .sendProductDetailsMail(this.client.regularUserId)
      .subscribe(() => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.mailSentsuccessfully
        );
      });
  }

  onCityChanged() {}

  copyShippingAdressFromMain() {
    /*    if (this.product.patient.regularUser.userAdresses.length == 1) {
      var userShippingAddeess = new UserAdress();
      userShippingAddeess.adressTypeId = AdressTypeEnum.Shipping;
      this.product.patient.regularUser.userAdresses.push(userShippingAddeess);
    } */
    this.leadForm.controls['shippingCity'].setValue(this.formCity.value);
    this.leadForm.controls['shippingStreet'].setValue(this.formStreet.value);
    if (this.formCityCode.value)
      this.leadForm.controls['shippingCityCode'].setValue(
        this.formCityCode.value
      );
    if (this.formStreetCode.value)
      this.leadForm.controls['shippingStreetCode'].setValue(
        this.formStreetCode.value
      );

    this.leadForm.controls['shippingStreetNum'].setValue(
      this.formsStreetNum.value
    );
    this.leadForm.controls['shippingEntrance'].setValue(
      this.leadForm.get('entrance').value
    );
    this.leadForm.controls['shippingApartment'].setValue(
      this.leadForm.get('apartment').value
    );
    this.leadForm.controls['shippingShipNotes'].setValue(
      this.formShipNotes.value
    );

    /*
    if (!this.product.patient.regularUser.getAdressObj(AdressTypeEnum.Shipping))
    {
      var userAdress=new UserAdress();
      userAdress.regularUserId=this.product.patient.regularUserId;
      userAdress.adressTypeId=AdressTypeEnum.Shipping;
      this.product.patient.regularUser.userAdresses.push(userAdress);
    }*/
  }

  objectsAreSame(x, y) {
    var objectsAreSame = true;
    for (var propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }
}
