import { Component, OnInit } from '@angular/core';
import { GeneticFormula } from 'src/app/data/GeneticFormula';
import { GeneticFormulasService } from 'src/app/Services/genetic-formulas.service';

@Component({
  selector: 'app-genetic-formula',
  templateUrl: './genetic-formula.component.html',
  styleUrls: ['./genetic-formula.component.css'],
})
export class GeneticFormulaComponent implements OnInit {
  formulas: GeneticFormula[];
  constructor(private geneticFormulasService: GeneticFormulasService) {}

  ngOnInit(): void {
    this.getAll();
  }
  getAll() {
    this.geneticFormulasService.getAll().subscribe((data) => {
      this.formulas = data;
    });
  }
  addNewFormula() {
    var formula = new GeneticFormula();
    this.geneticFormulasService.post(formula).subscribe((data) => {
      this.getAll();
    });
  }
}
