import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject } from 'rxjs';
import { FIREBASE_REFERENCES } from '../firebase.module';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnonimousAuthService {
  isFireLogged = new BehaviorSubject<any>(null);
  readonly isFireLogged$ = this.isFireLogged.asObservable();
  authStateCalls: any = null;
  authStateChat: any = null;
  constructor(
    @Inject(FIREBASE_REFERENCES.CALLS_FIREAUTH)
    private readonly afAuthCalls: AngularFireAuth,
    @Inject(FIREBASE_REFERENCES.CHAT_FIREAUTH)
    private readonly afAuthChat: AngularFireAuth
  ) {
    /*
    this.afAuthCalls.authState.subscribe((auth) => {
      console.log('afAuthCalls state' + auth);
      this.authStateCalls = auth;
    });
    this.afAuthChat.authState.subscribe((auth) => {
      console.log('afAuthCalls state' + auth);
      this.authStateChat = auth;
    });
    */
  }
  get isUserAnonymousLoggedIn(): boolean {
    return (
      this.isUserAnonymousLoggedInChat && this.isUserAnonymousLoggedInCalls
    );
  }

  get isUserAnonymousLoggedInChat(): boolean {
    return this.authStateChat !== null ? this.authStateChat.isAnonymous : false;
  }
  get isUserAnonymousLoggedInCalls(): boolean {
    return this.authStateCalls !== null
      ? this.authStateCalls.isAnonymous
      : false;
  }

  get currentUserIdChat(): string {
    return this.authStateChat !== null ? this.authStateChat.uid : '';
  }
  get currentUserIdCalls(): string {
    return this.authStateCalls !== null ? this.authStateCalls.uid : '';
  }
  anonymousLogin() {
    this.afAuthChat
      .signInAnonymously()
      .then((user) => {
        this.authStateChat = user;
        if (this.authStateChat) {
          this.isFireLogged.next(this.authStateChat);
        }
      })
      .catch((error) => console.log(error.code));

    // this.afAuthCalls
    //   .signInAnonymously()
    //   .then((user) => {
    //     this.authStateCalls = user;
    //     if (this.authStateChat && this.authStateCalls) {
    //       this.isFireLogged.next(this.authStateCalls);
    //     }
    //   })
    //   .catch((error) => console.log(error));
  }

  signOut(): void {
    this.afAuthCalls.signOut();
    this.afAuthChat.signOut();
  }
}
