import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../data/Product';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { ProductForClientMin } from '../data/ProductForClientMin';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { DatePipe } from '@angular/common';
import { ErrorMessage } from '../data/ErrorMessage';
import { ProductForTableMin } from '../data/ProductForTableMin';
import { StatusChangeObj } from '../data/StatusChangeObj';
import { ActionResponse } from '../data/ActionResponse';
import { BarcodeHistoryResponse } from '../data/BarcodeHistoryResponse';
import { SendInfo } from '../data/SendInfo';
import { ProductTable } from '../data/PoductTable';
import { map } from 'rxjs/operators';
import { dataURLToBlob } from 'blob-util';
import { plainToClass } from 'class-transformer';
import { Patient } from '../data/Patients';
import { RegularUser } from '../data/RegularUser';
import { UserAdress } from '../data/UserAdress';
import { UserGoal } from '../data/UserGoal';
import { GoalToPatient } from '../data/GoalToPatient';
import { ProductPatientDetails } from '../data/ProductPatientDetails';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  loadProduct$: Subject<boolean> = new Subject<boolean>();
  setSignedConcent(patientId, isSigned) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/setSignedConcent/${patientId}/${isSigned}`
    );
  }
  getConsentUploadPath(patientId: number, inx: any) {
    return `Products/UploadConsent/${patientId}/${inx}`;
  }
  downloadConsent(patientId: number, inx: any) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/DownloadConsent/${patientId}/${inx}`
    );
  }
  getDownloadConsentUrl(patientId: number, inx: any) {
    return `${environment.baseUrl}Products/DownloadConsent/${patientId}/${inx}`;
  }
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  /*
  search(searchTerm) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/Search/${searchTerm}`
    );
  }
  */
  searchV2(
    searchTerm: string,
    orderBy = 'date',
    acending = true,
    pageSize,
    pageNumber
  ) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/SearchV3/${searchTerm}/${orderBy}/${acending}/${pageSize}/${pageNumber}`
    );
  }
  getAll() {
    return this.http.get<Product[]>(`${environment.baseUrl}Products/`);
  }
  StatusChangeActions(productId, actionsId) {
    return this.http.get<ActionResponse[]>(
      `${environment.baseUrl}Products/StatusChangeActions/${productId}/${actionsId}`
    );
  }
  getByPatientId(patientId) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/ByPatient/${patientId}`
    );
  }
  setLifeStyleNewComplete(productId: number, value: boolean) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/setLifeStyleNewComplete/${productId}/${value}`
    );
  }
  /*
  getAllByFilter(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }Products/Filter/${this.httpFilterHelper.createPathFromHeadFilterProducts(
        headFilter,
        this.datepipe
      )}`
    );
  }*/
  getAllByFilterV2(headFilter: HeadFilter) {
    return this.http.get<ProductTable>(
      `${
        environment.baseUrl
      }Products/FilterV2/${this.httpFilterHelper.createPathFromHeadFilterProducts(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getById(productId) {
    return this.http
      .get<Product | ErrorMessage>(
        `${environment.baseUrl}Products/SingleProductV3/${productId}`
      )
      .pipe(
        map((data) => {
          if ((data as Product).productId) {
            var product = plainToClass(Product, data);
            if (product.patient) {
              product.patient = plainToClass(Patient, product.patient);
              if (product.patient.regularUser) {
                product.patient.regularUser = plainToClass(
                  RegularUser,
                  product.patient.regularUser
                );
                if (product.patient.regularUser.userAdresses)
                  product.patient.regularUser.userAdresses =
                    product.patient.regularUser.userAdresses.map((adress) =>
                      plainToClass(UserAdress, adress)
                    );
              }
            }

            return product;
          } else {
            return data;
          }
        })
      );
  }
  getByClientId(clientId) {
    return this.http.get<ProductForClientMin[]>(
      `${environment.baseUrl}Products/ClientProducts/${clientId}`
    );
  }
  put(product: Product) {
    return this.http.put<Product | ErrorMessage>(
      `${environment.baseUrl}Products/${product.productId}`,
      product
    );
  }
  post(product: Product) {
    return this.http.post<Product>(`${environment.baseUrl}Products/`, product);
  }
  delete(product: Product) {
    return this.http.delete(
      `${environment.baseUrl}Products/${product.clientId}`
    );
  }

  changeOnFollow(productId: number, onFollow: boolean) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/changeOnFollow/${productId}/${onFollow}`
    );
  }

  changePatient(product: ProductForClientMin) {
    return this.http.post<ProductForClientMin | ErrorMessage>(
      `${environment.baseUrl}Products/ChangePatient`,
      product
    );
  }
  changeBarcode(product: ProductForClientMin) {
    return this.http.post(
      `${environment.baseUrl}Products/ChangeBarcode`,
      product
    );
  }
  changeBarcodeV2(productId: number, barcode: string) {
    return this.http.get<ErrorMessage | any>(
      `${environment.baseUrl}Products/ChangeBarcodeV2/${productId}/${barcode}`
    );
  }
  /*
  changeStatusTableMin(product: ProductForTableMin) {
    return this.http.put<Product>(
      `${environment.baseUrl}Products/changeStatusTableMin/${product.productId}`,
      product
    );
  }
  changeStatusClientMin(product: ProductForClientMin) {
    return this.http.put(
      `${environment.baseUrl}Products/changeStatusClientMin/${product.productId}`,
      product
    );
  }*/
  changeStatus(
    productId: number,
    statusId: number,
    forceActions: boolean = false
  ) {
    return this.http.get<StatusChangeObj>(
      `${environment.baseUrl}Products/changeStatus/${productId}/${statusId}/${forceActions}`
    );
  }
  changeAgent(productId: number, agentId) {
    return this.http.get<StatusChangeObj>(
      `${environment.baseUrl}Products/changeAgent/${productId}/${agentId}`
    );
  }
  changeAgent2(productId: number, agent2Id) {
    return this.http.get<StatusChangeObj>(
      `${environment.baseUrl}Products/changeAgent2/${productId}/${agent2Id}`
    );
  }
  changeFirstCall(productId: number, firstCall: boolean) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/changeFirstCall/${productId}/${firstCall}`
    );
  }

  bulkChangeAgent(productIds: number[], agentId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Products/BulkChangeAgent/${agentId}`,
      productIds
    );
  }
  bulkChangeAgent2(
    productIds: number[],
    agentId: number,
    isAgent2: boolean,
    transferMissions: boolean
  ) {
    return this.http.post<any>(
      `${environment.baseUrl}Products/BulkChangeAgentV2/${agentId}/${isAgent2}/${transferMissions}`,
      productIds
    );
  }

  bulkChangeStatus(productIds: number[], statusId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Products/BulkChangeStatus/${statusId}`,
      productIds
    );
  }

  getByDealId(dealId) {
    return this.http.get<ProductForClientMin[]>(
      `${environment.baseUrl}Products/DealProducts/${dealId}`
    );
  }
  GetStatusChange() {
    return this.http.get<ProductForTableMin[]>(
      `${environment.baseUrl}Products/GetStatusChange/`
    );
  }

  getBarcodeHistory(productId: number) {
    return this.http.get<BarcodeHistoryResponse>(
      `${environment.baseUrl}Products/BarcodeHistory/${productId}`
    );
  }

  sendInfo(sendInfo: SendInfo) {
    return this.http.post<SendInfo>(
      `${environment.baseUrl}Products/SendInfo/`,
      sendInfo
    );
  }

  generateReport(productId) {
    return `${environment.baseUrl}Products/GenerateReport/${productId}`;
  }

  lockLifestyleVersion(productId, isLock) {
    return this.http.get<BarcodeHistoryResponse>(
      `${environment.baseUrl}Products/LockLifestyleVersion/${productId}/${isLock}`
    );
  }
  setLifestyleVersion(productId, versionId) {
    return this.http.get<BarcodeHistoryResponse>(
      `${environment.baseUrl}Products/setLifestyleVersion/${productId}/${versionId}`
    );
  }
  getUserGoals() {
    return this.http.get<UserGoal[]>(`${environment.baseUrl}Products/GetGoals`);
  }

  setUserGoals(goals: GoalToPatient[], productId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Products/SetGoals/${productId}`,
      goals
    );
  }

  getExpireDate(productId: number) {
    return this.http.get<ProductPatientDetails>(
      `${environment.baseUrl}Products/ExpireDateGet/${productId}`
    );
  }
  updateExpireDate(productPatientDetails: ProductPatientDetails) {
    return this.http.put<ErrorMessage | any>(
      `${environment.baseUrl}Products/ExpireDateUpdate/`,
      productPatientDetails
    );
  }
}
