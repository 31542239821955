import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/data/Company';
import { CompaniesService } from 'src/app/Services/companies.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-choose-coupon-dialog',
  templateUrl: './choose-coupon-dialog.component.html',
  styleUrls: ['./choose-coupon-dialog.component.scss'],
})
export class ChooseCouponDialogComponent implements OnInit {
  selectedCompanyId;
  companies: Company[];
  codeCoupon:string;
  constructor(
    public dialogRef: MatDialogRef<ChooseCouponDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companiesService: CompaniesService
  ) {
   
    dialogRef.disableClose = true;
    
  }
  ngOnInit(): void {
    this.companiesService.getAll().subscribe((res) => (this.companies = res));
  }
  onSourceFilterChange(event) {
    this.selectedCompanyId = event.value;
  }
  closeCoupon(){
    this.dialogRef.close({ event: 'close'});
  }
  sendCoupon(){
    if(this.selectedCompanyId && this.codeCoupon){
      this.dialogRef.close({ event: 'close', list: {
        companyId: this.selectedCompanyId,
        codeCoupon: this.codeCoupon
      }});
    }
  }
}
