<div class="title">Create Parameter</div>
<div
  class="item"
  *ngIf="
    data.availableParams?.length > 0 &&
    !selectionListTypes.includes(data.paramType.fgParamTypeId)
  "
>
  <mat-radio-group [(ngModel)]="addMode" (ngModelChange)="addModeChange()">
    <mat-radio-button class="example-radio-button" value="new">
      New
    </mat-radio-button>
    <mat-radio-button class="example-radio-button" value="list">
      List
    </mat-radio-button>
  </mat-radio-group>
</div>
<ng-container *ngIf="addMode">
  <ng-container *ngIf="addMode === 'list' && data.availableParams?.length > 0">
    <div class="item flex mt-2">
      <div class="label">Prototype:</div>
      <mat-form-field appearance="fill" class="item__select">
        <mat-select
          (selectionChange)="onSelectProptotype($event)"
          placeholder="Select"
        >
          <mat-option
            *ngFor="let item of data.availableParams"
            [value]="item.fgParamId"
          >
            {{ item.fgParamName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="addMode === 'new' || selectedPrototype">
    <div class="item flex mt-2">
      <div class="label">Name:</div>
      <mat-form-field appearance="fill" class="item__field">
        <input matInput [(ngModel)]="parameter.fgParamName" />
      </mat-form-field>
    </div>
    <div
      class="item flex mt-2"
      *ngIf="data.paramType.fgParamTypeId == FgParamType.StaticParam"
    >
      <div class="label">Value:</div>
      <mat-form-field appearance="fill" class="item__field">
        <input matInput type="number" [(ngModel)]="parameter.fgParamValue" />
      </mat-form-field>
    </div>
    <div
      class="item flex mt-2"
      *ngIf="selectionListTypes.includes(data.paramType.fgParamTypeId)"
    >
      <div class="label">Select type:</div>
      <mat-form-field class="item__select" appearance="fill">
        <mat-select [(ngModel)]="parameter.fgParamKeyId" placeholder="Select">
          <mat-option
            *ngFor="let item of data.coefficients"
            [value]="item.fgCoefficientId"
          >
            {{ item.fgCoefficientName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item flex mt-2" *ngIf="nutrients">
      <div class="label">Select nutrient:</div>
      <mat-form-field class="item__select" appearance="fill">
        <mat-select
          [disabled]="addMode === 'list'"
          (selectionChange)="onSelectNutrient($event)"
          [(ngModel)]="parameter.fgParamKeyId"
          placeholder="Select"
        >
          <mat-option *ngFor="let item of nutrients" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item flex mt-2" *ngIf="alergans">
      <div class="label">Select alergan:</div>
      <mat-form-field class="item__select" appearance="fill">
        <mat-select
          [disabled]="addMode === 'list'"
          (selectionChange)="onSelectAlergan($event)"
          [(ngModel)]="parameter.fgParamKeyId"
          placeholder="Select"
        >
          <mat-option *ngFor="let item of alergans" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item flex mt-2" *ngIf="bloodTest">
      <div class="label">Select Blood test:</div>
      <mat-form-field class="item__select" appearance="fill">
        <mat-select
          [disabled]="addMode === 'list'"
          (selectionChange)="onSelectBloodTest($event)"
          [(ngModel)]="parameter.fgParamKeyId"
          placeholder="Select"
        >
          <mat-option *ngFor="let item of bloodTest" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item flex mt-2" *ngIf="alertParam">
      <div class="label">Select Alert:</div>
      <mat-form-field class="item__select" appearance="fill">
        <mat-select
          [disabled]="addMode === 'list'"
          (selectionChange)="onSelectAlertParam($event)"
          [(ngModel)]="parameter.fgParamKeyId"
          placeholder="Select"
        >
          <mat-option *ngFor="let item of alertParam" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
<div class="actions mt-4">
  <button
    [disabled]="!parameter?.fgParamName"
    class="save"
    mat-button
    (click)="create()"
    type="submit"
  >
    Create
  </button>
  <button class="cancel" mat-button mat-dialog-close type="button">
    Cancel
  </button>
</div>
<!-- [disabled]="
      !formula?.formulaName || !formula?.formulaType || !formula?.orderBy
    " -->
