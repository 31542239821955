<div
  class="lifestyle"
  style="width: 90rem; max-width: 100%; margin: 0 auto; display: flex"
>
  <div style="text-align: center; margin-right: 30px">עמוד מס' {{ step }}</div>
  <!--
  <div style="margin-right: auto" class="backBtn" (click)="goBack()">
    חזרה >
  </div>
  -->
  <mat-card class="lifestyle__container">
    <!--
    <div class="lifestyle__title">שאלון אורח חיים</div>
      -->
    <form
      (ngSubmit)="saveThis1()"
      *ngIf="lifestyleForm1 && step == 1"
      [formGroup]="lifestyleForm1"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">סוג התזונה</div>
      <div class="mb-4 lifestyle__form__field grid" id="nutTypes">
        <div
          *ngFor="
            let item of lifestyleForm1.get('nutritionTypes')['controls'];
            let i = index
          "
        >
          <mat-checkbox [formControl]="item">{{
            nutritionOptions[i].name
          }}</mat-checkbox>
        </div>
      </div>
      <div *ngIf="otherNutritionText" class="mb-4 lifestyle__form__field">
        <mat-label class="other-title">אחר:</mat-label>
        <mat-form-field style="width: 100%" appearance="fill">
          <textarea
            placeholder="סוג תזונה..."
            formControlName="otherNutritionText"
            matInput
          ></textarea>
        </mat-form-field>
      </div>

      <!-- sensitivityToFood -->

      <div class="mb-4 lifestyle__form__field">
        <mat-label class="lifestyle__form__field__title">
          רגישויות / אלרגיות למזונות:</mat-label
        >
        <mat-form-field style="width: 100%" appearance="fill">
          <textarea
            placeholder="אני רגיש ל.."
            formControlName="sensitivityToFood"
            matInput
          ></textarea>
        </mat-form-field>
      </div>
      <div class="mb-4 lifestyle__form__field">
        <mat-label class="lifestyle__form__field__title"
          >מאכלים שאת/ה נוהג להימנע מהם או פחות אוהב/ת</mat-label
        >
        <mat-form-field style="width: 100%" appearance="fill">
          <textarea
            placeholder="אני נמנע או פחות אוהב.."
            formControlName="foodToAvoid"
            matInput
          ></textarea>
        </mat-form-field>
      </div>

      <p class="lifestyle__form__field__title">בעיות עיכול?</p>
      <div class="mb-4 lifestyle__form__field grid" id="digestProbs">
        <p
          *ngFor="
            let item of lifestyleForm1.get('digestionProblems')['controls'];
            let i = index
          "
        >
          <mat-checkbox [formControl]="item">{{
            digestionOptions[i].name
          }}</mat-checkbox>
        </p>
      </div>
      <div *ngIf="otherDigestionText" class="mb-4 lifestyle__form__field">
        <mat-label class="other-title">אחר:</mat-label>
        <mat-form-field style="width: 100%" appearance="fill">
          <textarea formControlName="otherDigestionText" matInput></textarea>
        </mat-form-field>
      </div>
      <div class="errors" *ngIf="lifestyleForm1Error">
        <div *ngIf="!lifestyleForm1.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; שדות חסרים:
        </div>
        <div
          *ngIf="
            lifestyleForm1.controls.nutritionTypes.errors &&
            lifestyleForm1.controls.nutritionTypes.errors.required
          "
        >
          סוג התזונה
        </div>
        <div
          *ngIf="
            otherNutritionText &&
            lifestyleForm1.controls.otherNutritionText.errors &&
            lifestyleForm1.controls.otherNutritionText.errors.required
          "
        >
          סוג התזונה - אחר
        </div>
        <div
          *ngIf="
            lifestyleForm1.controls.sensitivityToFood.errors &&
            lifestyleForm1.controls.sensitivityToFood.errors.required
          "
        >
          רגישויות / אלרגיות למזונות:
        </div>
        <div
          *ngIf="
            lifestyleForm1.controls.foodToAvoid.errors &&
            lifestyleForm1.controls.foodToAvoid.errors.required
          "
        >
          מאכלים שאת/ה נוהג להימנע מהם או פחות אוהב/ת
        </div>
        <div
          *ngIf="
            lifestyleForm1.controls.digestionProblems.errors &&
            lifestyleForm1.controls.digestionProblems.errors.required
          "
        >
          בעיות עיכול
        </div>
        <div
          *ngIf="
            otherDigestionText &&
            lifestyleForm1.controls.otherDigestionText.errors &&
            lifestyleForm1.controls.otherDigestionText.errors.required
          "
        >
          בעיות עיכול - אחר
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" disabled (click)="prevStep()" mat-button>
          הקודם
        </button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>
    <!--lifestyle form 2-->
    <form
      *ngIf="lifestyleForm2 && step == 2"
      (ngSubmit)="saveThis2()"
      [formGroup]="lifestyleForm2"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">
        הרגלי שתייה- מספר כוסות ביום: (טווח 0 – 20)
      </div>
      <div class="lifestyle__form__field grid-2">
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">מים</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              type="text"
              formControlName="waterNumGlassesPerDay"
            />
          </mat-form-field>
        </div>
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">שתיה מתוקה/מוגזת</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="sweetNumGlassesPerDay"
            />
          </mat-form-field>
        </div>
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">שתייה דיאטטית</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="dietNumGlassesPerDay"
            />
          </mat-form-field>
        </div>
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">קפה</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="coffeePerDay"
            />
          </mat-form-field>
        </div>
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">תה</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="teaPerDay"
            />
          </mat-form-field>
        </div>
        <div class="lifestyle__form__field">
          <div class="lifestyle__form__field__subtitle">אלכוהול</div>
          <mat-form-field appearance="fill">
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="alcoholPerDay"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="errors" *ngIf="lifestyleForm2Error">
        <div *ngIf="!lifestyleForm2.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; שדות חסרים:
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.waterNumGlassesPerDay.errors &&
            lifestyleForm2.controls.waterNumGlassesPerDay.errors.required
          "
        >
          מים
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.sweetNumGlassesPerDay.errors &&
            lifestyleForm2.controls.sweetNumGlassesPerDay.errors.required
          "
        >
          שתיה מתוקה/מוגזת
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.dietNumGlassesPerDay.errors &&
            lifestyleForm2.controls.dietNumGlassesPerDay.errors.required
          "
        >
          שתייה דיאטטית
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.coffeePerDay.errors &&
            lifestyleForm2.controls.coffeePerDay.errors.required
          "
        >
          קפה
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.teaPerDay.errors &&
            lifestyleForm2.controls.teaPerDay.errors.required
          "
        >
          תה
        </div>
        <div
          *ngIf="
            lifestyleForm2.controls.alcoholPerDay.errors &&
            lifestyleForm2.controls.alcoholPerDay.errors.required
          "
        >
          אלכוהול
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>
    <!--lifestyle form 3-->
    <form
      *ngIf="lifestyleForm3 && step == 3"
      (ngSubmit)="saveThis3()"
      [formGroup]="lifestyleForm3"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">
        הרגלי אכילה- יום טיפוסי: נא לרשום בפירוט רב
      </div>
      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle">בוקר</mat-label>
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="eatingHabitsMorning" matInput> </textarea>
        </mat-form-field>
      </div>
      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle">ביניים</mat-label>
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="eatingHabitsMiddle1" matInput> </textarea>
        </mat-form-field>
      </div>
      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle">צהריים</mat-label>
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="eatingHabitsLunch" matInput> </textarea>
        </mat-form-field>
      </div>
      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle">ביניים</mat-label>
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="eatingHabitsMiddle2" matInput> </textarea>
        </mat-form-field>
      </div>
      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle">ערב</mat-label>
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="eatingHabitsSupper" matInput> </textarea>
        </mat-form-field>
      </div>
      <div class="errors" *ngIf="lifestyleForm3Error">
        <div *ngIf="!lifestyleForm3.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; שדות חסרים:
        </div>
        <div
          *ngIf="
            lifestyleForm3.controls.eatingHabitsMorning.errors &&
            lifestyleForm3.controls.eatingHabitsMorning.errors.required
          "
        >
          בוקר
        </div>
        <div
          *ngIf="
            lifestyleForm3.controls.eatingHabitsMiddle1.errors &&
            lifestyleForm3.controls.eatingHabitsMiddle1.errors.required
          "
        >
          בוקר - ביניים
        </div>
        <div
          *ngIf="
            lifestyleForm3.controls.eatingHabitsLunch.errors &&
            lifestyleForm3.controls.eatingHabitsLunch.errors.required
          "
        >
          צהריים
        </div>
        <div
          *ngIf="
            lifestyleForm3.controls.eatingHabitsMiddle2.errors &&
            lifestyleForm3.controls.eatingHabitsMiddle2.errors.required
          "
        >
          צהריים - ביניים
        </div>
        <div
          *ngIf="
            lifestyleForm3.controls.eatingHabitsSupper.errors &&
            lifestyleForm3.controls.eatingHabitsSupper.errors.required
          "
        >
          ערב
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>

    <!--lifestyleForm4-->
    <form
      *ngIf="lifestyleForm4 && step == 4"
      (ngSubmit)="saveThis4()"
      [formGroup]="lifestyleForm4"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">בריאות ואורח חיים</div>

      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle"
          >תרופות/ תוספי תזונה שנלקחים באופן קבוע:</mat-label
        >
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="drugsTakenText" matInput> </textarea>
        </mat-form-field>
      </div>

      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle"
          >באיזה שעה הינך קם/קמה בבוקר:</mat-label
        >
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="getUpHour" matInput> </textarea>
        </mat-form-field>
      </div>

      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle"
          >באיזו שעה הינך הולך/הולכת לישון:</mat-label
        >
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="sleepHours" matInput> </textarea>
        </mat-form-field>
      </div>

      <div class="lifestyle__form__field">
        <mat-label class="lifestyle__form__field__subtitle"
          >מה היית רוצה לשפר בהרגלי האכילה? במה חשוב לך להתמקד? ציין בקצרה את
          המטרה:</mat-label
        >
        <mat-form-field appearance="fill" style="width: 100%">
          <textarea formControlName="whatToAprrove" matInput> </textarea>
        </mat-form-field>
      </div>

      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">עישון</div>
        <mat-radio-group
          formControlName="isSmoking"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            כן
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            כיום לא, בעבר כן
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            לא, אף פעם
          </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="lifestyleForm4.controls.isSmoking.value === '0'">
          <div class="lifestyle__form__field">
            <div class="lifestyle__form__field__subtitle">
              מספר סיגריות ליום
            </div>
            <mat-form-field style="width: 100%" appearance="fill">
              <textarea matInput formControlName="smokingYesTextNum"></textarea>
            </mat-form-field>
          </div>
          <div class="lifestyle__form__field">
            <div class="lifestyle__form__field__subtitle">זמן עישון בשנים</div>
            <mat-form-field style="width: 100%" appearance="fill">
              <textarea
                matInput
                formControlName="smokingYesTextYears"
              ></textarea>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="lifestyleForm4.controls.isSmoking.value === '1'">
          <div class="lifestyle__form__field">
            <div class="lifestyle__form__field__subtitle">כמה שנים עישנת?</div>
            <mat-form-field style="width: 100%" appearance="fill">
              <textarea
                matInput
                formControlName="smokingNoTextYears"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="lifestyle__form__field">
            <div class="lifestyle__form__field__subtitle">
              כמה שנים אתה לא מעשן?
            </div>
            <mat-form-field style="width: 100%" appearance="fill">
              <textarea matInput formControlName="smokingNoTextQuit"></textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div class="lifestyle__form__field" *ngIf="isFemale">
        <div class="lifestyle__form__field__subtitle">האם את בהריון</div>
        <mat-radio-group
          formControlName="isPregnent"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            כן
          </mat-radio-button>
          <ng-container
            *ngIf="lifestyleForm4.controls.isPregnent.value === '0'"
          >
            <div class="lifestyle__form__field">
              <div class="lifestyle__form__field__subtitle">
                באיזה טרימסטר את
              </div>
              <mat-form-field style="width: 100%" appearance="fill">
                <textarea matInput formControlName="tremester"></textarea>
              </mat-form-field>
            </div>
          </ng-container>
          <mat-radio-button class="example-radio-button" value="1">
            לא
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">
          כמה שעות את/ה ישן בצהריים?
        </div>
        <mat-radio-group
          formControlName="noonSleep"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            כלל לא
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            חצי שעה עד שעה
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            בין שעה לשעתיים
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            מעל שעתיים
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">עיסוק:</div>
        <mat-radio-group
          formControlName="occupation"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            עבודה פיזית
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            עבודה משרדית
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            סטודנט
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            לא עובד
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">
          באילו שעות את/אתה עובד/ת:
        </div>
        <mat-radio-group
          formControlName="workHours"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            שעות היום
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            שעות הלילה
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            לא קבוע / משמרות
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            לא עובד
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">איכות שינה:</div>
        <mat-radio-group
          formControlName="sleepQuality"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            טובה מאד
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            טובה
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            בינונית
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            אינה טובה
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">
          האם הינך מצוי/ה בלחץ (סטרס) ביום יום?
        </div>
        <mat-radio-group
          formControlName="areYouInStress"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            גבוה מאוד
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            גבוה
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            בינוני
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            נמוך
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="4">
            אין לחץ
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="lifestyle__form__field">
        <div class="lifestyle__form__field__subtitle">
          כיצד הינך מגדיר את מצבך הרפואי
        </div>
        <mat-radio-group
          formControlName="healthStatus"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="0">
            טוב מאוד
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1">
            טוב
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">
            לא טוב
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">
            גרוע מאוד
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="errors" *ngIf="lifestyleForm4Error">
        <div *ngIf="!lifestyleForm4.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; שדות חסרים:
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.isSmoking.errors &&
            lifestyleForm4.controls.isSmoking.errors.required
          "
        >
          עישון
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.smokingYesTextNum.errors &&
            lifestyleForm4.controls.smokingYesTextNum.errors.required
          "
        >
          מספר סיגריות ליום
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.smokingYesTextYears.errors &&
            lifestyleForm4.controls.smokingYesTextYears.errors.required
          "
        >
          זמן עישון בשנים
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.smokingNoTextYears.errors &&
            lifestyleForm4.controls.smokingNoTextYears.errors.required
          "
        >
          כמה שנים עישנת
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.smokingNoTextQuit.errors &&
            lifestyleForm4.controls.smokingNoTextQuit.errors.required
          "
        >
          כמה שנים אתה לא מעשן
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.isPregnent.errors &&
            lifestyleForm4.controls.isPregnent.errors.required
          "
        >
          האם את בהריון
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.tremester.errors &&
            lifestyleForm4.controls.tremester.errors.required
          "
        >
          באיזה טרימסטר את
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.getUpHour.errors &&
            lifestyleForm4.controls.getUpHour.errors.required
          "
        >
          באיזה שעה הינך קם/קמה בבוקר
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.sleepHours.errors &&
            lifestyleForm4.controls.sleepHours.errors.required
          "
        >
          באיזו שעה הינך הולך/הולכת לישון
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.noonSleep.errors &&
            lifestyleForm4.controls.noonSleep.errors.required
          "
        >
          כמה שעות את/ה ישן בצהריים
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.workHours.errors &&
            lifestyleForm4.controls.workHours.errors.required
          "
        >
          באילו שעות את/אתה עובד/ת
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.sleepQuality.errors &&
            lifestyleForm4.controls.sleepQuality.errors.required
          "
        >
          איכות שינה
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.areYouInStress.errors &&
            lifestyleForm4.controls.areYouInStress.errors.required
          "
        >
          האם הינך מצוי/ה בלחץ (סטרס) ביום יום
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.whatToAprrove.errors &&
            lifestyleForm4.controls.whatToAprrove.errors.required
          "
        >
          מה היית רוצה לשפר בהרגלי האכילה
        </div>
        <div
          *ngIf="
            lifestyleForm4.controls.healthStatus.errors &&
            lifestyleForm4.controls.healthStatus.errors.required
          "
        >
          כיצד הינך מגדיר את מצבך הרפואי
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>

    <!--lifestyleForm5-->
    <form
      *ngIf="lifestyleForm5 && step == 5"
      (ngSubmit)="saveThis5()"
      [formGroup]="lifestyleForm5"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">פעילות גופנית</div>

      <mat-radio-group
        formControlName="isExercising"
        class="example-radio-group"
      >
        <mat-radio-button class="example-radio-button" value="0">
          כן
        </mat-radio-button>

        <div
          class="lifestyle__form__field"
          *ngIf="lifestyleForm5.controls.isExercising.value == 0"
          style="margin-right: 3rem"
        >
          <div class="lifestyle__form__field__subtitle">
            ציין את סוגי הפעילות הגופנית שאתה מבצע על בסיס קבוע (ניתן לבחור כמה
            תחומים):
          </div>
          <br />
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="yoga">יוגה</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.yoga.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="yogaDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="yogaNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="pilates">פילאטיס</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.pilates.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="pilatesDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="pilatesNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="dance">ריקוד</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.dance.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="danceDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="danceNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="walk">הליכה</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.walk.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="walkDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="walkNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="run">ריצה</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.run.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="runDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="runNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="gym">מכון כושר</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.gym.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="gymDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="gymNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="swim">שחייה</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.swim.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="swimDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="swimNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="bicycle">אופניים</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.bicycle.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="bicycleDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="bicycleNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="tennis">טניס</mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.tennis.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="tennisDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="tennisNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="basketball">כדורסל</mat-checkbox>
            <div
              class="grid-2"
              *ngIf="lifestyleForm5.controls.basketball.value"
            >
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="basketballDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="basketballNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="football">כדורגל </mat-checkbox>
            <div class="grid-2" *ngIf="lifestyleForm5.controls.football.value">
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="footballDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="footballNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="lifestyle__form__field">
            <mat-checkbox formControlName="ifOtherSport">אחר</mat-checkbox>
            <div
              class="grid-2"
              *ngIf="lifestyleForm5.controls.ifOtherSport.value"
            >
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">שם הפעילות</div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="שם הפעילות.."
                    formControlName="otherSport"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  משך זמן הפעילות
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="משך זמן הפעילות.."
                    formControlName="otherSportDuration"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="lifestyle__form__field">
                <div class="lifestyle__form__field__subtitle">
                  מס פעמים בשבוע
                </div>
                <mat-form-field appearance="fill">
                  <input
                    placeholder="מס פעמים בשבוע.."
                    formControlName="otherSportNumInWeek"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <br />
        </div>

        <mat-radio-button class="example-radio-button" value="1">
          לא
        </mat-radio-button>
      </mat-radio-group>
      <div class="errors" *ngIf="lifestyleForm5Error">
        <div *ngIf="!lifestyleForm5.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; שדות חסרים:
        </div>
        <div
          *ngIf="
            lifestyleForm5.controls.isExercising.errors &&
            lifestyleForm5.controls.isExercising.errors.required
          "
        >
          פעילות גופנית
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>

    <!--lifestyleForm6-->
    <form
      *ngIf="lifestyleForm6 && step == 6"
      (ngSubmit)="saveThis6()"
      [formGroup]="lifestyleForm6"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">מחלות עבר והווה</div>
      <ng-container
        *ngFor="let dieseases of lifeStyleDiseaseOptions; let i = index"
        formArrayName="dieseases"
      >
        <mat-card [formGroupName]="i" class="mb-2">
          <mat-checkbox
            (checked)="ifChecked($event, i)"
            formControlName="ifActive"
            class="checkbox-name"
          >
            {{ dieseases.name }}</mat-checkbox
          >
          <br />
          <input
            type="hidden"
            formControlName="dieseasName"
            value="{{ dieseases.name }}"
          />
          <div
            *ngIf="showDiseasesFields(i) && dieseases.name !== 'אין מחלה ידועה'"
          >
            <ng-container *ngIf="dieseases.showDiseaseName">
              <div class="lifestyle__form__field dieseas">
                <label
                  class="lifestyle__form__field__subtitle dieseas__title"
                  >{{
                    dieseases.newPlaceholder
                      ? dieseases.newPlaceholder
                      : "שם המחלה"
                  }}</label
                >
                <mat-form-field appearance="fill" style="width: 100%">
                  <input
                    matInput
                    formControlName="dieseasName2"
                    [placeholder]="
                      dieseases.newPlaceholder
                        ? dieseases.newPlaceholder
                        : 'שם המחלה'
                    "
                  />
                </mat-form-field>
              </div>
            </ng-container>
            <div class="lifestyle__form__field dieseas">
              <label class="lifestyle__form__field__subtitle dieseas__title">{{
                dieseases.newPlaceholder2
                  ? dieseases.newPlaceholder2
                  : "מתי התחילה"
              }}</label>
              <mat-form-field appearance="fill" style="width: 100%">
                <input
                  matInput
                  formControlName="whenStarted"
                  [placeholder]="
                    dieseases.newPlaceholder2
                      ? dieseases.newPlaceholder2
                      : 'מתי התחילה'
                  "
                />
              </mat-form-field>
            </div>
            <div class="lifestyle__form__field dieseas">
              <label class="lifestyle__form__field__subtitle dieseas__title"
                >טיפול תרופתי</label
              >
              <mat-form-field appearance="fill" style="width: 100%">
                <input
                  matInput
                  formControlName="medCare"
                  placeholder="טיפול תרופתי"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </ng-container>

      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>הבא</button>
      </div>
    </form>

    <!--lifestyleForm7-->
    <form
      *ngIf="lifestyleForm7 && step == 7"
      (ngSubmit)="saveThis7()"
      [formGroup]="lifestyleForm7"
      class="lifestyle__form"
    >
      <div class="lifestyle__form__field__title">דפוסי אכילה</div>
      <ng-container
        *ngFor="let label of eatingPatternLabels; let i = index"
        formArrayName="eatingPattern"
      >
        <div [formGroupName]="i" class="lifestyle__form__field mb-2">
          <label class="lifestyle__form__field__subtitle">{{ label }} </label>
          <mat-radio-group formControlName="value" class="example-radio-group">
            <mat-radio-button
              *ngFor="let pattern of eatingPatternData"
              class="example-radio-button"
              [value]="pattern"
            >
              {{ pattern }}
            </mat-radio-button>
          </mat-radio-group>
          <input type="hidden" formControlName="name" />
        </div>
      </ng-container>
      <div class="errors" *ngIf="lifestyleForm7Error">
        <div *ngIf="!lifestyleForm7.valid">
          <img
            style="height: 20px"
            src="../../../assets/error-icon.svg"
            alt=""
          />
          &nbsp; נראה שמשהו חסר אנא בדוק/י שוב את הטופס
        </div>
      </div>
      <div class="action-btns">
        <button class="prev" (click)="prevStep()" mat-button>הקודם</button>
        <button class="next" mat-button>סיום</button>
      </div>
    </form>
  </mat-card>
</div>
