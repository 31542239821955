<div style="display: flex;">
    <img src="../../../assets/note_pink.svg">
    <div style="display: flex;">
        <span class="text--test">Patient LifeStle Report sent to Patient</span>
        <img src="../../../assets/calendar-60.svg">
        6 May 2020
        <img src="../../../assets/clock.svg">
        14:00
    </div>
    <div>bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla</div>
</div>