import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { TubeTestResult } from '../data/TubeTestResult';
import { TubeTestResultForApp } from '../data/TubeTestResultForApp';
import { TubeTestBullet } from '../data/TubeTestBullet';

@Injectable({
  providedIn: 'root',
})
export class TubeTestResultsService {
  constructor(private http: HttpClient) {}
  getByBarcode(barcode) {
    return this.http.get<TubeTestResult[]>(
      `${environment.baseUrl}TubeTestResults/${barcode}`
    );
  }
  getByBarcodeAndCategory(barcode, categoryId, langId = 1) {
    return this.http.get<TubeTestResultForApp[]>(
      `${environment.baseUrl}TubeTestResults/CategoryLang/${barcode}/${categoryId}/${langId}`
    );
  }

  getByBarcodeAndCategoryV1(barcode, categoryId) {
    return this.http.get<TubeTestResult[]>(
      `${environment.baseUrl}TubeTestResults/Category/${barcode}/${categoryId}`
    );
  }

  getTubeTestResults(barcode) {
    return this.http.get<TubeTestBullet[]>(
      `${environment.baseUrl}TubeTestResults/GetTubeTestResults/${barcode}`
    );
  }
}
