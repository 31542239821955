<div class="phone-vrification">
  <table
    class="phone-vrification__table"
    mat-table
    [dataSource]="phoneVerificationSettings"
  >
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>Country</th>
      <td mat-cell *matCellDef="let element">
        <img
          src="https://flagcdn.com/16x12/{{
            element.country.nameCode | lowercase
          }}.png"
        />
        {{ element.country.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="countryCode">
      <th mat-header-cell *matHeaderCellDef>Country code</th>
      <td mat-cell *matCellDef="let element">{{ element.countryCode }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width: 110px">
        <button
          [routerLink]="['/phone-verification-setting/edit']"
          [queryParams]="{ countryId: 0 }"
          mat-icon-button
          class="add"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          [routerLink]="['/phone-verification-setting/edit']"
          [queryParams]="{ countryId: element.countryId }"
          mat-icon-button
          class="edit"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          (click)="delete(element.countryId)"
          mat-icon-button
          class="delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
