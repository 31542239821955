<div class="row-container" *ngIf="client != null">
  <mat-list>
    <mat-list-item>
      <div class="d-flex-between">
        <span *ngIf="classAttr == 'clientAttr'" class="card-title"
          >Client Profile
        </span>
        <span *ngIf="classAttr == 'patientAttr'" class="card-title"
          >Patient details
        </span>
        <span *ngIf="classAttr == 'clientOnPatientAttr'" class="card-title"
          >Profile
        </span>
        <span *ngIf="classAttr == 'leadAttr'" class="card-title"
          >Lead Profile
          <!-- -
          <span *ngIf="isDuplicate" style="font-weight: 400; color: #f44336;"
            >Exists In Clients</span
          > -->
        </span>

        <button
          class="rect-action-btn rect-action-btn--client"
          *ngIf="classAttr !== 'clientOnPatientAttr'"
          [ngStyle]="classAttr == 'leadAttr' && { 'margin-left': '140px' }"
          (click)="moveToEditMode()"
          [class.rect-action-btn--client]="classAttr == 'clientAttr'"
          [class.rect-action-btn--lead]="classAttr == 'leadAttr'"
          mat-button
        >
          Edit
        </button>
        <button
          class="rect-action-btn rect-action-btn--client"
          *ngIf="displayMailBtn && classAttr == 'leadAttr'"
          [ngStyle]="classAttr == 'leadAttr' && { 'margin-left': '140px' }"
          (click)="sendMail()"
          [class.rect-action-btn--client]="classAttr == 'clientAttr'"
          [class.rect-action-btn--lead]="classAttr == 'leadAttr'"
          mat-button
        >
          Send Mail
        </button>
        <!--getStatusSelectColor();-->
        <!--*ngIf="classAttr == 'leadAttr'"-->

        <span *ngIf="!userHasPermisions(['Leads_ChangeStatus'])"
          ><span style="font-weight: bold">Status: </span>
          {{ client.leadStatus.name }}</span
        >

        <mat-form-field
          *ngIf="
            classAttr == 'leadAttr' && userHasPermisions(['Leads_ChangeStatus'])
          "
          style="margin-left: auto; margin-right: 20px"
          class="example-full-width round-select"
        >
          <mat-select
            class="lead-status-select"
            placeholder="Choose Status"
            disableOptionCentering
            (selectionChange)="onStatusChange($event)"
            [style.background-color]="getStatusSelectColor()"
            [value]="client.leadStatusId"
          >
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.leadStatusId"
              class="lead-status lead-status{{ status.leadStatusId }}"
              >{{ status.name }}</mat-option
            >
            <!--<option
        *ngFor="let clientType of clientTypes"
        [ngValue]="clientType.clientTypeId"
      >
        {{ clientType.name }}
      </option>-->
          </mat-select>
        </mat-form-field>

        <!--(click)="saveLead()"-->

        <span *ngIf="true"
          ><span class="card-title">Agent:</span>
          {{
            client &&
              client.agent &&
              client.agent.regularUser &&
              client.agent.regularUser.firstName
          }}</span
        >
      </div>
    </mat-list-item>
    <div
      class="divider"
      [class.divider--client]="classAttr == 'clientAttr'"
      [class.divider--lead]="classAttr == 'leadAttr'"
      [class.divider--test]="classAttr == 'clientOnPatientAttr'"
    ></div>

    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Custumer Type: </span>
      {{ client.clientType && client.clientType.name }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Full Name: </span>
      <span
        *ngIf="classAttr == 'clientOnPatientAttr'"
        (click)="gotoClient()"
        style="text-decoration: underline; color: blue; cursor: pointer"
      >
        {{ getName(client.regularUser) }}
      </span>
      <span *ngIf="classAttr != 'clientOnPatientAttr'">
        {{ getName(client.regularUser) }}
      </span>
    </mat-list-item>
    <mat-list-item class="row-container__item" *ngIf="classAttr != 'leadAttr'">
      <span
        *ngIf="classAttr == 'clientAttr' || classAttr == 'clientOnPatientAttr'"
        class="boldSpan"
      >
        Client ID:
      </span>
      <span *ngIf="classAttr == 'leadAttr'" class="boldSpan"> Lead ID: </span>
      {{ client.regularUser.socialSecurityNum }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Email: </span> {{ client.regularUser.email }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Tel: </span>
      <span style="cursor: pointer" (click)="callClientPhone(getUserPhone())">
        <img src="../../../assets/phone.svg" alt="phone" class="phone-img" />
        <ng-container *ngIf="getUserPhoneObj()">
          <img
            src="https://flagcdn.com/16x12/{{
              getUserPhoneObj().nameCode | lowercase
            }}.png"
            [matTooltip]="getUserPhoneObj().name"
          />
          ({{ getUserPhoneObj().countryCode }})
        </ng-container>

        <ng-container> {{ getUserPhone() }}</ng-container>
      </span>
    </mat-list-item>
    <mat-list-item *ngIf="classAttr !== 'leadAttr'" class="row-container__item">
      <span class="boldSpan"> Address: </span>
      <!-- {{ client.regularUser.country }},<br /> -->
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].barCity &&
          client.regularUser.userAdresses[0].barCity.name
      }},<br />
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].barStreet &&
          client.regularUser.userAdresses[0].barStreet.name
      }},
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].streetNum
      }},
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].entrance
      }},
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].apartment
      }}
    </mat-list-item>
    <mat-list-item *ngIf="classAttr !== 'leadAttr'" class="row-container__item">
      <span class="boldSpan"> Shipping Notes: </span>
      {{
        client.regularUser.userAdresses &&
          client.regularUser.userAdresses[0] &&
          client.regularUser.userAdresses[0].shipNotes
      }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Lead Source: </span>
      {{ client.leadSource && client.leadSource.name }}
    </mat-list-item>

    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Product is For: </span>
      {{ client.productIsFor && client.productIsFor.name }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Product Type: </span>
      <ng-container *ngFor="let product of client.futureProducts">
        {{ product.amount }} X {{ product.productType.productName }}
      </ng-container>
    </mat-list-item>
  </mat-list>
</div>
