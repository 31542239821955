<!--<app-head-filter  [classAttr]="'agentAttr'"
  (filterChanged)="getAllWithFilter($event)" [headFilter]="headFilter"></app-head-filter>
-->

<mat-form-field
  appearance="fill"
  class="upgrade"
  style="padding-top: 1.34375em; margin-right: 30px"
>
  <mat-label>Role:</mat-label>
  <mat-select
    (selectionChange)="onRoleFilterChange($event)"
    matNativeControl
    disableOptionCentering
    [(ngModel)]="selectedRoleTypeId"
  >
    <!-- formControlName="agentId"-->
    <mat-option [value]="-1" selected> All </mat-option>
    <mat-option *ngFor="let roleType of userRoles" [value]="roleType.id">
      {{ roleType.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<app-agents-table
  [agentsData]="agentsData"
  [userRoles]="userRoles"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event)"
>
</app-agents-table>
<!--
<app-table-paginator *ngIf="agentsData" [headFilter]="headFilter" [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event)" [classAttr]="'clientsAttr'">
</app-table-paginator>
-->

<!--
<table mat-table [dataSource]="agents" class="mat-elevation-z8">


  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
  </ng-container>


  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>


  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef> Weight </th>
    <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
  </ng-container>


  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef> Symbol </th>
    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<button (click)="addNewAgent()" mat-raised-button color="primary">add Agent</button>\
-->
