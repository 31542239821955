<div>
  <div class="recomendations">
    <!-- <div
      class="recomendations__cta"
      *ngIf="recomendationsStep == -1"
      [@fade]="'in'"
    >
      <div>
        <div style="display: flex; justify-content: flex-end">
          <button mat-button class="back-btn" (click)="goBack()">חזרה ></button>
        </div>
        <div class="recomendations__title">המלצות</div>
        <div class="recomendations__hr"></div>
        <div class="recomendations__desc">
          דוח שילובים נועד לשקלל את הנטיות הגנטיות שלכם יחד עם ערכי בדיקות הדם.
          התוצאות יאפשרו לכם לקבל המלצות אורח חיים המותאמות באופן המדוייק ביותר
          עבורכם
        </div>
        <div *ngIf="!hasBloodTest" class="recomendations__disclaimer">
          <div class="recomendations__disclaimer__warning">
            <mat-icon>warning</mat-icon>
          </div>
          לא הוזנו ערכי בדיקות דם, ולכן תוצאות השילובים אינן מותאמות באופן
          המדוייק ביותר. ממולת להזין את ערכי בדיקות הדם לקבל שילובים מתאימים
        </div>
      </div>
      <button
        class="recomendations__cta-btn"
        (click)="incStep()"
        mat-raised-button
      >
        גלה עוד
      </button>
    </div> -->

    <div class="recomendations__page" #pageRef>
      <div
        style="width: 90%; max-width: 800px; margin: 0 auto; padding-top: 2rem"
      >
        <ngx-skeleton-loader
          *ngIf="isLoading"
          [theme]="{ height: '50px' }"
          count="5"
        ></ngx-skeleton-loader>
      </div>
      <ng-container *ngIf="!isLoading">
        <div class="recomendations__page__title">המלצות ליישום</div>
        <div class="recomendations__page__container">
          <div dir="ltr" style="width: 90%; max-width: 800px; margin: 0 auto">
            <button
              mat-button
              class="recomendations__page__back"
              (click)="goBack()"
            >
              חזרה >
            </button>
            <div class="scroll-bar">
              <ng-container
                *ngFor="
                  let item of recomendations;
                  let index = index;
                  let last = last
                "
              >
                <!--(click)="scrollToRec(viewIndex)"-->
                <div class="scroll-bar__item">
                  <div
                    class="scroll-bar__item__dot"
                    (click)="scrollToRec(index)"
                    [ngStyle]="{
                      'background-color':
                        visibleIndex == index
                          ? '#' + item.algoBullet.category.color
                          : '#DBDBDB',
                      transform:
                        visibleIndex == index ? 'scale(1.5)' : 'scale(1)'
                    }"
                  ></div>
                  <div *ngIf="!last" class="scroll-bar__item__line"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container
            *ngFor="
              let item of recomendations;
              let index = index;
              let last = last
            "
          >
            <div
              class="recomendations__page__wrapper"
              [class.last]="last"
              #elementRef
              id="rec-{{ index }}"
            >
              <div>
                <div class="recomendations__page__header">
                  <span
                    class="recomendations__page__header__title"
                    [ngStyle]="{ color: '#' + item.algoBullet.category.color }"
                  >
                    {{ item.algoBullet.title }}</span
                  >
                </div>
                <div
                  class="recomendations__page__desc"
                  [ngStyle]="{
                    'border-color': '#' + item.algoBullet.category.color
                  }"
                >
                  {{ item.algoBullet.description }}
                  <div *ngIf="item.algoBullet.algoBulletLinkId">
                    <button
                      mat-button
                      class="recomendations__page__link"
                      (click)="
                        openLinkDialog(
                          item.algoBullet.algoBulletLinkId,
                          item.algoBullet.category.color
                        )
                      "
                    >
                      {{ item.algoBullet.linkViewText }}
                    </button>
                  </div>
                </div>
              </div>

              <div
                *ngIf="item.algoBullet?.algoBulletImage?.imageUrl"
                class="recomendations__page__img"
              >
                <img
                  src="{{ item.algoBullet.algoBulletImage.imageUrl }}"
                  alt=""
                />
              </div>
              <div class="recomendations__page__reference">
                <mat-icon>warning</mat-icon>
                <div class="text">
                  <div class="text-title">
                    <strong>מדדים שנבדקו: </strong>
                  </div>
                  {{ item.referenceParameters }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="recomendations" class="recomendations__page__navigation">
          <button
            [disabled]="visibleIndex == 0"
            mat-icon-button
            (click)="scrollToRec(visibleIndex, 'prev')"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <button
            [disabled]="visibleIndex == recomendations.length - 1"
            mat-icon-button
            (click)="scrollToRec(visibleIndex, 'next')"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
