<div id="print-section" *ngIf="deal">
  <h1 style="text-align: right">פרטי עסקה</h1>
  <div dir="rtl">
    <mat-button-toggle-group
      name="fontStyle"
      [value]="selectedTabInx + 1"
      aria-label="Font Style"
    >
      <mat-button-toggle
        *ngFor="let deal of data.deal.reports; let i = index"
        (click)="changeDealInx(i)"
        [value]="i + 1"
      >
        {{ i + 1 }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <!--  <pre>
     {{ deal | json }}
  </pre> -->

  <mat-card>
    <div class="all-dialog" style="direction: rtl">
      <div class="deal-popup">
        <div
          class="wrap_main_deal_info"
          *ngIf="!deal || deal.tranzillaTranscationReportId == 0"
        >
          <ng-container *ngIf="deal.sum">
            <h3>סוג העסקה: {{ deal.paymentMethodName }}</h3>
            <p>
              סכום העסקה:
              {{ deal.sum }}
            </p>
          </ng-container>
          <ng-container *ngIf="!deal.sum">
            <h3>סוג העסקה: {{ data.deal.deal.paymentMethod.name }}</h3>
            <p>No details to display</p>
          </ng-container>
        </div>
        <div
          *ngIf="deal && deal.tranzillaTranscationReportId > 0"
          class="wrap_main_deal_info"
        >
          <div class="row" style="display: flex">
            <div class="large-8 column">
              <div class="deal_info_inner">
                <div class="row">
                  <div class="large-12 column pad_col">
                    <div class="title">
                      <h3>פרטי עסקה</h3>
                    </div>
                  </div>
                </div>
                <div class="row" style="display: flex">
                  <div class="large-6 column pad_col wrapper_fix">
                    <div class="wrap_items">
                      <div class="deal_row_item clearfix">
                        <div
                          class="label_wrap"
                          style="
                            width: 20% !important;
                            vertical-align: top !important;
                          "
                        >
                          <span>סטטוס</span>
                        </div>
                        <div class="value_wrap" style="width: 75% !important">
                          <span
                            *ngIf="deal.isSuccess == false"
                            style="color: red"
                            >{{
                              deal.bankResponseCode &&
                                deal.bankResponseCode.name
                            }}
                          </span>
                          <span
                            *ngIf="deal.isSuccess == true"
                            style="color: green"
                            >{{
                              deal.bankResponseCode &&
                                deal.bankResponseCode.name
                            }}
                          </span>
                        </div>
                      </div>

                      <div class="deal_row_item clearfix">
                        <div class="label_wrap">
                          <span>סוג עסקה</span>
                        </div>
                        <div class="value_wrap">
                          <span title="A">{{ deal.transModeDesc }}</span>
                        </div>
                      </div>

                      <div class="deal_row_item clearfix">
                        <div class="label_wrap">
                          <span>סכום</span>
                        </div>
                        <div
                          class="value_wrap"
                          style="direction: ltr !important"
                        >
                          <span>₪ {{ getAmount() }}</span>
                        </div>
                      </div>

                      <div class="deal_row_item clearfix">
                        <div class="label_wrap">
                          <span>שעת עסקה</span>
                        </div>
                        <div
                          class="value_wrap"
                          style="direction: ltr !important; width: 100px"
                        >
                          <span>{{
                            createDate | date : "dd/MM/yyyy HH:mm"
                          }}</span>
                        </div>
                      </div>

                      <div class="deal_row_item clearfix">
                        <div class="label_wrap">
                          <span>סוג תשלום</span>
                        </div>
                        <div class="value_wrap">
                          <span>{{ deal.creditTypeDesc }}</span>
                        </div>
                      </div>
                      <div class="deal_row_item clearfix">
                        <div class="label_wrap">
                          <span>מספר אישור</span>
                        </div>
                        <div class="value_wrap">
                          <span>{{ deal.aprovalNumber }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="large-4 column pad_col card_info_col wrapper_fix"
              style="margin-right: 30px"
            >
              <div class="title">
                <h3>פרטי אמצעי תשלום</h3>
              </div>
              <div class="wrap_items">
                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>שולם באמצעות</span>
                  </div>
                  <div class="value_wrap">
                    <span>כרטיס אשראי</span>
                  </div>
                </div>

                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>4 ספ' אחרונות</span>
                  </div>
                  <div class="value_wrap">
                    <span>{{ deal.lastFourDigits }}</span>
                  </div>
                </div>
                <div *ngIf="numPayments" class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>מספר תשלומים</span>
                  </div>
                  <div class="value_wrap">
                    <span>{{ numPayments }}</span>
                  </div>
                </div>

                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>תוקף</span>
                  </div>
                  <div class="value_wrap">
                    <span>{{ deal.expireDate }}</span>
                  </div>
                </div>

                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>ת"ז בעל הכרטיס</span>
                  </div>
                  <div class="value_wrap">
                    <span>{{ deal.idnum }}</span>
                  </div>
                </div>

                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>מנפיק</span>
                  </div>
                  <div class="value_wrap">
                    <span>ישראכרט</span>
                  </div>
                </div>

                <div class="deal_row_item clearfix">
                  <div class="label_wrap">
                    <span>סוג כרטיס</span>
                  </div>
                  <div class="value_wrap">
                    <span>ישראלי</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row more_info_deal_bottom">
              <div class="large-12 column pad_col bottomTop">
                <div class="large-6 column pad_col moveRight wrapper_fix">
                  <div class="title">
                    <h3>פרטים נוספים לעסקה</h3>
                  </div>
                  <div class="wrap_items">
                    <div class="deal_row_item clearfix">
                      <div class="label_wrap">
                        <span>כתובת</span>
                      </div>
                      <div class="value_wrap">
                        <span>{{ deal.adress }}</span>
                      </div>
                    </div>
                    <div class="deal_row_item clearfix">
                      <div class="label_wrap">
                        <span>שם חברה</span>
                      </div>
                      <div class="value_wrap">
                        <span>{{ deal.companyName }} </span>
                      </div>
                    </div>
                    <div class="deal_row_item clearfix">
                      <div class="label_wrap">
                        <span>אימייל</span>
                      </div>
                      <div class="value_wrap">
                        <span>{{ deal.email }}</span>
                      </div>
                    </div>
                    <div class="deal_row_item clearfix">
                      <div class="label_wrap">
                        <span>טלפון</span>
                      </div>
                      <div class="value_wrap">
                        <span>{{ deal.phone }}</span>
                      </div>
                    </div>
                    <div class="deal_row_item clearfix">
                      <div class="label_wrap">
                        <span>איש קשר</span>
                      </div>
                      <div class="value_wrap">
                        <span>{{ deal.contact }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap_ctrl_btns">
          <ng-container *ngIf="environment.isAditionPayment">
            <button
              class="btn-rect btn-rect--client"
              printSectionId="print-section"
              (click)="getContinueDeal(data.deal.deal)"
              *ngIf="
                data.deal.deal.dealBundleId &&
                data.deal.deal.dealBundle.leftToPay > 0
              "
            >
              Complete Payment
            </button>
          </ng-container>
          &nbsp;&nbsp;
          <button
            *ngIf="
              hasPermisions(['Cancelations_Create']) &&
              !data.deal.deal.isCancelDeal
            "
            class="open-cancel"
            mat-button
            (click)="onOpenCancelationRequest()"
          >
            Open Cancelation Request
          </button>
          <button
            *ngIf="data.deal.deal.isCancelDeal"
            disabled
            class="open-cancel"
            mat-button
          >
            In Cancellation Request
          </button>
          &nbsp;&nbsp;

          <button
            class="btn-rect btn-rect--client"
            printSectionId="print-section"
            ngxPrint
          >
            print
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
