<div style="display: flex; align-items: center">
  <button class="back back--client" (click)="goBackToClients()">
    < Back to Clients
  </button>
  <button (click)="gotoLogs()" class="logging--btn">
    <img src="../../../../assets/triangle.svg" />
    Log
  </button>
  <button
    *ngIf="hasPermission() && client"
    (click)="openSendInfoDialog()"
    class="send-info"
  >
    Send info
  </button>
</div>
<div style="display: flex" class="edit-client">
  <div style="width: 80%">
    <div>
      <div style="display: flex">
        <mat-card
          style="width: 50%; margin: 20px; padding-top: 0"
          class="clientDetails"
        >
          <!-- <mat-list>-->

          <app-client-profile-view
            [client]="client"
            *ngIf="isEditMode == false"
            (moveToEdit)="moveToEditMode()"
            [classAttr]="'clientAttr'"
          >
          </app-client-profile-view>
          <app-client-profile-edit
            [client]="client"
            [clientTypes]="clientTypes"
            [leadSources]="leadSources"
            [agents]="agents"
            [productIsFors]="productIsFors"
            *ngIf="isEditMode == true"
            (cancelForm)="onCancelForm($event)"
            (saveForm)="onChildFormValidated($event)"
            [classAttr]="'clientAttr'"
            [clientProducts]="clientProducts"
          >
          </app-client-profile-edit>
        </mat-card>
        <mat-card
          style="
            width: 50%;
            margin: 20px;
            height: calc(100vh - 145px);
            padding: 0;
          "
        >
          <perfect-scrollbar
            style="max-height: 100%; height: 100%; padding: 16px"
            [perfectScrollbar]="config"
          >
            <div
              class="purchases"
              style="position: sticky; top: 0; background: #fff; z-index: 11"
            >
              <div
                class="purchases__title"
                style="
                  font-family: 'Segoe UI';
                  margin-top: 5px;
                  padding-bottom: 10px;
                "
              >
                <span
                  style="font-size: 20px; font-weight: 500; margin-right: 10px"
                  [ngStyle]="purchaseMode == 1 && { fontWeight: 'bold' }"
                  >Purchases</span
                >
                <span
                  [ngStyle]="purchaseMode == 2 && { fontWeight: 'bold' }"
                  class="breadCrumb"
                  >Choose Product</span
                >
                <span>></span>
                <span
                  [ngStyle]="purchaseMode == 3 && { fontWeight: 'bold' }"
                  class="breadCrumb"
                  >Personal Details
                </span>
                <span>></span>
                <span
                  [ngStyle]="purchaseMode == 4 && { fontWeight: 'bold' }"
                  class="breadCrumb"
                  >Payment Details</span
                >
              </div>
            </div>
            <!--
            <app-transactions-table
              *ngIf="purchaseMode == 1 && client && statuses"
              [transactionData]="clientDeals"
              [statuses]="statuses"
            >
            </app-transactions-table>
            -->
            <app-client-purchases
              *ngIf="purchaseMode == 1 && client && statuses"
              (openNewPurchaseEmitter)="openNewPurchase($event)"
              (openContinueDealEmitter)="openContinueDeal($event)"
              [clientDealBundles]="clientDealBundles"
              [agentUser]="agentUser"
              [assignedAgentId]="client.agentId"
              [statuses]="statuses"
            >
            </app-client-purchases>

            <app-purchase-choose-products
              *ngIf="purchaseMode == 2"
              (completePurchase)="onPurchasePartFinished($event)"
              (cancelPurchase)="cancelPurchase()"
              [client]="client"
              [clientProducts]="getClientProductsForUpgrade()"
            >
            </app-purchase-choose-products>
            <app-purchase-personal-details
              *ngIf="purchaseMode == 3"
              (completePurchase)="onPurchasePartFinished($event)"
              (cancelPurchase)="cancelPurchase()"
              [client]="client"
              [deal]="deal"
            >
            </app-purchase-personal-details>
            <app-purchase-payment-details
              *ngIf="purchaseMode == 4"
              (completePurchase)="onPurchasePartFinished($event)"
              (cancelPurchase)="cancelPurchase()"
              [client]="client"
              [creditCurrencies]="creditCurrencies"
              [creditTypes]="creditTypes"
              [deal]="deal"
            >
            </app-purchase-payment-details>
            <app-loading-circle *ngIf="purchaseMode == 5"> </app-loading-circle>
          </perfect-scrollbar>
        </mat-card>
      </div>

      <mat-card style="margin: 20px" class="clientTests">
        <mat-tab-group #tabs animationDuration="0ms" style="min-height: 400px">
          <mat-tab style="min-height: 400px">
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Products</div>
            </ng-template>

            <app-client-tests
              (openPatientEdit)="openPatientEditDialog($event)"
              [clientProducts]="clientProducts"
            >
            </app-client-tests>
          </mat-tab>
          <mat-tab style="min-height: 400px">
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Activities</div>
            </ng-template>

            <app-lead-activities-view
              [classTabsAttr]="'clientLeadsActivitesTabs'"
              (createNewTask)="createNewTask($event)"
              *ngIf="isTaskEditMode == false"
              [notes]="clientTasks"
              [agents]="agents"
              [classAttr]="'clientAttr'"
            ></app-lead-activities-view>
            <app-lead-create-task
              (submitTask)="saveTask($event)"
              (cancelTask)="closeNewTaskWindow()"
              *ngIf="isTaskEditMode == true"
              [note]="activityToEdit"
              [agents]="agents"
              [currAgent]="agentUser"
            >
            </app-lead-create-task>
          </mat-tab>
          <mat-tab
            style="min-height: 400px"
            *ngIf="userHasPermision(['Clients_ViewServiceRequest'])"
          >
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Service Requests</div>
            </ng-template>

            <!--<app-lead-requests-view
              (createNewRequest)="createNewRequest($event)"
              *ngIf="isRequestEditMode == false && requests"
              [classAttr]="'clientAttr'"
              [requests]="requests"
            ></app-lead-requests-view>-->
            <div *ngIf="isRequestEditMode == false && requests">
              <perfect-scrollbar
                style="max-height: 30vh; height: 30vh"
                [perfectScrollbar]="config"
              >
                <app-service-request-table
                  *ngIf="requests && agents"
                  [requestsStatuses]="requestsStatuses"
                  [agents]="agents"
                  [serviceRequests]="requests"
                >
                </app-service-request-table>
              </perfect-scrollbar>
              <div>
                <button
                  class="btn-wide btn-wide--client"
                  (click)="createNewRequest()"
                >
                  + New Request
                </button>
              </div>
            </div>
            <app-lead-create-request
              *ngIf="isRequestEditMode == true"
              (cancelRequest)="closeNewRequestWindow()"
              (submitRequest)="saveRequest($event)"
              [request]="requestToEdit"
              [currAgent]="agentUser"
              [allAgents]="agents"
            >
            </app-lead-create-request>
          </mat-tab>
          <mat-tab style="min-height: 400px" *ngIf="ifHasCallPermission()">
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Calls</div>
            </ng-template>
            <div style="display: flex">
              <button
                mat-button
                class="fakeTab"
                [ngClass]="{ active: isCallsEditMode }"
                (click)="activateCallEditMode(true)"
              >
                Calls
              </button>
              <button
                mat-button
                class="fakeTab"
                [ngClass]="{ active: !isCallsEditMode }"
                (click)="activateCallEditMode(false)"
              >
                Calls History
              </button>
            </div>
            <app-client-create-call
              #clientCreateCall
              [subjects]="callsStatuses"
              [agentUser]="agentUser"
              *ngIf="isCallsEditMode"
              [note]="callToEdit"
              (submitCall)="saveCall($event)"
              (cancelCall)="closeNewCallWindow()"
            >
            </app-client-create-call>
            <app-client-calls-view
              [notes]="clientCalls"
              [metaData]="clientCallsMetaData"
              [clientId]="clientId"
              *ngIf="!isCallsEditMode"
              [classAttr]="'clientAttr'"
              (createNewCall)="createNewCall($event)"
            >
            </app-client-calls-view>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>

  <div style="width: 20%; margin: 20px">
    <app-client-notes
      [classAttr]="'clientAttr'"
      [notes]="clientNotes"
      (openNewNote)="createNewNote($event)"
      (reloadNotes)="onReloadNotes()"
      *ngIf="isNoteEditMode == false"
    >
    </app-client-notes>
    <app-client-add-note
      [note]="noteToEdit"
      [classAttr]="'clientAttr'"
      [statuses]="leadStatuses"
      (submitNote)="saveNote($event)"
      (cancelNote)="closeNewNoteWindow()"
      (reloadNotes)="onReloadNotes()"
      *ngIf="isNoteEditMode == true"
    >
    </app-client-add-note>
  </div>
</div>
