import { Agent } from './Agent';
import { Client } from './Client';
import { Deal } from './Deal';
import { Logging } from './Logging';
import { Menu } from './Menu';
import { Patient } from './Patients';
import { ProductType } from './ProductType';
import { Status } from './Status';
import { TubeTest } from './TubeTest';

export class Product {
  productId: number;
  productBundleId: number | null;
  productBundle: any;
  clientId: number;
  client: Client;
  patientId: number | null;
  patient: Patient;
  dealId: number;
  deal: Deal;
  cancelDealId: number | null;
  cancelDeal: Deal;
  productTypeId: number;
  productType: ProductType;
  barCode: string;
  tubeTest: TubeTest;
  codeCoupon: string;
  statusId: number;
  status: Status;
  dateCreated: string;
  agentId: number | null;
  agent: Agent;
  agent2Id: number | null;
  agent2: Agent;
  isCanceled: boolean;
  newProductTypeId: number | null;
  firstCall: boolean;
  upgragePurchaseDate: string;
  lifeStyleReportComplete: boolean;
  bloodTestFilled: boolean;
  isUrgentTest: boolean;
  arrivedToOffice: string;
  arrivedToCostumer: string | null;
  completedDate: string | null;
  updateDate: string;
  calorieDeficit: number | null;
  onFollow: boolean;
  fileUploads: any[];
  loggings: Logging[];
  barcodeHistories: any[];
  emailHistories: any[];
  clientName: string;
  menus: Menu[];
  consent1: boolean;
  consent2: boolean;
  consent3: boolean;
  bloodTestForBulletsFilled: boolean;
}
