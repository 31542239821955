<mat-list-item (click)="onRowClick(deal)" role="listitem">
  <!-- <span style="width: 2.5%">
    <mat-checkbox
      class="leadsTable__item__checkbox"
      (click)="$event.stopPropagation()"
      (change)="isChecked(deal.dealId, deal.isChecked)"
      [(ngModel)]="deal.isChecked"
    >
    </mat-checkbox>
  </span> -->
  <span>
    {{ deal.dateUpdated | date: "dd-MM-yyyy HH:mm" }}
  </span>
  <span
    ><span>{{ deal.costumerName }}</span></span
  >
  <span style="width: 250px; word-break: break-word">{{ deal.email }}</span>
  <span>{{ deal.phone }}</span>
  <span>{{ deal.agent }}</span>
  <span style="width: 100px">{{
    isInner ? deal.amount : deal.bundleAmount
  }}</span>
  <span
    style="width: 110px; font-size: 1.4rem"
    class="round-indicator"
    *ngIf="status"
    [ngStyle]="{
      'background-color': status.color
    }"
    >{{ status.name }}
    <!-- <div *ngIf="isNeedApproveBool">Need Approve</div> -->
  </span>
  <span style="padding-left: 10px">
    {{ deal.paymentMethod && deal.paymentMethod.name }}
  </span>
  <span *ngIf="isRefund(deal.dateUpdated)"><button>Refund</button></span>

  <span
    style="width: 100px"
    *ngIf="hasPermmisions() && status.statusId == 88"
    style="min-width: 200px"
  >
    <mat-form-field class="example-full-width round-select" style="width: 85px">
      <mat-select
        (click)="$event.stopPropagation()"
        (selectionChange)="handleApproval($event, deal)"
        matNativeControl
        disableOptionCentering
        placeholder="Approve"
      >
        <mat-option style="text-align: center" [value]="3">
          Approved</mat-option
        >
        <mat-option style="text-align: center" [value]="50">
          Not Approved</mat-option
        >
      </mat-select>
    </mat-form-field>
  </span>

  <span style="width: 70px">
    <button
      style="border: 1px solid black; padding: 0 7px; border-radius: 13px"
      mat-button
      (click)="cancelDeal(deal, $event)"
      *ngIf="showCancelDeal()"
    >
      Cancel
    </button>
  </span>
</mat-list-item>
