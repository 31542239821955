import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductType } from '../data/ProductType';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductTypesService {
  productTypes: ProductType[];

  constructor(private http: HttpClient) {}
  getAll() {
    if (this.productTypes) {
      return of(this.productTypes);
    } else
      return this.http.get<ProductType[]>(
        `${environment.baseUrl}ProductTypes/`
      );
  }
}
