<div>
  <div>
    <div class="table">
      <div class="table__header">
        <div>Full name</div>
        <div>Status</div>
        <div>Profile</div>
      </div>
      <div class="table__body">
        <div class="table__body__row" *ngFor="let item of users">
          <div>{{ item.name }}</div>
          <div>
            <mat-form-field appearance="fill" style="width: 150px">
              <mat-select [(ngModel)]="item.isActive">
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Not active</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <button mat-button (click)="goToProfile(item.agentId)">
              <img src="../../../assets/edit_icon_pink.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button mat-button (click)="dialogRef.close()" class="btn btn-fill">
      close
    </button>
  </div>
</div>
