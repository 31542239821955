<mat-dialog-content class="mat-typography">
  <form *ngIf="settingsForm" class="form" [formGroup]="settingsForm">
    <ng-container>
      <div class="title">Laboratory version</div>
      <div class="d-flex space-between mb-2">
        <div class="item">
          <div class="item__label">Version name:</div>
          <mat-form-field class="item__field" appearance="fill">
            <input type="test" matInput formControlName="versionName" />
          </mat-form-field>
        </div>
        <div class="item" *ngIf="data.versionId == 0">
          <div class="item__label">Laboratory:</div>
          <mat-form-field class="item__field" appearance="fill">
            <mat-select formControlName="labId">
              <mat-option *ngFor="let item of labs" [value]="item.labId">{{
                item.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <div class="title">Laboratory settings</div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">File type:</div>
        <mat-form-field class="item__field" appearance="fill">
          <mat-select
            placeholder="select file type"
            formControlName="fileTypeId"
          >
            <mat-option *ngFor="let item of fileTypes" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Position column num:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="positionColumnNum" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">NA result position:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input matInput formControlName="nAResultPos" />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">NA result value:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input matInput formControlName="nAResultValue" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">Result regex:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input matInput formControlName="resultRegex" />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Row delimeter:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input matInput formControlName="rowDelimeter" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">Result column num:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="resultColumnNum" />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Chromosome column name:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="chromosomeColumnNum" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">File encoding:</div>
        <mat-form-field class="item__field" appearance="fill">
          <mat-select
            placeholder="select file encoding"
            formControlName="fileEncodingTypeId"
          >
            <mat-option *ngFor="let item of filesEncoding" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Barcode regex pos:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="barCodeRegexPos" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item">
        <div class="item__label">Second result pos:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="secondResultPos" />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">First result pos:</div>
        <mat-form-field class="item__field" appearance="fill">
          <input type="number" matInput formControlName="firstResultPos" />
        </mat-form-field>
      </div>
    </div>
    <div class="item mb-2">
      <div class="item__label">PDF version:</div>
      <div class="d-flex" style="align-items: center">
        <mat-form-field class="item__field" appearance="fill">
          <mat-select
            placeholder="select pdf version"
            formControlName="pdfFile"
          >
            <mat-option *ngFor="let item of pdfTemplates" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <input
          type="file"
          #templateFile
          (change)="onUploadPdfTemplateChange(templateFile.files)"
          hidden
        />
        <button mat-icon-button (click)="templateFile.click()">
          <mat-icon>file_upload</mat-icon>
        </button>
        <!--  <div *ngIf="templateFile.files.length > 0">
          {{ templateFile.files[0].name }}
        </div> -->
      </div>
    </div>
  </form>
  <p *ngIf="!labSettings">No Content!</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="actions">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      [disabled]="!labSettings"
      class="save"
      (click)="onSubmit(settingsForm)"
      mat-button
      type="submit"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
