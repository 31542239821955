import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { LabsVersionEditComponent } from '../labs-version-edit/labs-version-edit.component';

@Component({
  selector: 'app-duplicate-version',
  templateUrl: './duplicate-version.component.html',
  styleUrls: ['./duplicate-version.component.scss'],
})
export class DuplicateVersionComponent implements OnInit {
  selectedDupVersionId: number;
  rewriteSettings;
  versions: LabVersion[];
  langId: number = 1;
  labId = 0;
  isLoading: boolean;
  errorMessage: string;
  constructor(
    private dialogRef: MatDialogRef<DuplicateVersionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private algorithmService: AlgorithmService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getLabWithVersions();
  }
  getLabWithVersions() {
    this.algorithmService
      .getLabWithVersions(this.langId, this.labId)
      .subscribe((data) => {
        this.versions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.versions.push(y);
          });
        });
      });
  }
  onRewriteChange() {
    if (this.rewriteSettings) {
      this.popupHandlerService.openConfirmationDialog(
        'When you confirm this action you will rewrite the regex <br> in the database and you will not able to restore it.',
        'Warning'
      );
    }
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data.ans === 'yes') {
        this.rewriteSettings = true;
      } else {
        this.rewriteSettings = false;
      }
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.algorithmService
      .duplicateVersion(
        this.selectedDupVersionId,
        this.data,
        !this.rewriteSettings ? false : true
      )
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this.errorMessage = (data as ErrorMessage).message;
          this.isLoading = false;
        } else {
          this.dialogRef.close(true);
          this.isLoading = false;
        }
      });
  }
}
