<form *ngIf="video" (ngSubmit)="save(videoForm)" #videoForm="ngForm">
  <h2 mat-dialog-title>Add new video</h2>
  <mat-dialog-content class="mat-typography">
    <div class="d-flex">
      <div class="video-form">
        <div class="video-form__item">
          <div class="video-form__item__label"></div>
          <div class="video-form__item__field">
            <div
              *ngIf="!showVideoPreview && !showStreamVideo"
              (click)="file.click()"
              class="upload-video"
            >
              <mat-icon>cloud_upload</mat-icon>
              Click to select video to upload
            </div>
            <cloudflare-stream
              *ngIf="showStreamVideo"
              [controls]="true"
              [src]="getVideoFile()"
              height="200px"
            ></cloudflare-stream>
            <video
              *ngIf="showVideoPreview"
              #preVideoElement
              width="100%"
              controls
            >
              <source id="video_here" />
              Your browser does not support HTML5 video.
            </video>
            <div *ngIf="uploadProgress">
              <mat-progress-bar
                color="primary"
                mode="determinate"
                [value]="uploadProgress"
              ></mat-progress-bar>
              <p>{{ uploadProgress }}%</p>
            </div>
            <div *ngIf="videoError" class="video-form__video-error">
              {{ videoError }}
            </div>
            <div *ngIf="uploadError" class="video-form__video-error">
              {{ uploadError }}
            </div>
            <button
              class="video-form__change-btn"
              mat-button
              *ngIf="showVideoPreview || showStreamVideo"
              (click)="file.click()"
              type="button"
              [disabled]="uploadProgress"
            >
              Change video
            </button>
            <input
              hidden
              accept="video/*"
              name="file"
              type="file"
              #file
              (change)="uploadVideo(file.files)"
            />
          </div>
        </div>

        <div class="video-form__item">
          <div class="video-form__item__label">Title</div>
          <mat-form-field class="video-form__item__field" appearance="fill">
            <input
              matInput
              [(ngModel)]="video.title"
              name="video-name"
              required
              type="text"
              placeholder="Title of the video"
              [disabled]="uploadProgress"
            />
          </mat-form-field>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Description</div>
          <mat-form-field class="video-form__item__field" appearance="fill">
            <textarea
              matInput
              [(ngModel)]="video.desc"
              name="video-desc"
              required
              type="text"
              placeholder="Description of the video"
              [disabled]="uploadProgress"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Editor</div>
          <app-autocomplete-agent
            [disabled]="uploadProgress"
            class="autocomplete-agent video-form__item__field"
            [style.border-bottom]="agentIdError ? '2px solid #f44336' : ''"
            (selectedValue)="onAgentFilterChange($event)"
            [data]="agents"
            [initialValue]="getAgentNameById()"
            [widthPrec]="100"
            [label]="'Name of the editor'"
            [direction]="'ltr'"
          ></app-autocomplete-agent>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Category</div>
          <mat-form-field class="video-form__item__field" appearance="fill">
            <mat-select
              [disabled]="uploadProgress"
              placeholder="Select category"
              [(ngModel)]="video.videoCategory.videoCategoryId"
              name="video-category"
              required
            >
              <mat-option
                *ngFor="let item of categories"
                [value]="item.videoCategoryId"
                >{{ item.categoryName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Date</div>
          <mat-form-field
            class="video-form__item__field"
            color="accent"
            appearance="fill"
          >
            <input
              [(ngModel)]="video.uploadDate"
              name="video-date"
              required
              matInput
              [disabled]="uploadProgress"
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker color="primary"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Publish</div>
          <mat-slide-toggle
            [disabled]="uploadProgress"
            class="video-form__item__field"
            [(ngModel)]="video.isActive"
            name="video-status"
          ></mat-slide-toggle>
        </div>
        <div class="video-form__item">
          <div class="video-form__item__label">Is Disclaimer</div>
          <mat-slide-toggle
            [disabled]="uploadProgress"
            class="video-form__item__field"
            [(ngModel)]="video.isDisclaimer"
            name="video-disclaimer"
          ></mat-slide-toggle>
        </div>
      </div>
      <div class="video-image">
        <app-algo-image
          [langId]="langId"
          [algoImageType]="FileUploadType.VideoPoster"
          (onUploadFinished)="onUploadFinished($event)"
          [selectedImg]="getImage(FileUploadType.VideoPoster)"
          [checkedImgId]="getCheckedImage(FileUploadType.VideoPoster)"
          [isVod]="true"
          [streamId]="getVideoFile()"
        ></app-algo-image>
        <!-- <div
          *ngIf="!showImagePreview"
          class="upload-image"
          (click)="onBoxImageClick(image)"
        >
          Upload image
        </div>
        <img
          width="400"
          #preImageElement
          *ngIf="showImagePreview"
          src=""
          alt=""
          class="video-image__preview"
        />
        <div class="video-image__error" *ngIf="imgError">{{ imgError }}</div>
        <button
          class="video-image__change-btn"
          *ngIf="showImagePreview"
          (click)="image.click()"
          mat-button
          [disabled]="uploadProgress"
          type="button"
        >
          Change image
        </button>
        <input
          hidden
          name="video"
          type="file"
          accept="image/*"
          #image
          placeholder="Choose image"
          (change)="uploadImg(image.files)"
        /> -->
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="close" type="button" mat-button mat-dialog-close>
      Cancel
    </button>
    <button [disabled]="uploadProgress" class="save" type="submit" mat-button>
      {{ video.videoId ? "Save" : "Add" }} video
    </button>
  </mat-dialog-actions>
</form>
