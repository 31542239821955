<div class="conversation" [class.from-user]="!message.directionToUser">
  <ng-container
    *ngIf="
      message.attachments &&
        message.attachments.length > 0 &&
        message.attachments[0].fileName;
      else string
    "
  >
    <ng-container *ngIf="fileExtension() === 'image'; else pdf">
      <img class="attachment" [src]="showAttachment()" alt="attachment" />
    </ng-container>
    <ng-template #pdf>
      <mat-icon class="attachment">picture_as_pdf</mat-icon>
    </ng-template>
    <a class="zoom-in" [href]="showAttachment()" target="_blank">
      <mat-icon>zoom_in</mat-icon>
    </a>
  </ng-container>
  <ng-template #string
    >{{
      (message && message.template && message.template.context) ||
        message.context
    }}
  </ng-template>
</div>
<div class="meta-data">
  <ng-container *ngIf="message.createdDate">
    {{ message.createdDate | date : "d/M/yy HH:mm" }}</ng-container
  >
  &nbsp;<mat-icon
    class="read-mark"
    [class.readed]="message.statusId == MessageStatus.Readed"
    >{{
      message.statusId == MessageStatus.Readed ? "done_all" : "done"
    }}</mat-icon
  >
</div>
