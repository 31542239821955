import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PatientWeightHistory } from '../data/PatientWeightHistory';

@Injectable({
  providedIn: 'root',
})
export class PatientWeightHistoriesService {
  constructor(private http: HttpClient) {}

  getHistory(patientId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}PatientWeightHistories/ForPatient/${patientId}`
    );
  }

  post(patientWeightHistory: PatientWeightHistory) {
    return this.http.post<PatientWeightHistory>(
      `${environment.baseUrl}PatientWeightHistories/`,
      patientWeightHistory
    );
  }
}
