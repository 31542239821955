import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Client } from 'src/app/data/Client';
import { throttle } from 'rxjs/operators';
import { LeadsService } from 'src/app/Services/leads.service';
import { Globals } from 'src/app/globals';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { SendMailService } from 'src/app/Services/send-mail.service';
import { Router } from '@angular/router';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { AdressTypeEnum } from 'src/app/Enums/StatusesEnum';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { RegularUser } from 'src/app/data/RegularUser';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-client-profile-view',
  templateUrl: './client-profile-view.component.html',
  styleUrls: ['./client-profile-view.component.css'],
})
export class ClientProfileViewComponent implements OnInit {
  phoneSettings: PhoneVerificationSetting;
  constructor(
    private leadsService: LeadsService,
    private popupHandlerService: PopupHandlerService,
    private sendMailService: SendMailService,
    private router: Router,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService,
    private regularUsersService: RegularUsersService
  ) {}
  regularUser: RegularUser;
  displayMailBtn: boolean = true;
  @Input() leadStatuses;
  @Input() isDuplicate;
  @Input() client: Client;
  @Input() classAttr;
  @Output('leadChanged') leadChanged: EventEmitter<any> = new EventEmitter();
  @Output('moveToEdit') moveToEdit: EventEmitter<any> = new EventEmitter();
  clientProductsSummery;
  clientTypes;
  isFormDisabled;
  hideAgentPlaceholder;
  ngOnInit(): void {
    this.clientProductsSummery = [];
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
    //this.getPhoneSettings();
    //var a1 = this.client.clientType;
  }

  getPhoneSettings() {
    var address = this.client?.regularUser.getAdressObj(AdressTypeEnum.Main);
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
      });
  }
  getUserPhone() {
    if (this.client.regularUser.getUserPhone()) {
      var phoneNumber = this.client.regularUser.getUserPhone();
      return phoneNumber;
    }
  }
  getUserPhoneObj() {
    if (this.client.regularUser.getUserPhoneObj()) {
      var phoneObj = this.client.regularUser.getUserPhoneObj();
      return phoneObj.country;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    var a = this.client;
  }
  moveToEditMode() {
    this.moveToEdit.emit(true);
    //var a = this.client.productIsFor.name;
  }
  callClientPhone(phone) {
    //  window.open('tel:' + phone);
  }
  hidePlaceholderAgentFunc() {}
  onStatusChange(event) {
    var client = { ...this.client };
    this.client.leadStatus = this.leadStatuses.filter((x) => {
      return x.leadStatusId == event.value;
    })[0];
    //client.leadStatus = this.client.leadStatus;
    client.leadStatusId = event.value;

    this.leadsService.changeStatusGet(client).subscribe(() => {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.status
      );
      this.leadChanged.emit();
    });

    /* this.leadsService.put(client).subscribe((newClient) => {
      //this.client = newClient;
      
    }); */
  }
  getStatusSelectColor() {
    return this.client.leadStatus.color;
  }
  getName(regularUser) {
    if (regularUser.lastName == null) {
      return regularUser.firstName;
    }
    return regularUser.firstName + ' ' + regularUser.lastName;
  }
  gotoClient() {
    this.router.navigate(['/clients/edit/'], {
      queryParams: { clientId: this.client.clientId },
    });
  }
  sendMail() {
    this.sendMailService
      .sendProductDetailsMail(this.client.regularUserId)
      .subscribe(() => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.mailSentsuccessfully
        );
      });
  }
  userHasPermisions(perms) {
    return userHasPermisions(perms, this.regularUser);
  }
}
