<div class="container pb-4" style="direction: rtl">
  <div
    style="margin-right: auto; margin-top: 5px; cursor: pointer"
    (click)="goBack()"
  >
    חזרה >
  </div>

  <div
    style="
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 40px;
      margin: auto;
    "
  >
    <img
      *ngIf="category && (categoryId != -1 || getImage(category?.files))"
      [src]="getImage([userSummary.image])"
    />
    <span style="font-size: 30px; font-weight: bolder; margin-right: 30px">
      <span *ngIf="categoryId != -1" [innerHtml]="userSummary?.categoryName">
      </span>
      <ng-container *ngIf="categoryId == -1"> ממצאים בעלי משמעות </ng-container>
    </span>
  </div>
  <div
    style="position: relative"
    [style.height]="categoryId == -1 ? '90px' : ''"
  >
    <nav
      class="result-nav"
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      *ngIf="results"
    >
      <a
        mat-tab-link
        *ngFor="let type of resultTypes; let i = index"
        (click)="onSelectType(type)"
        [active]="activeType.typeId == type.typeId"
        class="result-nav__item"
        [ngClass]="{
          selected: activeType.typeId == type.typeId
        }"
        [disabled]="resultsCount(type.typeId) == 0"
        [ngStyle]="{
          'background-color':
            activeType?.typeId == type.typeId ? type.color : '',
          color: activeType.typeId == type.typeId ? '#ffffff' : ''
        }"
      >
        <span style="position: relative">
          <span
            class="result-count"
            [style.background-color]="
              activeType.typeId == type.typeId ? '#ffffff' : type.color
            "
            [style.color]="
              activeType.typeId == type.typeId ? type.color : '#ffffff'
            "
            [class.disabled]="resultsCount(type.typeId) == 0"
          >
            {{ resultsCount(type.typeId) }}
          </span>
          {{ type.typeName }}
        </span>
      </a>
    </nav>
    <div style="position: absolute; left: 0; top: 32px; z-index: 200">
      <app-change-lang
        [selectedLangId]="langId"
        (changeLang)="onChangeLang($event)"
      ></app-change-lang>
    </div>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <mat-accordion>
      <ng-container *ngFor="let result of results">
        <mat-expansion-panel
          *ngIf="activeType?.typeId == result.order || categoryId == -1"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="subcat-name">
                <span
                  [style.background-color]="'#' + result.color"
                  class="dot"
                ></span>
                <span
                  style="color: #1b3f4e; font-weight: bolder"
                  [innerHtml]="result.name"
                >
                </span>
              </div>
              <div class="precenteg-bar-wrapper">
                <div class="precenteg-bar">
                  <div
                    class="fill"
                    [style.width]="result.persent + '%'"
                    [style.background-color]="'#' + result.color"
                  ></div>
                </div>
                <div class="precenteg-text">{{ result.persent }}%</div>
                <small *ngIf="canEdit()" class="sum"
                  >({{ result.sum }}/{{ result.max }})</small
                >
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="title-underlined"> סיכום: </span>
          <div
            style="padding-top: 20px; padding-bottom: 20px"
            [innerHtml]="blankify(result.description)"
          ></div>
          <span class="title-underlined"> המלצות: </span>
          <ol class="recomendaion-list">
            <li
              *ngFor="let item of result.recommendationList"
              [innerHtml]="blankify(item.recommend)"
            ></li>
          </ol>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-tab-nav-panel>
  <ng-container *ngIf="tubeTestResults?.length > 0">
    <span class="changes-title">רשימת השינויים והגנים שהתקבלו</span>
    <table
      style="direction: ltr"
      mat-table
      [dataSource]="tubeTestResults"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="geneName">
        <th
          class="table-header"
          mat-header-cell
          *matHeaderCellDef
          style="text-align: center"
        >
          שם הגן הנבדק
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.geneName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="snpNumber">
        <th
          class="table-header"
          mat-header-cell
          *matHeaderCellDef
          style="text-align: center"
        >
          RS NO.
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          rs{{ element.snpNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="result">
        <th
          class="table-header"
          mat-header-cell
          *matHeaderCellDef
          style="text-align: center"
        >
          הגנוטיפ שהתקבל
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <div [innerHtml]="element.snpResult"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <th class="table-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ element.description }}"
            class="info-circle"
          >
            <span>i</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
</div>
