import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { base64StringToBlob } from 'blob-util';
import { FileSaverOptions } from 'file-saver';
import { take } from 'rxjs/operators';
import { AlgoBullet } from 'src/app/data/AlgoBullet';
import { AlgoBulletMin } from 'src/app/data/AlgoBulletMin';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { Category } from 'src/app/data/Category';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { SubCategory } from 'src/app/data/SubCategory';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { AlgoBulletService } from 'src/app/Services/algo-bullet.service';
import { BloodTestTypesService } from 'src/app/Services/blood-test-types.service';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { SubCategoriesService } from 'src/app/Services/sub-categories.service';
import { saveAsHelper } from 'src/app/Utils/saveAsUtil';

@Component({
  selector: 'app-algo-groups',
  templateUrl: './algo-groups.component.html',
  styleUrls: ['./algo-groups.component.scss'],
})
export class AlgoGroupsComponent implements OnInit {
  categories: Category[];
  //@ViewChild('bulletFormDirective') ngForm
  bulletsData: AlgoBulletMin[];
  agents;
  @ViewChild('toggle') toggleDatepicker: ElementRef;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesTable: LeadStatus[];
  regularUser: RegularUser;
  bulletForm: FormGroup;
  genetic: FormArray;
  bloodeTest: FormArray;
  subCategories: SubCategory[];
  algoConditions: Status[];
  algoValues: Status[];
  bloodeTestTypes: BloodTestType[];
  algoBullet: AlgoBullet;
  tableResizeBig: boolean = false;
  tableResizeMedium: boolean;
  isLoading: boolean = false;

  /*  clientWidth:any;
  tableResize:any;
  formResize:any; */
  constructor(
    private filterMemoryService: FilterMemoryService,
    private fb: FormBuilder,
    private subCategoriesService: SubCategoriesService,
    private statusesService: StatusesService,
    private bloodeTestService: BloodTestTypesService,
    private algoBulletService: AlgoBulletService,
    private popupHandlerService: PopupHandlerService,
    private catergoriesService: CatergoriesService,
    private exelDownloadService: ExelDownloadService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.leads);
    this.algoBullet = new AlgoBullet();
    this.algoBullet.bulletAlgoCrossings = [];
    //SET DEFAULT HEAD FILTER
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.algoBullets
    );
  }

  ngOnInit(): void {
    this.createForm();

    this.subCategoriesService
      .getAll()
      .subscribe((category) => (this.subCategories = category));

    this.statusesService.getAll().subscribe((status) => {
      this.algoConditions = status.filter(
        (data) => data.category === 'AlgoBulletCondition'
      );
      this.algoValues = status.filter(
        (data) => data.category === 'AlgoBulletValue'
      );
    });
  }

  getSelectedValue(value: number) {
    if (value) {
      return this.subCategories?.find((x) => x.subCategoryId == value)
        ?.subCategoryName;
    }
  }

  // Create Form
  createForm() {
    this.bulletForm = this.fb.group({
      genetic: this.fb.array([this.createGeneticItem()]),
    });
  }

  //genetic fields
  createGeneticItem(subCategoryId = null, conditionId = null, valueId = null) {
    return this.fb.group({
      subCategoryId: [subCategoryId, Validators.required],
      conditionId: [conditionId, Validators.required],
      valueId: [valueId, Validators.required],
    });
  }
  addGeneticItem() {
    this.genetic = this.bulletForm.get('genetic') as FormArray;
    this.genetic.push(this.createGeneticItem());
  }
  removeGeneticItem(index) {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          (this.bulletForm.get('genetic') as FormArray).removeAt(index);
        }
      });
  }

  disableRemoveBtn() {
    if (this.bulletForm.get('genetic')['controls'].length > 1) {
      return false;
    }
    if (this.bulletForm.get('genetic')['controls'].length == 1) {
      return true;
    }
  }

  // Reset and clear form after submit
  resetAndClearForm() {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          (this.bulletForm.get('genetic') as FormArray).clear();
          this.bulletForm.reset();
          this.addGeneticItem();
        }
      });
  }

  exportToExel() {
    if (this.bulletForm.valid) {
      this.isLoading = true;
      this.bulletForm.get('genetic').disable();
      var data = this.bulletForm.get('genetic').value;

      this.exelDownloadService.getAlgoGroups(data).subscribe((data) => {
        //this.fileObservable = data;
        let contentType = 'text/csv';

        //let blob = base64StringToBlob(this.fileObservable.fileContent, contentType);\
        let blob = base64StringToBlob(data, contentType);
        var now = new Date();
        var timeStamp = now.toDateString().split(' ').join('_');

        saveAsHelper(blob, `algo_groups_${timeStamp}.csv`);
        this.isLoading = false;
        this.bulletForm.get('genetic').enable();
      });
    }
  }
  get geneticFields() {
    return this.bulletForm.get('genetic')['controls'];
  }
}
