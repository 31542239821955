import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { FoodAlert } from 'src/app/data/food-genes/FoodAlert';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss'],
})
export class EditTagComponent implements OnInit {
  ImageType = AlgoImageType;
  constructor(
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditTagComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      alert: FoodAlert;
      langId: number;
    }
  ) {}

  ngOnInit(): void {
    if (!this.data.alert) {
      this.data.alert = new FoodAlert();
      this.data.alert.foodAlertId = 0;
      this.data.alert.langId = this.data.langId;
    } else {
      this.data.alert.langId = this.data.langId;
    }
  }
  save() {
    if (this.data.alert.foodAlertId == 0) {
      this.foodGenesService.createAlert(this.data.alert).subscribe((data) => {
        this._snackBar.open('Alert saved', '', {
          duration: 2000,
        });
        this.dialogRef.close({ type: 'save' });
      });
    } else {
      this.foodGenesService.updateAlert(this.data.alert).subscribe((data) => {
        this._snackBar.open('Alert Updated', '', {
          duration: 2000,
        });
        this.dialogRef.close({ type: 'save' });
      });
    }
  }
  onUploadFinished(event: AppImage) {
    this.data.alert.icon = event;
  }
  getImage() {
    const image = this.data.alert.icon;
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
}
