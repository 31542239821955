import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import * as moment from 'moment';
import { elementAt } from 'rxjs/operators';
import { BloodTestResult } from 'src/app/data/BloodTestResult';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { BloodTestResultsNewService } from 'src/app/Services/blood-test-results-new.service';

import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-bloodtest-report-view',
  templateUrl: './bloodtest-report-view.component.html',
  styleUrls: ['./bloodtest-report-view.component.scss'],
})
export class BloodtestReportViewComponent implements OnInit {
  validateValues: boolean = false;
  isMobile: boolean = true;
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  @Input() patientId: number;
  @Input() gender: string;
  results: BloodTestResult[];
  dateTaken: any;
  dateTakenForm: any;
  allEditMode: boolean = false;
  editInx: number = -1;
  selectedUnit = '1';
  private fb: FormBuilder = new FormBuilder();
  bloodTestForm: FormGroup;

  formResults: BloodTestResult[];
  historyMode: boolean = false;
  historyDates: BloodTestResult[];
  selectedHistoryDate;
  numFloatRegx = '^[0-9][.]{0,1}[0-9]{0,1}$';
  numIntRegx = '^[1-9]{1}[0-9]{0,10}$';
  constructor(
    private bloodTestResultsNewService: BloodTestResultsNewService,

    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getBloodTestResultsNew();
    this.getHistoryDates();
  }
  getbloodTestFormLine(inx) {
    return this.bloodTestsArray[inx];
  }
  get bloodTestsArray(): FormArray {
    return this.bloodTestForm.get('bloodTestsArray') as FormArray;
  }
  /*
  createFormGroup() {
    this.bloodTestForm = this.fb.group({
      bloodTestsArray: new FormArray([]),
    });
    this.results.forEach((result) => {
      this.bloodTestsArray.push(this.fb.group({ value: [result.result] }));
    });
  }*/
  getHistoryDates() {
    this.bloodTestResultsNewService
      .getDatesListByPatientId(this.patientId)
      .subscribe((res) => {
        this.historyDates = res;
        if (this.historyDates && this.historyDates.length > 0)
          this.selectedHistoryDate = this.historyDates[0];
      });
  }
  populateFromData(data) {
    this.results = data;
    this.results = this.results.map((x) => {
      x.isAlt = false;
      return x;
    });
    this.createFormResults();
    // this.createFormGroup();
    this.dateTaken = this.results.reduce((a, b) => {
      return new Date(a.dateCreated) > new Date(b.dateCreated) ? a : b;
    }).dateCreated;
  }
  getBloodTestResultsNew() {
    this.bloodTestResultsNewService
      .getByPatientId(+this.patientId)
      .subscribe((data) => {
        this.populateFromData(data);
      });
  }
  createFormResults() {
    if (this.results) {
      this.formResults = JSON.parse(JSON.stringify(this.results));
      this.formResults = this.formResults.map((x) => {
        x.result = null;
        return x;
      });
    }
  }
  /*
  getBloodeTestResults() {
    this.bloodTestResultsNewService
      .getByPatientId(+this.patientId)
      .subscribe((data) => {
        this.results = data;
      });
  }
  */
  /*
  onEditClick() {
    this.onEdit.emit();
  }
  */
  testRegex(value, re) {
    const regex = new RegExp(re);
    return regex.test(value);
  }
  onUpdateClick() {
    this.allEditMode = true;
  }
  goToEditSingleMode(result: BloodTestResult) {
    this.editInx = result.bloodTestTypeId;
    this.dateTakenForm = null;
  }
  onOkClicked(result: BloodTestResult, inx: number) {
    let strError = '';
    if (!this.formResults[inx].result) {
      strError += 'Please Fill Value';
    }
    if (!this.dateTakenForm) {
      strError += '<br> Please Choose Date';
    }
    if (this.validateValues) {
      if (this.formResults[inx].bloodTestType.roundToInt) {
        if (
          this.formResults[inx].result > 0 &&
          !this.testRegex(this.formResults[inx].result, this.numIntRegx)
        ) {
          strError += 'Please fill integer number';
        }
      } else {
        if (
          this.formResults[inx].result > 0 &&
          !this.testRegex(this.formResults[inx].result, this.numFloatRegx)
        ) {
          strError += 'עליך להזין מספר אחד אחרי הנקודה';
        }
      }
    }
    if (!this.dateTakenForm) {
      this.popupHandlerService.openDisapearingAlertDialog(
        '',
        '',
        '',
        strError,
        2000
      );
      return;
    }
    result.result = this.formResults.find(
      (x) => x.bloodTestTypeId == result.bloodTestTypeId
    ).result;
    result.dateCreated = moment(this.dateTakenForm).format('yyyy-MM-DD');
    this.bloodTestResultsNewService.saveSingle(result).subscribe((res) => {
      this.dateTakenForm = null;
      this.editInx = -1;
      this.getHistoryDates();
      this.getBloodTestResultsNew();
    });
  }
  onCancelEditClicked(result: BloodTestResult) {
    this.createFormResults();
    this.editInx = -1;
  }
  cancelAllEditMode() {
    this.createFormResults();
    this.allEditMode = false;
  }
  saveInAllEditMode() {
    if (!this.dateTakenForm) {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.costumMessage,
        '',
        '',
        'Please Choose Date',
        2000
      );
      return;
    }
    const atLeastOneField = this.formResults.some((x) => x.result);
    if (!atLeastOneField) {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.costumMessage,
        '',
        '',
        'Please Fill At Least One Value',
        2000
      );
      return;
    }
    var dateStr = moment(this.dateTakenForm).format('yyyy-MM-DD');
    this.formResults = this.formResults.map((x) => {
      x.dateCreated = dateStr;
      return x;
    });
    this.bloodTestResultsNewService
      .saveAll(this.formResults)
      .subscribe((res) => {
        this.allEditMode = false;
        this.getHistoryDates();
        this.getBloodTestResultsNew();
        this.dateTakenForm = null;
      });
  }

  onResultChanged(result: BloodTestResult) {
    result.resultAlt = result.result * result.bloodTestType.unitsConvertion;
    result.resultAlt =
      Math.round((result.resultAlt + Number.EPSILON) * 100) / 100;
  }
  onResultAltChanged(result: BloodTestResult) {
    result.result = result.resultAlt / result.bloodTestType.unitsConvertion;
    result.result = Math.round((result.result + Number.EPSILON) * 100) / 100;
  }
  onHistoryClick() {
    this.historyMode = true;
    this.getHistoryList();
  }
  getHistoryList() {
    this.bloodTestResultsNewService
      .getSpecificDateByPatientId(this.patientId, this.selectedHistoryDate)
      .subscribe((data) => {
        this.populateFromData(data);
      });
  }
  onHistoryBackClick() {
    this.historyMode = false;
    this.getBloodTestResultsNew();
  }
  onHistoryDateChanged(event) {
    this.getHistoryList();
  }

  onAltChange(result: BloodTestResult, formResult: BloodTestResult) {
    result.isAlt = formResult.isAlt;
  }
}
