import { Component, Input, OnInit } from '@angular/core';
import { Agent } from 'src/app/data/Agent';
import { AgentMin } from 'src/app/data/AgentMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Note } from 'src/app/data/Note';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';

import { AgentsService } from 'src/app/Services/agents.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { NotesService } from 'src/app/Services/notes.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-calls-reviews',
  templateUrl: './calls-reviews.component.html',
  styleUrls: ['./calls-reviews.component.css'],
})
export class CallsReviewsComponent implements OnInit {
  //@Input() data;
  @Input() reviews: any;

  headFilter: HeadFilter;
  @Input() metaData: PaginationMetadata;
  agents: AgentMin[];
  rating;
  subjects: Status[];
  agentUser: RegularUser;
  @Input() clientId: number = -1;
  @Input() patientId: number = -1;
  isLoading: boolean;
  constructor(
    private notesService: NotesService,
    private filterMemoryService: FilterMemoryService,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    private regularUsersService: RegularUsersService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.callsReviews
    );
  }

  ngOnInit(): void {
    var a = this.clientId;
    var a1 = this.patientId;
    if (!this.reviews) {
      this.getAllCalls();
    } else {
      this.reviews = this.reviews;
      this.metaData = new PaginationMetadata();
      this.metaData.currentPage = 1;
      this.metaData.pageSize = 15;
    }

    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
    this.statusesService.getAll().subscribe((data) => {
      this.subjects = [];
      if (
        userHasPermisions(['CallSubjectSupportList_GetList'], this.agentUser)
      ) {
        var theList = data.filter((status: Status) => {
          return status.category == 'CallSubjectSupport';
        });
        this.subjects = this.subjects.concat(theList);
      }
      if (userHasPermisions(['CallSubjectNutriList_GetList'], this.agentUser)) {
        var theList = data.filter((status: Status) => {
          return status.category == 'CallSubjectNutri';
        });
        this.subjects = this.subjects.concat(theList);
      }
    });
  }
  getAllCalls() {
    this.isLoading = true;
    this.notesService
      .getAllCalls(this.headFilter, this.clientId, this.patientId)
      .subscribe((data) => {
        this.isLoading = false;
        this.reviews = data.list;
        this.metaData = data.metaData;
        this.rating = data.average;
      });
  }
  getAllWithFilter(e) {
    this.headFilter = e;
    this.filterMemoryService.setfilter(
      this.headFilter,
      PageTypeEnum.callsReviews
    );
    this.getAllCalls();
  }
}
