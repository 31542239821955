import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ProductType } from 'src/app/data/ProductType';
import { LocalProduct } from 'src/app/data/LocalProduct';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client } from 'src/app/data/Client';
import { FutureProduct } from 'src/app/data/FutureProduct';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';

@Component({
  selector: 'app-client-edit-purchase-thumb',
  templateUrl: './client-edit-purchase-thumb.component.html',
  styleUrls: ['./client-edit-purchase-thumb.component.scss'],
})
export class ClientEditPurchaseThumbComponent implements OnInit, OnChanges {
  displayedColumns: string[] = [
    'checked',
    'patientName',
    'patientId',
    'Status',
  ];
  @Input() clientProducts: ProductForClientMin[];
  @Input() product: FutureProduct;
  @Input() productTypes: ProductType[];
  @Input() remainingProductTypes: ProductType[];
  @Input() client: Client;
  @Input() index: Number;
  @Input() showMinimal: boolean = false;
  @Output() updateProductData: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  total;

  thumbForm: FormGroup;
  formReady: Boolean;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {}
  hideCustumerPlaceholder;
  ngOnInit(): void {
    var a = this.product;
    var a2 = this.client;
    var a1 = this.productTypes;
    //var a3 = this.clientProducts[0];

    // this.remainingProductTypes = [...this.productTypes];
    this.createFormGroup();
  }
  addNewProduct() {
    if (this.thumbForm.value.productTypeId == 7)
      this.thumbForm.controls.amount.setValue(0);
    var productType = this.productTypes.filter((obj) => {
      return obj.productTypeId === this.thumbForm.value.productTypeId;
    })[0];
    var pricePerUnit: number = Number(productType.priceForPrivate);
    if (!this.showMinimal) {
      pricePerUnit =
        this.client.clientType.clientTypeId == 1
          ? Number(productType.priceForPrivate)
          : Number(productType.priceForPublic);
    }

    this.thumbForm.controls.pricePerUnit.setValue(pricePerUnit);
    this.total = pricePerUnit * Number(this.thumbForm.value.amount);
    this.thumbForm.controls.formTotal.setValue(this.total);
    this.updateProductData.next(this.thumbForm);
  }
  calculateAmount() {
    this.total =
      Number(this.thumbForm.value.pricePerUnit) *
      Number(this.thumbForm.value.amount);
    this.thumbForm.controls.formTotal.setValue(this.total);
    this.updateProductData.next(this.thumbForm);
  }
  saveThis() {}
  increment() {
    var amount = Number(this.thumbForm.controls.amount.value);
    this.thumbForm.controls.amount.setValue(++amount);
    this.calculateAmount();
  }
  decrement() {
    var amount = Number(this.thumbForm.controls.amount.value);
    this.thumbForm.controls.amount.setValue(--amount);
    this.calculateAmount();
  }
  createFormGroup() {
    this.thumbForm = new FormGroup({
      index: new FormControl(this.index),
      productTypeId: new FormControl(
        this.product ? this.product.productTypeId : null,
        [Validators.required]
      ),
      amount: new FormControl(this.product ? this.product.amount : 1, [
        Validators.required,
      ]),
      pricePerUnit: new FormControl(
        this.product && this.product.productType
          ? this.product.productType.priceForPrivate
          : 1,
        [Validators.required]
      ),
      formTotal: new FormControl(0),
      products: new FormControl(this.clientProducts),
    });
    setTimeout(() => {
      this.formReady = true;
    }, 100);
  }
  get productTypeId() {
    return this.thumbForm.get('productTypeId');
  }
  get amount() {
    return this.thumbForm.get('amount').value;
  }
  get pricePerUnit() {
    return this.thumbForm.get('pricePerUnit');
  }
  onCheckedChanged() {
    var amount = this.clientProducts.filter((x) => x.isChecked).length;
    this.thumbForm.controls.amount.setValue(amount);
    var productType = this.productTypes.filter((obj) => {
      return obj.productTypeId === this.thumbForm.value.productTypeId;
    })[0];
    var pricePerUnit: number = Number(productType.priceForPrivate);
    if (!this.showMinimal) {
      pricePerUnit =
        this.client.clientType.clientTypeId == 1
          ? Number(productType.priceForPrivate)
          : Number(productType.priceForPublic);
    }

    this.thumbForm.controls.pricePerUnit.setValue(pricePerUnit);
    this.total = pricePerUnit * Number(this.thumbForm.value.amount);
    this.thumbForm.controls.formTotal.setValue(this.total);
    this.updateProductData.next(this.thumbForm);
  }
}
