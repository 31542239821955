import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Client } from '../data/Client';
import { ClientsService } from './clients.service';
import { HeadFilter } from '../data/HeadFilter';

@Injectable({
  providedIn: 'root',
})
export class ClientsPusherService {
  private readonly clients = new BehaviorSubject<any>([]);
  readonly clients$ = this.clients.asObservable();
  constructor(private clientsService: ClientsService) {}
  headFilter: HeadFilter;
  getAll(headFilter: HeadFilter = null) {
    if (headFilter) this.headFilter = headFilter;
    return this.clientsService.getAllByFilter(headFilter).subscribe((data) => {
      this.clients.next(data);
    });
  }
  put(client: Client) {
    return this.clientsService.put(client).subscribe((data) => {
      this.getAll();
    });
  }
}
