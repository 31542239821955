import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { PrototypeCoefficient } from 'src/app/data/food-genes/PrototypeCoefficient';
import { CoefficientTypeIdEnum } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-coefficient-board',
  templateUrl: './food-genes-coefficient-board.component.html',
  styleUrls: ['./food-genes-coefficient-board.component.scss'],
})
export class FoodGenesCoefficientBoardComponent implements OnInit, OnChanges {
  @Input() coeffType: FgCoefficientType;
  @Input() coeff: FgCoefficient;
  @Input() langId: boolean;
  @Input() canEdit: boolean;
  @Input() versionId: number;
  CoefficientTypeIdEnum = CoefficientTypeIdEnum;
  addMode: 'new' | 'list';
  prototypes: PrototypeCoefficient[];
  selectedPrototype: PrototypeCoefficient;
  constructor(
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coeff && changes.coeff.currentValue) {
      if (changes.coeff.currentValue.fgCoefficientId > 0) {
        this.addMode = 'new';
        this.selectedPrototype = null;
      }
    } else if (changes.coeff && changes.coeff.currentValue === null) {
      this.coeff = new FgCoefficient();
      this.addMode = null;
    }
  }
  ngOnInit(): void {
    if (!this.coeff) {
      this.coeff = new FgCoefficient();
    }
  }
  addModeChange() {
    if (this.addMode === 'list' && !this.prototypes) {
      this.getCoefficientAvailableByVersion();
    } else {
      this.selectedPrototype = null;
      this.coeff = new FgCoefficient();
    }
  }
  onSelectProptotype(event: MatSelectChange) {
    const prototypeId = event.value;
    const prototype = this.prototypes.find(
      (x) => x.fgCoefficientId === prototypeId
    );
    this.selectedPrototype = prototype;
    this.coeff.fgCoefficientName = prototype.fgCoefficientName;
    this.coeff.orderBy = prototype.orderBy;
    this.coeff.validExceptPersent = prototype.validExceptPersent;
  }
  getCoefficientAvailableByVersion() {
    return this.foodGenesService
      .getCoefficientAvailableByVersion(this.versionId)
      .subscribe((res) => {
        this.prototypes = res;
      });
  }
  showSuccessMessage() {
    this._snackBar.open('Success', 'Close', {
      duration: 2000,
    });
  }
  save() {
    this.coeff.fgCoefficientName = this.coeff.fgCoefficientName.replace(
      / /g,
      '_'
    );
    if (this.coeff.fgCoefficientId > 0) {
      this.foodGenesService.updateCoefficient(this.coeff).subscribe((res) => {
        this.foodGenesService.loadCoefficients$.next(true);
        this.showSuccessMessage();
        //this.foodGenesService.mainBoardState$.next(false);
      });
    } else {
      const prototype = new PrototypeCoefficient();
      prototype.fgCoefficientId = 0;
      prototype.fgCoefficientName = this.coeff.fgCoefficientName;
      prototype.fgCoefficientType = this.coeffType.fgCoefficientTypeId;
      prototype.orderBy = this.coeff.orderBy;
      prototype.validExceptPersent = this.coeff.validExceptPersent;
      this.foodGenesService
        .createCoefficient(prototype, this.versionId)
        .subscribe((res) => {
          if ((res as ErrorMessage)?.message) {
            this._snackBar.open((res as ErrorMessage).message, 'Close');
            return;
          } else {
            this.foodGenesService.loadCoefficients$.next(true);
            this.showSuccessMessage();
            //this.foodGenesService.mainBoardState$.next(false);
          }
        });
    }
  }
  cancel() {
    this.foodGenesService.mainBoardState$.next(false);
  }
}
