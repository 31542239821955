import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-scales',
  templateUrl: './food-scales.component.html',
  styleUrls: ['./food-scales.component.css']
})
export class FoodScalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
