import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoBulletImage } from '../data/AlgoBulletImage';

@Injectable({
  providedIn: 'root',
})
export class AlgoBulletImagesService {
  constructor(private http: HttpClient) {}

  post(image) {
    return this.http.post<any>(
      `${environment.baseUrl}AlgoBulletImages/AlgoBulletImages`,
      image
    );
  }

  getImages() {
    return this.http.get<AlgoBulletImage[]>(
      `${environment.baseUrl}AlgoBulletImages/GetMinAlgoBulletImages`
    );
  }
  getSingleImg(id: number) {
    return this.http.get<AlgoBulletImage>(
      `${environment.baseUrl}AlgoBulletImages/${id}`
    );
  }
}
