import { Component, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';

@Component({
  selector: 'app-dynamic-typed-message',
  templateUrl: './dynamic-typed-message.component.html',
  styleUrls: ['./dynamic-typed-message.component.scss'],
})
export class DynamicTypedMessageComponent implements OnInit {
  message: ChatMessage;
  langId: number;
  constructor() {}

  ngOnInit(): void {}

  getRidSlashN(text: string) {
    return text.replace(/\\n/g, '');
  }
}
