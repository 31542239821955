<h2 mat-dialog-title>Video categories</h2>
<mat-dialog-content class="mat-typography">
  <div class="cat-table">
    <div class="cat-table__header">
      <div class="cat-table__header__item">Category name</div>
      <div class="cat-table__header__item count">Num of videos</div>
      <div class="cat-table__header__item action">
        <button mat-button (click)="addNewCategory()" [disabled]="editMode">
          + Add new category
        </button>
      </div>
    </div>
    <div class="cat-table__body">
      <div class="cat-table__body__item" *ngFor="let item of videoCategories">
        <div class="cat-table__body__item__name">
          <ng-container *ngIf="!isEditMode(item.videoCategoryId)">{{
            item.categoryName
          }}</ng-container>
          <mat-form-field
            #categoryName
            appearance="fill"
            *ngIf="isEditMode(item.videoCategoryId)"
          >
            <input matInput [(ngModel)]="item.categoryName" />
          </mat-form-field>
        </div>
        <div class="cat-table__body__item__count">{{ item.videoCount }}</div>
        <div class="cat-table__body__item__action">
          <button
            *ngIf="isEditMode(item.videoCategoryId)"
            (click)="save(item)"
            mat-icon-button
            class="check"
          >
            <mat-icon>check</mat-icon>
          </button>
          <button
            (click)="toggleEditMode(item)"
            mat-icon-button
            [disabled]="editMode && catToEdit !== item.videoCategoryId"
            class="edit"
          >
            <mat-icon>{{
              !isEditMode(item.videoCategoryId) ? "edit" : "close"
            }}</mat-icon>
          </button>
          <button
            *ngIf="!isEditMode(item.videoCategoryId)"
            (click)="delete(item)"
            mat-icon-button
            [disabled]="editMode && catToEdit !== item.videoCategoryId"
            class="delete"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    [disabled]="editMode"
    type="button"
    class="close"
    mat-button
    (click)="close()"
  >
    Close
  </button>
</mat-dialog-actions>
