<div *ngIf="lifestyleQuestions">
  <button (click)="accordion1.openAll(); accordion2.openAll()">
    Expand All
  </button>
  <button (click)="accordion1.closeAll(); accordion2.closeAll()">
    Collapse All
  </button>
  <h1>nodes</h1>
  <mat-accordion [multi]="true" #accordion1="matAccordion">
    <div *ngFor="let question of lifestyleQuestions">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ question.answerText }} </mat-panel-title>
        </mat-expansion-panel-header>
        <app-lifestyle-question-node
          [question]="question"
          [designTypes]="designTypes"
          (addNewQuest)="onAddNewQuestion($event)"
          (deleteQuest)="onDeleteQuestion($event)"
        >
        </app-lifestyle-question-node>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
  <h1>templates</h1>
  <mat-accordion [multi]="true" #accordion2="matAccordion">
    <div *ngFor="let question of templates">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ question.answerText }} </mat-panel-title>
        </mat-expansion-panel-header>
        <app-lifestyle-question-node
          [question]="question"
          [designTypes]="designTypes"
          (addNewQuest)="onAddNewQuestion($event)"
          (deleteQuest)="onDeleteQuestion($event)"
        >
        </app-lifestyle-question-node>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
<button (click)="addNewQuestion()">add</button>
<br />
<button (click)="addTemplateQuestion()">add template</button>
