<div class="table">
  <div class="table__item header">
    <span class="name">
      {{ !isRecipe ? "Food" : "Recipe" }}
    </span>
    <span class="source">Source</span>
    <span class="date">Date added</span>
    <span class="date">Language</span>
    <span style="width: 60px"></span>
  </div>

  <div *ngFor="let item of data" class="table__item">
    <div class="table__item__name name">
      <mat-checkbox #matCheckbox (change)="isChecked(item.id, $event.checked)">
        <span>{{ item.name }}</span>
      </mat-checkbox>

      <!--  (click)="stopPropegation($event)"-->
    </div>
    <span class="source">{{ getSourceKey(item.sourceKey) }}</span>
    <span class="date">{{ item.dateCreated | date: "MM/dd/yyyy" }}</span>
    <span class="langs">{{ getLangs(item.langs) }}</span>
    <button class="edit" mat-icon-button (click)="goToEditfood(item)">
      <mat-icon>border_color</mat-icon>
    </button>
    <button class="delete" mat-icon-button (click)="delete(item)">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>
