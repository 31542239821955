export enum PageTypeEnum {
  leads = 'leads',
  clients = 'clients',
  tests = 'tests',
  transactions = 'transactions',
  serviceRequests = 'serviceRequests',
  appReports = 'appReports',
  cancelations = 'cancelations',
  refunds = 'refunds',
  algoBullets = 'algoBullets',
  callsReviews = 'callsReviews',
  foods = 'foods',
  foodCategories = 'foodCategories',
  videos = 'videos',
  snps = 'snps',
  snpVersion = 'snpVersion',
  translation = 'translation',
  labPackages = 'labPackages',
}
export enum CancelModeEnum {
  newRequest = 'new',
  conservation = 'conservation',
  manager = 'manager',
  closed = 'closed',
}
