import { Component, OnInit } from '@angular/core';
import { ExternalFoodFileTypes } from 'src/app/data/ExternalFoodFileTypes';
import { FoodNutrient } from 'src/app/data/FoodNutrient';
import { Status } from 'src/app/data/Status';
import { ZameretData } from 'src/app/data/ZameretData';
import { ZameretNutrientList } from 'src/app/data/ZameretNutrientList';
import { NutrientType } from 'src/app/Enums/StatusesEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ZameretNutrientListsService } from 'src/app/Services/zameret-nutrient-lists.service';

@Component({
  selector: 'app-upload-zameret-data',
  templateUrl: './upload-zameret-data.component.html',
  styleUrls: ['./upload-zameret-data.component.scss'],
})
export class UploadZameretDataComponent implements OnInit {
  zameretData: ZameretData;
  fileTypes: ExternalFoodFileTypes[];
  zameretList: ZameretNutrientList[];
  nutrientTypes: Status[];
  nutrients: FoodNutrient[];
  displayedColumns: string[] = [
    'columnName',
    'nutrientName',
    'nutrientType',
    'isNutrient',
  ];
  constructor(
    private zameretNutrientListsService: ZameretNutrientListsService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getZameretData();
  }
  getFileUploadPath(fileId) {
    return this.zameretNutrientListsService.uploadFile(fileId);
  }

  uploadFileFinished(event) {
    //console.log('uploadFileFinished: ', event);

    if (event == true) {
      this.getZameretData();
    }
  }

  getZameretData() {
    this.zameretNutrientListsService.getZameretData().subscribe((data) => {
      this.zameretData = data;
      this.fileTypes = this.zameretData.fileTypes;
      this.zameretList = this.zameretData.zameretList;
      this.nutrientTypes = this.zameretData.nutrientTypes;
      this.nutrients = this.zameretData.nutrients;
    });
  }

  submit() {
    this.zameretList = this.zameretList.map((x) => {
      if (x.isNutrient) {
        x.isNutrient = 1;
      } else {
        x.isNutrient = 0;
      }
      return x;
    });
    this.zameretNutrientListsService.put(this.zameretList).subscribe(() => {
      this.popupHandlerService.openDisapearingAlertDialog('success');
      this.getZameretData();
    });
  }
}
