import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActionResponse } from '../data/ActionResponse';
import { BaseResponce } from '../data/algorithm/BaseResponce';
import { PrototypeSnpByVer } from '../data/algorithm/PrototypeSnpByVer';
import { ErrorMessage } from '../data/ErrorMessage';
import { FgCalculatorFunctions } from '../data/food-genes/FgCalculatorFunctions';
import { FgCoefficient } from '../data/food-genes/FgCoefficient';
import { FgCoefficientSnp } from '../data/food-genes/FgCoefficientSnp';
import { FgCoefficientType } from '../data/food-genes/FgCoefficientType';
import { FgFoodToUserAlergan } from '../data/food-genes/FgFoodToUserAlergan';
import { FgFormula } from '../data/food-genes/FgFormula';
import { FgFullLabel } from '../data/food-genes/FgFullLabel';
import { FgParamType } from '../data/food-genes/FgParamType';
import { FgSimulationParam } from '../data/food-genes/FgSimulationParam';
import { FgSnp } from '../data/food-genes/FgSnp';
import { FgVersion } from '../data/food-genes/FgVersion';
import { FoodAlert } from '../data/food-genes/FoodAlert';
import { FoodCat } from '../data/food-genes/FoodCat';
import { FoodCategoryType } from '../data/food-genes/FoodCategoryType';
import { FoodGenesChangeLog } from '../data/food-genes/FoodGenesChangeLog';
import { FoodMin } from '../data/food-genes/FoodMin';
import { FoodRest } from '../data/food-genes/FoodRest';
import { MakeEffort } from '../data/food-genes/MakeEffort';
import { PrototypeCoefficient } from '../data/food-genes/PrototypeCoefficient';
import { PrototypeFormula } from '../data/food-genes/PrototypeFormula';
import { PrototypeParam } from '../data/food-genes/PrototypeParam';
import { SimulationResult } from '../data/food-genes/SimulationResult';
import { HeadFilter } from '../data/HeadFilter';
import { PaginationMetadata } from '../data/PaginationMetadata';

export interface HistoryPaginationObject {
  list: FoodGenesChangeLog[];
  metadata: PaginationMetadata;
}
export interface AllergiesPaginationObject {
  list: FgFoodToUserAlergan[];
  metaData: PaginationMetadata;
}

@Injectable({
  providedIn: 'root',
})
export class FoodGenesService {
  renderCat$: Subject<number> = new Subject<number>();
  loadCoefficients$: Subject<boolean> = new Subject<boolean>();
  mainBoardState$: Subject<boolean> = new Subject<boolean>();
  formula$: BehaviorSubject<FgFormula> = new BehaviorSubject<FgFormula>(null);
  formulas$: Subject<FgFormula[]> = new Subject<FgFormula[]>();
  versions$: BehaviorSubject<FgVersion[]> = new BehaviorSubject<FgVersion[]>(
    []
  );
  saveFormulas: FgFormula[];

  constructor(private http: HttpClient) {}

  //#region Coefficient
  createCoefficient(coefficient: PrototypeCoefficient, versionId: number) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/CreateCoefficient/${versionId}`,
      coefficient
    );
  }
  updateCoefficient(coefficient: FgCoefficient) {
    return this.http.put<any>(
      `${environment.baseUrl}FoodGenes/UpdateCoefficient`,
      coefficient
    );
  }
  removeCoefficient(coefficientId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}FoodGenes/RemoveCoefficient/${coefficientId}`
    );
  }
  getCoefficientById(coefficientId: number) {
    return this.http.get<FgCoefficient>(
      `${environment.baseUrl}FoodGenes/GetCoefficientById/${coefficientId}`
    );
  }
  getCoefficientAvailableByVersion(versionId: number) {
    return this.http.get<PrototypeCoefficient[]>(
      `${environment.baseUrl}FoodGenes/GetCoefficientAvailableByVersion/${versionId}`
    );
  }
  removeCoefficientSnp(coefficientSnpId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/CoefficientSnpRemove/${coefficientSnpId}`
    );
  }
  getCoefficientTypesWithCoefficients(versionId: number) {
    return this.http.get<FgCoefficientType[]>(
      `${environment.baseUrl}FoodGenes/GetCoefficientTypesWithCoefficients/${versionId}`
    );
  }

  //#endregion

  //#region Formula
  getAvailableFormulaForVersion(versionId: number) {
    return this.http.get<PrototypeFormula[]>(
      `${environment.baseUrl}FoodGenes/GetAvailableFormulaForVersion/${versionId}`
    );
  }
  getAvailableFormulasForVersionBySourceVersion(
    sourseVersionId: number,
    targetVersionId: number
  ) {
    return this.http.get<FgFormula[] | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/GetAvailableFormulasForVersionBySourceVersion/${sourseVersionId}/${targetVersionId}`
    );
  }
  getFormulaByOwnerCoefficient(versionId: number, coefficientId: number) {
    return this.http.get<FgFormula[]>(
      `${environment.baseUrl}FoodGenes/GetFormulaByOwnerCoefficient/${versionId}/${coefficientId}`
    );
  }
  getFormulaById(formulaId: number) {
    return this.http.get<FgFormula>(
      `${environment.baseUrl}FoodGenes/GetFormulaById/${formulaId}`
    );
  }
  addFormula(
    formula: PrototypeFormula,
    versionId: number,
    ownerId: number,
    ownerType: number,
    sourceFormulaId: number
  ) {
    return this.http.post<FgFormula | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/AddFormula/${versionId}/${ownerId}/${ownerType}/${sourceFormulaId}`,
      formula
    );
  }
  getCalcFunctions() {
    return this.http.get<FgCalculatorFunctions[]>(
      `${environment.baseUrl}FoodGenes/GetCalcFunctions`
    );
  }
  getFormulaAvailableParams(formulaId: number) {
    return this.http.get<FgParamType[]>(
      `${environment.baseUrl}FoodGenes/GetFormulaAvailableParams/${formulaId}`
    );
  }
  updateFormula(formula: FgFormula) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/UpdateFormula/${formula.fgVersionId}`,
      formula
    );
  }
  checkFormula(formulaId: number) {
    return this.http.get<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/CheckFormula/${formulaId}`
    );
  }
  getFormulaByOwnerCoefficientType(
    versionId: number,
    coefficientTypeId: number
  ) {
    return this.http.get<FgFormula[]>(
      `${environment.baseUrl}FoodGenes/GetFormulaByOwnerCoefficientType/${versionId}/${coefficientTypeId}`
    );
  }
  removeFormula(formulaId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/RemoveFormula/${formulaId}`
    );
  }
  //#endregion

  //#region Labels
  getLabels(langId: number, startIndex: number, fetchIndex: number) {
    return this.http.get<FgFullLabel[]>(
      `${environment.baseUrl}FoodGenes/GetLabels/${langId}/${startIndex}/${fetchIndex}`
    );
  }
  getLabelById(langId: number, labelId: number) {
    return this.http.get<FgFullLabel>(
      `${environment.baseUrl}FoodGenes/GetLabelById/${langId}/${labelId}`
    );
  }
  createLabel(label: FgFullLabel) {
    return this.http.post<any>(
      `${environment.baseUrl}FoodGenes/CreateLabel`,
      label
    );
  }
  updateLabel(label: FgFullLabel) {
    return this.http.put<any>(
      `${environment.baseUrl}FoodGenes/UpdateLabel`,
      label
    );
  }
  //#endregion

  //#region Tags
  getAlerts(langId: number, startIndex: number, fetchIndex: number) {
    return this.http.get<FoodAlert[]>(
      `${environment.baseUrl}FoodGenes/GetAlerts/${langId}/${startIndex}/${fetchIndex}`
    );
  }
  createAlert(alert: FoodAlert) {
    return this.http.post<any>(
      `${environment.baseUrl}FoodGenes/CreateAlert`,
      alert
    );
  }
  updateAlert(alert: FoodAlert) {
    return this.http.put<any>(
      `${environment.baseUrl}FoodGenes/UpdateAlert`,
      alert
    );
  }
  getTagsForParams(langId: number) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetTagsForParams/${langId}`
    );
  }
  //#endregion

  //#region Snps
  getSnpByCoefficient(coefficientId: number) {
    return this.http.get<FgCoefficientSnp[]>(
      `${environment.baseUrl}FoodGenes/GetSnpByCoefficient/${coefficientId}`
    );
  }
  getSnpById(snpId: number) {
    return this.http.get<FgCoefficientSnp>(
      `${environment.baseUrl}FoodGenes/GetSnpById/${snpId}`
    );
  }
  getAvailableSnpForSubCat(versionId: number, coefficientId: number) {
    return this.http.get<PrototypeSnpByVer[]>(
      `${environment.baseUrl}FoodGenes/AvailableSnpForSubCatGet/${versionId}/${coefficientId}`
    );
  }
  //#endregion

  //#region CoefficientSnp
  createCoefficientSnp(fgSnp: FgSnp, coefficientId: number, versionId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}FoodGenes/CreateCoefficientSnp/${versionId}/${coefficientId}`,
      fgSnp
    );
  }
  updateCoefficientSnpAllele(
    fgCoefficientSnp: FgCoefficientSnp,
    versionId: number
  ) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/UpdateCoefficientSnpAllele/${versionId}`,
      fgCoefficientSnp
    );
  }
  //#endregion

  //#region History
  getFoodGenesHistory(
    typeId: number,
    versionId: number,
    startIndex: number,
    fetchIndex: number
  ) {
    return this.http.get<HistoryPaginationObject | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/GetFoodGenesHistory/${typeId}/${versionId}/${startIndex}/${fetchIndex}`
    );
  }
  getFoodGenesLoggerTypes(moduleId = 61) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetLoggerTypes/${moduleId}`
    );
  }
  //#endregion

  //#region Simulator
  getParamsForSimulator(versionId: number) {
    return this.http.get<FgSimulationParam[]>(
      `${environment.baseUrl}FoodGenes/GetParamsForSimulator/${versionId}`
    );
  }
  simulateByVersion(
    versionId: number,
    foodId: number,
    parameters: FgSimulationParam[],
    langId: number
  ) {
    return this.http.post<SimulationResult>(
      `${environment.baseUrl}FoodGenes/SimulateByVersion/${versionId}/${foodId}/${langId}`,
      parameters
    );
  }
  //#endregion

  //#region Parameters
  getParamTypesWithParams(versionId: number) {
    return this.http.get<FgParamType[]>(
      `${environment.baseUrl}FoodGenes/GetParamTypesWithParams/${versionId}`
    );
  }
  addParamToVersion(param: PrototypeParam, versionId: number) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/AddParamToVersion/${versionId}`,
      param
    );
  }
  setParam(param: PrototypeParam, versionId: number) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/SetParam/${versionId}`,
      param
    );
  }
  removeParameter(paramId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/RemoveParameter/${paramId}`
    );
  }
  getParamAvailableByVersion(versionId: number, typeId: number) {
    return this.http.get<PrototypeParam[]>(
      `${environment.baseUrl}FoodGenes/GetParamAvailableByVersion/${versionId}/${typeId}`
    );
  }
  getNutrientsForParams(langId: number) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetNutrientsForParams/${langId}`
    );
  }
  getAlergensForParams(langId: number) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetAlergensForParams/${langId}`
    );
  }
  getBloodResultTypesForParams(langId: number) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetBloodResultTypesForParams/${langId}`
    );
  }
  getBaseResponceFormulasByVersion(versionId: number) {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}FoodGenes/GetBaseResponceFormulasByVersion/${versionId}`
    );
  }
  //#endregion

  //#region Food Categories
  getFoodCategories(langId: number, categoryType: number) {
    return this.http.get<FoodCat[]>(
      `${environment.baseUrl}FoodGenes/GetFoodCategories/${langId}/${categoryType}`
    );
  }
  searchFoodCategories(search: string, langId: number, categoryType: number) {
    return this.http.get<FoodCat[]>(
      `${environment.baseUrl}FoodGenes/SearchFoodCategories/${langId}/${search}/${categoryType}`
    );
  }
  addFoodCategory(foodCategories: FoodCat) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/AddFoodCategory`,
      foodCategories
    );
  }
  setFoodCategory(foodCategories: FoodCat) {
    return this.http.put<any>(
      `${environment.baseUrl}FoodGenes/SetFoodCategory`,
      foodCategories
    );
  }
  removeFoodCategory(foodCategoryId: number, langId: number) {
    return this.http.delete<any | FoodMin[]>(
      `${environment.baseUrl}FoodGenes/RemoveFoodCategory/${foodCategoryId}/${langId}`
    );
  }
  getDifficulties(langId: number) {
    return this.http.get<MakeEffort[]>(
      `${environment.baseUrl}FoodGenes/GetMakeEfforts/${langId}`
    );
  }
  getRests(langId: number) {
    return this.http.get<FoodRest[]>(
      `${environment.baseUrl}FoodGenes/GetRests/${langId}`
    );
  }
  foodToCategory(catId: number, foods: number[]) {
    return this.http.post<any>(
      `${environment.baseUrl}FoodGenes/FoodToCategory/${catId}`,
      foods
    );
  }
  getFoodCategoryTypes(langId: number) {
    return this.http.get<FoodCategoryType[]>(
      `${environment.baseUrl}FoodGenes/GetFoodCategoryTypes/${langId}`
    );
  }
  getFoodCatsById(foodCatId: number, langId: number) {
    return this.http.get<FoodCat>(
      `${environment.baseUrl}FoodGenes/GetFoodCategory/${langId}/${foodCatId}`
    );
  }
  //#endregion

  //#region Versions
  getFgVersions(langId: number, versionId = 0) {
    return this.http.get<FgVersion[]>(
      `${environment.baseUrl}FoodGenes/GetFgVersions/${versionId}/${langId}`
    );
  }
  createFgVersion(
    labVersionId: number,
    versionName: string,
    duplicateFrom: number
  ) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/FgVersionCreate/${labVersionId}/${versionName}/${duplicateFrom}`,
      {}
    );
  }
  updateFgVersion(
    fgVersionId: number,
    versionName: string,
    finishFormulaId: number
  ) {
    return this.http.put<any>(
      `${environment.baseUrl}FoodGenes/FgVersionUpdate/${fgVersionId}/${versionName}/${finishFormulaId}`,
      {}
    );
  }
  lockFgVersion(fgVersionId: number, isLocked: boolean) {
    return this.http.get<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/FgVersionLocking/${fgVersionId}/${isLocked}`
    );
  }
  duplicateFgVersion(duplicateTo: number, duplicateFrom: number) {
    return this.http.get<any | ErrorMessage>(
      `${environment.baseUrl}FoodGenes/FgVersionDuplicate/${duplicateTo}/${duplicateFrom}`
    );
  }
  //#endregion

  //#region FoodAllergies

  getFoodAlergans(headFilter: HeadFilter) {
    return this.http.get<AllergiesPaginationObject>(
      `${environment.baseUrl}FoodGenes/GetFoodAlergans/${headFilter.langId}/${headFilter.pageNumber}/${headFilter.pageSize}`
    );
  }
  insertFoodAlergans(foodAlergans: FgFoodToUserAlergan, langId: number) {
    return this.http.post<ActionResponse>(
      `${environment.baseUrl}FoodGenes/InsertFoodAlergans/${langId}`,
      foodAlergans
    );
  }
  setFoodUserAlergans(foodAlergans: FgFoodToUserAlergan, langId: number) {
    return this.http.put<ActionResponse>(
      `${environment.baseUrl}FoodGenes/SetFoodUserAlergans/${langId}`,
      foodAlergans
    );
  }

  //#endregion
}
