<div class="wrapper" [class.is-loading]="isLoading">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <div [class.is-loading]="isLoading">
    <mat-dialog-content class="mat-typography">
      <div class="title">Duplicate version</div>
      <div class="form">
        <div class="item mb-2 not-margin">
          <!-- <div class="item__label">You dont have to choose version:</div> -->
          <mat-form-field class="item__field" appearance="fill">
            <mat-select
              placeholder="select laboratory version"
              [(ngModel)]="selectedDupVersionId"
            >
              <mat-option
                *ngFor="let item of versions"
                [value]="item.labVersionId"
                >{{ item.labName | uppercase }} - {{ item.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="item mb-2">
          <mat-checkbox
            [(ngModel)]="rewriteSettings"
            (change)="onRewriteChange()"
          >
            Rewrite regex settings
          </mat-checkbox>
        </div>
      </div>
      <div class="error-message" *ngIf="errorMessage">
        Error: {{ errorMessage }}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="actions">
        <button mat-button mat-dialog-close>Cancel</button>
        <button
          [disabled]="!selectedDupVersionId"
          class="save"
          (click)="onSubmit()"
          mat-button
          type="submit"
        >
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
