import { Food } from './Food';
import { FoodScale } from './FoodScale';
import { RecipeBulletLang } from './RecipeBulletLang';
import { RecipeHeader } from './RecipeHeader';

export class RecipeBullet {
  recipeBulletId: number;
  recipeHeaderId: number;
  recipeHeader: RecipeHeader;
  parentFoodId: number;
  ingredientFoodId: number;
  ingredientFood: Food;
  foodScaleId: number | null;
  weight: number | null;
  foodScale: FoodScale;
  quantity: number | null;
  recipeBulletLangs: RecipeBulletLang[];
}
