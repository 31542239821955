<perfect-scrollbar [scrollIndicators]="true" class="sidenav-snp">
  <div class="add-button-wrapper">
    <ng-container
      *ngIf="
        canEdit &&
        selectedCoeffType?.fgCoefficientTypeId !== CoefficientTypeIdEnum.Alerts
      "
    >
      <ng-container *ngIf="mode == FoodGenesSidenavEnum.snp">
        <button
          class="add-button"
          (click)="getAvailableCoeffSnps()"
          mat-mini-fab
        >
          <mat-icon mat-list-icon>add</mat-icon>
        </button>
        <button mat-button (click)="getAvailableCoeffSnps()" class="add-text">
          Add RS
        </button>
      </ng-container>
      <ng-container
        *ngIf="
          mode == FoodGenesSidenavEnum.param &&
          !userAutomaticTypes.includes(selectedParamType.fgParamTypeId)
        "
      >
        <button class="add-button" (click)="addParameter()" mat-mini-fab>
          <mat-icon mat-list-icon>add</mat-icon>
        </button>
        <button mat-button (click)="addParameter()" class="add-text">
          Add parameter
        </button>
      </ng-container>
    </ng-container>
  </div>
  <mat-list class="snp-list">
    <div [ngSwitch]="mode">
      <div *ngSwitchCase="FoodGenesSidenavEnum.snp">
        <ng-container *ngIf="coeffSnps">
          <mat-list-item
            matRipple
            (click)="onSelectSnp(snp)"
            *ngFor="let snp of coeffSnps"
            [ngClass]="{
              selected: snp.fgCoeffSnpId === selectedSnp?.fgCoeffSnpId
            }"
            class="snp-item rs"
          >
            <div
              style="width: 100%"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              {{ snp.fgsnp.prototypeSnpNumber }}
              <button
                *ngIf="canEdit"
                class="delete-btn"
                (click)="removeSnp(snp, $event)"
                mat-icon-button
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </ng-container>
      </div>
      <div *ngSwitchCase="FoodGenesSidenavEnum.param">
        <ng-container *ngIf="params">
          <mat-list-item
            matRipple
            (click)="onSelectParam(param)"
            *ngFor="let param of params"
            [ngClass]="{
              selected: param.fgParamId === selectedParam?.fgParamId
            }"
            class="snp-item rs"
          >
            <div
              style="width: 100%"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              {{ param.fgParamName }}
              <button
                *ngIf="canEdit && showDeleteParamBtn()"
                class="delete-btn"
                (click)="removeParameter(param, $event)"
                mat-icon-button
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </ng-container>
      </div>
    </div>

    <mat-list-item
      *ngIf="mode == FoodGenesSidenavEnum.snp"
      matRipple
      (click)="onSelectFormula()"
      [ngClass]="{
        selected: isFormulaSelected
      }"
      class="snp-item summary text-center"
    >
      Formula
    </mat-list-item>
  </mat-list>
</perfect-scrollbar>
