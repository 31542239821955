import { Country } from './Country';

export class PhoneVerificationSetting {
  phoneVerificationSettingId: number;
  countryId: number;
  country: Country;
  countryCode: string;
  phonePrefixs: string;
  mobilePrefixs: string;
  maxNumLength: number;
  minNumLength: number;
  localPrefix: string;
}
