<div [dir]="dir()" class="food-genes">
  <mat-sidenav-container class="sidenav-full">
    <mat-sidenav
      opened
      #mainNav
      mode="side"
      position="start"
      role="navigation"
      class="main-sidenav"
      [disableClose]="true"
    >
      <!-- 
      [isLoading]="isLoadingCats"
      [selectedCategory]="selectedCategory"
      [selectedSubCategory]="selectedSubCategory"
      [categories]="categories"
       -->
      <app-food-genes-sidenav
        *ngIf="coefficiernts && languages && versions && paramsType"
        [coefficiernts]="coefficiernts"
        [parameters]="paramsType"
        [selectedCoeffType]="selectedCoeffType"
        [selectedParamType]="selectedParamType"
        [canSearch]="userCanSearch()"
        [canEdit]="canEdit"
        [sidenavSnp]="snpNav"
        [languages]="languages"
        [fgVersions]="versions"
        [langId]="langId"
        [versionId]="versionId"
        [dir]="dir()"
        [indexExpanded]="indexExpanded"
        (selected)="onSelected($event)"
        (closeMainBoard)="onCloseMainBoard($event)"
        (changeLanguage)="onChangeLanguage($event)"
        (searchSnp)="onSearchSnp($event)"
        (changeVersion)="onChangeVersion($event)"
        (loadCategories)="onLoadCoeffs($event)"
        (openMainBoardEvent)="openMainBoard($event)"
        (selectParam)="onSelected($event)"
      ></app-food-genes-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-sidenav-container class="sidenav-full">
        <mat-sidenav
          #snpNav
          mode="side"
          position="start"
          role="navigation"
          class="snp-nav"
          [disableClose]="true"
        >
          <app-food-genes-sidenav-secondary
            [coefficients]="baseCoefficients"
            [mode]="sidenavMode"
            [versionId]="versionId"
            [canEdit]="canEdit"
            [langId]="langId"
            [sidenavSnp]="snpNav"
            [selectedCoeff]="selectedCoeff"
            [selectedCoeffType]="selectedCoeffType"
            [selectedSnp]="selectedSnp"
            [coeffSnps]="coeffSnps"
            [params]="params"
            [formulas]="formulas"
            [selectedParam]="selectedParam"
            [selectedParamType]="selectedParamType"
            [availableParams]="availableParams"
            (selectSnp)="onSelectSnp($event)"
            (selectFormula)="onSelectFormula($event)"
            (selectParam)="onSelectParam($event)"
            (closeMainBoard)="onCloseMainBoard($event)"
            (loadSnps)="onLoadSnps($event)"
            (loadParams)="onLoadParams($event)"
          ></app-food-genes-sidenav-secondary>
        </mat-sidenav>

        <mat-sidenav-content>
          <app-food-genes-main-board
            *ngIf="mainBoardOpened"
            [calcFunctions]="calcFunctions"
            [canEdit]="canEdit"
            [langId]="langId"
            [snp]="selectedSnp"
            [param]="selectedParam"
            [mode]="mainBoardMode"
            [versionId]="versionId"
            [baseCoeff]="baseCoefficients"
            [coeffType]="selectedCoeffType"
            [coeff]="selectedCoeff"
            (loadCoeffs)="onLoadCoeffs($event)"
            (closeMainBoard)="onCloseMainBoard($event)"
          ></app-food-genes-main-board>
          <!-- <div *ngIf="mainBoardOpened">
              <app-main-board
                [mode]="mainBoardMode"
                [snp]="algoSubCategorySnp"
                [category]="selectedCategory"
                [subCategory]="selectedSubCategory"
                [subCatResultTypes]="subCatResultTypes"
                [results]="snpResults"
                [langId]="langId"
                [versionId]="versionId"
                [canEdit]="canEdit"
                [dir]="dir()"
                (selectResult)="onSelectSearchResult($event)"
                (loadResultTypes)="onLoadResultTypes($event)"
                (loadCategories)="onLoadCategories($event)"
                (loadRs)="onLoadRs($event)"
                (closeMainBoard)="onCloseMainBoard($event)"
              ></app-main-board>
            </div> -->
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
