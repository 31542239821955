export enum StatementType {
  SystemMessage = 7,
  ConversationMessage = 8,
  ChoiceMessage = 10,
  HiddenChoice = 11,
  AnswerMessage = 14,
  StartQSequence = 15,
}
export enum TemplateContentType {
  ReadOnly = 5,
  ClickSegment = 6,
}
export enum TemplateType {
  General = 1,
  Complex = 2,
  DynamicTyped = 3,
  Review = 4,
}
export enum MessageStatus {
  New = 5,
  Readed = 6,
  Answered = 14,
}
export enum SessionStatus {
  //All = -1,
  New = 5,
  Active = 7,
  Closed = 4,
  Connected = 8,
  Waiting = 9,
}
export enum SessionState {
  //All = -1,
  Active = 7,
  Closed = 4,
}
export enum DepartmentType {
  All = 0,
  //Sales = 1,
  Support = 2,
  Nutritionist = 3,
  //Finance = 4,
}
