import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailTemplateLang } from '../data/EmailTemplateLang';
import { environment } from 'src/environments/environment';
import { FileUpload } from '../data/FileUpload';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesLangsService {
  GetTemplateFiles(templateId: number, langId: number) {
    return this.http.get<FileUpload[]>(
      `${environment.baseUrl}EmailTemplateLangs/GetTemplateFiles/${templateId}/${langId}`
    );
  }
  getFileUploadPath(templateId: number, langId: number) {
    return `EmailTemplateLangs/UploadFile/${templateId}/${langId}`;
  }
  constructor(private http: HttpClient) {}

  getByTemplateId(templateId: number, langId: number) {
    return this.http.get<EmailTemplateLang>(
      `${environment.baseUrl}EmailTemplateLangs/ByTemplateAndLang/${templateId}/${langId}`
    );
  }
  getByTemplateIdForSend(
    templateId: number,
    langId: number,
    clientId: number = -1,
    productId: number = -1
  ) {
    return this.http.get<EmailTemplateLang>(
      `${environment.baseUrl}EmailTemplateLangs/ForClientOrProduct/${templateId}/${langId}/${clientId}/${productId}`
    );
  }

  post(emailTemplateLang: EmailTemplateLang) {
    return this.http.post<EmailTemplateLang>(
      `${environment.baseUrl}EmailTemplateLangs`,
      emailTemplateLang
    );
  }

  put(emailTemplateLang: EmailTemplateLang) {
    return this.http.put<EmailTemplateLang>(
      `${environment.baseUrl}EmailTemplateLangs/${emailTemplateLang.emailTempleteLangId}`,
      emailTemplateLang
    );
  }
}
