import { Component, Input, OnInit } from '@angular/core';
import { RegularUserForTable } from 'src/app/data/RegularUserForTable';
import { RoleTypeForTable } from 'src/app/data/RoleTypeForTable';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.scss'],
})
export class RolesTableComponent implements OnInit {
  @Input() rolesData: RoleTypeForTable[];
  users: RegularUserForTable[];
  constructor(
    private popupHandlerService: PopupHandlerService,
    private regularUsersService: RegularUsersService
  ) {}

  ngOnInit(): void {}

  getRoleUsers() {}

  onUsersClick(roleTypeId: number) {
    this.regularUsersService
      .getActiveRoleUsers(roleTypeId)
      .subscribe((data) => {
        this.users = data;
        this.popupHandlerService.openRoleUsersDialog(this.users);
      });
  }
}
