import { Component, OnInit } from '@angular/core';
import { AgentMin } from 'src/app/data/AgentMin';
import { GlobalParamsEnum, UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { copy } from 'copy-anything';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-far-login-admin',
  templateUrl: './far-login-admin.component.html',
  styleUrls: ['./far-login-admin.component.scss'],
})
export class FarLoginAdminComponent implements OnInit {
  agents: AgentMin[];
  agentId: number;
  agentsStr: AgentMin[];
  constructor(
    private agentsService: AgentsService,
    private globalParamsService: GlobalParamsService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (!this.agents) this.getAgents();
  }
  getActiveAgent() {
    this.globalParamsService
      .getByName(GlobalParamsEnum.FarLoginSupportAdmin)
      .subscribe((data) => {
        this.agentId = +data.value;
      });
  }
  getAgents() {
    this.agentsService.getAll().subscribe((data) => {
      this.agents = [...data].filter(
        (x) =>
          x.roleTypeId == UserRoleEmum.Manager ||
          x.roleTypeId == UserRoleEmum.Developer ||
          x.roleTypeId == UserRoleEmum.SalesManager ||
          x.roleTypeId == UserRoleEmum.SupportManager ||
          x.roleTypeId == UserRoleEmum.nutritionistManager
      );
      // recreate agents array because the agentsService saved the agents in memory after the first time
      // and the map function will create duplicates role names when navigating other pages
      // copy function does deep copy of the object
      this.agentsStr = copy(this.agents)
        .map((x) => {
          x.name = UserRoleEmum[x.roleTypeId] + ' - ' + x.name;
          return x;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      this.getActiveAgent();
    });
  }
  save() {
    const globalParam = new GlobalParam();
    globalParam.globalParamsId = 9;
    globalParam.key = GlobalParamsEnum.FarLoginSupportAdmin;
    globalParam.value = this.agentId.toString();
    this.globalParamsService
      .putByName(GlobalParamsEnum.FarLoginSupportAdmin, globalParam)
      .subscribe((data) => {
        this.getActiveAgent();
        this._snackBar.open('Saved!', 'Close', {})._dismissAfter(4000);
      });
  }
}
