import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SNPResult } from '../data/SNPResult';
import { SnpResultLangMin } from '../data/SnpResultLangMin';

@Injectable({
  providedIn: 'root',
})
export class SnpResultsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<SNPResult[]>(`${environment.baseUrl}SnpResults/`);
  }
  getById(id) {
    return this.http.get<SNPResult[]>(`${environment.baseUrl}SnpResults/${id}`);
  }
  put(snpResult: SNPResult) {
    return this.http.put<SNPResult[]>(
      `${environment.baseUrl}SnpResults/${snpResult.snpResultId}`,
      snpResult
    );
  }

  putLangMin(snpResultLangMin: SnpResultLangMin, snpNum: number) {
    return this.http.put<any>(
      `${environment.baseUrl}SnpResultLangs/Min/${snpNum}`,
      snpResultLangMin
    );
  }
}
