<div *ngIf="this.labVersions?.length == 0">
  <br />
  There is no locked versions to upload results
</div>
<mat-form-field class="round-select lab-versions">
  <mat-select [(ngModel)]="versionId" class="lab-versions__select">
    <mat-option [value]="item.labVersionId" *ngFor="let item of labVersions">
      {{ item.labName | uppercase }} - {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div style="display: flex; justify-content: center">
  <div
    [ngStyle]="{
      color: numberValue === 1 ? 'green' : numberValue === 2 ? 'red' : 'black',
      'font-size': '18px'
    }"
  >
    <div class="message">{{ statusMessage }}</div>
    <div class="message">{{ errorMessage }}</div>
  </div>
</div>
<br />
<div>
  <button mat-raised-button (click)="inputFile.click()">Choose file</button>

  <span *ngIf="inputFile.files.length > 0">
    &nbsp;{{ inputFile.files[0].name }}
  </span>
</div>
<input
  #inputFile
  type="file"
  placeholder="Choose file"
  (change)="uploadLabResult(inputFile.files)"
  hidden
/>
<br />
<ngx-skeleton-loader
  *ngIf="inUploadProcess"
  [theme]="{ height: '30px' }"
  count="15"
></ngx-skeleton-loader>
<mat-card
  style="height: calc(100vh - 200px); padding: 0; overflow: hidden"
  *ngIf="errors && !inUploadProcess"
>
  <mat-card-title
    style="
      color: red;
      border-bottom: 2px solid red;
      padding-bottom: 1rem;
      padding: 16px;
    "
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>warning</mat-icon>&nbsp;Errors report
  </mat-card-title>
  <perfect-scrollbar style="height: 91%">
    <mat-card-content style="padding: 16px">
      <mat-accordion #accordion="matAccordion" [multi]="true">
        <mat-expansion-panel
          [disabled]="true"
          [hideToggle]="true"
          style="border-bottom: 1px solid red"
        >
          <mat-expansion-panel-header style="color: black; font-weight: 500">
            <mat-panel-title> Error type </mat-panel-title>
            <mat-panel-description> Error Description </mat-panel-description>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel
          *ngFor="let item of errors; let i = index"
          [style.margin-top]="i == 0 ? '0' : ''"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong style="font-weight: 500"
                >{{ item.statusName }}
              </strong></mat-panel-title
            >
            <mat-panel-description>
              {{ item.statusDescription }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="errors-grid">
            <div *ngFor="let item of item.errors" class="errors-grid-item">
              <div *ngIf="item.barcode">Barcode: {{ item.barcode }}</div>
              <div *ngIf="item.algoSubCatId">
                AlgoSubCatId: {{ item.algoSubCatId }}
              </div>
              <div *ngIf="item.algoSnpId">AlgoSnpId: {{ item.algoSnpId }}</div>
              <div *ngIf="item.chromosome">
                Chromosome: {{ item.chromosome }}
              </div>
              <div *ngIf="item.coordinate">
                Coordinate: {{ item.coordinate }}
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </perfect-scrollbar>
</mat-card>
