import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AgentInteraction } from 'src/app/data/AgentInteraction';
import { RegularUser } from 'src/app/data/RegularUser';
import { AgentStatusEnum } from 'src/app/Enums/StatusesEnum';
import { Globals } from 'src/app/globals';
import { AgentInteractionService } from 'src/app/Services/agent-interaction.service';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('navContainer') navContainer: ElementRef;
  @ViewChild('navList') navList: ElementRef;
  @Output('changeInteraction') changeInteraction: EventEmitter<number> =
    new EventEmitter();
  searchTerm;
  lastMainUrl;
  showDebugMenu: boolean = false;
  @Input() regularUser: RegularUser;
  @Input() isDashboard: RegularUser;
  @Input() chatAlerts: number;
  @Input() chatAvailable: boolean;

  @Input() agentInteraction: AgentInteraction;
  AgentStatusEnum = AgentStatusEnum;
  offset: number;
  offsetLeft: number = 50;
  offsetRight: number = 50;

  constructor(
    private router: Router,
    private regularUsersService: RegularUsersService,
    private loc: Location,
    public firebaseService: FirebaseChatIndicatorService
  ) {}

  ngOnInit(): void {
    this.showDebugMenu = Globals.showDebugMenu;
    setTimeout(() => {
      //this.offsetLeft = this.navContainer.nativeElement.clientWidth * 0.1;
      //this.offsetRight = this.navContainer.nativeElement.clientWidth * 0.1;
      //this.offset = this.navContainer.nativeElement.clientWidth * 0.1;
    }, 100);
  }

  isOverflownX({ clientWidth, scrollWidth }) {
    return scrollWidth > clientWidth;
  }
  canScrollLeft() {}

  scrollRight() {
    this.navList.nativeElement.scrollRight += this.offsetRight;
  }
  scrollLeft() {
    this.navList.nativeElement.style.transform = `translateX(${this.offsetLeft}px)`;
    this.offsetLeft += 50;
  }

  changeAgentInteraction(statusId: number) {
    this.changeInteraction.emit(statusId);
    this.agentInteraction.statusId = statusId;
  }

  showAgentInteraction() {
    switch (this.agentInteraction?.statusId) {
      case AgentStatusEnum.Online:
        return { name: 'Online', color: 'green' };
      case AgentStatusEnum.Offline:
        return { name: 'Offline', color: 'red' };
      case AgentStatusEnum.Break:
        return { name: 'Break', color: 'orange' };
    }
  }

  getClassName() {
    if (
      this.router.url === '/editGenesData' ||
      this.router.url === '/dashboard' ||
      this.router.url === '/support-dashboard' ||
      this.router.url === '/sales-call-statistics' ||
      this.router.url === '/scripts'
    ) {
      return '';
    } else if (this.router.url === '/tests') {
      return 'container-wide';
    } else return 'container';
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.regularUser);
  }
  userHasRole(role: string) {
    if (!this.regularUser || !this.regularUser.userRole) return false;
    var userRole = this.regularUser.userRole.userRoleName;
    if (userRole === role) return true;
    return false;
  }
  getClassNavName(value) {
    if (this.loc.path().split('/').includes('labDeliveries')) {
      var url = this.loc.path().split('/')[2];
    }
    if (this.loc.path().split('/').includes('debug')) {
      var url = this.loc.path().split('/')[2];
    } else {
      var url = this.loc.path().split('/')[1];
    }
    this.lastMainUrl = url;

    if (value.includes(this.lastMainUrl)) {
      return this.lastMainUrl;
    } else if (value === 'divider') {
      return this.lastMainUrl;
    }
  }
  title = 'myGenesClient';
  onSearchFieldInput() {}
  onSearchBtnClick() {
    this.router.navigate(['/search/'], {
      queryParams: { searchTerm: this.searchTerm },
    });
  }

  onLogout() {
    const setAgentOffline = userHasPermisions(
      ['AgentInteraction_GetList'],
      this.regularUser
    );
    this.regularUsersService.logout(setAgentOffline);
    this.regularUser = null;
  }
  onKeyUpClick(event) {
    alert('onKeyUpClick' + event);
  }
  onKeyUpClick1(event) {
    alert('onKeyUpClick1' + event);
  }
}
