import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailTemplate } from 'src/app/data/EmailTemplate';
import { EmailTemplateLang } from 'src/app/data/EmailTemplateLang';
import { EmailTemplateMin } from 'src/app/data/EmailTemplateMin';
import { EmailTemplatesService } from 'src/app/Services/email-templates.service';
import { TriggerEventObjectTypesService } from 'src/app/Services/trigger-event-object-types.service';

@Component({
  selector: 'app-all-auto-responder',
  templateUrl: './all-auto-responder.component.html',
  styleUrls: ['./all-auto-responder.component.css'],
})
export class AllAutoResponderComponent implements OnInit {
  emailTempaltes: EmailTemplateMin[];
  templateLangs: EmailTemplateLang;
  displayedColumns: string[] = ['icon', 'templateName', 'event', 'lastChange'];
  constructor(
    private emailTemplateService: EmailTemplatesService,
    
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getEmailTempaltes();
  }
  getEmailTempaltes() {
    this.emailTemplateService.getAllAutoSender().subscribe((data) => {
      this.emailTempaltes = data; //.filter((x) => x.displayName);
     // console.table(this.emailTempaltes);
    });
  }

  createNew() {
    var emailTempalte = new EmailTemplate();
    emailTempalte.isAutoSender = true;
    this.emailTemplateService.post(emailTempalte).subscribe((data) => {
      this.goTo(data.emailTemplateId);
    });
  }

  goTo(templateId: number) {
    this.router.navigate(['/autoresponder/edit'], {
      queryParams: { templateId },
    });
  }
}
