import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Note } from '../data/Note';
import { NoteForCallMin } from '../data/NoteForCallMin';
import { NotesService } from './notes.service';

@Injectable({
  providedIn: 'root',
})
export class NotesPusherService {
  mode: string;
  private patientId: number;
  private clientId: number;
  private serviceRequestId: number;
  private cancelationRequestId: number;

  constructor(private noteService: NotesService) {}
  private readonly notes = new BehaviorSubject<NoteForCallMin[]>([]);
  readonly notes$ = this.notes.asObservable();

  setMode(mode) {
    this.mode = mode;
  }
  getAllNotes(id: number = null, mode = null) {
    this.notes.next([]);
    if (mode) this.mode = mode;

    var req;
    switch (this.mode) {
      case 'client':
        if (id) this.clientId = id;
        req = this.noteService.getAllByClientId(this.clientId);
        break;
      case 'patient':
        if (id) this.patientId = id;
        req = this.noteService.getByPatientId(this.patientId);
        break;
      case 'serviceRequest':
        if (id) this.serviceRequestId = id;
        req = this.noteService.getAllByServiceRequestId(this.serviceRequestId);
        break;
      case 'cancelationRequest':
        if (id) this.cancelationRequestId = id;
        req = this.noteService.getAllByCancelationRequestId(
          this.cancelationRequestId
        );
        break;
    }

    req.subscribe((data) => {
      (data as NoteForCallMin[]).map((item) => {
        item.dueDateObj = new Date(Date.parse(item.dueDate));
        item.dateCreatedObj = new Date(Date.parse(item.dateCreated));
      });
      this.notes.next(data);
    });
  }
  put(note: Note) {
    this.noteService.put(note).subscribe((data) => {
      this.getAllNotes();
    });
  }
  putMin(note: NoteForCallMin) {
    this.noteService.putMin(note).subscribe((data) => {
      this.getAllNotes();
    });
  }
  post(note: Note) {
    this.noteService.post(note).subscribe((data) => {
      this.getAllNotes();
    });
  }
  openNewTaskForFollowUpNotAnswerCall(patientId: number) {
    this.noteService
      .openNewTaskForFollowUpNotAnswerCall(patientId)
      .subscribe((res) => {
        this.getAllNotes();
      });
  }
  reset() {
    this.clientId = -1;
    this.notes.next([]);
  }
  changeStatusMinCall(note: NoteForCallMin) {
    this.noteService.changeStatusMinCall(note).subscribe((data) => {
      this.getAllNotes();
    });
  }
}
