<!--https://napster2210.github.io/ngx-spinner/ -->
<!-- <ngx-spinner style="width: 500px;height: 300px;" bdColor="rgba(0,0,0,0.8)" size="medium" color="color"
    type="ball-clip-rotate" [fullScreen]="isFullScreen">
    <p style="color: white"> Loading...
    </p>
</ngx-spinner> -->

<ngx-spinner *ngIf="mode=='inline'" style="width: 500px;height: 300px;" bdColor="rgba(0,0,0,0)" size="medium"
    color="#000" type="ball-clip-rotate" [fullScreen]="false">
    <p style="color: black"> Loading...
    </p>
</ngx-spinner>
<ngx-spinner *ngIf="mode=='fullScreen'" style="width: 500px;height: 300px;" bdColor="rgba(0,0,0,0.8)" size="medium"
    color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading...
    </p>
</ngx-spinner>
<!--
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
-->