import { Component, OnInit } from '@angular/core';
import { FoodLangsService } from 'src/app/Services/food-langs.service';
import { FoodsService } from 'src/app/Services/foods.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-food-for-category',
  templateUrl: './food-for-category.component.html',
  styleUrls: ['./food-for-category.component.css'],
})
export class FoodForCategoryComponent implements OnInit {
  foods;
  displayedColumns: string[] = ['name', 'category'];
  constructor(
    private foodLangsService: FoodLangsService,
    private foodsService: FoodsService
  ) {}

  ngOnInit(): void {
    this.foodsService
      .ForCategoryLinkage(1, environment.foodSourceKey)
      .subscribe((data) => {
        this.foods = data;
      });
  }
}
