import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';
import { StatementType, TemplateType } from 'src/app/Enums/ChatEnum';
import { AnswerMessageComponent } from '../bubble-templates/answer-message/answer-message.component';
import { ConversationMessageComponent } from '../bubble-templates/conversation-message/conversation-message.component';
import { SystemMessageComponent } from '../bubble-templates/system-message/system-message.component';

@Component({
  selector: 'app-main-chat-bubble',
  templateUrl: './main-chat-bubble.component.html',
  styleUrls: ['./main-chat-bubble.component.scss'],
})
export class MainChatBubbleComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild('dynamicBubble', { read: ViewContainerRef })
  dynamicBubble: ViewContainerRef;
  componentRef: ComponentRef<any>;
  @Input() message: ChatMessage;
  @Input() langId: number;

  componentMap = {
    [StatementType.SystemMessage]: SystemMessageComponent,
    [StatementType.ConversationMessage]: ConversationMessageComponent,
    [StatementType.AnswerMessage]: AnswerMessageComponent,
  };
  statementType: number;

  constructor() {}
  ngAfterViewChecked(): void {}
  ngAfterContentChecked(): void {}
  ngAfterViewInit(): void {
    this.statementType =
      this.message?.template?.statementType ||
      StatementType.ConversationMessage;
    setTimeout(() => {
      if (this.statementType in this.componentMap) {
        this.loadComponent();
      }
    }, 100);
  }

  ngOnInit(): void {}

  loadComponent(): void {
    this.dynamicBubble.clear();
    this.componentRef = this.dynamicBubble.createComponent(
      this.componentMap[this.statementType]
    );
    this.componentRef.instance.message = this.message;
    this.componentRef.instance.langId = this.langId;
  }
}
