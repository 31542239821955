import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Note } from 'src/app/data/Note';
import { NotesService } from 'src/app/Services/notes.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { PatientNotesPusherService } from 'src/app/Services/patient-notes-pusher.service';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';

@Component({
  selector: 'app-lead-activity-thumb',
  templateUrl: './lead-activity-thumb.component.html',
  styleUrls: ['./lead-activity-thumb.component.css'],
})
export class LeadActivityThumbComponent implements OnInit {
  constructor(
    private notesPusherService: NotesPusherService,
    //private patientNotesPuserService: PatientNotesPusherService
  ) {}
  @Output('editNote')
  editNote: EventEmitter<NoteForCallMin> = new EventEmitter();
  @Input() note: NoteForCallMin;
  @Input() classAttr;
  @Input() testPusher;
  ngOnInit(): void {
    this.note.dueDate;
    var a = this.note;
  }
  changeToDone() {
    this.note.statusId = 12; //completed
    /*  
    if (this.testPusher) {
      this.patientNotesPuserService.changeStatusMinCall(this.note);
    } else {
      this.notesPusherService.changeStatusMinCall(this.note);
    }
    */
    this.notesPusherService.changeStatusMinCall(this.note);
  }
  isDatePassed(dateStr) {
    var date = new Date(dateStr);
    var today = new Date();
    var isTrue = date.getTime() < today.getTime();
    return isTrue;
  }
  onEditNote() {
    this.editNote.emit(this.note);
  }
}
