import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { Laboratory } from 'src/app/data/algorithm/Laboratory';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { Language } from 'src/app/data/Language';
import { FoodGenesBoardEnum } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-food-genes-sidenav',
  templateUrl: './food-genes-sidenav.component.html',
  styleUrls: ['./food-genes-sidenav.component.scss'],
})
export class FoodGenesSidenavComponent implements OnInit {
  @ViewChildren('panelsCategory') panelsCategory: QueryList<MatExpansionPanel>;
  @Input() coefficiernts: FgCoefficientType[];
  @Input() parameters;
  @Input() isLoading: boolean;
  @Input() canEdit: boolean;
  @Input() canSearch: boolean;
  @Input() sidenavSnp: MatSidenav;
  @Input() languages: Language[];
  @Input() langId: number;
  @Input() versionId: number;
  @Input() fgVersions: FgVersion[];
  @Input() dir: string;
  @Input() indexExpanded: number;
  searchTerm: string;

  @Output('selected')
  selected: EventEmitter<{
    subCoeff?: FgCoefficient;
    coeff?: FgCoefficientType;
    param?: FgParamType;
    isFormulaSelected?: boolean;
  }> = new EventEmitter();
  @Output('selectSummary') selectSummary: EventEmitter<boolean> =
    new EventEmitter();
  @Output('selectParam') selectParam: EventEmitter<FgParamType> =
    new EventEmitter();
  @Output('closeMainBoard') closeMainBoard: EventEmitter<boolean> =
    new EventEmitter();
  @Output('searchSnp') searchSnp: EventEmitter<string> = new EventEmitter();
  @Output('changeLanguage') changeLanguage: EventEmitter<number> =
    new EventEmitter();
  @Output('changeVersion') changeVersion: EventEmitter<string> =
    new EventEmitter();
  @Output() loadCategories = new EventEmitter<boolean>();
  @Output() openMainBoardEvent: EventEmitter<{
    mode: FoodGenesBoardEnum;
    coeff?: FgCoefficientType;
    subCoeff?: FgCoefficient;
  }> = new EventEmitter<null>();
  selectedCoeff: FgCoefficient;
  @Input() selectedCoeffType: FgCoefficientType;
  selectedParamType: FgParamType;
  selectedParameterTypesPanel: boolean;
  constructor(
    private foodGenesService: FoodGenesService,
    private popupHandlaerService: PopupHandlerService
  ) {}

  ngOnInit(): void {}
  onSelectCoeff(coeff: FgCoefficientType, inx: number) {
    this.selectedParameterTypesPanel = false;
    if (
      this.selectedCoeffType?.fgCoefficientTypeId === coeff.fgCoefficientTypeId
    ) {
      this.selectedCoeffType = null;
      this.selectedCoeff = null;
      this.sidenavSnp.close();
    } else {
      this.selectedCoeffType = coeff;
    }
    setTimeout(() => {
      this.indexExpanded = inx;
    }, 300);
  }
  onSelectParaneterTypePanel() {
    this.selectedParameterTypesPanel = !this.selectedParameterTypesPanel;
    this.selectedCoeffType = null;
  }
  onSelectParamType(item: FgParamType) {
    this.selectedCoeff = null;
    if (this.selectedParamType?.fgParamTypeId === item.fgParamTypeId) {
      this.selectedParamType = null;
      this.selected.emit(null);
      this.foodGenesService.mainBoardState$.next(true);
      //this.closeMainBoard.emit(true);
      if (this.sidenavSnp.opened) {
        this.sidenavSnp.close();
      }
    } else {
      this.selectedParamType = item;
      this.selected.emit({
        param: this.selectedParamType,
      });
      this.sidenavSnp.open();
    }
  }
  onSelectSubCoeff(subCoeff: FgCoefficient) {
    //this.selectedSubCategory = subCategory;

    if (this.selectedCoeff?.fgCoefficientId === subCoeff.fgCoefficientId) {
      //if select the same subcategory then close the sidenav
      this.selectedCoeff = null;
      this.selected.emit(null);
      //this.closeMainBoard.emit(true);
      this.foodGenesService.mainBoardState$.next(true);
      if (this.sidenavSnp.opened) {
        this.sidenavSnp.close();
      }
    } else {
      //if subcategory selected for the first time programtically load the summary
      this.selectedCoeff = subCoeff;
      this.selectedParamType = null;
      this.selected.emit({
        subCoeff: this.selectedCoeff,
        coeff: this.selectedCoeffType,
        isFormulaSelected: true,
      });
      this.sidenavSnp.open();
    }
  }
  onSearchBtnClick() {
    if (this.searchTerm.length > 0) {
      this.searchSnp.emit(this.searchTerm);
    }
  }
  onHistoryClick() {
    this.openMainBoardEvent.emit({
      mode: FoodGenesBoardEnum.history,
    });
  }
  onSimulatorClick() {
    this.popupHandlaerService.openFoodGenesSimulator(
      this.versionId,
      this.langId
    );
  }
  onChangeLang(langId: number) {
    this.changeLanguage.emit(langId);
    // reopen the sidenav with current index
    setTimeout(() => {
      if (this.indexExpanded > -1) {
        this.panelsCategory.toArray()[this.indexExpanded].open();
      }
    }, 1000);
  }
  onChangeVersion(event: MatSelectChange) {
    this.changeVersion.emit(event.value);
    const versions = this.fgVersions.filter(
      (x) => x.fgVersionId !== event.value
    );
    this.foodGenesService.versions$.next(versions);
  }
  onSave(event) {
    if (event) {
      console.log('save SideNavCatComponent');
      this.loadCategories.emit(true);
    }
  }
}
