<div class="flex-container" *ngIf="!environment.firebaseLogin">
  <form
    [formGroup]="loginForm"
    (ngSubmit)="submitLoginForm()"
    class="example-form"
  >
    <mat-card class="flex-item">
      <mat-card-title
        style="
          background-color: #153341;
          color: white;
          line-height: 60px;
          margin: 0;
          margin-top: -50px;
        "
      >
        Welcome Back
      </mat-card-title>
      <div>
        <mat-list style="margin-top: 40px">
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 250px"
            >
              <input
                style="border: 1px solid black"
                placeholder="User Name"
                class="edit-field login-field"
                matInput
                formControlName="userName"
              />
              <mat-error *ngIf="getFalse()"> Please enter Height </mat-error>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 250px"
            >
              <input
                type="password"
                matInput
                style="border: 1px solid black"
                placeholder="Password"
                class="edit-field login-field"
                matInput
                formControlName="password"
              />
              <mat-error *ngIf="getFalse()"> Please enter password </mat-error>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="authError">
            <p class="authError">{{ authError }}</p>
          </mat-list-item>

          <button class="login-btn">LOGIN</button>
          <br />
          <!--
          <button class="login-btn" routerLink="/resetPassword" type="button">
            Reset Password
          </button>
          -->
        </mat-list>
      </div>
    </mat-card>
  </form>
</div>
<div *ngIf="environment.firebaseLogin">
  <button (click)="loginWithGoogle()">login with google</button>
</div>
