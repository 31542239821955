<h2 mat-dialog-title>Food in use</h2>
<mat-dialog-content class="mat-typography">
  <table mat-table [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="foodName">
      <th mat-header-cell *matHeaderCellDef>Food</th>
      <td mat-cell *matCellDef="let element">{{ element.foodName }}</td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{ element.categoryName }}</td>
    </ng-container>

    <ng-container matColumnDef="sourceName">
      <th mat-header-cell *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let element">{{ element.sourceName }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
