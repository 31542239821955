import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RegularUsersService } from '../Services/regular-users.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  loginForm = new FormGroup({
    userName: new FormControl(''),
    password: new FormControl(''),
  });
  constructor(
    private regularUsersService: RegularUsersService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.loginForm.value);
    //this.router.navigate(['editGenesData']);
    this.regularUsersService
      .validate(this.loginForm.value)
      .subscribe((data) => {
        Globals.isValidated = true;
        this.router.navigate(['editGenesData']);
      }),
      (error) => {
        //console.log('oops', error)
      };
  }
}
