import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { take } from 'rxjs/operators';
import { ClientEditDialogWraperComponent } from 'src/app/Cards/client-edit-dialog-wraper/client-edit-dialog-wraper.component';
import { AgentMin } from 'src/app/data/AgentMin';
import { Client } from 'src/app/data/Client';
import { CreditType } from 'src/app/data/CreditType';
import { Deal } from 'src/app/data/Deal';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { Product } from 'src/app/data/Product';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { ProductIsFor } from 'src/app/data/ProductIsFor';
import { ProductType } from 'src/app/data/ProductType';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { Status } from 'src/app/data/Status';
import { UserAdress } from 'src/app/data/UserAdress';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { PaymentMethodEnum } from 'src/app/Enums/StatusesEnum';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { CallsReviewsEditService } from 'src/app/Service/calls-reviews-edit.service';
import { AgentsService } from 'src/app/Services/agents.service';
import { ClientTypeService } from 'src/app/Services/client-type.service';
import { ClientsService } from 'src/app/Services/clients.service';
import { CreditCurrenciesService } from 'src/app/Services/credit-currencies.service';
import { CreditTypesService } from 'src/app/Services/credit-types.service';
import { DealsService } from 'src/app/Services/deals.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { NotesService } from 'src/app/Services/notes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductIsForService } from 'src/app/Services/product-is-for.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { ProductsForClientPusherServiceService } from 'src/app/Services/products-for-client-pusher-service.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestsPusherService } from 'src/app/Services/service-requests-pusher.service';
import { ServiceRequestsService } from 'src/app/Services/service-requests.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss'],
})
export class EditClientComponent implements OnInit, AfterViewInit {
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @ViewChild('clientCreateCall') clientCreateCallEl: ElementRef;
  disableNextBtn: boolean;
  isCallsEditMode: boolean = false;
  clientNotes: NoteForCallMin[];
  requests: RequestForTableMin[];
  isNoteEditMode: boolean = false;
  client: Client;
  clientDealBundles: DealBundleForTable[];
  clientInteractions;
  purchaseMode = 1;
  isEditMode = false;
  assignedAgentId;
  clientTypes;
  leadFormReady;
  leadSources;
  leadStatuses;
  agents: AgentMin[];
  productIsFors: ProductIsFor[];

  dealsDisplayedColumns: string[] = [
    'date',
    'product',
    'sum',
    'status',
    'pMethod',
  ];
  clientForm: FormGroup = null;
  clientProducts: ProductForClientMin[];
  noteToEdit: Note;
  activityToEdit: Note;
  requestToEdit: ServiceRequest;
  deal: Deal;
  clientId: number;
  creditCurrencies;
  creditTypes: CreditType[];
  errorMessage: ErrorMessage;
  agentUser: RegularUser;
  requestsStatuses: Status[];
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  callToEdit: Note;
  clientCalls: any;
  callsStatuses: Status[];
  noteId: any;
  headFilter: any;
  clientCallsMetaData: any;
  globalParams: GlobalParam[];
  isTaskEditMode: boolean = false;
  isRequestEditMode: boolean = false;
  clientTasks;
  statuses: Status[];
  productTypes: ProductType[];

  constructor(
    private router: Router,
    private dealsService: DealsService,
    private clientsService: ClientsService,
    private clientTypeService: ClientTypeService,
    private leadSourcesService: LeadSourcesService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private agentsService: AgentsService,
    private productsService: ProductsService,
    private notesPusherService: NotesPusherService,
    private statusesService: StatusesService,
    public dialog: MatDialog,
    private productIsForService: ProductIsForService,
    private creditCurrenciesService: CreditCurrenciesService,
    private creditTypesService: CreditTypesService,
    private productsPusherService: ProductsForClientPusherServiceService,
    private popupHandler: PopupHandlerService,
    private regularUsersService: RegularUsersService,
    private serviceRequestsPusherService: ServiceRequestsPusherService,
    private serviceRequestsService: ServiceRequestsService,
    private notesService: NotesService,
    private filterMemoryService: FilterMemoryService,
    private callsReviewsEditService: CallsReviewsEditService,
    private globalParamsService: GlobalParamsService,
    private productTypeService: ProductTypesService
  ) {
    this.callsReviewsEditService.onEdit$.subscribe((data) => {
      if (data) {
        this.noteId = data;
        if (this.noteId && this.clientCalls.length > 0) {
          this.loadCallToEdit();

          //
        }
        setTimeout(() => {
          //this.clientCreateCallEl.scrollIntoView();
          this.tabGroup.selectedIndex = 4;
          this.isCallsEditMode = true;
        }, 100);
      }
    });
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.callsReviews
    );
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
    this.productsPusherService.clientProducts$.subscribe((products) => {
      this.clientProducts = products;
      if (this.clientProducts.length > 0) {
        var product = this.clientProducts[0];
      }
    });
    this.productsPusherService.errorMessage$.subscribe((error) => {
      this.errorMessage = error;
      if (this.errorMessage) {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          this.errorMessage.message,
          4000
        );
      }
    });
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
    });
  }

  ngOnInit(): void {
    this.globalParamsService
      .getAll()
      .subscribe((res) => (this.globalParams = res));
    this.creditCurrenciesService.getAll().subscribe((data) => {
      this.creditCurrencies = data;
    });
    this.creditTypesService.getAll().subscribe((data) => {
      this.creditTypes = data.filter((x) => x.creditTypeId != 6);
    });
    this.productIsForService
      .getAll()
      .subscribe((data) => (this.productIsFors = data));
    this.clientTypeService
      .getAll()
      .subscribe((data) => (this.clientTypes = data));

    this.leadSourcesService
      .getAll()
      .subscribe((data) => (this.leadSources = data));

    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });
    this.route.queryParams.subscribe((params) => {
      this.clientId = params['clientId'];
      if (params['noteId']) {
        this.noteId = params['noteId'];
      }

      this.clientsService.getById(this.clientId).subscribe((data) => {
        if ((data as Client).clientId) {
          this.client = data as Client;
          this.recreateClient();

          this.client.regularUser.email.trim() ==
            this.client.regularUser.email.toLowerCase().trim();
          this.getClientProducts();
          this.getClientDealBundles();
          this.notesPusherService.getAllNotes(this.clientId, 'client');
          this.getAllCalls();
          this.serviceRequestsPusherService.getAll(null, this.clientId);
        } else {
          this.router.navigate(['home']);
        }

        //  this.client = this.clientsService.addProductsSummery(this.client);
        /* var CustumerType = this.client.clientType.name;
        var fullName = this.client.regularUser.firstName;
        var email = this.client.regularUser.email;
        var tel = this.client.regularUser.phone;
        var leadSource = this.client.leadSource.name;
        var productFor = this.client.theProductIsFor; */
      });

      this.notesPusherService.notes$.subscribe((data) => {
        this.clientNotes = data.filter((item) => {
          return (
            item.noteTypeId == 1 || item.noteTypeId == 4 || item.noteTypeId == 6
          );
        });
        this.clientNotes.sort((a, b) => {
          /*
          if (!a.dateCreatedObj && !b.dateCreatedObj) return 0;
          if (!a.dateCreatedObj) return 1;
          if (!b.dateCreatedObj) return -1;
          */
          return a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime()
            ? 1
            : -1;
        });
        this.clientTasks = data.filter((item) => {
          return item.noteTypeId == 2;
        });
        // this.clientCalls = data.filter((item) => item.noteTypeId == 4);
        /* 
        if (this.noteId && this.clientCalls.length > 0) {
          this.callToEdit = this.clientCalls.find(
            (note) => note.noteId == this.noteId
          );
          
      
        }
        */
        this.clientTasks = this.clientTasks.sort((a, b) =>
          a.dueDateObj.getTime() < b.dueDateObj.getTime() ? 1 : -1
        );
        if (this.clientNotes.length == 0) this.clientNotes = null;
        if (this.clientTasks.length == 0) this.clientTasks = null;
      });

      this.serviceRequestsPusherService.setMode('client');
      this.serviceRequestsPusherService.requests$.subscribe((requests) => {
        this.requests = requests;
      });
    });

    this.statusesService.getAll().subscribe((data) => {
      this.callsStatuses = [];
      if (
        userHasPermisions(['CallSubjectSupportList_GetList'], this.agentUser)
      ) {
        var theList = data.filter((status: Status) => {
          return status.category == 'CallSubjectSupport';
        });
        this.callsStatuses = this.callsStatuses.concat(theList);
      }
      if (userHasPermisions(['CallSubjectNutriList_GetList'], this.agentUser)) {
        var theList = data.filter((status: Status) => {
          return status.category == 'CallSubjectNutri';
        });
        this.callsStatuses = this.callsStatuses.concat(theList);
      }

      this.leadStatuses = data.filter((status: Status) => {
        return status.category == 'Lead';
      });
      this.requestsStatuses = data.filter((status: Status) => {
        return status.category == 'RequestStatus';
      });
      /*
      this.callsStatuses = data.filter((status: Status) => {
        if (
          this.agentUser.roleTypeId == RoleTypeEmum.Support ||
          this.agentUser.roleTypeId == RoleTypeEmum.SupportManager
        ) {
          return status.category == 'CallSubjectSupport';
        } else if (
          this.agentUser.roleTypeId == RoleTypeEmum.nutritionist ||
          this.agentUser.roleTypeId == RoleTypeEmum.nutritionistManager
        ) {
          return status.category == 'CallSubjectNutri';
        } else if (
          this.agentUser.roleTypeId == RoleTypeEmum.Manager ||
          this.agentUser.roleTypeId == RoleTypeEmum.Developer
        ) {
          return (
            status.category == 'CallSubjectNutri' ||
            status.category == 'CallSubjectSupport'
          );
        }
      });
      */
    });
    this.productTypeService
      .getAll()
      .subscribe((data) => (this.productTypes = data));
  }
  recreateClient() {
    this.client = plainToClass(Client, this.client);
    //  this.client = data as Client;

    this.client.regularUser = plainToClass(
      RegularUser,
      this.client.regularUser
    );
    this.client.regularUser.userAdresses =
      this.client.regularUser.userAdresses.map((userAdress) =>
        plainToClass(UserAdress, userAdress)
      );
  }
  getClient() {
    this.clientsService.getById(this.clientId).subscribe((data) => {
      if ((data as Client).clientId) {
        this.client = data as Client;
        this.recreateClient();
      }
    });
  }
  hasPermission() {
    return userHasPermisions(['Clients_SendInfo'], this.agentUser);
  }
  openSendInfoDialog() {
    this.popupHandler.openEmailTemplateDialog(this.client, null);
  }
  openContinueDeal(deal: Deal) {
    this.deal = deal;
    this.purchaseMode = 4;
  }
  loadCallToEdit() {
    this.notesService.getById(this.noteId).subscribe((res) => {
      this.callToEdit = res;
    });
  }

  getAllCalls() {
    this.notesService
      .getAllCalls(this.headFilter, this.clientId)
      .subscribe((data) => {
        this.clientCalls = data.list;
        this.clientCallsMetaData = data.metaData;
        if (this.noteId && this.clientCalls.length > 0) {
          this.loadCallToEdit();
          //console.log('edit callToEdit', this.callToEdit);
        }
      });
  }
  ngAfterViewInit() {
    //console.log(this.clientCreateCallEl);

    if (this.noteId) {
      setTimeout(() => {
        this.tabGroup.selectedIndex = 4;
        this.isCallsEditMode = true;
        //this.clientCreateCallEl.scrollIntoView();
      }, 100);
    }
    setTimeout(() => {
      //this.clientCreateCallEl.nativeElement.scrollIntoView();
    }, 2000);
  }
  ifHasCallPermission() {
    return userHasPermisions(['Clients_ViewCalls'], this.agentUser);
    /*
    if (
      this.agentUser.roleTypeId == RoleTypeEmum.Support ||
      this.agentUser.roleTypeId == RoleTypeEmum.SupportManager
    )
      return true;
    else if (
      this.agentUser.roleTypeId == RoleTypeEmum.nutritionist ||
      this.agentUser.roleTypeId == RoleTypeEmum.nutritionistManager
    )
      return true;
    else if (
      this.agentUser.roleTypeId == RoleTypeEmum.Manager ||
      this.agentUser.roleTypeId == RoleTypeEmum.Developer
    )
      return true;
    else return false;
    */
  }
  getClientProducts() {
    this.productsPusherService.getByClientId(this.clientId);
  }
  goBackToClients() {
    this.router.navigate(['/clients']);
  }
  saveClient() {}
  moveToEditMode() {
    var a = this.client;
    this.isEditMode = true;
  }
  completePurchase(purchase: Deal = null) {
    this.dealsService.post(this.deal).subscribe(
      (deal) => {
        var textType = 'purchaseSuccess';
        if (this.deal.paymentMethodId == PaymentMethodEnum.CreditCard) {
          var bankOk =
            deal.dealHistories[0].tranzillaTransactionResponse.bankResponseCode
              .isSuccess;
          textType = bankOk ? 'purchaseSuccess' : 'purchaseRejected';
        } else {
        }
        this.openPurchaseCompleteDialog(null, textType, '', '');
        this.purchaseMode = 1;
        //this.clientDeals.push(deal);
        this.getClientDealBundles();
        this.getClientProducts();
      },
      (error) => {
        this.openPurchaseCompleteDialog(null, 'purchaseRejected', '', '');
        this.purchaseMode = 1;
      }
    );
  }
  cancelPurchase() {
    this.purchaseMode = 1;
  }
  openNewPurchase(event) {
    this.purchaseMode = 2;
  }
  get leadSourceId() {
    return this.clientForm.get('leadSourceId');
  }
  get clientTypeId() {
    return this.clientForm.get('clientTypeId');
  }
  get fullName() {
    return this.clientForm.get('fullName');
  }
  get email() {
    return this.clientForm.get('email');
  }
  get phone() {
    return this.clientForm.get('phone');
  }
  get productFor() {
    return this.clientForm.get('productFor');
  }
  get ProductType() {
    return this.clientForm.get('ProductType');
  }
  onChildFormValidated(client: Client) {
    //client.clientRole = null;

    this.clientsService.put(client).subscribe((res) => {
      if ((res as Client).clientId) {
        this.isEditMode = false;
        this.getClient();
        //this.client = resClient;
        //this.recreateClient();
        var resClient = res as Client;
        if (resClient.isAgentChanged) {
          this.popupHandler.confirmDialogSubject
            .pipe(take(1))
            .subscribe((res) => {
              if (res.ans == 'yes') {
                this.clientsService
                  .transferAgentDeals(client)
                  .subscribe((res) => {});
              }
            });
          this.popupHandler.openConfirmationDialog('', 'Transfer Agent Deals');
        }
      } else {
        var resError = res as ErrorMessage;
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          resError.message,
          4000
        );
      }
      /*
      this.router.navigate(['/editLead'], {
        queryParams: { clientId: client.clientId },
      });*/
    });
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
  }

  saveNote(note: Note | NoteForCallMin) {
    /* note.clientId = this.client.clientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      this.notesPusherService.post(note);
    } */
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.clientId = this.client.clientId;
      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.clientId = this.client.clientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesPusherService.post(fullNote);
    }

    this.isNoteEditMode = false;
  }
  createNewCall(note: Note) {
    this.callToEdit = note;
    this.isCallsEditMode = true;
  }
  createNewNote(note) {
    if (note.noteId) {
    }
    this.noteToEdit = note;
    if (!this.noteToEdit) this.noteToEdit = new Note();
    this.isNoteEditMode = true;
  }
  openPatientEditDialog(productMin: ProductForClientMin) {
    this.productsService.getById(productMin.productId).subscribe((product) => {
      let dialogRef = this.dialog.open(ClientEditDialogWraperComponent, {
        width: '600px',
        height: '830px',
        data: {
          client: null,
          product: product,
          clientTypes: this.clientTypes,
          leadSources: this.leadSources,
          agents: this.agents,
          classAttr: 'patientDialogAttr',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //console.log('The dialog was closed');
        if (result && result.data) {
          this.getClientProducts();
        }
        dialogRef = null;
        // if (result && result.data) this.savePatientDetails(result.data);
      });
    });
  }
  savePatientDetails(product: Product) {
    product.patient.clientId = this.client.clientId;
    product.statusId = 9; //Sent to customer
    this.productsPusherService.put(product);
  }
  closeNewCallWindow() {
    this.isCallsEditMode = false;
  }
  closeNewTaskWindow() {
    this.isTaskEditMode = false;
  }
  closeNewRequestWindow() {
    this.isRequestEditMode = false;
  }
  saveRequest(request: ServiceRequest) {
    request.clientId = this.client.clientId;
    request.assigningAgentId = this.agentUser.agent.agentId;
    if (request.serviceRequestId > 0) {
      this.serviceRequestsPusherService.put(request);
    } else {
      this.serviceRequestsPusherService.post(request);
    }
    this.serviceRequestsPusherService.getAll(null, this.clientId);
    this.isRequestEditMode = false;
  }

  saveCall(note: Note | NoteForCallMin) {
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.clientId = this.client.clientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      fullNote.noteTypeId = 4;
      this.notesPusherService.post(fullNote);
    }
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.callsReviews
    );
    setTimeout(() => this.getAllCalls(), 2000);
    this.isCallsEditMode = false;
  }

  saveTask(note: Note | NoteForCallMin) {
    /*    note.clientId = this.client.clientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    //note.dueDate = null;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      this.notesPusherService.post(note);
    } */

    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.clientId = this.client.clientId;
      noteMin.assigningAgent.agentId = this.agentUser.agent.agentId;

      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.clientId = this.client.clientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesPusherService.post(fullNote);
    }
    this.isTaskEditMode = false;
  }
  createNewTask(note: Note) {
    if (!note) note = new Note();
    if (!note.assignedAgentId && this.client.agent)
      note.assignedAgentId = this.client.agent.agentId;
    this.activityToEdit = note;
    this.isTaskEditMode = true;
  }

  //request
  createNewRequest(request: ServiceRequest = null) {
    if (!request) request = new ServiceRequest();
    if (!request.assignedAgentId)
      request.assignedAgentId = +this.globalParams.find(
        (x) => x.key == 'defaultServiceRequestAgentId'
      ).value;
    //   request.assignedAgentId = this.client.agent.agentId;
    this.requestToEdit = request;
    this.isRequestEditMode = true;
  }

  isNew() {
    var leadId = this.route.snapshot.paramMap.get('clientId');
    if (leadId === '-1') {
      return false;
    }
  }
  onCancelForm(e) {
    this.isEditMode = false;
  }
  gotoLogs() {
    this.router.navigate(['/clients/edit/log'], {
      queryParams: { clientId: this.client.clientId },
    });
  }

  onPurchasePartFinished(deal: Deal) {
    this.deal = deal;

    if (this.purchaseMode == 4) {
      this.purchaseMode = 5;
      this.completePurchase();
    } else {
      this.purchaseMode++;
    }
  }
  openPurchaseCompleteDialog(
    lead: Client = null,
    type = '',
    from = '',
    to = ''
  ) {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: lead,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      var a = result;
    });
  }
  getClientProductsForUpgrade() {
    return this.clientProducts.filter(
      (x) => x.patientId != null && x.productType == 'Nutrogenics'
    );
  }
  activateCallEditMode(val) {
    this.isCallsEditMode = val;
    if (val) {
      this.createNewCall(new Note());
    } else {
      this.getAllCalls();
    }
  }
  onReloadNotes() {
    this.notesPusherService.getAllNotes(this.clientId, 'client');
  }
  userHasPermision(perms) {
    return userHasPermisions(perms, this.agentUser);
  }
  getClientDealBundles() {
    this.dealsService.getByClientId(this.client.clientId).subscribe((data) => {
      this.clientDealBundles = data;
    });
  }
}
