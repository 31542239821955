import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-buttons-list-dialog',
  templateUrl: './buttons-list-dialog.component.html',
  styleUrls: ['./buttons-list-dialog.component.css'],
})
export class ButtonsListDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {}
  onOptionSelected(val) {
    this.dialogRef.close(val);
  }
}
