import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AgentSettings } from '../data/AgentSettings';

@Injectable({
  providedIn: 'root',
})
export class AgentSettingsService {
  constructor(private http: HttpClient) {}

  getAgentSettings(agentId: number, langId: number) {
    return this.http.get<AgentSettings>(
      `${environment.baseUrl}Branch/AgentSettingsGet/${agentId}/${langId}`
    );
  }

  setAgentSettings(agentSettings: AgentSettings) {
    return this.http.put<any>(
      `${environment.baseUrl}Branch/AgentSettingsSet`,
      agentSettings
    );
  }
  addAgentSettings(agentSettings: AgentSettings) {
    return this.http.post<any>(
      `${environment.baseUrl}Branch/AgentSettingsAdd`,
      agentSettings
    );
  }
}
