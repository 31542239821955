import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Client } from '../data/Client';
import { Observable } from 'rxjs';
import { Logging } from '../data/Logging';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingsService {
  constructor(private http: HttpClient) {}
  getByClientId(clientId): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}Loggings/Client/${clientId}`
    );
  }
  getByProductId(productId): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}Loggings/Product/${productId}`
    );
  }
}
