import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActionConvertion } from '../data/ActionConvertion';
import { Client } from '../data/Client';
import { ErrorMessage } from '../data/ErrorMessage';
import { ModuleMinForConvertion } from '../data/ModuleMinForConvertion';
import { PermissionRule } from '../data/PermissionRule';

@Injectable({
  providedIn: 'root',
})
export class ActionConvertionsService {
  update(moduleId: number, actionId: number, ruleId: number, checked: boolean) {
    return this.http.get<ActionConvertion[]>(
      `${environment.baseUrl}ActionConvertions/Update/${moduleId}/${actionId}/${ruleId}/${checked}`
    );
  }
  getForRoleAndModule(roleId, moduleId) {
    return this.http.get<ActionConvertion[]>(
      `${environment.baseUrl}ActionConvertions/ForRoleAndModule/${roleId}/${moduleId}`
    );
  }
  getForModuleAndAction(moduleId, actionId) {
    return this.http.get<PermissionRule[]>(
      `${environment.baseUrl}ActionConvertions/ForModuleAndAction/${moduleId}/${actionId}`
    );
  }
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<ActionConvertion[]>(
      `${environment.baseUrl}ActionConvertions/`
    );
  }
  getAllMin() {
    return this.http.get<ModuleMinForConvertion[]>(
      `${environment.baseUrl}ActionConvertions/Min/`
    );
  }
  post(actionConvertion: ActionConvertion): Observable<ActionConvertion> {
    return this.http.post<ActionConvertion>(
      `${environment.baseUrl}ActionConvertions/`,
      actionConvertion
    );
  }
  put(actionConvertion: ActionConvertion): Observable<ActionConvertion> {
    return this.http.put<ActionConvertion>(
      `${environment.baseUrl}ActionConvertions/${actionConvertion.actionConvertionId}`,
      actionConvertion
    );
  }
  delete(actionConvertion: ActionConvertion) {
    return this.http.delete(
      `${environment.baseUrl}ActionConvertions/${actionConvertion.actionConvertionId}`
    );
  }
  saveAllForModuleAndRole(actionConvertions: ActionConvertion[]) {
    throw new Error('Method not implemented.');
  }
}
