<mat-card class="mb-2 formula" *ngIf="formula">
  <mat-card-header>
    <mat-card-title>
      <div>Formula editor</div>
      <div>{{ titleByFormulaType() }}</div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form
      (ngSubmit)="saveFormula()"
      [formGroup]="formulaForm"
      *ngIf="formulaForm"
    >
      <!-- Formula name -->
      <div class="row">
        <div class="row__label">Name:</div>
        <mat-form-field class="w-100 field" appearance="fill">
          <input
            matInput
            placeholder="Formula name"
            formControlName="formulaName"
          />
        </mat-form-field>
      </div>

      <!-- Formula OrderBy -->
      <div class="row">
        <div class="row__label">Order by:</div>
        <mat-form-field class="field" appearance="fill">
          <input
            matInput
            placeholder="Order By"
            formControlName="orderBy"
            type="number"
          />
        </mat-form-field>
      </div>

      <!-- Formula pattern -->
      <div class="row">
        <div class="row__label">Pattern:</div>
        <div class="formula-aggregation">
          <mat-form-field
            class="w-100 field"
            [class.error]="showPatternError"
            appearance="fill"
          >
            <mat-chip-list
              #chipList
              formControlName="formulaPattern"
              [selectable]="true"
              [disabled]="!canEdit"
            >
              <mat-chip
                #chip
                *ngFor="let item of formulaArray; let i = index"
                [class.selected]="activeChipIndex === i"
                (click)="onChipClick(i, item)"
              >
                {{ item.param }}
              </mat-chip>
              <input
                [matChipInputFor]="chipList"
                [matChipInputAddOnBlur]="true"
              />
            </mat-chip-list>
          </mat-form-field>

          <!-- Aggregation -->
          <mat-form-field
            class="select short"
            appearance="fill"
            *ngIf="getAggregatingType"
          >
            <mat-select
              formControlName="aggregatingType"
              placeholder="Aggregation type"
            >
              <mat-option *ngFor="let agg of aggregatingTypes" [value]="agg.id">
                {{ agg.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- All calc functions -->
      <div class="row">
        <div class="row__label"></div>
        <div>
          <ng-container *ngIf="true">
            <button
              type="button"
              mat-button
              (click)="onChipDelete()"
              [disabled]="
                activeChipIndex < 0 || formulaArray.length <= 0 || !canEdit
              "
              class="calc-btn"
            >
              <mat-icon>backspace</mat-icon>
            </button>
            <button
              type="button"
              mat-button
              [disabled]="
                activeChipIndex <= 0 || formulaArray.length <= 0 || !canEdit
              "
              (click)="onChipBack()"
              class="calc-btn"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              mat-button
              [disabled]="
                activeChipIndex >= formulaArray.length - 1 || !canEdit
              "
              type="button"
              (click)="onChipNext()"
              class="calc-btn"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </ng-container>
          <!-- Calc functions -->
          <ng-container
            *ngIf="formula.formulaType == FormulaType.FormulaCommand"
          >
            <ng-container *ngFor="let item of calcFunctions">
              <button
                type="button"
                mat-button
                [disabled]="item.isDisabled || !canEdit"
                (click)="onCalcClick(item)"
                class="calc-btn"
              >
                {{ item.fgCalculatorFunctName }}
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Parameters -->
      <div class="row">
        <div class="row__label"></div>
        <div class="param-flex">
          <div class="param-flex__inner">
            <div class="param-flex__range" *ngIf="showRange && formula">
              <div class="param-flex__range__item">
                <div class="param-flex__range__item__label">Min</div>
                <div class="range">
                  <app-menu-param-types
                    *ngIf="notAlertTags"
                    [data]="notAlertTags"
                    (selectedParamChange)="onRangeParamSelect($event, 'min')"
                    [selectedParamId]="
                      getMinMaxRangeValues()?.minParam?.fgParamId
                    "
                  ></app-menu-param-types>
                </div>
                <!-- [selectedParamId]="formulaForm.get('defaultValue').value"
                  (selectedParamChange)="onDefaultValueSelect($event)" -->
              </div>
              <div class="param-flex__range__item">
                <div class="param-flex__range__item__label">Max</div>
                <div class="range">
                  <app-menu-param-types
                    *ngIf="notAlertTags"
                    [data]="notAlertTags"
                    (selectedParamChange)="onRangeParamSelect($event, 'max')"
                    [selectedParamId]="
                      getMinMaxRangeValues()?.maxParam?.fgParamId
                    "
                  ></app-menu-param-types>
                </div>
              </div>
              <div class="param-flex__range__item">
                <div class="param-flex__range__item__label">Value</div>
                <div class="range">
                  <app-menu-param-types
                    *ngIf="notAlertTags"
                    [data]="notAlertTags"
                    (selectedParamChange)="onRangeParamIdSelect($event)"
                    [selectedParamId]="formula?.rangeOnParamId"
                  ></app-menu-param-types>
                </div>
              </div>
            </div>
            <div class="param-flex__range" *ngIf="showAssignment">
              <div class="range">
                <app-menu-param-types
                  *ngIf="notAlertTags"
                  [data]="notAlertTags"
                  (selectedParamChange)="onParamSelect($event.fgParamName)"
                ></app-menu-param-types>
              </div>
            </div>
            <div class="parameters" *ngIf="!showRange && !showAssignment">
              <ng-container *ngFor="let item of paramTypes">
                <mat-form-field
                  class="select select-param"
                  appearance="fill"
                  *ngIf="item.params && item.params.length > 0"
                >
                  <mat-select
                    [disabled]="isParamDisabled || !canEdit"
                    [placeholder]="item.fgParamName"
                  >
                    <mat-option
                      *ngFor="let param of item.params"
                      (click)="onParamSelect(param.fgParamName)"
                    >
                      {{ param.fgParamName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <button
            (click)="checkFormula()"
            *ngIf="canEdit"
            type="button"
            mat-button
            class="check-formula"
          >
            Check formula
          </button>
        </div>
      </div>

      <!-- Conditions -->
      <div class="conditions">
        <mat-button-toggle-group
          class="logic-operator"
          *ngIf="getLogicOperator"
          formControlName="logicOperator"
        >
          <mat-button-toggle
            *ngFor="let item of logicOperators"
            [value]="item.id"
            >{{ item.name }}</mat-button-toggle
          >
        </mat-button-toggle-group>
        <div
          class="cond-row"
          formArrayName="formulaCondition"
          *ngFor="
            let condition of formulaForm.get('formulaCondition')['controls'];
            let i = index
          "
        >
          <div [formGroupName]="i" class="cond-row__item">
            <span class="cond-row__item__label">Condition:</span>
            <div class="cond-row__item__type">
              <app-menu-param-types
                *ngIf="notAlertTags"
                [data]="notAlertTags"
                [selectedParamId]="condition.get('firstParam').value"
                (selectedParamChange)="
                  onParamConditionSelect($event, i, 'firstParam')
                "
              ></app-menu-param-types>
            </div>
            <mat-form-field class="cond-row__item__operand" appearance="fill">
              <mat-select formControlName="operator" placeholder="Operand">
                <mat-option
                  *ngFor="let operand of conditionOprands"
                  [value]="operand.id"
                >
                  {{ operand.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="cond-row__item__type">
              <app-menu-param-types
                *ngIf="notAlertTags"
                [data]="notAlertTags"
                [selectedParamId]="condition.get('secondParam').value"
                (selectedParamChange)="
                  onParamConditionSelect($event, i, 'secondParam')
                "
              ></app-menu-param-types>
            </div>
            <button
              class="cond-row__item__remove"
              type="button"
              mat-icon-button
              (click)="onConditionDelete(i)"
            >
              <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <button
            class="add-condition"
            type="button"
            mat-button
            (click)="onConditionAdd()"
            *ngIf="canEdit"
          >
            <mat-icon>add</mat-icon>
            Add condition
          </button>
        </div>

        <!-- Default value -->
        <div class="row mt-4" *ngIf="getDefaultValue">
          <div class="row__label">Default value:</div>
          <div class="default-value">
            <app-menu-param-types
              *ngIf="notAlertTags"
              [data]="notAlertTags"
              [selectedParamId]="formulaForm.get('defaultValue').value"
              (selectedParamChange)="onDefaultValueSelect($event)"
            ></app-menu-param-types>
          </div>
        </div>
      </div>

      <div class="mt-4" style="display: flex; justify-content: space-between">
        <div>
          <button *ngIf="canEdit" type="submit" mat-button class="save">
            Save
          </button>
          <button type="button" (click)="cancel()" mat-button class="cancel">
            Cancel
          </button>
        </div>
        <button
          *ngIf="canEdit"
          type="button"
          (click)="deleteFormula()"
          mat-button
          class="cancel"
          style="margin-right: 0"
        >
          Delete
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
