<table
  class="table mat-elevation-z8"
  mat-table
  [dataSource]="dataSource"
  #table
>
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef style="width: 15%">Category</th>
    <td mat-cell *matCellDef="let element">
      {{ element.translationCategory.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="keyword">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">
      <button mat-icon-button class="table__add" (click)="addNewKeyword()">
        <mat-icon>add</mat-icon>
      </button>
      Keyword
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.keyword }}
    </td>
  </ng-container>
  <ng-container matColumnDef="translation">
    <th mat-header-cell *matHeaderCellDef>
      <button
        [class.active]="lang.languageId == activeLang?.languageId"
        class="lang-btn"
        (click)="onSelectLang(lang)"
        mat-button
        *ngFor="let lang of languages"
      >
        {{ lang.name }}
      </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="input-translations">
        <ng-container *ngFor="let item of element.translations">
          <ng-container *ngIf="item.languageId == activeLang?.languageId">
            <mat-form-field
              *ngIf="editInx == item.translationKeywordId && editMode"
              appearance="fill"
              [ngStyle]="{
                'text-align': activeLang.isRtl ? 'right' : 'left',
                direction: activeLang.isRtl ? 'rtl' : 'ltr'
              }"
            >
              <input matInput [(ngModel)]="item.value" />
            </mat-form-field>
            <div
              class="view-name"
              *ngIf="
                !editMode || (editMode && editInx !== item.translationKeywordId)
              "
            >
              {{ item.value }}
            </div>
            <button
              mat-icon-button
              (click)="onEdit(item.translationKeywordId)"
              *ngIf="this.editInx !== item.translationKeywordId && !editMode"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="onSave(item.translationKeywordId)"
              *ngIf="editInx == item.translationKeywordId && editMode"
            >
              <mat-icon>check</mat-icon>
            </button>
            <button
              (click)="onCancelEdit()"
              *ngIf="editInx == item.translationKeywordId && editMode"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
