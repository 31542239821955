<div [dir]="direction" class="autocomplete-countries" style="display: inline">
  <mat-form-field
    appearance="fill"
    [matMenuTriggerFor]="valueComplete"
    class="main-field"
    (menuOpened)="focusInput()"
  >
    <img
      matPrefix
      src="https://flagcdn.com/16x12/{{ country.nameCode | lowercase }}.png"
      style="transform: translateY(3px); margin-right: 5px"
      [matTooltip]="country.name"
    />
    <input
      style="cursor: pointer"
      class="input-compelete"
      type="text"
      matInput
      #value
      disabled
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="open"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu
    #valueComplete="matMenu"
    xPosition="before"
    yPosition="below"
    class="menu-complete-countries"
    panelClass="autocomplete-menu"
  >
    <ng-container *ngIf="data; else noData">
      <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <input
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div>

      <button
        *ngFor="let value of filteredValuesOptions || data"
        class="menu-complete-countries__item"
        style="width: 100%"
        mat-button
        (click)="onValueSelected(value.countryId)"
      >
        <img
          src="https://flagcdn.com/16x12/{{ value.nameCode | lowercase }}.png"
          [matTooltip]="value.name"
        />
        {{ value.countryCode }}
      </button>
    </ng-container>
    <ng-template #noData> no data </ng-template>
  </mat-menu>
</div>
