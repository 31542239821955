import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SalesDashboard } from 'src/app/data/SalesDashboard';
import { FirebaseCallsService } from 'src/app/Services/firebase-calls.service';
import { DashboardLeadStatusCount } from 'src/app/data/DashboardLeadStatusCount';
import { DashboardAgentStat } from 'src/app/data/DashboardAgentStat';
import { DashboardCallStatSale } from 'src/app/data/DashboardCallStatSale';
import { AgentStatusEnum } from 'src/app/Enums/StatusesEnum';
import { convertToTimespan } from 'src/app/Utils/dateUtils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sales-call-statistics',
  templateUrl: './sales-call-statistics.component.html',
  styleUrls: ['./sales-call-statistics.component.scss'],
})
export class SalesCallStatisticsComponent implements OnInit, OnDestroy {
  @ViewChild('agentsCard') agentsCard: ElementRef;
  salesDashboard: SalesDashboard;
  DashboardLeadStatusCounts: DashboardLeadStatusCount[];
  DashboardAgentStats: DashboardAgentStat[];
  DashboardCallStatSales: DashboardCallStatSale;
  displayedColumns: string[] = [
    'status',
    'name',
    'answeredCall',
    'outcomingCalls',
    'totalTalkTime',
    'totalBreakTime',
  ];
  canvasWidth = 200;
  needleValue = 60;
  centralLabel: string;
  bottomLabel: string;
  options = {
    hasNeedle: true,
    needleColor: '#153341',
    needleStartValue: 0,
    arcColors: ['#119c99', '#f9b904', '#e80505'],
    arcDelimiters: [50, 80],
    rangeLabel: ['0', '120'],
  };
  chunkIndex: number = 0;
  subscribe: Subscription;

  constructor(private firebaseCallsService: FirebaseCallsService) {}
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribe = this.getDashboardStatistics();
  }
  getDashboardStatistics() {
    return this.firebaseCallsService
      .getDashboardStatistics()
      .subscribe((res) => {
        this.salesDashboard = res['SalesDashboard'];
        const {
          DashboardLeadStatusCounts,
          DashboardCallStatSales,
          DashboardAgentStats,
        } = this.salesDashboard;
        this.DashboardLeadStatusCounts = DashboardLeadStatusCounts;
        this.DashboardCallStatSales = DashboardCallStatSales;
        this.DashboardAgentStats = DashboardAgentStats;
        this.needleValue = this.calcNeedleValue();
        this.bottomLabel = this.averageResponse().toFixed(1);
      });
  }
  renderAgentRows(agents: DashboardAgentStat[]): DashboardAgentStat[] {
    setTimeout(() => {
      const agentsCardHeight = this.agentsCard.nativeElement.offsetHeight - 60;
      const chunk = Math.round(agentsCardHeight / 48) - 1;
      const chunkedArray = this.agentsChunkedArray(agents, chunk);
      const length = chunkedArray.length;
      const lastIndex = chunkedArray.length - 1;

      if (this.chunkIndex == 0) {
        return this.sortAgentsByStatus(chunkedArray[this.chunkIndex]);
      }
      setInterval(() => {
        this.chunkIndex++;
        if (length > this.chunkIndex) {
          const prevArr = chunkedArray[this.chunkIndex - 1];
          const currArr = chunkedArray[this.chunkIndex];
          const fullArr = prevArr.concat(currArr).reverse();
          const chunkedArr = this.agentsChunkedArray(fullArr, chunk);
          return this.sortAgentsByStatus(chunkedArr[0]);
        } else if (this.chunkIndex == lastIndex + 1) {
          this.chunkIndex = 0;
          return this.sortAgentsByStatus(chunkedArray[this.chunkIndex]);
        }
      }, 1000 * 10);
    }, 500);
    return this.sortAgentsByStatus(agents);
  }
  sortAgentsByStatus(array: DashboardAgentStat[]) {
    return array.sort((a, b) => {
      if (a.AgentStatusId < b.AgentStatusId) {
        return -1;
      }
      if (a.AgentStatusId > b.AgentStatusId) {
        return 1;
      }
      return 0;
    });
  }
  agentsChunkedArray(array: DashboardAgentStat[], chunk: number) {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }
  getAgentStatusColor(statusId: number) {
    switch (statusId) {
      case AgentStatusEnum.Online:
        return '#119C99';
      case AgentStatusEnum.Break:
        return '#FF8A5C';
      case AgentStatusEnum.Offline:
        return '#E80505';
    }
  }
  convertToTimespan(duration: any) {
    return convertToTimespan(duration);
  }
  precenageResponse() {
    const missing = this.DashboardCallStatSales.MissingCallCount;
    const count = this.DashboardCallStatSales.AnsweredCallCount + missing;
    if (count == 0) return 100;
    return 100 - (missing / count) * 100;
  }
  averageResponse() {
    const count = this.DashboardCallStatSales.AnsweredCallCount;
    const sum = this.DashboardCallStatSales.ResponseTimeSum;
    if (count == 0) return 0;
    return sum / count;
  }
  calcNeedleValue() {
    let value = this.averageResponse();
    if (value > 100) {
      value = value - 20;
    } else if (value >= 60) {
      value = value - 10;
    }
    return value;
  }
}
