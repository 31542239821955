import { Component, Input, OnInit } from '@angular/core';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { GuidedQuestion } from 'src/app/data/GuidedQuestion';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { GlobalParamsService } from 'src/app/Services/global-params.service';

@Component({
  selector: 'app-preview-chat',
  templateUrl: './preview-chat.component.html',
  styleUrls: ['./preview-chat.component.scss'],
})
export class PreviewChatComponent implements OnInit {
  @Input() activeRequest: ServiceRequest;
  guidedQuestionTree;
  guidedQuestionData;
  chatHistory: any;

  constructor(private globalParamsService: GlobalParamsService) {}

  ngOnInit(): void {
    var a = this.activeRequest;
    this.globalParamsService.getAll().subscribe((data) => {
      this.guidedQuestionTree = JSON.parse(
        data.find((x) => x.key === 'GuidedQuestionTree').value
      );

      this.guidedQuestionData = JSON.parse(
        data.find((x) => x.key === 'GuidedQuestionData').value
      );

      this.initChat();
    });
  }

  initChat() {
    this.chatHistory = [];
    this.selectNextAnswer('111');
  }
  selectNextAnswer(GQId) {
    if (this.guidedQuestionTree) {
      var item = this.searchInTree(GQId, this.guidedQuestionTree);
      var children = item?.children;
      var obj = this.guidedQuestionData[GQId] as GuidedQuestion;

      var gq = new GuidedQuestion();
      gq.nextQuestionTxt = obj.nextQuestionTxt;
      gq.answerTxt = obj.answerTxt;
      if (children)
        gq.answers = children.map((x) => {
          var obj1 = this.guidedQuestionData[x.item] as GuidedQuestion;
          var gq1 = new GuidedQuestion();
          gq1.guidedQuestionId = x.item;
          gq1.actionId = obj1.actionId;
          gq1.answerTxt = obj1.answerTxt;
          gq1.nextQuestionTxt = obj1.nextQuestionTxt;
          return gq1;
        });

      this.chatHistory.push(gq);
    }
  }

  onAnswerSelect(answer) {}
  searchInTree(search: string, directories: any[]) {
    for (let directory of directories) {
      if (directory.item === search) {
        return directory;
      }
      if (directory.children !== undefined && directory.children.length > 0) {
        let childsearch = this.searchInTree(search, directory.children);
        if (childsearch !== undefined) {
          return childsearch;
        }
      }
    }
    return undefined;
  }
}
