//import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MyGenesMaterialModule } from '@mygenes/my-genes-material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ResizableModule } from 'angular-resizable-element';
import player from 'lottie-web';
/*
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'; */
import * as _moment from 'moment';
import { InViewportModule } from 'ng-in-viewport';
import { LottieModule } from 'ngx-lottie';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { NgxPrintModule } from 'ngx-print';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// Import library module
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './Auth/error.interceptor';
import { JwtInterceptor } from './Auth/jwt.interceptor';
import { AuthenticationModule } from './authentication/authentication.module';
import { ClientEditDialogWraperComponent } from './Cards/client-edit-dialog-wraper/client-edit-dialog-wraper.component';
import { PatientReportsComponent } from './Cards/patient-reports/patient-reports.component';
import { ClientAddNoteComponent } from './CardsEdit/client-add-note/client-add-note.component';
import { ClientCreateCallComponent } from './CardsEdit/client-create-call/client-create-call.component';
import { ClientProfileEditComponent } from './CardsEdit/client-profile-edit/client-profile-edit.component';
import { DnaReportEditComponent } from './CardsEdit/dna-report-edit/dna-report-edit.component';
import { LeadCreateRequestComponent } from './CardsEdit/lead-create-request/lead-create-request.component';
import { LeadCreateTaskComponent } from './CardsEdit/lead-create-task/lead-create-task.component';
import { LifestyleFormDynamicComponent } from './CardsEdit/lifestyle-form-dynamic/lifestyle-form-dynamic.component';
import { LifestyleReportEditComponent } from './CardsEdit/lifestyle-report-edit/lifestyle-report-edit.component';
import { PatientProfileEditComponent } from './CardsEdit/patient-profile-edit/patient-profile-edit.component';
import { PatientReportsEditComponent } from './CardsEdit/patient-reports-edit/patient-reports-edit.component';
import { PurchaseChooseProductsComponent } from './CardsEdit/purchase-choose-products/purchase-choose-products.component';
import { PurchasePaymentDetailsComponent } from './CardsEdit/purchase-payment-details/purchase-payment-details.component';
import { PurchasePersonalDetailsComponent } from './CardsEdit/purchase-personal-details/purchase-personal-details.component';
import { RequestEditComponent } from './CardsEdit/request-edit/request-edit.component';
import { SugartestReportEditComponent } from './CardsEdit/sugartest-report-edit/sugartest-report-edit.component';
import { ActivitiesDashboardComponent } from './CardsView/activities-dashboard/activities-dashboard.component';
import { BloodtestReportViewComponent } from './CardsView/bloodtest-report-view/bloodtest-report-view.component';
import { ClientCallsViewComponent } from './CardsView/client-calls-view/client-calls-view.component';
import { ClientNotesComponent } from './CardsView/client-notes/client-notes.component';
import { ClientProfileViewComponent } from './CardsView/client-profile-view/client-profile-view.component';
import { ClientPurchasesComponent } from './CardsView/client-purchases/client-purchases.component';
import { ClientTestsComponent } from './CardsView/client-tests/client-tests.component';
import { DnaReportInnerComponent } from './CardsView/dna-report-inner/dna-report-inner.component';
import { DnaReportViewComponent } from './CardsView/dna-report-view/dna-report-view.component';
import { DnaResultsCalculatorComponent } from './CardsView/dna-results-calculator/dna-results-calculator.component';
import { FailedPatientDeliveriesComponent } from './CardsView/failed-patient-deliveries/failed-patient-deliveries.component';
import { LeadActivitiesViewComponent } from './CardsView/lead-activities-view/lead-activities-view.component';
import { LeadRequestsViewComponent } from './CardsView/lead-requests-view/lead-requests-view.component';
import { LeadTasksComponent } from './CardsView/lead-tasks/lead-tasks.component';
import { LifestyleReportViewComponent } from './CardsView/lifestyle-report-view/lifestyle-report-view.component';
import { NutritionReportViewComponent } from './CardsView/nutrition-report-view/nutrition-report-view.component';
import { PatientDeliveriesComponent } from './CardsView/patient-deliveries/patient-deliveries.component';
import { PatientProfileViewComponent } from './CardsView/patient-profile-view/patient-profile-view.component';
import { PurchaseDetailsDialogComponent } from './CardsView/purchase-details-dialog/purchase-details-dialog.component';
import { RequestViewComponent } from './CardsView/request-view/request-view.component';
import { SugartestReportViewComponent } from './CardsView/sugartest-report-view/sugartest-report-view.component';
import { AllAgentsComponent } from './Components/Agents/all-agents/all-agents.component';
import { EditAgentComponent } from './Components/Agents/edit-agent/edit-agent.component';
import { ResetPasswordComponent } from './Components/Agents/reset-password/reset-password.component';
import { AllAlgoBulletLinksComponent } from './Components/algo-bullets-links/all-algo-bullet-links/all-algo-bullet-links.component';
import { EditAlgoBulletLinksComponent } from './Components/algo-bullets-links/edit-algo-bullet-links/edit-algo-bullet-links.component';
import { AllAlgoBulletsComponent } from './Components/algo-bullets/all-algo-bullets/all-algo-bullets.component';
import { EditAlgoBulletsComponent } from './Components/algo-bullets/edit-algo-bullets/edit-algo-bullets.component';
import { ArticlesComponent } from './Components/articles/articles.component';
import { AllAutoResponderComponent } from './Components/auto-responder/all-auto-responder/all-auto-responder.component';
import { EditAutoResponderComponent } from './Components/auto-responder/edit-auto-responder/edit-auto-responder.component';
import { AgentScheduleComponent } from './Components/calendar-management/agent-schedule/agent-schedule.component';
import { AgentsCalendarTableComponent } from './Components/calendar-management/agents-calendar-table/agents-calendar-table.component';
import { EditAgentScheduleComponent } from './Components/calendar-management/edit-agent-schedule/edit-agent-schedule.component';
import { MeetingSettingsComponent } from './Components/calendar-management/meeting-settings/meeting-settings.component';
import { CallsReviewsComponent } from './Components/calls-reviews/calls-reviews.component';
import { AllCancelationsComponent } from './Components/Cancelations/all-cancelations/all-cancelations.component';
import { EditCancelationRequestComponent } from './Components/Cancelations/edit-cancelation-request/edit-cancelation-request.component';
import { ChatHourComponent } from './Components/chat-hour/chat-hour.component';
import { ChatListDataComponent } from './Components/chat-list/chat-list-data/chat-list-data.component';
import { ChatListComponent } from './Components/chat-list/chat-list.component';
import { ClientCuponsComponent } from './Components/client-cupons/client-cupons.component';
import { AllClientsComponent } from './Components/Clients/all-clients/all-clients.component';
import { ClientLogsComponent } from './Components/Clients/client-logs/client-logs.component';
import { EditClientComponent } from './Components/Clients/edit-client/edit-client.component';
import { CreateNewUserComponent } from './Components/create-new-user/create-new-user.component';
import { AllDealsComponent } from './Components/Deals/all-deals/all-deals.component';
import { EventActionsComponent } from './Components/event-actions/event-actions.component';
import { AllFoodsComponent } from './Components/Food/all-foods/all-foods.component';
import { BulkEditFoodComponent } from './Components/Food/bulk-edit-food/bulk-edit-food.component';
import { BulkEditRecipeComponent } from './Components/Food/bulk-edit-recipe/bulk-edit-recipe.component';
import { EditFoodComponent } from './Components/Food/edit-food/edit-food.component';
import { FoodCategoriesComponent } from './Components/Food/food-categories/food-categories.component';
import { FoodDetailsComponent } from './Components/Food/food-details/food-details.component';
import { FoodForCategoryComponent } from './Components/Food/food-for-category/food-for-category.component';
import { FoodNutrientsComponent } from './Components/Food/food-nutrients/food-nutrients.component';
import { FoodScalesComponent } from './Components/Food/food-scales/food-scales.component';
import { BloodTestResultSliderComponent } from './Components/Fragments/blood-test-result-slider/blood-test-result-slider.component';
import { LifestyleQuestionEditNodeComponent } from './Components/Fragments/lifestyle-question-edit-node/lifestyle-question-edit-node.component';
import { LifestyleQuestionNodeV2Component } from './Components/Fragments/lifestyle-question-node-v2/lifestyle-question-node-v2.component';
import { LifestyleQuestionNodeComponent } from './Components/Fragments/lifestyle-question-node/lifestyle-question-node.component';
import { LifestyleVersionsComponent } from './Components/Fragments/lifestyle-versions/lifestyle-versions.component';
import { NutritionalValuesComponent } from './Components/Fragments/nutritional-values/nutritional-values.component';
import { GeneticFormulaComponent } from './Components/genetic-formula/genetic-formula.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { AllLeadsComponent } from './Components/Leads/all-leads/all-leads.component';
import { EditLeadComponent } from './Components/Leads/edit-lead/edit-lead.component';
import { LeadLogsComponent } from './Components/Leads/lead-logs/lead-logs.component';
import { ViewLeadComponent } from './Components/Leads/view-lead/view-lead.component';
import { LifeStyleCalculationTypesComponent } from './Components/lifestyle/life-style-calculation-types/life-style-calculation-types.component';
import { LifeStyleCalculationsComponent } from './Components/lifestyle/life-style-calculations/life-style-calculations.component';
import { LifeStyleLocationsComponent } from './Components/lifestyle/life-style-locations/life-style-locations.component';
import { LifestyleReportEditNewComponent } from './Components/lifestyle/lifestyle-report-edit-new/lifestyle-report-edit-new.component';
import { LifestyleReportPreviewComponent } from './Components/lifestyle/lifestyle-report-preview/lifestyle-report-preview.component';
import { LoadingPageComponent } from './Components/loading-page/loading-page.component';
import { AlgoGroupsComponent } from './Components/Manage/algo-groups/algo-groups.component';
import { BarHoldupComponent } from './Components/Manage/bar-holdup/bar-holdup.component';
import { ConfettiComponent } from './Components/Manage/confetti/confetti.component';
import { CreateDnaPdfComponent } from './Components/Manage/create-dna-pdf/create-dna-pdf.component';
import { DashboardComponent } from './Components/Manage/dashboard/dashboard.component';
import { DebugLinksComponent } from './Components/Manage/debug-links/debug-links.component';
import { ErrorLogsComponent } from './Components/Manage/error-logs/error-logs.component';
import { ExternalDealComponent } from './Components/Manage/external-deal/external-deal.component';
import { ExternalLeadComponent } from './Components/Manage/external-lead/external-lead.component';
import { FarLoginHistoryComponent } from './Components/Manage/far-login-history/far-login-history.component';
import { FirebaseLoginComponent } from './Components/Manage/firebase-login/firebase-login.component';
import { FoodCategoriesLingkageComponent } from './Components/Manage/food-categories-lingkage/food-categories-lingkage.component';
import { GuidedQuestComponent } from './Components/Manage/guided-quest/guided-quest.component';
import { GuidedQuestionsComponent } from './Components/Manage/guided-questions/guided-questions.component';
import { LifestyleTreeV2Component } from './Components/Manage/lifestyle-tree-v2/lifestyle-tree-v2.component';
import { LifestyleTreeComponent } from './Components/Manage/lifestyle-tree/lifestyle-tree.component';
import { ModuleToActionsComponent } from './Components/Manage/module-to-actions/module-to-actions.component';
import { PermissionCategoriesComponent } from './Components/Manage/permission-categories/permission-categories.component';
import { PermissionsForRolesNewComponent } from './Components/Manage/permissions-for-roles-new/permissions-for-roles-new.component';
import { PermissionsForRolesComponent } from './Components/Manage/permissions-for-roles/permissions-for-roles.component';
import { PermissionsComponent } from './Components/Manage/permissions/permissions.component';
import { ProcessDnaDataComponent } from './Components/Manage/process-dna-data/process-dna-data.component';
import { RolesComponent } from './Components/Manage/roles/roles.component';
import { SearchBarcodeHistoryComponent } from './Components/Manage/search-barcode-history/search-barcode-history.component';
import { SendSmsComponent } from './Components/Manage/send-sms/send-sms.component';
import { SetPatientsBarcodeComponent } from './Components/Manage/set-patients-barcode/set-patients-barcode.component';
import { TestGoogleMapApiComponent } from './Components/Manage/test-google-map-api/test-google-map-api.component';
import { TestStatusesComponent } from './Components/Manage/test-statuses/test-statuses.component';
import { TransferAgentsClientsComponent } from './Components/Manage/transfer-agents-clients/transfer-agents-clients.component';
import { ViewMailsComponent } from './Components/Manage/view-mails/view-mails.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { NutritionMenuTemplatesComponent } from './Components/nutrition-menu-templates/nutrition-menu-templates.component';
import { MenuBulletsComponent } from './Components/nutrition-menu/menu-bullets/menu-bullets.component';
import { MenuHtmlComponent } from './Components/nutrition-menu/menu-html/menu-html.component';
import { MenuListEditComponent } from './Components/nutrition-menu/menu-list-edit/menu-list-edit.component';
import { MenuListViewComponent } from './Components/nutrition-menu/menu-list-view/menu-list-view.component';
import { NutritionMenuComponent } from './Components/nutrition-menu/nutrition-menu.component';
import { OfficePhoneExtensionsComponent } from './Components/office-phone-extensions/office-phone-extensions.component';
import { AllDeliveriesResponsesComponent } from './Components/Patients/all-deliveries-responses/all-deliveries-responses.component';
import { AllPatientsDeliveriesComponent } from './Components/Patients/all-patients-deliveries/all-patients-deliveries.component';
import { AllPatientsComponent } from './Components/Patients/all-patients/all-patients.component';
import { AllPhoneVerificationSettingComponent } from './Components/phone-verification-settings/all-phone-verification-setting/all-phone-verification-setting.component';
import { EditPhoneVerificationSettingComponent } from './Components/phone-verification-settings/edit-phone-verification-setting/edit-phone-verification-setting.component';
import { EditRefundComponent } from './Components/Refunds/edit-refund/edit-refund.component';
import { ReportRecomendationsComponent } from './Components/report-recomendations/report-recomendations.component';
import { AllRolesComponent } from './Components/roles/all-roles/all-roles.component';
import { EditRoleComponent } from './Components/roles/edit-role/edit-role.component';
import { SalesCallStatisticsComponent } from './Components/sales-call-statistics/sales-call-statistics.component';
import { SearchResultsComponent } from './Components/search-results/search-results.component';
import { AllServiceRequestsComponent } from './Components/ServiceRequests/all-service-requests/all-service-requests.component';
import { EditServiceRequestComponent } from './Components/ServiceRequests/edit-service-request/edit-service-request.component';
import { SupportDashboardComponent } from './Components/support-dashboard/support-dashboard.component';
import { AllTestsComponent } from './Components/Tests/all-tests/all-tests.component';
import { EditTestUserComponent } from './Components/Tests/edit-test-user/edit-test-user.component';
import { PatientLogsComponent } from './Components/Tests/patient-logs/patient-logs.component';
import { TestGeneralInformationComponent } from './Components/Tests/test-general-information/test-general-information.component';
import { TopNavComponent } from './Components/top-nav/top-nav.component';
import { AllTransactionsComponent } from './Components/Transactions/all-transactions/all-transactions.component';
import { TriggerEventsComponent } from './Components/trigger-events/trigger-events.component';
import { AllTubePackagesForLabComponent } from './Components/TubesPackages/all-tube-packages-for-lab/all-tube-packages-for-lab.component';
import { AllTubePackagesForOfficeComponent } from './Components/TubesPackages/all-tube-packages-for-office/all-tube-packages-for-office.component';
import { EditTubePackageLabComponent } from './Components/TubesPackages/edit-tube-package-lab/edit-tube-package-lab.component';
import { EditTubePackageOfficeComponent } from './Components/TubesPackages/edit-tube-package-office/edit-tube-package-office.component';
import { LabErrorsReportComponent } from './Components/TubesPackages/lab-errors-report/lab-errors-report.component';
import { UploadZameretDataComponent } from './Components/upload-zameret-data/upload-zameret-data.component';
import { VerificationTokenPageComponent } from './Components/verification-token-page/verification-token-page.component';
import { VersionHistoryComponent } from './Components/version-history/version-history.component';
import { AllVideosComponent } from './Components/vod/all-videos/all-videos.component';
import { EditVideoComponent } from './Components/vod/edit-video/edit-video.component';
import { VideoCategoriesComponent } from './Components/vod/video-categories/video-categories.component';
import { ENV_CONFIG } from './data/Env';
import { ActionsSelectionComponent } from './Dialogs/actions-selection/actions-selection.component';
import { AlertDialogComponent } from './Dialogs/alert-dialog/alert-dialog.component';
import { AlgoLinkDialogComponent } from './Dialogs/algo-link-dialog/algo-link-dialog.component';
import { BarcodeHistoryDialogComponent } from './Dialogs/barcode-history-dialog/barcode-history-dialog.component';
import { ButtonsListDialogComponent } from './Dialogs/buttons-list-dialog/buttons-list-dialog.component';
import { CallIncomeComponent } from './Dialogs/call-income/call-income.component';
import { CaloricDeficitDialogComponent } from './Dialogs/caloric-deficit-dialog/caloric-deficit-dialog.component';
import { ChangeBarcodeDialogComponent } from './Dialogs/change-barcode-dialog/change-barcode-dialog.component';
import { ChooseCouponDialogComponent } from './Dialogs/choose-coupon-dialog/choose-coupon-dialog.component';
import { ConfirmDialogComponent } from './Dialogs/confirm-dialog/confirm-dialog.component';
import { EditComponent } from './Dialogs/email-template/edit/edit.component';
import { EmailTemplateComponent } from './Dialogs/email-template/email-template.component';
import { SendComponent } from './Dialogs/email-template/send/send.component';
import { FilesManagerComponent } from './Dialogs/files-manager/files-manager.component';
import { FollowUpScheduallerDialogComponent } from './Dialogs/follow-up-schedualler-dialog/follow-up-schedualler-dialog.component';
import { NewEmailTemplateNameComponent } from './Dialogs/new-email-template-name/new-email-template-name.component';
import { RecordCallDialogComponent } from './Dialogs/record-call-dialog/record-call-dialog.component';
import { RoleUsersComponent } from './Dialogs/role-users/role-users.component';
import { UnitScaleDialogComponent } from './Dialogs/unit-scale-dialog/unit-scale-dialog.component';
import { ViteminsDialogComponent } from './Dialogs/vitemins-dialog/vitemins-dialog.component';
import { WebImagesFileManagerComponent } from './Dialogs/web-images-file-manager/web-images-file-manager.component';
import { WeightHistoryComponent } from './Dialogs/weight-history/weight-history.component';
import { WeightUpdateComponent } from './Dialogs/weight-update/weight-update.component';
import { DnaReportInnerV1Component } from './dna-report-inner-v1/dna-report-inner-v1.component';
import { DnaReportViewV1Component } from './dna-report-view-v1/dna-report-view-v1.component';
//import { MatInputModule } from '@angular/material/input';
/* import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card'; */
import { EditGenesPanelComponent } from './edit-genes-panel/edit-genes-panel.component';
import { SelectDnaTemplateComponent } from './edit-genes-panel/select-dna-template/select-dna-template.component';
import {
  AngularFireAuthFactory,
  AngularFirestoreFactory,
  FirebaseModule,
  FIREBASE_PROJECT_ONE,
  FIREBASE_PROJECT_TWO,
  FIREBASE_REFERENCES,
} from './firebase.module';
import { AutocompleteAddressComponent } from './Fragments/autocomplete-address/autocomplete-address.component';
import { AutocompleteAgentComponent } from './Fragments/autocomplete-agent/autocomplete-agent.component';
import { AutocompleteCountriesComponent } from './Fragments/autocomplete-countries/autocomplete-countries.component';
import { AutocompleteFoodCategoriesComponent } from './Fragments/autocomplete-food-categories/autocomplete-food-categories.component';
import { AutocompleteFoodsComponent } from './Fragments/autocomplete-foods/autocomplete-foods.component';
import { BloodTestThumbComponent } from './Fragments/blood-test-thumb/blood-test-thumb.component';
//import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatComponent } from './Fragments/chat/chat.component';
import { ClientEditPurchaseThumbComponent } from './Fragments/client-edit-purchase-thumb/client-edit-purchase-thumb.component';
import { ClientNoteThumbComponent } from './Fragments/client-note-thumb/client-note-thumb.component';
import { ClientProductThumbViewComponent } from './Fragments/client-product-thumb-view/client-product-thumb-view.component';
import { DashboardActivityThumbComponent } from './Fragments/dashboard-activity-thumb/dashboard-activity-thumb.component';
import { LeadActivityThumbComponent } from './Fragments/lead-activity-thumb/lead-activity-thumb.component';
import { LeadConfirmChangeDialogComponent } from './Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { LeadRequestThumbComponent } from './Fragments/lead-request-thumb/lead-request-thumb.component';
import { LeadStatusItemComponent } from './Fragments/lead-status-item/lead-status-item.component';
import { LifestyleProgressBarThumbComponent } from './Fragments/lifestyle-progress-bar-thumb/lifestyle-progress-bar-thumb.component';
import { LifestyleProgressBarComponent } from './Fragments/lifestyle-progress-bar/lifestyle-progress-bar.component';
import { LoadingCircleComponent } from './Fragments/loading-circle/loading-circle.component';
import { MenuCaloriesBarComponent } from './Fragments/menu-calories-bar/menu-calories-bar.component';
import { PatientReportThumbComponent } from './Fragments/patient-report-thumb/patient-report-thumb.component';
import { PreviewChatComponent } from './Fragments/preview-chat/preview-chat.component';
import { SnapSliderComponent } from './Fragments/snap-slider/snap-slider.component';
import { TestActivityThumbComponent } from './Fragments/test-activity-thumb/test-activity-thumb.component';
import { TransactionThumbComponent } from './Fragments/transaction-thumb/transaction-thumb.component';
import { WorkTimeSelectComponent } from './Fragments/work-time-select/work-time-select.component';
import { HomeComponent } from './home/home.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { ClientsService } from './Services/clients.service';
import { DealsService } from './Services/deals.service';
//import { WeightHistoryComponent } from './Components/Manage/weight-history/weight-history.component';
import { FirebaseAnonimousAuthService } from './Services/firebase-anonimous-auth.service';
import { LeadsService } from './Services/leads.service';
import { NotesService } from './Services/notes.service';
import { ProductsService } from './Services/products.service';
import { AgentsTableComponent } from './Tables/agents-table/agents-table.component';
import { AlgoBulletsTableComponent } from './Tables/algo-bullets-table/algo-bullets-table.component';
import { CallsTableComponent } from './Tables/calls-table/calls-table.component';
import { CancelationTableComponent } from './Tables/cancelation-table/cancelation-table.component';
import { ClientsTableComponent } from './Tables/clients-table/clients-table.component';
import { DeliveriesTableComponent } from './Tables/deliveries-table/deliveries-table.component';
import { FoodCategoriesTableComponent } from './Tables/food-categories-table/food-categories-table.component';
import { FoodTableComponent } from './Tables/food-table/food-table.component';
import { HeadFilterComponent } from './Tables/head-filter/head-filter.component';
import { LeadsTableComponent } from './Tables/leads-table/leads-table.component';
import { LogsTableComponent } from './Tables/logs-table/logs-table.component';
import { PackageTubesTableComponent } from './Tables/package-tubes-table/package-tubes-table.component';
import { PatientsTableComponent } from './Tables/patients-table/patients-table.component';
import { RolesTableComponent } from './Tables/roles-table/roles-table.component';
import { ServiceRequestTableComponent } from './Tables/service-request-table/service-request-table.component';
import { TablePaginatorComponent } from './Tables/table-paginator/table-paginator.component';
import { TestsPackageTableComponent } from './Tables/tests-package-table/tests-package-table.component';
import { TestsTableComponent } from './Tables/tests-table/tests-table.component';
import { TransactionsTableComponent } from './Tables/transactions-table/transactions-table.component';
import { VideosTableComponent } from './Tables/videos-table/videos-table.component';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TextEditorDialogComponent } from './text-editor-dialog/text-editor-dialog.component';
import { UploadResultsCsvComponent } from './upload-results-csv/upload-results-csv.component';
//import { AddNewRoleComponent } from './Dialogs/add-new-role/add-new-role.component';
import { ActionConvertionsComponent } from './Components/roles/action-convertions/action-convertions.component';
import { MenuPdfDialogComponent } from './Dialogs/menu-pdf-dialog/menu-pdf-dialog.component';
import { TranslationCategoriesComponent } from './Components/Translations/translation-categories/translation-categories.component';
import { TranslationKeywordsComponent } from './Components/Translations/translation-keywords/translation-keywords.component';
import { TranslationsComponent } from './Components/Translations/translations/translations.component';
import { LeadTransferRulesComponent } from './Components/lead-transfer-rules/lead-transfer-rules.component';
import { AllSnpsComponent } from './Algorithm/snps/all-snps/all-snps.component';
import { SnpsVersionComponent } from './Algorithm/snps/snps-version/snps-version.component';
import { EditSnpComponent } from './Algorithm/snps/edit-snp/edit-snp.component';
import { SnpTableComponent } from './Tables/snp-table/snp-table.component';
import { SnpVersionTableComponent } from './Tables/snp-version-table/snp-version-table.component';

import { AddNewRoleComponent } from './Dialogs/add-new-role/add-new-role.component';
import { AddNewModuleComponent } from './Dialogs/add-new-module/add-new-module.component';

import { RoleManagmentComponent } from './Components/roles/role-managment/role-managment.component';
import { RoleManagmentV2Component } from './Components/roles/role-managment-v2/role-managment-v2.component';
import { AddNewModuleV2Component } from './Dialogs/add-new-module-v2/add-new-module-v2.component';
import { RuleToModuleActionComponent } from './Components/roles/rule-to-module-action/rule-to-module-action.component';
import { UploadFileTestComponent } from './upload-file-test/upload-file-test.component';
import { AllCategoriesComponent } from './Algorithm/categories/all-categories/all-categories.component';
import { MainBoardComponent } from './Algorithm/categories/main-board/main-board.component';
import { SideNavCatComponent } from './Algorithm/categories/sidenav-cat/sidenav-cat.component';
import { SideNavSnpComponent } from './Algorithm/categories/sidenav-snp/sidenav-snp.component';
import { EditRsComponent } from './Algorithm/categories/edit-rs/edit-rs.component';
import { EditSummaryComponent } from './Algorithm/categories/edit-summary/edit-summary.component';
import { AddCategoryComponent } from './Algorithm/categories/add-category/add-category.component';
import { SearchSnpComponent } from './Algorithm/categories/search-snp/search-snp.component';
import { AlgoImageComponent } from './Algorithm/categories/algo-image/algo-image.component';
import { AddResultTypeComponent } from './Algorithm/categories/add-result-type/add-result-type.component';
import { MorePanelComponent } from './Algorithm/categories/more-panel/more-panel.component';
import { EditCategoryComponent } from './Algorithm/categories/edit-category/edit-category.component';
import { EditSubCategoryComponent } from './Algorithm/categories/edit-sub-category/edit-sub-category.component';
import { AddSnpComponent } from './Algorithm/categories/add-snp/add-snp.component';
import { UploadLabResultComponent } from './Algorithm/labs/upload-lab-result/upload-lab-result.component';
import { AlgoPrototypeComponent } from './Algorithm/prototypes/algo-prototype/algo-prototype.component';
import { CategoryPrototypeComponent } from './Algorithm/prototypes/category-prototype/category-prototype.component';
import { SubcategoryPrototypeComponent } from './Algorithm/prototypes/subcategory-prototype/subcategory-prototype.component';
import { ResultTypePrototypeComponent } from './Algorithm/prototypes/result-type-prototype/result-type-prototype.component';
import { SingleCategoryComponent } from './Algorithm/dna-report/single-category/single-category.component';
import { AlgoCategoriesComponent } from './Algorithm/dna-report/algo-categories/algo-categories.component';
import { TranslationComponent } from './Components/translation/translation.component';
import { TranslationTableComponent } from './Tables/translation-table/translation-table.component';
import { AddKeywordComponent } from './Components/translation/add-keyword/add-keyword.component';
import { FileExtensionsComponent } from './Components/file-settings/file-extensions/file-extensions.component';
import { FileTypesComponent } from './Components/file-settings/file-types/file-types.component';
import { EditFileTypeComponent } from './Components/file-settings/edit-file-type/edit-file-type.component';
import { LabsVersionComponent } from './Algorithm/labs/labs-version/labs-version.component';
import { LabsVersionEditComponent } from './Algorithm/labs/labs-version-edit/labs-version-edit.component';
import { DuplicateVersionComponent } from './Algorithm/labs/duplicate-version/duplicate-version.component';
import { TransactionBundleThumbComponent } from './Fragments/transaction-bundle-thumb/transaction-bundle-thumb.component';
import { LabPackegesComponent } from './Algorithm/labs/lab-packeges/lab-packeges.component';

import { ClientPurchasesOldComponent } from './CardsView/client-purchases-old/client-purchases-old.component';
import { DealBundleThumbComponent } from './Fragments/deal-bundle-thumb/deal-bundle-thumb.component';
import { DealThumbComponent } from './Fragments/deal-thumb/deal-thumb.component';
import { ChangeLangComponent } from './Algorithm/change-lang/change-lang.component';
import { LabsListComponent } from './Algorithm/labs/labs-list/labs-list.component';
import { AddLabComponent } from './Algorithm/labs/add-lab/add-lab.component';
import { RecomendationsComponent } from './Algorithm/dna-report/recomendations/recomendations.component';
import { FarLoginAdminComponent } from './Components/far-login-admin/far-login-admin.component';
import { ZoomEventsComponent } from './Components/zoom-events/zoom-events.component';
import { ZoomEventsEditComponent } from './Components/zoom-events-edit/zoom-events-edit.component';
import { FoodGenesWrapperComponent } from './FoodGenes/food-genes-wrapper/food-genes-wrapper.component';
import { FoodGenesSidenavComponent } from './FoodGenes/food-genes-sidenav/food-genes-sidenav.component';
import { FoodGenesSidenavSecondaryComponent } from './FoodGenes/food-genes-sidenav-secondary/food-genes-sidenav-secondary.component';
import { FoodGenesMainBoardComponent } from './FoodGenes/food-genes-main-board/food-genes-main-board.component';
import { FoodGenesSnpBoardComponent } from './FoodGenes/food-genes-snp-board/food-genes-snp-board.component';
import { FoodGenesFormulaBoardComponent } from './FoodGenes/food-genes-formula-board/food-genes-formula-board.component';
import { FoodGenesAddParameterComponent } from './FoodGenes/food-genes-add-parameter/food-genes-add-parameter.component';
import { EditFoodCategoryItemComponent } from './Components/Food/edit-food-category-item/edit-food-category-item.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FoodCategoriesSelectionPanelComponent } from './Components/Food/food-categories-selection-panel/food-categories-selection-panel.component';
import { FoodCategoriesSelectionItemComponent } from './Components/Food/food-categories-selection-item/food-categories-selection-item.component';
import { FoodInUseErrorComponent } from './Dialogs/food-in-use-error/food-in-use-error.component';
import { FoodGenesCoefficientBoardComponent } from './FoodGenes/food-genes-coefficient-board/food-genes-coefficient-board.component';
import { FoodGenesCreateFormulaComponent } from './FoodGenes/food-genes-create-formula/food-genes-create-formula.component';
import { FormulaDevComponent } from './FoodGenes/formula-dev/formula-dev.component';
import { MenuParamTypesComponent } from './FoodGenes/menu-param-types/menu-param-types.component';
import { CalcFunctionsComponent } from './FoodGenes/calc-functions/calc-functions.component';
import { LabelsTableComponent } from './FoodGenes/food-genes-labels/labels-table/labels-table.component';
import { TagsTableComponent } from './FoodGenes/food-genes-tags/tags-table.component';
import { EditLabelComponent } from './FoodGenes/food-genes-labels/edit-label/edit-label.component';
import { EditTagComponent } from './FoodGenes/food-genes-tags/edit-tag/edit-tag.component';
import { FoodGenesParamsComponent } from './FoodGenes/food-genes-params/food-genes-params.component';
import { FoodGenesCreateParamComponent } from './FoodGenes/food-genes-create-param/food-genes-create-param.component';
import { FoodGenesVersionsComponent } from './FoodGenes/food-genes-versions/food-genes-versions.component';
import { FoodGenesEditVersionComponent } from './FoodGenes/food-genes-edit-version/food-genes-edit-version.component';
import { FoodGenesHistoryComponent } from './FoodGenes/food-genes-history/food-genes-history.component';
import { FoodGenesSimulatorComponent } from './FoodGenes/food-genes-simulator/food-genes-simulator.component';
import { FgHistoryTableComponent } from './Tables/fg-history-table/fg-history-table.component';
import { BarcodesManagmentComponent } from './Components/barcodes-managment/barcodes-managment.component';
import { AddBarcodeComponent } from './Dialogs/add-barcode/add-barcode.component';
import { ToastErrorComponent } from './FoodGenes/toast-error/toast-error.component';
import { AgentsListComponent } from './Chat/agents-list/agents-list.component';
import { MainChatBoardComponent } from './Chat/main-chat-board/main-chat-board.component';
import { ChatMessagesComponent } from './Chat/chat-messages/chat-messages.component';
import { ChatInputComponent } from './Chat/chat-input/chat-input.component';
import { MainChatBubbleComponent } from './Chat/main-chat-bubble/main-chat-bubble.component';
import { SystemMessageComponent } from './Chat/bubble-templates/system-message/system-message.component';
import { ConversationMessageComponent } from './Chat/bubble-templates/conversation-message/conversation-message.component';
import { AnswerMessageComponent } from './Chat/bubble-templates/answer-message/answer-message.component';
import { AgentSettingsComponent } from './Components/Agents/agent-settings/agent-settings.component';
import { FoodAllergiesComponent } from './FoodGenes/food-allergies/food-allergies.component';
import { ReviewMessageComponent } from './Chat/bubble-templates/review-message/review-message.component';
import { FilteredResultsComponent } from './Chat/filtered-results/filtered-results.component';
import { ChatFilterComponent } from './Chat/chat-filter/chat-filter.component';
import { WaitingListComponent } from './Chat/waiting-list/waiting-list.component';
import { ComplexMessageComponent } from './Chat/bubble-templates/complex-message/complex-message.component';
import { DynamicTypedMessageComponent } from './Chat/bubble-templates/dynamic-typed-message/dynamic-typed-message.component';

//import { LayoutModule } from '@angular/cdk/layout';

//env

//firebase
/*
const firebaseConfig = {
  apiKey: 'AIzaSyDdIm8PGQJS45Sr7_hYAUDFrJrNvQ8HSZk',
  authDomain: 'mygeneschat.firebaseapp.com',
  databaseURL: 'https://mygeneschat.firebaseio.com',
  projectId: 'mygeneschat',
  storageBucket: 'mygeneschat.appspot.com',
  messagingSenderId: '1087373424417',
  appId: '1:1087373424417:web:379dcd7919a9584a243060',
  measurementId: 'G-VFY8K5FF83',
};
*/
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

//import { MatTooltipModule } from '@angular/material/tooltip';

export function playerFactory() {
  return player;
}
// tslint:disable-next-line:no-duplicate-imports
//import { default as _rollupMoment, Moment } from 'moment';
const moment = _moment;
export const MY_FORMATS = {
  /*  parse: {
    dateInput: 'DD-MM-YYYY',
  }, */
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

//export const options: Partial<IConfig> | (() => Partial<IConfig>);
@NgModule({
  declarations: [
    // MatInputModule,
    AppComponent,
    UploadResultsCsvComponent,
    EditGenesPanelComponent,
    LoginDialogComponent,
    HomeComponent,
    TextEditorDialogComponent,
    AllAgentsComponent,
    EditAgentComponent,
    AllLeadsComponent,
    EditLeadComponent,
    AllClientsComponent,
    EditClientComponent,
    AllDealsComponent,
    ViewLeadComponent,
    AllTestsComponent,
    AllTransactionsComponent,
    EditTestUserComponent,
    ClientProfileViewComponent,
    ClientProfileEditComponent,
    LeadTasksComponent,
    LeadCreateTaskComponent,
    ClientNotesComponent,
    ClientAddNoteComponent,
    ClientPurchasesComponent,
    PatientProfileViewComponent,
    PatientProfileEditComponent,
    PatientReportsComponent,
    LeadActivitiesViewComponent,
    ClientTestsComponent,
    CreateNewUserComponent,
    ClientEditPurchaseThumbComponent,
    ClientCuponsComponent,
    LifestyleReportEditComponent,
    ClientProductThumbViewComponent,
    ClientNoteThumbComponent,
    LeadActivityThumbComponent,
    ClientEditDialogWraperComponent,
    LeadConfirmChangeDialogComponent,
    LeadLogsComponent,
    ClientLogsComponent,
    PatientLogsComponent,
    LogsTableComponent,
    DebugLinksComponent,
    ExternalLeadComponent,
    TestGoogleMapApiComponent,
    HeadFilterComponent,
    LeadsTableComponent,
    ClientsTableComponent,
    TestsTableComponent,
    SearchResultsComponent,
    PurchaseChooseProductsComponent,
    PurchasePaymentDetailsComponent,
    PurchasePersonalDetailsComponent,
    PatientReportsEditComponent,
    LifestyleReportEditComponent,
    ExternalDealComponent,
    PurchaseDetailsDialogComponent,
    TransactionsTableComponent,
    TablePaginatorComponent,
    PatientReportThumbComponent,
    LoadingCircleComponent,
    ConfirmDialogComponent,
    LifestyleReportViewComponent,
    BloodtestReportViewComponent,

    SugartestReportEditComponent,
    SugartestReportViewComponent,
    DnaReportViewComponent,
    DnaReportEditComponent,
    NutritionReportViewComponent,
    AgentsTableComponent,
    PermissionsComponent,
    RolesComponent,
    PermissionsForRolesComponent,
    ResetPasswordComponent,
    //LoginPageComponent,
    //EnterTokenComponent,
    TopNavComponent,
    BloodTestThumbComponent,
    SendSmsComponent,
    AllTubePackagesForLabComponent,
    AllTubePackagesForOfficeComponent,
    EditTubePackageLabComponent,
    EditTubePackageOfficeComponent,
    TestsPackageTableComponent,
    PackageTubesTableComponent,
    SetPatientsBarcodeComponent,
    AllPatientsComponent,
    PatientsTableComponent,
    LabErrorsReportComponent,
    HomePageComponent,
    ActivitiesDashboardComponent,
    DashboardActivityThumbComponent,
    TestActivityThumbComponent,
    AllPatientsDeliveriesComponent,
    PatientDeliveriesComponent,
    DeliveriesTableComponent,
    AllDeliveriesResponsesComponent,
    AllServiceRequestsComponent,
    ServiceRequestTableComponent,
    DashboardComponent,
    ConfettiComponent,
    ActionsSelectionComponent,
    LoadingPageComponent,
    LeadCreateRequestComponent,
    LeadRequestsViewComponent,
    LeadRequestThumbComponent,
    EditServiceRequestComponent,
    RequestViewComponent,
    RequestEditComponent,
    DnaReportInnerComponent,
    DnaResultsCalculatorComponent,
    LifestyleFormDynamicComponent,
    FailedPatientDeliveriesComponent,
    AllCancelationsComponent,
    CancelationTableComponent,
    EditCancelationRequestComponent,
    ButtonsListDialogComponent,
    EditRefundComponent,
    BarHoldupComponent,
    CreateDnaPdfComponent,
    ChooseCouponDialogComponent,
    AutocompleteAgentComponent,
    ViewMailsComponent,
    AllAlgoBulletsComponent,
    EditAlgoBulletsComponent,
    AlgoBulletsTableComponent,
    TestStatusesComponent,
    ChatComponent,
    GuidedQuestionsComponent,
    ClientCreateCallComponent,
    ClientCallsViewComponent,
    CallsTableComponent,
    CallsReviewsComponent,
    ArticlesComponent,
    FirebaseLoginComponent,
    GuidedQuestComponent,
    WeightHistoryComponent,
    AutocompleteAddressComponent,
    PreviewChatComponent,
    NavbarComponent,
    TransactionThumbComponent,
    ProcessDnaDataComponent,
    ChatListComponent,
    BarcodeHistoryDialogComponent,
    FarLoginHistoryComponent,
    EmailTemplateComponent,
    NewEmailTemplateNameComponent,
    AllAutoResponderComponent,
    EditAutoResponderComponent,
    VersionHistoryComponent,
    TriggerEventsComponent,
    EventActionsComponent,
    ChatListDataComponent,
    SendComponent,
    EditComponent,
    SelectDnaTemplateComponent,
    SearchBarcodeHistoryComponent,
    ChatHourComponent,
    AllRolesComponent,
    RolesTableComponent,
    RoleUsersComponent,
    DnaReportViewV1Component,
    DnaReportInnerV1Component,
    PermissionCategoriesComponent,
    FoodCategoriesLingkageComponent,
    NutritionMenuComponent,
    NutritionMenuTemplatesComponent,
    FoodCategoriesComponent,
    FoodForCategoryComponent,
    FoodDetailsComponent,
    MenuListViewComponent,
    MenuListEditComponent,
    MenuBulletsComponent,
    AutocompleteFoodsComponent,
    UnitScaleDialogComponent,
    WeightUpdateComponent,
    AlgoGroupsComponent,
    AllFoodsComponent,
    EditFoodComponent,
    FoodTableComponent,
    ViteminsDialogComponent,
    FollowUpScheduallerDialogComponent,
    TestGeneralInformationComponent,
    CaloricDeficitDialogComponent,
    ChangeBarcodeDialogComponent,
    MenuHtmlComponent,
    BulkEditFoodComponent,
    BulkEditRecipeComponent,
    FoodScalesComponent,
    FoodNutrientsComponent,
    GeneticFormulaComponent,
    LifestyleTreeComponent,
    LifestyleQuestionNodeComponent,
    LifestyleTreeV2Component,
    LifestyleQuestionNodeV2Component,
    ErrorLogsComponent,
    LifestyleVersionsComponent,

    LifestyleReportEditNewComponent,
    LifestyleReportPreviewComponent,
    LifestyleQuestionEditNodeComponent,
    SnapSliderComponent,
    EditAlgoBulletLinksComponent,
    AllAlgoBulletLinksComponent,
    FilesManagerComponent,
    LifestyleProgressBarComponent,
    LifestyleProgressBarThumbComponent,
    ReportRecomendationsComponent,
    AlgoLinkDialogComponent,
    AutocompleteFoodCategoriesComponent,
    FoodCategoriesTableComponent,
    BloodTestResultSliderComponent,
    NutritionalValuesComponent,
    EditRoleComponent,
    UploadZameretDataComponent,
    LeadStatusItemComponent,
    MenuCaloriesBarComponent,
    LifeStyleLocationsComponent,
    LifeStyleCalculationsComponent,
    LifeStyleCalculationTypesComponent,
    TransferAgentsClientsComponent,
    VerificationTokenPageComponent,
    WebImagesFileManagerComponent,
    CallIncomeComponent,
    AgentsCalendarTableComponent,
    MeetingSettingsComponent,
    AgentScheduleComponent,
    EditAgentScheduleComponent,
    WorkTimeSelectComponent,
    RecordCallDialogComponent,
    OfficePhoneExtensionsComponent,
    AutocompleteCountriesComponent,
    AllVideosComponent,
    VideoCategoriesComponent,
    EditVideoComponent,
    VideosTableComponent,
    PermissionsForRolesNewComponent,
    ModuleToActionsComponent,
    AlertDialogComponent,
    SupportDashboardComponent,
    AllPhoneVerificationSettingComponent,
    EditPhoneVerificationSettingComponent,
    SalesCallStatisticsComponent,
    AddNewRoleComponent,
    ActionConvertionsComponent,
    MenuPdfDialogComponent,
    TranslationCategoriesComponent,
    TranslationKeywordsComponent,
    TranslationsComponent,
    LeadTransferRulesComponent,
    AllSnpsComponent,
    SnpsVersionComponent,
    EditSnpComponent,
    SnpTableComponent,
    SnpVersionTableComponent,
    AddNewModuleComponent,

    RoleManagmentComponent,
    RoleManagmentV2Component,
    AddNewModuleV2Component,
    RuleToModuleActionComponent,
    UploadFileTestComponent,
    AllCategoriesComponent,
    MainBoardComponent,
    SideNavCatComponent,
    SideNavSnpComponent,
    EditRsComponent,
    EditSummaryComponent,
    AddCategoryComponent,
    SearchSnpComponent,
    AlgoImageComponent,
    AddResultTypeComponent,
    MorePanelComponent,
    EditCategoryComponent,
    EditSubCategoryComponent,
    AddSnpComponent,
    UploadLabResultComponent,
    AlgoPrototypeComponent,
    CategoryPrototypeComponent,
    SubcategoryPrototypeComponent,
    ResultTypePrototypeComponent,
    SingleCategoryComponent,
    AlgoCategoriesComponent,
    TranslationComponent,
    TranslationTableComponent,
    AddKeywordComponent,
    FileExtensionsComponent,
    FileTypesComponent,
    EditFileTypeComponent,
    LabsVersionComponent,
    LabsVersionEditComponent,
    DuplicateVersionComponent,
    TransactionBundleThumbComponent,
    LabPackegesComponent,

    ClientPurchasesOldComponent,
    DealBundleThumbComponent,
    DealThumbComponent,
    ChangeLangComponent,
    LabsListComponent,
    AddLabComponent,
    RecomendationsComponent,
    FarLoginAdminComponent,
    ZoomEventsComponent,
    ZoomEventsEditComponent,
    FoodGenesWrapperComponent,
    FoodGenesSidenavComponent,
    FoodGenesSidenavSecondaryComponent,
    FoodGenesMainBoardComponent,
    FoodGenesSnpBoardComponent,
    FoodGenesFormulaBoardComponent,
    FoodGenesAddParameterComponent,
    EditFoodCategoryItemComponent,
    FoodCategoriesSelectionPanelComponent,
    FoodCategoriesSelectionItemComponent,
    FoodInUseErrorComponent,
    FoodGenesCoefficientBoardComponent,
    FoodGenesCreateFormulaComponent,
    FormulaDevComponent,
    MenuParamTypesComponent,
    CalcFunctionsComponent,
    LabelsTableComponent,
    TagsTableComponent,
    EditLabelComponent,
    EditTagComponent,
    FoodGenesParamsComponent,
    FoodGenesCreateParamComponent,
    FoodGenesVersionsComponent,
    FoodGenesEditVersionComponent,
    FoodGenesHistoryComponent,
    FoodGenesSimulatorComponent,
    FgHistoryTableComponent,
    BarcodesManagmentComponent,
    AddBarcodeComponent,
    ToastErrorComponent,
    AgentsListComponent,
    MainChatBoardComponent,
    ChatMessagesComponent,
    ChatInputComponent,
    MainChatBubbleComponent,
    SystemMessageComponent,
    ConversationMessageComponent,
    AnswerMessageComponent,
    AgentSettingsComponent,
    FoodAllergiesComponent,
    ReviewMessageComponent,
    FilteredResultsComponent,
    ChatFilterComponent,
    WaitingListComponent,
    ComplexMessageComponent,
    DynamicTypedMessageComponent,
  ],
  imports: [
    FirebaseModule,
    BrowserModule,
    InViewportModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    //CKEditorModule,
    QuillModule.forRoot(),
    AngularEditorModule,

    PerfectScrollbarModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxPrintModule,
    NgxSpinnerModule,
    LottieModule.forRoot({ player: playerFactory }),
    /* AngularFireModule.initializeApp(
      environment.firebaseConfigChat,
      'mygenesChat'
    ), */
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    EmojiModule,
    PickerModule,
    DragDropModule,
    CdkAccordionModule,
    ScrollingModule,
    MyGenesMaterialModule,
    NgxSkeletonLoaderModule,
    ResizableModule,
    [NgxMaterialTimepickerModule.setLocale('he-IL')],
    AuthenticationModule,
    CloudflareStreamModule,
    GaugeChartModule,
    /*
    JwtModule.forRoot({
      config: {
        tokenGetter: function  tokenGetter() {
             return     localStorage.getItem('access_token')},
       // whitelistedDomains: ['http://localhost:60405'],
       // blacklistedRoutes: ['http://localhost:60405/api/RegularUsers/']
      }
    })
   */
  ],
  providers: [
    { provide: ENV_CONFIG, useValue: environment },
    { provide: FIREBASE_PROJECT_ONE, useValue: 'PROJECT-ONE' },
    { provide: FIREBASE_PROJECT_TWO, useValue: 'PROJECT-TWO' },
    {
      provide: FIREBASE_REFERENCES.CHAT_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_ONE],
      useFactory: AngularFirestoreFactory,
    },
    {
      provide: FIREBASE_REFERENCES.CALLS_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TWO],
      useFactory: AngularFirestoreFactory,
    },
    {
      provide: FIREBASE_REFERENCES.CHAT_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_ONE],
      useFactory: AngularFireAuthFactory,
    },
    {
      provide: FIREBASE_REFERENCES.CALLS_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TWO],
      useFactory: AngularFireAuthFactory,
    },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

    DatePipe,
    NotesService,
    LeadsService,
    ClientsService,
    DealsService,
    ProductsService,
    FirebaseAnonimousAuthService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
