
<div style="display: flex; flex-direction: column">
  <button *ngFor="let status of mailTypes" (click)="onLinkClick(status)">
    {{ status.name }}
  </button>
</div> 
<mat-form-field
        appearance="fill"
        class="upgrade"
        style="padding-top: 1.34375em; margin-right: 30px"
      >
        <mat-label>Mail Type:</mat-label>
        <mat-select
          (selectionChange)="onMailTypeChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="selectedMailTypeId"
        >
          <!-- formControlName="agentId"-->
        
          <mat-option
            *ngFor="let status of mailTypes"
            [value]="status.statusId"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
<!--
      <iframe id="frame" frameborder="0" [src]="iframeUrl"></iframe>-->

      <div [innerHTML]="safeHTML(iframeUrl)"></div>