import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { CategoryLangMin } from '../data/CategoryLangMin';
import { Language } from '../data/Language';
import { RegularUser } from '../data/RegularUser';
import { SnpLangMin } from '../data/SnpLangMin';
import { SubCategoryLang } from '../data/SubCategoryLang';
import { SubCatResultMin } from '../data/SubCatResultMin';
import { FileUploadType } from '../Enums/StatusesEnum';
import { AlgorithmVersionService } from '../Services/algorithm-version.service';
import { CategoriesForEditService } from '../Services/categories-for-edit.service';
import { LanguagesService } from '../Services/languages.service';
import { PopupHandlerService } from '../Services/popup-handler.service';
import { RegularUsersService } from '../Services/regular-users.service';
import { SnpResultsService } from '../Services/snpresults.service';
import { SnpsService } from '../Services/snps.service';
import { SubCategoriesService } from '../Services/sub-categories.service';
import { SubCategoryResultsService } from '../Services/sub-category-results.service';
import { TextEditorDialogComponent } from '../text-editor-dialog/text-editor-dialog.component';

@Component({
  selector: 'app-edit-genes-panel',
  templateUrl: './edit-genes-panel.component.html',
  styleUrls: ['./edit-genes-panel.component.scss'],
})
export class EditGenesPanelComponent implements OnInit {
  @ViewChild('innerPanel') innerPanel: ElementRef;
  @ViewChildren('panels') panels: QueryList<MatExpansionPanel>;
  selectedRsNum;
  imgError;
  algoImage;
  isNewDesign: boolean = true;
  myControl = new FormControl();
  options;
  filteredOptions: Observable<string[]>;
  rsNums: string[] = ['1111', '1112', '1113', '1212', '1114', '2113', '2114'];
  filteredRsNums: Observable<string[]>;
  algorithmVersions = [
    //{ versionId: -1, name: 'All' }, not implemented yet
    { versionId: 1, name: 'GGA' },
    { versionId: 2, name: 'Hadasa' },
  ];
  editSnpResultInx = -1;
  editCatSumInx = -1;
  events: string[] = [];
  isSideNavOpened = false;
  panelOpenState = false;
  selectedCategory = null;
  selectedSubCategory: SubCategoryLang = null;
  subCatForLang: SubCatResultMin;
  langId: number = 1;
  categories: CategoryLangMin[];
  selectedSnp = null;
  isCatSumeryShowed = false;
  regularUser: RegularUser;
  editSelectedSubCatNameMode: boolean;
  snpForLang: SnpLangMin;
  indexExpanded: number = -1;
  selectedCategoryColor: any;
  languages: Language[];
  editorConfig: AngularEditorConfig = {
    sanitize: false,
    editable: true,
  };

  selectedVersionId: number = 1;
  selectedSubCategoryResultId: any;
  selectedRsNumError: boolean;
  subCatToAddError: boolean;
  subCatEditMode: boolean;
  subCatIdToEdit: number;
  subCategoryPanelWidth: any;
  //dir: 'ltr' | 'rtl' = this.isRtl();

  constructor(
    private categoriesForEditService: CategoriesForEditService,
    private router: Router,
    public dialog: MatDialog,
    private regularUsersService: RegularUsersService,
    private subCategoriesService: SubCategoriesService,
    private snpResultsService: SnpResultsService,
    private languageService: LanguagesService,
    private subCategoryResultsService: SubCategoryResultsService,
    private popupHandlerService: PopupHandlerService,
    private snpsService: SnpsService,
    private algorithmVersionService: AlgorithmVersionService
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }

  ngOnInit(): void {
    this.algorithmVersionService.getAll().subscribe((res) => {
      this.algorithmVersions = res.map((x) => {
        return { versionId: x.algorithmVersionId, name: x.name };
      });
    });
    this.filteredRsNums = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterRsNums(value))
    );
    this.getSnpsForAutoComplete();
    //this.getAll();
    this.getMenuForLang();
    this.getLanguages();
  }
  getCategories() {}
  getMissingSubCatsList() {}
  getSnpsForAutoComplete() {
    this.snpsService
      .getSnpsNotExistsByVersion(this.selectedVersionId)
      .subscribe((data) => {
        this.rsNums = data.map((x) => x.toString());
      });
  }
  private _filterRsNums(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.rsNums.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getLanguages() {
    this.languageService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  onChangeLang(langId) {
    this.langId = langId;
    this.getMenuForLang();
  }
  getSubCatForLang() {
    this.categoriesForEditService
      .getSubCatForLang(
        this.selectedSubCategory.subCategoryId,
        this.langId,
        this.selectedVersionId
      )
      .subscribe((data) => {
        //console.log(data);

        this.subCatForLang = data;
        /*   */
        /* console.log(
          'length' + this.subCatForLang.subCategoryResultLangs.length
        ); */
        if (this.selectedSnp) {
          this.getSnpForLang();
        }
      });
  }
  getMenuForLang() {
    this.categoriesForEditService
      .getMenuForLang(this.langId, this.selectedVersionId)
      .subscribe((data) => {
        //console.table(data);
        this.categories = data;
        setTimeout(() => {
          if (this.indexExpanded > -1) {
            this.panels.toArray()[this.indexExpanded].open();
          }
        }, 1000);
      });
  }

  isRtl(): 'rtl' | 'ltr' {
    return this.languages?.find((x) => x.languageId == this.langId).isRtl
      ? 'rtl'
      : 'ltr';
  }

  langName() {
    return this.languages?.find((x) => x.languageId == this.langId).name;
  }

  getSnpForLang() {
    this.categoriesForEditService
      .getSnpForLang(
        this.selectedSnp,
        this.langId,
        this.selectedVersionId,
        this.selectedSubCategory.subCategoryId
      )
      .subscribe((data) => {
        //console.log(data);
        this.snpForLang = data;
      });
  }

  /*   getAll() {
    this.categoriesForEditService.getAll().subscribe((data) => {
      this.categories = data;
    });
  } */
  setCategoryBg(categoryId: number) {
    return this.selectedCategory == categoryId ? '#395DEC' : '';
  }
  onCategoryClick(
    event,
    category: CategoryLangMin,
    color: string,
    index: number
  ) {
    this.selectedCategory = category;
    this.selectedCategoryColor = color;
    setTimeout(() => {
      this.indexExpanded = index;
    }, 300);
  }

  onCategoryClosed() {
    this.selectedCategory = null;

    this.selectedSubCategory = null;
    //this.selectedSnp = null;
    this.isCatSumeryShowed = false;
    setTimeout(() => (this.isSideNavOpened = false), 500);
  }
  onSubCategoryClick(
    event,
    category: CategoryLangMin,
    subCategory: SubCategoryLang
  ) {
    //console.log(subCategory);
    this.subCategoryPanelWidth = this.innerPanel.nativeElement.clientWidth;
    this.selectedSnp = null;
    //this.isSideNavOpened = !this.isSideNavOpened;
    if (!this.subCatEditMode) {
      if (
        this.selectedSubCategory &&
        subCategory.subCategoryId == this.selectedSubCategory.subCategoryId
      ) {
        this.isSideNavOpened = !this.isSideNavOpened;
        this.selectedSubCategory = null;
      } else {
        this.isSideNavOpened = true;
        this.isCatSumeryShowed = true;
        this.selectedSubCategory = subCategory;
        this.getSubCatForLang();
      }
    }
  }
  onSnpClick(event, subCategory, snp) {
    this.selectedSnp = snp;
    this.isCatSumeryShowed = false;
    this.getSnpForLang();
  }
  onSubCategorySummeryClick(event, subCategory) {
    this.selectedSnp = null;
    this.isCatSumeryShowed = true;
    var a = this.selectedSubCategory;
  }
  editSubCatProperty(subCatResult, type) {
    //console.log(subCatResult, type);
    if (userHasPermisions(['Algorithm_Update'], this.regularUser)) {
      const dialogRef = this.dialog.open(TextEditorDialogComponent, {
        width: '1400px',
        height: '800px',
        data: {
          subCatResult: subCatResult,
          type: type,
          dir: this.isRtl(),
          langName: this.langName(),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //this.getMenuForLang();
        console.log('The dialog was closed');
      });
    }
  }
  canEdit() {
    return userHasPermisions(['Algorithm_Update'], this.regularUser);
  }
  editSelectedSubCatName() {
    this.editSelectedSubCatNameMode = true;
  }
  saveSelectedSubCatName() {
    this.subCategoriesService
      .changeSubCatName(
        this.selectedSubCategory.subCategoryId,
        this.selectedSubCategory.name
      )
      .subscribe((res) => {
        this.editSelectedSubCatNameMode = false;
      });
  }
  changeEditCatSumInx(inx) {
    this.editCatSumInx = inx;
  }
  /*
  editSnpResult(inx)
  {
    this.editSnpResultInx=inx;
  }*/
  editSnpDesc(inx) {
    if (this.canEdit()) {
      this.editSnpResultInx = inx;
    }
  }
  saveSnpDesc(inx) {
    // var a=  this.selectedSnp.snpResults[0].description
    this.snpResultsService
      .putLangMin(
        this.snpForLang.snpResultLangs[inx],
        this.snpForLang.snpNumber
      )
      .subscribe((x) => {
        this.editSnpResultInx = -1;
      });
  }

  togglePanels(index: number) {
    this.indexExpanded = index == this.indexExpanded ? -1 : index;
  }
  onSearchRs(snpNumber: number) {
    this.categoriesForEditService
      .searchSnpNumber(snpNumber)
      .subscribe((data) => {
        //console.log(data);
        var subCat = null;
        this.categories.forEach((cat) => {
          subCat = cat.subCategoryLangs.find(
            (sub) => sub.subCategoryId == data.subCategoryId
          );
          this.subCatForLang = null;
          this.snpForLang = null;
          if (subCat) {
            this.selectedCategory = cat;
            this.togglePanels(this.selectedCategory.categoryId);
            this.isSideNavOpened = true;
            this.isCatSumeryShowed = false;
            this.selectedSubCategory = subCat;
            this.selectedSnp = data.snpNumber;
            this.getSubCatForLang();
          }
        });
      });
  }
  saveMinMaxChanges(inx) {
    var subCatResultId =
      this.subCatForLang.subCategoryResultLangs[inx].subCategoryResultId;
    var min = this.subCatForLang.subCategoryResultLangs[inx].minValue;
    var max = this.subCatForLang.subCategoryResultLangs[inx].maxValue;
    this.subCategoryResultsService
      .changeMinMax(subCatResultId, this.selectedVersionId, min, max)
      .subscribe((data) => {});
    // this.subCategoryResultsService.()
  }
  isThreeCatResults() {
    var a =
      this.subCatForLang &&
      this.subCatForLang.subCategoryResultLangs.length == 3;
    return (
      this.subCatForLang &&
      this.subCatForLang.subCategoryResultLangs.length == 3
    );
  }
  setSubCatNumResults(num) {
    this.subCategoryResultsService
      .setSubCatNumResults(this.selectedSubCategory.subCategoryId, num)
      .subscribe((res) => {
        this.getSubCatForLang();
      });
  }
  addNewCategory() {
    this.categoriesForEditService
      .addCategory(this.selectedVersionId)
      .subscribe((res) => {
        this.getMenuForLang();
      });
  }
  addNewRs() {}
  openFileManager(subCategoryResultId) {
    this.selectedSubCategoryResultId = subCategoryResultId;
    this.popupHandlerService.openWebImagesFilesManagerDialog({
      typeId: FileUploadType.SubCatResultImages,
    });

    this.popupHandlerService.filesManagerSubject
      .pipe(take(1))
      .subscribe(({ event, imgId }) => {
        if (event === 'add') {
          this.subCategoryResultsService
            .setWebImageId(this.selectedSubCategoryResultId, imgId)
            .subscribe((res) => {
              this.getSubCatForLang();
            });
        }
      });
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  editSnp() {}
  duplicateSnp() {}
  deleteSnpFromVersion(snp) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.ans == 'yes') {
          this.snpsService
            .deleteSnpFromVersion(
              snp,
              this.selectedVersionId,
              this.selectedSubCategory.subCategoryId
            )
            .subscribe((res) => {
              this.getSubCatForLang();
            });
        }
      });
  }
  saveSubCatName(subCategoryLang: SubCategoryLang) {
    this.categoriesForEditService
      .changeSubCatName(subCategoryLang)
      .subscribe((data) => {
        this.closeEditSubCatName();
      });
  }
  openEditSubCatName(subCategoryLang: SubCategoryLang) {
    setTimeout(() => {
      this.subCategoryPanelWidth = this.innerPanel.nativeElement.clientWidth;
    }, 200);
    this.subCatEditMode = true;
    this.subCatIdToEdit = subCategoryLang.subCategoryId;
  }
  closeEditSubCatName() {
    setTimeout(() => {
      this.subCategoryPanelWidth = this.innerPanel.nativeElement.clientWidth;
    }, 200);
    this.subCatEditMode = false;
    this.subCatIdToEdit = null;
  }

  duplicateSubCat() {}
  deleteSubCat(subCategoryLang: SubCategoryLang) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.ans == 'yes') {
          this.categoriesForEditService
            .deleteSubCat(subCategoryLang.subCategoryId, this.selectedVersionId)
            .subscribe((data) => {
              this.getMenuForLang();
              if (
                this.selectedSubCategory.subCategoryId ==
                subCategoryLang.subCategoryId
              ) {
                this.selectedSubCategory = null;
                this.isSideNavOpened = false;
              }
            });
        }
      });
  }
  addNewSnp() {
    if (!this.selectedRsNum) {
      this.selectedRsNumError = true;
      return;
    }
    this.snpsService
      .addSnpToVersion(
        +this.selectedRsNum,
        this.selectedSubCategory.subCategoryId,
        this.selectedVersionId
      )
      .subscribe((res) => {
        this.getSubCatForLang();
        this.selectedRsNumError = false;
      });
  }

  addSubCat(categoryId) {
    this.categoriesForEditService
      .addSubCategory(categoryId, this.selectedVersionId)
      .subscribe((res) => {
        //console.log('arrived 2!!!!!!!');
        this.getMenuForLang();
        //this.
      });
  }

  onselectedVersionChanged(event) {
    //console.log(this.selectedVersionId);
    this.isSideNavOpened = false;
    this.selectedSubCategory = null;
    this.getSnpsForAutoComplete();
    this.getMenuForLang();
  }

  addSubCatToVersion(subCatToAdd, index: number) {
    if (!subCatToAdd) {
      this.subCatToAddError = true;
      return;
    }

    this.categoriesForEditService
      .addSubCatToVersion(+subCatToAdd, this.selectedVersionId)
      .subscribe((data) => {
        this.getMenuForLang();
        this.subCatToAddError = false;
      });
  }
  saveSnpLang() {
    var a = this.snpForLang;
    this.categoriesForEditService
      .SaveSnpLangMin(this.snpForLang)
      .subscribe((res) => {
        this.getSnpForLang();
      });
    /*
    this.snpResultsService
      .putLangMin(this.snpForLang.snpResultLangs[inx])
      .subscribe((x) => {
        this.editSnpResultInx = -1;
      });
      */
  }
  saveSnpResultLang(inx) {
    /* if (!this.snpForLang.snpResultLangs[inx]) {
      var res = new SnpResultLangMin();
      res.description = '';
      res.result = '';
      res.value = null;
      res.order = inx + 1;
      //res.snpResultLangId = ;
      this.snpForLang.snpResultLangs[inx] = res;
    } */
    this.snpResultsService
      .putLangMin(
        this.snpForLang.snpResultLangs[inx],
        this.snpForLang.snpNumber
      )
      .subscribe((x) => {});
  }
}
