<!-- cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle -->
<div class="call-income" *ngIf="call && currentUser">
  <div class="call-income__title">
    <mat-icon class="phone">call</mat-icon>
    <span style="margin-left: 2rem" *ngIf="!isMinimize"> Income Call</span>
  </div>
  <div class="call-income__item">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <span
          [matTooltip]="isMinimize ? clientType() : ''"
          class="client-type {{ clientType() }}"
        ></span>
      </div>
      Client type:
    </div>
    <div class="call-income__item__value">{{ clientType() }}</div>
  </div>
  <div class="call-income__item" *ngIf="!isProduct() && call.AgentName">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <mat-icon
          [matTooltip]="isMinimize ? call.AgentName : ''"
          style="color: #1b3f4f; font-size: 2rem; cursor: default"
          >support_agent</mat-icon
        >
      </div>
      Agent:
    </div>
    <div class="call-income__item__value">{{ call.AgentName }}</div>
  </div>
  <div class="call-income__item">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <img
          [matTooltip]="isMinimize ? call.FullName : ''"
          src="../../../assets/paycall/person.svg"
          alt=""
        />
      </div>
      Name:
      <div
        class="multiple"
        matTooltip="There are several leads/clients who hold this phone number"
        *ngIf="callsArray.Documents.length > 1"
      >
        +{{ callsArray.Documents.length }}
      </div>
    </div>
    <div class="call-income__item__value">
      <ng-container *ngIf="callsArray.Documents.length == 1">
        <ng-container *ngIf="!call.IsLinked">
          {{ call.FullName }}
        </ng-container>
        <a target="_blank" *ngIf="call.IsLinked" [href]="call.LinkUrl">{{
          call.FullName
        }}</a>
      </ng-container>
      <div
        *ngIf="callsArray.Documents.length > 1"
        class="call-income__item__value__link"
      >
        <ng-container *ngIf="!call.IsLinked">
          {{ call.FullName }}
        </ng-container>
        <a target="_blank" *ngIf="call.IsLinked" [href]="call.LinkUrl">{{
          call.FullName
        }}</a>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button
            *ngFor="let item of callsArray.Documents"
            (click)="onCallChange(item.IDNumber)"
            mat-menu-item
          >
            {{ item.FullName }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="call-income__item">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <img
          [matTooltip]="isMinimize ? call.CallerNumber : ''"
          src="../../../assets/paycall/phone.svg"
          alt=""
        />
      </div>

      Phone number:
    </div>
    <div class="call-income__item__value">{{ call.CallerNumber }}</div>
  </div>
  <div class="call-income__item" *ngIf="call.Email">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <img
          [matTooltip]="isMinimize ? call.Email : ''"
          src="../../../assets/paycall/mail.svg"
          alt=""
        />
      </div>
      Email:
    </div>
    <div class="call-income__item__value">{{ call.Email }}</div>
  </div>

  <div class="call-income__item" *ngIf="call.Status">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <img
          [matTooltip]="isMinimize ? call.Status : ''"
          src="../../../assets/paycall/status.svg"
          alt=""
        />
      </div>
      Status:
    </div>
    <div class="call-income__item__value">{{ call.Status }}</div>
  </div>
  <ng-container *ngIf="isProduct()">
    <div class="call-income__item">
      <div class="call-income__item__label">
        <div class="call-income__item__label__icon">
          <img
            [matTooltip]="isMinimize ? call.IDNumber : ''"
            src="../../../assets/paycall/id-number.svg"
            alt=""
          />
        </div>
        ID number:
      </div>
      <div class="call-income__item__value">
        {{ call.IDNumber }}
      </div>
    </div>
    <div class="call-income__item">
      <div class="call-income__item__label">
        <div class="call-income__item__label__icon">
          <img
            [matTooltip]="isMinimize ? call.LastConversationDate : ''"
            src="../../../assets/paycall/last-call-date.svg"
            alt=""
          />
        </div>
        Last call date:
      </div>
      <div class="call-income__item__value">
        {{ call.LastConversationDate }}
      </div>
    </div>
    <div class="call-income__item">
      <div class="call-income__item__label">
        <div class="call-income__item__label__icon">
          <img
            [matTooltip]="isMinimize ? call.LastConversationType : ''"
            src="../../../assets/paycall/last-call-type.svg"
            alt=""
          />
        </div>
        Last call type:
      </div>
      <div class="call-income__item__value">
        {{ call.LastConversationType }}
      </div>
    </div>
    <div class="call-income__item">
      <div class="call-income__item__label">
        <div class="call-income__item__label__icon">
          <img
            [matTooltip]="isMinimize ? call.OnFollowing : ''"
            src="../../../assets/paycall/follow.svg"
            alt=""
          />
        </div>
        On following:
      </div>
      <div class="call-income__item__value">
        {{ call.OnFollowing ? "Yes" : "No" }}
      </div>
    </div>
    <div class="call-income__item">
      <div class="call-income__item__label">
        <div class="call-income__item__label__icon">
          <img
            [matTooltip]="isMinimize ? call.FirstNutritionistConversation : ''"
            src="../../../assets/paycall/first-call.svg"
            alt=""
          />
        </div>
        First Call with nutritionist:
      </div>
      <div class="call-income__item__value">
        {{ call.FirstNutritionistConversation ? "Yes" : "No" }}
      </div>
    </div>
  </ng-container>
  <div class="call-income__item" *ngIf="call.Note">
    <div class="call-income__item__label">
      <div class="call-income__item__label__icon">
        <img
          [matTooltip]="isMinimize ? call.Note.Title : ''"
          src="../../../assets/paycall/note.svg"
          alt=""
        />
      </div>
      Last Note:
    </div>
    <div class="call-income__item__value">{{ call.Note.Title }}</div>
  </div>
  <!-- <button (click)="close()">close</button> -->
  <div class="btn-toggle">
    <button
      [ngStyle]="{ 'margin-left': isMinimize ? 'auto' : '' }"
      mat-icon-button
      (click)="minimizeToggle()"
    >
      <mat-icon>{{ !isMinimize ? "chevron_left" : "chevron_right" }}</mat-icon>
    </button>
  </div>
</div>
