import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BarcodeStorageService } from 'src/app/Services/barcode-storage.service';

@Component({
  selector: 'app-add-barcode',
  templateUrl: './add-barcode.component.html',
  styleUrls: ['./add-barcode.component.scss'],
})
export class AddBarcodeComponent implements OnInit {
  addMode: 'range' | 'list';
  rangeFrom: number;
  rangeTo: number;
  range: number = 0;
  barcodeList: string[] = [];
  manuallBarcode: string;
  constructor(
    private _matSnackBar: MatSnackBar,
    private barcodeStorageService: BarcodeStorageService,
    public dialogRef: MatDialogRef<AddBarcodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  addByOption(option: 'range' | 'list') {
    this.addMode = option;
  }

  onRangeChange() {
    if (!this.rangeFrom || !this.rangeTo) return;
    if (this.rangeTo >= this.rangeFrom) {
      this.range = this.rangeTo - this.rangeFrom + 1;
    } else {
      this.range = 0;
    }
  }

  addToList() {
    if (this.manuallBarcode == null || this.manuallBarcode == '') return;
    this.barcodeList.push(this.manuallBarcode);
    this.manuallBarcode = '';
  }
  removeFromList(index: number) {
    this.barcodeList.splice(index, 1);
  }
  generateRange() {
    this.barcodeStorageService
      .insertBarcodesByRange(this.rangeFrom, this.rangeTo)
      .subscribe((data) => {
        if (data.isSuccess) {
          this.dialogRef.close(true);
          this._matSnackBar
            .open('Barcodes added successfully', 'Close')
            ._dismissAfter(3000);
        } else {
          this._matSnackBar.open(data.message, 'Close');
        }
      });
  }
  generateList() {
    this.barcodeStorageService
      .insertBarcodesByList(this.barcodeList)
      .subscribe((data) => {
        if (data.isSuccess) {
          this.dialogRef.close(true);
          this._matSnackBar
            .open('Barcodes added successfully', 'Close')
            ._dismissAfter(3000);
        } else {
          this._matSnackBar.open(data.message, 'Close');
        }
      });
  }
}
