import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'src/app/data/Language';
import { Meal } from 'src/app/data/Meal';
import { Menu } from 'src/app/data/Menu';
import { MenuForTable } from 'src/app/data/MenuForTable';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';
import { LanguagesService } from 'src/app/Services/languages.service';
import { NutriMenuService } from 'src/app/Services/nutri-menu.service';
import { ProductsService } from 'src/app/Services/products.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-nutrition-menu',
  templateUrl: './nutrition-menu.component.html',
  styleUrls: ['./nutrition-menu.component.scss'],
})
export class NutritionMenuComponent implements OnInit {
  productId: string;
  editMode: boolean = false;
  meal: Meal;
  createNewMenu: boolean;
  editModeBullets: boolean;
  languages: Language[];
  mealChanged: boolean = false;
  menuList: MenuForTable[];
  isLoading: boolean = false;
  mealId: number;
  menuId: number;
  product: Product;
  mealTypes: Status[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private nutriMenuService: NutriMenuService,
    private languagesService: LanguagesService,
    private productsService: ProductsService,
    private statusesService: StatusesService
  ) {}

  ngOnInit(): void {
    this.getLanguages();
    this.getMealTypes();
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.productId = params['productId'];
        this.getMenuList();
        this.getProduct();
      }
    });
  }

  getMealTypes() {
    this.statusesService.getAll().subscribe((data) => {
      this.mealTypes = data.filter((x) => x.category === 'MealType');
    });
  }

  getProduct() {
    this.productsService.getById(+this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
      } else {
        this.router.navigate(['home']);
      }
    });
  }

  goBack() {
    this.router.navigate(['tests/edit'], {
      queryParams: { productId: this.productId },
    });
  }

  getMenuList() {
    this.isLoading = true;
    this.nutriMenuService
      .getMenusByProductId(+this.productId)
      .subscribe((data) => {
        this.menuList = data;
        this.isLoading = false;
      });
  }

  getWeight(isGoal = false) {
    const array = this.product.patient.patientWeightHistories?.filter(
      (x) => x.isGoal == isGoal
    );
    if (array?.length > 0) {
      return array.reduce((a, b) => {
        return new Date(a?.dateUpdated) > new Date(b?.dateUpdated) ? a : b;
      })?.value;
    }
    return null;
  }

  canEditMenu() {
    const { regularUser } = this.product.patient;

    const filledDetails =
      regularUser.birthDate && regularUser.gender && regularUser.height;
    const signConcent = this.product.patient.isSignedConcent;
    const weight = this.getWeight(true) && this.getWeight();

    return weight && filledDetails && signConcent;
  }

  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  hasMealChenged(event: boolean) {
    this.mealChanged = event;
  }

  getMenuForEdit(menuId: number) {
    this.createNewMenu = false;
    this.menuId = menuId;
  }

  onCreateNewMenu() {
    this.editMode = true;
    this.createNewMenu = true;
    this.editModeBullets = false;
  }

  backToMenus() {
    this.meal = null;
    this.editMode = false;
    this.editModeBullets = false;
    this.getMenuList();
  }

  editModeBulletsToggle(event: boolean) {
    this.editModeBullets = event;
  }

  editMeal(mealId: number) {
    this.mealId = mealId;
  }
}
