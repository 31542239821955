<mat-card class="zameret" *ngIf="zameretData">
  <mat-list class="zameret__file-list">
    <mat-list-item *ngFor="let file of fileTypes">
      <div class="zameret__file-list__item">
        <div class="zameret__file-list__item__name">{{ file.name }}:</div>
        <div class="zameret__file-list__item__upload">
          <app-upload-results-csv
            [accept]="'.xls,.xlsx,.csv'"
            [title]="'Upload'"
            [uploadPath]="getFileUploadPath(file.externalFoodFileType)"
            (onUploadFinished)="uploadFileFinished($event)"
          ></app-upload-results-csv>
        </div>
      </div>
    </mat-list-item>
  </mat-list>

  <table style="width: 100%" mat-table [dataSource]="zameretList">
    <ng-container matColumnDef="columnName">
      <th mat-header-cell *matHeaderCellDef>Column name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="nutrientName">
      <th mat-header-cell *matHeaderCellDef>Nutrient name</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>nutrients</mat-label>
          <mat-select [(ngModel)]="element.nutrientId">
            <mat-option
              *ngFor="let item of nutrients"
              [value]="item.foodNutrientId"
            >
              {{ item.foodNutrientLangs[0].name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="nutrientType">
      <th mat-header-cell *matHeaderCellDef>Nutrient type</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>nutrient types</mat-label>
          <mat-select [(ngModel)]="element.nutrientType">
            <mat-option
              *ngFor="let item of nutrientTypes"
              [value]="item.statusId"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="isNutrient">
      <th mat-header-cell *matHeaderCellDef>Is nutrient</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [(ngModel)]="element.isNutrient"
          [checked]="element.isNutrient == 1"
        ></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div style="margin-top: 2rem">
    <button mat-raised-button (click)="submit()">Submit</button>
  </div>

  <!-- <div class="zameret__mapping">
    <mat-form-field appearance="fill">
      <mat-label>column</mat-label>
      <mat-select>
        <mat-option *ngFor="let item of zameretList" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>nutrients</mat-label>
      <mat-select>
        <mat-option
          *ngFor="let item of nutrients"
          [value]="item.foodNutrientId"
        >
          {{ item.foodNutrientLangs[0].name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>nutrient types</mat-label>
      <mat-select>
        <mat-option *ngFor="let item of nutrientTypes" [value]="item.statusId">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox> is nutrient </mat-checkbox>
  </div> -->
</mat-card>
