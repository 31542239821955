import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SmsDetails } from '../data/SmsDetails';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SendSmsService {
  constructor(private http: HttpClient) {}
  sendSms(smsDetails: SmsDetails) {
    return this.http.post<any>(`${environment.baseUrl}SendSms/`, smsDetails);
  }
}
