import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AppModule } from 'src/app/data/AppModule';
import { EmailTemplateMin } from 'src/app/data/EmailTemplateMin';
import { ModuleAction } from 'src/app/data/ModuleAction';
import { ModuleToAction } from 'src/app/data/ModuleToAction';
import { PermissionRule } from 'src/app/data/PermissionRule';
import { Status } from 'src/app/data/Status';
import { TriggerEventObjectType } from 'src/app/data/TriggerEventObjectType';
import { UserRoleMin } from 'src/app/data/user-roles/UserRoleMin';
import { UserRole } from 'src/app/data/UserRole';
import { AppModulesService } from 'src/app/Services/app-modules.service';
import { ModuleToActionsService } from 'src/app/Services/module-to-actions.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { UserPermissionsService } from 'src/app/Services/user-permissions.service';
import { UserRolesService } from 'src/app/Services/user-roles.service';

@Component({
  selector: 'app-role-managment',
  templateUrl: './role-managment.component.html',
  styleUrls: ['./role-managment.component.scss'],
})
export class RoleManagmentComponent implements OnInit {
  usingConventions = false;
  newAppModuleId: number;
  newModuleActionId: number;
  newEventName: string;
  eventTypes: Status[];
  statuses: Status[];
  actionTypes: Status[];
  emailTemplates: EmailTemplateMin[];
  productTypes: Status[];
  triggerEventObjectTypes: TriggerEventObjectType[];
  moduleToActions: ModuleToAction[];

  //userRoles
  userRoles: UserRoleMin[];

  showedActions: ModuleAction[];
  showedRules: PermissionRule[];
  selectedRoleId: number;
  selectedModuleId: number;
  selectedActionId: number;
  allAppModules: AppModule[];
  userRolesLoading: boolean;
  appModulesLoading: boolean;
  showedActionsLoading: boolean;
  showedRulesLoading: boolean;

  constructor(
    private appModulesService: AppModulesService,
    private moduleToActionsService: ModuleToActionsService,
    private popupHandlerService: PopupHandlerService,
    private userRolesService: UserRolesService,
    private userPermissionsService: UserPermissionsService
  ) {}
  eventsDisplayedColumns: string[] = [
    'AppModuleId',
    'ModuleActionId',
    'Delete',
  ];
  appModules: AppModule[];
  moduleActions: ModuleAction[];
  ngOnInit(): void {
    //userRoles

    this.getAllUserRoles();
    //ModuleToAction
    this.getAllAppModules();
    //this.getAllModuleActions();
    this.getAllModuleToActions();
  }

  //UserRoles Service
  getAllUserRoles() {
    this.userRolesLoading = true;
    this.userRolesService.getAll().subscribe((data) => {
      this.userRoles = data;
      this.userRoles = this.orderByName(this.userRoles, 'userRoleName');
      this.userRolesLoading = false;
    });
  }
  orderByName(data: any[], prop: string) {
    return data.sort((a, b) => {
      const nameA = a[prop].toUpperCase(); // ignore upper and lowercase;
      const nameB = b[prop].toUpperCase(); // ignore upper and lowercase;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  getActionsForRoleAndModule() {
    this.showedActionsLoading = true;
    this.userPermissionsService
      .forRoleAndModule(this.selectedRoleId, this.selectedModuleId)
      .subscribe((data) => {
        this.showedActions = data;
        this.showedActionsLoading = false;
      });
  }
  getExistingModulesForRole(loadAlsoActions: boolean = false) {
    this.showedRules = [];
    this.showedActions = [];
    this.appModulesLoading = true;
    this.appModulesService
      .getExistingModulesForRole(this.selectedRoleId)
      .subscribe((data) => {
        this.appModules = this.orderByName(data, 'appModuleName');
        this.appModulesLoading = false;
        if (loadAlsoActions) {
          this.getActionsForRoleAndModule();
        }
      });
  }
  addNewRole() {
    this.popupHandlerService.openAddNewRoleDialog();
    this.popupHandlerService.addNewRoleSubject
      .pipe(take(1))
      .subscribe(({ event, data }) => {
        if (event == 'save') {
          var userRole = new UserRole();
          userRole.userRoleName = data;
          this.userRolesService.post(userRole).subscribe((res) => {
            this.getAllUserRoles();
          });
          /* let role = {
          name: data,
          roleId: this.roles[this.roles.length - 1].roleId + 1,
        };
        this.roles.push(role); */
        }
      });
  }
  addNewModuleDisabled() {
    return this.appModules && this.appModules.filter((x) => x.isNew).length > 0;
  }
  onRoleClick(roleId: number) {
    //this.selectedRoleId = this.selectedRoleId == roleId ? null : roleId;
    this.selectedRoleId = roleId;
    this.getExistingModulesForRole();
    /*
    if (this.selectedRoleId == roleId) {
      this.selectedRoleId = null;
      this.selectedModuleId = null;
    } else {
      this.selectedRoleId = roleId;
      this.selectedModuleId = null;
      if (this.selectedRoleId) {
        this.showedModules = this.userRoles.find(
          (x) => x.userRoleId == roleId
        ).modules;
      }
    }*/
    //this.getExistingModulesForRole();
  }
  onModuleClick(module: AppModule) {
    this.selectedModuleId = module.appModuleId;
    if (module.isNew) {
      this.getActionsForRoleAndModule();
    } else {
      this.getActionsForRoleAndModule();
    }

    /*
    this.moduleToActionsService
      .forModule(this.selectedModuleId)
      .subscribe((data) => {
        this.showedActions = data;
      });
      */

    /*
    this.showedActions = this.showedModules.find(
      (x) => x.appModuleId == moduleId
    ).actions;
    */
  }
  onActionClick(actionId: number) {
    this.selectedActionId = actionId;
    this.getPermissionsForRoleAndModuleAndAction();
    /*
    this.showedRules = this.showedActions.find(
      (x) => x.moduleActionId == actionId
    );
    */
  }
  getPermissionsForRoleAndModuleAndAction() {
    this.showedRulesLoading = true;
    this.userPermissionsService
      .forRoleAndModuleAndAction(
        this.selectedRoleId,
        this.selectedModuleId,
        this.selectedActionId
      )
      .subscribe((data) => {
        this.showedRules = data;
        this.showedRulesLoading = false;
      });
  }
  updateActionForRoleAndModule(action: ModuleAction, event) {
    if (
      this.showedActions.filter((x) => x.checked).length == 1 &&
      action.checked
    ) {
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((res) => {
          if (
            res.ans == 'yes' &&
            res.caller == 'role-management-delete-last-action'
          ) {
            this.userPermissionsService
              .updateActionForRoleAndModule(
                this.selectedRoleId,
                this.selectedModuleId,
                action.moduleActionId,
                !action.checked
              )
              .subscribe((res) => {
                //this.getActionsForRoleAndModule();
                this.getExistingModulesForRole();
              });
          } else {
            action.checked = false;
            setTimeout(() => {
              action.checked = true;
            }, 10);
          }
        });

      this.popupHandlerService.openConfirmationDialog(
        'Are you sure?',
        'delete module',
        'role-management-delete-last-action'
      );
    } else {
      this.userPermissionsService
        .updateActionForRoleAndModule(
          this.selectedRoleId,
          this.selectedModuleId,
          action.moduleActionId,
          !action.checked
        )
        .subscribe((res) => {
          if (
            this.appModules.find((x) => x.appModuleId == this.selectedModuleId)
              .isNew
          ) {
            this.getExistingModulesForRole(true);
          } else {
            this.getActionsForRoleAndModule();
          }
        });
    }
  }
  updatePermissionForRoleAndModule(rule: PermissionRule, event) {
    this.userPermissionsService
      .updateRuleForRoleAndModuleAndAction(
        this.selectedRoleId,
        this.selectedModuleId,
        this.selectedActionId,
        rule.permissionRuleId,
        !rule.checked
      )
      .subscribe((res) => {
        this.getPermissionsForRoleAndModuleAndAction();
      });
  }

  onPermissionClick(permissionId: number) {
    console.log(permissionId);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  //ModuleToAction
  getAllModuleToActions() {
    this.moduleToActionsService.getAll().subscribe((data) => {
      this.moduleToActions = data;
    });
  }
  /*
  getExistingModulesForRole() {
    this.appModulesService
      .getExistingModulesForRole(this.selectedRoleId)
      .subscribe((data) => {
        this.appModules = data;
      });
  }*/
  /*
  getAllModuleActions() {
    this.moduleActionsService.getAll().subscribe((data) => {
      this.moduleActions = data;
    });
  }
*/
  getAllAppModules() {
    this.appModulesService.getAll().subscribe((data) => {
      this.allAppModules = data;
    });
  }
  /*
  AddModuleToAction() {
    var moduleToAction = new ModuleToAction();
    moduleToAction.appModuleId = this.newAppModuleId;
    moduleToAction.moduleActionId = this.newModuleActionId;
    this.moduleToActionsService.post(moduleToAction).subscribe((res) => {
      this.getAllModuleActions();
    });
  }
  */
  /*
  saveModuleForModuleToAction(event, moduleToAction: ModuleToAction) {
    moduleToAction.appModuleId = event.value;
    this.moduleToActionsService
      .put(moduleToAction)
      .subscribe((res) => this.getAllModuleActions());
  }
  */
  /*
  saveActionForModuleToAction(event, moduleToAction: ModuleToAction) {
    moduleToAction.moduleActionId = event.value;
    this.moduleToActionsService
      .put(moduleToAction)
      .subscribe((res) => this.getAllModuleActions());
  }
  */
  deleteModuleToAction(moduleToAction: ModuleToAction) {
    /*
  
      */
  }
  addNewModule() {
    this.popupHandlerService.openAddNewModuleDialog(this.selectedRoleId);
    this.popupHandlerService.addNewModuleSubject
      .pipe(take(1))
      .subscribe(({ event, data }) => {
        if (event == 'save') {
          var appModule: AppModule = this.allAppModules.find(
            (x) => x.appModuleId == data
          );
          appModule.isNew = true;
          this.appModules.push(appModule);
          this.selectedModuleId = appModule.appModuleId;
          this.getActionsForRoleAndModule();
        } else {
        }
      });
  }
  editModule(moduleId: number) {}
  deleteModule(moduleId: number) {
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes' && res.caller == 'role-management-delete-module') {
          this.appModulesService
            .deleteForRole(this.selectedRoleId, this.selectedModuleId)
            .subscribe((x) => {
              this.getExistingModulesForRole();
            });
        }
      });

    this.popupHandlerService.openConfirmationDialog(
      'delete module',
      'Are you sure?',
      'role-management-delete-module'
    );
  }
  isPermisionShowed() {
    return (
      this.selectedActionId &&
      this.showedActions.filter(
        (x) => x.moduleActionId == this.selectedActionId
      ).length > 0 &&
      this.showedActions.find((x) => x.moduleActionId == this.selectedActionId)
        .checked
    );
  }
}
