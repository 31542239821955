import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/data/Product';
import { SubCategoryForApp } from 'src/app/data/SubCategoryForApp';
import { TubeTestResultForApp } from 'src/app/data/TubeTestResultForApp';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { TubeTestResultsService } from 'src/app/Services/tube-test-results.service';

@Component({
  selector: 'app-dna-report-inner',
  templateUrl: './dna-report-inner.component.html',
  styleUrls: ['./dna-report-inner.component.scss'],
})
export class DnaReportInnerComponent implements OnInit {
  categoryId: number;
  productId: number;
  results: SubCategoryForApp[];
  rankResults: SubCategoryForApp[];
  // rankTwoResults: TubeTestSubCategoryResult[];
  // rankThreeResults: TubeTestSubCategoryResult[];

  barcode: string;
  tubeTestResults: TubeTestResultForApp[];
  displayedColumns: string[] = ['geneName', 'snpNumber', 'result', 'info'];
  rankInx: number = 3;
  product: Product;
  categoryName: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private catergoriesService: CatergoriesService,
    private tubeTestResultsService: TubeTestResultsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.categoryId = +this.route.snapshot.queryParamMap.get('categoryId');
    this.productId = +this.route.snapshot.queryParamMap.get('productId');

    this.catergoriesService
      .getByCategoryId(this.productId, this.categoryId)
      .subscribe((data) => {
        this.results = data.subCats;
        this.categoryName = data.categoryName;

        this.setRankResults();
      });
    this.productsService.getById(this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
        this.tubeTestResultsService
          .getByBarcodeAndCategory(this.product.barCode, this.categoryId)
          .subscribe((data) => {
            this.tubeTestResults = data;
            this.tubeTestResults.map((x) => {
              let snpResultSpl = x.snpResult.split('');
              snpResultSpl = snpResultSpl.map((y) => {
                if (y === x.redLetter) {
                  y = `<span class='red'>${y}</span>`;
                }
                return y;
              });
              x.snpResult = snpResultSpl.join('');
              return x;
            });
          });
      } else {
      }
    });
  }
  goBack() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        tabIndex: 0,
        patientId: this.product.patient.patientId,
        edit: 'right',
      },
    });
    /*
    this.router.navigate(['tests/edit/dna-report'], {
      queryParams: {
      productId: this.productId,
      },
    });*/
  }
  hideToolTip(element) {}
  showToolTip(element) {}
  changeRankTab(inx) {
    this.rankInx = inx;
    this.setRankResults();
  }
  setRankResults() {
    if (this.categoryId == -1) {
      this.rankResults = this.results;
    } else {
      this.rankResults = this.results.filter((x) => x.order == this.rankInx);
    }
  }
  colorNumbers(txt: string) {
    txt = txt.replace('<span>', '').replace('<span>', '');
    //var txt1 = unescape(txt);
    for (var i = 1; i < 20; i++) {
      if (txt.indexOf('. ')) {
        var t1 = txt.split(i + '. ');
      }
      if (txt.indexOf('.&nbsp;')) {
        var t2 = txt.split(i + '.&nbsp;');
      }

      if (
        t1.length > 1 &&
        (t1[0].indexOf('<div>') || t1[0].indexOf('<span>'))
      ) {
        txt = `${t1[0]} <span class="color-number"> ${i}.</span> ${t1[1]}`;
      }
      if (
        t2.length > 1 &&
        (t2[0].indexOf('<div>') || t2[0].indexOf('<span>'))
      ) {
        txt = `${t2[0]} <span class="color-number"> ${i}.</span> ${t2[1]}`;
      }

      if (t1.length > 2) {
        for (var j = 2; j < t1.length; j++) {
          txt += t1[j];
        }
      }
      /* if (t1.length > 2) {
        for (var j = 2; j < t1.length; j++) {
          txt += t1[j];
        }
      } */
      if (t2.length > 2) {
        for (var j = 2; j < t1.length; j++) {
          txt += t2[j];
        }
      }
    }
    return txt;
  }

  stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
