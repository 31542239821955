<div>
  <!--[formGroup]="taskForm" -->
  <form [formGroup]="callForm" (ngSubmit)="saveCall()" class="example-form">
    {{ getNow() | date: "dd-MM-yyyy HH:mm" }}
    <div style="display: flex">
      <!---
      <mat-form-field class="example-full-width">
        <input
          formControlName="title"
          class="edit-field"
          name="title"
          matInput
          placeholder="Headline"
        />

        <mat-error> Please Fill Headline </mat-error>
      </mat-form-field>
    -->

      <mat-form-field appearance="fill">
        <mat-label>Subject</mat-label>
        <mat-select formControlName="subject">
          <mat-option
            *ngFor="let subject of subjects"
            [value]="subject.statusId"
          >
            {{ subject.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      &nbsp;&nbsp;&nbsp;
    </div>
    <br />
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Massage</mat-label>
      <textarea
        matInput
        placeholder="Message"
        name="title"
        formControlName="title"
      ></textarea>
    </mat-form-field>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      "
    >
      <button
        class="btn-submit btn-submit--lead"
        style="margin-right: 20px"
        type="submit"
      >
        Submit
      </button>

      <button
        style="margin-right: 20px; padding: 0 0.5rem"
        *ngIf="isFollowUp"
        class="btn-submit"
        type="button"
        (click)="onNotAnswerCall()"
      >
        Not Answer
      </button>

      <button class="btn-submit" type="button" (click)="onCancelNewCall()">
        Cancel
      </button>
    </div>
  </form>
</div>
