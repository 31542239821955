import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { combineAll, map } from 'rxjs/operators';
import { ErrorMessage } from '../data/ErrorMessage';
import { getPermissionsArr, RegularUser } from '../data/RegularUser';
import { ProductsService } from '../Services/products.service';
import { RegularUsersService } from '../Services/regular-users.service';
import { intersect } from '../Utils/general-helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  isInited = false;
  constructor(
    private router: Router,
    private authenticationService: RegularUsersService
  ) {
    authenticationService.currentUser.subscribe((user) => {
      if (!user) this.router.navigate(['/login']);
    });
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (!user || !user.userRole) {
      this.authenticationService.logout();
    }
    this.authenticationService.validate(user).subscribe(
      (user) => {
        if ((user as RegularUser).firstName) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        } else {
          const err = user as ErrorMessage;
          if (err.message == 'Reset Password') {
            const obj = JSON.parse(err.innerExeption);
            setTimeout(() => {
              this.router.navigate(['resetPassword'], {
                queryParams: { email: obj.email, token: obj.token },
              });
            }, 100);
          } else {
            this.router.navigate(['login']);
          }
        }
      },
      (err) => {
        this.authenticationService.logout();
      }
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var currentUser: RegularUser = this.authenticationService.currentUserValue;
    var currentLoginUser: RegularUser =
      this.authenticationService.currentLoginUserValue;

    if (currentUser) {
      const permissionsArr = getPermissionsArr(currentUser);
      if (!currentLoginUser) {
        return this.authenticationService.validate(currentUser).pipe(
          map((data) => {
            if ((data as RegularUser).regularUserId) {
              if (route.data.permissions) {
                if (
                  intersect(route.data.permissions, permissionsArr).length > 0
                ) {
                  return true;
                } else {
                  this.router.navigate(['home']);
                  return false;
                }
              } else return true;
            } else {
              this.router.navigate(['/login'], {
                queryParams: { returnUrl: state.url },
              });
            }
          })
        );
      } else {
        if (route.data.permissions) {
          if (intersect(route.data.permissions, permissionsArr).length > 0) {
            return true;
          } else {
            this.router.navigate(['home']);
            return false;
          }
        } else return true;
      }
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
  responseFromStorageValidate() {}
  /*
    if (currentUser) {
      // logged in so return true
    }

    // not logged in so redirect to login page with the return url
  
  }
  */
}
