import { AlgoBulletImage } from './AlgoBulletImage';
import { AlgoBulletLink } from './AlgoBulletLink';
import { BulletAlgoCrossing } from './BulletAlgoCrossing';
import { BulletBloodCrossing } from './BulletBloodCrossing';
import { Category } from './Category';

export class AlgoBullet {
  algoBulletId: number;
  title: string;
  description: string;
  categoryId: number;
  category: Category;
  bulletBloodCrossings: BulletBloodCrossing[];
  bulletAlgoCrossings: BulletAlgoCrossing[];
  algoBulletLinkId: number | null;
  algoBulletLink: AlgoBulletLink;
  algoBulletImageId: number | null;
  algoBulletImage: AlgoBulletImage;
  linkViewText: string;
  order: number;
}
