<!-- <table
  mat-table
  [dataSource]="moduleToActions"
  class="mat-elevation-z8"
  style="width: 600px"
>
  <ng-container matColumnDef="AppModuleId">
    <th style="width: 250px" mat-header-cell *matHeaderCellDef>AppModule</th>
    <td
      style="width: 250px"
      mat-cell
      *matCellDef="let moduleToAction"
      style="vertical-align: top"
    >
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: darkgray; width: 180px"
            (click)="stopPropegation($event)"
            class="lead-status-select"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              saveModuleForModuleToAction($event, moduleToAction)
            "
            [(ngModel)]="moduleToAction.appModuleId"
          >
            <mat-option
              *ngFor="let appModule of appModules"
              [value]="appModule.appModuleId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ appModule.appModuleName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="ModuleActionId">
    <th style="width: 200px" mat-header-cell *matHeaderCellDef>ModuleAction</th>
    <td
      style="width: 250px"
      mat-cell
      *matCellDef="let moduleToAction"
      style="vertical-align: top"
    >
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            (click)="stopPropegation($event)"
            class="lead-status-select"
            matNativeControl
            disableOptionCentering
            [(ngModel)]="moduleToAction.moduleActionId"
          >
            <mat-option
              *ngFor="let moduleAction of moduleActions"
              [value]="moduleAction.moduleActionId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ moduleAction.moduleActionName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="Delete">
    <th style="width: 50px" mat-header-cell *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let moduleToAction"
      (click)="deleteModuleToAction(moduleToAction)"
      style="vertical-align: top; height: 50px; padding-top: 20px"
    >
      <span class="material-icons" style="cursor: pointer"> delete </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="eventsDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: eventsDisplayedColumns"></tr>
</table>
<mat-form-field class="example-full-width round-select" style="width: 200px">
  <mat-select
    style="background-color: darkgray; width: 180px"
    (click)="stopPropegation($event)"
    class="lead-status-select"
    matNativeControl
    disableOptionCentering
    [(ngModel)]="newAppModuleId"
  >
    <mat-option
      *ngFor="let appModule of appModules"
      [value]="appModule.appModuleId"
      class="trigger-status"
      style="text-align: center"
    >
      {{ appModule.appModuleName }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="example-full-width round-select" style="width: 200px">
  <mat-select
    style="background-color: darkgray; width: 180px"
    (click)="stopPropegation($event)"
    class="lead-status-select"
    matNativeControl
    disableOptionCentering
    [(ngModel)]="newModuleActionId"
  >
    <mat-option
      *ngFor="let moduleAction of moduleActions"
      [value]="moduleAction.moduleActionId"
      class="trigger-status"
      style="text-align: center"
    >
      {{ moduleAction.moduleActionName }}
    </mat-option>
  </mat-select>
</mat-form-field>

<button (click)="AddModuleToAction()">Add</button>
<div style="height: 300px"></div> -->
<div class="manage-roles-wrapper">
  <mat-card class="manage-roles">
    <mat-card class="manage-roles__roles">
      <div class="manage-roles__roles__title">Roles</div>
      <perfect-scrollbar
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
      >
        <div
          class="manage-roles__roles__item"
          *ngFor="let role of userRoles"
          (click)="onRoleClick(role.userRoleId)"
          matRipple
          [class.active]="selectedRoleId == role.userRoleId"
        >
          <div>{{ role.userRoleName }}</div>
          <mat-icon *ngIf="selectedRoleId == role.userRoleId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </perfect-scrollbar>
      <div class="add-new">
        <button (click)="addNewRole()" mat-button>Add new role</button>
      </div>
    </mat-card>
    <div class="manage-roles__modules">
      <div class="manage-roles__modules__title">Modules</div>
      <perfect-scrollbar
        style="height: calc(100% - 60px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
      >
        <div
          class="manage-roles__modules__wrapper"
          *ngFor="let module of appModules"
        >
          <button
            class="manage-roles__modules__wrapper__more"
            mat-icon-button
            *ngIf="selectedModuleId == module.appModuleId"
            [matMenuTriggerFor]="moreMenu"
          >
            <mat-icon (click)="editModule(module.appModuleId)"
              >more_vert</mat-icon
            >
          </button>

          <mat-menu #moreMenu="matMenu">
            <button
              (click)="editModule(module.appModuleId)"
              mat-menu-item
              style="display: flex; justify-content: space-between"
            >
              <span> Edit </span>
              <span>
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  style="margin-right: 0"
                  >mode_edit</mat-icon
                >
              </span>
            </button>
            <button
              mat-menu-item
              (click)="deleteModule(module.appModuleId)"
              style="display: flex; justify-content: space-between"
            >
              <span> Delete </span>
              <span>
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  style="margin-right: 0"
                  >delete</mat-icon
                >
              </span>
            </button>
          </mat-menu>
          <div
            class="manage-roles__modules__item"
            (click)="onModuleClick(module.appModuleId)"
            matRipple
            [class.active]="selectedModuleId == module.appModuleId"
          >
            {{ module.appModuleName }}
            <mat-icon
              class="manage-roles__modules__item__right-icon"
              *ngIf="selectedModuleId == module.appModuleId"
              >keyboard_arrow_right</mat-icon
            >
          </div>
        </div>
      </perfect-scrollbar>
      <div class="add-new" *ngIf="selectedRoleId">
        <button (click)="addNewModule()" mat-button>Add module</button>
      </div>
    </div>
    <mat-card class="manage-roles__actions" *ngIf="selectedModuleId">
      <div class="manage-roles__actions__title">Actions</div>
      <perfect-scrollbar
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
      >
        <div
          class="manage-roles__actions__item"
          *ngFor="let action of showedActions"
          (click)="onActionClick(action.moduleActionId)"
          matRipple
          [class.active]="selectedActionId == action.moduleActionId"
        >
          <mat-checkbox
            [checked]="action.checked == true"
            (click)="stopPropagation($event)"
          ></mat-checkbox>
          {{ action.name }}
          <mat-icon *ngIf="selectedActionId == action.moduleActionId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </perfect-scrollbar>
    </mat-card>
    <div class="manage-roles__permissions" *ngIf="selectedActionId">
      <div class="manage-roles__permissions__title">Permissions</div>
      <perfect-scrollbar
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
      >
        <div
          class="manage-roles__permissions__item"
          *ngFor="let rule of showedRules"
          (click)="onPermissionClick(rule.permissionRuleId)"
          matRipple
        >
          <mat-checkbox>{{ rule.permissionRuleName }}</mat-checkbox>
        </div>
      </perfect-scrollbar>
    </div>
  </mat-card>
</div>
