import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArticleCategory } from '../data/ArticleCategory';
import { HeadFilter } from '../data/HeadFilter';

@Injectable({
  providedIn: 'root',
})
export class ArticleCategoriesService {
 
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<ArticleCategory[]>(
      `${environment.baseUrl}ArticleCategories/`
    );
  }

  getById(clientId) {
    return this.http.get<ArticleCategory>(
      `${environment.baseUrl}ArticleCategories/${clientId}`
    );
  }
  put(articleCategory: ArticleCategory) {
    return this.http.put<any>(
      `${environment.baseUrl}ArticleCategories/${articleCategory.articleCategoryId}`,
      articleCategory
    );
  }
  post(articleCategory: ArticleCategory) {
    return this.http.post<any>(
      `${environment.baseUrl}ArticleCategories/`,
      articleCategory
    );
  }
  delete(articleCategory: ArticleCategory) {
    return this.http.delete(
      `${environment.baseUrl}ArticleCategories/${articleCategory.articleCategoryId}`
    );
  }
  duplicate(articleCategoryId: number) {
    return this.http.get<ArticleCategory>(
      `${environment.baseUrl}ArticleCategories/Duplicate/${articleCategoryId}`
    );
  }
}
