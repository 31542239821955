import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-categories-lingkage',
  templateUrl: './food-categories-lingkage.component.html',
  styleUrls: ['./food-categories-lingkage.component.css']
})
export class FoodCategoriesLingkageComponent implements OnInit {

  constructor( ) { }
  foods;
  displayedColumns: string[] = ['Food', 'Category'];
  ngOnInit(): void {

  }

}
