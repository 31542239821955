import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { Subscription } from 'rxjs';
//import { DynamicFormComponent } from '../dynamic-form-elements/dynamic-form/dynamic-form.component';
import { LifeStyleExercise } from '../../data/LifestyleExercise.model';
import { LifeStyleReportLocal } from '../../data/LifeStyleReportLocal';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';
import { LifeStyleReportsService } from 'src/app/Services/life-style-reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ProductsService } from 'src/app/Services/products.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';

@Component({
  selector: 'app-lifestyle-form-dynamic',
  templateUrl: './lifestyle-form-dynamic.component.html',
  styleUrls: ['./lifestyle-form-dynamic.component.scss'],
})
export class LifestyleFormDynamicComponent implements OnInit {
  lifestyle: LifeStyleReport;
  lifestyleLocal: LifeStyleReportLocal;
  lifestyleForm1: FormGroup;
  lifestyleForm2: FormGroup;
  lifestyleForm3: FormGroup;
  lifestyleForm4: FormGroup;
  lifestyleForm5: FormGroup;
  lifestyleForm6: FormGroup;
  lifestyleForm7: FormGroup;
  lifestyleForm1Error: boolean = false;
  lifestyleForm2Error: boolean = false;
  lifestyleForm3Error: boolean = false;
  lifestyleForm4Error: boolean = false;
  lifestyleForm5Error: boolean = false;
  lifestyleForm6Error: boolean = false;
  lifestyleForm7Error: boolean = false;
  patientId: number;
  product: Product;
  productStatus: Status;
  otherNutritionText: boolean = false;
  otherDigestionText: boolean = false;
  nutritionOptions: any = [
    { value: 1, name: 'רגילה' },
    { value: 2, name: 'ללא מוצרי חלב' },
    { value: 3, name: 'טבעוני' },
    { value: 4, name: 'צמחוני' },
    { value: 5, name: 'ללא גלוטן' },
    { value: 6, name: 'אחר' },
  ];
  digestionOptions: any = [
    { value: 1, name: 'צרבות' },
    { value: 2, name: 'שלשולים' },
    { value: 3, name: 'עצירות' },
    { value: 4, name: 'מעי רגיש' },
    { value: 5, name: 'נפיחות' },
    { value: 6, name: 'גזים' },
    { value: 7, name: 'אין' },
    { value: 8, name: 'אחר' },
  ];
  subscription: Subscription;
  lifeStyleDiseaseOptions: any = [
    { name: 'אין מחלה ידועה', value: '', showDiseaseName: false },
    {
      name: 'ניתוח בריאטרי',
      value: '',
      showDiseaseName: true,
      newPlaceholder: 'סוג הניתוח',
      newPlaceholder2: 'לפני כמה שנים',
    },
    { name: 'טרום סוכרת', value: '', showDiseaseName: false },
    { name: 'סוכרת סוג 1', value: '', showDiseaseName: false },
    { name: 'סוכרת סוג 2', value: '', showDiseaseName: false },
    { name: 'סוכרת הריון', value: '', showDiseaseName: false },
    { name: 'תסמונת השחלות הפוליציסטיות', value: '', showDiseaseName: false },
    { name: 'רפלוקס/צרבת', value: '', showDiseaseName: false },
    { name: 'הליקובקטור פילורי', value: '', showDiseaseName: false },
    { name: 'מחלות לב וכלי דם', value: '', showDiseaseName: true },
    { name: 'יתר לחץ דם', value: '', showDiseaseName: false },
    { name: 'דלקת מפרקים', value: '', showDiseaseName: false },
    { name: 'גאוט', value: '', showDiseaseName: false },
    { name: 'בלוטת המגן (תריס)', value: '', showDiseaseName: true },
    { name: 'אנמיה', value: '', showDiseaseName: true },
    { name: 'צליאק', value: '', showDiseaseName: false },
    { name: 'קרוהן', value: '', showDiseaseName: false },
    { name: 'קוליטיס כיבית', value: '', showDiseaseName: false },
    { name: 'מעי רגיש', value: '', showDiseaseName: false },
    { name: 'מחלת כבד', value: '', showDiseaseName: true },
    { name: 'כליות', value: '', showDiseaseName: true },
    { name: 'דלקת בדרכי מרה', value: '', showDiseaseName: false },
    { name: 'מחלה אוטואימונית', value: '', showDiseaseName: true },
    { name: 'סרטן', value: '', showDiseaseName: true },
    { name: 'אחר (נא לפרט)', value: '', showDiseaseName: true },
  ];
  eatingPatternLabels = [
    'אכילה מהירה',
    'תאווה למתוק',
    'take away / אוכל מהיר יותר מפעם בשבוע',
    'אכילה מסיבות שאינן רעב',
    'אני אוכל על הדרך (בעמידה/הליכה/נהיגה/ליד המקרר/תוך כדי הכנת האוכל',
    'אכילה תוך כדי צפייה בטלוויזיה / קריאה/ דיבור או משחק בטלפון/מול המחשב',
    'אני אוכל עד כדי תחושה של מלאות יתר',
    'אכילת יתר כתוצאה מרגשות לא נעימים (מתח/עצבים/פיצוי/שעמום/עייפות)',
    'אני אוכל גם כשאני לא רעב מסיבות שונות (חזרה הביתה/לפי השעון/מפגשים חברתיים)',
    'אני אוהב לבשל',
  ];
  eatingPatternData = [
    'אף פעם',
    'לעיתים רחוקות',
    'לפעמים',
    'לעיתים קרובות',
    'תמיד',
  ];
  step = 1;
  isNewReport: boolean = true;
  isFemale: boolean = false;
  goBack() {
    // this.router.navigate(['home']);
  }
  constructor(
    private lifeStyleReportsService: LifeStyleReportsService,
    private route: ActivatedRoute,
    private regularUsersService: RegularUsersService,
    private productService: ProductsService,
    private router: Router,
    private fb: FormBuilder,
    private popupHandlerService: PopupHandlerService
  ) {
    this.lifestyleLocal = new LifeStyleReportLocal();
    this.lifestyle = new LifeStyleReport();
  }

  ngOnInit() {
  
    this.patientId = +this.route.snapshot.queryParamMap.get('patientId');
    this.productService.getByPatientId(this.patientId).subscribe((product) => {
      this.product = product;
      if (this.product.patient.regularUser.gender === 'female') {
        this.isFemale = true;
        if (this.lifestyleForm4) {
          this.lifestyleForm4.controls['isPregnent'].setValidators(
            Validators.required
          );
        }
      }
    
      this.productStatus = this.product.status;
    });

    this.lifeStyleReportsService
      .getByPatientId(this.patientId)
      .subscribe((data) => {
        if (data == null) {
          this.lifestyle = new LifeStyleReport();
          this.lifestyleLocal = new LifeStyleReportLocal();
        } else {
          if (data.valueNew == null) {
            this.lifestyleLocal = new LifeStyleReportLocal();
          }
          this.asignReport(data);
      

          this.isNewReport = false;
        }
        this.createForm();
        this.onChanges();
        if (this.lifestyleLocal.otherNutritionTypes) {
          this.otherNutritionText = true;
          this.lifestyleForm1.addControl(
            'otherNutritionText',
            new FormControl(
              this.lifestyleLocal.otherNutritionTypes,
              Validators.required
            )
          );
        }
        if (this.lifestyleLocal.otherDigestionProblems) {
          this.otherDigestionText = true;
          this.lifestyleForm1.addControl(
            'otherDigestionText',
            new FormControl(
              this.lifestyleLocal.otherDigestionProblems,
              Validators.required
            )
          );
        }
       
      });
  }
  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  asignReport(data) {
    this.lifestyle = data;
    this.step = this.lifestyle.stageNew == null ? 1 : this.lifestyle.stageNew;
    this.lifestyleLocal = this.lifestyle.valueNew
      ? JSON.parse(this.lifestyle.valueNew)
      : new LifeStyleReportLocal();
  }

  onChanges(): void {
    this.lifestyleForm1.get('nutritionTypes').valueChanges.subscribe((val) => {
      if (val[5] == true) {
        this.otherNutritionText = true;
        this.lifestyleForm1.addControl(
          'otherNutritionText',
          new FormControl(
            this.lifestyleLocal.otherNutritionTypes,
            Validators.required
          )
        );
      } else {
        this.otherNutritionText = false;
        this.lifestyleForm1.removeControl('otherNutritionText');
      }
    });
    this.lifestyleForm1
      .get('digestionProblems')
      .valueChanges.subscribe((val) => {
        if (val[7] == true) {
          this.otherDigestionText = true;
          this.lifestyleForm1.addControl(
            'otherDigestionText',
            new FormControl(
              this.lifestyleLocal.otherDigestionProblems,
              Validators.required
            )
          );
        } else {
          this.otherDigestionText = false;
          this.lifestyleForm1.removeControl('otherDigestionText');
        }
      });
    this.lifestyleForm4.get('isSmoking').valueChanges.subscribe((val) => {
     
      if (val == 0) {
        this.lifestyleForm4.controls['smokingYesTextNum'].setValidators(
          Validators.required
        );
        this.lifestyleForm4.controls['smokingYesTextYears'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm4.controls['smokingYesTextNum'].clearValidators();
        this.lifestyleForm4.controls[
          'smokingYesTextNum'
        ].updateValueAndValidity();
        this.lifestyleForm4.controls['smokingYesTextYears'].clearValidators();
        this.lifestyleForm4.controls[
          'smokingYesTextYears'
        ].updateValueAndValidity();
      }

      if (val == 1) {
        this.lifestyleForm4.controls['smokingNoTextQuit'].setValidators(
          Validators.required
        );
        this.lifestyleForm4.controls['smokingNoTextYears'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm4.controls['smokingNoTextQuit'].clearValidators();
        this.lifestyleForm4.controls[
          'smokingNoTextQuit'
        ].updateValueAndValidity();
        this.lifestyleForm4.controls['smokingNoTextYears'].clearValidators();
        this.lifestyleForm4.controls[
          'smokingNoTextYears'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm4.get('isPregnent').valueChanges.subscribe((val) => {
      if (val == 0) {
        this.lifestyleForm4.controls['tremester'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm4.controls['tremester'].clearValidators();
        this.lifestyleForm4.controls['tremester'].updateValueAndValidity();
      }
    });

    this.lifestyleForm5.get('yoga').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['yogaDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['yogaNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['yogaDuration'].clearValidators();
        this.lifestyleForm5.controls['yogaDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['yogaNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['yogaNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('pilates').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['pilatesDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['pilatesNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['pilatesDuration'].clearValidators();
        this.lifestyleForm5.controls[
          'pilatesDuration'
        ].updateValueAndValidity();
        this.lifestyleForm5.controls['pilatesNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'pilatesNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('dance').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['danceDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['danceNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['danceDuration'].clearValidators();
        this.lifestyleForm5.controls['danceDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['danceNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['danceNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('walk').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['walkDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['walkNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['walkDuration'].clearValidators();
        this.lifestyleForm5.controls['walkDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['walkNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['walkNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('run').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['runDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['runNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['runDuration'].clearValidators();
        this.lifestyleForm5.controls['runDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['runNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['runNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('gym').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['gymDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['gymNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['gymDuration'].clearValidators();
        this.lifestyleForm5.controls['gymDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['gymNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['gymNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('swim').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['swimDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['swimNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['swimDuration'].clearValidators();
        this.lifestyleForm5.controls['swimDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['swimNumInWeek'].clearValidators();
        this.lifestyleForm5.controls['swimNumInWeek'].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('bicycle').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['bicycleDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['bicycleNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['bicycleDuration'].clearValidators();
        this.lifestyleForm5.controls[
          'bicycleDuration'
        ].updateValueAndValidity();
        this.lifestyleForm5.controls['bicycleNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'bicycleNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('tennis').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['tennisDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['tennisNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['tennisDuration'].clearValidators();
        this.lifestyleForm5.controls['tennisDuration'].updateValueAndValidity();
        this.lifestyleForm5.controls['tennisNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'tennisNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('football').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['footballDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['footballNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['footballDuration'].clearValidators();
        this.lifestyleForm5.controls[
          'footballDuration'
        ].updateValueAndValidity();
        this.lifestyleForm5.controls['footballNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'footballNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('basketball').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['basketballDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['basketballNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['basketballDuration'].clearValidators();
        this.lifestyleForm5.controls[
          'basketballDuration'
        ].updateValueAndValidity();
        this.lifestyleForm5.controls['basketballNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'basketballNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm5.get('ifOtherSport').valueChanges.subscribe((val) => {
      if (val == true) {
        this.lifestyleForm5.controls['otherSport'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['otherSportDuration'].setValidators(
          Validators.required
        );
        this.lifestyleForm5.controls['otherSportNumInWeek'].setValidators(
          Validators.required
        );
      } else {
        this.lifestyleForm5.controls['otherSport'].clearValidators();
        this.lifestyleForm5.controls['otherSport'].updateValueAndValidity();
        this.lifestyleForm5.controls['otherSportDuration'].clearValidators();
        this.lifestyleForm5.controls[
          'otherSportDuration'
        ].updateValueAndValidity();
        this.lifestyleForm5.controls['otherSportNumInWeek'].clearValidators();
        this.lifestyleForm5.controls[
          'otherSportNumInWeek'
        ].updateValueAndValidity();
      }
    });
    this.lifestyleForm6.get('dieseases').valueChanges.subscribe((val) => {
 
    });
  }

  createNutritionCheckboxes() {
    if (this.lifestyleLocal.nutritionTypes) {
      const arr = this.lifestyleLocal.nutritionTypes.map((item) => {
        return new FormControl(item);
      });
      return new FormArray(arr, Validators.required);
    } else {
      const arr = this.nutritionOptions.map((item) => {
        return new FormControl(item.selected || false);
      });
      return new FormArray(arr, Validators.required);
    }
  }
  createDigestionCheckboxes() {
    if (this.lifestyleLocal.digestionProblems) {
      const arr = this.lifestyleLocal.digestionProblems.map((item) => {
        return new FormControl(item);
      });
      return new FormArray(arr, Validators.required);
    } else {
      const arr = this.digestionOptions.map((item) => {
        return new FormControl(item.selected || false);
      });
      return new FormArray(arr, Validators.required);
    }
  }
  showDiseasesFields(inx) {
    var obj = this.lifestyleForm6.controls;

    // var result = Object.keys(obj).map((key) => [obj[key]]);

    if (obj.dieseases.value[inx].ifActive == true) {
      return true;
    } else {
      return false;
    }
  }
  ifChecked(e, inx) {
    if (e.checked) {
      return true;
    }
    return false;
  }
  createDieseasesFields(i) {
    if (this.lifestyleLocal.lifeStyleDisease) {
      var obj = this.lifestyleLocal.lifeStyleDisease;
      // var result = Object.keys(obj).map((key) => [obj[key]]);
    }
    if (obj && obj[i]) {
      return new FormGroup({
        ifActive: new FormControl(obj ? obj[i].ifActive : false),
        dieseasName: new FormControl(this.lifeStyleDiseaseOptions[i].name),
        dieseasName2: new FormControl(obj ? obj[i].dieseasName2 : ''),
        whenStarted: new FormControl(obj ? obj[i].whenStarted : ''),
        medCare: new FormControl(obj ? obj[i].medCare : ''),
      });
    } else {
      return new FormGroup({
        ifActive: new FormControl(false),
        dieseasName: new FormControl(),
        dieseasName2: new FormControl(),
        whenStarted: new FormControl(),
        medCare: new FormControl(),
      });
    }
  }
  createRadioPattern(inx) {
    if (this.lifestyleLocal.lifeStyleEatingPaterns) {
      var obj = this.lifestyleLocal.lifeStyleEatingPaterns;
      //var result = Object.keys(obj).map((key) => [obj[key]]);
    }

    return new FormGroup({
      name: new FormControl(this.eatingPatternLabels[inx]),
      value: new FormControl(obj ? obj[inx].value : '', [Validators.required]),
    });
  }

  createForm() {
    this.lifestyleForm1 = new FormGroup({
      //nutritionTypes: this.createNutritionCheckboxes(),
      nutritionTypes: this.fb.array(
        this.nutritionOptions.map((x) => {
          if (this.lifestyleLocal.nutritionTypes) {
            return this.lifestyleLocal.nutritionTypes.includes(x.name)
              ? x.name
              : null;
          } else {
            return false;
          }
        })
      ),
      sensitivityToFood: new FormControl(
        this.lifestyleLocal.sensitivityToFood,
        [Validators.required]
      ),
      foodToAvoid: new FormControl(this.lifestyleLocal.foodToAvoid, [
        Validators.required,
      ]),
      //digestionProblems: this.createDigestionCheckboxes(),
      digestionProblems: this.fb.array(
        this.digestionOptions.map((x) => {
          if (this.lifestyleLocal.digestionProblems) {
            return this.lifestyleLocal.digestionProblems.includes(x.name)
              ? x.name
              : null;
          } else {
            return false;
          }
        })
      ),
    });
    const nutritionCheckboxControl = this.lifestyleForm1.controls
      .nutritionTypes as FormArray;
    this.subscription = nutritionCheckboxControl.valueChanges.subscribe(
      (checkbox) => {
        nutritionCheckboxControl.setValue(
          nutritionCheckboxControl.value.map((value, i) =>
            value ? this.nutritionOptions[i].name : false
          ),
          { emitEvent: false }
        );
      }
    );
    const digestCheckboxControl = this.lifestyleForm1.controls
      .digestionProblems as FormArray;
    this.subscription = digestCheckboxControl.valueChanges.subscribe(
      (checkbox) => {
        digestCheckboxControl.setValue(
          digestCheckboxControl.value.map((value, i) =>
            value ? this.digestionOptions[i].name : false
          ),
          { emitEvent: false }
        );
      }
    );
    this.lifestyleForm2 = new FormGroup({
      waterNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.waterNumGlassesPerDay,
        [Validators.required]
      ),
      sweetNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.sweetNumGlassesPerDay,
        [Validators.required]
      ),
      dietNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.dietNumGlassesPerDay,
        [Validators.required]
      ),
      coffeePerDay: new FormControl(this.lifestyleLocal.coffeePerDay, [
        Validators.required,
      ]),
      teaPerDay: new FormControl(this.lifestyleLocal.teaPerDay, [
        Validators.required,
      ]),
      alcoholPerDay: new FormControl(this.lifestyleLocal.alcoholPerDay, [
        Validators.required,
      ]),
    });
    this.lifestyleForm3 = new FormGroup({
      eatingHabitsMorning: new FormControl(
        this.lifestyleLocal.eatingHabitsMorning,
        [Validators.required]
      ),
      eatingHabitsMiddle1: new FormControl(
        this.lifestyleLocal.eatingHabitsMiddle1,
        [Validators.required]
      ),
      eatingHabitsLunch: new FormControl(
        this.lifestyleLocal.eatingHabitsLunch,
        [Validators.required]
      ),
      eatingHabitsMiddle2: new FormControl(
        this.lifestyleLocal.eatingHabitsMiddle2,
        [Validators.required]
      ),
      eatingHabitsSupper: new FormControl(
        this.lifestyleLocal.eatingHabitsSupper,
        [Validators.required]
      ),
    });
    this.lifestyleForm4 = new FormGroup({
      drugsTakenText: new FormControl(this.lifestyleLocal.drugsTakenText),
      isSmoking: new FormControl(this.lifestyleLocal.isSmoking),
      smokingYesTextNum: new FormControl(this.lifestyleLocal.smokingYesTextNum),
      smokingYesTextYears: new FormControl(
        this.lifestyleLocal.smokingYesTextYears
      ),
      smokingNoTextYears: new FormControl(
        this.lifestyleLocal.smokingNoTextYears
      ),
      smokingNoTextQuit: new FormControl(this.lifestyleLocal.smokingNoTextQuit),
      isPregnent: new FormControl(this.lifestyleLocal.isPregnent),
      tremester: new FormControl(this.lifestyleLocal.tremester),
      getUpHour: new FormControl(
        this.lifestyleLocal.getUpHour,
        Validators.required
      ),
      sleepHours: new FormControl(
        this.lifestyleLocal.sleepHours,
        Validators.required
      ),
      occupation: new FormControl(
        this.lifestyleLocal.occupation,
        Validators.required
      ),
      noonSleep: new FormControl(
        this.lifestyleLocal.noonSleep,
        Validators.required
      ),
      workHours: new FormControl(
        this.lifestyleLocal.workHours,
        Validators.required
      ),
      sleepQuality: new FormControl(
        this.lifestyleLocal.sleepQuality,
        Validators.required
      ),
      areYouInStress: new FormControl(this.lifestyleLocal.areYouInStress, [
        Validators.required,
      ]),
      whatToAprrove: new FormControl(
        this.lifestyleLocal.whatToAprrove,
        Validators.required
      ),
      healthStatus: new FormControl(
        this.lifestyleLocal.healthStatus,
        Validators.required
      ),
    });
    this.lifestyleForm5 = new FormGroup({
      isExercising: new FormControl(
        this.lifestyleLocal.isExercising,
        Validators.required
      ),
      yoga: new FormControl(this.lifestyleLocal.yoga),
      yogaDuration: new FormControl(this.lifestyleLocal.yogaDuration),
      yogaNumInWeek: new FormControl(this.lifestyleLocal.yogaNumInWeek),

      pilates: new FormControl(this.lifestyleLocal.pilates),
      pilatesDuration: new FormControl(this.lifestyleLocal.pilatesDuration),
      pilatesNumInWeek: new FormControl(this.lifestyleLocal.pilatesNumInWeek),

      dance: new FormControl(this.lifestyleLocal.dance),
      danceDuration: new FormControl(this.lifestyleLocal.danceDuration),
      danceNumInWeek: new FormControl(this.lifestyleLocal.danceNumInWeek),

      walk: new FormControl(this.lifestyleLocal.walk),
      walkDuration: new FormControl(this.lifestyleLocal.walkDuration),
      walkNumInWeek: new FormControl(this.lifestyleLocal.walkNumInWeek),

      run: new FormControl(this.lifestyleLocal.run),
      runDuration: new FormControl(this.lifestyleLocal.runDuration),
      runNumInWeek: new FormControl(this.lifestyleLocal.runNumInWeek),

      gym: new FormControl(this.lifestyleLocal.gym),
      gymDuration: new FormControl(this.lifestyleLocal.gymDuration),
      gymNumInWeek: new FormControl(this.lifestyleLocal.gymNumInWeek),

      swim: new FormControl(this.lifestyleLocal.swim),
      swimDuration: new FormControl(this.lifestyleLocal.swimDuration),
      swimNumInWeek: new FormControl(this.lifestyleLocal.swimNumInWeek),

      bicycle: new FormControl(this.lifestyleLocal.bicycle),
      bicycleDuration: new FormControl(this.lifestyleLocal.bicycleDuration),
      bicycleNumInWeek: new FormControl(this.lifestyleLocal.bicycleNumInWeek),

      tennis: new FormControl(this.lifestyleLocal.tennis),
      tennisDuration: new FormControl(this.lifestyleLocal.tennisDuration),
      tennisNumInWeek: new FormControl(this.lifestyleLocal.tennisNumInWeek),

      football: new FormControl(this.lifestyleLocal.football),
      footballDuration: new FormControl(this.lifestyleLocal.footballDuration),
      footballNumInWeek: new FormControl(this.lifestyleLocal.footballNumInWeek),

      basketball: new FormControl(this.lifestyleLocal.basketball),
      basketballDuration: new FormControl(
        this.lifestyleLocal.basketballDuration
      ),
      basketballNumInWeek: new FormControl(
        this.lifestyleLocal.basketballNumInWeek
      ),

      ifOtherSport: new FormControl(this.lifestyleLocal.ifOtherSport),
      otherSport: new FormControl(this.lifestyleLocal.otherSport),
      otherSportDuration: new FormControl(
        this.lifestyleLocal.otherSportDuration
      ),
      otherSportNumInWeek: new FormControl(
        this.lifestyleLocal.otherSportNumInWeek
      ),
    });
    this.lifestyleForm6 = this.fb.group({
      dieseases: this.fb.array([
        this.createDieseasesFields(0),
        this.createDieseasesFields(1),
        this.createDieseasesFields(3),
        this.createDieseasesFields(4),
        this.createDieseasesFields(4),
        this.createDieseasesFields(5),
        this.createDieseasesFields(6),
        this.createDieseasesFields(7),
        this.createDieseasesFields(8),
        this.createDieseasesFields(9),
        this.createDieseasesFields(10),
        this.createDieseasesFields(11),
        this.createDieseasesFields(12),
        this.createDieseasesFields(13),
        this.createDieseasesFields(14),
        this.createDieseasesFields(15),
        this.createDieseasesFields(16),
        this.createDieseasesFields(17),
        this.createDieseasesFields(18),
        this.createDieseasesFields(19),
        this.createDieseasesFields(20),
        this.createDieseasesFields(21),
        this.createDieseasesFields(22),
        this.createDieseasesFields(23),
        this.createDieseasesFields(24),
      ]),
      // Form Array to set default values
      // checkboxes: this.fb.array(this.checkboxes.map(x => this.defaultValues.includes(x.value) ? x.value : null))
    });
    /* const checkboxControl = this.lifestyleForm6.controls.dieseases as FormArray;
    this.subscription = checkboxControl.valueChanges.subscribe((checkbox) => {
    

      checkboxControl.setValue(
        checkboxControl.value.map((value, i) =>
          value ? this.lifeStyleDiseaseOptions[i].name : false
        ),
        { emitEvent: false }
      );
    }); */
    this.lifestyleForm7 = new FormGroup({
      eatingPattern: this.fb.array([
        this.createRadioPattern(0),
        this.createRadioPattern(1),
        this.createRadioPattern(2),
        this.createRadioPattern(3),
        this.createRadioPattern(4),
        this.createRadioPattern(5),
        this.createRadioPattern(6),
        this.createRadioPattern(7),
        this.createRadioPattern(8),
        this.createRadioPattern(9),
      ]),
    });
  }
  saveThis1() {
    if (this.step == 1 && this.lifestyleForm1.valid) {
      this.lifestyleForm1Error = false;
      const noutritionCheckboxControl = this.lifestyleForm1.controls
        .nutritionTypes as FormArray;
      const digestionCheckboxControl = this.lifestyleForm1.controls
        .digestionProblems as FormArray;
      this.lifestyleLocal.nutritionTypes = noutritionCheckboxControl.value.filter(
        (value) => !!value
      );
      this.lifestyleLocal.otherNutritionTypes = this.lifestyleForm1.value.otherNutritionText;
      this.lifestyleLocal.sensitivityToFood = this.lifestyleForm1.value.sensitivityToFood;
      this.lifestyleLocal.foodToAvoid = this.lifestyleForm1.value.foodToAvoid;
      this.lifestyleLocal.digestionProblems = digestionCheckboxControl.value.filter(
        (value) => !!value
      );
      this.lifestyleLocal.otherDigestionProblems = this.lifestyleForm1.value.otherDigestionText;
      this.lifestyle.stageNew = 2;
      this.lifestyle.patientId = this.patientId;
      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.step = 2;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 2;
        });
      }
    } else {
      this.lifestyleForm1Error = true;
    }
  }
  saveThis2() {
    if (this.step == 2 && this.lifestyleForm2.valid) {
      this.lifestyleLocal.waterNumGlassesPerDay = this.lifestyleForm2.value.waterNumGlassesPerDay;
      this.lifestyleLocal.sweetNumGlassesPerDay = this.lifestyleForm2.value.sweetNumGlassesPerDay;
      this.lifestyleLocal.dietNumGlassesPerDay = this.lifestyleForm2.value.dietNumGlassesPerDay;
      this.lifestyleLocal.coffeePerDay = this.lifestyleForm2.value.coffeePerDay;
      this.lifestyleLocal.teaPerDay = this.lifestyleForm2.value.teaPerDay;
      this.lifestyleLocal.alcoholPerDay = this.lifestyleForm2.value.alcoholPerDay;
      this.lifestyle.stageNew = 3;

      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.step = 3;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 3;
        });
      }
    } else {
      this.lifestyleForm2Error = true;
    }
  }
  saveThis3() {
    if (this.step == 3 && this.lifestyleForm3.valid) {
      this.lifestyleLocal.eatingHabitsMorning = this.lifestyleForm3.value.eatingHabitsMorning;
      this.lifestyleLocal.eatingHabitsMiddle1 = this.lifestyleForm3.value.eatingHabitsMiddle1;
      this.lifestyleLocal.eatingHabitsLunch = this.lifestyleForm3.value.eatingHabitsLunch;
      this.lifestyleLocal.eatingHabitsMiddle2 = this.lifestyleForm3.value.eatingHabitsMiddle2;
      this.lifestyleLocal.eatingHabitsSupper = this.lifestyleForm3.value.eatingHabitsSupper;
      this.lifestyle.stageNew = 4;

      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.step = 4;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 4;
        });
      }
    } else {
      this.lifestyleForm3Error = true;
    }
  }
  saveThis4() {
    if (this.step == 4 && this.lifestyleForm4.valid) {
      this.lifestyleLocal.drugsTakenText = this.lifestyleForm4.value.drugsTakenText;
      this.lifestyleLocal.isSmoking = this.lifestyleForm4.value.isSmoking;
      this.lifestyleLocal.smokingYesTextNum = this.lifestyleForm4.value.smokingYesTextNum;
      this.lifestyleLocal.smokingYesTextYears = this.lifestyleForm4.value.smokingYesTextYears;
      this.lifestyleLocal.smokingNoTextYears = this.lifestyleForm4.value.smokingNoTextYears;
      this.lifestyleLocal.smokingNoTextQuit = this.lifestyleForm4.value.smokingNoTextQuit;
      this.lifestyleLocal.isPregnent = this.lifestyleForm4.value.isPregnent;
      this.lifestyleLocal.tremester = this.lifestyleForm4.value.tremester;
      this.lifestyleLocal.getUpHour = this.lifestyleForm4.value.getUpHour;
      this.lifestyleLocal.sleepHours = this.lifestyleForm4.value.sleepHours;
      this.lifestyleLocal.noonSleep = this.lifestyleForm4.value.noonSleep;
      this.lifestyleLocal.workHours = this.lifestyleForm4.value.workHours;
      this.lifestyleLocal.occupation = this.lifestyleForm4.value.occupation;
      this.lifestyleLocal.sleepQuality = this.lifestyleForm4.value.sleepQuality;
      this.lifestyleLocal.areYouInStress = this.lifestyleForm4.value.areYouInStress;
      this.lifestyleLocal.whatToAprrove = this.lifestyleForm4.value.whatToAprrove;
      this.lifestyleLocal.healthStatus = this.lifestyleForm4.value.healthStatus;

      this.lifestyle.stageNew = 5;

      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.step = 5;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 5;
        });
      }
    } else {
      this.lifestyleForm4Error = true;
    }
  }
  saveThis5() {
    if (this.step == 5 && this.lifestyleForm5.valid) {
      this.lifestyleLocal.isExercising = this.lifestyleForm5.value.isExercising;
      this.lifestyleLocal.yoga = this.lifestyleForm5.value.yoga;
      this.lifestyleLocal.yogaDuration = this.lifestyleForm5.value.yogaDuration;
      this.lifestyleLocal.yogaNumInWeek = this.lifestyleForm5.value.yogaNumInWeek;

      this.lifestyleLocal.pilates = this.lifestyleForm5.value.pilates;
      this.lifestyleLocal.pilatesDuration = this.lifestyleForm5.value.pilatesDuration;
      this.lifestyleLocal.pilatesNumInWeek = this.lifestyleForm5.value.pilatesNumInWeek;

      this.lifestyleLocal.dance = this.lifestyleForm5.value.dance;
      this.lifestyleLocal.danceDuration = this.lifestyleForm5.value.danceDuration;
      this.lifestyleLocal.danceNumInWeek = this.lifestyleForm5.value.danceNumInWeek;

      this.lifestyleLocal.walk = this.lifestyleForm5.value.walk;
      this.lifestyleLocal.walkDuration = this.lifestyleForm5.value.walkDuration;
      this.lifestyleLocal.walkNumInWeek = this.lifestyleForm5.value.walkNumInWeek;

      this.lifestyleLocal.run = this.lifestyleForm5.value.run;
      this.lifestyleLocal.runDuration = this.lifestyleForm5.value.runDuration;
      this.lifestyleLocal.runNumInWeek = this.lifestyleForm5.value.runNumInWeek;

      this.lifestyleLocal.gym = this.lifestyleForm5.value.gym;
      this.lifestyleLocal.gymDuration = this.lifestyleForm5.value.gymDuration;
      this.lifestyleLocal.gymNumInWeek = this.lifestyleForm5.value.gymNumInWeek;

      this.lifestyleLocal.swim = this.lifestyleForm5.value.swim;
      this.lifestyleLocal.swimDuration = this.lifestyleForm5.value.swimDuration;
      this.lifestyleLocal.swimNumInWeek = this.lifestyleForm5.value.swimNumInWeek;

      this.lifestyleLocal.bicycle = this.lifestyleForm5.value.bicycle;
      this.lifestyleLocal.bicycleDuration = this.lifestyleForm5.value.bicycleDuration;
      this.lifestyleLocal.bicycleNumInWeek = this.lifestyleForm5.value.bicycleNumInWeek;

      this.lifestyleLocal.tennis = this.lifestyleForm5.value.tennis;
      this.lifestyleLocal.tennisDuration = this.lifestyleForm5.value.tennisDuration;
      this.lifestyleLocal.tennisNumInWeek = this.lifestyleForm5.value.tennisNumInWeek;

      this.lifestyleLocal.football = this.lifestyleForm5.value.football;
      this.lifestyleLocal.footballDuration = this.lifestyleForm5.value.footballDuration;
      this.lifestyleLocal.footballNumInWeek = this.lifestyleForm5.value.footballNumInWeek;

      this.lifestyleLocal.basketball = this.lifestyleForm5.value.basketball;
      this.lifestyleLocal.basketballDuration = this.lifestyleForm5.value.basketballDuration;
      this.lifestyleLocal.basketballNumInWeek = this.lifestyleForm5.value.basketballNumInWeek;

      this.lifestyleLocal.ifOtherSport = this.lifestyleForm5.value.ifOtherSport;
      this.lifestyleLocal.otherSport = this.lifestyleForm5.value.otherSport;
      this.lifestyleLocal.otherSportDuration = this.lifestyleForm5.value.otherSportDuration;
      this.lifestyleLocal.otherSportNumInWeek = this.lifestyleForm5.value.otherSportNumInWeek;

      this.lifestyle.stageNew = 6;

      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          //this.step = 6;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 6;
        });
      }
    } else {
      this.lifestyleForm5Error = true;
    }
  }
  saveThis6() {
    if (this.step == 6 && this.lifestyleForm6.valid) {
      const checkboxControl = this.lifestyleForm6.controls
        .dieseases as FormArray;
      const formValue = {
        ...this.lifestyleForm6.value,
        dieseases: checkboxControl.value.filter((value) => !!value),
      };
      //this.submittedValue = formValue;
      this.lifestyleLocal.lifeStyleDisease = this.lifestyleForm6.controls.dieseases.value;
      this.lifestyle.stageNew = 7;

      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.step = 7;
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.step = 7;
        });
      }
    } else {
      this.lifestyleForm6Error = true;
    }
  }
  saveThis7() {
    if (this.step == 7 && this.lifestyleForm7.valid) {
      this.lifestyleLocal.lifeStyleEatingPaterns = this.lifestyleForm7.controls.eatingPattern.value;
      this.step = 1;
      this.lifestyle.stageNew = 1;
      this.lifestyle.isCompleteNew = true;
      this.lifestyle.valueNew = JSON.stringify(this.lifestyleLocal);
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.lifeStyleReportComplete
      );
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          //this.router.navigate(['home']);
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          //this.router.navigate(['home']);
        });
      }
    } else {
      this.lifestyleForm7Error = true;
    }
  }
}
