import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NameAndId } from 'src/app/data/NameAndId';
import { FoodScaleValuesService } from 'src/app/Services/food-scale-values.service';

@Component({
  selector: 'app-unit-scale-dialog',
  templateUrl: './unit-scale-dialog.component.html',
  styleUrls: ['./unit-scale-dialog.component.scss'],
})
export class UnitScaleDialogComponent implements OnInit {
  quantity: number;
  foodScaleId: number;
  unitScaleValues: NameAndId[];
  calories: number;

  constructor(
    public dialogRef: MatDialogRef<UnitScaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private foodScaleValuesService: FoodScaleValuesService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.foodScaleValuesService
      .getFoodScaleValues(this.data.foodId, this.data.langId)
      .subscribe((data) => {
        this.unitScaleValues = data;

        if (this.unitScaleValues) {
          if (this.data?.foodScaleId) {
            this.foodScaleId = this.data.foodScaleId;
          }
          if (this.data?.quantity) {
            this.quantity = this.data.quantity;
          }
          if (this.data?.calories) {
            this.calories = this.data.quantity;
          }
        }
      });
  }

  onSave() {
    if (this.quantity && this.foodScaleId) {
      var foodScale = this.unitScaleValues.find(
        (x) => x.id == this.foodScaleId
      );
      this.dialogRef.close({
        event: 'save',
        data: { foodScale, quantity: this.quantity },
      });
    }
  }
  onCancel() {
    if ((!this.quantity && !this.foodScaleId) || !this.calories) {
      this.dialogRef.close({
        event: 'closeAndDelete',
        data: this.data,
      });
    } else {
      this.dialogRef.close({
        event: 'close',
        data: this.data,
      });
    }
  }
}
