import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Note } from 'src/app/data/Note';
import { DatePipe } from '@angular/common';
import { TimeUtilsService } from 'src/app/Services/time-utils.service';
import { Agent } from '../../data/Agent';
import * as moment from 'moment';
import { RegularUser } from 'src/app/data/RegularUser';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AgentMin } from 'src/app/data/AgentMin';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';

@Component({
  selector: 'app-lead-create-task',
  templateUrl: './lead-create-task.component.html',
  styleUrls: ['./lead-create-task.component.css'],
})
export class LeadCreateTaskComponent implements OnInit {
  @Output('submitTask') submitTask: EventEmitter<any> = new EventEmitter();
  @Output('cancelTask') cancelTask: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  agentAutoComplete: MatAutocompleteTrigger;
  @Input() note: NoteForCallMin | Note;
  @Input() agents: AgentMin[];
  @Input() currAgent: RegularUser;
  filterdAgentOptions: Agent[];
  //agentForAC: string[];
  constructor(
    public datepipe: DatePipe,
    private timeUtilsService: TimeUtilsService
  ) {}
  taskForm: FormGroup = null;
  ngOnInit(): void {
    var a = this.agents;
    var a1 = this.note;
    this.createTaskFormGroup();

    //this.agentForAC = this.agents.map((agent) => agent.name);
  }

  saveAgentForLead(event) {
    //this.note.assigningAgent.agentId = event;
    var selectedAgent = this.agents.find((x) => x.agentId == event).name;
    this.taskForm.controls['assignedAgentName'].setValue(selectedAgent);
  }

  getInitialValue() {
    var assignedAgent = '';

    if (this.note.noteId) {
      var minNote = this.note as NoteForCallMin;
      if (minNote.assignedAgent) assignedAgent = minNote.assignedAgent.name;
    } else {
      var fullNote = this.note as Note;
      if (fullNote.assignedAgentId) assignedAgent = this.currAgent.firstName;
    }
    return assignedAgent;
  }

  /*  onAgentAutocompleteChanged(event) {
    var a = event.target.value;
    var agentsDup = [...this.agents];
    this.filterdAgentOptions = agentsDup.filter((agent) => {
      return agent.name.indexOf(a) != -1;
    });
  } */
  /*
  ngOnChanges(changes: SimpleChanges) {
    this.createTaskFormGroup();
  }*/
  saveTask() {
    if (this.taskForm.valid) {
      //var note: Note = new Note();
      var date: Date = this.taskForm.value.date;
      var momentDate = moment(date).toDate();
      var seconds = this.timeUtilsService.hoursMinutesMaskedToSeconds(
        this.taskForm.value.hour
      );
      date = this.timeUtilsService.setTime(momentDate, seconds);
      //date.setSeconds(seconds);
      this.note.title = this.taskForm.value.title;
      this.note.desc = this.taskForm.value.desc;
      this.note.statusId = 10; //OPEN
      this.note.noteTypeId = 2; //Task
      this.note.dueDate = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm');
      if (this.note.noteId) {
        this.note = this.note as NoteForCallMin;
        this.note.assignedAgent.agentId = this.agents.find(
          (agent) =>
            agent.name.trim() === this.taskForm.value.assignedAgentName.trim()
        ).agentId;
      } else {
        this.note = this.note as Note;
        this.note.assignedAgentId = this.agents.find(
          (agent) => agent.name === this.taskForm.value.assignedAgentName
        ).agentId;
      }

      this.submitTask.emit(this.note);
    }
  }

  deleteAgentName() {
    this.taskForm.controls.assignedAgentName.setValue('');
    this.taskForm.value.assignedAgentName = '';
  }
  open(trigger) {
    trigger.openPanel();
  }
  onCancelNewTask() {
    this.cancelTask.emit();
  }

  createTaskFormGroup() {
    if (!this.note) return;

    this.taskForm = new FormGroup({
      assignedAgentName: new FormControl(
        this.getInitialValue() == ''
          ? this.currAgent.firstName
          : this.getInitialValue(),
        [Validators.required]
      ),
      title: new FormControl(this.note.title, [Validators.required]),
      desc: new FormControl(this.note.desc),
      date: new FormControl(this.note.dueDateObj && this.note.dueDateObj, [
        Validators.required,
      ]), //
      hour: new FormControl(
        this.datepipe.transform(this.note.dueDate, 'HHmm'),
        [Validators.required]
      ),
    });
    var a = this.taskForm.value;
    setTimeout(() => {}, 100);
  }
  get title() {
    return this.taskForm.get('title');
  }
  get desc() {
    return this.taskForm.get('desc');
  }
  get date() {
    return this.taskForm.get('date');
  }
  get hour() {
    return this.taskForm.get('hour');
  }

  triggerToggleClick() {
    /*  let inputElement: HTMLElement = this.toggleDatepicker
      .nativeElement as HTMLElement;
    inputElement.click(); */
    let element: HTMLElement = document.querySelector(
      '#toggle button'
    ) as HTMLElement;
    let e1 = document.querySelector('#toggle') as HTMLElement;
    element.click();
  }
  getNow() {
    return new Date(Date.now());
  }

  //date;
  Message;
  //desc;
  //hour;
  //title;
  model: Note;
  hours: any[] = [
    { text: '0:00', hour: 0 },
    { text: '1:00', hour: 1 },
    { text: '2:00', hour: 2 },
    { text: '3:00', hour: 3 },
    { text: '4:00', hour: 4 },
    { text: '5:00', hour: 5 },
    { text: '6:00', hour: 6 },
    { text: '7:00', hour: 7 },
    { text: '8:00', hour: 8 },
    { text: '9:00', hour: 9 },
    { text: '10:00', hour: 10 },
    { text: '11:00', hour: 11 },
    { text: '12:00', hour: 12 },
    { text: '13:00', hour: 13 },
    { text: '14:00', hour: 14 },
    { text: '15:00', hour: 15 },
    { text: '16:00', hour: 16 },
    { text: '17:00', hour: 17 },
    { text: '18:00', hour: 18 },
    { text: '19:00', hour: 19 },
    { text: '20:00', hour: 20 },
    { text: '21:00', hour: 21 },
    { text: '22:00', hour: 22 },
    { text: '23:00', hour: 23 },
  ];
  /*
    '1:00am',
    '2:00am',
    '3:00am',
    '4:00am',
    '5:00am',
    '6:00am',
    '7:00am',
    '8:00am',
    '9:00am',
    '10:00am',
    '11:00am',
    '12:00pm',
    '1:00pm',
    '2:00pm',
    '3:00pm',
    '4:00pm',
    '5:00pm',
    '6:00pm',
    '7:00pm',
    '8:00pm',
    '9:00pm',
    '10:00pm',
    '11:00pm',
    */
}
