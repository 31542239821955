<div class="row-container">
  <mat-list style="padding-top: 0">
    <mat-list-item>
      <div class="d-flex-between">
        <span style="font-size: 2rem" class="card-title">Request Info </span>

        <button
          (click)="moveToEditMode()"
          mat-button
          class="rect-action-btn rect-action-btn--client"
        >
          Edit
        </button>
      </div>
    </mat-list-item>
    <div style="margin: 0 0 1rem 0" class="divider divider--client"></div>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Client Name:</span>
      <span
        style="color: blue; text-decoration: underline; cursor: pointer"
        (click)="goToClient()"
      >
        {{ getFullName() }}
      </span>
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Email:</span>
      {{ getEmailAddress() }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Tel:</span>
      <ng-container> {{ getUserPhone() }}</ng-container>
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Subject:</span>
      {{ getRequestSubject() }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Content:</span>
      <span [innerHtml]="getRequestDesc()"></span>
    </mat-list-item>
  </mat-list>
</div>
