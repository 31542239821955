<div class="filter-wrapper">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="10px"
    class="filter-item"
  >
    <mat-button-toggle-group
      [(ngModel)]="chatFilter && chatFilter.isOpen"
      (change)="onStatusChange($event)"
    >
      <mat-button-toggle [value]="true"> Opened </mat-button-toggle>
      <mat-button-toggle [value]="false"> Closed </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      [(ngModel)]="chatFilter && chatFilter.departmentId"
      (change)="onDepartmentChange($event)"
    >
      <mat-button-toggle
        [matTooltip]="item.name"
        *ngFor="let item of departments"
        [value]="item.id"
        >{{ item.shortName }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div
    class="filter-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-form-field fxFlex="60" class="agents-select" appearance="outline">
      <mat-icon matPrefix>support_agent</mat-icon>
      <mat-select
        [disabled]="chatFilter && chatFilter.isWaiting"
        disableOptionCentering
        [(ngModel)]="chatFilter && chatFilter.agentId"
      >
        <mat-option [value]="0">All agents</mat-option>
        <mat-option *ngFor="let item of filteredAgents" [value]="item.agentId">
          {{ item.firstName + " " + item.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="chatFilter && chatFilter.isWaiting"
      [disabled]="!chatFilter || !chatFilter.isOpen"
      fxFlex="40"
      >Waiting list</mat-slide-toggle
    >
  </div>
  <div class="search">
    <mat-form-field class="search-input" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="search"
        matInput
        [(ngModel)]="chatFilter && chatFilter.searchTerm"
        placeholder="Search chat"
      />
      <button
        *ngIf="
          chatFilter &&
          chatFilter.searchTerm &&
          chatFilter.searchTerm.length > 0
        "
        mat-icon-button
        matSuffix
        matTooltip="Clear"
        matTooltipPosition="after"
        class="clear-search"
        (click)="clearSearchField()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="search-btn">
    <button mat-button (click)="filter()">Search</button>
  </div>
</div>
