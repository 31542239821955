<div style="display: flex; align-items: flex-start" *ngIf="serviceRequest">
  <div style="width: 80%">
    <div
      style="
        margin: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      "
    >
      <mat-card class="clientDetails">
        <app-request-view
          [serviceRequest]="serviceRequest"
          *ngIf="serviceRequest && isEditMode == false"
          (moveToEdit)="moveToEditMode()"
        >
        </app-request-view>
        <app-request-edit
          *ngIf="isEditMode == true"
          (cancelForm)="onCancelForm($event)"
          [serviceRequest]="serviceRequest"
          (saveForm)="onChildFormValidated($event)"
        >
        </app-request-edit>
      </mat-card>
      <mat-card>
        <div class="row-container">
          <mat-list style="padding-top: 0">
            <mat-list-item>
              <div class="d-flex-between">
                <span style="font-size: 2rem" class="card-title"
                  >Request Status
                </span>
              </div>
            </mat-list-item>
            <div
              style="margin: 0 0 1rem 0"
              class="divider divider--client"
            ></div>
            <mat-list-item class="row-container__item" *ngIf="!isAppReport">
              <span style="width: 190px" class="boldSpan">Opened By: </span>
              <span>{{ getAssigningAgentName() }}</span>
            </mat-list-item>
            <mat-list-item class="row-container__item">
              <span style="width: 180px" class="boldSpan"
                >Responsible Agent:</span
              >
              <app-autocomplete-agent
                (selectedValue)="saveAgentForRequest($event, serviceRequest)"
                [data]="agents"
                [initialValue]="getAgentNameById(serviceRequest)"
                [widthExp]="160"
                [label]="'Select Agent'"
              ></app-autocomplete-agent>
            </mat-list-item>
            <mat-list-item class="row-container__item">
              <span style="width: 180px" class="boldSpan">Status:</span>
              <mat-form-field
                class="example-full-width round-select"
                style="width: 130px"
                *ngIf="serviceRequest"
              >
                <mat-select
                  matNativeControl
                  disableOptionCentering
                  (selectionChange)="
                    saveStatusForRequest($event, serviceRequest)
                  "
                  [(ngModel)]="serviceRequest.statusId"
                >
                  <mat-option
                    *ngFor="let status of requestsStatuses"
                    style="text-align: center"
                    [value]="status.statusId"
                  >
                    {{ status.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item class="row-container__item">
              <span style="width: 180px" class="boldSpan">Received:</span>
              <span *ngIf="serviceRequest">{{
                serviceRequest.createdDate | date : "dd-MM-yyyy  HH:mm"
              }}</span>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card>
    </div>
    <mat-card
      style="width: 48%; margin: 15px"
      *ngIf="isAppReport && (getFoodName() || getImageUrl())"
    >
      <div style="margin-bottom: 1rem">
        <span style="font-size: 2rem" class="card-title">Report info</span>
      </div>
      <div style="margin: 0 0 1rem 0" class="divider divider--client"></div>
      <mat-list style="padding-top: 0">
        <mat-list-item class="row-container__item" *ngIf="getFoodName()">
          <span style="width: 180px" class="boldSpan"
            >{{ getSubjectDescription() }}:</span
          >
          <span>{{ getFoodName() }}</span>
        </mat-list-item>
      </mat-list>
      <div style="max-height: 500px; overflow: hidden" *ngIf="getImageUrl()">
        <img
          style="max-width: 100%; cursor: pointer"
          [src]="getImageUrl()"
          alt=""
          (click)="onImageClick()"
        />
      </div>
    </mat-card>
  </div>
  <div style="width: 20%; height: fit-content; margin-top: 15px">
    <app-client-notes
      [classAttr]="'clientAttr'"
      [notes]="serviceRequestNotes"
      [isAppReport]="isAppReport"
      (openNewNote)="createNewNote($event)"
      *ngIf="isNoteEditMode == false"
      (reloadNotes)="onReloadNotes()"
    >
    </app-client-notes>

    <app-client-add-note
      [note]="noteToEdit"
      [isAppReport]="isAppReport"
      [classAttr]="'clientAttr'"
      (submitNote)="saveNote($event)"
      (cancelNote)="closeNewNoteWindow()"
      *ngIf="isNoteEditMode == true"
    >
    </app-client-add-note>
  </div>
</div>
