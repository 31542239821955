<div class="versions">
  <button (click)="location.back()" class="versions__back" mat-button>
    < Back
  </button>

  <mat-card class="versions__card">
    <mat-card-header class="versions__card__header">
      <mat-card-title class="versions__card__header__title">
        FoodGenes Versions
      </mat-card-title>
      <div>
        <button
          class="versions__card__header__create"
          mat-button
          (click)="editVersion(null)"
        >
          Create new
        </button>
        &nbsp;
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="versions__table">
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            FoodGenes version
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.fgVersionName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="labVersion">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            Lab version
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.labVersionName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="final">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            Final formula
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.resultFunctionName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <button
              mat-icon-button
              class="versions__table__action"
              (click)="lockVersion(element)"
              matTooltip="{{ element.isLock ? 'Locked' : 'Unlocked' }}"
              matTooltipPosition="left"
            >
              <mat-icon>{{ element.isLock ? "lock" : "lock_open" }}</mat-icon>
            </button>
            <button
              mat-icon-button
              class="versions__table__action"
              (click)="editVersion(element, true)"
              matTooltip="Duplicate"
              matTooltipPosition="above"
              [disabled]="element.isLock"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
            <button
              mat-icon-button
              class="versions__table__action"
              (click)="editVersion(element)"
              matTooltip="Edit"
              matTooltipPosition="right"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
</div>
