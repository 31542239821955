<!--<div *ngIf="isLoading" class="loadingOverlay">
  <mat-spinner diameter="250"></mat-spinner>
</div>-->
<app-head-filter
  *ngIf="agents"
  [agents]="agents"
  [leadStatuses]="leadStatuses"
  [leadSources]="leadSources"
  [classAttr]="'clientsAttr'"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
></app-head-filter>
<div class="TableClearFix"></div>
<app-clients-table
  [agents]="agents"
  [leadStatuses]="leadStatuses"
  [leadSources]="leadSources"
  [clientsData]="clientsData"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event)"
  [isLoading]="isLoading"
>
</app-clients-table>
<app-table-paginator
  *ngIf="clientsData && clientsData.length > 0"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event)"
  [classAttr]="'clientsAttr'"
>
</app-table-paginator>
