<mat-card class="chat-hour">
  <div class="chat-hour__title">
    <span>Chat hours settings</span
    ><small>Last update: 22/32021 by Daniel At 14:22</small>
    <button mat-button class="btn boxed">Changes history</button>
  </div>
  <div class="chat-hour__body">
    <span class="chat-hour__body__desc">Activities hours:</span>
    <div class="chat-hour__body__form">
      <div
        class="chat-hour__body__form__days"
        *ngFor="let days of chatHours; let i = index"
      >
        <div class="chat-hour__body__form__days__name">
          {{ dayNames(i) }}
        </div>
        <div>
          <div
            *ngFor="let day of days; let last = last; let first = first"
            class="d-flex"
          >
            <div class="chat-hour__body__form__days__time-start">
              <mat-form-field appearance="fill">
                <mat-select
                  (selectionChange)="onHourChange(day)"
                  disableOptionCentering
                  [(ngModel)]="day.hourStart"
                >
                  <mat-option
                    *ngFor="let hour of hoursList"
                    [value]="hour.value"
                    >{{ hour.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="chat-hour__body__form__days__until">until</div>
            <div class="chat-hour__body__form__days__time-end">
              <mat-form-field appearance="fill">
                <mat-select
                  (selectionChange)="onHourChange(day)"
                  disableOptionCentering
                  [(ngModel)]="day.hourEnd"
                >
                  <mat-option
                    *ngFor="let hour of hoursList"
                    [value]="hour.value"
                    >{{ hour.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!first"
              class="chat-hour__body__form__days__delete"
              (click)="deleteChatHour(day.chatHourId)"
            >
              <button mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="chat-hour__body__form__days__add" *ngIf="last">
              <button
                mat-icon-button
                (click)="addChatHour(day.dayInWeek, day.hourEnd)"
              >
                <mat-icon>add_box</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-hour__footer">
    <div class="chat-hour__footer__action"></div>
    <!-- <button mat-button class="btn fill">save</button> -->
    <button mat-button class="btn rounded" (click)="restoreToDefault()">
      Restore to default
    </button>
    <button mat-button class="btn blank" (click)="setAsDefault()">
      Set as default
    </button>
  </div>
</mat-card>
