import { Patient } from './Patients';

export class LifeStyleReport {
  lifeStyleReportId: number;
  patientId: number;
  patient: Patient;
  value: string;
  isComplete: boolean;
  stage: number | null;
  valueNew: string;
  isCompleteNew: boolean;
  stageNew: number | null;
  isOldActive: boolean;
}
