<div style="position: relative">
  <div *ngIf="isLoading2" class="loadingOverlay2">
    <mat-spinner diameter="250"></mat-spinner>
  </div>
  <div
    class="all-algo-bullets"
    style="max-height: calc(100vh - 96px)"
    [class.medium]="tableResizeMedium"
  >
    <div
      style="position: relative"
      [ngStyle]="{ 'align-self': isLoading ? 'baseline' : 'unset' }"
    >
      <div *ngIf="isLoading" class="loadingOverlay">
        <mat-spinner color="accent" diameter="50"></mat-spinner>
      </div>
      <perfect-scrollbar
        style="max-height: calc(100vh - 96px)"
        [perfectScrollbar]="config"
      >
        <form
          (ngSubmit)="SubmitForm(bulletFormDirective)"
          #bulletFormDirective="ngForm"
          [formGroup]="bulletForm"
          *ngIf="bulletForm"
        >
          <mat-card>
            <div class="d-flex-between-centered mb-2">
              <mat-card-title class="form-title">Genetic test</mat-card-title>
              <div class="d-flex category">
                <div class="fw-500 fs-16 mr-1">Category</div>
                <mat-form-field class="upgrade" appearance="fill">
                  <mat-select
                    placeholder="Choose Category"
                    formControlName="categoryId"
                  >
                    <mat-option
                      value="{{ category.categoryId }}"
                      *ngFor="let category of categories"
                      >{{ category.categoryName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="genetic-header">
              <div>Parameters</div>
              <div>Condition</div>
              <div>Value</div>
              <div></div>
            </div>
            <div
              class="bb-nl"
              formArrayName="genetic"
              *ngFor="
                let a of bulletForm.get('genetic')['controls'];
                let i = index
              "
            >
              <div class="genetic-fields" [formGroupName]="i">
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <mat-select
                      placeholder="Parameter"
                      matTooltip="{{ getSubCategoryName(i) }}"
                      formControlName="subCategoryId"
                      disableOptionCentering
                    >
                      <mat-option
                        *ngFor="let subCategory of subCategories"
                        [value]="subCategory.subCategoryId"
                        >{{ subCategory.subCategoryName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <mat-select
                      placeholder="Condition"
                      formControlName="conditionId"
                    >
                      <mat-option
                        *ngFor="let condition of algoConditions"
                        [value]="condition.statusId"
                      >
                        {{ condition.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <mat-select placeholder="Value" formControlName="valueId">
                      <mat-option
                        *ngFor="let value of algoValues"
                        [value]="value.statusId"
                      >
                        {{ value.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <button
                    [disabled]="disableRemoveGeneticBtn()"
                    (click)="removeGeneticItem(i)"
                    class="trash"
                    type="button"
                    mat-icon-button
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="text-center">
              <button
                (click)="addGeneticItem()"
                type="button"
                class="add-btn"
                mat-button
              >
                Add parameter
              </button>
            </div>
          </mat-card>
          <br />
          <mat-card>
            <mat-card-title class="form-title">Blood test</mat-card-title>
            <div
              class="bloode-header"
              *ngIf="bulletForm.get('bloodeTest')['controls'].length > 0"
            >
              <div>Parameters</div>
              <div>Lower value</div>
              <div>Upper value</div>
              <div></div>
            </div>
            <div
              class="bb-nl"
              formArrayName="bloodeTest"
              *ngFor="
                let a of bulletForm.get('bloodeTest')['controls'];
                let i = index
              "
            >
              <div class="bloode-fields" [formGroupName]="i">
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <mat-select
                      placeholder="Parameter"
                      formControlName="bloodTestTypeId"
                    >
                      <mat-option
                        *ngFor="let bloodeTest of bloodeTestTypes"
                        [value]="bloodeTest.bloodTestTypeId"
                      >
                        {{ bloodeTest.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <input
                      placeholder="Lower value"
                      type="number"
                      formControlName="lowerValue"
                      matInput
                    />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="upgrade" appearance="fill">
                    <input
                      placeholder="Upper value"
                      type="number"
                      formControlName="upperValue"
                      matInput
                    />
                  </mat-form-field>
                </div>
                <div>
                  <button
                    (click)="removebloodeTestItem(i)"
                    type="button"
                    mat-icon-button
                    class="trash"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                (click)="addBloodeTestItem()"
                type="button"
                class="add-btn"
                mat-button
              >
                Add parameter
              </button>
            </div>
          </mat-card>
          <br />
          <mat-card>
            <div class="d-grid-2 title-and-desc">
              <div>
                <div class="label">Title</div>
                <mat-form-field
                  class="textarea"
                  appearance="fill"
                  style="width: 100%"
                >
                  <textarea
                    matTextareaAutosize
                    matAutosizeMinRows="4"
                    formControlName="title"
                    matInput
                  ></textarea>
                </mat-form-field>
              </div>
              <div>
                <div class="label">Description</div>
                <mat-form-field
                  class="textarea"
                  appearance="fill"
                  style="width: 100%"
                >
                  <textarea
                    matTextareaAutosize
                    matAutosizeMinRows="4"
                    formControlName="description"
                    matInput
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
          <div class="link-and-img">
            <mat-card class="links">
              <div>
                <div class="label">
                  <mat-icon>link</mat-icon>
                  Link Page
                </div>
                <mat-form-field
                  style="width: 100%; margin-bottom: 2rem"
                  class="upgrade"
                  appearance="fill"
                >
                  <mat-select
                    placeholder="link page"
                    formControlName="linkPageId"
                  >
                    <mat-option
                      *ngFor="let link of algoBulletLinks"
                      [value]="link.id"
                    >
                      {{ link.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <div class="label">Link text view</div>
                <mat-form-field appearance="fill" style="width: 100%">
                  <input
                    formControlName="linkViewText"
                    placeholder="insert text"
                    matInput
                  />
                </mat-form-field>
              </div>
            </mat-card>
            <mat-card class="upload-image">
              <div class="text">Result Icon</div>
              <!--  <app-algo-image
                [langId]="langId"
                [algoImageType]="AlgoImageType.AlgoCatSmallIcon"
                (onUploadFinished)="onUploadFinished($event)"
                [selectedImg]="getImage(AlgoImageType.AlgoCatSmallIcon)"
                [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatSmallIcon)"
              ></app-algo-image> -->
              <div>
                <div class="img-box">
                  <div *ngIf="!algoImage">
                    <div (click)="openFileManager()" class="img-box">
                      <mat-icon>cloud_upload</mat-icon>
                      Add Image
                    </div>
                    <div *ngIf="imgError" style="color: red; margin-top: 1rem">
                      {{ imgError }}
                    </div>
                  </div>

                  <img
                    *ngIf="algoImage"
                    style="
                      max-width: 100%;
                      width: 100px;
                      height: 100px;
                      display: block;
                      margin: 0 auto;
                    "
                    (click)="openFileManager()"
                    [src]="algoImage.imageUrl"
                    alt=""
                  />
                </div>
                <button
                  class="clear-image"
                  mat-button
                  *ngIf="algoImage"
                  (click)="algoImage = null"
                >
                  Clear image
                </button>
              </div>
            </mat-card>
          </div>
          <div
            class="text-center"
            style="position: sticky; bottom: -1px; background-color: #f9f9f9"
          >
            <button class="save-btn" mat-button>
              {{ isEditMode ? "Save" : "Add" }}
            </button>
            <button
              class="cancel-btn"
              mat-button
              type="button"
              (click)="resetAndClearForm(bulletFormDirective)"
            >
              Cancel
            </button>
            <br />
          </div>
        </form>
      </perfect-scrollbar>
    </div>
    <div [class.p-absolute]="tableResizeBig">
      <mat-card style="height: calc(100vh - 88px)" class="table-resize">
        <div class="d-flex-between">
          <mat-card-title>All Report Summaries</mat-card-title>
          <div>
            <button class="save-btn" (click)="updateReports()" mat-button>
              Update Reports
            </button>
            <button mat-icon-button (click)="tableResizeBig = !tableResizeBig">
              <mat-icon *ngIf="!tableResizeBig">fullscreen</mat-icon>
              <mat-icon *ngIf="tableResizeBig">fullscreen_exit</mat-icon>
            </button>
          </div>
        </div>
        <div style="margin-top: 25px"></div>
        <app-head-filter
          [bloodeTestTypes]="bloodeTestTypes"
          [categories]="categories"
          [subCategories]="subCategories"
          [algoBullet]="true"
          [headFilter]="headFilter"
          (filterChanged)="getAllWithFilter($event, true)"
          [classAttr]="'cancelationRequestAttr'"
          [isMainScreen]="false"
        >
        </app-head-filter>
        <app-algo-bullets-table
          *ngIf="bulletsData && bulletsData.length > 0"
          [isFullScreen]="tableResizeBig"
          (filterChanged)="getAllWithFilter($event)"
          (deleteBullet)="onDeleteBullet($event)"
          (editBullet)="onEditBullet($event)"
          (closeEditMode)="onCloseEditMode($event)"
          [bulletIdToEdit]="algoBullet?.algoBulletId"
          [categoryIdToEdit]="algoBullet?.categoryId"
          [isEditMode]="isEditMode"
          [bulletsData]="bulletsData"
          [headFilter]="headFilter"
          [metaData]="metaData"
        >
        </app-algo-bullets-table>
        <div style="height: 5px"></div>
        <app-table-paginator
          (filterChanged)="getAllWithFilter($event, false, true)"
          [sticky]="true"
          [agents]="agents"
          *ngIf="bulletsData && metaData"
          [headFilter]="headFilter"
          [metaData]="metaData"
          [classAttr]="'leadsAttr'"
        >
        </app-table-paginator>
      </mat-card>
    </div>
  </div>
</div>
