export class PrototypeSnp {
  snpNumber: number;
  gene: string;
  coordinate: string;
  chromosome: string;
  alfaLetter: string;
  altLetterFirst: string;
  altLetterSecond: string;
  altLetterThird: string;
  lastUpdated: string;
}
