import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private http: HttpClient) {}
  addStatisticsReport(url, time, size) {
    return this.http.get<any>(
      `${environment.baseUrl}FunctionNames/AddStatisticsReport/${url}/${time}/${size}`
    );
  }
}
