import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { FgFullLabel } from 'src/app/data/food-genes/FgFullLabel';
import { Language } from 'src/app/data/Language';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-labels-table',
  templateUrl: './labels-table.component.html',
  styleUrls: ['./labels-table.component.scss'],
})
export class LabelsTableComponent implements OnInit {
  labels: FgFullLabel[];

  langId: number = 1;
  displayedColumns: string[] = [
    'name',
    'orderBy',
    'image',
    'nutrient',
    'maxValue',
    'maxLiquidValue',
    'action',
  ];
  dataSource: MatTableDataSource<FgFullLabel> = new MatTableDataSource([]);
  nutrients: BaseResponce[];
  constructor(
    private foodGenesService: FoodGenesService,
    public location: Location,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getLabels();
    this.getNutrients();
  }

  getLabels() {
    this.foodGenesService.getLabels(this.langId, 0, 15).subscribe((data) => {
      this.labels = data;
      this.setDataSource(this.labels);
    });
  }
  getNutrients() {
    this.foodGenesService
      .getNutrientsForParams(this.langId)
      .subscribe((data) => {
        this.nutrients = data;
      });
  }
  getNutrientName(nutrientId: number) {
    const nutrient = this.nutrients?.find((x) => x.id == nutrientId);
    return nutrient?.name;
  }
  setDataSource(data: FgFullLabel[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  editLabel(label: FgFullLabel) {
    this.popupHandlerService.openAddNewTag(label, this.nutrients, this.langId);

    this.popupHandlerService.addNewTagSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.type == 'save') {
          this.getLabels();
        }
      });
  }
  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
    this.getLabels();
    this.getNutrients();
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  getImage(label: FgFullLabel) {
    if (!label?.icon) return;
    const imagePath = `${label.icon.webPath}/${label.icon.fileName}`;
    return this.replaceWebPath(imagePath);
  }
}
