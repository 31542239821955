import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { FgCalculatorFunctions } from 'src/app/data/food-genes/FgCalculatorFunctions';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { FgFormulaOwnerTypeEnum } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-food-genes-formula-board',
  templateUrl: './food-genes-formula-board.component.html',
  styleUrls: ['./food-genes-formula-board.component.scss'],
})
export class FoodGenesFormulaBoardComponent implements OnInit, OnChanges {
  @Input() canEdit: boolean;
  @Input() langId: boolean;
  @Input() coeffType: FgCoefficientType;
  @Input() coeff: FgCoefficient;
  @Input() versionId: number;
  @Input() formulas: FgFormula[];
  @Input() ownerType: number;
  @Input() calcFunctions: FgCalculatorFunctions[];
  selectedFormulaId: number;
  selectedFormula: FgFormula;
  indexExpanded: number = -1;
  itemExpandedId: number;
  versions: FgVersion[];

  constructor(
    private popupHandlerService: PopupHandlerService,
    private foodGenesService: FoodGenesService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    /* if (changes.formulas?.currentValue && !changes.formulas?.isFirstChange()) {
      this.foodGenesService.formula$.next(this.formulas[0]);
    } */
  }
  ngOnInit(): void {
    this.foodGenesService.formulas$.subscribe((res) => {
      if (res) {
        this.formulas = res;
        this.foodGenesService.saveFormulas = res;
        // this.foodGenesService.formula$.next(this.formulas[0]);
        //console.log('formulas', res);
      }
    });
    this.foodGenesService.formula$.subscribe((res) => {
      if (res) {
        this.selectedFormulaId = res.formulaId;
      }
    });
    this.foodGenesService.versions$.subscribe((res) => {
      if (res) {
        this.versions = res;
      }
    });
  }
  addFormula() {
    this.popupHandlerService.openFoodGenesFormulaPopup(
      this.versionId,
      this.coeff?.fgCoefficientId || this.coeffType.fgCoefficientTypeId,
      this.ownerType,
      this.versions
    );
    let called = false;
    this.popupHandlerService.openFormulaPopupSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res && !called) {
          const isFormulaAlreadyExists = this.formulas.some(
            (existingFormula) =>
              existingFormula.formulaId === res.formula.formulaId
          );
          if (!isFormulaAlreadyExists) {
            called = true;
            this.formulas.push(res.formula);
            this.foodGenesService.formulas$.next(this.formulas);
            this.selectedFormulaId = res.formula.formulaId;
            this.foodGenesService.formula$.next(res.formula);
          }
        }
      });
  }

  onSelectFormula(formula: FgFormula) {
    this.foodGenesService.formula$.next(formula);
  }
}
