import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArticleCategory } from '../data/ArticleCategory';
import { ArticleSubCategory } from '../data/ArticleSubCategory';
import { HeadFilter } from '../data/HeadFilter';

@Injectable({
  providedIn: 'root',
})
export class ArticleSubCategoriesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<ArticleSubCategory[]>(
      `${environment.baseUrl}ArticleSubCategories/`
    );
  }
  getById(articleSubCategoryId) {
    return this.http.get<ArticleSubCategory>(
      `${environment.baseUrl}ArticleSubCategories/${articleSubCategoryId}`
    );
  }
  put(articleSubCategory: ArticleSubCategory) {
    return this.http.put<any>(
      `${environment.baseUrl}ArticleSubCategories/${articleSubCategory.articleSubCategoryId}`,
      articleSubCategory
    );
  }
  post(articleSubCategory: ArticleSubCategory) {
    return this.http.post<any>(
      `${environment.baseUrl}ArticleSubCategories/`,
      articleSubCategory
    );
  }
  delete(articleCategory: ArticleSubCategory) {
    return this.http.delete(
      `${environment.baseUrl}ArticleSubCategories/${articleCategory.articleSubCategoryId}`
    );
  }
}
