import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PrototypeSnp } from 'src/app/data/algorithm/PrototypeSnp';
import { PrototypeSnpByVer } from 'src/app/data/algorithm/PrototypeSnpByVer';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-add-snp',
  templateUrl: './add-snp.component.html',
  styleUrls: ['./add-snp.component.scss'],
})
export class AddSnpComponent implements OnInit {
  snpNumberControl = new FormControl();
  filteredOptions: Observable<PrototypeSnpByVer[]>;
  isNotInList: boolean;
  allels: string[] = [];
  letters: string[];
  allelForm: FormGroup;
  allelFieldsNames = ['firstLetter', 'secondLetter'];
  selectedSnp: PrototypeSnpByVer;
  constructor(
    private dialogRef: MatDialogRef<AddSnpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PrototypeSnpByVer[],
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.createAllelForm();
    this.filteredOptions = this.allelForm.get('snpNumber').valueChanges.pipe(
      startWith(''),
      map((value) => {
        this.isNotInList = this.data.every((x) => x.snpNumber !== value);
        return this._filter(value);
      })
    );
  }
  createAllelForm() {
    this.allelForm = this.fb.group({
      snpNumber: ['', Validators.required],
      firstLetter: ['', Validators.required],
      secondLetter: ['', Validators.required],
    });
  }
  onSelectSnp(snp: PrototypeSnpByVer) {
    this.selectedSnp = snp;
    //console.log('selectedSnp', this.selectedSnp);
    if (this.selectedSnp.isExists) {
      this.allelForm.get('firstLetter').clearValidators();
      this.allelForm.get('firstLetter').updateValueAndValidity();
      this.allelForm.get('secondLetter').clearValidators();
      this.allelForm.get('secondLetter').updateValueAndValidity();
      this.allelForm.get('firstLetter').setValue('');
      this.allelForm.get('secondLetter').setValue('');
    } else {
      this.allelForm.get('firstLetter').setValidators(Validators.required);
      this.allelForm.get('firstLetter').updateValueAndValidity();
      this.allelForm.get('secondLetter').setValidators(Validators.required);
      this.allelForm.get('secondLetter').updateValueAndValidity();
    }

    this.letters = [
      snp.alfaLetter,
      snp.altLetterFirst,
      snp.altLetterSecond,
      snp.altLetterThird,
    ];
    this.letters = this.letters.filter((x) => x);
  }
  disableLetter(allel: string) {
    const values = [this.FirstLetter, this.SecondLetter];
    return values.some((x) => x == allel);
  }
  clearSelection(fieldName: string, ref: MatSelect) {
    this.allelForm.get(fieldName).setValue('');
  }
  hasFieldValue(fieldName: string) {
    return this.allelForm.get(fieldName)?.value?.toString().length > 0;
  }
  private _filter(value: number): PrototypeSnpByVer[] {
    const filterValue = value.toString();

    return this.data.filter((x) =>
      x.snpNumber.toString().includes(filterValue)
    );
  }
  addSnp() {
    this.dialogRef.close({
      type: 'add',
      fgSnp: this.selectedSnp,
      firstLetter: this.FirstLetter || '0',
      secondLetter: this.SecondLetter || '0',
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  get SnpNumber() {
    return this.allelForm.get('snpNumber').value;
  }

  get FirstLetter() {
    return this.allelForm.get('firstLetter').value;
  }
  get SecondLetter() {
    return this.allelForm.get('secondLetter').value;
  }
}
