<mat-card class="mt-50">
  <mat-card-title class="text-center p-tb-20"
    >Users loging from home history</mat-card-title
  >
  <table mat-table [dataSource]="usersList">
    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <!-- Name Column -->
    <ng-container matColumnDef="agentName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user">
        <span class="pointer" (click)="goTo(user.agentId)">{{
          user.agentName
        }}</span>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef>Login</th>
      <td mat-cell *matCellDef="let user">
        {{ user.startDate | date: "dd-MM-yyyy HH:mm" }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef>Logout</th>
      <td mat-cell *matCellDef="let user">
        <ng-container *ngIf="user.endDate !== '0001-01-01T00:00:00'">{{
          user.endDate | date: "dd-MM-yyyy HH:mm"
        }}</ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
