export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function intersect(array1: any[], array2: any[]) {
  return array1.filter((value) => array2.includes(value));
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function extrectVideoIdFromUrl(url: string) {
  return url?.split('/')[4]?.split('?')[0];
}

// export function lowercaseKeys(data: any[]) {
//   const lowerCase = (str) => str[0].toLowerCase() + str.slice(1);
//   return data.map((obj) =>
//     Object.assign(
//       {},
//       ...Object.entries(obj).map(([k, v]) => ({ [lowerCase(k)]: v }))
//     )
//   );
// }

export function lowercaseKeysRecursive(data: any) {
  const lowerCase = (str) => str[0].toLowerCase() + str.slice(1);
  if (!data) return;
  if (Array.isArray(data)) {
    return data.map((obj) =>
      Object.assign(
        {},
        ...Object.entries(obj).map(([k, v]) =>
          typeof v === 'object'
            ? { [lowerCase(k)]: lowercaseKeysRecursive(v) }
            : { [lowerCase(k)]: v }
        )
      )
    );
  }
  return Object.assign(
    {},
    ...Object.entries(data).map(([k, v]) =>
      typeof v === 'object'
        ? { [lowerCase(k)]: lowercaseKeysRecursive(v) }
        : { [lowerCase(k)]: v }
    )
  );
}
