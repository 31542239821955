import { AlgoBulletLink } from './AlgoBulletLink';
import { Language } from './Language';

export class AlgoBulletLinkLang {
  algoBulletLinkLangId: number;
  algoBulletLinkId: number;
  algoBulletLink: AlgoBulletLink;
  languageId: number;
  language: Language;
  title: string;
  description: string;
}
