<div style="text-align: center">
  <h1 mat-dialog-title>{{ getTitle() }}</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="data.details">
      <p [innerHtml]="data.details"></p>
    </ng-container>
    <ng-container *ngIf="!data.details">
      <p>Are You Sure?</p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button (click)="onYesClick()" cdkFocusInitial>Yes</button>
    <!--[mat-dialog-close]="data.animal" -->
  </div>
</div>
