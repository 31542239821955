import { I } from '@angular/cdk/keycodes';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { PrototypeCategory } from 'src/app/data/algorithm/PrototypeCategory';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

export enum AddModeEnum {
  FromList = 1,
  FromInput = 2,
}

@Component({
  selector: 'app-category-prototype',
  templateUrl: './category-prototype.component.html',
  styleUrls: ['./category-prototype.component.scss'],
})
export class CategoryPrototypeComponent implements OnInit, OnChanges {
  @Input() langId: number;
  filteredOptions: Observable<PrototypeCategory[]>;
  categories: PrototypeCategory[];
  categoryForm: FormGroup;
  isNotInList: boolean;
  category: PrototypeCategory;
  errorMessage: string;
  addMode: AddModeEnum = AddModeEnum.FromList;
  AddModeEnum = AddModeEnum;
  AlgoImageType = AlgoImageType;
  constructor(
    private fb: FormBuilder,
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.langId &&
      !changes.langId.isFirstChange() &&
      changes.langId.currentValue !== changes.langId.previousValue
    ) {
      this.getListPrototypeCategory();
    }
  }
  ngOnInit(): void {
    this.getListPrototypeCategory();
  }
  getListPrototypeCategory() {
    return this.algorithmService
      .getListPrototypeCategory(this.langId)
      .subscribe((data) => {
        this.categories = data;
        if (!this.category) {
          this.category = new PrototypeCategory();
          this.category.files = [];
        }
        this.initCategoryForm();
      });
  }
  setFilterdOptions() {
    this.filteredOptions = this.CategoryName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }
  onChangeMode(e: MatRadioChange) {
    this.category = new PrototypeCategory();
    this.category.files = [];
    this.createCategoryForm();
  }
  getPrototypeCategory(id: number) {
    return this.algorithmService
      .getPrototypeCategory(id, this.langId)
      .subscribe((data) => {
        this.category = data;
        this.category.color = `#${this.category.color}`;
        this.createCategoryForm();
      });
  }
  initCategoryForm() {
    this.createCategoryForm();
    this.setFilterdOptions();
    //this.onAddModeChange();
  }
  createCategoryForm() {
    this.categoryForm = this.fb.group({
      id: this.fb.control(this.category.categoryId),
      name: this.fb.control(this.category.name, Validators.required),
      color: this.fb.control(
        this.category.color ? this.category.color : '#000000',
        Validators.required
      ),
      orderBy: this.fb.control(this.category.orderBy, Validators.required),
      isPremium: this.fb.control(this.category.isPremium ? true : false),
    });
  }
  private _filter(value: number): PrototypeCategory[] {
    const filterValue = value?.toString();

    return this.categories?.filter((x) =>
      x.name.toString().includes(filterValue)
    );
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // when the user selects an option from the autocomplete
    const selectedCat = this.findCatByName(event.option.value);
    this.getPrototypeCategory(selectedCat.categoryId);
  }
  handleErrorOrSuccess(res: ErrorMessage | any, snackbarMassege: string) {
    if ((res as ErrorMessage)?.message) {
      const error = res as ErrorMessage;
      this.errorMessage = error.message;
    } else {
      this._snackBar
        .open(`Prototype category ${snackbarMassege}`, 'Close', {})
        ._dismissAfter(2000);
      this.getListPrototypeCategory();
    }
  }
  onSubmit() {
    if (this.categoryForm.valid) {
      this.category.categoryId = this.categoryForm.value.id
        ? this.categoryForm.value.id
        : 0;
      this.category.name = this.categoryForm.value.name;
      const color = this.categoryForm.value.color as string;
      this.category.color = color.replace('#', '');
      this.category.isPremium = this.categoryForm.value.isPremium;
      this.category.orderBy = this.categoryForm.value.orderBy;
      this.category.langId = this.langId;
      if (this.category.categoryId == 0) {
        this.algorithmService
          .addPrototypeCategory(this.category)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'added');
          });
      } else if (this.category.categoryId > 0) {
        this.algorithmService
          .setPrototypeCategory(this.category)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'updated');
          });
      }
    }
  }
  findCatByName(name: string) {
    return this.categories.find((x) => x.name === name);
  }
  onUploadFinished(event: AppImage) {
    const finded = this.category.files.find(
      (x) => x.fileType == event.fileType
    );
    if (finded) {
      this.category.files = this.category.files.filter(
        (x) => x.fileType != event.fileType
      );
    }
    this.category.files.push(event);
  }
  getImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  get CategoryName() {
    return this.categoryForm.get('name') as FormControl;
  }
  get CategoryColor() {
    return this.categoryForm.get('color') as FormControl;
  }
}
