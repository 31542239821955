import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Language } from 'src/app/data/Language';
import { NameAndId } from 'src/app/data/NameAndId';
import { LanguagesService } from 'src/app/Services/languages.service';

@Component({
  selector: 'app-autocomplete-foods',
  templateUrl: './autocomplete-foods.component.html',
  styleUrls: ['./autocomplete-foods.component.scss'],
})
export class AutocompleteFoodsComponent implements OnInit, AfterViewInit {
  @ViewChild('value') valueInput: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @ViewChild('spinner') spinner: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() data: string[];
  @Input() initialValue: string;
  @Input() placeholder: string;
  @Input() hasError: boolean;
  @Input() autoCompleteActiveFoodId: number;
  @Input() isRtl: boolean;
  @Input() showRanking: boolean = false;
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchTerm: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();

  textAlign: string;
  dir: string;
  inc = 0;
  isListenOnce: boolean;

  constructor() {}
  ngAfterViewInit(): void {
    if (this.autoCompleteActiveFoodId) {
      //this.valueInput.nativeElement.click();
      setTimeout(() => {
        this.trigger.openMenu();
      }, 0);
    }
  }

  ngOnInit(): void {
    if (this.isRtl) {
      this.dir = 'rtl';
      this.textAlign = 'right';
    } else {
      this.dir = 'ltr';
      this.textAlign = 'left';
    }
  }
  isNumber(value: any): boolean {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue);
  }
  elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;
    if (search.length >= 2) {
      this.searchTerm.emit(search);
      //console.log('onValueAutocompleteChanged:', this.inc++);
    }
  }

  menuOpened() {
    this.inputField.nativeElement.focus();

    document.body
      .querySelector('.mat-menu-panel')
      .addEventListener('scroll', (event: any) => {
        var element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          this.loadMore.emit(true);
          //console.log('scrolled');
        }
      });
  }

  onCheckboxClick(event: MatCheckboxChange) {}

  onValueSelected(e, id, name) {
    this.valueInput.nativeElement.value = name;
    /* var valuesDup = [...this.data];
    var value = valuesDup.filter((val) => {
      return val == selectedValue;
    }); */
    this.selectedValue.emit({ id, name });
  }
}
