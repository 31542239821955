import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgorithmVersion } from '../data/AlgorithmVersion';
import { WebImage } from '../data/WebImages';

@Injectable({
  providedIn: 'root',
})
export class WebImagesService {
  constructor(private http: HttpClient) {}
  getByType(typeId) {
    return this.http.get<WebImage[]>(
      `${environment.baseUrl}WebImages/GetWebImagesByType/${typeId}`
    );
  }
  getById(id) {
    return this.http.get<WebImage>(`${environment.baseUrl}WebImages/${id}}`);
  }
  post(image, typeId) {
    return this.http.post<any>(
      `${environment.baseUrl}WebImages/${typeId}`,
      image
    );
  }
}
