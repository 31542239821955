<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> פרטים אישיים </mat-panel-title>
      <!-- <mat-panel-description>
        מלא את הפרטים האישיים
        <mat-icon>account_circle</mat-icon>
      </mat-panel-description> -->
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm1"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm1"
    >
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>Full name</mat-label>
          <input matInput formControlName="fullName" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
      </div>
      <div class="d-flex-between">
        <mat-form-field class="d-block" appearance="fill">
          <mat-label>Id</mat-label>
          <input matInput formControlName="socialSecurityNum" />
        </mat-form-field>
        <mat-form-field class="d-block" appearance="fill">
          <mat-label>age</mat-label>
          <input matInput formControlName="age" />
        </mat-form-field>
      </div>
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>Select gender</mat-label>
          <mat-select formControlName="sexId">
            <mat-option value="1">Male</mat-option>
            <mat-option value="2">Female</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="d-block" appearance="fill">
          <mat-label>height</mat-label>
          <input matInput formControlName="height" />
        </mat-form-field>
      </div>
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>Weight</mat-label>
          <input matInput formControlName="weight" />
        </mat-form-field>
      </div>

      <mat-action-row>
        <!---->
        <button mat-button color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
    <mat-expansion-panel-header (click)="setStep(1)">
      <mat-panel-title> סוג התזונה </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      (ngSubmit)="saveThis()"
      *ngIf="lifestyleForm2"
      [formGroup]="lifestyleForm2"
    >
      <div class="mb-4">
        <p
          *ngFor="
            let item of lifestyleForm2.get('nutritionTypes')['controls'];
            let i = index
          "
        >
          <mat-checkbox [formControl]="item">{{
            nutritionData[i].name
          }}</mat-checkbox>
        </p>
      </div>

      <!-- sensitivityToFood -->

      <div class="mb-4">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>sensitivityToFood</mat-label>
          <textarea formControlName="sensitivityToFood" matInput></textarea>
        </mat-form-field>
      </div>
      <div class="mb-4">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>foodToAvoid</mat-label>
          <textarea formControlName="foodToAvoid" matInput></textarea>
        </mat-form-field>
      </div>

      <div class="mb-4">
        <p>Digest Problems?</p>
        <p
          *ngFor="
            let item of lifestyleForm2.get('digestionProblems')['controls'];
            let i = index
          "
        >
          <mat-checkbox [formControl]="item">{{
            digestionData && digestionData[i] && digestionData[i].name
          }}</mat-checkbox>
        </p>
      </div>

      <mat-action-row>
        <button mat-button type="button" color="warn" (click)="prevStep()">
          Previous
        </button>
        <button mat-button type="submit" color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> הרגלי שתייה </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm3"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm3"
    >
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>מים</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="waterNumGlassesPerDay"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>שתיה מתוקה/מוגזת</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="sweetNumGlassesPerDay"
          />
        </mat-form-field>
      </div>
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>שתייה דיאטטית</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="dietNumGlassesPerDay"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>קפה</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="coffeePerDay"
          />
        </mat-form-field>
      </div>
      <div class="d-flex-between">
        <mat-form-field appearance="fill">
          <mat-label>תה</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="teaPerDay"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>אלכוהול</mat-label>
          <input
            placeholder="מספר כוסות ביום"
            matInput
            formControlName="alcoholPerDay"
          />
        </mat-form-field>
      </div>
      <mat-action-row>
        <button mat-button type="button" color="warn" (click)="prevStep()">
          Previous
        </button>
        <button mat-button type="submit" color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> הרגלי אכילה </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm4"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm4"
    >
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>בוקר</mat-label>
        <textarea formControlName="eatingHabitsMorning" matInput> </textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>ביניים</mat-label>
        <textarea formControlName="eatingHabitsMiddle1" matInput> </textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>צהריים</mat-label>
        <textarea formControlName="eatingHabitsLunch" matInput> </textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>ביניים</mat-label>
        <textarea formControlName="eatingHabitsMiddle2" matInput> </textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>ערב</mat-label>
        <textarea formControlName="eatingHabitsSupper" matInput> </textarea>
      </mat-form-field>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Previous</button>
        <button mat-button color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> בריאות ואורח חיים </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm5"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm5"
    >
      <div>
        <label id="if-activities">Medicine</label>
        <mat-radio-group
          formControlName="isTakingDrugs"
          aria-labelledby="if-activities"
          class="example-radio-group"
        >
          <mat-radio-button
            (change)="ifMedicine($event)"
            class="example-radio-button"
            value="1"
          >
            Yes
          </mat-radio-button>
          <mat-radio-button
            (change)="ifMedicine($event)"
            class="example-radio-button"
            value="0"
          >
            No
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field
          style="width: 100%"
          *ngIf="showMedicineFlag"
          appearance="fill"
        >
          <textarea matInput formControlName="drugsTakenText"></textarea>
        </mat-form-field>
      </div>
      <div>
        <label id="if-activities">Smoking</label>
        <mat-radio-group
          formControlName="isSmoking"
          aria-labelledby="if-activities"
          class="example-radio-group"
        >
          <mat-radio-button
            (change)="ifSmoking($event)"
            class="example-radio-button"
            value="1"
          >
            Yes
          </mat-radio-button>
          <mat-radio-button
            (change)="ifSmoking($event)"
            class="example-radio-button"
            value="0"
          >
            No
          </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="showSmokingFlag">
          <mat-form-field style="width: 100%" appearance="fill">
            <label>Num of cigarettes per day</label>
            <textarea matInput formControlName="smokingTextNum"></textarea>
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <label>years of smoking</label>
            <textarea matInput formControlName="smokingTextYears"></textarea>
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <label>Quit before</label>
            <textarea matInput formControlName="smokingTextQuit"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

      <h4>שינה ומנוחה</h4>
      <mat-form-field style="width: 100%" appearance="fill">
        <label>Meditaion and relaxing activity</label>
        <textarea matInput formControlName="relaxingActivity"></textarea>
      </mat-form-field>

      <mat-form-field style="width: 100%" appearance="fill">
        <label>sleep Hours</label>
        <textarea matInput formControlName="sleepHours"></textarea>
      </mat-form-field>

      <mat-form-field style="width: 100%" appearance="fill">
        <label>are You In Stress</label>
        <textarea matInput formControlName="areYouInStress"></textarea>
      </mat-form-field>

      <mat-form-field style="width: 100%" appearance="fill">
        <label>what To Aprrove</label>
        <textarea matInput formControlName="whatToAprrove"></textarea>
      </mat-form-field>

      <div>
        <label id="if-activities">Activities</label>
        <mat-radio-group
          formControlName="ifActivities"
          aria-labelledby="if-activities"
          class="example-radio-group"
        >
          <mat-radio-button
            (change)="ifActivities($event)"
            class="example-radio-button"
            value="1"
          >
            Yes
          </mat-radio-button>
          <mat-radio-button
            (change)="ifActivities($event)"
            class="example-radio-button"
            value="0"
          >
            No
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <ng-container *ngIf="showActivitiesFlag">
        <app-test-activity-thumb
          *ngFor="let activity of lifestyleLocal.lifeStyleExercises"
          [ifActivities]="lifestyleLocal.ifActivities"
          [index]="activity.index"
          [name]="activity.name"
          [duration]="activity.duration"
          [numInWeek]="activity.numInWeek"
          (emitActivity)="emitActivity($event)"
        ></app-test-activity-thumb>
      </ng-container>
      <button
        *ngIf="showActivitiesFlag"
        mat-raised-button
        color="accent"
        type="button"
        (click)="addActivity()"
      >
        Add Activitiy +
      </button>
      <br />
      <div>
        <label>מצב בריאותך</label>
        <mat-radio-group
          formControlName="healthStatus"
          class="example-radio-group"
        >
          <mat-radio-button
            *ngFor="let status of healthStatusData"
            class="example-radio-button"
            [value]="status"
          >
            {{ status }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Previous</button>
        <button mat-button color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> מחלות עבר והווה </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm6"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm6"
    >
      <div>
        <div *ngFor="let label of LifeStyleDiseaseLabels; let i = index">
          <div formGroupName="d{{ i }}">
            <label>{{ label }} </label>
            <mat-radio-group
              formControlName="ifActive"
              class="example-radio-group"
            >
              <mat-radio-button class="example-radio-button" value="1">
                Yes
              </mat-radio-button>
              <mat-radio-button class="example-radio-button" value="0">
                No
              </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="showDiseasesFields(i)">
              <input type="hidden" formControlName="diseaseLabel" />
              <mat-form-field appearance="fill" style="width: 100%">
                <label>שם המחלה</label>
                <input
                  matInput
                  formControlName="diseaseName"
                  placeholder="שם המחלה"
                />
              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%">
                <label>מתי התחילה</label>
                <input
                  matInput
                  formControlName="whenStarted"
                  placeholder="מתי התחילה"
                />
              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%">
                <label>טיפול תרופתי</label>
                <input
                  matInput
                  formControlName="medCare"
                  placeholder="טיפול תרופתי"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <mat-action-row>
        <button mat-button color="warn" type="button" (click)="prevStep()">
          Previous
        </button>
        <button mat-button color="primary">Next</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> דפוסי אכילה </mat-panel-title>
    </mat-expansion-panel-header>
    <form
      *ngIf="lifestyleForm7"
      (ngSubmit)="saveThis()"
      [formGroup]="lifestyleForm7"
    >
      <div *ngFor="let label of eatingPatternLabels; let i = index">
        <div>
          <label>{{ label }} </label>
          <mat-radio-group [formControlName]="i" class="example-radio-group">
            <mat-radio-button
              *ngFor="let pattern of eatingPatternData"
              class="example-radio-button"
              [value]="pattern"
            >
              {{ pattern }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <mat-action-row>
        <button mat-button color="warn" type="button" (click)="prevStep()">
          הקודם
        </button>
        <button mat-button color="primary">סיום</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</mat-accordion>
