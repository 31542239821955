<select
  (change)="onCatChange($event.target.value)"
  [(ngModel)]="selectedCategory"
>
  <option *ngFor="let category of categories" value="{{ category.categoryId }}">
    {{ category.categoryName }}
  </option>
</select>
<select
  (change)="onSubCatChange($event.target.value)"
  [(ngModel)]="selectedSubCategory"
>
  <option *ngFor="let subcat of catSubCats" value="{{ subcat.subCategoryId }}">
    {{ subcat.subCategoryName }}
  </option>
</select>

<table mat-table [dataSource]="subCatResults" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="snpNumber">
    <th mat-header-cell *matHeaderCellDef style="width: 200px">SnpNumber</th>
    <td mat-cell *matCellDef="let element">RS{{ element.snp.snpNumber }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="result">
    <th mat-header-cell *matHeaderCellDef style="width: 200px">Result</th>
    <td mat-cell *matCellDef="let element">{{ element.snpResult.result }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef style="width: 200px">Value</th>
    <td mat-cell *matCellDef="let element">{{ element.snpResult.value }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
Sum: {{ subCatSum }}
