import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubCategoryResultsService } from '../Services/sub-category-results.service';
//import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Language } from '../data/Language';

@Component({
  selector: 'app-text-editor-dialog',
  templateUrl: './text-editor-dialog.component.html',
  styleUrls: ['./text-editor-dialog.component.css'],
})
export class TextEditorDialogComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
  };
  //@ViewChild('editor') editorComponent: CKEditorComponent;
  name = 'edit me';
  shortDescription = 'edit me';
  description = 'edit me';
  recomendations = 'edit me';
  dir: 'rtl' | 'ltr';
  langName: string;
  translatedTexts = {
    title: {
      Heb: 'כותרת',
      Eng: 'Title',
    },
    description: {
      Heb: 'סיכום',
      Eng: 'Description',
    },
    shortDescription: {
      Heb: 'סיכום קצר',
      Eng: 'Short Description',
    },
    recomendations: {
      Heb: 'המלצות',
      Eng: 'Recomendations',
    },
    changeTextBtn: {
      Heb: 'שנה טקסט',
      Eng: 'Change text',
    },
  };

  constructor(
    private dialogRef: MatDialogRef<TextEditorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private subCategoryResultsService: SubCategoryResultsService
  ) {}
  //public Editor = ClassicEditor;

  ngOnInit(): void {
    //selectedSubCategory.subCategoryResults[0].description
    //subCat: subCat, inx: inx,type:type
    this.name = this.data.subCatResult.name;
    this.shortDescription = this.data.subCatResult.shortDescription;
    this.description = this.data.subCatResult.description;
    this.recomendations = this.data.subCatResult.recomendations;
    this.dir = this.data.dir;
    this.langName = this.data.langName;
  }

  translatedTitle() {
    return this.translatedTexts.title[this.langName];
  }
  translatedShortDescription() {
    return this.translatedTexts.shortDescription[this.langName];
  }
  translatedDescription() {
    return this.translatedTexts.description[this.langName];
  }
  translatedRecomendartions() {
    return this.translatedTexts.recomendations[this.langName];
  }
  translatedBtn() {
    return this.translatedTexts.changeTextBtn[this.langName];
  }

  saveText() {
    /*
    if (this.data.type==1)
    {
      this.data.subCatResult.description=this.editorComponent.editorInstance.getData();
    }
    else
    {
      this.data.subCatResult.recomendations=this.editorComponent.editorInstance.getData();
    }
    this.subCategoryResultsService.put(this.data.subCatResult).subscribe(obj=>
      {
        this.dialogRef.close();
      })
   */
    this.data.subCatResult.shortDescription = this.shortDescription;
    this.data.subCatResult.description = this.description;
    this.data.subCatResult.recomendations = this.recomendations;
    this.data.subCatResult.name = this.name;
    this.subCategoryResultsService
      .putLangMin(this.data.subCatResult)
      .subscribe((obj) => {
        this.dialogRef.close();
      });
  }
}
