import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
//import { TubesPackagesService } from 'src/app/Services/tubes-packages.service';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { TubePackagesPusherService } from 'src/app/Services/tube-packages-pusher.service';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { TubePackageEnum } from 'src/app/Enums/StatusesEnum';
import { HttpFilterHelper } from 'src/app/Utils/http-filter-helper';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';

@Component({
  selector: 'app-all-tube-packages-for-office',
  templateUrl: './all-tube-packages-for-office.component.html',
  styleUrls: ['./all-tube-packages-for-office.component.css'],
})
export class AllTubePackagesForOfficeComponent implements OnInit {
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  tubesPackages: TubesPackage[];
  constructor(
    private router: Router,
    private tubesPackagesService: TubePackagesPusherService,
    private filterMemoryService: FilterMemoryService
  ) {
    this.headFilter = this.filterMemoryService.getDefaultFilter();
    this.headFilter.statuseIds = [
      TubePackageEnum.Closed,
      TubePackageEnum.Final,
      TubePackageEnum.Draft,
    ];
    this.tubesPackagesService.getAll(this.headFilter);
  }

  ngOnInit(): void {
    this.tubesPackagesService.tubesPackages$.subscribe((data) => {
      if (data.list && data.metaData) {
        this.tubesPackages = data.list;
        this.metaData = data.metaData;
      }
    });
  }
  addPackage() {
    this.tubesPackagesService.post(new TubesPackage());
  }
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    this.headFilter = e;
    this.tubesPackagesService.getAll(this.headFilter);
  }
}
