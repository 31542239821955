import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Country } from 'src/app/data/Country';

@Component({
  selector: 'app-autocomplete-countries',
  templateUrl: './autocomplete-countries.component.html',
  styleUrls: ['./autocomplete-countries.component.scss'],
})
export class AutocompleteCountriesComponent implements OnInit {
  @ViewChild('value') valueInput: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @Input() data: Country[];
  @Input() initialValue: any;
  @Input() widthExp: string;
  @Input() textAlign: string = 'right';
  @Input() direction: string = 'rtl';
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  filteredValuesOptions: any[];
  country: Country;
  constructor() {}

  ngOnInit(): void {
    if (!this.initialValue) {
      this.country = new Country();
      this.country.countryCode = '972';
      this.country.countryId = 1;
      this.country.name = 'Israel';
      this.country.nameCode = 'IL';
    } else {
      this.country = this.data.find((x) => x.countryId == this.initialValue);
    }

    setTimeout(() => {
      this.valueInput.nativeElement.value = this.country.countryCode;
    }, 500);
  }
  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;

    var valuesDup = [...this.data];
    this.filteredValuesOptions = valuesDup.filter((val) => {
      return val.countryCode.indexOf(search) != -1;
    });
  }

  focusInput() {
    this.inputField.nativeElement.focus();
  }

  onValueSelected(selectedValue: number) {
    this.country = this.data.find((x) => x.countryId == selectedValue);
    this.valueInput.nativeElement.value = this.country.countryCode;
    this.selectedValue.emit(this.country.countryId);
  }
}
