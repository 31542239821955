import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoAlleleCombination } from '../data/algorithm/AlgoAlleleCombination';
import { AlgoCategory } from '../data/algorithm/AlgoCategory';
import { AlgoResultType } from '../data/algorithm/AlgoResultType';
import { AlgoSnp } from '../data/algorithm/AlgoSnp';
import { AlgoSubCategory } from '../data/algorithm/AlgoSubCategory';
import { AlgoSubCategoryResult } from '../data/algorithm/AlgoSubCategoryResult';
import { AlgoSubCategoryResultType } from '../data/algorithm/AlgoSubCategoryResultType';
import { AlgoSubCategorySnp } from '../data/algorithm/AlgoSubCategorySnp';
import { AlgoUserSummary } from '../data/algorithm/AlgoUserSummary';
import { AppImage } from '../data/algorithm/AppImage';
import { BaseResponce } from '../data/algorithm/BaseResponce';
import { BulletCrossResult } from '../data/algorithm/BulletCrossResult';
import { Laboratory } from '../data/algorithm/Laboratory';
import { LaboratorySettings } from '../data/algorithm/LaboratorySettings';
import { LabResponceReport } from '../data/algorithm/LabResponceReport';
import { LabResultPackage } from '../data/algorithm/LabResultPackage';
import { LabVersion } from '../data/algorithm/LabVersion';
import { PrototypeCategory } from '../data/algorithm/PrototypeCategory';
import { PrototypeResultType } from '../data/algorithm/PrototypeResultType';
import { PrototypeSnp } from '../data/algorithm/PrototypeSnp';
import { PrototypeSnpByVer } from '../data/algorithm/PrototypeSnpByVer';
import { PrototypeSubCategory } from '../data/algorithm/PrototypeSubCategory';
import { SnpSearchResult } from '../data/algorithm/SnpSearchResult';
import { ErrorMessage } from '../data/ErrorMessage';
import { HeadFilter } from '../data/HeadFilter';
import { PaginationMetadata } from '../data/PaginationMetadata';
import { SubCategoryForApp } from '../data/SubCategoryForApp';
import { TubeTestResultForApp } from '../data/TubeTestResultForApp';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

export interface resultStat {
  subCategoryForApp: SubCategoryForApp[];
  tubeTestResultForApp: TubeTestResultForApp[];
}
@Injectable({
  providedIn: 'root',
})
export class AlgorithmService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient) {}

  //#region SNPS
  getSnpsWithPaging(headFilter: HeadFilter) {
    const params =
      this.httpFilterHelper.createSnpsPathFromHeadFilter(headFilter);
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/GetSnpsWithPaging/${params}`
    );
  }
  searchSnpsWithPaging(headFilter: HeadFilter) {
    const params =
      this.httpFilterHelper.createSearchSnpsPathFromHeadFilter(headFilter);
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/SearchSnpsWithPaging/${params}`
    );
  }
  postSnp(snp: PrototypeSnp, langId: number) {
    return this.http.post<PrototypeSnp | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PostSnp/${langId}`,
      snp
    );
  }
  putSnp(snp: PrototypeSnp, langId: number) {
    return this.http.put<PrototypeSnp>(
      `${environment.baseUrl}Algorithm/PutSnp/${langId}`,
      snp
    );
  }
  getSnpByNumber(snpNumber: number) {
    return this.http.get<PrototypeSnp>(
      `${environment.baseUrl}Algorithm/GetSnp/${snpNumber}`
    );
  }

  //#endregion

  //#region LABS
  getLabs(langId: number) {
    return this.http.get<Laboratory[]>(
      `${environment.baseUrl}Algorithm/GetLabs/${langId}`
    );
  }
  getLabWithVersions(langId: number, labId: number) {
    return this.http.get<Laboratory[]>(
      `${environment.baseUrl}Algorithm/GetLabWithVersions/${langId}/${labId}`
    );
  }
  setLabVersion(labVersion: LabVersion) {
    return this.http.put<any>(
      `${environment.baseUrl}Algorithm/LabVersionSet`,
      labVersion
    );
  }
  setLaboratoryVersionSettings(
    laboratorySettings: LaboratorySettings,
    versionName: string,
    langId: number
  ) {
    return this.http.put<any>(
      `${environment.baseUrl}Algorithm/SetLaboratoryVersionSettings/${versionName}/${langId}`,
      laboratorySettings
    );
  }
  createLabVersion(
    laboratorySettings: LaboratorySettings,
    langId: number,
    labId: number,
    labName: string
  ) {
    return this.http.post<any>(
      `${environment.baseUrl}Algorithm/LabVersionCreate/${langId}/${labId}/${labName}`,
      laboratorySettings
    );
  }
  getVersionSetting(versionId: number) {
    return this.http.get<LaboratorySettings>(
      `${environment.baseUrl}Algorithm/VersionSettingGet/${versionId}`
    );
  }
  unlockVersion(versionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/UnlockVersion/${versionId}`
    );
  }
  lockVersion(versionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/LockVersion/${versionId}`
    );
  }
  getLabFileTypes() {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}Algorithm/LabFileTypesGet`
    );
  }
  getLabPdfTemplates() {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}Algorithm/LabPdfTemplatesGet`
    );
  }
  duplicateVersion(
    fromVersion: number,
    toVersion: number,
    rewriteSettings: boolean
  ) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/DuplicateVersion/${fromVersion}/${toVersion}/${rewriteSettings}`
    );
  }
  getLabResultPackagesWithPaging(
    langId: number,
    pageId: number,
    pageSize: number
  ) {
    return this.http.get<{
      packages: LabResultPackage[];
      pagination: PaginationMetadata;
    }>(
      `${environment.baseUrl}Algorithm/LabResultPackages/${langId}/${pageId}/${pageSize}`
    );
  }
  setLab(lab: Laboratory) {
    return this.http.put<Laboratory>(
      `${environment.baseUrl}Algorithm/SetLab`,
      lab
    );
  }
  addLab(lab: Laboratory) {
    return this.http.post<Laboratory>(
      `${environment.baseUrl}Algorithm/AddLab`,
      lab
    );
  }

  //#endregion

  //#region SEARCH
  searchSnps(snpNum: string, versionId: number, langId: number) {
    return this.http.get<SnpSearchResult[]>(
      `${environment.baseUrl}Algorithm/SearchAlgoSnps/${snpNum}/${versionId}/${langId}`
    );
  }
  //#endregion

  //#region CATEGORIES SNPS
  getAlgoSnpSubCategory(suCatId: number) {
    return this.http.get<AlgoSnp[]>(
      `${environment.baseUrl}Algorithm/AlgoSnpSubCatGet/${suCatId}`
    );
  }
  getSubCatSnpByNum(algoId: number, subCatId: number, langId: number) {
    return this.http.get<AlgoSubCategorySnp>(
      `${environment.baseUrl}Algorithm/SubCatSnpGetByNum/${algoId}/${subCatId}/${langId}`
    );
  }
  setSnpAllele(snpAllele: AlgoAlleleCombination, langId: number) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SetSnpAllele/${langId}`,
      snpAllele
    );
  }
  setSnp(snp: AlgoSubCategorySnp) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SetSnp`,
      snp
    );
  }
  removeSubCatSnp(snpId: number, subCategoryId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SubCatSnpRemove/${snpId}/${subCategoryId}`
    );
  }
  getAvailableSnpForSubCat(subCatId: number) {
    return this.http.get<PrototypeSnpByVer[]>(
      `${environment.baseUrl}Algorithm/AvailableSnpForSubCatGet/${subCatId}`
    );
  }
  addSnpToSubCat(
    snpNum: number,
    subCatId: number,
    langId: number,
    firstLetter: string,
    secondLetter: string
  ) {
    return this.http.get<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SnpToSubCatAdd/${snpNum}/${subCatId}/${langId}/${firstLetter}/${secondLetter}`
    );
  }
  //#endregion

  //#region CATEGORY RESULT
  getSubCatResultTypes(subCatId: number, langId: number) {
    return this.http.get<AlgoSubCategoryResultType[]>(
      `${environment.baseUrl}Algorithm/SubCatResultTypesGet/${subCatId}/${langId}`
    );
  }
  getSubCatResultByType(subCatId: number, langId: number, typeId: number) {
    return this.http.get<AlgoSubCategoryResult>(
      `${environment.baseUrl}Algorithm/SubCatResultByTypeGet/${subCatId}/${langId}/${typeId}`
    );
  }
  setSubCategoryResult(subCategoryResult: AlgoSubCategoryResult) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SetSubCategoryResult`,
      subCategoryResult
    );
  }
  getAvailableResultTypes(subCatId: number, langId: number) {
    return this.http.get<AlgoResultType[]>(
      `${environment.baseUrl}Algorithm/AVailableResultTypesGet/${subCatId}/${langId}`
    );
  }
  getCatResultTypes(catId: number, langId: number) {
    return this.http.get<AlgoSubCategoryResultType[]>(
      `${environment.baseUrl}Algorithm/CatResultTypesGet/${catId}/${langId}`
    );
  }
  AddResultType(versionId: number, subCatId: number, resultTypeId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/AddResultType/${versionId}/${subCatId}/${resultTypeId}`
    );
  }
  removeResultType(resultId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}Algorithm/ResultTypeRemove/${resultId}`
    );
  }
  subCatPrototypeTypesGet(subCatId: number, langId: number) {
    return this.http.get<PrototypeResultType[]>(
      `${environment.baseUrl}Algorithm/SubCatPrototypeTypesGet/${subCatId}/${langId}`
    );
  }
  getCatPrototypeTypes(catId: number, langId: number) {
    return this.http.get<PrototypeResultType[]>(
      `${environment.baseUrl}Algorithm/CatResultTypesGet/${catId}/${langId}`
    );
  }
  //#endregion

  //#region CATEGORIES
  getAlgoCategory(catId: number, langId: number) {
    return this.http.get<AlgoCategory>(
      `${environment.baseUrl}Algorithm/AlgoCategoryGet/${catId}/${langId}`
    );
  }
  getAvailableCategories(versionId: number, langId: number) {
    return this.http.get<PrototypeCategory[]>(
      `${environment.baseUrl}Algorithm/GetAvailableCategoryForVersion/${versionId}/${langId}`
    );
  }
  addCategory(category: AlgoCategory) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/AddCategory`,
      category
    );
  }
  setCategory(category: AlgoCategory) {
    return this.http.put<any>(
      `${environment.baseUrl}Algorithm/SetCategory`,
      category
    );
  }
  getCatsWithSubCats(versionId: number, langId: number) {
    return this.http.get<AlgoCategory[]>(
      `${environment.baseUrl}Algorithm/GetCatsWithSubCats/${versionId}/${langId}`
    );
  }
  removeCategory(categoryId: number, versionId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/CategoryRemove/${categoryId}/${versionId}`
    );
  }
  //#endregion

  //#region SUBCATEGORIES
  addSubCategory(subCategory: AlgoSubCategory) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/AddSubCategory`,
      subCategory
    );
  }
  setSubCategory(subCategory: AlgoSubCategory, langId: number) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SetSubCategory/${langId}`,
      subCategory
    );
  }
  getAvailableSubCategories(versionId: number, catId: number, langId: number) {
    return this.http.get<PrototypeSubCategory[]>(
      `${environment.baseUrl}Algorithm/GetAvailableSubCategoryForVersion/${versionId}/${catId}/${langId}`
    );
  }
  removeSubCategory(subCatId: number, versionId: number) {
    return this.http.delete<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/SubCategoryRemove/${subCatId}/${versionId}`
    );
  }
  toggleActivateSubCat(subCatId: number, isActive: boolean) {
    return this.http.get<PrototypeSubCategory[]>(
      `${environment.baseUrl}Algorithm/ActivateDeactivateSubCat/${subCatId}/${isActive}`
    );
  }
  //#endregion

  //#region UPLOAD LAB RESULT
  uploadLabResult(versionId: number, file: any) {
    return this.http.post<LabResponceReport>(
      `${environment.baseUrl}Uploader/UploadLabResult/${versionId}`,
      file
    );
  }
  //#endregion

  //#region FILES
  getFilesByType(typeId: number, langId: number) {
    return this.http.get<AppImage[]>(
      `${environment.baseUrl}Files/FilesByType/${typeId}/${langId}`
    );
  }
  uploadAlgoImage(image: any, type: number, langId: number = null) {
    return this.http.post<AppImage | ErrorMessage>(
      `${environment.baseUrl}Uploader/UploadAlgoImages/${type}/${langId}`,
      image
    );
  }
  //#endregion

  //#region PROTOTYPE CATEGORY
  getListPrototypeCategory(langId: number) {
    return this.http.get<PrototypeCategory[]>(
      `${environment.baseUrl}Algorithm/PrototypeCategoryGetList/${langId}`
    );
  }
  getPrototypeCategory(catId: number, langId: number) {
    return this.http.get<PrototypeCategory>(
      `${environment.baseUrl}Algorithm/PrototypeCategoryGet/${catId}/${langId}`
    );
  }
  addPrototypeCategory(category: PrototypeCategory) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeCategoryAdd`,
      category
    );
  }
  setPrototypeCategory(category: PrototypeCategory) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeCategorySet`,
      category
    );
  }
  //#endregion

  //#region PROTOTYPE SUBCATEGORY
  getListPrototypeSubCategory(langId: number) {
    return this.http.get<PrototypeSubCategory[]>(
      `${environment.baseUrl}Algorithm/PrototypeSubCategoryGetList/${langId}`
    );
  }
  getPrototypeSubCategory(catId: number, langId: number) {
    return this.http.get<PrototypeSubCategory>(
      `${environment.baseUrl}Algorithm/PrototypeSubCategoryGet/${catId}/${langId}`
    );
  }
  addPrototypeSubCategory(category: PrototypeSubCategory) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeSubCategoryAdd`,
      category
    );
  }
  setPrototypeSubCategory(category: PrototypeSubCategory) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeSubCategorySet`,
      category
    );
  }
  //#endregion

  //#region PROTOTYPE RESULT TYPE
  getListPrototypeResultType(langId: number) {
    return this.http.get<AlgoResultType[]>(
      `${environment.baseUrl}Algorithm/PrototypeResultTypesListGet/${langId}`
    );
  }
  getPrototypeResultType(typeId: number, langId: number) {
    return this.http.get<AlgoResultType>(
      `${environment.baseUrl}Algorithm/PrototypeResultTypeGet/${typeId}/${langId}`
    );
  }
  addPrototypeResultType(resultType: AlgoResultType) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeResultTypeAdd`,
      resultType
    );
  }
  setPrototypeResultType(resultType: AlgoResultType) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Algorithm/PrototypeResultTypeSet`,
      resultType
    );
  }
  //#endregion

  //#region Recalculate RESULTS
  recalculateResultsByPackage(packageId: number) {
    return this.http.get<LabResponceReport>(
      `${environment.baseUrl}Algorithm/RecalculateResultsByPackage/${packageId}`
    );
  }
  recalculateResultsByVersion(versionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/RecalculateResultsByVersion/${versionId}`
    );
  }
  RecalculateResultsByBarcode(barcode: string) {
    return this.http.get<any>(
      `${environment.baseUrl}Algorithm/RecalculateResultsByBarcode/${barcode}`
    );
  }
  //#endregion

  //#region DNA REPORT
  getUserSummaryStatByCat(
    barcode: string,
    langId: number,
    imgType: number,
    catId = -1
  ) {
    // catId = -1 - all categories
    return this.http.get<AlgoUserSummary | AlgoUserSummary[]>(
      `${environment.baseUrl}Algorithm/SummaryUserStatByCat/${barcode}/${catId}/${langId}/${imgType}`
    );
  }
  getResultStatByBarcode(barcode: string, catId: number, langId: number) {
    return this.http.get<resultStat>(
      `${environment.baseUrl}Algorithm/StatResultByBarcodeGet/${barcode}/${catId}/${langId}`
    );
  }
  getAlgoCrossingGroupByBarcode(barcode: string, langId: number) {
    return this.http.get<BulletCrossResult[]>(
      `${environment.baseUrl}Algorithm/GetAlgoCrossingGroupByBarcode/${barcode}/${langId}`
    );
  }
  //#endregion
  //
  //
}
