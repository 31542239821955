import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MealToNutrient } from 'src/app/data/MealToNutrient';
import { FoodNutrientsZameret } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-vitemins-dialog',
  templateUrl: './vitemins-dialog.component.html',
  styleUrls: ['./vitemins-dialog.component.scss'],
})
export class ViteminsDialogComponent implements OnInit {
  mealToNutrients: MealToNutrient[];
  productId: number;
  constructor(
    public dialogRef: MatDialogRef<ViteminsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mealToNutrients = this.data.mealToNutrients;
    this.productId = this.data.productId;
  }

  getNutriVitemins(): any[] {
    var c = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_c
    );
    var b12 = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_b12
    );
    var iron = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.iron
    );
    var magnesium = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.magnesium
    );
    var folate = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.folate
    );
    var calcium = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.calcium
    );
    var zinc = this.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.zinc
    );

    var viteminsArr = [c, b12, iron, magnesium, folate, calcium, zinc];
    var viteminsArr2 = [];

    viteminsArr.forEach((vitemin: MealToNutrient) => {
      if (vitemin && vitemin.amount > 0) {
        viteminsArr2.push({
          name: vitemin.foodNutrientName.replace('ויטמין ', ''),
          amount: vitemin.amount,
          dailyAmount: vitemin.dailyAmount,
        });
      }
    });

    viteminsArr2.forEach((vit) => {
      switch (vit.name) {
        case 'ברזל':
          return (vit.name = 'iron');
        case 'מגנזיום':
          return (vit.name = 'magnesium');
        case 'חומצה פולית':
          return (vit.name = 'folate');
        case 'סידן':
          return (vit.name = 'calcium');
        case 'אבץ':
          return (vit.name = 'zinc');
      }
    });

    return viteminsArr2;
  }
}
