import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { ZoomEvent } from 'src/app/data/ZoomEvent';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { ZoomEventsService } from 'src/app/Services/zoom-events.service';

@Component({
  selector: 'app-zoom-events-edit',
  templateUrl: './zoom-events-edit.component.html',
  styleUrls: ['./zoom-events-edit.component.scss'],
})
export class ZoomEventsEditComponent implements OnInit {
  zoomEvent: ZoomEvent;
  zoomEventForm: FormGroup;
  errorMessage: string;
  langId = 1;
  AlgoImageType = AlgoImageType;
  constructor(
    private dialogRef: MatDialogRef<ZoomEventsEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private zoomEventsService: ZoomEventsService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data === 0) {
      this.zoomEvent = new ZoomEvent();
      this.zoomEvent.zoomEventId = this.data;
      this.zoomEvent.image = new AppImage();
      this.createFormGroup();
    } else {
      this.getZoomEvent();
    }
  }

  getZoomEvent() {
    this.zoomEventsService.getZoomEventById(this.data).subscribe((data) => {
      this.zoomEvent = data;
      this.createFormGroup();
    });
  }

  createFormGroup() {
    this.zoomEventForm = this.fb.group({
      eventId: this.fb.control(this.zoomEvent?.zoomEventId, [
        Validators.required,
      ]),
      eventName: this.fb.control(this.zoomEvent?.zoomEventName, [
        Validators.required,
      ]),
      eventDesc: this.fb.control(this.zoomEvent?.zoomEventDescription, [
        Validators.required,
      ]),
      eventLink: this.fb.control(
        this.zoomEvent?.zoomEventLink,
        Validators.required
      ),
      eventDate: this.fb.control(
        this.zoomEvent?.zoomEventDate,
        Validators.required
      ),
    });
  }

  getImage() {
    if (this.zoomEvent.image?.fileId)
      return `${this.zoomEvent.image.webPath}/${this.zoomEvent.image.fileName}`;
  }

  getCheckedImage() {
    if (this.zoomEvent.image?.fileId) return this.zoomEvent.image.fileId;
  }

  onUploadFinished(event: AppImage) {
    if (event) {
      this.zoomEvent.image = event;
    }
  }

  onSubmit(form: FormGroup) {
    if (form.invalid) return;

    this.zoomEvent.zoomEventId = form.value.eventId;
    this.zoomEvent.zoomEventName = form.value.eventName;
    this.zoomEvent.zoomEventDescription = form.value.eventDesc;
    this.zoomEvent.zoomEventLink = form.value.eventLink;
    this.zoomEvent.zoomEventDate = form.value.eventDate;
    if (this.zoomEvent.zoomEventId == 0) {
      this.zoomEventsService.addZoomEvent(this.zoomEvent).subscribe((data) => {
        this.dialogRef.close({ type: 'add' });
      });
    } else {
      this.zoomEventsService.setZoomEvent(this.zoomEvent).subscribe((data) => {
        this.dialogRef.close({ type: 'update' });
      });
    }
  }
}
