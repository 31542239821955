import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FileExtension } from 'src/app/data/FileExtension';
import { FilesService } from 'src/app/Services/files.service';

@Component({
  selector: 'app-file-extensions',
  templateUrl: './file-extensions.component.html',
  styleUrls: ['./file-extensions.component.scss'],
})
export class FileExtensionsComponent implements OnInit {
  langId: number = 1;
  fileExtensions: FileExtension[];
  editMode: boolean;
  editInx: number;
  addDisabled: boolean;

  constructor(
    private filesService: FilesService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getFileExtensions();
  }

  getFileExtensions(fromCancelEditMode = false) {
    this.filesService.getFileExtensions(this.langId).subscribe((data) => {
      this.fileExtensions = data;
      if (!fromCancelEditMode) {
        this.onCancelEditMode();
      }
    });
  }
  isEditMode(item: FileExtension) {
    return this.editInx == item.extensionId && this.editMode;
  }
  isViewMode(item: FileExtension) {
    return (
      !this.editMode || (this.editMode && this.editInx !== item.extensionId)
    );
  }
  editDisabled(item: FileExtension) {
    return this.editInx != item.extensionId && this.editMode;
  }
  onEditMode(item: FileExtension) {
    this.editInx = item.extensionId;
    this.editMode = true;
    this.addDisabled = true;
  }
  onCancelEditMode(item: FileExtension = null) {
    this.editInx = null;
    this.editMode = false;
    this.addDisabled = false;
    this.getFileExtensions(true);
    /* if (item && item.extensionId == 0) {
      this.fileExtensions.splice(this.fileExtensions.length - 1, 1);
    } */
  }
  onSignatureChange(item: FileExtension, index: number, form: NgForm) {
    setTimeout(() => {
      if (item.havingSignature) {
        form.controls[`signature-${index}`].setErrors({ required: true });
      } else {
        form.controls[`signature-${index}`].setErrors(null);
      }
    }, 300);
  }
  onSaveSuccess(message: string) {
    this._matSnackBar.open(message, 'Close', {})._dismissAfter(2000);
    this.getFileExtensions();
  }
  onSaveError(errorMessage: string) {
    this._matSnackBar.open(errorMessage, 'Close', {})._dismissAfter(2000);
  }
  handleServerResponse(response: ErrorMessage | any) {
    if (response?.message) {
      this.onSaveError(response.message);
    } else {
      this.onSaveSuccess('Extension updated');
    }
  }
  onSave(item: FileExtension, form: NgForm) {
    if (!form.valid) return;
    if (item.extensionId == 0) {
      this.filesService
        .addFileExtension(this.langId, item)
        .subscribe((data) => {
          this.handleServerResponse(data);
        });
    } else {
      this.filesService
        .editFileExtension(this.langId, item)
        .subscribe((data) => {
          this.handleServerResponse(data);
        });
    }
  }
  onAddNewExtension() {
    this.addDisabled = true;
    const newExtension = new FileExtension();
    newExtension.extensionId = 0;
    this.editInx = newExtension.extensionId;
    this.editMode = true;
    this.fileExtensions.push(newExtension);
  }
}
