import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Street } from '../data/Street';
import { BarStreet } from '../data/BarStreet';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StreetsService {
  constructor(private http: HttpClient) {}
  getByCity(cityId: number) {
    return this.http.get<BarStreet[]>(
      `${environment.baseUrl}Streets/${cityId}`
    );
  }
  search(cityId: number, val) {
    if (!cityId || !val) return of([]);
    return this.http.get<BarStreet[]>(
      `${environment.baseUrl}Streets/Search/${cityId}/${val}`
    );
  }
}
