<table class="table" mat-table [dataSource]="dataSource" #table>
  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">
      <button mat-icon-button class="table__add" (click)="addNewSnp()">
        <mat-icon>add</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="snpNumber">
    <th mat-header-cell *matHeaderCellDef>SNP Num.</th>
    <td mat-cell *matCellDef="let element">
      {{ element.snpNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="coordinate">
    <th mat-header-cell *matHeaderCellDef>Coordinate</th>
    <td mat-cell *matCellDef="let element">{{ element.coordinate }}</td>
  </ng-container>
  <ng-container matColumnDef="chromosome">
    <th mat-header-cell *matHeaderCellDef>Chromosome</th>
    <td mat-cell *matCellDef="let element">{{ element.chromosome }}</td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        class="table__edit"
        (click)="editSnp(element.snpNumber)"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
