import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Client } from 'src/app/data/Client';
import { Deal } from 'src/app/data/Deal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaymentMethod } from 'src/app/data/PaymentMethod';
import { ProductType } from 'src/app/data/ProductType';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PaymentMethodService } from 'src/app/Services/payment-method.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/data/Product';
import { TranzillaTransaction } from 'src/app/data/TranzillaTransaction';
import { City } from 'src/app/data/City';
import { Street } from 'src/app/data/Street';
import { CitiesService } from 'src/app/Services/cities.service';
import { StreetsService } from 'src/app/Services/streets.service';
import { BarCity } from 'src/app/data/BarCity';
import { BarStreet } from 'src/app/data/BarStreet';
import { AdressTypeEnum } from 'src/app/Enums/StatusesEnum';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { BehaviorSubject, Observable } from 'rxjs';
import { autocomplete } from 'src/app/Utils/autocomplete';

@Component({
  selector: 'app-purchase-personal-details',
  templateUrl: './purchase-personal-details.component.html',
  styleUrls: ['./purchase-personal-details.component.scss'],
})
export class PurchasePersonalDetailsComponent implements OnInit {
  cities: BarCity[];
  streets: BarStreet[];
  filteredCitiesOptions: BarCity[];
  filteredStreetsOptions: BarStreet[];
  @Input() deal: Deal;
  @Input() client: Client;
  @Output('completePurchase') completePurchaseEmitter: EventEmitter<any> =
    new EventEmitter();
  @Output('cancelPurchase') cancelPurchaseEmitter: EventEmitter<any> =
    new EventEmitter();
  childForms: FormGroup[] = [];
  myProducts = [];
  detailsForm: FormGroup = null;
  paymentMethods: PaymentMethod[];
  paymentFormReady: boolean = false;
  productTypes: ProductType[];
  productsTotal: number = 0;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  stageId: Number;
  showNoProductWarning: boolean = false;
  phoneSettings: PhoneVerificationSetting;

  searchTermCity$ = new BehaviorSubject<string>('');
  citiesResults$: Observable<BarCity[]> = this.searchTermCity$.pipe(
    autocomplete(800, (term: string) => {
      return this.citiesService.search(term);
    })
  );
  searchTermStreet$ = new BehaviorSubject<string>('');
  streetsResults$: Observable<BarStreet[]> = this.searchTermStreet$.pipe(
    autocomplete(800, (term: string) => {
      if (!this.cityCode && this.client) {
        this.cityCode = this.client.regularUser?.userAdresses[0].barCityCode;
      }
      return this.streetsService.search(this.cityCode, term);
    })
  );
  cityCode: number;
  isStreetSelected: boolean;
  savedBarStreet: BarStreet;
  constructor(
    private paymentMethodService: PaymentMethodService,
    private productTypesService: ProductTypesService,
    private citiesService: CitiesService,
    private streetsService: StreetsService,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService
  ) {
    this.stageId = Globals.stageId;
  }

  ngOnInit(): void {
    //this.citiesService.getAll().subscribe((data) => (this.cities = data));
    this.createFormGroup();
    this.paymentMethodService
      .getAll()
      .subscribe((data) => (this.paymentMethods = data));
    this.productTypesService.getAll().subscribe((data) => {
      this.productTypes = data;
      //this.getPhoneSettings();
    });
  }
  getPhoneSettings() {
    var address = this.client.regularUser.getAdressObj(AdressTypeEnum.Main);
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
      });
  }
  getUserPhone() {
    if (this.client.regularUser.getUserPhone()) {
      var phoneNumber = this.client.regularUser.getUserPhone();
      return phoneNumber;
    }
  }
  completePurchase() {
    if (this.detailsForm.valid) {
      var isProductFormsValid = true;
      this.childForms.forEach((product) => {
        if (!product.valid) isProductFormsValid = false;
      });
      if (isProductFormsValid) {
        this.deal.tranzillaTransaction = new TranzillaTransaction();
        var productsStr = '';
        var productTypes = {};
        this.deal.futureProducts.forEach((product) => {
          productTypes[product.productTypeId] = product.productTypeId;
        });
        for (let productTypeId in productTypes) {
          let productTypeIdInt = parseInt(productTypeId);
          var selected = this.productTypes.find(
            (x) => x.productTypeId == productTypeIdInt
          );
          var name = selected.desc;
          productsStr +=
            this.productTypes.find((x) => x.productTypeId == productTypeIdInt)
              .desc + ' , ';
        }
        productsStr = productsStr.substring(0, productsStr.length - 2);
        this.deal.tranzillaTransaction.pdesc = productsStr;
        this.deal.tranzillaTransaction.company =
          this.detailsForm.value.companyName;
        this.deal.tranzillaTransaction.email = this.detailsForm.value.email;
        // this.deal.tranzillaTransaction.country = this.detailsForm.value.country;
        this.deal.tranzillaTransaction.address =
          this.detailsForm.value.street +
          ' ' +
          this.detailsForm.value.streetNum +
          ' ' +
          this.detailsForm.value.city;
        this.deal.tranzillaTransaction.phone =
          this.detailsForm.value.phoneNumber;
        this.completePurchaseEmitter.emit(this.deal);
      }
    } else {
      const invalid = [];
      const controls = this.detailsForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a1 = invalid;
    }
  }
  cancelPurchase() {
    this.cancelPurchaseEmitter.emit();
  }
  createFormGroup() {
    this.detailsForm = new FormGroup({
      fullName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),

      street: new FormControl('', [Validators.required]),
      streetCode: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      cityCode: new FormControl(''),
      //country: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      streetNum: new FormControl(null, [Validators.required]),
      entrance: new FormControl(null),
      apartment: new FormControl(null),

      companyName: new FormControl(null),
    });

    setTimeout(() => {
      this.paymentFormReady = true;
    }, 100);
  }
  addNewProduct() {
    var o = new Object({ index: this.myProducts.length });
    this.myProducts.push(o);
  }
  updateProductData(event) {
    if (event.value.amount == 0) {
      this.myProducts.splice(event.value.index, 1);
    } else {
      this.childForms[event.value.index] = event;
    }
    this.productsTotal = 0;
    this.childForms.forEach((element) => {
      this.productsTotal += element.value.formTotal;
    });
  }

  copyDetailsFromClient() {
    this.detailsForm = new FormGroup({
      fullName: new FormControl(this.client.regularUser.firstName, [
        Validators.required,
      ]),
      phoneNumber: new FormControl(this.getUserPhone(), [Validators.required]),
      streetNum: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).streetNum,
        [Validators.required]
      ),
      street: new FormControl(
        this.client.regularUser.getAdressObj(
          AdressTypeEnum.Main
        ).barStreet.name,
        [Validators.required]
      ),
      streetCode: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barStreetCode,
        [Validators.required]
      ),
      city: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barCity.name,
        [Validators.required]
      ),
      cityCode: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).barCityCode,
        [Validators.required]
      ),
      entrance: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).entrance
      ),
      apartment: new FormControl(
        this.client.regularUser.getAdressObj(AdressTypeEnum.Main).apartment
      ),
      // country: new FormControl(this.client.regularUser.city, [Validators.required]),
      email: new FormControl(this.client.regularUser.email, [
        Validators.required,
      ]),
      companyName: new FormControl(null),
    });
  }
  onCitiesAutocompleteChanged(event) {
    var a = event.target.value;
    var citiesDup = [...this.cities];
    this.filteredCitiesOptions = citiesDup.filter((city) => {
      return city.name.indexOf(a) != -1;
    });
  }
  mapCities(results: BarCity[]) {
    if (!results) return [];
    if (results.length > 0) {
      this.cities = results;
      return this.cities.map((city: BarCity) => city.name);
    }
  }
  mapStreets(results: BarStreet[]) {
    if (!results) return [];
    if (results.length > 0) {
      this.streets = results;
      return this.streets.map((street: BarStreet) => street.name);
    }
  }

  onCitySelected(cityName: string) {
    var citiesDup = [...this.cities];
    const city = citiesDup.filter((city) => {
      return city.name == cityName;
    })[0];
    this.cityCode = city.cityCode;
    this.detailsForm.controls['city'].setValue(city.name);
    this.detailsForm.controls['cityCode'].setValue(city.cityCode);
    this.detailsForm.controls['street'].setValue('');
    this.detailsForm.controls['streetCode'].setValue(null);
    /* this.streetsService.getByCity(cityId).subscribe((data) => {
      this.streets = data;
    }); */
  }
  onStreetSelected(streetName: string) {
    this.isStreetSelected = true;
    var streetsDup = [...this.streets];
    this.savedBarStreet = streetsDup.filter((street) => {
      return street.name == streetName;
    })[0];
    this.detailsForm.controls['street'].setValue(this.savedBarStreet.name);
    this.detailsForm.controls['streetCode'].setValue(
      this.savedBarStreet.streetCode
    );
  }
  onStreetsAutocompleteChanged(event) {
    var a = event.target.value;
    this.filteredStreetsOptions = this.streets.filter((street) => {
      return street.name.indexOf(a) != -1;
    });
  }
  get fullName() {
    return this.detailsForm.get('fullName');
  }
  get phoneNumber() {
    return this.detailsForm.get('phoneNumber');
  }
  get street() {
    return this.detailsForm.get('street');
  }
  get city() {
    return this.detailsForm.get('city');
  }
  get email() {
    return this.detailsForm.get('email');
  }
  get companyName() {
    return this.detailsForm.get('companyName');
  }
}
