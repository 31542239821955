<button (click)="addNewCalculation()">Add</button>
<table
  #table
  mat-table
  [dataSource]="lifeStyleCalculations"
  class="mat-elevation-z8"
>
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="width: 200px; text-align: center"
    >
      Name
    </th>
    <td style="text-align: center" mat-cell *matCellDef="let element">
      <input [(ngModel)]="element.name" (change)="onElementChanged(element)" />
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="value">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="width: 200px; text-align: center"
    >
      value
    </th>
    <td style="text-align: center" mat-cell *matCellDef="let element">
      <input [(ngModel)]="element.value" (change)="onElementChanged(element)" />
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="location">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="width: 200px; text-align: center"
    >
      location
    </th>
    <td style="text-align: center" mat-cell *matCellDef="let element">
      <select
        (change)="onLocationChanged($event, element)"
        [(ngModel)]="element.lifeStyleLocationId"
      >
        <option
          *ngFor="let location of locations"
          [value]="location.lifeStyleLocationId"
        >
          {{ location.name }}
        </option>
      </select>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="type">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="width: 200px; text-align: center"
    >
      type
    </th>
    <td style="text-align: center" mat-cell *matCellDef="let element">
      <select
        (change)="onCalculationTypesChanged($event, element)"
        [(ngModel)]="element.lifeStyleCalculationTypeId"
      >
        <option
          *ngFor="let type of calculationTypes"
          [value]="type.lifeStyleCalculationTypeId"
        >
          {{ type.name }}
        </option>
      </select>
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="width: 200px; text-align: center"
    >
      action
    </th>
    <td style="text-align: center" mat-cell *matCellDef="let element">
      <button
        (click)="saveItem(element)"
        *ngIf="
          !element.lifeStyleCalculationId || element.lifeStyleCalculationId <= 0
        "
      >
        save
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
