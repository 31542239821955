<div class="role">
  <div class="role__title">Add module</div>
  <div class="role__label">Choose Module</div>

  <mat-form-field
    appearance="fill"
    class="upgrade"
    style="padding-top: 1.34375em; margin-right: 30px"
  >
    <mat-label>Category:</mat-label>
    <mat-select
      (selectionChange)="onDropdownChanged($event)"
      matNativeControl
      disableOptionCentering
      [(ngModel)]="selectedAppModuleId"
    >
      <mat-option
        *ngFor="let appModule of appModules"
        [value]="appModule.appModuleId"
      >
        {{ appModule.appModuleName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="role__title">Module Actions:</div>
  <div style="display: flex">
    <div cdkDropListGroup style="display: flex">
      <div class="example-container">
        <h5>Actions List :</h5>

        <div
          cdkDropList
          [cdkDropListData]="actionConvertionsChecked"
          class="example-list"
          cdkDropListSortingDisabled
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="example-box"
            *ngFor="let item of actionConvertionsChecked"
            cdkDrag
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="example-container">
        <h5>Add Action :</h5>

        <div
          cdkDropList
          [cdkDropListData]="actionConvertionsUnchecked"
          class="example-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="example-box"
            *ngFor="let item of actionConvertionsUnchecked"
            cdkDrag
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!--
      <div style="width: 50%">
        <div>Actions List :</div>
        <div style="border: 1px solid black; width: 175px; height: 300px"></div>
      </div>
  
      <div style="width: 50%">
        <div>Add Action :</div>
        <div style="border: 1px solid black; width: 175px; height: 300px"></div>
      </div>
    -->
  </div>
  <div class="role__actions">
    <button (click)="save()" class="save" mat-button>Save</button>
    <button (click)="cancel()" class="cancel" mat-button mat-dialog-close>
      Cancel
    </button>
  </div>
</div>
