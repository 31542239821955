<div style="
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  ">
  <p style="font-size: 1.8rem;"><strong>Send Coupon</strong></p>
  <p><strong>sending to: </strong>{{ data.cancelationRequest.clientName }} </p>
  <p><strong>Amount: </strong> {{data.cancelationRequest.amount}}</p>
  <hr style="width:90%;margin:0 auto;border-color:#DCF0F0" />
  <br>
  <p style="font-size: 1.8rem;"><strong>Choose Coupon type:</strong></p>
  <div>
    <mat-form-field appearance="fill" class="select-cupone">
      <mat-label>Select Coupon Type:</mat-label>
      <mat-select required (selectionChange)="onSourceFilterChange($event)" [ngClass]="'option-'+selectedCompanyId"
        [(ngModel)]="selectedCompanyId" disableOptionCentering>
        <mat-option *ngFor="let compamy of companies" [value]="compamy.companyId">
          <div style="display: flex;align-items: center">
            <img style="width:22px;margin-right: 15px;" [src]="compamy.logoImage" alt="">
            {{ compamy.name }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <mat-form-field appearance="fill">
    <input type="text" required matInput placeholder="Enter code coupon" [(ngModel)]="codeCoupon" />
  </mat-form-field>
  <div style="width: 44%;
      display: flex;
      justify-content: space-between;">
    <button class="send-btn" mat-button (click)="sendCoupon()">Send</button>
    <button class="cancel-btn" mat-button (click)="closeCoupon()">Cancel</button>
  </div>
</div>