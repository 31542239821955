import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GeneticFormula } from '../data/GeneticFormula';

@Injectable({
  providedIn: 'root',
})
export class GeneticFormulasService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<GeneticFormula[]>(
      `${environment.baseUrl}GeneticFormulas`
    );
  }

  put(geneticFormula: GeneticFormula) {
    return this.http.put(
      `${environment.baseUrl}GeneticFormulas/${geneticFormula.geneticFormulaId}`,
      geneticFormula
    );
  }
  post(foodCategory: GeneticFormula) {
    return this.http.post<GeneticFormula>(
      `${environment.baseUrl}GeneticFormulas/`,
      foodCategory
    );
  }

  delete(geneticFormula: GeneticFormula) {
    return this.http.delete(
      `${environment.baseUrl}FoodCategories/${geneticFormula.geneticFormulaId}`
    );
  }
}
