<div
  style="scroll-behavior: smooth"
  class="lifestyle"
  *ngIf="lifestyleQuestions && langs"
>
  <app-lifestyle-progress-bar
    [maxIndex]="getProgressBarMaxInx()"
    [currentIndex]="showIndex"
  ></app-lifestyle-progress-bar>
  <perfect-scrollbar
    style="max-height: calc(100vh - 260px)"
    [perfectScrollbar]="config"
  >
    <div class="lifestyle__container">
      <ng-container
        *ngFor="
          let question of lifestyleQuestions;
          let index = index;
          trackBy: trackByQuestionId
        "
      >
        <span *ngIf="false">
          index:{{ index }} showIndex: {{ showIndex }}refId:{{
            hasRefId(question)
          }}</span
        >
        <mat-card
          id="questioncard-{{ index }}"
          class="card-question"
          *ngIf="
            lifestyleQuestions &&
            langs &&
            showIndex >= index &&
            (!hasRefId(question) || refAnswered(question.referenceQuestionId))
          "
        >
          <!--
        <div>{{ question.lifestyleQuestionLangs[0].answerText }}</div>
    
        showIndex : {{ showIndex }} , index :{{ index }}, length:
        {{ lifestyleQuestions.length }}
-->
          <app-lifestyle-question-edit-node
            [ngStyle]="{ 'pointer-events': isLoading ? 'none' : '' }"
            [parentIdx]="index"
            [question]="question"
            [productId]="productId"
            [langs]="langs"
            [isLoading]="isLoading"
            [lifestyleQuestions]="lifestyleQuestions"
            (incrementIndex)="onIcrementIndex($event)"
            (updateAnswer)="OnUpdateAnswer($event)"
          ></app-lifestyle-question-edit-node>
          <div
            style="display: flex; justify-content: center"
            *ngIf="showNextBtn && validIndex == index"
          >
            <button (click)="showNextQuestion()" class="finishBtn">הבא</button>
          </div>
        </mat-card>
      </ng-container>

      <div class="card-question text-center" *ngIf="isDone">
        <button class="finishBtn" mat-button (click)="finishAnswering()">
          סיום
        </button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
