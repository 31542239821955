import { AppImage } from './algorithm/AppImage';

export class AgentSettings {
  agentSettingId: number;
  agentId: number;
  departmentId: number;
  isChatAvailable: boolean;
  description: string;
  name: string;
  langId: number;
  avatar: AppImage;
}
