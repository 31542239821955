import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgentMin } from 'src/app/data/AgentMin';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { BulkChangeLeadAgent } from 'src/app/data/connectors/BulkChangeLeadAgent';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { LeadStasusesService } from 'src/app/Services/lead-stasuses.service';
import { LeadsConnectorService } from 'src/app/Services/leads-connector.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { LeadSourcesService } from '../../../Services/lead-sources.service';
import { LeadsService } from '../../../Services/leads.service';

@Component({
  selector: 'app-all-leads',
  templateUrl: './all-leads.component.html',
  styleUrls: ['./all-leads.component.scss'],
})
export class AllLeadsComponent implements OnInit {
  leadsData: ClientForTableMin[];
  leadStatuses: LeadStatus[];
  agents: AgentMin[];
  leadSources: LeadSource[];
  //statuses;
  checkedIds: any;
  picker;
  date;
  checkedAll: boolean = false;
  selectedAgentText = 'Agent: All';
  source;
  @ViewChild('toggle') toggleDatepicker: ElementRef;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesTable: LeadStatus[];
  regularUser: RegularUser;
  leadStatusesGroup: LeadStatus[];
  isLoading: boolean;
  constructor(
    public leadsService: LeadsService,
    private agentsService: AgentsService,
    private leadStasusesService: LeadStasusesService,
    private leadSourcesService: LeadSourcesService,
    private filterMemoryService: FilterMemoryService,
    //private regularUsersService: RegularUsersService,
    private popupHandlerService: PopupHandlerService,
    private leadsConnectorService: LeadsConnectorService
  ) {
    this.checkedIds = {};

    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.leads);
    /*new HeadFilter(
      [],
      -1,
      -1,
      null,
      null,
      -1,
      '',
      false,
      100,
      1
    );*/
  }
  ngOnInit(): void {
    /* this.leadsConnectorService.bulkChangeLeadAgentSubject.subscribe((data) => {
      if (data) {
        this.popupHandlerService.openConfirmationDialog(
          '',
          'Transfer Missions?'
        );
        this.popupHandlerService.confirmDialogSubject
          .pipe(take(1))
          .subscribe((_data) => {
            if (_data.ans === 'yes') {
              this.bulkChangeAgent(data, true);
            } else if (_data.ans === 'no') {
              this.bulkChangeAgent(data, false);
            }
          });
      }
    }); */
    /* this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    }); */
    this.getAll();
    /* this.leadsService.leads$.subscribe((data) => {
      if (data.list && data.metaData) {
        this.leadsData = data.list;
        this.metaData = data.metaData;
      }
    }); */
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;

      this.agents = this.agents.filter((agent) => {
        return (
          agent.roleTypeId == UserRoleEmum.Sales ||
          agent.roleTypeId == UserRoleEmum.SalesManager ||
          agent.roleTypeId == UserRoleEmum.SalesTeamLeader ||
          agent.roleTypeId == UserRoleEmum.Developer ||
          agent.roleTypeId == UserRoleEmum.Manager
        );
      });
    });
    this.leadStasusesService.getAllForMenu().subscribe((data) => {
      this.leadStatusesGroup = data;
      let leadStatusAll = new LeadStatus();
      leadStatusAll.name = 'All';
      leadStatusAll.leadStatusId = -1;
      leadStatusAll.color = null;
      leadStatusAll.childerns = [];
      leadStatusAll.parentId = 0;
      this.leadStatusesGroup.unshift(leadStatusAll);
      this.leadStatusesGroup = this.leadStatusesGroup.filter((x) => {
        return x.leadStatusId !== 15 && x.leadStatusId !== 16;
      });
    });
    this.leadStasusesService.getAll().subscribe((data) => {
      this.leadStatuses = data;
    });
    this.leadStasusesService.getAll().subscribe((data) => {
      //  this.statuses = data;
      this.leadStatuses = data;
      this.leadStatuses = this.leadStatuses.filter((x) => {
        return x.leadStatusId != 15 && x.leadStatusId != 16;
      });
      this.leadStatusesTable = this.leadStatuses.filter((x) => {
        return x.parentId != 0;
      });
    });
    this.leadSourcesService.getAll().subscribe((data) => {
      this.leadSources = data;
    });
  }

  bulkChangeAgent(data: BulkChangeLeadAgent, transferMissions: boolean) {
    this.leadsService
      .bulkChangeAgent2(data.clientIds, data.agentId, transferMissions)
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog('agent');
        this.getAllWithFilter(this.headFilter, false, true);
      });
  }

  getAll() {
    this.isLoading = true;
    this.leadsService.getAllByFilter(this.headFilter).subscribe((data) => {
      this.leadsData = [...data.list];
      this.metaData = { ...data.metaData };
      this.isLoading = false;
    });
  }
  onCheckedIdsChanged(checkedIds) {
    this.checkedIds = checkedIds;

    this.checkedAll = true;

    setTimeout(() => {
      this.checkedAll = false;
    }, 10);
  }

  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    if (isFromHead) {
      this.checkedIds = {};
      this.checkedAll = false;
      /* setTimeout(() => {
        this.checkedAll = false;
      }, 10); */
    }
    if (isFormPaginator) {
      this.checkedAll = false;
      /*  setTimeout(() => {
        this.checkedAll = false;
      }, 10); */
    }
    this.headFilter = e;
    setTimeout(() => {
      this.filterMemoryService.setfilter(this.headFilter, PageTypeEnum.leads);
      this.getAll();
    }, 1000);
  }
  changeCheckedIds(newCheckedIds) {
    this.checkedIds = newCheckedIds;
  }
}
