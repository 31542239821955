<h1>Nutrition Report</h1>
<div style="text-align: left;">
    <div class="divider divider--test"></div>
    <span class="text--test">recomendations</span>
    <br />
    <span class="text--test">Menu</span>
    <br />
    <span>Morning</span>
    <br />
    <span>Middle</span>
    <br />
    <span>Lunch</span>
    <br />
    <span>Middle</span>
    <br />
    <span>Evening</span>
    <br />
    <button>send</button>
    <button>save</button>
</div>