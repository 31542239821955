<div mat-dialog-title class="title">Add new barcode</div>
<mat-dialog-content class="mat-typography">
  <div class="btns-wrapper" *ngIf="!addMode">
    <button mat-button class="range-btn" (click)="addByOption('range')">
      By range
    </button>
    <button mat-button class="list-btn" (click)="addByOption('list')">
      By list
    </button>
  </div>
  <ng-container *ngIf="addMode">
    <div *ngIf="addMode == 'list'">
      <div class="item">
        <div class="item__label">Barcode</div>
        <mat-form-field class="item__field" appearance="fill">
          <input
            matInput
            [(ngModel)]="manuallBarcode"
            (keyup.enter)="addToList()"
          />
        </mat-form-field>
      </div>
      <div class="action-btn">
        <button mat-button class="list-btn w-150" (click)="addToList()">
          Insert manually
        </button>
      </div>
      <div class="list-wrapper">
        <ng-container *ngIf="barcodeList && barcodeList.length > 0">
          <mat-card
            class="list-item"
            *ngFor="let barcode of barcodeList; let i = index"
          >
            {{ barcode }}
            <button class="remove" mat-icon-button (click)="removeFromList(i)">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </mat-card>
        </ng-container>
      </div>
    </div>
    <div *ngIf="addMode == 'range'">
      <div class="item">
        <div class="item__label">From</div>
        <mat-form-field class="item__field" appearance="fill">
          <input
            type="number"
            matInput
            placeholder="10"
            [(ngModel)]="rangeFrom"
            (ngModelChange)="onRangeChange()"
          />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">To</div>
        <mat-form-field class="item__field" appearance="fill">
          <input
            type="number"
            matInput
            placeholder="20"
            [(ngModel)]="rangeTo"
            (ngModelChange)="onRangeChange()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="range > -1 && rangeFrom && rangeTo" class="range-validation">
        You will create <strong>{{ range | number : "1.0-0" }}</strong> barcodes
      </div>
      <!-- <div class="action-btn">
        
      </div> -->
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="actions">
    <ng-container *ngIf="addMode">
      <button
        *ngIf="addMode == 'range'"
        [disabled]="range == 0"
        mat-button
        class="range-btn w-120"
        (click)="generateRange()"
      >
        Generate range
      </button>
      <button
        *ngIf="addMode == 'list'"
        [disabled]="!barcodeList || barcodeList.length == 0"
        mat-button
        class="range-btn w-120"
        (click)="generateList()"
      >
        Generate list
      </button>
    </ng-container>
    <button class="cancel" type="button" mat-button mat-dialog-close>
      Cancel
    </button>
  </div>
</mat-dialog-actions>
