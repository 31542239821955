<div class="labels">
  <button (click)="location.back()" class="labels__back" mat-button>
    < Back
  </button>

  <mat-card class="labels__card">
    <mat-card-header class="labels__card__header">
      <mat-card-title class="labels__card__header__title">
        Labels
      </mat-card-title>
      <div>
        <button
          class="labels__card__header__create"
          mat-button
          (click)="editLabel(null)"
        >
          Create new
        </button>
        &nbsp;
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="labels__table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="orderBy">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Order by</th>
          <td mat-cell *matCellDef="let element">
            {{ element.orderBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Image</th>
          <td mat-cell *matCellDef="let element">
            <img
              style="width: 45px; max-width: 100%; display: flex"
              src="{{ getImage(element) }}"
              alt="label"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="nutrient">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Nutrient</th>
          <td mat-cell *matCellDef="let element">
            {{ getNutrientName(element.nutrientKeyId) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="maxValue">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            Max Value
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.maxValue }}
          </td>
        </ng-container>
        <ng-container matColumnDef="maxLiquidValue">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            Max liquid value
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.maxLiquidValue }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">Edit</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <button
              mat-icon-button
              class="labels__table__action"
              (click)="editLabel(element)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
</div>
