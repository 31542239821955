import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { WebImage } from 'src/app/data/WebImages';
import { AlgoBulletImagesService } from 'src/app/Services/algo-bullet-images.service';
import { WebImagesService } from 'src/app/Services/web-images.service';
import { FilesManagerComponent } from '../files-manager/files-manager.component';

@Component({
  selector: 'app-web-images-file-manager',
  templateUrl: './web-images-file-manager.component.html',
  styleUrls: ['./web-images-file-manager.component.scss'],
})
export class WebImagesFileManagerComponent implements OnInit {
  images: WebImage[];
  imgError: string;
  selectedImg: any;

  constructor(
    public dialogRef: MatDialogRef<WebImagesFileManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private webImagesService: WebImagesService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getImages();
  }

  close() {
    this.dialogRef.close({ event: 'close' });
  }

  onSelectImage(event: MatRadioChange) {
    this.selectedImg = event.value;
  }

  getImages() {
    this.webImagesService
      .getByType(this.data.typeId)
      .subscribe((data) => (this.images = data));
  }

  addImage() {
    this.dialogRef.close({ event: 'add', imgId: this.selectedImg });
  }

  uploadImg(files) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    const allowTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/tiff',
      'image/svg',
      'image/svg+xml',
    ];
    this.imgError = '';
    if (!allowTypes.includes(fileToUpload.type)) {
      this.imgError = 'Please upload valid Image';
    } else {
      this.imgError = '';
      formData.append('file', fileToUpload, fileToUpload.name);

      this.webImagesService
        .post(formData, this.data.typeId)
        .subscribe((data) => {
          this.getImages();
        });
    }
  }
}
/*
//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#limiting_accepted_file_types*/
