import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DynamicAtachmentFile } from '../data/DynamicAtachmentFile';

@Injectable({
  providedIn: 'root',
})
export class DynamicAtachmentFilesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<DynamicAtachmentFile[]>(
      `${environment.baseUrl}DynamicAtachmentFiles/`
    );
  }
}
