<div class="history">
  <mat-card class="history__card">
    <mat-card-header class="history__card__header">
      <mat-card-title class="history__card__header__title">
        History
      </mat-card-title>
      <mat-form-field appearance="fill" class="history__card__header__select">
        <mat-select
          [(ngModel)]="selectedTypeId"
          (selectionChange)="getHistory()"
        >
          <mat-option *ngFor="let item of loggerTypes" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-header>
    <ngx-skeleton-loader
      *ngIf="!history"
      [theme]="{ height: '30px' }"
      count="13"
    ></ngx-skeleton-loader>
    <ng-container *ngIf="history">
      <perfect-scrollbar style="height: calc(100vh - 279px)">
        <table mat-table [dataSource]="dataSource" class="history__table">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdDate | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Agent</th>
            <td mat-cell *matCellDef="let element">
              {{ element.userName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="log">
            <th mat-header-cell *matHeaderCellDef style="width: 60%">Log</th>
            <td mat-cell *matCellDef="let element">
              {{ element.logValue }}
            </td>
          </ng-container>
          <ng-container matColumnDef="objectName">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">
              Object name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.objectName }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </perfect-scrollbar>
    </ng-container>
  </mat-card>
</div>
<app-table-paginator
  *ngIf="metaData"
  [metaData]="metaData"
  [headFilter]="headFilter"
  [minWidth]="true"
  [sticky]="true"
  (filterChanged)="getPaginatorData($event)"
>
</app-table-paginator>
