import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LifeStyleCalculation } from '../data/LifeStyleCalculation';
import { LifeStyleLocation } from '../data/LifeStyleLocation';

@Injectable({
  providedIn: 'root',
})
export class LifeStyleCalculationsService {
  constructor(private http: HttpClient) {}
  getAll(): Observable<LifeStyleCalculation[]> {
    return this.http.get<LifeStyleCalculation[]>(
      `${environment.baseUrl}LifeStyleCalculations/`
    );
  }
  put(
    lifeStyleCalculation: LifeStyleCalculation
  ): Observable<LifeStyleCalculation> {
    return this.http.put<LifeStyleCalculation>(
      `${environment.baseUrl}LifeStyleCalculations/${lifeStyleCalculation.lifeStyleCalculationId}`,
      lifeStyleCalculation
    );
    //.pipe(catchError(this.handlePutError));
  }
  post(
    lifeStyleCalculation: LifeStyleCalculation
  ): Observable<LifeStyleCalculation> {
    return this.http.post<LifeStyleCalculation>(
      `${environment.baseUrl}LifeStyleCalculations/`,
      lifeStyleCalculation
    );
  }
}
