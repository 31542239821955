<app-head-filter
  [callsReviews]="true"
  *ngIf="agents"
  [agents]="agents"
  [rating]="rating"
  [subjects]="subjects"
  [classAttr]="'clientsAttr'"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [isMainScreen]="clientId == -1 && patientId == -1"
></app-head-filter>
<div class="TableClearFix"></div>
<app-calls-table
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [reviews]="reviews"
  [metaData]="metaData"
  [clientId]="clientId"
  [patientId]="patientId"
  [isLoading]="isLoading"
>
</app-calls-table>
<app-table-paginator
  [sticky]="true"
  *ngIf="clientId == -1 && patientId == -1 && reviews && reviews.length > 0"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event)"
  [classAttr]="'clientsAttr'"
  [rating]="rating"
  [reviews]="reviews"
  [clietId]="clientId"
  [isMainScreen]="clientId == -1 && patientId == -1"
>
</app-table-paginator>
<div *ngIf="clientId !== -1 || patientId !== -1">
  <hr style="border: 1px solid #119c99" />
  number of calls: {{ reviews && reviews.length }}
</div>
