<div class="wrapper" *ngIf="barcodeHistory">
  <div class="patient-details">
    <div>
      <strong>Full Name: </strong>
      <span>{{ barcodeHistory.fullName }}</span>
    </div>
    <div>
      <strong>Patient ID: </strong>
      <span>{{ barcodeHistory.socialSecurityNum }}</span>
    </div>
  </div>
  <div class="table">
    <div class="table__title">Barcode History</div>
    <div class="table__header">
      <div>Barcode ID</div>
      <div>Created Barcode</div>
      <div>Status</div>
    </div>
    <div class="table__body">
      <div class="table__body__row" *ngFor="let item of tableData">
        <div>{{ item.barcode }}</div>
        <div>{{ item.createdDate | date: "dd-MM-yyyy" }}</div>
        <div>{{ getbarcodeStatus(item.statusId) }}</div>
      </div>
    </div>
  </div>
  <div class="button-wrapper">
    <button mat-button (click)="dialogRef.close()">Close</button>
  </div>
</div>
