<mat-card class="{{ action }}">
  <mat-list style="padding-top: 0">
    <mat-list-item>
      <div class="{{ action }}__title" style="width: 100%">
        <div class="d-flex-between">
          <div>
            <img
              *ngIf="action === 'overdue'"
              class="mr-1"
              src="../../../assets/noteRed.svg"
            />
            <img
              *ngIf="action === 'open'"
              class="mr-1"
              src="../../../assets/noteTurq.svg"
            />
            <span>{{ title }}</span
            >&nbsp;
            <span>({{ metaData && metaData.totalCount }})</span>
          </div>
          <div
            *ngIf="action === 'open'"
            style="display: flex; align-items: baseline"
          >
            <div style="color: #000; font-size: 1.6rem">Filter By: &nbsp;</div>
            <mat-form-field appearance="fill" class="filterBy">
              <mat-select
                (selectionChange)="onFilterChange()"
                matNativeControl
                disableOptionCentering
                [(ngModel)]="filterTxt"
              >
                <!-- formControlName="agentId"-->
                <mat-option value="118"> All </mat-option>
                <mat-option value="118"> Future </mat-option>
                <mat-option value="119"> Today </mat-option>
                <mat-option value="120"> Tomorow </mat-option>
                <mat-option value="121"> Next 7 Days </mat-option>
                <mat-option value="122"> This Month </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-divider class="divider"></mat-divider>
      </div>
    </mat-list-item>

    <mat-list-item>
      <div class="taskColumns">
        <div style="width: 16%">Title</div>
        <div style="width: 20%">Subject</div>
        <div style="width: 12.8%">
          <span style="cursor: pointer" (click)="changeSortOrder()">
            <img src="../../../assets/sort_arrows.svg" *ngIf="dateDecending" />
            <img
              class="rotate180"
              src="../../../assets/sort_arrows.svg"
              *ngIf="!dateDecending"
            />
          </span>
          Date
        </div>
        <div style="width: 12.8%">Time</div>
        <div style="width: 12.8%">Related</div>
        <div style="width: 12.8%">Name</div>
        <div style="width: 12.8%">Action</div>
      </div>
      <mat-divider class="{{ action }}"></mat-divider>
    </mat-list-item>
    <ng-container>
      <perfect-scrollbar style="height: 26.5vh" [perfectScrollbar]="config">
        <mat-list-item *ngFor="let activity of activitiesOpen">
          <app-dashboard-activity-thumb
            style="width: 100%"
            [note]="activity"
            (activityDone)="onActivityDone($event)"
          >
          </app-dashboard-activity-thumb>
        </mat-list-item>
      </perfect-scrollbar>
      <mat-paginator
        *ngIf="metaData"
        [length]="metaData.totalCount"
        [pageSize]="metaData.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="getNotes($event)"
      >
      </mat-paginator>
    </ng-container>
  </mat-list>
</mat-card>
