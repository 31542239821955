<div id="chart">
  <mat-card class="card" style="grid-area: one">
    <div class="title">מובילים השבוע-סכום</div>
    <ngx-charts-bar-horizontal
      *ngIf="dashBoardStats && show"
      [results]="weeklyLeaders"
      [xAxisLabel]="'סוכן'"
      [legendTitle]="'מובילים השבוע'"
      [yAxisLabel]="'סכום כולל'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"
      [showDataLabel]="true"
    >
    </ngx-charts-bar-horizontal>
  </mat-card>
  <!--class="card-double"-->
  <mat-card class="card" style="grid-area: two">
    <div class="title">מובילים החודש-סכום</div>
    <ngx-charts-bar-horizontal
      *ngIf="dashBoardStats && show"
      [results]="monthlyLeaders"
      [xAxisLabel]="'סוכן'"
      [legendTitle]="'מובילים החודש'"
      [yAxisLabel]="'סכום כולל'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"
      [showDataLabel]="true"
    >
    </ngx-charts-bar-horizontal>
  </mat-card>
  <mat-card class="card" style="grid-area: three">
    <div class="title">מובילים יומי-כמות מוצרים</div>
    <ngx-charts-bar-horizontal
      *ngIf="dashBoardStats && show"
      [results]="dailyLeadersCount"
      [xAxisLabel]="'סוכן'"
      [legendTitle]="'מובילים יומי'"
      [yAxisLabel]="'כמות'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"
      [showDataLabel]="true"
    >
    </ngx-charts-bar-horizontal>
  </mat-card>

  <mat-card class="card">
    <div
      style="
        background-image: url(../../../../assets/warren_buffett.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 100%;
      "
    ></div>
  </mat-card>

  <mat-card class="card" tyle="grid-area: four;">
    <div class="title">לידים חדשים</div>
    <ngx-charts-bar-horizontal
      *ngIf="dashBoardStats && show"
      [results]="newLeads"
      [xAxisLabel]="'ספירת רשומות'"
      [legendTitle]="'לידים חדשים'"
      [yAxisLabel]="'בעל הפניה'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"
      [showDataLabel]="true"
    >
    </ngx-charts-bar-horizontal>
  </mat-card>

  <!--[legend]="true"-->
  <!--
  <mat-card class="card" tyle="grid-area: five;">
    <div class="title">מובילים שבועי-כמות מוצרים</div>
    <ngx-charts-bar-horizontal
    *ngIf="dashBoardStats && show"
    [results]="weeklyLeadersSum"
    [xAxisLabel]="'סוכן'"
    [legendTitle]="'מובילים יומי'"
    [yAxisLabel]="'כמות'"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [xAxis]="true"
    [yAxis]="true"
    [gradient]="false"
    [showDataLabel]="true"
  >
  </ngx-charts-bar-horizontal>
  
  </mat-card>
  -->
  <!--
  <mat-card class="card" style="grid-area: three;">
    <div class="title">
      מובילים יומי
    </div>
    <ngx-charts-bar-horizontal
      *ngIf="dashBoardStats && show"
      [results]="dailyLeadersSum"
      [xAxisLabel]="'סוכן'"
      [legendTitle]="'מובילים יומי '"
      [yAxisLabel]="'סכום כולל'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"
      [showDataLabel]="true"
    >
    </ngx-charts-bar-horizontal>
  </mat-card>
  -->
  <mat-card class="card" style="grid-area: six">
    <div class="title" style="position: absolute; right: 0">
      מכירות יומי - לפי סוג מוצר
    </div>
    <div style="width: 95%; height: 90%; margin: 0 auto">
      <div style="height: -webkit-fill-available">
        <ngx-charts-pie-chart
          *ngIf="dashBoardStats && show"
          [results]="productTypesToday"
          [xAxisLabel]="'ספירת רשומות'"
          [legendTitle]="'מכירות יומי - לפי סוג מוצר'"
          [legendPosition]="'below'"
          [yAxisLabel]="'בעל הפניה'"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [doughnut]="true"
          [xAxis]="true"
          [yAxis]="true"
          [gradient]="false"
          [showDataLabel]="true"
          [labels]="true"
        >
        </ngx-charts-pie-chart>
        <div
          style="
            position: absolute;
            top: 50%;
            left: 51%;
            transform: translate(-50%, -50%);
            z-index: 100;
            border-radius: 50% 50%;
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5vw;
          "
        >
          {{ pieChartSum }}
        </div>
      </div>
      <div style="position: absolute; bottom: 20px; right: 10px; z-index: 100">
        {{ pieChartText }}
      </div>
    </div>
  </mat-card>
</div>
<ng-container>
  <div *ngIf="newDealEvent">
    <app-confetti [newDeal]="dashBoardStats.newDeal"></app-confetti>
  </div>
</ng-container>
