import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import { ChatFilter } from 'src/app/data/chat/ChatFilter';
import { GeneralAgent } from 'src/app/data/chat/GeneralAgent';
import { DepartmentType } from 'src/app/Enums/ChatEnum';

type FilterEnum = {
  id: number;
  name: string;
  shortName?: string;
};

@Component({
  selector: 'app-chat-filter',
  templateUrl: './chat-filter.component.html',
  styleUrls: ['./chat-filter.component.scss'],
})
export class ChatFilterComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() chatFilter: ChatFilter;
  @Input() agents: GeneralAgent[];
  @Input() agentId: number;
  @Input() departmentId: number;
  filteredAgents: GeneralAgent[] = [];
  departments: FilterEnum[] = [];
  agentsDisabled: boolean = true;

  @Output() applyFilters: EventEmitter<ChatFilter> =
    new EventEmitter<ChatFilter>();
  constructor() {}

  ngOnInit(): void {
    this.departments = this.makeObjectFromEnum(DepartmentType);
    this.filteredAgents = [...this.agents];
    this.chatFilter.agentId = 0;
    this.chatFilter.isWaiting = true;
    this.chatFilter.departmentId = this.departmentId;
  }

  filter() {
    this.applyFilters.emit(this.chatFilter);
  }
  onStatusChange(event: MatButtonToggleChange) {
    if (!event.value) {
      this.chatFilter.isWaiting = false;
    } else {
      this.chatFilter.isWaiting = event.value;
    }
  }
  onDepartmentChange(event: MatButtonToggleChange) {
    console.log('onDepartmentChange', event.value);

    const { value } = event;
    const agentsTemp = [...this.agents];
    this.filteredAgents = [];
    if (value === DepartmentType.All) {
      this.filteredAgents = agentsTemp;
      return;
    }
    this.filteredAgents = [...agentsTemp].filter(
      (agent) => agent.deparmentTypeId === value
    );
  }

  clearSearchField() {
    this.chatFilter.searchTerm = '';
  }

  makeObjectFromEnum(enumType: any) {
    return Object.keys(enumType)
      .filter((key) => isNaN(Number(key)))
      .map((key) => {
        return <FilterEnum>{
          id: enumType[key],
          name: key,
          shortName: key.substring(0, 1),
        };
      });
  }

  onWaitingListChange(event: MatButtonToggleChange) {
    this.agentsDisabled = event.value;
  }
}
