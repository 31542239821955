import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-users',
  templateUrl: './role-users.component.html',
  styleUrls: ['./role-users.component.scss'],
})
export class RoleUsersComponent implements OnInit {
  users: any[];
  constructor(
    public dialogRef: MatDialogRef<RoleUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.users = this.data;
  }

  goToProfile(agentId: number) {
    this.dialogRef.close();
    this.router.navigate(['/agents/edit'], { queryParams: { agentId } });
  }
}
