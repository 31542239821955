import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AddResultTypeComponent } from '../Algorithm/categories/add-result-type/add-result-type.component';
import { AddSnpComponent } from '../Algorithm/categories/add-snp/add-snp.component';
import { AddLabComponent } from '../Algorithm/labs/add-lab/add-lab.component';
import { DuplicateVersionComponent } from '../Algorithm/labs/duplicate-version/duplicate-version.component';
import { LabsVersionEditComponent } from '../Algorithm/labs/labs-version-edit/labs-version-edit.component';
import { EditSnpComponent } from '../Algorithm/snps/edit-snp/edit-snp.component';
import { PurchaseDetailsDialogComponent } from '../CardsView/purchase-details-dialog/purchase-details-dialog.component';
import { AddKeywordComponent } from '../Components/translation/add-keyword/add-keyword.component';
import { EditVideoComponent } from '../Components/vod/edit-video/edit-video.component';
import { VideoCategoriesComponent } from '../Components/vod/video-categories/video-categories.component';
import { ZoomEventsEditComponent } from '../Components/zoom-events-edit/zoom-events-edit.component';
import { AgentMin } from '../data/AgentMin';
import { AlgoResultType } from '../data/algorithm/AlgoResultType';
import { BaseResponce } from '../data/algorithm/BaseResponce';
import { Laboratory } from '../data/algorithm/Laboratory';
import { LabVersion } from '../data/algorithm/LabVersion';
import { PrototypeSnp } from '../data/algorithm/PrototypeSnp';
import { CancelationRequestForTableMin } from '../data/CancelationRequestForTableMin';
import { Client } from '../data/Client';
import { EventAction } from '../data/EventAction';
import { FireBaseCall } from '../data/FireBaseCall';
import { FgCoefficient } from '../data/food-genes/FgCoefficient';
import { FgFullLabel } from '../data/food-genes/FgFullLabel';
import { FgParam } from '../data/food-genes/FgParam';
import { FgParamType } from '../data/food-genes/FgParamType';
import { FgVersion } from '../data/food-genes/FgVersion';
import { FoodAlert } from '../data/food-genes/FoodAlert';
import { FoodMin } from '../data/food-genes/FoodMin';
import { PrototypeParam } from '../data/food-genes/PrototypeParam';
import { MealToNutrient } from '../data/MealToNutrient';
import { Patient } from '../data/Patients';
import { PatientWeightHistory } from '../data/PatientWeightHistory';
import { Status } from '../data/Status';
import { TranslationCategory } from '../data/TranslationCategory';
import { TranzillaTransactionReport } from '../data/TranzillaTransactionReport';
import { VideoCategory } from '../data/VideoCategory';
import { ActionsSelectionComponent } from '../Dialogs/actions-selection/actions-selection.component';
import { AddBarcodeComponent } from '../Dialogs/add-barcode/add-barcode.component';
import { AddNewModuleV2Component } from '../Dialogs/add-new-module-v2/add-new-module-v2.component';
import { AddNewModuleComponent } from '../Dialogs/add-new-module/add-new-module.component';
import { AddNewRoleComponent } from '../Dialogs/add-new-role/add-new-role.component';
import { AlertDialogComponent } from '../Dialogs/alert-dialog/alert-dialog.component';
import { AlgoLinkDialogComponent } from '../Dialogs/algo-link-dialog/algo-link-dialog.component';
import { BarcodeHistoryDialogComponent } from '../Dialogs/barcode-history-dialog/barcode-history-dialog.component';
import { ButtonsListDialogComponent } from '../Dialogs/buttons-list-dialog/buttons-list-dialog.component';
import { CallIncomeComponent } from '../Dialogs/call-income/call-income.component';
import { ChangeBarcodeDialogComponent } from '../Dialogs/change-barcode-dialog/change-barcode-dialog.component';
import { ChooseCouponDialogComponent } from '../Dialogs/choose-coupon-dialog/choose-coupon-dialog.component';
import { ConfirmDialogComponent } from '../Dialogs/confirm-dialog/confirm-dialog.component';
import { EmailTemplateComponent } from '../Dialogs/email-template/email-template.component';
import { FilesManagerComponent } from '../Dialogs/files-manager/files-manager.component';
import { FollowUpScheduallerDialogComponent } from '../Dialogs/follow-up-schedualler-dialog/follow-up-schedualler-dialog.component';
import { FoodInUseErrorComponent } from '../Dialogs/food-in-use-error/food-in-use-error.component';
import { MenuPdfDialogComponent } from '../Dialogs/menu-pdf-dialog/menu-pdf-dialog.component';
import { NewEmailTemplateNameComponent } from '../Dialogs/new-email-template-name/new-email-template-name.component';
import { RecordCallDialogComponent } from '../Dialogs/record-call-dialog/record-call-dialog.component';
import { RoleUsersComponent } from '../Dialogs/role-users/role-users.component';
import { UnitScaleDialogComponent } from '../Dialogs/unit-scale-dialog/unit-scale-dialog.component';
import { ViteminsDialogComponent } from '../Dialogs/vitemins-dialog/vitemins-dialog.component';
import { WebImagesFileManagerComponent } from '../Dialogs/web-images-file-manager/web-images-file-manager.component';
import { WeightHistoryComponent } from '../Dialogs/weight-history/weight-history.component';
import { WeightUpdateComponent } from '../Dialogs/weight-update/weight-update.component';
import { AlertDialogTypeEnum } from '../Enums/AlertDialogTypeEnum';
import { DisapearingTextDialogEnum } from '../Enums/DisapearingTextDialogEnum';
import { FoodGenesAddParameterComponent } from '../FoodGenes/food-genes-add-parameter/food-genes-add-parameter.component';
import { FoodGenesCreateFormulaComponent } from '../FoodGenes/food-genes-create-formula/food-genes-create-formula.component';
import { FoodGenesCreateParamComponent } from '../FoodGenes/food-genes-create-param/food-genes-create-param.component';
import { FoodGenesEditVersionComponent } from '../FoodGenes/food-genes-edit-version/food-genes-edit-version.component';
import { EditLabelComponent } from '../FoodGenes/food-genes-labels/edit-label/edit-label.component';
import { FoodGenesSimulatorComponent } from '../FoodGenes/food-genes-simulator/food-genes-simulator.component';
import { EditTagComponent } from '../FoodGenes/food-genes-tags/edit-tag/edit-tag.component';
import { LeadConfirmChangeDialogComponent } from '../Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { CancelationRequestService } from './cancelation-request.service';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class PopupHandlerService {
  addNewModuleSubject: Subject<any> = new Subject<any>();
  addNewModuleV2Subject: Subject<any> = new Subject<any>();
  dealDetailsDialogSubject: Subject<any> = new Subject<any>();
  confirmDialogSubject: Subject<any> = new Subject<any>();
  buttonOptionsDialogSubject: Subject<any> = new Subject<any>();
  actionsSelectionDialogSubject: Subject<any> = new Subject<any>();
  newEmailTemplateNameSubject: Subject<string> = new Subject<string>();
  confirmCouponSubject: Subject<any> = new Subject<any>();
  foodUnitScaleSubject: Subject<any> = new Subject<any>();
  weightUpdateSubject: Subject<any> = new Subject<any>();
  changeBracodeSubject: Subject<any> = new Subject<any>();
  filesManagerSubject: Subject<any> = new Subject<any>();
  incomeCallSubject: Subject<any> = new Subject<any>();
  incomeCalldialogRef: MatDialogRef<CallIncomeComponent, any>;
  videoSubject: Subject<any> = new Subject<any>();
  addNewRoleSubject: Subject<any> = new Subject<any>();
  addNewSnpSubject: Subject<any> = new Subject<any>();
  addNewResultTypeSubject: Subject<any> = new Subject<any>();
  addNewCatSnpSubject: Subject<any> = new Subject<any>();
  addNewKeywordSubject: Subject<any> = new Subject<any>();
  addNewLabVersionSubject: Subject<any> = new Subject<any>();
  duplicateVersionSubject: Subject<any> = new Subject<any>();
  addNewLabSubject: Subject<any> = new Subject<any>();
  videoCategoriesSubject: Subject<any> = new Subject<any>();
  addNewZoomEventSubject: Subject<any> = new Subject<any>();
  addNewFoodGenesParameter: Subject<any> = new Subject<any>();
  openFormulaPopupSubject: Subject<any> = new Subject<any>();
  addNewTagSubject: Subject<any> = new Subject<any>();
  addNewAlertSubject: Subject<any> = new Subject<any>();
  addNewFgVersionSubject: Subject<any> = new Subject<any>();
  addNewBarcodeSubject: Subject<any> = new Subject<any>();
  constructor(
    public dialog: MatDialog,
    private productsService: ProductsService,
    private cancelationRequestService: CancelationRequestService
  ) {}

  openFoodGenesFormulaPopup(
    versionId: number,
    ownerId: number,
    ownerType: number,
    versions: FgVersion[]
  ) {
    let dialogRef = this.dialog.open(FoodGenesCreateFormulaComponent, {
      width: '350px',
      data: { versionId, ownerId, ownerType, versions },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openFormulaPopupSubject.next(result);
        dialogRef = null;
      }
      dialogRef = null;
    });
  }

  openFollowUpNextTaskSchedualler(patientId: number) {
    const dialogRef = this.dialog.open(FollowUpScheduallerDialogComponent, {
      width: '1400px',
      height: '800px',
      data: {
        patientId: patientId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      /*
      if (result && result.data) {
        setTimeout(() => {
          this.dealDetailsDialogSubject.next(result.data.action);
        }, 300);
      }
      */
    });
  }
  openAlgoLinkDialog(linkId, langId, color) {
    this.dialog.open(AlgoLinkDialogComponent, {
      width: '750px',
      height: '70vh',
      data: {
        linkId,
        langId,
        color,
      },
    });
  }
  openDealDetailsDialog(deal: TranzillaTransactionReport[] = []) {
    const dialogRef = this.dialog.open(PurchaseDetailsDialogComponent, {
      width: '1400px',
      height: '800px',
      data: {
        deal: deal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        setTimeout(() => {
          this.dealDetailsDialogSubject.next(result.data.action);
        }, 300);
      }
    });
  }
  openPerformStatusChangeDialog(actionsList: EventAction[], productId) {
    const dialogRef = this.dialog.open(ActionsSelectionComponent, {
      data: {
        actionsList: actionsList,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ' + result.actionsList);
      var statusId = result.actionsList
        .filter((x) => x.checked == true)
        .map((result) => result.eventActionId);
      //this.actionsSelectionDialogSubject.next(result.data);
      //console.log('PopupHandlerService ', statusId.toString());
      if (statusId.length > 0) {
        this.productsService
          .StatusChangeActions(productId, statusId.toString())
          .subscribe((actionResponses) => {
            var txt = actionResponses
              .map((res) => {
                if (res.message == null) res.message = '';
                return `<span class="${
                  res.isSuccess ? 'color-green' : 'color-red'
                }">${res.actionName}  ${res.message}</span>`;
              })
              .join('<br/>');
            this.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.actionResults,
              '',
              '',
              txt,
              25000 * actionResponses.length
            );
          });
      }
    });
  }
  openPerformCancelationActionsDialog(
    actionsList: EventAction[],
    cancelationId
  ) {
    const dialogRef = this.dialog.open(ActionsSelectionComponent, {
      data: {
        actionsList: actionsList,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ' + result.actionsList);
      var statusId = result.actionsList
        .filter((x) => x.checked == true)
        .map((result) => result.eventActionId);
      //this.actionsSelectionDialogSubject.next(result.data);
      //console.log('PopupHandlerService ', statusId.toString());
      if (statusId.length > 0) {
        this.cancelationRequestService
          .PerformCancelationActions(cancelationId, statusId.toString())
          .subscribe((actionResponses) => {
            var txt = actionResponses
              .map(
                (res) =>
                  `<span class="${
                    res.isSuccess ? 'color-green' : 'color-red'
                  }">${res.actionName}  ${res.message}</span>`
              )
              .join('<br/>');
            this.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.actionResults,
              '',
              '',
              txt,
              25000 * actionResponses.length
            );
          });
      }
    });
  }
  openConfirmationDialog(
    details: any = null,
    title: string = null,
    caller = null,
    time: number = -1
  ) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        details: details,
        title: title,
      },
    });

    if (time > 0) {
      setTimeout(() => {
        dialogRef.close();
      }, time);
    }

    dialogRef.afterClosed().subscribe((result) => {
      //if (!result) return;
      //console.log('The dialog was closed' + result?.list);
      this.confirmDialogSubject.next({
        ans: result?.data?.answer,
        caller: caller,
      });
      dialogRef = null;
    });
  }
  openDisapearingAlertDialog(
    type = '',
    from = '',
    to = '',
    msg = '',
    time = 2000,
    alwaysOpen = false
  ) {
    //console.log('openDisapearingAlertDialog ', msg);

    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        type: type,
        from: from,
        to: to,
        msg: msg,
      },
    });
    if (!alwaysOpen) {
      setTimeout(() => {
        dialogRef.close();
      }, time);
    }

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      var a = result;
    });
  }
  openSelectProductResendType() {
    const dialogRef = this.dialog.open(ButtonsListDialogComponent, {
      width: '400px',
      height: '300px',
      data: {
        list: [
          {
            val: 1,
            name: 'retest on Lab',
          },
          {
            val: 2,
            name: 'resend to Costumer',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      var a = result;

      this.buttonOptionsDialogSubject.next(result);
    });
  }
  openCouponDialog(cancelationRequest: CancelationRequestForTableMin) {
    const dialogRef = this.dialog.open(ChooseCouponDialogComponent, {
      width: '500px',
      height: '400px',
      data: {
        cancelationRequest: cancelationRequest,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed' + result.list);
      //var a = result;
      this.confirmCouponSubject.next(result.list);
    });
  }
  openBarcodeHistoryDialog(productId: string, productStatus: Status) {
    const dialogRef = this.dialog.open(BarcodeHistoryDialogComponent, {
      width: '500px',
      height: '400px',
      data: {
        productId,
        productStatus,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('The dialog was closed' + result.list);
      }
    });
  }
  openEmailTemplateDialog(patient: Patient | Client, product) {
    this.dialog.open(EmailTemplateComponent, {
      width: '800px',
      data: { patient, product },
    });
  }
  openNewEmailTemplateNameDialog() {
    const dialogRef = this.dialog.open(NewEmailTemplateNameComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.newEmailTemplateNameSubject.next(data.templateName);
      }
    });
  }
  openRoleUsersDialog(users) {
    this.dialog.open(RoleUsersComponent, {
      width: '600px',
      height: 'auto',
      data: users,
    });
  }
  openFoodScaleDialog(
    foodId: number,
    langId: number,
    foodScaleId: number = null,
    quantity: number = null,
    calories: number = null
  ) {
    const dialogRef = this.dialog.open(UnitScaleDialogComponent, {
      width: '400px',
      data: { foodId, langId, foodScaleId, quantity, calories },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.foodUnitScaleSubject.next(data);
      }
    });
  }
  openWeightUpdateDialog(height: number, age: any, isGoal: boolean) {
    const dialogRef = this.dialog.open(WeightUpdateComponent, {
      width: '400px',
      data: {
        height,
        age,
        isGoal,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.weightUpdateSubject.next(data);
      }
    });
  }
  openWeightHistoryDialog(
    fullName: string,
    socialSecurityNum: string,
    list: PatientWeightHistory[],
    isGoal: boolean
  ) {
    const dialogRef = this.dialog.open(WeightHistoryComponent, {
      width: '500px',
      height: '400px',
      data: {
        fullName,
        socialSecurityNum,
        list,
        isGoal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  openViteminsDialog(mealToNutrients: MealToNutrient[], productId: number) {
    const dialogRef = this.dialog.open(ViteminsDialogComponent, {
      width: '500px',
      height: '430px',
      data: { mealToNutrients, productId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('The dialog was closed' + result.list);
      }
    });
  }
  openChangeBarcodeDialog(barcode: string) {
    const dialogRef = this.dialog.open(ChangeBarcodeDialogComponent, {
      width: '400px',
      height: '220px',
      data: { barcode },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeBracodeSubject.next(result);
      }
    });
  }
  openFilesManagerDialog(images: any[], selectedImageId: number = null) {
    const dialogRef = this.dialog.open(FilesManagerComponent, {
      width: '600px',
      height: '400px',
      data: { images, selectedImageId },
      panelClass: 'files-manager-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filesManagerSubject.next(result);
      }
    });
  }
  openWebImagesFilesManagerDialog(data) {
    const dialogRef = this.dialog.open(WebImagesFileManagerComponent, {
      width: '600px',
      height: '400px',
      data: data,
      panelClass: 'files-manager-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filesManagerSubject.next(result);
      }
    });
  }
  openIncomeCallDialog(data: FireBaseCall) {
    this.incomeCalldialogRef = this.dialog.open(CallIncomeComponent, {
      width: '600px',
      height: 'auto',
      data,
      panelClass: 'call-income-dialog',
      hasBackdrop: false,
    });

    this.incomeCalldialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.incomeCallSubject.next(result);
      }
    });
  }
  openRecordCallDialog(data: number) {
    this.dialog.open(RecordCallDialogComponent, {
      width: '350px',
      height: '110px',
      data,
    });
  }
  openAddNewVideoDialog(
    videoId: number,
    agents: AgentMin[],
    categories: VideoCategory[]
  ) {
    const dialogRef = this.dialog.open(EditVideoComponent, {
      width: '650px',
      data: { videoId, agents, categories },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.videoSubject.next(result);
      }
    });
  }
  openVideoCategoriesDialog() {
    const dialogRef = this.dialog.open(VideoCategoriesComponent, {
      width: '650px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.videoCategoriesSubject.next(result);
      }
    });
  }
  closeIncomeCallDialog() {
    if (this.incomeCalldialogRef) {
      this.incomeCalldialogRef.close();
      this.incomeCallSubject.next('close from service');
    }
  }
  openAlertDialog(type: AlertDialogTypeEnum, title: string) {
    this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: { type, title },
    });
  }
  openAddNewRoleDialog() {
    const dialogRef = this.dialog.open(AddNewRoleComponent, {
      width: '350px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addNewRoleSubject.next(result);
      }
    });
  }
  openAddNewModuleDialog(roleId) {
    const dialogRef = this.dialog.open(AddNewModuleComponent, {
      width: '350px',
      disableClose: true,
      data: { roleId: roleId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addNewModuleSubject.next(result);
      } else {
        this.addNewModuleSubject.next(null);
      }
    });
  }
  openAddNewModuleV2Dialog(roleId) {
    const dialogRef = this.dialog.open(AddNewModuleV2Component, {
      width: '850px',
      disableClose: true,
      data: { roleId: roleId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addNewModuleV2Subject.next(result);
      }
    });
  }
  openMenuPdfDialog(data) {
    this.dialog.open(MenuPdfDialogComponent, {
      width: '599px',
      data: data,
    });
  }
  openFoodInUseErrorDialog(data: FoodMin[]) {
    this.dialog.open(FoodInUseErrorComponent, {
      width: '700px',
      data,
    });
  }
  openAddNewSnp(data: number) {
    let dialogRef = this.dialog.open(EditSnpComponent, {
      width: '500px',
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewSnpSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewResultType(data: AlgoResultType[]) {
    let dialogRef = this.dialog.open(AddResultTypeComponent, {
      width: '300px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewResultTypeSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewCatSnp(data: PrototypeSnp[]) {
    let dialogRef = this.dialog.open(AddSnpComponent, {
      width: '300px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewCatSnpSubject.next(result);
      dialogRef = null;
    });
  }
  openNewFoodGenesVersion(
    fgVersion: FgVersion,
    fgVersions: FgVersion[],
    langId: number,
    isDuplicate: boolean = false
  ) {
    let dialogRef = this.dialog.open(FoodGenesEditVersionComponent, {
      width: '450px',
      data: { fgVersion, fgVersions, langId, isDuplicate },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewFgVersionSubject.next(result);
      dialogRef = null;
    });
  }
  openFoodGenesSimulator(versionId: number, langId: number) {
    this.dialog.open(FoodGenesSimulatorComponent, {
      width: '100vw',
      height: '90vh',
      data: { versionId, langId },
      disableClose: true,
      panelClass: 'full-screen-modal',
    });
  }
  openAddNewFoodGenesParameter(
    paramType: FgParamType,
    availableParams: PrototypeParam[],
    coefficients: FgCoefficient[],
    langId: number,
    versionId: number
  ) {
    let dialogRef = this.dialog.open(FoodGenesCreateParamComponent, {
      width: '450px',
      data: { paramType, availableParams, coefficients, langId, versionId },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewFoodGenesParameter.next(result);
      dialogRef = null;
    });
  }
  openAddNewKeyword(data: TranslationCategory[]) {
    let dialogRef = this.dialog.open(AddKeywordComponent, {
      width: '400px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewKeywordSubject.next(result);
      dialogRef = null;
    });
  }
  openAddLabVersion(data: any) {
    let dialogRef = this.dialog.open(LabsVersionEditComponent, {
      width: '600px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewLabVersionSubject.next(result);
      dialogRef = null;
    });
  }
  openZoomEventsEdit(data: number) {
    let dialogRef = this.dialog.open(ZoomEventsEditComponent, {
      width: '600px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.addNewZoomEventSubject.next(result);
      dialogRef = null;
    });
  }
  openDuplicateVersion(data: number) {
    let dialogRef = this.dialog.open(DuplicateVersionComponent, {
      width: '500px',
      data,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.duplicateVersionSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewLab(data: Laboratory) {
    let dialogRef = this.dialog.open(AddLabComponent, {
      width: '500px',
      data,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.addNewLabSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewTag(label: FgFullLabel, nutrients: BaseResponce[], langId: number) {
    let dialogRef = this.dialog.open(EditLabelComponent, {
      width: '600px',
      data: { label, nutrients, langId },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.addNewTagSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewAlert(alert: FoodAlert, langId: number) {
    let dialogRef = this.dialog.open(EditTagComponent, {
      width: '400px',
      data: { alert, langId },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.addNewAlertSubject.next(result);
      dialogRef = null;
    });
  }
  openAddNewBarcode() {
    let dialogRef = this.dialog.open(AddBarcodeComponent, {
      width: '400px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.addNewBarcodeSubject.next(result);
      dialogRef = null;
    });
  }
}
