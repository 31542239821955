import { EmailTemplate } from './EmailTemplate';
import { FileUpload } from './FileUpload';
import { Language } from './Language';
export class EmailTemplateLang {
  emailTempleteLangId: number;
  langId: number;
  language: Language;
  emailTempleteId: number;
  emailTemplate: EmailTemplate;
  textEmail: string;
  subject: string;
  textEmailStriped: string;
  textSms: string;
  name: string;
  updateDate: string;
  defaultTemplate: string;
  fileAtachments: FileUpload[];

  constructor(
    emailTempleteLangId = null,
    langId = null,
    language = null,
    emailTempleteId = null,
    emailTemplate = null,
    textEmail = null,
    subject = null,
    textEmailStriped = null,
    textSms = null,
    name = null,
    updateDate = null,
    defaultTemplate = null
  ) {
    this.emailTempleteLangId = emailTempleteLangId;
    this.langId = langId;
    this.language = language;
    this.emailTempleteId = emailTempleteId;
    this.emailTemplate = emailTemplate;
    this.textEmail = textEmail;
    this.subject = subject;
    this.textEmailStriped = textEmailStriped;
    this.textSms = textSms;
    this.name = name;
    this.updateDate = updateDate;
    this.defaultTemplate = defaultTemplate;
  }
}
