<!--<button (click)="uploadResultsFile()">Upload Results File</button>-->
<app-head-filter
  [classAttr]="'lab-all-deliveries'"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
></app-head-filter>
<div class="TableClearFix"></div>
<!-- <app-upload-results-csv></app-upload-results-csv>
 --><perfect-scrollbar style="max-height: 80vh;" [config]="config">
  <div style="padding-right: 30px;">
    <app-tests-package-table [type]="'lab'" [tubesPackages]="tubesPackages">
    </app-tests-package-table>
  </div>
</perfect-scrollbar>
<app-table-paginator
  *ngIf="tubesPackages && metaData"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'leadsAttr'"
  [headFilter]="headFilter"
>
</app-table-paginator>
