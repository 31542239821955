<form
  *ngIf="subCategoryForm"
  class="form"
  (ngSubmit)="onSubmit()"
  [formGroup]="subCategoryForm"
>
  <div class="label">בחירת קטגוריה</div>
  <mat-radio-group
    class="add-mode-radio-group"
    [(ngModel)]="addMode"
    [ngModelOptions]="{ standalone: true }"
    (change)="onChangeMode($event)"
  >
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromInput"
    >
      חדש
    </mat-radio-button>
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromList"
    >
      מתוך רשימה
    </mat-radio-button>
  </mat-radio-group>
  <div class="mb-2" [ngSwitch]="addMode">
    <div class="label" style="margin-bottom: 5px">שם תת קטגוריה</div>
    <div *ngSwitchCase="AddModeEnum.FromList">
      <div>
        <mat-form-field class="field" appearance="fill">
          <input
            placeholder="בחר מתוך הרשימה"
            type="text"
            matInput
            formControlName="name"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            (optionSelected)="onOptionSubCatSelected($event)"
            class="cat-panel"
            #auto="matAutocomplete"
          >
            <mat-option
              *ngFor="let item of subCatFilteredOptions | async"
              [value]="item.name"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="AddModeEnum.FromInput">
      <div class="mb-2">
        <mat-form-field class="field" appearance="fill">
          <input
            placeholder="הוסף חדש"
            type="text"
            matInput
            formControlName="name"
          />
        </mat-form-field>
      </div>
      <div class="label" style="margin-bottom: 5px">קטגורית אב</div>
      <div>
        <mat-form-field class="field" appearance="fill">
          <input
            placeholder="בחר מתוך הרשימה"
            type="text"
            matInput
            formControlName="catName"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            (optionSelected)="onOptionCatSelected($event)"
            class="cat-panel"
            #auto="matAutocomplete"
          >
            <mat-option
              *ngFor="let item of catFilteredOptions | async"
              [value]="item.name"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
    <div>
      <strong>ערך מינימלי</strong>&nbsp;
      <mat-form-field class="field order" appearance="fill">
        <input matInput type="number" formControlName="rangeStart" />
      </mat-form-field>
    </div>
    <div>
      <strong>ערך מקסימלי</strong>&nbsp;
      <mat-form-field class="field order" appearance="fill">
        <input matInput type="number" formControlName="rangeEnd" />
      </mat-form-field>
    </div>
    <div>
      <strong>אחוז איבוד</strong>&nbsp;
      <mat-form-field class="field order" appearance="fill">
        <input matInput type="number" formControlName="validExceptPersent" />
      </mat-form-field>
    </div>
  </div>
  <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
    <div class="label" style="margin-bottom: -10px">מיקום הקטגוריה</div>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-form-field class="field order" appearance="fill">
      <input matInput type="number" formControlName="orderBy" />
    </mat-form-field>
  </div>
  <div class="mb-2" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center" fxFlex="30">
      <strong>PDF אייקון קטן</strong>
      <app-algo-image
        [langId]="langId"
        [algoImageType]="AlgoImageType.PrototypeSubCatSmallIcon"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage(AlgoImageType.PrototypeSubCatSmallIcon)"
        [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeSubCatSmallIcon)"
      ></app-algo-image>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center"
      fxFlex="30"
      style="margin-right: 20px"
    >
      <strong>Desktop אייקון</strong>
      <app-algo-image
        [langId]="langId"
        [algoImageType]="AlgoImageType.PrototypeSubCatBigIcon"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage(AlgoImageType.PrototypeSubCatBigIcon)"
        [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeSubCatBigIcon)"
      ></app-algo-image>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center"
      fxFlex="30"
      style="margin-right: 20px"
    >
      <strong>אייקון ל PDF (מסגרת)</strong>
      <app-algo-image
        [langId]="langId"
        [algoImageType]="AlgoImageType.PrototypeSubCatPdfBorderTop"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage(AlgoImageType.PrototypeSubCatPdfBorderTop)"
        [checkedImgId]="
          getCheckedImage(AlgoImageType.PrototypeSubCatPdfBorderTop)
        "
      ></app-algo-image>
    </div>
  </div>

  {{ errorMessage }}
  <div class="mb-4"></div>
  <div class="form-action">
    <button mat-raised-button type="submit" class="save">שמירה</button>
  </div>
</form>
