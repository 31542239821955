import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit, OnChanges, OnDestroy {
  emojis: boolean = false;
  chatMessage: any = '';
  @Input() resetInput: boolean;
  @Output() resetInputEvent = new EventEmitter<boolean>();
  @Output() sendMessageEvent = new EventEmitter<string>();
  @Output() uploadImageEvent = new EventEmitter<any>();
  @Output() onStartTypingEvent = new EventEmitter<boolean>();
  constructor(private firebaseService: FirebaseChatIndicatorService) {}
  ngOnDestroy(): void {
    window.removeEventListener('click', this.closeEmotion);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.resetInput && !changes.resetInput.isFirstChange()) {
      this.chatMessage = '';
      setTimeout(() => {
        this.resetInputEvent.emit(false);
      }, 100);
    }
  }

  ngOnInit(): void {
    window.addEventListener('click', this.closeEmotion);
  }

  closeEmotion = () => {
    if (this.emojis) {
      this.emojis = false;
    }
  };
  preventCloseEmoticons(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  addEmoji(event: any) {
    this.chatMessage += event.emoji.native;
  }
  openEmoticons(e: any) {
    e.preventDefault();
    e.stopPropagation();

    this.emojis = !this.emojis;
  }

  uploadImg(files: any) {
    if (files.length === 0) {
      return;
    }
    //const fileToUpload = files[0] as File;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    this.uploadImageEvent.emit(formData);
  }

  onKeyUp(event: any) {
    if (this.chatMessage.length > 0) {
      this.onStartTypingEvent.emit(true);
    } else {
      this.onStartTypingEvent.emit(false);
    }
  }

  onEnter(event: any) {
    event.preventDefault();
    this.sendMessage();
  }

  sendMessage() {
    if (this.chatMessage) {
      this.sendMessageEvent.emit(this.chatMessage);
      this.chatMessage = '';
    }
  }
}
