<div class="select-template">
  <div class="select-template__preview">
    <img
      *ngIf="selectedTemplate"
      src="../../../assets/dna-template-{{ selectedTemplate }}.png"
      alt=""
    />
    <button
      mat-raised-button
      matSuffix
      (click)="templateBoxOpen = !templateBoxOpen"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
      Select template
    </button>
  </div>

  <mat-card *ngIf="templateBoxOpen" class="select-template__box">
    <mat-card-title class="select-template__box__title"
      >select Template
      <button mat-icon-button (click)="templateBoxOpen = !templateBoxOpen">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-title>
    <hr />
    <mat-card-body>
      <ng-container *ngFor="let item of templateImages">
        <div
          class="select-template__box__img"
          [class.selected]="selectedTemplate == item"
          (click)="onSelectTemplate(item)"
        >
          <img src="../../../assets/dna-template-{{ item }}.png" alt="" />
        </div>
      </ng-container>
    </mat-card-body>
  </mat-card>
</div>
