export class FgParam {
  fgParamId: number;
  fgParamName: string;
  fgParamType: number;
  fgParamValue: number;
  fgParamKeyId: number;
  paramCode: string;
  prototypeParamId: number;
  labVersionId: number;
  fgParamResult: number;
}
