<div class="title" *ngIf="fgVersion.fgVersionId == 0">Create new version</div>
<div class="title" *ngIf="fgVersion.fgVersionId > 0">
  {{ data.isDuplicate ? "Duplicate" : "Edit" }} version
</div>

<div class="grid item mt-4">
  <div class="label">
    {{ data.isDuplicate ? "Duplicate to: " : "Name: " }}
  </div>
  <mat-form-field appearance="fill" class="item__field">
    <input
      [disabled]="isLoading || data.isDuplicate"
      matInput
      [(ngModel)]="fgVersion.fgVersionName"
    />
  </mat-form-field>
</div>
<div class="grid item" *ngIf="labVersions && fgVersion.fgVersionId == 0">
  <!-- if new version -->
  <div class="label">Algo version:</div>
  <mat-form-field class="item__select" appearance="fill">
    <mat-select [(ngModel)]="fgVersion.labVersionId" placeholder="Select">
      <mat-option *ngFor="let item of labVersions" [value]="item.labVersionId">
        {{ item.labName }} - {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="grid item" *ngIf="fgVersion.fgVersionId == 0 || data.isDuplicate">
  <div class="label">
    <mat-checkbox
      [(ngModel)]="isDuplicate"
      *ngIf="!data.isDuplicate"
    ></mat-checkbox>
    Duplicate from:
  </div>
  <mat-form-field class="item__select" appearance="fill">
    <mat-select
      [disabled]="(!isDuplicate && !data.isDuplicate) || isLoading"
      (selectionChange)="onDuplicateChange($event)"
      placeholder="Select"
    >
      <mat-option
        *ngFor="let item of data.fgVersions"
        [value]="item.fgVersionId"
      >
        {{ item.fgVersionName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="grid item" *ngIf="fgVersion.fgVersionId > 0 && !data.isDuplicate">
  <div class="label">Final formula:</div>
  <mat-form-field class="item__select" appearance="fill">
    <mat-select
      [(ngModel)]="fgVersion.versionResultParamId"
      placeholder="Select"
    >
      <mat-option *ngFor="let item of formulas" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<!-- 
<div class="grid item">
  <div class="label">Value:</div>
  <mat-form-field appearance="fill" class="item__field">
    <input type="number" matInput [(ngModel)]="data.label.maxValue" />
  </mat-form-field>
</div>

<div class="grid item">
  <div class="label">Order by:</div>
  <mat-form-field appearance="fill" class="item__field">
    <input type="number" matInput [(ngModel)]="data.label.orderBy" />
  </mat-form-field>
</div> -->
<div class="actions mt-4">
  <div class="actions__empty"></div>
  <div class="actions__btns">
    <button
      [disabled]="
        !fgVersion.fgVersionName ||
        (isDuplicate && !duplicateId) ||
        !fgVersion.labVersionId ||
        isLoading
      "
      class="save"
      mat-button
      (click)="save()"
      type="submit"
    >
      Save
      <mat-icon *ngIf="isLoading">
        <mat-spinner diameter="20"></mat-spinner
      ></mat-icon>
    </button>
    <button
      class="cancel"
      mat-button
      mat-dialog-close
      type="button"
      [disabled]="isLoading"
    >
      Close
    </button>
  </div>
</div>
