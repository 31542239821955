import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../data/Company';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<Company[]>(`${environment.baseUrl}Companies/`);
  }
}
