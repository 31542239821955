import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { Menu } from 'src/app/data/Menu';
import { MenuForSite } from 'src/app/data/MenuForSite';
import { MenuForTable } from 'src/app/data/MenuForTable';
import { MenuToNutrient } from 'src/app/data/MenuToNutrient';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { FoodNutrientsZameret } from 'src/app/Enums/StatusesEnum';
import { NutriMenuService } from 'src/app/Services/nutri-menu.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-menu-list-view',
  templateUrl: './menu-list-view.component.html',
  styleUrls: ['./menu-list-view.component.scss'],
})
export class MenuListViewComponent implements OnInit {
  showCaloriesPie: boolean = false;
  @Input() productId: string;
  @Input() canEditMenu: boolean;
  @Input() isLoading: boolean;
  @Input() isTemplate: boolean;
  @Output('menuIdForEdit')
  menuIdForEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output('editMode')
  editMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('getMenuList')
  getMenuList: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() menuList: MenuForTable[];

  menu: MenuForSite;
  paiData: any[] = [];
  barData: any[] = [];
  colorScheme = {
    domain: ['#1b3f4e', '#119c99'],
  };
  menuPdfOpened: boolean;

  constructor(
    private nutriMenuService: NutriMenuService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {}

  getBarData(item: MenuForTable) {
    const menuItem = item?.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
    return {
      goal: Math.round(menuItem?.dailyAmount),
      menu: Math.round(menuItem?.amount),
    };
    /* for (let i = 0; i < this.menuList.length; i++) {
      let item = this.menuList[i];
      if (!item) return;
      const menuItem = item?.menuToNutrients?.find(
        (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
      );
      this.paiData.push([
        {
          goal: {
            value: Math.round(menuItem?.dailyAmount),
            name: 'Goal Calories',
            color: '#1b3f4e',
            colorValue: '#d3247e',
            icon: 'emoji_events',
          },
        },
        {
          menu: {
            value: Math.round(menuItem?.menu),
            name: 'Menu Calories',
            color: '#119c99',
            colorValue: '#1b3f4e',
          },
        },
      ]);
    } */
  }

  getByMenuIdForHtml(menuId: number, openPrintDialog: boolean = false) {
    this.nutriMenuService
      .getByMenuIdForHtml(menuId, this.productId, 1)
      .subscribe((data) => {
        this.menu = data;
        this.menu.menuId = menuId;
        if (openPrintDialog) {
          this.popupHandlerService.openMenuPdfDialog(this.menu);
        }
      });
  }

  generateHtmlMenu(menuId: number) {
    this.menuPdfOpened = false;
    this.menu = null;
    this.popupHandlerService.openConfirmationDialog(null, 'Send mail and sms');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.getByMenuIdForHtml(menuId);
        }
      });
  }

  printAsPdf(menuId: number) {
    this.menuPdfOpened = true;
    if (!this.menu) {
      this.getByMenuIdForHtml(menuId, true);
    } else {
      this.popupHandlerService.openMenuPdfDialog(this.menu);
      this.menuPdfOpened = false;
    }
  }

  onHtmlReady(html: string) {
    if (!this.menuPdfOpened) this.sendToMail(this.menu.menuId, html);
  }

  sendToMail(menuId: number, html: string = '') {
    this.nutriMenuService
      .sendToMail(menuId, +this.productId, html)
      .subscribe((actionResponses) => {
        var txt = actionResponses
          .map(
            (res) =>
              //   `<span style='color:${res.isSuccess ? 'green' : 'red'};'>${
              `<span class="${res.isSuccess ? 'color-green' : 'color-red'}">${
                res.actionName
              }  ${res.message}</span>`
          )
          .join('<br/>');
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.actionResults,
          '',
          '',
          txt,
          2500000 * actionResponses.length
        );
      });
  }

  setGeneticRateOffset(rate: number) {
    if (rate) return rate > 12 ? '101%' : '100%';
    else return '100%';
  }

  getMenuForEdit(menuId: number) {
    this.menuIdForEdit.emit(menuId);
    this.editMode.emit(true);
  }

  getGeneticRate(nutrients: MenuToNutrient[]) {
    return nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.geneticRate
    )?.amount;
  }
  getCalories(nutrients: MenuToNutrient[]) {
    return nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    )?.amount;
  }
  getFats(nutrients: MenuToNutrient[]) {
    return nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.total_fat
    )?.amount;
  }
  getCarbs(nutrients: MenuToNutrient[]) {
    return nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.carbohydrates
    )?.amount;
  }
  getProteins(nutrients: MenuToNutrient[]) {
    return nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.protein
    )?.amount;
  }
  getVitemins(nutrients: MenuToNutrient[]) {
    var c =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_c)
        ?.amount > 0
        ? 'c'
        : null;
    var b12 =
      nutrients?.find(
        (x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_b12
      )?.amount > 0
        ? 'b12'
        : null;
    var iron =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.iron)
        ?.amount > 0
        ? 'iron'
        : null;
    var magnesium =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.magnesium)
        ?.amount > 0
        ? 'magnesium'
        : null;
    var folate =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.folate)
        ?.amount > 0
        ? 'folate'
        : null;
    var calcium =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.calcium)
        ?.amount > 0
        ? 'calcium'
        : null;
    var zinc =
      nutrients?.find((x) => x.foodNutrientId == FoodNutrientsZameret.zinc)
        ?.amount > 0
        ? 'zinc'
        : null;

    var viteminsArr = [b12, iron, c, magnesium, folate, calcium, zinc];
    var viteminsArr2 = [];

    viteminsArr.forEach((vitemin) => {
      if (vitemin) {
        viteminsArr2.push(vitemin);
      }
    });

    return viteminsArr2.join(', ');
  }

  setGradient(value: number) {
    if (value >= 0 && value <= 25) {
      return 'low-bar';
    } else if (value >= 26 && value <= 50) {
      return 'medium-bar';
    } else if (value >= 51 && value <= 75) {
      return 'high-bar';
    } else if (value >= 76 && value <= 100) {
      return 'very-high';
    }
  }

  deleteMenu(menuId: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.nutriMenuService.delete(menuId).subscribe(() => {
            this.getMenuList.emit(true);
            this.popupHandlerService.openDisapearingAlertDialog('success');
          });
        }
      });
  }
}
