import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgentSettings } from 'src/app/data/AgentSettings';
import { Language } from 'src/app/data/Language';
import { DepartmentType } from 'src/app/Enums/ChatEnum';
import { AgentSettingsService } from 'src/app/Services/agent-settings.service';
import { Location } from '@angular/common';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-agent-settings',
  templateUrl: './agent-settings.component.html',
  styleUrls: ['./agent-settings.component.scss'],
})
export class AgentSettingsComponent implements OnInit {
  queryAgentId: number;
  langId: number = 1;
  agentSettings: AgentSettings;
  departments: {};
  AvatarImageType = 44;
  constructor(
    private route: ActivatedRoute,
    private agentSettingsService: AgentSettingsService,
    public location: Location,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryAgentId = +params['agentId'];
      if (this.queryAgentId != -1) {
        this.getAgentSettings();
        this.departments = this.makeObjectFromEnum(DepartmentType);
      }
    });
  }

  getAgentSettings() {
    this.agentSettingsService
      .getAgentSettings(this.queryAgentId, this.langId)
      .subscribe((data) => {
        if (data?.agentId) {
          this.agentSettings = data;
        } else {
          this.agentSettings = new AgentSettings();
          this.agentSettings.agentId = this.queryAgentId;
        }
      });
  }

  onUploadFinished(event: AppImage) {
    if (event) {
      this.agentSettings.avatar = event;
    }
  }
  getImage() {
    const image = this.agentSettings.avatar;
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage() {
    const image = this.agentSettings.avatar;
    return image?.fileId;
  }

  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
    this.getAgentSettings();
  }

  makeObjectFromEnum(enumType: any) {
    return Object.keys(enumType)
      .filter((key) => isNaN(Number(key)))
      .map((key) => {
        return {
          id: enumType[key],
          name: key,
        };
      });
  }

  showSeccuss() {
    this._matSnackBar.open('Saved', 'OK', { duration: 2000 });
  }

  save() {
    this.agentSettings.langId = this.langId;
    if (this.agentSettings.agentSettingId) {
      this.agentSettingsService
        .setAgentSettings(this.agentSettings)
        .subscribe(() => this.showSeccuss());
    } else {
      this.agentSettingsService
        .addAgentSettings(this.agentSettings)
        .subscribe(() => this.showSeccuss());
    }
  }
}
