<div class="dashboardThumb">
  <span [innerHtml]="note.title" style="width: 16%">{{ note.title }}</span>
  <span style="width: 20%">
    <perfect-scrollbar
      [innerHtml]="note.desc"
      style="max-height: 48px"
      [perfectScrollbar]="config"
    >
      {{ note.desc }}</perfect-scrollbar
    >
  </span>
  <span style="width: 12.8%">{{ note.dueDate | date : "dd-MM-yy" }}</span>
  <span style="width: 12.8%"> {{ note.dueDate | date : "HH:mm" }} </span>
  <span style="width: 12.8%">
    {{ getRelated(note) }}
  </span>
  <span style="width: 12.8%">
    <span
      (click)="getLink(note)"
      style="color: #00aeee; text-decoration: underline; cursor: pointer"
      >{{ note.fullName ? note.fullName : "No name" }}</span
    >
  </span>
  <span style="width: 12.8%">
    <button (click)="changeToDone()" mat-raised-button>Done</button>
  </span>
</div>
<mat-divider></mat-divider>
