import { Component, OnInit } from '@angular/core';
import { ChatHour } from 'src/app/data/ChatHour';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { ChatHourService } from 'src/app/Services/chat-hour.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-chat-hour',
  templateUrl: './chat-hour.component.html',
  styleUrls: ['./chat-hour.component.scss'],
})
export class ChatHourComponent implements OnInit {
  chatHours: ChatHour[][];
  hoursList: any[] = [];
  constructor(
    private chatHourService: ChatHourService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getAll();
    this.hoursListFn();
  }

  getAll() {
    this.chatHourService.getAll().subscribe((data) => {
      data.forEach((x) => {
        if (x.hourEnd == null) {
          x.hourEnd = 'NOT ACTIVE';
        }
        if (x.hourStart == null) {
          x.hourStart = 'NOT ACTIVE';
        }
      });
      this.chatHours = this.groupBy(data, 'dayInWeek');
    });
  }

  addChatHour(dayInWeek: number, hourEnd: string) {
    var chatHour = new ChatHour();
    chatHour.dayInWeek = dayInWeek;
    chatHour.hourStart = hourEnd;
    chatHour.hourEnd = '18:00:00';
    this.chatHourService.post(chatHour).subscribe(() => {
      this.getAll();
    });
  }

  onHourChange(chatHour: ChatHour) {
    if (chatHour.hourStart === 'NOT ACTIVE') {
      chatHour.hourStart = null;
    }
    if (chatHour.hourEnd === 'NOT ACTIVE') {
      chatHour.hourEnd = null;
    }
    this.chatHourService.put(chatHour).subscribe(() => {
      this.getAll();
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.success
      );
    });
  }

  deleteChatHour(chatHourId: number) {
    this.popupHandlerService.openConfirmationDialog(
      '',
      'You about to delete this item'
    );

    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data.ans == 'yes') {
        this.chatHourService.delete(chatHourId).subscribe(() => {
          this.getAll();
        });
      }
    });
  }

  groupBy(collection: ChatHour[], property: string) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  ungroup(chatHours) {
    var flatList: ChatHour[] = [];
    chatHours.forEach((list) => {
      list.forEach((obj) => {
        flatList.push(obj);
      });
    });
    return flatList;
  }

  hoursListFn() {
    for (var i = 9; i <= 18; i++) {
      this.hoursList.push({
        value: i == 9 ? '0' + i + ':00:00' : i + ':00:00',
        name: i == 9 ? '0' + i + ':00' : i + ':00',
      });
    }
    this.hoursList.push({ value: 'NOT ACTIVE', name: 'NOT ACTIVE' });
  }

  dayNames(dayInWeek: number) {
    switch (dayInWeek + 1) {
      case 1:
        return 'Sunday';
      case 2:
        return 'Monday';
      case 3:
        return 'Tuesday';
      case 4:
        return 'Wednesday';
      case 5:
        return 'Thursday';
      case 6:
        return 'Friday';
      case 7:
        return 'Saturday';
    }
  }

  restoreToDefault() {
    this.chatHourService.restoreDefault().subscribe((data) => {
      this.getAll();
    });
  }

  setAsDefault() {
    this.chatHours.map((x) => {
      x.map((y) => {
        if (y.hourEnd === 'NOT ACTIVE') {
          y.hourEnd = null;
        }
        if (y.hourStart === 'NOT ACTIVE') {
          y.hourStart = null;
        }
      });
    });
    this.chatHourService
      .SetDefault(this.ungroup(this.chatHours))
      .subscribe((res) => {
        this.getAll();
      });
  }
}
