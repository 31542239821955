import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Note } from '../data/Note';
import { NotesService } from './notes.service';
import { NoteForMissionsMin } from '../data/NoteForMissionsMin';

@Injectable({
  providedIn: 'root',
})
export class NotesAgentPusherService {
  private agentId: Number;
  constructor(private noteService: NotesService) {}
  private readonly agentNotes = new BehaviorSubject<NoteForMissionsMin[]>([]);
  readonly agentNotes$ = this.agentNotes.asObservable();
  getAgentNotes(agentId: Number = null) {
    if (agentId) this.agentId = agentId;
    this.noteService.getByAgentIdOld(this.agentId, 2).subscribe((data) => {
      data.map((item) => {
        item.dueDateObj = new Date(Date.parse(item.dueDate));
      });
      this.agentNotes.next(data);
    });
  }
  put(note: Note) {
    this.noteService.put(note).subscribe((data) => {
      this.getAgentNotes();
    });
  }
  changeStatusMin(note: NoteForMissionsMin) {
    this.noteService.changeStatusMin(note).subscribe((data) => {
      this.getAgentNotes();
    });
  }
  post(note: Note) {
    this.noteService.post(note).subscribe((data) => {
      this.getAgentNotes(this.agentId);
    });
  }
  reset() {
    this.agentId = -1;
    this.agentNotes.next([]);
  }
}
