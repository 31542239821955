import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrototypeParam } from 'src/app/data/food-genes/PrototypeParam';

@Component({
  selector: 'app-food-genes-add-parameter',
  templateUrl: './food-genes-add-parameter.component.html',
  styleUrls: ['./food-genes-add-parameter.component.scss'],
})
export class FoodGenesAddParameterComponent implements OnInit {
  parameter: PrototypeParam;
  parameterId: number;
  constructor(
    private dialogRef: MatDialogRef<FoodGenesAddParameterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PrototypeParam[]
  ) {}

  ngOnInit(): void {}

  addParameter() {
    this.parameter = this.data.find((p) => p.fgParamId === this.parameterId);
    //this.parameter.fgParamId = 0;
    this.dialogRef.close({
      type: 'add',
      parameter: this.parameter,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
