<div class="wrapper edit-template">
  <a class="back" [routerLink]="['/autoresponder']">< Back to templates</a>
  <div class="d-grid-2" *ngIf="emailTemplateLang && emailTemplate">
    <mat-card class="card-template">
      <div class="card-template__title">
        <div class="w-100">
          <span>Template Name: </span>
          <div style="display: inline-block">
            <mat-form-field class="w-500px" appearance="fill">
              <input matInput [(ngModel)]="emailTemplate.displayName" />
            </mat-form-field>
            <div class="errors" *ngIf="errors?.displayName">
              {{ errors.displayName }}
            </div>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small
            >Last change:
            {{ emailTemplateLang.updateDate | date: "dd-MM-yyyy" }}</small
          >
        </div>
        <mat-form-field class="select-lang" appearance="fill">
          <mat-label>Language</mat-label>
          <mat-select
            [(ngModel)]="langId"
            (selectionChange)="changeTemplateLang()"
          >
            <mat-option
              *ngFor="let lang of languages"
              [value]="lang.languageId"
              >{{ lang.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="card-template__body">
        <div class="mb-2"></div>
        <div class="d-flex">
          <div class="title pb-0">Send by:</div>
          <div class="ml-2">
            <mat-checkbox
              [(ngModel)]="emailTemplate.sendSms"
              class="example-margin"
              >SMS</mat-checkbox
            >
          </div>
          <div class="ml-2">
            <mat-checkbox
              [(ngModel)]="emailTemplate.sendMail"
              class="example-margin"
              >Email</mat-checkbox
            >
          </div>
          <div style="display: flex; align-items: center">
            <div class="title pb-0" style="margin-left: 20px">
              max Send Count :
            </div>
            <mat-form-field appearance="fill" style="width: 50px">
              <input
                matInput
                type="number"
                placeholder="max Send Count"
                [(ngModel)]="emailTemplate.maxSendCount"
              />
            </mat-form-field>
          </div>
          <div style="display: flex; align-items: center">
            <div class="title pb-0" style="margin-left: 20px">
              min Day Send Interval :
            </div>
            <mat-form-field appearance="fill" style="width: 50px">
              <input
                matInput
                width="50"
                type="number"
                placeholder="min Day Send Interval"
                [(ngModel)]="emailTemplate.minDaySendInterval"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="mb-2"></div>
        <div [class.subject-width-preview]="!showPreview">
          <div class="title">Action description</div>
          <mat-form-field appearance="fill" class="w-100">
            <input
              matInput
              placeholder="action description"
              [(ngModel)]="emailTemplate.actionName"
            />
          </mat-form-field>
          <div class="errors" *ngIf="errors?.actionName">
            {{ errors.actionName }}
          </div>
        </div>
        <div class="mb-2"></div>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Email"
            ><div class="email">
              <div class="mb-2"></div>
              <div [class.subject-width-preview]="!showPreview">
                <div class="title">
                  Subject&nbsp;&nbsp;
                  <small
                    >* In the SMS subject will not appear in the message
                    content</small
                  >
                </div>
                <mat-form-field appearance="fill" class="w-100">
                  <input
                    matInput
                    placeholder="subject"
                    [(ngModel)]="emailTemplateLang.subject"
                    (change)="setDirty()"
                  />
                </mat-form-field>
              </div>
              <div class="mb-2"></div>
              <div dir="rtl" class="d-flex">
                <div class="editor-field" [class.full-width]="showPreview">
                  <div class="title">Message content</div>
                  <angular-editor
                    [config]="editorConfig"
                    #textEmailField
                    [(ngModel)]="emailTemplateLang.textEmail"
                    (ngModelChange)="setDirty()"
                  ></angular-editor>
                </div>
                <div class="legend">
                  <div></div>
                  <hr />
                  <div>
                    <app-upload-results-csv
                      [title]="'Attach File'"
                      [uploadPath]="getFilesUploadPath()"
                      (onUploadFinished)="uploadFileFinished($event)"
                      [classAttr]="'emailTemplates'"
                    ></app-upload-results-csv>
                    <br />

                    <div
                      class="legend__item"
                      *ngFor="
                        let fileAtachment of emailTemplateLang.fileAtachments
                      "
                    >
                      <div class="legend__item__text">
                        {{ getFileName(fileAtachment) }}
                      </div>
                      <button
                        (click)="onDownloadFile(fileAtachment)"
                        class="legend__item__btn"
                        mat-mini-fab
                        color="primary"
                      >
                        <mat-icon>arrow_downward</mat-icon>
                      </button>
                      <button
                        (click)="onDeleteFile(fileAtachment)"
                        class="legend__item__btn"
                        mat-mini-fab
                        color="primary"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="dynamic-files">
                    <mat-form-field
                      dir="ltr"
                      style="text-align: left"
                      appearance="fill"
                    >
                      <mat-label>Dynamic files</mat-label>
                      <mat-select
                        [(ngModel)]="selectedDynamicAtachmentFiles"
                        (selectionChange)="
                          onChangeDynamicAtachmentFiles($event)
                        "
                        multiple
                      >
                        <mat-option
                          *ngFor="let item of dynamicAtachmentFiles"
                          [value]="item.dynamicTypeId"
                          >{{ item.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      class="dynamic-list__item"
                      *ngFor="
                        let item of emailTemplate.emailTemplateDynamicAttachments
                      "
                    >
                      {{ item.dynamicAttachmentFile.displayName }}
                    </div>
                  </div>
                  <div class="legend-box">
                    <button
                      *ngFor="let legend of legends"
                      (click)="setLegendInEmailField(legend)"
                      mat-button
                    >
                      {{ legend }}</button
                    >&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="SMS">
            <div class="mb-2"></div>
            <div class="d-flex-top">
              <div class="flex-1">
                <div class="title">Message content</div>
                <mat-form-field appearance="fill" class="w-100">
                  <textarea
                    matTextareaAutosize
                    matAutosizeMinRows="8"
                    matInput
                    #textSmsField
                    [(ngModel)]="emailTemplateLang.textSms"
                    class="sms-field"
                    (change)="setDirty()"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="legend no-fixed">
                <div class="title">Tags</div>
                <div class="legend-box">
                  <button
                    *ngFor="let legend of legends"
                    (click)="setLegendInSmsField(legend)"
                    mat-button
                  >
                    {{ legend }}</button
                  >&nbsp;
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="card-template__actions">
        <!--  <button (click)="showPreview = true" mat-button>Preview</button> -->
        <button (click)="saveEmailTemplate()" mat-button class="pink-fill">
          Save
        </button>
        <button (click)="resetTemplate()" mat-button class="pink">
          Reset template
        </button>
      </div>
    </mat-card>
    <!--  <mat-card
      class="card-preview"
      [class.full-screen]="fullPreview"
      [class.show]="showPreview"
    >
      <div class="card-preview__header">
        <div>
          <button mat-icon-button (click)="fullPreview = !fullPreview">
            <mat-icon *ngIf="!fullPreview">fullscreen</mat-icon>
            <mat-icon *ngIf="fullPreview">fullscreen_exit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="showPreview = false; fullPreview = false"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="card-preview__body">
        <angular-editor
          [config]="previewConfig"
          [(ngModel)]="emailTemplateLang.textEmail"
        ></angular-editor>
      </div>
    </mat-card> -->
  </div>
</div>
