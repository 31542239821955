import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlgoResultType } from 'src/app/data/algorithm/AlgoResultType';

@Component({
  selector: 'app-add-result-type',
  templateUrl: './add-result-type.component.html',
  styleUrls: ['./add-result-type.component.scss'],
})
export class AddResultTypeComponent implements OnInit {
  resultTypeId: number;
  constructor(
    public dialogRef: MatDialogRef<AddResultTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlgoResultType[]
  ) {}
  ngOnInit(): void {}
  addType() {
    this.dialogRef.close({
      type: 'add',
      id: this.resultTypeId,
    });
  }
}
