import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AgentInteraction } from '../data/AgentInteraction';

@Injectable({
  providedIn: 'root',
})
export class AgentInteractionService {
  constructor(private http: HttpClient) {}

  getById(agentId: number) {
    return this.http.get<AgentInteraction>(
      `${environment.baseUrl}AgentInteractions/${agentId}`
    );
  }
  post(agentInteraction: AgentInteraction) {
    return this.http.post<AgentInteraction>(
      `${environment.baseUrl}AgentInteractions`,
      agentInteraction
    );
  }
}
