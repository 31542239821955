import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { CallsReviewsEditService } from 'src/app/Service/calls-reviews-edit.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-calls-table',
  templateUrl: './calls-table.component.html',
  styleUrls: ['./calls-table.component.scss'],
})
export class CallsTableComponent implements OnInit {
  @Input() headFilter: HeadFilter;
  @Input() reviews: Note[];
  @Input() clientId: number = -1;
  @Input() patientId: number = -1;
  @Input() isLoading: boolean;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  headers = [
    { name: 'Date of call', val: 'Date' },
    { name: 'Client name', val: 'name' },
    { name: 'Call subject', val: 'subject' },
    { name: 'Agent', val: 'agent' },
    { name: 'Rating', val: 'Rating' },
    { name: 'Client comment', val: 'Comment' },
  ];
  constructor(
    private popupHandlerService: PopupHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private callsReviewsEditService: CallsReviewsEditService
  ) {}

  ngOnInit(): void {}

  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }

  editReview(
    event,
    { clientId, patientId, productId, noteId }: NoteForCallMin
  ) {
    if (this.clientId == -1 && this.patientId == -1) {
      if (clientId != 0) {
        this.router.navigate(['/clients/edit/'], {
          queryParams: { clientId: clientId, noteId: noteId },
        });
      } else if (productId != 0) {
        this.router.navigate(['/tests/edit/'], {
          queryParams: { productId: productId, noteId: noteId },
        });
      }
    } else {
      this.callsReviewsEditService.onEdit.next(noteId);
    }
  }

  getRating(rating) {
    if (rating && rating > 0) {
      var stars = '';
      for (let i = 0; i < rating; i++) {
        stars += '&#9733;';
      }
      return stars;
    }
    return 'Not rated yet';
  }
  openCustomerReview(review) {
    if (review) {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.customerReview,
        '',
        '',
        review,
        -1,
        true
      );
    }
  }
}
