import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Note } from '../data/Note';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ServiceRequest } from '../data/ServiceRequest';
import { HeadFilter } from '../data/HeadFilter';
import { RequestForTableMin } from '../data/RequestForTableMin';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestsService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getAll(headFilter: HeadFilter): Observable<RequestForTableMin[]> {
    return this.http.get<RequestForTableMin[]>(
      `${
        environment.baseUrl
      }ServiceRequests/Filter/${this.httpFilterHelper.createRequestsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getChatList() {
    return this.http.get<RequestForTableMin[]>(
      `${environment.baseUrl}ServiceRequests/ChatList`
    );
  }
  getByClientId(clientId): Observable<RequestForTableMin[]> {
    return this.http.get<RequestForTableMin[]>(
      `${environment.baseUrl}ServiceRequests/ForClient/${clientId}`
    );
  }
  getByProductId(productId): Observable<RequestForTableMin[]> {
    return this.http.get<RequestForTableMin[]>(
      `${environment.baseUrl}ServiceRequests/ForProduct/${productId}`
    );
  }

  getById(serviceRequestId): Observable<ServiceRequest> {
    return this.http.get<ServiceRequest>(
      `${environment.baseUrl}ServiceRequests/${serviceRequestId}`
    );
  }
  put(request: ServiceRequest): Observable<ServiceRequest> {
    return this.http.put<ServiceRequest>(
      `${environment.baseUrl}ServiceRequests/${request.serviceRequestId}`,
      request
    );
  }
  putMin(request: RequestForTableMin): Observable<RequestForTableMin> {
    return this.http.put<RequestForTableMin>(
      `${environment.baseUrl}ServiceRequests/Min/${request.serviceRequestId}`,
      request
    );
  }
  post(request: ServiceRequest): Observable<ServiceRequest> {
    return this.http.post<ServiceRequest>(
      `${environment.baseUrl}ServiceRequests/`,
      request
    );
  }
  requestsStatuses(list: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrl}ServiceRequests/requestsStatuses`,
      list
    );
  }
}
