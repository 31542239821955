import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from '../data/Product';
import { TubeTestSubCategoryResult } from '../data/TubeTestSubCategoryResult';
import { CatergoriesService } from '../Services/catergories.service';
import { ProductsService } from '../Services/products.service';
import { RegularUsersService } from '../Services/regular-users.service';
import { TubeTestResultsService } from '../Services/tube-test-results.service';

@Component({
  selector: 'app-dna-report-inner-v1',
  templateUrl: './dna-report-inner-v1.component.html',
  styleUrls: ['./dna-report-inner-v1.component.scss'],
})
export class DnaReportInnerV1Component implements OnInit {
  categoryId: number;
  productId: number;
  results: TubeTestSubCategoryResult[];
  rankResults: TubeTestSubCategoryResult[];
  // rankTwoResults: TubeTestSubCategoryResult[];
  // rankThreeResults: TubeTestSubCategoryResult[];

  barcode: string;
  tubeTestResults: Object;
  displayedColumns: string[] = ['geneName', 'snpNumber', 'result', 'info'];
  rankInx: number = 3;
  product: Product;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private catergoriesService: CatergoriesService,
    private regularUsersService: RegularUsersService,
    private tubeTestResultsService: TubeTestResultsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.categoryId = +this.route.snapshot.queryParamMap.get('categoryId');
    this.productId = +this.route.snapshot.queryParamMap.get('productId');

    this.catergoriesService
      .getByCategoryIdV1(this.productId, this.categoryId)
      .subscribe((data) => {
        this.results = data;
        this.setRankResults();
      });
    this.productsService.getById(this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
        this.tubeTestResultsService
          .getByBarcodeAndCategoryV1(this.product.barCode, this.categoryId)
          .subscribe((data) => {
            this.tubeTestResults = data;
          });
      } else {
        this.router.navigate(['home']);
      }
    });
  }
  goBack() {
    this.router.navigate(['tests/edit/dna-report'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
  hideToolTip(element) {}
  showToolTip(element) {}
  changeRankTab(inx) {
    this.rankInx = inx;
    this.setRankResults();
  }
  setRankResults() {
    if (this.categoryId == -1) {
      this.rankResults = this.results;
    } else {
      this.rankResults = this.results.filter(
        (x) => x.subCategoryResult.order == this.rankInx
      );
    }
  }
  colorNumbers(txt: string) {
    //return txt;
    var a = txt;

    for (var i = 1; i < 10; i++) {
      var t1 = txt.split(i + '.');
      if (t1.length > 1)
        txt = t1[0] + '<span style="color:green">' + i + '.</span>' + t1[1];
      if (t1.length > 2) {
        for (var j = 2; j < t1.length; j++) {
          txt += t1[j];
        }
      }
    }
    return txt;
  }
  stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
