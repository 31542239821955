import { Component, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';

@Component({
  selector: 'app-complex-message',
  templateUrl: './complex-message.component.html',
  styleUrls: ['./complex-message.component.scss'],
})
export class ComplexMessageComponent implements OnInit {
  message: ChatMessage;
  langId: number;
  constructor() {}

  ngOnInit(): void {}
}
