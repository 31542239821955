import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Note } from 'src/app/data/Note';
import { ActivatedRoute } from '@angular/router';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { ClientRequestNote } from 'src/app/data/food-genes/ClientRequestNote';

@Component({
  selector: 'app-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.css'],
})
export class ClientNotesComponent implements OnInit, OnChanges {
  @Input() notes: NoteForCallMin[] | ClientRequestNote[];
  @Input() isAppReport: boolean;
  @Input() classAttr;
  @Output('openNewNote') openNewNote: EventEmitter<any> = new EventEmitter();
  @Output('reloadNotes') reloadNotes: EventEmitter<any> = new EventEmitter();
  disableNew: boolean = false;
  regularUser: RegularUser;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  constructor(
    private route: ActivatedRoute,
    private regularUserService: RegularUsersService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.regularUserService.currentUser.subscribe(
      (user) => (this.regularUser = user)
    );
    this.route.queryParamMap.subscribe((params) => {
      var leadId = params.get('clientId');
      var productId = params.get('productId');
      if (leadId === '-1') {
        this.disableNew = true;
      }
    });
  }
  onOpenNewNote() {
    this.openNewNote.emit(new Note());
  }
  onEditNote(note: NoteForCallMin | ClientRequestNote) {
    const appNote = note as NoteForCallMin;
    const clientRequestNote = note as ClientRequestNote;
    if (!this.isAppReport) {
      if (appNote.assigningAgent.agentId == this.regularUser.agent.agentId)
        this.openNewNote.emit(note);
    } else {
      if (clientRequestNote.authorAgentId == this.regularUser.agent.agentId)
        this.openNewNote.emit(note);
    }
  }
  onReloadNotes(event) {
    if (event) this.reloadNotes.emit(event);
  }
}
