import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FoodNutrientLang } from '../data/FoodNutrientLang';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodNutrientLangsService {
  constructor(private http: HttpClient) {}

  getAll(langId: number) {
    return this.http.get<FoodNutrientLang[]>(
      `${environment.baseUrl}FoodNutrientLangs/All/${langId}`
    );
  }
}
