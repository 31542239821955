import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { NameAndId } from 'src/app/data/NameAndId';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { SourceKeys } from 'src/app/Enums/StatusesEnum';
import { FoodsService } from 'src/app/Services/foods.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-food-table',
  templateUrl: './food-table.component.html',
  styleUrls: ['./food-table.component.scss'],
})
export class FoodTableComponent implements OnInit, OnChanges {
  @ViewChildren('matCheckbox') matCheckbox: QueryList<MatCheckbox>;
  @Input() isRecipe: boolean;
  @Input() data: NameAndId[];
  @Input() sourceKey: string;
  @Input() selectedLang: number = 1;
  @Output() checkedList: EventEmitter<any> = new EventEmitter<any>();
  @Output() getData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() checkedIds: any;

  constructor(
    private router: Router,
    private popupHandlerService: PopupHandlerService,
    private foodsService: FoodsService //
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.checkedIds.currentValue?.length) {
      this.data.forEach((item) => {
        this.isChecked(item.id, false);
      });
      this.matCheckbox?.forEach((item) => {
        item.checked = false;
      });
    }
  }

  ngOnInit(): void {
    //this.checkedIdsInner = [...this.checkedIds];
  }
  goToEditfood(item: NameAndId) {
    this.router.navigate(['foods/edit'], {
      queryParams: {
        foodId: item.id,
        langId: this.selectedLang,
        isRecipe: this.isRecipe,
        sourceKey: this.sourceKey,
      },
    });
  }
  delete(item: NameAndId) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.foodsService.delete(item.id).subscribe((data) => {
            if ((data as ErrorMessage)?.message) {
              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.costumMessage,
                '',
                '',
                'Error: ' + (data as ErrorMessage).message
              );
            } else {
              this.getData.emit(true);
              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.success
              );
              //this.data = [...this.data];
            }
          });
        }
      });
  }
  getSourceKey(sourceKey: number) {
    switch (sourceKey) {
      case SourceKeys.zameret:
        return 'Zameret';
      case SourceKeys.usda:
        return 'USDA';
      case SourceKeys.mygenes:
        return 'MyGenes';
    }
  }
  getLangs(langs) {
    return langs.join('/');
  }
  isChecked(clientId: number, checked: boolean) {
    if (checked) {
      this.checkedIds[clientId] = true;
    } else {
      delete this.checkedIds[clientId];
    }
    this.checkedList.emit(this.checkedIds);
  }
}
