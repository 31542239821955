<div class="dashboard" *ngIf="supportDashboard">
  <div class="dashboard__statuses">
    <mat-card
      class="dashboard__statuses__item"
      *ngFor="let item of dashboardUserStatusCounts"
      [style.border-color]="item.StatusColor"
    >
      <div class="dashboard__statuses__item__count">
        {{ item.UsersCount }}
      </div>
      <div class="dashboard__statuses__item__name">
        {{ item.StatusName }}
      </div>
    </mat-card>
  </div>
  <div class="dashboard__rating-and-leaders">
    <div class="dashboard__rating">
      <mat-card class="dashboard__rating__item daily-rating">
        <div class="dashboard__rating__item__name">Daily rating amount</div>
        <div class="dashboard__rating__item__sum">
          <strong>{{ dashboardDailyRaiting.DailyCount }}</strong
          >/{{ dashboardDailyRaiting.DailySum }}
        </div>
      </mat-card>
      <mat-card class="dashboard__rating__item">
        <div class="dashboard__rating__item__name">Monthly average amount</div>
        <div class="dashboard__rating__item__average">
          <mat-icon>star</mat-icon>
          <strong>{{ monthlyAverage() | number: "1.0-1" }}</strong>
        </div>
      </mat-card>
      <mat-card class="dashboard__rating__item">
        <div class="dashboard__rating__item__name">Daily average amount</div>
        <div class="dashboard__rating__item__average">
          <mat-icon>star</mat-icon>
          <strong>{{ dailyAverage() | number: "1.0-1" }}</strong>
        </div>
      </mat-card>
    </div>
    <mat-card class="dashboard__leaders">
      <div class="dashboard__leaders__title">Rating leaders</div>
      <div
        class="dashboard__leaders__agent"
        *ngFor="let item of sortLeaders(dashboardRatingLeaders)"
      >
        <div class="dashboard__leaders__agent__name">{{ item.AgentName }}</div>
        <div class="dashboard__leaders__agent__bar">
          <div
            class="dashboard__leaders__agent__bar__fill"
            [style.width.%]="leaderBar(item)"
          ></div>
        </div>
        <div class="dashboard__leaders__agent__sum">
          {{ item.AgentRaitingCount }} /
          <mat-icon>star</mat-icon>
          <strong>{{ leaderAverage(item) | number: "1.0-1" }}</strong>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="dashboard__statistics">
    <mat-card class="dashboard__statistics__item">
      <mat-icon class="answered">phone</mat-icon>
      <div class="dashboard__statistics__item__count">
        {{ dashboardCallStatistic.AnsweredCallCount }}
      </div>
      <div class="dashboard__statistics__item__name">Answered calls</div>
    </mat-card>
    <mat-card class="dashboard__statistics__item">
      <mat-icon class="missing">call_end</mat-icon>
      <div class="dashboard__statistics__item__count">
        {{ dashboardCallStatistic.MissingCallCount }}
      </div>
      <div class="dashboard__statistics__item__name">Missed calls</div>
    </mat-card>
    <mat-card class="dashboard__statistics__item">
      <mat-icon class="percent">percent</mat-icon>
      <div class="dashboard__statistics__item__count">
        {{ precenageResponse() | number: "1.0-1" }}
      </div>
      <div class="dashboard__statistics__item__name">Precentage response</div>
    </mat-card>
    <mat-card class="dashboard__statistics__item">
      <rg-gauge-chart
        [canvasWidth]="canvasWidth"
        [needleValue]="needleValue"
        [centralLabel]="centralLabel"
        [options]="options"
        [bottomLabel]="bottomLabel"
      ></rg-gauge-chart>
      <div class="dashboard__statistics__item__name" style="padding-top: 10px">
        Average response time (sec)
      </div>
    </mat-card>
  </div>
  <mat-card class="dashboard__board"></mat-card>
</div>
