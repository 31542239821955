import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { Client } from 'src/app/data/Client';
import { Deal } from 'src/app/data/Deal';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { PatientDelivery } from 'src/app/data/PatientDeliveries';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { ProductPatientDetails } from 'src/app/data/ProductPatientDetails';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { Status } from 'src/app/data/Status';
import { UserAdress } from 'src/app/data/UserAdress';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { ProductTypeEnum } from 'src/app/Enums/StatusesEnum';
import { CallsReviewsEditService } from 'src/app/Service/calls-reviews-edit.service';
import { AgentsService } from 'src/app/Services/agents.service';
import { DealsService } from 'src/app/Services/deals.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { NotesService } from 'src/app/Services/notes.service';
import { PatientDeliveriesService } from 'src/app/Services/patient-deliveries.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestsPusherService } from 'src/app/Services/service-requests-pusher.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-edit-test-user',
  templateUrl: './edit-test-user.component.html',
  styleUrls: ['./edit-test-user.component.scss'],
})
export class EditTestUserComponent implements OnInit, AfterViewInit {
  globalParams: GlobalParam[];
  headFilter: any;
  callsStatuses;
  clientCalls;
  clientCallsMetaData: any;
  callToEdit: Note;
  isCallsEditMode: boolean = false;
  requestsStatuses: Status[];
  tabIndex;
  patientsTasks;
  activityToEdit: Note;
  noteToEdit: Note;
  patientNotes: NoteForCallMin[];
  isNoteEditMode: boolean = false;
  leadStatuses;
  client: Client;
  clientDeals: Deal[];
  clientInteractions;
  isPurchaseMode = false;
  isEditMode = false;
  clientForm;
  hideCustumerPlaceholder: boolean = false;
  hideSourcePlaceholder: boolean = false;
  hideAgentPlaceholder: boolean = false;
  clientTypes;
  leadFormReady;
  leadSources;
  agents: AgentMin[];
  statuses: Status[];

  dealsDisplayedColumns: string[] = [
    'date',
    'product',
    'sum',
    'status',
    'pMethod',
  ];
  deliveriesDisplayedColumns: string[] = [
    // 'patientId',
    // 'patientName',
    'deliveryType',
    //'patientStatus',
    'createDate',
    'error',
    'status',
    'lastDeliveryStatus',
    'action',
  ];

  productId: number;
  product: Product;
  patient: Patient;
  isTaskEditMode: boolean = false;
  patientId: any;
  patientDeliveries: PatientDelivery[];
  agentUser: RegularUser;
  isRequestEditMode: boolean = false;
  requests: RequestForTableMin[];
  requestToEdit: ServiceRequest;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  noteId: boolean;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  constructor(
    private router: Router,
    private dealsService: DealsService,
    private route: ActivatedRoute,
    private agentsService: AgentsService,
    private productsService: ProductsService,
    private notesPusherService: NotesPusherService,
    private statusesService: StatusesService,
    private patientDeliveriesService: PatientDeliveriesService,
    private regularUsersService: RegularUsersService,
    private serviceRequestsPusherService: ServiceRequestsPusherService,
    private notesService: NotesService,
    private filterMemoryService: FilterMemoryService,
    private callsReviewsEditService: CallsReviewsEditService,
    private globalParamsService: GlobalParamsService,
    private popupHandlerService: PopupHandlerService
  ) {
    this.callsReviewsEditService.onEdit$.subscribe((data) => {
      if (data) {
        this.noteId = data;
        if (this.noteId && this.clientCalls.length > 0) {
          this.loadCallToEdit();
        }
        setTimeout(() => {
          this.tabGroup.selectedIndex = 4;
          this.isCallsEditMode = true;
        }, 100);
      }
    });
    this.productsService.loadProduct$.subscribe((data) => {
      if (data) {
        this.loadProduct();
      }
    });
    this.agentsService.getAll().subscribe((data) => (this.agents = data));
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
  }
  ngAfterViewInit(): void {
    if (this.noteId) {
      setTimeout(() => {
        this.tabGroup.selectedIndex = 4;
        this.isCallsEditMode = true;
      }, 100);
    }
  }

  ngOnInit(): void {
    this.globalParamsService
      .getAll()
      .subscribe((res) => (this.globalParams = res));
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.callsReviews
    );
    this.serviceRequestsPusherService.setMode('product');
    this.notesPusherService.setMode('patient');
    this.serviceRequestsPusherService.requests$.subscribe((requests) => {
      this.requests = requests;
    });

    this.route.queryParams.subscribe((params) => {
      this.productId = params['productId'];
      if (params['noteId']) {
        this.noteId = params['noteId'];
      }
      this.serviceRequestsPusherService.getAll(null, this.productId);
      this.statusesService.getAll().subscribe((data) => {
        //  this.statuses = data;
        this.statuses = data;
        this.statuses = this.statuses.filter((x) => {
          return x.category == 'Product';
        });
        this.requestsStatuses = data.filter((status: Status) => {
          return status.category == 'RequestStatus';
        });
        this.callsStatuses = [];
        if (
          userHasPermisions(['CallSubjectSupportList_GetList'], this.agentUser)
        ) {
          var theList = data.filter((status: Status) => {
            return status.category == 'CallSubjectSupport';
          });
          this.callsStatuses = this.callsStatuses.concat(theList);
        }
        if (
          userHasPermisions(['CallSubjectNutriList_GetList'], this.agentUser)
        ) {
          var theList = data.filter((status: Status) => {
            return status.category == 'CallSubjectNutri';
          });
          this.callsStatuses = this.callsStatuses.concat(theList);
        }
        /*
        this.callsStatuses = data.filter((status: Status) => {
          if (
            this.agentUser.roleTypeId == RoleTypeEmum.Support ||
            this.agentUser.roleTypeId == RoleTypeEmum.SupportManager
          ) {
            return status.category == 'CallSubjectSupport';
          } else if (
            this.agentUser.roleTypeId == RoleTypeEmum.nutritionist ||
            this.agentUser.roleTypeId == RoleTypeEmum.nutritionistManager
          ) {
            return status.category == 'CallSubjectNutri';
          } else if (
            this.agentUser.roleTypeId == RoleTypeEmum.Manager ||
            this.agentUser.roleTypeId == RoleTypeEmum.Developer
          ) {
            return (
              status.category == 'CallSubjectNutri' ||
              status.category == 'CallSubjectSupport'
            );
          }
        });
        */
      });

      this.productsService.getById(this.productId).subscribe((data) => {
        if ((data as Product).productId) {
          this.product = data as Product;
          this.patient = this.product.patient;
        } else {
          this.router.navigate(['home']);
        }

        if (!this.patient) {
          this.patient = new Patient();

          this.patient.regularUser = new RegularUser();
        } else {
          this.patientId = this.patient.patientId;
          //console.log('edit test user->call getAllNotes', this.patientId);
          this.notesPusherService.getAllNotes(this.patient.patientId);
          this.notesPusherService.notes$.subscribe((data) => {
            this.patientNotes = data.filter((item) => {
              return (
                item.noteTypeId == 1 ||
                item.noteTypeId == 4 ||
                item.noteTypeId == 6
              );
            });
            this.patientNotes = this.patientNotes.sort((a, b) => {
              /*
              if (!a.dateCreatedObj && !b.dateCreatedObj) return 0;
              if (!a.dateCreatedObj) return 1;
              if (!b.dateCreatedObj) return -1;
              */
              return a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime()
                ? 1
                : -1;
            });
            this.patientsTasks = data.filter((item) => {
              return item.noteTypeId == 2;
            });
          });
          this.patientsTasks = this.patientNotes.sort((a, b) =>
            a.dueDateObj.getTime() < b.dueDateObj.getTime() ? 1 : -1
          );
          this.patientDeliveriesService
            .getByPatientId(this.patientId)
            .subscribe((data) => {
              this.patientDeliveries = data;
            });
        }
        this.getAllCalls();
      });
    });
  }

  saveStatusForProduct(event, product: Product) {
    var val = event.value;
    product.statusId = Number(event.value);
    product.status = this.statuses.find((x) => {
      return x.statusId == event.value;
    });
    //lead.status = null;

    setTimeout(() => {
      this.productsService
        .changeStatus(product.productId, product.statusId)
        .subscribe((obj) => {
          if (obj.isProblems) {
            var errorsHtml =
              "status wasn't Changed:<br/>" +
              obj.list.map((x) => x.name + '<br>').join();
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.errorFromServer,
              '',
              '',
              errorsHtml,
              4000
            );
            product.statusId = obj.oldStatusId;
            product.status = this.statuses.find((x) => {
              return x.statusId == obj.oldStatusId;
            });
          } else {
            if (obj.actionsList.length > 0) {
              this.popupHandlerService.openPerformStatusChangeDialog(
                obj.actionsList,
                product.productId
              );
            } else {
              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.status
              );
            }
          }
        });
    });
  }
  userHasPermission(permissions: string[]) {
    return userHasPermisions(permissions, this.agentUser);
  }
  getNutritionist() {
    if (!this.product.agentId || !this.product.agent) return 'Not Assigned';
    return this.product.agent.regularUser.firstName;
  }
  openSendInfoDialog() {
    this.popupHandlerService.openEmailTemplateDialog(
      this.patient,
      this.product
    );
  }
  goBackToTests() {
    this.router.navigate(['/tests']);
  }
  saveClient() {}
  moveToEditMode() {
    this.isEditMode = true;
  }
  completePurchase(event) {
    var purchase: Deal = new Deal();
    purchase.statusId = 3;
    purchase.paymentMethodId = 1;
    purchase.dealBundle.agentId = this.client.agentId;
    purchase.dealBundle.clientId = this.client.clientId;
    purchase.amount = 3000;
    purchase.dealBundle.client = this.client;
    // purchase.createDate="12/12/20";
    this.dealsService.post(purchase).subscribe((deal) => {
      //console.log(deal);
      this.clientDeals.push(deal);
    });
    this.isPurchaseMode = false;
  }
  openNewPucrhase(event) {
    this.isPurchaseMode = true;
  }
  hidePlaceholderCustumerFunc() {
    this.hideCustumerPlaceholder = true;
  }
  hidePlaceholderSourceFunc() {
    this.hideSourcePlaceholder = true;
  }
  hidePlaceholderAgentFunc() {
    this.hideAgentPlaceholder = true;
  }
  get leadSourceId() {
    return this.clientForm.get('leadSourceId');
  }
  get clientTypeId() {
    return this.clientForm.get('clientTypeId');
  }
  get fullName() {
    return this.clientForm.get('fullName');
  }
  get email() {
    return this.clientForm.get('email');
  }
  get phone() {
    return this.clientForm.get('phone');
  }
  get productFor() {
    return this.clientForm.get('productFor');
  }
  get ProductType() {
    return this.clientForm.get('ProductType');
  }
  onChildFormValidated(client) {}
  clientProducts;
  gotoLogs() {
    this.router.navigate(['/tests/edit/log'], {
      queryParams: { productId: this.product.productId },
    });
  }

  onCancelForm(e) {
    this.isEditMode = false;
  }
  loadProduct() {
    this.productsService.getById(this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
        this.ConvertProductToTypes();
        this.patient = this.product.patient;
      } else {
        this.router.navigate(['home']);
      }
    });
  }
  ConvertProductToTypes() {
    this.product = plainToClass(Product, this.product);
    this.product.patient = plainToClass(Patient, this.product.patient);
    this.product.patient.regularUser = plainToClass(
      RegularUser,
      this.product.patient.regularUser
    );
    this.product.patient.regularUser.userAdresses =
      this.product.patient.regularUser.userAdresses.map((adress) =>
        plainToClass(UserAdress, adress)
      );
  }
  savePatientDetails(product: Product) {
    //product.patient.clientId = this.client.clientId;
    //product.statusId = 9; //Sent to customer
    //this.productsService.put(product).subscribe((data) => {

    this.isEditMode = false;
    this.loadProduct();

    // });
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
  }
  saveNote(note: Note | NoteForCallMin) {
    /* note.patientId = this.patient.patientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    if (note.noteId > 0) this.notesPusherService.put(note);
    else this.notesPusherService.post(note); */
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.patientId = this.patient.patientId;
      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.patientId = this.patient.patientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesPusherService.post(fullNote);
    }
    this.isNoteEditMode = false;
  }
  createNewNote(note: Note) {
    this.noteToEdit = note;
    this.isNoteEditMode = true;
  }
  closeNewTaskWindow() {
    this.isTaskEditMode = false;
  }
  saveTask(note: Note | NoteForCallMin) {
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.patientId = this.patientId;
      noteMin.assigningAgent.agentId = this.agentUser.agent.agentId;
      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.patientId = this.patientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesPusherService.post(fullNote);
    }
    this.isTaskEditMode = false;
  }

  /*
  saveTask(note: Note) {
    note.patientId = this.patientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    //note.dueDate = null;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      this.notesPusherService.post(note);
    }
    this.isTaskEditMode = false;
  }
  */
  createNewTask(note: Note) {
    if (!note) note = new Note();
    if (!note.assignedAgentId) note.assignedAgentId = this.product.agentId;
    this.activityToEdit = note;
    this.isTaskEditMode = true;
  }
  createNewRequest(request: ServiceRequest = null) {
    if (!request) request = new ServiceRequest();
    if (!request.assignedAgentId)
      request.assignedAgentId = +this.globalParams.find(
        (x) => x.key == 'defaultServiceRequestAgentId'
      ).value;
    //  request.assignedAgentId = this.client.agent.agentId;
    this.requestToEdit = request;
    this.isRequestEditMode = true;
  }
  saveRequest(request: ServiceRequest) {
    request.productId = this.productId;
    request.assigningAgentId = this.agentUser.agent.agentId;
    if (request.serviceRequestId > 0) {
      this.serviceRequestsPusherService.put(request);
    } else {
      this.serviceRequestsPusherService.post(request);
    }
    // this.serviceRequestsPusherService.getAll(null, this.productId);
    this.isRequestEditMode = false;
  }
  closeNewRequestWindow() {
    this.isRequestEditMode = false;
  }
  ifFollowUpPermission() {
    return userHasPermisions(['Calls Follow Up'], this.agentUser);
  }
  ifHasCallPermission() {
    return userHasPermisions(
      ['Calls Patient', 'Calls Follow Up'],
      this.agentUser
    );
    /*
    if (
      this.agentUser.roleTypeId == RoleTypeEmum.Support ||
      this.agentUser.roleTypeId == RoleTypeEmum.SupportManager
    )
      return true;
    else if (
      this.agentUser.roleTypeId == RoleTypeEmum.nutritionist ||
      this.agentUser.roleTypeId == RoleTypeEmum.nutritionistManager
    )
      return true;
    else if (
      this.agentUser.roleTypeId == RoleTypeEmum.Manager ||
      this.agentUser.roleTypeId == RoleTypeEmum.Developer
    )
      return true;
    else return false;
    */
  }
  createNewCall(note: Note) {
    this.callToEdit = note;
    this.isCallsEditMode = true;
  }
  closeNewCallWindow() {
    this.isCallsEditMode = false;
  }
  callNotAnswerCall() {
    this.isCallsEditMode = false;
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.openNewTaskForFollowUpNotAnswerCall();
        }
      });
    this.popupHandlerService.openConfirmationDialog(
      null,
      'A new call Task Will Be Open For Another 3 hours'
    );
  }
  openNewTaskForFollowUpNotAnswerCall() {
    this.notesPusherService.openNewTaskForFollowUpNotAnswerCall(this.patientId);
  }
  saveCall(note: Note | NoteForCallMin) {
    /* note.patientId = this.patientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    note.assignedAgentId = this.agentUser.agent.agentId;
    note.noteTypeId = 4;
    //note.dueDate = null;
    if (note.noteId > 0) {
      this.notesPusherService.put(note);
    } else {
      this.notesPusherService.post(note);
    } */
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      this.notesPusherService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.patientId = this.patientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      fullNote.assignedAgentId = this.agentUser.agent.agentId;
      fullNote.noteTypeId = 4;
      this.notesPusherService.post(fullNote);
      if (this.ifFollowUpPermission()) {
        this.popupHandlerService.openFollowUpNextTaskSchedualler(
          this.patientId
        );
      }
    }
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.callsReviews
    );
    setTimeout(() => this.getAllCalls(), 2000);
    this.isCallsEditMode = false;
  }
  activateCallEditMode(val) {
    this.isCallsEditMode = val;
    if (val) {
      this.createNewCall(new Note());
    } else {
      this.getAllCalls();
    }
  }
  getAllCalls() {
    this.notesService
      .getAllCalls(this.headFilter, -1, this.patientId)
      .subscribe((data) => {
        this.clientCalls = data.list;
        this.clientCallsMetaData = data.metaData;
        if (this.noteId && this.clientCalls.length > 0) {
          this.loadCallToEdit();
          //console.log('edit callToEdit', this.callToEdit);
        }
      });
  }
  loadCallToEdit() {
    this.notesService.getById(this.noteId).subscribe((res) => {
      this.callToEdit = res;
    });
  }
  onReloadNotes() {
    this.notesPusherService.getAllNotes(this.patient.patientId);
  }
}
