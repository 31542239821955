<div
  [dir]="direction"
  class="autocomplete-agent"
  style="display: inline"
  [matTooltip]="initialValue"
  matTooltipPosition="above"
>
  <mat-form-field
    appearance="fill"
    [matMenuTriggerFor]="valueComplete"
    [ngStyle]="{ width: widthPrec ? widthPrec + '%' : widthExp + 'px' }"
    class="main-field"
    (menuOpened)="focusInput()"
    [class.disabled]="disabled"
  >
    <mat-label *ngIf="!headFilter">{{ label }}</mat-label>
    <input
      style="cursor: pointer"
      class="input-compelete"
      type="text"
      matInput
      #value
      [class.disabled]="disabled"
      disabled
      [(ngModel)]="initialValue"
      [class.not-active]="ifAgentActive(initialValue)"
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="open"
      [disabled]="disabled"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu
    #valueComplete="matMenu"
    xPosition="before"
    yPosition="below"
    class="menu-complete"
    [ngStyle]="{ 'text-align': textAlign }"
    [dir]="direction"
    [ngStyle]="{ 'min-width.px': widthExp }"
    (closed)="onClose()"
  >
    <ng-container *ngIf="data; else noData">
      <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <mat-label>Type something...</mat-label>
          <input
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            [ngStyle]="{ 'text-align': textAlign }"
            [dir]="direction"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div>

      <button
        *ngFor="let value of filteredValuesOptions || data"
        class="menu-complete__item"
        style="width: 100%"
        mat-button
        (click)="onValueSelected(value.agentId)"
        [ngStyle]="{ 'text-align': textAlign }"
        [dir]="direction"
        [disabled]="!headFilter && !value.isActive"
        [class.not-active]="!value.isActive"
      >
        {{ value.name }}
      </button>
    </ng-container>
    <ng-template #noData>
      <div style="text-align: center">
        <container-element [ngSwitch]="label">
          <some-element *ngSwitchCase="'Street'"
            >Please Choose City</some-element
          >
          <some-element *ngSwitchDefault>No Data</some-element>
        </container-element>
      </div>
    </ng-template>
  </mat-menu>
</div>
