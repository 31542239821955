import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationCategory } from 'src/app/data/TranslationCategory';

@Component({
  selector: 'app-add-keyword',
  templateUrl: './add-keyword.component.html',
  styleUrls: ['./add-keyword.component.scss'],
})
export class AddKeywordComponent implements OnInit {
  categoryId: number;
  keyword: string;
  constructor(
    public dialogRef: MatDialogRef<AddKeywordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TranslationCategory[]
  ) {}
  ngOnInit(): void {}
  addKeyword() {
    this.dialogRef.close({
      type: 'add',
      categoryId: this.categoryId,
      keyword: this.keyword,
    });
  }
}
