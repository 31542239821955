import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodScaleLangsService {
  foodScales: NameAndId[];
  constructor(private http: HttpClient) {}

  getAll(langId: number) {
    if (this.foodScales) {
      return of(this.foodScales); // return from cache
    } else {
      return this.http
        .get<NameAndId[]>(`${environment.baseUrl}FoodScaleLangs/${langId}`)
        .pipe(map((res) => (this.foodScales = res)));
    }
  }
}
