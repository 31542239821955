<!--<button (click)="uploadResultsFile()">Upload Results File</button>-->
<div class="lab">
  <ng-container *ngIf="tubePackage">
    <div class="wrapper-bg">
      <div class="wrapper">
        <p class="title">Detail of test tubes in a shipping certificate</p>
        <div class="d-flex-center">
          <div>
            <mat-form-field
              appearance="fill"
              class="example-full-width classThisOne"
            >
              <input
                placeholder="Search Barcode"
                class="edit-field"
                type="text"
                aria-label="Number"
                matInput
                [(ngModel)]="newBarcode"
                [matAutocomplete]="auto"
                (keyup)="searchTube($event)"
              />
              <!-- placeholder="barcode" -->
              <mat-autocomplete #auto="matAutocomplete">
                <!-- (keyup)="onCitiesAutocompleteChanged($event)"-->
                <!--  (optionSelected)="onCitySelected($event)"-->
                <mat-option
                  style="text-align: right"
                  *ngFor="let barcode of barcodes"
                  [value]="barcode"
                >
                  {{ barcode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- <input [(ngModel)]="newBarcode" />-->
            <img
              style="
                position: relative;
                top: 12px;
                right: 40px;
                cursor: pointer;
                padding: 1rem;
                transform: rotate(90deg);
              "
              src="../../assets/zoom-2.png"
              (click)="searchTube()"
            />
          </div>
          <button
            (click)="exportToExel()"
            style="
              margin-left: auto;
              background-color: transparent;
              color: #119c99;
              border: 2px solid #119c99;
            "
            class="btn-round btn-round--error"
          >
            Export to Exel
          </button>
          <button (click)="showReport()" class="btn-round btn-round--error">
            Errors Report
          </button>
          <!--
          <app-upload-results-csv [title]="UploadData"
            [packageId]="packageId"
          ></app-upload-results-csv>
          -->
          <button
            style="margin-left: 20px"
            class="btn-round btn-round--shipping"
            (click)="backToAll()"
          >
            Total Shipping Certificate
          </button>
        </div>
      </div>
    </div>
    <div class="clearFix"></div>
    <div
      *ngIf="packageTubes && packageTubes.length > 0"
      style="margin-bottom: 70px"
    >
      <!--
      <app-head-filter
        [headFilter]="headFilter"
        [leadStatuses]="statuses"
        (filterChanged)="getAllWithFilter($event, true)"
        [classAttr]="'editTubePackage'"
      >
      </app-head-filter>
-->
      <app-package-tubes-table
        [type]="'lab'"
        [packageTubes]="filteredPackageTubes"
      >
      </app-package-tubes-table>
    </div>
  </ng-container>
  <!--
  <app-table-paginator
    *ngIf="packageTubes && metaData"
    [metaData]="metaData"
    (filterChanged)="getAllWithFilter($event, false, true)"
    [classAttr]="'leadsAttr'"
  >
  </app-table-paginator>
  -->
</div>
