import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ZameretData } from '../data/ZameretData';
import { ZameretNutrientList } from '../data/ZameretNutrientList';

@Injectable({
  providedIn: 'root',
})
export class ZameretNutrientListsService {
  constructor(private http: HttpClient) {}

  getZameretData() {
    return this.http.get<ZameretData>(
      `${environment.baseUrl}ZameretNutrientLists`
    );
  }

  uploadFile(fileTypeId: number) {
    return `ZameretNutrientLists/UploadFile/${fileTypeId}`;
  }

  put(zameretNutrientList: ZameretNutrientList[]) {
    return this.http.put<any>(
      `${environment.baseUrl}ZameretNutrientLists`,
      zameretNutrientList
    );
  }
}
