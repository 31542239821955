import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryLangMin } from '../data/CategoryLangMin';
import { SnpLangMin } from '../data/SnpLangMin';
import { SubCategoryLang } from '../data/SubCategoryLang';
import { SubCatResultMin } from '../data/SubCatResultMin';

@Injectable({
  providedIn: 'root',
})
export class CategoriesForEditService {
  constructor(private http: HttpClient) {}
  getAll() {
    /*
    var token=localStorage.getItem("access_token")
    var headers_object = new HttpHeaders();
        headers_object.append('Content-Type', 'application/json');
        headers_object.append("Authorization", "Bearer " + token);

        const httpOptions = {
          headers: headers_object
        };
        */
    return this.http.get(`${environment.baseUrl}CategoriesForEdit/`);
  }
  SaveSnpLangMin(snpLangMin: SnpLangMin) {
    return this.http.put(
      `${environment.baseUrl}CategoriesForEdit/SaveSnpLangMin/${snpLangMin.snpNumber}`,
      snpLangMin
    );
  }
  addSubCategory(categoryId: any, selectedVersionId: number) {
    return this.http.get(
      `${environment.baseUrl}CategoriesForEdit/CreateNewSubCat/${categoryId}/${selectedVersionId}`
    );
  }
  addCategory(versionId: number) {
    return this.http.get(
      `${environment.baseUrl}CategoriesForEdit/createCategory/${versionId}`
    );
  }
  getSubCatData(subCatId: number) {
    return this.http.get(
      `${environment.baseUrl}CategoriesForEdit/SubCategoryData/${subCatId}`
    );
  }

  getSnpForLangOld(snpNumber: number, langId: number) {
    return this.http.get<SnpLangMin>(
      `${environment.baseUrl}CategoriesForEdit/SnpForLang/${snpNumber}/${langId}`
    );
  }
  getSnpForLangV2(snpNumber: number, langId: number, versionId: number) {
    return this.http.get<SnpLangMin>(
      `${environment.baseUrl}CategoriesForEdit/SnpForLangV2/${snpNumber}/${langId}/${versionId}`
    );
  }
  getSnpForLang(
    snpNumber: number,
    langId: number,
    versionId: number,
    subCategotyId: number
  ) {
    return this.http.get<SnpLangMin>(
      `${environment.baseUrl}CategoriesForEdit/SnpForLangV3/${snpNumber}/${langId}/${versionId}/${subCategotyId}`
    );
  }
  getSubCatForLangOld(
    subCategoryId: number,
    langId: number,
    versionId: number
  ) {
    return this.http.get<SubCatResultMin>(
      `${environment.baseUrl}CategoriesForEdit/SubCatForLang/${subCategoryId}/${langId}`
    );
  }
  getSubCatForLang(subCategoryId: number, langId: number, versionId) {
    return this.http.get<SubCatResultMin>(
      `${environment.baseUrl}CategoriesForEdit/SubCatForLangV2/${subCategoryId}/${langId}/${versionId}`
    );
  }
  getMenuForLangOld(langId: number) {
    return this.http.get<CategoryLangMin[]>(
      `${environment.baseUrl}CategoriesForEdit/MenuForLang/${langId}`
    );
  }
  getMenuForLang(langId: number, versionId: number) {
    return this.http.get<CategoryLangMin[]>(
      `${environment.baseUrl}CategoriesForEdit/MenuForLangV2/${langId}/${versionId}`
    );
  }
  searchSnpNumber(snpNumber: number) {
    return this.http.get<any>(
      `${environment.baseUrl}CategoriesForEdit/SearchSnpNumber/${snpNumber}`
    );
  }

  addSubCatToVersion(subCategoryId: number, versionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}CategoriesForEdit/AddSubCatToVersion/${subCategoryId}/${versionId}`
    );
  }
  changeSubCatName(subCategoryLang: SubCategoryLang) {
    return this.http.put<any>(
      `${environment.baseUrl}CategoriesForEdit/ChangeSubCatName/`,
      subCategoryLang
    );
  }

  deleteSubCat(id: number, versionId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}CategoriesForEdit/${id}/${versionId}`
    );
  }
}
