<p>
  <button mat-raised-button (click)="initChat()">Reset</button>
</p>
<mat-card>
  <div *ngFor="let guidedQuestion of chatHistory">
    <p class="buuble" dir="rtl">
      <span>{{ guidedQuestion.answerTxt }}</span>
    </p>
    <p class="buuble" dir="ltr">
      <span>{{ guidedQuestion.nextQuestionTxt }}</span>
    </p>
  </div>
  <div class="guided-question" *ngIf="chatHistory && chatHistory.length > 0">
    <ng-container
      *ngFor="let answer of chatHistory[chatHistory.length - 1].answers"
    >
      <button
        mat-raised-button
        (click)="selectNextAnswer(answer.guidedQuestionId)"
        class="guidedAnswerBtn"
      >
        {{ answer.answerTxt }}
      </button>
      <br />
    </ng-container>
  </div>
</mat-card>
