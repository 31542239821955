import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { PrototypeSnpByVer } from 'src/app/data/algorithm/PrototypeSnpByVer';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientSnp } from 'src/app/data/food-genes/FgCoefficientSnp';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';
import { FgSnp } from 'src/app/data/food-genes/FgSnp';
import { PrototypeParam } from 'src/app/data/food-genes/PrototypeParam';
import {
  CoefficientTypeIdEnum,
  FgParamTypeEnum,
  FoodGenesBoardEnum,
  FoodGenesSidenavEnum,
} from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-food-genes-sidenav-secondary',
  templateUrl: './food-genes-sidenav-secondary.component.html',
  styleUrls: ['./food-genes-sidenav-secondary.component.scss'],
})
export class FoodGenesSidenavSecondaryComponent implements OnInit {
  @Input() canEdit: boolean;
  @Input() langId: number;
  @Input() mode: FoodGenesSidenavEnum;
  @Input() sidenavSnp: MatSidenav;
  @Input() selectedCoeff: FgCoefficient;
  @Input() selectedCoeffType: FgCoefficientType;
  @Input() selectedSnp: FgCoefficientSnp;
  @Input() coeffSnps: FgCoefficientSnp[];
  @Input() params: FgParam[];
  @Input() selectedParam: FgParam;
  @Input() selectedParamType: FgParamType;
  @Input() availableParams: PrototypeParam[];
  @Input() versionId: number;
  @Input() coefficients: FgCoefficient[];
  CoefficientTypeIdEnum = CoefficientTypeIdEnum;
  @Output('selectSnp') selectSnp: EventEmitter<FgCoefficientSnp> =
    new EventEmitter();
  @Output('selectParam') selectParam: EventEmitter<FgParam> =
    new EventEmitter();
  @Output('selectFormula') selectFormula: EventEmitter<boolean> =
    new EventEmitter();
  @Output('loadSnps') loadSnps: EventEmitter<boolean> = new EventEmitter();
  @Output('loadParams') loadParams: EventEmitter<boolean> = new EventEmitter();
  // @Output('closeMainBoard') closeMainBoard: EventEmitter<boolean> =
  //   new EventEmitter();
  isFormulaSelected: boolean;
  availableCoeffSnps: PrototypeSnpByVer[];
  FoodGenesSidenavEnum = FoodGenesSidenavEnum;
  userAutomaticTypes = [
    FgParamTypeEnum.UserDnaResult,
    FgParamTypeEnum.MaxCoefficient,
    FgParamTypeEnum.CoefficientMin,
    FgParamTypeEnum.CoefficientMax,
    FgParamTypeEnum.MinCoefficient,
    FgParamTypeEnum.FormulaResult,
  ];
  constructor(
    private algorithmService: AlgorithmService,
    private foodGenesService: FoodGenesService,
    private popupHandlerService: PopupHandlerService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.selectedSubCoeff?.currentValue &&
      changes?.selectedSubCoeff?.previousValue
    ) {
      this.setMode('ngOnChanges');
    } else if (
      changes?.params?.currentValue &&
      !changes.params.isFirstChange()
    ) {
      this.params = changes.params.currentValue;
      this.setMode('ngOnChanges');
    }
    if (changes?.mode?.currentValue && !changes.mode?.isFirstChange()) {
      this.mode = changes.mode.currentValue;
    }
  }
  ngOnInit(): void {
    this.sidenavSnp.openedChange.subscribe((opened) => {
      if (!opened) {
        this.selectedSnp = null;
        this.isFormulaSelected = false;
        this.selectSnp.emit(null);
        this.foodGenesService.mainBoardState$.next(false);
      } else {
        //this.setMode('ngOnInit');
      }
    });
  }

  showDeleteParamBtn() {
    return !this.userAutomaticTypes.includes(
      this.selectedParamType.fgParamTypeId
    );
  }
  setMode(from: string) {
    console.log('setMode', from, this.mode, this.selectedSnp, this.params);

    switch (this.mode) {
      case FoodGenesSidenavEnum.snp:
        if (!this.selectedSnp) {
          this.isFormulaSelected = true;
          this.selectFormula.emit(true);
        }
        break;
      case FoodGenesSidenavEnum.snp:
        if (this.params) {
          this.selectedParam = this.params[0];
        }
        break;
      case FoodGenesSidenavEnum.param:
        if (this.params) {
          this.selectedParam = this.params[0];
          this.selectParam.emit(this.selectedParam);
        }
        break;
    }
  }
  stopPropagation(e: any) {
    e.stopPropagation();
  }
  removeParameter(param: FgParam, e: any) {
    e.stopPropagation();
    this.popupHandlerService.openConfirmationDialog('', 'Delete Parameter');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes') {
          this.foodGenesService
            .removeParameter(param.fgParamId)
            .subscribe((res) => {
              if ((res as ErrorMessage)?.message) {
                this._snackBar.open((res as ErrorMessage).message, 'Close');
              } else {
                this.loadParams.emit(true);
                this._snackBar.open('Parameter Deleted')._dismissAfter(2000);
                if (this.selectedParam?.fgParamId === param.fgParamId) {
                  this.selectedParam = null;
                  this.selectParam.emit(null);
                }
              }
            });
        }
      });
  }
  removeSnp(snp: FgCoefficientSnp, e: any) {
    this.stopPropagation(e);
    this.popupHandlerService.openConfirmationDialog('', 'Delete Snp');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes') {
          this.foodGenesService
            .removeCoefficientSnp(snp.fgCoeffSnpId)
            .subscribe((res) => {
              if ((res as ErrorMessage)?.message) {
                this._snackBar.open((res as ErrorMessage).message, '', {
                  duration: 4000,
                });
              } else {
                this.loadSnps.emit(true);
                if (this.selectedSnp?.fgCoeffSnpId === snp.fgCoeffSnpId) {
                  this.selectedSnp = null;
                  this.selectSnp.emit(null);
                }
              }
            });
        }
      });
  }
  addParameter() {
    this.popupHandlerService.openAddNewFoodGenesParameter(
      this.selectedParamType,
      this.availableParams,
      this.coefficients,
      this.langId,
      this.versionId
    );
    this.popupHandlerService.addNewFoodGenesParameter
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.type === 'create') {
          this.foodGenesService
            .addParamToVersion(data.parameter, this.versionId)
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._snackBar.open((data as ErrorMessage).message, '', {
                  duration: 4000,
                });
              } else {
                this.loadParams.emit(true);
                this._snackBar.open('Parameter added successfully', 'Close', {
                  duration: 2000,
                  panelClass: 'added-success',
                });
              }
            });
        }
      });
  }
  getAvailableCoeffSnps() {
    if (!this.selectedCoeffType) return;
    this.foodGenesService
      .getAvailableSnpForSubCat(
        this.versionId,
        this.selectedCoeff.fgCoefficientId
      )
      .subscribe((data) => {
        this.availableCoeffSnps = data;
        this.addSnp();
      });
  }
  addSnp() {
    this.popupHandlerService.openAddNewCatSnp(this.availableCoeffSnps);

    this.popupHandlerService.addNewCatSnpSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.type === 'add') {
          const fgSnp = new FgSnp();
          fgSnp.fgSnpId = 0;
          fgSnp.prototypeSnpNumber = data.fgSnp.snpNumber;
          fgSnp.firstLetter = data.firstLetter;
          fgSnp.secondLetter = data.secondLetter;

          this.foodGenesService
            .createCoefficientSnp(
              fgSnp,
              this.selectedCoeff.fgCoefficientId,
              this.versionId
            )
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._snackBar.open((data as ErrorMessage).message, 'Close');
              } else {
                this.loadSnps.emit(true);
                this._snackBar.open('Snp added successfully', 'Close', {
                  duration: 2000,
                  panelClass: 'added-success',
                });
              }
            });
        }
      });
  }
  onSelectSnp(snp: FgCoefficientSnp) {
    if (this.selectedSnp?.fgCoeffSnpId === snp.fgCoeffSnpId) {
      this.selectedSnp = null;
      this.foodGenesService.mainBoardState$.next(true);
      //this.closeMainBoard.emit();
    } else {
      this.selectedSnp = snp;
      this.isFormulaSelected = false;
      this.selectSnp.emit(this.selectedSnp);
    }
  }
  onSelectParam(param: FgParam) {
    if (this.selectedParam?.fgParamId === param.fgParamId) {
      this.selectedParam = null;
      this.foodGenesService.mainBoardState$.next(false);
      //this.closeMainBoard.emit(true);
    } else {
      this.selectedParam = param;
      this.isFormulaSelected = false;
      this.selectParam.emit(this.selectedParam);
    }
  }
  onSelectFormula() {
    this.selectedSnp = null;
    this.isFormulaSelected = true;
    this.selectSnp.emit(null);
    this.selectFormula.emit(true);
  }
}
