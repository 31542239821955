

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreditCurrenciesService {
  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get(`${environment.baseUrl}CreditCurrencies/`);
  }
}

