import { Component, OnInit, Input } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.css'],
})
export class ConfettiComponent implements OnInit {
  @Input() newDeal;
  options: AnimationOptions = {
    path: '/assets/confetti.json',
  };

  ngOnInit(): void {}

  animationCreated(animationItem: AnimationItem): void {
    //console.log(animationItem);
  }
}
