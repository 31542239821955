<div class="h-full pt-100">
  <mat-card class="w-500 ma-0">
    <form>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        "
      >
        <div style="text-align: center">
          <mat-card-title>you are logging from home.</mat-card-title>
          <br />
          sms with the access code for 4 hours was sent to yaniv
          <br />
          enter the code here to activate it:
          <br />
          <br />
          <div class="d-flex">
            <mat-form-field appearance="fill">
              <input
                matInput
                [(ngModel)]="token"
                placeholder="Enter token"
                name="token"
                id="token"
              />
            </mat-form-field>
            <button mat-button matSuffix type="button" (click)="submit($event)">
              submit
            </button>
          </div>
          <br />
          <br />
          <p *ngIf="submited && !token" style="color: red; font-weight: bold">
            u need to enter the code
          </p>
          <p *ngIf="errorMsg != ''" style="color: red; font-weight: bold">
            {{ errorMsg }}
          </p>
        </div>
      </div>
    </form>
  </mat-card>
</div>
