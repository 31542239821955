import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { DashboardCallStat } from 'src/app/data/DashboardCallStat';
import { DashboardDailyRaiting } from 'src/app/data/DashboardDailyRaiting';
import { DashboardMonthlyRaiting } from 'src/app/data/DashboardMonthlyRaiting';
import { DashboardRatingLeader } from 'src/app/data/DashboardRatingLeader';
import { DashboardUserStatusCount } from 'src/app/data/DashboardUserStatusCount';
import { SupportDashboard } from 'src/app/data/SupportDashboard';
import { FirebaseCallsService } from 'src/app/Services/firebase-calls.service';

@Component({
  selector: 'app-support-dashboard',
  templateUrl: './support-dashboard.component.html',
  styleUrls: ['./support-dashboard.component.scss'],
})
export class SupportDashboardComponent implements OnInit, OnDestroy {
  supportDashboard: SupportDashboard;
  dashboardCallStatistic: DashboardCallStat;
  dashboardDailyRaiting: DashboardDailyRaiting;
  dashboardMonthlyRaiting: DashboardMonthlyRaiting;
  dashboardRatingLeaders: DashboardRatingLeader[];
  dashboardUserStatusCounts: DashboardUserStatusCount[];

  canvasWidth = 250;
  needleValue = 60;
  centralLabel: string;
  bottomLabel: string;
  options = {
    hasNeedle: true,
    needleColor: '#153341',
    needleStartValue: 0,
    arcColors: ['#119c99', '#f9b904', '#e80505'],
    arcDelimiters: [50, 80],
    rangeLabel: ['0', '120'],
  };
  subscribe: Subscription;

  constructor(private firebaseCallsService: FirebaseCallsService) {}
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribe = this.getDashboardStatistics();
  }
  getDashboardStatistics() {
    return this.firebaseCallsService
      .getDashboardStatistics()
      .subscribe((res) => {
        this.supportDashboard = res['SupportDashboard'];
        this.dashboardUserStatusCounts =
          this.supportDashboard.DashboardUserStatusCounts;
        this.dashboardRatingLeaders =
          this.supportDashboard.DashboardRatingLeaders;
        this.dashboardMonthlyRaiting =
          this.supportDashboard.DashboardMonthlyRaiting;
        this.dashboardDailyRaiting =
          this.supportDashboard.DashboardDailyRaiting;
        this.dashboardCallStatistic =
          this.supportDashboard.DashboardCallStatistic;
        this.needleValue = this.calcNeedleValue();
        this.bottomLabel = this.averageResponse().toFixed(1);
      });
  }
  sortLeaders(agents: DashboardRatingLeader[]): DashboardRatingLeader[] {
    return agents.sort((a, b) => {
      return (
        b.AgentRaitingSum / b.AgentRaitingCount -
        a.AgentRaitingSum / a.AgentRaitingCount
      );
    });
  }
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  calcNeedleValue() {
    var value = this.averageResponse();
    if (value > 100) {
      value = value - 20;
    } else if (value >= 60) {
      value = value - 10;
    }
    return value;
  }
  monthlyAverage() {
    if (this.dashboardMonthlyRaiting.MonthlyCount == 0) return 0;
    return (
      this.dashboardMonthlyRaiting.MonthlySum /
      this.dashboardMonthlyRaiting.MonthlyCount
    );
  }
  dailyAverage() {
    if (this.dashboardDailyRaiting.DailyCount == 0) return 0;
    return (
      this.dashboardDailyRaiting.DailySum /
      this.dashboardDailyRaiting.DailyCount
    );
  }
  leaderAverage(item: DashboardRatingLeader) {
    if (item.AgentRaitingCount == 0) return 0;
    return item.AgentRaitingSum / item.AgentRaitingCount;
  }
  leaderBar(item: DashboardRatingLeader) {
    return (this.leaderAverage(item) / 5) * 100;
  }
  precenageResponse() {
    const missing = this.dashboardCallStatistic.MissingCallCount;
    const count = this.dashboardCallStatistic.AnsweredCallCount + missing;
    if (count == 0) return 100;
    return 100 - (missing / count) * 100;
  }
  averageResponse() {
    const count = this.dashboardCallStatistic.AnsweredCallCount;
    const sum = this.dashboardCallStatistic.ResponseTimeSum;
    if (count == 0) return 0;
    return sum / count;
  }
}
