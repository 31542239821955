import { AppImage } from '../algorithm/AppImage';

export class FoodCat {
  categoryId: number;
  parentId: number;
  grantParent: number;
  catLevel: number;
  maxCatLevel: number;
  langId: number;
  name: string;
  description: string;
  icon: AppImage;
  subCategories: FoodCat[];
  havingFood: boolean;
  categoryTypeId: number;
}
