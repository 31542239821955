import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { Client } from 'src/app/data/Client';
import { BulkChangeLeadAgent } from 'src/app/data/connectors/BulkChangeLeadAgent';
import { BulkChangeProductAgent } from 'src/app/data/connectors/BulkChangeProductAgent';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { SourceKeys, UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { LeadPusherService } from 'src/app/Services/lead-pusher.service';
import { LeadsConnectorService } from 'src/app/Services/leads-connector.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsPusherServiceService } from 'src/app/Services/products-pusher-service.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { TestsConnectorService } from 'src/app/Services/tests-connector.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent implements OnInit {
  regularUser: RegularUser;
  filterdStatuses;

  @Input() sticky: boolean;
  @Input() minWidth: boolean;
  @Input() isRecipe: boolean;
  @Input() langId: number;
  @Input() classAttr: string;
  paginationForm: FormGroup;
  @Input() metaData: PaginationMetadata;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  @Input() headFilter: HeadFilter;
  @Input() checkedIds: any;
  @Input() checkedAll: boolean = false;
  @Input() agents: AgentMin[];
  @Input() followUpAgents: AgentMin[];
  nutrisionists: AgentMin[];
  @Input() leadStatuses: LeadStatus[];
  @Input() statuses: Status[];
  @Input() rating;
  @Input() reviews;
  @Input() isMainScreen;
  @Input() foodCategories: FoodCat[];
  @Output('checkedIdsChanged')
  checkedIdsChanged: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    private leadsService: LeadPusherService,
    private popupHandlerService: PopupHandlerService,
    private router: Router,
    private testsConnentorService: TestsConnectorService,
    private regularUsersService: RegularUsersService,
    private foodGenesService: FoodGenesService
  ) {
    this.checkedIds = {};
    this.leadsService.leads$.subscribe((leads) => {});
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.nutrisionists && this.agents) {
      this.nutrisionists = this.agents.filter(
        (x) => x.roleTypeId == UserRoleEmum.nutritionist
      );
    }
    if (!this.paginationForm) {
      this.createFormGroup();
    }
    this.paginationForm.patchValue({
      pageNum: this.metaData.currentPage,
      itemsPerPage: this.metaData.pageSize,
    });
  }

  ngOnInit(): void {
    if (!this.paginationForm) {
      this.createFormGroup();
    }
    if (this.leadStatuses) {
      this.filterdStatuses = this.leadStatuses.filter((x) => {
        return x.parentId !== 0;
      });
    }
  }

  userHasPermision(permission) {
    return userHasPermisions(permission, this.regularUser);
  }

  addNewFood() {
    this.router.navigate(['foods/edit'], {
      queryParams: {
        foodId: -1,
        langId: this.langId,
        isRecipe: this.isRecipe,
        sourceKey: SourceKeys.mygenes,
      },
    });
  }

  getRoundedRating(rating) {
    var roundedString = rating.toFixed(1);
    return Number(roundedString);
  }
  createFormGroup() {
    this.paginationForm = new FormGroup({
      pageNum: new FormControl(),
      itemsPerPage: new FormControl(),
    });
  }
  gotoFirstPage() {
    this.headFilter.pageNumber = 1;
    this.filterChanged.emit(this.headFilter);
  }
  gotoPrevPage() {
    if (this.headFilter.pageNumber > 1) {
      this.headFilter.pageNumber--;
      this.filterChanged.emit(this.headFilter);
    }
  }
  gotoNextPage() {
    if (this.metaData.nextPage) {
      this.headFilter.pageNumber++;
      this.filterChanged.emit(this.headFilter);
    }
  }
  gotoLastPage() {
    this.headFilter.pageNumber = this.metaData.totalPages;
    this.filterChanged.emit(this.headFilter);
  }
  onItemsPerPageChange(event) {
    var itemsPerPage = +event.target.value;
    this.headFilter.pageSize = itemsPerPage;
    this.headFilter.pageNumber = 1;
    this.filterChanged.emit(this.headFilter);
    this.checkedIds = {};
    this.checkedIdsChanged.next(this.checkedIds);
    this.checkedAll = false;
  }
  onPageSelect(event) {
    var pageNum = +event.target.value;
    if (pageNum >= 1 && pageNum <= this.metaData.totalPages) {
      this.headFilter.pageNumber = pageNum;
      this.filterChanged.emit(this.headFilter);
    } else {
      this.filterChanged.emit(this.headFilter);
    }
  }
  getDisplayRange() {
    const currentPage =
      this.metaData.currentPage == 0 ? 1 : this.metaData.currentPage;
    var startInx = this.metaData.pageSize * (currentPage - 1) + 1;
    var itemsCnt = this.metaData.currentCount;
    var numItems = Math.min(this.metaData.pageSize, itemsCnt);
    var endInx = startInx + numItems - 1;
    //endInx=Math.max(endInx,itemsCnt)
    return startInx + ' - ' + endInx;
  }
  checkObjLength(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }
  displaySideEdit() {
    if (
      this.checkObjLength(this.checkedIds) >= 2 &&
      this.classAttr !== 'foods'
    ) {
      return true;
    }
    return false;
  }
  changeBulkAgent(event) {
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });

    let bulkChangeAgent = new BulkChangeLeadAgent();
    bulkChangeAgent.agentId = agentId;
    bulkChangeAgent.clientIds = checkedIdsArr;
    //this.leadsConnentorService.bulkChangeLeadAgent(bulkChangeAgent);
    this.checkedIds = {};
    this.checkedIdsChanged.next(this.checkedIds);

    this.popupHandlerService.openConfirmationDialog('', 'Transfer Missions?');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((_data) => {
        if (_data.ans === 'yes') {
          this.bulkChangeAgent(bulkChangeAgent, true);
        } else if (_data.ans === 'no') {
          this.bulkChangeAgent(bulkChangeAgent, false);
        }
      });

    /*  this.leadsService.bulkChangeAgent(checkedIdsArr, agentId);
    this.checkedIds = {};
    this.openConfirmChangDialog(null, 'agent');

    this.checkedIdsChanged.next(this.checkedIds);
    this.checkedAll = false; */
  }
  bulkChangeAgent(data: BulkChangeLeadAgent, transferMissions: boolean) {
    this.leadsService
      .bulkChangeAgent2(data.clientIds, data.agentId, transferMissions)
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog('agent');
        this.filterChanged.emit(this.headFilter);
      });
  }

  changeBulkNutri(event, isAgent2 = false) {
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });

    let bulkChangeAgent = new BulkChangeProductAgent();
    bulkChangeAgent.agentId = agentId;
    bulkChangeAgent.isAgent2 = isAgent2;
    bulkChangeAgent.productIds = checkedIdsArr;
    this.testsConnentorService.bulkChangeProductAgent(bulkChangeAgent);
    this.checkedIds = {};
    this.checkedIdsChanged.next(this.checkedIds);
    /* this.checkedIds = {};
    this.checkedIdsChanged.next(this.checkedIds);
    this.checkedAll = false; */
    //this.productsPusherServiceService.bulkChangeAgent(checkedIdsArr, agentId);

    //this.openConfirmChangDialog(null, 'agent');
  }
  /* changeBulkFollowUp(event) {
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });
    let bulkChangeAgent = new BulkChangeProductAgent();

    //this.testsConnentorService.bulkChangeProductAgent(checkedIdsArr);
    this.popupHandlerService.openConfirmationDialog('', 'Transfer Missions?');
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      const transferMissions = data.ans === 'yes' ? true : false;

      this.productsPusherServiceService.bulkChangeAgent2(
        checkedIdsArr,
        agentId,
        true,
        transferMissions
      );

      this.checkedIds = {};
      this.openConfirmChangDialog(null, 'agent');

      this.checkedIdsChanged.next(this.checkedIds);
      this.checkedAll = false;
    });
  } */
  changeBulkStatus(event) {
    var statusId = Number(event.target.value);
    if (statusId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });
    //lead.status = null;
    setTimeout(() => {
      this.leadsService.bulkChangeStatus(
        checkedIdsArr,
        statusId,
        this.headFilter
      );
      this.openConfirmChangDialog(null, 'status');
      this.filterChanged.emit(this.headFilter);
    }, 10);

    this.openConfirmChangDialog(null, 'status');

    this.checkedIds = {};
    this.checkedIdsChanged.next(this.checkedIds);

    this.checkedAll = false;
  }
  onSelectCat(cat: FoodCat) {
    const checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });
    this.foodGenesService
      .foodToCategory(cat.categoryId, checkedIdsArr)
      .subscribe((data) => {
        this.checkedIds = {};
        this.checkedIdsChanged.next(this.checkedIds);
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.success
        );
      });
  }
  openConfirmChangDialog(lead: Client = null, type = '', from = '', to = '') {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: lead,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      dialogRef.close();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      var a = result;
    });
  }
  getCheckedCount() {
    var cnt = 0;

    for (var inx in this.checkedIds) {
      cnt++;
    }
    return cnt;
  }
  openDeleteDialog() {
    this.popupHandlerService.openConfirmationDialog('', 'tests');
  }
  exportSelected() {}

  get PageNum() {
    return +this.paginationForm.get('pageNum').value;
  }
  canGoToNextPage() {
    return this.PageNum < this.metaData.totalPages;
  }
  canGoToPrevPage() {
    return this.metaData.currentPage <= 1;
  }
}
