import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { PrototypeCategory } from 'src/app/data/algorithm/PrototypeCategory';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  @Output() loadCategories = new EventEmitter<boolean>();
  @Output() closeMainBoard: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() langId: number = 1;
  @Input() versionId: number;
  @Input() backdrop: boolean;
  @Input() canEdit: boolean;
  AlgoImageType = AlgoImageType;
  addState: 'addFromList' | 'addFromInput'; //wheter to show add from list or add from input
  @Input() category: AlgoCategory;
  categories: PrototypeCategory[];
  constructor(
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.getAvailableCategories();
    this.category = new AlgoCategory();
    this.category.categoryId = 0;
    this.category.langId = this.langId;
    this.category.labVersionId = this.versionId;
    this.category.prototypeCategoryId = 0;
    this.category.description = 'new description';
  }
  onMenuClosed() {}
  onMenuOpened() {}
  getAvailableCategories() {
    this.algorithmService
      .getAvailableCategories(this.versionId, this.langId)
      .subscribe((res) => {
        this.categories = res;
        if (!this.categories || this.categories.length == 0) {
          this.addState = 'addFromInput';
        } else {
          this.addState = null;
        }
      });
  }
  onCatChange(e: number) {
    const prototype = this.categories.find((x) => x.categoryId == e);
    this.category.files = prototype.files;
    this.category.description = prototype.description;
    this.category.isPremium = prototype.isPremium;
    this.category.color = '#' + prototype.color;
    this.category.orderBy = prototype.orderBy;

    //this.subCategory.resultTypes = prototype.;
  }
  openMenu(event: any) {
    event.stopPropagation();
  }
  stopPropagation(event: any) {
    event.stopPropagation();
    //event.preventDefault();
  }
  onSelectCategory(value) {
    this.category.subcategories = [];
  }
  chooseState(state: 'addFromList' | 'addFromInput') {
    this.addState = state;
  }
  onUploadFinished(event: AppImage) {
    if (event) {
      const finded = this.category.files.find(
        (x) => x.fileType == event.fileType
      );
      if (finded) {
        this.category.files = this.category.files.filter(
          (x) => x.fileType != event.fileType
        );
      }
      this.category.files.push(event);
    }
  }
  getImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  save(form: NgForm) {
    if (form.invalid) return;
    this.category.color = this.category.color.replace('#', '');
    this.algorithmService.addCategory(this.category).subscribe((res) => {
      if ((res as ErrorMessage)?.message) {
        this._snackBar
          .open((res as ErrorMessage).message, 'Close', {})
          ._dismissAfter(5000);
      } else {
        this._snackBar.open('Category added', 'Close', {})._dismissAfter(2000);
        this.loadCategories.emit(true);
        this.closeMainBoard.emit(true);
      }
    });
  }
}
