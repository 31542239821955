import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/data/Language';
import { Translation } from 'src/app/data/Translation';
import { TranslationKeyword } from 'src/app/data/TranslationKeyword';
import { LanguagesService } from 'src/app/Services/languages.service';
import { TranslationKeywordsService } from 'src/app/Services/translation-keywords.service';
import { TranslationsService } from 'src/app/Services/translations.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css'],
})
export class TranslationsComponent implements OnInit {
  translations: TranslationKeyword[];
  langs: Language[];

  constructor(
    private translationsService: TranslationsService,
    private translationKeywordsService: TranslationKeywordsService,
    private languagesService: LanguagesService
  ) {}

  displayedColumns: string[] = ['keyword'];
  ngOnInit(): void {
    this.loadAll();
    this.languagesService.getAll().subscribe((data) => {
      this.langs = data;
      this.langs.forEach((lang) => {
        this.displayedColumns.push(lang.name);
      });
    });
  }
  loadAll() {
    this.translationKeywordsService
      .getAllWithTranslations()
      .subscribe((data) => (this.translations = data));
  }
  saveTrans(translation: Translation) {
    this.translationsService.put(translation).subscribe((x) => {
      this.loadAll();
    });
  }
}
