import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';

@Component({
  selector: 'app-weight-history',
  templateUrl: './weight-history.component.html',
  styleUrls: ['./weight-history.component.scss'],
})
export class WeightHistoryComponent implements OnInit {
  fullName: string;
  socialSecurityNum: string;
  tableData: PatientWeightHistory[];
  constructor(
    public dialogRef: MatDialogRef<WeightHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.data.list = this.data.list.sort((a, b) => {
      var x = new Date(a.dateUpdated).getTime();
      var y = new Date(b.dateUpdated).getTime();
      return x > y ? 1 : -1;
    });
  }
}
