import { Component, OnInit } from '@angular/core';
import { Permission } from 'src/app/data/Permission';
import { PermissionCategory } from 'src/app/data/PermissionCategory';
import { PermissionCategoriesService } from 'src/app/Services/permission-categories.service';
import { PermissionsService } from 'src/app/Services/permissions.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css'],
})
export class PermissionsComponent implements OnInit {
  permissions: Permission[];
  categories: PermissionCategory[];

  constructor(
    private permissionsService: PermissionsService,
    private permissionCategoriesService: PermissionCategoriesService
  ) {}
  displayedColumns: string[] = ['name', 'description', 'category'];
  ngOnInit(): void {
    this.permissionsService.getAllWithCategories().subscribe((data) => {
      this.permissions = data;
    });
    this.permissionCategoriesService.getAll().subscribe((data) => {
      this.categories = data;
    });
  }
  onCategoryFilterChange(event, permission: Permission) {
    var val = event.value;
    permission.permisionCategoryId = Number(event.value);
    permission.permissionCategory = this.categories.filter((x) => {
      return x.permisionCategoryId == event.value;
    })[0];
    //lead.status = null;

    this.permissionsService.put(permission).subscribe((data) => {});
  }
}
