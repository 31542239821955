<button class="back back--test" (click)="goBackToPackage()">
  < Back to Delivery
</button>

<mat-card>
  <div>
    <img src="../../../../assets/error_big.svg" style="width: 20px;" /><span
      style="
        margin-right: 40px;
        margin-left: 5px;
        font-weight: bold;
        font-size: 22px;
      "
      >Error Report</span
    >
    <span style="font-size: 22px; color: #1b3f4e;"> </span>
  </div>
  <hr style="border: 1px solid red;" />

  <table
    style="width: 100%;"
    mat-table
    [dataSource]="errorsList"
    class="mat-elevation-z8"
  >
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="errorType">
      <th style="width: 33%;" mat-header-cell *matHeaderCellDef>Error Type</th>
      <td mat-cell *matCellDef="let error">
        <img
          src="../../../assets/triangle_orange.svg"
          style="width: 15px; margin-right: 20px;"
        /><span>{{ error.error.name }}</span>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th style="width: 33%;" mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let error">{{ error.text }}</td>
    </ng-container>

    <!-- 
    <ng-container matColumnDef="dateTime">
      <th style="width: 33%;" mat-header-cell *matHeaderCellDef>
        Date && Time
      </th>
      <td mat-cell *matCellDef="let logging">
        <div>
          <img src="../../../assets/calendar-60.svg" />
          <span style="margin-right: auto;">
            {{ logging.dateCreated | date: "fullDate" }}
          </span>
          <img
            src="../../../assets/clock.svg"
            style="
              margin-left: 10px;
              position: relative;
              top: 2px;
              width: 15px;
            "
          />
          <span> {{ logging.dateCreated | date: "HH:mm" }} </span>
        </div>
      </td>
    </ng-container>
-->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
