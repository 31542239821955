import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Deal } from '../data/Deal';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { DatePipe } from '@angular/common';
import { DealForTableMin } from '../data/DealForTableMin';
import { DealForClientMin } from '../data/DealForClientMin';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { TranzillaTransactionResponse } from '../data/TranzillaTransactionResponse';
import { DealsSummery } from '../data/DealsSummery';
import { DealBundle } from '../data/DealBundle';
import { TranzillaTransactionReport } from '../data/TranzillaTransactionReport';
import * as moment from 'moment';
import { DealBundleForTable } from '../data/DealBundleForTable';

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  getAll() {
    return this.http.get<DealForTableMin[]>(`${environment.baseUrl}Deals/`);
  }
  /*
  getAllByFilter(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }Deals/Filter/${this.httpFilterHelper.createTransactionsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
*/
  getAllByFilterV2(headFilter: HeadFilter) {
    return this.http.post<any>(
      `${environment.baseUrl}Deals/FilterV3/`,
      headFilter
    );
  }

  getByClientId(clientId) {
    return this.http.get<DealBundleForTable[]>(
      `${environment.baseUrl}Deals/ClientDealsV2/${clientId}`
    );
  }
  getByDealId(dealId) {
    return this.http.get<Deal>(
      `${environment.baseUrl}Deals/SingleDeal/${dealId}`
    );
  }
  getTransactionsReports(dealId) {
    return this.http.get<TranzillaTransactionReport[]>(
      `${environment.baseUrl}Deals/GetTransactionReports/${dealId}`
    );
  }

  post(deal: Deal) {
    return this.http.post<Deal>(`${environment.baseUrl}Deals/`, deal);
  }

  cancel(deal: DealForTableMin) {
    return this.http.put<TranzillaTransactionResponse>(
      `${environment.baseUrl}Deals/Cancel/${deal.dealId}`,
      deal
    );
  }
  getSummery(headFilter: HeadFilter) {
    return this.http.get<DealsSummery>(
      `${
        environment.baseUrl
      }Deals/SummeryNew/${this.httpFilterHelper.createTransactionsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  changeStatus(deal: DealForTableMin) {
    return this.http.put<TranzillaTransactionResponse>(
      `${environment.baseUrl}Deals/ChangeStatus/${deal.dealId}`,
      deal
    );
  }
  getContinueDeal(dealId) {
    return this.http.get<Deal>(
      `${environment.baseUrl}Deals/getContinueDeal/${dealId}`
    );
  }
  downloadInovice(dealId) {
    return `${environment.baseUrl}ExelDownload/DealInovice/${dealId}`;
  }

  /*

  getSummery(checkedIds: number[]) {
    return this.http.post<DealsSummery>(
      `${environment.baseUrl}Deals/Summery/`,
      checkedIds
    );
  }
  */
}
