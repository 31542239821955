import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslationKeyword } from '../data/TranslationKeyword';

@Injectable({
  providedIn: 'root',
})
export class TranslationKeywordsService {
  constructor(private http: HttpClient) {}

  getAllWithTranslations() {
    return this.http.get<TranslationKeyword[]>(
      `${environment.baseUrl}TranslationKeywords/WithTranslations`
    );
  }
  getAll() {
    return this.http.get<TranslationKeyword[]>(
      `${environment.baseUrl}TranslationKeywords/`
    );
  }
  getById(agentId) {
    return this.http.get<TranslationKeyword>(
      `${environment.baseUrl}TranslationKeywords/${agentId}`
    );
  }

  put(translationKeyword: TranslationKeyword) {
    return this.http.put(
      `${environment.baseUrl}TranslationKeywords/${translationKeyword.translationKeywordId}`,
      translationKeyword
    );
  }
  post(triggerEventObjectType: TranslationKeyword) {
    return this.http.post<TranslationKeyword>(
      `${environment.baseUrl}TranslationKeywords/`,
      triggerEventObjectType
    );
  }
  delete(translationKeyword: TranslationKeyword) {
    return this.http.delete(
      `${environment.baseUrl}TranslationKeywords/${translationKeyword.translationKeywordId}`
    );
  }
}
