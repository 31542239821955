import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';

@Component({
  selector: 'app-food-categories-selection-item',
  templateUrl: './food-categories-selection-item.component.html',
  styleUrls: ['./food-categories-selection-item.component.scss'],
})
export class FoodCategoriesSelectionItemComponent implements OnInit {
  @Input() fromEditFoodComponent: boolean;
  @Input() fromAllFoodComponent: boolean;
  @Input() fromPaginatorComponent: boolean;
  @Input() selectedCat: FoodCat;
  @Input() category: FoodCat;
  @Input() index: number;
  @Output('selectCat') selectCat: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  toggleAccordion(accordionItem: any, e: any) {
    e.stopPropagation();
    accordionItem.toggle();
  }
  onClickCategory(
    accordionItem: any,
    category: FoodCat,
    e: any,
    from: 'allFood' | 'editFood'
  ) {
    if (from === 'editFood') {
      if (category.subCategories && category.subCategories.length > 0) {
        this.toggleAccordion(accordionItem, e);
      } else {
        this.selectCat.emit(category);
      }
    }
    if (from === 'allFood') {
      this.selectCat.emit(category);
    }
  }
}
