import { Component, ElementRef, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { LifeStyleCalculation } from 'src/app/data/LifeStyleCalculation';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { LifeStyleReportVersion } from 'src/app/data/LifeStyleReportVersion';
import { Status } from 'src/app/data/Status';
import { LifeStyleCalculationsService } from 'src/app/Services/life-style-calculations.service';
import { LifeStyleReportVersionsService } from 'src/app/Services/life-style-report-versions.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { TestGeneralInformationComponent } from '../../Tests/test-general-information/test-general-information.component';

@Component({
  selector: 'app-lifestyle-tree-v2',
  templateUrl: './lifestyle-tree-v2.component.html',
  styleUrls: ['./lifestyle-tree-v2.component.css'],
})
export class LifestyleTreeV2Component implements OnInit {
  isLoading: boolean = true;
  selectedVersionId: number;
  templatesNames: any;
  langId: number = 1;
  versionId: number = 3;
  selectedQuestionId: any;
  versions: LifeStyleReportVersion[];
  isDone: boolean = false;
  length: number[] = [];
  lifeStyleCalculations: LifeStyleCalculation[];
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private lifeStyleReportVersionsService: LifeStyleReportVersionsService,
    private statusesService: StatusesService,
    private lifeStyleCalculationsService: LifeStyleCalculationsService
  ) {}

  // @ViewChild('expand') expand: ElementRef;
  designTypes: Status[];
  containerDesignTypes: Status[];
  lifestyleQuestions: LifestyleQuestion[] = [];
  templates: LifestyleQuestion[] = [];
  ngOnInit(): void {
    this.lifeStyleCalculationsService
      .getAll()
      .subscribe((data) => (this.lifeStyleCalculations = data));
    this.lifeStyleReportVersionsService.getAll().subscribe((data) => {
      this.versions = data;
      var selectedVersion = this.versions.find((x) => x.default);
      this.selectedVersionId = selectedVersion.lifeStyleReportVersionId;
      this.isDone = selectedVersion.isDone;

      this.getAll();
    });

    this.statusesService.getAll().subscribe((res) => {
      this.designTypes = res.filter(
        (x) => x.category == 'lifeStyleQuestionType'
      );
      this.containerDesignTypes = res.filter(
        (x) => x.category == 'lifeStyleQuestionContainerType'
      );
    });
  }
  getAll() {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .getAll(this.langId, this.selectedVersionId)
      .subscribe((data) => {
        this.isLoading = false;
        this.lifestyleQuestions.forEach((question) => {
          this.searchNode(question, data.root);
        });

        this.lifestyleQuestions = data.root;
        this.isLoading = false;
        //this.length = data.root.length;
        for (let index = 0; index < data.root.length; index++) {
          this.length.push(index + 1);
        }
      });

    this.lifestyleQuestionsService
      .getTemplates(this.langId)
      .subscribe((data) => {
        this.templates = data.template;
        this.templatesNames = data.templatesNames;
      });
  }
  addNewQuestion() {
    this.isLoading = true;
    var question = new LifestyleQuestion();

    question.designTypeId = 232;
    question.level = 0;
    question.lifeStyleReportVersionId = this.versionId;
    //question.answerText = 'answer Text ' + this.getRandomInt(1000);
    //question.nextQuestionText = 'answer Text ' + this.getRandomInt(1000);
    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
    });
  }
  onAddNewQuestion(question) {
    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
    });
  }
  addTemplateQuestion() {
    this.isLoading = true;
    var question = new LifestyleQuestion();
    question.designTypeId = 232;
    question.level = -1;
    // question.answerText = 'answer Text ' + this.getRandomInt(1000);
    // question.nextQuestionText = 'answer Text ' + this.getRandomInt(1000);
    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
    });
  }
  onDeleteQuestion(question) {
    this.isLoading = true;
    this.lifestyleQuestionsService.delete(question).subscribe((res) => {
      this.getAll();
      this.isLoading = false;
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  searchNode(node: LifestyleQuestion, data: LifestyleQuestion[]) {
    if (node.isExpanded) {
      data.forEach((x) => {
        this.saveToNode(node, x);
      });
    }
    if (node.childQuestions)
      node.childQuestions.forEach((childNode) => {
        this.searchNode(childNode, data);
      });
  }
  saveToNode(node: LifestyleQuestion, dataNode: LifestyleQuestion) {
    this.isLoading = true;
    if (dataNode.lifestyleQuestionId == node.lifestyleQuestionId) {
      dataNode.isExpanded = true;
    } else {
      if (dataNode.childQuestions)
        dataNode.childQuestions.forEach((x) => {
          this.saveToNode(node, x);
        });
    }
  }
  onAddFromTemplate(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .addFromTemplate(obj.templateId, obj.questionId)
      .subscribe((res) => {
        this.getAll();
      });
  }
  onDuplicate(questionId) {
    this.isLoading = true;
    this.lifestyleQuestionsService.duplicate(questionId).subscribe((res) => {
      this.getAll();
    });
  }
  onMove(obj) {
    this.isLoading = true;
    if (obj.parentId == null) {
      this.lifestyleQuestionsService
        .move(obj.questionId, obj.direction)
        .subscribe((res) => {
          this.getAll();
        });
    } else {
      this.lifestyleQuestionsService
        .move(obj.questionId, obj.direction)
        .subscribe((res) => {
          this.getAll();
        });
    }
  }
  onCheckedChanged(obj) {
    this.selectedQuestionId = obj.questionId;
    this.lifestyleQuestions.forEach((node) => {
      this.removeIsCheckedRecursively(node, obj.questionId);
    });
  }
  removeIsCheckedRecursively(node: LifestyleQuestion, questionIdToSkip) {
    if (node.lifestyleQuestionId != questionIdToSkip) node.isChecked = false;
    if (node.childQuestions)
      node.childQuestions.forEach((child) => {
        this.removeIsCheckedRecursively(child, questionIdToSkip);
      });
  }
  onSetMarkAsReference(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .setMarkAsReference(obj.questionId, this.selectedQuestionId)
      .subscribe((res) => {
        this.getAll();
        this.isLoading = false;
      });
  }
  onChangeOrder(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .setOrder(obj.questionId, obj.order)
      .subscribe((res) => {
        this.getAll();
        this.isLoading = false;
      });
  }
  onRemoveReference(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .removeReference(obj.questionId)
      .subscribe((res) => {
        this.getAll();
      });
  }
  onMoveNodeToMarked(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .moveNodeToParent(obj.questionId, this.selectedQuestionId)
      .subscribe((res) => {
        this.getAll();
      });
  }
  onMoveNodeToRoot(obj) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .moveNodeToRoot(obj.questionId)
      .subscribe((res) => {
        this.getAll();
      });
  }
  onVersionChanged() {
    this.isLoading = true;
    var selectedVersion = this.versions.find(
      (x) => x.lifeStyleReportVersionId == this.selectedVersionId
    );
    this.selectedVersionId = selectedVersion.lifeStyleReportVersionId;
    this.isDone = selectedVersion.isDone;
    this.getAll();
  }
}
