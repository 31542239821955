import { AppImage } from '../algorithm/AppImage';

export class FgFullLabel {
  fgLabelId: number;
  orderBy: number;
  langId: number;
  name: string;
  description: string;
  icon: AppImage;
  nutrientKeyId: number;
  maxValue: number;
  maxLiquidValue: number;
}
