<div class="calendar-settings">
  <button mat-button class="calendar-settings__back" (click)="location.back()">
    < Back
  </button>
  <div class="calendar-settings__title">Calendar settings</div>

  <mat-card class="calendar-settings__card">
    <div class="calendar-settings__card">
      <div class="calendar-settings__card__header">
        <mat-icon>person</mat-icon> Ofir - Dietan
      </div>
      <div class="calendar-settings__card__body">
        <div class="calendar-settings__card__body__item">
          <div class="calendar-settings__card__body__item__label">Set date</div>
          <div class="calendar-settings__card__body__item__field datepicker">
            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" />
                <input matEndDate placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
        <div class="calendar-settings__card__body__item">
          <div class="calendar-settings__card__body__item__label">
            Work hours
          </div>
          <div class="calendar-settings__card__body__item__field">
            <!-- <app-work-time-select
              (hoursStartChange)="onHoursStartChange($event, 'work')"
              (minutsStartChange)="onMinutsStartChange($event, 'work')"
              (hoursEndChange)="onHoursEndChange($event, 'work')"
              (minutsEndChange)="onMinutsEndChange($event, 'work')"
            ></app-work-time-select> -->
            <ngx-timepicker-field
              min="09:00"
              max="18:00"
              [format]="24"
            ></ngx-timepicker-field>
            <ngx-timepicker-field
              min="09:00"
              max="18:00"
              [format]="24"
            ></ngx-timepicker-field>
          </div>
        </div>
        <div class="calendar-settings__card__body__item">
          <div class="calendar-settings__card__body__item__label">
            <mat-checkbox [(ngModel)]="lunchBreakActive"
              >Lunch break</mat-checkbox
            >
          </div>
          <div class="calendar-settings__card__body__item__field">
            <mat-form-field appearance="fill" class="time-select">
              <input type="time" matInput />
            </mat-form-field>
            <span class="until">until-</span>
            <mat-form-field appearance="fill" class="time-select">
              <input type="time" matInput min="09:00" max="18:00" />
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="calendar-settings__card__body__item">
          <div class="calendar-settings__card__body__item__label">
            <mat-checkbox [(ngModel)]="spacialCondintionActive"
              >Spacial condintions</mat-checkbox
            >
          </div>
          <div class="calendar-settings__card__body__item__field">
            <mat-form-field appearance="fill">
              <input type="time" matInput />
            </mat-form-field>
            until-
            <mat-form-field appearance="fill">
              <input type="time" matInput min="09:00" max="18:00" />
            </mat-form-field>
          </div>
        </div> -->
      </div>
    </div>
  </mat-card>
</div>
