import { Component, OnInit } from '@angular/core';
import { PermissionsForRolesService } from 'src/app/Services/permissions-for-roles.service';
import { PermissionForRole } from 'src/app/data/PermissionForRole';
import { RoleTypesService } from 'src/app/Services/role-types.service';
import { RoleType } from 'src/app/data/RoleType';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { Permission } from 'src/app/data/Permission';

@Component({
  selector: 'app-permissions-for-roles',
  templateUrl: './permissions-for-roles.component.html',
  styleUrls: ['./permissions-for-roles.component.scss'],
})
export class PermissionsForRolesComponent implements OnInit {
  newRoleName: string;
  permissions: Permission[];
  headers = [
    { name: 'Role', val: 'Role' },
    { name: 'Permissions', val: 'Permissions' },
  ];
  constructor(
    private permissionsService: PermissionsService,
    private roleTypesService: RoleTypesService,
    private permissionsForRolesService: PermissionsForRolesService
  ) {}

  roleTypesData: RoleType[];
  ngOnInit(): void {
    this.permissionsService.getAll().subscribe((data) => {
      this.permissions = data;
    });
    this.getAllRoles();
  }
  getAllRoles() {
    this.roleTypesService.getAllWithPermissions().subscribe((data) => {
      this.roleTypesData = data;
      var role = this.roleTypesData[0];
      var a1 = role.permissionsForRoles;
      var a2 = role.permissionsForRoles[0];
      var a3 = a2.permission.permissionAction;
      this.roleTypesData.map((roleType) => {
        roleType.newPermissionId = -1;
      });
    });
  }
  createNewRole() {}
  addPermissionClick(event, roleType: RoleType) {
    var permissionForRole = new PermissionForRole();
    permissionForRole.permissionId = roleType.newPermissionId;
    permissionForRole.roleId = roleType.roleTypeId;
    roleType.permissionsForRoles.push(permissionForRole);
    roleType.newPermissionId = -1;
    this.permissionsForRolesService
      .post(permissionForRole)
      .subscribe((data) => {
        this.getAllRoles();
      });
    /*this.roleTypesService.put(roleType).subscribe(data => {
      this.getAllRoles();
    })
    */
  }
  deletePermission(event, permissionForRole: PermissionForRole) {
    this.permissionsForRolesService
      .delete(permissionForRole)
      .subscribe((data) => {
        this.getAllRoles();
      });
  }
  addRole() {
    var roleType = new RoleType();
    roleType.roleName = this.newRoleName;
    this.newRoleName = '';
    this.roleTypesService.post(roleType).subscribe((res) => {
      this.getAllRoles();
    });
  }
}
