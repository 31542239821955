<div class="snp">
  <button (click)="location.back()" class="snp__back" mat-button>< Back</button>

  <mat-card class="snp__card">
    <mat-card-header class="snp__card__header">
      <mat-card-title class="snp__card__header__title">
        SNP table
      </mat-card-title>
      <label for="navSearch" class="snp__search">
        <input
          id="navSearch"
          type="text"
          [(ngModel)]="headFilter.searchTerm"
          (keyup.enter)="onSearchBtnClick()"
          placeholder="Search SNP Number"
        />
        <button
          (click)="onSearchBtnClick()"
          type="button"
          class="snp__search__btn"
        ></button>
      </label>
    </mat-card-header>
    <ngx-skeleton-loader
      *ngIf="!prototypeSnp"
      [theme]="{ height: '30px' }"
      count="15"
    ></ngx-skeleton-loader>
    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <app-snp-table
        *ngIf="prototypeSnp"
        [data]="prototypeSnp"
        (loadSnps)="getSnpsWithPaging()"
      ></app-snp-table>
    </perfect-scrollbar>
  </mat-card>
</div>

<app-table-paginator
  [minWidth]="true"
  [sticky]="true"
  *ngIf="pagination"
  [metaData]="pagination"
  [headFilter]="headFilter"
  (filterChanged)="getPaginatorData($event)"
>
</app-table-paginator>
