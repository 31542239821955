<mat-dialog-content class="mat-typography">
  <div id="print-menu" #menuHtml>
    <div
      style="
        text-align: right;
        direction: rtl;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
        color: #000000;
        font-size: 16px;
      "
    >
      <div style="font-size: 26px; font-weight: 500; margin: 40px 0">
        {{ menu.name }}
      </div>

      <div
        style="width: 100%; border-collapse: collapse"
        *ngFor="let meal of meals; let inx = index"
      >
        <table style="width: 100%">
          <tr>
            <td>
              <div>
                <img
                  src="https://mygenes.co.il/wp-content/uploads/2021/06/{{
                    meal.mealType.toLowerCase()
                  }}.png"
                  alt=""
                  style="float: right"
                  [ngStyle]="{
                    top:
                      meal.mealType.toLowerCase() === 'breakfast' ? '-10px' : ''
                  }"
                />
                <span
                  style="padding-right: 5px; font-weight: 500; font-size: 20px"
                  >{{ getMealType(meal.mealType) }}</span
                >
              </div>
            </td>
            <td align="left">
              <div>
                <img
                  src="https://mygenes.co.il/wp-content/uploads/2021/06/meal-time.png"
                  alt=""
                />
                {{ formatMealTime(meal.mealTime) }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 20px"></td>
          </tr>
        </table>

        <ng-container
          *ngFor="
            let bullet of meal.mealBullets;
            let bulletInx = index;
            let first = first;
            let last = last
          "
        >
          <ng-container *ngIf="bullet.mealBulletOptions.length > 0">
            <table style="width: 100%">
              <tr *ngIf="!first">
                <td style="padding: 20px 0">
                  <div
                    style="
                      background-color: #df3f92;
                      border-radius: 11px;
                      color: #fff;
                      padding: 3px 7px;
                      font-size: 14px;
                    "
                  >
                    בנוסף
                  </div>
                </td>
                <td style="margin: auto; vertical-align: center; width: 100%">
                  <div
                    style="height: 2px; background: #df3f92; width: 100%"
                  ></div>
                </td>
              </tr>
            </table>

            <table style="width: 100%">
              <tr
                *ngFor="
                  let option of bullet.mealBulletOptions;
                  let firstOption = first;
                  let lastOption = last
                "
              >
                <td
                  style="border-top: 2px solid #e2e2e2; padding: 5px 0"
                  [ngStyle]="{
                    'border-bottom': lastOption ? '2px solid #e2e2e2' : ''
                  }"
                >
                  <strong *ngIf="bullet.mealBulletOptions.length !== 1"
                    >או</strong
                  >
                  <span>
                    {{ option.foodName }}
                  </span>
                </td>
                <td
                  style="border-top: 2px solid #e2e2e2; padding: 5px 0"
                  [ngStyle]="{
                    'border-bottom': lastOption ? '2px solid #e2e2e2' : ''
                  }"
                  align="left"
                >
                  {{ option.quantity | number }}
                  {{ option.scaleName }}
                </td>
              </tr>
            </table>
          </ng-container>
        </ng-container>
        <table style="width: 100%">
          <tr *ngIf="meal.notes">
            <td>
              <div
                style="margin: 30px 0; border: 1px solid #000; padding: 10px"
              >
                <div style="font-weight: 500; font-size: 18px">דגשים:</div>
                <div style="margin-bottom: 10px"></div>
                <div [innerHtml]="meal.notes"></div>
              </div>
            </td>
          </tr>
        </table>
        <table style="width: 100%">
          <tr
            style="
              margin-bottom: 20px;
              font-weight: 500;
              font-size: 24px;
              text-align: center;
            "
          >
            <td>
              <span>דירוג גנטי </span>
              <span style="font-size: 28px"
                >{{
                  getGeneticRate(meal.nutrients)?.amount | number: "1.0-0"
                }}%</span
              >
            </td>
          </tr>
          <tr
            style="
              text-align: center;
              font-size: 18px;
              color: #1c1c1c;
              margin-bottom: 20px;
            "
          >
            <td>
              <span> ערכים תזונתיים </span>
              <span style="font-weight: bold"
                >ל{{ getMealType(meal.mealType) }}</span
              >
            </td>
          </tr>
        </table>
        <table style="width: 100%">
          <tr>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>קלוריות</td>
                  <td align="left">
                    {{ getCalories(meal.nutrients)?.amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>
                    פחמימות
                    <small
                      >({{
                        getPachmimot(meal.nutrients)?.unit
                          ? getPachmimot(meal.nutrients)?.unit
                          : "גרם"
                      }})</small
                    >
                  </td>
                  <td></td>
                  <td align="left">
                    {{ getPachmimot(meal.nutrients)?.amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="width: 50%; padding: 5px 10px">
              <table style="width: 100%">
                <tr>
                  <td>
                    שומנים
                    <small
                      >({{
                        getFats(meal.nutrients)?.unit
                          ? getFats(meal.nutrients)?.unit
                          : "גרם"
                      }})</small
                    >
                  </td>
                  <td></td>
                  <td align="left">
                    {{ getFats(meal.nutrients)?.amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
            <td style="width: 50%; padding: 5px 10px">
              <table style="width: 100%">
                <tr>
                  <td>
                    חלבונים
                    <small>
                      ({{
                        getHelbonim(meal.nutrients)?.unit
                          ? getHelbonim(meal.nutrients)?.unit
                          : "גרם"
                      }})</small
                    >
                  </td>
                  <td></td>
                  <td align="left">
                    {{ getHelbonim(meal.nutrients)?.amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[0].nutrientName }}
                    <small>({{ meal.vitamins[0].unit }})</small>
                  </td>
                  <td align="left">
                    {{ meal.vitamins[0].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[1].nutrientName }}
                    <small>({{ meal.vitamins[1].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[1].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="width: 50%; padding: 5px 10px">
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[2].nutrientName }}
                    <small>({{ meal.vitamins[2].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[2].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
            <td style="width: 50%; padding: 5px 10px">
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[3].nutrientName }}
                    <small>({{ meal.vitamins[3].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[3].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[4].nutrientName }}
                    <small>({{ meal.vitamins[4].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[4].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
            <td
              style="width: 50%; padding: 5px 10px; background-color: #f8f8f8"
            >
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[5].nutrientName }}
                    <small>({{ meal.vitamins[5].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[5].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="width: 50%; padding: 5px 10px">
              <table style="width: 100%">
                <tr>
                  <td>
                    {{ meal.vitamins[6].nutrientName }}
                    <small>({{ meal.vitamins[6].unit }})</small>
                  </td>

                  <td align="left">
                    {{ meal.vitamins[6].amount | number: "1.0-0" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 5%"></td>
          </tr>
        </table>
        <table style="width: 100%">
          <tr>
            <td>
              <div style="margin-bottom: 30px"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div
                style="
                  width: 100%;
                  height: 2px;
                  background-color: #000000;
                  margin-bottom: 30px;
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="margin-bottom: 50px"></div>
            </td>
          </tr>
        </table>
      </div>
      <div
        style="margin: 30px 0; border: 1px solid #000; padding: 10px"
        *ngIf="menu.notes"
      >
        <div style="font-weight: 500; font-size: 18px">דגשים:</div>
        <div style="margin-bottom: 10px"></div>
        <div [innerHtml]="menu.notes"></div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="printMode" align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button
    mat-button
    cdkFocusInitial
    printSectionId="print-menu"
    [useExistingCss]="true"
    ngxPrint
  >
    Print
  </button>
</mat-dialog-actions>

<!-- [mat-dialog-close]="true" -->
