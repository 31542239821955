import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Note } from 'src/app/data/Note';
import { Router } from '@angular/router';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { NotesService } from 'src/app/Services/notes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { NoteCategoryEnum } from 'src/app/Enums/StatusesEnum';
import { ClientRequestNote } from 'src/app/data/food-genes/ClientRequestNote';

@Component({
  selector: 'app-client-note-thumb',
  templateUrl: './client-note-thumb.component.html',
  styleUrls: ['./client-note-thumb.component.css'],
})
export class ClientNoteThumbComponent implements OnInit {
  @Output('reloadNotes') reloadNotes: EventEmitter<any> = new EventEmitter();
  @Input() classAttr;
  @Input() note: NoteForCallMin | ClientRequestNote;
  @Input() isAppReport: boolean;
  userAlign: boolean = true;
  constructor(
    private router: Router,
    private notesService: NotesService,
    private popupHandlerService: PopupHandlerService
  ) {}
  ngOnInit(): void {}
  gotoServiceRequest(e) {
    e.stopPropagation();
    e.preventDefault();

    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/requests/edit/'], {
        queryParams: {
          serviceRequestId: noteRequest.serviceRequestId
            ? noteRequest.serviceRequestId
            : appNoteRequest.clientRequestId,
        },
      })
    );
    window.open(url, '_blank');
  }
  gotoCancellationRequest(e, note: NoteForCallMin | ClientRequestNote) {
    e.stopPropagation();
    e.preventDefault();
    var str = '';
    const noteRequest = note as NoteForCallMin;
    const appNoteRequest = note as ClientRequestNote;
    switch (noteRequest?.statusId) {
      case NoteCategoryEnum.refund:
        str = 'refunds';
        break;
      case NoteCategoryEnum.cancelation:
        str = 'cancelations';
        break;
      default:
        break;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${str}/edit/`], {
        queryParams: { cancelRequestId: noteRequest.cancelationRequestId },
      })
    );
    window.open(url, '_blank');
  }
  getImageFileName() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      if (noteRequest.imageUrl) {
        var spl = noteRequest.imageUrl.split('/');
        return spl[spl.length - 1];
      }
    } else {
      if (appNoteRequest.noteImage.fileId) {
        return appNoteRequest.noteImage.fileName;
      }
    }
  }
  downloadNoteImage() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      window.open(noteRequest.imageUrl, '_blank');
    } else {
      window.open(
        appNoteRequest.noteImage.webPath + appNoteRequest.noteImage.fileName,
        '_blank'
      );
    }
  }
  getFilesUploadPath() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return this.notesService.getuploadFilePath(noteRequest.noteId);
    } else {
    }
  }
  uploadFileFinished(event) {
    //console.log('uploadFileFinished add-new-note');
    if (event == true) this.reloadNotes.emit(true);
  }
  preventDefault(event) {
    event.stopPropagation();
  }
  openRecordDialog(noteId: number) {
    this.popupHandlerService.openRecordCallDialog(noteId);
  }
  getNoteTitle() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.title;
    } else {
      return appNoteRequest.noteTitle;
    }
  }
  getNoteDesc() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.desc;
    } else {
      return appNoteRequest.noteContent;
    }
  }
  getNoteUpdateDate() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.lastUpdated;
    } else {
      return appNoteRequest.updatedDate;
    }
  }
  getNoteCreateDate() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.dateCreated;
    } else {
      return appNoteRequest.createdDate;
    }
  }
  getNoteId() {
    const noteRequest = this.note as NoteForCallMin;
    if (!this.isAppReport) {
      return noteRequest.noteId;
    }
  }
  getServiceRequestId() {
    const noteRequest = this.note as NoteForCallMin;
    if (!this.isAppReport) {
      return noteRequest.serviceRequestId;
    }
  }
  getClientRequestId() {
    const noteRequest = this.note as ClientRequestNote;
    if (this.isAppReport) {
      return noteRequest.clientRequestId;
    }
  }
  getCancelationRequestId() {
    const noteRequest = this.note as NoteForCallMin;
    if (!this.isAppReport) {
      return noteRequest.cancelationRequestId;
    }
  }
  getNoteCategory() {
    const noteRequest = this.note as NoteForCallMin;
    if (!this.isAppReport) {
      return noteRequest.noteCategory;
    }
  }
  getNoteTypeId() {
    const noteRequest = this.note as NoteForCallMin;
    if (!this.isAppReport) {
      return noteRequest.noteTypeId;
    }
  }
  getNoteAssigningAgentName() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.assigningAgent.name;
    } else {
      return appNoteRequest.authorAgentName;
    }
  }
  getImageUrl() {
    const noteRequest = this.note as NoteForCallMin;
    const appNoteRequest = this.note as ClientRequestNote;
    if (!this.isAppReport) {
      return noteRequest.imageUrl;
    } else {
      return (
        appNoteRequest?.noteImage?.webPath + appNoteRequest?.noteImage?.fileName
      );
    }
  }
}
