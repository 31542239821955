import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExelDownloadService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getTubePackageById(packageId) {
    return `${environment.baseUrl}ExelDownload/LabPackage/${packageId}`;
  }
  getDealsByCheckedIds(checkedId) {
    return `${environment.baseUrl}ExelDownload/Deals/${checkedId}`;
  }
  getDealsByFilter(headFilter: HeadFilter) {
    return `${
      environment.baseUrl
    }ExelDownload/DealsFilterExel/${this.httpFilterHelper.createTransactionsPathFromHeadFilter(
      headFilter,
      this.datepipe
    )}`;
  }
  getDealsByFilterNew(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }BlobsDownloads/DealsFilterExelV2/${this.httpFilterHelper.createTransactionsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getProductsByFilter(headFilter: HeadFilter) {
    return `${
      environment.baseUrl
    }ExelDownload/ProductsByFilter/${this.httpFilterHelper.createPathFromHeadFilterProductsDownload(
      headFilter,
      this.datepipe
    )}`;
  }

  generateBarcodesExcelFile(headFilter: HeadFilter) {
    headFilter.searchTerm =
      headFilter.searchTerm === '' ? null : headFilter.searchTerm;
    return this.http.get<any>(
      `${
        environment.baseUrl
      }BarcodeStorage/GenerateBarcodeStorageExcelFile/${this.httpFilterHelper.createBarcodeToExelPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getPatientNutritionReport(productId: Number) {
    return `${environment.baseUrl}ExelDownload/PatientNutritionReport/${productId}`;
  }
  getPatientContract(patientId: Number, part: number) {
    return `${environment.baseUrl}ExelDownload/PatientConsent/${patientId}/${part}`;
  }
  getProductDnaPdf(productId: Number) {
    return `${environment.baseUrl}ExelDownload/ProductDnaPdf/${productId}`;
  }
  getWeightHistories() {
    return `${environment.baseUrl}ExelDownload/WeightHistory/`;
  }
  getDnaDataProcessing() {
    return `${environment.baseUrl}ExelDownload/DnaDataProcessing/`;
  }
  getDnaDataAllSnps() {
    return `${environment.baseUrl}ExelDownload/DnaDataAllSnps/`;
  }
  getDnaDataVitamins() {
    return `${environment.baseUrl}ExelDownload/DnaDataVitamins/`;
  }
  getAlgoGroups(crossings) {
    return this.http.post<any>(
      `${environment.baseUrl}BlobsDownloads/AlgoGroupsV2/`,
      crossings
    );
  }
}
