import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dna-report-edit',
  templateUrl: './dna-report-edit.component.html',
  styleUrls: ['./dna-report-edit.component.css']
})
export class DnaReportEditComponent implements OnInit {

  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  onEditClick() {
    this.onEdit.emit(true);
  }
}
