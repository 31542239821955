import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Client } from 'src/app/data/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaymentMethod } from 'src/app/data/PaymentMethod';
import { ProductType } from 'src/app/data/ProductType';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PaymentMethodService } from 'src/app/Services/payment-method.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { Globals } from 'src/app/globals';
import { Deal } from 'src/app/data/Deal';
import { Product } from 'src/app/data/Product';
import { FutureProduct } from 'src/app/data/FutureProduct';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import {
  DealEnum,
  PurchaseChooseProductError,
} from 'src/app/Enums/StatusesEnum';
import { DealBundle } from 'src/app/data/DealBundle';

@Component({
  selector: 'app-purchase-choose-products',
  templateUrl: './purchase-choose-products.component.html',
  styleUrls: ['./purchase-choose-products.component.scss'],
})
export class PurchaseChooseProductsComponent implements OnInit {
  @Input() clientProducts: ProductForClientMin[];
  @Input() client: Client;
  @Output('completePurchase') completePurchaseEmitter: EventEmitter<any> =
    new EventEmitter();
  @Output('cancelPurchase') cancelPurchaseEmitter: EventEmitter<any> =
    new EventEmitter();
  @Output('disableNextBtn') disableNextBtn: EventEmitter<any> =
    new EventEmitter();
  childForms: FormGroup[] = [];
  myProducts: FutureProduct[] = [];
  paymentForm: FormGroup = null;
  paymentMethods: PaymentMethod[];
  paymentFormReady: boolean = false;
  productTypes: ProductType[];
  productsTotal: number = 0;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  stageId: Number;
  showNoProductWarning: boolean = false;
  //isProductFormsValid: boolean = true;
  formErrorTxt: string = '';
  isSubmited: boolean;
  hasUpgradeProduct: boolean = false;
  atleastOneChecked: boolean;
  isUpgrade: boolean = false;
  disableNextPricePerUnit: boolean;
  constructor(
    private paymentMethodService: PaymentMethodService,
    private productTypesService: ProductTypesService
  ) {
    this.stageId = Globals.stageId;
  }

  ngOnInit(): void {
    this.clientProducts.forEach((product) => (product.isChecked = false));
    this.createFormGroup();
    this.paymentMethodService
      .getAll()
      .subscribe((data) => (this.paymentMethods = data));
    this.productTypesService.getAll().subscribe((data) => {
      this.productTypes = data;
    });
  }
  completePurchase() {
    this.isSubmited = true;
    if (
      this.myProducts.some((x) => x.productTypeId == 7) &&
      this.myProducts.some((x) => x.productTypeId != 7)
    ) {
      this.formErrorTxt =
        "can't add Upgrade and Regular Product in the same Deal";
      return;
    }
    /*
    if (this.productsTotal == 0) {
      return;
    }*/

    this.formErrorTxt = '';
    if (this.productsTotal < 0) {
      this.formErrorTxt = PurchaseChooseProductError.InvalidAmount;
    }
    this.childForms.forEach((product) => {
      if (!product.valid)
        this.formErrorTxt = PurchaseChooseProductError.MissingProductType;
    });
    if (
      this.myProducts.length == 0 ||
      this.childForms.length != this.myProducts.length
    ) {
      this.formErrorTxt = PurchaseChooseProductError.MissingProductType;
    }

    if (
      this.formErrorTxt ==
      '' /* && (this.hasUpgradeProduct || this.productsTotal>=2500)*/
    ) {
      var deal: Deal = new Deal();
      deal.dealBundle = new DealBundle();
      deal.statusId = 3;
      deal.dealBundle.client = this.client;
      deal.dealBundle.agentId = this.client.agentId;
      deal.dealBundle.clientId = this.client.clientId;

      deal.amount = this.productsTotal;
      deal.dealBundle.amount = this.productsTotal;
      deal.dealBundle.leftToPay = deal.dealBundle.amount;
      deal.paymentMethodId = this.paymentForm.value.paymentMethodId;
      deal.futureProducts = this.myProducts;
      /*
      this.childForms.forEach((productForm) => {
        for (var i = 0; i < productForm.value.amount; i++) {
          var product: FutureProduct = new FutureProduct();

          product.clientId = this.client.clientId;
          // product.statusId = 6; //Not Registered
          product.productTypeId = productForm.value.productTypeId;
         
          deal.futureProducts.push(product);
        }
      });
      */
      this.completePurchaseEmitter.emit(deal);
    }
  }

  cancelPurchase() {
    this.cancelPurchaseEmitter.emit();
  }
  createFormGroup() {
    this.paymentForm = new FormGroup({
      paymentMethodId: new FormControl(null, [Validators.required]),
      cardHolderName: new FormControl('', [Validators.required]),
      cardNumber: new FormControl('', [Validators.required]),
      ExpireDate: new FormControl('', [Validators.required]),
      cvc: new FormControl(null, [Validators.required]),
    });

    setTimeout(() => {
      this.paymentFormReady = true;
    }, 100);
  }
  hideCustumerPlaceholder;
  hidePlaceholderCustumerFunc() {}
  addNewProduct() {
    var futureProduct = new FutureProduct({
      index: this.myProducts.length,
      amount: 1,
      clientId: this.client.clientId,
    });
    this.myProducts.push(futureProduct);
  }
  conertFormProductToFutureProduct(futureProduct, formProduct) {
    futureProduct.clientId = this.client.clientId;
    futureProduct.amount = formProduct.amount;
    futureProduct.productTypeId = formProduct.productTypeId;
    if (formProduct.products)
      futureProduct.products = formProduct.products.filter((x) => x.isChecked);
    return futureProduct;
  }
  updateProductData(event) {
    const { pricePerUnit } = event.value;
    if (pricePerUnit < 0) {
      this.disableNextPricePerUnit = true;
    } else {
      this.disableNextPricePerUnit = false;
    }

    if (event.value.productTypeId == DealEnum.Upgrade) {
      this.isUpgrade = true;
    } else {
      this.isUpgrade = false;
    }

    let formEvent: FormGroup = event;
    let productsForm = formEvent.get('products').value;

    this.atleastOneChecked = productsForm.some((x) => x.isChecked);
    //this.disableNextBtn.emit(atleastOneChecked);

    if (event.value.productTypeId != 7) {
      //upgrade
      if (event.value.amount == 0) {
        this.myProducts.splice(event.value.index, 1);
      } else {
        this.childForms[event.value.index] = event;
        this.myProducts[event.value.index] =
          this.conertFormProductToFutureProduct(
            this.myProducts[event.value.index],
            event.value
          );
      }
    } else {
      this.childForms[event.value.index] = event;
      this.myProducts[event.value.index] =
        this.conertFormProductToFutureProduct(
          this.myProducts[event.value.index],
          event.value
        );
      this.productsTotal = 0;
      this.childForms.forEach((element) => {
        this.productsTotal += element.value.formTotal;
      });
    }
    this.hasUpgradeProduct = false;
    this.productsTotal = 0;
    this.childForms.forEach((element) => {
      if (element.value.productTypeId == 7) {
        this.hasUpgradeProduct = true;
      }
      this.productsTotal += element.value.formTotal;
    });
  }
  get paymentMethodId() {
    return this.paymentForm.get('paymentMethodId');
  }
  get cardHolderName() {
    return this.paymentForm.get('cardHolderName');
  }
  get cardNumber() {
    return this.paymentForm.get('cardNumber');
  }
  get ExpireDate() {
    return this.paymentForm.get('ExpireDate');
  }
  get cvc() {
    return this.paymentForm.get('cvc');
  }
}
