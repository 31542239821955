import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from 'src/app/data/Country';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { CountriesService } from 'src/app/Services/countries.service';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { COMMA, ENTER, V } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { validateArrayNotEmpty } from 'src/app/Utils/validatorsUtils';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-edit-phone-verification-setting',
  templateUrl: './edit-phone-verification-setting.component.html',
  styleUrls: ['./edit-phone-verification-setting.component.scss'],
})
export class EditPhoneVerificationSettingComponent implements OnInit {
  @ViewChild('phonePrefixsList') phonePrefixsList: MatChipList;
  @ViewChild('mobilePrefixsList') mobilePrefixsList: MatChipList;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  countryId: number;
  phoneVerificationSetting: PhoneVerificationSetting;
  countries: Country[];
  phonePrefixsArr: string[] = [];
  mobilePrefixsArr: string[] = [];
  form: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService,
    private countriesService: CountriesService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.countryId = +params['countryId'];
        if (this.countryId > 0) {
          this.getByCountryId();
        } else {
          this.phoneVerificationSetting = new PhoneVerificationSetting();
          this.initForm();
        }
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      countryId: this.fb.control(this.countryId),
      phonePrefixs: this.fb.array(this.phonePrefixsArr, validateArrayNotEmpty),
      mobilePrefixs: this.fb.array(
        this.mobilePrefixsArr,
        validateArrayNotEmpty
      ),
      localPrefix: this.fb.control(this.phoneVerificationSetting.localPrefix),
      maxNumLength: this.fb.control(
        this.phoneVerificationSetting.maxNumLength,
        Validators.required
      ),
      minNumLength: this.fb.control(
        this.phoneVerificationSetting.minNumLength,
        Validators.required
      ),
    });
    this.form
      .get('phonePrefixs')
      .statusChanges.subscribe(
        (status) => (this.phonePrefixsList.errorState = status === 'INVALID')
      );
    this.form
      .get('mobilePrefixs')
      .statusChanges.subscribe(
        (status) => (this.mobilePrefixsList.errorState = status === 'INVALID')
      );

    setTimeout(() => {
      var htmlCollection = document.getElementsByClassName(
        'mat-form-field-flex'
      );
      htmlCollection.item(1).scrollTop = htmlCollection.item(1).scrollHeight;
      htmlCollection.item(2).scrollTop = htmlCollection.item(2).scrollHeight;
    }, 500);
  }

  getByCountryId() {
    this.phoneVerificationSettingsService
      .getByCountry(this.countryId)
      .subscribe((data) => {
        this.phoneVerificationSetting = data;
        this.phonePrefixsArr =
          this.phoneVerificationSetting.phonePrefixs.split(',');
        this.mobilePrefixsArr =
          this.phoneVerificationSetting.mobilePrefixs.split(',');
        this.initForm();
      });
  }

  saveCountries(event) {
    this.form.controls['countryId'].setValue(event);
  }

  getCountries() {
    this.countriesService.getAll().subscribe((data) => {
      this.countries = data;
    });
  }

  addPrefixs(event: MatChipInputEvent, controlName: string): void {
    const value = (event.value || '').trim();
    const chipInput = event.chipInput;
    if (value) {
      const control = <FormArray>this.form.get(controlName);
      control.push(this.initPrefix(value));
    }
    if (chipInput) {
      chipInput!.clear();
    }
  }
  removePrefixs(index: number, controlName: string): void {
    const control = <FormArray>this.form.get(controlName);
    control.removeAt(index);
  }

  initPrefix(name: string): FormControl {
    return this.fb.control(name);
  }

  reloadAfterPost(countryId: number) {
    this.router.navigate(['phone-verification-setting/edit'], {
      queryParams: {
        countryId,
      },
    });
  }

  save() {
    if (this.form.valid) {
      this.phoneVerificationSetting.phoneVerificationSettingId =
        this.countryId > 0
          ? this.phoneVerificationSetting.phoneVerificationSettingId
          : 0;
      this.phoneVerificationSetting.countryId =
        this.form.get('countryId').value;
      this.phoneVerificationSetting.phonePrefixs = this.form
        .get('phonePrefixs')
        .value.join(',');
      this.phoneVerificationSetting.mobilePrefixs = this.form
        .get('mobilePrefixs')
        .value.join(',');
      this.phoneVerificationSetting.maxNumLength =
        this.form.get('maxNumLength').value;
      this.phoneVerificationSetting.minNumLength =
        this.form.get('minNumLength').value;

      if (this.countryId > 0) {
        this.phoneVerificationSettingsService
          .put(this.phoneVerificationSetting)
          .subscribe((data) => {
            this.getByCountryId();
          });
      } else {
        this.phoneVerificationSettingsService
          .post(this.phoneVerificationSetting)
          .subscribe((data) => {
            this.reloadAfterPost(data.countryId);
          });
      }
    }
  }
}
