import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModuleAction } from '../data/ModuleAction';
import { PermissionRule } from '../data/PermissionRule';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  constructor(private http: HttpClient) {}
  forRoleAndModule(roleId, moduleId) {
    return this.http.get<ModuleAction[]>(
      `${environment.baseUrl}UserPermissions/ForRoleAndModule/${roleId}/${moduleId}`
    );
  }
  forRoleAndModuleAndAction(roleId, moduleId, actionId) {
    return this.http.get<PermissionRule[]>(
      `${environment.baseUrl}UserPermissions/ForRoleAndModuleAndAction/${roleId}/${moduleId}/${actionId}`
    );
  }
  updateActionForRoleAndModule(roleId, moduleId, actionId, isChecked) {
    return this.http.get<ModuleAction[]>(
      `${environment.baseUrl}UserPermissions/updateActionForRoleAndModule/${roleId}/${moduleId}/${actionId}/${isChecked}`
    );
  }
  updateRuleForRoleAndModuleAndAction(
    roleId,
    moduleId,
    actionId,
    ruleId,
    isChecked
  ) {
    return this.http.get<ModuleAction[]>(
      `${environment.baseUrl}UserPermissions/UpdateRuleForRoleAndModuleAndAction/${roleId}/${moduleId}/${actionId}/${ruleId}/${isChecked}`
    );
  }
}
