import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RegularUser } from '../data/RegularUser';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { FirebaseAnonimousAuthService } from './firebase-anonimous-auth.service';
import { RegularUserForTable } from '../data/RegularUserForTable';
import { PopupHandlerService } from './popup-handler.service';
import { Router } from '@angular/router';
import { userHasPermisions } from '../Utils/user-helper';
import { AgentInteractionService } from './agent-interaction.service';
import { AgentInteraction } from '../data/AgentInteraction';
import { AgentStatusEnum } from '../Enums/StatusesEnum';

@Injectable({
  providedIn: 'root',
})
export class RegularUsersService {
  requestLockedVerificationCode(userName: any) {
    return this.http.get<any>(
      `${environment.baseUrl}RegularUsers/RequestLockedVerificationCode/${userName}/`
    );
  }
  private currentUserSubject: BehaviorSubject<RegularUser>;
  private currentLoginUserSubject: BehaviorSubject<RegularUser> =
    new BehaviorSubject<RegularUser>(null);
  public currentUser: Observable<RegularUser>;
  constructor(
    private http: HttpClient,
    private anonAuthService: FirebaseAnonimousAuthService,
    private popupHandlerService: PopupHandlerService,
    private router: Router,
    private agentInteractionsService: AgentInteractionService
  ) {
    this.currentUserSubject = new BehaviorSubject<RegularUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  enterToken(
    accessToken: number,
    token: string
  ): Observable<RegularUser | ErrorMessage> {
    return this.http
      .get<RegularUser | ErrorMessage>(
        `${environment.baseUrl}RegularUsers/EnterToken/${accessToken}/${token}`
      )
      .pipe(
        map((user) => {
          if ((user as RegularUser).firstName) {
            var _user = user as RegularUser;
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('jwtToken', JSON.stringify(_user.token));
            localStorage.setItem('access_token', accessToken.toString());

            this.anonAuthService.anonymousLogin();
            this.currentUserSubject.next(_user);
            return _user;
          } else {
            this.logout();

            var errorMsg = user as ErrorMessage;
            return errorMsg;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        })
      );
  }

  getVersion() {
    return this.http.get<string>(`${environment.baseUrl}RegularUsers/Version`);
  }

  registerGeneral(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/Register/General`,
      user
    );
  }
  registerPatient(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/Register/Patient`,
      user
    );
  }
  validateFromLogin(__user) {
    return this.http
      .post<RegularUser | ErrorMessage>(
        `${environment.baseUrl}RegularUsers/`,
        __user
      )
      .pipe(
        map((user) => {
          if ((user as RegularUser).firstName) {
            var _user = user as RegularUser;
            localStorage.setItem('currentUser', JSON.stringify(_user));
            localStorage.setItem('jwtToken', JSON.stringify(_user.token));

            this.anonAuthService.anonymousLogin();
            this.currentUserSubject.next(_user);
            this.currentLoginUserSubject.next(_user);
            /* if (_user.isFarLogin) {
              this.reportFarLoginAlive().subscribe(() => {});
            } */
            if (userHasPermisions(['AgentInteraction_GetList'], _user)) {
              this.setUserInteraction(_user.agent.agentId);
            }

            /* if (_user == null) {
              console.log('[LoadingPageComponent] navigate login');
              this.router.navigate(['login']);
              return _user;
            } else if (userHasPermisions(['All Leads', 'My Leads'], _user)) {
              this.router.navigate(['leads']);
              return _user;
            } else {
              if (userHasPermisions(['All Patients', 'My Patients'], _user))
                this.router.navigate(['tests']);
            } */
            return user;
          } else {
            return user;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        })
      );
  }

  validate(__user): Observable<RegularUser | ErrorMessage> {
    return this.http
      .post<RegularUser | ErrorMessage>(
        `${environment.baseUrl}RegularUsers/LoginFromStorage/`,
        __user
      )
      .pipe(
        map((user) => {
          if ((user as RegularUser).firstName) {
            var _user = user as RegularUser;
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('jwtToken', JSON.stringify(_user.token));
            this.anonAuthService.anonymousLogin();
            this.currentUserSubject.next(_user);
            this.currentLoginUserSubject.next(_user);

            return _user;
          } else {
            this.logout();
            var errorMsg = user as ErrorMessage;
            return errorMsg;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        })
      );
  }
  resetPasword(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/ResetPassword/`,
      user
    );
  }

  resetPasswordHackV2(regularUserId, password) {
    return this.http.get<any>(
      `${environment.baseUrl}RegularUsers/ResetPasswordHackV2/${regularUserId}/${password}`
    );
  }
  resetPasswordHack(user) {
    return this.http.post<RegularUser | ErrorMessage>(
      `${environment.baseUrl}RegularUsers/ResetPasswordHack/`,
      user
    );
  }
  public get currentUserValue(): RegularUser {
    return this.currentUserSubject.value;
  }
  public get currentLoginUserValue(): RegularUser {
    return this.currentLoginUserSubject.value;
  }
  logout(setAgentOffline = false) {
    var accessToken = localStorage.getItem('access_token');
    if (accessToken && accessToken !== null) {
      this.endSession().subscribe((res) => {});
    }
    if (setAgentOffline) this.setAgentOffline();
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('access_token');
    //  localStorage.removeItem('access_token_far');
    this.currentUserSubject.next(null);
    this.popupHandlerService.closeIncomeCallDialog();
  }

  setAgentOffline() {
    if (!this.currentUserValue.agent) return;
    var agentInteraction = new AgentInteraction();
    agentInteraction.agentId = this.currentUserValue.agent.agentId;
    agentInteraction.agentInteractionId = 0;
    agentInteraction.statusId = AgentStatusEnum.Offline;

    this.agentInteractionsService
      .post(agentInteraction)
      .subscribe((data) => {});
  }
  getUserFromResetParams(email, token) {
    return this.http.get<RegularUser>(
      `${environment.baseUrl}RegularUsers/getResetParmas/${email}/${token}`
    );
  }

  reportFarLoginAlive() {
    var accessToken = localStorage.getItem('jwtToken');
    //console.log('accessToken', accessToken);
    return this.http.post<any>(
      `${environment.baseUrl}RegularUsers/reportFarLoginAlive/`,
      { accessToken }
    );
  }

  setUserInteraction(agentId) {
    let agentInteraction = new AgentInteraction();
    agentInteraction.agentId = agentId;
    agentInteraction.agentInteractionId = 0;
    agentInteraction.statusId = AgentStatusEnum.Online;

    this.agentInteractionsService
      .post(agentInteraction)
      .subscribe((data) => {});
  }

  endSession() {
    var accessToken = localStorage.getItem('access_token');
    return this.http.get<any>(
      `${environment.baseUrl}RegularUsers/endSession/${accessToken}`
    );
  }

  getActiveRoleUsers(roleTypeId: number) {
    return this.http.get<RegularUserForTable[]>(
      `${environment.baseUrl}RegularUsers/TableForRole/${roleTypeId}`
    );
  }
}
