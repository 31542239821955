import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { Router } from '@angular/router';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { AdressTypeEnum } from 'src/app/Enums/StatusesEnum';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { plainToClass } from 'class-transformer';
import { RegularUser } from 'src/app/data/RegularUser';
import { FoodReportRequest } from 'src/app/data/food-genes/FoodReportRequest';

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css'],
})
export class RequestViewComponent implements OnInit {
  @Input() serviceRequest: ServiceRequest | FoodReportRequest;
  @Output('moveToEdit') moveToEdit: EventEmitter<any> = new EventEmitter();
  phoneSettings: PhoneVerificationSetting;

  constructor(
    private router: Router,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService
  ) {}

  ngOnInit(): void {
    var a = this.serviceRequest;
    //this.getPhoneSettings();
  }
  getPhoneSettings() {
    var address = (
      this.serviceRequest as ServiceRequest
    ).client.regularUser.getAdressObj(AdressTypeEnum.Main);
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
      });
  }
  getUserPhone() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;
    if (sr.serviceRequestId) {
      if (sr.client) {
        var regularUser = plainToClass(RegularUser, sr.client.regularUser);
      } else if (sr.product) {
        var regularUser = plainToClass(
          RegularUser,
          sr.product.patient.regularUser
        );
      }
      if (regularUser.getUserPhone()) {
        return regularUser.getUserPhone();
      }
    } else {
      return cr.clientPhone;
    }
  }
  moveToEditMode() {
    this.moveToEdit.emit(true);
  }
  getFullName() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;

    if (sr.serviceRequestId) {
      const { client, product } = sr;
      if (client) {
        return client.regularUser.firstName + ' ' + client.regularUser.lastName;
      } else if (product) {
        return (
          product.patient.regularUser.firstName +
          ' ' +
          product.patient.regularUser.lastName
        );
      }
    } else {
      return cr.clientFirstName + ' ' + cr.clientSecondName;
    }
  }
  getEmailAddress() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;

    if (sr.serviceRequestId) {
      return sr.clientId
        ? sr.client.regularUser.email
        : sr.product.patient.regularUser.email;
    } else {
      return cr.clientEmail;
    }
  }
  getRequestSubject() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;

    if (sr.serviceRequestId) {
      return sr.subject.name;
    } else {
      return cr.requestSubject.name;
    }
  }
  getRequestDesc() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;

    if (sr.serviceRequestId) {
      return sr.desc;
    } else {
      return cr.requestContent;
    }
  }
  goToClient() {
    if ((this.serviceRequest as ServiceRequest).clientId) {
      this.router.navigate(['/clients/edit/'], {
        queryParams: {
          clientId: (this.serviceRequest as ServiceRequest).clientId,
        },
      });
    } else {
      this.router.navigate(['/tests/edit/'], {
        queryParams: { productId: this.serviceRequest.productId },
      });
    }
  }
}
