<div class="title">Add snp</div>
<form
  *ngIf="newSnp && snpForm"
  class="snp-form"
  (ngSubmit)="onSubmit()"
  [formGroup]="snpForm"
>
  <div class="d-flex space-between mb-2">
    <div class="item">
      <div class="item__label">SNP number:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input type="number" matInput formControlName="snpNumber" />
      </mat-form-field>
    </div>
    <div class="item">
      <div class="item__label">Chromosome:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input matInput formControlName="chromosome" />
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex space-between mb-2">
    <div class="item">
      <div class="item__label">Coordinate:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input type="number" matInput formControlName="coordinate" />
      </mat-form-field>
    </div>
    <div class="item">
      <div class="item__label">Gene:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input matInput formControlName="gene" />
      </mat-form-field>
    </div>
  </div>

  <div class="mb-2">
    <div class="item__label">
      Allele: <span>{{ allels.join(",") }}</span>
    </div>
    <div class="d-flex space-between mb-2">
      <div class="item__select" *ngFor="let letter of lettrs">
        <mat-form-field appearance="fill">
          <mat-select [formControlName]="letter">
            <mat-option
              [disabled]="disableLetter(item)"
              *ngFor="let item of allels"
              [value]="item"
              >{{ item }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-icon
          *ngIf="hasFieldValue(letter) && !isFieldDisabled(letter)"
          matPrefix
          (click)="clearSelection(letter)"
          >remove_circle_outline</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="actions mt-2">
    <button class="save" mat-button type="submit">Save</button>
    <button class="cancel" mat-button mat-dialog-close type="button">
      Cancel
    </button>
  </div>
</form>
