export enum FoodGenesCatTypeEnum {
  All = 0,
  Food = 1,
  Recipe = 2,
  Restaurants = 3,
}
export enum ServiceRequestTabs {
  All = -1,
  Customer = 104,
  Finance = 105,
  Nutrition = 106,
  Manager = -1,
  AppReports = 107,
  AppCustomerRequests = 108,
}

export enum PaymentMethodEnum {
  CreditCard = 1,
  ManualCreditCard = 2,
  Cash = 3,
  Check = 4,
  WiredTransfer = 5,
  FreeOfCharge = 6,
}

export enum CreditTypeEnum {
  Regular = 1,
  Credit = 6,
  Payments = 8,
}

export const enum LeadStatusEnum {
  NewLead = 2,
  RenewedLead = 3,
  Attempted1 = 5,
  Attempted2 = 6,
  Attempted3 = 7,
  Attempted4 = 8,
  Attempted5 = 9,
  Attempted6 = 10,
  Attempted7 = 11,
  ContantCreated = 13,
  Relevant = 14,
  Active = 16,
  NotActive = 15,
  DidntAnswerSeveralTimes = 18,
  WrongNumber = 19,
  NotInterested = 20,
}

export const enum ProductEnum {
  NotRegistered = 6,
  WaitingtoPickUp = 7,
  LaboratoryProcess = 8,
  SendToCustomer = 81,
  SentToCustomer = 9,
  Missingdetails = 14,
  ArrivedToCostumer = 61,
  ArrivedToLab = 62,
  Completed = 63,
  Canceled = 125,
  RetestOnLab = 126,
  ResendToPatient = 127,
}

export const enum DeliveryTypeEnum {
  Delivery = 65,
  Pickup = 66,
}

export const enum DeliveryEnum {
  SenttoBar = 67,
  Completed = 68,
}

export const enum TubeTestEnum {
  SentToLab = 69,
  RecievedbyLab = 70,
  Completed = 71,
}

export enum DealEnum {
  Done = 3,
  WaitingForPayment = 4,
  Canceled = 5,
  Rejected = 50,
  WaitingForAproval = 88,
  CancelationCandidate = 134,
  Cancelation = 135,
  RejectedCancelation = 136,
  Uncompleted = 192,
  Upgrade = 7,
}

export const enum TaskEnum {
  Open = 10,
  OverDue = 11,
  Completed = 12,
  Canceled = 13,
}

export const enum RequestEnum {
  Open = 112,
  InProgress = 113,
  Done = 114,
  Closed = 115,
}

export const enum TubePackageEnum {
  Draft = 58,
  Final = 59,
  Closed = 60,
}
/*
export const enum RoleTypeEmum {
  Lead = 1,
  Client = 2,
  Manager = 3,
  Developer = 4,
  Agent = 5,
  AgentsManager = 6,
  Support = 7,
  SupportManager = 8,
  Finance = 9,
  Lab = 10,
  NotActive = 11,
  nutritionist = 12,
  nutritionistManager = 13,
  ConservationAgent = 14,
  nutritionistFromHome = 24,
  followUpAgent = 25,
}
*/
export enum UserRoleEmum {
  Lead = 1,
  Client = 2,
  Nutro = 3,
  NutroPlus = 4,
  Developer = 5,
  Manager = 6,

  Sales = 7,
  SalesManager = 8,
  SalesTeamLeader = 19,
  Support = 9,
  SupportManager = 10,
  Finance = 11,
  Lab = 12,
  SupportCalendarManager = 22,

  nutritionist = 13,
  nutritionistManager = 14,
  ConservationAgent = 15,

  followUpAgent = 16,
  NotActive = 18,

  RegulationAgent = 20,
}

export const enum FirstCallEnum {
  NotRequired = 1,
  Missing = 2,
  Done = 3,
}

export const enum CancellationDecisionEnum {
  Rejected = 148,
  Saved = 149,
  Upgraded = 150,
  SavedWithCoupon = 151,
  DowngradedWithCoupon = 152,
  SavedWithRefund = 153,
  DowngradedWithRefund = 154,
  ProductCanceled = 155,
  AllProductCanceled = 156,
}

export const enum CancellationRequestEnum {
  NotSaved = 224,
  NewRequest = 128,
  ManagerApprove = 129,
  RefundApprove = 130,
  CouponApprove = 131,
  Closed = 132,
  RefundClosed = 340,
  CouponClosed = 341,
}

export const enum NoteCategoryEnum {
  nutritionCall = 117,
  cancelation = 146,
  refund = 147,
}

export const enum PaymentMethod {
  CreditCard = 1,
  ManualCreditCard = 2,
  Cash = 3,
  Check = 4,
  WiredTransfer = 5,
}

export const enum ServiceRequestSubjectEnum {
  UserNameOrPassword = 107,
  CustomerService = 108,
  Logistics = 109,
  Nutrition = 110,
  Finance = 111,
}

export const enum NoteTypeEnum {
  Note = 1,
  Task = 2,
  NutritionCall = 3,
  Call = 4,
  Record = 6,
}

export const enum Permissions {
  ChatHatSupport = 57,
  ChatHatNutrition = 58,
  ChatHatLogistics = 59,
  ChatHatFinance = 60,
}

export const enum FoodNutrientsZameret {
  ahuz_ibud_nozlim = 464,
  alcohol = 465,
  cholesterol = 476,
  food_energy = 486,
  total_fat = 526,
  total_dietary_fiber = 525,
  trans_fatty_acids = 528,
  satured_fat = 517,
  total_sugars = 527,
  protein = 515,
  carbohydrates = 474,
  vitamin_b12 = 534,
  vitamin_b6 = 535,
  vitamin_c = 536,
  vitamin_d = 537,
  vitamin_e = 538,
  vitamin_k = 539,
  iron = 491,
  calcium = 470,
  zinc = 540,
  magnesium = 498,
  folate = 484,
  geneticRate = 541,
}
export const enum NutrientType {
  nutrients = 222,
  vitamins = 221,
  allergens = 231,
  hiddenNutrient = 223,
}
export const enum SourceKeys {
  zameret = 1,
  usda = 2,
  mygenes = 3,
}
export const enum MenuErrors {
  CompletedWithEmptyMeals = 1,
  CompletedWithEmptyBullets = 2,
  CompletedWithEmptyBulletOptions = 3,
  CompletedWithCaloriesOutOfRange = 4,
}
export const enum QuestionDesignType {
  header = 232,
  headerCenter = 238,
  checkboxBig = 233,
  inputText = 234,
  radioRegular = 235,
  nextbutton = 236,
  radioButtonBig = 237,
  successThumb = 241,
}
export const enum QuestionContainerDesignType {
  flex = 260,
  block = 261,
  langDirection = 262,
  center = 263,
  flexCenter = 264,
}
export const enum LifeStyleInputType {
  checkbox = 1,
  radio = 2,
  text = 3,
  slider = 4,
}
export const enum permissionModuleAndAction {
  Leads_Search = 'Leads_Search',
  Leads_GetList = 'Leads_GetList',
  Leads_GetSingle = 'Leads_GetSingle',
  Clients_Search = 'Clients_Search',
  Clients_GetList = 'Clients_GetList',
  Clients_GetSingle = 'Clients_GetSingle',
  Menu_GetList = 'Menu_GetList',
  Products_Search = 'Products_Search',
  Products_GetList = 'Products_GetList',
  Products_GetSingle = 'Products_GetSingle',
  Products_ChangeStatus = 'Products_ChangeStatus',
  Products_ChangeAgent = 'Products_ChangeAgent',
  Deals_GetList = 'Deals_GetList',
  Deals_Update = 'Deals_Update',
  ServiceRequests_GetList = 'ServiceRequests_GetList',
  Cancelations_GetList = 'Cancelations_GetList',
  Reviews_GetList = 'Reviews_GetList',
  Food_GetList = 'Food_GetList',
  Food_GetSingle = 'Food_GetSingle',
  Knowledge_GetList = 'Knowledge_GetList',
  AlgoBullets_GetList = 'AlgoBullets_GetList',
  AutoSender_GetList = 'AutoSender_GetList',
  EmailTemplates_GetList = 'EmailTemplates_GetList',
  Algorithm_GetList = 'Algorithm_GetList',
  GeneticReport_GetList = 'GeneticReport_GetList',
  DeliveriesOffice_GetList = 'DeliveriesOffice_GetList',
  DeliveriesLab_GetList = 'DeliveriesLab_GetList',
  Manage_GetList = 'Manage_GetList',
  Agents_GetList = 'Agents_GetList',
  HomePage_GetList = 'HomePage_GetList',
  BloodTest_GetList = 'BloodTest_GetList',
  ChatHour_GetList = 'ChatHour_GetList',
  ManageAgents_GetList = 'ManageAgents_GetList',
  TriggerEvents_GetList = 'TriggerEvents_GetList',
  Links_GetList = 'Links_GetList',
  MenuTemplates_GetList = 'MenuTemplates_GetList',
  Translation_GetList = 'Translation_GetList',
  Translation_Update = 'Translation_Update',
  Translation_Create = 'Translation_Create',
  Translation_GetSingle = 'Translation_GetSingle',
  Translation_Search = 'Translation_Search',
  Translation_Delete = 'Translation_Delete',
  FoodZameretData_GetList = 'FoodZameretData_GetList',
  GlobalParams_Update = 'GlobalParams_Update',
  GlobalParams_Create = 'GlobalParams_Create',
  FoodCategories_GetList = 'FoodCategories_GetList',
  FoodCategories_Update = 'FoodCategories_Update',
  FoodCategories_Create = 'FoodCategories_Create',
  FoodCategories_Search = 'FoodCategories_Search',
  FoodCategories_Delete = 'FoodCategories_Delete',
}
/*
export const enum permissionAction {
  AllLeads = 'All Leads',
  MyLeads = 'My Leads',
  AllClients = 'All Clients',
  MyClients = 'My Clients',
  AllPatients = 'All Patients',
  MyPatients = 'My Patients',
  AllTransactions = 'All Transactions',
  MyTransactions = 'My Transactions',
  ManageAgents = 'Manage Agents',
  ManagePannel = 'Manage Pannel',
  DebugPannel = 'Debug Pannel',
  Algorithm = 'Algorithm',
  AllDeliveriesLab = 'All Deliveries Lab',
  AllDeliveriesOffice = 'All Deliveries Office',
  Search = 'Search',
  ViewAlgorithm = 'View Algorithm',
  AgentsStats = 'Agents Stats',
  SuportRequestsCostumerservice = 'Suport Requests Costumer service',
  SuportRequestsCostumerserviceManager = 'Suport Requests Costumer service Manager',
  SuportRequestsFinance = 'Suport Requests Finance',
  SuportRequestsFinanceManager = 'Suport Requests  Finance Manager',
  SuportRequestsCostumerNutrition = 'Suport Requests Costumer Nutrition',
  SuportRequestsCostumerNutritionManager = 'Suport Requests Costumer Nutrition Manager',
  SuportRequestsAll = 'Suport Requests All',
  Home = 'Home',
  AsignNutritionist = 'Asign Nutritionist',
  ChangeProductStatus = 'Change Product Status',
  AllNutritionCalls = 'All Nutrition Calls',

  Cancelations = 'Cancelations',
  CancelationConservation = 'Cancelation Conservation',
  CancelationManager = 'Cancelation Manager',
  CancelationRefund = 'Cancelation Refund',
  CancelationCoupon = 'Cancelation Coupon',
  SearchLeads = 'Search Leads',
  SearchClients = 'Search Clients',
  SearchProducts = 'Search Products',
  canpurchaseforall = 'can purchase for all',
  OpenCancelationRequest = 'Open Cancelation Request',
  AlgorithmBullets = 'Algorithm Bullets',
  CancelationConservationMyClients = 'Cancelation Conservation My Clients',
  CancelationManagerMyClients = 'Cancelation Manager My Clients',
  CancelationRefundMyClients = 'Cancelation Refund My Clients',
  CancelationCouponMyClients = 'Cancelation Coupon My Clients',
  CancelationEditButton = 'Cancelation Edit Button',
  CallsReviews = 'Calls Reviews',
  ChatAlerts = 'Chat Alerts',
  CallsClient = 'Calls Client',
  CallsPatient = 'Calls Patient',
  CallSubjectSupportList = 'Call Subject Support List',
  CallSubjectNutriList = 'Call Subject Nutri List',
  ScriptsEdit = 'Scripts Edit',
  ScriptsView = 'Scripts View',

  Logistics = 'Logistics',
  LoginFromEverywhere = 'Login From Everywhere',
  LoginFromHomeSupport = 'Login From Home Support',
  LoginFromHomeSales = 'Login From Home Sales',

  ChatHatSupport = 'Chat Hat Support',
  ChatHatNutrition = 'Chat Hat Nutrition',
  ChatHatLogistics = 'Chat Hat Logistics',
  ChatHatFinance = 'Chat Hat Finance',
  SendInfoLeads = 'Send Info Leads',
  SendInfoClients = 'Send Info Clients',
  SendInfoTests = 'Send Info Tests',
  Autosender = 'Autosender',
  SendinfoEdit = 'Send info edit',
  TransactionsExport = 'Transactions Export',
  MenuTemplates = 'Menu Templates',
  MenuList = 'Menu List',
  ChatHours = 'Chat Hours',
  AlgoGroups = 'Algo Groups',
  CallsFollowUp = 'Calls Follow Up',
  Deliveriestest = 'Deliveries test',
  ServiceRequesttest = 'Service Request test',
  EditFood = 'Edit Food',
  MyReviews = 'My Reviews',
  ManageUsers = 'Manage Users',
  ManageRoles = 'Manage Roles',
}
*/
export const enum AdressTypeEnum {
  Main = 1,
  Shipping = 2,
  Billing = 3,
}

export enum FileUploadType {
  PatientNutritionReport = 1,
  PatientDnaReport = 2,
  LabProcessData = 3,
  NutrugeneticsConsent = 4,
  SampleDocs = 5,
  DealInovice = 6,
  PdfTemplatePage = 7,
  FoodImages = 8,
  FoodImagesUrl = 9,
  EmailTemplateAttachment = 10,
  PrivacyPolicy = 11,
  TermsOfCancellation = 12,
  TermsOfUse = 13,
  AlgoBulletImagesPath = 14,
  AlgoBulletImagesUrl = 15,
  NoteImagesPath = 16,
  NoteImagesUrl = 17,
  ExternalFoodData = 18,
  UserProfileImage = 19,
  UserProfileImageUrl = 20,
  UserWeightImagePath = 21,
  UserWeightImageUrl = 22,
  SubCatResultImages = 23,
  VodVideoStreamCloudFlair = 26,
  VideoPoster = 28,
  VodVideo = 42,
}
export enum ProductTypeEnum {
  NutriPlus = 1,
  NutriBasic = 2,
}

export const enum MeetingSettingTypeEnum {
  FirstCall = 305,
  FollowUp = 306,
  Other = 312,
}

export enum ExtensionGroupType {
  Sales = 1,
  CustomerService = 2,
}
export enum PayCallCallbackStatus {
  Ring = 1,
  Answer = 2,
  Hangup = 3,
  Dial = 4,
}
export enum AgentStatusEnum {
  Online = 329,
  Offline = 330,
  Break = 331,
}

export enum FoodWithRecipes {
  withoutRecipes = 0,
  withRecipes = 1,
  Both = 2,
}

export enum FoodPublished {
  NotPublished = 0,
  Published = 1,
  PublishedBoth = 2,
}
export const enum PurchaseChooseProductError {
  MissingProductType = 'please select product type for all Products',
  InvalidAmount = `Total Amount Invalid`,
}

export enum FileTypeEnum {
  AlgoCatSmallIcon = 1,
  AlgoCatBiglIcon = 2,
  AlgoSubCatSmallIcon = 3,
  AlgoSubCatBiglIcon = 4,
}

export enum AlgoImageType {
  AlgoCatSmallIcon = 1,
  AlgoCatBigIcon = 2,
  AlgoSubCatSmallIcon = 3,
  AlgoSubCatBigIcon = 4,
  PrototypeCatSmallIcon = 5,
  PrototypeCatBigIcon = 6,
  PrototypeSubCatSmallIcon = 7,
  PrototypeSubCatBigIcon = 8,
  AlgoResultTypePass = 9, //(required langId)
  AlgoPdfTemplate = 10,
  LabResult = 11,
  AlgoCatDnaReport = 12,
  AlgoCatPdfBorderBottom = 13,
  AlgoCatPdfBorderMiddle = 14,
  PrototypeCatDnaReport = 15,
  PrototypeCatPdfBorderBottom = 16,
  PrototypeCatPdfBorderMiddle = 17,
  AlgoSubCatPdfBorderTop = 18,
  PrototypeSubCatPdfBorderTop = 19,
  PrototypeResultTypePass = 20, //(required langId)
  AlgoPageTitleImage = 22,
  PrototypePageTitleImage = 23,
  AlgoAppCatIcon = 24,
  PrototypeAppCatIcon = 25,
  AlgoAppPageTitleImage = 26,
  PrototypeAppPageTitleImage = 27,
  VideoPoster = 28,
  ZoomImage = 29,
  FoodCategoryImage = 36,
  FoodGenesLabel = 32,
  FoodGenesTag = 30,
}

export enum PrototypeTabs {
  Category = 1,
  SubCategory = 2,
  ResultType = 3,
}

export enum AlgoMainBoardEnum {
  summary = 1,
  snp = 2,
  search = 3,
  category = 4,
  subCategory = 5,
  addCategory = 6,
}
export enum FoodGenesOwnerEnum {
  CoefficientType = 11,
  Coefficient = 16,
}
export enum FoodGenesBoardEnum {
  formula = 1,
  snp = 2,
  param = 3,
  coefficientType = 4,
  coefficient = 5,
  createParam = 6,
  history = 7,
  empty = 0,
}
export enum FoodGenesSidenavEnum {
  snp = 1,
  param = 2,
}

export enum FgParamTypeEnum {
  NutrientParam = 1,
  AlertParam = 2,
  StaticParam = 3,
  UserDnaResult = 4,
  MaxCoefficient = 5,
  FormulaResult = 6,
  AlertTag = 7,
  CumeDist = 8,
  BloodTest = 9,
  CoefficientMax = 10,
  CoefficientMin = 11,
  MinCoefficient = 12,
}
export enum EncodingEnum {
  Ascii = 1,
  Utf8 = 2,
  Windows1255 = 3,
}

export enum UploadErrorsEnum {
  MissingSnp = 'MissingSNP',
  ResultNoMatch = 'ResultNoMatch',
  MissingSubCatSnp = 'MissingSubCatSNP',
  NotInRange = 'NotInRange',
  NAValue = 'NAValue',
  UnknownError = 'UnknownError',
}

export enum GlobalParamsEnum {
  FarLoginSupportAdmin = 'FarLoginSupportAdmin',
}

export enum CoefficientTypeIdEnum {
  Base = 1,
  Secondary = 2,
  Alerts = 3,
}
export enum FormulaType {
  FormulaCommand = 1,
  AgregationCommand = 2,
  AssignmentCommand = 3,
  AlertCommand = 4,
  PersentCommand = 5,
  RangeCommand = 6,
}

export enum FgFormulaOwnerTypeEnum {
  Coefficient = 11,
  CoefficientType = 16,
}

export enum FgCalculatorFunctType {
  Parameter = 1,
  Operand = 2,
}

export enum ConditionType {
  Less = 1,
  LessEqual = 2,
  Great = 3,
  GreatEqual = 4,
  Equal = 5,
  NotEqual = 6,
}

export enum ConditionLogicType {
  And = 1,
  Or = 2,
}

export enum AggregatingType {
  Min = 1,
  Max = 2,
  Sum = 3,
}

export enum BarcodeStatusEnum {}
