import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TubeTest } from '../data/TubeTest';
import { TubeTestsService } from './tube-tests.service';
import { ErrorMessage } from '../data/ErrorMessage';
import { TubesPackagesService } from './tubes-packages.service';
import { TubesPackage } from '../data/TubesPackage';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class TubeTestsPusherService {
  private readonly tubeTest = new BehaviorSubject<any>([]);
  private readonly errors = new BehaviorSubject<ErrorMessage>(null);
  readonly tubeTest$ = this.tubeTest.asObservable();
  readonly errors$ = this.errors.asObservable();
  packageId: number;
  constructor(
    private tubeTestService: TubeTestsService,
    private tubesPackagesService: TubesPackagesService,
    private productsService: ProductsService
  ) {}

  getAll(packageId: number = -1) {
    if (packageId != -1) this.packageId = packageId;
    this.tubeTestService.getByPackage(this.packageId).subscribe((data) => {
      this.tubeTest.next(data);
    });
  }
  addTube(tubesPackageId: number, barcode: string) {
    this.tubeTestService.addTube(tubesPackageId, barcode).subscribe((res) => {
      if ((res as TubeTest).barcode) {
        this.getAll();
        this.errors.next(null);
      } else {
        var error = res as ErrorMessage;
        this.errors.next(error);
      }
    });
  }

  changeProductStatus(productId: number, statusId: number) {
    this.productsService
      .changeStatus(productId, statusId, true)
      .subscribe((data) => {
        this.getAll();
      });
  }
  removeTube(barcode) {
    this.tubeTestService.removeTube(barcode).subscribe((res) => {
      this.getAll();
    });
  }
  put(tubeTest: TubeTest) {
    this.tubeTestService.put(tubeTest).subscribe((res) => {
      this.getAll();
    });
  }
  closePackage(tubesPackage: TubesPackage) {
    this.tubesPackagesService.closePackage(tubesPackage).subscribe((res) => {
      this.getAll();
    });
  }
  /*
  markAsRecieved(tubeTest: TubeTest) {
    this.tubeTestService.markAsRecieved(tubeTest).subscribe((res) => {
      this.getAll();
    });
  }*/
}
