import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '../data/Note';
import { Globals } from '../globals';
import { ProductIsFor } from '../data/ProductIsFor';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductIsForService {
  productIsFors: any;
  constructor(private http: HttpClient) { }

  getAll(): Observable<ProductIsFor[]> {


    if (this.productIsFors){
      return of(this.productIsFors);  // return from cache
  } else
  {
      return this.http.get<ProductIsFor[]>(`${environment.baseUrl}ProductIsFors/`).pipe(map(res => (this.productIsFors=res)));
  }

   // return this.http.get<ProductIsFor[]>(`${environment.baseUrl}ProductIsFors/`);
  }
}
