import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AlgoUserSummary } from 'src/app/data/algorithm/AlgoUserSummary';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { CategoryForApp } from 'src/app/data/CategoryForApp';
import { Language } from 'src/app/data/Language';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { AlgoImageType, ProductTypeEnum } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-algo-categories',
  templateUrl: './algo-categories.component.html',
  styleUrls: ['./algo-categories.component.scss'],
})
export class AlgoCategoriesComponent implements OnInit {
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  //categories: CategoryForApp[];
  productId: any;
  patientName: string;
  isPremiumUser: boolean;
  remainingTime: number;
  barcode: string;
  langId: number = 1;
  categories: AlgoUserSummary[];
  patientId: any;
  regularUser: RegularUser;
  product: Product;

  constructor(
    private productsService: ProductsService,
    private algorithmService: AlgorithmService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.barcode = params['barcode'];
      this.productId = params['productId'];
      this.patientId = params['patientId'];
      this.getUserSummary();
      this.productsService.getById(this.productId).subscribe((data) => {
        if ((data as Product).productId) {
          this.product = data as Product;
          this.regularUser = this.product.patient.regularUser;
          var firstName = this.regularUser.firstName ?? '';
          var lastName = this.regularUser.lastName ?? '';
          this.patientName = firstName + ' ' + lastName;
          this.isPremiumUser =
            this.product.productTypeId == ProductTypeEnum.NutriPlus;
          const now = new Date();
          const nowMinus2 = now.setDate(now.getDate() - 2);
          const purchaseDate = new Date(
            this.product.upgragePurchaseDate
          ).getTime();
          const inUpgaredProccess =
            purchaseDate > 0 && purchaseDate >= nowMinus2;
          if (inUpgaredProccess) {
            this.remainingTime = this.getRemainingTime(
              this.product.upgragePurchaseDate
            );
          }
        }
      });
    });
  }
  isPlusAndComplete() {
    if (!this.product) return false;
    return (
      this.product.productTypeId == ProductTypeEnum.NutriPlus &&
      (this.product.upgragePurchaseDate == null ||
        this.isUpgradeLockDone(this.product.upgragePurchaseDate))
    );
  }
  isUpgradeLockDone(date: string) {
    var purchaseDate = Date.parse(date);
    var now = moment();
    var upgrdeUnlock = moment(purchaseDate).add(2, 'days');
    if (now > upgrdeUnlock) return true;
    return false;
  }
  getUserSummary() {
    this.algorithmService
      .getUserSummaryStatByCat(
        this.barcode,
        this.langId,
        AlgoImageType.AlgoCatDnaReport
      )
      .subscribe((data: AlgoUserSummary[]) => {
        if ((data as AlgoUserSummary[]).length > 0) {
          this.categories = data.map((x) => {
            x.summary = x.summary.sort((a, b) => {
              return a.orderBy - b.orderBy;
            });
            return x;
          });
        }
      });
  }
  onChangeLang(e: Language) {
    this.langId = e.languageId;
    this.getUserSummary();
  }
  getImage(image: AppImage) {
    const webPath = this.replaceWebPath(image?.webPath);
    return image ? `${webPath}/${image.fileName}` : '';
  }
  replaceWebPath(image: string) {
    return image?.replace('{picdomain}', environment.picsDomain);
  }
  getRemainingTime(upgradeDate: string) {
    let upgradeDateTime = new Date(upgradeDate);
    upgradeDateTime.setDate(upgradeDateTime.getDate() + 2);
    const upgradeDateTimeNum = new Date(upgradeDateTime).getTime();
    const now = new Date().getTime();
    const remainingTime = upgradeDateTimeNum - now;
    return Math.round(remainingTime / 36e5); // calculate remaining time (milliseconds) to hours
  }
  onEditClick() {
    this.onEdit.emit();
  }
  goToCategory(categoryId: number) {
    this.router.navigate(['tests/edit/single-category'], {
      queryParams: {
        productId: this.productId,
        patientId: this.patientId,
        barcode: this.barcode,
        categoryId: categoryId,
      },
    });
  }
  goBack() {
    this.router.navigate(['tests/edit'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
}
