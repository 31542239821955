<div
  class="paginatorWrapper"
  [class.sticky]="sticky"
  [class.min-width]="minWidth"
  [class.barcodes]="classAttr == 'barcodes'"
>
  <form
    [formGroup]="paginationForm"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <ng-container *ngIf="rating && reviews">
      <div *ngIf="isMainScreen">
        Average Rating:<span
          style="
            color: #119c99;
            font-size: 25px;
            position: relative;
            top: 2px;
            width: fit-content;
          "
        >
          &#9733;</span
        >
        {{ getRoundedRating(rating) }}
      </div>
    </ng-container>
    <span>
      <span *ngIf="displaySideEdit()" style="margin-right: 40px">
        With Selected:
        <span *ngIf="leadStatuses && agents" class="round-select">
          <select (change)="changeBulkAgent($event)">
            <option value="-1">Select Agent</option>
            <option value="{{ agent.agentId }}" *ngFor="let agent of agents">
              {{ agent.name }}
            </option>
          </select>
          &nbsp;&nbsp;
          <span class="round-select">
            <select (change)="changeBulkStatus($event)">
              <option value="-1">Select Status</option>
              <option
                *ngFor="let status of filterdStatuses"
                value="{{ status.leadStatusId }}"
                [ngStyle]="{
                  'background-color': status.color,
                  color: status.parentId ? '#FFFFFF' : '#000000'
                }"
              >
                {{ status.name }}
              </option>
            </select>
          </span>
        </span>
        <span
          *ngIf="
            userHasPermision(['Products_ChangeAgentBulk']) &&
            classAttr == 'productsAttr'
          "
        >
          <select (change)="changeBulkNutri($event)" style="margin-right: 2rem">
            <option value="-1">Select Nutitionist</option>
            <option value="{{ agent.agentId }}" *ngFor="let agent of agents">
              {{ agent.name }}
            </option>
          </select>
          <select (change)="changeBulkNutri($event, true)">
            <option value="-1">Select Follow up Agent</option>
            <option
              value="{{ agent.agentId }}"
              *ngFor="let agent of followUpAgents"
            >
              {{ agent.name }}
            </option>
          </select>
          <!--
          <button (click)="openDeleteDialog()" mat-stroked-button color="warn">
            Delete</button
          >&nbsp;&nbsp;
          <button (click)="exportSelected()" mat-stroked-button color="primary">
            Export
          </button>
        -->
        </span>
      </span>
      <span
        *ngIf="
          classAttr == 'leadsAttr' ||
          classAttr == 'productsAttr' ||
          classAttr == 'transAttr' ||
          classAttr == 'foods'
        "
        style="display: flex; align-items: center"
      >
        <div style="display: flex; gap: 1rem" *ngIf="classAttr == 'foods'">
          <app-food-categories-selection-panel
            *ngIf="foodCategories && getCheckedCount() > 0"
            [fromEditFoodComponent]="true"
            [fromPaginatorComponent]="true"
            [categories]="foodCategories"
            (selectCat)="onSelectCat($event)"
          ></app-food-categories-selection-panel>

          <button mat-button class="btn btn-fill mr-1" (click)="addNewFood()">
            Add new
          </button>
        </div>
        selected {{ getCheckedCount() }} from {{ metaData.totalCount }}
      </span>
    </span>
    <span>
      <button
        [disabled]="canGoToPrevPage()"
        mat-icon-button
        class="paginationBtn"
        (click)="gotoFirstPage()"
      >
        <mat-icon>first_page</mat-icon>
      </button>
      <button
        [disabled]="canGoToPrevPage()"
        mat-icon-button
        class="paginationBtn"
        (click)="gotoPrevPage()"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <input
        formControlName="pageNum"
        (change)="onPageSelect($event)"
        type="number"
        style="width: 30px; text-align: center"
        value="1"
      />
      <button
        [disabled]="!canGoToNextPage()"
        mat-icon-button
        class="paginationBtn"
        (click)="gotoNextPage()"
      >
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button
        [disabled]="!canGoToNextPage()"
        mat-icon-button
        class="paginationBtn"
        (click)="gotoLastPage()"
      >
        <mat-icon>last_page</mat-icon>
      </button>

      <select
        formControlName="itemsPerPage"
        (change)="onItemsPerPageChange($event)"
      >
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
      <span style="margin-left: 10px">items per page </span>
    </span>
    <span style="margin-left: 40px"
      >{{ getDisplayRange() }}&nbsp;from {{ metaData.totalCount }}</span
    >
  </form>
</div>
