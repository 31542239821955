<span class="example-list-section">
  <h2>
    Please check the actions you want to do with the new transition status
  </h2>
  <ul>
    <li *ngFor="let action of data.actionsList">
      <mat-checkbox [(ngModel)]="action.checked">
        <!--  (ngModelChange)="updateAllComplete()"-->
        {{ action.desc }}
      </mat-checkbox>
    </li>
  </ul>
  <div style="text-align: center;">
    <button mat-raised-button color="primary" (click)="closeAfterSelection()">
      Apply
    </button>
  </div>
</span>
