import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductsService } from './products.service';
import { ProductForClientMin } from '../data/ProductForClientMin';
import { Product } from '../data/Product';
import { ErrorMessage } from '../data/ErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class ProductsForClientPusherServiceService {
  private readonly clientProducts = new BehaviorSubject<ProductForClientMin[]>(
    []
  );
  private readonly errorMessage = new BehaviorSubject<ErrorMessage>(null);

  readonly clientProducts$ = this.clientProducts.asObservable();
  readonly errorMessage$ = this.errorMessage.asObservable();

  clientId: number;
  constructor(private productsService: ProductsService) {}
  getByClientId(clientId: number = null) {
    if (clientId) this.clientId = clientId;
    this.productsService.getByClientId(this.clientId).subscribe((data) => {
      this.clientProducts.next(data);
    });
  }
  changePatient(product: ProductForClientMin) {
    this.productsService.changePatient(product).subscribe((data) => {
      var a = data;
      if ((data as ProductForClientMin).productId) {
        //this.errorMessage.next(null);
        this.getByClientId();
      } else {
        var err: ErrorMessage = data as ErrorMessage;
        this.errorMessage.next(err);
        this.getByClientId();
      }
    });
  }
  put(product: Product) {
    this.productsService.put(product).subscribe((res) => {
      if ((res as Product).productId) {
        this.getByClientId();
        this.errorMessage.next(null);
      } else {
        var errorMessage = res as ErrorMessage;
        this.errorMessage.next(errorMessage);
      }
    });
  }
}
