import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSubCategoryResultType } from 'src/app/data/algorithm/AlgoSubCategoryResultType';
import { AlgoUserSummary } from 'src/app/data/algorithm/AlgoUserSummary';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { PrototypeResultType } from 'src/app/data/algorithm/PrototypeResultType';
import { SummaryByType } from 'src/app/data/algorithm/SummaryByType';
import { Language } from 'src/app/data/Language';
import { RegularUser } from 'src/app/data/RegularUser';
import { SubCategoryForApp } from 'src/app/data/SubCategoryForApp';
import { TubeTestResultForApp } from 'src/app/data/TubeTestResultForApp';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-category',
  templateUrl: './single-category.component.html',
  styleUrls: ['./single-category.component.scss'],
})
export class SingleCategoryComponent implements OnInit {
  productId: number;
  categoryId: number;
  patientId: number;
  barcode: string;
  langId: number = 1;
  results: SubCategoryForApp[];
  tubeTestResults: TubeTestResultForApp[];
  displayedColumns: string[] = ['geneName', 'snpNumber', 'result', 'info'];
  rankResults: SubCategoryForApp[];
  rankInx: number;
  resultTypes: SummaryByType[];
  activeType: SummaryByType;
  category: AlgoCategory;
  regularUser: RegularUser;
  userSummary: AlgoUserSummary;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private algorithmService: AlgorithmService,
    private regularUserService: RegularUsersService
  ) {}

  ngOnInit(): void {
    this.categoryId = +this.route.snapshot.queryParamMap.get('categoryId');
    this.productId = +this.route.snapshot.queryParamMap.get('productId');
    this.patientId = +this.route.snapshot.queryParamMap.get('patientId');
    this.barcode = this.route.snapshot.queryParamMap.get('barcode');

    this.getSingleCategory();
    // this.getResultTypes();
    //this.getCategory();
    this.getCurrentUser();
  }
  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
    });
  }
  canEdit() {
    return userHasPermisions(['Algorithm_Update'], this.regularUser);
  }
  getCategory() {
    if (this.categoryId == -1) return;
    this.algorithmService
      .getAlgoCategory(this.categoryId, this.langId)
      .subscribe((data) => {
        this.category = data;
      });
  }
  getSingleCategory() {
    this.algorithmService
      .getResultStatByBarcode(this.barcode, this.categoryId, this.langId)
      .subscribe((data) => {
        this.results = data.subCategoryForApp;
        this.tubeTestResults = data.tubeTestResultForApp;
        this.setRedLetters();
        this.getResultTypes();
      });
  }
  getResultTypes() {
    if (this.categoryId == -1) return;
    this.algorithmService
      .getUserSummaryStatByCat(
        this.barcode,
        this.langId,
        AlgoImageType.AlgoPageTitleImage,
        this.categoryId
      )
      .subscribe((data: AlgoUserSummary[]) => {
        this.userSummary = data[0];
        this.resultTypes = this.userSummary.summary;
        this.orderByAndSetColorsToType();
        this.setActiveType();
      });
  }
  setActiveType() {
    this.activeType = this.resultTypes
      .map((x) => {
        if (this.resultsCount(x.typeId) > 0) {
          return x;
        }
      })
      .filter((y) => y)[0];
  }
  orderByAndSetColorsToType() {
    this.resultTypes = this.resultTypes
      .map((x) => {
        let type = x;
        type.color = `#${x.color || '00000080'}`;
        return type;
      })
      .sort((a, b) => {
        return a.orderBy - b.orderBy;
      });
  }
  setRedLetters() {
    this.tubeTestResults = this.tubeTestResults.map((x) => {
      let snpResultSpl = x.snpResult.split('');
      snpResultSpl = snpResultSpl.map((y) => {
        if (y === x.redLetter) {
          y = `<span class='red'>${y}</span>`;
        }
        return y;
      });
      x.snpResult = snpResultSpl.join('');
      return x;
    });
  }
  resultsCount(resultId: number) {
    const result = this.results.filter((x) => x.order == resultId);
    return result.length;
  }
  onSelectType(type: SummaryByType) {
    this.activeType = type;
    //this.getResultByType();
  }
  getImage(images: AppImage[]) {
    if (images?.length > 0) {
      const image = images.find(
        (x) => x.fileType == AlgoImageType.AlgoCatDnaReport
      );
      if (image) {
        const webPath = this.replaceWebPath(image.webPath);
        return image ? `${webPath}/${image.fileName}` : '';
      }
    }
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  getBadgeElement(e) {
    console.log('e', e);
  }
  getContrastColor(bgColor: any) {
    let color = bgColor.replace('#', '');
    color = color.match(/.{2}/g).map((x) => {
      return parseInt(x, 16) > 128 ? '00' : 'ff';
    });
    return `#${color.join('')}`;
  }
  goBack() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        barcode: this.barcode,
        patientId: this.patientId,
        tabIndex: 0,
        edit: 'right',
      },
    });
  }
  blankify(html, removeTags: boolean = false) {
    var root = document.createElement('div'),
      fonts = root.getElementsByTagName('font');

    root.innerHTML = html;

    Array.prototype.forEach.call(fonts, function (e) {
      e.removeAttribute('size');
      e.removeAttribute('face');
    });

    if (removeTags) {
      return root.textContent || root.innerText || '';
    } else {
      return root.innerHTML;
    }
  }
  onChangeLang(e: Language) {
    this.langId = e.languageId;
    this.getSingleCategory();
  }
}
