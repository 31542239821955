import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NoteForMissionsMin } from 'src/app/data/NoteForMissionsMin';
import { NotesAgentPusherService } from 'src/app/Services/notes-agent-pusher.service';
//import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { TaskEnum } from '../../Enums/StatusesEnum';

@Component({
  selector: 'app-dashboard-activity-thumb',
  templateUrl: './dashboard-activity-thumb.component.html',
  styleUrls: ['./dashboard-activity-thumb.component.scss'],
})
export class DashboardActivityThumbComponent implements OnInit {
  @Output('activityDone') activityDone: EventEmitter<NoteForMissionsMin> =
    new EventEmitter();
  @Input() note: NoteForMissionsMin;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  constructor(
    private router: Router,
    private notesAgentPusherService: NotesAgentPusherService
  ) {}

  isDatePassed(dateStr) {
    var date = new Date(dateStr);
    var today = new Date();
    var isTrue = date.getTime() < today.getTime();
    return isTrue;
  }

  getLink(note) {
    var to = this.getRelated(note).toLocaleLowerCase() + '/edit';

    if (to === 'tests/edit') {
      this.router.navigate([to], {
        queryParams: { productId: note.productId },
      });
    } else {
      this.router.navigate([to], {
        queryParams: { clientId: note.clientId },
      });
    }
    /*
    let url = '';
    if (to === 'tests/edit') {
      url = this.router.serializeUrl(
        this.router.createUrlTree([to], {
          queryParams: { productId: note.productId },
        })
      );
    } else {
      url = this.router.serializeUrl(
        this.router.createUrlTree([to], {
          queryParams: { clientId: note.clientId },
        })
      );
    }
    window.open(url, '_blank');
    */
  }
  getName(note) {
    if (note.client.regularUser.lastName == null) {
      return note.client.regularUser.firstName;
    }
    return (
      note.client.regularUser.firstName + ' ' + note.client.regularUser.lastName
    );
  }
  getRelated(note: NoteForMissionsMin) {
    switch (note.relatedId) {
      case 1:
        return 'Leads';
      case 2:
        return 'Clients';
      case 3:
        return 'Tests';
    }
    /*
    if (note.patient !== null) {
     
    } else if (note.client !== null) {
      if (note.client.clientRoleId == 1) {
        return 'Leads';
      } else if (note.client.clientRoleId == 2) {
        return 'Clients';
      }
    }
    */
  }

  changeToDone() {
    //console.log('dashboard-activity-thumb', 'click');

    this.note.statusId = TaskEnum.Completed; //completed
    this.activityDone.emit(this.note);
    //this.notesAgentPusherService.changeStatusMin(this.note);
  }

  ngOnInit(): void {}
}
