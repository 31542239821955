import { AppModule } from './AppModule';
import { ModuleAction } from './ModuleAction';

export class ModuleToAction {
  moduleToActionId: number;
  appModuleId: number;
  appModule: AppModule;
  moduleActionId: number;
  moduleAction: ModuleAction;
  checked: boolean;
}
