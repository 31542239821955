import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CancelationRequest } from 'src/app/data/CancelationRequest';
import { Deal } from 'src/app/data/Deal';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { RegularUser } from 'src/app/data/RegularUser';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { DealsService } from 'src/app/Services/deals.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-details-dialog',
  templateUrl: './purchase-details-dialog.component.html',
  styleUrls: ['./purchase-details-dialog.component.css'],
})
export class PurchaseDetailsDialogComponent implements OnInit, OnChanges {
  deal: any;
  dealToCancel;
  regularUser: RegularUser;
  selectedTabInx: number = 0;
  showBtnGroup: boolean;
  numPayments: any;
  //
  constructor(
    public dialogRef: MatDialogRef<PurchaseDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private CanselationService: CancelationRequestService,
    private regularUsersService: RegularUsersService,
    private dealService: DealsService
  ) {}
  changeDealInx(inx) {
    this.selectedTabInx = inx;
    if (this.data.deal.reports && this.data.deal.reports.length > 0) {
      this.deal = this.data.deal.reports[this.selectedTabInx];
      this.numPayments = this.data.deal.deal.numPayments;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {}
  getAmount() {
    if (this.deal) {
      return this.deal.sum;
    }
  }
  get tranzillaTranscationReport() {
    return (
      this.deal.dealHistories &&
      this.deal.dealHistories[0] &&
      this.deal.dealHistories[0].tranzillaTransactionReport
    );
  }
  get createDate() {
    var createDateObj = new Date(Date.parse(this.deal.createDate));
    return createDateObj;
  }
  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });

    if (
      this.data !=
      null /* && this.data.deal.dealHistories.length > 0 // todo: check why we do this condition*/
    ) {
      this.changeDealInx(this.selectedTabInx);

      /*  var tranzillaTranscationReport: TranzillaTransactionReport =
        this.deal.dealHistories &&
        this.deal.dealHistories[0] &&
        this.deal.dealHistories[0].tranzillaTransactionReport;

      if (!tranzillaTranscationReport)
        tranzillaTranscationReport = new TranzillaTransactionReport(); */

      /*  var createDate = tranzillaTranscationReport.createDate;
      var createDateObj = new Date(Date.parse(createDate));
      var id = tranzillaTranscationReport.idnum;
      var CreditTypeId = tranzillaTranscationReport.creditTypeDesc;
      var status = tranzillaTranscationReport.bankResponseCode.name;
      var sugIska = tranzillaTranscationReport.transModeDesc;
      var Adress = tranzillaTranscationReport.adress;
      var company = tranzillaTranscationReport.companyName;
      var email = tranzillaTranscationReport.email;
      var phone = tranzillaTranscationReport.phone;
      var contact = tranzillaTranscationReport.contact;
      var isSuccess = tranzillaTranscationReport.isSuccess;
      var sum = tranzillaTranscationReport.sum;
      var creditCurrencyId = tranzillaTranscationReport.creditCurrencyId;

      var bankDocumentStatusId =
        tranzillaTranscationReport.bankDocumentStatusId;
      var aprovalNumber = tranzillaTranscationReport.aprovalNumber;
      var aprovalNumber = (tranzillaTranscationReport.creditCompany = '');

      var lastFourDigits = tranzillaTranscationReport.lastFourDigits;
      var expireDate = tranzillaTranscationReport.expireDate; */
    }
  }
  onClose() {
    this.dialogRef.close({ event: 'close', data: null });
  }
  getContinueDeal(deal: Deal) {
    this.dealService.getContinueDeal(deal.dealId).subscribe((deal: Deal) => {
      this.dialogRef.close({
        event: 'close',
        data: {
          action: deal,
          message: 'getContinueDeal',
        },
      });
    });
  }
  onOpenCancelationRequest() {
    this.CanselationService.createFromDeal(
      this.data.deal.deal.dealBundle.dealBundleId
    ).subscribe((_data: any) => {
      if ((_data as CancelationRequest).cancelationRequestId) {
        this.dialogRef.close({
          event: 'close',
          data: { action: 'openCancelationRequest' },
        });
        this.router.navigate(['cancelations/new'], {
          queryParams: { cancelRequestId: _data.cancelationRequestId },
        });
      } else {
        var error = _data as ErrorMessage;
        var action = 'showDuplicateRequestAlert';
        if (error.message == 'Deal Has Reached Maximum refund')
          action = 'DealHasReachedMaximumrefund';
        if (error.message == 'Deal Has No Products Left')
          action = 'DealHasNoProductsLeft';
        this.dialogRef.close({
          event: 'close',
          data: {
            action: action,
            message: error.message,
          },
        });
      }
    });
    /*  this.dealToCancel = this.data.deal;
    var cancelationRequest = new CancelationRequest();
    cancelationRequest.cancelationRequestId = 123;
    cancelationRequest.originalDealId = this.dealToCancel.dealId;
    cancelationRequest.originalDeal = this.dealToCancel;
    cancelationRequest.cancelDealId = 1234;
    cancelationRequest.createDate = Date.now().toLocaleString();
    cancelationRequest.amount = this.dealToCancel.amount;
    cancelationRequest.creditStatusId = 128;
    cancelationRequest.requestReasonId = 135;
    cancelationRequest.reducedFromAgent = true; */
  }
  hasPermisions(perms) {
    return userHasPermisions(perms, this.regularUser);
  }
  get environment() {
    return environment;
  }
}
