import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { HeadFilter } from '../data/HeadFilter';
import { DealsService } from './deals.service';
import { DealForTableMin } from '../data/DealForTableMin';
import { PopupHandlerService } from './popup-handler.service';
import { DealsSummery } from '../data/DealsSummery';
import { DisapearingTextDialogEnum } from '../Enums/DisapearingTextDialogEnum';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DealsPusherService {
  public onCancelResponse = new Subject<any>();
  private readonly deals = new BehaviorSubject<any>([]);
  readonly deals$ = this.deals.asObservable();

  private readonly dealsSummery = new BehaviorSubject<DealsSummery>(null);
  readonly dealsSummery$ = this.dealsSummery.asObservable();

  constructor(
    private dealsService: DealsService,
    private popupHandler: PopupHandlerService,
    private datePipe: DatePipe
  ) {}
  headFilter: HeadFilter;

  getAll(headFilter: HeadFilter = null) {
    if (headFilter) this.headFilter = headFilter;
    this.dealsService
      .getAllByFilterV2(headFilter)
      .subscribe((data) => this.deals.next(data));
    this.dealsService.getSummery(headFilter).subscribe((data) => {
      this.dealsSummery.next(data);
    });
  }

  getAllV2(headFilter: HeadFilter = null) {
    if (headFilter) this.headFilter = headFilter;

    this.dealsService
      .getAllByFilterV2(headFilter)
      .subscribe((data) => this.deals.next(data));
    this.dealsService.getSummery(headFilter).subscribe((data) => {
      this.dealsSummery.next(data);
    });
  }
  cancel(deal: DealForTableMin) {
    return this.dealsService.cancel(deal).subscribe(
      (data) => {
        this.onCancelResponse.next();

        var isOk = data.bankResponseCode;
        this.popupHandler.openDisapearingAlertDialog(
          isOk
            ? DisapearingTextDialogEnum.cancelSuccess
            : DisapearingTextDialogEnum.cancelRejected
        );
        this.getAll(this.headFilter);
      },
      (error) => {
        this.popupHandler.openDisapearingAlertDialog('purchaseRejected');
      }
    );
  }
  changeStatus(deal: DealForTableMin) {
    return this.dealsService.changeStatus(deal).subscribe((data) => {
      this.getAll(this.headFilter);
    });
  }
}
