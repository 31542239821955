<div class="title">Template name</div>
<mat-form-field class="w-100" appearance="fill">
  <input matInput [(ngModel)]="templateName" required />
</mat-form-field>
<div class="d-flex-center">
  <button mat-button (click)="save()" class="btn btn-fill">save</button>
  <button mat-button (click)="dialogRef.close()" class="btn btn-outline ml-2">
    close
  </button>
</div>
