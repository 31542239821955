<div *ngIf="isLoading" class="loadingOverlay">
  <mat-spinner diameter="250"></mat-spinner>
</div>
<div class="office">
  <ng-container *ngIf="tubePackage">
    <div class="wrapper-bg">
      <div class="wrapper">
        <div class="d-flex-between mb-4">
          <div class="summ d-flex-center">
            <div class="summ__item">
              <span>Create Date:</span>
              {{ tubePackage.createDate | date: "dd-MM-yyyy" }}
            </div>
            <div class="summ__item">
              <span>Serial Number:</span> {{ tubePackage.tubesPackageId }}
            </div>
            <div class="summ__item">
              <span>Status:</span> {{ tubePackage.status.name }}
            </div>
          </div>

          <button
            style="margin-left: auto"
            *ngIf="showClosePackageBtn()"
            class="closeCrt"
            (click)="closePackage()"
          >
            Close
          </button>
          <button
            style="margin-left: 20px"
            class="closeCrt"
            (click)="backToAll()"
          >
            Total shipping certificate
          </button>
        </div>
        <div class="title" style="display: flex">
          <span *ngIf="packageTubes && packageTubes.length < this.packageLimit">
            Please type or scan the barcode on the tube for delivery.</span
          >
          <span
            *ngIf="packageTubes && packageTubes.length >= this.packageLimit"
          >
            number of Tubes In Packacge Exedeed Limit of {{ this.packageLimit }}
          </span>
          <span style="margin-left: auto">Arrived To Lab</span>
          <input [(ngModel)]="arrivedToLab" type="date" /><button
            (click)="changeArivedToLab()"
          >
            save
          </button>
        </div>

        <div class="barcode">
          <ng-container
            *ngIf="packageTubes && packageTubes.length < this.packageLimit"
          >
            <span>Insert barcode number:</span>
            <mat-form-field
              appearance="fill"
              class="example-full-width classThisOne"
            >
              <input
                class="edit-field barcode-no"
                type="text"
                aria-label="Number"
                matInput
                [(ngModel)]="newBarcode"
                [matAutocomplete]="auto"
                placeholder="N.O"
              />
              <!-- placeholder="barcode" -->
              <mat-autocomplete #auto="matAutocomplete">
                <!-- (keyup)="onCitiesAutocompleteChanged($event)"-->
                <!--  (optionSelected)="onCitySelected($event)"-->
                <mat-option *ngFor="let barcode of barcodes" [value]="barcode">
                  {{ barcode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- <input [(ngModel)]="newBarcode" />-->
            <button (click)="addTube()">Add</button>
          </ng-container>
          <button (click)="sendLifestyleReportMail()">
            Send lifesyle Report Mail
          </button>
          <button
            (click)="sendSelectedTubesResults()"
            *ngIf="selectedTubes?.length > 0"
            style="float: right"
          >
            Send results
          </button>
        </div>
        <div style="color: red; font-size: 20px" *ngIf="errorMessage">
          {{ errorMessage.message }}
        </div>
      </div>
    </div>
    <div class="clearFix"></div>
    <div
      *ngIf="packageTubes && packageTubes.length > 0"
      style="margin-bottom: 70px"
    >
      <app-package-tubes-table
        [type]="'office'"
        [packageTubes]="packageTubes"
        [nutrisionists]="nutritionists"
        (tubeSelected)="onTubeSelected($event)"
        [sendComplete]="sendComplete"
      >
      </app-package-tubes-table>
    </div>
  </ng-container>
  <!--
  <app-table-paginator
    *ngIf="packageTubes && metaData"
    [headFilter]="headFilter"
    [metaData]="metaData"
    (filterChanged)="getAllWithFilter($event, false, true)"
    [classAttr]="'leadsAttr'"
  >
  </app-table-paginator>
--></div>
