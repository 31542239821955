import { Component, OnInit } from '@angular/core';
import { ProductForTableMin } from 'src/app/data/ProductForTableMin';
import { ProductsService } from 'src/app/Services/products.service';

@Component({
  selector: 'app-test-statuses',
  templateUrl: './test-statuses.component.html',
  styleUrls: ['./test-statuses.component.css'],
})
export class TestStatusesComponent implements OnInit {
  displayedColumns: string[] = [
    'TubeId',
    'RegDate',
    'PatientName',
    'ChangeDate',
  ];
  products: ProductForTableMin[];
  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    this.productsService.GetStatusChange().subscribe((x) => {
      this.products = x;
      var a = this.products[0];
      var a1 = a.patientName;
    });
  }
}
