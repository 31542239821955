<button
  [matMenuTriggerFor]="menuValues"
  class="footer__calories__button"
  mat-button
>
  Nutritional values
</button>
<mat-menu #menuValues="matMenu" class="footer__calories__menu">
  <div class="d-flex">
    <div class="footer__calories__menu__values">
      <div class="footer__calories__menu__values__title">Menu Values</div>
      <div
        class="footer__calories__menu__values__row"
        *ngFor="let nutient of objNutrients"
      >
        <span>{{
          nutient.foodNutrient &&
            nutient.foodNutrient.foodNutrientLangs &&
            nutient.foodNutrient.foodNutrientLangs.length > 1 &&
            nutient.foodNutrient.foodNutrientLangs[1].name
        }}</span>
        {{ nutient.amount | number: "1.0-0" }}
      </div>
    </div>
    <div class="footer__calories__menu__pie">
      <app-menu-calories-bar
        [inEdit]="true"
        [data]="getBarData()"
      ></app-menu-calories-bar>
      <!-- <ngx-charts-pie-chart
        [view]="[100, 100]"
        [animations]="false"
        [scheme]="colorScheme"
        [results]="paiData[0]"
        [doughnut]="true"
        style="margin: -25px auto 0; display: table"
      >
      </ngx-charts-pie-chart>
      <div class="footer__calories__menu__pie__title">Menu calories:</div>
      <div class="footer__calories__menu__pie__calories">
        <span [ngStyle]="{ color: objCalories && objCalories.color }">
          {{ objCalories && objCalories.value | number: "1.0-0" }}</span
        >
        /
        <span [ngStyle]="{ color: objGoalCalories && objGoalCalories.color }">{{
          objGoalCalories && objGoalCalories.value | number: "1.0-0"
        }}</span>
        Cal
      </div> 
      <div
        class="footer__calories__menu__pie__error"
        *ngIf="!isMenuCaloriesInRange()"
      >
        The calorie difference is high or low from the target consumption.
        Please correct the menu
      </div>-->
    </div>
  </div>
  <div class="footer__calories__menu__vitemins">
    <div class="footer__calories__menu__vitemins__title">
      Vitamins & Minerals
    </div>
    <div
      class="footer__calories__menu__vitemins__row"
      *ngFor="let vitemin of objVitamins"
    >
      <span>{{
        vitemin.foodNutrient &&
          vitemin.foodNutrient.foodNutrientLangs &&
          vitemin.foodNutrient.foodNutrientLangs.length > 1 &&
          vitemin.foodNutrient.foodNutrientLangs[1].name
      }}</span>
      {{ vitemin.amount | number: "1.0-0" }}
    </div>
  </div>
</mat-menu>
