<mat-dialog-content class="mat-typography">
  <form *ngIf="zoomEventForm" class="form" [formGroup]="zoomEventForm">
    <div class="title">Zoom event</div>
    <div class="item">
      <div class="item__label">image:</div>
      <app-algo-image
        class="item__field"
        [langId]="langId"
        [algoImageType]="AlgoImageType.ZoomImage"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage()"
        [checkedImage]="getCheckedImage()"
      ></app-algo-image>
    </div>
    <div class="item">
      <div class="item__label">Name:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input matInput formControlName="eventName" />
      </mat-form-field>
    </div>
    <div class="item">
      <div class="item__label">Description:</div>
      <mat-form-field class="item__field" appearance="fill">
        <textarea
          matTextareaAutosize
          matAutosizeMinRows="4"
          formControlName="eventDesc"
          matInput
        ></textarea>
      </mat-form-field>
    </div>
    <div class="item">
      <div class="item__label">Link:</div>
      <mat-form-field class="item__field" appearance="fill">
        <input matInput formControlName="eventLink" />
      </mat-form-field>
    </div>
    <div class="item">
      <div class="item__label">Date:</div>
      <mat-form-field class="item__field" color="accent" appearance="fill">
        <input
          formControlName="eventDate"
          required
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="actions">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      [disabled]="!zoomEvent"
      class="save"
      (click)="onSubmit(zoomEventForm)"
      mat-button
      type="submit"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
