import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { PrototypeSnpByVer } from 'src/app/data/algorithm/PrototypeSnpByVer';
import { FgCalculatorFunctions } from 'src/app/data/food-genes/FgCalculatorFunctions';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientSnp } from 'src/app/data/food-genes/FgCoefficientSnp';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { PrototypeParam } from 'src/app/data/food-genes/PrototypeParam';
import { Language } from 'src/app/data/Language';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  CoefficientTypeIdEnum,
  FoodGenesBoardEnum,
  FoodGenesSidenavEnum,
} from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-food-genes-wrapper',
  templateUrl: './food-genes-wrapper.component.html',
  styleUrls: ['./food-genes-wrapper.component.scss'],
})
export class FoodGenesWrapperComponent implements OnInit {
  @ViewChild('mainNav') mainNav: MatSidenav;
  @ViewChild('snpNav') snpNav: MatSidenav;
  languages: Language[];
  langId: number = 2;
  labId: number = 0;
  versionId: number = 1;
  versions: FgVersion[];
  regularUser: RegularUser;
  canEdit: boolean;
  mainBoardOpened: boolean;
  mainBoardMode: any;
  coefficiernts: FgCoefficientType[];
  baseCoefficients: FgCoefficient[] = [];
  selectedCoeffType: FgCoefficientType;
  selectedCoeff: FgCoefficient;
  indexExpanded: number;
  paramsType: FgParamType[];
  coeffSnps: FgCoefficientSnp[];
  availableCoeffSnps: PrototypeSnpByVer[];
  selectedSnp: FgCoefficientSnp;
  selectedParamType: FgParamType;
  selectedParam: FgParam;
  params: FgParam[];
  sidenavMode: FoodGenesSidenavEnum;
  availableParams: PrototypeParam[];
  formulas: FgFormula[];
  calcFunctions: FgCalculatorFunctions[];
  constructor(
    private languagesService: LanguagesService,
    private algorithmService: AlgorithmService,
    private regularUserService: RegularUsersService,
    private _snackBar: MatSnackBar,
    private foodGenesService: FoodGenesService
  ) {}

  ngOnInit(): void {
    this.getLanguages();
    this.getLabWithVersions();
    this.getCurrentUser();
    this.getCoefficients();
    this.getParamsType();
    this.getCalcFunctions();

    this.foodGenesService.loadCoefficients$.subscribe((data) => {
      if (data) {
        this.getCoefficients();
      }
    });
    this.foodGenesService.mainBoardState$.subscribe((data) => {
      this.mainBoardOpened = data;
    });
  }

  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
      this.canEdit = this.userHasPermission();
    });
  }
  getCalcFunctions() {
    this.foodGenesService.getCalcFunctions().subscribe((data) => {
      this.calcFunctions = data;
    });
  }

  getCoefficients() {
    this.foodGenesService
      .getCoefficientTypesWithCoefficients(this.versionId)
      .subscribe((data) => {
        this.coefficiernts = data;
        [...this.coefficiernts]
          .filter((x) => x.fgCoefficientTypeId !== CoefficientTypeIdEnum.Alerts)
          .map((x) => {
            x.coefficients.map((y) => {
              this.baseCoefficients.push(y);
            });
          });
      });
  }
  getParamsType() {
    this.foodGenesService
      .getParamTypesWithParams(this.versionId)
      .subscribe((data) => {
        this.paramsType = data;
        if (this.selectedParamType) {
          this.params = this.paramsType.find(
            (x) => x.fgParamTypeId == this.selectedParamType.fgParamTypeId
          ).params;
        }
      });
  }
  userHasPermission() {
    return userHasPermisions(
      ['FoodGenes_Create', 'FoodGenes_Update', 'FoodGenes_Delete'],
      this.regularUser
    );
  }
  userCanSearch() {
    return userHasPermisions(['Algorithm_Search'], this.regularUser);
  }
  dir(): 'rtl' | 'ltr' {
    return this.languages?.find((x) => x.languageId == this.langId).isRtl
      ? 'rtl'
      : 'ltr';
  }
  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data.filter((lang) => lang.languageId !== 3);
    });
  }
  getLabWithVersions() {
    this.foodGenesService.getFgVersions(this.langId).subscribe((data) => {
      this.versions = data;
      const versions = this.versions.filter(
        (x) => x.fgVersionId !== this.versionId
      );
      this.foodGenesService.versions$.next(versions);
      /*         data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.versions.push(y);
          });
        }); */
    });
  }
  getCoeffSnps(coeffId: number) {
    if (!coeffId) return;
    this.foodGenesService.getSnpByCoefficient(coeffId).subscribe((data) => {
      this.coeffSnps = data;
    });
  }

  getFormula() {
    if (!this.selectedCoeffType) return;
    this.foodGenesService
      .getFormulaByOwnerCoefficient(
        this.versionId,
        this.selectedCoeff.fgCoefficientId
      )
      .subscribe((data) => {
        this.formulas = data;
        this.foodGenesService.formulas$.next(data);
        this.foodGenesService.formula$.next(this.formulas[0]);
        console.log('formulas next getFormula');
      });
  }
  getAvailableParams() {
    if (!this.selectedParamType) return;
    this.foodGenesService
      .getParamAvailableByVersion(
        this.versionId,
        this.selectedParamType.fgParamTypeId
      )
      .subscribe((data) => {
        this.availableParams = data;
        console.log('availableParams', this.availableParams);
      });
  }
  onSelectSnp(snp: FgCoefficientSnp) {
    if (snp) {
      this.selectedSnp = snp;
      this.mainBoardMode = FoodGenesBoardEnum.snp;
      this.mainBoardOpened = true;
      //this.subCatResultTypes = null;
      //this.getSubCategorySnp(this.selectedSnp.algoSnpNum);
    } else {
      //this.algoSubCategorySnp = null;
    }
  }
  onSelectParam(param: FgParam) {
    console.log('param', param);

    this.selectedParam = param;
    this.mainBoardMode = FoodGenesBoardEnum.param;
    this.mainBoardOpened = true;
    //this.snpNav.open();
  }
  onSelectFormula(event: boolean) {
    if (event) {
      this.mainBoardMode = FoodGenesBoardEnum.formula;
      this.mainBoardOpened = true;
      if (this.selectedCoeff) {
        this.getFormula();
        console.log('onSelectFormula getFormula');

        //this.getSubCatResultTypes('onSelectSummary');
      }
    }
  }
  onLoadSnps(event: boolean) {
    if (event) {
      this.getCoeffSnps(this.selectedCoeff.fgCoefficientId);
    }
  }
  onLoadParams(event: boolean) {
    if (event) {
      this.getParamsType();
    }
  }
  //#region Event emmiters
  onSelected(selected: any) {
    if (!selected) return;
    console.log('onSelected', selected);

    const { coeff, subCoeff, isFormulaSelected, param } = selected;
    this.selectedCoeff = subCoeff;
    this.selectedCoeffType = coeff;
    this.selectedParamType = param;
    this.params = param?.params;
    this.selectedSnp = null;
    this.mainBoardOpened = false;
    if (this.selectedParamType) {
      this.sidenavMode = FoodGenesSidenavEnum.param;
      this.mainBoardMode = FoodGenesBoardEnum.param;
      this.selectedParam = this.params && this.params[0];
      this.getAvailableParams();
    } else if (this.selectedCoeff) {
      this.sidenavMode = FoodGenesSidenavEnum.snp;
      this.getCoeffSnps(this.selectedCoeff.fgCoefficientId);
      //this.getSubCatResultTypes();
      if (isFormulaSelected) {
        this.onSelectFormula(true);
      }
    }
  }
  onCloseMainBoard(event: boolean) {
    if (event) {
      this.mainBoardOpened = false;
    }
  }
  openMainBoard({ category, subCategory, mode }) {
    this.selectedCoeffType = category || this.selectedCoeffType;
    this.selectedCoeff = subCategory;
    this.mainBoardMode = mode;
    this.mainBoardOpened = true;
    const boardMode: FoodGenesBoardEnum = mode;
    /* if (
      boardMode == AlgoMainBoardEnum.addCategory ||
      boardMode == AlgoMainBoardEnum.category ||
      boardMode == AlgoMainBoardEnum.subCategory
    ) {
      this.snpNav.close();
    } */
  }
  onChangeLanguage(langId: number) {
    if (langId == this.langId) return;
    this.langId = langId;
    /* this.getCatsWithSubCats();
    this.getLabWithVersions();
    if (this.selectedSubCategory) {
      this.getSubCatResultTypes('onChangeLanguage');
    }
    if (this.mainBoardMode === AlgoMainBoardEnum.snp) {
      this.getSubCategorySnp(this.selectedSnp.algoSnpNum);
    } */
  }
  onSearchSnp(event: string) {
    if (event.length > 0) {
      this.algorithmService
        .searchSnps(event, this.versionId, this.langId)
        .subscribe(
          (data) => {
            if (data.length > 0) {
              // this.snpResults = data;
              this.mainBoardOpened = true;
              //this.mainBoardMode = AlgoMainBoardEnum.search;
              // this.algoSnps = null;
              // this.selectedCategory = null;
              // this.selectedSubCategory = null;
              // this.algoSubCategorySnp = null;
              this.snpNav.close();
            } else {
              this._snackBar
                .open('No results found', 'Close', {})
                ._dismissAfter(3000);
            }
          },
          (error) => {
            this._snackBar
              .open('No results found', 'Close', {})
              ._dismissAfter(3000);
          }
        );
    }
  }
  onChangeVersion(event: string) {
    this.versionId = +event;
    this.getCoefficients();
    this.getParamsType();
    this.snpNav.close();
    this.mainBoardMode = null;
    //this.mainBoardOpened = false;
  }
  onLoadCoeffs(event: boolean) {
    if (event) {
      console.log('onLoadCategories');
      this.getCoefficients();
    }
  }
  //#endregion
}
