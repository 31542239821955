<perfect-scrollbar style="height: calc(100vh - 66px)">
  <div class="container">
    <div [ngSwitch]="mode">
      <div *ngSwitchCase="FoodGenesBoardEnum.empty"></div>
      <div *ngSwitchCase="FoodGenesBoardEnum.coefficientType">
        <app-food-genes-formula-board
          [versionId]="versionId"
          [coeff]="coeff"
          [coeffType]="coeffType"
          [langId]="langId"
          [canEdit]="canEdit"
          [ownerType]="FgFormulaOwnerTypeEnum.CoefficientType"
        ></app-food-genes-formula-board>
      </div>
      <div *ngSwitchCase="FoodGenesBoardEnum.coefficient">
        <app-food-genes-coefficient-board
          [coeffType]="coeffType"
          [coeff]="coeff"
          [langId]="langId"
          [canEdit]="canEdit"
          [versionId]="versionId"
        ></app-food-genes-coefficient-board>
      </div>
      <div *ngSwitchCase="FoodGenesBoardEnum.snp">
        <app-food-genes-snp-board
          [snp]="snp"
          [langId]="langId"
          [canEdit]="canEdit"
          [versionId]="versionId"
        ></app-food-genes-snp-board>
      </div>
      <div *ngSwitchCase="FoodGenesBoardEnum.formula">
        <app-food-genes-formula-board
          [calcFunctions]="calcFunctions"
          [versionId]="versionId"
          [coeff]="coeff"
          [coeffType]="coeffType"
          [langId]="langId"
          [canEdit]="canEdit"
          [ownerType]="FgFormulaOwnerTypeEnum.Coefficient"
        ></app-food-genes-formula-board>
      </div>
      <div *ngSwitchCase="FoodGenesBoardEnum.param">
        <app-food-genes-params
          [versionId]="versionId"
          [param]="param"
          [langId]="langId"
          [canEdit]="canEdit"
          [coefficients]="baseCoeff"
        ></app-food-genes-params>
      </div>
      <div *ngSwitchCase="FoodGenesBoardEnum.history">
        <app-food-genes-history
          [versionId]="versionId"
          [langId]="langId"
        ></app-food-genes-history>
      </div>
    </div>
  </div>
</perfect-scrollbar>
