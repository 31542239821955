import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AgentMin } from 'src/app/data/AgentMin';
import { ClientRequestNote } from 'src/app/data/food-genes/ClientRequestNote';
import { FoodReportRequest } from 'src/app/data/food-genes/FoodReportRequest';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { ServiceRequestNote } from 'src/app/data/ServiceRequestNote';
import { Status } from 'src/app/data/Status';
import { FIREBASE_REFERENCES } from 'src/app/firebase.module';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { AgentsService } from 'src/app/Services/agents.service';
import { FoodClientRequestService } from 'src/app/Services/food-client-request.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestNotesService } from 'src/app/Services/service-request-notes.service';
import { ServiceRequestsPusherService } from 'src/app/Services/service-requests-pusher.service';
import { ServiceRequestsService } from 'src/app/Services/service-requests.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { StorageService } from 'src/app/Services/storage.service';
import { replaceWebPath } from 'src/app/Utils/files-helper';

@Component({
  selector: 'app-edit-service-request',
  templateUrl: './edit-service-request.component.html',
  styleUrls: ['./edit-service-request.component.css'],
})
export class EditServiceRequestComponent implements OnInit {
  watchedNotesIds: any;
  chatRoom: any;
  isAppReport: boolean;
  langId: number = 1;
  agentUser: RegularUser;
  noteToEdit;
  isNoteEditMode: boolean = false;
  serviceRequestId: any;
  serviceRequest: ServiceRequest | FoodReportRequest;
  isEditMode: boolean = false;
  agents: AgentMin[];
  statuses: Status[];
  requestsStatuses;
  commentForm: FormGroup;
  isCommentEditMode: boolean = false;
  serviceRequestNotes: NoteForCallMin[] | ClientRequestNote[];
  serviceRequestComments: any;
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private serviceRequestsService: ServiceRequestsService,
    private foodClientReqeustService: FoodClientRequestService,
    private serviceRequestsPusherService: ServiceRequestsPusherService,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    public dialog: MatDialog,
    private serviceRequestNotesService: ServiceRequestNotesService,
    private notesPusherService: NotesPusherService,
    private regularUsersService: RegularUsersService,
    private storageService: StorageService,
    @Inject(FIREBASE_REFERENCES.CHAT_FIRESTORE)
    private firestore: AngularFirestore
  ) {}
  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
    this.route.queryParams.subscribe((params) => {
      this.serviceRequestId = params['serviceRequestId'];
      this.isAppReport = params['appReport'].toLowerCase() === 'true';
      this.watchedNotesIds = this.storageService.getServiveRequestCommentsIds(
        this.serviceRequestId
      );
      if (this.isAppReport) {
        this.getAppReport();
        this.getAppReportsStatuses();
      } else {
        this.getServiceReqeusNotes();
        this.getServiceReqeust();
        this.getRequestsStatuses();
      }
    });
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });

    this.createCommentFormGroup();
  }
  getRequestsStatuses() {
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
      this.requestsStatuses = this.statuses.filter((x) => {
        return x.category == 'RequestStatus';
      });
    });
  }
  getAppReportsStatuses() {
    this.foodClientReqeustService
      .getFoodRequestStatuses(this.langId)
      .subscribe((data) => {
        this.requestsStatuses = data.map((x) => {
          let subject = new Status();
          subject.statusId = x.clientRequestStatusId;
          subject.name = x.name;
          subject.category = 'appRequestStatus';
          return subject;
        });
      });
  }
  getServiceReqeusNotes() {
    this.notesPusherService.setMode('serviceRequest');
    this.notesPusherService.getAllNotes(this.serviceRequestId);
    this.notesPusherService.notes$.subscribe((data) => {
      this.serviceRequestNotes = data;
      this.serviceRequestNotes.forEach((x) => {
        this.watchedNotesIds[x.noteId] = true;
      });
      this.serviceRequestNotes = this.serviceRequestNotes.sort((a, b) =>
        a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime() ? 1 : -1
      );
      this.storageService.setServiveRequestCommentsIds(
        this.serviceRequestId,
        this.watchedNotesIds
      );
    });
  }
  onReloadNotes() {
    this.notesPusherService.getAllNotes(this.serviceRequestId);
  }
  getServiceReqeust() {
    this.serviceRequestsService
      .getById(this.serviceRequestId)
      .subscribe((data) => {
        this.serviceRequest = data;
        this.firestore
          .collection(`chatroom_${this.serviceRequest.serviceRequestId}`)
          .valueChanges()
          .subscribe((res) => {
            this.chatRoom = res;
          });
      });
  }
  getAppReport() {
    this.foodClientReqeustService
      .getFoodRequest(this.serviceRequestId, 1)
      .subscribe((data) => {
        this.serviceRequest = data;
        this.serviceRequestNotes = this.serviceRequest.notes;
      });
  }
  goBackToRequests() {
    this._location.back();
  }
  moveToEditMode() {
    this.isEditMode = true;
  }
  onCancelForm(e) {
    this.isEditMode = false;
  }
  onChildFormValidated(request: ServiceRequest | FoodReportRequest) {
    const sr = request as ServiceRequest;
    const cr = request as FoodReportRequest;
    if (sr.serviceRequestId) {
      this.serviceRequestsService.put(sr).subscribe((response) => {
        this.isEditMode = false;
      });
    } else {
      this.foodClientReqeustService.setFoodRequest(cr).subscribe((response) => {
        this.isEditMode = false;
      });
    }
  }
  saveAgentForRequest(event, request: ServiceRequest | FoodReportRequest) {
    if (!request) return;
    this.serviceRequest.assignedAgentId = event;
    setTimeout(() => {
      if (!this.isAppReport) {
        this.onChildFormValidated(request);
        this.serviceRequestsService
          .put(this.serviceRequest as ServiceRequest)
          .subscribe((response) => {
            this.openConfirmChangDialog(null, 'requestAgent');
          });
      } else {
        const cr = request as FoodReportRequest;
        this.foodClientReqeustService
          .setFoodRequestAgent(cr.clientRequestId, event)
          .subscribe((response) => {
            this.openConfirmChangDialog(null, 'requestAgent');
          });
      }
    }, 10);
  }
  getAgentNameById(request: ServiceRequest | FoodReportRequest) {
    const sr = request as ServiceRequest;
    const cr = request as FoodReportRequest;
    let agentId: number;
    if (!this.isAppReport) {
      agentId = sr.assignedAgentId ? sr.assignedAgentId : 0;
    } else {
      agentId = cr.assignedAgentId ? cr.assignedAgentId : 0;
    }
    if (!agentId || !this.agents) return;

    return this.agents.find((a) => a.agentId === agentId).name;
  }
  saveStatusForRequest(event, request: ServiceRequest | FoodReportRequest) {
    if (!request) return;
    request.statusId = Number(event.value);
    if (!this.isAppReport) {
      setTimeout(() => {
        this.serviceRequestsService
          .put(request as ServiceRequest)
          .subscribe((response) => {});
        this.openConfirmChangDialog(null, 'requestStatus');
      }, 10);
    } else {
      this.foodClientReqeustService
        .setFoodRequestStatus(
          (request as FoodReportRequest).clientRequestId,
          request.statusId
        )
        .subscribe((data) => {
          this.openConfirmChangDialog(null, 'requestStatus');
        });
    }
  }
  createCommentFormGroup() {
    this.commentForm = new FormGroup({
      desc: new FormControl('', [Validators.required]),
    });
  }
  saveComment() {
    if (this.commentForm.valid) {
      var serviceRequestNote = new ServiceRequestNote();
      serviceRequestNote.serviceRequestId = this.serviceRequestId;
      serviceRequestNote.text = this.commentForm.value.desc;
      this.serviceRequestNotesService
        .post(serviceRequestNote)
        .subscribe((response) => {
          this.isCommentEditMode = false;
        });
    }
  }
  openConfirmChangDialog(
    request: ServiceRequest = null,
    type = '',
    from = '',
    to = ''
  ) {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: request,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      var a = result;
    });
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
  }
  createNewNote(note) {
    this.noteToEdit = note;
    this.isNoteEditMode = true;
  }
  saveNote(note: Note | ClientRequestNote) {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;
    if (!this.isAppReport) {
      const noteToSave = note as Note;
      noteToSave.serviceRequestId = sr.serviceRequestId;
      noteToSave.assigningAgentId = this.agentUser.agent.agentId;
      if (noteToSave.noteId > 0) {
        this.notesPusherService.put(noteToSave);
      } else {
        if (sr.clientId) noteToSave.clientId = sr.clientId;
        if (sr.productId) noteToSave.patientId = sr.product.patientId;
        this.notesPusherService.post(noteToSave);
      }

      this.isNoteEditMode = false;
    } else {
      const appReportNote = note as ClientRequestNote;
      appReportNote.clientRequestId = cr.clientRequestId;
      appReportNote.clientRequestNoteId = appReportNote.clientRequestNoteId
        ? appReportNote.clientRequestNoteId
        : 0;
      appReportNote.authorAgentId = this.agentUser.agent.agentId;
      if (appReportNote.clientRequestNoteId == 0) {
        this.foodClientReqeustService
          .addFoodRequestNote(appReportNote)
          .subscribe((data) => {
            this.isNoteEditMode = false;
          });
      } else {
        this.foodClientReqeustService
          .setFoodRequestNote(appReportNote)
          .subscribe((data) => {
            this.isNoteEditMode = false;
          });
      }
    }
  }
  getAssigningAgentName() {
    const sr = this.serviceRequest as ServiceRequest;
    const cr = this.serviceRequest as FoodReportRequest;
    if (!this.isAppReport) {
      return sr && sr.assigningAgent && sr.assigningAgent.regularUser.firstName;
    } else {
      const agent = this.agents.find((x) => x.agentId == cr.assigningAgentId);
      return agent.name;
    }
  }
  getSubjectDescription() {
    const cr = this.serviceRequest as FoodReportRequest;
    return cr.subjectId == 8 ? 'Food Barcode' : 'Food Name';
  }
  getFoodName() {
    const cr = this.serviceRequest as FoodReportRequest;
    return cr.referenceName;
  }
  getImageUrl() {
    const sr = this.serviceRequest as FoodReportRequest;
    if (this.isAppReport && sr?.requestImage) {
      const path = sr?.requestImage?.webPath + '/' + sr?.requestImage?.fileName;
      return replaceWebPath(path);
    }
  }
  onImageClick() {
    window.open(this.getImageUrl(), '_blank');
  }
}
