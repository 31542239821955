<table mat-table [dataSource]="actions">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="actionType">
    <th style="width: 250px" mat-header-cell *matHeaderCellDef>Action Type</th>
    <td mat-cell *matCellDef="let eventAction">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              saveActionTypeForTriggerEvent($event, eventAction)
            "
            [(ngModel)]="eventAction.actionTypeId"
          >
            <mat-option
              *ngFor="let status of actionTypes"
              [value]="status.statusId"
              class="lead-status lead-status{{ status.statusId }}"
              style="text-align: center"
            >
              {{ status.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="EmailTemplate">
    <th style="width: 250px" mat-header-cell *matHeaderCellDef>
      Email Template
    </th>
    <td mat-cell *matCellDef="let eventAction">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px; font-size: 12px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              saveEmailTemplateForEventAction($event, eventAction)
            "
            [(ngModel)]="eventAction.emailTemplateId"
          >
            <mat-option
              *ngFor="let emailTemplate of emailTemplates"
              [value]="emailTemplate.emailTemplateId"
              class="lead-status lead-status{{ emailTemplate.emailTemplateId }}"
              style="text-align: center"
            >
              {{ emailTemplate.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="Active">
    <th style="width: 150px" mat-header-cell *matHeaderCellDef>Active</th>
    <td mat-cell *matCellDef="let eventAction">
      <input
        type="checkbox"
        id="isActive"
        name="isActive"
        [(ngModel)]="eventAction.isActive"
        (change)="ChangeIsActive(eventAction)"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="Delete">
    <th style="width: 100px" mat-header-cell *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let eventAction"
      (click)="deleteAction(eventAction)"
    >
      <span class="material-icons" style="cursor: pointer"> delete </span>
    </td></ng-container
  >

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
