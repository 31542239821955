<div class="agent-settings">
  <button (click)="location.back()" class="agent-settings__back" mat-button>
    < Back
  </button>

  <mat-card class="agent-settings__card" *ngIf="agentSettings">
    <mat-card-header class="agent-settings__card__header">
      <mat-card-title class="agent-settings__card__header__title">
        <div class="agent-settings__card__header__title__avatar">
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AvatarImageType"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage()"
            [checkedImgId]="getCheckedImage()"
            iconName="account_circle"
            iconSize="40px"
          ></app-algo-image>
        </div>
        {{ agentSettings.name }}
      </mat-card-title>
      <div>
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </mat-card-header>
    <div class="agent-settings__card__content">
      <div class="agent-settings__card__content__item">
        <div class="agent-settings__card__content__item__label">Department</div>
        <mat-form-field
          class="agent-settings__card__content__item__field round-select"
        >
          <mat-select
            placeholder="Select department"
            [(ngModel)]="agentSettings.departmentId"
          >
            <mat-option *ngFor="let item of departments" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="agent-settings__card__content__item">
        <div class="agent-settings__card__content__item__label">
          Description
        </div>
        <mat-form-field
          class="agent-settings__card__content__item__field"
          appearance="fill"
        >
          <input
            matInput
            placeholder="Description"
            [(ngModel)]="agentSettings.description"
          />
        </mat-form-field>
      </div>

      <div class="agent-settings__card__content__item">
        <mat-checkbox
          class="agent-settings__card__content__item__checkbox"
          [(ngModel)]="agentSettings.isChatAvailable"
          >Chat available</mat-checkbox
        >
      </div>
    </div>
    <div class="agent-settings__card__action">
      <button
        [disabled]="!agentSettings.departmentId || !agentSettings.description"
        mat-raised-button
        color="primary"
        (click)="save()"
        class="agent-settings__card__action__save"
      >
        Save
      </button>
    </div>
  </mat-card>
</div>
