import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';

@Component({
  selector: 'app-lead-request-thumb',
  templateUrl: './lead-request-thumb.component.html',
  styleUrls: ['./lead-request-thumb.component.css'],
})
export class LeadRequestThumbComponent implements OnInit {
  @Output('editRequest') editRequest: EventEmitter<
    RequestForTableMin
  > = new EventEmitter();

  @Input() request;
  constructor() {}

  ngOnInit(): void {}
}
