import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';
import { DealForTableMin } from 'src/app/data/DealForTableMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DealEnum, PaymentMethodEnum } from 'src/app/Enums/StatusesEnum';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-transaction-bundle-thumb',
  templateUrl: './transaction-bundle-thumb.component.html',
  styleUrls: ['./transaction-bundle-thumb.component.scss'],
})
export class TransactionBundleThumbComponent implements OnInit {
  @Input() dealBundle: DealBundleForTable;
  @Input() status: Status;
  @Input() regularUser: RegularUser;
  @Input() isInner: boolean;
  @Input() isNeedApproveBool: boolean = false;
  @Output('rowClick')
  rowClick: EventEmitter<DealForTableMin> = new EventEmitter();
  @Output('rowChecked')
  rowChecked: EventEmitter<any> = new EventEmitter();
  @Output('onHandleApproval')
  onHandleApproval: EventEmitter<DealForTableMin> = new EventEmitter();
  @Output('onCancelDeal')
  onCancelDeal: EventEmitter<DealForTableMin> = new EventEmitter();

  constructor(public datepipe: DatePipe) {}

  ngOnInit(): void {
    var a = this.status;
  }
  hasPermmisions() {
    return userHasPermisions(['Deals_ChangeStatus'], this.regularUser);
  }
  onRowClick(deal: DealForTableMin) {
    if (
      // true
      this.isInner ||
      deal.dealBundle == null
      /*
       ||
      (this.deal.paymentMethodId == PaymentMethodEnum.CreditCard &&
        (this.deal.statusId == DealEnum.Done ||
          this.deal.statusId == DealEnum.Rejected))
          */
    )
      this.rowClick.emit(deal);
  }
  showCancelDeal() {
    return true;
    /*
    return (
      userHasPermisions(['All Transactions'], this.regularUser) &&
      this.deal.paymentMethodId == PaymentMethodEnum.CreditCard &&
      this.deal.statusId == DealEnum.Done
    );
    */
  }

  cancelDeal(deal: DealForTableMin) {
    this.onCancelDeal.emit(deal);
  }

  isRefund(date: string) {
    return false;
  }
  isChecked(dealId: number, checked: boolean) {
    this.rowChecked.emit({ dealId, checked });
  }

  handleApproval(e, deal: DealForTableMin) {
    deal.statusId = e.value;
    /* deal.statusId = this.statuses.find(
      (x) => x.statusId == deal.statusId
    ).statusId; */
    this.onHandleApproval.emit(deal);
  }
}
