<div class="prototype">
  <div dir="ltr">
    <button (click)="location.back()" class="prototype__back" mat-button>
      < Back
    </button>
  </div>
  <div style="position: relative">
    <nav class="prototype-nav" mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        *ngFor="let tab of tabsPrototype; let i = index"
        (click)="onSelectTab(tab)"
        [active]="activeTab.isActive == tab.isActive"
        [ngClass]="{
          selected: activeTab?.isActive == tab.isActive
        }"
        class="prototype-nav__item"
        >{{ tab.name }}
      </a>
    </nav>
    <div style="position: absolute; left: 0; top: 32px; z-index: 200">
      <app-change-lang
        [selectedLangId]="langId"
        (changeLang)="onChangeLang($event)"
      ></app-change-lang>
    </div>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <mat-card>
      <div [ngSwitch]="activeTab.id" class="prototype-content">
        <app-category-prototype
          [langId]="langId"
          *ngSwitchCase="tabsEnum.Category"
        ></app-category-prototype>
        <app-subcategory-prototype
          [langId]="langId"
          *ngSwitchCase="tabsEnum.SubCategory"
        ></app-subcategory-prototype>
        <app-result-type-prototype
          [langId]="langId"
          *ngSwitchCase="tabsEnum.ResultType"
        >
        </app-result-type-prototype>
      </div>
    </mat-card>
  </mat-tab-nav-panel>
</div>
