import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take } from 'rxjs/operators';
import { AlgoResultType } from 'src/app/data/algorithm/AlgoResultType';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { AlgoSubCategoryResult } from 'src/app/data/algorithm/AlgoSubCategoryResult';
import { AlgoSubCategoryResultType } from 'src/app/data/algorithm/AlgoSubCategoryResultType';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { ResultRecommendation } from 'src/app/data/algorithm/ResultRecommendation';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-edit-summary',
  templateUrl: './edit-summary.component.html',
  styleUrls: ['./edit-summary.component.scss'],
})
export class EditSummaryComponent implements OnInit, OnChanges, OnDestroy {
  //#region Properties
  @Output('loadResultTypes') loadResultTypes = new EventEmitter<boolean>();
  @Input() subCatResultTypes: AlgoSubCategoryResultType[];
  @Input() canEdit: boolean;
  @Input() subCategory: AlgoSubCategory;
  @Input() dir: string;
  @Input() langId: number;
  @Input() versionId: number;
  activeType: AlgoSubCategoryResultType;
  result: AlgoSubCategoryResult;
  nameEditorConfig: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    showToolbar: false,
    width: '100%',
    height: '82px',
  };
  editorConfig: AngularEditorConfig = {
    sanitize: false,
    editable: true,
  };
  availableResultTypes: AlgoResultType[];
  resultTypeNameError: boolean;
  resultMaxError: boolean;
  resultMinError: boolean;
  AlgoImageType = AlgoImageType;
  errorMessage: string;
  //#endregion
  constructor(
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar,
    private popupHandlerService: PopupHandlerService
  ) {
    //console.log('constructor', this.subCatResultTypes);
    setTimeout(() => {
      this.nameEditorConfig.editable = this.canEdit;
      this.editorConfig.editable = this.canEdit;
      this.editorConfig.showToolbar = this.canEdit;
    }, 100);
  }
  ngOnDestroy(): void {
    this.activeType = null;
    this.subCatResultTypes = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.langId?.firstChange) {
      if (changes?.langId?.previousValue !== changes?.langId?.currentValue) {
        this.getResultByType();
      }
    }
    if (changes.subCatResultTypes && !changes.subCatResultTypes.firstChange) {
      if (changes.subCatResultTypes.currentValue?.length > 0) {
        this.activeType = this.activeType
          ? this.activeType
          : this.subCatResultTypes[0];
        this.getResultByType();
      }
    }
  }
  ngOnInit(): void {
    this.activeType = this.subCatResultTypes && this.subCatResultTypes[0];
    if (this.activeType) {
      this.getResultByType();
    }
  }
  onUploadFinished(files: AppImage) {
    if (!files) return;
    const finded = this.result.resultType.files.find(
      (x) => x.fileType == AlgoImageType.AlgoResultTypePass
    );
    if (finded) {
      this.result.resultType.files = this.result.resultType.files.filter(
        (x) => x.fileType != AlgoImageType.AlgoResultTypePass
      );
    }
    this.result.resultType.files.push(files);
  }
  getAlgoImage(imageType: number) {
    const image = this.result.resultType.files?.find(
      (x) => x.fileType === imageType
    );
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.result.resultType.files?.find(
      (x) => x.fileType === imageType
    );
    return image?.fileId;
  }
  removeResultType() {
    this.popupHandlerService.openConfirmationDialog('', 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.ans === 'yes') {
          this.algorithmService
            .removeResultType(this.activeType.algoSubCategoryResultTypeId)
            .subscribe((data) => {
              this.activeType = null;
              this.loadResultTypes.emit(true);
              this._snackBar.open(
                'Algorithm result type removed successfully',
                'Close',
                {
                  duration: 2000,
                  panelClass: 'added-success',
                }
              );
            });
        }
      });
  }
  pushNewTypeToResultTypes() {
    const newType = new AlgoSubCategoryResultType();
    newType.name = '+ New Type';
    newType.description = 'New Type Description';
    newType.resultTypeId = this.subCatResultTypes.length + 1;
    newType.langId = this.activeType.langId;
    newType.algoSubCategoryId = this.activeType.algoSubCategoryId;
    this.subCatResultTypes.push(newType);
    //console.log(this.subCatResultTypes);
  }
  addNewType() {
    this.popupHandlerService.openAddNewResultType(this.availableResultTypes);
    this.popupHandlerService.addNewResultTypeSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.type === 'add') {
          this.algorithmService
            .AddResultType(
              this.versionId,
              this.subCategory.subCategoryId,
              data.id
            )
            .subscribe((data) => {
              this.loadResultTypes.emit(true);
              this.getAvailabeResultTypes();
              this._snackBar.open(
                'Algorithm result type added successfully',
                'Close',
                {
                  duration: 2000,
                  panelClass: 'added-success',
                }
              );
            });
        }
      });
  }
  getAvailabeResultTypes() {
    this.algorithmService
      .getAvailableResultTypes(this.subCategory.subCategoryId, this.langId)
      .subscribe((data) => {
        this.availableResultTypes = data;
      });
  }
  getResultByType() {
    if (!this.activeType) return;
    this.algorithmService
      .getSubCatResultByType(
        this.activeType.algoSubCategoryId,
        this.langId,
        this.activeType.resultTypeId
      )
      .subscribe((data) => {
        this.result = data;
        if (!this.result.recomendations) {
          this.result.recomendations = [];
          this.result.recomendations.push(new ResultRecommendation());
        }
        if (!this.result.resultType) {
          this.result.resultType = new AlgoSubCategoryResultType();
        }
        this.result.resultType.color = this.result.resultType.color
          ? `#${this.result.resultType.color}`
          : '#000000';
        this.getAvailabeResultTypes();
      });
  }
  addRecomendation() {
    this.result.recomendations.push(new ResultRecommendation());
  }
  removeRecomendation(index: number) {
    if (this.result.recomendations[index]?.recomendations?.length > 0) {
      this.popupHandlerService.openConfirmationDialog('', 'Delete');
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((data) => {
          if (data.ans === 'yes') {
            this.result.recomendations.splice(index, 1);
          }
        });
    } else {
      this.result.recomendations.splice(index, 1);
    }
  }
  onSelectType(type: AlgoSubCategoryResultType) {
    this.activeType = type;
    this.getResultByType();
  }
  setErors(error: boolean) {
    this.resultTypeNameError = error;
    this.resultMaxError = error;
    this.resultMinError = error;
  }
  checkInvalidResultRange(range: number) {
    return range < 0 || !range;
  }
  handleErrorOrSuccess(res: ErrorMessage | any) {
    if ((res as ErrorMessage)?.message) {
      const error = res as ErrorMessage;
      this.errorMessage = error.message;
      this._snackBar.open(this.errorMessage, 'Close', {
        duration: 5000,
      });
    } else {
      this.loadResultTypes.emit(true);
      this._snackBar.open('Algorithm result update successfully', 'Close', {
        duration: 2000,
        panelClass: 'added-success',
      });
    }
  }
  save() {
    this.setErors(false);
    if (!this.result.resultType.name) {
      this.resultTypeNameError = true;
    }
    if (this.result.max == null) {
      this.resultMaxError = true;
    }
    if (this.result.min == null) {
      this.resultMinError = true;
    }

    if (
      !this.resultTypeNameError ||
      !this.resultMaxError ||
      !this.resultMinError
    ) {
      this.result.resultType.color = this.result.resultType.color.replace(
        '#',
        ''
      );
      this.algorithmService
        .setSubCategoryResult(this.result)
        .subscribe((data) => {
          this.handleErrorOrSuccess(data);
        });
    }
  }
}
