<div class="container">
  <div class="title">קבוצת סיומות</div>

  <div class="table" *ngIf="fileUploadSettings">
    <div class="table-header">
      <div class="percent-80">שם קבוצה</div>
      <div class="percent-20"></div>
    </div>
    <div class="table-body">
      <mat-card
        class="table-row"
        *ngFor="let item of fileUploadSettings; let i = index"
      >
        <div class="percent-80">
          <ng-container>
            {{ item.name }}
          </ng-container>
        </div>

        <div class="percent-20 actions">
          <button
            mat-button
            (click)="onEditGroup(item)"
            class="edit"
            type="button"
          >
            <mat-icon>edit</mat-icon>
            הגדרות קבוצה
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
