<div class="title">Add new snp</div>
<form [formGroup]="allelForm" *ngIf="allelForm">
  <div class="mb-2">
    <div class="item">
      <div class="label">Select snp number</div>
      <mat-form-field
        class="example-full-width"
        style="width: 100%"
        appearance="fill"
      >
        <mat-label>SNP Num.</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Number"
          matInput
          formControlName="snpNumber"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            (click)="onSelectSnp(item)"
            *ngFor="let item of filteredOptions | async"
            [value]="item.snpNumber"
          >
            {{ item.snpNumber }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div *ngIf="letters && !selectedSnp.isExists" class="label">
      Choose the alfa letters
    </div>
    <div
      class="item mb-2"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px"
      *ngIf="letters && !selectedSnp.isExists"
    >
      <div
        class="item__select"
        *ngFor="let letter of allelFieldsNames; let i = index"
        fxFlex="50%"
      >
        <div style="width: 100%">
          <div style="margin-bottom: 5px; text-align: left">
            Letter {{ i + 1 }}:
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-select #letterRef [formControlName]="letter">
                <mat-option
                  [disabled]="disableLetter(item)"
                  *ngFor="let item of letters"
                  [value]="item"
                  >{{ item }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-icon
              *ngIf="hasFieldValue(letter)"
              matPrefix
              (click)="clearSelection(letter, letterRef)"
              >remove_circle_outline</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions mt-2">
    <button
      class="save"
      mat-button
      [disabled]="allelForm.invalid || isNotInList"
      (click)="addSnp()"
      type="submit"
    >
      Add
    </button>
    <button class="cancel" mat-button (click)="closeDialog()" type="button">
      Cancel
    </button>
  </div>
</form>
