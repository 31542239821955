import { AppImage } from './algorithm/AppImage';

export class ZoomEvent {
  zoomEventId: number;
  zoomEventName: string;
  zoomEventDescription: string;
  zoomEventLink: string;
  zoomEventDate: any;
  image: AppImage;
}
