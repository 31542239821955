<form
  *ngIf="requestForm"
  [formGroup]="requestForm"
  (ngSubmit)="saveRequest()"
  class="example-form"
>
  <div style="display: flex; align-self: baseline">
    <mat-form-field appearance="fill">
      <mat-label>Subject</mat-label>
      <mat-select
        formControlName="subject"
        (selectionChange)="onSubjectChanged($event)"
      >
        <mat-option *ngFor="let subject of subjects" [value]="subject.statusId">
          {{ subject.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      style="margin-left: 1rem; align-self: baseline"
      [style.border-bottom]="
        requestForm.controls['assignedAgentName'].errors?.required &&
        isSubmitted
          ? '2px solid red'
          : ''
      "
    >
      <app-autocomplete-agent
        (selectedValue)="saveAgentForLead($event)"
        [data]="agents"
        [initialValue]="requestForm.value.assignedAgentName"
        [widthExp]="180"
        [label]="'Agent'"
      ></app-autocomplete-agent>
    </div>
  </div>

  <angular-editor
    [placeholder]="'Content'"
    name="desc"
    formControlName="desc"
    [config]="editorConfig"
    [class.desc-error]="
      requestForm.controls['desc'].errors?.required && isSubmitted
    "
  ></angular-editor>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    "
  >
    <button
      class="btn-submit btn-submit--lead"
      style="margin-right: 20px"
      type="submit"
    >
      Submit
    </button>
    <button class="btn-submit" (click)="onCancelRequest()">Cancel</button>
  </div>
</form>
