import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SNP } from '../data/SNP';

@Injectable({
  providedIn: 'root',
})
export class SnpsService {
  constructor(private http: HttpClient) {}
  postSnpToVersion(snp: SNP, versionId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Snps/PostSnp/${versionId}`,
      snp
    );
  }
  getAllSnps() {
    return this.http.get<number[]>(`${environment.baseUrl}Snps/GetAllSnps/`);
  }
  getSnpsNotExistsByVersion(versionId: number) {
    return this.http.get<number[]>(
      `${environment.baseUrl}Snps/GetSnpsNotExistsByVersion/${versionId}`
    );
  }
  addSnpToVersion(snpNum: number, subCategoryId: number, versionId: number) {
    return this.http.get<number[]>(
      `${environment.baseUrl}Snps/AddSnpToVersion/${snpNum}/${subCategoryId}/${versionId}`
    );
  }
  deleteSnpFromVersion(
    snpNum: number,
    versionId: number,
    subCategoryId: number
  ) {
    return this.http.get<number[]>(
      `${environment.baseUrl}Snps/DeleteSnpFromVersion/${snpNum}/${versionId}/${subCategoryId}`
    );
  }
  put(snp: SNP) {
    return this.http.put<number[]>(
      `${environment.baseUrl}Snps//${snp.sNPNumber}`,
      snp
    );
  }
}
