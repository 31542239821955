<div class="chat-wrapper">
  <div class="chat-list">
    <div class="chat-list__header">
      <ng-container *ngIf="docId">
        <div class="chat-list__header__img">
          <img
            src="../../../assets/avatar-{{ getAgentHat() }}.svg"
            alt="avatar"
          />
        </div>
        <div class="chat-list__header__name">{{ getAgentName() }}</div>
      </ng-container>
      <div style="margin-right: auto">
        <button [matMenuTriggerFor]="filterMenu" mat-icon-button>
          <img src="../../../assets/filter.svg" />
        </button>
        <mat-menu #filterMenu="matMenu" (click)="$event.stopPropagation()">
          <div
            style="height: auto; width: 350px"
            mat-menu-item
            [disableRipple]="true"
            (click)="$event.stopPropagation()"
          >
            <div style="display: flex; align-items: center">
              <span style="margin-right: 2rem">What to show:</span>
              <mat-button-toggle-group
                style="line-height: 32px"
                [(ngModel)]="showClosed"
                (change)="getClosed($event)"
              >
                <mat-button-toggle value="opened">Open</mat-button-toggle>
                <mat-button-toggle value="closed">Closed</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div
            style="height: auto; width: 350px"
            mat-menu-item
            [disableRipple]="true"
            (click)="$event.stopPropagation()"
          >
            <div style="display: flex; align-items: center">
              <span style="margin-right: 2rem">Filter by:</span>

              <div class="wrapper-filter">
                <div *ngFor="let item of filterTypes" class="filter-types">
                  <mat-checkbox
                    [(ngModel)]="item.checked"
                    (change)="onFilterChange()"
                    class="filter-types__checkbox {{ item.letter }}"
                    matRipple
                    ><div
                      class="{{ item.letter }} filter-types__checkbox__letter"
                    >
                      {{ item.letter }}
                    </div></mat-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="chat-list__search">
      <input
        (keyup)="searchChatList($event)"
        type="text"
        placeholder="חפש/י שיחה"
      />
      <mat-icon>search</mat-icon>
    </div>
    <!--  <div *ngIf="loadingList">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div> -->
    <!-- (psYReachEnd)="onScroll($event)" -->
    <perfect-scrollbar
      style="height: calc(100vh - 189px - 2rem)"
      [perfectScrollbar]="config"
    >
      <div [class.close-in-progress]="closingInProgress">
        <app-chat-list-data
          *ngIf="showClosed === 'opened'"
          [chatList]="filterdChatList"
          (rowClick)="onRowClick($event)"
          [style.opacity]="closingInProgress ? '0.5' : ''"
        ></app-chat-list-data>
        <app-chat-list-data
          *ngIf="showClosed === 'closed'"
          [chatList]="filterdChatList"
          (rowClick)="onRowClick($event)"
        ></app-chat-list-data>
      </div>
      <mat-spinner diameter="50" *ngIf="isFetching"></mat-spinner>
    </perfect-scrollbar>
  </div>
  <div class="chat-room">
    <div class="chat-room__header">
      <ng-container *ngIf="docId">
        <div class="chat-list__header__img">
          <img
            src="../../../assets/avatar-{{ clientAvatar }}.svg"
            alt="avatar"
          />
        </div>
        <div class="chat-list__header__name">{{ clientName }}</div>
        <button
          class="chat-room__header__close-request"
          mat-raised-button
          (click)="setChatToNotActive()"
          *ngIf="!isClosed"
          [disabled]="closingInProgress"
        >
          Close request
        </button>
      </ng-container>
    </div>
    <ng-container *ngIf="serviceRequestsData">
      <app-chat
        *ngIf="docId"
        [user]="agentUser"
        [docId]="docId"
        [letter]="agentLetter"
        [serviceRequest]="getServiceRequestForChat()"
        [closingInProgress]="closingInProgress"
      >
      </app-chat>
    </ng-container>
  </div>
</div>
