import { AppImage } from './AppImage';

export class AlgoResultType {
  algoResultTypeId: number;
  color: string;
  name: string;
  orderBy: number;
  langId: number;
  files: AppImage[];
}
