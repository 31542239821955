<div style="margin-bottom: 10px" class="leadsTable">
  <div class="leadsTable__header">
    <ng-container *ngFor="let item of headers">
      <div class="leadsTable__header__item" [ngStyle]="{ width: item.width }">
        <span
          [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
          class="clickble"
          (click)="changeSortBy(item.val)"
          >{{ item.name }}</span
        >

        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </div>
    </ng-container>
    <button
      *ngIf="selected?.length > 0"
      mat-button
      (click)="openImagesDialog()"
    >
      Change Image
    </button>
  </div>
  <div cdkScrollable style="height: calc(100vh - 390px); overflow-y: auto">
    <div
      cdkDropList
      class="leadsTable__list"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListData]="bulletsData"
      [cdkDropListConnectedTo]
    >
      <div
        *ngFor="let bulletItem of bulletsData"
        class="leadsTable__item"
        [ngStyle]="{
          opacity:
            bulletIdToEdit !== bulletItem.algoBulletId && isEditMode
              ? '0.3'
              : '1',
          'pointer-events':
            bulletIdToEdit !== bulletItem.algoBulletId && isEditMode
              ? 'none'
              : ''
        }"
        cdkDrag
        [cdkDragData]="bulletItem"
      >
        <span class="icon">
          <mat-checkbox
            (change)="onChangeSelection($event, bulletItem.algoBulletId)"
            [checked]="selection.isSelected(bulletItem.algoBulletId)"
          ></mat-checkbox>
          <img *ngIf="bulletItem.imageUrl" [src]="bulletItem.imageUrl" alt="" />
        </span>

        <span class="d-flex-center parameter" style="font-weight: bold">
          <div class="parameter-content">
            <div class="id">
              <small class="fow-400">
                {{ bulletItem.algoBulletId }}
              </small>
              <mat-icon *ngIf="bulletItem.algoBulletLinkId">link</mat-icon>
            </div>
            <div
              class="text"
              [innerHtml]="getParameters(bulletItem.parameters)"
            >
              <!--  [ngStyle]="{ width: !isFullScreen ? '89%' : '100%' }"<ng-container
                  *ngFor="
                    let parameter of bulletItem.parameters;
                    let last = last
                  "
                >
                  {{ parameter }}
                  <ng-container *ngIf="!last">,<br /></ng-container>
                </ng-container> -->
            </div>
          </div>
        </span>
        <span
          style="
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
          "
          class="bloode-test"
        >
          <div *ngFor="let bloodTest of bulletItem.bloodTest">
            <div
              style="
                display: inline;
                font-weight: bold;
                text-decoration: underline;
              "
            >
              {{ bloodTest.name }}
            </div>
            :&nbsp;{{ bloodTest.desc }}
          </div>
          <!--:&nbsp;{{ bloodTest.desc }}-->
        </span>
        <span class="title"
          >{{ bulletItem.title }}--order:{{ bulletItem.order }}</span
        >
        <span class="description">
          <div class="description-content">
            <div class="text">
              {{ bulletItem.description }}
            </div>
          </div>
        </span>

        <span style="padding: 0.25rem">
          <button
            *ngIf="bulletIdToEdit !== bulletItem.algoBulletId"
            class="edit-btn"
            mat-button
            (click)="editRow(bulletItem.algoBulletId)"
            mat-icon-button
          >
            <mat-icon class="table">border_color</mat-icon>
          </button>
          <button
            *ngIf="bulletIdToEdit == bulletItem.algoBulletId && isEditMode"
            class="edit-btn"
            mat-button
            (click)="close()"
            mat-icon-button
          >
            <mat-icon class="table">close</mat-icon>
          </button>
          <button
            class="delete-btn"
            mat-button
            (click)="deleteRow(bulletItem.algoBulletId)"
            mat-icon-button
          >
            <mat-icon class="table">delete_forever</mat-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
