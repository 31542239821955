<mat-card class="foods">
  <div class="wrapper-filters">
    <div class="d-flex space-between">
      <div class="foods__title">
        {{ +selectedCat === FoodGenesCatTypeEnum.Food ? "Food" : "Recipe" }}
        categories
      </div>
      <div class="foods__filters">
        <div class="foods__filters__search" *ngIf="foodCategoryPerms.canSearch">
          <mat-form-field appearance="fill">
            <input
              [(ngModel)]="searchTerm"
              #search
              (keyup)="onSearch($event)"
              matInput
              placeholder="Search"
            />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-button-toggle-group
          (change)="onChangeCat()"
          [(ngModel)]="selectedCat"
        >
          <mat-button-toggle
            *ngFor="let item of foodCatTypes"
            [value]="item.categoryTypeId"
            >{{ item.name }}</mat-button-toggle
          >
        </mat-button-toggle-group>
        &nbsp;&nbsp;&nbsp;
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </div>
    <hr class="hr" />
  </div>
  <!-- [perfectScrollbar]="config" -->
  <perfect-scrollbar style="height: calc(100vh - 220px)">
    <div class="foods__table">
      <ngx-skeleton-loader
        *ngIf="isLoading"
        count="10"
        [theme]="{ height: '30px' }"
      ></ngx-skeleton-loader>
      <app-food-categories-table
        *ngIf="!isLoading"
        [categories]="foodCats"
        [selectedLangId]="langId"
        [catType]="selectedCat"
        [foodCategoryPerms]="foodCategoryPerms"
        (getData)="onGetData($event)"
        (saveCategory)="onSaveCategory($event)"
        (editCanceled)="onEditCanceled($event)"
        (deleteCategory)="onDeleteCategory($event)"
      ></app-food-categories-table>
    </div>
  </perfect-scrollbar>
  <app-table-paginator
    [langId]="selectedLang"
    *ngIf="metaData"
    [sticky]="true"
    [metaData]="metaData"
    [classAttr]="'food-categories'"
    [headFilter]="headFilter"
    (filterChanged)="getPaginatorData($event)"
  >
  </app-table-paginator>
</mat-card>
