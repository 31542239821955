<div class="search">
  <mat-tab-group animationDuration="0">
    <mat-tab label="Client" *ngIf="userHasPermission(['Clients_Search'])">
      <div class="search-results">
        <span class="search-results-title">Clients</span>
        <div class="divider divider--client"></div>
        <div style="position: relative">
          <app-clients-table
            [agents]="agents"
            [leadStatuses]="leadStatuses"
            [leadSources]="leadSources"
            [clientsData]="clientsData"
            [metaData]="clientsMetaData"
            [headFilter]="clientsHeadFilter"
            [isLoading]="isClientsLoading"
          >
          </app-clients-table>
          <app-table-paginator
            *ngIf="clientsData && clientsMetaData"
            [headFilter]="clientsHeadFilter"
            [metaData]="clientsMetaData"
            (filterChanged)="clientGetAllWithFilter($event)"
            [classAttr]="'leadsAttr'"
          >
          </app-table-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Tests" *ngIf="userHasPermission(['Products_Search'])">
      <div class="search-results">
        <span class="search-results-title">Tests</span>
        <div class="divider divider--test"></div>
        <div style="position: relative">
          <app-tests-table
            *ngIf="followUpAgents"
            [agents]="nutritionists"
            [followUpAgents]="followUpAgents"
            [statuses]="statuses"
            [leadSources]="leadSources"
            [productsData]="productsData"
            [headFilter]="testsHeadFilter"
            [isSearchPage]="true"
            [isLoading]="isTestsLoading"
            [checkedIds]="testsCheckedIds"
            (checkedIdsChanged)="changeTestsCheckedIds($event)"
          >
          </app-tests-table>

          <app-table-paginator
            *ngIf="productsData && productsMetaData"
            [headFilter]="testsHeadFilter"
            [metaData]="productsMetaData"
            (filterChanged)="testsGetAllWithFilter($event)"
            [classAttr]="'productsAttr'"
            [checkedIds]="testsCheckedIds"
            (checkedIdsChanged)="onCheckedTestsIdsChanged($event)"
            [agents]="nutritionists"
            [followUpAgents]="followUpAgents"
          >
          </app-table-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Leads" *ngIf="userHasPermission(['Leads_Search'])">
      <div class="search-results">
        <span class="search-results-title">Leads</span>
        <div class="divider divider--lead"></div>
        <app-leads-table
          *ngIf="agents && leadStatuses"
          [agents]="agents"
          [leadStatuses]="leadStatuses"
          [leadSources]="leadSources"
          [leadsData]="leadsData"
          [metaData]="leadsMetaData"
          [headFilter]="leadsHeadFilter"
          [leadsServiceInject]="leadsService"
          [isLoading]="isLeadsLoading"
          [checkedIds]="leadsCheckedIds"
          (checkedIdsChanged)="changeLeadsCheckedIds($event)"
        >
        </app-leads-table>
        <app-table-paginator
          *ngIf="leadsData && leadsMetaData && leadsStatusesForBulk"
          [headFilter]="leadsHeadFilter"
          [metaData]="leadsMetaData"
          (filterChanged)="leadsGetAllWithFilter($event)"
          [classAttr]="'leadsAttr'"
          [checkedIds]="leadsCheckedIds"
          (checkedIdsChanged)="onCheckedLeadsIdsChanged($event)"
          [agents]="agents"
          [leadStatuses]="leadsStatusesForBulk"
        >
        </app-table-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
