import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Client } from 'src/app/data/Client';
import { Deal } from 'src/app/data/Deal';
import { PaymentMethod } from 'src/app/data/PaymentMethod';
import { ProductType } from 'src/app/data/ProductType';
import { Status } from 'src/app/data/Status';
import {
  CreditTypeEnum,
  DealEnum,
  PaymentMethodEnum,
} from 'src/app/Enums/StatusesEnum';
import { Globals } from 'src/app/globals';
import { ExcgangeRatesService } from 'src/app/Services/excgange-rates.service';
import { NumberUtilsService } from 'src/app/Services/number-utils.service';
import { PaymentMethodService } from 'src/app/Services/payment-method.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import {
  creditCardValidator,
  is_israeli_id_number,
  ValidateCreditCardNumber,
} from 'src/app/Utils/validatorsUtils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-payment-details',
  templateUrl: './purchase-payment-details.component.html',
  styleUrls: ['./purchase-payment-details.component.scss'],
})
export class PurchasePaymentDetailsComponent implements OnInit {
  paymentMethodId: number = -1;
  partialPayment: number;
  numPaymentsList = [];
  selectedCard = 'none';
  @Input() deal: Deal;
  @Input() client: Client;
  @Input() creditCurrencies;
  @Input() creditTypes;
  @Input() savedCards;
  @Output('completePurchase')
  completePurchaseEmitter: EventEmitter<any> = new EventEmitter();
  @Output('cancelPurchase')
  cancelPurchaseEmitter: EventEmitter<any> = new EventEmitter();
  childForms: FormGroup[] = [];
  myProducts = [];
  paymentForm: FormGroup = null;
  paymentMethods: PaymentMethod[];
  paymentFormReady: boolean = false;
  productTypes: ProductType[];
  productsTotal: number = 0;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  stageId: Number;
  showNoProductWarning: boolean = false;
  excgangeRates;
  currencyModifier: number = 1;
  paymentMethodChanged: boolean = false;
  isFormTouched: boolean = false;
  statuses: Status[];
  alternateProductNames: any;
  invalidIdNum: boolean;
  maxPaymentAmount: number;
  PaymentMethodEnum = PaymentMethodEnum;
  CreditTypeEnum = CreditTypeEnum;
  DealStatusEnum = DealEnum;

  constructor(
    private paymentMethodService: PaymentMethodService,
    private productTypesService: ProductTypesService,
    private excgangeRatesService: ExcgangeRatesService,
    private numberUtilsService: NumberUtilsService,
    private statusesService: StatusesService
  ) {
    this.stageId = Globals.stageId;
    for (var i = 2; i < 36; i++) {
      this.numPaymentsList.push(i);
    }
  }
  ngOnInit(): void {
    this.statusesService.getAll().subscribe((res) => {
      this.statuses = res;
      this.alternateProductNames = this.statuses.filter(
        (x) => x.category == 'NutroProductName'
      );
    });
    this.excgangeRatesService.getAll().subscribe((data) => {
      this.excgangeRates = data.rates;
    });
    this.createFormGroup();
    this.paymentMethodService.getAll().subscribe((data) => {
      this.paymentMethods = data;
      //  var a=this.paymentMethods[0].name
    });
    this.productTypesService.getAll().subscribe((data) => {
      this.productTypes = data;
    });
    this.maxPaymentAmount = this.deal.amount;
  }
  getPaymentMethodName(id: number) {
    return this.paymentMethods.find((x) => x.paymentMethodId == id).name;
  }
  getLastFourDigits(card) {
    if (card.tranzillaTransactionResponse?.length > 0)
      return card.tranzillaTransactionResponse.ccno;
  }
  /*   onPaymentSystemSelected(e) {
    this.paymentMethodChanged = true;
  } */
  onCompletePurchase() {
    if (this.deal.amount <= 0) {
      return;
    }
    this.invalidIdNum = false;
    if (!is_israeli_id_number(this.paymentForm.value.socialSecurityNum)) {
      // this.invalidIdNum = true;
      // return;
    }
    // this.paymentMethodId = +this.paymentMethodId;
    this.deal.paymentMethodId = +this.paymentMethodId;
    this.deal.amount = this.paymentForm.value.amount;
    if (this.paymentMethodId == PaymentMethodEnum.CreditCard) {
      var invalidFields = this.findInvalidControls();
      if (
        this.paymentForm.valid &&
        (this.paymentForm.value.creditTypeId == 1 ||
          this.paymentForm.value.numberOfPayments)
      ) {
        this.deal.tranzillaTransaction.pdesc =
          this.paymentForm.value.productName;
        this.deal.tranzillaTransaction.contact =
          this.paymentForm.value.invoiceName;
        this.deal.tranzillaTransaction.cardOwnerName =
          this.paymentForm.value.cardHolderName;
        this.deal.tranzillaTransaction.ccno = this.paymentForm.value.cardNumber;
        this.deal.tranzillaTransaction.myccv = this.paymentForm.value.cvv;
        this.deal.tranzillaTransaction.myid =
          this.paymentForm.value.socialSecurityNum;
        this.deal.tranzillaTransaction.currency =
          this.paymentForm.value.creditCurrencyId;
        this.deal.tranzillaTransaction.cred_type =
          this.paymentForm.value.creditTypeId;
        this.deal.tranzillaTransaction.npay =
          this.paymentForm.value.numberOfPayments;
        if (this.deal.tranzillaTransaction.cred_type == 1)
          this.deal.tranzillaTransaction.npay = '1';
        this.deal.tranzillaTransaction.fpay =
          this.paymentForm.value.firstPayment;
        this.deal.tranzillaTransaction.spay =
          this.paymentForm.value.allOtherPayments;

        this.deal.tranzillaTransaction.sum = (
          this.paymentForm.value.amount * this.currencyModifier
        ).toString();

        var expDate: string = this.paymentForm.value.expireDate;
        /*
      if (expDate.indexOf("/") == -1) {
        expDate = expDate.substring(0, 2) + "/" + expDate.substring(2, 4);
      }*/
        this.deal.tranzillaTransaction.expdate = expDate;
        if (this.existingCardId.value != 'none')
          this.deal.cardTokenId = +this.existingCardId.value;
        this.completePurchaseEmitter.emit(this.deal);
      } else {
        const invalid = [];
        const controls = this.paymentForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        this.markFormGroupTouched();
      }
    } // if (this.paymentMethodId == PaymentMethodEnum.ManualCreditCard)
    else if (this.paymentMethodId == PaymentMethodEnum.ManualCreditCard) {
      if (this.paymentForm.value.numberOfPayments) {
        this.deal.numPayments = this.paymentForm.value.numberOfPayments;
        this.deal.tranzillaTransaction.npay =
          this.paymentForm.value.numberOfPayments;

        this.completePurchaseEmitter.emit(this.deal);
      } else {
        this.markFormGroupTouched();
      }
    } else {
      this.deal.tranzillaTransaction.npay = '1';
      this.deal.numPayments = 1;
      this.completePurchaseEmitter.emit(this.deal);
    }
  }

  maximumPaymentError() {
    return this.amount.value > this.maxPaymentAmount;
  }
  minPaymentError() {
    return this.amount.value < 0;
  }

  cancelPurchase() {
    this.cancelPurchaseEmitter.emit();
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.paymentForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  createFormGroup() {
    this.paymentForm = new FormGroup({
      // paymentMethodId: new FormControl('none', [Validators.required]),
      creditTypeId: new FormControl(1, [Validators.required]),
      existingCardId: new FormControl('none', [Validators.required]),

      cardHolderName: new FormControl('', [Validators.required]),
      cardNumber: new FormControl('', [
        Validators.required,
        creditCardValidator,
      ]),
      expireDate: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
      socialSecurityNum: new FormControl('', [Validators.required]),
      creditCurrencyId: new FormControl(1, [Validators.required]),

      amount: new FormControl(this.deal.amount),

      numberOfPayments: new FormControl(1),
      firstPayment: new FormControl(this.deal.amount),
      allOtherPayments: new FormControl(0),
      invoiceName: new FormControl('', [Validators.required]),
      productName: new FormControl('', [Validators.required]),
    });

    setTimeout(() => {
      this.paymentFormReady = true;
    }, 100);
  }
  hideCustumerPlaceholder;
  fillCardFields() {
    if (this.existingCardId.value == 'none') {
      this.paymentForm.patchValue({
        cardHolderName: '',
        cardNumber: '',
        expireDate: '',
        cvv: '',
        socialSecurityNum: '',
        invoiceName: '',
        productName: '',
      });
      this.paymentForm.addControl(
        'cardNumber',
        new FormControl('', [Validators.required, creditCardValidator])
      );
      /*
      this.paymentForm.value.cardHolderName = '';
      this.paymentForm.value.cardNumber = '';
      this.paymentForm.value.expireDate = '';
      this.paymentForm.value.cvc = '';
      this.paymentForm.value.socialSecurityNum = '';
      */
    } else {
      var cardToken = this.client.clientCardTokens.filter((item) => {
        return item.cardTokenId == this.existingCardId.value;
      })[0];

      this.paymentForm.patchValue({
        cardHolderName: cardToken.cardHolderName,
        cardNumber: cardToken.cardNum,
        expireDate: cardToken.expireDate,
        cvv: 'cvv', // hardcoded for requierd field, no relevat because the server dont care about cvv in Card Toeknes
        socialSecurityNum: cardToken.cardHolderId,
        invoiceName:
          this.deal.dealBundle.client.regularUser.firstName +
          ' ' +
          this.deal.dealBundle.client.regularUser.lastName,
      });
      /*
      this.paymentForm.value.cardHolderName = "cardHolderName"
      this.paymentForm.value.cardNumber = cardToken.cardNum;
      this.paymentForm.value.expireDate = "expireDate"
      this.paymentForm.value.cvc = 'cvc';
      this.paymentForm.value.socialSecurityNum = 'socialSecurityNum';
      */
      this.paymentForm.removeControl('cardNumber');
    }
  }
  addNewProduct() {
    var o = new Object({ index: this.myProducts.length });
    this.myProducts.push(o);
  }
  updateProductData(event) {
    if (event.value.amount == 0) {
      this.myProducts.splice(event.value.index, 1);
    } else {
      this.childForms[event.value.index] = event;
    }
    this.productsTotal = 0;
    this.childForms.forEach((element) => {
      this.productsTotal += element.value.formTotal;
    });
  }
  /*
  get paymentMethodId() {
    return this.paymentForm.get('paymentMethodId');
  }*/
  get amount() {
    return this.paymentForm.get('amount');
  }
  get cardHolderName() {
    return this.paymentForm.get('cardHolderName');
  }
  get cardNumber() {
    return this.paymentForm.get('cardNumber');
  }
  get expireDate() {
    return this.paymentForm.get('expireDate');
  }
  get cvv() {
    return this.paymentForm.get('cvv');
  }
  get creditTypeId() {
    return this.paymentForm.get('creditTypeId');
  }
  get numberOfPayments() {
    return this.paymentForm.get('numberOfPayments');
  }
  get firstPayment() {
    return this.paymentForm.get('firstPayment');
  }

  get allOtherPayments() {
    return this.paymentForm.get('allOtherPayments');
  }
  get existingCardId() {
    return this.paymentForm.get('existingCardId');
  }
  get creditCurrencyId() {
    return this.paymentForm.get('creditCurrencyId');
  }
  get environment() {
    return environment;
  }
  getIsCreditCarValid() {
    return ValidateCreditCardNumber(this.cardNumber.value);
  }

  onCreditTypeChanged() {
    if (this.paymentForm.value.creditTypeId == 8) {
      this.calculateFirstAndSecond();
    }
  }
  onProductNameChanged() {}
  calculateFirstAndSecond() {
    var allOtherPayments = Math.floor(
      (this.amount.value * this.currencyModifier) / this.numberOfPayments.value
    );
    var first =
      this.amount.value * this.currencyModifier -
      allOtherPayments * (this.numberOfPayments.value - 1);

    //first = this.numberUtilsService.roundToNumDigits(first, 2);
    this.paymentForm.controls.firstPayment.setValue(first);
    this.paymentForm.controls.allOtherPayments.setValue(allOtherPayments);
  }
  calculateFirst() {
    var restAmoumt =
      this.allOtherPayments.value * (this.numberOfPayments.value - 1);
    var first = this.amount.value * this.currencyModifier - restAmoumt;
    first = this.numberUtilsService.roundToNumDigits(first, 2);
    this.paymentForm.controls.firstPayment.setValue(first);
  }
  calculateSecond() {
    var restAmoumt =
      this.amount.value * this.currencyModifier - this.firstPayment.value;
    var second = restAmoumt / (this.numberOfPayments.value - 1);
    second = this.numberUtilsService.roundToNumDigits(second, 2);
    this.paymentForm.controls.allOtherPayments.setValue(second);
  }
  calculatePrice() {
    var currencyId = +this.creditCurrencyId.value;
    switch (this.creditCurrencyId.value) {
      case 1:
        this.currencyModifier = 1;
      case 2:
        this.currencyModifier = this.excgangeRates['USD'];
      case 826:
        this.currencyModifier = this.excgangeRates['GBP'];
      case 978:
        this.currencyModifier = this.excgangeRates['EUR'];
    }
    this.calculateFirstAndSecond();
  }
  isShowNumPaymentsError() {
    var ret =
      this.isFormTouched &&
      ((this.paymentMethodId == 2 && !this.numberOfPayments.value) ||
        (this.paymentMethodId == 1 &&
          this.creditTypeId.value != 1 &&
          !this.numberOfPayments.value));
    return ret;
  }
  getTotalAmount() {
    return this.numberUtilsService.roundToNumDigits(
      this.deal.amount * this.currencyModifier,
      2
    );
  }
  private markFormGroupTouched() {
    this.isFormTouched = true;
    (<any>Object).values(this.paymentForm.controls).forEach((control) => {
      control.markAsTouched();
      /*
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
      */
    });
  }
  getLeftToPay() {
    var dealAmount = this.amount.value;
    if (+this.paymentMethodId !== PaymentMethodEnum.CreditCard) {
      //  dealAmount = this.amount.value;
    } else {
      // dealAmount = this.paymentForm.controls['amount'].value;
    }

    if (this.deal.dealBundle) {
      return this.deal.dealBundle.leftToPay - dealAmount;
    } else {
      return this.deal.dealBundle.amount - dealAmount;
    }
  }
  isValidAmount() {
    return +this.deal.amount > 0;
  }
}
