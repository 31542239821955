import { Injectable } from '@angular/core';
import { DashBoardStats } from '../data/DashBoardStats';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashBoardStatsService {
  constructor(private http: HttpClient) {}
  get() {
    return this.http.get<DashBoardStats>(
      `${environment.baseUrl}DashBoardStats/`
    );
  }
}
