<div>
  <mat-list-item class="row-container__item">
    <ng-container *ngIf="deal.dealBundle && deal.dealBundle.leftToPay > 0">
      <div *ngFor="let deal of deal.dealBundle.deals">
        <ng-container
          *ngIf="deal.paymentMethodId && deal.statusId == DealStatusEnum.Done"
        >
          Payed: <strong>{{ deal.amount | currency: "ILS" }}</strong>
          <ng-container *ngIf="paymentMethods">
            {{ getPaymentMethodName(deal.paymentMethodId) }}</ng-container
          >
          <!--  <div [ngSwitch]="deal.paymentMethod.paymentMethodId">
            <div *ngSwitchCase="1">
              
              Payed: <strong>₪{{ deal.amount }}</strong> -
              {{ deal.paymentMethod.name }}
              <div *ngFor="let card of deal.dealHistories">
                <ng-container *ngIf="getLastFourDigits(card)">
                  card: {{ getLastFourDigits(card) }}
                </ng-container>
              </div>
              <span style="color: red" *ngIf="deal.statusId != 3">
                - {{ deal.status.name }}
              </span>
            </div>
            <div *ngSwitchCase="2">
              
              Payed: <strong>₪{{ deal.amount }}</strong> -
              {{ deal.paymentMethod.name }}
              <span style="color: red" *ngIf="deal.statusId != 3">
                - {{ deal.status.name }}  
              </span>
            </div>
            <div *ngSwitchDefault>output2</div>
          </div> -->
        </ng-container>
      </div>
    </ng-container>
    <div class="d-flex-center">
      <span class="boldSpan"> Payment Method: </span>
      <mat-form-field class="example-full-width round-select">
        <mat-select
          (selectionChange)="paymentMethodChanged = true"
          placeholder="Choose Payment Method"
          [(ngModel)]="paymentMethodId"
          disableOptionCentering
          #payMethodSelect
        >
          <mat-option
            *ngFor="let paymentMethod of paymentMethods"
            value="{{ paymentMethod.paymentMethodId }}"
          >
            {{ paymentMethod.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-list-item>
  <form
    [formGroup]="paymentForm"
    (ngSubmit)="onCompletePurchase()"
    class="example-form"
  >
    <div class="row-container" *ngIf="paymentFormReady">
      <ng-container
        *ngIf="
          paymentMethodChanged &&
          paymentMethodId != PaymentMethodEnum.CreditCard
        "
        ><mat-list-item class="row-container__item">
          <div style="display: flex">
            <div style="margin-right: 20px">
              Partial payment
              <br />
              <mat-form-field class="example-full-width">
                <input
                  type="number"
                  min="0"
                  [max]="maxPaymentAmount"
                  class="edit-field"
                  matInput
                  formControlName="amount"
                />
              </mat-form-field>
              <div *ngIf="maximumPaymentError()" style="color: #f44336">
                Maximum payment is {{ maxPaymentAmount | number: "1.0" }}
              </div>
              <div *ngIf="minPaymentError()" style="color: #f44336">
                Minimum payment is 0
              </div>
            </div>
            <div>
              <div style="height: 50px">Left To Pay</div>
              {{ getLeftToPay() }}
            </div>
          </div>
        </mat-list-item></ng-container
      >

      <mat-list-item class="row-container__item">
        <div class="row-container__item__cc">
          <ng-container *ngIf="paymentMethodId == PaymentMethodEnum.CreditCard">
            <div class="boldSpan">Payment Details:</div>
            <mat-form-field
              class="example-full-width"
              *ngIf="existingCardId.value == 'none'"
            >
              <input
                class="edit-field"
                matInput
                formControlName="cardHolderName"
                placeholder="Card Holder Name"
                autocomplete=""
              />
            </mat-form-field>
            <mat-form-field
              class="example-full-width"
              *ngIf="existingCardId.value == 'none'"
            >
              <input
                [class.input-error]="
                  cardNumber.dirty == true && !getIsCreditCarValid()
                "
                class="edit-field"
                mask="0000 0000 0000 0000"
                matInput
                formControlName="cardNumber"
                placeholder="Card Number"
              />
            </mat-form-field>
            <br />
            <mat-form-field
              class="example-full-width"
              *ngIf="existingCardId.value == 'none'"
            >
              <input
                class="edit-field"
                matInput
                mask="00/00"
                formControlName="expireDate"
                placeholder="Expire Date"
              />
              <img
                src="../../../assets/calendar.svg"
                style="position: absolute; top: 33%; right: 5%"
              />
            </mat-form-field>
            <mat-form-field
              class="example-full-width"
              *ngIf="existingCardId.value == 'none'"
            >
              <input
                class="edit-field"
                matInput
                mask="000"
                formControlName="cvv"
                placeholder="CVV"
              />
            </mat-form-field>
            <br />

            <mat-form-field
              class="example-full-width"
              *ngIf="existingCardId.value == 'none'"
            >
              <input
                class="edit-field"
                matInput
                mask="000000000"
                formControlName="socialSecurityNum"
                placeholder="Card Holder Id"
              />

              <mat-error *ngIf="invalidIdNum"> Invalid Id </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width regular-select"
              style="position: relative; top: -15px"
            >
              <mat-select
                (selectionChange)="calculatePrice()"
                placeholder="Currency"
                class="rect-select"
                formControlName="creditCurrencyId"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let currency of creditCurrencies"
                  [value]="currency.creditCurrencyId"
                >
                  {{ currency.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <div class="form-error" *ngIf="invalidIdNum">Invalid Id Num</div>
            <hr />
            Invoice Details
            <br />
            <mat-form-field class="example-full-width">
              <input
                class="edit-field"
                matInput
                formControlName="invoiceName"
                placeholder="Name On Invoice"
              />
            </mat-form-field>
            <br />
            <mat-form-field class="example-full-width round-select">
              <div class="d-flex-center" style="width: 400px">
                <mat-select
                  (selectionChange)="onProductNameChanged()"
                  placeholder="Product Name on Inovice"
                  formControlName="productName"
                >
                  <mat-option
                    *ngFor="let status of alternateProductNames"
                    [value]="status.name"
                  >
                    {{ status.name }}
                  </mat-option>
                </mat-select>
              </div>
            </mat-form-field>

            <br />
            <hr />
            <ng-container *ngIf="environment.isAditionPayment">
              <div style="display: flex">
                <div>
                  Partial payment
                  <br />
                  <mat-form-field class="example-full-width">
                    <input
                      (change)="calculateFirstAndSecond()"
                      class="edit-field"
                      matInput
                      formControlName="amount"
                      type="number"
                      min="0"
                      [max]="maxPaymentAmount"
                    />
                  </mat-form-field>
                </div>
                <div>
                  <p>Left to pay:</p>
                  ₪<strong>{{ getLeftToPay() }}</strong>
                </div>
              </div>
              <br />
            </ng-container>
            <mat-form-field class="example-full-width round-select">
              <div class="d-flex-center" style="width: 300px">
                <span class="boldSpan" style="width: 130px">
                  Credit Type:
                </span>
                <br />
                <mat-select
                  (selectionChange)="onCreditTypeChanged()"
                  placeholder="Choose Credit Method"
                  formControlName="creditTypeId"
                  disableOptionCentering
                >
                  <mat-option
                    *ngFor="let type of creditTypes"
                    [value]="type.creditTypeId"
                  >
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </div>
              <!--
              <mat-error *ngIf="paymentMethodId.errors && paymentMethodId.errors.required">
                Please select Custumer Type
              </mat-error>
            -->
            </mat-form-field>
            <br />
          </ng-container>
          <div
            *ngIf="
              creditTypeId.value == CreditTypeEnum.Credit ||
              (paymentMethodId == PaymentMethodEnum.CreditCard &&
                creditTypeId.value == CreditTypeEnum.Payments) ||
              paymentMethodId == PaymentMethodEnum.ManualCreditCard
            "
          >
            <div>
              <div class="d-flex-center" style="width: 100%">
                <span class="boldSpan"> Number Of Payments : </span>

                <mat-form-field
                  class="example-full-width round-select"
                  style="width: 100px"
                >
                  <mat-select
                    (selectionChange)="calculateFirstAndSecond()"
                    placeholder="# Payments"
                    formControlName="numberOfPayments"
                    disableOptionCentering
                    ><mat-option
                      [value]="1"
                      *ngIf="
                        paymentMethodId == PaymentMethodEnum.ManualCreditCard
                      "
                    >
                      1
                    </mat-option>
                    <mat-option
                      *ngFor="let num of numPaymentsList"
                      [value]="num"
                    >
                      {{ num }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="form-error" *ngIf="isShowNumPaymentsError()">
                  Please Select Number of Payments
                </span>
              </div>
            </div>
            <div>
              <div
                *ngIf="paymentMethodId == PaymentMethodEnum.ManualCreditCard"
              >
                <span style="color: #119c99"> Notice! </span>the number of
                payments should be the same number you entered in Tranzila
              </div>
            </div>
            <div style="display: flex" *ngIf="numberOfPayments.value > 1">
              <div
                style="width: 25%; margin-right: 10px"
                *ngIf="creditTypeId.value == CreditTypeEnum.Payments"
              >
                <span style="font-size: 12px"> First Payment</span>

                <br />
                <mat-form-field class="example-full-width" style="width: 100%">
                  <input
                    (change)="calculateSecond()"
                    class="edit-field"
                    matInput
                    formControlName="firstPayment"
                    placeholder="Holder Id"
                  />
                </mat-form-field>
              </div>
              <div
                style="width: 25%"
                *ngIf="creditTypeId.value == CreditTypeEnum.Payments"
              >
                <span style="font-size: 12px">All other Payments</span>

                <br />
                <mat-form-field class="example-full-width" style="width: 100%">
                  <input
                    (change)="calculateFirst()"
                    class="edit-field"
                    matInput
                    formControlName="allOtherPayments"
                    placeholder="Card Golder Id"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="paymentMethodId == PaymentMethodEnum.CreditCard">
          <div
            class="form-error"
            *ngIf="
              (cardHolderName.touched &&
                cardHolderName.errors &&
                cardHolderName.errors.required) ||
              (expireDate.touched &&
                expireDate.errors &&
                expireDate.errors.required) ||
              (cvv.touched && cvv.errors && cvv.errors.required)
            "
          >
            Please Fill Payment Details
          </div>
        </ng-container>
        <div class="form-error" *ngIf="!isValidAmount()">invalid Amount</div>
        <br />
      </mat-list-item>
    </div>
  </form>
</div>

<div
  class="purchase-action"
  style="background-color: #fff; position: sticky; bottom: 0"
>
  <div class="purchase-action__text" style="width: 100%">
    Total Amount: <strong>{{ amount.value }}</strong>
    <div class="divider divider--client"></div>
  </div>
  <div style="display: flex; justify-content: space-around; width: 100%">
    <button
      class="purchase-action__btn"
      [class.disabled]="
        !paymentMethodChanged || maximumPaymentError() || minPaymentError()
      "
      (click)="onCompletePurchase()"
    >
      Next
    </button>
    <button class="purchase-action__btn" (click)="cancelPurchase()">
      Cancel
    </button>
  </div>
</div>
