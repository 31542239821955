import { Food } from './Food';
import { Language } from './Language';

export class FoodLang {
  foodLangId: number;
  foodId: number;
  food: Food;
  languageId: number;
  language: Language;
  name: string;
  description: string;
}
