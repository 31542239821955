import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Language } from 'src/app/data/Language';
import { LifestyleAnswerMin } from 'src/app/data/LifestyleAnswerMin';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { QuestionDesignType } from 'src/app/Enums/StatusesEnum';
import { LanguagesService } from 'src/app/Services/languages.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { refAnsweredUtil } from 'src/app/Utils/lifestyleUtil';

@Component({
  selector: 'app-lifestyle-report-preview',
  templateUrl: './lifestyle-report-preview.component.html',
  styleUrls: ['./lifestyle-report-preview.component.scss'],
})
export class LifestyleReportPreviewComponent implements OnInit {
  productId: number;

  langId: number = 1;
  langs: Language[];
  showIndex: number = 0;
  important: LifestyleQuestion[];
  rest: LifestyleQuestion[];
  isLoading: boolean;
  isPreview: boolean = true;
  //lifestyleQuestions: any;
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private route: ActivatedRoute,
    private lansService: LanguagesService
  ) {}

  ngOnInit(): void {
    this.productId = +this.route.snapshot.queryParamMap.get('productId');
    this.getQuestions();
    this.getLangs();
  }

  getLangs() {
    this.lansService.getAll().subscribe((data) => (this.langs = data));
  }

  onIcrementIndex(index) {
    //console.log('onIcrementIndex', index);
    //this.setIndex(index);
  }

  getQuestions() {
    this.lifestyleQuestionsService
      .getByProductIdForPreview(this.productId, this.langId)
      .subscribe((data) => {
        this.important = data.important;
        this.rest = data.rest;
        this.setIndex(this.showIndex);
      });
    /*
    this.productId$.subscribe((data) => {
      if (data) {
        this.productId = data;
        this.store.dispatch(
          loadLifestyleQuestions({
            productId: this.productId,
            langId: this.langId,
          })
        );
      }
    });
    this.lifestyleQuestions$.subscribe((data) => {
      let hasData = this.lifestyleQuestions ? true : false;
      if (data) {
        this.lifestyleQuestions = data;
        if (!hasData) this.setIndex(this.showIndex);
      }
    });
    */
  }

  hasRefId(question: LifestyleQuestion) {
    if (question.referenceQuestionId) {
      return true;
    }
    return false;
  }
  refAnswered(refId) {
    //console.log('refAnswered from root');
    return refAnsweredUtil(this.important, refId);
  }

  isValid(node: LifestyleQuestion) {
    /* if (
      node.referenceQuestionId &&
      this.refAnswered(node.referenceQuestionId)
    ) {
      return true;
    } */
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter
    ) {
      return false;
    }
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }

    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }

    //console.log('is valid node:', node.lifestyleQuestionId);

    return true;
  }

  setIndex(index) {
    console.log('setIndex', index);

    this.showIndex = 0;
    while (
      this.rest[this.showIndex] &&
      this.isValid(this.rest[this.showIndex])
    ) {
      if (this.isValid(this.rest[this.showIndex])) {
        //console.log('setIndex2', this.showIndex);
        if (this.rest[this.showIndex].referenceQuestionId) {
          if (this.refAnswered(this.rest[this.showIndex].referenceQuestionId)) {
            this.showIndex++;
          } else {
            this.showIndex += 2;
          }
        } else {
          this.showIndex++;
        }
        //      console.log('setIndex end', this.showIndex);
      }
    }
  }
  trackByQuestionId(index, question: LifestyleQuestion) {
    return question ? question.lifeStyleReportVersionId : undefined;
  }
  submitAnswers() {}
  /*
  OnUpdateAnswer(event) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .updateAnswer(
        this.productId,
        event.questionId,
        event.inputTypeId,
        event.value
      )
      .subscribe((answers) => {
        this.lifestyleQuestions = this.mergeQuestionsWithAnswers(
          answers,
          this.lifestyleQuestions
        );
        this.setIndex(this.showIndex);
      });
  }*/
  mergeQuestionsWithAnswers(
    answers: LifestyleAnswerMin[],
    questions: LifestyleQuestion[]
  ) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];

      element.lifeStyleAnswers = answers.filter(
        (x) => x.lifestyleQuestionId == element.lifestyleQuestionId
      );
      if (!element.lifeStyleAnswers) element.lifeStyleAnswers = [];
      if (element.childQuestions)
        element.childQuestions = this.mergeQuestionsWithAnswers(
          answers,
          element.childQuestions
        );
    }
    return questions;
  }
  convertAnwerMintoAnswer(answerMin: LifestyleAnswerMin) {}
}
