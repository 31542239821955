import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AgentInteraction } from './data/AgentInteraction';
import { FireBaseCall } from './data/FireBaseCall';
import { GlobalParam } from './data/GlobalParam';
import { IndicatorMsg } from './data/indicator-msg';
import { RegularUser } from './data/RegularUser';
import { DisapearingTextDialogEnum } from './Enums/DisapearingTextDialogEnum';
import { AgentStatusEnum } from './Enums/StatusesEnum';
import { Globals } from './globals';
import { AgentInteractionService } from './Services/agent-interaction.service';
import { FirebaseCallsService } from './Services/firebase-calls.service';
import { FirebaseChatIndicatorService } from './Services/firebase-chat-indicator.service';
import { PopupHandlerService } from './Services/popup-handler.service';
import { RegularUsersService } from './Services/regular-users.service';
import { ServiceRequestsService } from './Services/service-requests.service';
import { userHasPermisions } from './Utils/user-helper';
import { AgentSettingsService } from './Services/agent-settings.service';
import { AgentSettings } from './data/AgentSettings';
import { FirebaseAnonimousAuthService } from './Services/firebase-anonimous-auth.service';
import { UnreadMessage } from './data/chat/UnreadMessage';
import { NewSession } from './data/chat/NewSession';
import { MatSnackBar } from '@angular/material/snack-bar';

declare const ga: any;
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  lastMainUrl;
  showDebugMenu: boolean = false;
  regularUser: RegularUser;
  isOutDated = false;
  isFarLogin: boolean; //= false;
  userClicked: boolean;
  userNotClicked: boolean;
  userInactive: boolean;
  timeoutID: any;
  timeout1: any;
  timeoutID2: any;
  closeCallPopup: boolean;
  globalParam: GlobalParam[];
  PayCallPopupActive: boolean;
  isPayCallpopupOpen: boolean = false;
  farLoginReportTime: number = 1000 * 60 * 5;
  farLoginInactiveTime: number = 1000 * 60 * 15;
  agentInteraction: AgentInteraction;
  chatIndicator: IndicatorMsg[];
  chatAlerts: number;
  agentSettings: AgentSettings;

  constructor(
    private router: Router,
    private regularUsersService: RegularUsersService,
    private popupHandlerService: PopupHandlerService,
    private route: ActivatedRoute,
    private loc: Location,
    private firebaseCallsService: FirebaseCallsService,
    private agentInteractionsService: AgentInteractionService,
    public firebaseService: FirebaseChatIndicatorService,
    private serviceRequestsService: ServiceRequestsService,
    private agentSettingsService: AgentSettingsService,
    private anonAuthService: FirebaseAnonimousAuthService,
    private _matSnackBar: MatSnackBar
  ) {
    this.showDebugMenu = Globals.showDebugMenu;
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }

  handleReportFarLoginAndTimer() {
    this.isFarLogin = this.regularUser && this.regularUser.isFarLogin;
    if (!this.regularUser) return;
    if (this.regularUser && this.isFarLogin) {
      setInterval(() => {
        this.regularUsersService.reportFarLoginAlive().subscribe();
      }, 1000 * 5 * 60);
      this.regularUsersService.reportFarLoginAlive().subscribe();
      this.addTimerListener();
      this.startTimer();
    }
  }
  reportFarLoginAlive() {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      this.isFarLogin = this.regularUser && this.regularUser.isFarLogin;
      if (!this.regularUser) return;
      if (this.regularUser && this.isFarLogin) {
        setInterval(() => {
          this.regularUsersService
            .reportFarLoginAlive()
            .subscribe((data) => {});
        }, 1000 * 5 * 60);
        this.regularUsersService.reportFarLoginAlive().subscribe((data) => {});
        this.addTimerListener();
        this.startTimer();
      }
    });
  }

  addTimerListener() {
    window.addEventListener('mousemove', () => this.resetTimer(), true);
    window.addEventListener('mousedown', () => this.resetTimer(), true);
    window.addEventListener('keypress', () => this.resetTimer(), true);
    window.addEventListener('DOMMouseScroll', () => this.resetTimer(), true);
    window.addEventListener('touchmove', () => this.resetTimer(), true);
    window.addEventListener('mousewheel', () => this.resetTimer(), true);
    window.addEventListener('MSPointerMove', () => this.resetTimer(), true);
  }
  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   if (params) {
    //     this.closeCallPopup = params['closeCallPopup'] === 'true';
    //   }
    // });
    // if (userHasPermisions(['CallIndicator_GetList'], this.regularUser)) {
    //   this.getCallIndicator();
    // }
    if (userHasPermisions(['AgentInteraction_GetList'], this.regularUser)) {
      this.getAgentInteraction();
    }
    if (this.regularUser) {
      this.getAgentSettings();
    }

    // if (userHasPermisions(['Chat_GetIndicator'], this.regularUser)) {
    //   this.getChatAlert();
    // }
  }

  getAgentSettings() {
    this.agentSettingsService
      .getAgentSettings(this.regularUser.agent.agentId, 1)
      .subscribe((data) => {
        if (data?.agentId) {
          this.agentSettings = data;
          if (this.agentSettings.isChatAvailable) {
            this.loginToFirebaseAndStartListen();
          }
        }
      });
  }

  getCallIndicator() {
    this.firebaseCallsService.getCallIndicator().subscribe((data) => {
      var calls: FireBaseCall[] = [];
      if (environment.payCallPopupActive && data) {
        /*  data.forEach((x) => {
          calls[x.payload.doc.id] = x.payload.doc.data();
        }); */
        calls = Object.values(data);
        const agentId =
          this.regularUsersService.currentUserValue?.agent?.agentId;
        if (!agentId) return;

        var myCalls = calls.filter((x) => x.DestinationAgent == agentId);
        if (myCalls.length == 0) {
          if (this.isPayCallpopupOpen) {
            this.isPayCallpopupOpen = false;
            this.popupHandlerService.closeIncomeCallDialog();
          }
        } else {
          var firstCall = myCalls[0];
          if (!this.isPayCallpopupOpen) {
            this.isPayCallpopupOpen = true;
            this.popupHandlerService.openIncomeCallDialog(firstCall);
          }
        }
      }
    });
  }

  loginToFirebaseAndStartListen() {
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (data) {
        this.listenToNewSession();
        this.listenToUnreadMessages();
      }
    });
  }
  listenToUnreadMessages() {
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (data) {
        this.firebaseService
          .listenToUnreadMessages(this.agentSettings.agentId)
          .subscribe((unreadList: UnreadMessage[]) => {
            if (unreadList) {
              this.firebaseService.unreadMessages$.next(unreadList);
              // get unread messages count
              let count = 0;
              unreadList.forEach((x) => {
                if (x?.messagesCount > 0) {
                  count += x.messagesCount;
                }
              });
              this.firebaseService.unreadMessagesCount$.next(count);
              // show snackbar only if there is new unread message
              if (count > 0) {
                //check if the route is not chat
                if (this.loc.path() != '/chat') {
                  this._matSnackBar
                    .open('You have new unread message', 'OK', {
                      horizontalPosition: 'left',
                    })
                    ._dismissAfter(5000);
                }
              }
            }
          });
      }
    });
  }

  listenToNewSession() {
    this.firebaseService
      .lisetnToNewSession(this.regularUser.agent.agentId)
      .subscribe((data: NewSession[]) => {
        console.log('listenToNewSession out', data);
        if (data) {
          console.log('listenToNewSession in', data);
          this.firebaseService.waitingList$.next(data);
        }
      });
  }

  getAgentInteraction() {
    this.agentInteractionsService
      .getById(this.regularUser.agent.agentId)
      .subscribe(
        (res) => {
          this.agentInteraction = res;
        },
        (error) => {
          this.agentInteraction = null;
        }
      );
  }
  onChangeAgentInteraction(statusId: number) {
    //console.log('onChangeAgentInteraction', statusId);

    this.changeAgentInteraction(statusId);
  }
  changeAgentInteraction(statusId: number) {
    if (this.agentInteraction && this.regularUser) {
      this.agentInteraction = new AgentInteraction();
      this.agentInteraction.agentId = this.regularUser.agent.agentId;
      this.agentInteraction.agentInteractionId = 0;
    }
    if (this.agentInteraction) {
      this.agentInteraction.statusId = statusId;
      this.agentInteractionsService.post(this.agentInteraction).subscribe(
        (data) => {
          this.getAgentInteraction();
        },
        (error) => {
          this.agentInteraction = null;
        }
      );
    }
  }

  redirectAfterLogin() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-190YLYBCD1', {
          page_path: event.urlAfterRedirects,
        });
        if (
          event.url.split('?')[0] === '/login' ||
          event.url.split('?')[0] === '/'
        ) {
          this.regularUsersService.currentUser.subscribe((regularUser) => {
            this.regularUser = regularUser;
            if (this.regularUser == null) {
              console.log('[LoadingPageComponent] navigate login');
              this.router.navigate(['login']);
            } else {
              if (userHasPermisions(['Leads_GetList'], this.regularUser)) {
                this.router.navigate(['leads']);
              } else if (
                userHasPermisions(['Patients_GetList'], this.regularUser)
              )
                this.router.navigate(['tests']);
            }
          });
        }
      }
    });
  }

  isDashboard() {
    return (
      this.router.url === '/dashboard' ||
      this.router.url === '/sales-call-statistics' ||
      this.router.url === '/support-dashboard'
    );
  }

  startTimer() {
    this.timeoutID = setTimeout(() => this.goInactive(), 1000000);
  }
  resetTimer() {
    clearTimeout(this.timeoutID);
    this.startTimer();
  }
  goInactive() {
    if (this.regularUser && this.isFarLogin) {
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((res) => {
          if (!res.ans || res.ans === 'no') {
            this.regularUsersService.endSession().subscribe((data) => {});
            this.regularUsersService.logout();
          } else {
            this.resetTimer();
          }
        });

      this.popupHandlerService.openConfirmationDialog(
        '',
        'We discovered inactivity, do you want to stay in the system?',
        '',
        5000
      );
    }
  }
  /*
  reportFarLoginAlive() {
    this.regularUsersService.reportFarLoginAlive().subscribe((data) => {});
  }*/

  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
  nudgeVersionOutdated() {
    this.popupHandlerService.openDisapearingAlertDialog(
      DisapearingTextDialogEnum.outDatedVersion
    );
  }
  checkVersions() {
    this.regularUsersService.getVersion().subscribe((version) => {
      if (+version != environment.version) {
        this.isOutDated = true;
        setInterval(() => this.nudgeVersionOutdated(), 30000);
      }
    });
  }
  getClassNavName(value) {
    if (this.loc.path().split('/').includes('labDeliveries')) {
      var url = this.loc.path().split('/')[2];
    }
    if (this.loc.path().split('/').includes('debug')) {
      var url = this.loc.path().split('/')[2];
    } else {
      var url = this.loc.path().split('/')[1];
    }

    //var url = this.router.url.replace('/', '');
    this.lastMainUrl = url;

    if (value.includes(this.lastMainUrl)) {
      return this.lastMainUrl;
    } else if (value === 'divider') {
      return this.lastMainUrl;
    }
  }
  getClassName() {
    if (
      this.router.url === '/editGenesData' ||
      this.router.url === '/algorithm' ||
      this.router.url === '/food-genes' ||
      this.router.url === '/support-dashboard' ||
      this.router.url === '/sales-call-statistics' ||
      this.router.url === '/dashboard' ||
      this.router.url === '/scripts'
    ) {
      return '';
    } else if (
      this.router.url === '/tests' ||
      this.router.url === '/triggerEvents'
    ) {
      return 'container-wide';
    } else return 'container';
  }
}
