import { Component, OnInit } from '@angular/core';
import { RoleTypeForTable } from 'src/app/data/RoleTypeForTable';
import { RoleTypesService } from 'src/app/Services/role-types.service';

@Component({
  selector: 'app-all-roles',
  templateUrl: './all-roles.component.html',
  styleUrls: ['./all-roles.component.css'],
})
export class AllRolesComponent implements OnInit {
  roleTypes: RoleTypeForTable[];
  constructor(private roleTypesService: RoleTypesService) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.roleTypesService.getAllForTable().subscribe((data) => {
      this.roleTypes = data;
     // console.table(this.roleTypes);
    });
  }
}
