import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getNumberOfCurrencyDigits } from '@angular/common';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { TubeTest } from '../data/TubeTest';
import { TubeTestMinPackage } from '../data/TubeTestMinPackage';
import { ErrorMessage } from '../data/ErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class TubeTestsService {
  constructor(private http: HttpClient) {}
  getbyId(id) {
    return this.http.get<TubeTest>(`${environment.baseUrl}TubeTests/${id}`);
  }

  addTube(tubesPackageId: number, barcode: string) {
    return this.http.get<TubeTest | ErrorMessage>(
      `${environment.baseUrl}TubesPackages/AddTube/${tubesPackageId}/${barcode}`
    );
  }
  removeTube(barcode: string) {
    return this.http.get<TubeTest>(
      `${environment.baseUrl}TubesPackages/RemoveTube/${barcode}`
    );
  }
  getBarcodesAutocompleteForOffice() {
    return this.http.get<string[]>(
      `${environment.baseUrl}TubeTests/BarcodesAutocomplete/Office`
    );
  }
  getBarcodesAutocompleteForLab(packageId) {
    return this.http.get<string[]>(
      `${environment.baseUrl}TubeTests/BarcodesAutocomplete/Lab/${packageId}`
    );
  }
  getByBarcode(barcode) {
    return this.http.get<TubeTest>(
      `${environment.baseUrl}TubeTests/${barcode}`
    );
  }

  getByPackage(packageId) {
    return this.http.get<TubeTestMinPackage[]>(
      `${environment.baseUrl}TubeTests/ByPackage/${packageId}`
    );
  }
  put(tubeTest: TubeTest) {
    return this.http.put(
      `${environment.baseUrl}TubeTests/${tubeTest.barcode}`,
      tubeTest
    );
  }
  /*
  markAsRecieved(tubeTest: TubeTest) {
    return this.http.put(
      `${environment.baseUrl}TubeTests/markAsRecieved/${tubeTest.barcode}`,
      tubeTest
    );
  }*/
}
