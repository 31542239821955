import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UploadResultsCsvService } from 'src/app/Services/upload-results-csv.service';

@Component({
  selector: 'app-create-dna-pdf',
  templateUrl: './create-dna-pdf.component.html',
  styleUrls: ['./create-dna-pdf.component.css'],
})
export class CreateDnaPdfComponent implements OnInit {
  barcodes: string[];
  constructor(private uploadResultsCsvService: UploadResultsCsvService) {
    /* var pdfFonts = require('../../../../assets/js/vfs_fonts.js') ;
    pdfMake.vfs = pdfFonts.pdfMake.vfs; */
  }
  @ViewChild('pdfReport') pdfReport: ElementRef;

  ngOnInit(): void {
    this.barcodes = ['181856511'];
    // this.barcodes = ['56002005700262']; //for prod
    /* this.uploadResultsCsvService.GetDnaPdfData(barcodes).subscribe((data) => {
      this.uploadDnaPdfs(data);
    }); */
  }
  sendDataToPhp() {
    this.uploadResultsCsvService
      .sendDnaDatatoPhp(this.barcodes)
      .subscribe((res) => {});
  }
  generateDnaPdfs() {
    this.uploadResultsCsvService.generateDnaPdfs().subscribe((res) => {});
  }
  public captureScreen() {
    const pdfReport = this.pdfReport.nativeElement;
    var data = document.getElementById('pdfReport');
    /*
    html2canvas(pdfReport).then((canvas) => {
      var doc = new jsPDF();

      doc.addFileToVFS('Arimo-Regular-normal.ttf', Arimo);
      doc.addFont('Arimo-Regular-normal.ttf', 'Arimo', 'normal');
      doc.setFont('Arimo');

      var arabicText = '<p>דסזגדג</p><p>דגדג</p>';

      doc.html(data.innerHTML, {
        callback: function (doc) {
          doc.save();
        },
        x: 10,
        y: 10,
      });

      /* doc.html(arabicText);
      doc.save('MYPdf.pdf'); */

    // Few necessary setting options
    /* var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
      var format = "PNG";
      var x = 0; 
      var y = 0; 
      var alias = 'alias';
      var compression = 'SLOW';
      var rotation = 90;

      //const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'in'); // A4 size page of PDF  
      //pdf.addImage(contentDataURL, format, x, y, imgWidth, imgHeight, compression, rotation )  
      //var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

      var imgData  = canvas.toDataURL("image/jpeg");
      pdf.addImage(imgData, 'JPEG', 0, 0, 1, 1);
      pdf.save('MYPdf.pdf'); // Generated PDF    
    });
*/
  }
  /* uploadDnaPdfs(reportData: BarcodeResultsGroup) {
    var pdfs = [];
    
    data.forEach((group) => {
      var pdfData = this.generatePdf(group);
      pdfs.push(pdfData);
    });
    var examplePdf = this.generatePdf(data[0]);

   pdfMake.fonts = {
      Arimo: {
        normal: 'Arimo-Regular.ttf',
        bold: 'Arimo-Bold.ttf',
        italics: 'Arimo-Regular.ttf',
        bolditalics: 'Arimo-Bold.ttf',
      },
    };

    let docDefinition = {
      content: [
        'טקסט בעברית',
      ],
      defaultStyle: {
        font: 'Arimo',
        bold: true
      },
    };

    reportData.results.forEach((result) => {
      let txt = result.subCategoryResult.description;
      docDefinition.content.push(txt);
    });
    
    pdfMake.createPdf(docDefinition).download('document.pdf');
  }

   generatePdf(reportData: BarcodeResultsGroup) {
    let docDefinition = {
      header: 'C#Corner PDF Header',
      content: [
        {
          text:
            'Sample PDF generated with Angular and PDFMake for C#Corner Blog',
        },
      ],
    };
    reportData.results.forEach((result) => {
      let txt = this.heDecode(result.subCategoryResult.description);
      docDefinition.content.push({
        text: this.decodeHTMLEntities(txt),
      });
    });
    return docDefinition;
  } */

  /* heDecode(txt) {
    var he = require('he');
    return he.decode(txt);
  } */
  generatePdf() {
    this.uploadResultsCsvService
      .generatePdfs(this.barcodes)
      .subscribe((res) => {});
  }
}
