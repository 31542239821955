import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //console.log(data);
    this.dialogRef.disableClose = true;
  }

  getTitle() {
    switch (this.data?.title) {
      case 'transactions':
        return 'Cancel Transaction';
      case 'tests':
        return 'Delete Tests';
      case 'requests':
        return 'Are you sure you want to do this action?';
      default:
        return this.data.title;
    }
  }

  ngOnInit(): void {}
  onNoClick() {
    this.dialogRef.close({ event: 'close', data: { answer: 'no' } });
  }
  onYesClick() {
    this.dialogRef.close({ event: 'close', data: { answer: 'yes' } });
  }
}
