import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ArticleCategory } from 'src/app/data/ArticleCategory';
import { ArticleBullet } from 'src/app/data/ArticleBullet';
import { ArticleSubCategory } from 'src/app/data/ArticleSubCategory';
import { ArticleCategoriesService } from 'src/app/Services/article-categories.service';
import { ArticleSubCategoriesService } from 'src/app/Services/article-sub-categories.service';
import { ArticleBulletsService } from 'src/app/Services/article-bullets.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { RegularUser } from 'src/app/data/RegularUser';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit {
  @ViewChild('categoryNameInput') categoryNameInput: ElementRef;
  @ViewChild('subCategoryNameInput') subCategoryNameInput: ElementRef;
  @ViewChild('subSubCategoryNameInput') subSubCategoryNameInput: ElementRef;
  @ViewChild('bulletTitleInput') bulletTitleInput: ElementRef;
  @ViewChild('bulletDescInput') bulletDescInput: ElementRef;
  articleCategries: ArticleCategory[];
  articleSubCategries: ArticleSubCategory[];
  editModeCategoryName: boolean = false;
  editModeSubCategoryName: boolean = false;

  isChangedcategoryOrder: boolean = false;
  contentEditMode: boolean = false;
  bulletEditMode: boolean = false;
  categoryIdToEdit: number;
  isBulletSubCategory: boolean;
  subCategoryToShow: ArticleSubCategory;
  subCategoryIdToEdit: number;

  subSubCategoryIdToEdit: number;
  editModeSubSubCategoryName: boolean = false;

  subCategoryIdToEditInner: number;
  bulletIdToEdit: number;
  orderValues: number[] = [];
  maxOrder: number;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  currentUser: RegularUser;
  newSubSubToEdit: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    minHeight: '350px',
  };
  constructor(
    private articleCategriesService: ArticleCategoriesService,
    private articleSubCategriesService: ArticleSubCategoriesService,
    private articleBulletsService: ArticleBulletsService,
    private regularUsersService: RegularUsersService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
    // this.getAllSubCategories();
    this.regularUsersService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  canEdit() {
    return userHasPermisions(['Knowledge_Update'], this.currentUser);
  }

  sanitizeHTML(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getAllCategories() {
    this.articleCategriesService.getAll().subscribe((data) => {
      this.articleCategries = data;
      this.orderValues = [];
      this.maxOrder = Math.max.apply(
        Math,
        this.articleCategries.map((o) => o.order)
      );
      var a = this.maxOrder;
      for (var i = 1; i <= this.maxOrder; i++) this.orderValues.push(i);
      var b = this.orderValues;
    });
  }
  getAllSubCategories() {
    this.articleSubCategriesService.getAll().subscribe((data) => {
      this.articleSubCategries = data;
    });
  }
  preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  //Main Categories
  toggleEditModeCategoryName(categoryId: number = null) {
    this.editModeCategoryName = !this.editModeCategoryName;

    if (this.editModeCategoryName) {
      this.categoryIdToEdit = categoryId;
      setTimeout(() => this.categoryNameInput.nativeElement.focus(), 900);
    } else {
      this.getAllCategories();
      //this.categoryIdToEdit = categoryId;
    }
  }
  addCategory() {
    var articleCategory = new ArticleCategory();
    this.articleCategriesService.post(articleCategory).subscribe((data) => {
      this.getAllCategories();
      this.toggleEditModeCategoryName(data.articleCategoryId);
    });
  }
  duplicateCategory(categoryId: number) {
    this.articleCategriesService.duplicate(categoryId).subscribe((data) => {
      this.getAllCategories();
      this.toggleEditModeCategoryName(data.articleCategoryId);
    });
  }
  editCategory(category: ArticleCategory) {
    this.articleCategriesService.put(category).subscribe((data) => {
      this.editModeCategoryName = false;
      this.getAllCategories();
      this.isChangedcategoryOrder = false;
    });
  }
  deleteCategory(category: ArticleCategory) {
    this.articleCategriesService
      .delete(category)
      .subscribe(() => this.getAllCategories());
  }

  // Sub Categories
  toggleEditModeSubCategoryName(subCategoryId: number = null) {
    this.editModeSubCategoryName = !this.editModeSubCategoryName;
    if (this.editModeSubCategoryName) {
      this.subCategoryIdToEdit = subCategoryId;
      setTimeout(() => this.subCategoryNameInput.nativeElement.focus(), 500);
    } else {
      this.getAllCategories();
    }
  }

  findSubCategoryToExpand(subCategories: ArticleSubCategory[]) {
    return subCategories.find(
      (x) => x.articleSubCategoryId == this.subCategoryIdToEdit
    )?.articleSubCategoryId;
  }
  addSubCategory(articleCategoryId) {
    var articleSubCategory = new ArticleSubCategory();
    articleSubCategory.articleCategoryId = articleCategoryId;
    this.articleSubCategriesService
      .post(articleSubCategory)
      .subscribe((data) => {
        this.getAllCategories();
        this.toggleEditModeSubCategoryName(data.articleSubCategoryId);
      });
  }

  editSubCategory(subCategory: ArticleSubCategory) {
    this.articleSubCategriesService.put(subCategory).subscribe((data) => {
      this.editModeSubCategoryName = false;
      this.getAllCategories();
      if (this.subCategoryToShow) {
        this.openSubCategoryData(this.subCategoryToShow.articleSubCategoryId);
      }
    });
  }
  deleteSubCategory(subCategory: ArticleSubCategory) {
    this.articleSubCategriesService
      .delete(subCategory)
      .subscribe(() => this.getAllCategories());
  }
  openSubCategoryData(subCategoryId: number) {
    //this.subCategoryIdToEditInner = subCategoryId;
    this.articleSubCategriesService.getById(subCategoryId).subscribe((data) => {
      this.subCategoryToShow = data;
      this.subCategoryIdToEditInner = !this.subCategoryIdToEditInner
        ? this.subCategoryIdToEditInner
        : null;
    });
  }

  // Articles Bullets
  addBullet(subCategryId: number) {
    var articleBullet = new ArticleBullet();
    articleBullet.articleSubCategoryId = subCategryId;
    articleBullet.title = 'שאלה';
    articleBullet.desc = 'תשובה';
    this.articleBulletsService.post(articleBullet).subscribe((data) => {
      this.openSubCategoryData(subCategryId);
    });
  }
  deleteBullet(bulletId: number) {
    this.articleBulletsService.delete(bulletId).subscribe(() => {
      this.openSubCategoryData(this.subCategoryToShow.articleSubCategoryId);
    });
  }
  toggleEditModeBullet(buletId: number = null) {
    this.bulletEditMode = !this.bulletEditMode;
    if (this.bulletEditMode) {
      this.bulletIdToEdit = buletId;
      setTimeout(() => {
        if (this.bulletTitleInput && this.bulletTitleInput.nativeElement)
          this.bulletTitleInput.nativeElement.focus();
      }, 500);
    } else {
      this.openSubCategoryData(this.subCategoryToShow.articleSubCategoryId);
    }
  }
  editBullet(articleBullet: ArticleBullet) {
    this.articleBulletsService.put(articleBullet).subscribe(() => {
      this.openSubCategoryData(this.subCategoryToShow.articleSubCategoryId);
    });
  }

  // Sub Sub Category
  addSubSubCategory(articleSubCategoryId) {
    var articleSubCategory = new ArticleSubCategory();
    articleSubCategory.parentSubCategoryId = articleSubCategoryId;
    this.articleSubCategriesService
      .post(articleSubCategory)
      .subscribe((data) => {
        this.getAllCategories();
        this.subCategoryIdToEdit = data.parentSubCategoryId;
        this.toggleEditModeSubSubCategoryName(data.articleSubCategoryId);
      });
  }

  editSubSubCategory(subCategory: ArticleSubCategory) {
    this.articleSubCategriesService.put(subCategory).subscribe((data) => {
      this.editModeSubSubCategoryName = false;
      this.getAllCategories();
      if (this.subCategoryToShow) {
        this.openSubCategoryData(this.subCategoryToShow.articleSubCategoryId);
      }
    });
  }
  toggleEditModeSubSubCategoryName(subSubCategoryId: number = null) {
    this.editModeSubSubCategoryName = !this.editModeSubSubCategoryName;
    if (this.editModeSubSubCategoryName) {
      this.subSubCategoryIdToEdit = subSubCategoryId;
      setTimeout(() => this.subSubCategoryNameInput.nativeElement.focus(), 500);
    } else {
      this.getAllCategories();
    }
  }
  expandSubCategory(subCategory: ArticleSubCategory) {
    if (this.subCategoryIdToEdit == subCategory.articleSubCategoryId)
      return true;
  }
}
