import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NotesService } from 'src/app/Services/notes.service';

@Component({
  selector: 'app-record-call-dialog',
  templateUrl: './record-call-dialog.component.html',
  styleUrls: ['./record-call-dialog.component.css'],
})
export class RecordCallDialogComponent implements OnInit {
  record: string;
  audioHTML: HTMLAudioElement;
  isLoading: boolean;
  constructor(
    public dialogRef: MatDialogRef<RecordCallDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notesService: NotesService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getCallRecord();
  }

  getCallRecord() {
    this.isLoading = true;
    this.notesService.getCallRecord(this.data).subscribe((data) => {
      this.audioHTML = new Audio('data:audio/wav;base64,' + data);
      this.isLoading = false;
    });
  }

  sanitizeSrc(src) {
    return this.domSanitizer.bypassSecurityTrustUrl(src);
  }
}
