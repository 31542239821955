<mat-card class="snp">
  <mat-card-header>
    <mat-card-title> Edit Parameter </mat-card-title>
    <div
      *ngIf="canEdit"
      fxLayout="row"
      fxLayoutAlign="center"
      fxLayoutGap="10px"
    >
      <button
        type="button"
        (click)="toggleEditMode()"
        mat-button
        class="btn-fill"
      >
        <mat-icon *ngIf="!editMode">edit</mat-icon>
        <mat-icon *ngIf="editMode">cancel</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div
      class="mb-2"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <div fxFlex="10" style="font-weight: 500">Name</div>
      <mat-form-field class="field" appearance="fill">
        <input
          matInput
          [(ngModel)]="param.fgParamName"
          name="fgParamName"
          required
          [disabled]="!editMode"
        />
      </mat-form-field>
    </div>
    <div
      *ngIf="
        getNutrientName() &&
        !isStaticParam() &&
        !isUserAutomatic() &&
        !isLoading &&
        !editMode
      "
      class="mb-2"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <div fxFlex="10" style="font-weight: 500">{{ getNameFromEnum() }}</div>
      <mat-form-field class="field" appearance="fill">
        <input
          matInput
          name="fgParamKeyId"
          required
          [disabled]="true"
          [value]="getNutrientName()"
        />
      </mat-form-field>
    </div>
    <div
      *ngIf="isStaticParam()"
      class="mb-2"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <div fxFlex="10" style="font-weight: 500">Value</div>
      <mat-form-field class="field" appearance="fill">
        <input
          matInput
          [(ngModel)]="param.fgParamValue"
          name="fgParamValue"
          required
          [disabled]="!editMode"
        />
      </mat-form-field>
    </div>
    <div class="action" *ngIf="editMode">
      <button type="submit" (click)="save()" mat-button class="btn-save">
        Save
      </button>
    </div>
  </mat-card-content>
</mat-card>
