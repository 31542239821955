<table mat-table [dataSource]="translationKeywords" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let item">{{ item.translationKeywordId }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let item">{{ item.keyword }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let item">
      <select [(ngModel)]="item.translationCategoryId">
        <option value="-1">no Category</option>
        <option
          *ngFor="let cat of categories"
          value="cat.translationCategoryId"
        >
          {{ cat.name }}
        </option>
      </select>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Edit</th>
    <td mat-cell *matCellDef="let item">
      <button (click)="deleteKeyword(item)">
        <mat-icon aria-hidden="false" aria-label="delete">delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div>
  <input [(ngModel)]="newKeyword" />
  <select [(ngModel)]="selectedCatId">
    <option value="-1">no Category</option>
    <option *ngFor="let cat of categories" value="cat.translationCategoryId">
      {{ cat.name }}
    </option>
  </select>
  <button (click)="addNewKeyword()">Add Keyword</button>
</div>
