<h1>my genes admin</h1>

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <label>
    User Name:
    <input type="text" formControlName="userName" />
  </label>
  <br />
  <label>
    password:
    <input type="password" formControlName="password" />
  </label>
  <br />
  <button type="submit" [disabled]="!loginForm.valid">Submit</button>
</form>
