import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  getValue(key) {
    var obj = localStorage.getItem(key);
    if (obj) return JSON.parse(obj);
    return new Object();
  }
  setValue(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  }
  getServiveRequestCommentsIds(serviceRequestId) {
    var ids = this.getValue('serviceRequest_' + serviceRequestId);
    if (!ids) ids = {};
    return ids;
  }
  setServiveRequestCommentsIds(serviceRequestId, val) {
    this.setValue('serviceRequest_' + serviceRequestId, val);
  }
}
