<button
  class="select-btn"
  type="button"
  [matMenuTriggerFor]="morePanel"
  mat-button
  #buttonRef
>
  {{ selectedCat ? selectedCat.name : "Select category" }}
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>
<mat-menu panelClass="more-panel" #morePanel="matMenu">
  <ul style="list-style: none; margin-left: -45px">
    <li *ngIf="fromAllFoodComponent">
      
    </li>
    <li *ngFor="let item of categories; let i = index">
      <app-food-categories-selection-item
        [fromEditFoodComponent]="fromEditFoodComponent"
        [fromAllFoodComponent]="fromAllFoodComponent"
        [selectedCat]="selectedCat"
        [category]="item"
        [index]="i"
        (selectCat)="onSelectCat($event)"
      ></app-food-categories-selection-item>
    </li>
  </ul>
</mat-menu>
