<div
  class="autocomplete-agent"
  style="display: inline"
  matTooltipPosition="above"
>
  <mat-form-field
    appearance="fill"
    [matMenuTriggerFor]="valueComplete"
    class="main-field"
    (menuOpened)="focusInput()"
  >
    <mat-label>Choose Categories</mat-label>
    <input
      style="cursor: pointer"
      class="input-compelete"
      type="text"
      matInput
      #value
      disabled
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="open"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu
    #valueComplete="matMenu"
    xPosition="before"
    yPosition="below"
    class="menu-complete"
  >
    <ng-container *ngIf="data; else noData">
      <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <mat-label>Type something...</mat-label>
          <input
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div>
      <ng-container *ngFor="let value of filteredValuesOptions || data">
        <p style="margin-left: 1rem" (click)="$event.stopPropagation()">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onChangeSelection($event, value.id)"
            [checked]="selection.isSelected(value.id)"
          >
            {{ value?.name }}
          </mat-checkbox>
        </p>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div style="text-align: center">No Data</div>
    </ng-template>
  </mat-menu>
</div>
