import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { Meal } from 'src/app/data/Meal';
import { Menu } from 'src/app/data/Menu';
import { MenuToNutrient } from 'src/app/data/MenuToNutrient';
import { NameAndId } from 'src/app/data/NameAndId';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  FoodNutrientsZameret,
  MenuErrors,
  NutrientType,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { NutriMenuService } from 'src/app/Services/nutri-menu.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-menu-list-edit',
  templateUrl: './menu-list-edit.component.html',
  styleUrls: ['./menu-list-edit.component.scss'],
})
export class MenuListEditComponent implements OnInit, OnChanges {
  @Output('getMenuForEdit')
  getMenuForEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output('mealIdForEdit')
  mealIdForEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output('editModeBullets')
  editModeBullets: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() menuId: number;
  @Input() createNewFromGroup: boolean;
  @Input() product: Product;
  productId: number;
  @Input() mealChanged: boolean = false;
  @Input() canEditMenu: boolean;
  @Input() mealTypes: Status[];
  @Input() isTemplate: boolean;

  value: number = 20;
  menu: Menu;
  menuForEdit: Menu;
  mealsForm: FormGroup;

  agents: AgentMin[];
  menuAgentId: number;
  nutritionists;
  isSaved: boolean = false;
  hoursList: string[] = [];
  mealIdToEdit: number;
  mealEditMode: boolean = false;
  isNewMeal: boolean = false;
  showFormErrors: boolean;
  error: string;
  templates: NameAndId[];
  isSubmiting: boolean = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: true,
  };
  isLoading: boolean;
  isRowEditActive: boolean = false;
  goalCalories: any;
  menuCalories: any;
  paiData: any[] = [];
  colorScheme = {
    domain: ['#1b3f4e', '#119c99'],
  };
  menuForEditNutrients: MenuToNutrient[];
  menuForEditVitamins: MenuToNutrient[];
  //selectedTemplateId: boolean;

  constructor(
    private fb: FormBuilder,

    private nutriMenuService: NutriMenuService,
    private popupHandlerService: PopupHandlerService,
    private agentsService: AgentsService,
    private regularUsersService: RegularUsersService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuId?.currentValue) {
      this.menuId = changes.menuId.currentValue;
    }
  }

  ngOnInit(): void {
    if (this.product) {
      this.productId = this.product.productId;
    }
    this.getNutritionist();
    this.hoursListFn();
    if (this.createNewFromGroup) {
      this.menuId = null;
      this.menuForEdit = null;
      this.menu = new Menu();
      this.createMealsForm();
      this.getMenuTemplates();
    }
    if (this.menuId) {
      this.initMenu(this.menuId);
    }

    this.nutriMenuService.getMenuSubject$.subscribe((data) => {
      if (data) {
        //console.log('this.menuId: ', this.menuId);
        this.initMenu(this.menuId);
      }
    });
  }

  createMealsForm() {
    this.mealsForm = this.fb.group({
      menuName: this.fb.control(
        this.menuForEdit ? this.menuForEdit.name : '',
        Validators.required
      ),
      nutritionist: this.fb.control(
        this.productId ? this.menuAgentId : '',
        Validators.required
      ),
      status: this.fb.control(
        this.productId && this.menuForEdit
          ? this.menuForEdit.isComplete
          : false,
        Validators.required
      ),
      meals: this.fb.array([]),
      notes: this.fb.control(this.menuForEdit ? this.menuForEdit.notes : ''),
    });
    if (!this.productId) {
      this.mealsForm.removeControl('nutritionist');
      this.mealsForm.get('status').setValue(false);
    }
    if (this.menuForEdit) {
      this.menuForEdit.meals.forEach((x) => {
        (this.mealsForm.get('meals') as FormArray).push(
          this.createMenuMeals(x)
        );
      });
    }
  }

  initMenu(menuId: number) {
    this.isLoading = true;
    this.nutriMenuService.getByMenuId(menuId).subscribe((data) => {
      this.menuForEdit = data;
      if (data.menuToNutrients) {
        this.menuForEditNutrients = data.menuToNutrients.filter(
          (x) => x.foodNutrient.nutrientTypeId == NutrientType.nutrients
        );
        this.menuForEditVitamins = data.menuToNutrients.filter(
          (x) => x.foodNutrient.nutrientTypeId == NutrientType.vitamins
        );
      }
      this.createNewFromGroup = false;
      this.isSaved = true;
      this.menuAgentId = this.menuForEdit.createdByAgentId;
      this.createMealsForm();
      this.getMenuCalories();
      this.isLoading = false;
    });
  }

  getMenu(menuId: number) {
    this.nutriMenuService.getByMenuId(menuId).subscribe((data) => {
      this.menuForEdit = data;
      this.getMenuCalories();
      //if((this.menuCalories.value + 150 || this.menuCalories.value - 150)  )
    });
  }

  getMenuCalories() {
    const menuToNutrients = this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
    if (menuToNutrients) {
      this.menuCalories = { value: menuToNutrients.amount, color: '#BB0404' };
      this.goalCalories = {
        value: menuToNutrients.dailyAmount,
        color: '#D3247E',
      };
    }
  }

  getCurrentNutAgent() {
    this.regularUsersService.currentUser.subscribe((user) => {
      if (
        user.userRoleId == UserRoleEmum.nutritionist ||
        user.userRoleId == UserRoleEmum.nutritionistManager
      ) {
        this.menuAgentId = user.agent.agentId;
      }
    });
  }

  getMenuTemplates() {
    //this.isLoading = true;
    this.nutriMenuService.getTemplatesDropdown().subscribe((data) => {
      this.templates = data;
      //this.isLoading = false;
    });
  }

  onSelectTemplate(event: MatSelectChange) {
    if (event) {
      this.nutriMenuService
        .createFromTeplate(+event.value, this.productId)
        .subscribe(({ menuId }) => {
          this.menuId = menuId;
          this.initMenu(this.menuId);
          //this.getMenuForEdit.emit(menuId);
          //this.saveMeals();
        });
    }
  }

  getNutritionist() {
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
      this.nutritionists = this.agents.filter(
        (x) =>
          x.roleTypeId == UserRoleEmum.nutritionist ||
          x.roleTypeId == UserRoleEmum.nutritionistManager
      );

      this.getCurrentNutAgent();
    });
  }

  hoursListFn() {
    for (var i = 0; i <= 23; i++) {
      this.hoursList.push(i <= 9 ? '0' + i + ':00:00' : i + ':00:00');
    }
  }

  saveNutritionistForMenu(event) {
    /* const selectedAgent = this.agents.find(
      (agent) => agent.name === event[0]
    ).agentId; */
    //this.menu.createdByAgentId = selectedAgent;
    this.menuAgentId = event;
    this.mealsForm.get('nutritionist').setValue(this.menuAgentId);
  }
  setNutritionist(agentId) {
    this.menuAgentId = agentId;
    this.mealsForm.get('nutritionist').setValue(this.menuAgentId);
  }

  getAgentNameById(agentId) {
    this.setNutritionist(agentId);
    if (this.agents)
      return this.agents.find((a) => a.agentId === agentId)?.name;
  }

  getAgentIdByName(agentName) {
    if (this.agents)
      return this.agents.find((a) => a.name === agentName)?.agentId;
  }

  addNewMeal() {
    (this.mealsForm.get('meals') as FormArray).push(this.createMenuMeals());
    this.isSaved = false;
    this.isNewMeal = true;
  }

  removeMealItem(index) {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.menuForEdit.meals.splice(index, 1);
          (this.mealsForm.get('meals') as FormArray).removeAt(index);
          this.isSubmiting = false;
          this.mealChanged = false;
          this.mealEditMode = false;
          this.mealIdToEdit = null;
        }
      });
  }

  createMenuMeals(meal: Meal = null) {
    return this.fb.group({
      mealTypeId: [meal ? meal.mealTypeId : '', Validators.required],
      mealTime: [
        meal ? this.deFormatMealTime(meal.mealTime) : '',
        [
          Validators.required,
          Validators.pattern(
            '^(([0-1]{1,1})([0-9]{1,1})([0-5]{1,1})([0-9]{1,1}))|((2)([0-3]{1,1})([0-5]{1,1})([0-9]{1,1}))$'
          ),
        ],
      ],
      mealId: [meal ? meal.mealId : 0],
      notes: [meal ? meal.notes : ''],
      menuId: [meal ? meal.menuId : 0],
    });
  }

  editMeal(mealId: number) {
    this.mealEditMode = !this.mealEditMode;
    if (this.mealEditMode) {
      this.mealIdToEdit = mealId;

      this.mealIdForEdit.emit(this.mealIdToEdit);
      this.editModeBullets.emit(true);
    } else {
      this.mealIdToEdit = null;
      this.editModeBullets.emit(false);
      if (this.mealsForm.touched && this.mealsForm.dirty) {
        // this.saveMeals();
      }
    }
  }

  ifEditModeActive(mealId: number) {
    if (this.mealIdToEdit == mealId && this.mealEditMode) {
      return true;
    }
  }

  showActionBtns(inx: number) {
    if (
      this.menuForEdit &&
      this.menuForEdit.meals &&
      this.menuForEdit?.meals[inx]?.mealId
    ) {
      return true;
    }
  }

  getMealName(inx) {
    var mealTypeId =
      this.mealsForm.get('meals')['controls'][inx].value.mealTypeId;

    return this.mealTypes?.find((x) => x.statusId == mealTypeId)?.name;
  }

  getMealTime(formInx) {
    var mealTime =
      this.mealsForm.get('meals')['controls'][formInx].value.mealTime;
    var mealTimeFmr = this.formatMealTime(mealTime).split(':');

    return mealTimeFmr[0] + ':' + mealTimeFmr[1];
  }

  handleApiErros(error) {
    switch (error.errorId) {
      case MenuErrors.CompletedWithEmptyBulletOptions:
        return 'Cant save menu on "Completed" with empty meal option';
      case MenuErrors.CompletedWithEmptyBullets:
        return 'Cant save menu on "Completed" with empty meal';
      case MenuErrors.CompletedWithEmptyMeals:
        return 'Cant save menu on "Completed" with empty meal in the menu';
      case MenuErrors.CompletedWithCaloriesOutOfRange:
        return `The calorie difference is high or low from the target consumption. Cant save menu on "Completed"`;
    }
  }

  deFormatMealTime(mealTime: string) {
    var mealSpl = mealTime.split(':');
    return mealSpl[0] + mealSpl[1];
  }

  formatMealTime(mealTime: string) {
    var mealTimeSpl = mealTime.split('');
    if (mealTimeSpl.indexOf(':') == -1) {
      var mealTimeFmr =
        mealTimeSpl[0] +
        mealTimeSpl[1] +
        ':' +
        mealTimeSpl[2] +
        mealTimeSpl[3] +
        ':00';

      return mealTimeFmr;
    } else {
      return mealTime;
    }
  }

  setGeneticRateOffset(rate: number) {
    if (rate) return rate > 0 ? '101%' : '100%';
    else return '100%';
  }

  getGeneticRate() {
    return this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.geneticRate
    )?.amount;
  }

  getCalories() {
    return this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    )?.amount;
  }
  getFats() {
    return this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.total_fat
    )?.amount;
  }
  getCarbs() {
    return this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.carbohydrates
    )?.amount;
  }
  getProteins() {
    return this.menuForEdit.menuToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.protein
    )?.amount;
  }
  getVitemins() {
    let nutrients = this.menuForEdit.menuToNutrients;
    var c = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_c
    )?.amount;
    var b12 = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.vitamin_b12
    )?.amount;
    var iron = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.iron
    )?.amount;
    var magnesium = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.magnesium
    )?.amount;
    var folate = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.folate
    )?.amount;
    var calcium = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.calcium
    )?.amount;
    var zinc = nutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.zinc
    )?.amount;

    return [
      { name: 'b12', value: b12 },
      { name: 'iron', value: iron },
      { name: 'c', value: c },
      { name: 'magnesium', value: magnesium },
      { name: 'folate', value: folate },
      { name: 'calcium', value: calcium },
      { name: 'zinc', value: zinc },
    ];
    /* var viteminsArr2 = [];

    viteminsArr.forEach((vitemin) => {
      if (vitemin) {
        viteminsArr2.push(vitemin);
      }
    });

    return viteminsArr2.join(', '); */
  }

  saveMeals() {
    if (this.mealsForm.valid) {
      this.isSubmiting = true;
      this.showFormErrors = false;
      if (this.menuForEdit) {
        this.menuForEdit.name = this.mealsForm.get('menuName').value;
        this.menuForEdit.notes = this.mealsForm.get('notes').value;
        if (this.productId) {
          this.menuForEdit.isTemplate = false;
          this.menuForEdit.createdByAgentId =
            this.mealsForm.get('nutritionist').value;
        } else {
          this.menuForEdit.isTemplate = true;
        }
        this.menuForEdit.isComplete = this.mealsForm.get('status').value;
        this.menuForEdit.meals = this.mealsForm.get('meals').value;

        this.menuForEdit.meals.forEach(
          (data) => (data.mealTime = this.formatMealTime(data.mealTime))
        );

        this.nutriMenuService.put(this.menuForEdit).subscribe((data) => {
          if (data && 'errorId' in data) {
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.errorFromServer,
              '',
              '',
              this.handleApiErros(data),
              4000
            );
            this.isNewMeal = false;
            this.mealEditMode = false;
            this.isSubmiting = false;
            this.initMenu(this.menuForEdit.menuId);
          } else {
            this.popupHandlerService.openDisapearingAlertDialog('success');
            this.menuId = this.menuForEdit.menuId;
            this.initMenu(this.menuForEdit.menuId);
            this.isNewMeal = false;
            this.mealEditMode = false;
            this.isSubmiting = false;
            this.menuForEdit.meals.map((meal) => {
              if (Object.values(meal).indexOf(this.mealIdToEdit) > -1) {
                this.editModeBullets.emit(true);
              } else {
                this.editModeBullets.emit(false);
              }
            });
          }
        });
      } else {
        this.menu.name = this.mealsForm.get('menuName').value;
        this.menu.notes = this.mealsForm.get('notes').value;
        if (this.productId) {
          this.menu.isTemplate = false;
          this.menu.createdByAgentId = this.mealsForm.get('nutritionist').value;
          this.menu.productId = this.productId;
        } else {
          this.menu.isTemplate = true;
        }
        this.menu.isComplete = this.mealsForm.get('status').value;
        this.menu.meals = this.mealsForm.get('meals').value;

        this.menu.meals.forEach(
          (data) => (data.mealTime = this.formatMealTime(data.mealTime))
        );

        this.nutriMenuService.post(this.menu).subscribe((data) => {
          if (data && 'errorId' in data) {
            this.popupHandlerService.openDisapearingAlertDialog(
              '',
              '',
              '',
              this.handleApiErros(data)
            );
            this.isNewMeal = false;
            this.isSubmiting = false;
            this.mealEditMode = false;
            this.initMenu(this.menuForEdit.menuId);
          } else {
            this.menuForEdit = data;
            this.menuId = this.menuForEdit.menuId;
            this.isNewMeal = false;
            this.initMenu(this.menuForEdit.menuId);
            this.isSubmiting = false;
            this.mealEditMode = false;
            this.popupHandlerService.openDisapearingAlertDialog('success');
          }
        });
      }
    } else {
      this.showFormErrors = true;
      this.isSubmiting = false;
    }
    //this.isSubmiting = false;
  }
  getEditIconName(meal: Meal) {
    if (this.mealIdToEdit == meal.mealId) {
      return 'close';
    }
    if (this.isTemplate) {
      return 'border_color';
    }
    return this.menuForEdit.isComplete || !this.canEditMenu
      ? 'visibility'
      : 'border_color';
  }
  isMenuCaloriesInRange() {
    var diff = Math.abs(this.menuCalories.value - this.goalCalories.value);
    if (diff <= 150) return true;
    return false;
  }
}
