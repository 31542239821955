<mat-list class="clientsTable" role="list">
  <mat-list-item class="clientsTable__header" role="listitem">
    <ng-container *ngFor="let item of headers">
      <span
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
      >
        <span (click)="changeSortBy(item.val)">{{ item.name }} </span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
  </mat-list-item>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{
        height: '50px',
        'margin-bottom': '10px',
        'background-color': 'rgba(27, 63, 78,0.15)'
      }"
      count="10"
    ></ngx-skeleton-loader>
  </div>
  <ng-container *ngIf="!isLoading">
    <mat-card class="clientsTable__item" *ngFor="let review of reviews">
      <mat-list-item role="listitem">
        <span> {{ review.dateCreated | date : "dd-MM-yyyy" }}</span>
        <span>{{ review.name }}</span>
        <span>{{ review.noteCategory && review.noteCategory.name }}</span>
        <span>{{ review.assigningAgent && review.assigningAgent.name }}</span>
        <span
          style="color: #119c99"
          [innerHtml]="getRating(review.rating)"
        ></span>

        <span>
          <mat-icon
            (click)="openCustomerReview(review.comment)"
            style="cursor: pointer"
            [matBadgeHidden]="!review.comment"
            matBadge="&#8288;"
            matBadgeColor="warn"
          >
            <ng-container *ngIf="review.noteTypeId == 4">
              <img src="../../../assets/awesome-headphones.svg" />
            </ng-container>
            <ng-container *ngIf="review.noteTypeId == 5">
              comment
            </ng-container>
          </mat-icon>
        </span>
        <!--
      <span class="text-center" style="margin-left: auto">
        <button
          mat-button
          (click)="editReview($event, review)"
          class="edit-btn"
        >
          <img src="../../../../assets/client-edit.svg" alt="edit" />
        </button>
      </span>
      -->
      </mat-list-item>
    </mat-card>
  </ng-container>
</mat-list>
