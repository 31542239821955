<h2>Dashboard</h2>
<button mat-button routerLink="/dashboard">Sales dashboard</button>
<br />
<button mat-button routerLink="/sales-call-statistics">
  Sales call statistics dashboard
</button>
<br />
<button mat-button routerLink="/support-dashboard">
  Support call statistics dashboard
</button>
<!--
<br />
<button mat-button routerLink="/confetti">
  confetti
</button>
-->
<hr />
<!-- <h2>Old algorithm</h2>
<button mat-button routerLink="/editGenesData">Algorithm</button>
<hr /> -->
<h2>File extensions</h2>
<button mat-button routerLink="/file-extensions">Manage file extensions</button>
<br />
<button mat-button routerLink="/file-types">Manage file types</button>
<br />
<hr />
<h2>Food</h2>
<button mat-button routerLink="/food-categories">Food Categories</button>
<br />
<button mat-button routerLink="/food-for-category">Food For Category</button>
<br />
<hr />
<h2>Translations</h2>
<button mat-button routerLink="/translation">Translations</button>
<br />
<hr />

<h2>Manage Users new</h2>
<button mat-button routerLink="/role-managment">Role Managenent</button>
<br />
<button mat-button routerLink="/module-to-actions">Module to action</button>
<br />
<button mat-button routerLink="/rule-to-module-action">
  Rule to module action
</button>
<br />
<button
  *ngIf="userHasPermission(['GlobalParams_Create', 'GlobalParams_Update'])"
  mat-button
  routerLink="/far-login-admin"
>
  Far login admin
</button>
<br />
<!--
<button mat-button routerLink="/role-managment-v2">role Managenent v2</button>
<br />
<button mat-button routerLink="/action-convertions">action convertion</button>
-->
<hr />
<h2>Manage Users</h2>

<button mat-button routerLink="/agents">Agents</button>
<br />
<button mat-button routerLink="/farloginhistory">
  Login from home history
</button>

<br />
<!--
<button mat-button routerLink="/uploadCsv">
  upload files
</button>
<br />
-->
<!--
<button mat-button routerLink="/viewClientAnalysis">
  view Client Analysis
</button>
<br />
-->
<!--
<button mat-button routerLink="/allGenesData">
  editGenesData
</button>
<br />
-->
<!--
<button mat-button routerLink="/newPatient">
  new patient
</button
<br />
-->

<!--<button mat-button routerLink="/roles">Roles</button>
<br />

<button mat-button routerLink="/permissionsForRoles">
  Permissions For Roles
</button>
<br />
<button mat-button routerLink="/permissions">Permissions</button>
<br />--->
<button mat-button routerLink="/transfer-agents-clients">
  Transfer agents clients
</button>
<br />
<button mat-button routerLink="/office-phone-extensions">
  Office phone extensions
</button>
<br />
<button mat-button routerLink="/phone-verification-setting">
  Phone verification setting
</button>

<!--
<button mat-button routerLink="/sendSms">
  Send Sms
</button>
<br />
-->
<!--
<button mat-button (click)="sendMail()">Send Mail</button>
<br />
-->
<hr />
<h2>Bar deliveries</h2>
<!--
<button mat-button routerLink="/patientsDeliveries">Patients Deliveries</button>

<br />
-->
<button mat-button routerLink="/failedPatientsDeliveries">
  Failed Patients Deliveries
</button>
<br />
<!--
<button mat-button routerLink="/deliveriesResponses">
  Deliveries Responses
</button>
<br />
-->
<button mat-button routerLink="/barholdup">Bar holdup</button>
<br />
<hr />
<h2>Logistics</h2>
<button mat-button routerLink="/testsStatuses">Tests Statuses</button>
<hr />
<h2>Guided Questions</h2>
<button mat-button routerLink="/guidedQuestions">Guided questions tree</button>
<hr />
<br />
<h2>Lifestyle</h2>

<br />
<button mat-button routerLink="/lifestyle-tree2">Lifestyle Questions</button>
<br />
<button mat-button routerLink="/lifestyle-versions">Lifestyle versions</button>
<br />
<button mat-button routerLink="/lifestyle-calculations">
  Lifestyle calculations
</button>
<br />
<hr />
<br />
<h2>Misc</h2>

<button mat-button routerLink="/creatednapdf">Create dna pdf</button>
<br />
<!--
<button mat-button routerLink="/guidedQuestionsOld">Guided questions tree(old)</button>

<br>
-->
<button mat-button (click)="getWeightHistories()">Weight Histories</button>
<br />
<button mat-button routerLink="/farloginhistory">Far login history</button>
<br />
<button mat-button routerLink="/versionhistory">Version history</button>
<br />
<button mat-button routerLink="/triggerEvents">Trigger Events</button>
<br />
<hr />
<h2>VOD</h2>

<button mat-button routerLink="/videos">VOD</button>
<br />
<br />
<br />
<br />
<!--<br />
<button (click)="fixCanceledProductsStatus()">
  fix Canceled Products Status</button>
  -->
<!--
<br />
<button mat-button routerLink="/externalLead">external Lead</button>
<br />
<button mat-button routerLink="/externalDeal">external Deal</button>
<br />
-->
