import { Injectable } from '@angular/core';
import { TubesPackage } from '../data/TubesPackage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { DatePipe } from '@angular/common';
import { TubesPackageMin } from '../data/TubesPackageMin';

@Injectable({
  providedIn: 'root',
})
export class TubesPackagesService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  getAll(headFilter: HeadFilter) {
 
    return this.http.get<TubesPackageMin[]>(
      `${
        environment.baseUrl
      }TubesPackages/${this.httpFilterHelper.createLabPackagesPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getById(packageId) {
    return this.http.get<TubesPackage>(
      `${environment.baseUrl}TubesPackages/${packageId}`
    );
  }
  put(tubesPackage: TubesPackage) {
    return this.http.put<TubesPackage>(
      `${environment.baseUrl}TubesPackages/${tubesPackage.tubesPackageId}`,
      tubesPackage
    );
  }
  closePackage(tubesPackage: TubesPackage) {
    return this.http.put<TubesPackage>(
      `${environment.baseUrl}TubesPackages/closePackage/${tubesPackage.tubesPackageId}`,
      tubesPackage
    );
  }

  post(tubesPackage: TubesPackage) {
    return this.http.post<TubesPackage>(
      `${environment.baseUrl}TubesPackages/`,
      tubesPackage
    );
  }
}
