import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client } from '../data/Client';
import { LifeStyleReportVersion } from '../data/LifeStyleReportVersion';
import { ProductLifeStyleVersionStatus } from '../data/ProductLifeStyleVersionStatus';

@Injectable({
  providedIn: 'root',
})
export class LifeStyleReportVersionsService {
  constructor(private http: HttpClient) {}
  getAll(): Observable<LifeStyleReportVersion[]> {
    return this.http.get<LifeStyleReportVersion[]>(
      `${environment.baseUrl}LifeStyleReportVersions/`
    );
  }
  post(version: LifeStyleReportVersion) {
    return this.http.post<any>(
      `${environment.baseUrl}LifeStyleReportVersions/`,
      version
    );
  }
  put(version: LifeStyleReportVersion) {
    return this.http.put<any>(
      `${environment.baseUrl}LifeStyleReportVersions/${version.lifeStyleReportVersionId}`,
      version
    );
  }
  duplicateVersionData(targetId, sourceId) {
    return this.http.get<LifeStyleReportVersion[]>(
      `${environment.baseUrl}LifeStyleReportVersions/duplicateData/${targetId}/${sourceId}`
    );
  }
  setDefault(versionId) {
    return this.http.get<LifeStyleReportVersion[]>(
      `${environment.baseUrl}LifeStyleReportVersions/setDefault/${versionId}`
    );
  }
  setDone(versionId) {
    return this.http.get<LifeStyleReportVersion[]>(
      `${environment.baseUrl}LifeStyleReportVersions/setDone/${versionId}`
    );
  }
  GetAvailbleVersions(productId) {
    return this.http.get<ProductLifeStyleVersionStatus>(
      `${environment.baseUrl}LifeStyleReportVersions/GetAvailbleVersions/${productId}`
    );
  }
}
