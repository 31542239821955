<div
  matRipple
  class="chat-list__row"
  *ngFor="let item of firebaseService.chatRoomAgent$ | async"
  (click)="onRowClicked(item)"
  [class]="
    activeSession && activeSession.chatRoomId == item.chatRoomId ? 'active' : ''
  "
>
  <div class="chat-list__row__avatar">
    <span
      class="chat-list__row__avatar__notification"
      *ngIf="item.notReadMsgCount > 0"
    >
      {{ item.notReadMsgCount }}
    </span>
    <img [src]="getAvatarImage(item)" alt="avatar" />
  </div>
  <div class="chat-list__row__data">
    <div class="chat-list__row__data__msg">
      <div class="chat-list__row__data__msg__name">
        {{ getActiveSession(item.sessions).conversationUserName }}
      </div>
      <div class="chat-list__row__data__msg__last-msg">
        {{ item.isTyping ? "Typing..." : "" }}
        <!-- <span class="v-mark" [innerHtml]="getIsSeen(item)"></span>
        <span #msgText>{{ truncateTxt(item.msg, msgText) }}</span> -->
      </div>
    </div>
    <!-- <div class="chat-list__row__data__meta">
      <div
        [innerHtml]="getLatestDate(item.createdDate)"
        class="chat-list__row__data__meta__date"
      ></div>
      <div style="display: flex">
        <div
          class="chat-list__row__data__meta__type"
          [ngStyle]="{
            backgroundColor: getSubjectFromEnum(item.subjectId)?.color
          }"
        >
          {{ getSubjectFromEnum(item.subjectId)?.letter }}
        </div>
        <div
          *ngIf="item.countCrm > 0"
          class="chat-list__row__data__meta__indicator"
          style="margin-right: 5px"
        >
          {{ item.countCrm }}
        </div>
      </div>
    </div> -->
  </div>
</div>
