<mat-card [ngClass]="getlevelClass(question.level)" style="margin: 20px">
  <div style="border: 1px black solid">
    <!--
      <span>next question text:</span>
      <input
        (change)="valuechange($event)"
        [(ngModel)]="question.nextQuestionText"
      />
      <br />
      -->
    <span> id: {{ question.lifestyleQuestionId }} </span>
    <br />
    <span>answer text:</span>
    <input
      (change)="valuechange($event)"
      [(ngModel)]="
        question.lifestyleQuestionLangs &&
        question.lifestyleQuestionLangs[0] &&
        question.lifestyleQuestionLangs[0].answerText
      "
    />
    <br />
    <span>is Red:</span>
    <mat-checkbox
      (change)="valuechange($event)"
      [(ngModel)]="question.isRed"
    ></mat-checkbox>
    <br />
    <span style="margin-right: 62px">design Type:</span>
    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.designTypeId"
      name="designTypeSelect"
    >
      <option
        [value]="designType.statusId"
        *ngFor="let designType of designTypes"
      >
        {{ designType.name }}
      </option>
    </select>
    <span style="margin-left: 30px; margin-right: 63px"
      >preview design Type:</span
    >
    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.previewDesignTypeId"
      name="designTypeSelect"
    >
      <option
        [value]="designType.statusId"
        *ngFor="let designType of designTypes"
      >
        {{ designType.name }}
      </option>
    </select>
    <br />
    <span>container design Type:</span>
    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.containerDesignTypeId"
      name="containertDesignTypeSelect"
    >
      <option
        [value]="designType.statusId"
        *ngFor="let designType of containerDesignTypes"
      >
        {{ designType.name }}
      </option>
    </select>
    <span>preview container design Type:</span>
    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.previewContainerDesignTypeId"
      name="containertDesignTypeSelect"
    >
      <option
        [value]="designType.statusId"
        *ngFor="let designType of containerDesignTypes"
      >
        {{ designType.name }}
      </option>
    </select>
    <br />
    <span>Change order: </span>
    <select
      (change)="onChangeOrder($event)"
      [(ngModel)]="question.order"
      name="changeOrder"
    >
      <option [value]="order" *ngFor="let order of length">
        {{ order }}
      </option>
    </select>
    <br />

    <span> level:</span>
    <span>{{ question.level }}</span>
    <br />
    <span> Order:</span>
    <span>{{ question.order }}</span>
    <br />
    <span> referenceId:</span>
    <span>{{ question.referenceQuestionId }}</span>
    <br />

    <span> calculationId:</span>

    <select
      (change)="valuechange($event)"
      [(ngModel)]="question.lifeStyleCalculationId"
    >
      <option
        *ngFor="let calculation of lifeStyleCalculations"
        [value]="calculation.lifeStyleCalculationId"
      >
        {{ calculation.name }}
      </option>
    </select>
    <br />
    <button *ngIf="!isDone" (click)="deleteCurrentNode()">delete</button>
    <br />
    <div *ngIf="question.childQuestions && question.childQuestions.length > 0">
      <button
        *ngIf="!this.question.isExpanded"
        (click)="onUpdateExpanded(true)"
      >
        expand
      </button>
      <button
        *ngIf="this.question.isExpanded"
        (click)="onUpdateExpanded(false)"
      >
        collapse
      </button>
    </div>

    <div *ngIf="this.question.isExpanded">
      <div *ngFor="let childQuestion of question.childQuestions">
        <app-lifestyle-question-node-v2
          [length]="childLength"
          [isDone]="isDone"
          [question]="childQuestion"
          [designTypes]="designTypes"
          [containerDesignTypes]="containerDesignTypes"
          [templatesNames]="templatesNames"
          [lifeStyleCalculations]="lifeStyleCalculations"
          (addNewQuest)="onAddNewQuestion($event)"
          (deleteQuest)="onDeleteQuestion($event)"
          (addFromTemplate)="onAddFromTemplate($event)"
          (duplicate)="onDuplicate($event)"
          (move)="onMove($event)"
          (checkedChanged)="onCheckedChanged($event)"
          (setMarkAsReference)="onSetMarkAsReference($event)"
          (removeReference)="onRemoveReference($event)"
          (moveNodeToMarked)="onMoveNodeToMarked($event)"
          (moveNodeToRoot)="onMoveNodeToRoot($event)"
          (changeOrder)="onChangeOrder($event)"
        >
        </app-lifestyle-question-node-v2>
      </div>
    </div>
  </div>
  <button *ngIf="!isDone" (click)="addNewQuestion()">Add</button>
  <select *ngIf="!isDone" [(ngModel)]="templateId">
    <option *ngFor="let item of templatesNames" [value]="item.id">
      {{ item.name }}
    </option>
  </select>
  <button *ngIf="!isDone" (click)="onAddFromTemplate()">
    Add From Template
  </button>
  <button *ngIf="!isDone" (click)="onDuplicate()">duplicate</button>
  <br />
  <button (click)="onMoveUp()">
    <mat-icon aria-hidden="false" aria-label="Example home icon"
      >arrow_upward</mat-icon
    >
  </button>

  <button (click)="onMoveDown()">
    <mat-icon aria-hidden="false" aria-label="Example home icon"
      >arrow_downward</mat-icon
    >
  </button>
  <br />
  <mat-checkbox
    (change)="onCheckedClicked($event)"
    [(ngModel)]="question.isChecked"
  ></mat-checkbox
  ><span>mark for action</span>
  <br />
  <button (click)="onSetMarkAsReference()">set mark as reference</button>
  <button (click)="onRemoveReference()">remove reference</button>
  <br />
  <button (click)="onMoveNodeToMarked()">move to marked</button>
  <button (click)="onMoveNodeToRoot()">move to root</button>
</mat-card>
