import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AlgoBulletLink } from 'src/app/data/AlgoBulletLink';
import { AlgoBulletLinkLang } from 'src/app/data/AlgoBulletLinkLang';
import { Language } from 'src/app/data/Language';
import { AlgoBulletLinksService } from 'src/app/Services/algo-bullet-links.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-edit-algo-bullet-links',
  templateUrl: './edit-algo-bullet-links.component.html',
  styleUrls: ['./edit-algo-bullet-links.component.scss'],
})
export class EditAlgoBulletLinksComponent implements OnInit {
  linkId: string;
  langId: number = 1;
  algoBulletLink: AlgoBulletLink;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    minHeight: '350px',
  };
  selectedLang: Language;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private algoBulletsLinksService: AlgoBulletLinksService,
    private langsService: LanguagesService,
    private popupHandlerSevice: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.linkId = params['linkId'] || params['linkid'];

      if (this.linkId === '0') {
        this.langsService.getAll().subscribe((data) => {
          this.selectedLang = data.find((x) => x.languageId == this.langId);
          this.algoBulletLink = new AlgoBulletLink();
          this.algoBulletLink.algoBulletLinkId = 0;

          let algoBulletLinkLangs = new AlgoBulletLinkLang();
          algoBulletLinkLangs.algoBulletLinkLangId = 0;
          algoBulletLinkLangs.algoBulletLinkId = 0;
          algoBulletLinkLangs.languageId = this.selectedLang.languageId;
          algoBulletLinkLangs.title = '';
          algoBulletLinkLangs.description = '';
          this.algoBulletLink.algoBulletLinkLangs = [algoBulletLinkLangs];
        });
      } else {
        this.getAlgoBulletLink(+this.linkId, this.langId);
      }
    });
  }

  getAlgoBulletLink(linkId: number, langId: number) {
    this.algoBulletsLinksService
      .getAlgoBulletLink(linkId, langId)
      .subscribe((data) => {
        this.algoBulletLink = data;
      });
  }

  save() {
    if (
      this.algoBulletLink.algoBulletLinkLangs[0].title &&
      this.algoBulletLink.algoBulletLinkLangs[0].description
    ) {
      if (this.linkId === '0') {
        this.algoBulletsLinksService
          .post(this.algoBulletLink)
          .subscribe((data) => {
            this.popupHandlerSevice.openDisapearingAlertDialog('success');
            setTimeout(() => {
              this.router.navigate(['algo-bullet-links/edit'], {
                queryParams: { linkId: data.algoBulletLinkId },
              });
            }, 1000);
          });
      } else {
        this.algoBulletsLinksService
          .put(this.algoBulletLink)
          .subscribe((data) => {
            this.popupHandlerSevice.openDisapearingAlertDialog('success');
            this.getAlgoBulletLink(
              this.algoBulletLink.algoBulletLinkId,
              this.langId
            );
          });
      }
    }
  }
}
