import { Translation } from './Translation';
import { TranslationCategory } from './TranslationCategory';
import { TranslationSubCategory } from './TranslationSubCategory';

export class TranslationKeyword {
  translationKeywordId: number;
  keyword: string;
  translationCategoryId: number;
  translationCategory: TranslationCategory;
  translationSubCategoryId: number;
  translationSubCategory: TranslationSubCategory;
  translations: Translation[];
}
