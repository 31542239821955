import { Component, OnInit } from '@angular/core';
import { LifeStyleReportVersion } from 'src/app/data/LifeStyleReportVersion';
import { LifeStyleReportVersionsService } from 'src/app/Services/life-style-report-versions.service';

@Component({
  selector: 'app-lifestyle-versions',
  templateUrl: './lifestyle-versions.component.html',
  styleUrls: ['./lifestyle-versions.component.css'],
})
export class LifestyleVersionsComponent implements OnInit {
  displayedColumns: string[] = [
    'lifeStyleReportVersionId',
    'createDate',
    'doneDate',
    'isDone',
    'default',
    'name',
    'duplicateFrom',
    // 'edit',
  ];
  versions: LifeStyleReportVersion[];
  constructor(
    private lifeStyleReportVersionsService: LifeStyleReportVersionsService
  ) {}

  ngOnInit(): void {
    this.getAll();
  }
  addVersion() {
    var version = new LifeStyleReportVersion();

    this.lifeStyleReportVersionsService.post(version).subscribe((x) => {
      this.getAll();
    });
  }
  getAll() {
    this.lifeStyleReportVersionsService.getAll().subscribe((data) => {
      this.versions = data;
    });
  }
  saveChanges(item: LifeStyleReportVersion) {
    this.lifeStyleReportVersionsService.put(item).subscribe((x) => {
      this.getAll();
    });
  }
  setDefault(item: LifeStyleReportVersion) {
    this.lifeStyleReportVersionsService
      .setDefault(item.lifeStyleReportVersionId)
      .subscribe((x) => {
        this.getAll();
      });
  }
  setDone(item: LifeStyleReportVersion) {
    this.lifeStyleReportVersionsService
      .setDone(item.lifeStyleReportVersionId)
      .subscribe((x) => {
        this.getAll();
      });
  }
  onDuplicateClick(element: LifeStyleReportVersion) {
    this.lifeStyleReportVersionsService
      .duplicateVersionData(
        element.lifeStyleReportVersionId,
        element.duplicateFromId
      )
      .subscribe((x) => {});
  }
  editVersion() {} ///toDo: navigate to edit+ in edit handle parms.if params not exit load default
  // goToEdit() {}
}
