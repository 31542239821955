import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSnp } from 'src/app/data/algorithm/AlgoSnp';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { AlgoSubCategoryResultType } from 'src/app/data/algorithm/AlgoSubCategoryResultType';
import { AlgoSubCategorySnp } from 'src/app/data/algorithm/AlgoSubCategorySnp';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { PrototypeSnpByVer } from 'src/app/data/algorithm/PrototypeSnpByVer';
import { SnpSearchResult } from 'src/app/data/algorithm/SnpSearchResult';
import { Language } from 'src/app/data/Language';
import { RegularUser } from 'src/app/data/RegularUser';
import { AlgoMainBoardEnum } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.scss'],
})
export class AllCategoriesComponent implements OnInit {
  //#region Class properties
  @ViewChild('snpNav') snpNav: MatSidenav;
  @ViewChild('catNav') catNav: MatSidenav;
  langId = 1;
  versionId = 2;
  categories: AlgoCategory[];
  selectedCategory: AlgoCategory;
  selectedSubCategory: AlgoSubCategory;
  algoSnps: AlgoSnp[];
  algoSubCategorySnp: AlgoSubCategorySnp;
  mainBoardOpened: boolean;
  subCatResultTypes: AlgoSubCategoryResultType[];
  languages: Language[];
  snpResults: SnpSearchResult[];
  indexExpanded: number = -1;
  canEdit: boolean = false;
  selectedSnp: AlgoSnp;
  mainBoardMode: AlgoMainBoardEnum;
  AlgoMainBoardEnum = AlgoMainBoardEnum;
  availableCatSnps: PrototypeSnpByVer[];
  versions: LabVersion[];
  labId: number = 0;
  isLoadingCats: boolean;
  regularUser: RegularUser;
  //#endregion
  constructor(
    private algorithmService: AlgorithmService,
    private languagesService: LanguagesService,
    private _snackBar: MatSnackBar,
    private regularUserService: RegularUsersService
  ) {}
  ngOnInit(): void {
    this.getLanguages();
    this.getLabWithVersions();
    this.getCatsWithSubCats();
    this.getCurrentUser();
  }
  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
      this.canEdit = this.userHasPermission();
    });
  }
  userHasPermission() {
    return userHasPermisions(
      ['Algorithm_Create', 'Algorithm_Update', 'Algorithm_Delete'],
      this.regularUser
    );
  }

  userCanSearch() {
    return userHasPermisions(['Algorithm_Search'], this.regularUser);
  }

  //#region Services
  getCatsWithSubCats() {
    this.isLoadingCats = true;
    this.algorithmService
      .getCatsWithSubCats(this.versionId, this.langId)
      .subscribe((data) => {
        this.categories = data;
        this.categories = this.categories.map((x) => {
          const cat = x;
          cat.color = '#' + cat.color;
          return cat;
        });
        this.isLoadingCats = false;
      });
  }
  getAlgoSnps(subCatId: number) {
    this.algorithmService.getAlgoSnpSubCategory(subCatId).subscribe((data) => {
      this.algoSnps = data;
    });
  }
  getSubCategorySnp(algoId: number) {
    this.algorithmService
      .getSubCatSnpByNum(
        algoId,
        this.selectedSubCategory.subCategoryId,
        this.langId
      )
      .subscribe((data) => {
        this.mainBoardOpened = true;
        this.algoSubCategorySnp = data;
      });
  }
  getSubCatResultTypes(from: string = null) {
    //if (from) console.log('from: ', from);
    this.algorithmService
      .getSubCatResultTypes(this.selectedSubCategory.subCategoryId, this.langId)
      .subscribe((data) => {
        this.subCatResultTypes = data.map((x) => {
          const type = x;
          if (type.color) {
            type.color = '#' + type.color;
          }
          return type;
        });
        this.mainBoardOpened = true;
        //console.log('this.subCatResultTypes ', this.subCatResultTypes);
      });
  }
  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data.filter((lang) => lang.languageId !== 3);
    });
  }
  getLabWithVersions() {
    this.algorithmService
      .getLabWithVersions(this.langId, this.labId)
      .subscribe((data) => {
        this.versions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.versions.push(y);
          });
        });
      });
  }
  getAvialableCatSnps() {
    this.algorithmService
      .getAvailableSnpForSubCat(this.selectedSubCategory.subCategoryId)
      .subscribe((res) => {
        this.availableCatSnps = res;
      });
  }
  //#endregion

  //#region UI
  toggleSidenav(catNav: MatSidenav, snpNav: MatSidenav) {
    catNav.toggle();
    if (snpNav.opened) {
      snpNav.toggle();
    } else {
      snpNav.close();
    }
  }
  dir(): 'rtl' | 'ltr' {
    return this.languages?.find((x) => x.languageId == this.langId).isRtl
      ? 'rtl'
      : 'ltr';
  }
  expandCatPanel(index: number) {
    this.indexExpanded = index == this.indexExpanded ? -1 : index;
  }
  //#endregion

  //#region Event emmiters
  onSelected(selected: any) {
    if (!selected) return;
    const { category, subCategory, isSummarySelected } = selected;
    this.selectedSubCategory = subCategory;
    this.selectedCategory = category;
    this.mainBoardOpened = false;
    if (this.selectedSubCategory) {
      this.getAlgoSnps(this.selectedSubCategory.subCategoryId);
      this.getAvialableCatSnps();
      this.getSubCatResultTypes();
      if (isSummarySelected) {
        this.mainBoardMode = AlgoMainBoardEnum.summary;
      }
    }
  }
  openMainBoard({ category, subCategory, mode }) {
    this.selectedCategory = category || this.selectedCategory;
    this.selectedSubCategory = subCategory;
    this.mainBoardMode = mode;
    this.mainBoardOpened = true;
    const boardMode: AlgoMainBoardEnum = mode;
    if (
      boardMode == AlgoMainBoardEnum.addCategory ||
      boardMode == AlgoMainBoardEnum.category ||
      boardMode == AlgoMainBoardEnum.subCategory
    ) {
      this.snpNav.close();
    }
  }
  onSelectSnp(snp: AlgoSnp) {
    if (snp) {
      this.selectedSnp = snp;
      this.mainBoardMode = AlgoMainBoardEnum.snp;
      this.subCatResultTypes = null;
      this.getSubCategorySnp(this.selectedSnp.algoSnpNum);
    } else {
      this.algoSubCategorySnp = null;
    }
  }
  onSelectSummary(event: boolean) {
    if (event) {
      this.mainBoardMode = AlgoMainBoardEnum.summary;
      if (this.selectedSubCategory) {
        this.getSubCatResultTypes('onSelectSummary');
      }
    }
  }
  onCloseMainBoard(event: boolean) {
    if (event) {
      this.mainBoardOpened = false;
    }
  }
  onChangeLanguage(langId: number) {
    if (langId == this.langId) return;
    this.langId = langId;
    this.getCatsWithSubCats();
    this.getLabWithVersions();
    if (this.selectedSubCategory) {
      this.getSubCatResultTypes('onChangeLanguage');
    }
    if (this.mainBoardMode === AlgoMainBoardEnum.snp) {
      this.getSubCategorySnp(this.selectedSnp.algoSnpNum);
    }
  }
  onSearchSnp(event: string) {
    if (event.length > 0) {
      this.algorithmService
        .searchSnps(event, this.versionId, this.langId)
        .subscribe(
          (data) => {
            if (data.length > 0) {
              this.snpResults = data;
              this.mainBoardOpened = true;
              this.mainBoardMode = AlgoMainBoardEnum.search;
              this.algoSnps = null;
              this.selectedCategory = null;
              this.selectedSubCategory = null;
              this.algoSubCategorySnp = null;
              this.snpNav.close();
            } else {
              this._snackBar
                .open('No results found', 'Close', {})
                ._dismissAfter(3000);
            }
          },
          (error) => {
            this._snackBar
              .open('No results found', 'Close', {})
              ._dismissAfter(3000);
          }
        );
    }
  }
  onChangeVersion(event: string) {
    this.versionId = +event;
    this.getCatsWithSubCats();
    this.snpNav.close();
    this.mainBoardMode = null;
  }
  onSelectSearchResult(event: SnpSearchResult) {
    //console.log('event', event);
    let subCat: AlgoSubCategory;
    this.categories.forEach((cat) => {
      subCat = cat.subcategories.find(
        (sub) => sub.subCategoryId == event.subCategoryId
      );
      if (subCat) {
        this.snpNav.open();
        this.mainBoardMode = AlgoMainBoardEnum.snp;
        this.selectedCategory = cat;
        this.selectedSubCategory = subCat;
        this.getAlgoSnps(subCat.subCategoryId);
        this.getSubCategorySnp(event.algoSnpNum);
        this.expandCatPanel(this.categories.indexOf(cat));
      }
    });
  }
  onLoadResultTypes(event: boolean) {
    if (event) {
      this.getSubCatResultTypes('onLoadResultTypes');
    }
  }
  onLoadCategories(event: boolean) {
    if (event) {
      console.log('onLoadCategories');
      this.getCatsWithSubCats();
    }
  }
  onLoadSnps(event: boolean) {
    if (event) {
      this.getAlgoSnps(this.selectedSubCategory.subCategoryId);
      this.getAvialableCatSnps();
    }
  }
  onLoadRs(event: boolean) {
    if (event) {
      this.getSubCategorySnp(this.selectedSnp.algoSnpNum);
    }
  }
  //#endregion
}
