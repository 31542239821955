import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadResultsCsvService {
  constructor(private http: HttpClient) {}
  GetDnaPdfData(barcodes: string[]) {
    return this.http.post<any>(
      `${environment.baseUrl}UploadResultsCsv/GetDnaPdfData`,
      barcodes
    );
  }
  sendDnaDatatoPhp(barcodes: string[]) {
    return this.http.post<any>(
      `${environment.baseUrl}UploadResultsCsv/sendDataPhp`,
      barcodes
    );
  }
  generateDnaPdfs() {
    return this.http.get<any>(
      `${environment.baseUrl}UploadResultsCsv/generateDnaPdfs`
    );
  }
  generatePdfs(barcodes: string[]) {
    return this.http.post<any>(
      `${environment.baseUrl}UploadResultsCsv/generatePdf`,
      barcodes
    );
  }
}
