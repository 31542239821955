import { Component, Input, OnInit } from '@angular/core';
import { DealBundleForTable } from 'src/app/data/DealBundleForTable';
import { Status } from 'src/app/data/Status';
import { DealEnum } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-deal-bundle-thumb',
  templateUrl: './deal-bundle-thumb.component.html',
  styleUrls: ['./deal-bundle-thumb.component.scss'],
})
export class DealBundleThumbComponent implements OnInit {
  @Input() dealBundle: DealBundleForTable;
  @Input() statuses: Status[];

  constructor() {}

  ngOnInit(): void {}
  getProductsStr(products: string[]) {
    var prods = '';
    if (!products) return prods;
    var prodsDict = {};
    products.forEach((product) => {
      if (prodsDict[product] == undefined) {
        prodsDict[product] = 0;
      }
      prodsDict[product]++;
    });
    for (const property in prodsDict) {
      prods += `${property} (${prodsDict[property]})<br>`;
    }
    return prods;
  }
  getDealStatusColor(dealBundle: DealBundleForTable) {
    return (
      this.statuses &&
      this.statuses?.find((x) => x.statusId == dealBundle.statusId)?.color
    );
  }
  getDealStatus(dealBundle: DealBundleForTable) {
    return (
      this.statuses &&
      this.statuses.find((x) => x.statusId == dealBundle.statusId)?.name
    );
  }
  getDealLeftToPay(dealBundle: DealBundleForTable) {
    if (dealBundle.statusId == DealEnum.Uncompleted) {
      return dealBundle.leftToPay
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return false;
    }
  }
}
