import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Agent } from 'src/app/data/Agent';
import { AgentMin } from 'src/app/data/AgentMin';

@Component({
  selector: 'app-autocomplete-agent',
  templateUrl: './autocomplete-agent.component.html',
  styleUrls: ['./autocomplete-agent.component.scss'],
})
export class AutocompleteAgentComponent implements OnInit, OnChanges {
  @ViewChild('value') valueInput: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @Input() data: any[];
  @Input() headFilter: boolean;
  @Input() label: string;
  @Input() initialValue: string;
  @Input() widthExp: string;
  @Input() widthPrec: string;
  @Input() disabled: string;
  @Input() textAlign: string = 'right';
  @Input() direction: string = 'rtl';
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  filteredValuesOptions: any[];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.data &&
      changes.data.currentValue &&
      !changes.data.isFirstChange()
    ) {
      this.filteredValuesOptions = this.data;
    }
  }

  ngOnInit(): void {}

  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;

    var valuesDup = [...this.data];
    this.filteredValuesOptions = valuesDup.filter((val) => {
      return val.name.indexOf(search) != -1;
    });
  }

  focusInput() {
    this.inputField.nativeElement.focus();
  }
  onClose() {
    this.inputField.nativeElement.value = '';
  }
  ifAgentActive(agentName: string) {
    return !this.data?.find((x) => x.name === agentName)?.isActive;
  }

  onValueSelected(selectedValue: number) {
    this.selectedValue.emit(selectedValue);
  }
}
