<mat-list class="packageTubesTable" role="list">
  <mat-list-item class="packageTubesTable__header" role="listitem">
    <span style="width: 45px">
      <mat-checkbox
        (change)="onChangeMasterSelection($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
      >
      </mat-checkbox>
    </span>
    <span>Patient Name</span>
    <span>Id</span>
    <span>Purchase Date</span>
    <span>barcode</span>
    <span>status</span>
    <span></span>
    <span>days from pickup</span>
    <span>dna results</span>
    <span>resend</span>
  </mat-list-item>

  <mat-card
    class="packageTubesTable__item"
    *ngFor="let tube of packageTubes; index as inx"
  >
    <mat-list-item role="listitem">
      <span style="width: 45px; transform: translateX(-50px)">
        # {{ inx + 1 }}
      </span>
      <span style="width: 45px; margin-left: -40px">
        <ng-container *ngIf="!isStatusCompleted(tube); else statusCompleted">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onChangeSelection($event, tube)"
            [checked]="selection.isSelected(tube)"
            [aria-label]="checkboxLabel(tube)"
          >
          </mat-checkbox>
        </ng-container>
        <ng-template #statusCompleted>
          <mat-icon>done</mat-icon>
        </ng-template>
      </span>

      <span class="d-flex-center">
        <img
          *ngIf="tube.product && tube.product.productTypeId == 2"
          src="../../../assets/Nu_Original.svg"
          alt="tube"
          class="mr-1"
        />
        <img
          *ngIf="tube.product && tube.product.productTypeId == 1"
          src="../../../assets/Nu_Plus.svg"
          alt="tube"
          class="mr-1"
        />
        <span style="display: flex; align-items: center">
          <div
            (click)="goToPatient(tube)"
            style="color: blue; text-decoration: underline; cursor: pointer"
          >
            {{ tube.product?.fullName }}
          </div>
          <mat-icon
            matTooltip="URGENT!"
            *ngIf="tube.product && tube.product.isUrgentTest"
            style="color: red; margin-left: 0.5rem"
            >error_outline</mat-icon
          >
        </span>
      </span>
      <span>{{ tube.product?.socialSecurityNum }}</span>
      <span>{{ tube.product?.createdDate | date: "dd-MM-yyyy" }}</span>

      <span class="d-flex-center">
        <img
          *ngIf="tube.hasBarcodeChanged"
          src="../../../assets/barcode-changed.svg"
          alt="checkmark"
          width="25"
          style="margin-right: 5px"
        />
        {{ tube.barcode }}
      </span>
      <span
        class="status"
        style="margin-right: 1rem; margin-left: -1rem"
        [ngStyle]="{
          'background-color':
            tube &&
            tube.product &&
            tube.product.status &&
            tube.product.status.color
        }"
      >
        {{ tube.product && tube.product?.status && tube.product?.status.name }}
      </span>

      <span *ngIf="type == 'lab'" class="text-center">
        <button
          class="statusActionBtn"
          mat-button
          (click)="markAsRecieved($event, tube)"
        >
          <span *ngIf="tube && tube.product && tube.product.statusId == 62">
            Accepted</span
          >
          <span
            class="text"
            *ngIf="tube && tube.productId && tube.product.statusId == 8"
          >
            Received</span
          >
          <span
            class="receivedCheck"
            [class.received]="
              tube && tube.product && tube.product.statusId == 8
            "
            ><img src="../../../assets/checkmark.svg" alt="checkmark"
          /></span>
        </button>
      </span>

      <span *ngIf="type == 'office'">
        <button
          style="width: 100%; background-color: red"
          class="statusActionBtn"
          mat-button
          (click)="onRemoveFromPackage(tube)"
        >
          Remove From Package
        </button>
      </span>
      <span>
        <div *ngIf="type == 'office'" style="padding-left: 1rem">
          {{ tube.product && getDaysFromToday(tube.product.arrivedToOffice) }}
        </div>
        <!--| date: "dd-MM-yyyy" -->
      </span>
      <span>
        <button
          *ngIf="showReportBtn(tube.product)"
          class="reportBtn"
          (click)="goToDnaReport(tube.product)"
        >
          Dna Report
        </button>
      </span>
      <span>
        <button
          class="btn-rect btn-rect--client"
          (click)="openResendPopup(tube.product)"
        >
          Resend
        </button>
      </span>

      <!--
      <button
        style="margin-left: 30px;"
        mat-button
        (click)="markAsNotRecieved($event, tube)"
      >
        Abort
      </button>
      -->
    </mat-list-item></mat-card
  ></mat-list
>
