import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Deal } from 'src/app/data/Deal';
import { Product } from 'src/app/data/Product';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseDetailsDialogComponent } from '../purchase-details-dialog/purchase-details-dialog.component';
import { DealsService } from 'src/app/Services/deals.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { DealForClientMin } from 'src/app/data/DealForClientMin';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  CancellationRequestEnum,
  DealEnum,
  PaymentMethodEnum,
} from 'src/app/Enums/StatusesEnum';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { Router, RouterModule } from '@angular/router';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-client-purchases-old',
  templateUrl: './client-purchases-old.component.html',
  styleUrls: ['./client-purchases-old.component.scss'],
})
export class ClientPurchasesOldComponent implements OnInit, OnChanges {
  dealsDisplayedColumns: string[] = [
    'active',
    'date',
    'product',
    'sum',
    'status',
    'pMethod',
  ];
  // regularUser: RegularUser;
  statuses: Status[];
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  @Input() agentUser: RegularUser;
  @Input() assignedAgentId;
  @Input() inCancellation;
  @Input() clientDeals: DealForClientMin[];
  @Output('openNewPurchaseEmitter')
  openNewPurchaseEmitter: EventEmitter<any> = new EventEmitter();
  @Output('openContinueDealEmitter')
  openContinueDealEmitter: EventEmitter<Deal> = new EventEmitter();
  isOnRowClicked: boolean;
  constructor(
    public dialog: MatDialog,
    private dealsService: DealsService,
    private popupHandler: PopupHandlerService,
    private router: Router,
    private cancelationRequestService: CancelationRequestService,
    private statusesService: StatusesService
  ) {
    this.popupHandler.dealDetailsDialogSubject.subscribe((ans) => {
      if (ans == 'showDuplicateRequestAlert') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has Open Cancelation Request'
        );
      } else if (ans == 'DealHasReachedMaximumrefund') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has Reached Maximum refund'
        );
      } else if (ans == 'DealHasNoProductsLeft') {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer,
          '',
          '',
          'Deal Has No Products Left'
        );
      } else if ((ans as Deal).dealBundle.clientId) {
        //msg from popup  is getContinueDeal
        this.openContinueDealEmitter.emit(ans);
      }
    });
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    var a = this.clientDeals;
  }

  ngOnInit(): void {
    var a = this.clientDeals;
  }
  openNewPurchase() {
    this.openNewPurchaseEmitter.emit(true);
  }
  getProductsStr(products: string[]) {
    var prods = '';
    if (!products) return prods;
    var prodsDict = {};
    products.forEach((product) => {
      prodsDict[product] = 1;
    });
    for (const property in prodsDict) {
      prods += property + ' ';
    }
    return prods;
  }
  getDealLeftToPay(deal: DealForClientMin) {
    if (deal.statusId == DealEnum.Uncompleted) {
      return deal.leftToPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return false;
    }
  }
  getDealStatusColor(deal: DealForClientMin) {
    return (
      this.statuses &&
      this.statuses?.find((x) => x.statusId == deal.statusId)?.color
    );
  }
  getDealStatus(deal: DealForClientMin) {
    return (
      this.statuses &&
      this.statuses.find((x) => x.statusId == deal.statusId)?.name
    );
    //return deal.status.name;
    /*  if (!deal) return '';
    if (!deal.dealHistories) return '';
    if (deal.dealHistories.length == 0) return '';
    if (!deal.dealHistories[deal.dealHistories.length - 1]) return '';
    if (
      !deal.dealHistories[deal.dealHistories.length - 1]
        .tranzillaTransactionResponse
    )
      return '';
    if (
      !deal.dealHistories[deal.dealHistories.length - 1]
        .tranzillaTransactionResponse.bankResponseCode
    )
      return ''; */
    /*
        || !deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse
        || !deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse.bankResponseCode) return "";
        */
    /*
    var a = deal.dealHistories[deal.dealHistories.length - 1];
    var a1 = a.tranzillaTransactionResponse;
    var a2 = a1.bankResponseCode;
    var a3 = a2.name;
    */
    //return deal.dealHistories[deal.dealHistories.length - 1].tranzillaTransactionResponse.bankResponseCode.name
  }
  //this.statuses.push(PaymentMethodEnum.CreditCard, PaymentMethodEnum.ManualCreditCard, PaymentMethodEnum.)
  onRowClick(row: DealForClientMin) {
    if (this.isOnRowClicked) return;
    //if (row.status.statusId == DealEnum.Done) {
    //if (row.paymentMethodId == PaymentMethodEnum.CreditCard) {
    let url = '';
    if (row.isCancelDeal) {
      this.cancelationRequestService
        .getByCancelDealId(row.dealId)
        .subscribe((request) => {
          if (request.statusId == CancellationRequestEnum.NewRequest) {
            url = this.router.serializeUrl(
              this.router.createUrlTree(['/cancelations/new/'], {
                queryParams: { cancelRequestId: request.cancelationRequestId },
              })
            );
          } else if (
            request.statusId == CancellationRequestEnum.ManagerApprove
          ) {
            url = this.router.serializeUrl(
              this.router.createUrlTree(['/cancelations/new/'], {
                queryParams: { cancelRequestId: request.cancelationRequestId },
              })
            );
          } else if (
            request.statusId == CancellationRequestEnum.RefundApprove
          ) {
            url = this.router.serializeUrl(
              this.router.createUrlTree(['/cancelations/new/'], {
                queryParams: { cancelRequestId: request.cancelationRequestId },
              })
            );
          }
          if (url != '') window.open(url, '_blank');
        });
    } else {
      this.dealsService.getTransactionsReports(row.dealId).subscribe((deal) => {
        this.popupHandler.openDealDetailsDialog(deal);
      });
    }
    this.isOnRowClicked = true;
    setTimeout(() => {
      this.isOnRowClicked = false;
    }, 1000);
    //}
  }

  showPurchaseBtn() {
    if (
      userHasPermisionsWithRule(['Deals_Create'], this.agentUser) ||
      (this.agentUser.agent.agentId == this.assignedAgentId &&
        userHasPermisionsWithRule(
          ['Deals_Create_IsTargetAgent'],
          this.agentUser
        ))
    ) {
      return true;
    }
    return false;
  }
}
