<div class="container" style="direction: rtl">
  <div style="margin-right: auto" class="backBtn" (click)="goBack()">
    < חזרה
  </div>
  <br />
  <!--
    <button style="width: 200px; margin-left: auto" (click)="goToCalculation()">
      calculations Explained
    </button>
    -->
  <br />
  <h1 style="text-align: center">
    {{ patientName }}
  </h1>
  <button class="significant" (click)="goToCategory(-1)">
    ממצאים בעלי משמעות
    <span style="position: relative; top: -5px; left: -8px">_______</span> >
  </button>
  <br />
  <div
    style="
      margin: auto;
      font-size: 30px;
      font-weight: bolder;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 10px;
    "
  >
    קטגוריות
  </div>
  <br />
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-self: center;
      margin: auto;
    "
  >
    <span class="dotSmall green"></span
    ><span class="rank-text green"> ממצאים תקינים</span>
    <span class="dotSmall yellow"></span
    ><span class="rank-text yellow"> ממצאים בעלי חשיבות קלה/נמוכה</span>
    <span class="dotSmall red"></span
    ><span class="rank-text red"> ממצאים בעלי השפעה משמעותית</span>
  </div>
  <div style="height: 20px"></div>
  <div class="categories-grid">
    <ng-container *ngFor="let result of categories; index as i">
      <mat-card class="category-card" (click)="goToCategory(result.categoryId)">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 150px;
            min-height: 150px;
          "
        >
          <img
            src="../../../assets/categoryThumb_{{ result.categoryId }}.svg"
          />
        </div>
        <span style="text-align: center; font-weight: bold">
          {{ result.category.categoryName }}
        </span>

        <div style="display: flex; justify-content: center; margin-top: 20px">
          <div class="dot green">{{ result.rankOneCount }}</div>
          <div class="dot yellow">{{ result.rankTwoCount }}</div>
          <div class="dot red">{{ result.rankThreeCount }}</div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
