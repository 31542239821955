import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Language } from 'src/app/data/Language';
import { Translation } from 'src/app/data/Translation';
import { TranslationCategory } from 'src/app/data/TranslationCategory';
import { TranslationKeyword } from 'src/app/data/TranslationKeyword';

@Component({
  selector: 'app-translation-table',
  templateUrl: './translation-table.component.html',
  styleUrls: ['./translation-table.component.scss'],
})
export class TranslationTableComponent implements OnInit, OnChanges {
  @Input() data: TranslationKeyword[];
  @Input() categories: TranslationCategory[];
  @Input() languages: Language[];
  @Input() saveComplete: boolean = false;
  @Output() saveTranslation = new EventEmitter<Translation[]>();
  @Output() addKeyword = new EventEmitter<boolean>();
  dataSource: MatTableDataSource<TranslationKeyword> = new MatTableDataSource(
    []
  );
  displayedColumns: string[] = ['category', 'keyword', 'translation'];
  activeLang: Language;
  editInx: number;
  editMode: boolean = false;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.setDataSource(changes.data?.currentValue);
    }
    if (changes.saveComplete?.currentValue) {
      this.editMode = false;
      this.editInx = null;
      this.saveComplete = false;
    }
  }
  ngOnInit(): void {
    this.setDataSource(this.data);
    this.activeLang = this.languages.find((x) => x.languageId === 2);
    console.log('data', this.data);
  }

  setDataSource(data: TranslationKeyword[]) {
    this.dataSource = new MatTableDataSource(data);
    this.editMode = false;
    this.editInx = null;
  }

  onSelectLang(lang: Language) {
    this.activeLang = lang;
    //this.editMode = false;
    //this.editInx = null;
  }
  onEdit(inx: number) {
    this.editInx = inx;
    this.editMode = true;
  }
  onCancelEdit() {
    this.editInx = null;
    this.editMode = false;
  }
  onSave(translationKeywordId: number) {
    const translation = this.data.find(
      (x) => x.translationKeywordId === translationKeywordId
    ).translations;
    this.saveTranslation.emit(translation);
  }

  isRowEdited(translation: Translation) {
    return this.editInx === translation.translationKeywordId && this.editMode;
  }
  addNewKeyword() {
    this.addKeyword.emit(true);
  }
}
