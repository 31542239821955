<div class="calories-bar">
  <div class="calories-bar__item goal">
    <div class="calories-bar__item__value goal">
      {{ data.goal }} <small>Cal</small>
    </div>
    <div class="calories-bar__item__bar goal">
      <div
        class="calories-bar__item__bar__fill goal"
        [ngStyle]="{ height: setBarHeight('goal') + '%' }"
      ></div>
      <div
        *ngIf="calculateDiff().diff > 0 && inEdit"
        class="dashed-line"
        [ngStyle]="{ bottom: setBarHeight('goal') + '%' }"
      ></div>
    </div>
    <div class="calories-bar__item__text goal">
      <mat-icon><img src="../../../assets/goal.svg" alt="goal" /></mat-icon>
      Goal calories
    </div>
  </div>
  <div class="calories-bar__diff" *ngIf="calculateDiff().diff > 0 && inEdit">
    <div class="hidden">hidden</div>
    <div class="relative">
      <span
        [ngStyle]="{
          color: calculateDiff().color,
          top: 'calc(94% - ' + calculateDiff().diffHeight + '%)'
        }"
        >{{ calculateDiff().diff }} <small>Cal</small></span
      >
    </div>
    <div class="hidden">Menu calories</div>
  </div>

  <div class="calories-bar__item menu">
    <div class="calories-bar__item__value menu">
      {{ data.menu }} <small>Cal</small>
    </div>
    <div class="calories-bar__item__bar menu">
      <div
        class="calories-bar__item__bar__fill menu"
        [ngStyle]="{ height: setBarHeight('menu') + '%' }"
      ></div>
      <div
        *ngIf="calculateDiff().diff > 0 && inEdit"
        class="dashed-line"
        [ngStyle]="{ bottom: setBarHeight('menu') + '%' }"
      ></div>
    </div>
    <div class="calories-bar__item__text menu">Menu calories</div>
  </div>
</div>
