export enum DisapearingTextDialogEnum {
  agent = 'agent',
  changeEgentError = 'agent',
  requestAgent = 'requestAgent',
  nutAgent = 'nutAgent',
  followUpAgent = 'followUpAgent',
  requestStatus = 'requestStatus',
  requestManager = 'requestManager',
  status = 'status',
  bulkAgents = 'bulkAgents',
  purchaseSuccess = 'purchaseSuccess',
  purchaseRejected = 'purchaseRejected',
  fillAllFields = 'fill_all_fields',
  clientAlreadyExists = 'clientAlreadyExists',
  agentCreated = 'agentCreated',
  agentSaved = 'agentSaved',
  chooseFutureProduces = 'chooseFutureProduces',
  resetMailSent = 'resetMailSent',
  genericError = 'genericError',
  errorFromServer = 'errorFromServer',
  fillAddressFromAutoComlete = 'fillAddressFromAutoComlete',
  packageSentToPatient = 'packageSentToPatient',
  barcodeChanged = 'barcodeChanged',
  mailSentsuccessfully = 'mailSentsuccessfully',
  newPassword = 'newPassword',
  outDatedVersion = 'outDatedVersion',
  actionResults = 'actionResults',
  lifeStyleReportComplete = 'lifeStyleReportComplete',
  refundSaved = 'refundSaved',
  cancelationSaved = 'cancelationSaved',
  duplicateCancelationRequest = 'duplicateCancelationRequest',
  deletedSuccessfully = 'deletedSuccessfully',
  customerReview = 'customerReview',
  cancelSuccess = 'cancelSuccess',
  cancelRejected = 'cancelRejected',
  success = 'success',
  costumMessage = 'costumMessage',
}
/*
agent:'agent1',
status:"status"
  the lead status was changed
</h1>
<h1 *ngIf="data.type == 'bulkAgents'">
  the leads agents were changed
</h1>
<h1 *ngIf="data.type == 'purchaseSuccess'">
  the purchase is Completed Successfully
</h1>
<h1 *ngIf="data.type == 'purchaseRejected'">
  the purchase was Rejected
</h1>
*/
