import { Food } from './Food';
import { RecipeBullet } from './RecipeBullet';
import { RecipeHeaderLang } from './RecipeHeaderLang';

export class RecipeHeader {
  recipeHeaderId: number;
  foodId: number;
  food: Food;
  recipeBullets: RecipeBullet[];
  recipeHeaderLangs: RecipeHeaderLang[];

  constructor(init?: Partial<RecipeHeader>) {
    Object.assign(this, init);
  }
}
