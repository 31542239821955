<div class="office-extensions">
  <div class="office-extensions__filter" *ngIf="officePhoneExtensionGroups">
    <span style="font-weight: 500; font-size: 1.8rem">Filter by:</span>
    <mat-form-field appearance="fill">
      <mat-label>Departement</mat-label>
      <mat-select
        (selectionChange)="getFilteredExtension(false)"
        [(ngModel)]="groupId"
        [disabled]="editMode"
      >
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let item of officePhoneExtensionGroups"
          [value]="item.officePhoneExtensionGroupId"
          >{{ item.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select
        (selectionChange)="getFilteredExtension(false)"
        [(ngModel)]="isActive"
        [disabled]="editMode"
      >
        <mat-option [value]="-1">All</mat-option>
        <mat-option [value]="1">Active</mat-option>
        <mat-option [value]="0">Not active</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button (click)="addNewExt()" [disabled]="editMode">
      Add new extension
    </button>
  </div>
  <div class="TableClearFix"></div>

  <table
    class="office-extensions__table"
    mat-table
    [dataSource]="officePhoneExtensions"
    *ngIf="officePhoneExtensions && agents && officePhoneExtensionGroups"
    #table
  >
    <ng-container matColumnDef="extNumber">
      <th mat-header-cell *matHeaderCellDef>Extension Num.</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field
          appearance="fill"
          *ngIf="isEditMode(element.officePhoneExtensionId)"
          #extensionNameFields
        >
          <input
            matInput
            type="number"
            [(ngModel)]="element.phoneExtensionName"
            placeholder="Type Extension number"
            [disabled]="newRowId !== element.officePhoneExtensionId"
          />
        </mat-form-field>
        <ng-container *ngIf="!isEditMode(element.officePhoneExtensionId)">{{
          element.phoneExtensionName
        }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="agent">
      <th mat-header-cell *matHeaderCellDef>Agent</th>
      <td mat-cell *matCellDef="let element">
        <!-- <mat-form-field
          appearance="fill"
          *ngIf="isEditMode(element.officePhoneExtensionId)"
          #extensionAgentFields
          class="select"
        >
          <mat-select placeholder="Select agent" [(ngModel)]="element.agentId">
            <mat-option *ngFor="let item of agents" [value]="item.agentId">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field> -->
        <app-autocomplete-agent
          *ngIf="isEditMode(element.officePhoneExtensionId)"
          (selectedValue)="saveAgentForExt($event, element)"
          [data]="agents"
          [initialValue]="getAgentNameById(element.agentId)"
          [widthExp]="180"
        ></app-autocomplete-agent>
        <ng-container *ngIf="!isEditMode(element.officePhoneExtensionId)">{{
          getAgentName(element.agentId)
        }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="departement">
      <th mat-header-cell *matHeaderCellDef>Departement</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field
          appearance="fill"
          *ngIf="isEditMode(element.officePhoneExtensionId)"
          #extensionGroupFields
          class="select"
        >
          <mat-select
            placeholder="Select departement"
            [(ngModel)]="element.officePhoneExtensionGroupId"
          >
            <mat-option
              *ngFor="let item of officePhoneExtensionGroups"
              [value]="item.officePhoneExtensionGroupId"
              >{{ item.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="!isEditMode(element.officePhoneExtensionId)">{{
          getGroupName(element.officePhoneExtensionGroupId)
        }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <button
          (click)="toggleStatus(element)"
          [disabled]="editMode"
          mat-button
          class="subscribe-btn"
          [class.not-active]="!element.isActive"
        >
          {{ element.isActive ? "Active" : "Not active" }}
        </button>
        <!-- <mat-slide-toggle
          (change)="toggleStatus(element)"
          color="primary"
          [(ngModel)]="element.isActive"
          [disabled]="editMode"
          >{{ element.isActive ? "Active" : "Not active" }}</mat-slide-toggle
        > -->
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width: 110px">Action</th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="isEditMode(element.officePhoneExtensionId)"
          (click)="save(element)"
          mat-icon-button
          class="check"
        >
          <mat-icon>check</mat-icon>
        </button>
        <button
          (click)="toggleEditMode(element)"
          mat-icon-button
          [disabled]="
            editMode && rowIdToEdit !== element.officePhoneExtensionId
          "
          class="edit"
        >
          <mat-icon>{{
            !isEditMode(element.officePhoneExtensionId) ? "edit" : "close"
          }}</mat-icon>
        </button>
        <button
          *ngIf="!isEditMode(element.officePhoneExtensionId)"
          (click)="delete(element.officePhoneExtensionId)"
          mat-icon-button
          [disabled]="
            editMode && rowIdToEdit !== element.officePhoneExtensionId
          "
          class="delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div style="padding-bottom: 15em"></div>
</div>
