<!--<div *ngIf="isLoading" class="loadingOverlay">
  <mat-spinner diameter="250"></mat-spinner>
</div>-->
<app-head-filter
  *ngIf="agents && leadStatusesGroup && leadStatuses"
  [agents]="agents"
  [leadStatusesGroup]="leadStatusesGroup"
  [leadStatuses]="leadStatuses"
  [leadSources]="leadSources"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event, true)"
  [classAttr]="'leadsAttr'"
></app-head-filter>
<div class="TableClearFix"></div>
<app-leads-table
  *ngIf="agents && leadStatusesGroup"
  [agents]="agents"
  [leadStatuses]="leadStatusesGroup"
  [leadSources]="leadSources"
  [leadsData]="leadsData"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  (checkedIdsChanged)="changeCheckedIds($event)"
  [leadsServiceInject]="leadsService"
  [isLoading]="isLoading"
>
</app-leads-table>
<div style="height: 5px"></div>
<app-table-paginator
  [agents]="agents"
  [leadStatuses]="leadStatuses"
  *ngIf="leadsData && metaData && leadStatuses && agents"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'leadsAttr'"
  (checkedIdsChanged)="onCheckedIdsChanged($event)"
>
</app-table-paginator>
<!--
<table mat-table [dataSource]="leadsData" class="mat-elevation-z8">

    
   
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>
  
   
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
   
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef> Weight </th>
      <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
    </ng-container>
  
   
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
-->
