import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { FgFoodAllergan } from 'src/app/data/food-genes/FgFoodAllergan';
import { FgFoodToUserAlergan } from 'src/app/data/food-genes/FgFoodToUserAlergan';
import { FgUserAllergen } from 'src/app/data/food-genes/FgUserAllergen';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Language } from 'src/app/data/Language';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-allergies',
  templateUrl: './food-allergies.component.html',
  styleUrls: ['./food-allergies.component.scss'],
})
export class FoodAllergiesComponent implements OnInit {
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  langId: number = 1;
  wheelSpeed = 1;
  data = [
    { id: 1, allergy: 'Allergy 1', allergen: 'Allergen 1' },
    { id: 2, allergy: 'Allergy 2', allergen: 'Allergen 2' },
    { id: 3, allergy: 'Allergy 3', allergen: 'Allergen 3' },
  ];
  editMode = false;
  rowInxToEdit = -1;
  allergans: FgFoodToUserAlergan[];
  constructor(
    private foodGenesService: FoodGenesService,
    private filterMemoryService: FilterMemoryService,
    private _matSnackBar: MatSnackBar
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.snps);
    this.headFilter.langId = this.langId;
  }

  ngOnInit(): void {
    this.getFoodAllergies();
  }

  getFoodAllergies() {
    this.foodGenesService.getFoodAlergans(this.headFilter).subscribe((data) => {
      this.allergans = data.list;
      this.metaData = data.metaData;
    });
  }
  onChangeLang(language: Language) {
    this.langId = language.languageId;
    this.headFilter.langId = this.langId;
    this.getFoodAllergies();
  }
  onEditClicked(inx: number) {
    this.editMode = true;
    this.rowInxToEdit = inx;
    this.wheelSpeed = 0;
  }
  onCancelClicked(item?: FgFoodToUserAlergan, inx?: number) {
    this.editMode = false;
    this.rowInxToEdit = -1;
    this.wheelSpeed = 1;
    if (item && inx) {
      if (!item.foodAllergan.foodNutrientId || !item.userAllergy.fgAllergenId) {
        this.allergans.splice(inx, 1);
      }
    }
  }
  onSaveClicked(item: FgFoodToUserAlergan) {
    if (!item.foodAllergan.name && !item.userAllergy.name) {
      return;
    }
    this.onCancelClicked();
    if (item.foodAllergan.foodNutrientId && item.userAllergy.fgAllergenId) {
      this.foodGenesService
        .setFoodUserAlergans(item, this.langId)
        .subscribe((data) => {
          if (data.isSuccess) {
            this.getFoodAllergies();
          } else {
            this._matSnackBar.open(data.message, 'Close');
          }
        });
    } else {
      this.foodGenesService
        .insertFoodAlergans(item, this.langId)
        .subscribe((data) => {
          if (data.isSuccess) {
            this.getFoodAllergies();
          } else {
            this._matSnackBar.open(data.message, 'Close');
          }
        });
    }
  }
  getPaginatorData(event: HeadFilter) {
    if (event) {
      this.headFilter = event;
      this.headFilter.langId = this.langId;
      this.getFoodAllergies();
    }
  }
  scrollToBottom(speed: number = 0) {
    this.perfectScroll.directiveRef.scrollToBottom(0, speed); //for update scroll
  }
  addNewItem() {
    const newItem = new FgFoodToUserAlergan();
    newItem.foodAllergan = new FgFoodAllergan();
    newItem.userAllergy = new FgUserAllergen();
    this.allergans.push(newItem);
    this.scrollToBottom(0);
    this.onEditClicked(this.allergans.length - 1);
  }
}
