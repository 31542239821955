import { Component, OnInit } from '@angular/core';
import { TranslationCategory } from 'src/app/data/TranslationCategory';
import { TranslationCategoriesService } from 'src/app/Services/translation-categories.service';

@Component({
  selector: 'app-translation-categories',
  templateUrl: './translation-categories.component.html',
  styleUrls: ['./translation-categories.component.css'],
})
export class TranslationCategoriesComponent implements OnInit {
  newCategoryName;
  constructor(
    private translationCategoriesService: TranslationCategoriesService
  ) {}
  translationCategories: TranslationCategory[];
  displayedColumns: string[] = ['id', 'name', 'delete'];
  ngOnInit(): void {
    this.loadAll();
  }
  loadAll() {
    this.translationCategoriesService.getAll().subscribe((data) => {
      this.translationCategories = data;
    });
  }
  addCategory() {
    var cat = new TranslationCategory();
    cat.name = this.newCategoryName;
    this.translationCategoriesService.post(cat).subscribe((res) => {
      this.newCategoryName = '';
      this.loadAll();
    });
  }
  deleteCategory(translationCategory: TranslationCategory) {
    this.translationCategoriesService
      .delete(translationCategory)
      .subscribe((res) => {
        this.loadAll();
      });
  }
  editCatName(translationCategory: TranslationCategory) {
    this.translationCategoriesService
      .put(translationCategory)
      .subscribe((res) => {
        this.loadAll();
      });
  }
}
