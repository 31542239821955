<!--<div id="content" #content>
    <mat-card>
        <div class="alert alert-info">
            <strong>Html To PDF Conversion - Angular 6</strong>
        </div>
        <div>
            <input type="button" value="CPTURE" (click)="captureScreen()" />
        </div>
    </mat-card>
</div>
<div>
    <mat-card>
        <div #pdfReport id="pdfReport">
            <p>saas</p>
            <h1 style="text-align: center">דדדדד</h1>
        </div>
    </mat-card>
</div>-->
<button (click)="generatePdf()">generate Pdf on Server</button>
<br />
<button (click)="sendDataToPhp()">send to php</button>
<br />
<button (click)="generateDnaPdfs()">generate missing dna pdfs</button>
