import { Component, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
})
export class SystemMessageComponent implements OnInit {
  message: ChatMessage;
  constructor() {}

  ngOnInit(): void {}
}
