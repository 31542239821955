import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NoteForMissionsMin } from 'src/app/data/NoteForMissionsMin';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { NotesService } from 'src/app/Services/notes.service';

@Component({
  selector: 'app-activities-dashboard',
  templateUrl: './activities-dashboard.component.html',
  styleUrls: ['./activities-dashboard.component.scss'],
})
export class ActivitiesDashboardComponent implements OnInit, OnChanges {
  pageEvent: PageEvent;
  dateDecending: boolean = true;
  notes: NoteForMissionsMin[];
  @Input() agentId;
  @Input() title: string;
  @Input() action: string;
  @Input() ngForTxt: string;
  @Input() filterTxt: number = 118;
  activitiesOpen = [];
  activitiesOverdue = [];
  filterOverdue: number = 118;
  filterOpen: number = 118;
  pageSize: number = 25;
  pageNumber: number = 1;
  metaData: PaginationMetadata;

  constructor(private notesService: NotesService) {}
  ngOnInit(): void {
    // this.getNotes();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.drawAll();
    this.getNotes();
  }

  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };

  drawAll() {
    if (!this.notes) return;
    this.activitiesOpen = this.notes;
  }
  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  onFilterChange() {
    // this.drawAll();
    this.getNotes();
  }
  getNotes(event?: PageEvent) {
    if (event) {
      this.pageSize = event.pageSize;
      this.pageNumber = event.pageIndex + 1;
    }
    this.notesService
      .getByAgentId(
        this.agentId,
        2,
        this.action == 'overdue',
        this.pageSize,
        this.pageNumber,
        this.filterTxt,
        this.dateDecending
      )
      .subscribe((data) => {
        data;
        this.notes = data.list.map((item) => {
          if (!item.fullName.trim()) item.fullName = 'Default User';
          item.dueDateObj = new Date(Date.parse(item.dueDate));
          return item;
        });
        this.metaData = data.metaData;
        this.drawAll();
      });
  }
  onActivityDone(note: NoteForMissionsMin) {
    this.notesService.changeStatusMin(note).subscribe((res) => {
      this.getNotes();
    });
  }
  changeSortOrder() {
    this.dateDecending = !this.dateDecending;
    this.getNotes();
  }
}
