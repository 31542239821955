import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryForApp } from 'src/app/data/CategoryForApp';
import { Product } from 'src/app/data/Product';
import { ProductTypeEnum } from 'src/app/Enums/StatusesEnum';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-dna-report-view',
  templateUrl: './dna-report-view.component.html',
  styleUrls: ['./dna-report-view.component.scss'],
})
export class DnaReportViewComponent implements OnInit {
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  categories: CategoryForApp[];
  productId: any;
  patientName: string;
  isPremiumUser: boolean;
  remainingTime: number;
  constructor(
    private regularUsersService: RegularUsersService,
    private productsService: ProductsService,
    private catergoriesService: CatergoriesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.productId = params['productId'];
      this.catergoriesService.getAll(this.productId).subscribe((data) => {
        this.categories = data;
      });
      this.productsService.getById(this.productId).subscribe((data) => {
        if ((data as Product).productId) {
          const product = data as Product;
          var firstName = product.patient.regularUser.firstName ?? '';
          var lastName = product.patient.regularUser.lastName ?? '';
          this.patientName = firstName + ' ' + lastName;
          this.isPremiumUser =
            product.productTypeId == ProductTypeEnum.NutriPlus;
          const now = new Date();
          const nowMinus2 = now.setDate(now.getDate() - 2);
          const purchaseDate = new Date(product.upgragePurchaseDate).getTime();
          const inUpgaredProccess =
            purchaseDate > 0 && purchaseDate >= nowMinus2;
          if (inUpgaredProccess) {
            this.remainingTime = this.getRemainingTime(
              product.upgragePurchaseDate
            );
          }
        }
      });
      /*
    this.regularUsersService.currentUser.subscribe((user) => {
      var productId = user.patient.products[0].productId;
      this.catergoriesService.getAll(productId).subscribe((data) => {
        this.categories = data;
        var a = this.categories[0];
        var a1 = a.category.categoryName;
        var a2 = a.rankOneCount;
      });
    });
    */
    });
  }

  getRemainingTime(upgradeDate: string) {
    let upgradeDateTime = new Date(upgradeDate);
    upgradeDateTime.setDate(upgradeDateTime.getDate() + 2);
    const upgradeDateTimeNum = new Date(upgradeDateTime).getTime();
    const now = new Date().getTime();
    const remainingTime = upgradeDateTimeNum - now;
    return Math.round(remainingTime / 36e5); // calculate remaining time (milliseconds) to hours
  }
  onEditClick() {
    this.onEdit.emit();
  }
  goToCategory(categoryId: number) {
    this.router.navigate(['tests/edit/dna-report-inner'], {
      queryParams: {
        productId: this.productId,
        categoryId: categoryId,
      },
    });
  }
  goBack() {
    this.router.navigate(['tests/edit'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
  goToCalculation() {
    this.router.navigate(['dna-calculations'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
}
