import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { Client } from 'src/app/data/Client';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { UserAdress } from 'src/app/data/UserAdress';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  AdressTypeEnum,
  ProductEnum,
  ProductTypeEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { BloodTestTypesService } from 'src/app/Services/blood-test-types.service';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { NumberUtilsService } from 'src/app/Services/number-utils.service';
import { PatientWeightHistoriesService } from 'src/app/Services/patient-weight-histories.service';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { getWeightUtil, userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-patient-profile-view',
  templateUrl: './patient-profile-view.component.html',
  styleUrls: ['./patient-profile-view.component.scss'],
})
export class PatientProfileViewComponent implements OnInit {
  @Input() product: Product;
  @Output('moveToEdit') moveToEdit: EventEmitter<any> = new EventEmitter();
  @Output('reloadProduct')
  reloadProduct: EventEmitter<any> = new EventEmitter();
  @Input() statuses: Status[];
  @Input() client: Client;
  passwordEditMode: boolean = false;
  newPassword;
  newPasswordError;
  nutrisionists: AgentMin[];
  showWeightGoal: boolean = true;
  productId: string;
  currentUser: RegularUser;
  bloodTestTypes: BloodTestType[];
  newWeight: number;
  newGoal: number;
  phoneSettings: PhoneVerificationSetting;
  mainAddress: UserAdress;
  shippingAddress: UserAdress;
  barcode: any;
  //agentForAC: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private numberUtilsService: NumberUtilsService,
    private productsService: ProductsService,
    private popupHandlerService: PopupHandlerService,
    private regularUsersService: RegularUsersService,
    private bloodTestTypesService: BloodTestTypesService,
    private exelDownloadService: ExelDownloadService,
    private agentsService: AgentsService,
    private weightHistoriesService: PatientWeightHistoriesService,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService
  ) {
    this.productId = this.route.snapshot.queryParamMap.get('productId');
  }
  ngOnInit(): void {
    this.barcode = this.product.barCode;
    this.mainAddress = this.product.patient.regularUser.getAdressObj(
      AdressTypeEnum.Main
    );

    this.shippingAddress = this.product.patient.regularUser.getAdressObj(
      AdressTypeEnum.Shipping
    );
    this.bloodTestTypesService.getAll().subscribe((data) => {
      this.bloodTestTypes = data;
    });
    this.regularUsersService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
    this.agentsService.getAll().subscribe((res) => {
      if (res) {
        this.nutrisionists = res.filter(
          (x) =>
            x.roleTypeId == UserRoleEmum.nutritionist ||
            x.roleTypeId == UserRoleEmum.nutritionistManager
        );
      }
    });
  }
  patientHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.product.patient.regularUser);
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.currentUser);
  }
  getUserPhoneObj() {
    if (this.product.patient.regularUser.getUserPhoneObj()) {
      var phoneObj = this.product.patient.regularUser.getUserPhoneObj();
      return phoneObj.country;
    }
  }
  mainAddressAsShipping() {
    if (this.shippingAddress) {
      if (
        this.mainAddress.barCityCode == this.shippingAddress.barCityCode &&
        this.mainAddress.barStreetCode == this.shippingAddress.barStreetCode &&
        this.mainAddress.entrance == this.shippingAddress.entrance &&
        this.mainAddress.apartment == this.shippingAddress.apartment &&
        this.mainAddress.shipNotes == this.shippingAddress.shipNotes
      ) {
        return true;
      }
    }
  }
  getPhoneSettings() {
    var address = this.product.patient.regularUser.getAdressObj(
      AdressTypeEnum.Main
    );
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
      });
  }
  getUserPhone() {
    if (this.product.patient.regularUser.getUserPhone()) {
      var phoneNumber = this.product.patient.regularUser.getUserPhone();
      return phoneNumber;
    }
  }
  setUrgent() {
    this.product.isUrgentTest = !this.product.isUrgentTest;
    this.productsService.put(this.product).subscribe((data) => {});
  }
  moveToEditMode() {
    this.moveToEdit.emit(true);
    //var a = this.patient.productIsFor.name;
  }
  calculateBmi() {
    let height = this.product.patient.regularUser.height;
    let weight = this.getWeight();
    if (height && weight) {
      if (height > 3) {
        /// some entried filled in CM --> convert to meters
        height = height / 100;
      }
      let bmi = weight / (height * height);
      bmi = this.numberUtilsService.roundToNumDigits(bmi, 1);
      return bmi;
    }
  }
  showMenuListButton() {
    const { regularUser } = this.product.patient;

    const filledDetails =
      regularUser.birthDate && regularUser.gender && regularUser.height;
    const signConcent = this.product.patient.isSignedConcent;
    const weight = this.getWeight(true) && this.getWeight();

    return weight && filledDetails && signConcent;
  }
  statusCompleted() {
    return this.product.statusId == ProductEnum.Completed;
  }
  calculateAge() {
    if (this.product.patient.regularUser.birthDate == null) {
      return '';
    }
    var today = new Date();
    var birthDateObj = new Date(this.product.patient.regularUser.birthDate);

    var age = today.getFullYear() - birthDateObj.getFullYear();
    if (
      today.getMonth() < birthDateObj.getMonth() ||
      (today.getMonth() == birthDateObj.getMonth() &&
        today.getDate() < birthDateObj.getDay())
    ) {
      age--;
    }

    return age;
  }
  onLifestyleReportClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        tabIndex: 2,
        patientId: this.product.patient.patientId,
        barcode: this.barcode,
      },
    });
  }
  onLifestyleReportEditClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        tabIndex: 2,
        patientId: this.product.patient.patientId,
        edit: 'right',
        barcode: this.barcode,
      },
    });
  }
  onBloodTestReportClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        tabIndex: 1,
        patientId: this.product.patient.patientId,
        gender: this.product.patient.regularUser.gender,
        barcode: this.barcode,
      },
    });
  }
  onBloodTestReportEditClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        patientId: this.product.patient.patientId,
        tabIndex: 1,
        edit: 'right',
        barcode: this.barcode,
      },
    });
  }
  onHba1cReportClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        patientId: this.product.patient.patientId,
        tabIndex: 3,
        barcode: this.barcode,
      },
    });
  }
  onHba1cReportEditClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        patientId: this.product.patient.patientId,
        tabIndex: 3,
        edit: 'right',
        barcode: this.barcode,
      },
    });
  }
  onNutritionReportEditClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        patientId: this.product.patient.patientId,
        tabIndex: 0,
        edit: 'left',
        barcode: this.barcode,
      },
    });
  }
  getFullName(regularUser) {
    if (regularUser.lastName == null) {
      return regularUser.firstName;
    }
    return regularUser.firstName + ' ' + regularUser.lastName;
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveNutritionistForProduct(event) {
    if (!this.product) return;

    this.product.agentId = event;

    setTimeout(() => {
      this.productsService
        .changeAgent(this.product.productId, this.product.agentId)
        .subscribe((res) => {
          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.agentSaved
          );
        });
    }, 10);
  }
  getAgentNameById(agentId) {
    if (!agentId && !this.nutrisionists) {
      return;
    } else {
      return this.nutrisionists?.find((a) => a.agentId === agentId)?.name;
    }
  }
  saveStatusForProduct(event, product: Product) {
    var val = event.value;
    product.statusId = Number(event.value);
    product.status = this.statuses.find((x) => {
      return x.statusId == event.value;
    });
    //lead.status = null;

    setTimeout(() => {
      this.productsService
        .changeStatus(product.productId, product.statusId)
        .subscribe((obj) => {
          if (obj.isProblems) {
            var errorsHtml =
              "status wasn't Changed:<br/>" +
              obj.list.map((x) => x.name + '<br>').join();
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.errorFromServer,
              '',
              '',
              errorsHtml,
              4000
            );
            product.statusId = obj.oldStatusId;
            product.status = this.statuses.find((x) => {
              return x.statusId == obj.oldStatusId;
            });
          } else {
            if (obj.actionsList.length > 0) {
              this.popupHandlerService.openPerformStatusChangeDialog(
                obj.actionsList,
                product.productId
              );
            } else {
              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.status
              );
            }
          }
        });
    });
  }
  canChangeStatus() {
    return true;
    return userHasPermisions(['Change Product Status'], this.currentUser);
  }
  bloodTestFilled() {
    return this.product.bloodTestFilled;
    /*
    if (!this.bloodTestTypes || !this.product) return false;
    return (
      this.product.patient.bloodTestResults.filter(
        (x) => x.bloodTestTypeId != 1
      ).length >=
      this.bloodTestTypes.length - 1
    );
    */
  }
  lifestyleReportDone() {
    return this.product.lifeStyleReportComplete;
    /*
    return (
      this.product.patient.lifeStyleReport &&
      (this.product.patient.lifeStyleReport.isComplete ||
        this.product.patient.lifeStyleReport.isCompleteNew)
    );
    */
  }
  onDnaReportClick() {
    this.router.navigate(['/tests/edit/reports'], {
      queryParams: {
        productId: this.productId,
        barcode: this.barcode,
        tabIndex: 0,
        patientId: this.product.patient.patientId,
        edit: 'right',
      },
    });
  }

  downloadNutritionReport() {
    var url = this.exelDownloadService.getPatientNutritionReport(
      +this.productId
    );
    window.open(url, '_blank');
  }
  onDnaReportGenerateClick() {
    var url = this.productsService.generateReport(this.productId);
    window.open(url);
    /*
    this.productsService.generateReport(this.productId).subscribe((data) => {
      let contentType = 'text/csv';
      //let blob = base64StringToBlob(this.fileObservable.fileContent, contentType);\
      let blob = base64StringToBlob(data, contentType);
      var now = new Date();
      var timeStamp = now.toDateString().split(' ').join('_');

      saveAsHelper(blob, `dna_report.pdf`);
    });
    */
  }
  isProductPlus() {
    return this.product.productTypeId == ProductTypeEnum.NutriPlus;
  }
  getName(regularUser) {
    if (regularUser?.lastName == null) {
      return regularUser?.firstName;
    }
    return regularUser?.firstName + ' ' + regularUser?.lastName;
  }
  gotoClient() {
    this.router.navigate(['/clients/edit/'], {
      queryParams: { clientId: this.product.clientId },
    });
  }
  changePasswordHack() {
    if (!this.newPassword) {
      this.newPasswordError = true;
      return;
    }
    if (!this.is8Length() || !this.hasLetters() || !this.hasNumbers()) {
      this.newPasswordError = true;
      return;
    }

    if (this.newPassword) {
      this.regularUsersService
        .resetPasswordHackV2(
          this.product.patient.regularUserId,
          this.newPassword
        )
        .subscribe(() => {
          this.newPassword = '';
          this.passwordEditMode = false;
          this.reloadProduct.emit(true);
          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.newPassword
          );
        });
    }
  }
  is8Length() {
    return this.newPassword && this.newPassword.length >= 8;
  }
  hasLetters() {
    return (
      this.newPassword &&
      /[a-z]/.test(this.newPassword) &&
      /[A-Z]/.test(this.newPassword)
    );
  }
  hasNumbers() {
    return this.newPassword && /[0-9]/.test(this.newPassword);
  }
  getNutritionist() {
    if (!this.product.agentId || !this.product.agent) return 'Not Assigned';
    return this.product.agent.regularUser.firstName;
  }
  togglePassEditMode() {
    return (this.passwordEditMode = !this.passwordEditMode);
  }
  textToggleBtn() {
    return this.passwordEditMode ? 'Cancel' : 'Edit';
  }
  canEditNutritionist() {
    return true;
    return userHasPermisions(['Asign Nutritionist'], this.currentUser);
  }
  openBarcodeHistory() {
    this.popupHandlerService.openBarcodeHistoryDialog(
      this.productId,
      this.product.status
    );
  }
  goToMenu() {
    this.router.navigate(['menu'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
  disableUpdateWeightGoal() {
    const w = this.getWeight();
    const bd = this.product.patient.regularUser.birthDate;
    return !(w && bd);
  }
  updateGoalError() {
    let goalError = [];
    if (!this.getWeight()) {
      goalError.push('Weight');
    }
    if (!this.product.patient.regularUser.birthDate) {
      goalError.push('Birth Date');
    }
    if (goalError.length > 1) {
      return goalError.join(' and ');
    } else {
      return goalError.toString();
    }
  }
  missingMenuDetails() {
    const { regularUser } = this.product.patient;

    const filledDetails =
      regularUser.birthDate && regularUser.gender && regularUser.height;
    const signConcent = this.product.patient.isSignedConcent;
    const weight = this.getWeight();
    const weightGoal = this.getWeight(true);
    let error = [];
    if (!filledDetails) {
      error.push('Personal details');
    }
    if (!weight) {
      error.push('Weight');
    }
    if (!weightGoal) {
      error.push('Weight Goal');
    }
    if (!signConcent) {
      error.push('Sign Concent');
    }
    return error.join(' and ');
  }
  getWeight(isGoal = false) {
    return getWeightUtil(isGoal, this.product);
  }
  openWeightUpdateDialog(isGoal = false) {
    if (isGoal && !this.getWeight(false)) return;
    this.popupHandlerService.openWeightUpdateDialog(
      this.product.patient.regularUser.height,
      this.calculateAge(),
      isGoal
    );

    this.popupHandlerService.weightUpdateSubject
      .pipe(take(1))
      .subscribe(({ event, data }) => {
        if (event === 'save') {
          let patientWeightHistory = new PatientWeightHistory();
          patientWeightHistory.isGoal = isGoal;
          patientWeightHistory.patientWeightHistoryId = 0;
          patientWeightHistory.patientId = this.product.patientId;
          //patientWeightHistory.patient = this.product.patient;
          patientWeightHistory.value = data.weight;
          patientWeightHistory.dateUpdated = data.date;
          this.weightHistoriesService
            .post(patientWeightHistory)
            .subscribe((data) => {
              this.product.patient.patientWeightHistories.push(data);
              this.getWeight(data.isGoal);
              this.popupHandlerService.openDisapearingAlertDialog('success');
            });
        }
      });
  }
  openWeightHistoryDialog(isGoal: boolean = false) {
    this.weightHistoriesService
      .getHistory(this.product.patientId)
      .subscribe(({ fullName, socialSecurityNum, list }) => {
        if (!isGoal) {
          list = list.filter((x) => !x.isGoal);
        } else {
          list = list.filter((x) => x.isGoal);
        }
        this.popupHandlerService.openWeightHistoryDialog(
          fullName,
          socialSecurityNum,
          list,
          isGoal
        );
      });
    //
  }
}
