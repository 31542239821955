import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientDelivery } from 'src/app/data/PatientDeliveries';
import { PatientDeliveriesService } from 'src/app/Services/patient-deliveries.service';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { PatientsService } from 'src/app/Services/patients.service';
import { ProductEnum } from 'src/app/Enums/StatusesEnum';
import { ProductType } from 'src/app/data/ProductType';
import { PatientDeliveryMin } from 'src/app/data/PatientDeliveryMin';

@Component({
  selector: 'app-all-patients-deliveries',
  templateUrl: './all-patients-deliveries.component.html',
  styleUrls: ['./all-patients-deliveries.component.css'],
})
export class AllPatientsDeliveriesComponent implements OnInit {
  displayedColumns: string[] = [
    'patientId',
    'patientName',
    'deliveryType',
    //'patientStatus',
    'createDate',
    'error',
    'status',
    'lastDeliveryStatus',
    'action',
  ];
  constructor(
    private patientDeliveriesService: PatientDeliveriesService,
    private router: Router,
    private patientsService: PatientsService
  ) {}
  patientDeliveries: PatientDeliveryMin[];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit(): void {
    if (this.router.url == '/patientsDeliveries') {
      this.patientDeliveriesService
        .getAll()
        .subscribe((data) => (this.patientDeliveries = data));
    } else {
      this.patientDeliveriesService
        .getStatusNotOk()
        .subscribe((data) => (this.patientDeliveries = data));
    }
  }
  isFailed() {
    return this.router.url != '/patientsDeliveries';
  }
}
