import { Component, OnInit } from '@angular/core';

import { PermissionsService } from 'src/app/Services/permissions.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css'],
})
export class EditRoleComponent implements OnInit {
  categories: any;

  constructor(private permissionsService: PermissionsService) {}
  displayedColumns: string[] = ['name', 'desc', 'action'];
  ngOnInit(): void {
    this.permissionsService.getAllGroupByCategories().subscribe((data) => {
      this.categories = data;
    });
  }
}
