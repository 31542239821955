import { Patient } from './Patients';

export class PatientWeightHistory {
  patientWeightHistoryId: number;
  patientId: number;
  patient?: Patient;
  value: number;
  dateUpdated: string;
  isGoal: boolean;
}
