import { Status } from './Status';
import { Client } from './Client';
import { Patient } from './Patients';
import { Agent } from './Agent';
import { ServiceRequestNote } from './ServiceRequestNote';
import { Product } from './Product';
import { Note } from './Note';

export class ServiceRequest {
  serviceRequestId: number;
  createdDate: string;
  lastUpdated: string;
  statusId: number | null;
  status: Status;
  subjectId: number | null;
  subject: Status;
  categoryId: number | null;
  category: Status;
  clientId: number | null;
  client: Client;
  productId: number | null;
  product: Product;
  assigningAgentId: number;
  assigningAgent: Agent;
  assignedAgentId: number | null;
  assignedAgent: Agent;
  desc: string;
  numAttempts: number;
  forManager: boolean;
  serviceRequestNotes: Note[];
  requestGuidedQuestions: any[];
  guidedQuestionsMode: boolean;
  isChat: boolean;
}
