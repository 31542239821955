<form #f="ngForm" (ngSubmit)="onSubmit()">
  <label for="Name">Name</label>
  <input name="firstName" [(ngModel)]="firstName" />
  <br />
  <label for="email">Email</label>
  <input name="email" [(ngModel)]="email" />
  <br />
  <label for="phone">Phone</label>
  <input name="phone" [(ngModel)]="phone" />
  <br />
  <label for="Source">Source</label>
  <input name="source" [(ngModel)]="source" />
  <br />
  <button type="submit">submit</button>
</form>
{{clientId}}