import { RegularUser } from './RegularUser';
import { Patient } from './Patients';
import { Deal } from './Deal';
import { LeadSource } from './LeadSource';
import { ClientRole } from './ClientRole';
import { Status } from './Status';
import { Agent } from './Agent';
import { ClientType } from './ClientType';
import { Product } from './Product';
import { ProductSummery } from './ProductSummery';
import { Note } from './Note';
import { ProductIsFor } from './ProductIsFor';
import { Logging } from './Logging';
import { CardToken } from './CardToken';
import { LeadStatus } from './LeadStatus';
import { FutureProduct } from './FutureProduct';
import { BarCity } from './BarCity';
import { BarStreet } from './BarStreet';
import { RoleType } from './RoleType';

export class Client {
  clientId: number;
  regularUserId: number;
  regularUser: RegularUser;
  patients: Patient[];
  clientDeals: Deal[];
  clientRoleId: number;
  clientRole: ClientRole;
  leadStatusId: number;
  leadStatus: LeadStatus;
  productIsForId: number | null;
  productIsFor: ProductIsFor;
  leadSourceId: number;
  leadSource: LeadSource;
  leadSourceText: string;
  agentId: number;
  agent: Agent;
  clientTypeId: number;
  clientType: ClientType;
  futureProducts: FutureProduct[];
  products: Product[];
  clientNotes: Note[];
  clientLogs: Logging[];
  clientCardTokens: CardToken[];
  isDuplicate: boolean;
  isChecked: boolean = false;
  convertedDate: string | null;
  isAgentChanged: boolean;
  constructor(type = '') {
    this.regularUser = new RegularUser();
    this.clientRole = new ClientRole(type);
  }
}
