import { Component, OnInit } from '@angular/core';
import { EmailTemplate } from 'src/app/data/EmailTemplate';
import { EmailTemplateMin } from 'src/app/data/EmailTemplateMin';
import { EventAction } from 'src/app/data/EventAction';
import { Status } from 'src/app/data/Status';
import { TriggerEvent } from 'src/app/data/TriggerEvent';
import { EmailTemplatesService } from 'src/app/Services/email-templates.service';
import { EventActionsService } from 'src/app/Services/event-actions.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { TriggerEventsService } from 'src/app/Services/trigger-events.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TriggerEventObjectType } from 'src/app/data/TriggerEventObjectType';
import { TriggerEventObjectTypesService } from 'src/app/Services/trigger-event-object-types.service';

@Component({
  selector: 'app-trigger-events',
  templateUrl: './trigger-events.component.html',
  styleUrls: ['./trigger-events.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TriggerEventsComponent implements OnInit {
  newEventName: string;
  eventTypes: Status[];
  statuses: Status[];
  actionTypes: Status[];
  emailTemplates: EmailTemplateMin[];
  productTypes: Status[];
  triggerEventObjectTypes:TriggerEventObjectType[]

  constructor(
    private triggerEventsService: TriggerEventsService,
    private statusesService: StatusesService,
    private eventActionsService: EventActionsService,
    private emailTemplatesService: EmailTemplatesService,
    private triggerEventObjectTypesService: TriggerEventObjectTypesService

  ) {}
  eventsDisplayedColumns: string[] = [
    'EventType',
    'StatusId',
    'ProductType',
    'ObjectType',
    'Name',
    'Delete',
    'Actions',
  ];
  triggerEvents: TriggerEvent[];
  expandedElement: TriggerEvent | null;
  ngOnInit(): void {
    this.statusesService.getAll().subscribe((res) => {
      this.eventTypes = res.filter((x) => x.category == 'EventType');
      this.statuses = res.filter(
        (x) => x.category == 'Product' || x.category == 'RequestStatus'
      );
      this.actionTypes = res.filter((x) => x.category == 'ActionType');
      this.productTypes = res.filter(
        (x) => x.category == 'TriggerEventProductType'
      );
    });
    this.emailTemplatesService.getAllAutoSender().subscribe((res) => {
      this.emailTemplates = res;
    });
    this.triggerEventObjectTypesService.getAll().subscribe(res=>{
      this.triggerEventObjectTypes = res;
    })

    this.getAll();
  }
  getAll() {
    this.triggerEventsService
      .getAll()
      .subscribe((res) => (this.triggerEvents = res));
  }

  AddEvent() {
    var triggerEvent = new TriggerEvent();
    triggerEvent.name = this.newEventName;
    triggerEvent.statusId = 1;
    triggerEvent.eventTypeId = 1;
    this.triggerEventsService.post(triggerEvent).subscribe((res) => {
      this.getAll();
    });
  }
  AddAction(triggerEvent: TriggerEvent) {
    var eventAction: EventAction = new EventAction();
    eventAction.triggerEventId = triggerEvent.triggerEventId;
    eventAction.actionTypeId = 1;
    eventAction.emailTemplateId = 1;
    eventAction.isActive = true;
    this.eventActionsService.post(eventAction).subscribe((x) => this.getAll());
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveStatusForTriggerEvent(event, triggerEvent: TriggerEvent) {
    triggerEvent.statusId = event.value;
    this.triggerEventsService
      .put(triggerEvent)
      .subscribe((res) => this.getAll());
  }
  saveEventTypeForTriggerEvent(event, triggerEvent: TriggerEvent) {
    triggerEvent.eventTypeId = event.value;
    this.triggerEventsService
      .put(triggerEvent)
      .subscribe((res) => this.getAll());
  }
  savePruductTypeForTriggerEvent(event, triggerEvent: TriggerEvent) {
    triggerEvent.triggerEventProductTypeId = event.value;
    this.triggerEventsService
      .put(triggerEvent)
      .subscribe((res) => this.getAll());
  }
  deleteEvent(triggerEvent: TriggerEvent) {
    this.triggerEventsService
      .delete(triggerEvent)
      .subscribe((res) => this.getAll());
  }
  updateEvents() {
    this.getAll();
  }
  saveTriggerEvent(triggerEvent: TriggerEvent) {
    this.triggerEventsService
      .put(triggerEvent)
      .subscribe((res) => this.getAll());
  }
}
