<mat-list class="leadsTable" role="list">
  <mat-list-item class="leadsTable__header" role="listitem">
    <span>Tube ID</span>
    <span>Registered date</span>
    <span>Patient Name </span>
    <span>Patient ID</span>
    <span>Client ID</span>
    <span>Status</span>
  </mat-list-item>
  <mat-card class="leadsTable__item" *ngFor="let patientItem of patientsData">
    <mat-list-item role="listitem">
      <span class="d-flex-center">
        <img
          *ngIf="!isProductPlus(patientItem)"
          class="mr-1"
          src="../../../assets/Nu_Original.svg"
          alt="s"
        />
        <img
          *ngIf="isProductPlus(patientItem)"
          class="mr-1"
          src="../../../assets/Nu_Plus.svg"
          alt="s"
        />
        {{ patientItem.barCode }}
        <!-- + " " + leadItem.regularUser.lastName --></span
      >

      <span>{{ patientItem.registeredDate | date: "dd-MM-yyyy" }}</span>
      <span>{{ patientItem.patientName }}</span>
      <span>{{ patientItem.patientIdNum }}</span>
      <span>{{ patientItem.clientIdNum }}</span>
      <span
        class="round-indicator"
        [style.background-color]="patientItem.status.color"
        >{{ patientItem.status.name }}</span
      >
      <span style="text-align: center"
        ><button (click)="editTest($event, patientItem)">
          <img src="../../../../assets/client-edit.svg" alt="edit" /></button
      ></span>
    </mat-list-item>
  </mat-card>
</mat-list>
