import { HttpClient, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-results-csv',
  templateUrl: './upload-results-csv.component.html',
  styleUrls: ['./upload-results-csv.component.scss'],
})
export class UploadResultsCsvComponent implements OnInit {
  /*private readonly uploadResponse = new BehaviorSubject<any>([]);
  readonly uploadResponse$ = this.uploadResponse.asObservable();
  */
  // @Output('submitNote') submitNote: EventEmitter<any> = new EventEmitter();
  @Input() packageId: number;
  @Input() title: string;
  @Input() accept: string;
  @Input() uploadPath: string;
  @Input() classAttr: string;
  @Input() disabled: boolean;
  @ViewChild('inputFile') inputFile: ElementRef;
  @Output() public onUploadFinished = new EventEmitter();

  public progress: number;
  public message: string;
  displayedColumns: string[] = ['geneName', 'Result'];
  dataSource: any[] = [];
  afuConfig;
  typeError: string;
  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {}

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    this.typeError = '';
    const allowTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/tiff',
      'image/svg',
      'image/svg+xml',
      'text/csv',
      '.xls',
      '.xlsx',
      '.csv',
      '.tsv',
      '.tabular',
      '.dat',
      '.doc',
      '.docx',
      '.xml',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-excel',
    ];

    this.dataSource = [];
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      var type;
      if (files[i].type == '') {
        type = '.' + files[i].name.split('.').pop();
      } else {
        type = files[i].type;
      }
      /* if (!allowTypes.includes(type)) {
        this.typeError = 'File type not valid';
        return;
      } */
      formData.append('file', files[i], files[i].name);
    }
    this.onUploadFinished.emit(false);
    this.typeError = '';
    // const path='https://localhost:5001/api/upload';
    //const path = 'http://localhost:44331/api/UploadCsv';
    // const path = `${environment.baseUrl}UploadResultsCsv/${this.packageId}`;
    const path = `${environment.baseUrl}${this.uploadPath}`;

    this.http
      .post<any[]>(path, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.dataSource = event.body;
          this.inputFile.nativeElement.value = '';
          this.onUploadFinished.emit(true);
        }
      });
    /*
    this.http.post<any>(path, formData).subscribe((res) => {
      var a = res;
      this.uploadResponse.next(res);
    });
    */
  };
}
