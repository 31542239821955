<div class="wrapper">
  <div class="title">Summary Report Links</div>
  <div class="text-center" style="margin-bottom: 2rem">
    <button class="create-new" (click)="createNew()" mat-button>
      Create new
    </button>
  </div>
  <table mat-table [dataSource]="algoBulletLinks">
    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <!-- Name Column -->
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="icon" mat-cell *matCellDef="let algoLink">
        <mat-icon>link</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="linkPageName">
      <th mat-header-cell *matHeaderCellDef>Link Page Name</th>
      <td mat-cell *matCellDef="let algoLink">
        {{ algoLink.name }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="lastChange">
      <th mat-header-cell *matHeaderCellDef>Last change</th>
      <td class="date" mat-cell *matCellDef="let algoLink">
        {{ algoLink.updateDate | date: "dd-MM-yyyy HH:mm" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="action" mat-cell *matCellDef="let algoLink">
        <button (click)="delete($event, algoLink.id)" mat-icon-button>
          <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="goTo(row.id)"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</div>
