<mat-accordion>
  <mat-expansion-panel hideToggle *ngFor="let formula of formulas">
    <mat-expansion-panel-header>
      <mat-panel-title> This is the expansion title </mat-panel-title>
      <mat-panel-description>
        This is a summary of the content
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let coef of formula.geneticFormulaCoefs">
      <div *ngFor="let element of coef.geneticFormulaCoefElements"></div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<button (click)="addNewFormula()">Add</button>
