<!--<div *ngIf="isLoading" class="loadingOverlay">
  <mat-spinner diameter="250"></mat-spinner>
</div>-->

<app-head-filter
  *ngIf="agents"
  [checkedIds]="checkedIds"
  [agents]="agents"
  [leadStatuses]="statuses"
  [leadSources]="leadSources"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event, true)"
  [classAttr]="'dealsAttr'"
  [paymentMethods]="paymentMethods"
></app-head-filter>
<div class="TableClearFix"></div>
<div style="padding: 0 1rem" *ngIf="isLoading" class="skeleton-loader">
  <ngx-skeleton-loader
    class="skeleton-loader"
    [theme]="{
      height: '70px',
      'margin-bottom': '10px',
      'background-color': 'rgba(27, 63, 78,0.15)'
    }"
    count="10"
  ></ngx-skeleton-loader>
</div>
<app-transactions-table
  *ngIf="!isLoading && statuses"
  [dealsSummery]="dealsSummery"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  [transactionData]="transactionData"
  [statuses]="statuses"
>
</app-transactions-table>

<app-table-paginator
  *ngIf="transactionData && metaData"
  [checkedIds]="checkedIds"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'transAttr'"
>
</app-table-paginator>
