import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LeadStatus } from 'src/app/data/LeadStatus';

@Component({
  selector: 'app-lead-status-item',
  templateUrl: './lead-status-item.component.html',
  styleUrls: ['./lead-status-item.component.css'],
})
export class LeadStatusItemComponent implements OnInit {
  @Input() items: LeadStatus[];
  @ViewChild('childMenu') public childMenu;
  constructor() {}

  ngOnInit(): void {}
}
