import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client } from '../data/Client';
import { ClientForTableMin } from '../data/ClientForTableMin';
import { ErrorMessage } from '../data/ErrorMessage';
import { HeadFilter } from '../data/HeadFilter';
import { LeadsTable } from '../data/LeadsTable';
import { LeadsServiceInterface } from '../serviceInterfaces/LeadsServiceInterface';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

@Injectable({
  providedIn: 'root',
})
export class LeadsService implements LeadsServiceInterface {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  readonly leads = new BehaviorSubject<any>([]);
  readonly leads$ = this.leads.asObservable();

  changeStatusGet(lead: ClientForTableMin | Client) {
    return this.http.get<any>(
      `${environment.baseUrl}Leads/ChangeStatusGet/${lead.clientId}/${lead.leadStatusId}`
    );
  }
  changeAgent(lead: ClientForTableMin): Observable<Client> {
    return this.http.put<Client>(
      `${environment.baseUrl}Leads/ChangeAgent/${lead.clientId}`,
      lead
    );
  }
  changeStatus(lead: ClientForTableMin): Observable<Client> {
    return this.http.put<Client>(
      `${environment.baseUrl}Leads/ChangeStatus/${lead.clientId}`,
      lead
    );
  }
  search(searchTerm: string) {
    return this.http.get<any>(
      `${environment.baseUrl}Leads/Search/${searchTerm}`
    );
  }
  searchV2(searchTerm: string, orderBy, acending, pageSize, pageNumber) {
    return this.http.get<any>(
      `${environment.baseUrl}Leads/SearchV2/${searchTerm}/${orderBy}/${acending}/${pageSize}/${pageNumber}`
    );
  }
  getAll(): Observable<Client[]> {
    return this.http.get<Client[]>(`${environment.baseUrl}Leads/`);
  }
  getAllByFilter(headFilter: HeadFilter) {
    //console.log('leads.service getAllByFilter()', headFilter);
    if (headFilter) {
      return this.http.get<LeadsTable>(
        `${
          environment.baseUrl
        }Leads/Filter/${this.httpFilterHelper.createPathFromHeadFilter(
          headFilter,
          this.datepipe
        )}`

        /* `${environment.baseUrl}Leads/Filter/${headFilter.agentId}/${
      headFilter.statusId
      }/${headFilter.sourceId}/${
      headFilter.dateStart == null
        ? null
        : this.datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
      }/${
      headFilter.dateEnd == null
        ? null
        : this.datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
      }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${headFilter.acending}/${headFilter.pageSize}/${headFilter.pageNumber}`
 */
      );
    }
  }
  getNewLead(): Observable<Client> {
    return this.http.get<Client>(`${environment.baseUrl}Leads/NewLead`);
  }
  getById(leadId): Observable<Client | ErrorMessage> {
    return this.http.get<Client>(`${environment.baseUrl}Leads/${leadId}`);
  }

  put(lead: Client): Observable<Client | ErrorMessage> {
    return this.http.put<Client>(
      `${environment.baseUrl}Leads/${lead.clientId}`,
      lead
    );
    //.pipe(catchError(this.handlePutError));
  }

  putOld(lead: Client): Observable<Client> {
    return this.http.put<Client>(
      `${environment.baseUrl}Leads/${lead.clientId}`,
      lead
    );
    //  .pipe(catchError(this.handlePutError));
  }
  bulkChangeAgent(clientIds: number[], agentId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Leads/bulkChangeAgent/${agentId}`,
      clientIds
    );
  }
  bulkChangeAgent2(
    clientIds: number[],
    agentId: number,
    transferMissions: boolean
  ) {
    return this.http.post<any>(
      `${environment.baseUrl}Leads/bulkChangeAgentV2/${agentId}/${transferMissions}`,
      clientIds
    );
  }
  bulkChangeStatus(clientIds: number[], statusId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Leads/bulkChangeStatus/${statusId}`,
      clientIds
    );
  }
  handlePutError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  /*
  bulkPut(leads: ClientForTableMin[]) {
    var regularUserId = Globals.regularUserId;
    return this.http.post<Client[]>(
      `${environment.baseUrl}Leads/BulkPut/${regularUserId}`,
      leads
    );
  }*/
  post(lead: Client): Observable<Client | ErrorMessage> {
    return this.http.post<Client>(`${environment.baseUrl}Leads/`, lead);
  }
  delete(lead: Client) {
    return this.http.delete(`${environment.baseUrl}Leads/${lead.clientId}`);
  }
}
