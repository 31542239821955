import { AppImage } from './AppImage';

export class AlgoSubCategoryResultType {
  algoSubCategoryResultTypeId: number;
  algoSubCategoryId: number;
  resultTypeId: number;
  orderBy: number;
  langId: number;
  color: string;
  name: string;
  description: string;
  files: AppImage[];
  lastUpdated: string;
}
