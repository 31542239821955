<button
  [routerLink]="['/phone-verification-setting']"
  style="margin: 1rem 0"
  mat-icon-button
>
  <mat-icon>arrow_back</mat-icon>
</button>
<mat-card
  style="width: 50%"
  *ngIf="countries && phoneVerificationSetting && form"
>
  <mat-card-title style="margin-bottom: 3rem">
    {{ countryId > 0 ? "Edit" : "Add" }} phone verification settings
  </mat-card-title>
  <form [formGroup]="form">
    <mat-card-content>
      <div class="item">
        <div class="item__label">Country code</div>
        <app-autocomplete-countries
          class="item__label"
          (selectedValue)="saveCountries($event)"
          [data]="countries"
          [initialValue]="form.get('countryId').value"
        >
        </app-autocomplete-countries>
      </div>
      <div class="item">
        <div class="item__label">Phone prefixs</div>
        <mat-form-field class="item__field chip-list" appearance="fill">
          <mat-chip-list #phonePrefixsList formArrayName="phonePrefixs">
            <mat-chip
              *ngFor="
                let item of form.get('phonePrefixs')['controls'];
                let index = index
              "
              (removed)="removePrefixs(index, 'phonePrefixs')"
            >
              {{ item.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="New prefix ..."
              [matChipInputFor]="phonePrefixsList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addPrefixs($event, 'phonePrefixs')"
            />
          </mat-chip-list>
          <mat-error>Atleast 1 prefixs need to be added</mat-error>
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Mobile prefixs</div>
        <mat-form-field class="item__field chip-list" appearance="fill">
          <mat-chip-list #mobilePrefixsList formArrayName="mobilePrefixs">
            <mat-chip
              *ngFor="
                let item of form.get('mobilePrefixs')['controls'];
                let index = index
              "
              (removed)="removePrefixs(index, 'mobilePrefixs')"
            >
              {{ item.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="New prefix ..."
              [matChipInputFor]="mobilePrefixsList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addPrefixs($event, 'mobilePrefixs')"
            />
          </mat-chip-list>
          <mat-error>Atleast 1 prefixs need to be added</mat-error>
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Local prefix</div>
        <mat-form-field appearance="fill" class="item__field">
          <input name="localPrefix" matInput formControlName="localPrefix" />
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Max length</div>
        <mat-form-field appearance="fill" class="item__field">
          <input matInput type="number" formControlName="maxNumLength" />
          <mat-error>Maximum prefix length is required</mat-error>
        </mat-form-field>
      </div>
      <div class="item">
        <div class="item__label">Min length</div>
        <mat-form-field appearance="fill" class="item__field">
          <input matInput type="number" formControlName="minNumLength" />
          <mat-error>Minimum prefix length is required</mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>
  </form>
  <mat-card-actions align="center" style="margin-bottom: 0; padding-bottom: 0">
    <button mat-raised-button type="submit" (click)="save()">SAVE</button>
    <button
      [routerLink]="['/phone-verification-setting']"
      mat-button
      type="button"
    >
      CANCEL
    </button>
  </mat-card-actions>
</mat-card>
