<div style="display: flex; align-items: center">
  <button class="back back--test" (click)="goBackToTests()">
    < Back to Tests
  </button>

  <button (click)="gotoLogs()" class="logging--btn">
    <img src="../../../../assets/triangle.svg" />
    Log
  </button>
  <button
    *ngIf="userHasPermission(['Products_SendInfo']) && patient && product"
    (click)="openSendInfoDialog()"
    class="send-info"
  >
    Send info
  </button>
</div>
<div style="display: flex">
  <div style="width: 80%">
    <div>
      <div style="display: flex">
        <mat-card style="width: 50%; margin: 20px" class="clientDetails">
          <mat-list>
            <app-patient-profile-view
              *ngIf="product && isEditMode == false"
              (moveToEdit)="moveToEditMode()"
              [product]="product"
              [statuses]="statuses"
              (reloadProduct)="loadProduct()"
            >
            </app-patient-profile-view>
            <app-client-profile-edit
              (cancelForm)="onCancelForm($event)"
              (saveFormProduct)="savePatientDetails($event)"
              *ngIf="product && isEditMode == true"
              [product]="product"
              [classAttr]="'patientAttr'"
              [agents]="agents"
            >
            </app-client-profile-edit>
          </mat-list>
        </mat-card>
        <mat-card style="width: 50%; margin: 20px">
          <app-test-general-information
            *ngIf="agentUser && product && statuses"
            [product]="product"
            [agentUser]="agentUser"
            [statuses]="statuses"
          >
          </app-test-general-information>
        </mat-card>
      </div>
      <mat-card style="margin: 20px" class="clientTests">
        <mat-tab-group
          #tabs
          [(selectedIndex)]="tabIndex"
          class="clientTestsTabs"
        >
          <mat-tab label="Activities">
            <app-lead-activities-view
              [classTabsAttr]="'clientTestsActivitesTabs'"
              (createNewTask)="createNewTask($event)"
              *ngIf="isTaskEditMode == false"
              [notes]="patientsTasks"
              [classAttr]="'clientAttr'"
              [testPusher]="true"
            >
            </app-lead-activities-view>
            <app-lead-create-task
              (submitTask)="saveTask($event)"
              (cancelTask)="closeNewTaskWindow()"
              *ngIf="isTaskEditMode == true"
              [agents]="agents"
              [currAgent]="agentUser"
              [note]="activityToEdit"
            >
            </app-lead-create-task>
          </mat-tab>
          <mat-tab
            *ngIf="userHasPermission(['Products_ViewDeliveries'])"
            label="Deliveries"
          >
            <app-deliveries-table
              [displayedColumns]="deliveriesDisplayedColumns"
              [patientDeliveries]="patientDeliveries"
              [classAttr]="'PatientDeliveries'"
            >
            </app-deliveries-table>
            <!--
            <app-patient-deliveries
              [patientDeliveries]="patientDeliveries"
              [headFilter]="headFilter"
            >
            </app-patient-deliveries>
          -->
          </mat-tab>
          <mat-tab
            *ngIf="userHasPermission(['Products_ViewServiceRequest'])"
            style="min-height: 400px"
          >
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Service Requests</div>
            </ng-template>
            <!--<app-lead-requests-view
              (createNewRequest)="createNewRequest($event)"
              *ngIf="isRequestEditMode == false && requests"
              [classAttr]="'clientAttr'"
              [requests]="requests"
            ></app-lead-requests-view>-->
            <div *ngIf="isRequestEditMode == false && requests">
              <perfect-scrollbar
                style="max-height: 30vh; height: 30vh"
                [perfectScrollbar]="config"
              >
                <app-service-request-table
                  *ngIf="requests && agents"
                  [agents]="agents"
                  [serviceRequests]="requests"
                  [requestsStatuses]="requestsStatuses"
                >
                </app-service-request-table>
              </perfect-scrollbar>
              <div>
                <button
                  class="btn-wide btn-wide--client"
                  (click)="createNewRequest()"
                >
                  + New Request
                </button>
              </div>
            </div>
            <app-lead-create-request
              *ngIf="isRequestEditMode == true && agents"
              (cancelRequest)="closeNewRequestWindow()"
              (submitRequest)="saveRequest($event)"
              [request]="requestToEdit"
              [currAgent]="agentUser"
              [allAgents]="agents"
            >
            </app-lead-create-request>
          </mat-tab>
          <mat-tab
            style="min-height: 400px"
            *ngIf="userHasPermission(['Products_ViewCalls'])"
          >
            <ng-template mat-tab-label>
              <div class="task-overdue-tab-unselected">Calls</div>
            </ng-template>
            <div style="display: flex">
              <button
                mat-button
                class="fakeTab"
                [ngClass]="{ active: isCallsEditMode }"
                (click)="activateCallEditMode(true)"
              >
                Calls
              </button>
              <button
                mat-button
                class="fakeTab"
                [ngClass]="{ active: !isCallsEditMode }"
                (click)="activateCallEditMode(false)"
              >
                Calls History
              </button>
            </div>
            <app-client-create-call
              #clientCreateCall
              [subjects]="callsStatuses"
              [agentUser]="agentUser"
              *ngIf="isCallsEditMode"
              [note]="callToEdit"
              [isFollowUp]="userHasPermission(['Calls Follow Up'])"
              (submitCall)="saveCall($event)"
              (cancelCall)="closeNewCallWindow()"
              (notAnswerCall)="callNotAnswerCall()"
            >
              <!--  -->
            </app-client-create-call>
            <app-client-calls-view
              [notes]="clientCalls"
              [metaData]="clientCallsMetaData"
              [patientId]="patientId"
              *ngIf="!isCallsEditMode"
              [classAttr]="'clientAttr'"
              (createNewCall)="createNewCall($event)"
            >
            </app-client-calls-view>
          </mat-tab>
        </mat-tab-group>

        <!--
        Reports
        <app-patient-report-thumb></app-patient-report-thumb>
      -->
        <!-- Tests <hr />
        <app-client-tests [clientProducts]="clientProducts"></app-client-tests>
        -->
      </mat-card>
    </div>
  </div>
  <div style="width: 20%; margin: 20px">
    <app-client-notes
      [classAttr]="'testAttr'"
      [notes]="patientNotes"
      (openNewNote)="createNewNote($event)"
      (reloadNotes)="onReloadNotes()"
      *ngIf="patientNotes && isNoteEditMode == false"
    >
    </app-client-notes>
    <app-client-add-note
      [classAttr]="'testAttr'"
      [statuses]="leadStatuses"
      (submitNote)="saveNote($event)"
      (cancelNote)="closeNewNoteWindow()"
      (reloadNotes)="onReloadNotes()"
      *ngIf="patientNotes && isNoteEditMode == true"
      [note]="noteToEdit"
    >
    </app-client-add-note>
  </div>
</div>
