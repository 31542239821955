<mat-list style="height: 400px; margin-top: 20px;" class="activitiesThumbList">
  <mat-list-item *ngFor="let request of requests" style="min-height: 60px;">
    <app-lead-request-thumb
      [request]="request"
      [classAttr]="classAttr"
      style="width: 100%;"
    >
    </app-lead-request-thumb>
  </mat-list-item>
  <mat-list-item *ngIf="disableNew == false" class="row-container__item">
    <button
      class="btn-wide"
      [class.btn-wide--lead]="classAttr == 'leadAttr'"
      [class.btn-wide--client]="classAttr == 'clientAttr'"
      (click)="onCreateNewRequest()"
    >
      + New Request
    </button>
  </mat-list-item>
</mat-list>
