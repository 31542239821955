<div class="title" *ngIf="data.label.fgLabelId == 0">Create new Label</div>
<div class="title" *ngIf="data.label.fgLabelId > 0">Edit Label</div>

<div class="grid item mt-4">
  <div class="label">Name:</div>
  <mat-form-field appearance="fill" class="item__field">
    <input matInput [(ngModel)]="data.label.name" />
  </mat-form-field>
</div>
<div class="d-flex" style="width: 100%; gap: 50px">
  <div style="flex: 0.4">
    <div class="grid">
      <div></div>
      <div class="grid item">
        <div class="label">Nutrient:</div>
        <mat-form-field class="item__select" appearance="fill">
          <mat-select
            [(ngModel)]="data.label.nutrientKeyId"
            placeholder="Select"
          >
            <mat-option *ngFor="let item of data.nutrients" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="grid">
      <div></div>
      <div class="grid item">
        <div class="label">Value:</div>
        <mat-form-field appearance="fill" class="item__field">
          <input type="number" matInput [(ngModel)]="data.label.maxValue" />
        </mat-form-field>
      </div>
    </div>
    <div class="grid">
      <div></div>
      <div class="grid item">
        <div class="label">Order by:</div>
        <mat-form-field appearance="fill" class="item__field">
          <input type="number" matInput [(ngModel)]="data.label.orderBy" />
        </mat-form-field>
      </div>
    </div>
    <div class="grid">
      <div></div>
      <div class="grid item">
        <div class="label">Max liquid value:</div>
        <mat-form-field appearance="fill" class="item__field">
          <input
            type="number"
            matInput
            [(ngModel)]="data.label.maxLiquidValue"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div style="flex: 0.6">
    <app-algo-image
      [langId]="data.langId"
      [algoImageType]="ImageType.FoodGenesLabel"
      (onUploadFinished)="onUploadFinished($event)"
      [selectedImg]="getImage()"
    ></app-algo-image>
  </div>
</div>

<div class="actions mt-4">
  <div class="actions__empty"></div>
  <div class="actions__btns">
    <button class="save" mat-button (click)="save()" type="submit">Save</button>
    <button class="cancel" mat-button mat-dialog-close type="button">
      Close
    </button>
  </div>
</div>
<!-- 
  [disabled]="
          !coeff.fgCoefficientName &&
          !coeff.orderBy &&
          !coeff.validExceptPersent
        " -->
