import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NewSession } from 'src/app/data/chat/NewSession';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss'],
})
export class WaitingListComponent implements OnInit, OnChanges {
  waitingList: NewSession[];
  selectedRow: NewSession;
  @Input() resetSelected: boolean;
  @Output() openSession: EventEmitter<NewSession> = new EventEmitter();

  constructor(public firebaseService: FirebaseChatIndicatorService) {}

  ngOnInit(): void {
    // this.firebaseService.waitingList$.subscribe((data) => {
    //   if (data) {
    //     this.waitingList = data;
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.resetSelected &&
      changes.resetSelected.currentValue &&
      !changes.resetSelected.isFirstChange()
    ) {
      this.selectedRow = null;
    }
  }
  onRowClicked(row: NewSession) {
    this.selectedRow = row;
    this.openSession.emit(row);
  }
}
