import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllCategoriesComponent } from './Algorithm/categories/all-categories/all-categories.component';
import { RecomendationsComponent } from './Algorithm/dna-report/recomendations/recomendations.component';
import { SingleCategoryComponent } from './Algorithm/dna-report/single-category/single-category.component';
import { LabPackegesComponent } from './Algorithm/labs/lab-packeges/lab-packeges.component';
import { LabsListComponent } from './Algorithm/labs/labs-list/labs-list.component';
import { LabsVersionComponent } from './Algorithm/labs/labs-version/labs-version.component';
import { UploadLabResultComponent } from './Algorithm/labs/upload-lab-result/upload-lab-result.component';
import { AlgoPrototypeComponent } from './Algorithm/prototypes/algo-prototype/algo-prototype.component';
import { AllSnpsComponent } from './Algorithm/snps/all-snps/all-snps.component';
import { SnpsVersionComponent } from './Algorithm/snps/snps-version/snps-version.component';
import { AuthGuard } from './Auth/auth.guard';
import { PatientReportsEditComponent } from './CardsEdit/patient-reports-edit/patient-reports-edit.component';
import { DnaReportInnerComponent } from './CardsView/dna-report-inner/dna-report-inner.component';
import { DnaReportViewComponent } from './CardsView/dna-report-view/dna-report-view.component';
import { DnaResultsCalculatorComponent } from './CardsView/dna-results-calculator/dna-results-calculator.component';
import { MainChatBoardComponent } from './Chat/main-chat-board/main-chat-board.component';
import { AgentSettingsComponent } from './Components/Agents/agent-settings/agent-settings.component';
import { AllAgentsComponent } from './Components/Agents/all-agents/all-agents.component';
import { EditAgentComponent } from './Components/Agents/edit-agent/edit-agent.component';
import { ResetPasswordComponent } from './Components/Agents/reset-password/reset-password.component';
import { AllAlgoBulletLinksComponent } from './Components/algo-bullets-links/all-algo-bullet-links/all-algo-bullet-links.component';
import { EditAlgoBulletLinksComponent } from './Components/algo-bullets-links/edit-algo-bullet-links/edit-algo-bullet-links.component';
import { AllAlgoBulletsComponent } from './Components/algo-bullets/all-algo-bullets/all-algo-bullets.component';
import { ArticlesComponent } from './Components/articles/articles.component';
import { AllAutoResponderComponent } from './Components/auto-responder/all-auto-responder/all-auto-responder.component';
import { EditAutoResponderComponent } from './Components/auto-responder/edit-auto-responder/edit-auto-responder.component';
import { BarcodesManagmentComponent } from './Components/barcodes-managment/barcodes-managment.component';
import { AgentScheduleComponent } from './Components/calendar-management/agent-schedule/agent-schedule.component';
import { AgentsCalendarTableComponent } from './Components/calendar-management/agents-calendar-table/agents-calendar-table.component';
import { MeetingSettingsComponent } from './Components/calendar-management/meeting-settings/meeting-settings.component';
import { CallsReviewsComponent } from './Components/calls-reviews/calls-reviews.component';
import { AllCancelationsComponent } from './Components/Cancelations/all-cancelations/all-cancelations.component';
import { EditCancelationRequestComponent } from './Components/Cancelations/edit-cancelation-request/edit-cancelation-request.component';
import { ChatHourComponent } from './Components/chat-hour/chat-hour.component';
import { ChatListComponent } from './Components/chat-list/chat-list.component';
import { AllClientsComponent } from './Components/Clients/all-clients/all-clients.component';
import { ClientLogsComponent } from './Components/Clients/client-logs/client-logs.component';
import { EditClientComponent } from './Components/Clients/edit-client/edit-client.component';
import { CreateNewUserComponent } from './Components/create-new-user/create-new-user.component';
import { EnterTokenComponent } from './Components/enter-token/enter-token.component';
import { FarLoginAdminComponent } from './Components/far-login-admin/far-login-admin.component';
import { EditFileTypeComponent } from './Components/file-settings/edit-file-type/edit-file-type.component';
import { FileExtensionsComponent } from './Components/file-settings/file-extensions/file-extensions.component';
import { FileTypesComponent } from './Components/file-settings/file-types/file-types.component';
import { AllFoodsComponent } from './Components/Food/all-foods/all-foods.component';
import { BulkEditFoodComponent } from './Components/Food/bulk-edit-food/bulk-edit-food.component';
import { BulkEditRecipeComponent } from './Components/Food/bulk-edit-recipe/bulk-edit-recipe.component';
import { EditFoodComponent } from './Components/Food/edit-food/edit-food.component';
import { FoodCategoriesComponent } from './Components/Food/food-categories/food-categories.component';
import { FoodDetailsComponent } from './Components/Food/food-details/food-details.component';
import { FoodForCategoryComponent } from './Components/Food/food-for-category/food-for-category.component';
import { FoodNutrientsComponent } from './Components/Food/food-nutrients/food-nutrients.component';
import { FoodScalesComponent } from './Components/Food/food-scales/food-scales.component';
import { LifestyleVersionsComponent } from './Components/Fragments/lifestyle-versions/lifestyle-versions.component';
import { GeneticFormulaComponent } from './Components/genetic-formula/genetic-formula.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { AllLeadsComponent } from './Components/Leads/all-leads/all-leads.component';
import { EditLeadComponent } from './Components/Leads/edit-lead/edit-lead.component';
import { LeadLogsComponent } from './Components/Leads/lead-logs/lead-logs.component';
import { LifeStyleCalculationsComponent } from './Components/lifestyle/life-style-calculations/life-style-calculations.component';
import { LoadingPageComponent } from './Components/loading-page/loading-page.component';
import { AlgoGroupsComponent } from './Components/Manage/algo-groups/algo-groups.component';
import { BarHoldupComponent } from './Components/Manage/bar-holdup/bar-holdup.component';
import { ConfettiComponent } from './Components/Manage/confetti/confetti.component';
import { CreateDnaPdfComponent } from './Components/Manage/create-dna-pdf/create-dna-pdf.component';
import { DashboardComponent } from './Components/Manage/dashboard/dashboard.component';
import { DebugLinksComponent } from './Components/Manage/debug-links/debug-links.component';
import { ExternalDealComponent } from './Components/Manage/external-deal/external-deal.component';
import { ExternalLeadComponent } from './Components/Manage/external-lead/external-lead.component';
import { FarLoginHistoryComponent } from './Components/Manage/far-login-history/far-login-history.component';
import { FirebaseLoginComponent } from './Components/Manage/firebase-login/firebase-login.component';
import { GuidedQuestComponent } from './Components/Manage/guided-quest/guided-quest.component';
import { GuidedQuestionsComponent } from './Components/Manage/guided-questions/guided-questions.component';
import { LifestyleTreeV2Component } from './Components/Manage/lifestyle-tree-v2/lifestyle-tree-v2.component';
import { LifestyleTreeComponent } from './Components/Manage/lifestyle-tree/lifestyle-tree.component';
import { ModuleToActionsComponent } from './Components/Manage/module-to-actions/module-to-actions.component';
import { PermissionsForRolesNewComponent } from './Components/Manage/permissions-for-roles-new/permissions-for-roles-new.component';
import { PermissionsForRolesComponent } from './Components/Manage/permissions-for-roles/permissions-for-roles.component';
import { PermissionsComponent } from './Components/Manage/permissions/permissions.component';
import { ProcessDnaDataComponent } from './Components/Manage/process-dna-data/process-dna-data.component';
import { RolesComponent } from './Components/Manage/roles/roles.component';
import { SearchBarcodeHistoryComponent } from './Components/Manage/search-barcode-history/search-barcode-history.component';
import { SendSmsComponent } from './Components/Manage/send-sms/send-sms.component';
import { TestStatusesComponent } from './Components/Manage/test-statuses/test-statuses.component';
import { TransferAgentsClientsComponent } from './Components/Manage/transfer-agents-clients/transfer-agents-clients.component';
import { ViewMailsComponent } from './Components/Manage/view-mails/view-mails.component';
import { NutritionMenuTemplatesComponent } from './Components/nutrition-menu-templates/nutrition-menu-templates.component';
import { NutritionMenuComponent } from './Components/nutrition-menu/nutrition-menu.component';
import { OfficePhoneExtensionsComponent } from './Components/office-phone-extensions/office-phone-extensions.component';
import { AllDeliveriesResponsesComponent } from './Components/Patients/all-deliveries-responses/all-deliveries-responses.component';
import { AllPatientsDeliveriesComponent } from './Components/Patients/all-patients-deliveries/all-patients-deliveries.component';
import { AllPhoneVerificationSettingComponent } from './Components/phone-verification-settings/all-phone-verification-setting/all-phone-verification-setting.component';
import { EditPhoneVerificationSettingComponent } from './Components/phone-verification-settings/edit-phone-verification-setting/edit-phone-verification-setting.component';
import { EditRefundComponent } from './Components/Refunds/edit-refund/edit-refund.component';
import { ReportRecomendationsComponent } from './Components/report-recomendations/report-recomendations.component';
import { ActionConvertionsComponent } from './Components/roles/action-convertions/action-convertions.component';
import { AllRolesComponent } from './Components/roles/all-roles/all-roles.component';
import { EditRoleComponent } from './Components/roles/edit-role/edit-role.component';
import { RoleManagmentV2Component } from './Components/roles/role-managment-v2/role-managment-v2.component';
import { RoleManagmentComponent } from './Components/roles/role-managment/role-managment.component';
import { RuleToModuleActionComponent } from './Components/roles/rule-to-module-action/rule-to-module-action.component';
import { SalesCallStatisticsComponent } from './Components/sales-call-statistics/sales-call-statistics.component';
import { SearchResultsComponent } from './Components/search-results/search-results.component';
import { AllServiceRequestsComponent } from './Components/ServiceRequests/all-service-requests/all-service-requests.component';
import { EditServiceRequestComponent } from './Components/ServiceRequests/edit-service-request/edit-service-request.component';
import { SupportDashboardComponent } from './Components/support-dashboard/support-dashboard.component';
import { AllTestsComponent } from './Components/Tests/all-tests/all-tests.component';
import { EditTestUserComponent } from './Components/Tests/edit-test-user/edit-test-user.component';
import { PatientLogsComponent } from './Components/Tests/patient-logs/patient-logs.component';
import { AllTransactionsComponent } from './Components/Transactions/all-transactions/all-transactions.component';
import { TranslationComponent } from './Components/translation/translation.component';
import { TranslationCategoriesComponent } from './Components/Translations/translation-categories/translation-categories.component';
import { TranslationKeywordsComponent } from './Components/Translations/translation-keywords/translation-keywords.component';
import { TranslationsComponent } from './Components/Translations/translations/translations.component';
import { TriggerEventsComponent } from './Components/trigger-events/trigger-events.component';
import { AllTubePackagesForLabComponent } from './Components/TubesPackages/all-tube-packages-for-lab/all-tube-packages-for-lab.component';
import { AllTubePackagesForOfficeComponent } from './Components/TubesPackages/all-tube-packages-for-office/all-tube-packages-for-office.component';
import { EditTubePackageLabComponent } from './Components/TubesPackages/edit-tube-package-lab/edit-tube-package-lab.component';
import { EditTubePackageOfficeComponent } from './Components/TubesPackages/edit-tube-package-office/edit-tube-package-office.component';
import { LabErrorsReportComponent } from './Components/TubesPackages/lab-errors-report/lab-errors-report.component';
import { UploadZameretDataComponent } from './Components/upload-zameret-data/upload-zameret-data.component';
import { VersionHistoryComponent } from './Components/version-history/version-history.component';
import { AllVideosComponent } from './Components/vod/all-videos/all-videos.component';
import { EditVideoComponent } from './Components/vod/edit-video/edit-video.component';
import { VideoCategoriesComponent } from './Components/vod/video-categories/video-categories.component';
import { ZoomEventsComponent } from './Components/zoom-events/zoom-events.component';
import { EditGenesPanelComponent } from './edit-genes-panel/edit-genes-panel.component';
import { permissionModuleAndAction } from './Enums/StatusesEnum';
import { FoodAllergiesComponent } from './FoodGenes/food-allergies/food-allergies.component';
import { FoodGenesFormulaBoardComponent } from './FoodGenes/food-genes-formula-board/food-genes-formula-board.component';
import { LabelsTableComponent } from './FoodGenes/food-genes-labels/labels-table/labels-table.component';
import { TagsTableComponent } from './FoodGenes/food-genes-tags/tags-table.component';
import { FoodGenesVersionsComponent } from './FoodGenes/food-genes-versions/food-genes-versions.component';
import { FoodGenesWrapperComponent } from './FoodGenes/food-genes-wrapper/food-genes-wrapper.component';
import { FormulaDevComponent } from './FoodGenes/formula-dev/formula-dev.component';
import { UploadFileTestComponent } from './upload-file-test/upload-file-test.component';
import { UploadResultsCsvComponent } from './upload-results-csv/upload-results-csv.component';

const routes: Routes = [
  //{ path: 'loginNew', component: HomeComponent },

  { path: '', redirectTo: 'loading', pathMatch: 'full' },
  { path: 'loading', component: LoadingPageComponent },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (f) => f.AuthenticationModule
      ),
  },
  {
    path: 'enterToken',
    component: EnterTokenComponent,
  },
  {
    path: 'uploadCsv',
    component: UploadResultsCsvComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'editGenesData',
    component: EditGenesPanelComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Algorithm_GetList],
    },
  },
  {
    path: 'algorithm',
    component: AllCategoriesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Algorithm_GetList],
    },
  },
  {
    path: 'algorithm/prototype',
    component: AlgoPrototypeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Algorithm_GetList],
    },
  },
  {
    path: 'labs/upload-result',
    component: UploadLabResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'labs/versions',
    component: LabsVersionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'labs/packages',
    component: LabPackegesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'labs/list',
    component: LabsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'snps',
    component: AllSnpsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'snps/versions',
    component: SnpsVersionComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'reportSummary',
    component: AllAlgoBulletsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.AlgoBullets_GetList],
    },
  },
  {
    path: 'agents',
    component: AllAgentsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.ManageAgents_GetList],
    },
  },
  {
    path: 'agents/edit',
    component: EditAgentComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.ManageAgents_GetList],
    },
  },
  {
    path: 'leads',
    component: AllLeadsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Leads_GetList],
    },
  },

  {
    path: 'leads/edit',
    component: EditLeadComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Leads_GetSingle],
    },
  },
  {
    path: 'leads/edit/log',
    component: LeadLogsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Leads_GetSingle],
    },
  },
  {
    path: 'clients',
    component: AllClientsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Clients_GetList],
    },
  },
  {
    path: 'clients/edit',
    component: EditClientComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Clients_GetList],
    },
  },
  {
    path: 'clients/edit/log',
    component: ClientLogsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Clients_GetSingle],
    },
  },
  {
    path: 'tests',
    component: AllTestsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Products_GetList],
    },
  },
  {
    path: 'tests/edit',
    component: EditTestUserComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Products_GetSingle],
    },
  },
  {
    path: 'tests/edit/log',
    component: PatientLogsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Products_GetSingle],
    },
  },
  {
    path: 'tests/edit/reports',
    component: PatientReportsEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Products_GetSingle],
    },
  },
  {
    path: 'tests/edit/dna-report',
    component: DnaReportViewComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.GeneticReport_GetList],
    },
  },
  {
    path: 'tests/edit/dna-report-inner',
    component: DnaReportInnerComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.GeneticReport_GetList],
    },
  },

  {
    path: 'transactions',
    component: AllTransactionsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Deals_GetList],
    },
  },
  {
    path: 'newPatient',
    component: CreateNewUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/debug',
    component: DebugLinksComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'externalLead',
    component: ExternalLeadComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'externalDeal',
    component: ExternalDealComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'search',
    component: SearchResultsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [
        permissionModuleAndAction.Leads_Search,
        permissionModuleAndAction.Clients_Search,
        permissionModuleAndAction.Products_Search,
      ],
    },
  },
  {
    path: 'permissions',
    component: PermissionsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'permissionsForRoles',
    component: PermissionsForRolesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'permissionsForRolesNew',
    component: PermissionsForRolesNewComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.ManageAgents_GetList],
    },
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'sendSms',
    component: SendSmsComponent,
  },

  {
    path: 'labDeliveries/lab',
    component: AllTubePackagesForLabComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.DeliveriesLab_GetList],
    },
  },
  {
    path: 'labDeliveries/office',
    component: AllTubePackagesForOfficeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.DeliveriesOffice_GetList],
    },
  },
  {
    path: 'labDeliveries/lab/edit',
    component: EditTubePackageLabComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.DeliveriesLab_GetList],
    },
  },
  {
    path: 'labDeliveries/office/edit',
    component: EditTubePackageOfficeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.DeliveriesOffice_GetList],
    },
  },
  {
    path: 'labDeliveries/lab/report',
    component: LabErrorsReportComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.DeliveriesLab_GetList],
    },
  },

  /*
  {
    path: 'patientsDeliveries',
    component: AllPatientsDeliveriesComponent,
    canActivate: [AuthGuard],
  },
  */
  {
    path: 'failedPatientsDeliveries',
    component: AllPatientsDeliveriesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'deliveriesResponses',
    component: AllDeliveriesResponsesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'support-dashboard',
    component: SupportDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'sales-call-statistics',
    component: SalesCallStatisticsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'confetti',
    component: ConfettiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requests',
    component: AllServiceRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requests/edit',
    component: EditServiceRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dna-calculations',
    component: DnaResultsCalculatorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancelations',
    component: AllCancelationsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Cancelations_GetList],
    },
  },
  {
    path: 'cancelations/new',
    component: EditCancelationRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancelations/conservation',
    component: EditCancelationRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancelations/manager',
    component: EditCancelationRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancelations/closed',
    component: EditCancelationRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'refunds/edit',
    component: EditRefundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'barholdup',
    component: BarHoldupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creatednapdf',
    component: CreateDnaPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'viewMails',
    component: ViewMailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'testsStatuses',
    component: TestStatusesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'guidedQuestions',
    component: GuidedQuestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'guidedQuestionsOld',
    component: GuidedQuestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'callsReviews',
    component: CallsReviewsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Reviews_GetList],
    },
  },
  {
    path: 'scripts',
    component: ArticlesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Knowledge_GetList],
    },
  },
  {
    path: 'firebaselogin',
    component: FirebaseLoginComponent,
  },
  {
    path: 'processDnaData',
    component: ProcessDnaDataComponent,
  },
  {
    path: 'chatList',
    component: ChatListComponent,
    canActivate: [AuthGuard],
    /*
    data: {
      permissions: [permissionModuleAndAction.chat],
    },
    */
  },
  {
    path: 'farloginhistory',
    component: FarLoginHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'versionhistory',
    component: VersionHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'autoresponder',
    component: AllAutoResponderComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.AutoSender_GetList],
    },
  },
  {
    path: 'autoresponder/edit',
    component: EditAutoResponderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'triggerEvents',
    component: TriggerEventsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.TriggerEvents_GetList],
    },
  },
  {
    path: 'search-barcode-history',
    component: SearchBarcodeHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'chat-hour',
    component: ChatHourComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.ChatHour_GetList],
    },
  },
  {
    path: 'roles-managment',
    component: AllRolesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'menu',
    component: NutritionMenuComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Menu_GetList],
    },
  },
  {
    path: 'menu-templates',
    component: NutritionMenuTemplatesComponent,
    canActivate: [AuthGuard],

    data: {
      permissions: [permissionModuleAndAction.MenuTemplates_GetList],
    },
  },
  {
    path: 'food-categories',
    component: FoodCategoriesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.FoodCategories_GetList],
    },
  },
  {
    path: 'food-for-category',
    component: FoodForCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'food-details',
    component: FoodDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'algo-groups',
    component: AlgoGroupsComponent,
    canActivate: [AuthGuard],

    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'foods',
    component: AllFoodsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'recipes',
    component: AllFoodsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'foods/edit-food-bulk',
    component: BulkEditFoodComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'foods/edit-recipe-bulk',
    component: BulkEditRecipeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'foods/allergies',
    component: FoodAllergiesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'food-scales',
    component: FoodScalesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'food-nutrients',
    component: FoodNutrientsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'foods/edit',
    component: EditFoodComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Food_GetList],
    },
  },
  {
    path: 'genetic-formula',
    component: GeneticFormulaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lifestyle-tree',
    component: LifestyleTreeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'lifestyle-tree2',
    component: LifestyleTreeV2Component,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'lifestyle-versions',
    component: LifestyleVersionsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Manage_GetList],
    },
  },
  {
    path: 'algo-bullet-links',
    component: AllAlgoBulletLinksComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.AlgoBullets_GetList],
    },
  },
  {
    path: 'algo-bullet-links/edit',
    component: EditAlgoBulletLinksComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.AlgoBullets_GetList],
    },
  },
  {
    path: 'report-recomendations',
    component: ReportRecomendationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recomendations',
    component: RecomendationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-role',
    component: EditRoleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-zameret-data',
    component: UploadZameretDataComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.FoodZameretData_GetList],
    },
  },
  {
    path: 'lifestyle-calculations',
    component: LifeStyleCalculationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transfer-agents-clients',
    component: TransferAgentsClientsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    component: AgentsCalendarTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'meeting-settings',
    component: MeetingSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agent-schedule',
    component: AgentScheduleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'office-phone-extensions',
    component: OfficePhoneExtensionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'phone-verification-setting',
    component: AllPhoneVerificationSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'phone-verification-setting/edit',
    component: EditPhoneVerificationSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'videos',
    component: AllVideosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'video',
    component: EditVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'video-categories',
    component: VideoCategoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'module-to-actions',
    component: ModuleToActionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'rule-to-module-action',
    component: RuleToModuleActionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'action-convertions',
    component: ActionConvertionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'translations',
    component: TranslationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'translation-categories',
    component: TranslationCategoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'translation-keywords',
    component: TranslationKeywordsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'translation',
    component: TranslationComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [permissionModuleAndAction.Translation_GetList],
    },
  },
  {
    path: 'role-managment',
    component: RoleManagmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'role-managment-v2',
    component: RoleManagmentV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'test-upload',
    component: UploadFileTestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'file-extensions',
    component: FileExtensionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'file-types',
    component: FileTypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'file-types/edit',
    component: EditFileTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tests/edit/single-category',
    component: SingleCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'zoom-events',
    component: ZoomEventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'food-genes',
    component: FoodGenesWrapperComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'food-genes/lebels',
    component: LabelsTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'food-genes/tags',
    component: TagsTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'food-genes/versions',
    component: FoodGenesVersionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'formula-dev',
    component: FormulaDevComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'barcode-storage',
    component: BarcodesManagmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    component: MainChatBoardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agent-settings',
    component: AgentSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'far-login-admin',
    component: FarLoginAdminComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [
        permissionModuleAndAction.GlobalParams_Create,
        permissionModuleAndAction.GlobalParams_Update,
      ],
    },
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
