<mat-card class="category">
  <div class="title">עריכת תת קטגוריה</div>
  <div class="body">
    <div
      class="from-actions"
      *ngIf="!addState"
      fxLayout="row"
      fxLayoutAlign="center"
      fxLayoutGap="10px"
    >
      <button class="input" mat-button (click)="chooseState('addFromInput')">
        חדשה
      </button>
      <button class="list" mat-button (click)="chooseState('addFromList')">
        מתוך רשימה
      </button>
    </div>
    <form (ngSubmit)="save(subCatform)" #subCatform="ngForm" *ngIf="addState">
      <mat-form-field
        *ngIf="addState === 'addFromInput'"
        appearance="fill"
        class="field"
      >
        <input
          matInput
          type="text"
          placeholder="הכנס שם קטגוריה"
          [(ngModel)]="subCategory.name"
          name="nameInput"
          required
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="addState === 'addFromList'"
        appearance="fill"
        class="field"
      >
        <mat-select
          required
          name="nameSelect"
          placeholder="בחר קטגוריה"
          [(ngModel)]="subCategory.prototypeSubCategoryId"
          (ngModelChange)="onSubCatChange($event)"
        >
          <mat-option
            *ngFor="let item of subCategories"
            [value]="item.subCategoryId"
          >
            {{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div class="mb-1_5"></div>

      <div class="mb-1_5"></div>
      <div fxLayout="row" fxLayoutAlign="center">
        <div class="range">
          <mat-form-field class="field range" appearance="fill">
            <input
              type="number"
              name="rangeStart"
              [(ngModel)]="subCategory.rangeStart"
              required
              matInput
            />
          </mat-form-field>
          <div>ערך מינימלי</div>
        </div>
        <div class="range">
          <mat-form-field class="field range" appearance="fill">
            <input
              type="number"
              name="rangeEnd"
              [(ngModel)]="subCategory.rangeEnd"
              required
              matInput
            />
          </mat-form-field>
          ערך מקסימלי
        </div>
        <div class="range">
          <mat-form-field class="field range" appearance="fill">
            <input
              type="number"
              name="validExceptPersent"
              [(ngModel)]="subCategory.validExceptPersent"
              required
              matInput
            />
          </mat-form-field>
          אחוז איבוד
        </div>
      </div>
      <div *ngIf="subCategory.subCategoryId == 0" class="mb-1_5"></div>
      <mat-form-field
        *ngIf="subCategory.subCategoryId == 0"
        appearance="fill"
        class="field"
        [class.ng-invalid]="
          subCatform.submitted && subCategory.resultTypes.length == 0
        "
        [class.mat-form-field-invalid]="
          subCatform.submitted && subCategory.resultTypes.length == 0
        "
      >
        <mat-select
          placeholder="הוסף נטייה"
          (selectionChange)="onSelectResultTypes($event)"
          multiple
          name="resultTypes"
          required
        >
          <mat-option *ngFor="let type of resultTypes" [value]="type">{{
            type.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mb-2"></div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field fxFlex="7" appearance="fill" class="field range">
          <input
            matInput
            type="number"
            class="order"
            name="orderBy"
            [(ngModel)]="subCategory.orderBy"
            required
          />
        </mat-form-field>

        <div fxFlex="93" class="label">מיקום הקטגוריה</div>
      </div>
      <div class="mb-1_5"></div>
      <hr />
      <div class="mb-1_5"></div>
      <div class="mb-2" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center" fxFlex="30">
          <strong>PDF אייקון קטן</strong>
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AlgoImageType.AlgoSubCatSmallIcon"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage(AlgoImageType.AlgoSubCatSmallIcon)"
            [checkedImgId]="getCheckedImage(AlgoImageType.AlgoSubCatSmallIcon)"
          ></app-algo-image>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          fxFlex="30"
          style="margin-right: 20px"
        >
          <strong>Desktop אייקון</strong>
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AlgoImageType.AlgoSubCatBigIcon"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage(AlgoImageType.AlgoSubCatBigIcon)"
            [checkedImgId]="getCheckedImage(AlgoImageType.AlgoSubCatBigIcon)"
          ></app-algo-image>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          fxFlex="30"
          style="margin-right: 20px"
        >
          <strong>אייקון ל PDF (מסגרת)</strong>
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AlgoImageType.AlgoSubCatPdfBorderTop"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage(AlgoImageType.AlgoSubCatPdfBorderTop)"
            [checkedImgId]="
              getCheckedImage(AlgoImageType.AlgoSubCatPdfBorderTop)
            "
          ></app-algo-image>
        </div>
      </div>
      <div class="mb-1_5"></div>
      <div class="mb-1_5 error-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <div
        class="actions"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
      >
        <button class="save" type="submit" mat-button>
          <span>שמירה</span>
        </button>
        <button
          class="cancel"
          type="button"
          (click)="closeMainBoard.emit(true)"
          mat-button
        >
          <span>ביטול</span>
        </button>
      </div>
    </form>
  </div>
</mat-card>
