<div class="title">Add new laboratory</div>
<div class="mb-2">
  <div class="item mb-2">
    <mat-form-field class="item__field" appearance="fill">
      <input
        matInput
        placeholder="Enter laboratory name"
        [(ngModel)]="data.name"
      />
    </mat-form-field>
  </div>
</div>
<div class="actions mt-2">
  <button class="save" mat-button [disabled]="!data.name" (click)="addLab()">
    Add
  </button>
  <button class="cancel" mat-button (click)="closeDialog()" type="button">
    Cancel
  </button>
</div>
