<div class="title">Add new result type</div>
<div class="mb-2">
  <div class="item mb-2">
    <mat-form-field class="item__field" appearance="fill">
      <mat-select placeholder="Result Type" [(ngModel)]="resultTypeId">
        <mat-option
          *ngFor="let resultType of data"
          [value]="resultType.algoResultTypeId"
        >
          {{ resultType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="actions mt-2">
  <button
    class="save"
    type="button"
    mat-button
    [disabled]="!resultTypeId"
    (click)="addType()"
  >
    Add
  </button>
  <button class="cancel" mat-button mat-dialog-close type="button">
    Cancel
  </button>
</div>
