import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BloodTestType } from '../data/BloodTestType';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BloodTestTypesService {
  bloodTestTypes: BloodTestType[];

  constructor(private http: HttpClient) { }
  getAll() {

    if (this.bloodTestTypes){
      return of(this.bloodTestTypes);  // return from cache
  } else
  {
      return this.http.get<BloodTestType[]>(`${environment.baseUrl}BloodTestTypes/`).pipe(map(res => (this.bloodTestTypes=res)));
  }
    //return this.http.get<BloodTestType[]>(`${environment.baseUrl}BloodTestTypes/`)
  }
}
