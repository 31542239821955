<h1>
  <ng-container *ngIf="isFailed()">Failed</ng-container> Patients Deliveries
</h1>

<app-deliveries-table
  [patientDeliveries]="patientDeliveries"
  [displayedColumns]="displayedColumns"
  [classAttr]="'AllDeliveries'"
>
</app-deliveries-table>
