<!--<mat-list class="leadsTable" role="list"> -->
<!-- <mat-list-item class="leadsTable__header" role="listitem">-->

<!--
      <span>Name</span>
      <span>Id</span>
      <span>Registered Date</span>
      <span>Email</span>
      <span>Tel</span>
      <span>Status</span>
    -->
<h1>Permissions For Roles</h1>
<br />
<input [(ngModel)]="newRoleName" />
<button mat-button style="border: 1px solid blue;" (click)="addRole()">
  Add Role
</button>

<!--
        
<ng-container *ngFor="let item of headers">
    <span class="clickble">
        <span>{{item.name}} </span>


    </span>

</ng-container>
-->
<!--<button (click)="createNewRole()" class="new-agent-btn">create new Role</button>-->
<!--
        <ng-container *ngFor="let item of headers">
            <span class="clickble" [ngClass]="{'sortSelected': headFilter.orderBy==item.val}">
                <span (click)="changeSortBy(item.val)">{{item.name}} </span>
                <ng-container *ngIf="headFilter.orderBy==item.val">
                    <img src="../../../assets/sort_arrows.svg" *ngIf="headFilter.acending==true"
                        (click)="changeSortOrder()">
                    <img class="rotate180" src="../../../assets/sort_arrows.svg" *ngIf="headFilter.acending==false"
                        (click)="changeSortOrder()">
                </ng-container>

            </span>
        </ng-container>
        -->
<!-- </mat-list-item> -->
<mat-card
  style="padding: 15px; padding-left: 40px;"
  class="leadsTable__item"
  *ngFor="let roleType of roleTypesData"
>
  <!-- <mat-list-item role="listitem">-->
  <!--   <span class="d-flex-center">-->
  <span style="font-weight: bold; font-size: 20px;">
    {{ roleType.roleName }}</span
  >
  <!-- </span>-->
  <!-- <span>-->
  <div style="margin-top: 15px;">
    <div
      style="display: flex; margin-bottom: 10px;"
      *ngFor="let permissionForRole of roleType.permissionsForRoles"
    >
      <div
        style="
          width: 200px;
          padding: 10px;
          border: 1px solid black;
          border-radius: 25px;
        "
      >
        {{ permissionForRole.permission && permissionForRole.permission.permissionAction }}
      </div>
      <div
        (click)="deletePermission($event, permissionForRole)"
        style="
          color: red;
          font-size: 25px;
          margin-top: 10px;
          margin-left: 20px;
          cursor: pointer;
        "
      >
        X
      </div>
    </div>
  </div>

  <!--  </span> -->
  <span style="width: 200px;">
    <mat-form-field
      class="example-full-width round-select"
      style="width: 200px;"
    >
      <mat-select
        class="role-status-select"
        matNativeControl
        disableOptionCentering
        style="color: black;"
        [value]="-1"
        [(value)]="roleType.newPermissionId"
      >
        <!-- [(ngModel)]="agentItem.roleTypeId"-->
        <!--(selectionChange)="saveRoleForAgent($event, agentItem)"
                           [(ngModel)]="agentItem.roleTypeId"   -->
        <mat-option [value]="-1">Choose</mat-option>
        <mat-option
          *ngFor="let permission of permissions"
          [value]="permission.permissionId"
          style="text-align: center;"
        >
          {{ permission.permissionAction }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>

  <!--<span style="text-align: center;">agent</span>-->

  <span class="text-center" style="margin-left: 30px;">
    <button
      mat-button
      class="edit-btn"
      (click)="addPermissionClick($event, roleType)"
    >
      Add Permission
    </button>
  </span>

  <!-- </mat-list-item>-->
</mat-card>
<div style="height: 200px;"></div>
<!-- </mat-list> -->
