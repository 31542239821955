<app-client-profile-edit
  [product]="product"
  [client]="client"
  [clientTypes]="clientTypes"
  [leadSources]="leadSources"
  [productIsFors]="productIsFors"
  [agents]="agents"
  *ngIf="data != null"
  (cancelForm)="onCancelForm()"
  (saveForm)="onChildFormValidated($event)"
  (saveFormProduct)="onChildProductFormValidated($event)"
  [classAttr]="classAttr"
>
</app-client-profile-edit>
