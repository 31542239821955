import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-email-template-name',
  templateUrl: './new-email-template-name.component.html',
  styleUrls: ['./new-email-template-name.component.css'],
})
export class NewEmailTemplateNameComponent implements OnInit {
  templateName: string;
  constructor(
    public dialogRef: MatDialogRef<NewEmailTemplateNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  save() {
    if (!this.templateName) return;
    else
      this.dialogRef.close({ event: 'close', templateName: this.templateName });
  }
}
