import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MealForSite } from 'src/app/data/MealForSite';
import { MealToNutrientSite } from 'src/app/data/MealToNutrientSite';
import { MenuForSite } from 'src/app/data/MenuForSite';
import { FoodNutrientsZameret } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-menu-html',
  templateUrl: './menu-html.component.html',
  styleUrls: ['./menu-html.component.scss'],
})
export class MenuHtmlComponent implements OnInit, AfterViewInit {
  @Input() menu: MenuForSite;
  @Input() printMode: boolean;
  @ViewChild('menuHtml') menuHtml: ElementRef;
  @Output('htmlReady') htmlReady: EventEmitter<string> = new EventEmitter();
  meals: MealForSite[];
  constructor() {}

  ngAfterViewInit(): void {
    this.htmlReady.next(this.menuHtml.nativeElement.innerHTML);
  }

  ngOnInit(): void {
    this.meals = this.menu.meals;
  }

  getMealType(mealType: string) {
    switch (mealType) {
      case 'Breakfast':
        return 'ארוחת בוקר';
      case 'Lunch':
        return 'ארוחת צהריים';
      case 'Dinner':
        return 'ארוחת ערב';
      case 'Snack':
        return 'ארוחת ביניים';
      case 'Night':
        return 'ארוחת לילה';
    }
  }

  formatMealTime(mealTime: string) {
    var a = mealTime.split(':');
    return a[0] + ':' + a[1];
  }

  getGeneticRate(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == FoodNutrientsZameret.geneticRate
    );
  }
  getCalories(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == FoodNutrientsZameret.food_energy
    );
  }

  getFats(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == FoodNutrientsZameret.total_fat
    );
  }

  getHelbonim(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == FoodNutrientsZameret.protein);
  }

  getPachmimot(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == FoodNutrientsZameret.carbohydrates
    );
  }
}
