import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';

@Component({
  selector: 'app-food-categories-selection-panel',
  templateUrl: './food-categories-selection-panel.component.html',
  styleUrls: ['./food-categories-selection-panel.component.scss'],
})
export class FoodCategoriesSelectionPanelComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('buttonRef') buttonRef: MatButton;
  @Input() fromEditFoodComponent: boolean;
  @Input() fromAllFoodComponent: boolean;
  @Input() fromPaginatorComponent: boolean = false;
  @Input() selectedCat: FoodCat;
  @Input() categories: FoodCat[];
  @Output('selectCat') selectCat: EventEmitter<any> = new EventEmitter();
  buttonWidth: number;
  constructor() {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.buttonWidth = this.buttonRef._elementRef.nativeElement.clientWidth;
    }, 500);
  }

  onSelectCat(category: FoodCat) {
    this.selectedCat = category;
    this.selectCat.emit(category);
  }

  ngOnInit(): void {
    const allFinded = this.categories.find((cat) => cat.categoryId == 0);
    if (allFinded) {
      //remove all from categories
      this.categories = this.categories.filter((cat) => cat.categoryId != 0);
    }

    if (this.fromAllFoodComponent && !this.fromPaginatorComponent) {
      const all = new FoodCat();
      all.categoryId = 0;
      all.name = 'All';
      this.categories.unshift(all);
    }
  }
}
