import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Meal } from '../data/Meal';

@Injectable({
  providedIn: 'root',
})
export class MealsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Meal[]>(`${environment.baseUrl}Meals/`);
  }

  getByMealId(mealId: number, productId: number = -1, langId: number = 1) {
    return this.http.get<Meal>(
      `${environment.baseUrl}Meals/${mealId}/${productId}/${langId}`
    );
  }

  getByMenuId(menuId: number) {
    return this.http.get<Meal[]>(
      `${environment.baseUrl}Meals/ForMenu/${menuId}`
    );
  }

  getCalories(foodId: number, foodScaleId: number, quantity: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Meals/GetCalories/${foodId}/${foodScaleId}/${quantity}`
    );
  }

  put(meal: Meal, langId: number) {
    return this.http.put<Meal>(
      `${environment.baseUrl}Meals/${meal.mealId}/${langId}`,
      meal
    );
  }

  post(meal: Meal) {
    return this.http.put<Meal>(`${environment.baseUrl}Meals/`, meal);
  }

  delete(mealId: number) {
    return this.http.delete<Meal>(`${environment.baseUrl}Meals/${mealId}`);
  }
}
