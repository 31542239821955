<div class="homePage">
  <div class="left-side" *ngIf="stats && hasAgentStatsPermmisions()">
    <ng-container>
      <!-- <mat-form-field
        *ngIf="hasAgentStatsPermmisions()"
        class="example-full-width round-select"
        style="width: 200px; margin-bottom: -30px; height: 40px"
      >
        <mat-select
          (selectionChange)="onAgentFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="displayedAgentId"
        >
          <mat-option [value]="-2" selected> Nutrition Calls </mat-option>
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option *ngFor="let agent of agents" [value]="agent.agentId">{{
            agent.name
          }}</mat-option>
        </mat-select>
      </mat-form-field> -->
      <app-autocomplete-agent
        *ngIf="hasAgentStatsPermmisionsAll()"
        (selectedValue)="onAgentFilterChange($event)"
        [data]="agents"
        [initialValue]="getAgentNameById(displayedAgentId)"
        [widthPrec]="100"
        [label]="'Agent'"
      ></app-autocomplete-agent>

      <mat-card>
        <mat-list style="padding-top: 0">
          <mat-list-item>
            <div class="d-flex-center" style="padding-bottom: 1rem">
              <img class="mr-1" src="../../../assets/lead.svg" alt="" />
              <span style="font-weight: 500; font-size: 1.8rem">
                New Leads</span
              >
            </div>
            <mat-divider
              style="border-color: #00aeee; border-top-width: 2px"
            ></mat-divider>
          </mat-list-item>
          <mat-list-item>
            <span style="font-weight: 500"> Number of new leads:</span>
            {{ stats.numberOfNewLeads }}
          </mat-list-item>
        </mat-list>
      </mat-card>
      <mat-card>
        <mat-list style="padding-top: 0">
          <mat-list-item>
            <div class="d-flex-center" style="padding-bottom: 1rem">
              <img class="mr-1" src="../../../assets/transactions.svg" alt="" />
              <span style="font-weight: 500; font-size: 1.8rem"
                >Transactions</span
              >
            </div>
            <mat-divider
              style="border-color: #119c99; border-top-width: 2px"
            ></mat-divider>
          </mat-list-item>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              font-size: 1.4rem;
              grid-gap: 1rem 0;
              margin-top: 1rem;
            "
          >
            <div>
              <span style="font-weight: 500">Daily: </span>
              {{ stats.dailyTransactionsCount }}
            </div>
            <div>
              <span style="font-weight: 500">Total: </span>
              {{ stats.dailyTransactionsSum }}₪
            </div>
            <div>
              <span style="font-weight: 500">Monthly: </span>
              {{ stats.monthlyTransactionsCount }}
            </div>
            <div>
              <span style="font-weight: 500">Total: </span>
              {{ stats.monthlyTransactionsSum }}₪
            </div>
          </div>
        </mat-list>
      </mat-card>

      <mat-card>
        <mat-list style="padding-top: 0">
          <mat-list-item>
            <div class="d-flex-center" style="padding-bottom: 1rem">
              <img class="mr-1" src="../../../assets/cancel.svg" alt="" />
              <span style="font-weight: 500; font-size: 1.8rem"
                >Cancellations</span
              >
            </div>
            <mat-divider
              style="border-color: #119c99; border-top-width: 2px"
            ></mat-divider>
          </mat-list-item>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              font-size: 1.4rem;
              grid-gap: 1rem 0;
              margin-top: 1rem;
            "
          >
            <div>
              <span style="font-weight: 500">Daily: </span>
              {{ stats.dailyCancelationsCount }}
            </div>
            <div>
              <span style="font-weight: 500">Total: </span>
              {{ stats.dailyCancelationsSum * -1 }}₪
            </div>
            <div>
              <span style="font-weight: 500">Monthly: </span>
              {{ stats.monthlyCancelationsCount }}
            </div>
            <div>
              <span style="font-weight: 500">Total: </span>
              {{ stats.monthlyCancelationsSum * -1 }}₪
            </div>
          </div>
        </mat-list>
      </mat-card>

      <mat-card>
        <div class="d-flex-center">
          <img class="mr-1" src="../../../assets/podium.svg" alt="" />
          <div style="font-size: 1.8rem">
            <span style="font-weight: 500; font-size: 1.8rem"> Your Rank: </span
            >{{ stats.myRank }}
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
  <div class="right-side" *ngIf="hasActivitiesDashboardPermisions()">
    <app-activities-dashboard
      title="Overdue Tasks"
      action="overdue"
      ngForTxt="activitiesOverdue"
      filterTxt="118"
      [agentId]="displayedAgentId"
    >
    </app-activities-dashboard>

    <app-activities-dashboard
      title="Open Tasks"
      action="open"
      ngForTxt="activitiesOpen"
      filterTxt="118"
      [agentId]="displayedAgentId"
    >
    </app-activities-dashboard>
  </div>
</div>
<div class="welcomeScreen" *ngIf="showWelcomeScreen()">
  <h1 style="font-size: 80px">Welcome to the CRM</h1>
</div>
