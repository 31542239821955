<button class="back back--client" (click)="goBackToAgents()">
  < Back to Agents
</button>
<mat-card>
  <form
    *ngIf="agentForm"
    [formGroup]="agentForm"
    (ngSubmit)="onSubmit()"
    class="example-form"
  >
    <!--<form *ngIf="model" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>-->
    <mat-list>
      <mat-list-item>
        <span class="card-title">Agent Profile </span>
      </mat-list-item>
      <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Name: </span>
        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="firstName" />
          <mat-error *ngIf="getFalse()"> Please enter Name </mat-error>
        </mat-form-field>
      </mat-list-item>

      <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Phone: </span>
        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="phone" />
          <mat-error *ngIf="getFalse()"> Please enter Phone </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Email: </span>

        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="email" />
          <mat-error *ngIf="getFalse()"> Please enter Email </mat-error>
        </mat-form-field>
      </mat-list-item>
      <!-- <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Office Extension: </span>
        <mat-form-field class="example-full-width round-select">
          <mat-select
            placeholder="Choose Extension"
            formControlName="officePhoneExtension"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let item of officePhoneExtensions"
              [value]="item.officePhoneExtensionId"
            >
              {{ item.phoneExtensionName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item> -->
      <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Gender: </span>
        <mat-radio-group
          class="w320"
          style="margin-left: auto"
          aria-label="gender"
          value=""
          formControlName="gender"
        >
          <mat-radio-button value="male"
            ><span style="margin-right: 20px">male </span></mat-radio-button
          >
          <mat-radio-button value="female">female</mat-radio-button>
        </mat-radio-group>
        <span
          style="margin-left: 40px; color: red"
          *ngIf="submited && gender.errors && gender.errors.required"
          >you need to choose gender</span
        >
      </mat-list-item>
      <mat-list-item class="row-container__item" *ngIf="agent.agentId > 0">
        <span class="boldSpan" style="width: 80px"> Password: </span>
        <mat-form-field class="example-full-width">
          <input
            #passwordInput="matInput"
            class="edit-field"
            matInput
            formControlName="password"
            autocomplete="chrome-off"
          />
        </mat-form-field>

        <button
          mat-button
          type="button"
          (click)="setPasswordHack()"
          class="agent-round-btn"
          style="padding: 0 16px; margin-left: 2rem"
          [disabled]="isLoading"
        >
          set password
        </button>
        <!-- <button
          type="button"
          (click)="sendResetPasswordMail()"
          class="agent-round-btn"
          [disabled]="isLoading"
        >
          resend mail
        </button> -->
      </mat-list-item>
      <!-- <button (click)="togglePassField" mat-button class="agent-round-btn">Change password</button> -->
      <mat-list-item class="row-container__item" *ngIf="agent.agentId > 0">
        <div
          *ngIf="agent.agentId > 0"
          style="
            color: #707070;
            text-align: left;
            font-size: 12px;
            font-weight: lighter;
            margin-bottom: 10px;
          "
        >
          <!--  *ngIf="passwordInput.focused"-->
          <span style="color: #1b3f4e; font-weight: bold">
            Your password must contain:</span
          >
          <br />
          <img
            src="../../../../assets/validationV.svg"
            class="v_icon"
            *ngIf="is8Length()"
          />
          <img
            src="../../../../assets/validationV_1.svg"
            class="v_icon"
            *ngIf="!is8Length()"
          />
          <span [ngClass]="{ validGreen: is8Length() }">
            at least 8 characters</span
          >
          <br />
          <img
            src="../../../../assets/validationV.svg"
            class="v_icon"
            *ngIf="hasLetters()"
          />
          <img
            src="../../../../assets/validationV_1.svg"
            class="v_icon"
            *ngIf="!hasLetters()"
          />
          <span [ngClass]="{ validGreen: hasLetters() }">
            Uppercase and lowercase letters
          </span>
          <br />
          <img
            src="../../../../assets/validationV.svg"
            class="v_icon"
            *ngIf="hasNumbers()"
          />
          <img
            src="../../../../assets/validationV_1.svg"
            class="v_icon"
            *ngIf="!hasNumbers()"
          />
          <span [ngClass]="{ validGreen: hasNumbers() }">
            At least one number
          </span>
          <br />
        </div>
      </mat-list-item>

      <mat-list-item class="row-container__item">
        <span class="boldSpan" style="width: 80px"> Role: </span>
        <mat-form-field class="example-full-width round-select">
          <mat-select
            placeholder="Choose Role"
            matNativeControl
            formControlName="userRoleId"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let roleType of userRoles"
              [value]="roleType.id"
            >
              {{ roleType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
      <div class="row-container__item">
        <span class="boldSpan" style="width: 80px">Status: </span>
        <mat-button-toggle-group formControlName="isActive">
          <mat-button-toggle [value]="true">Active</mat-button-toggle>
          <mat-button-toggle [value]="false">Not Active</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <br />
      <!--
      <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" name="firstName" [(ngModel)]="model.firstName"
              #firstName="ngModel" />
      </div>
      <div class="form-group">
          <label for="alterEgo">Email</label>
          <input type="text" class="form-control" id="email" name="email" [(ngModel)]="model.email"
              #email="ngModel" />
      </div>
      <div class="form-group">
          <label for="alterEgo">Phone</label>
          <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="model.phone"
              #phone="ngModel" />
      </div>
      <div class="form-group">
          <label for="alterEgo">password</label>
          <input type="text" class="form-control" id="alterEgo" name="password"
              [(ngModel)]="model.password" #password="ngModel" />
      </div>
      -->
      <button
        type="submit"
        mat-button
        class="rect-action-btn"
        class="rect-action-btn--client"
        [disabled]="isLoading"
      >
        Save
      </button>

      <!--  <button type="submit" class="btn btn-success">Submit</button>-->
    </mat-list>
  </form>
</mat-card>
