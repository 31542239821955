import { Component, OnInit } from '@angular/core';
import { ActionConvertion } from 'src/app/data/ActionConvertion';
import { AppModule } from 'src/app/data/AppModule';
import { EmailTemplateMin } from 'src/app/data/EmailTemplateMin';
import { ModuleAction } from 'src/app/data/ModuleAction';
import { ModuleToAction } from 'src/app/data/ModuleToAction';
import { PermissionRule } from 'src/app/data/PermissionRule';
import { Status } from 'src/app/data/Status';
import { TriggerEventObjectType } from 'src/app/data/TriggerEventObjectType';
import { ActionMin } from 'src/app/data/user-roles/ActionMin';
import { ModuleMin } from 'src/app/data/user-roles/ModuleMin';
import { RuleMin } from 'src/app/data/user-roles/RuleMin';
import { UserRoleMin } from 'src/app/data/user-roles/UserRoleMin';
import { ActionConvertionsService } from 'src/app/Services/action-convertions.service';
import { AppModulesService } from 'src/app/Services/app-modules.service';
import { ModuleActionsService } from 'src/app/Services/module-actions.service';
import { ModuleToActionsService } from 'src/app/Services/module-to-actions.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { UserRolesService } from 'src/app/Services/user-roles.service';

@Component({
  selector: 'app-rule-to-module-action',
  templateUrl: './rule-to-module-action.component.html',
  styleUrls: ['./rule-to-module-action.component.scss'],
})
export class RuleToModuleActionComponent implements OnInit {
  usingConventions = false;
  newAppModuleId: number;
  newModuleActionId: number;
  newEventName: string;
  eventTypes: Status[];
  statuses: Status[];
  actionTypes: Status[];
  emailTemplates: EmailTemplateMin[];
  productTypes: Status[];
  triggerEventObjectTypes: TriggerEventObjectType[];
  moduleToActions: ModuleToAction[];

  //userRoles
  userRoles: UserRoleMin[];
  showedModules: ModuleMin[] = [
    { actions: [], appModuleId: 1, appModuleName: 'test 1' },
    { actions: [], appModuleId: 2, appModuleName: 'test 2' },
  ];
  showedActions: ModuleAction[];
  showedRules: PermissionRule[];
  selectedRoleId: number;
  selectedModuleId: number;
  selectedActionId: number;

  constructor(
    private appModulesService: AppModulesService,
    private moduleActionsService: ModuleActionsService,
    private moduleToActionsService: ModuleToActionsService,
    private popupHandlerService: PopupHandlerService,
    private userRolesService: UserRolesService,
    private actionConvertionsService: ActionConvertionsService
  ) {}
  eventsDisplayedColumns: string[] = [
    'AppModuleId',
    'ModuleActionId',
    'Delete',
  ];
  appModules: AppModule[];
  moduleActions: ModuleAction[];
  ngOnInit(): void {
    //userRoles

    //ModuleToAction
    this.getAllAppModules();
    this.getAllModuleActions();
    this.getAllModuleToActions();
  }

  //UserRoles Service
  getModuleActions() {
    this.moduleToActionsService
      .forModule(this.selectedModuleId)
      .subscribe((data) => {
        this.showedActions = data.filter((x) => x.checked);
      });
  }
  onModuleClick(moduleId: number) {
    this.selectedModuleId = moduleId;
    this.getModuleActions();
    /*
    this.showedActions = this.showedModules.find(
      (x) => x.appModuleId == moduleId
    ).actions;
    */
  }
  onActionClick(action: ModuleToAction) {
    /*
    this.moduleToActionsService
      .updateChecked(
        this.selectedModuleId,
        action.moduleActionId,
        !action.checked
      )
      .subscribe((data) => {
        this.getModuleActions();
      });
      */
    this.selectedActionId = action.moduleActionId;
    this.loadShowedRules();
    /*
    
    this.showedRules = this.showedActions.find(
      (x) => x.moduleActionId == actionId
    ).;
    */
  }
  loadShowedRules() {
    this.actionConvertionsService
      .getForModuleAndAction(this.selectedModuleId, this.selectedActionId)
      .subscribe((data) => {
        this.showedRules = data;
      });
  }
  onPermissionClick(rule: PermissionRule) {
    console.log(rule.permissionRuleId);
    this.actionConvertionsService
      .update(
        this.selectedModuleId,
        this.selectedActionId,
        rule.permissionRuleId,
        !rule.checked
      )
      .subscribe((res) => {
        this.loadShowedRules();
      });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  //ModuleToAction
  getAllModuleToActions() {
    this.moduleToActionsService.getAll().subscribe((data) => {
      this.moduleToActions = data;
    });
  }
  getExistingModulesForRole() {
    this.appModulesService
      .getExistingModulesForRole(this.selectedRoleId)
      .subscribe((data) => {
        this.appModules = data;
      });
  }
  getAllModuleActions() {
    this.moduleActionsService.getAll().subscribe((data) => {
      this.moduleActions = data;
    });
  }

  getAllAppModules() {
    this.appModulesService.getAll().subscribe((data) => {
      this.appModules = data;
    });
  }
  AddModuleToAction() {
    var moduleToAction = new ModuleToAction();
    moduleToAction.appModuleId = this.newAppModuleId;
    moduleToAction.moduleActionId = this.newModuleActionId;
    this.moduleToActionsService.post(moduleToAction).subscribe((res) => {
      this.getAllModuleActions();
    });
  }
  saveModuleForModuleToAction(event, moduleToAction: ModuleToAction) {
    moduleToAction.appModuleId = event.value;
    this.moduleToActionsService
      .put(moduleToAction)
      .subscribe((res) => this.getAllModuleActions());
  }
  saveActionForModuleToAction(event, moduleToAction: ModuleToAction) {
    moduleToAction.moduleActionId = event.value;
    this.moduleToActionsService
      .put(moduleToAction)
      .subscribe((res) => this.getAllModuleActions());
  }
  deleteModuleToAction(moduleToAction: ModuleToAction) {
    this.moduleToActionsService
      .delete(moduleToAction.moduleToActionId)
      .subscribe((res) => this.getAllModuleActions());
  }
  addNewModule() {
    this.popupHandlerService.openAddNewModuleDialog(this.selectedRoleId);
    this.popupHandlerService.addNewModuleSubject.subscribe(
      ({ event, data }) => {}
    );
  }
  editModule(moduleId: number) {}
  deleteModule(moduleId: number) {
    this.appModulesService
      .deleteForRole(this.selectedRoleId, this.selectedModuleId)
      .subscribe((x) => {
        this.getExistingModulesForRole();
      });
  }
}
