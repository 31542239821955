<mat-card>
  <div style="display: flex">
    <h1>Create New Role</h1>
    <button>save</button>
    <button>cancel</button>
  </div>
  <hr />
  <mat-list>
    <mat-list-item>
      Role Name:
      <input />
    </mat-list-item>
    <mat-list-item>
      Role Description:
      <input />
    </mat-list-item>
    <mat-list-item style="display: flex">
      <span> Permissions </span><button>expand all</button>
      <button>colapse all</button>
    </mat-list-item>
  </mat-list>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let category of categories">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ category.name }} </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="category.list" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.permissionAction }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef>Desc</th>
          <td mat-cell *matCellDef="let element">
            {{ element.permissionAction }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element"><input type="checkbox" /></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div *ngFor="let permission of category.list">
        {{ permission.permissionAction }}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
