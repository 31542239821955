<mat-tab-group
  #tabGroup
  animationDuration="0ms"
  style="min-height: 400px"
  [class]="classTabsAttr"
>
  <mat-tab style="min-height: 400px">
    <ng-template mat-tab-label>
      <div
        [matBadgeHidden]="activities.length == 0"
        matBadge="{{
          activities && activities.length > 0 && activities.length
        }}"
        matBadgeColor="warn"
        class="task-overdue-tab-unselected"
      >
        Open Task
      </div>
    </ng-template>
    <div *ngFor="let activity of activities">
      <app-lead-activity-thumb
        [testPusher]="testPusher"
        [note]="activity"
        [classAttr]="classAttr"
        style="width: 100%"
        (editNote)="onEditNote($event)"
      >
      </app-lead-activity-thumb>
    </div>
    <!--
    <mat-list
      style="height: 400px; margin-top: 20px"
      class="activitiesThumbList"
    >
      <mat-list-item
        *ngFor="let activity of activities"
        style="min-height: 60px"
      >
        <app-lead-activity-thumb
          [testPusher]="testPusher"
          [note]="activity"
          [classAttr]="classAttr"
          style="width: 100%"
          (editNote)="onEditNote($event)"
        >
        </app-lead-activity-thumb>
      </mat-list-item>
    </mat-list>
    -->
  </mat-tab>

  <mat-tab style="min-height: 400px">
    <ng-template mat-tab-label>
      <!--label="Overdue Tasks"-->

      <div
        [matBadgeHidden]="activitiesOverdue.length == 0"
        matBadge="{{
          activitiesOverdue &&
            activitiesOverdue.length > 0 &&
            activitiesOverdue.length
        }}"
        matBadgeColor="warn"
        [class.task-overdue-tab]="tabGroup.selectedIndex == 1"
        [class.task-overdue-tab-unselected]="tabGroup.selectedIndex != 1"
      >
        Overdue Tasks
      </div>
    </ng-template>
    <div *ngFor="let activity of activitiesOverdue">
      <mat-card>
        <app-lead-activity-thumb
          [testPusher]="testPusher"
          (editNote)="onEditNote($event)"
          [note]="activity"
          [classAttr]="classAttr"
          style="width: 100%"
        >
        </app-lead-activity-thumb>
      </mat-card>
    </div>
    <!--
    <mat-list style="height: 400px; margin-top: 20px" class="activitiesThumbList">
      <mat-list-item *ngFor="let activity of activitiesOverdue" style="min-height: 60px">
        <app-lead-activity-thumb [testPusher]="testPusher" (editNote)="onEditNote($event)" [note]="activity"
          [classAttr]="classAttr" style="width: 100%">
        </app-lead-activity-thumb>
      </mat-list-item>
    </mat-list>
    -->

    <!--   <mat-list>-->
    <!--   <mat-list-item-->

    <!--
      </mat-list-item>
    </mat-list>
    -->
  </mat-tab>

  <mat-tab style="min-height: 400px">
    <ng-template mat-tab-label>
      <div class="task-overdue-tab-unselected">Task History</div>
    </ng-template>
    <div *ngFor="let activity of activitiesFinished">
      <app-lead-activity-thumb
        [testPusher]="testPusher"
        [note]="activity"
        [classAttr]="classAttr"
        style="width: 100%"
      >
      </app-lead-activity-thumb>
    </div>
    <!-- <mat-list>
      <mat-list-item
        *ngFor="let activity of activitiesFinished"
        class="activitiesThumbList"
      >
        <app-lead-activity-thumb
          [testPusher]="testPusher"
          [note]="activity"
          [classAttr]="classAttr"
          style="width: 100%"
        >
        </app-lead-activity-thumb>
      </mat-list-item>
    </mat-list>
  </mat-tab>
</mat-tab-group>
<mat-list>
  -->
  </mat-tab></mat-tab-group
>
<div>
  <button
    class="btn-wide"
    [class.btn-wide--lead]="classAttr == 'leadAttr'"
    [class.btn-wide--client]="classAttr == 'clientAttr'"
    (click)="onCreateNewTask()"
  >
    + New Task
  </button>
</div>
