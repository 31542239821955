<div class="top-nav">
  <div class="top-nav-category">
    <strong>Category</strong>
    <mat-form-field appearance="fill">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-select
        [(ngModel)]="selectedCategory"
        (selectionChange)="onSelectCategory($event)"
        placeholder="Select category"
      >
        <mat-option
          *ngFor="let category of categories"
          [value]="category.translationCategoryId"
        >
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="top-nav-search">
    <label for="navSearch" class="top-nav-search-label">
      <input
        id="navSearch"
        type="text"
        [(ngModel)]="headFilter.searchTerm"
        (keyup.enter)="onSearchBtnClick()"
        placeholder="Search keyword..."
      />
      <button
        (click)="onSearchBtnClick()"
        type="button"
        class="top-nav-search-btn"
      ></button>
    </label>
  </div>
</div>
<perfect-scrollbar style="height: calc(100vh - 226px)">
  <app-translation-table
    *ngIf="translations && languages"
    [data]="translations"
    [languages]="languages"
    [saveComplete]="saveComplete"
    (saveTranslation)="onSaveTranslation($event)"
    (addKeyword)="onAddKeyword($event)"
  ></app-translation-table>
</perfect-scrollbar>
<app-table-paginator
  [minWidth]="true"
  [sticky]="true"
  *ngIf="pagination"
  [metaData]="pagination"
  [headFilter]="headFilter"
  (filterChanged)="getPaginatorData($event)"
>
</app-table-paginator>
