import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkingDaysService {
  baseUrl: string = 'https://api.workingdays.org/1.2/api.php';
  key: string = 'C63801D7-DB7D-A791-FD2B-FCC87F632C1E';
  country_code: string = 'IL';
  configuration: string = 'Federal%20holidays';
  command: string = 'analyse';
  weekend: string = '0000011';
  nocache: string = '1623575949376';
  constructor(private http: HttpClient) {}

  getWorkingDays(start_date: string, end_date: string) {
    return this.http.get<any>(
      `${this.baseUrl}?key=${this.key}&country_code=${this.country_code}&configuration=${this.configuration}&command=${this.command}&start_date=${start_date}&end_date=${end_date}&weekend=${this.weekend}&nocache=${this.nocache}`
    );
  }
}
