import { Agent } from '../data/Agent';
import { BloodTestResult } from './BloodTestResult';
import { Client } from './Client';
import { GoalToPatient } from './GoalToPatient';
import { LifeStyleReport } from './LifeStyleReport';
import { Logging } from './Logging';
import { Note } from './Note';
import { NutritionReport } from './NutritionReport';
import { PatientDelivery } from './PatientDeliveries';
import { PatientWeightHistory } from './PatientWeightHistory';
import { Product } from './Product';
import { RegularUser } from './RegularUser';

export class Patient {
  patientId: number;
  regularUserId: number;
  regularUser: RegularUser;
  clientId: number;
  client: Client;
  userId: number;
  products: Product[];
  codeCoupon: string;
  patientNotes: Note[];
  patientLogs: Logging[];
  nutritionReport: NutritionReport;
  bloodTestResults: BloodTestResult[];
  patientDeliveries: PatientDelivery[];
  filledDetails: boolean;
  agentId: number | null;
  agent: Agent;
  lifeStyleReport: LifeStyleReport;
  patientWeightHistories: PatientWeightHistory[];
  bloodTestComplete: boolean;
  suggerComplete: boolean;
  isSignedConcent: boolean;
  goalToPatient: GoalToPatient[];
  constructor() {}
}
