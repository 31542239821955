import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';
import { MatFormField } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { take, takeUntil, takeWhile } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { ConditionParticipant } from 'src/app/data/food-genes/ConditionParticipant';
import { FgCalculatorFunctions } from 'src/app/data/food-genes/FgCalculatorFunctions';
import { FgCondition } from 'src/app/data/food-genes/FgCondition';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';
import {
  AggregatingType,
  ConditionLogicType,
  ConditionType,
  FgCalculatorFunctType,
  FormulaType,
} from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

class CalcEvent {
  isReplaced: boolean;
  numParams: number;
  paramSelected: number;
  values: string[];
  calcFuncPattern: string;
  canBeEnd: boolean;
  afterFuncType: number;
  isClosingBracket?: boolean;
  withStart?: boolean;
}

class FormulaChip {
  param: string;
  calcEvent: CalcEvent;
}

@Component({
  selector: 'app-formula-dev',
  templateUrl: './formula-dev.component.html',
  styleUrls: ['./formula-dev.component.scss'],
})
export class FormulaDevComponent implements OnInit, OnDestroy {
  @ViewChild('formulaField') formulaField: ElementRef;
  @ViewChild('patternField') patternField: MatFormField;
  @ViewChild('formulaFormDirective') ngForm: FormGroupDirective;
  @Input() versionId: number;
  @Input() calcFunctions: FgCalculatorFunctions[];
  @Input() canEdit: boolean;
  formulaForm: FormGroup;
  formula: FgFormula;
  FormulaType = FormulaType;
  paramTypes: FgParamType[];
  calcEvent: CalcEvent;
  isParamDisabled: boolean;
  formulaArray: FormulaChip[] = [];
  formulaControl = new FormControl([]);
  activeChipIndex: number = 0;
  conditionOprands = [];
  logicOperators = [];
  aggregatingTypes = [];
  alertTags: FgParamType[];
  allParamTypes: FgParamType[];
  notAlertTags: FgParamType[];
  showRange: boolean;
  showAssignment: boolean;
  showPatternError: boolean;
  formulaParamsTemp: FgParam[];
  notAlertTagsMap: Map<string, FgParam[]>;
  notAlertTagsRootLevel: string[];
  formulaAvailableParams: FgParamType[];
  formulaSubscriber: Subscription;

  constructor(
    private foodGenesService: FoodGenesService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private popupHandlerService: PopupHandlerService
  ) {}
  ngOnDestroy(): void {
    this.formula = null;
    this.formulaForm?.reset();
    this.formulaSubscriber?.unsubscribe();
  }
  ngOnInit(): void {
    this.getFormula();
  }
  getFormula() {
    this.formulaSubscriber = this.foodGenesService.formula$.subscribe(
      (data) => {
        if (data) {
          this.formula = data;
          this.formula.formulaParams = this.formulaParamsTemp
            ? this.formulaParamsTemp
            : this.formula.formulaParams;

          this.formulaToArrayByRegex();
          this.toggleDisableCalcFunc(this.calcEvent);
          this.getFormulaAvailableParams();
          this.createObjsFromEnum();
          if (!this.formula?.condition) {
            this.formula.condition = new FgCondition();
            this.formula.condition.conditionId = 0;
            if (!this.formula.condition.conditionParticipants) {
              this.formula.condition.conditionParticipants = [];
            }
          }
          this.showAssignment = false;
          this.showRange = false;
          if (this.formula.formulaType == FormulaType.RangeCommand) {
            this.showRange = true;
          } else if (
            this.formula.formulaType == FormulaType.AssignmentCommand
          ) {
            this.showAssignment = true;
          }
        } else {
          this.formula = null;
        }
      }
    );
  }
  createObjsFromEnum() {
    this.createConditionOprandsObjFromEnum();
    this.createLogicOperatorsObjFromEnum();
    this.createAggregatingTypesObjFromEnum();
  }
  getMinMaxRangeValues() {
    const min = this.formulaArray[0]?.param.trim();
    const max = this.formulaArray[1]?.param.trim();
    const minParam = this.formula?.formulaParams?.find(
      (x) => x.fgParamName?.trim() == min
    );
    const maxParam = this.formula?.formulaParams?.find(
      (x) => x.fgParamName?.trim() == max
    );

    return { minParam, maxParam };
  }
  createForm() {
    this.formulaForm = this.fb.group({
      formulaId: this.fb.control(this.formula?.formulaId),
      formulaName: this.fb.control(
        { value: this.formula?.formulaName, disabled: !this.canEdit },
        Validators.required
      ),
      orderBy: this.fb.control(
        { value: this.formula?.orderBy, disabled: !this.canEdit },
        Validators.required
      ),
      formulaPattern: this.fb.control([], Validators.required),
      formulaCondition: this.fb.array([]),
    });

    if (this.formula?.formulaType !== FormulaType.AlertCommand) {
      this.paramTypes = this.notAlertTags;
    }

    // set parametrs control if formulaType is AlertCommand
    if (this.formula?.formulaType == FormulaType.AlertCommand) {
      this.paramTypes = this.alertTags;
    }
    // add aggregatingType control if formulaType is AgregationCommand
    else if (this.formula?.formulaType == FormulaType.AgregationCommand) {
      this.formulaForm.addControl(
        'aggregatingType',
        this.fb.control(
          this.formula.aggregationType || null,
          Validators.required
        )
      );
    }

    // only if has condition participants
    if (this.formula?.condition?.conditionParticipants.length > 0) {
      this.formula.condition.conditionParticipants.forEach((item) => {
        if (!this.formulaForm.contains('defaultValue')) {
          this.formulaForm.addControl(
            'defaultValue',
            this.fb.control(
              this.formula.condition.defaultValueParam,
              Validators.required
            )
          );
        }
        if (!this.formulaForm.contains('logicOperator')) {
          this.formulaForm.addControl(
            'logicOperator',
            this.fb.control(
              this.formula.condition.conditionType,
              Validators.required
            )
          );
        }
        const condition = this.createConditionItem(
          item.firstParam,
          item.secondParam,
          item.operator,
          item.conditionParticipantId,
          item.conditionId
        );
        this.getFormulaCondition.push(condition);
      });
    } /* else {
      this.getFormulaCondition.push(this.createConditionItem());
    } */
    this.getFormulaPattern.disable();
    this.getFormulaCondition.valueChanges.subscribe((data) => {
      if (data.length == 0) {
        this.formulaForm.removeControl('defaultValue');
        this.formulaForm.removeControl('logicOperator');
      } else {
        if (!this.formulaForm.contains('defaultValue')) {
          this.formulaForm.addControl(
            'defaultValue',
            this.fb.control([], Validators.required)
          );
        }
        if (!this.formulaForm.contains('logicOperator')) {
          this.formulaForm.addControl(
            'logicOperator',
            this.fb.control(ConditionLogicType.And, Validators.required)
          );
        }
      }
    });
  }
  createConditionItem(
    firstParam = null,
    secondParam = null,
    operator = null,
    conditionParticipantId = 0,
    conditionId = 0
  ) {
    return this.fb.group({
      conditionParticipantId: this.fb.control(conditionParticipantId),
      conditionId: this.fb.control(conditionId),
      firstParam: [firstParam, Validators.required],
      secondParam: [secondParam, Validators.required],
      operator: [operator, Validators.required],
    });
  }
  getCalcFunctions() {
    this.foodGenesService.getCalcFunctions().subscribe((data) => {
      this.calcFunctions = data;
    });
  }
  getFormulaAvailableParams() {
    this.foodGenesService
      .getFormulaAvailableParams(this.formula.formulaId)
      .subscribe((data) => {
        console.log('getFormulaAvailableParams');
        this.formulaAvailableParams = data;
        this.notAlertTags = data.filter((x) => x.fgParamName !== 'AlertTag');
        this.alertTags = data.filter((x) => x.fgParamName == 'AlertTag');
        this.createForm();
      });
  }
  onCalcClick(calcFn: FgCalculatorFunctions) {
    if (!calcFn.isDisabled) {
      this.calcEvent = {
        isReplaced: calcFn.isReplaced,
        numParams: calcFn.fgCalculatorFunctParamNum,
        canBeEnd: calcFn.isCanBeEnd,
        paramSelected:
          calcFn.calculatorFunctType == FgCalculatorFunctType.Operand ? 1 : 0,
        values: [],
        calcFuncPattern: calcFn.fgCalculatorFunctPattern,
        afterFuncType: calcFn.afterFunctionType,
      };
      this.toggleDisableCalcFunc(this.calcEvent);
    }
    if (!calcFn.isReplaced) {
      this.addToFormula(calcFn.fgCalculatorFunctPattern, this.calcEvent);
    }
  }
  onRangeParamIdSelect(param: FgParam) {
    this.formula.rangeOnParamId = param.fgParamId;
  }
  onParamSelect(value: string) {
    //let calcEvent = { ...this.formulaArray[this.activeChipIndex]?.calcEvent };

    const calcEvent = this.calcEvent ? this.calcEvent : new CalcEvent();
    if (!calcEvent?.isReplaced) {
      calcEvent.isReplaced = true;
      calcEvent.numParams = 0;
      calcEvent.paramSelected = 0;
      calcEvent.values = [];
      calcEvent.calcFuncPattern = value;
      calcEvent.canBeEnd = true;
      calcEvent.afterFuncType = FgCalculatorFunctType.Operand;

      this.addToFormula(value, calcEvent);
    } else {
      calcEvent.paramSelected++; // Increment param selected
      calcEvent.values.push(value); // Add value to array

      // Reset if all params selected
      if (calcEvent.paramSelected === calcEvent.numParams) {
        const paramReplaced = this.replaceParam();
        this.resetCalcEvent();
        this.addToFormula(paramReplaced, calcEvent);
      }
    }
  }
  onRangeParamSelect(fgParam: FgParam, rangeValue: 'min' | 'max' = null) {
    if (rangeValue) {
      if (rangeValue == 'min') {
        this.activeChipIndex = 0;
        this.formula.formulaParams[0] = fgParam;
      } else if (rangeValue == 'max') {
        this.activeChipIndex = 1;
        this.formula.formulaParams[1] = fgParam;
      }
      this.onChipDelete();

      this.addToFormula(fgParam.fgParamName, new CalcEvent());
    }
  }
  resetCalcEvent() {
    this.calcEvent.values = [];
    //this.calcEvent.paramSelected = 0;
    this.calcEvent.isReplaced = false;
  }
  replaceParam() {
    const length = this.calcEvent.numParams;
    let value = this.calcEvent.calcFuncPattern;
    for (let index = 1; index <= length; index++) {
      value = value.replace(`p${index}`, this.calcEvent.values[index - 1]);
    }
    return value;
  }
  addToFormula(param: string, calcEvent: CalcEvent) {
    if (this.showPatternError) {
      this.showPatternError = false;
    }

    this.formulaArray.splice(this.activeChipIndex + 1, 0, {
      param,
      calcEvent: calcEvent,
    });
    this.onChipNext();
    this.toggleDisableCalcFunc(calcEvent);
  }
  toggleDisableCalcFunc(calcEvent: CalcEvent) {
    if (!this.calcFunctions) return;
    //const calcEvent = { ...this.calcEvent };
    this.calcFunctions.forEach((calcBtn) => {
      // Disable if calcFuncType is not equal to afterFuncType
      if (calcBtn.calculatorFunctType !== calcEvent?.afterFuncType) {
        calcBtn.isDisabled = true;
      } else if (
        // Disable if calcEvent is not null and numParams is greater than paramSelected
        calcEvent?.numParams > calcEvent?.paramSelected &&
        calcBtn.calculatorFunctType !== FgCalculatorFunctType.Parameter
      ) {
        calcBtn.isDisabled = true;
      } else {
        calcBtn.isDisabled = false;
      }
      // Enable if withStart is true and calcEvent is null or calcFuncPattern is empty
      if (calcBtn.withStart && (!calcEvent || !calcEvent?.calcFuncPattern)) {
        calcBtn.isDisabled = false;
      }
    });

    // Disable params if numParams is greater than paramSelected
    if (calcEvent?.numParams > calcEvent?.paramSelected) {
      this.isParamDisabled = false;
    } else if (calcEvent?.afterFuncType == FgCalculatorFunctType.Parameter) {
      // Enable params if afterFuncType is Parameter
      this.isParamDisabled = false;
    } else {
      this.isParamDisabled = true;
    }

    // Enable params if paramatersDangle is null or calcFuncPattern is empty
    if (!calcEvent || !calcEvent?.calcFuncPattern || calcEvent.withStart) {
      this.isParamDisabled = false;
    }
  }
  onChipClick(index: number, item: FormulaChip) {
    this.activeChipIndex = index;
    this.calcEvent = item.calcEvent;
    this.toggleDisableCalcFunc(this.calcEvent);
  }
  onChipBack(toggleDisable = true) {
    this.activeChipIndex--;

    this.calcEvent = this.formulaArray[this.activeChipIndex]?.calcEvent;
    if (toggleDisable) {
      this.toggleDisableCalcFunc(this.calcEvent);
    }
  }
  onChipNext(toggleDisable = true) {
    this.activeChipIndex++;
    this.calcEvent = this.formulaArray[this.activeChipIndex]?.calcEvent;

    if (toggleDisable) {
      this.toggleDisableCalcFunc(this.calcEvent);
    }
  }
  onChipDelete() {
    this.formulaArray.splice(this.activeChipIndex, 1);
    if (this.formulaArray.length == 0 && !this.showPatternError) {
      this.showPatternError = true;
    }
    this.activeChipIndex--;
    this.calcEvent = this.formulaArray[this.activeChipIndex]?.calcEvent;
    this.toggleDisableCalcFunc(this.calcEvent);
  }
  formulaToArrayByRegex() {
    this.formulaArray = [];
    let regex: RegExp;
    if (this.formula?.formulaType == FormulaType.RangeCommand) {
      regex = /((\()|([א-תa-z_0-9\({}]+\)?)|[\+\^\-\*\/]|(\)))/gi;
    } else {
      regex = /((\()|([א-תa-z_0-9\,\({}]+\)?)|[\+\^\-\*\/]|(\)))/gi;
    }
    if (this.formula?.formulaType == FormulaType.AgregationCommand) {
      const aggName = AggregatingType[this.formula.aggregationType];
      const str = `[\(\)]|${aggName}`;
      const regex = new RegExp(str, 'gi');
      this.formula.formulaNamedPattern = this.formula.formulaNamedPattern
        .replace(regex, '')
        .replace(/\,/g, ' ');
    }
    const formulaArray = this.formula.formulaNamedPattern?.match(regex);
    let regItemFilter;
    if (this.formula?.formulaType == FormulaType.FormulaCommand) {
      regItemFilter = /[{}]/g;
    } else regItemFilter = /[{},]/g;
    if (!formulaArray) return;
    formulaArray.forEach((element) => {
      const obj = {
        param: ' ' + element.replace(regItemFilter, ''),
        calcEvent: this.setCalcEventByParamName(element),
      };
      this.formulaArray.push(obj);
    });
    this.calcEvent = this.formulaArray[this.activeChipIndex]?.calcEvent;
  }
  setCalcEventByParamName(paramName: string): CalcEvent {
    let calcName = '';
    const calcFnSplited = paramName.split('(')[0];
    if (calcFnSplited) calcName = calcFnSplited;
    else calcName = paramName;
    const calcFuncFinded = this.calcFunctions?.find(
      (x) => x.fgCalculatorFunctName.trim() === calcName
    );
    const calcEvent = new CalcEvent();
    if (calcFuncFinded) {
      calcEvent.calcFuncPattern = calcName;
      calcEvent.afterFuncType = calcFuncFinded.afterFunctionType;
      calcEvent.numParams = calcFuncFinded.fgCalculatorFunctParamNum;
      calcEvent.isReplaced = false;
      calcEvent.paramSelected = 0;
      calcEvent.values = [];
      calcEvent.canBeEnd = true;
      calcEvent.withStart = calcFuncFinded.withStart;
    } else {
      calcEvent.calcFuncPattern = calcName;
      calcEvent.afterFuncType = FgCalculatorFunctType.Operand;
      calcEvent.numParams = 0;
      calcEvent.isReplaced = false;
      calcEvent.paramSelected = 0;
      calcEvent.values = [];
      calcEvent.canBeEnd = true;
    }
    return calcEvent;
  }
  onConditionDelete(index: number) {
    (this.formulaForm.get('formulaCondition') as FormArray).removeAt(index);
  }
  onConditionAdd() {
    (this.formulaForm.get('formulaCondition') as FormArray).push(
      this.createConditionItem()
    );
  }
  createConditionOprandsObjFromEnum() {
    if (this.conditionOprands.length > 0) return;
    for (var n in ConditionType) {
      if (typeof ConditionType[n] === 'number') {
        this.conditionOprands.push({ id: <any>ConditionType[n], name: n });
      }
    }
  }
  createLogicOperatorsObjFromEnum() {
    if (this.logicOperators.length > 0) return;
    for (var n in ConditionLogicType) {
      if (typeof ConditionLogicType[n] === 'number') {
        this.logicOperators.push({ id: <any>ConditionLogicType[n], name: n });
      }
    }
  }
  createAggregatingTypesObjFromEnum() {
    if (this.aggregatingTypes.length > 0) return;
    for (var n in AggregatingType) {
      if (typeof AggregatingType[n] === 'number') {
        this.aggregatingTypes.push({ id: <any>AggregatingType[n], name: n });
      }
    }
  }
  onParamConditionSelect(param: FgParam, index: number, typeIndex: string) {
    if (param) {
      const condition = this.formulaForm.get('formulaCondition')['controls'][
        index
      ] as FormGroup;
      condition.get(typeIndex).setValue(param.fgParamId);
    }
  }
  onDefaultValueSelect(param: FgParam) {
    if (param) {
      this.formulaForm.get('defaultValue').setValue(param.fgParamId);
    }
  }
  titleByFormulaType() {
    switch (this.formula.formulaType) {
      case FormulaType.AgregationCommand:
        return 'Agregation';
      case FormulaType.AlertCommand:
        return 'Alert';
      case FormulaType.AssignmentCommand:
        return 'Assignment';
      case FormulaType.FormulaCommand:
        return 'Formula';
      case FormulaType.RangeCommand:
        return 'Range';
    }
  }
  saveFormula() {
    if (this.formulaForm.valid) {
      if (this.formulaArray.length == 0) {
        this.showPatternError = true;
        return;
      } else {
        this.showPatternError = false;
      }
      const conditions: FormGroup[] =
        this.formulaForm.get('formulaCondition')['controls'];
      // Add conditions to formula object from form
      if (conditions.length > 0) {
        this.formula.condition.conditionParticipants = [];
        conditions.forEach((condition) => {
          const cp = new ConditionParticipant();
          cp.firstParam = condition.value.firstParam;
          cp.secondParam = condition.value.secondParam;
          cp.operator = condition.value.operator;
          cp.conditionId = condition.value.conditionId;
          cp.conditionParticipantId = condition.value.conditionParticipantId;
          this.formula.condition.conditionParticipants.push(cp);
        });
        this.formula.condition.conditionType =
          this.formulaForm.value.logicOperator;
        this.formula.condition.defaultValueParam =
          this.formulaForm.value.defaultValue;
      } else {
        this.formula.condition = null;
      }
      this.formula.formulaName = this.formulaForm.value.formulaName;
      this.formula.orderBy = this.formulaForm.value.orderBy;
      if (this.formula.formulaType == FormulaType.RangeCommand) {
        this.formulaParamsTemp = this.formula.formulaParams;
      } else {
        this.formulaParamsTemp = null;
      }
      this.formula.formulaParams = null;
      if (this.getAggregatingType) {
        this.formula.formulaNamedPattern = this.formulaArray
          .map((x) => x.param)
          .join(',');
        const aggName = AggregatingType[this.formulaForm.value.aggregatingType];
        this.formula.aggregationType = this.formulaForm.value.aggregatingType;
        this.formula.formulaNamedPattern =
          aggName + '(' + this.formula.formulaNamedPattern + ')';
      } else if (this.formula.formulaType == FormulaType.FormulaCommand) {
        this.formula.formulaNamedPattern = this.formulaArray
          .map((x) => x.param)
          .join('');
      } else {
        this.formula.formulaNamedPattern = this.formulaArray
          .map((x) => x.param)
          .join(',');
      }

      this.foodGenesService.updateFormula(this.formula).subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this.snackBar.open((data as ErrorMessage).message, 'Close');
        } else {
          this.getFormula();

          this.snackBar.open('Formula updated', 'Close', {
            duration: 5000,
          });
        }
      });
    }
  }
  checkFormula() {
    this.foodGenesService
      .checkFormula(this.formula.formulaId)
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this.snackBar.open((data as ErrorMessage).message, 'Close', {
            panelClass: 'error-snackbar-snp',
          });
        } else {
          this.snackBar.open('Formula checked', 'Close', {
            duration: 5000,
          });
        }
      });
  }
  deleteFormula() {
    this.popupHandlerService.openConfirmationDialog('', 'Delete formula');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          this.foodGenesService
            .removeFormula(this.formula.formulaId)
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this.snackBar.open((data as ErrorMessage).message, 'Close', {
                  panelClass: 'error-snackbar-snp',
                });
              } else {
                data = this.foodGenesService.saveFormulas;
                if (data) {
                  const formulas = data;
                  const index = formulas.findIndex(
                    (x) => x.formulaId == this.formula.formulaId
                  );
                  formulas.splice(index, 1);
                  this.foodGenesService.formulas$.next(formulas);
                  this.foodGenesService.formula$.next(formulas[0]);
                  this.onConditionDelete(index);
                }
                this.snackBar.open('Formula deleted', 'Close', {
                  duration: 2000,
                });
              }
            });
        }
      });
  }

  cancel() {
    this.foodGenesService.mainBoardState$.next(false);
  }
  colorizeClosingBrackets() {}

  get getFormulaPattern() {
    return this.formulaForm.get('formulaPattern') as FormControl;
  }
  get getFormulaCondition() {
    return this.formulaForm.get('formulaCondition') as FormArray;
  }
  get getDefaultValue() {
    return this.formulaForm.get('defaultValue') as FormControl;
  }
  get getLogicOperator() {
    return this.formulaForm.get('logicOperator') as FormControl;
  }
  get getAggregatingType() {
    return this.formulaForm.get('aggregatingType') as FormControl;
  }
  get getFormulaId() {
    return this.formulaForm.get('formulaId') as FormControl;
  }
}
