import { Component, OnInit } from '@angular/core';
import { PatientNotesPusherService } from 'src/app/Services/patient-notes-pusher.service';
import { PatientsService } from 'src/app/Services/patients.service';
import { Patient } from 'src/app/data/Patients';

@Component({
  selector: 'app-patients-table',
  templateUrl: './patients-table.component.html',
  styleUrls: ['./patients-table.component.css'],
})
export class PatientsTableComponent implements OnInit {
  patientsData: Patient[];

  constructor(private patientsService: PatientsService) {
    patientsService.getAll().subscribe((data) => (this.patientsData = data));
  }
  ngOnInit(): void {}
  editTest(event, patientItem) {}
  isProductPlus(patient: Patient) {
    return (
      patient.products.filter(
        (x) => x.productTypeId == 1 || x.productTypeId == 2
      )[0].productTypeId == 1
    );
  }
}
