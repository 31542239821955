<div class="main">
  <mat-sidenav-container class="main__container">
    <mat-sidenav
      mode="side"
      position="end"
      opened
      class="main__container__nav"
      style="max-width: max-content"
    >
      <perfect-scrollbar
        style="height: calc(100vh - 39px)"
        [perfectScrollbar]="config"
      >
        <div class="main__title">
          <p style="text-transform: uppercase">Knowledge</p>
          <button *ngIf="canEdit()" (click)="addCategory()" mat-button>
            + Add category
          </button>
        </div>

        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let articleCategory of articleCategries"
            [expanded]="
              (categoryIdToEdit == articleCategory.articleCategoryId &&
                editModeCategoryName) ||
              (subCategoryIdToEdit &&
                findSubCategoryToExpand(articleCategory.articleSubCategories) ==
                  subCategoryIdToEdit)
            "
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <button
                  mat-button
                  matSuffix
                  (click)="preventDefault($event)"
                  [matMenuTriggerFor]="mainMore"
                  class="main-more-btn"
                  *ngIf="canEdit()"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu
                  class="main-more-menu"
                  #mainMore="matMenu"
                  xPosition="after"
                >
                  <button
                    mat-menu-item
                    (click)="
                      toggleEditModeCategoryName(
                        articleCategory.articleCategoryId
                      )
                    "
                  >
                    <mat-icon class="mat-icon-rtl-mirror">edit</mat-icon>
                    <span>Edit category name</span>
                  </button>
                  <button mat-menu-item (click)="$event.stopPropagation()">
                    <mat-icon class="mat-icon-rtl-mirror"
                      >format_list_numbered</mat-icon
                    >
                    <span>Change order</span>
                    <mat-form-field
                      appearance="fill"
                      style="width: 50px; margin-right: 10px"
                    >
                      <mat-select
                        (selectionChange)="editCategory(articleCategory)"
                        [(ngModel)]="articleCategory.order"
                      >
                        <ng-container *ngFor="let order of orderValues">
                          <mat-option [value]="order">{{ order }}</mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </button>
                  <button
                    mat-menu-item
                    (click)="addSubCategory(articleCategory.articleCategoryId)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror"
                      >add_circle_outline</mat-icon
                    >
                    <span>Add sub category</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      duplicateCategory(articleCategory.articleCategoryId)
                    "
                  >
                    <mat-icon class="mat-icon-rtl-mirror"
                      >content_copy</mat-icon
                    >
                    <span>Duplicate category</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="deleteCategory(articleCategory)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror"
                      >delete_outline</mat-icon
                    >
                    <span>Delete category</span>
                  </button>
                </mat-menu>

                <ng-container
                  *ngIf="
                    categoryIdToEdit == articleCategory.articleCategoryId &&
                      editModeCategoryName;
                    else viewMode
                  "
                >
                  <mat-form-field
                    appearance="fill"
                    (click)="preventDefault($event)"
                    class="edit-category-name"
                  >
                    <input
                      (keydown.Space)="$event.stopPropagation()"
                      #categoryNameInput
                      matInput
                      [(ngModel)]="articleCategory.name"
                    />
                  </mat-form-field>
                  <button
                    (click)="
                      preventDefault($event); editCategory(articleCategory)
                    "
                    class="action-icon-btn"
                    mat-icon-button
                  >
                    <mat-icon>done</mat-icon>
                  </button>
                  <button
                    (click)="
                      preventDefault($event); toggleEditModeCategoryName()
                    "
                    class="action-icon-btn"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </ng-container>
                <ng-template #viewMode>{{ articleCategory.name }}</ng-template>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p
              style="display: flex; align-items: baseline"
              *ngFor="let subCategory of articleCategory.articleSubCategories"
            >
              <button
                mat-button
                matSuffix
                (click)="preventDefault($event)"
                [matMenuTriggerFor]="subMore"
                class="main-more-btn"
                *ngIf="canEdit()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu
                class="main-more-menu"
                #subMore="matMenu"
                xPosition="after"
              >
                <button
                  mat-menu-item
                  (click)="addSubSubCategory(subCategory.articleSubCategoryId)"
                >
                  <mat-icon class="mat-icon-rtl-mirror"
                    >add_circle_outline</mat-icon
                  >
                  <span>Add sub sub category</span>
                </button>

                <button
                  mat-menu-item
                  (click)="
                    toggleEditModeSubCategoryName(
                      subCategory.articleSubCategoryId
                    )
                  "
                >
                  <mat-icon class="mat-icon-rtl-mirror">edit</mat-icon>
                  <span>Edit sub category name</span>
                </button>
                <button
                  *ngIf="
                    subCategory.childrenSubCategories &&
                    subCategory.childrenSubCategories.length == 0
                  "
                  mat-menu-item
                  (click)="$event.stopPropagation()"
                >
                  <mat-checkbox
                    class="qa-checkbox"
                    [(ngModel)]="subCategory.isBullets"
                    (change)="editSubCategory(subCategory)"
                  >
                    <span style="margin-right: 10px; color: unset"
                      >Set as Q&A</span
                    ></mat-checkbox
                  >
                </button>
                <button mat-menu-item (click)="deleteSubCategory(subCategory)">
                  <mat-icon class="mat-icon-rtl-mirror"
                    >delete_outline</mat-icon
                  >
                  <span>Delete sub category</span>
                </button>
              </mat-menu>
              <ng-container
                *ngIf="
                  subCategoryIdToEdit == subCategory.articleSubCategoryId &&
                    editModeSubCategoryName;
                  else viewMode
                "
              >
                <mat-form-field
                  appearance="fill"
                  (click)="preventDefault($event)"
                  class="edit-category-name"
                >
                  <input
                    #subCategoryNameInput
                    matInput
                    [(ngModel)]="subCategory.name"
                  />
                </mat-form-field>
                <button
                  (click)="preventDefault($event); editSubCategory(subCategory)"
                  class="action-icon-btn"
                  mat-icon-button
                >
                  <mat-icon>done</mat-icon>
                </button>
                <button
                  (click)="
                    preventDefault($event); toggleEditModeSubCategoryName()
                  "
                  class="action-icon-btn"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
              <ng-template #viewMode>
                <ng-container
                  *ngIf="
                    subCategory.childrenSubCategories &&
                      subCategory.childrenSubCategories.length > 0;
                    else noSubSub
                  "
                >
                  <mat-accordion class="has-sub-sub">
                    <mat-expansion-panel
                      [expanded]="expandSubCategory(subCategory)"
                    >
                      <mat-expansion-panel-header style="padding-right: 0">
                        <mat-panel-title>
                          <span
                            (click)="
                              openSubCategoryData(
                                subCategory.articleSubCategoryId
                              )
                            "
                            >{{ subCategory.name }}</span
                          >
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <p *ngFor="let item of subCategory.childrenSubCategories">
                        <ng-container
                          *ngIf="
                            subSubCategoryIdToEdit ==
                              item.articleSubCategoryId &&
                              editModeSubSubCategoryName;
                            else viewSubSubMode
                          "
                        >
                          <mat-form-field
                            appearance="fill"
                            (click)="preventDefault($event)"
                            class="edit-category-name"
                          >
                            <input
                              #subSubCategoryNameInput
                              matInput
                              [(ngModel)]="item.name"
                            />
                          </mat-form-field>
                          <button
                            (click)="
                              preventDefault($event); editSubSubCategory(item)
                            "
                            class="action-icon-btn"
                            mat-icon-button
                          >
                            <mat-icon>done</mat-icon>
                          </button>
                          <button
                            (click)="
                              preventDefault($event);
                              toggleEditModeSubSubCategoryName()
                            "
                            class="action-icon-btn"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </ng-container>
                        <ng-template #viewSubSubMode>
                          <button
                            mat-button
                            matSuffix
                            (click)="preventDefault($event)"
                            [matMenuTriggerFor]="subSubMore"
                            class="main-more-btn"
                            *ngIf="canEdit()"
                          >
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu
                            class="main-more-menu"
                            #subSubMore="matMenu"
                            xPosition="after"
                          >
                            <button
                              mat-menu-item
                              (click)="
                                toggleEditModeSubSubCategoryName(
                                  item.articleSubCategoryId
                                )
                              "
                            >
                              <mat-icon class="mat-icon-rtl-mirror"
                                >edit</mat-icon
                              >
                              <span>Edit sub sub category name</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="$event.stopPropagation()"
                            >
                              <mat-checkbox
                                class="qa-checkbox"
                                [(ngModel)]="item.isBullets"
                                (change)="editSubCategory(item)"
                              >
                                <span style="margin-right: 10px; color: unset"
                                  >Set as Q&A</span
                                ></mat-checkbox
                              >
                            </button>
                            <button
                              mat-menu-item
                              (click)="deleteSubCategory(item)"
                            >
                              <mat-icon class="mat-icon-rtl-mirror"
                                >delete_outline</mat-icon
                              >
                              <span>Delete sub category</span>
                            </button>
                          </mat-menu>
                          <span
                            (click)="
                              openSubCategoryData(item.articleSubCategoryId)
                            "
                            style="cursor: pointer"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </p>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>
                <ng-template #noSubSub>
                  <span
                    (click)="
                      openSubCategoryData(subCategory.articleSubCategoryId)
                    "
                    style="cursor: pointer"
                    >{{ subCategory.name }}</span
                  >
                </ng-template>
              </ng-template>
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </perfect-scrollbar>
    </mat-sidenav>
    <mat-sidenav-content
      class="main__container__content"
      style="margin-right: 357px"
    >
      <mat-card *ngIf="subCategoryToShow">
        <mat-card-title style="display: flex; align-items: center">
          <button
            *ngIf="canEdit()"
            style="
              width: revert;
              color: #119c99;
              border-color: #119c99;
              padding: 0 0.5rem;
              min-width: auto;
              margin-left: 1rem;
            "
            mat-stroked-button
            (click)="
              subCategoryIdToEditInner = subCategoryToShow.articleSubCategoryId
            "
          >
            <mat-icon class="mat-icon-rtl-mirror">edit</mat-icon>
          </button>
          <ng-container
            *ngIf="
              subCategoryIdToEditInner ==
                subCategoryToShow.articleSubCategoryId;
              else contentViewMode
            "
          >
            <mat-form-field
              appearance="fill"
              (click)="preventDefault($event)"
              class="edit-inner-category-name edit-category-name"
            >
              <input
                #subCategoryNameInput
                matInput
                [(ngModel)]="subCategoryToShow.name"
              />
            </mat-form-field>
            <button
              (click)="
                preventDefault($event); editSubCategory(subCategoryToShow)
              "
              class="action-icon-btn"
              mat-icon-button
            >
              <mat-icon>done</mat-icon>
            </button>
            <button
              (click)="
                preventDefault($event);
                openSubCategoryData(subCategoryToShow.articleSubCategoryId)
              "
              class="action-icon-btn"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>
          <ng-template #contentViewMode>
            <span>{{ subCategoryToShow.name }}</span>
          </ng-template>
        </mat-card-title>
        <hr style="margin: 1.5rem 0" />
        <ng-container *ngIf="!subCategoryToShow.isBullets; else isBullets">
          <ng-container *ngIf="!contentEditMode; else contentViewMode"
            ><p [innerHtml]="sanitizeHTML(subCategoryToShow.content)"></p>
            <button
              mat-button
              *ngIf="canEdit()"
              (click)="contentEditMode = true"
            >
              Edit
            </button>
          </ng-container>
          <ng-template #contentViewMode>
            <angular-editor
              [config]="editorConfig"
              [(ngModel)]="subCategoryToShow.content"
            ></angular-editor>
            <button
              mat-button
              (click)="
                editSubCategory(subCategoryToShow); contentEditMode = false
              "
            >
              Save</button
            ><button mat-button (click)="contentEditMode = false">
              Cancel
            </button></ng-template
          >
        </ng-container>
        <ng-template #isBullets>
          <button
            class="add-bullet"
            (click)="addBullet(subCategoryToShow.articleSubCategoryId)"
            mat-button
            *ngIf="canEdit()"
          >
            + Add Q&A
          </button>
          <div
            class="bullets"
            *ngFor="let bullet of subCategoryToShow.articleBullets"
          >
            <mat-accordion class="bullets__qa">
              <div class="bullets__qa__wrapper">
                <button
                  mat-button
                  matSuffix
                  (click)="preventDefault($event)"
                  class="more-btn"
                  [matMenuTriggerFor]="qaMenu"
                  *ngIf="canEdit()"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu
                  class="main-more-menu"
                  #qaMenu="matMenu"
                  xPosition="before"
                >
                  <button
                    mat-menu-item
                    (click)="toggleEditModeBullet(bullet.articleBulletId)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="deleteBullet(bullet.articleBulletId)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror"
                      >delete_outline</mat-icon
                    >
                    <span>Delete</span>
                  </button>
                </mat-menu>
                <mat-expansion-panel
                  [expanded]="
                    bulletIdToEdit == bullet.articleBulletId && bulletEditMode
                  "
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="q-mark">&#63;</span>
                      <ng-container
                        *ngIf="
                          bulletIdToEdit == bullet.articleBulletId &&
                            bulletEditMode;
                          else viewModeTitle
                        "
                      >
                        <mat-form-field
                          (click)="$event.stopPropagation()"
                          appearance="fill"
                          style="width: 100%; text-align: right; direction: rtl"
                        >
                          <textarea
                            #bulletTitleInput
                            (keydown.Space)="$event.stopPropagation()"
                            matInput
                            rows="1"
                            [(ngModel)]="bullet.title"
                          ></textarea>
                        </mat-form-field> </ng-container
                      ><ng-template #viewModeTitle>
                        {{ bullet.title }}</ng-template
                      >
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container
                    *ngIf="
                      bulletIdToEdit == bullet.articleBulletId &&
                        bulletEditMode;
                      else viewModeDesc
                    "
                  >
                    <mat-form-field
                      (click)="$event.stopPropagation()"
                      appearance="fill"
                      style="width: 100%; text-align: right; direction: rtl"
                    >
                      <!--
                    <angular-editor
                    (click)="$event.stopPropagation()"
                    #bulletTitleInput
                    [(ngModel)]="bullet.desc"
                    >

                    </angular-editor>
                    -->

                      <textarea
                        #bulletTitleInput
                        matInput
                        rows="1"
                        [(ngModel)]="bullet.desc"
                      ></textarea>
                    </mat-form-field>
                    <button
                      mat-button
                      (click)="editBullet(bullet); bulletEditMode = false"
                    >
                      Save
                    </button>
                    <button mat-button (click)="bulletEditMode = false">
                      Cancel
                    </button> </ng-container
                  ><ng-template #viewModeDesc>
                    <div style="white-space: break-spaces">
                      {{ bullet.desc }}
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>
        </ng-template>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
