import { Food } from './Food';
import { FoodNutrient } from './FoodNutrient';

export class FoodToNutrient {
  foodToNutrientId: number;
  foodId: number;
  food: Food;
  foodNutrientId: number;
  foodNutrient: FoodNutrient;
  amount: number;
}
