<!--<div *ngIf="isLoading" class="loadingOverlay">
  <mat-spinner diameter="250"></mat-spinner>
</div>-->
<app-head-filter
  *ngIf="agents && followUpAgents"
  [agents]="nutrisionists"
  [followUpAgents]="followUpAgents"
  [leadStatuses]="statuses"
  [leadSources]="leadSources"
  [headFilter]="headFilter"
  (filterChanged)="getAllWithFilter($event, true)"
  [classAttr]="'productsAttr'"
></app-head-filter>
<div class="TableClearFix"></div>
<app-tests-table
  *ngIf="nutrisionists"
  [agents]="nutrisionists"
  [followUpAgents]="followUpAgents"
  [statuses]="statuses"
  [leadSources]="leadSources"
  [productsData]="productsData"
  [checkedIds]="checkedIds"
  [checkedAll]="checkedAll"
  [headFilter]="headFilter"
  (checkedIdsChanged)="changeCheckedIds($event)"
  (filterChanged)="getAllWithFilter($event)"
  [isLoading]="isLoading"
>
</app-tests-table>
<app-table-paginator
  *ngIf="productsData && metaData"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [checkedIds]="checkedIds"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'productsAttr'"
  [agents]="nutrisionists"
  [followUpAgents]="followUpAgents"
  (checkedIdsChanged)="onCheckedIdsChanged($event)"
  [sticky]="true"
>
</app-table-paginator>
