import { Component, OnInit } from '@angular/core';
import { RegularUser } from 'src/app/data/RegularUser';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { SendMailService } from 'src/app/Services/send-mail.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-debug-links',
  templateUrl: './debug-links.component.html',
  styleUrls: ['./debug-links.component.css'],
})
export class DebugLinksComponent implements OnInit {
  regularUser: RegularUser;
  constructor(
    private sendMailService: SendMailService,
    private popupHandlerService: PopupHandlerService,
    private exelDownloadService: ExelDownloadService,
    private regularUsersService: RegularUsersService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.regularUsersService.currentUser.subscribe((data) => {
      this.regularUser = data;
    });
  }

  sendMail() {
    this.sendMailService.sendProductDetailsMail(128).subscribe(() => {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.mailSentsuccessfully
      );
    });
  }
  userHasPermission(permission: string[]) {
    return userHasPermisions(permission, this.regularUser);
  }
  fixCanceledProductsStatus() {}
  getWeightHistories() {
    var url = this.exelDownloadService.getWeightHistories();
    window.open(url, '_blank');
  }
  getDnaDataProcessing() {
    var url = this.exelDownloadService.getDnaDataProcessing();
    window.open(url, '_blank');
  }
  getDnaDataAllSnps() {
    var url = this.exelDownloadService.getDnaDataAllSnps();
    window.open(url, '_blank');
  }
  getDnaDataVitamins() {
    var url = this.exelDownloadService.getDnaDataVitamins();
    window.open(url, '_blank');
  }
}
