import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FoodCategoryPerms } from 'src/app/Components/Food/food-categories/food-categories.component';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { FoodCategory } from 'src/app/data/FoodCategory';
import { FoodCategoryLang } from 'src/app/data/FoodCategoryLang';
import { Language } from 'src/app/data/Language';

@Component({
  selector: 'app-food-categories-table',
  templateUrl: './food-categories-table.component.html',
  styleUrls: ['./food-categories-table.component.scss'],
})
export class FoodCategoriesTableComponent implements OnInit {
  selectedLangId: number = 1;
  @Input() foodCategoryPerms: FoodCategoryPerms;
  @Input() categories: FoodCat[];
  @Input() langs: Language[];
  @Input() editInx: number;
  @Input() catType: number;
  @Output('getData') getData: EventEmitter<boolean> = new EventEmitter();
  @Output('editCanceled') editCanceled: EventEmitter<FoodCat> =
    new EventEmitter();
  @Output('saveCategory') saveCategory: EventEmitter<FoodCat> =
    new EventEmitter();
  @Output('addCategory') addCategory: EventEmitter<any> = new EventEmitter();
  @Output('deleteCategory') deleteCategory: EventEmitter<any> =
    new EventEmitter();
  itemToExpand: CdkAccordionItem;
  disableButton: boolean;
  constructor(private router: Router) {}
  ngOnInit(): void {}
  createNewCategory() {
    this.disableButton = true;
    const category = new FoodCat();
    category.categoryId = 0;
    category.catLevel = 0;
    category.maxCatLevel = 0;
    category.categoryTypeId = this.catType;
    category.langId = this.selectedLangId;
    this.categories.push(category);
  }
  getLangName() {
    const lang = this.langs?.find((x) => x.languageId == this.selectedLangId);
    return lang ? lang.name : '';
  }
  onSetEditInx(inx: number) {
    this.editInx = inx;
    if (this.editInx == -1) {
      this.getData.emit(true);
    }
  }
  onEditModeChange(editMode: boolean) {
    if (editMode) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }
  onEditCanceled(category: FoodCat) {
    this.editCanceled.emit(category);
  }
  onSaveCategory(category: FoodCat) {
    this.saveCategory.emit(category);
    this.disableButton = false;
  }
  onDeleteCategory(category: FoodCategory) {
    this.deleteCategory.emit(category);
  }
}
