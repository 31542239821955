import { ArticleBullet } from './ArticleBullet';
import { ArticleCategory } from './ArticleCategory';


export class ArticleSubCategory {
  articleSubCategoryId: number;
  articleCategoryId: number | null;
  articleCategory: ArticleCategory;
  name: string;
  isBullets: boolean;
  content: string;
  articleBullets: ArticleBullet[];
  parentSubCategoryId: number | null;
  parentSubCategory: ArticleSubCategory;
  childrenSubCategories: ArticleSubCategory[];
}
