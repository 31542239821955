import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';

@Component({
  selector: 'app-menu-param-types',
  templateUrl: './menu-param-types.component.html',
  styleUrls: ['./menu-param-types.component.css'],
})
export class MenuParamTypesComponent implements OnInit, OnChanges {
  @Input() data: FgParamType[];
  @Input() selectedParam: FgParam;
  @Input() selectedParamId: number;
  @Output() selectedParamChange: EventEmitter<FgParam> = new EventEmitter();
  constructor() {}

  ngOnChanges(changes: any): void {
    if (changes.data && !changes.data.isFirstChange()) {
      this.data = changes.data.currentValue;
      this.compareAndSetSelectedParam();
    }
  }

  ngOnInit(): void {
    if (this.selectedParamId) {
      this.compareAndSetSelectedParam();
    }
  }

  compareAndSetSelectedParam() {
    this.data &&
      this.data.forEach((type) => {
        type.params?.forEach((param) => {
          if (param.fgParamId === this.selectedParamId) {
            this.selectedParam = param;
          }
        });
      });
  }

  onParamClick(param: FgParam) {
    this.selectedParam = param;
    this.selectedParamChange.emit(this.selectedParam);
  }

  hasParams(index: number) {
    return (
      this.data[index] &&
      this.data[index].params &&
      this.data[index].params.length > 0
    );
  }
}
