import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TubesPackage } from '../data/TubesPackage';
import { TubesPackagesService } from './tubes-packages.service';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

@Injectable({
  providedIn: 'root',
})
export class TubePackagesPusherService {
  private readonly tubesPackages = new BehaviorSubject<any>([]);
  readonly tubesPackages$ = this.tubesPackages.asObservable();
  headFilter: HeadFilter;
  constructor(private tubesPackagesService: TubesPackagesService) {}

  getAll(headFilter: HeadFilter = null) {
    if (headFilter) this.headFilter = headFilter;
    return this.tubesPackagesService
      .getAll(this.headFilter)
      .subscribe((data) => this.tubesPackages.next(data));
  }

  put(tubesPackage: TubesPackage) {
    return this.tubesPackagesService
      .put(tubesPackage)
      .subscribe((data) => this.getAll());
  }
  post(tubesPackage: TubesPackage) {
    return this.tubesPackagesService
      .post(tubesPackage)
      .subscribe((data) => this.getAll());
  }
}
