<mat-card class="allergies">
  <div class="wrapper-filters">
    <div class="d-flex space-between">
      <div class="allergies__title">Food Allergies</div>
      <div>
        <button
          [disabled]="editMode"
          class="add-btn"
          mat-button
          (click)="addNewItem()"
        >
          <mat-icon>add</mat-icon>
          Add item
        </button>
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </div>
    <hr class="hr" />
  </div>
  <!-- [perfectScrollbar]="config" -->

  <div class="allergies__table">
    <div class="allergies__table__head">
      <div>Allergies</div>
      <div></div>
      <div>Allergens</div>
      <div></div>
    </div>
    <perfect-scrollbar
      [config]="{ wheelSpeed: wheelSpeed }"
      #perfectScroll
      style="height: calc(100vh - 276px)"
    >
      <div class="allergies__table__body" *ngIf="allergans">
        <div
          class="allergies__table__body__item"
          *ngFor="let item of allergans; let inx = index"
        >
          <mat-form-field appearance="fill" [class.semi-disabled]="!editMode">
            <input
              matInput
              placeholder="Allergies"
              [(ngModel)]="item.foodAllergan.name"
              [disabled]="!editMode || (rowInxToEdit !== inx && editMode)"
            />
          </mat-form-field>
          <div class="link">
            <mat-icon>link</mat-icon>
          </div>
          <mat-form-field appearance="fill" [class.semi-disabled]="!editMode">
            <input
              matInput
              placeholder="Allergens"
              [(ngModel)]="item.userAllergy.name"
              [disabled]="!editMode || (rowInxToEdit !== inx && editMode)"
            />
          </mat-form-field>
          <div class="actions">
            <button
              *ngIf="rowInxToEdit !== inx"
              [disabled]="rowInxToEdit !== inx && editMode"
              mat-icon-button
              class="save"
              (click)="onEditClicked(inx)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <ng-container *ngIf="editMode && rowInxToEdit == inx">
              <button
                mat-icon-button
                class="save"
                (click)="onSaveClicked(item)"
              >
                <mat-icon>check</mat-icon>
              </button>
              <button
                mat-icon-button
                class="close"
                (click)="onCancelClicked(item, inx)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <app-table-paginator
    *ngIf="metaData"
    [langId]="langId"
    [sticky]="true"
    [metaData]="metaData"
    [headFilter]="headFilter"
    (filterChanged)="getPaginatorData($event)"
  >
  </app-table-paginator>
</mat-card>
