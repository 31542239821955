import { EmailTemplate } from "./EmailTemplate";
import { Status } from "./Status";
import { TriggerEvent } from "./TriggerEvent";


export class EventAction {
    eventActionId: number;
    triggerEventId: number;
    triggerEvent: TriggerEvent;
    actionTypeId: number;
    actionType: Status;
    emailTemplateId: number;
    emailTemplate: EmailTemplate;
    isActive: boolean;
}