<!--<button (click)="addPackage()">Add Package</button>-->
<app-head-filter
  [classAttr]="'office-all-deliveries'"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
></app-head-filter>
<div class="TableClearFix"></div>

<perfect-scrollbar style="max-height: 80vh" [config]="config">
  <div style="padding-right: 30px; padding-bottom: 40px">
    <app-tests-package-table [type]="'office'" [tubesPackages]="tubesPackages">
    </app-tests-package-table>
  </div>
</perfect-scrollbar>

<app-table-paginator
  *ngIf="tubesPackages && metaData"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'leadsAttr'"
  [headFilter]="headFilter"
>
</app-table-paginator>
