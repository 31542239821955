import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LifeStyleCalculation } from 'src/app/data/LifeStyleCalculation';
import { LifeStyleCalculationType } from 'src/app/data/LifeStyleCalculationTypes';
import { LifeStyleLocation } from 'src/app/data/LifeStyleLocation';
import { LifeStyleCalculationTypesService } from 'src/app/Services/life-style-calculation-types.service';
import { LifeStyleCalculationsService } from 'src/app/Services/life-style-calculations.service';
import { LifeStyleLocationsService } from 'src/app/Services/life-style-locations.service';

@Component({
  selector: 'app-life-style-calculations',
  templateUrl: './life-style-calculations.component.html',
  styleUrls: ['./life-style-calculations.component.css'],
})
export class LifeStyleCalculationsComponent implements OnInit {
  @ViewChild('table', { static: true }) table;
  lifeStyleCalculations: MatTableDataSource<LifeStyleCalculation> =
    new MatTableDataSource([]);
  calculationTypes: LifeStyleCalculationType[];
  locations: LifeStyleLocation[];
  //lifeStyleCalculations: LifeStyleCalculation[] = [];

  constructor(
    private lifeStyleCalculationsService: LifeStyleCalculationsService,
    private lifeStyleCalculationTypesService: LifeStyleCalculationTypesService,
    private lifeStyleLocationsService: LifeStyleLocationsService
  ) {}

  displayedColumns: string[] = ['name', 'value', 'location', 'type', 'action'];
  ngOnInit(): void {
    this.lifeStyleLocationsService.getAll().subscribe((data) => {
      this.locations = data;
    });
    this.lifeStyleCalculationTypesService.getAll().subscribe((data) => {
      this.calculationTypes = data;
    });
    this.getAll();
  }
  addNewCalculation() {
    var item: LifeStyleCalculation = new LifeStyleCalculation();

    this.lifeStyleCalculations.data.push(item);
    this.table.renderRows();
  }
  onLocationChanged(event, item: LifeStyleCalculation) {
    if (item.lifeStyleCalculationId > 0)
      this.lifeStyleCalculationsService.put(item).subscribe((data) => {
        this.getAll();
      });
  }
  getAll() {
    this.lifeStyleCalculationsService.getAll().subscribe((data) => {
      this.lifeStyleCalculations.data = data;
    });
  }
  onCalculationTypesChanged(event, item: LifeStyleCalculation) {
    if (item.lifeStyleCalculationId > 0)
      this.lifeStyleCalculationsService.put(item).subscribe((data) => {
        this.getAll();
      });
  }
  saveItem(item: LifeStyleCalculation) {
    this.lifeStyleCalculationsService.post(item).subscribe((data) => {
      this.getAll();
    });
  }
  onElementChanged(item: LifeStyleCalculation) {
    if (item.lifeStyleCalculationId > 0)
      this.lifeStyleCalculationsService.put(item).subscribe((data) => {
        this.getAll();
      });
  }
}
