<div class="blood-test-view">
  <div class="blood-test-view__header">
    <div style="display: flex; align-items: center">
      <strong>Date taken: </strong>
      <span *ngIf="!allEditMode">{{ dateTaken | date: "MM/dd/yyyy" }}</span>

      <mat-form-field class="datepicker" appearance="fill" *ngIf="allEditMode">
        <input
          placeholder="Choose a date"
          [(ngModel)]="dateTakenForm"
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div class="dates-select" *ngIf="historyMode">
        <mat-form-field appearance="fill">
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

          <mat-select
            (selectionChange)="onHistoryDateChanged($event)"
            [(ngModel)]="selectedHistoryDate"
          >
            <mat-option
              *ngFor="let historyDate of historyDates"
              [value]="historyDate"
            >
              {{ historyDate | date: "dd-MM-yyyy" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex">
      <button
        *ngIf="!historyMode"
        mat-button
        class="btn btn-fill mr-1"
        [ngClass]="{ disabled: allEditMode }"
        (click)="onUpdateClick()"
      >
        Update
      </button>
      <button
        *ngIf="!historyMode"
        mat-button
        class="btn btn-outline"
        [disabled]="!historyDates || this.historyDates.length == 0"
        [ngClass]="{ disabled: !historyDates || this.historyDates.length == 0 }"
        (click)="onHistoryClick()"
      >
        <img src="../../../assets/history-24px.svg" alt="" />
        History
      </button>
      <button
        mat-button
        *ngIf="historyMode"
        (click)="onHistoryBackClick()"
        style="color: #d3247e; line-height: 45px; cursor: pointer"
      >
        < back
      </button>
    </div>
  </div>

  <div class="result" *ngFor="let result of results; let i = index">
    <div class="result__item">
      <mat-icon
        matTooltip="Required For Recomendations"
        class="result__item__warning"
        *ngIf="result.bloodTestType.isRequiredForBullets"
        >warning</mat-icon
      >
      <span class="result__item__name">{{ result.bloodTestType.name }}: </span>
      <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId">
        <span class="result__item__value">{{ result.result }}</span>
        <span class="result__item__units">{{
          result.bloodTestType.units
        }}</span>
      </ng-container>
      <ng-container *ngIf="allEditMode || editInx == result.bloodTestTypeId">
        <div class="result__item__input">
          <input
            (change)="onResultChanged(formResults[i])"
            type="number"
            *ngIf="!formResults[i].isAlt"
            [(ngModel)]="formResults[i].result"
          />
          <input
            (change)="onResultAltChanged(formResults[i])"
            type="number"
            *ngIf="formResults[i].isAlt"
            [(ngModel)]="formResults[i].resultAlt"
          />
          <span
            class="result__item__input__unit"
            *ngIf="result.bloodTestType.units === '%'"
            >%</span
          >
        </div>
        <mat-button-toggle-group
          (change)="onAltChange(result, formResults[i])"
          *ngIf="result.bloodTestType.altUnits"
          [(ngModel)]="formResults[i].isAlt"
          aria-label="Font Style"
        >
          <!--  [(ngModel)]="selectedUnit"-->
          <mat-button-toggle [value]="false">{{
            result.bloodTestType.units
          }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{
            result.bloodTestType.altUnits
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
    </div>
    <app-blood-test-result-slider
      style="width: 33%; justify-content: center; display: flex"
      [result]="result"
      [gender]="gender"
    >
    </app-blood-test-result-slider>

    <!--   *ngIf="isRedMax(result)"-->
    <div class="result__item__date">
      <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId"
        ><span *ngIf="result.result" style="width: 100px">
          {{ result.dateCreated | date: "dd-MM-yyyy" }}
        </span>
        <span style="color: red; margin-right: 20px" *ngIf="!result.result">
          missing
        </span>
      </ng-container>
      <mat-form-field
        appearance="fill"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        class="datepicker"
      >
        <input
          placeholder="Choose a date"
          [(ngModel)]="dateTakenForm"
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button
        mat-icon-button
        class="action-btn"
        *ngIf="
          allEditMode || (editInx != result.bloodTestTypeId && editInx != -1)
        "
      >
        <img src="../../../assets/ok_button_pink_disabled.svg" />
      </button>

      <button
        class="action-btn"
        (click)="goToEditSingleMode(result)"
        *ngIf="!allEditMode && editInx == -1"
        mat-icon-button
      >
        <img src="../../../assets/edit_button_pink.svg" />
      </button>
      <button
        class="action-btn"
        (click)="onOkClicked(result, i)"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <img src="../../../assets/ok_button_pink.svg" />
      </button>
      <button
        class="action-btn"
        (click)="onCancelEditClicked(result)"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <img src="../../../assets/cancel_button_pink.svg" />
      </button>
    </div>
  </div>
  <mat-card *ngIf="allEditMode" class="footer-actions">
    <button mat-button class="btn btn-fill mr-2" (click)="saveInAllEditMode()">
      Save
    </button>
    <button
      mat-button
      class="btn btn-fill"
      (click)="cancelAllEditMode()"
      type="button"
    >
      Cancel
    </button>
  </mat-card>
</div>
