import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BulkChangeProductAgent } from '../data/connectors/BulkChangeProductAgent';

@Injectable({
  providedIn: 'root',
})
export class TestsConnectorService {
  bulkChangeProductAgentSubject: Subject<BulkChangeProductAgent> =
    new Subject();

  constructor() {}

  bulkChangeProductAgent(bulkChangeProductAgent: BulkChangeProductAgent) {
    this.bulkChangeProductAgentSubject.next(bulkChangeProductAgent);
  }
}
