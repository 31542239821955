import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeliveryResponse } from '../data/DeliveryResponse';

@Injectable({
  providedIn: 'root',
})
export class DeliveryResponsesService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<DeliveryResponse>(
      `${environment.baseUrl}DeliveryResponses/`
    );
  }

  getByPatientDeliveryId(patientDeliveryId: number) {
    return this.http.get<DeliveryResponse>(
      `${environment.baseUrl}DeliveryResponses/${patientDeliveryId}`
    );
  }
}
