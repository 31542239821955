import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calc-functions',
  templateUrl: './calc-functions.component.html',
  styleUrls: ['./calc-functions.component.css']
})
export class CalcFunctionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
