<table mat-table [dataSource]="translationCategories" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>id</th>
    <td mat-cell *matCellDef="let item">{{ item.translationCategoryId }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let item">
      <input (change)="editCatName(item)" [(ngModel)]="item.name" />
    </td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let item">
      <button (click)="deleteCategory(item)">
        <mat-icon aria-hidden="false" aria-label="delete">delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div style="display: flex">
  <input [(ngModel)]="newCategoryName" />
  <button (click)="addCategory()">Add Category</button>
</div>
