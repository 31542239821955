import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionConvertion } from 'src/app/data/ActionConvertion';
import { AppModule } from 'src/app/data/AppModule';
import { ActionConvertionsService } from 'src/app/Services/action-convertions.service';
import { AppModulesService } from 'src/app/Services/app-modules.service';
import { AddNewRoleComponent } from '../add-new-role/add-new-role.component';

@Component({
  selector: 'app-add-new-module-v2',
  templateUrl: './add-new-module-v2.component.html',
  styleUrls: ['./add-new-module-v2.component.scss'],
})
export class AddNewModuleV2Component implements OnInit {
  usingConventions = false;
  roleId;
  selectedAppModuleId: number;
  appModules: AppModule[];
  actionConvertions: ActionConvertion[];
  actionConvertionsChecked: ActionConvertion[];
  actionConvertionsUnchecked: ActionConvertion[];
  constructor(
    public dialogRef: MatDialogRef<AddNewRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appModulesService: AppModulesService,
    private actionConvertionsService: ActionConvertionsService
  ) {}

  ngOnInit(): void {
    this.roleId = this.data.roleId;
    this.appModulesService
      .getMissingModulesForRole(this.roleId)
      .subscribe((data) => {
        this.appModules = data;
      });
  }
  onDropdownChanged(e) {
    this.actionConvertionsService
      .getForRoleAndModule(this.roleId, this.selectedAppModuleId)
      .subscribe((data) => {
        this.actionConvertions = data;
        this.actionConvertionsChecked = data.filter((x) => x.checked == true);
        this.actionConvertionsUnchecked = data.filter(
          (x) => x.checked == false
        );
      });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  save() {
    var actionConvertions = this.actionConvertionsChecked.concat(
      this.actionConvertionsUnchecked
    );
    this.actionConvertionsService.saveAllForModuleAndRole(actionConvertions);
    this.dialogRef.close();
  }
  cancel() {
    this.dialogRef.close();
  }
}
