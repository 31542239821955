<div class="title">Add new keyword</div>
<div class="mb-2">
  <div class="item mb-2">
    <strong>Keyword</strong>
    <mat-form-field class="item__field" appearance="fill">
      <input matInput placeholder="Keyword" [(ngModel)]="keyword" />
    </mat-form-field>
  </div>
  <div class="item mb-2">
    <strong>Category</strong>
    <mat-form-field class="item__field" appearance="fill">
      <mat-select placeholder="Select category" [(ngModel)]="categoryId">
        <mat-option
          *ngFor="let item of data"
          [value]="item.translationCategoryId"
        >
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="actions mt-2">
  <button
    class="save"
    type="button"
    mat-button
    [disabled]="!categoryId || !keyword || keyword === ''"
    (click)="addKeyword()"
  >
    Add
  </button>
  <button class="cancel" mat-button mat-dialog-close type="button">
    Cancel
  </button>
</div>
