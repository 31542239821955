import { Component, Input, OnInit } from '@angular/core';
import { SubCategoryResultLangMin } from 'src/app/data/SubCategoryResultLangMin';

@Component({
  selector: 'app-select-dna-template',
  templateUrl: './select-dna-template.component.html',
  styleUrls: ['./select-dna-template.component.scss'],
})
export class SelectDnaTemplateComponent implements OnInit {
  @Input() subCategory: SubCategoryResultLangMin;
  templateBoxOpen: boolean = false;
  templateImages = [1, 2, 3, 4, 5];
  selectedTemplate: number;
  constructor() {}

  ngOnInit(): void {}

  onSelectTemplate(item: number) {
    this.selectedTemplate = item;
    this.templateBoxOpen = false;
  }
  openTemplateBox() {
    //console.log(item);
  }
}
