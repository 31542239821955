<button
  (click)="stopPropagation($event)"
  [matMenuTriggerFor]="moreMenu"
  #moreMenuTrigger="matMenuTrigger"
  (menuClosed)="onMenuClosed()"
  (menuOpened)="onMenuOpened()"
  panelClass="cat-menu"
  mat-icon-button
  class="more-btn"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #moreMenu="matMenu" class="cat-panel" [hasBackdrop]="true">
  <button
    (click)="editCategory()"
    mat-menu-item
    *ngIf="fromCategory && !isAlerts()"
  >
    <mat-icon>edit</mat-icon>
    Edit {{ name }}
  </button>
  <button mat-menu-item (click)="editSubCategory()">
    <mat-icon>{{ fromSubCategory ? "edit" : "add" }}</mat-icon>
    <span>{{ fromSubCategory ? "Edit" : "Add" }} {{ subName }}</span>
  </button>
  <button
    *ngIf="fromSubCategory && !isFoodGenes"
    mat-menu-item
    (click)="toggleActivateSubCategory()"
  >
    <mat-icon>{{
      subCategory.isActive ? "visibility_off" : "visibility"
    }}</mat-icon>
    {{ subCategory.isActive ? "Hide" : "Show" }} {{ subName }}
  </button>
  <button mat-menu-item (click)="delete()" *ngIf="!fromCategory">
    <mat-icon>delete</mat-icon>
    Delete {{ fromSubCategory && !isFoodGenes ? "Sub" : "" }}
    {{ isFoodGenes ? "Coefficient" : name }}
  </button>
</mat-menu>
