<form
  *ngIf="categoryForm"
  class="form"
  (ngSubmit)="onSubmit()"
  [formGroup]="categoryForm"
>
  <div class="label">בחירת קטגוריה</div>
  <mat-radio-group
    class="add-mode-radio-group"
    [(ngModel)]="addMode"
    [ngModelOptions]="{ standalone: true }"
    (change)="onChangeMode($event)"
  >
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromInput"
    >
      חדש
    </mat-radio-button>
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromList"
    >
      מתוך רשימה
    </mat-radio-button>
  </mat-radio-group>
  <div class="mb-2" [ngSwitch]="addMode">
    <div class="label" style="margin-bottom: 5px">שם קטגוריה</div>
    <div *ngSwitchCase="AddModeEnum.FromList">
      <div>
        <mat-form-field class="field" appearance="fill">
          <input
            placeholder="בחר מתוך הרשימה"
            type="text"
            matInput
            formControlName="name"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            (optionSelected)="onOptionSelected($event)"
            class="cat-panel"
            #auto="matAutocomplete"
          >
            <mat-option
              *ngFor="let item of filteredOptions | async"
              [value]="item.name"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="AddModeEnum.FromInput">
      <mat-form-field class="field" appearance="fill">
        <input
          placeholder="הוסף חדש"
          type="text"
          matInput
          formControlName="name"
        />
      </mat-form-field>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    style="position: relative; width: fit-content"
    class="mb-2"
  >
    <div class="label">צבע לקטגוריה</div>
    <button
      style="background: rgb(76, 180, 178, 0.3); margin-right: 5px"
      (click)="colorPicker.click()"
      mat-icon-button
    >
      <mat-icon [style.color]="CategoryColor.value">palette</mat-icon>
    </button>
    <input
      formControlName="color"
      type="color"
      #colorPicker
      style="visibility: hidden; position: absolute; top: 10px; left: 0"
    />
  </div>

  <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
    <div class="label" style="margin-bottom: -10px">מיקום הקטגוריה</div>

    <mat-form-field class="field order" appearance="fill">
      <input matInput type="number" formControlName="orderBy" />
    </mat-form-field>
  </div>
  <div class="mb-2" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
      <div fxLayout="column" fxLayoutAlign="center" fxFlex="45">
        <strong>PDF אייקון קטן</strong>
        <app-algo-image
          [langId]="langId"
          [algoImageType]="AlgoImageType.PrototypeCatSmallIcon"
          (onUploadFinished)="onUploadFinished($event)"
          [selectedImg]="getImage(AlgoImageType.PrototypeCatSmallIcon)"
          [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeCatSmallIcon)"
        ></app-algo-image>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="45"
        style="margin-right: 20px"
      >
        <strong>DNA REPORT אייקון</strong>
        <app-algo-image
          [langId]="langId"
          [algoImageType]="AlgoImageType.PrototypeCatDnaReport"
          (onUploadFinished)="onUploadFinished($event)"
          [selectedImg]="getImage(AlgoImageType.PrototypeCatDnaReport)"
          [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeCatDnaReport)"
        ></app-algo-image>
      </div>
    </div>

    <div
      fxFlex="30"
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayoutGap="15px"
      style="margin-right: 20px"
    >
      <div fxLayout="column" fxLayoutAlign="center">
        <strong>מסגרת חלק תחתון</strong>
        <app-algo-image
          isRectangle="true"
          [langId]="langId"
          textBox="מסגרת חלק תחתון"
          [algoImageType]="AlgoImageType.PrototypeCatPdfBorderBottom"
          (onUploadFinished)="onUploadFinished($event)"
          [selectedImg]="getImage(AlgoImageType.PrototypeCatPdfBorderBottom)"
          [checkedImgId]="
            getCheckedImage(AlgoImageType.PrototypeCatPdfBorderBottom)
          "
        ></app-algo-image>
      </div>
      <div fxLayout="column" fxLayoutAlign="center">
        <strong>מסגרת חלק אמצע</strong>
        <app-algo-image
          isRectangle="true"
          [langId]="langId"
          textBox="מסגרת חלק אמצע"
          [selectedImg]="getImage(AlgoImageType.PrototypeCatPdfBorderMiddle)"
          (onUploadFinished)="onUploadFinished($event)"
          [algoImageType]="AlgoImageType.PrototypeCatPdfBorderMiddle"
          [checkedImgId]="
            getCheckedImage(AlgoImageType.PrototypeCatPdfBorderMiddle)
          "
        ></app-algo-image>
      </div>
    </div>
  </div>
  <div class="mb-2" fxLayout="row">
    <div
      fxLayout="column"
      fxLayoutAlign="center"
      fxFlex="30"
      style="margin-left: 20px"
    >
      <strong>Desktop אייקון</strong>
      <app-algo-image
        [langId]="langId"
        [algoImageType]="AlgoImageType.PrototypeCatBigIcon"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage(AlgoImageType.PrototypeCatBigIcon)"
        [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeCatBigIcon)"
      ></app-algo-image>
    </div>
    <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
      <strong>Desktop תמונת כותרת</strong>
      <app-algo-image
        isBigRectangle="true"
        [langId]="langId"
        textBox="תמונת כותרת"
        [selectedImg]="getImage(AlgoImageType.PrototypePageTitleImage)"
        (onUploadFinished)="onUploadFinished($event)"
        [algoImageType]="AlgoImageType.PrototypePageTitleImage"
        [checkedImgId]="getCheckedImage(AlgoImageType.PrototypePageTitleImage)"
      ></app-algo-image>
    </div>
  </div>
  <div class="mb-2" fxLayout="row">
    <div
      fxLayout="column"
      fxLayoutAlign="center"
      fxFlex="30"
      style="margin-left: 20px"
    >
      <strong>App אייקון</strong>
      <app-algo-image
        [langId]="langId"
        [algoImageType]="AlgoImageType.PrototypeAppCatIcon"
        (onUploadFinished)="onUploadFinished($event)"
        [selectedImg]="getImage(AlgoImageType.PrototypeAppCatIcon)"
        [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeAppCatIcon)"
      ></app-algo-image>
    </div>
    <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
      <strong>App תמונת כותרת</strong>
      <app-algo-image
        isBigRectangle="true"
        [langId]="langId"
        textBox="App תמונת כותרת"
        [selectedImg]="getImage(AlgoImageType.PrototypeAppPageTitleImage)"
        (onUploadFinished)="onUploadFinished($event)"
        [algoImageType]="AlgoImageType.PrototypeAppPageTitleImage"
        [checkedImgId]="
          getCheckedImage(AlgoImageType.PrototypeAppPageTitleImage)
        "
      ></app-algo-image>
    </div>
  </div>
  <div class="mb-2">
    <div class="label"></div>
    <mat-checkbox formControlName="isPremium">
      <strong fxLayout="row" fxLayoutAlign="start center"
        >הגדר כפרימיום <mat-icon>workspace_premium</mat-icon></strong
      >
    </mat-checkbox>
    <small>קטגוריה המוגדרת כפרימיום תוצג רק ללקוחות שרכשו בדיקה מורחבת</small>
  </div>
  {{ errorMessage }}
  <div class="mb-4"></div>
  <div class="form-action">
    <button mat-raised-button type="submit" class="save">שמירה</button>
  </div>
</form>
