import { Food } from './Food';
import { FoodScale } from './FoodScale';
import { FoodScaleLang } from './FoodScaleLang';

export class FoodScaleValue {
  foodScaleValueId: number;
  foodId: number;
  food: Food;
  foodScaleId: number;
  foodScale: FoodScale;
  weight: number;
  amount: number;
  modifier: string;
  hidden: boolean;
}
