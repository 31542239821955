<!-- [style.pointer-events]="expression"  -->
<div [class.cat-wrapper]="isFoodCategoryImage" (click)="openFileManager()">
  <ng-container *ngIf="!tempImg || imgError">
    <ng-container *ngIf="iconName; else defaultImage">
      <button mat-icon-button>
        <mat-icon
          [ngStyle]="{
            'font-size': iconSize ? iconSize : 'inherit',
            width: iconSize ? iconSize : 'inherit',
            height: iconSize ? iconSize : 'inherit',
            'line-height': iconSize ? iconSize : 'inherit'
          }"
          >{{ iconName }}</mat-icon
        >
      </button>
    </ng-container>
    <ng-template #defaultImage>
      <div
        class="image"
        [class.rectangle]="isRectangle"
        [class.big-rectangle]="isBigRectangle"
      >
        <mat-icon>cloud_upload</mat-icon>
        <div>{{ textBox }}</div>
      </div>
    </ng-template>
  </ng-container>
  <div
    [style.width.px]="isFoodCategoryImage ? 100 : ''"
    [class.image]="!iconName"
    *ngIf="tempImg && !imgError"
  >
    <img
      style="
        max-width: 100%;
        max-height: 100%;
        display: block;
        padding: 5px;
        cursor: pointer;
      "
      [class.cat-image]="isFoodCategoryImage"
      [src]="sanitizePreviewSrc(tempImg)"
      alt="{{ tempImgName }}"
    />
  </div>
</div>
<div class="text-center" *ngIf="imgError" style="color: red">
  <small>{{ imgError }}</small>
</div>
