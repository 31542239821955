export const environment = {
  foodSourceKey: 'zameret',
  treeServiceRequestId: 1112,
  firebaseLogin: false,
  version: 1,
  production: false,
  baseUrl: 'https://webapitest.mygenes.co.il/api/',
  //baseUrl: 'https://webapi.mygenes.co.il/api/',
  nodeUrl: 'https://nodeapi.mygenes.co.il/',
  logStatistics: false,
  isAditionPayment: true,
  reportAliveTime: 30000,
  userActionTimeOut: 20000,
  payCallPopupActive: true,
  isNewPermissions: false,
  picsDomain: 'https://picstest.mygenes.co.il',
  cloudflareAccountId: 'c91a55606fe01bf8c56392c8bab489e0',
  cloudflareGlobalApiKey: 'b5287bc6e121b2ffc8182e7da81e76fe41db2',
  cloudflareEmail: 'yaniv.g@mygenes.co.il',
  /* firebaseConfig: {
    apiKey: 'AIzaSyB_CjdzAqQjHCZWq_TS2W-wMUJMKOpfKsI',
    authDomain: 'my-genes-dev.firebaseapp.com',
    projectId: 'my-genes-dev',
    storageBucket: 'my-genes-dev.appspot.com',
    messagingSenderId: '624918560266',
    appId: '1:624918560266:web:09513d891a48a816c529f3',
    measurementId: 'G-YJHRQB02KF',
  }, */

  /* firebaseConfig: {
    apiKey: 'AIzaSyDdIm8PGQJS45Sr7_hYAUDFrJrNvQ8HSZk',
    authDomain: 'mygeneschat.firebaseapp.com',
    databaseURL: 'https://mygeneschat.firebaseio.com',
    projectId: 'mygeneschat',
    storageBucket: 'mygeneschat.appspot.com',
    messagingSenderId: '1087373424417',
    appId: '1:1087373424417:web:379dcd7919a9584a243060',
    measurementId: 'G-VFY8K5FF83',
  }, */
  firebaseConfig: {
    'PROJECT-ONE': {
      // apiKey: 'AIzaSyB_CjdzAqQjHCZWq_TS2W-wMUJMKOpfKsI',
      // authDomain: 'my-genes-dev.firebaseapp.com',
      // projectId: 'my-genes-dev',
      // storageBucket: 'my-genes-dev.appspot.com',
      // messagingSenderId: '624918560266',
      // appId: '1:624918560266:web:09513d891a48a816c529f3',
      // measurementId: 'G-YJHRQB02KF',
      apiKey: 'AIzaSyCNJiKbc7U1yeM-gb_t6qEiOFFDiULGN48',
      authDomain: 'mygenes-qa.firebaseapp.com',
      projectId: 'mygenes-qa',
      storageBucket: 'mygenes-qa.appspot.com',
      messagingSenderId: '404166087320',
      appId: '1:404166087320:web:ea5316f43f4239c92576ed',
    },
    'PROJECT-TWO': {
      apiKey: 'AIzaSyBlSHdJGdOS5Ssfs9HPkryTdQVdgPA79_U',
      authDomain: 'mygenescall-be6fe.firebaseapp.com',
      databaseURL: 'https://mygenescall-be6fe-default-rtdb.firebaseio.com',
      projectId: 'mygenescall-be6fe',
      storageBucket: 'mygenescall-be6fe.appspot.com',
      messagingSenderId: '949225495138',
      appId: '1:949225495138:web:9a88b5243ae952d7468f3b',
      measurementId: 'G-HS1M68R5XT',
    },
  },
  recaptcha: {
    siteKey: '6LdcGxgeAAAAAGpKIQo4ZFP4aSzVksGrlEIq1BWh',
  },
};
