<mat-list class="rolesTable" role="list">
  <mat-list-item class="rolesTable__header" role="listitem">
    <span>Name</span>
    <span>Description</span>
    <span>Users</span>
    <span>Action</span>
  </mat-list-item>
  <mat-card class="rolesTable__item" *ngFor="let roleItem of rolesData">
    <mat-list-item role="listitem">
      <span class="d-flex-center"
        ><img src="../../../../assets/client.svg" alt="s" class="roleSvg" />{{
          roleItem.roleName
        }}
      </span>
      <span>{{ roleItem.desc }}</span>
      <span
        ><span
          style="cursor: pointer; color: #03aef7; font-weight: 500"
          (click)="onUsersClick(roleItem.roleTypeId)"
          >{{ roleItem.numActive }}/{{ roleItem.numTotal }}</span
        ></span
      >
      <span
        ><button mat-button>
          <img src="../../../../assets/client-edit.svg" alt="edit" /></button
      ></span>
    </mat-list-item>
  </mat-card>
</mat-list>
