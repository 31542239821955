<div style="position: relative">
  <div *ngIf="isLoading" class="loadingOverlay">
    <mat-spinner diameter="250"></mat-spinner>
  </div>
  <div style="display: flex; align-items: center">
    <button class="back back--lead" (click)="goBackToLeads()">
      < Back to Leads
    </button>
    <button *ngIf="leadId > 0" (click)="gotoLogs()" class="logging--btn">
      <img src="../../../../assets/triangle.svg" />
      Log
    </button>
    <button
      *ngIf="hasPermission() && lead && leadId > 0"
      (click)="openSendInfoDialog()"
      class="send-info"
    >
      Send info
    </button>
  </div>
  <div style="display: flex">
    <div style="width: 75%; margin: 15px">
      <mat-card class="clientDetails">
        <mat-list>
          <mat-list-item style="color: red" *ngIf="errorMsg.length > 0">
            {{ errorMsg }}
          </mat-list-item>
          <app-client-profile-view
            [classAttr]="'leadAttr'"
            [client]="lead"
            [leadStatuses]="leadStatuses"
            *ngIf="lead && isEditMode == false"
            (moveToEdit)="moveToEditMode()"
            (leadChanged)="getLeadData()"
          >
          </app-client-profile-view>
          <app-client-profile-edit
            (cancelForm)="onCancelForm($event)"
            [classAttr]="'leadAttr'"
            [client]="lead"
            [clientTypes]="clientTypes"
            [leadSources]="leadSources"
            [agents]="agents"
            [productIsFors]="productIsFors"
            [leadStatuses]="leadStatuses"
            *ngIf="lead && isEditMode == true"
            (saveForm)="onChildFormValidated($event)"
          >
          </app-client-profile-edit>

          <mat-list-item *ngIf="!isNewLead" class="row-container__item--action">
            <button class="btn-wide btn-wide--lead" (click)="convertToClient()">
              Convert To Client
            </button>
          </mat-list-item>
        </mat-list>
      </mat-card>
      <div class="mb-4"></div>
      <mat-card class="clientActivities" *ngIf="!isNewLead">
        Activities
        <mat-divider></mat-divider>
        <app-lead-activities-view
          (createNewTask)="createNewTask($event)"
          *ngIf="isTaskEditMode == false"
          [notes]="clientTasks"
          [classAttr]="'leadAttr'"
        ></app-lead-activities-view>
        <app-lead-create-task
          [agents]="agents"
          (submitTask)="saveTask($event)"
          (cancelTask)="closeNewTaskWindow()"
          *ngIf="isTaskEditMode == true"
          [note]="activityToEdit"
          [currAgent]="agentUser"
        ></app-lead-create-task>
      </mat-card>
    </div>

    <div style="width: 20%; margin-top: 15px">
      <app-client-notes
        [classAttr]="'leadAttr'"
        [notes]="clientNotes"
        (openNewNote)="createNewNote($event)"
        (reloadNotes)="onReloadNotes()"
        *ngIf="isNoteEditMode == false && !isNewLead"
      >
      </app-client-notes>
      <app-client-add-note
        [note]="noteToEdit"
        [classAttr]="'leadAttr'"
        [statuses]="leadStatuses"
        (submitNote)="saveNote($event)"
        (cancelNote)="closeNewNoteWindow()"
        (reloadNotes)="onReloadNotes()"
        *ngIf="isNoteEditMode == true"
      >
      </app-client-add-note>
    </div>
  </div>
</div>
