import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodScaleValuesService {
  constructor(private http: HttpClient) {}

  getFoodScaleValues(foodId: number, langId: number) {
    return this.http.get<NameAndId[]>(
      `${environment.baseUrl}FoodScaleValues/ForFood/${foodId}/${langId}`
    );
  }
}
