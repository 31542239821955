<div class="weight-update">
  <div class="title">Caloric Deficit</div>
  <div class="mb-2"></div>
  <div class="d-grid-2">
    <div class="label">Deficit:</div>
    <mat-form-field appearance="fill">
      <input required type="number" matInput [(ngModel)]="deficit" />
    </mat-form-field>
  </div>
  <div class="mb-2"></div>
  <div class="d-flex text-center">
    <button class="btn btn-fill mr-2" (click)="save()" mat-button>Save</button>
    <button class="btn btn-outline" (click)="close()" mat-button>Cancel</button>
  </div>
</div>
