import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FgParamType } from 'src/app/data/food-genes/FgParamType';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { FoodAlert } from 'src/app/data/food-genes/FoodAlert';
import { PrototypeParam } from 'src/app/data/food-genes/PrototypeParam';
import { FgParamTypeEnum } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-create-param',
  templateUrl: './food-genes-create-param.component.html',
  styleUrls: ['./food-genes-create-param.component.scss'],
})
export class FoodGenesCreateParamComponent implements OnInit {
  addMode: 'new' | 'list';
  FgParamType = FgParamTypeEnum;
  selectedPrototype: PrototypeParam;
  parameter: PrototypeParam;
  selectionListTypes = [
    FgParamTypeEnum.UserDnaResult,
    FgParamTypeEnum.MaxCoefficient,
    FgParamTypeEnum.CoefficientMin,
    FgParamTypeEnum.CoefficientMax,
    FgParamTypeEnum.MinCoefficient,
    FgParamTypeEnum.FormulaResult,
  ];
  nutrients: BaseResponce[];
  alergans: BaseResponce[];
  bloodTest: BaseResponce[];
  alertParam: BaseResponce[];
  constructor(
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FoodGenesCreateParamComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      paramType: FgParamType;
      availableParams: PrototypeParam[];
      coefficients: FgCoefficient[];
      langId: number;
      versionId: number;
    }
  ) {}

  ngOnInit(): void {
    if (
      this.data?.availableParams?.length === 0 &&
      !this.selectionListTypes.includes(this.data.paramType.fgParamTypeId)
    ) {
      this.addMode = 'new';
      this.parameter = new FgParam();
    }
    if (this.selectionListTypes.includes(this.data.paramType.fgParamTypeId)) {
      this.addMode = 'list';
      this.parameter = new FgParam();
    }
    this.getDataByType();
  }

  onSelectProptotype(event: MatSelectChange) {
    const prototypeId = event.value;
    const prototype = this.data.availableParams.find(
      (x) => x.fgParamId === prototypeId
    );
    this.selectedPrototype = prototype;
    this.parameter = { ...prototype };
  }

  addModeChange() {
    if (this.addMode === 'list' /* && !this.prototypes */) {
      //this.getAvailableFormulaForVersion();
    } else {
      this.selectedPrototype = null;
      this.parameter = new PrototypeParam();
      this.parameter.fgParamId = 0;
    }
  }

  getDataByType() {
    switch (this.data.paramType.fgParamTypeId) {
      case FgParamTypeEnum.AlertParam:
        this.getAlergans();
        break;
      case FgParamTypeEnum.AlertTag:
        this.getAlertParam();
        break;
      case FgParamTypeEnum.BloodTest:
        this.getBloodTest();
        break;
      case FgParamTypeEnum.NutrientParam:
        this.getNutrients();
        break;
    }
  }

  getNutrients() {
    this.foodGenesService
      .getNutrientsForParams(this.data.langId)
      .subscribe((data) => (this.nutrients = data));
  }
  getAlergans() {
    this.foodGenesService
      .getAlergensForParams(this.data.langId)
      .subscribe((data) => (this.alergans = data));
  }
  getBloodTest() {
    this.foodGenesService
      .getBloodResultTypesForParams(this.data.langId)
      .subscribe((data) => (this.bloodTest = data));
  }
  getAlertParam() {
    this.foodGenesService
      .getTagsForParams(this.data.langId)
      .subscribe((data) => (this.alertParam = data));
  }
  onSelectAlertParam(event: MatSelectChange) {
    const alertId = event.value;
    const alert = this.alertParam.find((x) => x.id === alertId);
    this.parameter.fgParamName = alert.name;
  }
  onSelectAlergan(event: MatSelectChange) {
    const alerganId = event.value;
    const alergan = this.alergans.find((x) => x.id === alerganId);
    this.parameter.fgParamName = alergan.name;
  }
  onSelectNutrient(event: MatSelectChange) {
    const nutrientId = event.value;
    const nutrient = this.nutrients.find((x) => x.id === nutrientId);
    this.parameter.fgParamName = nutrient.name;
  }
  onSelectBloodTest(event: MatSelectChange) {
    const bloodTestId = event.value;
    const bloodTest = this.bloodTest.find((x) => x.id === bloodTestId);
    this.parameter.fgParamName = bloodTest.name;
  }

  create() {
    this.parameter.fgParamType = this.data.paramType.fgParamTypeId;

    this.parameter.fgParamName = this.parameter.fgParamName.replace(/ /g, '_');

    this.dialogRef.close({
      parameter: this.parameter,
      type: 'create',
    });

    /* this.foodGenesService
      .addParamToVersion(this.parameter, this.data.versionId)
      .subscribe((data) => {
        this.dialogRef.close(data);
      }); */
  }
}
