<div>
  <mat-list style="padding: 0" class="purchases-table" role="list">
    <!-- style="padding-top: 0; padding-right: 10px; padding-bottom: 6rem" -->
    <mat-list-item
      class="purchases-table__header"
      role="listitem"
      style="
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
      "
    >
      <!-- <span style="width: 2.5%">
      <mat-checkbox
        class="leadsTable__item__checkbox"
        (change)="checkAll($event)"
        [(ngModel)]="checkedAll"
      >
      </mat-checkbox>
    </span> -->
      <span style="width: 40px">Active:</span>
      <span style="width: 80px">Date: </span>
      <span style="width: 120px">Product:</span>

      <span style="width: 70px">Sum: </span>
      <span style="width: 150px">Status: </span>
      <span style="width: 80px"> P.Method:</span>
    </mat-list-item>
    <ng-container *ngFor="let bundle of clientDealBundles">
      <mat-accordion *ngIf="showBundleExpansionPanel(bundle)">
        <!-- *ngIf="ifIncompletePayment(transItem); else isCompletePayment"-->
        <mat-expansion-panel
          style="padding: 1rem 0; margin-bottom: 10px; padding-right: 1rem"
        >
          <mat-expansion-panel-header
            class="leadsTable__item"
            style="padding: 0"
          >
            <app-deal-bundle-thumb
              [dealBundle]="bundle"
              [statuses]="statuses"
            ></app-deal-bundle-thumb>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let deal of bundle.deals"
            ><!--transItem.dealBundle-->
            <p>
              <app-deal-thumb
                style="cursor: pointer"
                [deal]="deal"
                [statuses]="statuses"
                [products]="bundle.products"
                (click)="onRowClick(deal)"
                [isInner]="true"
              ></app-deal-thumb>
            </p>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>

      <ng-container *ngIf="!showBundleExpansionPanel(bundle)">
        <app-deal-thumb
          style="cursor: pointer"
          [deal]="bundle.deals[0]"
          [statuses]="statuses"
          [products]="bundle.products"
          (click)="onRowClick(bundle.deals[0])"
          [isInner]="true"
        ></app-deal-thumb>
      </ng-container>
    </ng-container>
  </mat-list>
  <!--
  <table style="width: 100%" mat-table [dataSource]="clientDealBundles">
    <ng-container matColumnDef="active">
      <th style="width: 8%; padding-left: 0" mat-header-cell *matHeaderCellDef>
        Active:
      </th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; padding-left: 0"
      >
        <img
          *ngIf="deal.hasActiveCancelationRequest || deal.statusId == 192"
          width="20"
          src="../../../assets/active-cancel.svg"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th style="width: 18%" mat-header-cell *matHeaderCellDef>Date:</th>
      <td mat-cell *matCellDef="let deal" style="cursor: pointer">
        {{ deal.dateCreated | date: "dd-MM-yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th style="width: 20%" mat-header-cell *matHeaderCellDef>Product:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="text-align: center; cursor: pointer"
      >
        {{ getProductsStr(deal.products) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sum">
      <th style="width: 10%" mat-header-cell *matHeaderCellDef>Sum:</th>
      <td mat-cell *matCellDef="let deal" style="cursor: pointer">
        {{ deal.amount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th style="width: 25%" mat-header-cell *matHeaderCellDef>Status:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; align-items: center"
      >
        <span
          class="round-indicator"
          style="font-size: 1.3rem; text-transform: uppercase"
          [ngStyle]="{ 'background-color': getDealStatusColor(deal) }"
        >
          {{ getDealStatus(deal) }}
        </span>
        <div
          style="color: #e91779; font-size: 1.3rem"
          *ngIf="getDealLeftToPay(deal)"
        >
          <strong>₪{{ getDealLeftToPay(deal) }}</strong> left to pay
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pMethod">
      <th style="width: 25%" mat-header-cell *matHeaderCellDef>P.Method:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; font-size: 1.3rem; line-height: 1.2"
      >
        {{ deal.paymentMethod && deal.paymentMethod.name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dealsDisplayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: dealsDisplayedColumns"
      (click)="onRowClick(row)"
      style="position: relative; height: 60px"
    ></tr>
  </table>
-->
  <ng-container *ngIf="!inCancellation">
    <ng-container *ngIf="showPurchaseBtn()">
      <div
        class="purchases-action"
        style="
          position: sticky;
          bottom: 0;
          background-color: #fff;
          padding-bottom: 0;
        "
      >
        <button class="purchases-action__btn" (click)="openNewPurchase()">
          Make New Purchase
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!showPurchaseBtn()">
      <div class="purchases-action">
        <span style="font-size: 16px; font-weight: 500"
          >To make a purchase you need to be the Agent of this Client</span
        >
      </div>
    </ng-container>
  </ng-container>
</div>
