import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoBullet } from '../data/AlgoBullet';
import { AlgoBulletMin } from '../data/AlgoBulletMin';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

@Injectable({
  providedIn: 'root',
})
export class AlgoBulletService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getAll(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }AlgoBullets/Filter/${this.httpFilterHelper.createAlgoBulletPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getByBulletId(id: number) {
    return this.http.get<AlgoBullet>(`${environment.baseUrl}AlgoBullets/${id}`);
  }
  put(algoBullet: AlgoBullet) {
    return this.http.put<any>(
      `${environment.baseUrl}AlgoBullets/${algoBullet.algoBulletId}`,
      algoBullet
    );
  }
  post(algoBullet: AlgoBullet) {
    return this.http.post<any>(
      `${environment.baseUrl}AlgoBullets/`,
      algoBullet
    );
  }
  delete(algoBulletId: any) {
    return this.http.delete<any>(
      `${environment.baseUrl}AlgoBullets/${algoBulletId}`
    );
  }
  updateReports() {
    return this.http.post<any>(
      `${environment.baseUrl}AlgoBullets/ReCreatingAlgoBullet/`,
      {}
    );
  }

  setBulletsOrder(algoBullets: AlgoBullet[]) {
    return this.http.post<any>(
      `${environment.baseUrl}AlgoBullets/setBulletsOrder`,
      algoBullets
    );
  }

  setBulletsImage(imageId: number, algoBulletIds: number[]) {
    return this.http.put<any>(
      `${environment.baseUrl}AlgoBullets/SetAlgoBulletsImage/${imageId}`,
      algoBulletIds
    );
  }
}
