import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentMin } from 'src/app/data/AgentMin';
import { Client } from 'src/app/data/Client';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { LeadsServiceInterface } from 'src/app/serviceInterfaces/LeadsServiceInterface';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisionsWithRule } from '../../Utils/user-helper';

@Component({
  selector: 'app-leads-table',
  templateUrl: './leads-table.component.html',
  styleUrls: ['./leads-table.component.scss'],
})
export class LeadsTableComponent implements OnInit, OnChanges {
  isSearchPage: boolean;
  @Input() checkedAll: boolean = false;
  @Input() isSearch: boolean = false;
  @Input() leadsData: ClientForTableMin[];
  @Input() leadStatuses: LeadStatus[];
  @Input() leadSources: LeadSource[];
  @Input() leadsServiceInject: LeadsServiceInterface;
  @Input() agents: AgentMin[];
  @Input() headFilter: HeadFilter;
  @Input() isLoading;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  @Output('checkedIdsChanged')
  checkedIdsChanged: EventEmitter<any> = new EventEmitter();
  @Input() metaData: PaginationMetadata;
  @Input() checkedIds: any;
  headers = [
    { name: 'Full Name', val: 'Name', width: '11.3%' },
    { name: 'Email', val: 'Email', width: '15%' },
    { name: 'Tel', val: 'Tel', width: '9%' },
    { name: 'Source', val: 'Source', width: '100px' },
    {
      name: 'Created Date',
      val: 'RegisteredDate',
      width: '9%',
    },
    {
      name: 'Updated Date',
      val: 'UpdatedDate',
      width: '9%',
    },
    { name: 'Agent', val: 'Agent', width: '11.3%' },
    { name: 'Status', val: 'Status', width: '11.3%' },
    { name: 'Tasks', val: 'Tasks', width: '2.5%' },

    //{ name: 'Client Type', val: 'ClientType', width: '11.6%' },
  ];
  regularUser: RegularUser;
  //agentForAC: string[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    //private leadsPusherService: LeadPusherService,
    //private leadsService: LeadsService,
    private regularUsersService: RegularUsersService
  ) {
    this.checkedIds = {};
    if (this.leadsServiceInject?.leads$) {
      this.leadsServiceInject.leads$.subscribe((leads) => {});
    }

    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
    var isSearchPage = this.route.snapshot.queryParamMap.get('searchTerm');
    this.isSearchPage = isSearchPage ? true : false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.leadsData)
      this.leadsData.forEach((client) => {
        if (this.checkedIds[client.clientId]) client.isChecked = true;
      });
  }
  onRowClicked(event, leadItem: ClientForTableMin) {
    // this.moveToEditScreen(leadItem.clientId);
  }
  ngOnInit(): void {
    //this.agentForAC = this.agents.map((agent) => agent.name);
    this.leadStatuses = this.leadStatuses.filter((x) => x.leadStatusId !== -1);

    //this.
  }

  /* getPhoneSettings() {
    var address = this.patient.regularUser.getAdressObj(AdressTypeEnum.Main);
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
        this.createFormGroup();
      });
  } */
  callLeadPhone(phone) {
    // window.open('tel:' + phone);
  }

  canChangeAgent(agentId = null) {
    return (
      userHasPermisionsWithRule(['Leads_ChangeAgent'], this.regularUser) ||
      (userHasPermisionsWithRule(
        ['Leads_ChangeAgent_NoTargetAgent'],
        this.regularUser
      ) &&
        agentId == 1)
    );
  }
  canChangeBulk() {
    return userHasPermisionsWithRule(
      ['Leads_ChangeAgentBulk', 'Leads_ChangeStatusBulk'],
      this.regularUser
    );
  }
  showStatusFilter(agentId = null) {
    return (
      (agentId == this.regularUser.agent.agentId &&
        userHasPermisionsWithRule(
          ['Leads_ChangeStatus_IsTargetAgent'],
          this.regularUser
        )) ||
      userHasPermisionsWithRule(['Leads_ChangeStatus'], this.regularUser)
    );
  }
  showEditButton(agentId = null) {
    return (
      (agentId == this.regularUser.agent.agentId &&
        userHasPermisionsWithRule(
          ['Leads_GetSingle_IsTargetAgent'],
          this.regularUser
        )) ||
      userHasPermisionsWithRule(['Leads_GetSingle'], this.regularUser)
    );
  }
  isChecked(event, clientId, checked) {
    if (checked) {
      this.checkedIds[clientId] = true;
    } else {
      delete this.checkedIds[clientId];
    }
  }
  checkObjLength(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }
  /*   displaySideEdit() {
    if (this.checkObjLength(this.checkedIds) >= 2) {
      return true;
    }
    return false;
  } */
  moveToEditScreen(clientId = -1) {
    this.router.navigate(['/leads/edit/'], {
      queryParams: { clientId: clientId },
    });
  }
  editLead(event, leadItem: ClientForTableMin) {
    event.stopPropagation();
    this.moveToEditScreen(leadItem.clientId);
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveAgentForLead(event, lead: ClientForTableMin) {
    if (!lead) return;
    var oldAgentId = lead.agent.agentId;
    lead.agent.agentId = event;
    //lead.agent = this.agents.find((x) => x.agentId == event);

    setTimeout(() => {
      this.leadsServiceInject.changeAgent(lead).subscribe(
        (data) => {
          if ((data as ErrorMessage)?.message) {
            var _data = data as ErrorMessage;
            lead.agent.agentId = oldAgentId;
            this.openConfirmChangDialog(null, 'Error: ' + _data.message);
          } else {
            this.openConfirmChangDialog(null, 'agent');
            this.filterChanged.emit(this.headFilter);
          }
        },
        (error) => {}
      );
    }, 10);
  }
  getAgentNameById(agentId) {
    return this.agents?.find((x) => x.agentId == agentId)?.name;
  }
  /*   getAgentNameById(agentId) {
    return this.agents.find((a) => a.agentId === agentId).name;
  } */

  getLeadStatusRecurs(statusId) {
    for (let index = 0; index < this.leadStatuses.length; index++) {
      const element = this.leadStatuses[index];
      if (!element.childerns || element.childerns.length == 0) {
        if (element.leadStatusId == statusId) {
          return element;
        }
      } else {
        for (let index = 0; index < element.childerns.length; index++) {
          const child = element.childerns[index];
          if (child.leadStatusId == statusId) {
            return child;
          }
        }
      }
    }
  }

  saveStatusForLead(item: LeadStatus /* event */, lead: ClientForTableMin) {
    lead.leadStatusId = item.leadStatusId;
    //lead.leadStatusId = event.value;
    lead.leadStatus = this.getLeadStatusRecurs(item.leadStatusId);
    //lead.status = null;
    setTimeout(() => {
      this.leadsServiceInject.changeStatusGet(lead).subscribe((data) => {});

      this.openConfirmChangDialog(null, 'status');
      this.filterChanged.emit(this.headFilter);
    }, 10);
  }

  openConfirmChangDialog(lead: Client = null, type = '', from = '', to = '') {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: lead,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      var a = result;
    });
  }

  changeBulkAgent(event) {
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    var checkedIdsArr = Object.keys(this.checkedIds).map(function (key) {
      return Number(key);
    });
    this.leadsServiceInject.bulkChangeAgent(checkedIdsArr, agentId);
    this.checkedIds = {};
    this.openConfirmChangDialog(null, 'agent');
    /*
    var changedLeads: ClientForTableMin[] = [];
    var agentId = Number(event.target.value);
    if (agentId == -1) return;
    for (var id in this.checkedIds) {
      this.leadsData.forEach((client) => {
        if (client.clientId == Number(id)) {
          var newClient = { ...client };
          newClient.agent.agentId = agentId;
          newClient.agent = null;
          changedLeads.push(newClient);
        }
      });
    }
    this.openConfirmChangDialog(null, 'bulkAgents');
    this.leadsPusherService.bulkPut(changedLeads);
    */

    this.checkedIds = [];
    this.checkedIdsChanged.next(this.checkedIds);
    this.checkedAll = false;
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }
  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }

  checkAll(event) {
    var isChecked = event.checked;

    this.leadsData.forEach((client) => {
      if (isChecked) {
        this.checkedIds[client.clientId] = true;
        client.isChecked = true;
      } else {
        delete this.checkedIds[client.clientId];
        client.isChecked = false;
      }
    });
  }
  getCheckedCount() {
    var cnt = 0;

    for (var inx in this.checkedIds) {
      cnt++;
    }
    return cnt;
  }

  getStatusName(leadStatuses: LeadStatus[], leadItem: ClientForTableMin) {
    for (let index = 0; index < leadStatuses.length; index++) {
      const element = leadStatuses[index];
      if (!element.childerns || element.childerns.length == 0) {
        if (element.leadStatusId == leadItem.leadStatusId) {
          return element.name;
        }
      } else {
        for (let index = 0; index < element.childerns.length; index++) {
          const child = element.childerns[index];
          if (child.leadStatusId == leadItem.leadStatusId) {
            return child.name;
          }
        }
      }
    }
  }
}
