import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { FoodReportRequest } from 'src/app/data/food-genes/FoodReportRequest';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { Status } from 'src/app/data/Status';
import { ServiceRequestTabs } from 'src/app/Enums/StatusesEnum';
import { FIREBASE_REFERENCES } from 'src/app/firebase.module';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { FoodClientRequestService } from 'src/app/Services/food-client-request.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestsPusherService } from 'src/app/Services/service-requests-pusher.service';

@Component({
  selector: 'app-service-request-table',
  templateUrl: './service-request-table.component.html',
  styleUrls: ['./service-request-table.component.scss'],
})
export class ServiceRequestTableComponent implements OnInit {
  @Input() serviceRequests: ServiceRequest;
  @Input() headFilter: HeadFilter;
  @Input() agents: AgentMin[];
  @Input() requestsStatuses: Status[];
  @Input() isLoading: boolean;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  @Output('appReportClicked')
  appReportClicked: EventEmitter<boolean> = new EventEmitter();
  request: RequestForTableMin;
  tabInx: number = 0;
  chatIndicatorCrm: any;
  agentForAC: string[];
  chatIndicatorCrmOld: any;
  ServiceRequestTabs = ServiceRequestTabs;
  searchTerm;
  currentUser: RegularUser;

  constructor(
    private serviceRequestsPusherService: ServiceRequestsPusherService,
    private foodClientRequestService: FoodClientRequestService,
    private popupHandlerService: PopupHandlerService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(FIREBASE_REFERENCES.CHAT_FIRESTORE)
    private readonly firestore: AngularFirestore,
    private firebaseChatIndicatorCrm: FirebaseChatIndicatorService,
    private regularUsersService: RegularUsersService
  ) {}
  headers = [
    {
      name: 'Received',
      val: 'RegisteredDate',
      width: '',
      showInAppReports: true,
    },
    { name: 'Name', val: 'Name', width: '', showInAppReports: true },
    { name: 'Tel', val: 'Tel', width: '', showInAppReports: true },
    { name: 'Subject', val: 'Subject', width: '', showInAppReports: true },
    { name: 'Opened By', val: 'openedBy', width: '', showInAppReports: true },
    { name: 'Agent', val: 'Agent', width: '', showInAppReports: true },
    { name: 'Attempts', val: 'Attempts', width: '', showInAppReports: true },
    { name: 'Status', val: 'Status', width: '', showInAppReports: true },
    { name: '', val: '', width: '10%', showInAppReports: true },
    { name: 'Comments', val: 'Comments', width: '11%', showInAppReports: true },
  ];
  internalHeaders = [
    { name: 'Received', val: 'RegisteredDate', width: '' },
    { name: 'Name', val: 'Name', width: '' },
    { name: 'Subject', val: 'Subject', width: '' },
    { name: 'Agent', val: 'Agent', width: '' },
    { name: 'Status', val: 'Status', width: '' },
  ];
  ngOnInit(): void {
    this.firebaseChatIndicatorCrm.chatIndicatorCrm$.subscribe((data) => {
      if (data) {
        if (this.chatIndicatorCrm) {
          this.chatIndicatorCrmOld = { ...this.chatIndicatorCrm };
        }
        this.chatIndicatorCrm = {};
        data.forEach((x) => {
          this.chatIndicatorCrm[x.payload.doc.id] = x.payload.doc.data();
        });
        if (this.chatIndicatorCrmOld) {
          var hasNewRequests = false;
          for (const reqId in this.chatIndicatorCrm) {
            if (!this.chatIndicatorCrmOld[reqId]) {
              hasNewRequests = true;
            }
          }
          if (hasNewRequests) {
            this.filterChanged.emit(this.headFilter);
          }
        }
      }
    });

    var a = this.requestsStatuses;
  }
  classActiveChatIndicatorCrm(serviceRequestId) {
    this.chatIndicatorCrm &&
      this.chatIndicatorCrm[serviceRequestId.toString()] &&
      this.chatIndicatorCrm[serviceRequestId.toString()].val > 0;
  }
  showChatIndicatorCrm(serviceRequestId) {
    if (this.chatIndicatorCrm) {
      (this.chatIndicatorCrm &&
        !this.chatIndicatorCrm[serviceRequestId.toString()]) ||
        this.chatIndicatorCrm[serviceRequestId.toString()].val == 0;
    }
  }
  selectedAgent(event, request: RequestForTableMin | FoodReportRequest) {
    const sr = request as RequestForTableMin;
    const cr = request as FoodReportRequest;
    if (sr.serviceRequestId) {
      sr.assignedAgent.agentId = event;
      sr.assignedAgent.name = this.agents.find(
        (x) => x.agentId == sr.assignedAgent.agentId
      ).name;
      setTimeout(() => {
        this.serviceRequestsPusherService.putMin(sr);
        this.openConfirmChangDialog(null, 'requestAgent');
      }, 10);
    } else {
      cr.assignedAgentId = event;
      this.foodClientRequestService
        .setFoodRequestAgent(cr.clientRequestId, event)
        .subscribe((response) => {
          this.openConfirmChangDialog(null, 'requestAgent');
        });
    }
  }
  getNumUnwatchedComments() {}
  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }

    this.filterChanged.emit(this.headFilter);
  }
  setAttempt(request: RequestForTableMin | FoodReportRequest) {
    this.popupHandlerService.openConfirmationDialog('', 'requests');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes' && this.request) {
          this.request.numAttempts++;
          if (
            this.headFilter?.categoryId == this.ServiceRequestTabs.AppReports ||
            this.headFilter?.categoryId ==
              this.ServiceRequestTabs.AppCustomerRequests
          ) {
            this.foodClientRequestService
              .setFoodRequestAttempt(
                (request as FoodReportRequest).clientRequestId,
                this.request.numAttempts
              )
              .subscribe((data) => {});
          } else {
            this.serviceRequestsPusherService.putMin(this.request);
          }
        }
      });
    this.request = request as RequestForTableMin;
  }
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveAgentForRequest(event, request: RequestForTableMin) {
    if (!request) return;
    request.assignedAgent.agentId = Number(event.value);
    //lead.agent = null;

    setTimeout(() => {
      this.serviceRequestsPusherService.putMin(request);
      this.openConfirmChangDialog(null, 'requestAgent');
    }, 10);
  }
  saveStatusForRequest(event, request: RequestForTableMin | FoodReportRequest) {
    if (!request) return;
    request.statusId = Number(event.value);
    setTimeout(() => {
      if (
        this.headFilter?.categoryId == this.ServiceRequestTabs.AppReports ||
        this.headFilter?.categoryId ==
          this.ServiceRequestTabs.AppCustomerRequests
      ) {
        this.foodClientRequestService
          .setFoodRequestStatus(
            (request as FoodReportRequest).clientRequestId,
            request.statusId
          )
          .subscribe((data) => {
            this.openConfirmChangDialog(null, 'requestStatus');
          });
      } else {
        this.serviceRequestsPusherService.putMin(request as RequestForTableMin);
        this.openConfirmChangDialog(null, 'requestStatus');
      }
    }, 10);
  }
  moveToEditScreen(serviceRequestId = -1) {
    if (this.checkAuthorization()) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/requests/edit/'], {
          queryParams: {
            serviceRequestId: serviceRequestId,
            appReport:
              this.headFilter?.categoryId ==
                this.ServiceRequestTabs.AppReports ||
              this.headFilter?.categoryId ==
                this.ServiceRequestTabs.AppCustomerRequests,
          },
        })
      );
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${this.currentUser.token}`);
      const authorizedUrl = new Request(url, { headers });
      window.open(authorizedUrl.url, '_blank');
    } else {
      console.error('Unauthorized access');
    }
  }

  checkAuthorization() {
    this.currentUser = this.regularUsersService.currentUserValue;
    return this.currentUser && this.currentUser.token;
  }

  editRequest(event, request: RequestForTableMin | FoodReportRequest) {
    event.stopPropagation();
    const { serviceRequestId } = request as RequestForTableMin;
    const { clientRequestId } = request as FoodReportRequest;
    const id = serviceRequestId || clientRequestId;
    this.moveToEditScreen(id);
  }
  sendToManager(request: RequestForTableMin) {
    if (!request) return;
    request.forManager = true;
    setTimeout(() => {
      this.serviceRequestsPusherService.putMin(request);
      this.openConfirmChangDialog(null, 'requestManager');
    }, 10);
  }
  openConfirmChangDialog(
    request: ServiceRequest = null,
    type = '',
    from = '',
    to = ''
  ) {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        client: request,
        type: type,
        from: from,
        to: to,
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      var a = result;
    });
  }
  changeTab(categoryId, onlyManager) {
    this.headFilter.categoryId = categoryId;
    this.headFilter.onlyManager = onlyManager;
    if (
      this.headFilter.categoryId == this.ServiceRequestTabs.AppReports ||
      this.headFilter.categoryId == this.ServiceRequestTabs.AppCustomerRequests
    ) {
      this.serviceRequestsPusherService.setMode('appReports');
      const type =
        this.headFilter.categoryId == this.ServiceRequestTabs.AppReports
          ? 1
          : 2;
      this.headFilter.type = type;
      this.headFilter.langId = 1;
      this.headFilter.statusId = 0;
      this.headFilter.subjectId = 0;
      this.headFilter.agentId = 0;
      this.headers.find((x) => x.val == 'openedBy').showInAppReports = false;
      this.appReportClicked.emit(true);
    } else {
      this.serviceRequestsPusherService.setMode('all');
      this.headers.find((x) => x.val == 'openedBy').showInAppReports = true;
      this.headFilter.statusId = -1;
      this.headFilter.subjectId = -1;
      this.headFilter.agentId = -1;
      this.appReportClicked.emit(false);
    }
    this.filterChanged.emit(this.headFilter);
  }
  onSearchBtnClick() {
    this.filterChanged.emit(this.headFilter);
  }
  onSearchFieldInput() {}
  userHasPermisions(a) {}
  getRequestSubject(request: RequestForTableMin) {
    if (request.subjectId) {
      return request.subject?.name;
    } else {
      return 'no subject';
    }
  }
  getNumComments(serviceRequestId) {
    if (
      this.chatIndicatorCrm &&
      this.chatIndicatorCrm[serviceRequestId.toString()]
    )
      var a = this.chatIndicatorCrm[serviceRequestId.toString()].val;
    return (
      this.chatIndicatorCrm &&
      this.chatIndicatorCrm[serviceRequestId] &&
      this.chatIndicatorCrm[serviceRequestId.toString()].val
    );
  }

  getNoteCount(request: RequestForTableMin | FoodReportRequest) {
    const { numComments } = request as RequestForTableMin;
    const { notesCount } = request as FoodReportRequest;
    if (numComments) {
      return numComments;
    } else {
      return notesCount;
    }
  }
  getAssignedAgentName(request: RequestForTableMin | FoodReportRequest) {
    const { assignedAgent } = request as RequestForTableMin;
    const { assignedAgentId } = request as FoodReportRequest;
    if (assignedAgent) {
      return assignedAgent.name;
    } else {
      return this.agents.find((x) => x.agentId == assignedAgentId)?.name;
    }
  }
}
