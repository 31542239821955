import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { AgentForTableMin } from '../data/AgentForTableMin';
import { Agent } from '../data/Agent';
import { AgentForTableMinV2 } from '../data/AgentForTableMinV2';

@Injectable({
  providedIn: 'root',
})
export class ManageAgentsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<AgentForTableMin[]>(
      `${environment.baseUrl}ManageAgents/`
    );
  }
  getbyFilter(roleTypeId) {
    return this.http.get<AgentForTableMinV2[]>(
      `${environment.baseUrl}ManageAgents/FilterV2/${roleTypeId}`
    );
  }
  getById(agentId) {
    return this.http.get<Agent>(
      `${environment.baseUrl}ManageAgents/${agentId}`
    );
  }
  put(agent) {
    return this.http.put(
      `${environment.baseUrl}ManageAgents/${agent.agentId}`,
      agent
    );
  }
  post(agent) {
    return this.http.post<Agent>(`${environment.baseUrl}ManageAgents/`, agent);
  }
  delete(agent) {
    return this.http.delete(
      `${environment.baseUrl}ManageAgents/${agent.agentId}`
    );
  }
  changeRole(agent: AgentForTableMin) {
    return this.http.put(
      `${environment.baseUrl}ManageAgents/ChangeRole/${agent.agentId}`,
      agent
    );
  }
  changeRoleV2(agent: AgentForTableMinV2) {
    return this.http.put(
      `${environment.baseUrl}ManageAgents/ChangeRoleV2/${agent.agentId}`,
      agent
    );
  }
}
