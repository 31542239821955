import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { AlertDialogTypeEnum } from 'src/app/Enums/AlertDialogTypeEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideoCategoriesService } from 'src/app/Services/video-categories.service';
import { VideosService } from 'src/app/Services/videos.service';

@Component({
  selector: 'app-video-categories',
  templateUrl: './video-categories.component.html',
  styleUrls: ['./video-categories.component.scss'],
})
export class VideoCategoriesComponent implements OnInit {
  @ViewChildren('categoryName')
  categoryName: QueryList<MatFormField>;
  videoCategories: VideoCategory[];
  catToEdit: number;
  editMode: boolean;
  changed: boolean;
  constructor(
    public dialogRef: MatDialogRef<VideoCategoriesComponent>,
    private videosService: VideosService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories(changed = false) {
    this.videosService.getAllVideoCategories().subscribe((data) => {
      this.videoCategories = data;
      this.editMode = false;
      this.catToEdit = null;
      this.changed = changed;
    });
  }

  addNewCategory() {
    let category = new VideoCategory();
    category.videoCategoryId = 0;
    this.editMode = true;
    this.catToEdit = category.videoCategoryId;
    this.videoCategories.push(category);
  }

  toggleEditMode(category: VideoCategory) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.catToEdit = category.videoCategoryId;
    } else {
      this.getAllCategories();
    }
  }

  isEditMode(id: number) {
    return this.editMode && this.catToEdit == id;
  }

  save(category: VideoCategory) {
    if (!category.categoryName) {
      this.categoryName
        .toArray()[0]
        ._elementRef.nativeElement.classList.add('error');
      return;
    }
    const isChanged = true;
    if (category.videoCategoryId > 0) {
      this.videosService.updateCategory(category).subscribe((data) => {
        this.getAllCategories(isChanged);
      });
    } else {
      this.videosService.insertCategory(category).subscribe((data) => {
        this.getAllCategories(isChanged);
      });
    }
  }

  delete(category: VideoCategory) {
    this.videosService.getAllVideoCategories().subscribe((data) => {
      this.videoCategories = data;
      const thisCategory = this.videoCategories.find(
        (x) => x.videoCategoryId == category.videoCategoryId
      );
      if (thisCategory.videoCount >= 1) {
        this.popupHandlerService.openAlertDialog(
          AlertDialogTypeEnum.videoCategories,
          'Error!'
        );
      } else {
        this.popupHandlerService.openConfirmationDialog(null, 'Delete');
        this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
          if (data.ans === 'yes') {
            const isChanged = true;
            this.videosService
              .deleteCategory(category.videoCategoryId)
              .subscribe((data) => this.getAllCategories(isChanged));
          }
        });
      }
    });
  }

  close() {
    this.dialogRef.close({ isChanged: this.changed });
  }
}
