import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { Client } from 'src/app/data/Client';
import { BulkChangeProductAgent } from 'src/app/data/connectors/BulkChangeProductAgent';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';

import { AgentsService } from 'src/app/Services/agents.service';
import { ClientsService } from 'src/app/Services/clients.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { LeadStasusesService } from 'src/app/Services/lead-stasuses.service';
import { LeadsService } from 'src/app/Services/leads.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { TestsConnectorService } from 'src/app/Services/tests-connector.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  clientsHeadFilter: HeadFilter = this.filterMemoryService.getDefaultFilter();
  leadsHeadFilter: HeadFilter = this.filterMemoryService.getDefaultFilter();
  testsHeadFilter: HeadFilter = this.filterMemoryService.getDefaultFilter();

  leadsMetaData: PaginationMetadata;
  clientsMetaData: PaginationMetadata;
  productsMetaData: PaginationMetadata;
  leadsData: Client[];
  leadStatuses: LeadStatus[];
  agents: AgentMin[];
  leadSources: LeadSource[];
  //statuses;
  checkedIds: any;
  picker;
  date;
  clientsData: Client[];
  productsData: ProductsService[];
  searchTerm: any;
  statuses: Status[];
  nutritionists: AgentMin[];
  followUpAgents: AgentMin[];
  regularUser: RegularUser;
  leadStatusesGroup: LeadStatus[];
  isClientsLoading: boolean;
  isTestsLoading: boolean;
  isLeadsLoading: boolean;
  testsCheckedIds: any = {};
  testsCheckedAll: boolean = false;
  leadsCheckedIds: any = {};
  leadsCheckedAll: boolean = false;
  leadsStatusesForBulk: LeadStatus[];

  //tabInx: number = 0;
  constructor(
    public leadsService: LeadsService,
    private agentsService: AgentsService,
    private leadStasusesService: LeadStasusesService,
    private leadSourcesService: LeadSourcesService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private statusesService: StatusesService,
    private regularUsersService: RegularUsersService,
    private filterMemoryService: FilterMemoryService,
    private testConnectorService: TestsConnectorService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;

      this.route.queryParams.subscribe((params) => {
        this.searchTerm = encodeURI(params['searchTerm']);
        if (userHasPermisions(['Leads_Search'], this.regularUser))
          this.leadsSearch();
        if (userHasPermisions(['Clients_Search'], this.regularUser))
          this.clientsSearch();
        if (userHasPermisions(['Products_Search'], this.regularUser))
          this.testsSearch();
      });
    });
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
      this.nutritionists = this.agents.filter(
        (x) =>
          x.roleTypeId == UserRoleEmum.nutritionist ||
          x.roleTypeId == UserRoleEmum.nutritionistManager
      );
      this.followUpAgents = data.filter(
        (x) => x.roleTypeId == UserRoleEmum.followUpAgent
      );
    });
    this.testConnectorService.bulkChangeProductAgentSubject.subscribe(
      (data) => {
        if (data) {
          this.popupHandlerService.openConfirmationDialog(
            '',
            'Transfer Missions?'
          );
          this.popupHandlerService.confirmDialogSubject
            .pipe(take(1))
            .subscribe((_data) => {
              if (_data.ans === 'yes') {
                this.bulkChangeAgent(data, true);
              } else if (_data.ans === 'no') {
                this.bulkChangeAgent(data, false);
              }
            });
        }
      }
    );
    this.leadStasusesService.getAllForMenu().subscribe((data) => {
      this.leadStatuses = data;
      this.leadStatuses = this.leadStatuses.filter((x) => {
        return x.leadStatusId != 15 && x.leadStatusId != 16;
      });
    });
    this.leadStasusesService.getAll().subscribe((data) => {
      this.leadsStatusesForBulk = data;
    });
    this.leadSourcesService.getAll().subscribe((data) => {
      this.leadSources = data;
    });
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data;
      this.statuses = this.statuses.filter((status) => {
        return status.category === 'Product';
      });
    });
  }
  bulkChangeAgent(data: BulkChangeProductAgent, transferMissions: boolean) {
    this.productsService
      .bulkChangeAgent2(
        data.productIds,
        data.agentId,
        data.isAgent2,
        transferMissions
      )
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog('agent');
        this.testsGetAllWithFilter(this.testsHeadFilter);
      });
  }
  userHasPermission(permissions: string[]) {
    return userHasPermisions(permissions, this.regularUser);
  }
  changeTestsCheckedIds(e) {
    this.testsCheckedIds = e;
  }
  onCheckedTestsIdsChanged(checkedIds) {
    this.testsCheckedIds = checkedIds;

    this.testsCheckedAll = true;

    setTimeout(() => {
      this.testsCheckedAll = false;
    }, 10);
  }
  changeLeadsCheckedIds(e) {
    this.leadsCheckedIds = e;
  }
  onCheckedLeadsIdsChanged(checkedIds) {
    this.leadsCheckedIds = checkedIds;

    this.leadsCheckedAll = true;

    setTimeout(() => {
      this.leadsCheckedAll = false;
    }, 10);
  }
  setHeadFilterByMetaData(
    metaData: PaginationMetadata,
    headFilter: HeadFilter
  ) {
    headFilter.pageSize = metaData.pageSize;
    headFilter.pageNumber = metaData.currentPage;
    return headFilter;
  }
  leadsSearch(pageSize = 10, pageNumber = 1) {
    this.isLeadsLoading = true;
    this.leadsService
      .searchV2(this.searchTerm, 'date', true, pageSize, pageNumber)
      .subscribe((data) => {
        this.isLeadsLoading = false;
        this.leadsData = data.list;
        this.leadsMetaData = data.metaData;
        this.leadsHeadFilter = this.setHeadFilterByMetaData(
          data.metaData,
          this.leadsHeadFilter
        );
      });
  }
  clientsSearch(pageSize = 10, pageNumber = 1) {
    this.isClientsLoading = true;
    this.clientsService
      .searchV2(this.searchTerm, 'date', true, pageSize, pageNumber)
      .subscribe((data) => {
        this.isClientsLoading = false;
        this.clientsData = data.list;
        this.clientsMetaData = data.metaData;
        this.clientsHeadFilter = this.setHeadFilterByMetaData(
          data.metaData,
          this.clientsHeadFilter
        );
      });
  }
  testsSearch(pageSize = 10, pageNumber = 1) {
    this.isTestsLoading = true;
    this.productsService
      .searchV2(this.searchTerm, 'date', true, pageSize, pageNumber)
      .subscribe((data) => {
        this.isTestsLoading = false;
        this.productsData = data.list;
        this.productsMetaData = data.metaData;
        this.testsHeadFilter = this.setHeadFilterByMetaData(
          data.metaData,
          this.testsHeadFilter
        );
      });
  }
  clientGetAllWithFilter(e) {
    this.leadsHeadFilter = e;
    var { pageNumber, pageSize } = this.leadsHeadFilter;
    this.clientsSearch(pageSize, pageNumber);
  }
  leadsGetAllWithFilter(e) {
    this.clientsHeadFilter = e;
    var { pageNumber, pageSize } = this.clientsHeadFilter;
    this.leadsSearch(pageSize, pageNumber);
  }
  testsGetAllWithFilter(e) {
    this.testsHeadFilter = e;
    var { pageNumber, pageSize } = this.testsHeadFilter;
    this.testsSearch(pageSize, pageNumber);
  }
}
