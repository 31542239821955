<form
  *ngIf="resultTypeForm"
  class="form"
  (ngSubmit)="onSubmit()"
  [formGroup]="resultTypeForm"
>
  <div class="label">בחירת נטייה</div>
  <mat-radio-group
    class="add-mode-radio-group"
    [(ngModel)]="addMode"
    [ngModelOptions]="{ standalone: true }"
    (change)="onChangeMode($event)"
  >
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromInput"
    >
      חדש
    </mat-radio-button>
    <mat-radio-button
      class="add-mode-radio-button"
      [value]="AddModeEnum.FromList"
    >
      מתוך רשימה
    </mat-radio-button>
  </mat-radio-group>
  <div class="mb-2" [ngSwitch]="addMode">
    <div class="label" style="margin-bottom: 5px">שם נטייה</div>
    <div *ngSwitchCase="AddModeEnum.FromList">
      <div>
        <mat-form-field class="field" appearance="fill">
          <input
            placeholder="בחר מתוך הרשימה"
            type="text"
            matInput
            formControlName="name"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            (optionSelected)="onOptionSelected($event)"
            class="cat-panel"
            #auto="matAutocomplete"
          >
            <mat-option
              *ngFor="let item of filteredOptions | async"
              [value]="item.name"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="AddModeEnum.FromInput">
      <mat-form-field class="field" appearance="fill">
        <input
          placeholder="הוסף חדש"
          type="text"
          matInput
          formControlName="name"
        />
      </mat-form-field>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    style="position: relative; width: fit-content"
    class="mb-2"
  >
    <div class="label">צבע לנטייה</div>
    <button
      style="background: rgb(76, 180, 178, 0.3); margin-right: 5px"
      (click)="colorPicker.click()"
      mat-icon-button
      type="button"
    >
      <mat-icon [style.color]="CategoryColor.value">palette</mat-icon>
    </button>
    <input
      formControlName="color"
      type="color"
      #colorPicker
      style="visibility: hidden; position: absolute; top: 10px; left: 0"
    />
  </div>

  <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
    <div class="label" style="margin-bottom: -10px">מיקום הנטייה</div>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-form-field class="field order" appearance="fill">
      <input matInput type="number" formControlName="orderBy" />
    </mat-form-field>
  </div>
  <div class="mb-2">
    <strong>תמונת בר נטייה</strong>
    <app-algo-image
      style="display: block; width: 180px"
      [langId]="langId"
      [algoImageType]="AlgoImageType.PrototypeResultTypePass"
      (onUploadFinished)="onUploadFinished($event)"
      [selectedImg]="getImage(AlgoImageType.PrototypeResultTypePass)"
      [checkedImgId]="getCheckedImage(AlgoImageType.PrototypeResultTypePass)"
      isrRectangle="true"
    ></app-algo-image>
  </div>
  {{ errorMessage }}
  <div class="mb-4"></div>
  <div class="form-action">
    <button mat-raised-button type="submit" class="save">שמירה</button>
  </div>
</form>
