<mat-list class="test-general-information">
  <mat-list-item
    ><span class="card-title"> General Information </span></mat-list-item
  >
  <hr style="border-color: #d3247e; border-style: solid" />
  <mat-list-item class="row-container__item">
    <div class="d-flex-center">
      <span class="boldSpan"> Product type: </span>
      <span>{{ product.productType.productName }}</span>
    </div>
  </mat-list-item>
  <mat-list-item class="row-container__item">
    <div class="d-flex-center">
      <span class="boldSpan"> Status: </span>
      <mat-form-field
        *ngIf="userHasPermission(['Products_ChangeStatus'])"
        class="example-full-width round-select"
        style="width: 200px"
      >
        <mat-select
          class="lead-status-select"
          disableOptionCentering
          (selectionChange)="saveStatusForProduct($event, product)"
          [(ngModel)]="product.statusId"
          style="text-align: center"
          [ngStyle]="{
            'background-color': product.status && product.status.color
          }"
        >
          <mat-option
            *ngFor="let status of statuses"
            [value]="status.statusId"
            style="text-align: center"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span
        *ngIf="!userHasPermission(['Products_ChangeStatus'])"
        style="
          padding: 10px;
          border-radius: 25px 25px;
          text-align: center;
          color: #fff;
        "
        [ngStyle]="{
          'background-color': product.status && product.status.color
        }"
        >{{ product.status.name }}</span
      >
    </div>
  </mat-list-item>
  <mat-list-item class="row-container__item" style="width: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div class="d-flex-center">
        <span class="boldSpan">Nutritionist: </span>
        <span *ngIf="!userHasPermission(['Asign Nutritionist'])">
          <small> {{ getNutritionist() }}</small
          >&nbsp;
        </span>
        <app-autocomplete-agent
          (selectedValue)="saveNutritionistForProduct($event)"
          [data]="nutrisionists"
          [initialValue]="getAgentNameById(product.agentId)"
          [widthExp]="180"
          [label]="'Select Nutritionist'"
        ></app-autocomplete-agent>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item class="row-container__item" style="width: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div class="d-flex-center" style="flex: 1">
        <span class="boldSpan">Follow up: </span>
        <span *ngIf="!userHasPermission(['Asign Nutritionist'])">
          <small> {{ getNutritionist() }}</small
          >&nbsp;
        </span>
        <app-autocomplete-agent
          (selectedValue)="saveAgent2ForProduct($event)"
          [data]="followUpAgents"
          [initialValue]="getFollowUpAgentNameById(product.agent2Id)"
          [widthExp]="180"
          [label]="'Select Follow-up'"
        ></app-autocomplete-agent>
        <strong class="text-center" fxFlex="5">|</strong>
        <div fxLayout="row">
          <div class="">
            <strong style="font-weight: 500"> First call: &nbsp;&nbsp;</strong>
            <mat-checkbox
              #onFirstCall
              (change)="changeOnFirstCall($event)"
              [checked]="product.firstCall"
            >
              <!--   {{ onFollow.checked ? "On follow" : "Not on follow" }} -->
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item class="row-container__item" style="width: 100%">
    <div
      class="boldSpan"
      *ngIf="
        product.productType.productTypeId == ProductTypeEnum.NutriPlus &&
        chatExpiredDate
      "
    >
      Chat expired:
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="flex: 1">
      <mat-form-field
        appearance="fill"
        *ngIf="
          product.productType.productTypeId == ProductTypeEnum.NutriPlus &&
          chatExpiredDate
        "
      >
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [(ngModel)]="chatExpiredDate"
          [min]="minDateExpireChat"
          [matDatepicker]="dp3"
          disabled
          (ngModelChange)="changeChatExpiredDate()"
        />
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
      </mat-form-field>
      <strong
        class="text-center"
        fxFlex="5"
        *ngIf="
          product.productType.productTypeId == ProductTypeEnum.NutriPlus &&
          chatExpiredDate
        "
        >|</strong
      >
      <div class="">
        <strong style="font-weight: 500">On Follow:&nbsp;&nbsp;</strong>
        <mat-checkbox disabled #onFollow [checked]="product.onFollow">
          <!-- (change)="changeOnFollow($event)" -->
          <!--   {{ onFollow.checked ? "On follow" : "Not on follow" }} -->
        </mat-checkbox>
      </div>
    </div>
  </mat-list-item>

  <mat-list-item class="row-container__item" style="width: 100%">
    <div
      class="boldSpan"
      *ngIf="product.productType.productTypeId == ProductTypeEnum.NutriPlus"
    >
      FoodGenes expired:
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="flex: 1">
      <mat-form-field
        appearance="fill"
        *ngIf="product.productType.productTypeId == ProductTypeEnum.NutriPlus"
      >
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [(ngModel)]="fgExpiredDate"
          [min]="minDateExpireChat"
          [matDatepicker]="dp33"
          disabled
          (ngModelChange)="changeFoodGenesExpiredDate()"
        />
        <mat-datepicker-toggle matSuffix [for]="dp33"></mat-datepicker-toggle>
        <mat-datepicker #dp33 disabled="false"></mat-datepicker>
      </mat-form-field>
      <!-- <strong
        class="text-center"
        fxFlex="5"
        *ngIf="
          product.productType.productTypeId == ProductTypeEnum.NutriPlus &&
          fgExpiredDate
        "
        > -->
    </div>
  </mat-list-item>

  <mat-list-item class="row-container__item" style="width: 100%">
    <div class="boldSpan">Edit Password:</div>
    <div>
      <mat-form-field *ngIf="passwordEditMode">
        <input
          #passwordInput="matInput"
          matInput
          class="edit-field"
          [(ngModel)]="newPassword"
        />
        <div
          style="color: #d3247e; font-size: 1.39rem; position: absolute"
          *ngIf="newPasswordError"
        >
          password is not valid
          <!--please enter a new password-->
        </div>
      </mat-form-field>
      <div>
        <button
          *ngIf="passwordEditMode"
          class="rect-action-btn rect-action-btn--test"
          style="margin-right: 1rem; margin-top: -1rem; margin-bottom: 1rem"
          (click)="changePasswordHack()"
          mat-button
        >
          Save
        </button>
        <button
          (click)="togglePassEditMode()"
          class="rect-action-btn"
          [class.btn-cancel-form--test]="passwordEditMode == true"
          [class.rect-action-btn--test]="passwordEditMode != true"
          [ngStyle]="passwordEditMode == true && { color: '#d3247e' }"
          mat-button
        >
          {{ textToggleBtn() }}
        </button>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item
    class="row-container__item"
    style="width: 100%"
    *ngIf="passwordEditMode"
  >
    <div
      style="
        color: #707070;
        text-align: left;
        margin-left: 140px;
        font-size: 12px;
        font-weight: lighter;
      "
    >
      <span style="color: #1b3f4e; font-weight: bold">
        password must contain:</span
      >
      <br />
      <img
        src="../../../../assets/validationV.svg"
        class="v_icon"
        *ngIf="is8Length()"
      />
      <img
        src="../../../../assets/validationV_1.svg"
        class="v_icon"
        *ngIf="!is8Length()"
      />
      <span [ngClass]="{ validGreen: is8Length() }">
        at least 8 characters</span
      >
      <br />
      <img
        src="../../../../assets/validationV.svg"
        class="v_icon"
        *ngIf="hasLetters()"
      />
      <img
        src="../../../../assets/validationV_1.svg"
        class="v_icon"
        *ngIf="!hasLetters()"
      />
      <span [ngClass]="{ validGreen: hasLetters() }">
        Uppercase and lowercase letters
      </span>
      <br />
      <img
        src="../../../../assets/validationV.svg"
        class="v_icon"
        *ngIf="hasNumbers()"
      />
      <img
        src="../../../../assets/validationV_1.svg"
        class="v_icon"
        *ngIf="!hasNumbers()"
      />
      <span [ngClass]="{ validGreen: hasNumbers() }">
        At least one number
      </span>
      <br />
    </div>
  </mat-list-item>
  <mat-list-item class="row-container__item">
    <span class="boldSpan">Barcode: </span>
    <!-- <div
      class="d-flex-center"
      style="justify-content: space-between; width: 100%"
    > -->
    {{ product.barCode }}
    <div style="margin-left: auto">
      <button
        style="padding: 0; min-width: 30px"
        (click)="openChangeBarcodeDialog()"
        mat-button
        class=""
      >
        <img src="../../../../assets/test-edit.svg" alt="edit" />
      </button>
      <button mat-button style="color: #d3247e" (click)="openBarcodeHistory()">
        <img src="../../../assets/history-24px.svg" />
        barcode History
      </button>
    </div>
  </mat-list-item>
  <!-- <mat-list-item class="row-container__item">
    <span class="boldSpan"> Contract Status: </span>

    <span
      *ngIf="!isSignedConcent()"
      class="text--test"
      style="
        text-decoration: underline;

        width: 160px;
        white-space: nowrap;
      "
      >Not Signed</span
    >
    <span
      *ngIf="isSignedConcent()"
      style="cursor: pointer"
      class="round-indicator test"
      (click)="onDownloadContract()"
    >
      Download Contract</span
    >
  </mat-list-item> -->
  <mat-list-item class="row-container__item">
    <span class="boldSpan" style="width: 150px; margin-right: 0">
      Package Number:
    </span>
    <span>{{ getTubePackageId() }} </span>
  </mat-list-item>
  <mat-list-item
    *ngIf="isStatusCompleted() && userHasPermission(['Algorithm_Create'])"
    class="row-container__item"
  >
    <span class="boldSpan" style="width: 150px; margin-right: 0">
      Recalulate Results:
    </span>
    <span>
      <button
        class="rect-action-btn rect-action-btn--test"
        mat-button
        (click)="recalculateByBarcode()"
      >
        <mat-icon>refresh</mat-icon>
        Recalulate
      </button>
    </span>
  </mat-list-item>
  <div style="margin-bottom: 2rem"></div>
  <mat-list-item>
    <span class="card-title">
      <img style="margin-right: 1rem" src="../../../assets/file.svg" />
      Contract Status
    </span>
  </mat-list-item>
  <hr style="border-color: #d3247e; border-style: solid" />
  <mat-list-item class="row-container__item">
    <span class="boldSpan" style="width: 150px; margin-right: 0">
      privacy policy:
    </span>
    <span
      *ngIf="!consentFileExist(1)"
      style="cursor: default"
      class="round-indicator test"
      >Missing</span
    >
    <button
      (click)="downloadConsentFile(1)"
      *ngIf="consentFileExist(1)"
      mat-icon-button
      class="download"
    >
      <img src="../../../../assets/download-2.svg" alt="" />
    </button>
    <app-upload-results-csv
      style="margin-left: auto"
      [classAttr]="'patientAttr'"
      [title]="'Upload'"
      [uploadPath]="getConsentUploadPath(1)"
      (onUploadFinished)="uploadFileFinished($event)"
    ></app-upload-results-csv>
  </mat-list-item>
  <mat-list-item class="row-container__item">
    <span class="boldSpan" style="width: 150px; margin-right: 0">
      Terms of cancelation:
    </span>
    <span
      *ngIf="!consentFileExist(2)"
      style="cursor: default"
      class="round-indicator test"
      >Missing</span
    >
    <button
      (click)="downloadConsentFile(2)"
      *ngIf="consentFileExist(2)"
      mat-icon-button
      class="download"
    >
      <img src="../../../../assets/download-2.svg" alt="" />
    </button>

    <app-upload-results-csv
      style="margin-left: auto"
      [classAttr]="'patientAttr'"
      [title]="'Upload'"
      [uploadPath]="getConsentUploadPath(2)"
      (onUploadFinished)="uploadFileFinished($event)"
    ></app-upload-results-csv>
  </mat-list-item>
  <mat-list-item class="row-container__item">
    <span class="boldSpan" style="width: 150px; margin-right: 0">
      Terms of Use:
    </span>
    <span
      *ngIf="!consentFileExist(3)"
      style="cursor: default"
      class="round-indicator test"
      >Missing</span
    >
    <button
      (click)="downloadConsentFile(3)"
      *ngIf="consentFileExist(3)"
      mat-icon-button
      class="download"
    >
      <img src="../../../../assets/download-2.svg" alt="" />
    </button>
    <app-upload-results-csv
      style="margin-left: auto"
      [classAttr]="'patientAttr'"
      [title]="'Upload'"
      [uploadPath]="getConsentUploadPath(3)"
      (onUploadFinished)="uploadFileFinished($event)"
    ></app-upload-results-csv>
  </mat-list-item>
  <mat-list-item class="row-container__item">
    <span class="boldSpan" style="width: 163px; margin-right: 0">
      Signed All Papers:
    </span>
    <mat-checkbox
      (click)="onSignedAllPapersClicked()"
      [(ngModel)]="product.patient && product.patient.isSignedConcent"
    >
    </mat-checkbox>
  </mat-list-item>
  <div style="margin-bottom: 2rem"></div>
  <mat-list-item>
    <span class="card-title"> Client goals </span>
    <button
      class="rect-action-btn rect-action-btn--test save-goal-btn"
      (click)="saveGoals()"
      [disabled]="!userGoalsModelChange || userGoalsModel.length == 0"
      mat-button
    >
      Save goals
    </button>
  </mat-list-item>
  <hr style="border-color: #d3247e; border-style: solid" />
  <div>
    <p>Select the goals for which the customer purchased the test</p>
    <div>
      <mat-form-field
        class="example-full-width round-select user-goals"
        *ngIf="userGoals"
      >
        <mat-select
          placeholder="Choose goals"
          (selectionChange)="onClientGoalsChange($event)"
          [(ngModel)]="userGoalsModel"
          (ngModelChange)="userGoalsModelChange = true"
          multiple
          disableOptionCentering
        >
          <mat-option *ngFor="let goal of userGoals" [value]="goal.goalId">
            {{ goal.goalName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-list>
