import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../data/Patients';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { Product } from '../data/Product';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<Patient[]>(`${environment.baseUrl}Patients/`);
  }
  getById(patientId) {
    return this.http.get<Patient>(
      `${environment.baseUrl}Patients//${patientId}`
    );
  }
  getPatientsProducts(patientId) {
    return this.http.get<Product[]>(
      `${environment.baseUrl}Patients/PatientProducts/${patientId}`
    );
  }
  put(patient: Patient) {
    return this.http.put(
      `${environment.baseUrl}Patients/${patient.patientId}`,
      patient
    );
  }
  post(patient: Patient) {
    return this.http.post<Patient>(`${environment.baseUrl}Patients/`, patient);
  }
  delete(lead: Patient) {
    return this.http.delete(`${environment.baseUrl}Patients/${lead.clientId}`);
  }
  resendDelivery(patientId, deliveryType) {
    return this.http.get<Patient>(
      `${environment.baseUrl}Patients/ResendToBar/${patientId}/${deliveryType}`
    );
  }
}
