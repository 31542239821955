import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VideoCategory } from '../data/VideoCategory';
import { VideoCategoryMin } from '../data/VideoCategoryMin';

@Injectable({
  providedIn: 'root',
})
export class VideoCategoriesService {
  constructor(private http: HttpClient) {}

  getForDropdown() {
    return this.http.get<VideoCategory[]>(
      `${environment.baseUrl}VideoCategories/ForDropDown`
    );
  }

  getForTable() {
    return this.http.get<VideoCategory[]>(
      `${environment.baseUrl}VideoCategories/ForTable`
    );
  }

  put(videoCategory: VideoCategory) {
    return this.http.put<any>(
      `${environment.baseUrl}VideoCategories/${videoCategory.videoCategoryId}`,
      videoCategory
    );
  }

  post(videoCategory: VideoCategory) {
    return this.http.post<any>(
      `${environment.baseUrl}VideoCategories`,
      videoCategory
    );
  }

  delete(videoCategoryId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}VideoCategories/${videoCategoryId}`
    );
  }
}
