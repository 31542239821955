import { Component, OnInit } from '@angular/core';
import { SendSmsService } from 'src/app/Services/send-sms.service';
import { SmsDetails } from 'src/app/data/SmsDetails';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css'],
})
export class SendSmsComponent implements OnInit {
  constructor(private sendSmsService: SendSmsService) {}

  ngOnInit(): void {}
  onSendsms() {
    var smsDetails = new SmsDetails();
    smsDetails.fromNumber = '0525277853';
    smsDetails.toNumberList = ['0525277853'];
    smsDetails.text = 'test sending mail';
    this.sendSmsService.sendSms(smsDetails).subscribe((ans) => {});
  }
}
