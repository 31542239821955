<perfect-scrollbar [scrollIndicators]="true" class="sidenav-snp">
  <div class="add-button-wrapper">
    <ng-container *ngIf="canEdit">
      <button class="add-button" (click)="addSnp()" mat-mini-fab>
        <mat-icon mat-list-icon>add</mat-icon>
      </button>
      <button mat-button (click)="addSnp()" class="add-text">הוסף RS</button>
    </ng-container>
  </div>
  <mat-list class="snp-list">
    <ng-container *ngIf="algoSnps">
      <mat-list-item
        matRipple
        (click)="onSelectSnp(snp)"
        *ngFor="let snp of algoSnps"
        [ngClass]="{
          selected: snp.algoSnpNum === selectedSnp?.algoSnpNum
        }"
        [ngStyle]="{
          'border-right':
            snp.algoSnpNum === selectedSnp?.algoSnpNum
              ? '5px solid' + selectedCategory?.color
              : 'none'
        }"
        class="snp-item rs"
      >
        <div
          style="width: 100%"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          {{ snp.prototypeSnpNumber }}
          <button
            *ngIf="canEdit"
            class="delete-btn"
            (click)="removeSnp(snp, $event)"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </ng-container>
    <mat-list-item
      matRipple
      (click)="onSelectSummary()"
      [ngClass]="{
        selected: isSummarySelected
      }"
      class="snp-item summary text-center"
      [ngStyle]="{
        'border-right': isSummarySelected
          ? '5px solid' + selectedCategory?.color
          : 'none'
      }"
    >
      סיכום
    </mat-list-item>
  </mat-list>
</perfect-scrollbar>
