import { ChatMessage } from './ChatMessage';

export class ChatSession {
  chatSessionId: number;
  statusId: number;
  departmentId: number | null;
  closedBy: number | null;
  userId: number;
  currentAgent: number | null;
  chatRoomId: number;
  createdDate: string;
  messages: ChatMessage[];
  conversationUserAvatar: string;
  conversationUserName: string;
  conversationUserDescription: string;
  conversationAgentAvatar: string;
  conversationAgentName: string;
  conversationAgentDescription: string;
  statusMessage: string;
  closedDate: string;
  chatTitle: string;
}
