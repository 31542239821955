import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { BloodTestResult } from 'src/app/data/BloodTestResult';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';
import { Product } from 'src/app/data/Product';
import { ProductLifeStyleVersionStatus } from 'src/app/data/ProductLifeStyleVersionStatus';
import { RegularUser } from 'src/app/data/RegularUser';
import { LifeStyleReportVersionsService } from 'src/app/Services/life-style-report-versions.service';
import { LifeStyleReportsService } from 'src/app/Services/life-style-reports.service';
import { ProductsService } from 'src/app/Services/products.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-patient-reports-edit',
  templateUrl: './patient-reports-edit.component.html',
  styleUrls: ['./patient-reports-edit.component.scss'],
})
export class PatientReportsEditComponent implements OnInit, AfterViewInit {
  tabIndex;
  editSide;
  isEditModeRight: boolean = false;
  isEditModeLeft: boolean = false;
  patientId: string;
  lifeStyleReport: LifeStyleReport;
  gender: string;
  bloodTestResults: BloodTestResult[];
  isLifeStyleEdit: boolean = false;
  productId: number;
  newLifeStyleSystem: boolean = false;
  productLifeStyleVersionStatus: ProductLifeStyleVersionStatus;
  barcode: string;
  regularUser: RegularUser;
  product: Product;
  langId: number = 1;
  constructor(
    private route: ActivatedRoute,
    private lifeStyleReportsService: LifeStyleReportsService,
    private lifeStyleReportVersionsService: LifeStyleReportVersionsService,
    private productsService: ProductsService,
    private router: Router
  ) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tabIndex = this.route.snapshot.queryParamMap.get('tabIndex');
    }, 500);
  }

  ngOnInit(): void {
    this.productId = +this.route.snapshot.queryParamMap.get('productId');
    this.barcode = this.route.snapshot.queryParamMap.get('barcode');
    this.patientId = this.route.snapshot.queryParamMap.get('patientId');

    console.log('tabIndex', this.tabIndex);

    this.editSide = this.route.snapshot.queryParamMap.get('edit');
    this.gender = this.route.snapshot.queryParamMap.get('gender');
    if (this.editSide === 'right') this.isEditModeRight = true;
    if (this.editSide === 'left') this.isEditModeLeft = true;
    this.getLifeStyleReport();
    this.getVersions();
    this.productsService.getById(this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
      } else {
        this.router.navigate(['home']);
      }
    });
  }
  onTabChanged(event: MatTabChangeEvent) {
    const tabInx = event.index;
  }

  getVersions() {
    this.lifeStyleReportVersionsService
      .GetAvailbleVersions(this.productId)
      .subscribe((res) => {
        this.productLifeStyleVersionStatus = res;
        var defaultVersion = this.productLifeStyleVersionStatus.versions.find(
          (x) => x.default
        ).lifeStyleReportVersionId;
        if (defaultVersion > 2) this.newLifeStyleSystem = true;
      });
  }
  getLifeStyleReport() {
    this.lifeStyleReportsService
      .getByPatientId(this.patientId)
      .subscribe((data) => (this.lifeStyleReport = data));
  }
  onRightEdit() {
    this.isEditModeRight = true;
  }
  onRightExitEdit() {
    this.isEditModeRight = false;
  }
  goBack() {
    this.router.navigate(['tests/edit'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
  clearLifeStleReport() {
    this.lifeStyleReport.isComplete = false;
    this.lifeStyleReportsService
      .put(this.lifeStyleReport)
      .subscribe((res) => {});
  }
  setOldLifeStyleNotActive() {
    this.lifeStyleReport.isOldActive = false;
    this.lifeStyleReportsService
      .put(this.lifeStyleReport)
      .subscribe((res) => {});
  }
  setOldLifeStyleActive() {
    this.lifeStyleReport.isOldActive = true;
    this.lifeStyleReportsService
      .put(this.lifeStyleReport)
      .subscribe((res) => {});
  }
  gotoLifestyleEditMode() {
    this.isLifeStyleEdit = true;
  }
  gotoLifestylePreviewMode() {
    this.isLifeStyleEdit = false;
  }
  onLifeStyleVersionSelect(event) {
    this.productsService
      .setLifestyleVersion(
        this.productId,
        this.productLifeStyleVersionStatus.selectedLifeStyleVersionId
      )
      .subscribe((res) => {});
  }
  onUpdateLifestlyeDone() {
    this.productsService
      .setLifeStyleNewComplete(this.productId, true)
      .subscribe((res) => {
        this.getVersions();
      });
  }
  patientHasPermisions(premissions: string[]) {
    if (!this.product) return false;
    return userHasPermisions(premissions, this.product.patient.regularUser);
  }
}
