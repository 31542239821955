<app-head-filter
  *ngIf="agents && decisions"
  [agents]="agents"
  [headFilter]="headFilter"
  [decisions]="decisions"
  [leadStatuses]="statuses"
  (filterChanged)="getAllWithFilter($event, true)"
  [classAttr]="'cancelationRequestAttr'"
>
</app-head-filter>
<div class="TableClearFix"></div>
<app-cancelation-table
  [agentUser]="agentUser"
  *ngIf="agents && tabsList"
  [tabsList]="tabsList"
  [requestsStatuses]="statuses"
  [serviceRequests]="cancellationRequests"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [metaData]="metaData"
  [classAttr]="'cancelationRequestAttr'"
  [agents]="agents"
  [isLoading]="isLoading"
>
  <!--*ngIf="leadsData && leadsData.length > 0"-->
</app-cancelation-table>
<app-table-paginator
  *ngIf="cancellationRequests && metaData"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event, false, true)"
  [classAttr]="'leadsAttr'"
>
</app-table-paginator>

<!-- (checkedIdsChanged)="onCheckedIdsChanged($event)"-->
