<perfect-scrollbar class="sidenav-cat">
  <div class="top-nav">
    <label for="navSearch" class="search" *ngIf="canSearch">
      <input
        id="navSearch"
        type="search"
        (keyup.enter)="onSearchBtnClick()"
        [placeholder]="dir === 'ltr' ? 'Search SNP Number' : 'חפש מספר RS'"
        [(ngModel)]="searchTerm"
        [ngStyle]="{
          'padding-right': dir === 'ltr' ? '3rem' : '1rem',
          'padding-left': dir === 'ltr' ? '1rem' : '3rem'
        }"
      />
      <button
        (click)="onSearchBtnClick()"
        type="button"
        class="search__btn"
        [ngStyle]="{
          right: dir === 'ltr' ? '2px' : 'unset',
          left: dir === 'rtl' ? '2px' : 'unset'
        }"
      ></button>
    </label>
    <div class="mb-1"></div>
    <mat-form-field
      class="round-select lab-versions"
      [class.rtl]="dir === 'rtl'"
      [class.ltr]="dir === 'ltr'"
    >
      <mat-select
        [(ngModel)]="versionId"
        (selectionChange)="onChangeVersion($event)"
        class="lab-versions__select"
      >
        <mat-option
          [value]="item.labVersionId"
          *ngFor="let item of algoVersions"
        >
          {{ item.labName | uppercase }} - {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="mb-1"></div>
    <!-- <app-add-category
      *ngIf="canEdit"
      mode="add"
      [canEdit]="canEdit"
      [versionId]="versionId"
      (onSave)="onSave($event)"
    ></app-add-category> -->
    <div *ngIf="canEdit">
      <button (click)="addCategory()" mat-mini-fab class="add-button">
        <mat-icon>add</mat-icon>
      </button>
      <button (click)="addCategory()" mat-button class="add-text">
        הוסף קטגוריה
      </button>
    </div>
    <div class="mb-2"></div>
  </div>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{ height: '20px' }"
      count="15"
    ></ngx-skeleton-loader>
  </div>
  <mat-accordion #accordion="matAccordion" *ngIf="categories && !isLoading">
    <mat-expansion-panel
      *ngFor="let category of categories; let i = index"
      [expanded]="indexExpanded == i"
      #panelsCategory
    >
      <mat-expansion-panel-header
        [ngClass]="{
          selected: category?.categoryId === selectedCategory?.categoryId
        }"
        (click)="onSelectCategory(category, i)"
        class="panel-header"
      >
        <mat-panel-title>
          <app-more-panel
            *ngIf="canEdit"
            [canEdit]="canEdit"
            [isCategory]="true"
            [category]="category"
            [versionId]="versionId"
            (loadCategories)="onSave($event)"
            [langId]="langId"
            [fromCategory]="true"
            (openMainBoard)="openMainBoardEvent.emit($event)"
          ></app-more-panel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span
              class="cat-color"
              [style.background-color]="category.color"
            ></span>
            {{ category.name }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <ng-container *ngFor="let subcategory of category.subcategories">
          <mat-list-item
            (click)="onSelectSubCategory(subcategory)"
            [ngClass]="{
              selected:
                subcategory?.subCategoryId ===
                selectedSubCategory?.subCategoryId
            }"
            [ngStyle]="{
              'background-color':
                subcategory?.subCategoryId ===
                selectedSubCategory?.subCategoryId
                  ? category?.color
                  : ''
            }"
            class="sub-title"
            matRipple
          >
            <div class="sub-title-item">
              <div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <app-more-panel
                    *ngIf="canEdit"
                    [isSubCategory]="true"
                    [category]="selectedCategory"
                    [subCategory]="subcategory"
                    [versionId]="versionId"
                    (loadCategories)="onSave($event)"
                    [langId]="langId"
                    [fromSubCategory]="true"
                    (openMainBoard)="openMainBoardEvent.emit($event)"
                  ></app-more-panel>
                  {{ subcategory.name }}
                </div>
              </div>
              <mat-icon
                [matTooltip]="subcategory.isActive ? 'Visible' : 'Hidden'"
                class="visibility"
                >{{
                  subcategory.isActive ? "visibility" : "visibility_off"
                }}</mat-icon
              >
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="d-flex-center" style="justify-content: center; margin-top: 1rem">
    <ng-container *ngFor="let language of languages">
      <img
        src="../../assets/lang-icon-{{ language.languageId }}.svg"
        style="cursor: pointer; margin: 5px"
        [ngStyle]="{
          transform: language.languageId == 2 ? 'translateY(-3px)' : 'none'
        }"
        [ngStyle]="{
          filter: language.languageId != langId ? 'opacity(0.5)' : 'none'
        }"
        (click)="onChangeLang(language.languageId)"
      />
    </ng-container>
  </div>
</perfect-scrollbar>
