<div>
  <table style="width: 100%" mat-table [dataSource]="clientDeals">
    <ng-container matColumnDef="active">
      <th style="width: 8%; padding-left: 0" mat-header-cell *matHeaderCellDef>
        Active:
      </th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; padding-left: 0"
      >
        <img
          *ngIf="deal.hasActiveCancelationRequest || deal.statusId == 192"
          width="20"
          src="../../../assets/active-cancel.svg"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th style="width: 18%" mat-header-cell *matHeaderCellDef>Date:</th>
      <td mat-cell *matCellDef="let deal" style="cursor: pointer">
        {{ deal.dateCreated | date: "dd-MM-yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th style="width: 20%" mat-header-cell *matHeaderCellDef>Product:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="text-align: center; cursor: pointer"
      >
        {{ getProductsStr(deal.products) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sum">
      <th style="width: 10%" mat-header-cell *matHeaderCellDef>Sum:</th>
      <td mat-cell *matCellDef="let deal" style="cursor: pointer">
        {{ deal && deal.amount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th style="width: 25%" mat-header-cell *matHeaderCellDef>Status:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; align-items: center"
      >
        <span
          class="round-indicator"
          style="font-size: 1.3rem; text-transform: uppercase"
          [ngStyle]="{ 'background-color': getDealStatusColor(deal) }"
        >
          {{ getDealStatus(deal) }}
        </span>
        <div
          style="color: #e91779; font-size: 1.3rem"
          *ngIf="getDealLeftToPay(deal)"
        >
          <strong>₪{{ getDealLeftToPay(deal) }}</strong> left to pay
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pMethod">
      <th style="width: 25%" mat-header-cell *matHeaderCellDef>P.Method:</th>
      <td
        mat-cell
        *matCellDef="let deal"
        style="cursor: pointer; font-size: 1.3rem; line-height: 1.2"
      >
        {{ deal.paymentMethod && deal.paymentMethod.name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dealsDisplayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: dealsDisplayedColumns"
      (click)="onRowClick(row)"
      style="position: relative; height: 60px"
    ></tr>
  </table>
  <ng-container *ngIf="!inCancellation">
    <ng-container *ngIf="showPurchaseBtn()">
      <div
        class="purchases-action"
        style="
          position: sticky;
          bottom: 0;
          background-color: #fff;
          padding-bottom: 0;
        "
      >
        <button class="purchases-action__btn" (click)="openNewPurchase()">
          Make New Purchase
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!showPurchaseBtn()">
      <div class="purchases-action">
        <span style="font-size: 16px; font-weight: 500"
          >To make a purchase you need to be the Agent of this Client</span
        >
      </div>
    </ng-container>
  </ng-container>
</div>
