<div>
  <div class="recomendations">
    <div class="recomendations__page" #pageRef>
      <div
        style="width: 90%; max-width: 800px; margin: 0 auto; padding-top: 2rem"
      >
        <ngx-skeleton-loader
          *ngIf="isLoading"
          [theme]="{ height: '50px' }"
          count="5"
        ></ngx-skeleton-loader>
      </div>
      <ng-container *ngIf="!isLoading">
        <div class="recomendations__page__title">המלצות ליישום</div>
        <div class="recomendations__page__container">
          <div dir="ltr" style="width: 90%; max-width: 800px; margin: 0 auto">
            <button
              mat-button
              class="recomendations__page__back"
              (click)="goBack()"
            >
              חזרה >
            </button>
            <div class="scroll-bar">
              <ng-container
                *ngFor="
                  let item of recomendations;
                  let index = index;
                  let last = last
                "
              >
                <div class="scroll-bar__item">
                  <div
                    class="scroll-bar__item__dot"
                    (click)="scrollToRec(index)"
                    [ngStyle]="{
                      'background-color':
                        visibleIndex == index
                          ? '#' + item.algoBulletCategoryColor
                          : '#DBDBDB',
                      transform:
                        visibleIndex == index ? 'scale(1.5)' : 'scale(1)'
                    }"
                  ></div>
                  <div *ngIf="!last" class="scroll-bar__item__line"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container
            *ngFor="
              let item of recomendations;
              let index = index;
              let last = last
            "
          >
            <div
              class="recomendations__page__wrapper"
              [class.last]="last"
              #elementRef
              id="rec-{{ index }}"
            >
              <div>
                <div class="recomendations__page__header">
                  <span
                    class="recomendations__page__header__title"
                    [ngStyle]="{ color: '#' + item.algoBulletCategoryColor }"
                  >
                    {{ item.algoBulletTitle }}</span
                  >
                </div>
                <div
                  class="recomendations__page__desc"
                  [ngStyle]="{
                    'border-color': '#' + item.algoBulletCategoryColor
                  }"
                >
                  {{ item.algoBulletDescription }}
                  <div *ngIf="item?.algoBulletLinkId">
                    <button
                      mat-button
                      class="recomendations__page__link"
                      (click)="
                        openLinkDialog(
                          item?.algoBulletLinkId,
                          item.algoBulletCategoryColor
                        )
                      "
                    >
                      {{ item.algoBulletLinkName }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                *ngIf="item?.algoBulletImageUrl"
                class="recomendations__page__img"
              >
                <img src="{{ item.algoBulletImageUrl }}" alt="" />
              </div>
              <div class="recomendations__page__reference">
                <mat-icon>warning</mat-icon>
                <div class="text">
                  <div class="text-title">
                    <strong>מדדים שנבדקו: </strong>
                  </div>
                  {{ item.algoBulletReference }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="recomendations" class="recomendations__page__navigation">
          <button
            [disabled]="visibleIndex == 0"
            mat-icon-button
            (click)="scrollToRec(visibleIndex, 'prev')"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <button
            [disabled]="
              visibleIndex == recomendations?.length - 1 ||
              recomendations?.length == 0
            "
            mat-icon-button
            (click)="scrollToRec(visibleIndex, 'next')"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
