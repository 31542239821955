import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { validWeightRange } from 'src/app/Utils/validatorsUtils';

@Component({
  selector: 'app-weight-update',
  templateUrl: './weight-update.component.html',
  styleUrls: ['./weight-update.component.scss'],
})
export class WeightUpdateComponent implements OnInit {
  date: string;
  weight: number;
  errorMsg: string;
  constructor(
    public dialogRef: MatDialogRef<WeightUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  save() {
    this.errorMsg = '';
    if (!this.date || !this.weight) {
      this.errorMsg = 'please Fill Date and weight';
      return;
    }
    if (this.data.isGoal) {
      const bmi = this.getBmi(this.weight, this.data.height);
      const validWeightObj = validWeightRange(this.data.age);

      if (bmi < validWeightObj.min || bmi > validWeightObj.max) {
        const minWeight = this.getWeightFromBmi(
          validWeightObj.min,
          this.data.height
        );
        const maxWeight = this.getWeightFromBmi(
          validWeightObj.max,
          this.data.height
        );
        if (bmi < validWeightObj.min) {
          this.errorMsg = `you choose weight that resulting in Bmi smaller then ${validWeightObj.min} .please choose weight that is greater then ${minWeight}`;
        } else {
          this.errorMsg = `you choose weight that resulting in Bmi greater then ${validWeightObj.max} .please choose weight that is smaller then ${maxWeight}`;
        }
        return;
      }
    }
    if (this.date && this.weight) {
      const date = moment(this.date).format('yyyy-MM-DD');
      this.dialogRef.close({
        event: 'save',
        data: { date, weight: this.weight },
      });
    }
  }
  close() {
    this.dialogRef.close({ event: 'close' });
  }
  getBmi(weight, height) {
    return (weight / (height * height)) * 10000;
  }
  getWeightFromBmi(bmi, height) {
    return (bmi * (height * height)) / 10000;
  }
}
