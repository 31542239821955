import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadResultErrorsService } from 'src/app/Services/upload-result-errors.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { UploadResultError } from 'src/app/data/UploadResultError';

@Component({
  selector: 'app-lab-errors-report',
  templateUrl: './lab-errors-report.component.html',
  styleUrls: ['./lab-errors-report.component.css'],
})
export class LabErrorsReportComponent implements OnInit {
  displayedColumns: string[] = ['errorType', 'description'];
  packageId: string;
  errorsList: UploadResultError[];
  fileDataId: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private uploadResultErrorsService: UploadResultErrorsService
  ) {}

  ngOnInit(): void {
    this.fileDataId = this.route.snapshot.queryParamMap.get('fileDataId');
    this.uploadResultErrorsService
      .getbyFileDataId(this.fileDataId)
      .subscribe((res) => {
        this.errorsList = res;
      });
    /*
    this.packageId = this.route.snapshot.queryParamMap.get('packageId');
    this.uploadResultErrorsService
      .getbyPackageId(this.packageId)
      .subscribe((res) => {
        this.errorsList = res;
        if (this.errorsList.length > 0) {
          var err = this.errorsList[0];
          var a = err.error.name;
          var a1 = err.text;
        }
      });
      */
  }
  goBackToPackage() {
    this.router.navigate(['/labDeliveries/lab/edit'], {
      queryParams: { packageId: this.packageId },
    });
  }
}
