import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-report-thumb',
  templateUrl: './patient-report-thumb.component.html',
  styleUrls: ['./patient-report-thumb.component.css']
})
export class PatientReportThumbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
