import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FoodMin } from 'src/app/data/food-genes/FoodMin';

@Component({
  selector: 'app-food-in-use-error',
  templateUrl: './food-in-use-error.component.html',
  styleUrls: ['./food-in-use-error.component.scss'],
})
export class FoodInUseErrorComponent implements OnInit {
  displayedColumns: string[] = ['foodName', 'categoryName', 'sourceName'];
  dataSource: MatTableDataSource<FoodMin> = new MatTableDataSource([]);
  constructor(
    public dialogRef: MatDialogRef<FoodInUseErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FoodMin[]
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }
}
