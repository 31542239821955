import { Component, OnInit } from '@angular/core';
import { AgentMin } from 'src/app/data/AgentMin';
import { RoleType } from 'src/app/data/RoleType';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';

import { AgentsService } from 'src/app/Services/agents.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-transfer-agents-clients',
  templateUrl: './transfer-agents-clients.component.html',
  styleUrls: ['./transfer-agents-clients.component.css'],
})
export class TransferAgentsClientsComponent implements OnInit {
  selectedAgentType: number = 0;
  selectedAgentFromId: number = 0;
  selectedAgentToId: number = 0;
  agents: AgentMin[];
  filterAgents: AgentMin[];
  nutritionists: AgentMin[];
  followUps: AgentMin[];
  isTransferTasks: boolean;
  constructor(
    private agentsService: AgentsService,
    private popupHandlerService: PopupHandlerService
  ) {
    agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });
  }
  onSelectedAgentTypeChanged(event) {
    this.selectedAgentFromId = this.selectedAgentToId = 0;
    switch (+this.selectedAgentType) {
      case 1: //sales
        this.filterAgents = this.agents.filter(
          (x) =>
            x.roleTypeId == UserRoleEmum.Sales ||
            x.roleTypeId == UserRoleEmum.SalesTeamLeader
        );
        break;
      case 2: //nutritionist
        this.filterAgents = this.agents.filter(
          (x) => x.roleTypeId == UserRoleEmum.nutritionist
        );
        break;
      case 3: //followUp
        this.filterAgents = this.agents.filter(
          (x) => x.roleTypeId == UserRoleEmum.followUpAgent
        );
        break;
    }
  }
  performTranster() {
    this.agentsService
      .transferAgentsClients(
        this.selectedAgentType,
        this.selectedAgentFromId,
        this.selectedAgentToId,
        this.isTransferTasks
      )
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog('', 'Seccuss');
      });
  }
  ngOnInit(): void {}
}
