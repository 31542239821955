import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-toast-error',
  templateUrl: './toast-error.component.html',
  styleUrls: ['./toast-error.component.css'],
})
export class ToastErrorComponent implements OnInit {
  message: string;
  constructor(
    private matSnackbarRef: MatSnackBarRef<ToastErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {}

  ngOnInit(): void {
    this.message = this.data.replace(/\\n/g, '<br/>');
  }

  close() {
    this.matSnackbarRef.dismiss();
  }
}
