<button (click)="goBack()" mat-button>
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back to patient
</button>
<div class="menu" *ngIf="product">
  <mat-card class="menu__list">
    <ng-container *ngIf="editMode == false">
      <div class="sticky">
        <div class="d-flex-between">
          <span class="title">Menu List</span>
          <button
            [disabled]="!canEditMenu()"
            mat-button
            class="btn btn-fill"
            (click)="onCreateNewMenu()"
          >
            Create New Menu
          </button>
        </div>
        <hr class="hr pink" />
      </div>

      <app-menu-list-view
        *ngIf="menuList && product"
        [canEditMenu]="canEditMenu()"
        [isLoading]="isLoading"
        [menuList]="menuList"
        [productId]="productId"
        (menuIdForEdit)="getMenuForEdit($event)"
        (getMenuList)="getMenuList()"
        (editMode)="editMode = $event"
      ></app-menu-list-view>
    </ng-container>

    <ng-container *ngIf="editMode == true">
      <div class="sticky">
        <div class="d-flex-between">
          <span class="title">Create Menu</span>
          <button mat-button class="btn btn-fill" (click)="backToMenus()">
            Back to menus
          </button>
        </div>
        <hr class="hr pink" />
      </div>
      <app-menu-list-edit
        *ngIf="menuId || (createNewMenu && product && mealTypes)"
        [mealTypes]="mealTypes"
        [canEditMenu]="canEditMenu()"
        [product]="product"
        [menuId]="menuId"
        [mealChanged]="mealChanged"
        [createNewFromGroup]="createNewMenu"
        (getMenu)="getMenuForEdit($event)"
        (mealIdForEdit)="editMeal($event)"
        (editModeBullets)="editModeBulletsToggle($event)"
      ></app-menu-list-edit>
    </ng-container>
  </mat-card>

  <mat-card class="menu__details" *ngIf="editMode && editModeBullets">
    <app-menu-bullets
      *ngIf="languages && mealId && languages && product && mealTypes"
      [mealTypes]="mealTypes"
      [canEditMenu]="canEditMenu()"
      [mealId]="mealId"
      [productId]="productId"
      (getMeal)="editMeal($event)"
      [languages]="languages"
      (mealChanged)="hasMealChenged($event)"
      (getMenuForEdit)="getMenuForEdit($event)"
    ></app-menu-bullets>
  </mat-card>
</div>
