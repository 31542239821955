import { Component, OnInit } from '@angular/core';
import { PatientDeliveriesService } from 'src/app/Services/patient-deliveries.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-deliveries',
  templateUrl: './patient-deliveries.component.html',
  styleUrls: ['./patient-deliveries.component.css'],
})
export class PatientDeliveriesComponent implements OnInit {
  constructor(
    private patientDeliveriesService: PatientDeliveriesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var patientId = +this.route.snapshot.queryParamMap.get('patientId');
    this.patientDeliveriesService.getByPatientId(patientId);
  }
}
