import { Component, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';

@Component({
  selector: 'app-review-message',
  templateUrl: './review-message.component.html',
  styleUrls: ['./review-message.component.scss'],
})
export class ReviewMessageComponent implements OnInit {
  message: ChatMessage;
  langId: number;
  rating: number;
  constructor() {}

  ngOnInit(): void {
    this.rating = this.message.template.segments[0].dynamicObjectIdentity;
  }

  showIcon(item: number) {
    if (this.rating >= item) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
