import { Component, OnInit, ViewChild } from '@angular/core';
import { ExternalDeal } from 'src/app/data/ExternalDeal';
import { ExternalLead } from 'src/app/data/ExternalLead';
import { ExternalDealsService } from 'src/app/Services/external-deals.service';

@Component({
  selector: 'app-external-deal',
  templateUrl: './external-deal.component.html',
  styleUrls: ['./external-deal.component.css'],
})
export class ExternalDealComponent implements OnInit {
  externalDeal: ExternalDeal = new ExternalDeal();
  @ViewChild('f') form: any;
  constructor(private externalDealsService: ExternalDealsService) {}

  ngOnInit(): void {}
  onSubmit() {
    if (this.form.valid) {
      //console.log('Form Submitted!');
    }
    var a = this.form.value;
    var deal: ExternalDeal = new ExternalDeal();
    /*
        lead.email = this.form.value.email;
        lead.firstName = this.form.value.firstName;
        lead.phone = this.form.value.phone;
        lead.source = this.form.value.source;
        */
    // this.form.value.productTypeId = 1;
    this.externalDeal.productTypeId = '1';
    this.externalDealsService.post(this.externalDeal).subscribe((ans) => {});
  }
  sendJsonCreateDeal() {
    var str = {
      cityCode: '6',
      city: 'נוה זיו',
      streetCode: '2',
      street: 'מרגנית',
      streetNum: '1',
      entrance: '1',
      apartment: '1',
      shipNotes: '',
      clientId: '2221',
      productTypeId: '1',
      firstName: 'יוסף',
      lastName: 'דדיה',
      phone: '0542010143',
      email: 'ydadya@gmail.com',
      idNum: '313275555',
      npay: 2,
    };
    this.externalDealsService.createDeal(str).subscribe((ans) => {});
  }
  sendJsonTransactionResponse() {
    //var str={"clientId":2220,"dealId":2589,"tranzilaResponse":{"success":true,"error":null,"transaction_id":"212","auth_number":"0005215","amount":"10","currency_code":1,"credit_card_last_4_digits":"0266","expiry_month":"08","expiry_year":"26","user_form_data":{"expiry":""}}}
    //var str={"clientId":2220,"dealId":2747,"tranzilaResponse":null,"error":[{"code":10002,"message":"Invalid credit card number","param":"credit_card_number"}]};
    // var str={"clientId":2221,"dealId":2749,"tranzilaResponse":null,"error":[{"code":10002,"message":"Invalid credit card number","param":"credit_card_number"}]}
    //var str={"clientId":2221,"dealId":2763,"tranzilaResponse":{"success":true,"error":"Refusal. Please contact the card owner to check the reason with his credit company.","transaction_id":"216","amount":"2760","currency_code":1,"credit_card_last_4_digits":"0266","expiry_month":"08","expiry_year":"26","user_form_data":{"expiry":""}},"productTypeId":"1"}
    var str = {
      clientId: 2241,
      dealId: 2877,
      tranzilaResponse: {
        success: true,
        error: null,
        transaction_id: '263',
        auth_number: '0113258',
        amount: '10',
        currency_code: 1,
        credit_card_last_4_digits: '0266',
        expiry_month: '08',
        expiry_year: '26',
        user_form_data: {
          expiry: '',
          contact: 'test firstname test lastname',
          address: 'ללא רחוב 1 1',
          city: 'תמר',
          email: 'test@gmail.com',
        },
      },
      productTypeId: '2',
    };
    this.externalDealsService.TransactionResponse(str).subscribe((ans) => {
      //console.log(ans);
    });
  }
}
