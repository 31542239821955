<table mat-table [dataSource]="triggerEvents" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="EventType">
    <th style="width: 250px" mat-header-cell *matHeaderCellDef>eventType</th>
    <td mat-cell *matCellDef="let triggerEvent" style="vertical-align: top">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              saveEventTypeForTriggerEvent($event, triggerEvent)
            "
            [(ngModel)]="triggerEvent.eventTypeId"
          >
            <mat-option
              *ngFor="let status of eventTypes"
              [value]="status.statusId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ status.name }}
            </mat-option>
            <!--background-color: {{ status.color }}-->
          </mat-select>
        </mat-form-field>
      </span>
      <!--
        {{triggerEvent.eventTypeId}}
    --></td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="StatusId">
    <th style="width: 200px" mat-header-cell *matHeaderCellDef>status</th>
    <td mat-cell *matCellDef="let triggerEvent" style="vertical-align: top">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="saveStatusForTriggerEvent($event, triggerEvent)"
            [(ngModel)]="triggerEvent.statusId"
          >
            <mat-option
              *ngFor="let status of statuses"
              [value]="status.statusId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ status.name }}
            </mat-option>
            <!--background-color: {{ status.color }}-->
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="ProductType">
    <th style="width: 200px" mat-header-cell *matHeaderCellDef>Pruduct Type</th>
    <td mat-cell *matCellDef="let triggerEvent" style="vertical-align: top">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              savePruductTypeForTriggerEvent($event, triggerEvent)
            "
            [(ngModel)]="triggerEvent.triggerEventProductTypeId"
          >
            <mat-option
              *ngFor="let status of productTypes"
              [value]="status.statusId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ status.name }}
            </mat-option>
            <!--background-color: {{ status.color }}-->
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="ObjectType">
    <th style="width: 200px" mat-header-cell *matHeaderCellDef>Pruduct Type</th>
    <td mat-cell *matCellDef="let triggerEvent" style="vertical-align: top">
      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            style="background-color: lightgray; width: 180px"
            (click)="stopPropegation($event)"
            matNativeControl
            disableOptionCentering
            (selectionChange)="
              savePruductTypeForTriggerEvent($event, triggerEvent)
            "
            [(ngModel)]="triggerEvent.triggerEventObjectTypeId"
          >
            <mat-option
              *ngFor="let triggerEventObjectType of triggerEventObjectTypes"
              [value]="triggerEventObjectType.triggerEventObjectTypeId"
              class="trigger-status"
              style="text-align: center"
            >
              {{ triggerEventObjectType.name }}
            </mat-option>
            <!--background-color: {{ status.color }}-->
          </mat-select>
        </mat-form-field>
      </span>
    </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="Name">
    <th style="width: 150px" mat-header-cell *matHeaderCellDef>Name</th>
    <td
      mat-cell
      *matCellDef="let triggerEvent"
      style="vertical-align: top; padding-top: 20px"
    >
      <input
        type="text"
        [(ngModel)]="triggerEvent.name"
        (focusout)="saveTriggerEvent(triggerEvent)"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="Delete">
    <th style="width: 50px" mat-header-cell *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let triggerEvent"
      (click)="deleteEvent(triggerEvent)"
      style="vertical-align: top; height: 50px; padding-top: 20px"
    >
      <span class="material-icons" style="cursor: pointer"> delete </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th style="width: 800px" mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let triggerEvent">
      <app-event-actions
        [actions]="triggerEvent.eventActions"
        [actionTypes]="actionTypes"
        [emailTemplates]="emailTemplates"
        (updateEvents)="updateEvents()"
      >
      </app-event-actions>

      <button (click)="AddAction(triggerEvent)">Add Action</button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="eventsDisplayedColumns; sticky: true"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: eventsDisplayedColumns"></tr>
</table>
<input type="text" [(ngModel)]="newEventName" />
<button (click)="AddEvent()">Add Event</button>
<div style="height: 300px"></div>
