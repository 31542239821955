<div class="categories">
  <div class="categories__header">
    <div>
      {{ getLangName() }}
    </div>
    <div></div>
  </div>
  <ng-container *ngIf="categories">
    <!-- [class.disabled]="editInx != -1 && editInx != category.categoryId"
     (setEditInx)="onSetEditInx($event)"
      (saveCategory)="onSaveCategory($event)"
      (deleteCategory)="onDeleteCategory($event)"
    -->
    <ul style="list-style: none; margin-left: -45px">
      <li *ngFor="let category of categories; let i = index">
        <app-edit-food-category-item
          *ngIf="foodCategoryPerms"
          class="categories__item"
          [category]="category"
          [index]="i"
          [langId]="selectedLangId"
          [itemToExpand]="itemToExpand"
          (editModeChange)="onEditModeChange($event)"
          (saveCategory)="onSaveCategory($event)"
          (editCanceled)="onEditCanceled($event)"
          (deleteCategory)="onDeleteCategory($event)"
          [foodCategoryPerms]="foodCategoryPerms"
        ></app-edit-food-category-item>
      </li>
    </ul>
  </ng-container>
  <div dir="rtl" style="padding: 2rem">
    <button
      mat-button
      class="add-new"
      (click)="createNewCategory()"
      *ngIf="foodCategoryPerms.canAdd"
      [disabled]="disableButton"
    >
      Add new category
    </button>
  </div>
</div>
