import { Component, OnInit } from '@angular/core';
import { TranslationCategory } from 'src/app/data/TranslationCategory';
import { TranslationKeyword } from 'src/app/data/TranslationKeyword';
import { TranslationCategoriesService } from 'src/app/Services/translation-categories.service';
import { TranslationKeywordsService } from 'src/app/Services/translation-keywords.service';

@Component({
  selector: 'app-translation-keywords',
  templateUrl: './translation-keywords.component.html',
  styleUrls: ['./translation-keywords.component.css'],
})
export class TranslationKeywordsComponent implements OnInit {
  translationKeywords: TranslationKeyword[];
  categories: TranslationCategory[];
  selectedCatId;
  constructor(
    private translationKeywordsService: TranslationKeywordsService,
    private translationCategoriesService: TranslationCategoriesService
  ) {}
  newKeyword;
  displayedColumns: string[] = ['id', 'name', 'category', 'edit'];
  ngOnInit(): void {
    this.loadAll();
    this.translationCategoriesService
      .getAll()
      .subscribe((res) => (this.categories = res));
  }
  loadAll() {
    this.translationKeywordsService
      .getAll()
      .subscribe((data) => (this.translationKeywords = data));
  }
  addNewKeyword() {
    var keyword = new TranslationKeyword();
    keyword.keyword = this.newKeyword;
    if (this.selectedCatId > 0) {
      keyword.translationCategoryId = this.selectedCatId;
    }
    this.translationKeywordsService.post(keyword).subscribe((res) => {
      this.loadAll();
    });
  }
  deleteKeyword(keyword: TranslationKeyword) {
    this.translationKeywordsService.delete(keyword).subscribe((res) => {
      this.loadAll();
    });
  }
}
