<div style="display: flex; align-items: center">
  <div style="width: 40px">
    <img
      *ngIf="dealBundle.hasActiveCancelationRequest"
      width="20"
      src="../../../assets/active-cancel.svg"
      matTooltip="In cancelation request"
    />
  </div>
  <div style="width: 80px; font-size: 1.4rem">
    {{ dealBundle.dateCreated | date: "dd-MM-yyyy" }}
  </div>
  <div
    style="width: 120px; font-size: 1.2rem"
    [innerHtml]="getProductsStr(dealBundle.products)"
  ></div>
  <div style="width: 70px">
    {{ dealBundle.amount }}
  </div>
  <div style="width: 150px; cursor: pointer; align-items: center">
    <div
      class="round-indicator"
      style="
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: 0.5rem 0.2rem;
      "
      [ngStyle]="{ 'background-color': getDealStatusColor(dealBundle) }"
    >
      {{ getDealStatus(dealBundle) }}
    </div>
    <div
      style="color: #e91779; font-size: 1.3rem"
      *ngIf="getDealLeftToPay(dealBundle)"
    >
      <strong>₪{{ getDealLeftToPay(dealBundle) }}</strong> left to pay
    </div>
  </div>
</div>
