import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FgCalculatorFunctions } from 'src/app/data/food-genes/FgCalculatorFunctions';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgCoefficientSnp } from 'src/app/data/food-genes/FgCoefficientSnp';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import {
  FgFormulaOwnerTypeEnum,
  FoodGenesBoardEnum,
} from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-main-board',
  templateUrl: './food-genes-main-board.component.html',
  styleUrls: ['./food-genes-main-board.component.scss'],
})
export class FoodGenesMainBoardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() snp: FgCoefficientSnp;
  @Input() param: FgParam;
  @Input() canEdit: boolean;
  @Input() langId: boolean;
  @Input() mode: FoodGenesBoardEnum;
  @Input() coeffType: FgCoefficientType;
  @Input() coeff: FgCoefficient;
  @Input() baseCoeff: FgCoefficient[];
  @Input() versionId: number;
  @Input() calcFunctions: FgCalculatorFunctions[];
  formulas: FgFormula[];
  FoodGenesBoardEnum = FoodGenesBoardEnum;
  FgFormulaOwnerTypeEnum = FgFormulaOwnerTypeEnum;
  constructor(private foodGenesService: FoodGenesService) {}
  ngOnDestroy(): void {
    this.mode = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formulas?.currentValue && !changes.formulas.isFirstChange()) {
      this.formulas = changes.formulas.currentValue;
    }
    if (
      this.mode == FoodGenesBoardEnum.coefficientType &&
      !changes.mode?.isFirstChange()
    ) {
      this.getFormulaByCoefficientType();
    }
  }

  ngOnInit(): void {
    if (this.mode == FoodGenesBoardEnum.coefficientType) {
      this.getFormulaByCoefficientType();
    }
  }

  getFormulaByCoefficientType() {
    this.foodGenesService
      .getFormulaByOwnerCoefficientType(
        this.versionId,
        this.coeffType.fgCoefficientTypeId
      )
      .subscribe((res) => {
        this.formulas = res;
        this.foodGenesService.formulas$.next(this.formulas);
        if (this.mode == FoodGenesBoardEnum.coefficientType) {
          this.foodGenesService.formula$.next(this.formulas[0]);
        }
      });
  }
}
