import { FormControl, AbstractControl } from '@angular/forms';

export function matchOtherValidator(otherControlName: string) {
  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {
    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error(
          'matchOtherValidator(): other control is not found in parent group'
        );
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      };
    }

    return null;
  };
}
export function passwordValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  var regEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(.{8,})$/;
  if (!regEx.test(control.value)) return { passwordValidator: true };
  /*
     if( control.value !==null && (isNaN(control.value) || control.value <20  || control.value> 70)){
       return {'ageValidator': true}
     }
     */
  return null;
}
export function is_israeli_id_number(id) {
  id = String(id).trim();
  if (id.length > 9 || isNaN(id)) return false;
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
}
export function validWeightRange(age) {
  if (age <= 65) {
    return { min: 18.5, max: 25 };
  } else {
    return { min: 23, max: 30 };
  }
}
export function validateArrayNotEmpty(c: FormControl) {
  if (c.value && c.value.length === 0) {
    return {
      validateArrayNotEmpty: { valid: false },
    };
  }
  return null;
}

export function ValidateCreditCardNumber(ccNum) {
  var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  var amexpRegEx = /^(?:3[47][0-9]{13})$/;
  var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  var dinersRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  var isracardRegEx = /^(3640|4580)-?([0-9]{4}-?){3}$/;
  var testRegEx = /^12312312$/;
  var isValid = false;

  if (visaRegEx.test(ccNum)) {
    isValid = true;
  } else if (mastercardRegEx.test(ccNum)) {
    isValid = true;
  } else if (amexpRegEx.test(ccNum)) {
    isValid = true;
  } else if (discovRegEx.test(ccNum)) {
    isValid = true;
  } else if (dinersRegEx.test(ccNum)) {
    isValid = true;
  } else if (testRegEx.test(ccNum)) {
    isValid = true;
  } else if (isracardRegEx.test(ccNum)) {
    isValid = true;
  }

  return isValid;
}

export function creditCardValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (!ValidateCreditCardNumber(control.value))
    return { creditCardValidator: true };
  /*
   if( control.value !==null && (isNaN(control.value) || control.value <20  || control.value> 70)){
     return {'ageValidator': true}
   }
   */
  return null;
}
