import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FileExtension } from 'src/app/data/FileExtension';
import { FilesService } from 'src/app/Services/files.service';
import { FileUploadSettings } from 'src/app/data/FileUploadSettings';

@Component({
  selector: 'app-edit-file-type',
  templateUrl: './edit-file-type.component.html',
  styleUrls: ['./edit-file-type.component.scss'],
})
export class EditFileTypeComponent implements OnInit {
  fileTypeId: number;
  langId: number = 1;
  fileUploadSetting: FileUploadSettings;
  extensions: FileExtension[];
  maxSizeMode: boolean;
  selectedExtensions: number[] = [];
  constructor(
    private route: ActivatedRoute,
    private filesService: FilesService,
    private _matSnackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fileTypeId = +this.route.snapshot.queryParamMap.get('id');
    this.getExtensions();
    this.getFileExtension();
  }

  onSaveSuccess(message: string) {
    this._matSnackBar.open(message, 'Close', {})._dismissAfter(2000);
    setTimeout(() => {
      this.router.navigate(['/file-types']);
    }, 800);
  }
  onSaveError(errorMessage: string) {
    this._matSnackBar.open(errorMessage, 'Close', {})._dismissAfter(2000);
  }
  handleServerResponse(response: ErrorMessage | any) {
    if (response?.message) {
      this.onSaveError(response.message);
    } else {
      this.onSaveSuccess('File type updated');
    }
  }

  onSubmit(form: NgForm) {
    if (form.invalid) return;
    this.filesService
      .updateFileType(this.langId, this.fileUploadSetting)
      .subscribe((data) => {
        this.handleServerResponse(data);
      });
  }

  onCancel() {
    this.router.navigate(['/file-types']);
  }

  getFileExtension() {
    this.filesService.getFileSettings(this.fileTypeId).subscribe((data) => {
      if (data.length == 1) {
        this.fileUploadSetting = data[0];
        this.maxSizeMode =
          this.fileUploadSetting.maxSize && this.fileUploadSetting.maxSize > 0;
        if (this.fileUploadSetting.extensions.length > 0) {
          this.selectedExtensions = this.fileUploadSetting.extensions.map(
            (x) => x.extensionId
          );
        }
      }
    });
  }
  getExtensions() {
    this.filesService.getFileExtensions(this.langId).subscribe((data) => {
      this.extensions = data
        .sort((a, b) =>
          a.havingSignature === b.havingSignature ? 0 : a ? -1 : 1
        )
        .reverse();
    });
  }
  onMaxSizeChanged(e: MatRadioChange) {
    if (!this.maxSizeMode) {
      this.fileUploadSetting.maxSize = -1;
    }
  }
  onExtensionsChange(e: MatSelectChange) {
    const values = e.value as number[];
    this.fileUploadSetting.extensions = values.map((x) => {
      const ext = this.extensions.find((y) => y.extensionId == x);
      return {
        extensionId: x,
        fileTypeId: this.fileTypeId,
        langId: this.langId,
        havingSignature: ext.havingSignature,
        extension: ext.extension,
        groupId: ext.groupId,
        signature: ext.signature,
      };
    });
  }
}
