<mat-list-item role="listitem">
  <!-- (click)="onRowClick(deal)"-->
  <!-- <span style="width: 2.5%">
      <mat-checkbox
        class="leadsTable__item__checkbox"
        (click)="$event.stopPropagation()"
        (change)="isChecked(deal.dealId, deal.isChecked)"
        [(ngModel)]="deal.isChecked"
      >
      </mat-checkbox>
    </span> -->
  <span>
    {{ dealBundle.dateUpdated | date: "dd-MM-yyyy HH:mm" }}
  </span>
  <span
    ><span>{{ dealBundle.costumerName }}</span></span
  >
  <span style="width: 250px; word-break: break-word">{{
    dealBundle.email
  }}</span>
  <span>{{ dealBundle.phone }}</span>
  <span>{{ dealBundle.agent }}</span>
  <span style="width: 100px">{{
    isInner ? dealBundle.amount : dealBundle.amount
  }}</span>
  <span
    style="width: 110px; font-size: 1.4rem"
    class="round-indicator"
    *ngIf="status"
    [ngStyle]="{
      'background-color': status.color
    }"
    >{{ status.name }}
    <!-- <div *ngIf="isNeedApproveBool">Need Approve</div> -->
  </span>
  <span style="padding-left: 10px">
    <!--{{ dealBundle.paymentMethod && dealBundle.paymentMethod.name }} -->
  </span>
  <span></span>
  <!--<span *ngIf="isRefund(deal.dateUpdated)"><button>Refund</button></span> -->
  <span style="width: 100px">
    <!--
    <span
      *ngIf="hasPermmisions() && status.statusId == 88"
      style="min-width: 200px"
    >
      <mat-form-field
        class="example-full-width round-select"
        style="width: 85px"
      >
        <mat-select
          (click)="$event.stopPropagation()"
          (selectionChange)="handleApproval($event, dealBundle)"
          matNativeControl
          disableOptionCentering
          placeholder="Approve"
        >
          <mat-option style="text-align: center" [value]="3">
            Approved</mat-option
          >
          <mat-option style="text-align: center" [value]="50">
            Not Approved</mat-option
          >
        </mat-select>
      </mat-form-field>
    </span>
    -->
  </span>
</mat-list-item>
