import { I } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit, OnChanges {
  //#region Properties
  @Input() canEdit: boolean;
  @Input() category: AlgoCategory;
  @Input() versionId: number;
  @Input() langId: number;
  addState: 'addFromList' | 'addFromInput';
  AlgoImageType = AlgoImageType;
  bigIcon: string;
  @Output() loadCategories: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMainBoard: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion
  constructor(
    private _snackBar: MatSnackBar,
    private algorithmService: AlgorithmService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.category && changes.category.currentValue) {
      this.category = changes.category.currentValue;
    }
  }
  ngOnInit(): void {}
  chooseState(state: 'addFromList' | 'addFromInput') {
    this.addState = state;
  }
  onUploadFinished(event: AppImage) {
    if (event) {
      const finded = this.category.files.find(
        (x) => x.fileType == event.fileType
      );
      if (finded) {
        this.category.files = this.category.files.filter(
          (x) => x.fileType != event.fileType
        );
      }
      this.category.files.push(event);
    }
  }

  getImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.category.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  save(catForm: NgForm) {
    if (catForm.invalid) return;
    this.category.color = this.category.color.replace('#', '');
    this.category.orderBy = this.category.orderBy ? this.category.orderBy : 0;
    //this.category.prototypeCategoryId = this.category.categoryId;
    this.algorithmService.setCategory(this.category).subscribe((res) => {
      this._snackBar.open('Category updeted', 'Close', {})._dismissAfter(2000);
      this.loadCategories.emit(true);
      this.closeMainBoard.emit(true);
    });
  }
}
