import { Component, OnInit, Input } from '@angular/core';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { TubesPackagesService } from 'src/app/Services/tubes-packages.service';
import { Router } from '@angular/router';
import { ProductEnum } from 'src/app/Enums/StatusesEnum';
import { TubesPackageMin } from 'src/app/data/TubesPackageMin';

@Component({
  selector: 'app-tests-package-table',
  templateUrl: './tests-package-table.component.html',
  styleUrls: ['./tests-package-table.component.scss'],
})
export class TestsPackageTableComponent implements OnInit {
  constructor(private router: Router) {}
  @Input() tubesPackages: TubesPackageMin[];
  @Input() type: string;

  ngOnInit(): void {
    if (this.tubesPackages && this.tubesPackages.length > 0) {
      var a = this.tubesPackages[0];
      var a1 = a.tubesPackageId;
      var a2 = a.createDate;
      var a3 = a.status.name;
    }
  }
  editPackage(event, tubesPackage: TubesPackageMin) {
    this.router.navigate([`/labDeliveries/${this.type}/edit`], {
      queryParams: { packageId: tubesPackage.tubesPackageId },
    });
  }
  onRemoveFromPackage() {}
  sendFillLifeStyleReportMail() {}
  getNumCompleted(tubesPackage: TubesPackageMin) {
    return tubesPackage.completedTubesNum;
    /*
    var cnt = 0;
    tubesPackage.tubeTests.forEach((x) => {
      if (x.product && x.product.statusId == ProductEnum.Completed) cnt++;
    });
    return cnt;
    */
  }
  getLabProcess(tubesPackage: TubesPackageMin) {
    return tubesPackage.labProcessTubesNum;
    /*
    var cnt = 0;
    tubesPackage.tubeTests.forEach((x) => {
      if (x.product && x.product.statusId == ProductEnum.LaboratoryProcess)
        cnt++;
    });
    return cnt;
    */
  }
  getOtherStatus(tubesPackage: TubesPackageMin) {
    return tubesPackage.othersTubesNum;
    /*
    var cnt = 0;
    tubesPackage.tubeTests.forEach((x) => {
      if (
        x.product &&
        x.product.statusId != ProductEnum.Completed &&
        x.product.statusId != ProductEnum.LaboratoryProcess
      )
        cnt++;
    });
    return cnt;
    */
  }
}
