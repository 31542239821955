import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { Status } from 'src/app/data/Status';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';

@Component({
  selector: 'app-lifestyle-question-node',
  templateUrl: './lifestyle-question-node.component.html',
  styleUrls: ['./lifestyle-question-node.component.scss'],
})
export class LifestyleQuestionNodeComponent implements OnInit {
  @Input() question: LifestyleQuestion;
  @Input() designTypes: Status[];
  @Output() addNewQuest = new EventEmitter<LifestyleQuestion>();
  @Output() deleteQuest = new EventEmitter<LifestyleQuestion>();
  constructor(private lifestyleQuestionsService: LifestyleQuestionsService) {}

  ngOnInit(): void {
    //this.mep.expanded = true;
  }
  addNewQuestion() {
    var question = new LifestyleQuestion();
    question.designTypeId = 232;
    question.level = this.question.level + 1;
    if (this.question.level == -1) question.level = 1;

    question.parentQuestionId = this.question.lifestyleQuestionId;
    this.addNewQuest.next(question);
  }
  onAddNewQuestion(question) {
    this.addNewQuest.next(question);
  }
  getlevelClass(level) {
    switch (level % 4) {
      case 0:
        return 'color-level-1';
      case 1:
        return 'color-level-2';
      case 2:
        return 'color-level-3';
      case 3:
        return 'color-level-4';
    }
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  valuechange($event) {
    this.lifestyleQuestionsService.put(this.question).subscribe((res) => {});
  }
  deleteCurrentNode() {
    this.deleteQuest.next(this.question);
  }
  onDeleteQuestion(question) {
    this.deleteQuest.next(question);
  }
}
