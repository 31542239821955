<div class="row-container" *ngIf="product && product.patient != null">
  <mat-list>
    <mat-list-item>
      <div class="d-flex-center">
        <span class="card-title d-flex-center">
          <img
            *ngIf="product.productTypeId == 2"
            class="mr-1"
            src="../../../assets/Nu_Original.svg"
            alt="s"
          />
          <img
            *ngIf="product.productTypeId == 1"
            class="mr-1"
            src="../../../assets/Nu_Plus.svg"
            alt="s"
          />
          Patient Details
        </span>
        &nbsp;&nbsp;
        <!-- rect-action-btn rect-action-btn--test -->
        <button (click)="moveToEditMode()" mat-button class="">
          <img src="../../../../assets/test-edit.svg" alt="edit" />
        </button>
      </div>
    </mat-list-item>
    <hr style="border-color: #d3247e; border-style: solid" />
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Customer Name: </span>
      <span
        (click)="gotoClient()"
        style="text-decoration: underline; color: blue; cursor: pointer"
      >
        {{ product.clientName }}
      </span>
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Full Name:</span>
      {{ getFullName(product.patient.regularUser) }}
    </mat-list-item>
    <mat-list-item class="row-container__item" style="width: 100%">
      <span class="boldSpan">Patient ID </span>
      {{ product.patient.regularUser.socialSecurityNum }}
    </mat-list-item>

    <mat-list-item class="row-container__item">
      <span class="boldSpan">Email Address:</span>
      {{ product.patient.regularUser.email }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Tel:</span>
      <ng-container *ngIf="getUserPhoneObj()">
        <img
          src="https://flagcdn.com/16x12/{{
            getUserPhoneObj().nameCode | lowercase
          }}.png"
          [matTooltip]="getUserPhoneObj().name"
        />
        ({{ getUserPhoneObj().countryCode }})
      </ng-container>
      <ng-container> {{ getUserPhone() }}</ng-container>
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan">Main address: </span>
      <ng-container *ngIf="mainAddress">
        <ng-container *ngIf="mainAddress?.barCity?.name">
          {{ mainAddress?.barCity?.name }}
          <br />
        </ng-container>
        <ng-container *ngIf="mainAddress?.barStreet?.name">
          {{ mainAddress?.barStreet?.name }}
        </ng-container>
        <ng-container *ngIf="mainAddress?.streetNum">
          {{ mainAddress?.streetNum }}
        </ng-container>
        <ng-container *ngIf="mainAddress?.entrance">
          {{ mainAddress?.entrance }}
        </ng-container>
        <ng-container *ngIf="mainAddress?.apartment">
          {{ mainAddress?.apartment }}
        </ng-container>
      </ng-container>
    </mat-list-item>
    <mat-list-item
      class="row-container__item"
      *ngIf="product.patient.regularUser.userAdresses[0]?.shipNotes"
    >
      <span class="boldSpan"> Main Shipping Notes: </span>
      <ng-container *ngIf="product.patient.regularUser?.userAdresses">
        {{
          product.patient.regularUser.userAdresses[0]?.shipNotes
        }}</ng-container
      >
    </mat-list-item>
    <ng-container *ngIf="!mainAddressAsShipping() && shippingAddress">
      <mat-list-item class="row-container__item">
        <span class="boldSpan"> Shipping Address: </span>
        <ng-container *ngIf="shippingAddress?.barCity?.name">
          {{ shippingAddress?.barCity?.name }}
          <br />
        </ng-container>
        <ng-container *ngIf="shippingAddress?.barStreet?.name">
          {{ shippingAddress?.barStreet?.name }}
        </ng-container>
        <ng-container *ngIf="shippingAddress?.streetNum">
          {{ shippingAddress?.streetNum }}
        </ng-container>
        <ng-container *ngIf="shippingAddress?.entrance">
          {{ shippingAddress?.entrance }}
        </ng-container>
        <ng-container *ngIf="shippingAddress?.apartment">
          {{ shippingAddress?.apartment }}
        </ng-container>
      </mat-list-item>
      <mat-list-item
        class="row-container__item"
        *ngIf="shippingAddress?.shipNotes"
      >
        <span class="boldSpan"> Shipping Notes: </span>
        {{ shippingAddress?.shipNotes }}
      </mat-list-item></ng-container
    >

    <div style="margin-bottom: 2rem"></div>
    <mat-list-item>
      <div class="d-flex-center">
        <mat-icon
          style="
            color: #d3247e;
            margin-right: 1rem;
            font-size: 3.5rem;
            height: 35px;
            width: 35px;
          "
          >person</mat-icon
        >
        <span class="card-title">Personal Details:</span>
      </div>
    </mat-list-item>
    <hr style="border-color: #d3247e; border-style: solid" />
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Birth date: </span>
      {{ product.patient.regularUser.birthDate | date: "fullDate" }}
      &nbsp;<strong style="color: #d3247e">({{ calculateAge() }})</strong>
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Gender: </span>
      {{ product.patient.regularUser.gender }}
    </mat-list-item>

    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Height: </span>
      {{ product.patient.regularUser.height }}
    </mat-list-item>
    <mat-list-item class="row-container__item">
      <span class="boldSpan"> Weight: </span>

      <!-- <div
        class="d-flex-center"
        style="justify-content: space-between; width: 100%"
      > -->
      <ng-container *ngIf="getWeight()"> {{ getWeight() }} kg</ng-container>

      <div style="margin-left: auto">
        <button (click)="openWeightUpdateDialog()" mat-button class="">
          <img src="../../../../assets/test-edit.svg" alt="edit" />
        </button>
        <button
          mat-button
          style="color: #d3247e"
          (click)="openWeightHistoryDialog()"
        >
          <img src="../../../assets/history-24px.svg" />
          Weight History
        </button>
        <!--  </div> -->
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="showWeightGoal" class="row-container__item">
      <span class="boldSpan" style="display: flex; align-items: center">
        <img
          style="margin-right: 0.5rem"
          src="../../../assets/goal.svg"
          alt="goal"
        />
        Weight goal:
      </span>
      <span style="color: #d3247e" *ngIf="getWeight(true)">
        <strong> {{ getWeight(true) }} </strong>kg</span
      >

      <div style="margin-left: auto">
        <div>
          <button
            class="update-goal"
            [disabled]="disableUpdateWeightGoal()"
            (click)="openWeightUpdateDialog(true)"
            mat-button
          >
            <img src="../../../../assets/test-edit.svg" alt="edit" />
          </button>

          <button
            mat-button
            style="color: #d3247e"
            (click)="openWeightHistoryDialog(true)"
          >
            <img src="../../../assets/history-24px.svg" />
            Weight goal History
          </button>
        </div>
        <div
          *ngIf="disableUpdateWeightGoal()"
          style="color: red; font-size: 1.4rem"
        >
          please fill {{ updateGoalError() }}
        </div>
      </div>
    </mat-list-item>
    <!-- <mat-list-item class="row-container__item">
      <span class="boldSpan"> Caloric Deficit: </span>

      <button style="margin-left: auto" mat-button class="">
        <img src="../../../../assets/test-edit.svg" alt="edit" />
      </button>
    </mat-list-item> -->

    <mat-list-item class="row-container__item">
      <span class="boldSpan"> BMI: </span> {{ calculateBmi() }}
    </mat-list-item>

    <mat-list-item
      class="row-container__item"
      *ngIf="product && product.productTypeId == 1"
    >
      <span class="boldSpan" style="width: 150px; margin-right: 0">
        Blood Test:
      </span>
      <span
        (click)="onBloodTestReportClick()"
        class="text--test"
        style="text-decoration: underline; cursor: pointer; width: 160px"
        >Patient Blood Test</span
      >

      <button (click)="onBloodTestReportEditClick()" mat-button>
        <img src="../../../assets/edit_icon_pink.svg" />
      </button>
      <span
        *ngIf="!bloodTestFilled()"
        style="cursor: default"
        class="round-indicator test"
        >Missing</span
      >
      <span *ngIf="bloodTestFilled()" class="Vcontainer">
        <img src="../../../assets/V.svg" />
      </span>
    </mat-list-item>
    <mat-list-item
      class="row-container__item"
      *ngIf="product && product.productTypeId == 1"
    >
      <span class="boldSpan" style="width: 150px; margin-right: 0">
        Lifestyle Report:
      </span>
      <span
        (click)="onLifestyleReportClick()"
        class="text--test"
        style="text-decoration: underline; cursor: pointer; width: 160px"
        >Patient Lifestyle Report</span
      >
      <button (click)="onLifestyleReportEditClick()" mat-button>
        <img src="../../../assets/edit_icon_pink.svg" />
      </button>
      <span
        *ngIf="!lifestyleReportDone()"
        style="cursor: default"
        class="round-indicator test"
        >Missing</span
      >
      <span *ngIf="lifestyleReportDone()" class="Vcontainer">
        <img src="../../../assets/V.svg" />
      </span>
    </mat-list-item>
    <!--list-item class="row-container__item">
      <span class="boldSpan" style="width: 150px; margin-right: 0">
        Hba1c:
      </span>
      <span
        (click)="onHba1cReportClick()"
        class="text--test"
        style="
          text-decoration: underline;
          cursor: pointer;
          width: 160px;
          white-space: nowrap;
        "
        >Patient Hba1c Report</span
      >
      <button (click)="onHba1cReportEditClick()" mat-button>
        <img src="../../../assets/edit_icon_pink.svg" />
      </button>
      <span
        *ngIf="!sugarFilled()"
        style="cursor: default"
        class="round-indicator test"
        >Missing</span
      >
      
      <span *ngIf="sugarFilled()" class="Vcontainer">
        <img src="../../../assets/V.svg" />
      </span>
      
    </mat-list-item>
-->

    <mat-list-item class="row-container__item" *ngIf="statusCompleted()">
      <span class="boldSpan" style="width: 150px; margin-right: 0">
        Menu:
      </span>
      <!-- <span
        (click)="downloadNutritionReport()"
        class="text--test underline-button"
        >Menu</span
      > -->
      <button
        *ngIf="userHasPermisions(['Menu_GetList'])"
        (click)="goToMenu()"
        class="round-indicator test"
        mat-button
      >
        Menu List
      </button>
      <span class="menu-error" *ngIf="!showMenuListButton()">
        <mat-icon>remove_red_eye</mat-icon> View only, Missing:
        {{ missingMenuDetails() }}
      </span>
      <!-- &nbsp;
      <app-upload-results-csv
        style="margin-left: auto; margin-right: 20px; margin-top: 14px"
        [title]="'Upload'"
        [classAttr]="'patientAttr'"
        [uploadPath]="'patients/uploadNutritionReport/' + productId"
      ></app-upload-results-csv> -->
      <!--
      <button (click)="onNutritionReportEditClick()" mat-button>
        <img src="../../../assets/edit_icon_pink.svg" />
      </button>
      -->
    </mat-list-item>
    <mat-list-item
      *ngIf="this.product.statusId == 63"
      class="row-container__item"
    >
      <span class="boldSpan" style="width: 150px; margin-right: 0">
        Dna Report:
      </span>
      <span class="text--test underline-button" (click)="onDnaReportClick()"
        >Dna Report</span
      >
      <button
        *ngIf="isProductPlus()"
        style="padding: 0"
        mat-button
        class="text--test underline-button"
        [routerLink]="['/recomendations']"
        [queryParams]="{ productId: productId }"
      >
        Recomendations
      </button>
      <!--
      <span
        class="text--test underline-button"
        (click)="onDnaReportDownloadClick()"
      >
        Download Report
      </span>
      -->
      <span
        class="text--test underline-button"
        (click)="onDnaReportGenerateClick()"
      >
        Download Report
      </span>
    </mat-list-item>
  </mat-list>
</div>
