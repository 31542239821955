import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { Status } from 'src/app/data/Status';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-lifestyle-tree',
  templateUrl: './lifestyle-tree.component.html',
  styleUrls: ['./lifestyle-tree.component.css'],
})
export class LifestyleTreeComponent implements OnInit {
  langId;
  @ViewChild('expand') expand: ElementRef;
  designTypes: Status[];
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private statusesService: StatusesService
  ) {}
  lifestyleQuestions: LifestyleQuestion[] = [];
  templates: LifestyleQuestion[] = [];
  ngOnInit(): void {
    this.statusesService.getAll().subscribe((res) => {
      this.designTypes = res.filter(
        (x) => x.category == 'lifeStyleQuestionType'
      );
    });
    this.getAll();
  }
  getAll() {
    this.lifestyleQuestionsService.getAll(this.langId, 3).subscribe((data) => {
      this.lifestyleQuestions = data.root;

      this.templates = data.template;
    });
  }
  addNewQuestion() {
    var question = new LifestyleQuestion();

    question.designTypeId = 232;
    question.level = 0;

    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
    });
  }
  onAddNewQuestion(question) {
    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
      this.expand.nativeElement.click();
    });
  }
  addTemplateQuestion() {
    var question = new LifestyleQuestion();
    question.designTypeId = 232;
    question.level = -1;

    this.lifestyleQuestionsService.post(question).subscribe((res) => {
      this.getAll();
    });
  }
  onDeleteQuestion(question) {
    this.lifestyleQuestionsService.delete(question).subscribe((res) => {
      this.getAll();
    });
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
}
