import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrototypeSnp } from 'src/app/data/algorithm/PrototypeSnp';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-edit-snp',
  templateUrl: './edit-snp.component.html',
  styleUrls: ['./edit-snp.component.scss'],
})
export class EditSnpComponent implements OnInit {
  newSnp: PrototypeSnp;
  snpForm: FormGroup;
  allels = ['A', 'C', 'G', 'T'];
  lettrs: string[] = [
    'alfaLetter',
    'altLetterFirst',
    'altLetterSecond',
    'altLetterThird',
  ];
  errorMessage: string;
  langId: number = 1;
  constructor(
    private dialogRef: MatDialogRef<EditSnpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private algorithmService: AlgorithmService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.data > 0) {
      this.algorithmService.getSnpByNumber(this.data).subscribe((data) => {
        this.newSnp = data;
        this.createSnpForm();
      });
    } else {
      this.newSnp = new PrototypeSnp();
      this.createSnpForm();
    }
  }
  hasFieldValue(fieldName: string) {
    return this.snpForm.get(fieldName)?.value?.toString().length > 0;
  }

  hasValue(propName: string) {
    return this.newSnp[propName]?.toString().length > 0;
  }
  clearSelection(fieldName: string) {
    this.snpForm.get(fieldName).setValue('');
  }
  disableLetter(letter: string) {
    const values = [
      this.AlfaLetter,
      this.AltLetterFirst,
      this.AltLetterSecond,
      this.AltLetterThird,
    ];
    return values.some((l) => l == letter);
  }
  createSnpForm() {
    this.snpForm = this.fb.group({
      snpNumber: [
        { value: this.newSnp.snpNumber, disabled: this.hasValue('snpNumber') },
        [Validators.required, Validators.pattern('^[1-9]{1}[0-9]+$')],
      ],
      chromosome: [
        {
          value: this.newSnp.chromosome,
          disabled: this.hasValue('chromosome'),
        },
        Validators.required,
      ],
      coordinate: [
        {
          value: this.newSnp.coordinate,
          disabled: this.hasValue('coordinate'),
        },
        [Validators.required, Validators.pattern('^[1-9]{1}[0-9]+$')],
      ],
      gene: [
        { value: this.newSnp.gene, disabled: this.hasValue('gene') },
        Validators.required,
      ],
      alfaLetter: [
        {
          value: this.newSnp.alfaLetter,
          disabled: this.hasValue('alfaLetter'),
        },
      ],
      altLetterFirst: [
        {
          value: this.newSnp.altLetterFirst,
          disabled: this.hasValue('altLetterFirst'),
        },
      ],
      altLetterSecond: [
        {
          value: this.newSnp.altLetterSecond,
          disabled: this.hasValue('altLetterSecond'),
        },
      ],
      altLetterThird: [
        {
          value: this.newSnp.altLetterThird,
          disabled: this.hasValue('altLetterThird'),
        },
      ],
    });
  }
  setLettersValidator() {
    const filedNames = [
      { field: 'alfaLetter', isEmpty: false },
      { field: 'altLetterFirst', isEmpty: false },
      { field: 'altLetterSecond', isEmpty: false },
      { field: 'altLetterThird', isEmpty: false },
    ];
    filedNames.map((fn) => {
      const field = this.snpForm.get(fn.field) as FormControl;
      if (!field.value || field.value === '') {
        const index = filedNames.indexOf(fn);
        filedNames[index].isEmpty = true;
      }
    });
    const emptyFields = filedNames.filter((fn) => fn.isEmpty);
    if (emptyFields.length > 2) {
      emptyFields.map((fn) => {
        const field = this.snpForm.get(fn.field) as FormControl;
        field.setErrors({ required: true });
      });
    } else {
      emptyFields.map((fn) => {
        const field = this.snpForm.get(fn.field) as FormControl;
        field.setErrors(null);
      });
    }
  }

  handelResponse(response: ErrorMessage | any) {
    if ((response as ErrorMessage)?.message) {
      const error = response as ErrorMessage;
      this.errorMessage = error.message;
    } else {
      this.errorMessage = null;
      this.dialogRef.close({ type: 'save', data: this.newSnp });
    }
  }
  onSubmit() {
    this.setLettersValidator();
    if (this.snpForm.valid) {
      this.errorMessage = null;
      this.newSnp.snpNumber = this.snpForm.get('snpNumber').value;
      this.newSnp.chromosome = this.snpForm.get('chromosome').value;
      this.newSnp.coordinate = this.snpForm.get('coordinate').value;
      this.newSnp.gene = this.snpForm.get('gene').value;
      this.newSnp.alfaLetter = this.snpForm.get('alfaLetter').value;
      this.newSnp.altLetterFirst = this.snpForm.get('altLetterFirst').value;
      this.newSnp.altLetterSecond = this.snpForm.get('altLetterSecond').value;
      this.newSnp.altLetterThird = this.snpForm.get('altLetterThird').value;
      if (this.data == 0) {
        this.algorithmService
          .postSnp(this.newSnp, this.langId)
          .subscribe((response) => {
            this.handelResponse(response);
          });
      } else {
        this.algorithmService
          .putSnp(this.newSnp, this.langId)
          .subscribe((response) => {
            this.handelResponse(response);
          });
      }
    }
  }

  isFieldDisabled(fieldName: string) {
    return this.snpForm.get(fieldName)?.disabled;
  }

  get AlfaLetter() {
    return this.snpForm.get('alfaLetter').value as FormControl;
  }
  get AltLetterFirst() {
    return this.snpForm.get('altLetterFirst').value;
  }
  get AltLetterSecond() {
    return this.snpForm.get('altLetterSecond').value;
  }
  get AltLetterThird() {
    return this.snpForm.get('altLetterThird').value;
  }
}
