import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Food } from 'src/app/data/Food';
import { FgSimulationParam } from 'src/app/data/food-genes/FgSimulationParam';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { SimulationFormulaResult } from 'src/app/data/food-genes/SimulationFormulaResult';
import { SimulationResult } from 'src/app/data/food-genes/SimulationResult';
import { FoodPublished, FoodWithRecipes } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { FoodLangsService } from 'src/app/Services/food-langs.service';
import { autocomplete } from 'src/app/Utils/autocomplete';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-food-genes-simulator',
  templateUrl: './food-genes-simulator.component.html',
  styleUrls: ['./food-genes-simulator.component.scss'],
})
export class FoodGenesSimulatorComponent implements OnInit {
  simulatorParams: FgSimulationParam[];

  searchTermFood$ = new BehaviorSubject<string>('');
  foodsResults$: Observable<Food[]> = this.searchTermFood$.pipe(
    autocomplete(800, (term: string) => {
      return this.foodLangsService.SearchByName(
        term,
        this.data.langId,
        environment.foodSourceKey,
        FoodWithRecipes.Both,
        FoodPublished.Published
      );
    })
  );
  food: any;
  dataSource: MatTableDataSource<SimulationFormulaResult> =
    new MatTableDataSource([]);
  displayedColumns: string[] = [
    'order',
    'name',
    'formula',
    'condition',
    'result',
  ];
  simulationResult: SimulationResult;
  simulationFormulaResult: SimulationFormulaResult[];

  constructor(
    private foodGenesService: FoodGenesService,
    private foodLangsService: FoodLangsService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FoodGenesSimulatorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      versionId: number;
      langId: number;
    }
  ) {}

  ngOnInit(): void {
    this.getSimulatorParams();
  }

  getSimulatorParams() {
    this.foodGenesService
      .getParamsForSimulator(this.data.versionId)
      .subscribe((data) => {
        this.simulatorParams = data;
      });
  }
  onFoodSelected(food: any) {
    this.food = food;
  }
  setDataSource(data: SimulationFormulaResult[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  recalculate() {
    for (let index = 0; index < this.simulatorParams.length; index++) {
      const element = this.simulatorParams[index];
      if (element.fgParamValue == null) {
        this._snackBar.open('Please fill all fields', 'Close', {
          duration: 2000,
        });
        return;
      }
    }
    if (!this.food || this.food.id == null || this.food.id == 0) {
      this._snackBar.open('Please select food', 'Close', {
        duration: 2000,
      });
      return;
    }

    this.foodGenesService
      .simulateByVersion(
        this.data.versionId,
        this.food.id,
        this.simulatorParams,
        this.data.langId
      )
      .subscribe((data) => {
        this.simulationResult = data;
        this.simulationFormulaResult = data.calculation;
        this.setDataSource(this.simulationFormulaResult);
      });
  }
}
