<div class="edit-panel" [dir]="isRtl()">
  <div
    #innerPanel
    class="edit-panel-inner"
    [class.edit-mode-subcat-name]="subCatEditMode"
  >
    <div style="margin-bottom: 20px; margin-inline-start: 30px">
      <mat-form-field class="example-full-width round-select">
        <mat-select
          (selectionChange)="onselectedVersionChanged($event)"
          style="background-color: white; width: 180px"
          [(ngModel)]="selectedVersionId"
        >
          <mat-option
            [value]="version.versionId"
            *ngFor="let version of algorithmVersions"
          >
            {{ version.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="background-color: #1b3f4e; width: 100%">
      <label class="search">
        <input type="text" #serchRs placeholder="חפש מספר rs" />
        <button (click)="onSearchRs(serchRs.value)" type="button"></button>
      </label>

      <!--  <div
        *ngIf="isNewDesign"
        style="display: flex; margin-right: 30px"
        (click)="addNewCategory()"
      >
        <div><img src="../../assets/addIcon.png" /></div>
        <div style="margin-right: 10px">
          <span style="color: white; height: 40px; line-height: 40px"
            >לחץ להוספת קטגוריה</span
          >
        </div>
      </div> -->
    </div>

    <mat-accordion *ngIf="categories" style="background-color: #1b3f4e">
      <mat-expansion-panel
        *ngFor="let category of categories; let i = index"
        (opened)="
          onCategoryClick($event, category.categoryId, category.color, i)
        "
        (closed)="onCategoryClosed()"
        style="background-color: #1b3f4e"
        class="mat-elevation-z"
        #panels
      >
        <mat-expansion-panel-header class="panel-header">
          <mat-panel-title class="cat-title">
            <span
              class="dot"
              [ngStyle]="{ 'background-color': '#' + category.color }"
            ></span>
            {{ category.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <ng-container *ngFor="let subcategory of category.subCategoryLangs">
            <mat-list-item
              class="panel-header__item"
              [ngStyle]="{
                'background-color':
                  selectedSubCategory &&
                  subcategory.subCategoryId == selectedSubCategory.subCategoryId
                    ? '#' + category.color
                    : '#1b3f4e'
              }"
              (click)="onSubCategoryClick($event, category, subcategory)"
            >
              <button
                (click)="stopPropegation($event)"
                mat-icon-button
                aria-label="Example icon-button with a menu"
                [matMenuTriggerFor]="subCatMenu"
                [disabled]="!canEdit()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #subCatMenu="matMenu">
                <button mat-menu-item (click)="openEditSubCatName(subcategory)">
                  <mat-icon>edit</mat-icon>
                  <span>ערוך תת קטגוריה</span>
                </button>
                <button mat-menu-item (click)="addSubCat(category.categoryId)">
                  <mat-icon>content_copy</mat-icon>
                  <span>הוסף תת קטגוריה</span>
                </button>
                <button
                  mat-menu-item
                  (click)="deleteSubCat(subcategory)"
                  *ngIf="selectedVersionId != -1"
                >
                  <mat-icon>delete</mat-icon>
                  <span>מחק</span>
                </button>
              </mat-menu>

              <span
                *ngIf="subCatIdToEdit != subcategory.subCategoryId"
                class="subcategory-name"
                >{{ subcategory.name }}</span
              >
              <mat-form-field
                *ngIf="
                  subCatIdToEdit == subcategory.subCategoryId && subCatEditMode
                "
                class="edit-subcat-name"
                appearance="fill"
              >
                <input matInput [(ngModel)]="subcategory.name" type="text" />
              </mat-form-field>
              <ng-container
                *ngIf="
                  subCatIdToEdit == subcategory.subCategoryId && subCatEditMode
                "
              >
                <button mat-icon-button (click)="saveSubCatName(subcategory)">
                  <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button (click)="closeEditSubCatName()">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>

              <img
                src="../../assets/arrowLeft.png"
                style="margin-inline-start: auto; height: 13px"
                [ngStyle]="{
                  transform: isRtl() == 'ltr' ? 'rotate(180deg)' : 'none'
                }"
                *ngIf="subCatIdToEdit != subcategory.subCategoryId"
              />
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
          <ng-container
            *ngIf="category.missingSubCategoryLangs.length > 0 && canEdit()"
          >
            ><mat-list-item>
              <mat-form-field appearance="fill" class="subcat-select">
                <mat-select #subCatSelect placeholder="תת קטגוריה">
                  <mat-option
                    *ngFor="let subcategory of category.missingSubCategoryLangs"
                    [value]="subcategory.subCategoryId"
                  >
                    {{ subcategory.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="subCatToAddError">
              <div style="color: #fff">
                Please choose sub-category
              </div></mat-list-item
            >
            <mat-list-item>
              <div
                class="add-btn"
                (click)="addSubCatToVersion(subCatSelect.value, i)"
              >
                <button mat-icon-button>
                  <mat-icon>add</mat-icon>
                </button>
                <span style="color: #fff"> הוסף תת קטגוריה</span>
              </div>
            </mat-list-item></ng-container
          >
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
    <div
      class="d-flex-center"
      style="justify-content: center; margin-top: 1rem"
    >
      <ng-container *ngFor="let language of languages">
        <img
          src="../../assets/lang-icon-{{ language.languageId }}.svg"
          style="cursor: pointer; margin: 5px"
          [ngStyle]="{
            transform: language.languageId == 2 ? 'translateY(-3px)' : 'none'
          }"
          [ngStyle]="{
            filter: language.languageId != langId ? 'opacity(0.5)' : 'none'
          }"
          (click)="onChangeLang(language.languageId)"
        />
      </ng-container>
    </div>
  </div>
  <mat-sidenav
    style="background-color: #dadada"
    #sidenav
    mode="side"
    [(opened)]="isSideNavOpened"
    (opened)="events.push('open!')"
    (closed)="events.push('close!')"
  >
    <div
      style="
        width: 280px;
        background-color: #dadada;
        padding-top: 90px;
        position: fixed;
        overflow: auto;
        height: calc(100vh - 80px);
      "
      [style.margin-inline-start.px]="subCategoryPanelWidth"
    >
      <!-- <div style="display: flex">
        <div style="margin-right: 28px">
          <img src="../../assets/addIconBlue.png" />
        </div>
        <div style="margin-right: 10px">
          <span style="color: GRAY; height: 40px; line-height: 40px"
            >לחץ להוספת rs</span
          >
        </div>
      </div> -->
      <ng-container *ngIf="selectedSubCategory != null">
        <form *ngIf="canEdit()" class="example-form" style="padding: 0 10px">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>RS Number</mat-label>
            <input
              [(ngModel)]="selectedRsNum"
              type="text"
              placeholder="RS Number"
              aria-label="Number"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredRsNums | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div style="color: red" *ngIf="selectedRsNumError">
            RS Nunber is required
          </div>
          <div class="add-btn" *ngIf="isNewDesign" (click)="addNewSnp()">
            <button mat-icon-button (click)="addNewRs()">
              <mat-icon>add</mat-icon>
            </button>
            <span>לחץ להוספת snp</span>
          </div>
        </form>
        <mat-list class="rs-list">
          <mat-divider></mat-divider>
          <ng-container *ngFor="let snp of subCatForLang?.snps">
            <mat-list-item
              (click)="onSnpClick($event, selectedSubCategory, snp)"
              class="rs-list__item"
              [ngStyle]="{
                'background-color': selectedSnp == snp ? '#FFFFFF' : '#DADADA'
              }"
            >
              <button
                (click)="stopPropegation($event)"
                mat-icon-button
                aria-label="Example icon-button with a menu"
                [matMenuTriggerFor]="snpMenu"
                [disabled]="!canEdit()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #snpMenu="matMenu">
                <!--<button mat-menu-item (click)="editSnp()">
                  <mat-icon>edit</mat-icon>
                  <span>ערוך Snp</span>
                </button>
                  <button mat-menu-item (click)="duplicateSnp()">
                  <mat-icon>content_copy</mat-icon>
                  <span>שכפל Snp</span>
                </button> -->

                <button
                  mat-menu-item
                  (click)="deleteSnpFromVersion(snp)"
                  *ngIf="selectedVersionId != -1"
                >
                  <mat-icon>delete</mat-icon>
                  <span>מחק מהגירסא</span>
                </button>
              </mat-menu>

              <span class="rs-name">rs{{ snp }}</span>
              <img
                src="../../assets/arrowLeft.png"
                style="margin-inline-start: auto; margin-inline-end: 20px"
                [ngStyle]="{
                  transform: isRtl() == 'ltr' ? 'rotate(180deg)' : 'none'
                }"
              />
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
          <mat-list-item
            class="rs-list__item"
            (click)="onSubCategorySummeryClick($event, selectedSubCategory)"
            style="padding: 0px"
            [ngStyle]="{
              'background-color': isCatSumeryShowed ? '#FFFFFF' : '#DADADA'
            }"
          >
            <div
              style="width: 10px; height: 100%; margin-inline-end: 20px"
              [ngStyle]="{
                'background-color': '#' + selectedCategoryColor
              }"
            ></div>

            <!-- <img src="../../assets/dotsGray.png" style="margin-right: 20px; margin-left: 20px;">-->

            <span class="rs-name"
              >סיכום &nbsp;
              <span *ngIf="!editSelectedSubCatNameMode">
                {{ selectedSubCategory && selectedSubCategory.name }}
              </span></span
            >

            <input
              *ngIf="editSelectedSubCatNameMode"
              [(ngModel)]="selectedSubCategory.name"
            />

            <mat-icon
              *ngIf="canEdit() && !editSelectedSubCatNameMode"
              aria-hidden="false"
              aria-label="Example home icon"
              (click)="editSelectedSubCatName()"
              >edit</mat-icon
            >
            <mat-icon
              *ngIf="editSelectedSubCatNameMode"
              aria-hidden="false"
              aria-label="Example home icon"
              style="margin-inline-start: auto"
              (click)="saveSelectedSubCatName()"
              >save</mat-icon
            >
            <img
              src="../../assets/arrowLeft.png"
              style="margin-inline-start: auto; margin-inline-end: 20px"
              [ngStyle]="{
                transform: isRtl() == 'ltr' ? 'rotate(180deg)' : 'none'
              }"
            />
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
      </ng-container>
    </div>
  </mat-sidenav>
  <div
    class="snpDetails"
    *ngIf="isSideNavOpened && selectedSnp && snpForLang"
    style="
      width: 1340px;
      height: 1000px;
      background-color: #f9f9f9;
      color: #1b3f4e;
      padding: 40px;
    "
    [ngStyle]="{
      'margin-inline-start': 'calc(280px + ' + subCategoryPanelWidth + 'px)'
    }"
  >
    <h1 style="color: #1b3f4e; text-align: center">
      rs-{{ selectedSnp }} {{ selectedSubCategory.name }}
    </h1>
    <div style="margin-bottom: 15px; margin-top: 35px; text-align: center">
      <mat-card
        *ngIf="isNewDesign"
        style="text-align: center; display: flex; justify-content: space-around"
      >
        <div>
          <span>שם הגנוטיפ :</span>
          <input
            type="text"
            (change)="saveSnpLang()"
            [(ngModel)]="snpForLang.geneName"
            [disabled]="!canEdit()"
          />
        </div>
        <div>
          <span>קואודינטה : </span>
          <input
            type="text"
            (change)="saveSnpLang()"
            [(ngModel)]="snpForLang.coordinate"
            [disabled]="!canEdit()"
          />
        </div>
        <div>
          <span>כרומוזום : </span>
          <input
            type="text"
            (change)="saveSnpLang()"
            [(ngModel)]="snpForLang.chromosome"
            [disabled]="!canEdit()"
          />
        </div>
      </mat-card>
      <mat-card *ngIf="!isNewDesign">
        {{ snpForLang.geneName }}
      </mat-card>
    </div>
    <div style="display: flex; margin-bottom: 15px">
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px"
        >גנוטיפ 1</mat-card
      >
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px"
        >גנוטיפ 2</mat-card
      >
      <mat-card style="flex-grow: 1; text-align: center">גנוטיפ 3</mat-card>
    </div>
    <div style="display: flex; margin-bottom: 15px">
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px">
        <input
          (change)="saveSnpResultLang(0)"
          type="number"
          [(ngModel)]="
            snpForLang.snpResultLangs[0] && snpForLang.snpResultLangs[0].value
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px">
        <input
          (change)="saveSnpResultLang(1)"
          type="number"
          [(ngModel)]="
            snpForLang.snpResultLangs[1] && snpForLang.snpResultLangs[1].value
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
      <mat-card style="flex-grow: 1; text-align: center">
        <input
          (change)="saveSnpResultLang(2)"
          type="number"
          [(ngModel)]="
            snpForLang.snpResultLangs[2] && snpForLang.snpResultLangs[2].value
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
    </div>
    <div style="display: flex; margin-bottom: 15px">
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px">
        <input
          (change)="saveSnpResultLang(0)"
          [(ngModel)]="
            snpForLang.snpResultLangs[0] && snpForLang.snpResultLangs[0].result
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
      <mat-card style="flex-grow: 1; text-align: center; margin-left: 10px">
        <input
          (change)="saveSnpResultLang(1)"
          [(ngModel)]="
            snpForLang.snpResultLangs[1] && snpForLang.snpResultLangs[1].result
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
      <mat-card style="flex-grow: 1; text-align: center">
        <input
          (change)="saveSnpResultLang(2)"
          [(ngModel)]="
            snpForLang.snpResultLangs[2] && snpForLang.snpResultLangs[2].result
          "
          [disabled]="!canEdit()"
        />
      </mat-card>
    </div>
    <div style="display: flex; margin-bottom: 15px">
      <mat-card style="width: 33%; margin-left: 10px" (click)="editSnpDesc(0)">
        <ng-container *ngIf="editSnpResultInx != 0">
          סיכום
          <br />
          {{
            snpForLang.snpResultLangs &&
              snpForLang.snpResultLangs[0] &&
              snpForLang.snpResultLangs[0].description
          }}
        </ng-container>
        <ng-container *ngIf="editSnpResultInx == 0">
          <textarea
            style="width: 100%; height: 90%"
            [(ngModel)]="
              snpForLang.snpResultLangs[0] &&
              snpForLang.snpResultLangs[0].description
            "
          ></textarea>
          <button (click)="saveSnpDesc(0)">save</button>
        </ng-container>
      </mat-card>
      <mat-card style="width: 33%; margin-left: 10px" (click)="editSnpDesc(1)">
        <ng-container *ngIf="editSnpResultInx != 1">
          סיכום
          <br />
          {{
            snpForLang.snpResultLangs &&
              snpForLang.snpResultLangs[1] &&
              snpForLang.snpResultLangs[1].description
          }}
        </ng-container>
        <ng-container *ngIf="editSnpResultInx == 1">
          <textarea
            style="width: 100%; height: 90%"
            [(ngModel)]="
              snpForLang.snpResultLangs[1] &&
              snpForLang.snpResultLangs[1].description
            "
          ></textarea>
          <button (click)="saveSnpDesc(1)">save</button>
        </ng-container>
      </mat-card>
      <mat-card style="width: 33%; text-align: right" (click)="editSnpDesc(2)">
        <ng-container *ngIf="editSnpResultInx != 2">
          סיכום
          <br />
          {{
            snpForLang.snpResultLangs &&
              snpForLang.snpResultLangs[2] &&
              snpForLang.snpResultLangs[2].description
          }}
        </ng-container>
        <ng-container *ngIf="editSnpResultInx == 2">
          <textarea
            style="width: 100%; height: 90%"
            [(ngModel)]="
              snpForLang.snpResultLangs[2] &&
              snpForLang.snpResultLangs[2].description
            "
          ></textarea>
          <button (click)="saveSnpDesc(2)">save</button>
        </ng-container>
      </mat-card>
    </div>
  </div>
  <div
    *ngIf="isSideNavOpened && isCatSumeryShowed"
    style="
      width: 1340px;
      height: 1000px;
      background-color: #f9f9f9;
      padding: 40px;
    "
    [ngStyle]="{
      'margin-inline-start': 'calc(280px + ' + subCategoryPanelWidth + 'px)',
      'text-align': isRtl() === 'rtl' ? 'right' : 'left'
    }"
  >
    <div class="select-template-row">
      <ng-container *ngFor="let item of subCatForLang?.subCategoryResultLangs">
        <div style="display: flex; justify-content: center" *ngIf="canEdit()">
          <div class="img-box">
            <div *ngIf="!item.imagePath">
              <div
                (click)="openFileManager(item.subCategoryResultId)"
                class="img-box"
              >
                <mat-icon>cloud_upload</mat-icon>
                Add Image
              </div>
              <div *ngIf="imgError" style="color: red; margin-top: 1rem">
                {{ imgError }}
              </div>
            </div>

            <img
              *ngIf="item.imagePath"
              style="
                max-width: 100%;
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 auto;
              "
              (click)="openFileManager(item.subCategoryResultId)"
              [src]="item.imagePath"
              alt=""
            />
          </div>
          <button
            class="clear-image"
            mat-button
            *ngIf="algoImage"
            (click)="algoImage = null"
          >
            Clear image
          </button>
        </div>

        <!-- <app-select-dna-template [subCategory]="item"></app-select-dna-template>-->
      </ng-container>
      <!-- <input type="checkbox" checked="subCatForLang && subCatForLang.subCategoryResultLangs.length==3" >-->
    </div>
    <button
      (click)="setSubCatNumResults(3)"
      *ngIf="subCatForLang && subCatForLang.subCategoryResultLangs.length == 2"
    >
      מעבר ל3 תשובות
    </button>
    <button
      (click)="setSubCatNumResults(2)"
      *ngIf="subCatForLang && subCatForLang.subCategoryResultLangs.length == 3"
    >
      מעבר ל2 תשובות
    </button>

    <h1 style="color: #1b3f4e; text-align: center; text-align: center">
      סיכום {{ selectedSubCategory && selectedSubCategory.name }}
    </h1>
    <!--
    <div>
        <mat-card>ADIQR</mat-card>
    </div>
    -->
    <div
      style="display: flex; margin-bottom: 10px; margin-top: 35px"
      *ngIf="subCatForLang?.subCategoryResultLangs?.length > 0"
    >
      <mat-card
        style="width: 33%; text-align: center; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[0], 3)"
        [innerHtml]="subCatForLang.subCategoryResultLangs[0].name"
      >
        {{ subCatForLang.subCategoryResultLangs[0].name }}</mat-card
      >
      <mat-card
        style="width: 33%; text-align: center; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[1], 3)"
        [innerHtml]="subCatForLang.subCategoryResultLangs[1].name"
      >
        {{ subCatForLang.subCategoryResultLangs[1].name }}</mat-card
      >
      <mat-card
        style="width: 33%; text-align: center"
        *ngIf="subCatForLang.subCategoryResultLangs.length > 2"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[2], 3)"
        [innerHtml]="subCatForLang.subCategoryResultLangs[2].name"
      >
        {{ subCatForLang.subCategoryResultLangs[2].name }}</mat-card
      >
    </div>
    <div
      style="display: flex; margin-bottom: 10px"
      *ngIf="subCatForLang?.subCategoryResultLangs?.length > 0"
    >
      <mat-card style="width: 33%; text-align: center; margin-left: 10px">
        <!---
       <b> min :</b>
       <span (click)="changeEditCatSumInx(1)" *ngIf="editCatSumInx!=1">
        {{
          subCatForLang.subCategoryResultLangs[0].minValue
        }}
       </span>
       <input *ngIf="editCatSumInx==1" [(ngModel)]="electedSubCategory.subCategoryResults[0].minValue">
       ,
      <b>max </b>-->
        <span> ערך מינימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[0].minValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[0].minValue"
        />
        <span> ערך מקסימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[0].maxValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[0].maxValue"
        />
        <mat-icon
          style="position: relative; top: 7px; cursor: pointer"
          *ngIf="canEdit()"
          (click)="saveMinMaxChanges(0)"
          >save</mat-icon
        >
      </mat-card>
      <mat-card style="width: 33%; text-align: center; margin-left: 10px">
        <span> ערך מינימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[1].minValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[1].minValue"
        />
        <span> ערך מקסימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[1].maxValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[1].maxValue"
        />
        <mat-icon
          style="position: relative; top: 7px; cursor: pointer"
          *ngIf="canEdit()"
          (click)="saveMinMaxChanges(1)"
          >save</mat-icon
        >
      </mat-card>
      <mat-card
        style="width: 33%; text-align: center"
        *ngIf="subCatForLang.subCategoryResultLangs.length > 2"
      >
        <span> ערך מינימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[2].minValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[2].minValue"
        />
        <span> ערך מקסימלי: </span>
        <span *ngIf="!canEdit()">
          {{ subCatForLang.subCategoryResultLangs[2].maxValue }}
        </span>
        <input
          style="width: 50px"
          *ngIf="canEdit()"
          [(ngModel)]="subCatForLang.subCategoryResultLangs[2].maxValue"
        />
        <mat-icon
          style="position: relative; top: 7px; cursor: pointer"
          *ngIf="canEdit()"
          (click)="saveMinMaxChanges(2)"
          >save</mat-icon
        >
      </mat-card>
    </div>
    <div
      style="display: flex; margin-bottom: 10px"
      *ngIf="subCatForLang?.subCategoryResultLangs?.length > 0"
    >
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[0], 1)"
      >
        <span style="font-weight: bold"> סיכום קצר </span>
        <br />
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[0].shortDescription"
        >
        </span>
      </mat-card>
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[1], 1)"
      >
        <span style="font-weight: bold"> סיכום קצר </span>
        <br />
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[1].shortDescription"
        >
        </span>
      </mat-card>
      <mat-card
        style="width: 33%"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[2], 1)"
        *ngIf="subCatForLang.subCategoryResultLangs.length > 2"
      >
        <span style="font-weight: bold"> סיכום קצר </span>
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[2].shortDescription"
        >
        </span>
      </mat-card>
    </div>
    <div
      style="display: flex; margin-bottom: 10px"
      *ngIf="subCatForLang?.subCategoryResultLangs?.length > 0"
    >
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[0], 1)"
      >
        <span style="font-weight: bold"> סיכום </span>
        <br />
        <span [innerHtml]="subCatForLang.subCategoryResultLangs[0].description">
        </span>
      </mat-card>
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[1], 1)"
      >
        <span style="font-weight: bold"> סיכום </span>
        <br />
        <span [innerHtml]="subCatForLang.subCategoryResultLangs[1].description">
        </span>
      </mat-card>
      <mat-card
        style="width: 33%"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[2], 1)"
        *ngIf="subCatForLang.subCategoryResultLangs.length > 2"
      >
        <span style="font-weight: bold"> סיכום </span>
        <span [innerHtml]="subCatForLang.subCategoryResultLangs[2].description">
        </span>
      </mat-card>
    </div>
    <div
      style="display: flex; margin-bottom: 10px"
      *ngIf="subCatForLang?.subCategoryResultLangs?.length > 0"
    >
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[0], 2)"
      >
        <span style="font-weight: bold"> המלצות </span>
        <br />
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[0].recomendations"
        >
        </span>
      </mat-card>
      <mat-card
        style="width: 33%; margin-left: 10px"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[1], 2)"
      >
        <span style="font-weight: bold"> המלצות </span>
        <br />
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[1].recomendations"
        >
        </span>
      </mat-card>
      <mat-card
        style="width: 33%; text-align: right"
        (click)="editSubCatProperty(subCatForLang.subCategoryResultLangs[2], 2)"
        *ngIf="subCatForLang.subCategoryResultLangs.length > 2"
      >
        <span style="font-weight: bold"> המלצות </span>
        <br />
        <span
          [innerHtml]="subCatForLang.subCategoryResultLangs[2].recomendations"
        >
        </span>
      </mat-card>
    </div>
  </div>
</div>
