<mat-card>
  <form (ngSubmit)="onSubmit(noteForm)" #noteForm="ngForm">
    <div class="card-title">
      <ng-container *ngIf="hasNoteId()"> Edit note </ng-container>
      <ng-container *ngIf="!hasNoteId()"> New note </ng-container>
    </div>
    <div
      class="divider"
      [class.divider--client]="classAttr == 'clientAttr'"
      [class.divider--lead]="classAttr == 'leadAttr'"
      [class.divider--lead]="classAttr == 'testAttr'"
    ></div>
    <div style="width: 100%">
      <div class="new-date">
        {{ today | date: "medium" }}

        <app-upload-results-csv
          *ngIf="hasNoteId()"
          style="float: right"
          [title]="'Attach File'"
          [uploadPath]="getFilesUploadPath()"
          (onUploadFinished)="uploadFileFinished($event)"
          [classAttr]="'notesAttr'"
        ></app-upload-results-csv>
      </div>
    </div>
    <mat-form-field class="example-full-width" style="width: 100%">
      <textarea
        class="edit-field"
        matInput
        placeholder="message"
        [(ngModel)]="noteContent"
        (ngModelChange)="onNoteChange($event)"
        name="title"
        required
        style="width: 100%; height: 200px; text-align: right; direction: rtl"
      ></textarea>
    </mat-form-field>

    <div
      (click)="downloadNoteImage()"
      *ngIf="hasNoteImage()"
      style="
        display: flex;
        border: 0.2px solid rgba(0, 174, 238, 0.2);
        padding: 2px;
        justify-content: center;
        cursor: pointer;
      "
    >
      <mat-icon
        style="position: relative; transform: rotate(-90deg); cursor: pointer"
        aria-hidden="false"
        aria-label="Example home icon"
        >attachment</mat-icon
      >
      <span>
        {{ getImageFileName() }}
      </span>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      "
    >
      <button
        class="btn-submit"
        [class.btn-submit--lead]="classAttr == 'leadAttr'"
        [class.btn-submit--client]="classAttr == 'clientAttr'"
        [class.btn-submit--test]="classAttr == 'testAttr'"
        style="margin-right: 20px"
        type="submit"
      >
        Submit
      </button>
      <button
        class="btn-cancel"
        [class.btn-cancel--lead]="classAttr == 'leadAttr'"
        [class.btn-cancel--client]="classAttr == 'clientAttr'"
        (click)="onCancelNewNote()"
      >
        Cancel
      </button>
    </div>
  </form>
</mat-card>
