import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from '../data/Product';
import { TubeTestCategoryResult } from '../data/TubeTestCategoryResult';
import { CatergoriesService } from '../Services/catergories.service';
import { ProductsService } from '../Services/products.service';
import { RegularUsersService } from '../Services/regular-users.service';

@Component({
  selector: 'app-dna-report-view-v1',
  templateUrl: './dna-report-view-v1.component.html',
  styleUrls: ['./dna-report-view-v1.component.scss'],
})
export class DnaReportViewV1Component implements OnInit {
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  categories: TubeTestCategoryResult[];
  productId: any;
  patientName: string;
  constructor(
    private regularUsersService: RegularUsersService,
    private productsService: ProductsService,
    private catergoriesService: CatergoriesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.productId = params['productId'];
      this.catergoriesService.getAllV1(this.productId).subscribe((data) => {
        this.categories = data;
      });
      this.productsService.getById(this.productId).subscribe((data) => {
        if ((data as Product).productId) {
          var firstName = (data as Product).patient.regularUser.firstName ?? '';
          var lastName = (data as Product).patient.regularUser.lastName ?? '';
          this.patientName = firstName + ' ' + lastName;
        } else {
        }
      });
      /*
    this.regularUsersService.currentUser.subscribe((user) => {
      var productId = user.patient.products[0].productId;
      this.catergoriesService.getAll(productId).subscribe((data) => {
        this.categories = data;
        var a = this.categories[0];
        var a1 = a.category.categoryName;
        var a2 = a.rankOneCount;
      });
    });
    */
    });
  }
  onEditClick() {
    this.onEdit.emit();
  }
  goToCategory(categoryId: number) {
    this.router.navigate(['tests/edit/dna-report-inner'], {
      queryParams: {
        productId: this.productId,
        categoryId: categoryId,
      },
    });
  }
  goBack() {
    this.router.navigate(['tests/edit'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
  goToCalculation() {
    this.router.navigate(['dna-calculations'], {
      queryParams: {
        productId: this.productId,
      },
    });
  }
}
