import { AlgoSubCategoryResultType } from './AlgoSubCategoryResultType';
import { AppImage } from './AppImage';

export class AlgoSubCategory {
  subCategoryId: number;
  parentCategoryId: number;
  langId: number;
  name: string;
  description: string;
  labVersionId: number;
  prototypeSubCategoryId: number;
  lastUpdated: string;
  files: AppImage[];
  resultTypes: AlgoSubCategoryResultType[];
  orderBy: number;
  rangeStart: number;
  rangeEnd: number;
  validExceptPersent: number;
  isActive: boolean;
}
