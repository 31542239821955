<ngx-skeleton-loader
  *ngIf="!subCatResultTypes && !result"
  [theme]="{ height: '30px' }"
  count="15"
></ngx-skeleton-loader>
<ng-container *ngIf="subCatResultTypes && result">
  <nav class="result-nav" mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      *ngFor="let type of subCatResultTypes; let i = index"
      (click)="onSelectType(type)"
      [active]="activeType?.resultTypeId == type.resultTypeId"
      class="result-nav__item"
      [ngClass]="{
        selected: activeType?.resultTypeId == type.resultTypeId
      }"
      [ngStyle]="{
        'background-color': type.color,
        color: type.color ? '#ffffff' : '#000000'
      }"
      >{{ type.name }}
    </a>
    <a
      *ngIf="availableResultTypes?.length > 0 && canEdit"
      mat-tab-link
      (click)="addNewType()"
      class="result-nav__item new"
    >
      <mat-icon>add_circle_outline</mat-icon>
      &nbsp; הוספת נטייה
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <mat-card>
      <div class="label">כללי:</div>
      <div
        class="pad-start-2"
        fxLayout="row"
        fxLayoutAlign="space-between start"
      >
        <div
          class="box-border"
          fxFlex="68"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="37px"
        >
          <div>
            מספר עמוד ב PDF
            <input
              [disabled]="!canEdit"
              class="pdf-input"
              type="number"
              [(ngModel)]="result.pdfPageNum"
            />
          </div>
          <div>
            <app-algo-image
              [style.pointer-events]="canEdit ? 'auto' : 'none'"
              isRectangle="true"
              textBox="תמונת בר נטיות"
              [langId]="langId"
              [canEdit]="canEdit"
              [algoImageType]="AlgoImageType.AlgoResultTypePass"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getAlgoImage(AlgoImageType.AlgoResultTypePass)"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoResultTypePass)"
            ></app-algo-image>
          </div>
          <div style="position: relative" *ngIf="canEdit">
            <button
              [style.color]="result.resultType.color"
              style="background: rgb(76, 180, 178, 0.3)"
              (click)="colorPicker.click()"
              mat-icon-button
            >
              <mat-icon>palette</mat-icon>
            </button>
            <input
              [(ngModel)]="result.resultType.color"
              type="color"
              #colorPicker
              style="visibility: hidden; position: absolute; top: 10px; left: 0"
            />
          </div>
        </div>
        <div>
          <button
            *ngIf="canEdit"
            (click)="removeResultType()"
            class="delete-result"
            mat-button
            [disabled]="subCatResultTypes.length == 1"
          >
            <mat-icon>delete_forever</mat-icon>
            מחק נטייה
          </button>
        </div>
      </div>
      <div class="mb-2"></div>
      <div class="label">שם נטייה:</div>
      <div
        class="pad-start-2"
        fxLayout="row"
        fxLayout.md="column"
        fxLayout.sm="column"
      >
        <div fxFlex="68" fxLayout="row" fxLayoutAlign="start start">
          <textarea
            [style.border]="resultTypeNameError ? '1px solid red' : ''"
            class="name-editor"
            [placeholder]="'Enter type name here...'"
            [(ngModel)]="result.resultType && result.resultType.name"
            rows="4"
          ></textarea>
          <!-- <angular-editor
            [style.border]="resultTypeNameError ? '1px solid red' : ''"
            class="name-editor"
            [config]="nameEditorConfig"
            [placeholder]="'Enter type name here...'"
            [(ngModel)]="result.resultType && result.resultType.name"
            >{{ result.name }}</angular-editor
          > -->
        </div>
        <div fxFlex="2"></div>
        <div fxFlex="30" fxLayout="row" fxLayoutGap="10px">
          <div
            fxFlex="100"
            class="box-border"
            fxLayout="row"
            fxLayoutAlign="center"
          >
            <div class="range">
              <input
                [style.border]="resultMinError ? '1px solid red' : ''"
                type="number"
                [disabled]="!canEdit"
                [(ngModel)]="result.min"
                style="width: 50px; overflow: hidden; text-overflow: ellipsis"
              />
              אחוז מינימלי
            </div>
            <div class="range">
              <input
                [style.border]="resultMaxError ? '1px solid red' : ''"
                type="number"
                [disabled]="!canEdit"
                [(ngModel)]="result.max"
                style="width: 50px; overflow: hidden; text-overflow: ellipsis"
              />
              אחוז מקסימלי
            </div>
          </div>
          <!-- <div fxFlex="50">
            <app-algo-image
              [canEdit]="canEdit"
              [langId]="langId"
              [algoImageType]="AlgoImageType.AlgoResultTypePass"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getAlgoImage(AlgoImageType.AlgoResultTypePass)"
            ></app-algo-image>
          </div> -->
        </div>
      </div>
      <div class="mb-2"></div>
      <div class="label">סיכום קצר:</div>
      <div class="pad-start-2" fxLayout="row">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
          <angular-editor
            class="name-editor"
            [config]="editorConfig"
            [placeholder]="'Enter type name here...'"
            [(ngModel)]="result.shortDescription"
            >{{ result.name }}</angular-editor
          >
        </div>
      </div>
      <div class="mb-2"></div>
      <div class="label">סיכום:</div>
      <div class="pad-start-2" fxLayout="row">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
          <angular-editor
            class="name-editor"
            [config]="editorConfig"
            [placeholder]="'Enter type name here...'"
            [(ngModel)]="result.description"
            >{{ result.name }}</angular-editor
          >
        </div>
      </div>
      <div class="mb-2"></div>
      <div class="label">המלצות:</div>
      <div class="pad-start-2 recomendations" fxLayout="column">
        <div
          *ngFor="let item of result.recomendations; let i = index"
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="item"
          [ngClass]="{ right: dir === 'rtl', left: dir === 'ltr' }"
        >
          <angular-editor
            class="name-editor"
            [config]="editorConfig"
            [placeholder]="'Enter type name here...'"
            [(ngModel)]="item.recomendations"
            >{{ result.name }}</angular-editor
          >
          <button
            *ngIf="canEdit"
            fxFlexAlign="end"
            type="button"
            (click)="removeRecomendation(i)"
            mat-icon-button
            class="remove-rec"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
      </div>
      <button
        class="add-rec"
        *ngIf="canEdit"
        mat-button
        (click)="addRecomendation()"
      >
        <mat-icon>add_circle_outline</mat-icon>
        הוספה
      </button>
      <div class="mb-2"></div>
      <div class="action" *ngIf="canEdit">
        <button
          style="margin-right: auto; display: block"
          mat-raised-button
          (click)="save()"
          class="save-result"
        >
          שמירה
        </button>
      </div>
    </mat-card>
  </mat-tab-nav-panel>
</ng-container>
