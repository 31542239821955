import {
  AfterContentChecked,
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
//import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from '@kolkov/angular-editor';
import { DynamicAtachmentFile } from 'src/app/data/DynamicAtachmentFile';
import { DynamicType } from 'src/app/data/DynamicType';
import { EmailTemplate } from 'src/app/data/EmailTemplate';
import { EmailTemplateDynamicAttachment } from 'src/app/data/EmailTemplateDynamicAttachment';
import { EmailTemplateLang } from 'src/app/data/EmailTemplateLang';
import { FileUpload } from 'src/app/data/FileUpload';
import { Language } from 'src/app/data/Language';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { DynamicAtachmentFilesService } from 'src/app/Services/dynamic-atachment-files.service';
import { EmailTemplatesLangsService } from 'src/app/Services/email-templates-langs.service';
import { EmailTemplatesService } from 'src/app/Services/email-templates.service';
import { FileUploadsService } from 'src/app/Services/file-uploads.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { TriggerEventObjectTypesService } from 'src/app/Services/trigger-event-object-types.service';
import { isEmpty } from 'src/app/Utils/general-helper';

@Component({
  selector: 'app-edit-auto-responder',
  templateUrl: './edit-auto-responder.component.html',
  styleUrls: ['./edit-auto-responder.component.scss'],
})
export class EditAutoResponderComponent implements OnInit {
  @ViewChild('textEmailField') textEmailField: AngularEditorComponent;
  @ViewChild('textSmsField') textSmsField: ElementRef;
  templateId: number;
  langId: number = 1; //Hebrew
  emailTemplateLang: EmailTemplateLang;
  emailTemplate: EmailTemplate;
  showPreview: boolean = false;
  fullPreview: boolean = false;
  languages: Language[];
  previewConfig: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    minHeight: '350px',
  };
  legends: string[] = [];
  /*
    'clientName',
    'agentName',
    'siteLink',
    'registerLink',
    // 'Login Link',
    //  'DNA Result Link',
    // 'LifeStyle Form Link',
    'numPayments',
    'totalAmount',
    //'Days From Arrived To Costumer',
    // 'requestFeedbackLink',
  ];
  */
  editorFocused: any;
  templateSuccess: boolean;
  LangSuccess: boolean;
  isDirty: boolean = false;
  errors: any;
  dynamicAtachmentFiles: DynamicAtachmentFile[];
  selectedDynamicAtachmentFiles: any[];
  constructor(
    private route: ActivatedRoute,
    private emailTemplateLangsService: EmailTemplatesLangsService,
    private emailTemplatesService: EmailTemplatesService,
    private popupHandlerService: PopupHandlerService,
    private languagesService: LanguagesService, //private _sanitizer: DomSanitizer
    private triggerEventObjectTypesService: TriggerEventObjectTypesService,
    private fileUploadsService: FileUploadsService,
    private dynamicAtachmentFilesService: DynamicAtachmentFilesService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.templateId = params['templateId'];
      this.getEmailTemplateById();
      this.getTemplateLang(this.templateId, this.langId);
      this.getAllLangs();
      this.getDynamicAtachmentFiles();
      this.triggerEventObjectTypesService
        .getTags(this.templateId)
        .subscribe((res) => {
          this.legends = res.map((x) => x.name);
        });
    });
  }
  getAllDynamicTypes() {
    this.dynamicAtachmentFilesService
      .getAll()
      .subscribe((data) => (this.dynamicAtachmentFiles = data));
  }

  onChangeDynamicAtachmentFiles(event: MatSelectChange) {
    var values = event.value as [];
    if (!this.emailTemplate.emailTemplateDynamicAttachments) {
      this.emailTemplate.emailTemplateDynamicAttachments = [];
    }
    if (values.length == 0) {
      this.emailTemplate.emailTemplateDynamicAttachments = [];
    } else {
      values.map((x) => {
        var emailTemplateDynamicAttachment =
          new EmailTemplateDynamicAttachment();
        emailTemplateDynamicAttachment.emailTemplateId =
          this.emailTemplate.emailTemplateId;
        emailTemplateDynamicAttachment.dynamicAttachmentFileId = x;
        emailTemplateDynamicAttachment.dynamicAttachmentFile =
          new DynamicAtachmentFile();
        emailTemplateDynamicAttachment.emailTemplateDynamicAttachmentId = 0;
        this.emailTemplate.emailTemplateDynamicAttachments.push(
          emailTemplateDynamicAttachment
        );
      });
    }
  }

  getDynamicFileName(item: DynamicAtachmentFile) {
    return this.dynamicAtachmentFiles.find(
      (x) => x.dynamicAtachmentFileId == item.dynamicAtachmentFileId
    ).name;
  }

  getDynamicAtachmentFiles() {
    this.dynamicAtachmentFilesService.getAll().subscribe((data) => {
      this.dynamicAtachmentFiles = data;
    });
  }

  getEmailTemplateById() {
    this.emailTemplatesService.getById(this.templateId).subscribe((data) => {
      this.emailTemplate = data;
      this.selectedDynamicAtachmentFiles =
        this.emailTemplate.emailTemplateDynamicAttachments.map(
          (x) => x.dynamicAttachmentFileId
        );
    });
  }

  getAllLangs() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  getTemplateLang(templateId: number, langId: number) {
    this.emailTemplateLangsService
      .getByTemplateId(templateId, langId)
      .subscribe((data) => {
        if (data) {
          this.emailTemplateLang = data;
          this.isDirty = false;
        }
      });
  }

  setLegendInEmailField(legend: string) {
    this.textEmailField.focus();
    let selection = window.getSelection().getRangeAt(0);
    let parentNode = document.createElement('span'); //create a custom node to insert
    selection.insertNode(parentNode);
    parentNode.insertAdjacentHTML('beforebegin', `{${legend}}`);
    parentNode.insertAdjacentHTML('afterend', ''); //add space after and before node to bring cursor outof the node
    window.getSelection().empty();
  }

  setLegendInSmsField(legend: string) {
    this.textSmsField.nativeElement.focus();
    var startPos = this.textSmsField.nativeElement.selectionStart;
    var endPos = this.textSmsField.nativeElement.selectionEnd;

    var textSms = this.emailTemplateLang.textSms
      ? this.emailTemplateLang.textSms
      : ' ';
    (textSms =
      textSms.substring(0, startPos) +
      `{${legend}}` +
      textSms.substring(endPos, textSms.length)),
      (this.emailTemplateLang.textSms = textSms);
    this.textSmsField.nativeElement.focus();
  }

  changeTemplateLang() {
    if (this.isDirty) {
      this.popupHandlerService.openConfirmationDialog(
        '',
        'Are you want to save changes'
      );
      this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
        if (data.ans === 'yes') {
          this.saveEmailTemplate();
        } else {
          this.getTemplateLang(this.templateId, this.langId);
        }
      });
    } else {
      this.getTemplateLang(this.templateId, this.langId);
    }
  }

  resetTemplate() {
    this.emailTemplateLang.textEmail = this.emailTemplateLang.defaultTemplate;
  }

  saveTemplateLang() {
    this.LangSuccess = false;
    this.emailTemplateLang.emailTempleteId = this.templateId;
    this.emailTemplateLang.textEmail =
      this.textEmailField.textArea.nativeElement.innerHTML;
    this.emailTemplateLang.textEmailStriped =
      this.textEmailField.textArea.nativeElement.innerText;
    this.emailTemplateLang.textSms = this.emailTemplateLang.textSms;

    this.emailTemplateLangsService.put(this.emailTemplateLang).subscribe(() => {
      this.getTemplateLang(this.templateId, this.langId);
      this.getEmailTemplateById();
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.success
      );
      this.isDirty = false;
    });
  }
  saveEmailTemplate() {
    this.templateSuccess = false;
    this.errors = {};
    this.emailTemplate.emailTemplateId = this.templateId;
    if (!this.emailTemplate.displayName) {
      this.errors.displayName = 'Template name is required';
    }
    if (!this.emailTemplate.actionName) {
      this.errors.actionName = 'Action description is required';
    }
    if (isEmpty(this.errors)) {
      this.emailTemplatesService.put(this.emailTemplate).subscribe(() => {
        this.saveTemplateLang();
      });
    }
  }
  uploadFileFinished(event) {
    //console.log('uploadFileFinished!!!!!');
    this.getFileAtachments();
  }
  getFileAtachments() {
    this.emailTemplateLangsService
      .GetTemplateFiles(this.templateId, this.langId)
      .subscribe((data) => {
        this.emailTemplateLang.fileAtachments = data;
      });
  }
  setDirty() {
    this.isDirty = true;
  }
  getFilesUploadPath() {
    return this.emailTemplateLangsService.getFileUploadPath(
      this.templateId,
      this.langId
    );
  }
  getFileName(fileAtachment: FileUpload) {
    var spl = fileAtachment.path.split('/');
    return spl[spl.length - 1];
  }
  onDeleteFile(file: FileUpload) {
    this.fileUploadsService.delete(file.fileUploadId).subscribe((data) => {
      this.getFileAtachments();
    });
  }
  onDownloadFile(file: FileUpload) {
    var url = this.fileUploadsService.download(file.fileUploadId);
    window.open(url);
  }
  /* isInViewport() {
    var bounding = this.textEmailField.textArea.nativeElement.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  } */
  /* isPassTheViewPort() {
    var bounding = this.textEmailField.textArea.nativeElement.getBoundingClientRect();
    var height = bounding.height;
    var screenHeight =
      window.innerHeight || document.documentElement.clientHeight;
    console.log('h:', height, 'sh: ', screenHeight);
  } */
  /*   sanitizeHtmlPreview() {
    return this._sanitizer.bypassSecurityTrustHtml(
      this.emailTemplateLang.textEmail
    );
  } */
}
