import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { AgentForTableMin } from '../data/AgentForTableMin';
import { Agent } from '../data/Agent';
import { Permission } from '../data/Permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<Permission[]>(`${environment.baseUrl}Permissions/`);
  }
  getAllGroupByCategories() {
    return this.http.get<any>(
      `${environment.baseUrl}Permissions/GroupByCategories`
    );
  }
  getAllWithCategories() {
    return this.http.get<Permission[]>(
      `${environment.baseUrl}Permissions/WithCategories`
    );
  }
  getById(permissionId) {
    return this.http.get<Permission>(
      `${environment.baseUrl}Permissions/${permissionId}`
    );
  }
  put(permission: Permission) {
    return this.http.put(
      `${environment.baseUrl}Permissions/${permission.permissionId}`,
      permission
    );
  }
  post(permission: Permission) {
    return this.http.post<Permission>(
      `${environment.baseUrl}Permissions/`,
      permission
    );
  }
  delete(permission: Permission) {
    return this.http.delete(
      `${environment.baseUrl}Permissions/${permission.permissionId}`
    );
  }
}
