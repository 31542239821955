<button (click)="saveDataSource()">Save DataSource</button>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    matTreeNodePadding
  >
    <button mat-icon-button disabled></button>
    <mat-checkbox
      hidden
      class="checklist-leaf-node"
      [ngClass]="{
        'drop-above':
          dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
        'drop-below':
          dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
        'drop-center':
          dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node
      }"
      [checked]="checklistSelection.isSelected(node)"
      (change)="checklistSelection.toggle(node)"
      draggable="true"
      (dragstart)="handleDragStart($event, node)"
      (dragover)="handleDragOver($event, node)"
      (drop)="handleDrop($event, node)"
      (dragend)="handleDragEnd($event)"
      >{{ node.item }}</mat-checkbox
    >
  </mat-tree-node>

  <mat-tree-node
    *matTreeNodeDef="let node; when: hasNoContent"
    matTreeNodePadding
  >
    <button mat-icon-button disabled></button>
    <mat-card>
      <mat-form-field>
        <span>answer:</span>
        <input
          [(ngModel)]="
            treeObjects[node.item] && treeObjects[node.item].answerText
          "
          matInput
          #answerText
        />
        <!--placeholder="New item..."-->
      </mat-form-field>
      <br />
      <mat-form-field>
        <span>next Question:</span>
        <input
          [(ngModel)]="
            treeObjects[node.item] && treeObjects[node.item].newQuestionTxt
          "
          matInput
          #newQuestionTxt
        />
        <!--placeholder="New item..."-->
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-select
          [(ngModel)]="
            treeObjects[node.item] && treeObjects[node.item].actionId
          "
          #actionId
        >
          <mat-option [value]="0"> option 1 </mat-option>
          <mat-option [value]="1"> option 2 </mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <button
        mat-button
        (click)="
          saveNode(node, answerText.value, newQuestionTxt.value, actionId.value)
        "
      >
        Save
      </button>
    </mat-card>
  </mat-tree-node>

  <div id="aaabbb" style="display: flex">
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.filename"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-card
        style="display: flex"
        hidden
        [ngClass]="{
          'drop-above':
            dragNodeExpandOverArea === 'above' &&
            dragNodeExpandOverNode === node,
          'drop-below':
            dragNodeExpandOverArea === 'below' &&
            dragNodeExpandOverNode === node,
          'drop-center':
            dragNodeExpandOverArea === 'center' &&
            dragNodeExpandOverNode === node
        }"
        draggable="true"
        (dragstart)="handleDragStart($event, node)"
        (dragover)="handleDragOver($event, node)"
        (drop)="handleDrop($event, node)"
        (dragend)="handleDragEnd($event)"
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="todoItemSelectionToggle(node)"
      >
        <div>
          <div>
            answer:
            {{ getNodeAnswer(node) }}
          </div>
          <div>
            action:
            {{ getNodeAction(node) }}
          </div>
          <div>
            next question:
            {{ getNodeNextQuest(node) }}
          </div>
        </div>
      </mat-card>
      <button mat-icon-button (click)="addNewItem(node)">
        <mat-icon>add</mat-icon>
      </button>
    </mat-tree-node>
  </div>
</mat-tree>

<span #emptyItem></span>
