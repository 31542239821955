import { HeadFilter } from '../data/HeadFilter';
import { DatePipe } from '@angular/common';

export class HttpFilterHelper {
  createTranslationPathFromHeadFilter(headFilter: HeadFilter) {
    if (headFilter.searchTerm) {
      return `${headFilter.searchTerm}/${headFilter.categoryId}/${headFilter.subCategoryId}/${headFilter.pageNumber}/${headFilter.pageSize}`;
    } else {
      return `${headFilter.categoryId}/${headFilter.subCategoryId}/${headFilter.pageNumber}/${headFilter.pageSize}`;
    }
  }
  createSnpsPathFromHeadFilter(headFilter: HeadFilter) {
    return `${headFilter.pageNumber}/${headFilter.pageSize}`;
  }
  createSearchSnpsPathFromHeadFilter(headFilter: HeadFilter) {
    return `${headFilter.searchTerm}/${headFilter.pageNumber}/${headFilter.pageSize}`;
  }
  createVideoPathFromHeadFilter(headFilter: HeadFilter, datepipe: DatePipe) {
    var str = `${headFilter.agentId}/${headFilter.categoryId}/${
      headFilter.statusId
    }/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy}/${headFilter.acending}/${headFilter.pageSize}/${
      headFilter.pageNumber
    }/${headFilter.langId}`;
    return str;
  }
  createBarcodePathFromHeadFilter(headFilter: HeadFilter, datepipe: DatePipe) {
    var str = `${headFilter.searchTerm}/${headFilter.categoryId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.statusId}/${headFilter.pageNumber}/${headFilter.pageSize}`;
    return str;
  }
  createBarcodeToExelPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.searchTerm}/${headFilter.categoryId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.statusId}`;
    return str;
  }
  createAlgoBulletPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.categoryId}/${
      headFilter.subCategoryId ? headFilter.subCategoryId : -1
    }/${headFilter.bloodTestTypeId ? headFilter.bloodTestTypeId : -1}/${
      headFilter.orderBy
    }/${headFilter.acending}/${headFilter.pageSize}/${headFilter.pageNumber}`;

    return str;
  }
  createCallsPathFromHeadFilter(headFilter: HeadFilter, datepipe: DatePipe) {
    var str = `${headFilter.agentId}/${headFilter.rating}/${
      headFilter.subjectId
    }/${headFilter.noteTypeId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy}/${headFilter.acending}/${headFilter.pageSize}/${
      headFilter.pageNumber
    }`;
    return str;
  }
  createPathFromHeadFilter(headFilter: HeadFilter, datepipe: DatePipe) {
    //if (headFilter == null) return;
    var str = `${headFilter.agentId}/${headFilter.statusId}/${
      headFilter.sourceId
    }/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${
      headFilter.acending
    }/${headFilter.pageSize}/${headFilter.pageNumber}`;
    return str;
  }
  createPathFromHeadFilterProductsExel(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.agentId}/${headFilter.statusId}/${
      headFilter.sourceId
    }/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${
      headFilter.acending
    }/${headFilter.pageSize}/${headFilter.pageNumber}`;
    return str;
  }
  createPathFromHeadFilterProducts(headFilter: HeadFilter, datepipe: DatePipe) {
    var str = `${headFilter.agentId}/${headFilter.agent2Id}/${
      headFilter.statusId
    }/${headFilter.sourceId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.lifeStyle}/${headFilter.firstCall}/${headFilter.onFollow}/${
      headFilter.orderBy == '' ? 'All' : headFilter.orderBy
    }/${headFilter.acending}/${headFilter.pageSize}/${headFilter.pageNumber}`;
    return str;
  }
  createPathFromHeadFilterProductsDownload(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.agentId}/${headFilter.agent2Id}/${
      headFilter.statusId
    }/${headFilter.sourceId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.lifeStyle}/${headFilter.firstCall}/${headFilter.onFollow}/${
      headFilter.orderBy == '' ? 'All' : headFilter.orderBy
    }/${headFilter.acending}/${headFilter.pageSize}/${headFilter.pageNumber}`;
    return str;
  }
  createTransactionsPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.agentId}/${headFilter.statusId}/${
      headFilter.sourceId
    }/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${
      headFilter.acending
    }/${headFilter.pageSize}/${headFilter.pageNumber}/${
      headFilter.paymentMethodId
    }`;
    return str;
  }
  createLabPackagesPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.statuseIds}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${
      headFilter.acending
    }/${headFilter.pageSize}/${headFilter.pageNumber}`;
    return str;
  }
  createCancellationRequestsPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    var str = `${headFilter.tabId}/${headFilter.agentId}/${
      headFilter.statusId
    }/${headFilter.decisionId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.secondStartDate == null
        ? null
        : datepipe.transform(headFilter.secondStartDate, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.secondEndDate == null
        ? null
        : datepipe.transform(headFilter.secondEndDate, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy}/${headFilter.acending}/${headFilter.pageSize}/${
      headFilter.pageNumber
    }/`;

    str = str.replace('%20', '');
    return str;
  }
  createRequestsPathFromHeadFilter(headFilter: HeadFilter, datepipe: DatePipe) {
    var searchTerm = headFilter.searchTerm;
    if (searchTerm == '') searchTerm = 'emptystring';

    if (headFilter.onlyManager == undefined) headFilter.onlyManager = false;
    // headFilter.searchTerm = 'no';
    var str = `${headFilter.statusId}/${headFilter.agentId}/${
      headFilter.sourceId
    }/${headFilter.subjectId}/${
      headFilter.dateStart == null
        ? null
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? null
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.orderBy == '' ? 'All' : headFilter.orderBy}/${
      headFilter.acending
    }/${headFilter.pageSize}/${headFilter.pageNumber}/${
      headFilter.categoryId
    }/${headFilter.onlyManager}/${searchTerm}`;
    return str;
  }
  createAppReportPathFromHeadFilter(
    headFilter: HeadFilter,
    datepipe: DatePipe
  ) {
    return `${headFilter.type}/${headFilter.langId}/${headFilter.subjectId}/${
      headFilter.agentId
    }/${headFilter.statusId}/${
      headFilter.dateStart == null
        ? 0
        : datepipe.transform(headFilter.dateStart, 'yyyy-MM-dd hh:mm')
    }/${
      headFilter.dateEnd == null
        ? 0
        : datepipe.transform(headFilter.dateEnd, 'yyyy-MM-dd hh:mm')
    }/${headFilter.pageNumber}/${headFilter.pageSize}/${headFilter.orderBy}/${
      headFilter.acending
    }`;
  }
}
