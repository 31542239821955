import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Note } from 'src/app/data/Note';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';

@Component({
  selector: 'app-client-calls-view',
  templateUrl: './client-calls-view.component.html',
  styleUrls: ['./client-calls-view.component.css'],
})
export class ClientCallsViewComponent implements OnInit {
  @Input() classAttr;
  @Input() testPusher;
  @Input() notes: Note[];
  @Input() patientId: number = -1;
  @Input() clientId: number = -1;
  @Input() metaData: PaginationMetadata;
  @Output('updateNote') updateNote: EventEmitter<Note> = new EventEmitter();
  @Output('createNewCall')
  createNewCall: EventEmitter<Note> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  
  }
  onCreateNewCall() {
    this.createNewCall.emit(new Note());
  }

  onEditNote(note: Note) {
    this.createNewCall.emit(note);
  }
}
