<div
  matRipple
  class="chat-list__row"
  *ngFor="let item of filterResults"
  (click)="onRowClicked(item)"
  [class]="
    selectedResult && selectedResult.chatRoomId == item.chatRoomId
      ? 'active'
      : ''
  "
>
  <div class="chat-list__row__avatar">
    <span
      class="chat-list__row__avatar__notification"
      *ngIf="item.notReadMsgCount > 0"
    >
      {{ item.unreadedMessages }}
    </span>
    <img [src]="getAvatarImage(item)" alt="avatar" />
  </div>
  <div class="chat-list__row__data">
    <div class="chat-list__row__data__name">
      {{ item.userName }}
    </div>
    <div *ngIf="item.isWaiting" class="chat-list__row__data__badge">
      <span>Waiting</span>
    </div>
  </div>
</div>
