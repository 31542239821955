<div class="vitamins">
  <div class="vitamins__title">Vitemins And Minerals</div>
  <div class="vitamins__header">
    <div class="vitemin__header__name">Name</div>
    <div class="vitemin__header__value">
      <span class="vitemin__value__amount">Amount</span>
      <span> / </span>
      <span class="vitemin__value__dailyAmonut">Daily amount</span>
    </div>
  </div>
  <div class="vitamins__column">
    <div *ngFor="let vitamin of getNutriVitemins(); let last = last">
      <span class="vitamin">{{ vitamin.name }}</span>
      <span class="vitamin"
        >{{ vitamin.amount | number: "1.0-0"
        }}<ng-container *ngIf="productId"
          >/{{ vitamin.dailyAmount | number: "1.0-0" }}</ng-container
        ></span
      >
    </div>
  </div>
</div>
<div class="button-wrapper">
  <button mat-button (click)="dialogRef.close()">Close</button>
</div>
