<div class="role">
  <div class="role__title">Add module</div>
  <div class="role__label">Choose Module</div>

  <mat-form-field
    appearance="fill"
    class="upgrade"
    style="padding-top: 1.34375em; margin-right: 30px"
  >
    <mat-label>Category:</mat-label>
    <mat-select
      (selectionChange)="onDropdownChanged($event)"
      matNativeControl
      disableOptionCentering
      [(ngModel)]="selectedAppModuleId"
    >
      <mat-option
        *ngFor="let appModule of appModules"
        [value]="appModule.appModuleId"
      >
        {{ appModule.appModuleName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="role__actions">
    <button (click)="save()" class="save" mat-button>Save</button>
    <button (click)="cancel()" class="cancel" mat-button mat-dialog-close>
      Cancel
    </button>
  </div>
</div>
