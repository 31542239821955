<div *ngIf="unitScaleValues">
  <div class="title">How much?</div>
  <div class="mb-2"></div>
  <div class="d-flex-between">
    <div class="label">Unit of mesure:</div>
    <mat-form-field appearance="fill">
      <mat-select required [(ngModel)]="foodScaleId">
        <mat-option *ngFor="let item of unitScaleValues" [value]="item.id">{{
          item.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mb-2"></div>
  <div class="d-flex-between">
    <div class="label">Serving size:</div>
    <mat-form-field appearance="fill">
      <input required type="number" matInput [(ngModel)]="quantity" />
    </mat-form-field>
  </div>

  <div class="mb-2"></div>
  <div class="d-flex text-center">
    <button class="btn btn-fill mr-2" (click)="onSave()" mat-button>
      Save
    </button>
    <button class="btn btn-outline" (click)="onCancel()" mat-button>
      Cancel
    </button>
  </div>
</div>
