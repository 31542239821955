import { AlgoSubCategory } from './AlgoSubCategory';
import { AppImage } from './AppImage';

export class AlgoCategory {
  categoryId: number;
  langId: number;
  name: string;
  description: string;
  color: string;
  isPremium: boolean;
  labVersionId: number;
  prototypeCategoryId: number;
  lastUpdated: string;
  files: AppImage[];
  subcategories: AlgoSubCategory[];
  orderBy: number;
}
