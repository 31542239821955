import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fg-history-table',
  templateUrl: './fg-history-table.component.html',
  styleUrls: ['./fg-history-table.component.scss'],
})
export class FgHistoryTableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
