import { Component, Input, OnInit } from '@angular/core';
import { BloodTestResult } from 'src/app/data/BloodTestResult';

@Component({
  selector: 'app-blood-test-result-slider',
  templateUrl: './blood-test-result-slider.component.html',
  styleUrls: ['./blood-test-result-slider.component.scss'],
})
export class BloodTestResultSliderComponent implements OnInit {
  @Input() result: BloodTestResult;
  @Input() gender: string;
  constructor() {}

  ngOnInit(): void {}
  isRedMax(result: BloodTestResult) {
    if (!result.result) return false;
    if (this.gender == 'male') {
      return result.result > result.bloodTestType.maxMale;
    } else {
      return result.result > result.bloodTestType.maxFemale;
    }
  }
  isGreenVisible(result: BloodTestResult) {
    if (!result.result) return false;
    if (this.gender == 'male') {
      return (
        result.result >= result.bloodTestType.minMale &&
        result.result <= result.bloodTestType.maxMale
      );
    } else {
      return (
        result.result >= result.bloodTestType.minFemale &&
        result.result <= result.bloodTestType.maxFemale
      );
    }
  }
  greenArrowPos(result: BloodTestResult) {
    var min =
      this.gender == 'male'
        ? result.bloodTestType.minMale
        : result.bloodTestType.minFemale;
    var max =
      this.gender == 'male'
        ? result.bloodTestType.maxMale
        : result.bloodTestType.maxFemale;

    var pos = ((result.result - min) / (max - min)) * 102;
    return pos + 'px';
  }
  getUnit(value, result: BloodTestResult) {
    if (!result.isAlt) return value;
    return result.bloodTestType.unitsConvertion * value;
  }
  isRedMin(result: BloodTestResult) {
    if (!result.result) return false;
    if (this.gender == 'male') {
      return result.result < result.bloodTestType.minMale;
    } else {
      return result.result < result.bloodTestType.minFemale;
    }
  }
  isInRangeSingleCondition(result: BloodTestResult) {
    if (!result.result) return false;
    if (result.bloodTestType.minFemale === -1.0) {
      if (this.gender == 'male') {
        return result.result < result.bloodTestType.maxMale;
      } else {
        return result.result < result.bloodTestType.maxFemale;
      }
    } else {
      if (this.gender == 'male') {
        return result.result > result.bloodTestType.minMale;
      } else {
        return result.result > result.bloodTestType.minFemale;
      }
    }
  }
}
