import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caloric-deficit-dialog',
  templateUrl: './caloric-deficit-dialog.component.html',
  styleUrls: ['./caloric-deficit-dialog.component.css'],
})
export class CaloricDeficitDialogComponent implements OnInit {
  deficit;
  constructor() {}

  ngOnInit(): void {}
  save() {}
  close() {}
}
