<div style="display: flex">
  <div class="manage-roles__modules">
    <div class="manage-roles__modules__title">Modules</div>
    <perfect-scrollbar
      style="height: calc(100% - 60px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__modules__wrapper"
        *ngFor="let module of appModules"
      >
        <!--
        <button
          class="manage-roles__modules__wrapper__more"
          mat-icon-button
          *ngIf="selectedModuleId == module.appModuleId"
          [matMenuTriggerFor]="moreMenu"
        >
          <mat-icon (click)="editModule(module.appModuleId)"
            >more_vert</mat-icon
          >
        </button>
-->
        <!--
        <mat-menu #moreMenu="matMenu">
          <button
            (click)="editModule(module.appModuleId)"
            mat-menu-item
            style="display: flex; justify-content: space-between"
          >
            <span> Edit </span>
            <span>
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                style="margin-right: 0"
                >mode_edit</mat-icon
              >
            </span>
          </button>
          <button
            mat-menu-item
            (click)="deleteModule(module.appModuleId)"
            style="display: flex; justify-content: space-between"
          >
            <span> Delete </span>
            <span>
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                style="margin-right: 0"
                >delete</mat-icon
              >
            </span>
          </button>
        </mat-menu>
        -->
        <div
          class="manage-roles__modules__item"
          (click)="onModuleClick(module.appModuleId)"
          matRipple
          [class.active]="selectedModuleId == module.appModuleId"
        >
          {{ module.appModuleName }}
          <mat-icon
            class="manage-roles__modules__item__right-icon"
            *ngIf="selectedModuleId == module.appModuleId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </div>
    </perfect-scrollbar>
    <div class="add-new" *ngIf="selectedRoleId">
      <button (click)="addNewModule()" mat-button>Add module</button>
    </div>
  </div>
  <mat-card class="manage-roles__actions" *ngIf="selectedModuleId">
    <div class="manage-roles__actions__title">Actions</div>
    <perfect-scrollbar
      style="height: calc(100% - 37px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__actions__item"
        *ngFor="let action of showedActions"
        matRipple
        (click)="onActionClick(action)"
        [class.active]="selectedActionId == action.moduleActionId"
      >
        {{ action.moduleActionName }}
        <mat-icon *ngIf="selectedActionId == action.moduleActionId"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </perfect-scrollbar>
  </mat-card>
  <div class="manage-roles__permissions" *ngIf="selectedActionId">
    <div class="manage-roles__permissions__title">Permissions</div>
    <perfect-scrollbar
      style="height: calc(100% - 37px)"
      [perfectScrollbar]="{ wheelSpeed: 0.5 }"
    >
      <div
        class="manage-roles__permissions__item"
        *ngFor="let rule of showedRules"
        (click)="onPermissionClick(rule)"
        matRipple
      >
        <mat-checkbox [checked]="rule.checked == true">{{
          rule.permissionRuleName
        }}</mat-checkbox>
      </div>
    </perfect-scrollbar>
  </div>
</div>
