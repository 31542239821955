<!-- <mat-accordion class="formula-accordion" #accordion="matAccordion">
  <mat-expansion-panel
    class="formula-accordion__panel"
    #panel
    *ngFor="let item of formulas; let i = index"
    [expanded]="indexExpanded == i"
    (opened)="onOpened(item, i)"
    (closed)="onClosed(item, i)"
    hideToggle
  >
    <mat-expansion-panel-header class="formula-accordion__panel__header">
      <mat-panel-title class="formula-accordion__panel__header__title"
        >{{ isItemExpanded(item, i) ? "Formula creator" : item.formulaName }}
      </mat-panel-title>
      <div
        class="formula-accordion__panel__header__order"
        *ngIf="isItemExpanded(item, i)"
        (click)="$event.stopPropagation()"
      >
        <div class="formula-accordion__panel__header__order__label">
          Order formula
        </div>
        <input
          class="formula-accordion__panel__header__order__input"
          type="number"
        />
      </div>
    </mat-expansion-panel-header>
    <div class="formula-accordion__panel__body">
      <div class="formula-accordion__panel__body__header">
        <div class="formula-accordion__panel__body__header__title">
          Formulas creator
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

 -->
<ng-container>
  <nav class="formula-nav" mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      *ngFor="let formula of formulas; let i = index"
      (click)="onSelectFormula(formula)"
      [active]="selectedFormulaId == formula.formulaId"
      class="formula-nav__item"
      [ngClass]="{
        selected: selectedFormulaId == formula.formulaId
      }"
      >{{ formula.formulaName }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <app-formula-dev
      [calcFunctions]="calcFunctions"
      [versionId]="versionId"
      [canEdit]="canEdit"
    ></app-formula-dev>
  </mat-tab-nav-panel>
</ng-container>
<div class="text-center mt-4">
  <button
    *ngIf="canEdit"
    class="formula-accordion__add-button"
    mat-raised-button
    color="primary"
    (click)="addFormula()"
  >
    Add new formula
  </button>
</div>
<!-- (click)="onSelectType(type)"
[active]="activeType?.resultTypeId == type.resultTypeId"
class="result-nav__item"
[ngClass]="{
  selected: activeType?.resultTypeId == type.resultTypeId
}"
[ngStyle]="{
  'background-color': type.color,
  color: type.color ? '#ffffff' : '#000000'
}" -->
