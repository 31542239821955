import { EventAction } from "./EventAction";
import { Status } from "./Status";
import { TriggerEventObjectType } from "./TriggerEventObjectType";
export class TriggerEvent {
    triggerEventId: number;
    name: string;
    statusId: number;
    status: Status;
    eventTypeId: number;
    eventType: Status;
    triggerEventProductTypeId: number | null;
    triggerEventProductType: Status;
    triggerEventObjectTypeId: number | null;
    triggerEventObjectType: TriggerEventObjectType;
    eventActions: EventAction[];
}