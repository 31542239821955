import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ZoomEvent } from 'src/app/data/ZoomEvent';
import { ZoomEventsService } from 'src/app/Services/zoom-events.service';
import { Location } from '@angular/common';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { I } from '@angular/cdk/keycodes';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-zoom-events',
  templateUrl: './zoom-events.component.html',
  styleUrls: ['./zoom-events.component.scss'],
})
export class ZoomEventsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'date', 'action'];
  dataSource: MatTableDataSource<ZoomEvent> = new MatTableDataSource([]);
  zoomEvents: ZoomEvent[];
  constructor(
    private zoomEventsService: ZoomEventsService,
    public location: Location,
    private popupHandlerService: PopupHandlerService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getZoomEvents();
  }

  getZoomEvents() {
    this.zoomEventsService.getZoomEventList().subscribe((data) => {
      this.setDataSource(data);
    });
  }

  setDataSource(data: ZoomEvent[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  openZoomEvent(zoomEventId: number) {
    this.popupHandlerService.openZoomEventsEdit(zoomEventId);

    this.popupHandlerService.addNewZoomEventSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.type === 'add' || data.type === 'update') {
          this._matSnackBar
            .open(`Event ${data.type}ed`, '', {})
            ._dismissAfter(2000);
          this.getZoomEvents();
        }
      });
  }
}
