import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PrototypeSnp } from 'src/app/data/algorithm/PrototypeSnp';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-snp-table',
  templateUrl: './snp-table.component.html',
  styleUrls: ['./snp-table.component.scss'],
})
export class SnpTableComponent implements OnInit, OnChanges {
  @Input() data: PrototypeSnp[];
  @Output('saveSnp') saveSnp: EventEmitter<PrototypeSnp> = new EventEmitter();
  @Output('loadSnps') loadSnps: EventEmitter<boolean> = new EventEmitter();
  dataSource: MatTableDataSource<PrototypeSnp> = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'add',
    'snpNumber',
    'coordinate',
    'chromosome',
    'actions',
  ];
  constructor(
    private popupHandlerService: PopupHandlerService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataSource(changes.data.currentValue);
  }

  ngOnInit(): void {
    this.setDataSource(this.data);
  }

  setDataSource(data: PrototypeSnp[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  editSnp(snpNumber: number) {
    this.popupHandlerService.openAddNewSnp(snpNumber);
  }

  addNewSnp(snpNumber: number = 0) {
    this.popupHandlerService.openAddNewSnp(snpNumber);

    this.popupHandlerService.addNewSnpSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.type === 'save') {
          this.loadSnps.emit(true);
          this._snackBar.open('SNP added successfully', 'Close', {
            duration: 2000,
            panelClass: 'added-success',
          });
        }
      });
  }
}
