<div style="position: relative">
  <div *ngIf="isLoading" class="loadingOverlay">
    <mat-spinner diameter="250"></mat-spinner>
  </div>
  <select (change)="onVersionChanged()" [(ngModel)]="selectedVersionId">
    <option
      [value]="version.lifeStyleReportVersionId"
      *ngFor="let version of versions"
    >
      {{ version.name }}
    </option>
  </select>
  <div *ngIf="lifestyleQuestions">
    <h1>nodes</h1>

    <div *ngFor="let question of lifestyleQuestions">
      <app-lifestyle-question-node-v2
        [length]="length"
        [isDone]="isDone"
        [question]="question"
        [designTypes]="designTypes"
        [containerDesignTypes]="containerDesignTypes"
        [templatesNames]="templatesNames"
        [lifeStyleCalculations]="lifeStyleCalculations"
        (addNewQuest)="onAddNewQuestion($event)"
        (deleteQuest)="onDeleteQuestion($event)"
        (addFromTemplate)="onAddFromTemplate($event)"
        (duplicate)="onDuplicate($event)"
        (move)="onMove($event)"
        (checkedChanged)="onCheckedChanged($event)"
        (setMarkAsReference)="onSetMarkAsReference($event)"
        (removeReference)="onRemoveReference($event)"
        (moveNodeToMarked)="onMoveNodeToMarked($event)"
        (moveNodeToRoot)="onMoveNodeToRoot($event)"
        (changeOrder)="onChangeOrder($event)"
      >
      </app-lifestyle-question-node-v2>
    </div>

    <h1>templates</h1>

    <div *ngFor="let question of templates">
      <app-lifestyle-question-node-v2
        [isDone]="false"
        [question]="question"
        [designTypes]="designTypes"
        [containerDesignTypes]="containerDesignTypes"
        (addNewQuest)="onAddNewQuestion($event)"
        (deleteQuest)="onDeleteQuestion($event)"
        (addFromTemplate)="onAddFromTemplate($event)"
        (duplicate)="onDuplicate($event)"
        (move)="onMove($event)"
        (checkedChanged)="onCheckedChanged($event)"
      >
      </app-lifestyle-question-node-v2>
    </div>
  </div>
  <button (click)="addNewQuestion()" *ngIf="!isDone">add</button>
  <br />
  <button (click)="addTemplateQuestion()">add template</button>
</div>
