import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgentForTableMin } from 'src/app/data/AgentForTableMin';
import { AgentForTableMinV2 } from 'src/app/data/AgentForTableMinV2';
import { RoleType } from 'src/app/data/RoleType';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';

import { ManageAgentsService } from 'src/app/Services/manage-agents.service';

@Component({
  selector: 'app-agents-calendar-table',
  templateUrl: './agents-calendar-table.component.html',
  styleUrls: ['./agents-calendar-table.component.scss'],
})
export class AgentsCalendarTableComponent implements OnInit {
  agents: AgentForTableMinV2[];
  selectedRoleTypeId = -1;
  constructor(private agentsService: ManageAgentsService) {}

  ngOnInit(): void {
    this.getAgents();
  }

  getAgents() {
    this.agentsService
      .getbyFilter(this.selectedRoleTypeId)
      .subscribe((data) => {
        this.agents = data.filter(
          (x) =>
            x.userRoleId == UserRoleEmum.nutritionist ||
            x.userRoleId == UserRoleEmum.nutritionistManager ||
            x.userRoleId == UserRoleEmum.followUpAgent
        );
      });
  }
}
