import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Client } from 'src/app/data/Client';
import { DynamicAtachmentFile } from 'src/app/data/DynamicAtachmentFile';
import { EmailTemplate } from 'src/app/data/EmailTemplate';
import { EmailTemplateLang } from 'src/app/data/EmailTemplateLang';
import { Language } from 'src/app/data/Language';
import { NameAndPhone } from 'src/app/data/NameAndPhone';
import { Patient } from 'src/app/data/Patients';
import { PhoneVerificationSetting } from 'src/app/data/PhoneVerificationSetting';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { SendInfo } from 'src/app/data/SendInfo';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { AdressTypeEnum } from 'src/app/Enums/StatusesEnum';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { ClientsService } from 'src/app/Services/clients.service';
import { EmailTemplatesLangsService } from 'src/app/Services/email-templates-langs.service';
import { EmailTemplatesService } from 'src/app/Services/email-templates.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PhoneVerificationSettingsService } from 'src/app/Services/phone-verification-settings.service';
//import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { isEmpty } from 'src/app/Utils/general-helper';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent implements OnInit {
  @ViewChild('textEmailField') textEmailField: ElementRef;

  emailTemplates: EmailTemplate[];
  emailTemplate: EmailTemplate;
  emailTemplateLangs: EmailTemplateLang[];
  emailTemplateLang: EmailTemplateLang;

  patient: Patient | Client;
  product: Product;
  sendInfo: SendInfo;
  errors: any = {};

  legends: string[] = [
    'clientName',
    'agentName',
    'siteLink',
    //'Register Link',
    // 'Login Link',
    // 'DNA Result Link',
    // 'LifeStyle Form Link',
    // 'requestFeedbackLink',
  ];
  isClient: boolean = false;
  isPatient: boolean = false;
  patientsPhones: NameAndPhone[];

  languageId: number = 1;
  languages: Language[];
  editMode: boolean = false;
  clientOrProductId: number;
  clientId: number;
  templateForm: FormGroup;
  textLangs: FormArray;
  isNewTemplate: boolean = false;
  agentUser: RegularUser;
  showFormErrors: boolean;
  phoneSettings: PhoneVerificationSetting;

  constructor(
    public dialogRef: MatDialogRef<EmailTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emailTemplateService: EmailTemplatesService,
    private emailTemplateLagnsService: EmailTemplatesLangsService,
    private productService: ProductsService,
    private clientsService: ClientsService,
    public dialog: MatDialog,
    private languagesService: LanguagesService,
    private formBuilder: FormBuilder,
    private regularUsersService: RegularUsersService,
    private phoneVerificationSettingsService: PhoneVerificationSettingsService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.patient = this.data.patient;
    this.product = this.data.product;
    this.getAgentUser();
    this.getTemplatesForSend();
    this.getAllLangs();
    this.getPatientPhones();
  }

  getPatientPhones() {
    if ((this.patient as Client).products) {
      this.clientId = this.patient.clientId;
      this.clientsService
        .getPatientPhones(this.patient.clientId)
        .subscribe((data) => {
          this.patientsPhones = data;
          //this.getPhoneSettings();
          this.createFormGroup();
        });
      this.isClient = true;
    } else {
      this.isPatient = true;
      //this.getPhoneSettings();
      this.createFormGroup();
    }
  }

  lisetnToValueChange() {
    this.SelectedTemplateId.valueChanges.subscribe((val) => {
      if (val) {
        this.getTemplateLangs(val);
      }
      if (val && this.emailTemplate) {
        this.TemplateName.setValue(this.emailTemplate.name);
        this.textLangs = this.templateForm.get('textLangs') as FormArray;
        this.textLangs.clear();
        this.emailTemplate.emailTemplateLangs.forEach((x) => {
          this.textLangs.push(this.createTextLangsFields(x));
        });
      }
    });
    this.SendSms.valueChanges.subscribe((val) => {
      if (val) {
        this.SelectedNumberMethod.setValidators(Validators.required);
        this.SelectedNumberMethod.updateValueAndValidity();
      } else {
        this.SelectedNumberMethod.clearValidators();
        this.SelectedNumberMethod.updateValueAndValidity();
      }
    });
  }

  getPhoneSettings() {
    var address = this.patient.regularUser.getAdressObj(AdressTypeEnum.Main);
    var counrtyId = address && address?.countryId ? address.countryId : 0;
    this.phoneVerificationSettingsService
      .getByCountry(counrtyId)
      .subscribe((data) => {
        this.phoneSettings = data;
        this.createFormGroup();
      });
  }
  getUserPhone() {
    if (this.patient.regularUser.getUserPhone()) {
      var phoneNumber = this.patient.regularUser.getUserPhone();

      /* if (phoneNumber.startsWith(this.phoneSettings.countryCode)) {
        phoneNumber = phoneNumber.replace(this.phoneSettings.countryCode, '');
        phoneNumber = this.phoneSettings.localPrefix + phoneNumber;
        return phoneNumber;
      } */
      return phoneNumber;
    }
  }

  getAgentUser() {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
  }
  hasPermission() {
    return userHasPermisions(['EmailTemplates_Update'], this.agentUser);
  }
  onSelectTemplate(event) {
    //console.log(event.value);
  }

  isRtl() {
    return this.languages.find((x) => x.languageId == this.languageId).isRtl
      ? 'rtl'
      : 'ltr';
  }

  createFormGroup() {
    this.templateForm = this.formBuilder.group({
      selectedTemplateId: this.formBuilder.control('', Validators.required),
      templateName: this.formBuilder.control('', Validators.required),
      sendSms: this.formBuilder.control(''),
      sendEmail: this.formBuilder.control(''),
      selectedNumberMethod: this.formBuilder.control(''),
      selectedPhoneNumber: this.formBuilder.control(''),
      phoneNumber: this.formBuilder.control(this.getUserPhone()),
      alternatePhoneNumber: this.formBuilder.control(''),
      textLangs: this.formBuilder.array([]),
    });
    this.PhoneNumber.disable();
    if (this.isPatient) {
      this.SelectedPhoneNumber.disable();
    }
    this.lisetnToValueChange();
  }

  createTextLangsFields(emailTemplateLang: EmailTemplateLang) {
    return this.formBuilder.group({
      emailTempleteLangId: [emailTemplateLang.emailTempleteLangId],
      langId: [emailTemplateLang.langId],
      language: [emailTemplateLang.language],
      emailTempleteId: [emailTemplateLang.emailTempleteId],
      emailTemplate: [emailTemplateLang.emailTemplate],
      subject: [emailTemplateLang.subject],
      textEmail: [emailTemplateLang.textEmail],
    });
  }

  getTemplatesForSend(templateId: number = null) {
    this.emailTemplateService.getAllSms().subscribe((data) => {
      this.emailTemplates = data;

      if (templateId) {
        this.getTemplateLangs(templateId);
      }
    });
  }

  // Get the selected template from list
  getTemplateLangs(templateId: number = this.SelectedTemplateId.value) {
    //if (!this.emailTemplate) return;
    this.emailTemplate = this.emailTemplates.find(
      (x) => x.emailTemplateId == templateId
    );
    if (!this.editMode && this.emailTemplate) {
      this.getTemplateTextByclientOrProduct();
    }
  }

  changeTemplateLang(langId: number) {
    this.languageId = langId;
    if (!this.editMode && this.emailTemplate) {
      this.getTemplateTextByclientOrProduct();
    }
  }

  getTemplateTextByclientOrProduct() {
    if (this.product) {
      this.getTemplateTextForSend(
        this.emailTemplate.emailTemplateId,
        this.languageId,
        -1,
        this.product.productId
      );
    } else {
      this.getTemplateTextForSend(
        this.emailTemplate.emailTemplateId,
        this.languageId,
        this.patient.clientId,
        -1
      );
    }
  }

  // Get all supported languages
  getAllLangs() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  getTemplateTextForSend(
    templateId: number,
    langId: number,
    clientId: number,
    productId: number
  ) {
    this.emailTemplateLagnsService
      .getByTemplateIdForSend(templateId, langId, clientId, productId)
      .subscribe((data) => {
        if (data) {
          //console.log(data);

          // Convert the tags in the email text to the real values
          var textLangs = this.templateForm.get('textLangs') as FormArray;
          var lang = textLangs.controls.find(
            (x) => x.value.langId == data.langId
          );

          if (data.langId == lang.value.langId) {
            return lang.patchValue({
              textEmail: data.textEmail,
            });
          }
        }
      });
  }

  createNewTemplate() {
    this.editMode = true;
    this.isNewTemplate = true;
    this.errors = {};
    this.disableFields();
    this.templateForm.reset();

    this.textLangs = this.templateForm.get('textLangs') as FormArray;
    this.textLangs.clear();
    var emailTemplateLang = new EmailTemplateLang();

    this.languages.forEach((x) => {
      emailTemplateLang.langId = x.languageId;
      this.textLangs.push(this.createTextLangsFields(emailTemplateLang));
    });
  }

  disableFields() {
    this.AlternatePhoneNumber.disable();
    this.SelectedNumberMethod.disable();
    this.SelectedPhoneNumber.disable();
    this.SelectedTemplateId.disable();
  }
  enableFields() {
    this.AlternatePhoneNumber.enable();
    this.SelectedNumberMethod.enable();
    this.SelectedPhoneNumber.enable();
    this.SelectedTemplateId.enable();
    this.PhoneNumber.setValue(this.getUserPhone());
  }

  onEditTemplate(event, templateId: number) {
    event.stopPropagation();
    this.editMode = true;
    this.SelectedTemplateId.setValue(templateId);
    this.disableFields();
    //this.AlternatePhoneNumber.disable();
    this.getTemplateLangs();
  }

  onDeleteTemplate(event, templateId: number) {
    event.stopPropagation();
    this.openConfirmationDialog('delete', templateId);
  }

  get TemplateName(): AbstractControl {
    return this.templateForm.get('templateName');
  }
  get SelectedTemplateId(): AbstractControl {
    return this.templateForm.get('selectedTemplateId');
  }
  get SendSms(): AbstractControl {
    return this.templateForm.get('sendSms');
  }
  get SendEmail(): AbstractControl {
    return this.templateForm.get('sendEmail');
  }
  get SelectedNumberMethod(): AbstractControl {
    return this.templateForm.get('selectedNumberMethod');
  }
  get SelectedPhoneNumber(): AbstractControl {
    return this.templateForm.get('selectedPhoneNumber');
  }
  get PhoneNumber(): AbstractControl {
    return this.templateForm.get('phoneNumber');
  }
  get AlternatePhoneNumber(): AbstractControl {
    return this.templateForm.get('alternatePhoneNumber');
  }
  get Subject(): AbstractControl {
    return this.templateForm.get('subject');
  }
  get Message(): AbstractControl {
    return this.templateForm.get('message');
  }

  saveTemplate() {
    this.errors = {};
    this.showFormErrors = true;

    if (this.templateForm.valid) {
      var textLangs = (this.templateForm.get('textLangs') as FormArray).value;
      var textLangValue = textLangs.find(
        (x) => x.langId == this.languageId
      ) as EmailTemplateLang;
      if (!textLangValue.textEmail || !textLangValue.subject) {
        this.showFormErrors = true;
        this.errors.textLang = 'Please fill the template text and subject';
      } else {
        this.showFormErrors = false;
      }

      if (this.isNewTemplate) {
        var emailTemplate = new EmailTemplate();
        emailTemplate.name = this.TemplateName.value;
        emailTemplate.isAutoSender = false;

        this.emailTemplateService.post(emailTemplate).subscribe((data) => {
          if (!data) return;
          this.emailTemplate = data;

          // loop through all templateLangs that has been return
          // and patch the missing properties to the form langs object
          this.emailTemplate.emailTemplateLangs.forEach((y) => {
            var textLangs = this.templateForm.get('textLangs') as FormArray;
            var lang = textLangs.controls.find(
              (x) => x.value.langId == y.langId
            );

            if (y.langId == lang.value.langId) {
              return lang.patchValue({
                emailTempleteId: y.emailTempleteId,
                emailTempleteLangId: y.emailTempleteLangId,
              });
            }
          });

          var textLangs = (this.templateForm.get('textLangs') as FormArray)
            .value;
          this.emailTemplate.emailTemplateLangs = textLangs;
          if (!this.showFormErrors) {
            this.emailTemplateService
              .put(this.emailTemplate)
              .subscribe((data: EmailTemplate) => {
                this.editMode = false;
                this.isNewTemplate = false;
                this.getTemplatesForSend(data.emailTemplateId);
                this.SelectedTemplateId.setValue(data.emailTemplateId);
                this.enableFields();
              });
          }
        });
      } else {
        this.emailTemplate.name = this.TemplateName.value;
        this.emailTemplate.emailTemplateLangs = (
          this.templateForm.get('textLangs') as FormArray
        ).value;

        this.emailTemplateService
          .put(this.emailTemplate)
          .subscribe((data: EmailTemplate) => {
            this.editMode = false;
            this.getTemplateLangs();
            this.enableFields();
          });
      }
    }
  }

  send() {
    //

    /* if (!this.SendEmail.value && !this.SendSms.value) {
      this.errors.smsOrMail = 'Please check at least one from these options!';
    }
    if (!this.SelectedNumberMethod.value) {
      this.errors.selectedNumber = 'Please choose one from these options!';
    }
    if (
      this.SelectedNumberMethod.value === 'text' &&
      !this.AlternatePhoneNumber.value
    ) {
      this.errors.alternatePhoneNumber =
        'Please provide phone number to send this message';
    } */
    this.errors = {};
    this.showFormErrors = true;
    if (this.showFormErrors && this.templateForm.valid) {
      if (!this.SendEmail.value && !this.SendSms.value) {
        this.errors.smsOrMail = 'Please check at least one from these options!';
      }
      if (
        this.SelectedNumberMethod.value === 'text' &&
        !this.AlternatePhoneNumber.value
      ) {
        this.errors.alternatePhoneNumber =
          'Please provide phone number to send this message';
      }
      this.showFormErrors = false;
      this.sendInfo = new SendInfo();
      if (this.SendEmail.value) {
        this.sendInfo.mail = this.patient.regularUser.email;
      }
      if (this.SendSms.value) {
        if (this.patientsPhones) {
          if (this.SelectedNumberMethod.value === 'list') {
            this.sendInfo.phone = this.SelectedPhoneNumber.value;
          } else {
            this.sendInfo.phone = this.AlternatePhoneNumber.value;
          }
        } else {
          if (this.SelectedNumberMethod.value === 'list') {
            this.sendInfo.phone = this.PhoneNumber.value;
          } else {
            this.sendInfo.phone = this.AlternatePhoneNumber.value;
          }
          //this.sendInfo.phone = this.patient.regularUser.phone;
        }
      }
      if (
        (this.patient as Client).products &&
        (this.patient as Client).clientTypeId !== 1
      ) {
        // client
        this.sendInfo.clientId = this.patient.clientId;
      } else if ((this.patient as Patient).patientId) {
        // test
        var patient = this.patient as Patient;
        this.sendInfo.productId = this.product.productId;
      } else if ((this.patient as Client).clientTypeId == 1) {
        // lead
        var lead = this.patient as Client;
        this.sendInfo.clientId = lead.clientId;
      }

      var textLang: EmailTemplateLang = this.templateForm
        .get('textLangs')
        ['controls'].find((x) => x.value.langId == this.languageId).value;

      this.sendInfo.text = textLang.textEmail;
      this.sendInfo.title = textLang.subject;

      if (isEmpty(this.errors)) {
        this.productService.sendInfo(this.sendInfo).subscribe((data) => {
          this.openDisapearingAlertDialog(DisapearingTextDialogEnum.success);
          setTimeout(() => this.close(), 1500);
        });
      }

      //console.log(this.sendInfo);
    }
  }

  setLegendInText(legend: string) {
    var textLangs = (this.templateForm.get('textLangs') as FormArray).controls;
    var textToInsert = textLangs.find((x) => x.value.langId == this.languageId);
    var textEmail = textToInsert.value.textEmail
      ? textToInsert.value.textEmail
      : ' ';

    var startPos = this.textEmailField.nativeElement.selectionStart;
    var endPos = this.textEmailField.nativeElement.selectionEnd;
    textToInsert.patchValue({
      textEmail:
        textEmail.substring(0, startPos) +
        `{${legend}}` +
        textEmail.substring(endPos, textEmail.length),
    });
    this.textEmailField.nativeElement.focus();
  }

  setDisabled() {
    return false;
    /* return (
      (!this.sendEmail && !this.sendSms) ||
      (this.sendSms && this.patientsPhones && !this.selectedPhoneNumber) ||
      this.emailTemplateLang && !this.emailTemplateLang.textEmail ||
      !this.selectedTemplateId ||
      this.editMode
    ); */
  }

  openConfirmationDialog(
    action: 'edit' | 'delete',
    templateId: number = null,
    title: string = null
  ): any {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (result.data.answer === 'yes') {
        if (action === 'edit') {
          this.editMode = false;
          if (this.SelectedTemplateId.value || this.isNewTemplate) {
            this.templateForm.reset();
            this.isNewTemplate = false;
            this.SelectedTemplateId.setValue(null);
            this.enableFields();
          }
        }
        if (action === 'delete') {
          this.emailTemplateService.delete(templateId).subscribe(() => {
            this.getTemplatesForSend();
          });
        }
      }
    });
  }

  openDisapearingAlertDialog(type = '', from = '', to = '', msg = '') {
    //console.log('openDisapearingAlertDialog ', msg);

    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        type: type,
        from: from,
        to: to,
        msg: msg,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      var a = result;
    });
  }

  close() {
    if (this.editMode) {
      this.openConfirmationDialog('edit');
    } else {
      this.dialogRef.close({ event: 'close' });
    }
  }

  unFieldText(langId) {
    var textLangs = (this.templateForm.get('textLangs') as FormArray).controls;
    var lang = textLangs.find((x) => x.value.langId == langId)
      ?.value as EmailTemplateLang;
    if (!lang?.subject || !lang?.textEmail) {
      return true;
    }
  }
}
