import { Language } from "./Language";
import { RecipeHeader } from "./RecipeHeader";

export class RecipeHeaderLang {
  recipeHeaderLangId: number;
  recipeHeaderId: number;
  recipeHeader: RecipeHeader;
  languageId: number;
  language: Language;
  name: string;
}
