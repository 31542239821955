import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { EditFoodFilter } from 'src/app/data/EditFoodFilter';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Language } from 'src/app/data/Language';
import { NameAndId } from 'src/app/data/NameAndId';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import {
  FoodGenesCatTypeEnum,
  permissionModuleAndAction,
  SourceKeys,
} from 'src/app/Enums/StatusesEnum';
import { EditFoodsService } from 'src/app/Services/edit-foods.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { FoodSourceKeysService } from 'src/app/Services/food-source-keys.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { SearchFoodService } from 'src/app/Services/search-food.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-all-foods',
  templateUrl: './all-foods.component.html',
  styleUrls: ['./all-foods.component.scss'],
})
export class AllFoodsComponent implements OnInit {
  langs: Language[];
  selectedLang: number = 1;
  selectedSource: number[] = [1];
  foodMetaData: PaginationMetadata;
  recipeMetaData: PaginationMetadata;
  isRecipe: boolean = false;
  searchTerm: string;
  data: any;
  headFilter: HeadFilter;
  isLoading: boolean;
  foodsCheckList: any = {};
  recipesCheckList: any = {};
  sourceKeys: NameAndId[];
  sourceKeysLabel: string;
  searchTimeout: any;
  foodCategories: FoodCat[];
  regularUser: RegularUser;
  selectedCat: FoodCat;
  categoryType: number;
  constructor(
    private languagesService: LanguagesService,
    private editFoodsService: EditFoodsService,
    private filterMemoryService: FilterMemoryService,
    private router: Router,
    private foodSourceKeysService: FoodSourceKeysService,
    private foodGenesService: FoodGenesService,
    regularUsersService: RegularUsersService,
    private popupHandlerService: PopupHandlerService,
    private searchfoodSrevice: SearchFoodService
  ) {
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.foods);
    this.headFilter.categoryId = 0;
  }

  ngOnInit(): void {
    this.isRecipe = this.router.url === '/foods' ? false : true;
    this.categoryType = this.isRecipe
      ? FoodGenesCatTypeEnum.Recipe
      : FoodGenesCatTypeEnum.Food;
    this.searchfoodSrevice.getSearchTerm().subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
    this.getLangs();
    this.getFoodsForTable();
    this.getSourceKeys();
    this.getFoodCategories();
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.regularUser);
  }
  onSelectCat(cat: FoodCat) {
    this.selectedCat = cat;
    this.headFilter.categoryId = this.selectedCat.categoryId;
    this.getFoodsForTable();
  }
  getSelectedCategoryRecursively(
    categoryId: number,
    array: FoodCat[]
  ): FoodCat {
    let res: FoodCat = null;
    if (!array || array?.length == 0) return null;
    for (let index = 0; index < array.length; index++) {
      let element = array[index];
      if (element.categoryId == categoryId) {
        return element;
      }
    }
    for (let index = 0; index < array?.length; index++) {
      let subArray = array[index].subCategories;
      res = this.getSelectedCategoryRecursively(categoryId, subArray);
      if (res) return res;
    }
    return null;
  }
  getFoodCategories() {
    if (
      !this.userHasPermisions([
        permissionModuleAndAction.FoodCategories_GetList,
      ])
    )
      return;

    this.foodGenesService
      .getFoodCategories(this.selectedLang, this.categoryType)
      .subscribe((data) => {
        this.foodCategories = data;
      });
  }
  getSourceKeys() {
    this.foodSourceKeysService.getAll().subscribe((data) => {
      this.sourceKeys = data;
    });
  }
  getLangs() {
    this.languagesService.getAll().subscribe((data) => {
      this.langs = data;
    });
  }
  onSearch(event: KeyboardEvent) {
    var { key } = event;
    if (
      key === 'Alt' ||
      key === 'Control' ||
      key === 'Shift' ||
      key === 'Enter'
    ) {
      return;
    }
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.searchfoodSrevice.setSearchTerm(this.searchTerm);
      this.getFoodsForTable();
    }, 500);
  }
  onSelectionChange() {
    this.headFilter.pageNumber = 1;
    this.getFoodsForTable();
    this.getFoodCategories();
  }

  getFoodsForTable(
    pageSize = this.headFilter.pageSize,
    pageNumber = this.headFilter.pageNumber,
    categoryId = this.headFilter.categoryId
  ) {
    this.showSourceKeysLabels();
    this.isLoading = true;
    var searchTerm: string;
    if (!this.searchTerm || this.searchTerm === '') {
      searchTerm = '';
    } else {
      searchTerm = this.searchTerm;
    }
    let editFoodFilter = new EditFoodFilter();
    editFoodFilter.isRecipe = this.isRecipe;
    editFoodFilter.langId = this.selectedLang;
    editFoodFilter.pageNumber = pageNumber;
    editFoodFilter.pageSize = pageSize;
    editFoodFilter.sourceKey = this.selectedSource.toString();
    editFoodFilter.searchTerm = searchTerm;
    editFoodFilter.categoryId = categoryId;

    this.editFoodsService.getAllForTable(editFoodFilter).subscribe((data) => {
      this.data = data;
      this.isLoading = false;
    });
  }

  getPaginatorData(headFilter: HeadFilter) {
    this.getFoodsForTable(headFilter.pageSize, headFilter.pageNumber);
  }
  isEmpty() {
    return _.isEmpty(this.foodsCheckList);
  }

  openTranslations() {
    if (!this.isRecipe) {
      var str = Object.keys(this.foodsCheckList).join(',');
      this.router.navigate(['foods/edit-food-bulk'], {
        queryParams: {
          foodIds: str,
          langId: this.selectedLang,
          isRecipe: true,
        },
      });
    } else {
      var str = Object.keys(this.foodsCheckList).join(',');
      this.router.navigate(['foods/edit-recipe-bulk'], {
        queryParams: {
          foodIds: str,
          langId: this.selectedLang,
          isRecipe: true,
        },
      });
      //this.editFoodsService.getRecipesBulkForEdit(this.foodsCheckList, this.selectedLang).subscribe(res=>{

      //});
    }
  }

  showSourceKeysLabels() {
    var sourceKeysArr = [];
    this.selectedSource.map((x) => {
      switch (x) {
        case SourceKeys.zameret:
          sourceKeysArr.push('Zameret');
          break;
        case SourceKeys.usda:
          sourceKeysArr.push('USDA');
          break;
        case SourceKeys.mygenes:
          sourceKeysArr.push('MyGenes');
          break;
      }
    });
    this.sourceKeysLabel = sourceKeysArr.join(', ');
  }
  onFoodCheckListChanged(list) {
    this.foodsCheckList = list;
  }
}
