<div class="lifestyle" *ngIf="rest && langs">
  <div class="lifestyle__container">
    <ng-container
      *ngFor="
        let question of important;
        let index = index;
        trackBy: trackByQuestionId
      "
    >
      <span *ngIf="false">
        index:{{ index }} showIndex: {{ showIndex }}refId:{{
          hasRefId(question)
        }}</span
      >
      <mat-card class="card-question">
        <!--   *ngIf="
          important &&
          langs &&
          showIndex >= index &&
          (!hasRefId(question) || refAnswered(question.referenceQuestionId))
        "-->
        <!--
          <div>{{ question.lifestyleQuestionLangs[0].answerText }}</div>
        
          showIndex : {{ showIndex }} , index :{{ index }}
          -->
        <app-lifestyle-question-edit-node
          class="important"
          [important]="true"
          [ngStyle]="{
            'pointer-events': isLoading && !isPreview ? 'none' : ''
          }"
          [parentIdx]="index"
          [question]="question"
          [productId]="productId"
          [langs]="langs"
          [isLoading]="isLoading"
          [isPreview]="isPreview"
          [isPreview]="true"
          [lifestyleQuestions]="important"
          (incrementIndex)="onIcrementIndex($event)"
        ></app-lifestyle-question-edit-node>
      </mat-card>
    </ng-container>
  </div>
  <div style="color: #d3247e">OTHER INFO</div>
  <hr style="border: 1px solid #d3247e" />
  <div class="lifestyle__container">
    <ng-container
      *ngFor="
        let question of rest;
        let index = index;
        trackBy: trackByQuestionId
      "
    >
      <span *ngIf="false">
        index:{{ index }} showIndex: {{ showIndex }}refId:{{
          hasRefId(question)
        }}</span
      >
      <mat-card
        class="card-question"
        *ngIf="
          rest &&
          langs &&
          (true ||
            !hasRefId(question) ||
            refAnswered(question.referenceQuestionId))
        "
      >
        <!--
          <div>{{ question.lifestyleQuestionLangs[0].answerText }}</div>
        
          showIndex : {{ showIndex }} , index :{{ index }}
          -->
        <app-lifestyle-question-edit-node
          [ngStyle]="{ 'pointer-events': isLoading ? 'none' : '' }"
          [parentIdx]="index"
          [question]="question"
          [productId]="productId"
          [langs]="langs"
          [isLoading]="isLoading"
          [lifestyleQuestions]="rest"
          [isPreview]="true"
          (incrementIndex)="onIcrementIndex($event)"
        ></app-lifestyle-question-edit-node>
      </mat-card>
    </ng-container>
  </div>
</div>
