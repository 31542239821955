<mat-card class="wrapper">
  <div class="title">
    {{ coeff.fgCoefficientId > 0 ? "Edit" : "Add" }}
    coefficient
  </div>
  <div class="item" *ngIf="!coeff.fgCoefficientId">
    <div class="item__label">Add From:</div>
    <div class="item__field">
      <mat-radio-group [(ngModel)]="addMode" (ngModelChange)="addModeChange()">
        <mat-radio-button class="example-radio-button" value="new">
          New
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" value="list">
          List
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <ng-container *ngIf="addMode">
    <div class="item" *ngIf="addMode === 'list' && prototypes">
      <div class="item__label">Select prototype:</div>
      <div class="item__field">
        <mat-form-field appearance="fill" class="form-field">
          <mat-select
            (selectionChange)="onSelectProptotype($event)"
            placeholder="Select"
          >
            <mat-option
              *ngFor="let item of prototypes"
              [value]="item.fgCoefficientId"
            >
              {{ item.fgCoefficientName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="addMode === 'new' || selectedPrototype">
      <div class="item mt-4">
        <div class="item__label">Name:</div>
        <div class="item__field">
          <mat-form-field appearance="fill" class="form-field">
            <input matInput [(ngModel)]="coeff.fgCoefficientName" />
          </mat-form-field>
        </div>
      </div>
      <div class="item">
        <div class="item__label">Order by:</div>
        <div class="item__field">
          <mat-form-field appearance="fill" class="form-field number">
            <input
              matInput
              [(ngModel)]="coeff.orderBy"
              type="number"
              min="1"
              step="1"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="item"
        *ngIf="coeffType?.fgCoefficientTypeId !== CoefficientTypeIdEnum.Alerts"
      >
        <div class="item__label">Valid except percent:</div>
        <div class="item__field">
          <mat-form-field appearance="fill" class="form-field number">
            <input
              matInput
              [(ngModel)]="coeff.validExceptPersent"
              type="number"
              min="0"
              max="100"
              step="1"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="actions mt-4">
    <div class="actions__empty"></div>
    <div class="actions__btns">
      <button
        class="save"
        mat-button
        [disabled]="
          !coeff.fgCoefficientName &&
          !coeff.orderBy &&
          !coeff.validExceptPersent
        "
        (click)="save()"
        type="submit"
      >
        Save
      </button>
      <button class="cancel" mat-button (click)="cancel()" type="button">
        Close
      </button>
    </div>
  </div>
</mat-card>
