import { Agent } from './Agent';
import { OfficePhoneExtensionGroup } from './OfficePhoneExtensionGroup';

export class OfficePhoneExtension {
  officePhoneExtensionId: number;
  officePhoneExtensionGroupId: number;
  officePhoneExtensionGroup: OfficePhoneExtensionGroup;
  phoneExtensionName: string;
  displayName: string;
  payCallSIP: string;
  isActive: boolean;
  agentId: number;
  agent: Agent;
}
