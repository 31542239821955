<div class="container">
  <div class="title">עריכת סיומות</div>

  <div class="table" *ngIf="fileExtensions">
    <div class="table-header">
      <div class="percent-20">סיומת</div>
      <div class="percent-20 text-center">בעלת חתימה</div>
      <div class="percent-50">חתימה</div>
      <div class="percent-10"></div>
    </div>
    <div class="table-body">
      <mat-card
        class="table-row"
        *ngFor="let item of fileExtensions; let i = index"
      >
        <form (ngSubmit)="onSave(item, extensionForm)" #extensionForm="ngForm">
          <div class="percent-20">
            <mat-form-field appearance="fill" *ngIf="isEditMode(item)">
              <input
                name="extension-{{ i }}"
                required
                matInput
                [(ngModel)]="item.extension"
              />
            </mat-form-field>
            <ng-container *ngIf="isViewMode(item)">
              {{ item.extension }}
            </ng-container>
          </div>
          <div class="percent-20 text-center">
            <mat-slide-toggle
              [disabled]="isViewMode(item)"
              [(ngModel)]="item.havingSignature"
              (change)="onSignatureChange(item, i, extensionForm)"
              name="havingSignature-{{ i }}"
            ></mat-slide-toggle>
          </div>
          <div class="percent-50">
            <mat-form-field
              *ngIf="item.havingSignature && isEditMode(item)"
              appearance="fill"
            >
              <input
                name="signature-{{ i }}"
                matInput
                [(ngModel)]="item.signature"
              />
            </mat-form-field>
            <ng-container *ngIf="isViewMode(item)">
              {{ item.signature }}
            </ng-container>
          </div>
          <div class="percent-10 actions">
            <button
              mat-icon-button
              (click)="onEditMode(item)"
              *ngIf="isViewMode(item)"
              [disabled]="editDisabled(item)"
              class="edit"
              type="button"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="isEditMode(item)"
              class="save"
              type="submit"
              [disabled]="extensionForm.invalid"
            >
              <mat-icon>check</mat-icon>
            </button>
            <button
              (click)="onCancelEditMode(item)"
              *ngIf="isEditMode(item)"
              mat-icon-button
              class="close"
              type="button"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </form>
      </mat-card>
    </div>
    <div class="table-footer">
      <button
        mat-raised-button
        color="primary"
        (click)="onAddNewExtension()"
        [disabled]="addDisabled"
      >
        <mat-icon>add</mat-icon>
        הוסף סיומת
      </button>
    </div>
    <br /><br />
  </div>
</div>
