<table mat-table [dataSource]="products" class="mat-elevation-z8 mt-50">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="TubeId">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">Tube Id</th>
    <td mat-cell *matCellDef="let product">{{ product.barCode }}</td>
  </ng-container>

  <ng-container matColumnDef="RegDate">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">RegDate</th>
    <td mat-cell *matCellDef="let product">
      {{ product.registeredDate | date: "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="PatientName">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">Name</th>
    <td mat-cell *matCellDef="let product">{{ product.patientName }}</td>
  </ng-container>

  <ng-container matColumnDef="ChangeDate">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">Change Date</th>
    <td mat-cell *matCellDef="let product">
      {{ product.statusChangeDate | date: "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
