import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgCoefficientType } from 'src/app/data/food-genes/FgCoefficientType';
import {
  AlgoMainBoardEnum,
  CoefficientTypeIdEnum,
  FoodGenesBoardEnum,
} from 'src/app/Enums/StatusesEnum';
import { ToastErrorComponent } from 'src/app/FoodGenes/toast-error/toast-error.component';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-more-panel',
  templateUrl: './more-panel.component.html',
  styleUrls: ['./more-panel.component.scss'],
})
export class MorePanelComponent implements OnInit {
  //#region Properties
  @ViewChild('catCard', { static: false }) catCard: ElementRef;
  @Output() loadCategories: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openMainBoard: EventEmitter<{
    mode: AlgoMainBoardEnum | FoodGenesBoardEnum;
    category?: AlgoCategory;
    subCategory?: AlgoSubCategory;
  }> = new EventEmitter<null>();
  @Input() canEdit: boolean;
  @Input() category: AlgoCategory;
  @Input() subCategory: AlgoSubCategory;
  @Input() versionId: number;
  @Input() isCategory = false;
  @Input() isSubCategory = false;
  @Input() fromCategory: boolean;
  @Input() fromSubCategory: boolean;
  @Input() langId: number;
  @Input() isFoodGenes: boolean = false;
  slectedResultTypes: number[];
  isCategoryMenuOpen = false;
  addState: 'addFromList' | 'addFromInput'; //wheter to show add from list or add from input
  name: string;
  subName: string;
  //#endregion
  constructor(
    private _snackBar: MatSnackBar,
    private popupHandlerService: PopupHandlerService,
    private algorithmService: AlgorithmService,
    private foodGenesService: FoodGenesService
  ) {}
  ngOnInit(): void {
    this.name = this.isFoodGenes ? 'Coefficient type' : 'Category';
    this.subName = this.isFoodGenes ? 'Coefficient' : 'Sub category';
  }
  stopPropagation(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
  onMenuClosed() {
    this.addState = null;
  }
  onSelectResults() {}
  onMenuOpened() {}
  delete() {
    this.popupHandlerService.openConfirmationDialog('', `Delete ${this.name}`);
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes') {
          //const category = this.data as AlgoCategory;
          if (this.isFoodGenes) {
            const coeff = { ...this.subCategory } as any;
            this.foodGenesService
              .removeCoefficient(coeff.fgCoefficientId)
              .subscribe((data) => {
                if ((data as ErrorMessage)?.message) {
                  // this._snackBar.open((data as ErrorMessage).message);
                  this._snackBar.openFromComponent(ToastErrorComponent, {
                    data: (data as ErrorMessage).message,
                  });
                } else {
                  this._snackBar
                    .open(`${this.name} deleted`, 'Close', {})
                    ._dismissAfter(2000);
                  this.loadCategories.emit(true);
                }
              });
            return;
          }
          if (this.isCategory) {
            this.algorithmService
              .removeCategory(this.category.categoryId, this.versionId)
              .subscribe((data) => {
                if ((data as ErrorMessage)?.message) {
                  this._snackBar.open((data as ErrorMessage).message, '', {
                    duration: 2000,
                  });
                } else {
                  this._snackBar
                    .open(`${this.name} deleted`, 'Close', {})
                    ._dismissAfter(2000);
                  this.loadCategories.emit(true);
                }
              });
          } else {
            this.algorithmService
              .removeSubCategory(this.subCategory.subCategoryId, this.versionId)
              .subscribe((data) => {
                if ((data as ErrorMessage)?.message) {
                  this._snackBar.open((data as ErrorMessage).message, '', {
                    duration: 2000,
                  });
                } else {
                  this._snackBar
                    .open(`${this.subName} deleted`, 'Close', {})
                    ._dismissAfter(2000);
                  this.loadCategories.emit(true);
                }
              });
          }
        }
      });
  }
  onLoadCategories() {
    this.isCategoryMenuOpen = false;
    this.loadCategories.emit(true);
  }
  editCategory() {
    const mode = this.isFoodGenes
      ? FoodGenesBoardEnum.coefficientType
      : AlgoMainBoardEnum.category;
    this.openMainBoard.emit({
      mode,
      category: this.category,
      subCategory: this.subCategory || null,
    });
  }
  toggleActivateSubCategory() {
    const isActive = !this.subCategory.isActive;
    this.algorithmService
      .toggleActivateSubCat(this.subCategory.subCategoryId, isActive)
      .subscribe(() => {
        this._snackBar
          .open(
            `Sub Category ${isActive ? 'Activated' : 'Deactivated'}`,
            'Close',
            {}
          )
          ._dismissAfter(2000);
        this.loadCategories.emit(true);
      });
  }
  editSubCategory() {
    const from = this.fromCategory ? 'category' : 'subCategory';
    const mode = this.isFoodGenes
      ? FoodGenesBoardEnum.coefficient
      : AlgoMainBoardEnum.subCategory;
    this.openMainBoard.emit({
      mode,
      category: this.category,
      subCategory: this.fromCategory ? null : this.subCategory,
    });
  }

  editCoeffType() {
    const mode = this.isFoodGenes
      ? FoodGenesBoardEnum.coefficientType
      : AlgoMainBoardEnum.category;
    this.openMainBoard.emit({
      mode,
      category: this.category,
      subCategory: this.subCategory || null,
    });
  }

  isAlerts() {
    const coeff = { ...this.category } as any;
    return (
      (coeff as FgCoefficientType).fgCoefficientTypeId ==
      CoefficientTypeIdEnum.Alerts
    );
  }
}
