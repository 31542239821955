import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlgoResultType } from 'src/app/data/algorithm/AlgoResultType';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { AddModeEnum } from '../category-prototype/category-prototype.component';

@Component({
  selector: 'app-result-type-prototype',
  templateUrl: './result-type-prototype.component.html',
  styleUrls: ['./result-type-prototype.component.scss'],
})
export class ResultTypePrototypeComponent implements OnInit, OnChanges {
  @Input() langId: number = 1;
  resultTypes: AlgoResultType[];
  resultType: AlgoResultType;
  resultTypeForm: FormGroup;
  filteredOptions: Observable<AlgoResultType[]>;
  errorMessage: string;
  addMode: AddModeEnum = AddModeEnum.FromList;
  AddModeEnum = AddModeEnum;
  AlgoImageType = AlgoImageType;
  constructor(
    private fb: FormBuilder,
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.langId &&
      changes.langId.currentValue !== changes.langId.previousValue
    ) {
      this.getListPrototypeResultType();
    }
  }

  ngOnInit(): void {
    this.getListPrototypeResultType();
  }
  onChangeMode(e: MatRadioChange) {
    this.resultType = new AlgoResultType();
    this.resultType.files = [];
    this.createForm();
  }
  getListPrototypeResultType() {
    return this.algorithmService
      .getListPrototypeResultType(this.langId)
      .subscribe((data) => {
        this.resultTypes = data;
        if (!this.resultType) {
          this.resultType = new AlgoResultType();
          this.resultType.files = [];
        }
        this.createForm();
      });
  }
  getPrototypeResultType(typeId: number) {
    return this.algorithmService
      .getPrototypeResultType(typeId, this.langId)
      .subscribe((data) => {
        this.resultType = data;
        this.resultType.color = `#${this.resultType.color}`;
        this.createForm();
      });
  }
  createForm() {
    this.resultTypeForm = this.fb.group({
      id: this.fb.control(
        this.resultType.algoResultTypeId ? this.resultType.algoResultTypeId : 0
      ),
      name: this.fb.control(this.resultType.name, Validators.required),
      color: this.fb.control(
        this.resultType.color ? this.resultType.color : '#000000',
        Validators.required
      ),
      orderBy: this.fb.control(this.resultType.orderBy, Validators.required),
    });
    this.setFilterdOptions();
  }
  setFilterdOptions() {
    this.filteredOptions = this.ResultTypeName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }
  private _filter(value: number): AlgoResultType[] {
    const filterValue = value?.toString();

    return this.resultTypes?.filter((x) =>
      x.name.toString().includes(filterValue)
    );
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // when the user selects an option from the autocomplete
    const selectedResult = this.findResultByName(event.option.value);
    this.getPrototypeResultType(selectedResult.algoResultTypeId);
  }
  handleErrorOrSuccess(res: ErrorMessage | any, snackbarMassege: string) {
    if ((res as ErrorMessage)?.message) {
      const error = res as ErrorMessage;
      this.errorMessage = error.message;
    } else {
      this._snackBar
        .open(`Prototype category ${snackbarMassege}`, 'Close', {})
        ._dismissAfter(2000);
      this.getListPrototypeResultType();
    }
  }
  findResultByName(name: string) {
    return this.resultTypes.find((x) => x.name === name);
  }
  onUploadFinished(event) {
    const finded = this.resultType.files.find(
      (x) => x.fileType == event.fileType
    );
    if (finded) {
      this.resultType.files = this.resultType.files.filter(
        (x) => x.fileType != event.fileType
      );
    }
    this.resultType.files.push(event);
  }
  getImage(imageType: number) {
    const image = this.resultType.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.resultType.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  get ResultTypeName() {
    return this.resultTypeForm.get('name');
  }
  get CategoryColor() {
    return this.resultTypeForm.get('color');
  }
  onSubmit() {
    if (this.resultTypeForm.valid) {
      this.resultType.algoResultTypeId = this.resultTypeForm.value.id;
      this.resultType.name = this.resultTypeForm.value.name;
      const color = this.resultTypeForm.value.color as string;
      this.resultType.color = color.replace('#', '');
      this.resultType.orderBy = this.resultTypeForm.value.orderBy;
      this.resultType.langId = this.langId;
      if (this.resultType.algoResultTypeId == 0) {
        this.algorithmService
          .addPrototypeResultType(this.resultType)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'added');
          });
      } else if (this.resultType.algoResultTypeId > 0) {
        this.algorithmService
          .setPrototypeResultType(this.resultType)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'updated');
          });
      }
    }
  }
}
