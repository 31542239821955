<div class="container">
  <div class="title">הגדרת קבוצת סיומות</div>
  <mat-card>
    <form
      (ngSubmit)="onSubmit(settingsForm)"
      #settingsForm="ngForm"
      *ngIf="fileUploadSetting"
    >
      <div class="form-group">
        <label>שם קבוצה</label>
        <mat-form-field appearance="fill">
          <input
            matInput
            type="text"
            placeholder="שם קבוצה"
            name="name"
            [(ngModel)]="fileUploadSetting.name"
            #name="ngModel"
            required
            disabled
          />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label>סיומות</label>
        <mat-form-field appearance="fill">
          <mat-select
            placeholder="סיומות"
            [(ngModel)]="selectedExtensions"
            (selectionChange)="onExtensionsChange($event)"
            multiple
            name="extensions"
            required
          >
            <mat-option
              *ngFor="let item of extensions"
              [value]="item.extensionId"
              >{{ item.extension }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong>ביצוע סריקה</strong>
        <mat-slide-toggle
          [(ngModel)]="fileUploadSetting.isScan"
          name="isScan"
        ></mat-slide-toggle>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong>בדיקת חתימה</strong>
        <mat-slide-toggle
          [(ngModel)]="fileUploadSetting.isSignatureCheck"
          name="isSignatureCheck"
        ></mat-slide-toggle>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong>קובץ בכמה שפות</strong>
        <mat-slide-toggle
          [(ngModel)]="fileUploadSetting.byLang"
          name="byLang"
        ></mat-slide-toggle>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong>האם יש גישה מהweb?</strong>
        <mat-slide-toggle
          [(ngModel)]="fileUploadSetting.isWebAccess"
          name="isWebAccess"
        ></mat-slide-toggle>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>
          <div><strong>בחירת תמונה אחת</strong></div>
          <small>בעת העלאת קובץ מסוג זה האם לבצע דריסת תמונה קודמת</small>
        </div>
        <mat-slide-toggle
          [(ngModel)]="fileUploadSetting.isSingleMode"
          name="isSingleMode"
        ></mat-slide-toggle>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong>מקסימום גודל</strong>
        <div fxLayout="row">
          <mat-radio-group
            [(ngModel)]="maxSizeMode"
            (change)="onMaxSizeChanged($event)"
            name="maxSizeMode"
          >
            <mat-radio-button [value]="false">ללא הגבלה</mat-radio-button>
            <mat-radio-button [value]="true"> </mat-radio-button>
          </mat-radio-group>
          <div style="position: relative">
            <input
              [style.border]="maxSize.errors?.['pattern'] || maxSize.errors?.['required'] ? '1px solid red' : ''"
              [disabled]="!maxSizeMode"
              [(ngModel)]="fileUploadSetting.maxSize"
              type="number"
              name="maxSize"
              style="width: 8.5rem; padding-right: 5px"
              pattern="[1-9]+[0-9]*"
              #maxSize="ngModel"
              required
            />
            <small style="position: absolute; left: 5px; bottom: 0px"
              >ק"ב</small
            >
          </div>
          <div *ngIf="maxSize.errors?.['pattern']">
            &nbsp;
            <small>ערך לא חוקי</small>
          </div>
        </div>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong fxFlex="25">שם ברירת מחדל</strong>
        <div fxFlex="75">
          <input
            [style.border]="nameTemplate.errors?.['required'] ? '1px solid red' : ''"
            type="text"
            placeholder="שמור ב..."
            [(ngModel)]="fileUploadSetting.nameTemplate"
            name="nameTemplate"
            class="input-native"
            required
            #nameTemplate="ngModel"
          />
        </div>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong fxFlex="25">נתיב שמירה</strong>
        <div fxFlex="75">
          <input
            [style.border]="devicePath.errors?.['required'] ? '1px solid red' : ''"
            type="text"
            placeholder="שמור ב..."
            [(ngModel)]="fileUploadSetting.devicePath"
            name="devicePath"
            class="input-native"
            required
            #devicePath="ngModel"
          />
        </div>
      </div>
      <div
        class="form-group"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <strong fxFlex="25">נתיב דומיין</strong>
        <div fxFlex="75">
          <input
            type="text"
            placeholder="שמור ב..."
            [(ngModel)]="fileUploadSetting.domainPath"
            name="domainPath"
            class="input-native"
          />
        </div>
      </div>
      <div class="form-group actions">
        <button type="submit" class="save" mat-button>שמירה</button>
        <button type="button" class="cancel" (click)="onCancel()" mat-button>
          ביטול
        </button>
      </div>
    </form>
  </mat-card>
</div>
