import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { CloudflareErrorMessage } from 'src/app/data/CloudflareErrorMessage';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Video } from 'src/app/data/Video';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { VideoForTable } from 'src/app/data/VideoForTable';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { FileUploadType } from 'src/app/Enums/StatusesEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideosService } from 'src/app/Services/videos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-videos-table',
  templateUrl: './videos-table.component.html',
  styleUrls: ['./videos-table.component.scss'],
})
export class VideosTableComponent implements OnInit {
  constructor(
    private popupHandlerService: PopupHandlerService,
    private videosService: VideosService
  ) {}
  @Input() videos: Video[];
  @Input() headFilter: HeadFilter;
  @Input() agents: AgentMin[];
  @Input() categories: VideoCategory[];

  @Output('filterChanged') filterChanged: EventEmitter<HeadFilter> =
    new EventEmitter();
  headers = [
    { name: 'poster', val: 'poster', class: '' },
    { name: 'Video Name', val: 'VideoName', class: '' },
    { name: 'Upload Date', val: 'UploadDate', class: '' },
    { name: 'Create Date', val: 'CreateDate', class: '' },
    { name: 'Video Length', val: 'Duration', class: '' },
    { name: 'Video Editor', val: 'Agent', class: '' },
    { name: 'Category', val: 'Category', class: '' },
    { name: 'Status', val: 'Active', class: '' },
    { name: '', val: '', class: 'action' },
  ];
  ngOnInit(): void {}

  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }
  openVideoDialog(videoId: number) {
    this.popupHandlerService.openAddNewVideoDialog(
      videoId,
      this.agents,
      this.categories
    );
  }
  onToggleStatus({ checked }: MatSlideToggleChange, videoId: number) {
    this.videosService.toggleStatus(videoId, checked).subscribe((data) => {
      this.filterChanged.emit(this.headFilter);
    });
  }
  deleteVideoFromCloudflare(video: Video) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.ans === 'yes') {
          if (this.getVideo(video)) {
            this.videosService
              .deleteVideo(this.getVideo(video))
              .subscribe((data) => {
                if ((data as CloudflareErrorMessage)?.message) {
                  const error = data as CloudflareErrorMessage;
                  this.popupHandlerService.openDisapearingAlertDialog(
                    DisapearingTextDialogEnum.costumMessage,
                    '',
                    '',
                    error.message
                  );
                  if (error.status == 404) {
                    this.deleteVideo(video);
                  }
                } else {
                  this.deleteVideo(video);
                }
              });
          } else {
            this.deleteVideo(video);
          }
        }
      });
  }
  deleteVideo(video: Video) {
    this.videosService.delete(video.videoId).subscribe((data) => {
      this.filterChanged.emit(this.headFilter);
    });
  }
  getPoster(video: Video) {
    const poster = video.files.find(
      (x) => x.fileType == FileUploadType.VideoPoster
    );
    if (poster) {
      const imagePath = `${poster.webPath}/${poster.fileName}`;
      return this.replaceWebPath(imagePath);
    }
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }

  getVideo(video: Video) {
    const videoFile = video.files.find(
      (x) => x.fileType == FileUploadType.VodVideo
    );
    if (videoFile) {
      return videoFile.fileName;
    }
  }
}
