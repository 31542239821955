import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { take } from 'rxjs/operators';
import { AlgoBullet } from 'src/app/data/AlgoBullet';
import { AlgoBulletImage } from 'src/app/data/AlgoBulletImage';
import { AlgoBulletMin } from 'src/app/data/AlgoBulletMin';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { BulletAlgoCrossing } from 'src/app/data/BulletAlgoCrossing';
import { BulletBloodCrossing } from 'src/app/data/BulletBloodCrossing';
import { Category } from 'src/app/data/Category';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { SubCategory } from 'src/app/data/SubCategory';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { AlgoBulletImagesService } from 'src/app/Services/algo-bullet-images.service';
import { AlgoBulletLinksService } from 'src/app/Services/algo-bullet-links.service';
import { AlgoBulletService } from 'src/app/Services/algo-bullet.service';
import { BloodTestTypesService } from 'src/app/Services/blood-test-types.service';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { SubCategoriesService } from 'src/app/Services/sub-categories.service';

@Component({
  selector: 'app-all-algo-bullets',
  templateUrl: './all-algo-bullets.component.html',
  styleUrls: ['./all-algo-bullets.component.scss'],
})
export class AllAlgoBulletsComponent implements OnInit {
  categories: Category[];
  @ViewChild('bulletFormDirective') ngForm: FormGroupDirective;
  bulletsData: AlgoBulletMin[];
  agents;
  @ViewChild('toggle') toggleDatepicker: ElementRef;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesTable: LeadStatus[];
  regularUser: RegularUser;
  bulletForm: FormGroup;
  genetic: FormArray;
  bloodeTest: FormArray;
  subCategories: SubCategory[];
  algoConditions: Status[];
  algoValues: Status[];
  bloodeTestTypes: BloodTestType[];
  algoBullet: AlgoBullet;
  tableResizeBig: boolean = false;
  tableResizeMedium: boolean;
  isLoading: boolean = false;
  isLoading2: boolean = false;
  isEditMode: boolean;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  imgError: string;
  tempFormData: FormData;
  tempImgName: string;
  tempImg: any;
  langId = 1;
  algoBulletLinks: any[];
  algoImage: AlgoBulletImage;
  /*  clientWidth:any;
  tableResize:any;
  formResize:any; */
  constructor(
    private filterMemoryService: FilterMemoryService,
    private fb: FormBuilder,
    private subCategoriesService: SubCategoriesService,
    private statusesService: StatusesService,
    private bloodeTestService: BloodTestTypesService,
    private algoBulletService: AlgoBulletService,
    private popupHandlerService: PopupHandlerService,
    private catergoriesService: CatergoriesService,
    private domSanitizer: DomSanitizer,
    private algoBulletsLinksService: AlgoBulletLinksService,
    private algoBulletImagesService: AlgoBulletImagesService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.leads);
    this.algoBullet = new AlgoBullet();
    this.algoBullet.bulletAlgoCrossings = [];
    //SET DEFAULT HEAD FILTER
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.algoBullets
    );
  }
  ngOnInit(): void {
    this.catergoriesService
      .onlyCategories()
      .subscribe((categories) => (this.categories = categories));

    this.subCategoriesService
      .getAll()
      .subscribe((category) => (this.subCategories = category));

    this.statusesService.getAll().subscribe((status) => {
      this.algoConditions = status.filter(
        (data) => data.category === 'AlgoBulletCondition'
      );
      this.algoValues = status.filter(
        (data) => data.category === 'AlgoBulletValue'
      );
    });
    this.bloodeTestService
      .getAll()
      .subscribe((data) => (this.bloodeTestTypes = data));
    this.createForm();
    this.getAllBullets();
    this.getAlgoLinks();
  }
  getAlgoImage(id: number) {
    this.algoBulletImagesService.getSingleImg(id).subscribe((data) => {
      this.algoImage = data;
    });
  }
  getAlgoLinks() {
    this.algoBulletsLinksService.getAll(this.langId).subscribe((data) => {
      this.algoBulletLinks = data;
    });
  }
  getSelectedValue(value: number) {
    if (value) {
      return this.subCategories?.find((x) => x.subCategoryId == value)
        ?.subCategoryName;
    }
  }
  getAllBullets() {
    this.algoBulletService.getAll(this.headFilter).subscribe((data) => {
      this.bulletsData = data.list;
      this.metaData = data.metaData;
    });
  }
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    this.headFilter = e;
    this.filterMemoryService.setfilter(
      this.headFilter,
      PageTypeEnum.algoBullets
    );
    this.getAllBullets();
  }
  onCloseEditMode(event) {
    if (event) {
      this.resetAndClearForm(this.ngForm);
    }
  }
  sanitizePreviewSrc(src) {
    return this.domSanitizer.bypassSecurityTrustUrl(src);
  }
  onEditBullet(id) {
    this.isLoading = true;
    this.isEditMode = true;
    this.tableResizeBig = false;
    this.algoBulletService.getByBulletId(id).subscribe((data) => {
      this.algoBullet = data;
      var {
        categoryId,
        bulletAlgoCrossings,
        bulletBloodCrossings,
        description,
        title,
        algoBulletLinkId,
        linkViewText,
        algoBulletImageId,
        algoBulletImage,
      } = this.algoBullet;
      // reset form
      //this.bulletForm.reset();

      // Category Id
      this.bulletForm.controls['categoryId'].setValue(categoryId.toString());

      // BulletAlgoCrossing
      this.genetic = this.bulletForm.get('genetic') as FormArray;
      this.genetic.clear();
      this.genetic.patchValue(bulletAlgoCrossings);
      bulletAlgoCrossings.forEach((x) => {
        this.genetic.push(this.fb.group(x));
      });

      // BulletBloodCrossing
      this.bloodeTest = this.bulletForm.get('bloodeTest') as FormArray;
      this.bloodeTest.clear();
      this.bloodeTest.patchValue(bulletBloodCrossings);
      bulletBloodCrossings.forEach((x) => {
        this.bloodeTest.push(this.fb.group(x));
      });

      // Title & Description
      this.bulletForm.controls['title'].setValue(title);
      this.bulletForm.controls['description'].setValue(description);
      this.bulletForm.controls['linkPageId'].setValue(algoBulletLinkId);
      this.bulletForm.controls['linkViewText'].setValue(linkViewText);

      if (algoBulletImageId) {
        this.algoImage = algoBulletImage;
      }
      this.isLoading = false;
    });
  }
  onDeleteBullet(id) {
    this.algoBulletService.delete(id).subscribe((data) => {
      this.getAllBullets();
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.deletedSuccessfully
      );
    });
  }
  getSubCategoryName(idx) {
    var id =
      this.bulletForm.get('genetic')['controls'][idx].value.subCategoryId;
    return this.subCategories?.find((x) => x.subCategoryId == id)
      ?.subCategoryName;
  }
  openFileManager() {
    this.algoBulletImagesService.getImages().subscribe((data) => {
      this.popupHandlerService.openFilesManagerDialog(
        data,
        this.algoBullet.algoBulletImageId
      );
    });

    this.popupHandlerService.filesManagerSubject
      .pipe(take(1))
      .subscribe(({ event, selectedImg, formData }) => {
        if (event === 'add') {
          this.algoBullet.algoBulletImageId = selectedImg.algoBulletImageId;
          this.getAlgoImage(this.algoBullet.algoBulletImageId);
        } else if (event === 'upload') {
          this.algoBulletImagesService.post(formData).subscribe((data) => {
            this.openFileManager();
          });
        }
      });
  }
  // Create Form
  createForm() {
    this.bulletForm = this.fb.group({
      genetic: this.fb.array([this.createGeneticItem()]),
      bloodeTest: this.fb.array([this.createBloodeTestItem()]),
      title: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required),
      categoryId: this.fb.control('', Validators.required),
      linkPageId: this.fb.control(''),
      linkViewText: this.fb.control(''),
    });
    (this.bulletForm.get('bloodeTest') as FormArray).clear();
  }
  //genetic fields
  createGeneticItem(subCategoryId = null, conditionId = null, valueId = null) {
    return this.fb.group({
      subCategoryId: [subCategoryId, Validators.required],
      conditionId: [conditionId, Validators.required],
      valueId: [valueId, Validators.required],
    });
  }
  addGeneticItem() {
    this.genetic = this.bulletForm.get('genetic') as FormArray;
    this.genetic.push(this.createGeneticItem());
  }
  removeGeneticItem(index) {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          (this.bulletForm.get('genetic') as FormArray).removeAt(index);
        }
      });
  }
  disableRemoveGeneticBtn() {
    if (this.bulletForm.get('genetic')['controls'].length > 1) {
      return false;
    }
    if (this.bulletForm.get('genetic')['controls'].length == 1) {
      return true;
    }
  }
  //bloode test fields
  createBloodeTestItem(
    bloodTestTypeId = null,
    lowerValue = null,
    upperValue = null,
    description = null
  ) {
    return this.fb.group({
      bloodTestTypeId: [bloodTestTypeId, Validators.required],
      lowerValue: [lowerValue, Validators.required],
      upperValue: [upperValue, Validators.required],
      /*  description: [description, Validators.required], */
    });
  }
  addBloodeTestItem() {
    this.bloodeTest = this.bulletForm.get('bloodeTest') as FormArray;
    this.bloodeTest.push(this.createBloodeTestItem());
  }
  removebloodeTestItem(index) {
    this.popupHandlerService.openConfirmationDialog(null, 'delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          (this.bulletForm.get('bloodeTest') as FormArray).removeAt(index);
        }
      });
  }
  // Reset and clear form after submit
  resetAndClearForm(
    bulletFormDirective: FormGroupDirective,
    afterSave = false
  ) {
    if (true) {
      //if (!afterSave) {

      this.popupHandlerService.openConfirmationDialog(null, 'Clear form');
      //}*/

      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((result) => {
          if (!result) return;
          if (result.ans === 'yes') {
            (this.bulletForm.get('genetic') as FormArray).clear();
            (this.bulletForm.get('bloodeTest') as FormArray).clear();
            this.bulletForm.reset();
            bulletFormDirective.resetForm();
            this.algoBullet = new AlgoBullet();
            this.algoImage = null;
            this.addGeneticItem();
            this.isEditMode = false;
          }
        });
    }
  }
  // Submit Form
  SubmitForm(bulletFormDirective: FormGroupDirective) {
    if (this.bulletForm.valid) {
      this.algoBullet.categoryId = this.bulletForm.value.categoryId;
      this.algoBullet.bulletAlgoCrossings = this.bulletForm.value.genetic.map(
        ({ subCategoryId, conditionId, valueId }) => {
          return new BulletAlgoCrossing(subCategoryId, conditionId, valueId);
        }
      );
      this.algoBullet.bulletBloodCrossings =
        this.bulletForm.value.bloodeTest.map((x) => {
          var a = x;
          var bulletBloodCrossing = new BulletBloodCrossing(
            x.bloodTestTypeId,
            x.lowerValue,
            x.upperValue
            //  x.description
          );

          return bulletBloodCrossing;
        });
      this.algoBullet.title = this.bulletForm.value.title;
      this.algoBullet.description = this.bulletForm.value.description;
      this.algoBullet.algoBulletLinkId = this.bulletForm.value.linkPageId;
      this.algoBullet.linkViewText = this.bulletForm.value.linkViewText;

      if (this.algoBullet && !this.algoBullet.algoBulletId) {
        this.algoBulletService.post(this.algoBullet).subscribe((data) => {
          this.resetAndClearForm(bulletFormDirective, true);
          this.getAllBullets();
        });
      } else {
        this.algoBulletService.put(this.algoBullet).subscribe((data) => {
          this.resetAndClearForm(bulletFormDirective, true);
          this.getAllBullets();
        });
      }
    }
  }
  updateReports() {
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.isLoading2 = true;
          this.algoBulletService.updateReports().subscribe((res) => {
            this.isLoading2 = false;
          });
        }
      });
    this.popupHandlerService.openConfirmationDialog('', 'Update Results');
  }
}
