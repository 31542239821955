<mat-list class="leadsTable" role="list">
  <mat-list-item class="leadsTable__header" role="listitem">
    <!--
      <span>Name</span>
      <span>Id</span>
      <span>Registered Date</span>
      <span>Email</span>
      <span>Tel</span>
      <span>Status</span>
    -->
    <ng-container *ngFor="let item of headers">
      <span class="clickble">
        <span>{{ item.name }} </span>
      </span>
    </ng-container>
    <button (click)="moveToEditScreen()" class="new-agent-btn">
      Create New Agent
    </button>
    <!--
        <ng-container *ngFor="let item of headers">
            <span class="clickble" [ngClass]="{'sortSelected': headFilter.orderBy==item.val}">
                <span (click)="changeSortBy(item.val)">{{item.name}} </span>
                <ng-container *ngIf="headFilter.orderBy==item.val">
                    <img src="../../../assets/sort_arrows.svg" *ngIf="headFilter.acending==true"
                        (click)="changeSortOrder()">
                    <img class="rotate180" src="../../../assets/sort_arrows.svg" *ngIf="headFilter.acending==false"
                        (click)="changeSortOrder()">
                </ng-container>

            </span>
        </ng-container>
        -->
  </mat-list-item>
  <mat-card class="leadsTable__item" *ngFor="let agentItem of agentsData">
    <mat-list-item role="listitem">
      <span class="d-flex-center">
        <img class="clientSvg" src="../../../../assets/client.svg" alt="s" />
        <span style="text-align: center"> {{ agentItem.firstName }}</span>
      </span>
      <span style="text-align: center">{{ agentItem.email }}</span>
      <span style="text-align: center">{{ agentItem.phone }}</span>

      <span>
        <mat-form-field
          class="example-full-width round-select"
          style="width: 200px"
        >
          <mat-select
            class="agent-status-select"
            matNativeControl
            disableOptionCentering
            (selectionChange)="saveRoleForAgent($event, agentItem)"
            [(ngModel)]="agentItem.userRoleId"
            style="color: black"
          >
            <mat-option
              *ngFor="let roleType of userRoles"
              [value]="roleType.id"
              style="text-align: center"
            >
              {{ roleType.name }}
            </mat-option>
            <!--background-color: {{ status.color }}-->
          </mat-select>
        </mat-form-field>
      </span>

      <!--<span style="text-align: center;">agent</span>-->
      <span class="text-center">
        <button
          mat-button
          (click)="editAgent($event, agentItem)"
          class="edit-btn"
        >
          <img src="../../../../assets/client-edit.svg" alt="edit" />
        </button>
        <button
          mat-button
          (click)="editAgentSettings($event, agentItem)"
          class="edit-btn"
          style="margin-left: 1rem"
        >
          <mat-icon style="color: #00babf">settings</mat-icon>
        </button>
      </span>
    </mat-list-item>
  </mat-card>
</mat-list>
