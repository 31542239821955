<div class="title">Add new parameter</div>
<div class="item__select">
  <div class="label">Select parameter</div>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-select [(ngModel)]="parameterId">
      <mat-option *ngFor="let item of data" [value]="item.fgParamId">{{
        item.fgParamName
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="actions mt-2">
  <button
    class="save"
    mat-button
    [disabled]="!parameterId"
    (click)="addParameter()"
    type="submit"
  >
    Add
  </button>
  <button class="cancel" mat-button (click)="closeDialog()" type="button">
    Cancel
  </button>
</div>
