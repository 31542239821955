<mat-card class="far-card">
  <mat-card-title>Far login admin</mat-card-title>
  <mat-card-content>
    <div class="item">
      <div class="label">Choose the manager that will get the request SMS</div>
      <mat-form-field class="round-select">
        <mat-select placeholder="select agent" [(ngModel)]="agentId">
          <mat-option [value]="item.agentId" *ngFor="let item of agentsStr">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item">
      <button mat-raised-button (click)="save()">Save</button>
    </div>
  </mat-card-content>
</mat-card>
