import { Component, OnInit } from '@angular/core';
import { AgentMin } from 'src/app/data/AgentMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { Status } from 'src/app/data/Status';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { VideoCategoryMin } from 'src/app/data/VideoCategoryMin';
import { VideoForTable } from 'src/app/data/VideoForTable';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideoCategoriesService } from 'src/app/Services/video-categories.service';
import { VideosService } from 'src/app/Services/videos.service';

@Component({
  selector: 'app-all-videos',
  templateUrl: './all-videos.component.html',
  styleUrls: ['./all-videos.component.css'],
})
export class AllVideosComponent implements OnInit {
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  videos: VideoForTable[];
  statuses: Status[];
  editors: AgentMin[];
  categories: VideoCategory[];
  tableCategories: VideoCategoryMin[];

  constructor(
    private filterMemoryService: FilterMemoryService,
    private videosService: VideosService,
    private agentsService: AgentsService,
    private videoCategoriesService: VideoCategoriesService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.videos);
    this.headFilter.langId = 1;
    this.headFilter.orderBy = 'UploadDate';
    this.getAllVideos();
    this.getAgents();
    this.getCategoriesForDropdown();
    this.subscribeToCateoriesChanges();
    this.subscribeToVideoChanges();
  }
  subscribeToVideoChanges() {
    this.popupHandlerService.videoSubject.subscribe((data) => {
      if (data?.event === 'close') {
        this.popupHandlerService.openDisapearingAlertDialog('Success');
        this.getAllVideos();
      }
    });
  }
  subscribeToCateoriesChanges() {
    this.popupHandlerService.videoCategoriesSubject.subscribe((data) => {
      if (data?.isChanged) {
        this.getCategoriesForDropdown();
      }
    });
  }
  getAgents() {
    this.agentsService.getAll().subscribe((data) => {
      this.editors = [...data].filter((agent) => {
        return (
          agent.roleTypeId == UserRoleEmum.nutritionist ||
          agent.roleTypeId == UserRoleEmum.nutritionistManager
        );
      });
    });
  }
  getCategoriesForDropdown() {
    this.videosService.getAllVideoCategories().subscribe((data) => {
      this.categories = data;
    });
  }
  getAllWithFilter(e) {
    this.headFilter = e;
    this.headFilter.langId = 1;
    this.filterMemoryService.setfilter(this.headFilter, PageTypeEnum.videos);
    this.getAllVideos();
  }
  getAllVideos() {
    this.videosService.getAllVideos(this.headFilter).subscribe((data) => {
      this.videos = data.list;
      this.metaData = data.metaData;
    });
  }
}
