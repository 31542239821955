<div>
  <!--[formGroup]="taskForm" -->
  <form [formGroup]="taskForm" (ngSubmit)="saveTask()" class="example-form">
    {{ getNow() | date: "dd-MM-yyyy HH:mm" }}
    <div style="display: flex">
      <mat-form-field class="example-full-width">
        <input
          formControlName="title"
          class="edit-field"
          name="title"
          matInput
          placeholder="Headline"
          type="search"
        />
        <!--  formControlName="email"-->
        <!-- <mat-error> -->
        <!--*ngIf="leadFormReady && email.errors && email.errors.required"-->
        <!--  Please Fill Headline
        </mat-error> -->
      </mat-form-field>

      &nbsp;&nbsp;&nbsp;

      <!--   <mat-form-field class="example-full-width">
        <input
          type="text"
          matInput
          (keyup)="onAgentAutocompleteChanged($event)"
          formControlName="assignedAgentName"
          [matAutocomplete]="auto"
          class="edit-field"
          #agentAutoComplete
        />

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let agent of filterdAgentOptions"
            [value]="agent.name"
          >
            {{ agent.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->

      <div style="margin-top: 1rem">
        <app-autocomplete-agent
          (selectedValue)="saveAgentForLead($event)"
          [data]="agents"
          [initialValue]="taskForm.value.assignedAgentName"
          [widthExp]="180"
          [label]="'Agent'"
        ></app-autocomplete-agent>
      </div>

      <span
        style="
          height: 40px;
          display: flex;
          align-items: flex-end;
          margin-right: 1rem;
          margin-left: auto;
        "
        >Reminder</span
      >

      <div style="margin-left: 0; margin-right: 20px" class="datepicker">
        <mat-form-field>
          <input
            (click)="triggerToggleClick()"
            [value]="date"
            formControlName="date"
            matInput
            [matDatepicker]="picker"
            placeholder="Date"
          />
          <mat-datepicker-toggle
            id="toggle"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!-- <mat-form-field>
          <input
            formControlName="date"
            matInput
            [satDatepicker]="picker"
            [value]="date"
            (click)="triggerToggleClick()"
          />
          <sat-datepicker
            [closeAfterSelection]="false"
            #picker
            [rangeMode]="false"
          ></sat-datepicker>
          <sat-datepicker-toggle
            id="toggle"
            matSuffix
            [for]="picker"
          ></sat-datepicker-toggle>
        </mat-form-field> -->
      </div>
      <div>
        <mat-form-field class="example-full-width regular-select time">
          <input
            formControlName="hour"
            placeholder="Time"
            type="text"
            mask="00:00"
            matNativeControl
          />
          <mat-icon matSuffix>access_time</mat-icon>
          <!--
          <mat-select
            formControlName="hour"
            matNativeControl
            disableOptionCentering
            [value]="-1"
          >
           
            <mat-option *ngFor="let hour of hours" [value]="hour.hour">{{
              hour.text
            }}</mat-option>
          </mat-select>
          -->
        </mat-form-field>
      </div>
      <!--
      <mat-checkbox class="leadsTable__item__checkbox"></mat-checkbox>
      All day
      -->
    </div>
    <br />
    <angular-editor
      [placeholder]="'message'"
      name="desc"
      formControlName="desc"
      >{{ desc }}</angular-editor
    >
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      "
    >
      <button
        class="btn-submit btn-submit--lead"
        style="margin-right: 20px"
        type="submit"
      >
        Submit
      </button>
      <button class="btn-submit" (click)="onCancelNewTask()">Cancel</button>
    </div>
  </form>
</div>
