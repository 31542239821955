import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/Services/clients.service';
import { Router } from '@angular/router';
import { Client } from 'src/app/data/Client';
import { LeadSource } from 'src/app/data/LeadSource';
import { AgentsService } from 'src/app/Services/agents.service';
import { LeadStasusesService } from 'src/app/Services/lead-stasuses.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { ClientsPusherService } from 'src/app/Services/clients-pusher.service';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { LeadStatus } from 'src/app/data/LeadStatus';

@Component({
  selector: 'app-all-clients',
  templateUrl: './all-clients.component.html',
  styleUrls: ['./all-clients.component.scss'],
})
export class AllClientsComponent implements OnInit {
  leadStatuses;
  agents;
  leadSources: LeadSource[];
  clientsData: ClientForTableMin[];
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesGroup: LeadStatus[];
  isLoading: boolean;
  constructor(
    private clientsService: ClientsPusherService,
    private router: Router,
    private agentsService: AgentsService,
    private leadStasusesService: LeadStasusesService,
    private leadSourcesService: LeadSourcesService,
    private filterMemoryService: FilterMemoryService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.clients);
    /*
    this.headFilter = new HeadFilter(
      [],
      -1,
      -1,
      null,
      null,
      -1,
      '',
      false,
      100,
      1
    );
    */
  }

  ngOnInit(): void {
    this.clientsService.clients$.subscribe((data) => {
      if (data.list && data.metaData) {
        this.clientsData = data.list;
        this.metaData = data.metaData;
        this.isLoading = false;
      }
    });
    this.getAllWithFilter();

    /*
    this.clientsService.getAll().subscribe((data) => {
      this.clientsData = data;
      if (this.clientsData.length > 0) {
        var item = this.clientsData[0];
        var name = item.regularUser.firstName;
        var Id = item.regularUser.socialSecurityNum;
        var date = item.regularUser.createdDate;
        var Email = item.regularUser.email;
        var tel = item.regularUser.phone;
        var Status = item.leadStatus.name;
      }
      */
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });

    this.leadStasusesService.getAll().subscribe((data) => {
      //  this.statuses = data;
      this.leadStatuses = data;
      this.leadStatuses = this.leadStatuses.filter((x) => {
        return x.leadStatusId == 15 || x.leadStatusId == 16;
      });
    });
    this.leadSourcesService.getAll().subscribe((data) => {
      this.leadSources = data;
    });
  }
  getAllWithFilter(e = null) {
    this.isLoading = true;
    if (e) this.headFilter = e;
    this.filterMemoryService.setfilter(this.headFilter, PageTypeEnum.clients);
    this.clientsService.getAll(this.headFilter);
  }
}
