import { Agent } from './Agent';
import { BarCity } from './BarCity';
import { BarStreet } from './BarStreet';
import { Client } from './Client';
import { Language } from './Language';
import { Patient } from './Patients';
import { UserAdress } from './UserAdress';
import { UserPhone } from './UserPhone';
import { UserRole } from './UserRole';

export class RegularUser {
  regularUserId: number;
  avatarId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  phone: string;
  userPhones: UserPhone[];
  email: string;
  userName: string;
  password: string;
  salt: string;
  socialSecurityNum: string;
  birthDate: string | null;
  gender: string;
  height: number | null;
  weight: number | null;
  pALevel: number | null;
  age: number | null;
  userRoleId: number | null;
  userRole: UserRole;
  agent: Agent;
  client: Client;
  patient: Patient;
  createdDate: string;
  resetPasswordToken: string;
  token: string;
  phoneVerificationCode: string;
  isFarLogin: boolean | null;
  langId: number | null;
  language: Language;
  userAdresses: UserAdress[];
  streetNum: number;
  city: string;
  street: string;
  barCityCode: number | null;
  barCity: BarCity;
  barStreetCode: number | null;
  barStreet: BarStreet;
  country: string;
  entrance: string;
  apartment: string;
  shipNotes: string;
  passwordStrengthId: number | null;
  isActive: boolean | null;
  myClass: RegularUser;
  constructor() {}
  getFullName() {
    var firstName = this.firstName ?? '';
    var lastName = this.lastName ?? '';
    return firstName + ' ' + lastName;
  }
  etPermissionsArr() {}
  getAdressObj(adressTypeId: number): UserAdress {
    if (this.userAdresses?.length == 0) return new UserAdress();
    return this.userAdresses?.find((x) => x.adressTypeId == adressTypeId);
  }

  getUserPhone() {
    return this.userPhones?.find((x) => x.isActive)?.phoneNumber;
  }
  getUserPhoneObj() {
    return this.userPhones?.find((x) => x.isActive);
  }
  getUserCounrtyId() {
    return this.userPhones?.find((x) => x.isActive)?.countryId
      ? this.userPhones?.find((x) => x.isActive).countryId
      : 1;
  }
}

export function getPermissionsArr(user: RegularUser) {
  if (!user.userRole) return [];
  var permissions = user.userRole.userPermissions.map((x) => {
    return x.appModule.appModuleName + '_' + x.moduleAction.moduleActionName;
  });
  return permissions;
}
