import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArticleBullet } from '../data/ArticleBullet';

@Injectable({
  providedIn: 'root',
})
export class ArticleBulletsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ArticleBullet[]>(
      `${environment.baseUrl}ArticleBullets/`
    );
  }

  getById(clientId) {
    return this.http.get<ArticleBullet>(
      `${environment.baseUrl}ArticleBullets/${clientId}`
    );
  }
  put(articleBullet: ArticleBullet) {
    return this.http.put<any>(
      `${environment.baseUrl}ArticleBullets/${articleBullet.articleBulletId}`,
      articleBullet
    );
  }
  post(articleBullet: ArticleBullet) {
    return this.http.post<any>(
      `${environment.baseUrl}ArticleBullets/`,
      articleBullet
    );
  }
  delete(articleBulletId: number) {
    return this.http.delete(
      `${environment.baseUrl}ArticleBullets/${articleBulletId}`
    );
  }
}
