import { Component, OnInit } from '@angular/core';
import { Logging } from 'src/app/data/Logging';

import { LoggingsService } from 'src/app/Services/loggings.service';
import { Router, ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-client-logs',
  templateUrl: './client-logs.component.html',
  styleUrls: ['./client-logs.component.css']
})
export class ClientLogsComponent implements OnInit {
  loggings: Logging[]
  clientName:string;
  queryClientId: any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private loggingsService: LoggingsService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryClientId = params['clientId'];
      this.loggingsService
        .getByClientId(this.queryClientId)
        .subscribe((data) => {
          this.loggings = data.list;
          this.clientName=data.clientName
        });
    });
  }
  goBackToClient() {
    this.router.navigate(['/clients/edit/'], {
      queryParams: { clientId: this.queryClientId },
    });
  }
}
