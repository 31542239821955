import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { Food } from '../data/Food';

@Injectable({
  providedIn: 'root',
})
export class FoodsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<Food[]>(`${environment.baseUrl}Foods/`);
  }
  ForCategoryLinkage(langId, sourceId) {
    return this.http.get<Food>(
      `${environment.baseUrl}Foods/ForCategoryLinkage/${langId}/${sourceId}`
    );
  }

  getById(foodId: number) {
    return this.http.get<Food>(`${environment.baseUrl}Foods/${foodId}`);
  }

  put(food: Food) {
    return this.http.put<Food>(
      `${environment.baseUrl}Foods/${food.foodId}`,
      food
    );
  }

  post(food: Food) {
    return this.http.put<Food>(`${environment.baseUrl}Foods/`, food);
  }

  delete(foodLangId: number) {
    return this.http.delete<Food | ErrorMessage>(
      `${environment.baseUrl}Foods/${foodLangId}`
    );
  }
}
