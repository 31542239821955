import { CdkAccordionItem } from '@angular/cdk/accordion/accordion-item';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { FoodCategoryPerms } from '../food-categories/food-categories.component';

@Component({
  selector: 'app-edit-food-category-item',
  templateUrl: './edit-food-category-item.component.html',
  styleUrls: ['./edit-food-category-item.component.scss'],
})
export class EditFoodCategoryItemComponent implements OnInit, OnChanges {
  editMode: boolean;
  expandedIndex = 0;
  AlgoImageType = AlgoImageType;
  @Input() foodCategoryPerms: FoodCategoryPerms;
  @Input() category: FoodCat;
  @Input() index: number;
  @Input() langId: number;
  @Output('editCanceled') editCanceled: EventEmitter<FoodCat> =
    new EventEmitter();
  @Output('saveCategory') saveCategory: EventEmitter<FoodCat> =
    new EventEmitter();
  @Output('deleteCategory') deleteCategory: EventEmitter<any> =
    new EventEmitter();
  @Output('editModeChange') editModeChange: EventEmitter<boolean> =
    new EventEmitter();

  constructor(
    private popupHandlerService: PopupHandlerService,
    private foodGenesService: FoodGenesService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.category &&
      changes.category.currentValue &&
      changes.category.currentValue.categoryId == 0
    ) {
      this.editMode = true;
      console.log('ngOnChanges edit mode', this.editMode);
    }
  }
  ngOnInit(): void {
    this.foodGenesService.renderCat$.subscribe((catId) => {
      if (this.category.categoryId == catId || this.category.categoryId == 0) {
        this.getCatById(catId);
      }
    });
  }
  moreButtonVisible() {
    return (
      this.foodCategoryPerms.canEdit ||
      this.foodCategoryPerms.canDelete ||
      this.foodCategoryPerms.canAdd
    );
  }
  getCatById(catId: number) {
    this.foodGenesService
      .getFoodCatsById(catId, this.langId)
      .subscribe((data) => {
        let subCat: FoodCat[];
        if (this.category?.subCategories?.length > 0) {
          subCat = [...this.category.subCategories];
        }
        this.category = data;
        if (subCat) this.category.subCategories = subCat;

        this.editMode = false;
      });
  }
  onEditClicked() {
    //this.setEditInx.emit(category.categoryId);
    this.editMode = true;
    this.editModeChange.emit(this.editMode);
  }
  notReachedMaxLevel() {
    return this.category.catLevel < this.category.maxCatLevel;
  }
  onAddSubCategory(accordionItem: any) {
    const category = new FoodCat();
    category.categoryId = 0;
    category.catLevel = 0;
    category.maxCatLevel = 0;
    category.parentId = this.category.categoryId;
    category.langId = this.langId;
    category.categoryTypeId = this.category.categoryTypeId;
    if (!this.category.subCategories) {
      this.category.subCategories = [];
    }
    this.category.subCategories.push(category);
    accordionItem.open();
    this.editModeChange.emit(true);
  }
  onCancelEditClicked(category: FoodCat) {
    this.editMode = false;
    this.editCanceled.emit(category);
    this.editModeChange.emit(this.editMode);
  }
  onSaveClicked(category: FoodCat, nameInput?: any) {
    if (this.category.name == '' || !this.category.name) {
      console.log('nameInput', nameInput);
    } else {
      this.saveCategory.emit(category);
    }
  }
  onDelete(category: FoodCat) {
    this.popupHandlerService.openConfirmationDialog('', 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          this.deleteCategory.emit(category);
        }
      });
  }
  onUploadFinished(event: AppImage, category: FoodCat) {
    category.icon = event;
    this.saveCategory.emit(category);
  }
  getImage() {
    const image = this.category.icon;
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  trackChanges = (index: number) => {
    return index;
  };
}
