import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-failed-patient-deliveries',
  templateUrl: './failed-patient-deliveries.component.html',
  styleUrls: ['./failed-patient-deliveries.component.css'],
})
export class FailedPatientDeliveriesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
