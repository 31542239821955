<mat-list class="clientsTable" role="list">
  <mat-list-item class="clientsTable__header" role="listitem">
    <ng-container *ngFor="let item of headers">
      <span
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
        [class.action]="item.class"
      >
        <span (click)="changeSortBy(item.val)">{{ item.name }} </span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
  </mat-list-item>
  <mat-card class="clientsTable__item" *ngFor="let video of videos">
    <mat-list-item role="listitem">
      <span><img [src]="getPoster(video)" alt="" /></span>
      <span>{{ video.title }}</span>
      <span>{{ video.uploadDate | date : "dd-MM-yyyy" }}</span>
      <span>{{ video.createDate | date : "dd-MM-yyyy" }}</span>
      <span>{{ video.duration }}</span>
      <span>{{ video.agentName }}</span>
      <span>{{ video.videoCategory.categoryName }}</span>
      <span>
        <mat-slide-toggle
          class="video-form__item__field"
          (change)="onToggleStatus($event, video.videoId)"
          [(ngModel)]="video.isActive"
        ></mat-slide-toggle>
      </span>
      <span class="action">
        <button
          (click)="openVideoDialog(video.videoId)"
          mat-icon-button
          class="edit"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          (click)="deleteVideoFromCloudflare(video)"
          mat-icon-button
          class="delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </span>
    </mat-list-item>
  </mat-card>
</mat-list>
