import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { CloudflareErrorMessage } from 'src/app/data/CloudflareErrorMessage';
import { Video } from 'src/app/data/Video';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { WebImage } from 'src/app/data/WebImages';
import { AlgoImageType, FileUploadType } from 'src/app/Enums/StatusesEnum';
import { VideosService } from 'src/app/Services/videos.service';
import { convertToTimespan } from 'src/app/Utils/dateUtils';
import { extrectVideoIdFromUrl } from 'src/app/Utils/general-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss'],
})
export class EditVideoComponent implements OnInit {
  @ViewChild('preVideoElement') preVideoElement: ElementRef;
  @ViewChild('preImageElement') preImageElement: ElementRef;
  video: Video;
  imgError: string;
  categories: VideoCategory[];
  agents: AgentMin[];
  isVideoChanged: boolean;
  oneTimeUrl: any;
  videoError: string;
  showVideoPreview: boolean;
  showStreamVideo: boolean;
  videoLength: any;
  //showImagePreview: boolean;
  videoFormData: FormData;
  videoFile: any;
  uploadProgress: Observable<any>;
  imageFile: File;
  uploadError: string;
  agentIdError: boolean;
  videoId: number;
  loadedStream: boolean;
  AlgoImageType = AlgoImageType;
  FileUploadType = FileUploadType;
  langId = 1;
  streamVideoId: string;
  test = false;
  constructor(
    public dialogRef: MatDialogRef<EditVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private videosService: VideosService,
    private domSanitizer: DomSanitizer
  ) {
    this.videoId = this.data.videoId;
    this.categories = this.data.categories;
    this.agents = this.data.agents;
    if (this.videoId == -1) {
      this.video = new Video();
      this.video.videoCategory = new VideoCategory();
      this.video.langId = this.langId;
      this.video.files = [];
    } else {
      this.getVideo(this.videoId);
    }
  }

  ngOnInit(): void {
    this.subscribeToUploadProgress();
  }
  subscribeToUploadProgress() {
    this.videosService.uploadProgress.subscribe((data) => {
      if (data) {
        this.uploadProgress = data;
      }
    });
  }
  getPoster() {
    const poster = this.video.files.find(
      (x) => x.fileType == FileUploadType.VideoPoster
    );
    if (poster) return poster;
  }
  onUploadFinished(event: AppImage) {
    if (event) {
      const finded = this.video.files.find((x) => x.fileType == event.fileType);
      if (finded) {
        this.video.files = this.video.files.filter(
          (x) => x.fileType != event.fileType
        );
      }
      this.video.files.push(event);
    }
  }
  getImage(imageType: number) {
    const image = this.video.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.video.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  getPosterPath() {
    const webPath = this.getPoster().webPath;
    const fileName = this.getPoster().fileName;
    const imagePath = `${webPath}/${fileName}`;
    return this.replaceWebPath(imagePath);
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }

  hasPosterImage() {
    const poster = this.video.files.find(
      (x) => x.fileType == FileUploadType.VideoPoster
    );
    if (poster) return poster;
  }
  getVideoFile() {
    const video = this.video.files?.find(
      (x) => x.fileType == FileUploadType.VodVideo
    );
    if (video) return video.fileName;
  }
  getVideo(videoId: number) {
    this.videosService.getById(videoId).subscribe((data) => {
      this.video = data;
      if (this.getVideoFile()) {
        this.showStreamVideo = true;
      }
      if (this.video?.uploadDate) {
        this.video.uploadDate = new Date(Date.parse(this.video.uploadDate));
      }
    });
  }
  uploadVideo(files: File[]) {
    if (files.length == 0) return;
    this.videoError = '';
    var file = files[0] as File;
    const allowTypes = ['video/mp4', 'video/webm', 'video/ogg'];
    var find = 'video/';
    var re = new RegExp(find, 'g');

    if (!allowTypes.includes(file.type)) {
      this.videoError = `Please upload valid video file (${allowTypes
        .join(', ')
        .replace(re, '.')})`;
      if (this.videoFile) {
        this.loadVideo();
      } else {
        this.showVideoPreview = false;
        this.showStreamVideo = false;
      }
      return;
    }
    this.videoFile = file;
    this.videoError = '';
    this.isVideoChanged = true;
    this.showVideoPreview = true;
    this.showStreamVideo = false;
    setTimeout(() => {
      this.loadVideo();
    }, 500);

    //this.videosService.uploadFile(this.videoFile);
    /* console.log(
      `Uploading ${file.name} with size ${file.size} and type ${file.type}`
    ); */
  }
  loadVideo() {
    const video = this.preVideoElement.nativeElement;
    video.pause();
    video.src = URL.createObjectURL(this.videoFile);
    video.load();
    video.addEventListener(
      'loadedmetadata',
      () => {
        var duration = Math.round(video.duration);

        this.video.duration = this.convertToTimespan(duration);
      },
      false
    );
  }
  convertToTimespan(duration: number) {
    return convertToTimespan(duration);
  }
  loadImage(onInit = false) {
    const image = this.preImageElement.nativeElement;
    if (onInit) {
      image.src = this.getPoster().webPath;
    } else {
      image.src = URL.createObjectURL(this.imageFile);
    }
  }
  onBoxImageClick(image: HTMLElement) {
    if (this.uploadProgress) return;
    image.click();
  }
  sanitizePreviewSrc(src: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(src);
  }
  /* uploadImg(img: File[]) {
    if (img.length == 0) {
      return;
    }
    const fileToUpload = img[0] as File;

    const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/tiff'];
    this.imgError = '';
    if (!allowTypes.includes(fileToUpload.type)) {
      this.imgError = 'Please upload valid Image';
      if (this.imageFile) {
        this.loadImage();
      } else {
        this.showImagePreview = null;
      }
      return;
    } else if (fileToUpload.size > 1000 * 1000) {
      //1MB
      this.imgError = 'Image to large, please upload file up to 1MB';
      return;
    }
    this.showImagePreview = true;
    this.imageFile = fileToUpload;
    setTimeout(() => {
      this.loadImage();
    }, 500);

    setTimeout(() => {
      var c = document.createElement('canvas');
      var img = this.preImageElement.nativeElement;
      c.height = img.naturalHeight;
      c.width = img.naturalWidth;
      var ctx = c.getContext('2d');

      ctx.drawImage(img, 0, 0, c.width, c.height);
      var base64String = c.toDataURL();

      //this.video.posterBase64 = base64String;
      //this.video.posterName = fileToUpload.name;
    }, 1000);
  } */

  onAgentFilterChange(event: number) {
    this.video.agentId = event;
    this.agentIdError = false;
  }
  getAgentNameById() {
    return this.agents?.find((x) => x.agentId == this.video.agentId)?.name;
  }
  postVideoMetadata() {
    this.videosService.post(this.video).subscribe(
      (data) => {
        this.dialogRef.close({ event: 'close' });
      },
      (error) => {
        this.videoError = 'Error uploading video';
        this.uploadProgress = null;
      }
    );
  }
  putVideoMetadata() {
    this.videosService.put(this.video).subscribe(
      (data) => {
        this.dialogRef.close({ event: 'close' });
      },
      (error) => {
        this.videoError = 'Error uploading video';
        this.uploadProgress = null;
      }
    );
  }
  uploadVideoMetadata() {
    if (this.video.videoId) {
      this.putVideoMetadata();
    } else {
      this.postVideoMetadata();
    }
  }
  subscribeToUploadComplete() {
    this.videosService.uploadComplete.pipe(take(1)).subscribe((data) => {
      if (data) {
        if (!this.getVideoFile()) {
          const streamId = extrectVideoIdFromUrl(data);
          this.uploadVodFile(streamId);
        } else {
          this.uploadVideoMetadata();
        }
      }
    });
  }
  subscribeToUploadError() {
    this.videosService.uploadError.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.uploadError =
          'There is something wrong with uploading this video please try again';
      }
    });
  }
  deleteAndPutVideo() {
    this.videosService.deleteVideo(this.getVideoFile()).subscribe((data) => {
      if ((data as CloudflareErrorMessage)?.message) {
        const error = data as CloudflareErrorMessage;
        this.videoError = error.message;
        if (error.status == 404) {
          this.videosService.uploadFile(this.videoFile);
          this.subscribeToUploadComplete();
          this.subscribeToUploadError();
        }
      } else {
        this.videosService.uploadFile(this.videoFile);
        this.subscribeToUploadComplete();
        this.subscribeToUploadError();
      }
    });
  }
  uploadVodFile(streamId: string) {
    this.videosService
      .uploadVodFile({}, FileUploadType.VodVideo, streamId, this.langId)
      .subscribe((data: AppImage) => {
        this.video.files.push(data);
        this.uploadVideoMetadata();
      });
  }
  save(videoForm: NgForm) {
    if (!this.video.agentId) {
      this.agentIdError = true;
    }
    /*  if (this.video.posterBase64) {
      this.video.posterBase64 = this.video.posterBase64.split(',')[1];
    } */
    if (!this.videoFile && !this.video.videoId) {
      this.videoError = 'Please upload video';
      return;
    }
    if (videoForm.form.valid) {
      if (this.isVideoChanged) {
        this.uploadError = '';
        if (this.getVideoFile()) {
          // delete the video before uploading new one
          this.deleteAndPutVideo();
        } else {
          this.videosService.uploadFile(this.videoFile);
          this.subscribeToUploadComplete();
          this.subscribeToUploadError();
        }
      } else {
        this.uploadVideoMetadata();
      }
    }
  }
}
