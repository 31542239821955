<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <div class="example-accordion-item-header">
      <button
        *ngIf="fromEditFoodComponent"
        (click)="onClickCategory(accordionItem, category, $event, 'editFood')"
        class="example-accordion-item-description"
        type="button"
        [class.parent]="category.catLevel === 1"
        mat-button
      >
        <mat-icon *ngIf="selectedCat?.categoryId == category.categoryId"
          >done</mat-icon
        >
        <mat-icon
          *ngIf="category.subCategories && category.subCategories.length > 0"
        >
          {{ accordionItem.expanded ? "remove" : "add" }}</mat-icon
        >
        {{ category.name }}
      </button>
      <ng-container *ngIf="fromAllFoodComponent">
        <button
          *ngIf="category.subCategories && category.subCategories.length > 0"
          mat-icon-button
          (click)="toggleAccordion(accordionItem, $event)"
          class="toggle-accordion-btn"
        >
          <mat-icon> {{ accordionItem.expanded ? "remove" : "add" }}</mat-icon>
        </button>
        <button
          (click)="onClickCategory(accordionItem, category, $event, 'allFood')"
          class="example-accordion-item-description"
          type="button"
          [class.parent]="category.catLevel === 1"
          mat-button
        >
          <mat-icon *ngIf="selectedCat?.categoryId == category.categoryId"
            >done</mat-icon
          >
          {{ category.name }}
          <mat-icon
            class="having-food-icon"
            *ngIf="
              category.havingFood &&
              category.subCategories &&
              category.subCategories.length
            "
            matTooltip="This category has food related"
            >warning</mat-icon
          >
        </button>
      </ng-container>
    </div>

    <ng-container
      *ngIf="category.subCategories && category.subCategories.length > 0"
    >
      <div
        class="example-accordion-item-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <ul style="list-style: none">
          <li *ngFor="let item of category.subCategories; let i = index">
            <app-food-categories-selection-item
              [fromEditFoodComponent]="fromEditFoodComponent"
              [fromAllFoodComponent]="fromAllFoodComponent"
              [fromPaginatorComponent]="fromPaginatorComponent"
              [selectedCat]="selectedCat"
              [category]="item"
              [index]="i"
              (selectCat)="selectCat.emit($event)"
            ></app-food-categories-selection-item>
          </li>
        </ul>
      </div>
    </ng-container>
  </cdk-accordion-item>
</cdk-accordion>
