import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalParam } from '../data/GlobalParam';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalParamsService {
  globalParms: GlobalParam[];
  constructor(private http: HttpClient) {}
  getAll() {
    if (this.globalParms) {
      return of(this.globalParms); // return from cache
    } else {
      return this.http
        .get<GlobalParam[]>(`${environment.baseUrl}GlobalParams/`)
        .pipe(map((res) => (this.globalParms = res)));
    }
    // return this.http.get<GlobalParam[]>(`${environment.baseUrl}GlobalParams/`);
  }
  getById(id) {
    return this.http.get<GlobalParam>(
      `${environment.baseUrl}GlobalParams/${id}`
    );
  }
  getByName(name: string) {
    return this.http.get<GlobalParam>(
      `${environment.baseUrl}GlobalParams/ByName/${name}`
    );
  }
  putById(val: string) {
    return this.http.put<GlobalParam>(
      `${environment.baseUrl}GlobalParams/`,
      val
    );
  }
  putByName(name: string, val: GlobalParam) {
    return this.http.put<GlobalParam[]>(
      `${environment.baseUrl}GlobalParams/ByName/${name}`,
      val
    );
  }
}
