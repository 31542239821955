<ngx-skeleton-loader
  *ngIf="!results"
  [theme]="{ height: '30px' }"
  count="15"
></ngx-skeleton-loader>
<ng-container *ngIf="results">
  <div class="headers">
    <div *ngFor="let item of headers" [style.width.%]="item.width">
      {{ item.name }}
    </div>
  </div>
  <mat-card
    class="item"
    *ngFor="let result of results"
    (click)="onSelectResult(result)"
    matRipple
  >
    <div class="column indicator">
      <span [style.background-color]="'#' + result.color"></span>
    </div>
    <div class="column">{{ result.categoryName }} <span>&#10229;</span></div>
    <div class="column">{{ result.subCategoryName }}<span>&#10229;</span></div>
    <div class="column">{{ result.snpNumber }}</div>
  </mat-card>
</ng-container>
