import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { retry } from 'rxjs/operators';
import { BarcodeHistoryMin } from 'src/app/data/BarcodeHistoryMin';
import { BarcodeHistoryResponse } from 'src/app/data/BarcodeHistoryResponse';
import { Status } from 'src/app/data/Status';
import { ProductsService } from 'src/app/Services/products.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { ChooseCouponDialogComponent } from '../choose-coupon-dialog/choose-coupon-dialog.component';

@Component({
  selector: 'app-barcode-history-dialog',
  templateUrl: './barcode-history-dialog.component.html',
  styleUrls: ['./barcode-history-dialog.component.scss'],
})
export class BarcodeHistoryDialogComponent implements OnInit {
  barcodeHistory: BarcodeHistoryResponse;
  tableData: BarcodeHistoryMin[];
  statuses: Status[];
  constructor(
    public dialogRef: MatDialogRef<BarcodeHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productsService: ProductsService,
    private statusesService: StatusesService
  ) {}

  ngOnInit(): void {
    var { productId } = this.data;
    this.productsService.getBarcodeHistory(productId).subscribe((data) => {
      if (data) {
        //console.log(data);
        this.barcodeHistory = data;
        this.tableData = this.barcodeHistory.barcodeHistoryMins;

        this.statusesService.getAll().subscribe((data) => {
          this.statuses = data;
        });
      }
    });
  }

  getbarcodeStatus(statusId: number) {
    if (statusId !== 0) {
      var status = this.statuses.find((x) => x.statusId == statusId);
      if (status) {
        return status.name;
      }
    } else {
      var productStatus: Status = this.data.productStatus;
      return productStatus.name;
    }
  }
}
