import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsService } from 'src/app/Services/products.service';
import { BarcodeHistoryDialogComponent } from '../barcode-history-dialog/barcode-history-dialog.component';

@Component({
  selector: 'app-change-barcode-dialog',
  templateUrl: './change-barcode-dialog.component.html',
  styleUrls: ['./change-barcode-dialog.component.scss'],
})
export class ChangeBarcodeDialogComponent implements OnInit {
  barcode: string;
  hasChanged: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ChangeBarcodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.barcode = this.data.barcode;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close({ event: 'close' });
  }
  save() {
    if (this.barcode && this.barcode !== '' && this.hasChanged) {
      this.dialogRef.close({ event: 'save', barcode: this.barcode });
    }
  }
}
