import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberUtilsService {

  constructor() { }

  roundToNumDigits(num, digits) {
    var multiple = Math.pow(10, digits);
    return Math.round((num + Number.EPSILON) * multiple) / multiple
  }

}
