<div style="font-size: 14px; display: flex">
  <div style="margin-right: 20px" (click)="onEditNote()">
    <img
      *ngIf="note.statusId != 12 && isDatePassed(note.dueDate)"
      src="../../../assets/noteRed.svg"
    />
    <img
      *ngIf="note.statusId != 12 && !isDatePassed(note.dueDate)"
      src="../../../assets/noteBlue.svg"
    />
    <img *ngIf="note.statusId == 12" src="../../../assets/activitiesVi.svg" />
  </div>
  <div [ngStyle]="isDatePassed(note.dueDate) && { color: 'red' }">
    <span style="font-weight: bold">{{ note.title }}</span>
    <span
      [class.text--client]="classAttr == 'clientAttr'"
      [class.text--lead]="classAttr == 'leadAttr'"
    >
      &nbsp;&nbsp; From:
      {{ note.assigningAgent && note.assigningAgent.name }}
    </span>
    <!--  -->
    &nbsp;&nbsp;
    <span
      [class.text--client]="classAttr == 'clientAttr'"
      [class.text--lead]="classAttr == 'leadAttr'"
    >
      &nbsp;&nbsp; To:
      {{ note.assignedAgent && note.assignedAgent.name }}
    </span>
    <!-- -->
    &nbsp;&nbsp;
    <img
      *ngIf="!isDatePassed(note.dueDate)"
      src="../../../assets/calendar-60.svg"
    />
    <img
      *ngIf="isDatePassed(note.dueDate)"
      src="../../../assets/calendar-60_red.svg"
    />
    {{ note.dueDate | date: "fullDate" }}
    &nbsp;
    <img *ngIf="!isDatePassed(note.dueDate)" src="../../../assets/clock.svg" />
    <img
      *ngIf="isDatePassed(note.dueDate)"
      src="../../../assets/clock_red.svg"
    />
    <span> {{ note.dueDate | date: "HH:mm" }} </span>
    <br />
    <span [innerHtml]="note.desc">{{ note.desc }}</span>
    <span></span>
  </div>
  <div style="margin-left: auto; display: flex; align-items: center">
    <button
      style="height: 30px"
      class="activities-done-btn"
      (click)="changeToDone()"
      *ngIf="note.status && note.status.statusId == 10"
    >
      <!-- status is open-->
      done
    </button>
    <div
      class="activities-complete-btn"
      *ngIf="note.status && note.status.statusId == 12"
    >
      completed
    </div>
  </div>
</div>
<mat-divider></mat-divider>
