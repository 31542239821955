import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AgentInteraction } from 'src/app/data/AgentInteraction';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { GoogleCaptcha } from 'src/app/data/GoogleRecaptcha';
import { RegularUser } from 'src/app/data/RegularUser';
import { AgentStatusEnum } from 'src/app/Enums/StatusesEnum';
import { AgentInteractionService } from 'src/app/Services/agent-interaction.service';
import { GoogleCaptchaService } from 'src/app/Services/google-captcha.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  authError: any = '';
  token: string | undefined;
  constructor(
    private regularUsersService: RegularUsersService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private googleCaptchaService: GoogleCaptchaService,
    private agentInteractionsService: AgentInteractionService
  ) {
    this.token = undefined;
    this.regularUsersService.currentUser.subscribe((user) => {
      if (user) this.router.navigate(['loading']);
    });
  }

  ngOnInit(): void {
    this.createFormGroup();
  }
  createFormGroup() {
    this.loginForm = new FormGroup({
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  }
  getFalse() {}
  submitLoginForm() {
    if (environment.production) {
      this.sendCaptchaToVerify();
    } else {
      this.doLogin();
    }
  }
  sendCaptchaToVerify() {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        const captcha = new GoogleCaptcha();
        captcha.response = token;
        this.googleCaptchaService
          .VerifyingGoogleCaptchaResponse(captcha)
          .subscribe((data) => {
            if ((data as ErrorMessage)?.message) {
              alert((data as ErrorMessage).message);
              return;
            } else {
              this.doLogin();
            }
          });
        //console.debug(`Token [${token}] generated`);
      });
  }
  changeAgentInteraction(agentInteraction: AgentInteraction) {
    this.agentInteractionsService.post(agentInteraction).subscribe(
      (data) => {
        console.log('changeAgentInteraction success');
      },
      (error) => {}
    );
  }
  doLogin() {
    if (!this.loginForm.valid) return;
    this.authError = '';
    var user = new RegularUser();
    user.userName = this.loginForm.value.userName;
    user.password = this.loginForm.value.password;

    this.regularUsersService.validateFromLogin(user).subscribe(
      (data) => {
        if ((data as RegularUser)?.firstName) {
          //  alert('reload!!');
          if (
            userHasPermisions(['AgentInteraction_GetList'], data as RegularUser)
          ) {
            var regularUser = data as RegularUser;
            const agentInteraction = new AgentInteraction();
            agentInteraction.agentId = regularUser.agent.agentId;
            agentInteraction.statusId = AgentStatusEnum.Online;
            agentInteraction.agentInteractionId = 0;
            this.changeAgentInteraction(agentInteraction);
          }
          this.router.navigate(['/loading']);
          setTimeout(() => {
            console.log('validateFromLogin reload');
            location.reload();
          }, 100);
        } else {
          var err = data as ErrorMessage;
          if (!err) return;
          if (err.message == 'Reset Password') {
            var obj = JSON.parse(err.innerExeption);
            setTimeout(() => {
              this.router.navigate(['resetPassword'], {
                queryParams: { email: obj.email, token: obj.token },
              });
            }, 100);

            //   this.router.navigateByUrl(err.innerExeption);
          } else if (err.message == 'Redirect To Enter Token Screen') {
            this.router.navigate(['enterToken'], {
              queryParams: { accessToken: err.innerExeption },
            });
          } else if (err.message == 'Verification Token Required') {
            this.router.navigate(['verificationToken'], {
              queryParams: { accessToken: err.innerExeption },
            });
          } else {
            alert(err.message);
          }
        }

        /*
        if (userHasPermisions(['All Leads', 'My Leads'], regularUser))
          this.router.navigate(['leads']);
        else {
          if (userHasPermisions(['All Patients', 'My Patients'], regularUser))
            this.router.navigate(['tests']);
        }*/
      },
      (error) => {
        this.authError = 'User Name or Password are not matched';
      }
    );
  }
  loginWithGoogle() {}
  get environment() {
    return environment;
  }
}
