import { Injectable } from '@angular/core';
import { ServiceRequestsService } from './service-requests.service';
import { BehaviorSubject } from 'rxjs';
import { ServiceRequest } from '../data/ServiceRequest';
import { HeadFilter } from '../data/HeadFilter';
import { RequestForTableMin } from '../data/RequestForTableMin';
import { PaginationMetadata } from '../data/PaginationMetadata';
import { FoodClientRequestService } from './food-client-request.service';
import { FoodReportRequest } from '../data/food-genes/FoodReportRequest';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestsPusherService {
  private readonly requests = new BehaviorSubject<RequestForTableMin[]>(null);
  private readonly appReportRequests = new BehaviorSubject<FoodReportRequest[]>(
    null
  );
  private readonly metaData = new BehaviorSubject<PaginationMetadata>(null);
  readonly requests$ = this.requests.asObservable();
  readonly appReportRequests$ = this.appReportRequests.asObservable();
  readonly metaData$ = this.metaData.asObservable();
  mode: string;
  clientId: number;
  productId: number;
  headFilter: any;
  constructor(
    private serviceRequestsService: ServiceRequestsService,
    private foodClientRequestsService: FoodClientRequestService
  ) {}
  setMode(mode) {
    this.mode = mode;
  }
  post(request: ServiceRequest) {
    return this.serviceRequestsService
      .post(request)
      .subscribe((response) => this.getAll());
  }
  put(request: ServiceRequest) {
    return this.serviceRequestsService
      .put(request)
      .subscribe((response) => this.getAll());
  }
  putMin(request: RequestForTableMin) {
    return this.serviceRequestsService
      .putMin(request)
      .subscribe((response) => this.getAll());
  }
  getAll(headFilter: HeadFilter = null, id = null, mode = null) {
    //this.requests.next([]);
    //debugger;
    var req = null;
    if (headFilter) this.headFilter = headFilter;
    if (mode) this.mode = mode;

    switch (this.mode) {
      case 'all':
        req = this.serviceRequestsService.getAll(this.headFilter);
        break;
      case 'appReports':
        req = this.foodClientRequestsService.getFoodRequests(this.headFilter);
        break;
      case 'client':
        if (id) this.clientId = id;
        req = this.serviceRequestsService.getByClientId(this.clientId);
        break;
      case 'product':
        if (id) this.productId = id;
        req = this.serviceRequestsService.getByProductId(this.productId);
        break;
    }
    req.subscribe((data) => {
      if (this.mode === 'all') {
        if (data.list && data.metaData) {
          this.requests.next(data.list);
          this.metaData.next(data.metaData);
        }
      } else if (this.mode === 'appReports') {
        if (data) {
          this.appReportRequests.next(data.request);
          this.metaData.next(data.paginationMetadata);
        }
      } else {
        this.requests.next(data);
      }
    });
  }
}
