<div class="flex-container" *ngIf="regularUser">
  <form
    [formGroup]="resetForm"
    (ngSubmit)="doResetPassword()"
    class="example-form"
  >
    <mat-card class="flex-item">
      <mat-card-title
        style="
          background-color: #153341;
          color: white;
          line-height: 60px;
          margin: 0;
          margin-top: -250px;
        "
      >
        Choose New Password
      </mat-card-title>
      <div>
        <mat-list style="margin-top: 40px">
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 250px"
            >
              <input
                type="text"
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="User Name"
                class="edit-field login-field"
                matInput
                formControlName="userName"
              />
              <mat-error *ngIf="getFalse()"> Password is not valid </mat-error>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item
            style="display: flex; justify-content: center; position: relative"
          >
            <mat-form-field
              class="example-full-width login-field"
              style="width: 250px"
            >
              <input
                autocomplete="new-password"
                #passwordInput="matInput"
                type="password"
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="Password"
                class="edit-field login-field"
                matInput
                formControlName="password"
              />

              <!--
              <mat-error *ngIf="password.errors && password.errors.required">
                Please enter Height
              </mat-error>
              -->
            </mat-form-field>
            <!--
            <div class="question-icon">?</div>
            -->
          </mat-list-item>
          <div
            style="
              color: #707070;
              text-align: left;
              margin-left: 100px;
              font-size: 12px;
              font-weight: lighter;
              margin-bottom: 10px;
            "
            *ngIf="passwordInput.focused"
          >
            <!--  *ngIf="passwordInput.focused"-->
            <span style="color: #1b3f4e; font-weight: bold">
              Your password must contain:</span
            >
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="is8Length()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!is8Length()"
            />
            <span [ngClass]="{ validGreen: is8Length() }">
              at least 8 characters</span
            >
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="hasLetters()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!hasLetters()"
            />
            <span [ngClass]="{ validGreen: hasLetters() }">
              Uppercase and lowercase letters
            </span>
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="hasNumbers()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!hasNumbers()"
            />
            <span [ngClass]="{ validGreen: hasNumbers() }">
              At least one number
            </span>
            <br />
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 250px"
            >
              <input
                type="password"
                matInput
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="Retype Password"
                class="edit-field login-field"
                matInput
                formControlName="retypePassword"
              />
              <!--
              <mat-error
                *ngIf="retypePassword.errors && retypePassword.errors.required"
              >
                Please enter Height
              </mat-error>
              -->
            </mat-form-field>
          </mat-list-item>

          <div
            class="errorTxt"
            *ngIf="isSubmitted && password.errors && password.errors.required"
          >
            password is not valid.
            <br />
            see help for details
          </div>
          <div
            class="errorTxt"
            *ngIf="
              isSubmitted &&
              !password.errors &&
              retypePassword.errors &&
              retypePassword.errors.required
            "
          >
            passwords doesn't match
          </div>
          <button class="login-btn">Submit</button>
        </mat-list>
      </div>
    </mat-card>
  </form>
</div>
