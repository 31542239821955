<div class="lab">
  <button (click)="location.back()" class="lab__back" mat-button>< Back</button>

  <mat-card class="lab__card">
    <mat-card-header class="lab__card__header">
      <mat-card-title class="lab__card__header__title">
        <mat-icon>science</mat-icon>
        Laboratories list
      </mat-card-title>
      <app-change-lang
        [selectedLangId]="langId"
        (changeLang)="onChangeLang($event)"
      ></app-change-lang>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="lab__table">
        <ng-container matColumnDef="lab">
          <th mat-header-cell *matHeaderCellDef style="width: 45%">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <button
                *ngIf="userHasPermission()"
                mat-icon-button
                class="lab__table__add"
                (click)="addLab()"
              >
                <mat-icon>add</mat-icon>
              </button>
              <div>Lab</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="lab__table__item">
              {{ element.name | uppercase }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="userHasPermission()"
              mat-icon-button
              class="lab__table__edit"
              (click)="addLab(element)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
</div>
