import { Component, Input, OnInit } from '@angular/core';
import { DealForTableMin } from 'src/app/data/DealForTableMin';
import { Status } from 'src/app/data/Status';

@Component({
  selector: 'app-deal-thumb',
  templateUrl: './deal-thumb.component.html',
  styleUrls: ['./deal-thumb.component.scss'],
})
export class DealThumbComponent implements OnInit {
  constructor() {}
  @Input() deal: DealForTableMin;
  @Input() statuses: Status[];
  @Input() products;
  ngOnInit(): void {}
  getProductsStr(products: string[]) {
    var prods = '';
    if (!products) return prods;
    var prodsDict = {};
    products.forEach((product) => {
      if (prodsDict[product] == undefined) {
        prodsDict[product] = 0;
      }
      prodsDict[product]++;
    });
    for (const property in prodsDict) {
      prods += `${property} (${prodsDict[property]})<br>`;
    }
    return prods;
  }
  getDealStatusColor(deal: DealForTableMin) {
    return (
      this.statuses &&
      this.statuses.find((x) => x.statusId == deal.statusId)?.color
    );
  }
  getDealStatus(deal: DealForTableMin) {
    return (
      this.statuses &&
      this.statuses.find((x) => x.statusId == deal.statusId)?.name
    );
  }
}
