import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { VersionHistory } from 'src/app/data/VersionHistory';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VersionHistroryService } from 'src/app/Services/version-histrory.service';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.css'],
})
export class VersionHistoryComponent implements OnInit {
  versionHistories: VersionHistory[];
  versionHistory: VersionHistory = new VersionHistory();
  systemNames = [
    { id: 1, name: 'WEBAPI' },
    { id: 2, name: 'ADMIN_PANEL' },
    { id: 3, name: 'SITE' },
    { id: 4, name: 'ANDROID_APP' },
    { id: 5, name: 'IOS_APP' },
  ];
  displayedColumns: string[] = ['systemName', 'createDate', 'number'];
  selectedSystemId: number;
  createDate: any;
  versionNumber: string;
  constructor(
    private versionHistoryService: VersionHistroryService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.versionHistoryService.getAll().subscribe((data) => {
      this.versionHistories = data;
      console.table(this.versionHistories);
    });
  }

  getSystemName(id) {
    return this.systemNames.find((x) => x.id == id).name;
  }

  post() {
    if (!this.selectedSystemId /* && !this.createDate */ && !this.versionNumber)
      return;
    this.versionHistory.systemId = this.selectedSystemId;
    this.versionHistory.createDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    this.versionHistory.number = this.versionNumber;
    this.versionHistoryService.post(this.versionHistory).subscribe((data) => {
      this.selectedSystemId = null;
      this.createDate = '';
      this.versionNumber = '';
      this.getAll();
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.success
      );
    });
  }
}
