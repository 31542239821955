import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/Services/categories.service';
import { SubCategoriesService } from 'src/app/Services/sub-categories.service';
import { Category } from 'src/app/data/Category';
import { SubCategory } from 'src/app/data/SubCategory';
import { ActivatedRoute } from '@angular/router';
import { TubeTestResultsService } from 'src/app/Services/tube-test-results.service';
import { ProductsService } from 'src/app/Services/products.service';
import { Product } from 'src/app/data/Product';
import { TubeTestResult } from 'src/app/data/TubeTestResult';

@Component({
  selector: 'app-dna-results-calculator',
  templateUrl: './dna-results-calculator.component.html',
  styleUrls: ['./dna-results-calculator.component.css'],
})
export class DnaResultsCalculatorComponent implements OnInit {
  categories: Category[];
  subCategories: SubCategory[];
  selectedCategory;
  selectedSubCategory;
  catSubCats: SubCategory[];

  displayedColumns: string[] = ['snpNumber', 'result', 'value'];
  productId: number;
  subCatResults: TubeTestResult[];
  product: Product;
  tubeTestResults: TubeTestResult[];
  subCatSum;

  constructor(
    private categoriesService: CategoriesService,
    private subCategoriesService: SubCategoriesService,
    private route: ActivatedRoute,
    private tubeTestResultsService: TubeTestResultsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.productId = +this.route.snapshot.queryParamMap.get('productId');
    this.productsService.getById(this.productId).subscribe((data) => {
      if ((data as Product).productId) {
        this.product = data as Product;
        this.tubeTestResultsService
          .getByBarcode(this.product.barCode)
          .subscribe((data) => {
            this.tubeTestResults = data;
          });
      }
    });

    this.categoriesService
      .getAll()
      .subscribe((data) => (this.categories = data));
    this.subCategoriesService
      .getAll()
      .subscribe((data) => (this.subCategories = data));
  }
  onCatChange(val) {
    this.catSubCats = this.subCategories.filter((x) => x.categoryId == +val);
    var a = val;
  }
  onSubCatChange(val) {
    this.subCatResults = this.tubeTestResults.filter((x) => {
      return x.snp.subCategoryId == +this.selectedSubCategory;
    });
    this.subCatSum = 0;
    for (var i = 0; i < this.subCatResults.length; i++) {
      var snpResult = this.subCatResults[i].snpResult;
      this.subCatSum += this.subCatResults[i].snpResult.value;
    }

    //this.subCatSum = this.subCatResults.forEach((x) => {});
    // reduce((a,b) => (a.sNPResult.value+b.sNPResult.value);
    var a = this.subCatSum;
  }
}
