import { Component, OnInit, ViewChild } from '@angular/core';
import { ExternalLeadService } from 'src/app/Services/external-lead.service';
import { ExternalLead } from 'src/app/data/ExternalLead';

@Component({
  selector: 'app-external-lead',
  templateUrl: './external-lead.component.html',
  styleUrls: ['./external-lead.component.css'],
})
export class ExternalLeadComponent implements OnInit {
  constructor(private externalLeadService: ExternalLeadService) {}
  firstName;
  email;
  phone;
  source;
  clientId;
  @ViewChild('f') form: any;
  ngOnInit(): void {}
  onSubmit() {
    if (this.form.valid) {
      //console.log('Form Submitted!');
    }
    var a = this.form.value;
    var lead: ExternalLead = new ExternalLead();

    lead.email = this.form.value.email;
    lead.firstName = this.form.value.firstName;
    lead.phone = this.form.value.phone;
    lead.source = this.form.value.source;

    this.externalLeadService.post(lead).subscribe((ans) => {
      this.clientId = ans;
    });
  }
}
