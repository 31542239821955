<div class="meeting-settings">
  <button mat-button class="meeting-settings__back" (click)="location.back()">
    < Back
  </button>
  <div class="meeting-settings__title">Meeting settings</div>

  <ng-container
    *ngFor="let item of meetingSettings; let first = first; let index = index"
  >
    <div class="mt-4" *ngIf="first"></div>
    <mat-card class="meeting-settings__item">
      <div>
        <div
          class="meeting-settings__item__row"
          [class.view-mode]="!inEditMode(item)"
        >
          <mat-form-field
            *ngFor="let lang of item.meetingSettingLangs"
            appearance="fill"
          >
            <input
              required
              [disabled]="!inEditMode(item)"
              [style.text-align]="lang.languageId == 1 ? 'right' : 'left'"
              [placeholder]="
                lang.languageId == 1 ? 'סוג הפגישה' : 'Meeting type'
              "
              matInput
              type="text"
              [(ngModel)]="lang.name"
            />
          </mat-form-field>
        </div>
        <div
          class="meeting-settings__item__row"
          [class.view-mode-select]="!inEditMode(item)"
        >
          <div class="d-flex">
            <div class="meeting-settings__item__row__label mr-2">
              Meeting time
            </div>
            <mat-form-field class="duration" appearance="fill">
              <mat-select
                required
                [disabled]="!inEditMode(item)"
                [(ngModel)]="item.meetingTime"
              >
                <mat-option *ngFor="let item of durationTime()" [value]="item"
                  >{{ item }} min</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-flex">
            <div class="meeting-settings__item__row__label mr-2">
              Prepertion time
            </div>
            <mat-form-field class="duration" appearance="fill">
              <mat-select
                required
                [disabled]="!inEditMode(item)"
                [(ngModel)]="item.preMeetingTime"
              >
                <mat-option *ngFor="let item of durationTime()" [value]="item"
                  >{{ item }} min</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="mt-1" [class.view-mode]="!inEditMode(item)">
          <mat-checkbox
            [disabled]="!inEditMode(item)"
            [(ngModel)]="item.isAvailableForCustomer"
            >Availlable to customer</mat-checkbox
          >
        </div>
      </div>
      <div class="meeting-settings__item__action">
        <ng-container *ngIf="inEditMode(item); else viewMode">
          <button
            mat-icon-button
            class="meeting-settings__item__action__save"
            (click)="saveMeeting(item, index)"
          >
            <mat-icon>check</mat-icon>
          </button>
          <button
            mat-icon-button
            class="meeting-settings__item__action__close"
            (click)="closeEditMode(item, index)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
        <ng-template #viewMode>
          <button
            mat-icon-button
            class="meeting-settings__item__action__edit"
            (click)="
              editMode = !editMode;
              meetingSettingIdToEdit = item.meetingSettingId
            "
            [disabled]="
              editMode && meetingSettingIdToEdit != item.meetingSettingId
            "
          >
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button
            mat-icon-button
            class="meeting-settings__item__action__delete"
            (click)="deleteMeeting(item)"
            [disabled]="
              editMode && meetingSettingIdToEdit != item.meetingSettingId
            "
          >
            <mat-icon>delete</mat-icon>
          </button>
        </ng-template>
      </div>
    </mat-card>
  </ng-container>
  <button
    class="meeting-settings__add-new"
    (click)="addNewMeeting()"
    mat-button
    [disabled]="editMode"
  >
    Add new meeting
  </button>
</div>
