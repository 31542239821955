import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LifeStyleCalculationType } from '../data/LifeStyleCalculationTypes';

@Injectable({
  providedIn: 'root',
})
export class LifeStyleCalculationTypesService {
  constructor(private http: HttpClient) {}
  getAll(): Observable<LifeStyleCalculationType[]> {
    return this.http.get<LifeStyleCalculationType[]>(
      `${environment.baseUrl}LifeStyleCalculationTypes/`
    );
  }
}
