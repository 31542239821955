import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '../data/Note';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { NoteForMissionsMin } from '../data/NoteForMissionsMin';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { DatePipe } from '@angular/common';
import { NoteForCallMin } from '../data/NoteForCallMin';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getAll(): Observable<Note[]> {
    return this.http.get<Note[]>(`${environment.baseUrl}Notes/`);
  }
  getAllCalls(
    headFilter: HeadFilter,
    clientId = -1,
    patientId = -1
  ): Observable<any> {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }Notes/CallsFilter/${clientId}/${patientId}/${this.httpFilterHelper.createCallsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getById(noteId): Observable<Note> {
    return this.http.get<Note>(`${environment.baseUrl}Notes/${noteId}`);
  }

  getAllByClientId(clientId): Observable<NoteForCallMin[]> {
    return this.http.get<NoteForCallMin[]>(
      `${environment.baseUrl}Notes/AllForClient/${clientId}`
    );
  }

  getByClientId(clientId, noteTypeId): Observable<NoteForCallMin[]> {
    return this.http.get<NoteForCallMin[]>(
      `${environment.baseUrl}Notes/ForClient/${clientId}/${noteTypeId}`
    );
  }

  getByAgentIdOld(agentId, noteTypeId): Observable<NoteForMissionsMin[]> {
    return this.http.get<NoteForMissionsMin[]>(
      `${environment.baseUrl}Notes/ForAgent/${agentId}/${noteTypeId}`
    );
  }

  getAllByServiceRequestId(serviceRequestId: number) {
    return this.http.get<NoteForCallMin[]>(
      `${environment.baseUrl}Notes/ForServiceRequest/${serviceRequestId}`
    );
  }
  getAllByCancelationRequestId(cancelationRequestId: number) {
    return this.http.get<NoteForCallMin[]>(
      `${environment.baseUrl}Notes/ForCancelationRequest/${cancelationRequestId}`
    );
  }
  getByAgentId(
    agentId,
    noteTypeId,
    isOverDue,
    pageSize,
    pageNumber,
    filter,
    decending
  ): Observable<any> {
    return this.http.get<NoteForMissionsMin[]>(
      `${environment.baseUrl}Notes/ForAgent/${agentId}/${noteTypeId}/${isOverDue}/${pageSize}/${pageNumber}/${filter}/${decending}`
    );
  }
  getByPatientId(productId): Observable<Note[]> {
    return this.http.get<Note[]>(
      `${environment.baseUrl}Notes/ForPatient/${productId}`
    );
  }
  put(note: Note): Observable<Note> {
    return this.http.put<Note>(
      `${environment.baseUrl}Notes/${note.noteId}`,
      note
    );
  }
  putMin(note: NoteForCallMin): Observable<Note> {
    return this.http.put<Note>(
      `${environment.baseUrl}Notes/Min/${note.noteId}`,
      note
    );
  }

  changeStatusMin(note: NoteForMissionsMin) {
    return this.http.put<NoteForMissionsMin>(
      `${environment.baseUrl}Notes/ChangeStatusMin/${note.noteId}`,
      note
    );
  }
  changeStatusMinCall(note: NoteForCallMin) {
    return this.http.put<NoteForMissionsMin>(
      `${environment.baseUrl}Notes/ChangeStatusMin/${note.noteId}`,
      note
    );
  }
  post(note: Note): Observable<Note> {
    return this.http.post<Note>(`${environment.baseUrl}Notes/`, note);
  }
  delete(note: Note) {
    return this.http.delete(`${environment.baseUrl}Notes/${note.noteId}`);
  }
  openNewTaskForFollowUpNotAnswerCall(patientId) {
    return this.http.delete(
      `${environment.baseUrl}Notes/openNewTaskForFollowUpNotAnswerCall/${patientId}`
    );
  }
  getuploadFilePath(noteId: number) {
    return `Notes/uploadImage/${noteId}/`;
  }

  getCallRecord(noteId: number) {
    return this.http.get<Blob>(
      `${environment.baseUrl}BlobsDownloads/GetCallRecord/${noteId}`
    );
  }
}
