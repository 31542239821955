import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BulletCrossResult } from 'src/app/data/algorithm/BulletCrossResult';
import { Product } from 'src/app/data/Product';
import { TubeTestBullet } from 'src/app/data/TubeTestBullet';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { TubeTestResultsService } from 'src/app/Services/tube-test-results.service';

@Component({
  selector: 'app-recomendations',
  templateUrl: './recomendations.component.html',
  styleUrls: ['./recomendations.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 250ms ease-in'),
      ]),
      transition(':leave', animate('500ms ease-in', style({ opacity: 0 }))),
    ]),
  ],
})
export class RecomendationsComponent implements OnInit, OnDestroy {
  @ViewChildren('elementRef') elementRef: QueryList<ElementRef>;
  @ViewChildren('pageRef') pageRef: ElementRef;
  recomendationsStep = -1;
  alive: boolean = true;
  result: string;
  firstTimeAnimation: boolean = true;
  panUpTimeout: any;
  recomendations: BulletCrossResult[];
  barcode: string;
  direction = '';
  lastScrollTop = 0;
  scrollY;
  visibleIndex = 0;
  isLoading: boolean;
  hasBloodTest: boolean;
  langId: number = 1;
  productId: any;
  product: Product;
  constructor(
    private tubeTestResultsService: TubeTestResultsService,
    private popupHandlerService: PopupHandlerService,
    private _location: Location,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private algorithmService: AlgorithmService
  ) {}

  goBack() {
    this._location.back();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.productId = params['productId'];

      this.productsService.getById(this.productId).subscribe((data) => {
        this.product = data as Product;
        this.barcode = this.product.barCode;
        this.hasBloodTest = this.product.bloodTestForBulletsFilled;
        this.getRecomendations();
      });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  openLinkDialog(linkId, color) {
    //console.log(linkId);
    this.popupHandlerService.openAlgoLinkDialog(linkId, this.langId, color);
  }

  elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  getRecomendations() {
    this.isLoading = true;
    this.algorithmService
      .getAlgoCrossingGroupByBarcode(this.barcode, this.langId)
      .subscribe((data) => {
        this.recomendations = data;
        this.isLoading = false;
        setTimeout(() => {
          const el = document.querySelector('.recomendations__page__container');
          fromEvent(el, 'scroll')
            .pipe(takeWhile(() => this.alive))
            .subscribe((res) => {
              this.elementRef.toArray().map((x) => {
                const isVisible = this.elementIsVisibleInViewport(
                  x.nativeElement
                );
                if (isVisible) {
                  let id = x.nativeElement.id.split('-')[1];
                  this.visibleIndex = id;
                }
              });
            });
        }, 0);
      });
  }
  scrollToRec(index, dir: 'prev' | 'next' = null) {
    if (dir === 'next') {
      index++;
    }
    if (dir === 'prev') {
      index = --index;
    }
    const el = document.querySelector(`#rec-${index}`);
    el.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }
}
