<form [formGroup]="thumbForm" (ngSubmit)="saveThis()" class="new-product">
  <mat-form-field class="round-select">
    <mat-select
      (selectionChange)="addNewProduct()"
      formControlName="productTypeId"
      placeholder="Choose"
      disableOptionCentering
    >
      <mat-option
        *ngFor="let productType of productTypes"
        [value]="productType.productTypeId"
      >
        {{ productType.productName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="new-product__action">
    <button
      *ngIf="thumbForm.value.productTypeId != 7"
      type="button"
      class="btn btn-danger btn-number"
      (click)="decrement()"
      data-type="minus"
    >
      <!-- <span class="glyphicon glyphicon-minus"></span> -->
      -
    </button>
    <span>{{ amount }}</span>
    <button
      *ngIf="thumbForm.value.productTypeId != 7"
      type="button"
      class="btn btn-danger btn-number"
      (click)="increment()"
      data-type="plus"
    >
      <!-- <span class="glyphicon glyphicon-minus"></span> -->
      +
    </button>
  </div>
  <mat-form-field *ngIf="showMinimal == true" class="new-product__input">
    <input
      (change)="calculateAmount()"
      matInput
      formControlName="pricePerUnit"
      type="number"
      min="0"
    />
  </mat-form-field>
  <span class="new-product__total" *ngIf="showMinimal == false">
    Total: <span>{{ total }}</span>
  </span>
</form>
<div *ngIf="thumbForm.value.productTypeId == 7">
  <table mat-table [dataSource]="clientProducts">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef style="min-width: 40px"></th>
      <td mat-cell *matCellDef="let product" style="padding-left: 12px">
        <mat-checkbox
          (change)="onCheckedChanged()"
          [(ngModel)]="product.isChecked"
          class="example-margin"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef style="min-width: 140px">
        Patient Name
      </th>
      <td mat-cell *matCellDef="let product">{{ product.patientName }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="patientId">
      <th mat-header-cell *matHeaderCellDef style="min-width: 140px">
        Patient Id
      </th>
      <td mat-cell *matCellDef="let product">
        {{ product.socialSecurityNum }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef style="min-width: 140px">Status</th>
      <td mat-cell *matCellDef="let product">{{ product.status.name }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
