import { Deal } from './Deal';

export class WiredTransfer {
  wiredTransferId: number;
  dealId: number;
  deal: Deal;
  bankName: string;
  branchNumber: string;
  accountNumber: string;
}
