<div style="font-size: 14px; display: flex;">
  <div style="margin-right: 20px;">
    <img src="../../../assets/noteBlue.svg" />
  </div>
  &nbsp;&nbsp;
  <div>
    <span>{{ request.subject.name }}</span>
  </div>
  &nbsp;&nbsp;
  <div>
    <span>{{ request.desc }}</span>
  </div>
  &nbsp;&nbsp;
  <div>
    <span>{{ request.fullName }}</span>
  </div>
  &nbsp;&nbsp;
  <div>
    <span>{{ request.phone }}</span>
  </div>
  &nbsp;&nbsp;
  <div>
    <span>{{ request.numAttempts }}</span>
  </div>
</div>
