import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-algo-bullets',
  templateUrl: './edit-algo-bullets.component.html',
  styleUrls: ['./edit-algo-bullets.component.css']
})
export class EditAlgoBulletsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
