import { FoodScale } from './FoodScale';
import { Language } from './Language';

export class FoodScaleLang {
  foodScaleLangId: number;
  foodScaleId: number;
  foodScale: FoodScale;
  languageId: number;
  language: Language;
  name: string;
}
