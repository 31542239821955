import { Component, OnInit } from '@angular/core';
import { PrototypeTabs } from 'src/app/Enums/StatusesEnum';
import { Location } from '@angular/common';
import { Language } from 'src/app/data/Language';

export type TabsPrototype = {
  id: number;
  name: string;
  isActive: boolean;
};

@Component({
  selector: 'app-algo-prototype',
  templateUrl: './algo-prototype.component.html',
  styleUrls: ['./algo-prototype.component.scss'],
})
export class AlgoPrototypeComponent implements OnInit {
  tabsPrototype: TabsPrototype[] = [
    { id: 1, name: 'קטגוריה', isActive: true },
    { id: 2, name: 'תת קטגוריה', isActive: false },
    { id: 3, name: 'נטייה', isActive: false },
  ];
  activeTab: TabsPrototype = this.tabsPrototype[0];
  tabsEnum = PrototypeTabs;
  langId: number = 1;
  constructor(public location: Location) {}

  ngOnInit(): void {}
  onSelectTab(tab: TabsPrototype) {
    this.tabsPrototype.forEach((t) => (t.isActive = false));
    tab.isActive = true;
    this.activeTab = tab;
  }

  onChangeLang(e: Language) {
    this.langId = e.languageId;
  }
  save() {}
}
