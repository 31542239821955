import { environment } from 'src/environments/environment';
import { Product } from '../data/Product';
import { RegularUser } from '../data/RegularUser';
import { UserPermission } from '../data/UserPermission';

/**
 *@description This function is used to check if the user has the permission with specific rule to access the page
 * @param premissions: string[]
 * @param user : RegularUser
 * @returns boolean
 */
export function userHasPermisionsWithRule(
  premissions: string[],
  user: RegularUser
) {
  if (!user || !user.userRole || !user.userRole.userPermissions) return false;

  for (var i = 0; i < premissions.length; i++) {
    var perm = premissions[i];
    var permSpl = perm.split('_');
    var module = permSpl[0];
    var action = permSpl[1];
    var rule = '';
    if (permSpl.length > 2) {
      rule = permSpl[2];
    }

    for (var j = 0; j < user.userRole.userPermissions.length; j++) {
      var serverPerm: UserPermission = user.userRole.userPermissions[j];
      if (
        module == serverPerm.appModule.appModuleName &&
        action == serverPerm.moduleAction.moduleActionName
      ) {
        if (
          rule == '' &&
          (serverPerm.rulesToPermission == null ||
            serverPerm.rulesToPermission.length == 0)
        ) {
          return true;
        } else {
          if (serverPerm && serverPerm.rulesToPermission)
            for (var i = 0; i < serverPerm.rulesToPermission.length; i++) {
              if (
                rule ==
                serverPerm.rulesToPermission[i].permissionRule
                  .permissionRuleName
              ) {
                return true;
              }
            }
        }
      }
    }
  }
  return false;
}
/**
 *@description This function is used to check if the user has the permission to access the page
 *@param premissions: string[]
 *@param user: RegularUser
 *@returns boolean
 */
export function userHasPermisions(
  premissions: string[],
  user: RegularUser
): boolean {
  //return false;
  if (!user || !user.userRole || !user.userRole.userPermissions) return false;
  for (let i = 0; i < premissions.length; i++) {
    let perm = premissions[i];
    let permSpl = perm.split('_');
    let module = permSpl[0];
    let action = permSpl[1];
    let rule = '';
    if (permSpl.length > 2) {
      rule = permSpl[2];
    }

    for (let j = 0; j < user.userRole.userPermissions.length; j++) {
      let serverPerm: UserPermission = user.userRole.userPermissions[j];
      if (
        module == serverPerm.appModule.appModuleName &&
        action == serverPerm.moduleAction.moduleActionName
      ) {
        return true;
      }
    }
  }
  return false;
}
/*
export function userHasPermisions(premissions: string[], user: RegularUser) {
  if (environment.isNewPermissions) {
    //return true;
    if (!user || !user.userRole) return false;
    var userPerms1 = user.userRole.userPermissions;
    for (var i = 0; i < userPerms1.length; i++) {
      if (premissions.includes(userPerms1[i].displayName)) return true;
    }
    return false;
  } else {
    if (!user || !user.role) return false;
    var userPerms = user.role.permissionsForRoles;
    for (var i = 0; i < userPerms.length; i++) {
      if (premissions.includes(userPerms[i].permission.permissionAction))
        return true;
    }
  }
  return false;
}
*/
export function getWeightUtil(isGoal: boolean, product: Product) {
  const array = product.patient.patientWeightHistories?.filter(
    (x) => x.isGoal == isGoal
  );
  if (array?.length > 0) {
    return array.reduce((a, b) => {
      return new Date(a?.dateUpdated) > new Date(b?.dateUpdated) ? a : b;
    })?.value;
  }
  return null;
}
