<ngx-skeleton-loader
  *ngIf="isLoading"
  [theme]="{ height: '25px' }"
  count="5"
></ngx-skeleton-loader>
<ng-container ngif="!isLoading">
  <div *ngIf="templates && productId">
    <span class="label"> Choose template: </span>
    <mat-form-field appearance="fill" class="dropdown">
      <mat-select (selectionChange)="onSelectTemplate($event)">
        <mat-option *ngFor="let item of templates" [value]="item.id">{{
          item.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mb-2"></div>
  <form class="list-edit" [formGroup]="mealsForm" *ngIf="mealsForm">
    <div>
      <span class="label"> {{ productId ? "Menu" : "Template" }} name: </span>
      <mat-form-field appearance="fill">
        <input matInput formControlName="menuName" />
      </mat-form-field>
    </div>
    <div class="mb-2"></div>
    <div style="display: flex; align-items: center" *ngIf="productId">
      <span class="label">Nutitionist: </span>
      <app-autocomplete-agent
        [ngStyle]="{
          'border-bottom':
            mealsForm.get('nutritionist').errors?.required && showFormErrors
              ? '2px solid #de211f'
              : ''
        }"
        (selectedValue)="saveNutritionistForMenu($event)"
        [data]="nutritionists"
        [initialValue]="getAgentNameById(menuAgentId)"
        [widthExp]="180"
        [label]="'Select Nutritionist'"
        [direction]="'ltr'"
        [textAlign]="'left'"
      ></app-autocomplete-agent>
      &nbsp;
      <span class="label" style="margin-left: 1rem"> Status: </span>
      <mat-button-toggle-group formControlName="status" aria-label="Status">
        <mat-button-toggle [value]="true">Completed</mat-button-toggle>
        <mat-button-toggle [value]="false">Draft</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="mb-2"></div>
    <span class="title">Menu:</span>
    <hr class="hr pink" />

    <mat-list
      class="mealsTable"
      role="list"
      *ngIf="mealsForm.get('meals')?.length > 0"
    >
      <mat-list-item class="mealsTable__header" role="listitem">
        <span>Type of meal</span>
        <span>At</span>
        <!-- <span *ngIf="!mealEditMode">Calories</span> -->
        <span class="actions">Actions</span>
      </mat-list-item>
      <div
        formArrayName="meals"
        *ngFor="let meal of mealsForm.get('meals')['controls']; let i = index"
      >
        <mat-card [formGroupName]="i" class="mealsTable__item">
          <mat-list-item role="listitem">
            <ng-container
              *ngIf="
                menuForEdit?.meals[i]?.mealId == mealIdToEdit ||
                  (isNewMeal &&
                    mealsForm.get('meals')['controls'][i].value.mealId == 0);
                else viewMode
              "
            >
              <span>
                <mat-form-field appearance="fill" class="dropdown">
                  <mat-select formControlName="mealTypeId">
                    <mat-option
                      *ngFor="let item of mealTypes"
                      [value]="item.statusId"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </span>
              <span>
                <mat-form-field appearance="fill" class="dropdown">
                  <!-- <mat-select formControlName="mealTime">
                    <mat-option *ngFor="let hour of hoursList" [value]="hour">{{
                      hour
                    }}</mat-option>
                  </mat-select> -->
                  <input
                    formControlName="mealTime"
                    placeholder="07:00"
                    matInput
                    mask="00:00"
                  />
                </mat-form-field>
              </span>
            </ng-container>
            <ng-template #viewMode>
              <span style="transform: translateY(2px)">{{
                getMealName(i)
              }}</span>

              <span style="transform: translateY(2px)">{{
                getMealTime(i)
              }}</span>
              <!-- <span *ngIf="!mealEditMode" style="transform: translateY(2px)">{{
                getCalories(menuForEdit.meals[i].menu.menuToNutrients)
              }}</span> -->
            </ng-template>
            <span class="actions" *ngIf="showActionBtns(i)">
              <button
                (click)="editMeal(menuForEdit.meals[i].mealId)"
                mat-button
                mat-icon-button
                [disabled]="
                  mealIdToEdit !== menuForEdit.meals[i].mealId && mealEditMode
                "
              >
                <mat-icon class="table">
                  {{ getEditIconName(menuForEdit.meals[i]) }}
                </mat-icon>
              </button>
              <button
                *ngIf="!this.menuForEdit.isComplete"
                [disabled]="mealEditMode"
                (click)="removeMealItem(i)"
                mat-button
                mat-icon-button
                class="delete"
              >
                <mat-icon class="table">delete_forever</mat-icon>
              </button>
            </span>
          </mat-list-item>
          <div>
            <small
              class="meal-error"
              *ngIf="meal.get('mealTime').hasError('pattern')"
            >
              Meal time not in valid, valid format is: 00:00-23:59
            </small>
          </div>
          <!--  <small
            class="meal-error"
            *ngIf="
              mealChanged &&
              ifEditModeActive(
                mealsForm.get('meals')['controls'][i].value.mealId
              ) &&
              mealEditMode
            "
            >Changes has made in the meal please save it first</small
          > -->
        </mat-card>
      </div>
    </mat-list>
    <div class="mb-2"></div>
    <button
      *ngIf="!mealEditMode && !isNewMeal"
      mat-button
      class="btn btn-fill"
      (click)="addNewMeal()"
      [disabled]="!canEditMenu && !isTemplate"
    >
      + Add New Meal
    </button>
    <div class="mb-2"></div>
    <hr class="hr gray" />
    <div class="remarks">
      <div class="label">Remarks</div>
      <angular-editor
        dir="rtl"
        class="w-100"
        formControlName="notes"
        [config]="editorConfig"
      ></angular-editor>
    </div>
    <div class="mb-2"></div>
    <div class="footer" *ngIf="menuForEdit || menu">
      <div
        class="footer__genetic-rate"
        [ngStyle]="{
          visibility: menuForEdit && productId ? 'visible' : 'hidden'
        }"
      >
        <ng-container *ngIf="menuForEdit && productId">
          <div class="footer__genetic-rate__title">
            <div class="label">Menu Genetic Rate:</div>
          </div>
          <div class="footer__genetic-rate__bar">
            <mat-progress-bar [value]="getGeneticRate() | number : '1.0-0'">
            </mat-progress-bar>
            <div
              class="footer__genetic-rate__bar__mask"
              [ngStyle]="{
                left: (getGeneticRate() | number : '1.0-0') + '%',
                width:
                  'calc(100% - ' + (getGeneticRate() | number : '1.0-0') + '%)'
              }"
            ></div>
            <div
              class="footer__genetic-rate__bar__value"
              [ngStyle]="{
                right:
                  'calc(' +
                  setGeneticRateOffset(getGeneticRate()) +
                  ' - ' +
                  (getGeneticRate() | number : '1.0-0') +
                  '%)'
              }"
            >
              {{ getGeneticRate() | number : "1.0-0" }}%
            </div>
          </div>
        </ng-container>
      </div>
      <div
        [ngStyle]="{
          visibility: menuForEdit && productId ? 'visible' : 'hidden'
        }"
        class="footer__calories"
      >
        <ng-container *ngIf="menuForEdit && productId">
          <app-nutritional-values
            *ngIf="menuForEdit.menuToNutrients"
            [originalNutrients]="menuForEdit.menuToNutrients"
          >
          </app-nutritional-values>

          <div
            class="footer__calories__text"
            [ngStyle]="{
              'margin-bottom': product?.calorieDeficit ? '1rem' : ''
            }"
            *ngIf="menuCalories"
          >
            <span [ngStyle]="{ color: menuCalories.color }">
              {{ menuCalories.value | number : "1.0-0" }}</span
            >
            /
            <span [ngStyle]="{ color: goalCalories.color }">{{
              goalCalories.value | number : "1.0-0"
            }}</span>
            Cal
          </div>
        </ng-container>

        <span
          *ngIf="product?.calorieDeficit && !isTemplate"
          class="footer__calories__deficit"
        >
          Caloric Deficit:
          <strong>{{ product.calorieDeficit }}</strong>
        </span>
      </div>
      <div class="footer__action">
        <button
          [disabled]="
            (mealChanged || isSubmiting || mealEditMode || !canEditMenu) &&
            !isTemplate
          "
          mat-button
          class="btn btn-fill mr-2"
          (click)="saveMeals()"
        >
          <ng-container *ngIf="isSubmiting">
            Saving
            <mat-icon> <mat-spinner diameter="20"></mat-spinner></mat-icon>
          </ng-container>
          <ng-container *ngIf="!isSubmiting">Save</ng-container>
        </button>
        <!-- <button
            *ngIf="menuForEdit && productId"
            [disabled]="mealChanged"
            mat-button
            class="btn btn-outline"
            (click)="saveAsTemplate()"
          >
            Save as template
          </button> -->
      </div>
      <!-- <div class="footer__error" *ngIf="mealChanged">
          Before saving the menu, save the meal first
        </div> -->
    </div>
  </form>
</ng-container>
