<div
  *ngIf="
    result.bloodTestType.minFemale === -1.0 ||
    result.bloodTestType.maxFemale == -1.0
  "
  class="d-flex"
  [ngStyle]="{
    color: isInRangeSingleCondition(result) ? 'green' : 'red'
  }"
>
  רצוי ערך
  {{ result.bloodTestType.minFemale === -1.0 ? "קטן" : "גדול" }}

  מ-
  <ng-container *ngIf="result.bloodTestType.minFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.maxMale, result)
        : getUnit(result.bloodTestType.maxFemale, result)
    }}
  </ng-container>
  <ng-container *ngIf="result.bloodTestType.maxFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.minMale, result)
        : getUnit(result.bloodTestType.minFemale, result)
    }}
  </ng-container>
</div>
<div
  *ngIf="
    result.bloodTestType.minFemale != -1.0 &&
    result.bloodTestType.maxFemale != -1.0
  "
  class="d-flex"
>
  <img
    [ngStyle]="{ visibility: isRedMin(result) ? 'visible' : 'hidden' }"
    src="../../../assets/triangle_red.svg"
    style="position: relative; top: -8px"
  />
  <!--  *ngIf="isRedMin(result)"-->
  <span class="indicator-bar__start">{{
    gender === "male"
      ? getUnit(result.bloodTestType.minMale, result)
      : getUnit(result.bloodTestType.minFemale, result)
  }}</span>
  <div style="width: 0px">
    <img
      *ngIf="isGreenVisible(result)"
      [ngStyle]="{ left: greenArrowPos(result) }"
      src="../../../assets/triangle_green.svg"
      class="indicator-bar__arrow"
    />
  </div>
  <div class="indicator-bar">
    <div class="indicator-bar__item"></div>
    <div class="indicator-bar__item"></div>
    <div class="indicator-bar__item"></div>
    <div class="indicator-bar__item"></div>
  </div>

  <span class="indicator-bar__end">{{
    gender === "male"
      ? getUnit(result.bloodTestType.maxMale, result)
      : getUnit(result.bloodTestType.maxFemale, result)
  }}</span>
  <img
    [ngStyle]="{ visibility: isRedMax(result) ? 'visible' : 'hidden' }"
    src="../../../assets/triangle_red.svg"
    class="indicator-bar__arrow"
  />
</div>
