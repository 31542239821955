import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ClientRequestNote } from 'src/app/data/food-genes/ClientRequestNote';
import { Note } from 'src/app/data/Note';
import { Globals } from 'src/app/globals';
import { NotesService } from 'src/app/Services/notes.service';

@Component({
  selector: 'app-client-add-note',
  templateUrl: './client-add-note.component.html',
  styleUrls: ['./client-add-note.component.css'],
})
export class ClientAddNoteComponent implements OnInit {
  constructor(private notesService: NotesService) {
    this.stageId = Globals.stageId;
  }
  stageId: number;
  templates;
  today: number = Date.now();
  @Input() note: Note | ClientRequestNote;
  @Input() classAttr;
  @Input() statuses;
  @Input() isAppReport: boolean;
  @Output('submitNote') submitNote: EventEmitter<any> = new EventEmitter();
  @Output('cancelNote') cancelNote: EventEmitter<any> = new EventEmitter();
  @Output('reloadNotes') reloadNotes: EventEmitter<any> = new EventEmitter();
  @ViewChild('addNoteForm') form: any;
  noteContent: string;
  Message;
  desc;
  ngOnInit(): void {
    const note = this.note as Note;
    const clientRequestNote = this.note as ClientRequestNote;
    if (note.noteId <= 0 || clientRequestNote.clientRequestNoteId <= 0) {
      if (this.isAppReport) {
        this.note = new ClientRequestNote();
      } else {
        this.note = new Note();
      }
    } else {
      if (!this.isAppReport) {
        this.noteContent = note.title;
      } else {
        this.noteContent = clientRequestNote.noteContent;
      }
    }
  }

  onSubmit(form) {
    if (form.valid) {
      let note: Note | ClientRequestNote;
      if (!this.isAppReport) {
        note = this.note as Note;
        note.title = form.value.title;
        note.statusId = form.value.statusId; //OPEN
        note.noteTypeId = 1; //Note
      } else {
        note = this.note as ClientRequestNote;
        note.noteContent = form.value.title;
      }
      this.submitNote.emit(note);
    }
  }
  onNoteChange(event) {
    if (this.isAppReport) {
      const note = this.note as ClientRequestNote;
      note.noteContent = event;
    } else {
      const note = this.note as Note;
      note.title = event;
    }
  }
  onCancelNewNote() {
    this.cancelNote.emit();
  }
  getFilesUploadPath() {
    if (this.isAppReport) {
      const note = this.note as Note;
      return this.notesService.getuploadFilePath(note.noteId);
    }
  }
  uploadFileFinished(event) {
    if (event == true) this.reloadNotes.emit(true);
  }
  getImageFileName() {
    if (this.isAppReport) {
      const note = this.note as Note;
      if (note.imageUrl) {
        var spl = note.imageUrl.split('/');
        return spl[spl.length - 1];
      }
    }
  }
  downloadNoteImage() {
    if (this.isAppReport) {
      const note = this.note as Note;
      window.open(note.imageUrl, '_blank');
    }
  }

  hasNoteId() {
    if (this.isAppReport) {
      const note = this.note as Note;
      return note.noteId;
    } else {
      const note = this.note as ClientRequestNote;
      return note.clientRequestNoteId;
    }
  }

  hasNoteImage() {
    if (this.isAppReport) {
      const note = this.note as Note;
      return note.imageUrl;
    } else {
      const note = this.note as ClientRequestNote;
      return note?.noteImage?.fileId;
    }
  }
}
