import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'src/app/data/Language';
import { Meal } from 'src/app/data/Meal';
import { Menu } from 'src/app/data/Menu';
import { MenuForTable } from 'src/app/data/MenuForTable';
import { Status } from 'src/app/data/Status';
import { LanguagesService } from 'src/app/Services/languages.service';
import { MealsService } from 'src/app/Services/meals.service';
import { NutriMenuService } from 'src/app/Services/nutri-menu.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-nutrition-menu-templates',
  templateUrl: './nutrition-menu-templates.component.html',
  styleUrls: ['./nutrition-menu-templates.component.scss'],
})
export class NutritionMenuTemplatesComponent implements OnInit {
  editMode: boolean = false;
  meal: Meal;
  createNewMenu: boolean;
  editModeBullets: boolean;
  languages: Language[];
  mealChanged: boolean = false;
  menuList: MenuForTable[];
  isLoading: boolean = false;
  mealId: number;
  menuId: number;
  isTemplate: boolean = true;
  mealTypes: Status[];

  constructor(
    private nutriMenuService: NutriMenuService,
    private languagesService: LanguagesService,
    private router: Router,
    private statusesService: StatusesService
  ) {}

  ngOnInit(): void {
    this.getLanguages();
    this.getMenuList();
    this.getMealTypes();
  }

  goBack() {
    this.router.navigate(['menu-templates']);
  }

  getMealTypes() {
    this.statusesService.getAll().subscribe((data) => {
      this.mealTypes = data.filter((x) => x.category === 'MealType');
    });
  }

  getMenuList() {
    this.nutriMenuService.getTemplatesList().subscribe((data) => {
      this.menuList = data;
    });
  }

  hasMealChenged(event: boolean) {
    this.mealChanged = event;
  }

  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  getMenuForEdit(menuId: number) {
    this.createNewMenu = false;
    this.menuId = menuId;
  }

  onCreateNewMenu() {
    this.editMode = true;
    this.createNewMenu = true;
    this.editModeBullets = false;
  }

  backToMenus() {
    this.meal = null;
    this.editMode = false;
    this.editModeBullets = false;
    this.getMenuList();
  }

  editModeBulletsToggle(event: boolean) {
    this.editModeBullets = event;
  }

  editMeal(mealId: number) {
    this.mealId = mealId;
  }
}
