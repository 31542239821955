import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Laboratory } from 'src/app/data/algorithm/Laboratory';
import { Language } from 'src/app/data/Language';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { RegularUser } from 'src/app/data/RegularUser';

@Component({
  selector: 'app-labs-list',
  templateUrl: './labs-list.component.html',
  styleUrls: ['./labs-list.component.scss'],
})
export class LabsListComponent implements OnInit {
  dataSource: MatTableDataSource<Laboratory> = new MatTableDataSource([]);
  langId: number = 1;
  displayedColumns: string[] = ['lab', 'actions'];
  regularUser: RegularUser;
  canEdit: any;
  constructor(
    private algorithmService: AlgorithmService,
    public location: Location,
    private popupHandlerService: PopupHandlerService,
    private _matSnackBar: MatSnackBar,
    private regularUserService: RegularUsersService
  ) {}

  ngOnInit(): void {
    this.getLabs();
    this.getCurrentUser();
  }
  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
      this.canEdit = this.userHasPermission();
    });
  }
  userHasPermission() {
    return userHasPermisions(
      ['Laboratory_Create', 'Laboratory_Update'],
      this.regularUser
    );
  }

  getLabs() {
    this.algorithmService.getLabs(this.langId).subscribe((data) => {
      this.setDataSource(data);
    });
  }

  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
    this.getLabs();
  }

  setDataSource(data: Laboratory[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  addLab(lab: Laboratory = new Laboratory()) {
    this.popupHandlerService.openAddNewLab(lab);

    this.popupHandlerService.addNewLabSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.type === 'add') {
          const lab = new Laboratory();
          lab.name = data.lab.name;
          lab.langId = this.langId;
          lab.labId = 0;
          lab.description = 'New lab';
          this.algorithmService.addLab(lab).subscribe(() => {
            this.getLabs();
            this._matSnackBar.open('Lab added', '', {})._dismissAfter(2000);
          });
        } else if (data && data.type === 'edit') {
          this.algorithmService.setLab(data.lab).subscribe(() => {
            this.getLabs();
            this._matSnackBar.open('Lab updated', '', {})._dismissAfter(2000);
          });
        }
      });
  }
}
