import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgentMin } from 'src/app/data/AgentMin';
import { CancelationRequestForTableMin } from 'src/app/data/CancelationRequestForTableMin';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { CancelationRequestService } from 'src/app/Services/cancelation-request.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-all-cancelations',
  templateUrl: './all-cancelations.component.html',
  styleUrls: ['./all-cancelations.component.css'],
})
export class AllCancelationsComponent implements OnInit {
  leadsData: ClientForTableMin[];
  statuses: Status[];
  agents: AgentMin[];
  leadSources: LeadSource[];
  //statuses;
  checkedIds: any;
  picker;
  date;
  checkedAll: boolean = false;
  selectedAgentText = 'Agent: All';
  source;
  agentUser: RegularUser;
  @ViewChild('toggle') toggleDatepicker: ElementRef;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesTable: LeadStatus[];
  cancellationRequests: CancelationRequestForTableMin[];
  decisions: Status[] = null;
  isLoading: boolean;
  tabsList: any[] = [];
  //creditStatuses: Status[];
  constructor(
    private cancellationRequestsService: CancelationRequestService,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    private filterMemoryService: FilterMemoryService,
    private regularUsersService: RegularUsersService
  ) {
    // GET LOGGED USER
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });

    //SET DEFAULT HEAD FILTER
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.cancelations
    );
    this.setTabsListByPermisions();
  }

  ngOnInit(): void {
    // GET AGENTS
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data.filter((agent: AgentMin) => {
        return (
          agent.roleTypeId == UserRoleEmum.Support ||
          agent.roleTypeId == UserRoleEmum.SupportManager ||
          agent.roleTypeId == UserRoleEmum.Manager ||
          agent.roleTypeId == UserRoleEmum.SalesManager ||
          agent.roleTypeId == UserRoleEmum.SalesTeamLeader
        );
      });
    });
    //var x = this.agents
    this.statusesService.getAll().subscribe((data) => {
      this.statuses = data.filter((x) => {
        return (
          x.category == 'CancelationRequest'
          // && x.statusId !== CancellationRequestEnum.Closed &&
          // x.statusId !== CancellationRequestEnum.Closed
        );
      });
      this.decisions = data.filter((x) => x.category == 'CancelationDecision');
      this.getAll();
    });
  }
  setTabsListByPermisions() {
    if (this.hasAllPermissions()) {
      this.tabsList.push({ id: 1, name: 'All' });
    }
    if (this.isUserHasPermisions(['Cancelations_ConservationTab'])) {
      this.tabsList.push({ id: 2, name: 'New Request' });
    }
    if (this.isUserHasPermisions(['Cancelations_ManagerTab'])) {
      this.tabsList.push({ id: 3, name: 'Manager' });
    }
    if (this.isUserHasPermisions(['Cancelations_CouponTab'])) {
      this.tabsList.push({ id: 4, name: 'Coupon' });
    }
    if (this.isUserHasPermisions(['Cancelations_RefundTab'])) {
      this.tabsList.push({ id: 5, name: 'Refund' });
    }
    if (this.tabsList.length > 0) {
      this.headFilter.tabId = this.tabsList[0].id;
    }
  }
  isUserHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.agentUser);
  }
  hasAllPermissions() {
    return (
      this.isUserHasPermisions(['Cancelations_ConservationTab']) &&
      this.isUserHasPermisions(['Cancelations_RefundTab']) &&
      this.isUserHasPermisions(['Cancelations_ManagerTab'])
    );
  }

  getAll() {
    this.isLoading = true;
    this.cancellationRequestsService
      .getAllByFilterV2(this.headFilter)
      .subscribe((data) => {
        this.isLoading = false;
        this.cancellationRequests = data.list;
        this.metaData = data.metaData;
      });
  }

  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    this.headFilter = e;
    this.filterMemoryService.setfilter(
      this.headFilter,
      PageTypeEnum.cancelations
    );
    this.getAll();
  }
}
