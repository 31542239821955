import { R } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { AppModule } from 'src/app/app.module';
import { ActionConvertion } from 'src/app/data/ActionConvertion';
import { ModuleAction } from 'src/app/data/ModuleAction';
import { ModuleMinForConvertion } from 'src/app/data/ModuleMinForConvertion';
import { PermissionRule } from 'src/app/data/PermissionRule';
import { ActionConvertionsService } from 'src/app/Services/action-convertions.service';
import { AppModulesService } from 'src/app/Services/app-modules.service';
import { ModuleActionsService } from 'src/app/Services/module-actions.service';
import { PermissionRulesService } from 'src/app/Services/permission-rules.service';

@Component({
  selector: 'app-action-convertions',
  templateUrl: './action-convertions.component.html',
  styleUrls: ['./action-convertions.component.scss'],
})
export class ActionConvertionsComponent implements OnInit {
  displayedColumns: string[] = ['module', 'action', 'rule', 'name', 'delete'];
  moduleMinForConvertions: ModuleMinForConvertion[];
  appModules: AppModule[];
  moduleActions: ModuleAction[];
  permissionRules: PermissionRule[];
  newAppModuleId;
  newModuleActionId;
  newPermissionRuleId;
  newConvertionName;
  constructor(
    private actionConvertionsService: ActionConvertionsService,
    private appModulesService: AppModulesService,
    private moduleActionsService: ModuleActionsService,
    private permissionRulesService: PermissionRulesService
  ) {}

  ngOnInit(): void {
    this.loadConvertions();

    this.appModulesService
      .getAll()
      .subscribe((data) => (this.appModules = data));
    this.moduleActionsService
      .getAll()
      .subscribe((data) => (this.moduleActions = data));
    this.permissionRulesService
      .getAll()
      .subscribe((data) => (this.permissionRules = data));
  }
  loadConvertions() {
    this.actionConvertionsService.getAllMin().subscribe((data) => {
      this.moduleMinForConvertions = data;
    });
  }
  addNewConvertion() {
    var actionConvertion: ActionConvertion = new ActionConvertion();
    actionConvertion.appModuleId = this.newAppModuleId;
    actionConvertion.moduleActionId = this.newModuleActionId;
    actionConvertion.permissionRuleId = this.newPermissionRuleId;
    actionConvertion.name = this.newConvertionName;
    if (actionConvertion.permissionRuleId == -1)
      actionConvertion.permissionRuleId = null;

    this.actionConvertionsService.post(actionConvertion).subscribe((res) => {
      this.resetNewParams();
      this.loadConvertions();
    });
  }
  resetNewParams() {
    this.newAppModuleId = null;
    this.newModuleActionId = null;
    this.newPermissionRuleId = null;
    this.newConvertionName = '';
  }
  deleteConvertion(actionConvertion: ActionConvertion) {
    this.actionConvertionsService.delete(actionConvertion).subscribe((res) => {
      this.loadConvertions();
    });
  }
  onConvertionNameChanged(actionConvertion: ActionConvertion) {
    this.actionConvertionsService.put(actionConvertion).subscribe((res) => {
      this.loadConvertions();
    });
  }
}
