<div style="position: relative">
  <div *ngIf="isLoading" class="loadingOverlay">
    <mat-spinner diameter="250"></mat-spinner>
  </div>
</div>
<div class="wrapper" [class.algoBullet]="algoBullet" *ngIf="headFilter">
  <div
    class="wrapper__leadsTableFilter"
    [ngClass]="{
      wrapper__leadsTableFilter__main_screen: isMainScreen,
      algoBullet: algoBullet,
      wrapper__leadsTableFilter__wide: classAttr == 'productsAttr'
    }"
    [ngStyle]="{ 'justify-content': classAttr == 'barcodes' ? 'center' : '' }"
  >
    <div
      style="width: 70px"
      class="title"
      *ngIf="
        classAttr != 'office-all-deliveries' &&
        classAttr != 'lab-all-deliveries'
      "
    >
      <span> Filter By </span>
    </div>
    <ng-container *ngIf="algoBullet">
      <mat-form-field
        appearance="fill"
        class="upgrade"
        style="padding-top: 1.34375em; margin-right: 30px"
      >
        <mat-label>Category:</mat-label>
        <mat-select
          (selectionChange)="onCategoryFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.subjectId"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option
            *ngFor="let category of categories"
            [value]="category.categoryId"
          >
            {{ category.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="padding-top: 1.34375em; margin-right: 30px"
        appearance="fill"
        class="upgrade"
      >
        <mat-label>Sub category:</mat-label>
        <mat-select
          (selectionChange)="onSubCategoryFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.subCategoryId"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option
            *ngFor="let sub of subCategories"
            [value]="sub.subCategoryId"
          >
            {{ sub.subCategoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="padding-top: 1.34375em"
        appearance="fill"
        class="upgrade"
      >
        <mat-label>Blood test:</mat-label>
        <mat-select
          (selectionChange)="onBloodTestFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.bloodTestTypeId"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option
            *ngFor="let bloodType of bloodeTestTypes"
            [value]="bloodType.bloodTestTypeId"
          >
            {{ bloodType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="callsReviews">
      <div style="position: relative">
        <div class="placeholder" style="left: -10px">Agent:</div>
        <app-autocomplete-agent
          class="autocomplete-agent"
          (selectedValue)="onAgentFilterChange($event)"
          [data]="agentsForAc"
          [initialValue]="getAgentNameById(headFilter.agentId)"
          [widthExp]="220"
          [label]="'Agent'"
          [direction]="'ltr'"
          [headFilter]="true"
        ></app-autocomplete-agent>
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <mat-form-field class="example-full-width regular-select">
        <div class="placeholder">Rating:</div>
        <mat-select
          (selectionChange)="onRatingFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.rating"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option [value]="0">Not Reated</mat-option>
          <mat-option [value]="1"
            ><span style="color: #119c99">&#9733;</span></mat-option
          >
          <!--stars -->

          <mat-option [value]="2"
            ><span style="color: #119c99">&#9733;&#9733;</span></mat-option
          >
          <!--stars -->
          <mat-option [value]="3"
            ><span style="color: #119c99"
              >&#9733;&#9733;&#9733;</span
            ></mat-option
          >
          <!--stars -->
          <mat-option [value]="4"
            ><span style="color: #119c99"
              >&#9733;&#9733;&#9733;&#9733;</span
            ></mat-option
          >
          <!--stars -->
          <mat-option [value]="5"
            ><span style="color: #119c99"
              >&#9733;&#9733;&#9733;&#9733;&#9733;</span
            ></mat-option
          >
          <!--stars -->
        </mat-select>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <!--spaces instead of margin right todo: fix -->
      <mat-form-field class="example-full-width regular-select placeholder-fix">
        <div class="placeholder">Subject:</div>
        <mat-select
          (selectionChange)="onCallSubjectFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.subjectId"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option
            *ngFor="let subject of subjects"
            [value]="subject.statusId"
            >{{ subject.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <!--spaces instead of margin right todo: fix -->
      <mat-form-field class="example-full-width regular-select placeholder-fix">
        <div class="placeholder">Type:</div>
        <mat-select
          (selectionChange)="onCallNoteTypeFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.noteTypeId"
        >
          <!-- formControlName="agentId"-->
          <mat-option [value]="-1" selected> All </mat-option>
          <mat-option
            *ngFor="let subject of subjects"
            [value]="subject.statusId"
            >{{ subject.name }}</mat-option
          >

          <mat-option [value]="4">Call</mat-option>
          <mat-option [value]="5">Chat</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <!--spaces instead of margin right todo: fix -->
    </ng-container>
    <ng-container *ngIf="classAttr == 'videosAttr'">
      <div class="regular-select video">
        <mat-form-field appearance="fill">
          <div class="placeholder">Category:</div>
          <mat-select
            (selectionChange)="onCategoryFilterChange($event)"
            disableOptionCentering
            [(ngModel)]="headFilter.categoryId"
          >
            <!-- formControlName="agentId"-->
            <mat-option [value]="-1" selected> All </mat-option>
            <mat-option
              *ngFor="let category of videoCategories"
              [value]="category.videoCategoryId"
            >
              {{ category.categoryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field
        class="example-full-width regular-select"
        style="width: 200px"
      >
        <div class="placeholder">Status:</div>
        <mat-select
          (selectionChange)="onStatusFilterChange($event)"
          matNativeControl
          placeholder="Select Status"
          disableOptionCentering
          [(ngModel)]="headFilter.statusId"
        >
          <mat-option [value]="-1"> All </mat-option>
          <mat-option [value]="1"> Published </mat-option>
          <mat-option [value]="0"> Unpublished </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="classAttr == 'barcodes'">
      <div class="regular-select video">
        <mat-form-field appearance="fill">
          <div class="placeholder">Lot:</div>
          <mat-select
            (selectionChange)="onCategoryFilterChange($event)"
            disableOptionCentering
            [(ngModel)]="headFilter.categoryId"
          >
            <mat-option [value]="0" selected> All </mat-option>
            <mat-option *ngFor="let lot of lots" [value]="lot.lotId">
              {{ lot.lotId }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field
        class="example-full-width regular-select"
        style="width: 200px"
      >
        <div class="placeholder">Status:</div>
        <mat-select
          (selectionChange)="onStatusFilterChange($event)"
          matNativeControl
          disableOptionCentering
          [(ngModel)]="headFilter.statusId"
        >
          <mat-option [value]="0"> All </mat-option>
          <mat-option
            *ngFor="let status of barcodeStatuses"
            [value]="status.id"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!algoBullet">
      <div *ngIf="requestsSubjects && classAttr == 'requestsAttr'">
        <mat-form-field
          class="example-full-width regular-select requestSubject"
        >
          <div class="placeholder">Subject:</div>
          <mat-select
            (selectionChange)="onRequestSubjectFilterChange($event)"
            matNativeControl
            disableOptionCentering
            [(ngModel)]="headFilter.subjectId"
          >
            <!-- formControlName="agentId"-->
            <mat-option [value]="allValue" selected> All </mat-option>
            <mat-option
              *ngFor="let subject of requestsSubjects"
              [value]="subject.statusId"
              >{{ subject.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="paymentMethods">
        <mat-form-field class="example-full-width regular-select pMethod">
          <div class="placeholder">Pay method:</div>
          <mat-select
            (selectionChange)="onPaymenetMethodFilterChange($event)"
            matNativeControl
            disableOptionCentering
            [(ngModel)]="headFilter.paymentMethodId"
          >
            <!-- formControlName="agentId"-->
            <mat-option [value]="-1" selected> All </mat-option>
            <mat-option
              *ngFor="let paymentMethod of paymentMethods"
              [value]="paymentMethod.paymentMethodId"
            >
              {{ paymentMethod.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          showAgentFilter() &&
          !callsReviews &&
          classAttr != 'office-all-deliveries' &&
          classAttr != 'lab-all-deliveries'
        "
      >
        <div
          style="position: relative"
          [ngClass]="{ nutrisionist: classAttr == 'productsAttr' }"
        >
          <div
            *ngIf="classAttr == 'productsAttr'"
            class="placeholder"
            style="left: -10px"
          >
            nutrisionist:
          </div>
          <div
            *ngIf="classAttr !== 'productsAttr'"
            class="placeholder"
            style="left: -10px"
          >
            Agent:
          </div>
          <app-autocomplete-agent
            class="autocomplete-agent"
            (selectedValue)="onAgentFilterChange($event)"
            [data]="agentsForAc"
            [initialValue]="getAgentNameById(headFilter.agentId)"
            [widthExp]="200"
            [label]="'Agent'"
            [direction]="'ltr'"
            [headFilter]="true"
          ></app-autocomplete-agent>
        </div>
      </div>

      <div *ngIf="classAttr == 'productsAttr'">
        <div style="position: relative" class="follow-up">
          <div style="left: -10px" class="placeholder">follow-up:</div>

          <app-autocomplete-agent
            class="autocomplete-agent"
            (selectedValue)="onAgent2FilterChange($event)"
            [data]="followUpAgents"
            [initialValue]="getFollowUpAgentNameById(headFilter.agent2Id)"
            [widthExp]="200"
            [label]="'Agent'"
            [direction]="'ltr'"
            [headFilter]="true"
          ></app-autocomplete-agent>
        </div>
      </div>

      <div *ngIf="classAttr == 'leadsAttr' && !callsReviews">
        <!-- <mat-form-field class="example-full-width regular-select">
          <div class="placeholder">Status:</div>
          <mat-select
            (selectionChange)="onStatusFilterChange($event)"
            matNativeControl
            placeholder="Select Status"
            disableOptionCentering
            [(ngModel)]="headFilter.statusId"
          >
            <mat-option [value]="-1"> All </mat-option>
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.leadStatusId"
              [ngStyle]="{ color: status.parentId ? '#FFFFFF' : '#000000' }"
              [ngStyle]="{ 'background-color': status.color }"
              >{{ status.name }}</mat-option
            >
          </mat-select>
        </mat-form-field> -->
        <button
          [matTooltip]="selectedStatusName"
          matTooltipPosition="above"
          class="statuses-menu"
          mat-button
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"
        >
          <div class="statuses-menu__text">
            <span class="name">Status: {{ selectedStatusName }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu overlayPanelClass="statuses-panel" #menu="matMenu">
          <ng-container *ngFor="let item of leadStatusesGroup">
            <ng-container *ngIf="item.childerns && item.childerns.length == 0">
              <button mat-menu-item (click)="onStatusFilterChangeNew(item)">
                {{ item.name }}
              </button>
            </ng-container>
            <ng-container *ngIf="item.childerns && item.childerns.length > 0">
              <button
                (click)="onStatusFilterChangeNew(item); menuTrigger.closeMenu()"
                mat-menu-item
                [matMenuTriggerFor]="childMenu"
              >
                {{ item.name }}
              </button>
              <mat-menu
                overlayPanelClass="statuses-panel"
                #childMenu="matMenu"
                [overlapTrigger]="false"
              >
                <button
                  mat-menu-item
                  (click)="onStatusFilterChangeNew(item)"
                  *ngFor="let item of item.childerns"
                  [ngStyle]="{
                    'background-color': item.color,
                    color: '#f9f9f9'
                  }"
                >
                  {{ item.name }}
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </mat-menu>
      </div>

      <div *ngIf="classAttr == 'clientsAttr' && !callsReviews">
        <mat-form-field class="example-full-width regular-select">
          <div class="placeholder">Status:</div>
          <mat-select
            (selectionChange)="onStatusFilterChange($event)"
            matNativeControl
            placeholder="Select Status"
            disableOptionCentering
            [(ngModel)]="headFilter.statusId"
          >
            <mat-option [value]="allValue"> All </mat-option>
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.leadStatusId"
              [ngStyle]="{ color: status.parentId ? '#FFFFFF' : '#000000' }"
              [ngStyle]="{ 'background-color': status.color }"
              >{{ status.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          (classAttr == 'dealsAttr' ||
            classAttr == 'productsAttr' ||
            classAttr == 'requestsAttr' ||
            classAttr == 'cancelationRequestAttr' ||
            classAttr == 'refundsAttr') &&
          !callsReviews
        "
      >
        <mat-form-field
          class="example-full-width regular-select"
          *ngIf="!algoBullet"
        >
          <div class="placeholder">Status:</div>
          <mat-select
            (selectionChange)="onStatusFilterChange($event)"
            matNativeControl
            placeholder="Select Status"
            disableOptionCentering
            [(ngModel)]="headFilter.statusId"
          >
            <!-- formControlName="statusId"-->
            <mat-option [value]="allValue"> All </mat-option>
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.statusId"
              [ngStyle]="{ 'background-color': status.color }"
              >{{ status.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="decisions && classAttr == 'cancelationRequestAttr'">
        <mat-form-field class="example-full-width regular-select decision">
          <div class="placeholder">Decision:</div>
          <mat-select
            (selectionChange)="onDecisionFilterChange($event)"
            matNativeControl
            placeholder="Select Decision"
            disableOptionCentering
            [(ngModel)]="headFilter.decisionId"
          >
            <mat-option [value]="-1"> All </mat-option>
            <mat-option
              *ngFor="let decision of decisions"
              [value]="decision.statusId"
              [ngStyle]="{ 'background-color': decision.color }"
              >{{ decision.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" class="example-full-width">
          <mat-label *ngIf="classAttr != 'cancelationRequestAttr'"
            >Enter a date range</mat-label
          >
          <mat-label *ngIf="classAttr == 'cancelationRequestAttr'"
            >Request Date</mat-label
          >
          <mat-date-range-input
            (dateInput)="onDateFilterChanged($event)"
            (changes)="onDateFilterChanged($event)"
            (dateChange)="onDateFilterChanged($event)"
            (selectedChanged)="onDateFilterChanged($event)"
            [formGroup]="range"
            [rangePicker]="picker"
            (click)="triggerToggleClick()"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
              (click)="triggerToggleClick()"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (click)="triggerToggleClick()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            id="toggle"
            #toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
          >
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>
      </div>

      <div style="width: 70px" *ngIf="!algoBullet">
        <button (click)="clearDate($event)">Clear</button>
      </div>

      <div *ngIf="classAttr == 'cancelationRequestAttr'">
        <mat-form-field appearance="fill">
          <mat-label>Purchase Date</mat-label>
          <mat-date-range-input
            (dateInput)="onDateFilterChangedSecondary($event)"
            (changes)="onDateFilterChangedSecondary($event)"
            (dateChange)="onDateFilterChangedSecondary($event)"
            (selectedChanged)="onDateFilterChangedSecondary($event)"
            [formGroup]="rangeSecondary"
            [rangePicker]="pickerSecondary"
            (click)="triggerToggleClickSecondary()"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
              (click)="triggerToggleClickSecondary()"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (click)="triggerToggleClickSecondary()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            id="toggleSecondary"
            #toggleSecondary
            matSuffix
            [for]="pickerSecondary"
          >
          </mat-datepicker-toggle>
          <mat-date-range-picker #pickerSecondary></mat-date-range-picker>

          <mat-error
            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
          >
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>
      </div>

      <div
        style="width: 70px"
        *ngIf="classAttr == 'cancelationRequestAttr' && !algoBullet"
      >
        <button (click)="clearDateSecondary($event)">Clear</button>
      </div>

      <div *ngIf="classAttr === 'lab-all-deliveries'">
        <mat-checkbox
          (change)="onShowClosedClicked($event)"
          class="example-margin"
          [checked]="isShowClosed()"
          >Show closed shipping deliveries</mat-checkbox
        >
      </div>

      <div
        *ngIf="
          classAttr != 'office-all-deliveries' &&
          classAttr != 'lab-all-deliveries' &&
          classAttr != 'requestsAttr' &&
          classAttr != 'cancelationRequestAttr' &&
          classAttr != 'refundsAttr' &&
          classAttr != 'productsAttr' &&
          classAttr != 'barcodes' &&
          !callsReviews
        "
      >
        <mat-form-field
          class="regular-select regular-select--source"
          *ngIf="classAttr == 'leadsAttr' || classAttr == 'clientsAttr'"
        >
          <div class="placeholder">Source:</div>
          <mat-select
            (selectionChange)="onSourceFilterChange($event)"
            [(ngModel)]="headFilter.sourceId"
            matNativeControl
            disableOptionCentering
            placeholder="Select Source"
          >
            <!-- placeholder="source name"-->
            <mat-option [value]="-1"> All </mat-option>
            <mat-option
              [value]="source.leadSourceId"
              *ngFor="let source of leadSources"
              >{{ source.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="classAttr == 'requestsAttr' && allValue !== 0">
        <mat-form-field class="regular-select regular-select--source">
          <div class="placeholder">Open by:</div>
          <mat-select
            (selectionChange)="onSourceFilterChange($event)"
            [(ngModel)]="headFilter.sourceId"
            matNativeControl
            disableOptionCentering
            placeholder="Select Source"
          >
            <!-- placeholder="source name"-->
            <mat-option [value]="-1"> All </mat-option>
            <mat-option [value]="-2"> Agent </mat-option>
            <mat-option [value]="-3"> Site </mat-option>
            <mat-option *ngFor="let agent of agents" [value]="agent.agentId">{{
              agent.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        *ngIf="
          (classAttr == 'requestsAttr' ||
            classAttr == 'cancelationRequestAttr' ||
            classAttr == 'barcodes' ||
            classAttr == 'dealsAttr') &&
          allValue !== 0
        "
      >
        <label
          style="margin-left: auto"
          for="navSearch"
          class="top-nav__search"
        >
          <input
            id="navSearch"
            type="text"
            [(ngModel)]="headFilter.searchTerm"
            (keyup.enter)="getAllWithFilter()"
          />
          <button
            id="searchTermBtn"
            (click)="getAllWithFilter()"
            type="button"
            class="top-nav__search__btn"
          ></button>
        </label>
      </div>

      <div *ngIf="classAttr == 'productsAttr'" style="width: 150px">
        <mat-form-field class="regular-select regular-select--lifestyle">
          <div class="placeholder">LifeStyle:</div>
          <mat-select
            (selectionChange)="onLifeStyleFilterChange($event)"
            [(ngModel)]="headFilter.lifeStyle"
            matNativeControl
            disableOptionCentering
            placeholder="Select Source"
          >
            <mat-option [value]="-1"> All </mat-option>
            <mat-option [value]="1"> Not Filled </mat-option>
            <mat-option [value]="2"> Filled </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="classAttr == 'productsAttr'" style="width: 150px">
        <mat-form-field class="regular-select regular-select--firstcall">
          <div class="placeholder">First Call:</div>
          <mat-select
            (selectionChange)="onFirstCallFilterChange($event)"
            [(ngModel)]="headFilter.firstCall"
            matNativeControl
            disableOptionCentering
            placeholder="Select first call"
          >
            <!-- placeholder="source name" -->
            <mat-option [value]="-1"> All </mat-option>
            <mat-option [value]="1"> Not Done </mat-option>
            <mat-option [value]="2"> Done </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="classAttr == 'productsAttr'" style="width: 150px">
        <mat-form-field class="regular-select regular-select--follow">
          <div class="placeholder">On follow:</div>
          <mat-select
            (selectionChange)="onFollowFilterChange($event)"
            [(ngModel)]="headFilter.onFollow"
            matNativeControl
            disableOptionCentering
            placeholder="Select Follow"
          >
            <!-- placeholder="source name" -->
            <mat-option [value]="-1"> All </mat-option>
            <mat-option [value]="1">No </mat-option>
            <mat-option [value]="2">Yes </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        style="margin-left: auto"
        *ngIf="classAttr == 'leadsAttr' && userHasPermisions(['Leads_Create'])"
      >
        <button class="createNew" (click)="moveToEditScreen()">
          Create New Lead
        </button>
      </div>
      <div
        *ngIf="classAttr == 'office-all-deliveries'"
        style="margin-left: auto"
      >
        <button class="createNew" (click)="createNewDelivery()">
          New Delivery
        </button>
      </div>
      <div
        *ngIf="classAttr == 'dealsAttr' && userHasPermisions(['Deals_Export'])"
        style="margin-left: auto"
      >
        <button class="createNew" (click)="exportDealToExel()">
          Export to Exel
        </button>
      </div>
      <div *ngIf="classAttr == 'productsAttr'" style="margin-left: auto">
        <button class="createNew" (click)="exportProductsToExel()">
          Export to Exel
        </button>
      </div>
      <div *ngIf="classAttr == 'barcodes'" style="margin-left: auto">
        <button class="createNew" (click)="exportBarcodesToExel()">
          Export to Exel
        </button>
      </div>
      <!--
      <div style="margin-left: auto;" *ngIf="classAttr == 'lab-all-deliveries'">
        <app-upload-results-csv
          [title]="'Upload Matching'"
          [uploadPath]="'matching'"
        ></app-upload-results-csv>
      </div>
      -->
      <!-- (click)="isLoading = true"-->

      <!-- <div
        style="
          margin-left: auto;
          width: auto;
          display: flex;
          align-items: center;
        "
        *ngIf="classAttr == 'lab-all-deliveries'"
      >
        <mat-form-field
          style="width: 130px; margin-right: 10px"
          class="regular-select regular-select--algoVersion"
        >
          <mat-select
            (selectionChange)="onAlgorithmVersionChange($event)"
            [(ngModel)]="algorithmVersion"
            matNativeControl
            disableOptionCentering
            placeholder="Select version"
            (selectionChange)="onSelectVersion()"
          >
            <mat-option [value]="2"> Hadasa </mat-option>
            <mat-option [value]="1"> Gga </mat-option>
          </mat-select>
        </mat-form-field>
        <app-upload-results-csv
          [class.disabled-upload]="!algorithmVersion"
          [title]="'Upload Data'"
          [uploadPath]="uploadResultsCsvPath"
          (onUploadFinished)="uploadDnaPdfsFinished($event)"
        ></app-upload-results-csv>
      </div> 

      <div *ngIf="classAttr == 'lab-all-deliveries'">
        <button (click)="goToUploadHistory()">Upload History</button>
      </div>-->

      <div class="video-buttons" *ngIf="classAttr == 'videosAttr'">
        <button
          class="edit-categories"
          style="margin-right: 1rem"
          (click)="openVideoCategoriesDialog()"
          mat-button
        >
          Edit categories
        </button>
        <button class="add-new-video" (click)="openNewVideoDialog()" mat-button>
          Upload new video
        </button>
      </div>
    </ng-container>
  </div>
</div>
