import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailTemplate } from '../data/EmailTemplate';
import { EmailTemplateMin } from '../data/EmailTemplateMin';
import { ErrorMessage } from '../data/ErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService {
  constructor(private http: HttpClient) {}

  post(emailTemplate: EmailTemplate) {
    return this.http.post<EmailTemplate>(
      `${environment.baseUrl}/EmailTemplates`,
      emailTemplate
    );
  }

  put(emailTemplate: EmailTemplate) {
    return this.http.put<EmailTemplate | ErrorMessage>(
      `${environment.baseUrl}EmailTemplates/${emailTemplate.emailTemplateId}`,
      emailTemplate
    );
  }

  editName(templateId: number, templateName: string) {
    return this.http.get<EmailTemplate>(
      `${environment.baseUrl}/EmailTemplates/${templateId}/${templateName}`
    );
  }

  getAllSms() {
    return this.http.get<EmailTemplate[]>(
      `${environment.baseUrl}EmailTemplates/Sms`
    );
  }
  getAllAutoSender() {
    return this.http.get<EmailTemplateMin[]>(
      `${environment.baseUrl}EmailTemplates/AutoSender`
    );
  }

  getById(templateId: number) {
    return this.http.get<EmailTemplate>(
      `${environment.baseUrl}EmailTemplates/${templateId}`
    );
  }

  getAllForLang(langId: number) {
    return this.http.get<EmailTemplate[]>(
      `${environment.baseUrl}EmailTemplates/ForLang/${langId}`
    );
  }

  delete(templateId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}EmailTemplates/${templateId}`
    );
  }
}
