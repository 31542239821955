import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { LaboratorySettings } from 'src/app/data/algorithm/LaboratorySettings';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType, EncodingEnum } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-labs-version-edit',
  templateUrl: './labs-version-edit.component.html',
  styleUrls: ['./labs-version-edit.component.scss'],
})
export class LabsVersionEditComponent implements OnInit {
  labSettings: LaboratorySettings;
  labVersion: LabVersion;
  settingsForm: FormGroup;
  versionForm: FormGroup;
  errorMessage: string;
  selectedDupVersionId: number;
  labs = [];
  fileTypes: BaseResponce[];
  pdfTemplates: BaseResponce[];
  filesEncoding = [
    { id: EncodingEnum.Ascii, name: EncodingEnum[EncodingEnum.Ascii] },
    { id: EncodingEnum.Utf8, name: EncodingEnum[EncodingEnum.Utf8] },
    {
      id: EncodingEnum.Windows1255,
      name: EncodingEnum[EncodingEnum.Windows1255],
    },
  ];
  rewriteSettings;
  versions: LabVersion[];
  formDataPdf: FormData;
  errorUploadMessage: string;
  constructor(
    private dialogRef: MatDialogRef<LabsVersionEditComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { versionId: number; langId: number },
    private algorithmService: AlgorithmService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.getLabWithVersions();
    if (this.data.versionId === 0) {
      this.labSettings = new LaboratorySettings();
      this.labSettings.pdfFile = new AppImage();
      this.getLabs();
      this.getLabFileTypes();
      this.getPdfTemplates();
    } else {
      this.getLabSettings();
    }
  }
  getLabSettings() {
    this.algorithmService
      .getVersionSetting(this.data.versionId)
      .subscribe((data) => {
        this.labSettings = data;
        if (!this.labSettings) return;
        if (this.labSettings && !this.labSettings?.pdfFile) {
          this.labSettings.pdfFile = new AppImage();
        }
        this.getLabs();
        this.getLabFileTypes();
        this.getPdfTemplates();
      });
  }
  getLabWithVersions() {
    this.algorithmService
      .getLabWithVersions(this.data.langId, 0)
      .subscribe((data) => {
        this.versions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.versions.push(y);
          });
        });
        this.labVersion = this.versions.find(
          (x) => x.labVersionId === this.data.versionId
        );
      });
  }
  getLabFileTypes() {
    this.algorithmService.getLabFileTypes().subscribe((data) => {
      this.fileTypes = data;
    });
  }
  getPdfTemplates() {
    this.algorithmService.getLabPdfTemplates().subscribe((data) => {
      this.pdfTemplates = data;
    });
  }
  getLabs() {
    this.algorithmService.getLabs(this.data.langId).subscribe((data) => {
      this.labs = data;
      this.createSettingsForm();
    });
  }
  createSettingsForm() {
    this.settingsForm = this.fb.group({
      versionName: this.fb.control(this.labVersion?.name, [
        Validators.required,
      ]),
      labId: this.fb.control(this.labVersion?.labId, [Validators.required]),
      fileTypeId: this.fb.control(
        this.labSettings.fileTypeId,
        Validators.required
      ),
      positionColumnNum: this.fb.control(
        this.labSettings.positionColumnNum,
        Validators.required
      ),
      rowDelimeter: this.fb.control(
        this.labSettings.rowDelimeter,
        Validators.required
      ),
      nAResultValue: this.fb.control(
        this.labSettings.naResultValue,
        Validators.required
      ),
      nAResultPos: this.fb.control(
        this.labSettings.naResultPos,
        Validators.required
      ),
      resultRegex: this.fb.control(
        this.labSettings.resultRegex,
        Validators.required
      ),
      chromosomeColumnNum: this.fb.control(
        this.labSettings.chromosomeColumnNum,
        Validators.required
      ),
      resultColumnNum: this.fb.control(
        this.labSettings.resultColumnNum,
        Validators.required
      ),
      fileEncodingTypeId: this.fb.control(
        this.labSettings.fileEncodingTypeId,
        Validators.required
      ),
      barCodeRegexPos: this.fb.control(
        this.labSettings.barCodeRegexPos,
        Validators.required
      ),
      firstResultPos: this.fb.control(
        this.labSettings.firstResultPos,
        Validators.required
      ),
      secondResultPos: this.fb.control(
        this.labSettings.secondResultPos,
        Validators.required
      ),

      pdfFile: this.fb.control(
        this.labSettings.pdfFile.fileId,
        Validators.required
      ),
    });
  }
  onUploadPdfTemplateChange(files) {
    if (files.length == 0) {
      this.formDataPdf = null;
      return;
    }

    this.formDataPdf = new FormData();
    this.formDataPdf.append('file', files[0]);
    this.uploadPdfTemplate();
  }

  uploadPdfTemplate() {
    this.algorithmService
      .uploadAlgoImage(
        this.formDataPdf,
        AlgoImageType.AlgoPdfTemplate,
        this.data.langId
      )
      .subscribe((data) => {
        this._snackBar.open('הועלה בהצלחה', 'סגור', {})._dismissAfter(2000);
        this.getPdfTemplates();
      });
  }

  onSubmit(form: FormGroup) {
    if (this.data.versionId > 0) {
      form.controls['versionName'].setErrors(null);
      form.controls['labId'].setErrors(null);
    }
    if (form.invalid) return;

    this.labSettings.fileTypeId = form.value.fileTypeId;
    this.labSettings.positionColumnNum = form.value.positionColumnNum;
    this.labSettings.rowDelimeter = form.value.rowDelimeter;
    this.labSettings.naResultValue = form.value.nAResultValue;
    this.labSettings.naResultPos = form.value.nAResultPos;
    this.labSettings.resultRegex = form.value.resultRegex;
    this.labSettings.chromosomeColumnNum = form.value.chromosomeColumnNum;
    this.labSettings.resultColumnNum = form.value.resultColumnNum;
    this.labSettings.fileEncodingTypeId = form.value.fileEncodingTypeId;
    this.labSettings.barCodeRegexPos = form.value.barCodeRegexPos;
    this.labSettings.firstResultPos = form.value.firstResultPos;
    this.labSettings.secondResultPos = form.value.secondResultPos;
    this.labSettings.pdfFile.fileId = form.value.pdfFile;
    if (this.data.versionId === 0) {
      this.algorithmService
        .createLabVersion(
          this.labSettings,
          this.data.langId,
          form.value.labId,
          form.value.versionName
        )
        .subscribe((data) => {
          //this.uploadPdfTemplate();
          if ((data as ErrorMessage)?.message) {
            this.errorUploadMessage = (data as ErrorMessage).message;
          } else {
            this.dialogRef.close({ type: 'add' });
          }
        });
    } else {
      this.algorithmService
        .setLaboratoryVersionSettings(
          this.labSettings,
          form.value.versionName,
          this.data.langId
        )
        .subscribe((data) => {
          //this.uploadPdfTemplate();
          if ((data as ErrorMessage)?.message) {
            this.errorUploadMessage = (data as ErrorMessage).message;
          } else {
            this.dialogRef.close({ type: 'add' });
          }
        });
    }
  }
}
