import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BulkChangeLeadAgent } from '../data/connectors/BulkChangeLeadAgent';

@Injectable({
  providedIn: 'root',
})
export class LeadsConnectorService {
  bulkChangeLeadAgentSubject: Subject<BulkChangeLeadAgent> = new Subject();

  constructor() {}

  bulkChangeLeadAgent(bulkChangeLeadAgent: BulkChangeLeadAgent) {
    this.bulkChangeLeadAgentSubject.next(bulkChangeLeadAgent);
  }
}
