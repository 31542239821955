<div class="wrapper">
  <div class="title">Auto Responder Templates</div>
  <div class="text-center">
    <button style="margin-left: 3rem" (click)="createNew()" mat-raised-button>
      create new template
    </button>
  </div>
  <table mat-table [dataSource]="emailTempaltes">
    <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <!-- Name Column -->
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let template">
        <img src="../../../../assets/document.svg" alt="" />
      </td>
    </ng-container>
    <ng-container matColumnDef="templateName">
      <th mat-header-cell *matHeaderCellDef>Template name</th>
      <td mat-cell *matCellDef="let template">
        {{ template.displayName }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="event">
      <th mat-header-cell *matHeaderCellDef>Event</th>
      <td mat-cell *matCellDef="let template">
        {{ template?.actionName }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="lastChange">
      <th mat-header-cell *matHeaderCellDef>Last change</th>
      <td mat-cell *matCellDef="let template">
        {{ template.updateDate | date: "dd-MM-yyyy HH:mm" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="goTo(row.emailTemplateId)"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</div>
