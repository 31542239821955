<div class="all-algo-bullets">
  <div class="text-center form-title">Export by groups</div>
  <form [formGroup]="bulletForm" *ngIf="bulletForm">
    <mat-card>
      <div class="genetic-header" [class.and-offset]="geneticFields.length > 1">
        <div *ngIf="geneticFields.length > 1"></div>
        <div>Parameters</div>
        <div>Condition</div>
        <div>Value</div>
        <div></div>
      </div>
      <div
        class="bb-nl"
        formArrayName="genetic"
        *ngFor="let a of geneticFields; let i = index; let first = first"
      >
        <div class="genetic-fields-wrapper">
          <div
            class="genetic-fields-wrapper__and"
            *ngIf="!first || geneticFields.length > 1"
          >
            <ng-container *ngIf="!first">AND</ng-container>
          </div>

          <div class="genetic-fields" [formGroupName]="i">
            <div>
              <mat-form-field class="upgrade" appearance="fill">
                <mat-label>Parameter</mat-label>
                <mat-select
                  matTooltip="{{ getSelectedValue(geneticSelect.value) }}"
                  #geneticSelect
                  formControlName="subCategoryId"
                  disableOptionCentering
                >
                  <mat-option
                    *ngFor="let subCategory of subCategories"
                    [value]="subCategory.subCategoryId"
                    >{{ subCategory.subCategoryName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="upgrade" appearance="fill">
                <mat-label>Condition</mat-label>
                <mat-select formControlName="conditionId">
                  <mat-option
                    *ngFor="let condition of algoConditions"
                    [value]="condition.statusId"
                  >
                    {{ condition.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="upgrade" appearance="fill">
                <mat-label>Value</mat-label>
                <mat-select formControlName="valueId">
                  <mat-option
                    *ngFor="let value of algoValues"
                    [value]="value.statusId"
                  >
                    {{ value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <button
                [disabled]="disableRemoveBtn() || isLoading"
                (click)="removeGeneticItem(i)"
                type="button"
                mat-icon-button
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          [disabled]="isLoading"
          (click)="addGeneticItem()"
          type="button"
          class="add-btn"
          mat-button
        >
          Add parameter
        </button>
      </div>
    </mat-card>
    <div class="text-center">
      <button
        [disabled]="isLoading"
        (click)="exportToExel()"
        class="save-btn"
        mat-button
      >
        <ng-container *ngIf="isLoading">
          Exporting
          <mat-icon> <mat-spinner diameter="20"></mat-spinner></mat-icon>
        </ng-container>
        <ng-container *ngIf="!isLoading">Export To Exel</ng-container>
      </button>
    </div>
  </form>
</div>
