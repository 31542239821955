import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Client } from 'src/app/data/Client';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { Router } from '@angular/router';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit {
  @Input() clientsData: ClientForTableMin[];
  @Input() leadStatuses: LeadStatus[];
  @Input() leadSources: any;
  @Input() agents: any;
  @Input() headFilter: HeadFilter;
  @Input() isLoading;
  @Output('filterChanged') filterChanged: EventEmitter<HeadFilter> =
    new EventEmitter();
  headers = [
    { name: 'Full Name', val: 'Name' },
    { name: 'ID', val: 'ID' },
    { name: 'Created Date', val: 'RegisteredDate' },
    { name: 'Email', val: 'Email' },
    { name: 'Tel', val: 'Tel' },
    /*
    { name: "Source", val: "Source" },
    { name: "Agent", val: "Agent" },*/
    { name: 'Status', val: 'Status' },
    { name: '', val: '' },
    /*
    { name: "Client Type", val: "ClientType" },
    */
  ];
  regularUser: RegularUser;
  constructor(
    private router: Router,
    private regularUsersService: RegularUsersService
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }

  ngOnInit(): void {}
  moveToEditScreen(clientId = -1) {
    this.router.navigate(['/clients/edit/'], {
      queryParams: { clientId: clientId },
    });
  }
  callClientPhone(phone) {
    //  window.open('tel:' + phone);
  }
  editClient(event, clientItem: Client) {
    this.moveToEditScreen(clientItem.clientId);
  }
  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }
  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }
  showAgentFilterNoManager(agentId = null) {
    return (
      agentId == this.regularUser.agent.agentId ||
      userHasPermisionsWithRule(['Clients_Update'], this.regularUser)
    );
  }
}
