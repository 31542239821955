import { FoodNutrient } from './FoodNutrient';
import { Language } from './Language';

export class FoodNutrientLang {
  foodNutrienLangId: number;
  foodNutrientId: number;
  foodNutrient: FoodNutrient;
  languageId: number;
  language: Language;
  name: string;
}
