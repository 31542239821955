<div>
  <form
    [formGroup]="detailsForm"
    (ngSubmit)="copyDetailsFromClient()"
    class="example-form"
  >
    <button class="btn-rect btn-rect--client">Copy Details From Client</button>
    <div class="row-container" *ngIf="paymentFormReady">
      <span>Personal details: </span>
      <br />
      <!-- <hr style="border: 1px solid #d2eceb;" />-->
      <mat-list-item class="row-container__item">
        <div class="row-container__item__cc">
          <!--
              <mat-error *ngIf="false">
                Card Number
              </mat-error>
              -->
          <mat-form-field class="example-full-width">
            <input
              class="edit-field"
              matInput
              formControlName="fullName"
              placeholder="Full Name"
            />
          </mat-form-field>
          <br />
          <mat-form-field class="example-full-width">
            <input
              class="edit-field"
              matInput
              formControlName="phoneNumber"
              placeholder="Phone Number"
            />
          </mat-form-field>
          <br />
          <span>Adress</span>
          <br />
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 1rem;
              margin-top: 1.5rem;
            "
          >
            <app-autocomplete-address
              style="border: 1px solid #000"
              [initialValue]="city.value"
              [label]="'City'"
              [data]="mapCities(citiesResults$ | async)"
              (selectedValue)="onCitySelected($event)"
              [widthExp]="'200'"
              (searchTerm)="searchTermCity$.next($event)"
            ></app-autocomplete-address>
            <app-autocomplete-address
              style="border: 1px solid #000"
              [initialValue]="street.value"
              [label]="'Street'"
              [data]="mapStreets(streetsResults$ | async)"
              (selectedValue)="onStreetSelected($event)"
              [widthExp]="'200'"
              (searchTerm)="searchTermStreet$.next($event)"
            ></app-autocomplete-address>
          </div>
          <!-- <div style="display: flex">
            <mat-form-field class="example-full-width classThisOne">
              <input
                (keyup)="onCitiesAutocompleteChanged($event)"
                class="edit-field"
                type="text"
                placeholder="City"
                aria-label="Number"
                matInput
                formControlName="city"
                [matAutocomplete]="auto"
                style="text-align: right; direction: rtl"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onCitySelected($event)"
              >
                <mat-option
                  style="text-align: right"
                  *ngFor="let city of filteredCitiesOptions"
                  [value]="city.name"
                >
                  {{ city.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
              class="example-full-width classThisTwo"
              style="margin-left: 20px"
            >
              <input
                (keyup)="onStreetsAutocompleteChanged($event)"
                class="edit-field"
                type="text"
                placeholder="Street"
                aria-label="Number"
                matInput
                formControlName="street"
                [matAutocomplete]="auto1"
                style="text-align: right; direction: rtl"
              />
              <mat-autocomplete
                #auto1="matAutocomplete"
                (optionSelected)="onSteetSelected($event)"
              >
                <mat-option
                  style="text-align: right"
                  *ngFor="let street of filteredStreetsOptions"
                  [value]="street.name"
                >
                  {{ street.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
          <div class="d-flex-between">
            <mat-form-field style="margin-right: 1rem; width: 100px">
              <input
                placeholder="Street Number"
                class="edit-field"
                matInput
                formControlName="streetNum"
              />
            </mat-form-field>
            <mat-form-field style="margin-right: 1rem; width: 100px">
              <input
                type="number"
                placeholder="Entrence"
                class="edit-field"
                matInput
                formControlName="entrance"
              />
            </mat-form-field>
            <mat-form-field style="margin-right: 1rem; width: 100px">
              <input
                placeholder="Apartment"
                class="edit-field"
                matInput
                formControlName="apartment"
              />
            </mat-form-field>
            <!--
                        <mat-form-field style="margin-right: 1rem;">
                          <input placeholder="City" class="edit-field" matInput formControlName="city" />
                        </mat-form-field>
                      -->
            <!--
                        <mat-form-field style="margin-right: 1rem;">
                          <input placeholder="Country" class="edit-field" matInput formControlName="country" />
                        </mat-form-field>
                        -->
          </div>

          <!--
                    <mat-form-field class="example-full-width" style="width:25%">
                        <input class="edit-field" matInput formControlName="street" placeholder="Street" />

                    </mat-form-field>
                    <mat-form-field class="example-full-width" style="width:25%">
                        <input class="edit-field" matInput formControlName="city" placeholder="City" />

                    </mat-form-field>

                    <mat-form-field class="example-full-width" style="width:25%">
                        <input class="edit-field" matInput formControlName="country" placeholder="Country" />
                    </mat-form-field>-->
          <br />
          <mat-form-field class="example-full-width">
            <input
              class="edit-field"
              matInput
              formControlName="email"
              placeholder="Email"
            />
          </mat-form-field>
          <br />
          <mat-form-field class="example-full-width">
            <input
              class="edit-field"
              matInput
              formControlName="companyName"
              placeholder="Company Name"
            />
          </mat-form-field>
        </div>
        <div class="form-error" *ngIf="false">Please Fill Payment Details</div>
        <br />
      </mat-list-item>
    </div>
  </form>
  <br />
</div>
<div class="purchase-action">
  <button class="purchase-action__btn" (click)="completePurchase()">
    Next
  </button>
  <button class="purchase-action__btn" (click)="cancelPurchase()">
    Cancel
  </button>
</div>
