import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentMin } from 'src/app/data/AgentMin';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { TubeTest } from 'src/app/data/TubeTest';
import { TubeTestMinPackage } from 'src/app/data/TubeTestMinPackage';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  ProductEnum,
  TubePackageEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { SendMailService } from 'src/app/Services/send-mail.service';
import { TubeTestsPusherService } from 'src/app/Services/tube-tests-pusher.service';
import { TubeTestsService } from 'src/app/Services/tube-tests.service';
import { TubesPackagesService } from 'src/app/Services/tubes-packages.service';

@Component({
  selector: 'app-edit-tube-package-office',
  templateUrl: './edit-tube-package-office.component.html',
  styleUrls: ['./edit-tube-package-office.component.scss'],
})
export class EditTubePackageOfficeComponent implements OnInit {
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  myControl = new FormControl();
  packageId: number;
  packageTubes: TubeTest[];
  newBarcode: string;
  tubePackage: TubesPackage;
  barcodes: string[] = [];
  errorMessage: ErrorMessage;
  arrivedToLab;
  nutritionists: AgentMin[];
  selectedTubes: number[];
  sendComplete: boolean;
  isLoading: boolean = false;
  globalParam: GlobalParam[];
  packageLimit: number;
  constructor(
    private route: ActivatedRoute,
    private tubeTestService: TubeTestsService,
    private tubeTestPusherService: TubeTestsPusherService,
    private tubesPackagesService: TubesPackagesService,
    private sendMailService: SendMailService,
    private router: Router,
    private agentsService: AgentsService,
    private productsService: ProductsService,
    private popupHandlerService: PopupHandlerService,
    private globalParamsService: GlobalParamsService
  ) {
    /*
    this.tubePackage.createDate;
    this.tubePackage.tubesPackageId;
    this.tubePackage.status.name;
    */
  }
  ngOnInit(): void {
    this.globalParamsService.getAll().subscribe((res) => {
      this.globalParam = res;
      this.packageLimit = +this.globalParam.find((x) => x.key == 'PackageLimit')
        .value;
    });
    this.agentsService
      .getAll()
      .subscribe(
        (data) =>
          (this.nutritionists = data.filter(
            (x) =>
              x.roleTypeId == UserRoleEmum.nutritionist ||
              x.roleTypeId == UserRoleEmum.nutritionistManager
          ))
      );
    this.tubeTestService
      .getBarcodesAutocompleteForOffice()
      .subscribe((data) => (this.barcodes = data));

    this.tubeTestPusherService.tubeTest$.subscribe((data) => {
      this.packageTubes = data.list;
      this.metaData = data.metaData;
    });

    this.tubeTestPusherService.errors$.subscribe((err) => {
      this.errorMessage = err;
    });
    this.route.queryParams.subscribe((params) => {
      this.packageId = +params['packageId'];

      this.getPackage();

      this.tubeTestPusherService.getAll(this.packageId);
    });
    this.tubeTestService
      .getBarcodesAutocompleteForOffice()
      .subscribe((data) => (this.barcodes = data));
  }

  getPackage() {
    this.tubesPackagesService
      .getById(this.packageId)
      .subscribe((tubePackage) => {
        this.tubePackage = tubePackage;
        //   this.packageTubes = tubePackage.tubeTests;
      });
  }

  onTubeSelected(tubes: TubeTestMinPackage[]) {
    this.selectedTubes = tubes
      .map((tube) => tube.product)
      .map((product) => product.productId);
  }

  sendSelectedTubesResults() {
    this.isLoading = true;
    var statusId = ProductEnum.Completed;
    this.productsService
      .bulkChangeStatus(this.selectedTubes, statusId)
      .subscribe((actionResponses) => {
        var txt = actionResponses
          .map(
            (res) =>
              `<span class="${res.isSuccess ? 'color-green' : 'color-red'}">${
                res.actionName
              }  ${res.message}</span>`
          )
          .join('<br/>');
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.actionResults,
          '',
          '',
          txt,
          25000 * actionResponses.length
        );
        this.isLoading = false;
        this.tubeTestPusherService.getAll(this.packageId);
        this.selectedTubes = [];
        this.sendComplete = true;
      });
  }
  addTube() {
    if (this.packageTubes?.length > this.packageLimit) {
      this.popupHandlerService.openDisapearingAlertDialog(
        `You reach the maximum limit to this package (${this.packageTubes.length})`
      );
    } else {
      this.tubeTestPusherService.addTube(this.packageId, this.newBarcode);
    }
  }
  sendLifestyleReportMail() {
    this.sendMailService
      .sendLifeStyleReportMail(this.packageId)
      .subscribe((res) => {});
  }
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {}
  closePackage() {
    this.tubePackage.statusId = TubePackageEnum.Closed;
    this.tubeTestPusherService.closePackage(this.tubePackage);
  }
  showClosePackageBtn() {
    return this.tubePackage.statusId == TubePackageEnum.Draft;
  }
  backToAll() {
    this.router.navigate(['/labDeliveries/office']);
  }
  changeArivedToLab() {
    this.tubePackage.ArivedToLabDate = this.arrivedToLab;
    this.tubesPackagesService.put(this.tubePackage).subscribe((data) => {});
  }
}
