export class FgVersion {
  fgVersionId: number;
  fgVersionName: string;
  versionResultParamId: number;
  fgVersionStatus: number;
  isLock: boolean;
  createdDate: string;
  labVersionId: number;
  labVersionName: string;
  resultFunctionName: string;
}
