<div class="alert-dialog">
  <div class="alert-dialog__title">{{ title }}</div>
  <div [ngSwitch]="type">
    <div *ngSwitchCase="AlertDialogTypeEnum.videoCategories">
      It's not possible to delete this category because is associated with more
      then one videos. only empty categories can be deleted
    </div>
    <div *ngSwitchDefault>output2</div>
  </div>
</div>
<div class="text-center mt-2">
  <button type="button" class="close" mat-button mat-dialog-close>Close</button>
</div>
