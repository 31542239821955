import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { IndicatorMsg } from 'src/app/data/indicator-msg';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import {
  RequestEnum,
  ServiceRequestSubjectEnum,
} from 'src/app/Enums/StatusesEnum';

import { FirebaseAnonimousAuthService } from 'src/app/Services/firebase-anonimous-auth.service';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestsService } from 'src/app/Services/service-requests.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit, OnDestroy {
  openedChatList: IndicatorMsg[] = [];
  closedChatList: any[] = [];
  serviceRequests: RequestForTableMin[];
  filterdChatList: any[] = [];
  agentUser: RegularUser;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  loadingList: boolean;
  docId: number;
  clientName: any;
  isClosed: boolean;
  selected = -1;
  filterTypes: any[] = [
    { letter: 'S', value: 108, color: '#D3247E', checked: true },
    { letter: 'F', value: 111, color: '#8700D4', checked: true },
    { letter: 'L', value: 109, color: '#FF6F00', checked: true },
    { letter: 'N', value: 110, color: '#00AEEE', checked: true },
  ];
  showClosed: 'opened' | 'closed' = 'opened';
  subjectLetter: string;
  clientAvatar: any;
  agentLetter: string;
  firstKey: any;
  listToCompare: any;
  firstKeyToCompare: any;
  chatListOldLength: number;
  chatListOldToCompare: any[];
  chatListOld: any;
  doc: any;
  isCalled: boolean = false;
  serviceRequestsData: RequestForTableMin[];
  chatListRawData: any;
  chatListClosedRawData: any;
  docIdInt: number;
  chatListSubscription: any;
  closingInProgress: boolean;
  isFetching: boolean;
  closedPageNum: number = 0;
  constructor(
    private anonAuthService: FirebaseAnonimousAuthService,
    private regularUsersService: RegularUsersService,
    private serviceRequestsService: ServiceRequestsService,
    private firebaseService: FirebaseChatIndicatorService
  ) {}
  ngOnDestroy(): void {
    this.doc = null;
    this.docId = null;
    if (this.chatListSubscription) this.chatListSubscription.unsubscribe();
  }

  ngOnInit(): void {
    //console.log('ChatListComponent OnInit');

    this.regularUsersService.currentUser.subscribe((data) => {
      this.agentUser = data;
    });
    this.serviceRequestsService.getChatList().subscribe((data) => {
      this.serviceRequestsData = data;

      this.renderList('onInit');
    });

    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (!data) return;

      if (this.isCalled) return;
      this.isCalled = true;
      this.loadingList = true;
      this.chatListSubscription = this.firebaseService
        .getOpened()
        .subscribe((res) => {
          //console.log('res: ', res);
          if (!res) {
            this.loadingList = false;
            return;
          }
          this.openedChatList = [];

          /* if (this.chatListOld && res.length > this.chatListOld.length) {
            this.loadServiceRequest();
          } */

          this.chatListRawData = res;
          this.renderList('firebase');
          this.renderClosedList();
          //this.chatListOld = res;

          this.loadingList = false;
        });
    });

    /* */
  }

  onScroll(event) {
    if (event) {
      this.getClosePagination();
      /*   setTimeout(() => {
        this.isFetching = false;
      }, 500); */
    }
    console.log('closedPageNum: ', this.closedPageNum);
  }

  /*  callRenderList() {
    if (!this.chatList || !this.serviceRequestsData) return;
    this.serviceRequests = this.renderList(this.chatList);
  } */

  loadServiceRequest() {
    this.serviceRequestsService.getChatList().subscribe((data) => {
      this.serviceRequestsData = data;
      this.renderList('api');
      this.renderClosedList();
    });
  }

  renderList(param: string): any {
    if (!this.chatListRawData || !this.serviceRequestsData) return;
    this.openedChatList = this.chatListRawData
      .map((x) => {
        var key = x.payload.doc.id;
        var val: any = x.payload.doc.data();
        val.key = key;

        this.serviceRequestsData.map((sr) => {
          if (parseInt(val.key) == sr.serviceRequestId) {
            val.isDisplayNameFound = true;
            val.displayName = sr.fullName;
          }
        });
        return val;
      })

      .filter((chat) => !chat.isGQ)
      .sort((a, b) => {
        var x = moment(a.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
        var y = moment(b.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
        return x > y ? -1 : 1;
      });

    this.openedChatList.forEach((x) => {
      if (!x.isDisplayNameFound) {
        x.displayName = x.clientName;
      }
    });

    this.filterdChatList = [...this.openedChatList];
    this.closingInProgress = false;
  }
  renderClosedList(): any {
    if (!this.chatListClosedRawData || !this.serviceRequestsData) return;
    this.closedChatList = this.chatListClosedRawData
      .map((x) => {
        var key = x.payload.doc.id;
        var val: any = x.payload.doc.data();
        val.key = key;

        this.serviceRequestsData.map((sr) => {
          var valKey = parseInt(val.key);
          if (valKey === sr.serviceRequestId) {
            val.displayName = sr.fullName;
          }
        });
        return val;
      })
      .filter((chat) => !chat.isGQ)
      .sort((a, b) => {
        var x = moment(a.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
        var y = moment(b.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
        return x > y ? -1 : 1;
      });
    //console.log('closedChatList: ', this.closedChatList);
  }

  getClosed(e) {
    this.closedPageNum = 0;

    this.showClosed = e.value;
    this.doc = null;
    this.docId = null;
    if (this.showClosed === 'closed') {
      this.firebaseService.getClosed().subscribe((data) => {
        this.chatListClosedRawData = data;
        this.renderClosedList();

        this.filterdChatList = [...this.closedChatList];

        //console.log('filterdChatList: ', this.filterdChatList);
      });
    } else {
      this.filterdChatList = [...this.openedChatList];
    }
  }

  getClosePagination() {
    this.isFetching = true;
    this.closedPageNum++;
    this.firebaseService.getClosed().subscribe((data) => {
      this.chatListClosedRawData = data;
      this.renderClosedList();

      this.filterdChatList = [...this.closedChatList];
      this.isFetching = false;
    });
  }

  onRowClick(item: any) {
    //this.rowClick.emit(item);
    this.doc = item;
    this.docId = this.doc.key;
    this.docIdInt = +this.doc.key;
    this.clientName = this.doc.displayName;
    this.isClosed = this.doc.isClosed;
    this.clientAvatar = this.doc.avatarClientId;
    this.agentLetter = this.getSubjectFromEnum(this.doc.subjectId).letter;
  }

  searchChatList(event) {
    var value = event.target.value;
    this.loadingList = true;
    if (value !== '') {
      this.filterdChatList = this.filterdChatList.filter(
        (chat: IndicatorMsg) => {
          if (chat.clientName.includes(value)) {
            //this.loadingList = false;
            return chat;
          } else {
            //this.loadingList = false;
            if (this.showClosed === 'opened') {
              this.filterdChatList = [...this.openedChatList];
            } else {
              this.filterdChatList = [...this.closedChatList];
            }
          }
        }
      );
    } else {
      //this.loadingList = false;
      if (this.showClosed === 'opened') {
        this.filterdChatList = [...this.openedChatList];
      } else {
        this.filterdChatList = [...this.closedChatList];
      }
    }
  }

  onFilterChange() {
    var filterTypeIds = this.filterTypes
      .filter((x) => x.checked == true)
      .map((y) => y.value);

    if (this.showClosed === 'opened') {
      this.filterdChatList = [...this.openedChatList];
    } else {
      this.filterdChatList = [...this.closedChatList];
    }
    this.filterdChatList = this.filterdChatList.filter((x) => {
      return filterTypeIds.includes(x.subjectId);
    });
  }

  getAgentHat() {
    if (userHasPermisions(['Chat Hat Support'], this.agentUser)) return 'S';
    else if (userHasPermisions(['ChatHatNutrition_GetList'], this.agentUser))
      return 'N';
    else if (userHasPermisions(['ChatHatLogistics_GetList'], this.agentUser))
      return 'L';
    else if (userHasPermisions(['Chat Hat Finance_GetList'], this.agentUser))
      return 'F';
    else return 'S';
  }

  getSubjectFromEnum(subjectId) {
    switch (subjectId) {
      case ServiceRequestSubjectEnum.CustomerService:
      case ServiceRequestSubjectEnum.UserNameOrPassword:
        return {
          letter: 'S',
          color: '#D3247E',
        };
      case ServiceRequestSubjectEnum.Finance:
        return {
          letter: 'F',
          color: '#8700D4',
        };
      case ServiceRequestSubjectEnum.Logistics:
        return {
          letter: 'L',
          color: '#FF6F00',
        };
      case ServiceRequestSubjectEnum.Nutrition:
        return {
          letter: 'N',
          color: '#00AEEE',
        };
    }
  }

  getAgentName(): string {
    var firstName = '';
    var lastName = '';
    if (this.agentUser.firstName) firstName = this.agentUser.firstName;
    if (this.agentUser.lastName) lastName = this.agentUser.lastName;
    if (lastName === '') return firstName;
    if (firstName === '') return lastName;
    return `${firstName} ${lastName}`;
  }

  setChatToNotActive() {
    this.closingInProgress = true;
    this.serviceRequestsService.getChatList().subscribe((data) => {
      this.serviceRequestsData = data;

      var docId = +this.docId;

      var activeRequst = this.serviceRequestsData.find(
        (data) => data.serviceRequestId == docId
      );

      if (activeRequst) {
        activeRequst.statusId = RequestEnum.Closed;
        this.serviceRequestsService.putMin(activeRequst).subscribe(() => {
          this.closeRequestInFirebase();
        });
      } else {
        //console.log('setChatToNotActive: activeRequst is undefined');
        this.closeRequestInFirebase();
      }
    });
  }
  closeRequestInFirebase() {
    this.firebaseService.closeRequest('chatIndicator', `${this.docId}`);
    this.getClosed({ e: { value: 'closed' } });
    this.showClosed = 'opened';
    this.doc = null;
    this.docId = null;
  }
  getServiceRequestForChat() {
    if (this.serviceRequestsData) {
      var selectedServiceRequest = this.serviceRequestsData.find(
        (x) => x.serviceRequestId == +this.docId
      );
      return selectedServiceRequest;
    }
  }
}

/* if (
      chat.regularUserId !=
      this.activeRequest.product.patient.regularUser.regularUserId
    ) {
      return !chat.seenBy ||
        !chat.seenBy.includes(
          this.activeRequest.product.patient.regularUser.regularUserId
        )
        ? 'not seen'
        : 'seen';
    } else return ''; */
