import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Food } from 'src/app/data/Food';
import { Language } from 'src/app/data/Language';
import { Meal } from 'src/app/data/Meal';
import { MealBullet } from 'src/app/data/MealBullet';
import { MealBulletOption } from 'src/app/data/MealBulletOption';
import { MealToNutrient } from 'src/app/data/MealToNutrient';
import { NameAndId } from 'src/app/data/NameAndId';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  FoodNutrientsZameret,
  FoodPublished,
  FoodWithRecipes,
  NutrientType,
} from 'src/app/Enums/StatusesEnum';
import { FoodLangsService } from 'src/app/Services/food-langs.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { MealsService } from 'src/app/Services/meals.service';
import { NutriMenuService } from 'src/app/Services/nutri-menu.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { environment } from 'src/environments/environment';
import { autocomplete } from '../../../Utils/autocomplete';

@Component({
  selector: 'app-menu-bullets',
  templateUrl: './menu-bullets.component.html',
  styleUrls: ['./menu-bullets.component.scss'],
})
export class MenuBulletsComponent implements OnInit {
  @Output('mealChanged')
  mealChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() mealId: number;
  @Input() saveBullets: boolean;
  @Input() productId: string;
  @Input() languages: Language[];
  @Input() canEditMenu: boolean;
  @Input() mealTypes: Status[];
  @Input() isTemplate: boolean;

  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
  };
  isRtl: boolean;
  meal: Meal;
  mealNutrients;
  mealVitamins;
  mealCalories;
  mealGoalCalories;
  langId: number = 1;
  foods: NameAndId[];
  value: number = 45;
  validbullets: boolean = true;
  isSubmiting: boolean = false;
  isLoading: boolean;
  autoCompleteActiveFoodId: number;
  autoCompleteActiveCounter = 1;

  searchTermFood$ = new BehaviorSubject<string>('');
  foodsResults$: Observable<Food[]> = this.searchTermFood$.pipe(
    autocomplete(800, (term: string) => {
      if (this.productId) {
        return this.foodLangsService.searchByNameForProduct(
          term,
          this.langId,
          FoodWithRecipes.Both,
          +this.productId
        );
      } else {
        return this.foodLangsService.SearchByName(
          term,
          this.langId,
          environment.foodSourceKey,
          FoodWithRecipes.Both,
          FoodPublished.Published
        );
      }
    })
  );
  calculateGeneticRate: boolean;

  constructor(
    private foodLangsService: FoodLangsService,
    private popupHandlerService: PopupHandlerService,
    private mealsService: MealsService,
    private menusService: NutriMenuService,
    private globalParamsService: GlobalParamsService
  ) {}

  ngOnInit(): void {
    this.isRtl = this.languages.find((x) => x.languageId == this.langId).isRtl;
    this.getMeal();
    this.getGlobalParams();
  }

  searchFood(term: string) {
    if (this.productId) {
      autocomplete(800, (term: string) => {
        return this.foodLangsService.searchByNameForProduct(
          term,
          this.langId,
          FoodWithRecipes.Both,
          +this.productId
        );
      });
    } else {
      autocomplete(800, (term: string) => {
        return this.foodLangsService.SearchByName(
          term,
          this.langId,
          environment.foodSourceKey,
          FoodWithRecipes.Both,
          FoodPublished.Published
        );
      });
    }
  }
  getGlobalParams() {
    this.globalParamsService.getAll().subscribe((data) => {
      this.calculateGeneticRate =
        data.find((x) => x.globalParamsId == 15).value === 'false' //CalculateGeneticRate
          ? false
          : true;
    });
  }
  setAutoCompleteActive(foodId) {
    this.autoCompleteActiveFoodId = foodId;
  }
  getMealName() {
    return this.mealTypes?.find((x) => x.statusId == this.meal.mealTypeId)
      ?.name;
  }
  getMeal() {
    this.isLoading = true;
    this.mealsService
      .getByMealId(this.mealId, this.productId ? +this.productId : -1)
      .subscribe((data) => {
        this.meal = data;
        /*
        this.mealNutrients = this.meal.mealToNutrients.filter(
          (x) => x.foodNutrient.nutrientTypeId == NutrientType.nutrients
        );
        this.mealVitamins = this.meal.mealToNutrients.filter(
          (x) => x.foodNutrient.nutrientTypeId == NutrientType.vitamins
        );
          */
        this.isLoading = false;
        if (this.meal.mealBullets.length == 0) {
          this.addNewBullet();
        }
      });
  }

  geneticRateChanged() {
    this.mealChanged.emit(true);
  }

  onNotesChange() {
    this.mealChanged.emit(true);
  }

  addNewBulletOption(bulletIndex) {
    let mealBulletOption = new MealBulletOption();
    mealBulletOption.foodId = --this.autoCompleteActiveCounter;
    this.meal.mealBullets[bulletIndex].mealBulletOptions.push(mealBulletOption);
  }

  addNewBullet() {
    let mealBullet = new MealBullet();
    mealBullet.mealId = this.meal.mealId;

    let mealBulletOption = new MealBulletOption();
    mealBulletOption.foodId = --this.autoCompleteActiveCounter;
    mealBullet.mealBulletOptions = [mealBulletOption];

    this.meal.mealBullets.push(mealBullet);
  }

  disableRemoveBtn(bulletIndex: number) {
    if (this.meal && this.meal.menu && this.meal.menu.isComplete) return true;
    if (this.meal.mealBullets[bulletIndex].mealBulletOptions.length > 1) {
      return false;
    }
    if (this.meal.mealBullets.length == 1) {
      return true;
    }
  }

  removeOption(bulletIndex: number, optionIndex: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.meal.mealBullets[bulletIndex].mealBulletOptions =
            this.meal.mealBullets[bulletIndex].mealBulletOptions.filter(
              (item, index) => index !== optionIndex
            );
          if (
            this.meal.mealBullets[bulletIndex].mealBulletOptions.length == 0
          ) {
            this.meal.mealBullets.splice(bulletIndex, 1);
          }
        }
      });
  }

  getCalories(
    foodId: number,
    foodScaleId: number,
    quantity: number,
    bulletIndex: number,
    optionIndex: number
  ) {
    this.mealsService
      .getCalories(foodId, foodScaleId, quantity)
      .subscribe((data) => {
        this.meal.mealBullets[bulletIndex].mealBulletOptions[
          optionIndex
        ].calories = data.calories;
        this.meal.mealBullets[bulletIndex].mealBulletOptions[
          optionIndex
        ].weight = data.weight;
      });
  }

  getFoodTypes(query: string) {
    this.foodLangsService
      .SearchByName(
        query,
        this.langId,
        environment.foodSourceKey,
        FoodWithRecipes.Both,
        FoodPublished.Published
      )
      .subscribe((data) => {
        this.foods = data;
      });
  }

  onFoodSelected(food: any, bulletIndex: number, optionIndex: number) {
    this.meal.mealBullets[bulletIndex].mealBulletOptions[optionIndex].foodId =
      food.id;
    this.meal.mealBullets[bulletIndex].mealBulletOptions[optionIndex].foodName =
      food.name;
    this.popupHandlerService.openFoodScaleDialog(
      food.id,
      this.langId,
      null,
      null
    );
    this.listenToFoodScaleDialog(bulletIndex, optionIndex);
  }

  openUnitScaleDialog(
    bulletIndex: number,
    optionIndex: number,
    foodId: number,
    foodScaleId: number,
    quantity: number,
    calories: number = null
  ) {
    this.popupHandlerService.openFoodScaleDialog(
      foodId,
      this.langId,
      foodScaleId,
      quantity,
      calories
    );
    this.listenToFoodScaleDialog(bulletIndex, optionIndex);
  }

  listenToFoodScaleDialog(bulletIndex: number, optionIndex: number) {
    this.popupHandlerService.foodUnitScaleSubject
      .pipe(take(1))
      .subscribe(({ event, data }) => {
        if (!data) return;

        if (event === 'closeAndDelete') {
          this.meal.mealBullets[bulletIndex].mealBulletOptions[
            optionIndex
          ].foodId = null;
          this.meal.mealBullets[bulletIndex].mealBulletOptions[
            optionIndex
          ].foodName = null;
        } else if (event === 'save') {
          this.meal.mealBullets[bulletIndex].mealBulletOptions[
            optionIndex
          ].foodScaleId = data.foodScale.id;

          this.meal.mealBullets[bulletIndex].mealBulletOptions[
            optionIndex
          ].foodScaleName = data.foodScale.name;

          this.meal.mealBullets[bulletIndex].mealBulletOptions[
            optionIndex
          ].quantity = data.quantity;

          var foodId =
            this.meal.mealBullets[bulletIndex].mealBulletOptions[optionIndex]
              .foodId;
          var foodScaleId =
            this.meal.mealBullets[bulletIndex].mealBulletOptions[optionIndex]
              .foodScaleId;
          var quantity =
            this.meal.mealBullets[bulletIndex].mealBulletOptions[optionIndex]
              .quantity;

          this.getCalories(
            foodId,
            foodScaleId,
            quantity,
            bulletIndex,
            optionIndex
          );
          this.mealChanged.emit(true);
        }
      });
  }

  getNutriCalories(meal: Meal) {
    return meal?.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.food_energy
    );
  }
  getNutriFats(meal: Meal) {
    return meal?.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.total_fat
    );
  }
  getNutriCarbo(meal: Meal) {
    return meal?.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.carbohydrates
    );
  }
  getNutriProteins(meal: Meal) {
    return meal?.mealToNutrients?.find(
      (x) => x.foodNutrientId == FoodNutrientsZameret.protein
    );
  }

  openViteminsDialog(mealToNutrients: MealToNutrient[]) {
    this.popupHandlerService.openViteminsDialog(
      mealToNutrients,
      +this.productId
    );
  }

  clear() {
    this.popupHandlerService.openConfirmationDialog(null, 'Clear');

    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;
        if (result.ans === 'yes') {
          this.meal.mealBullets.length = 0;
          this.addNewBullet();
        }
      });
  }

  save() {
    this.meal.mealBullets.map((bullet) => {
      bullet.mealBulletOptions.map((option) => {
        if (
          !option.foodId &&
          !option.foodScaleId &&
          !option.quantity &&
          !option.calories
        ) {
          this.validbullets = false;
          return;
        } else {
          this.validbullets = true;
        }
      });
    });

    if (!this.validbullets) {
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.costumMessage,
        '',
        '',
        "Can't save meal with empty option"
      );
    } else {
      this.isSubmiting = true;
      this.mealsService.put(this.meal, this.langId).subscribe((data: any) => {
        this.mealChanged.emit(false);
        this.getMeal();
        this.menusService.getMenu();
        this.isSubmiting = false;
        this.popupHandlerService.openDisapearingAlertDialog('success');
      });
    }
  }
}
