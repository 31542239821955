import { AppModule } from './AppModule';
import { ModuleAction } from './ModuleAction';
import { PermissionRule } from './PermissionRule';

export class ActionConvertion {
  actionConvertionId: number;
  appModuleId: number;
  appModule: AppModule;
  moduleActionId: number;
  moduleAction: ModuleAction;
  permissionRuleId: number | null;
  permissionRule: PermissionRule;
  name: string;
  checked: boolean;
}
