import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FoodLang } from '../data/FoodLang';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodLangsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<FoodLang[]>(`${environment.baseUrl}FoodLangs/`);
  }

  SearchByName(
    query: string,
    langId: number,
    sourceKey: string,
    withRecipes: number,
    isPublished: number
  ) {
    if (query.length < 2) {
      return of([]); // return from cache
    } else {
      return this.http.get<NameAndId[]>(
        `${environment.baseUrl}FoodLangs/SearchByName/${query}/${langId}/${withRecipes}/${isPublished}`
      );
    }
  }

  searchByNameForProduct(
    query: string,
    langId: number,
    withRecipes: number,
    productId: number
  ) {
    if (query.length < 2) {
      return of([]); // return from cache
    } else {
      return this.http.get<NameAndId[]>(
        `${environment.baseUrl}FoodLangs/SearchByNameForProduct/${query}/${langId}/${withRecipes}/${productId}`
      );
    }
  }
  /*
  SearchByNameV2(
    query: string,
    langId: number,
    sourceKey: string,
    pageSize: number = 50,
    pageNumber: number = 1
  ) {
    if (query.length < 2 || query === '') {
      return of([]); // return from cache
    } else {
      return this.http.get<any>(
        `${environment.baseUrl}FoodLangs/SearchByNameV2/${query}/${langId}/${sourceKey}/${pageSize}/${pageNumber}`
      );
    }
  }
*/
  getById(foodLangId: number) {
    return this.http.get<FoodLang>(
      `${environment.baseUrl}FoodLangs/${foodLangId}`
    );
  }

  put(foodLang: FoodLang) {
    return this.http.put<FoodLang>(
      `${environment.baseUrl}FoodLangs/${foodLang.foodLangId}`,
      foodLang
    );
  }

  post(foodLang: FoodLang) {
    return this.http.put<FoodLang>(
      `${environment.baseUrl}FoodLangs/`,
      foodLang
    );
  }

  delete(foodLangId: number) {
    return this.http.delete<FoodLang>(
      `${environment.baseUrl}FoodLangs/${foodLangId}`
    );
  }
}
