import { Component, OnInit } from '@angular/core';
import { Note } from 'src/app/data/Note';
import { RegularUser } from 'src/app/data/RegularUser';
import { NotesService } from 'src/app/Services/notes.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { environment } from 'src/environments/environment';
import { AgentsService } from 'src/app/Services/agents.service';
import { NotesAgentPusherService } from 'src/app/Services/notes-agent-pusher.service';
import { NoteForMissionsMin } from 'src/app/data/NoteForMissionsMin';
import { Agent } from 'src/app/data/Agent';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { AgentMin } from 'src/app/data/AgentMin';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  displayedAgentId = -1;
  agents: AgentMin[];
  agentNotes: NoteForMissionsMin[];
  regularUser: RegularUser;
  newLeads: number;
  transactionDaily: any;
  averageCancellations: number;
  myRank: any;
  stats: any;
  isLoaded: boolean = false;

  constructor(
    private noteService: NotesAgentPusherService,
    private regularUsersService: RegularUsersService,
    private agentsService: AgentsService
  ) {
    this.noteService.agentNotes$.subscribe((notes) => {
      this.agentNotes = notes;
    });

    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      if (!user?.agent) return;
      this.displayedAgentId = user.agent.agentId;
      if (!this.isLoaded) this.getHomeStats();
      this.isLoaded = true;
    });
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
      var all = new AgentMin();
      all.name = 'All';
      all.agentId = -1;
      all.isActive = true;
      var nutCall = new AgentMin();
      nutCall.name = 'Nutrition Calls';
      nutCall.agentId = -2;
      nutCall.isActive = true;
      this.agents.unshift(all, nutCall);
    });
  }

  getAgentNameById(agentId) {
    return this.agents?.find((x) => x.agentId == agentId)?.name;
  }

  isprod() {
    if (environment.production == true) return true;
    return false;
  }
  ngOnInit(): void {
    this.getAgentNotes();
  }
  onAgentFilterChange(event) {
    this.displayedAgentId = event;
    this.getHomeStats();
    this.getAgentNotes();
  }
  getAgentNotes() {
    //this.noteService.getAgentNotes(this.displayedAgentId);
  }
  getHomeStats() {
    this.agentsService
      .getHomePageStats(this.displayedAgentId)
      .subscribe((data) => {
        this.stats = data;
        //console.log(data);
      });
  }
  hasAgentStatsPermmisionsAll() {
    return userHasPermisions(['AgentsStats_GetList'], this.regularUser);
  }
  hasAgentStatsPermmisions() {
    return userHasPermisions(['AgentsStats_GetSingle'], this.regularUser);
  }
  hasActivitiesDashboardPermisions() {
    return userHasPermisions(['ActivitiesDashboard_GetList'], this.regularUser);
  }
  showWelcomeScreen() {
    return (
      !userHasPermisions(['ActivitiesDashboard_GetList'], this.regularUser) &&
      !userHasPermisions(['AgentsStats_GetSingle'], this.regularUser) &&
      !userHasPermisions(['AgentsStats_GetSingle'], this.regularUser)
    );
  }
}
