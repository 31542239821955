<div class="wrapper">
  <div>
    <div class="title">Simulator version</div>
  </div>
  <div>
    <div class="title">Calculations</div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="wrapper">
    <div>
      <div *ngFor="let item of simulatorParams" class="item flex">
        <div class="label">
          {{ item.fgParamName }}&nbsp;({{ item.coefficientMinValue }}-{{
            item.coefficientMaxValue
          }})
        </div>
        <mat-form-field appearance="fill" class="item__field">
          <input matInput type="number" [(ngModel)]="item.fgParamValue" />
        </mat-form-field>
      </div>
      <div class="item flex">
        <div class="label">Select food</div>
        <app-autocomplete-foods
          class="item__field"
          [data]="foodsResults$ | async"
          (selectedValue)="onFoodSelected($event)"
          (searchTerm)="searchTermFood$.next($event)"
          [langId]="data.langId"
          [isRtl]="data.langId !== 1 ? false : true"
        ></app-autocomplete-foods>
      </div>
    </div>
    <div>
      <ng-container *ngIf="simulationResult">
        <div class="item flex" style="margin: 2rem 2rem; display: flex">
          <div class="label">Final result:</div>
          <div class="">{{ simulationResult.result }}</div>
        </div>
        <table mat-table [dataSource]="dataSource" class="versions__table">
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Order</th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.formulaName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="formula">
            <th mat-header-cell *matHeaderCellDef style="width: 55%">
              Formula
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.formula }}
            </td>
          </ng-container>

          <ng-container matColumnDef="condition">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">
              Condition
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.isHasCondition ? element.isCondition : "N/A" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Result</th>
            <td mat-cell *matCellDef="let element">
              {{ element.result }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="actions">
    <button mat-button class="recalculate" (click)="recalculate()">
      Recalculate
    </button>
    <button mat-button class="close" mat-dialog-close>Close</button>
  </div>
</mat-dialog-actions>
