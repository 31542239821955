<div class="title">Create formula</div>
<div class="item">
  <mat-radio-group [(ngModel)]="addMode" (ngModelChange)="addModeChange()">
    <mat-radio-button class="example-radio-button" value="new">
      New
    </mat-radio-button>
    <mat-radio-button class="example-radio-button" value="list">
      List
    </mat-radio-button>
  </mat-radio-group>
</div>
<ng-container *ngIf="addMode">
  <ng-container *ngIf="addMode === 'list'">
    <div class="item">
      <div class="label">Select Version:</div>
      <mat-form-field appearance="fill" class="item__select">
        <mat-select
          (selectionChange)="onSelectVersion($event)"
          placeholder="Select"
        >
          <mat-option
            *ngFor="let item of data.versions"
            [value]="item.fgVersionId"
          >
            {{ item.fgVersionName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item" *ngIf="prototypes">
      <div class="label">Select prototype:</div>
      <mat-form-field appearance="fill" class="item__select">
        <mat-select
          (selectionChange)="onSelectProptotype($event)"
          placeholder="Select"
        >
          <mat-option
            *ngFor="let item of prototypes"
            [value]="item.prototypeFormulaId"
          >
            {{ item.formulaName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="addMode === 'new' || selectedPrototype">
    <div class="item mt-4">
      <div class="label">Formula name:</div>
      <mat-form-field appearance="fill" class="item__field">
        <input matInput [(ngModel)]="formula.formulaName" />
      </mat-form-field>
    </div>
    <div class="item">
      <div class="label">Select type:</div>
      <mat-form-field
        class="item__select"
        *ngIf="formulaTypes"
        appearance="fill"
      >
        <mat-select [(ngModel)]="formula.formulaType" placeholder="Select">
          <mat-option *ngFor="let item of formulaTypes" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item">
      <div class="label">Order by:</div>
      <mat-form-field appearance="fill" class="item__field">
        <input
          matInput
          [(ngModel)]="formula.orderBy"
          type="number"
          min="1"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
<div class="actions mt-2">
  <button
    class="save"
    mat-button
    [disabled]="
      !formula?.formulaName || !formula?.formulaType || !formula?.orderBy
    "
    (click)="create()"
    type="submit"
  >
    Create
  </button>
  <button class="cancel" mat-button mat-dialog-close type="button">
    Cancel
  </button>
</div>
