import { Component, OnInit, Input } from '@angular/core';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Router } from '@angular/router';
import { ManageAgentsService } from 'src/app/Services/manage-agents.service';

import { NameAndId } from 'src/app/data/NameAndId';
import { AgentForTableMinV2 } from 'src/app/data/AgentForTableMinV2';

@Component({
  selector: 'app-agents-table',
  templateUrl: './agents-table.component.html',
  styleUrls: ['./agents-table.component.scss'],
})
export class AgentsTableComponent implements OnInit {
  @Input() headFilter: HeadFilter;
  @Input() agentsData: AgentForTableMinV2[];
  @Input() userRoles: NameAndId[];
  constructor(
    private router: Router,
    private manageAgentsService: ManageAgentsService
  ) {}

  ngOnInit(): void {}
  editAgent(e, agent) {
    this.moveToEditScreen(agent.agentId);
  }
  changeSortOrder() {}
  changeSortBy(e) {}
  headers = [
    { name: 'Name', val: 'Name' },
    { name: 'Email', val: 'Email' },
    { name: 'Tel', val: 'Tel' },
    { name: 'Role', val: 'Role' },
  ];
  getAllWithFilter(event) {}
  moveToEditScreen(agentId = -1) {
    this.router.navigate(['/agents/edit/'], {
      queryParams: { agentId: agentId },
    });
  }
  editAgentSettings(e, agent) {
    this.router.navigate(['/agent-settings/'], {
      queryParams: { agentId: agent.agentId },
    });
  }
  saveRoleForAgent(event, agent: AgentForTableMinV2) {
    var val = event.value;
    agent.userRoleId = Number(event.value);
    /*
    agent.role = this.userRoles.filter((x) => {
      return x.roleTypeId == event.value;
    })[0];
    */

    setTimeout(() => {
      this.manageAgentsService.changeRoleV2(agent).subscribe((ans) => {});
    }, 10);
  }
}
