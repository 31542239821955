import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoleType } from '../data/RoleType';
import { RoleTypeForTable } from '../data/RoleTypeForTable';

@Injectable({
  providedIn: 'root',
})
export class RoleTypesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<RoleType[]>(`${environment.baseUrl}RoleTypes/`);
  }
  getAllWithPermissions() {
    return this.http.get<RoleType[]>(
      `${environment.baseUrl}RoleTypes/withPermissions`
    );
  }

  getById(roleTypeId) {
    return this.http.get<RoleType>(
      `${environment.baseUrl}RoleTypes/${roleTypeId}`
    );
  }
  put(roleType: RoleType) {
    return this.http.put(
      `${environment.baseUrl}RoleTypes/${roleType.roleTypeId}`,
      roleType
    );
  }
  post(roleType: RoleType) {
    return this.http.post<RoleType>(
      `${environment.baseUrl}RoleTypes/`,
      roleType
    );
  }
  delete(roleType: RoleType) {
    return this.http.delete(
      `${environment.baseUrl}RoleTypes/${roleType.roleTypeId}`
    );
  }

  getAllForTable() {
    return this.http.get<RoleTypeForTable[]>(
      `${environment.baseUrl}RoleTypes/ForTable`
    );
  }
}
