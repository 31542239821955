import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceRequestNote } from '../data/ServiceRequestNote';
import { environment } from 'src/environments/environment';
import { Note } from '../data/Note';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestNotesService {
  constructor(private http: HttpClient) {}

  getByServiceRequestId(serviceRequestId) {
    return this.http.get<Note[]>(
      `${environment.baseUrl}ServiceRequestNotes/${serviceRequestId}`
    );
  }
  put(serviceRequestNote: ServiceRequestNote) {
    return this.http.put(
      `${environment.baseUrl}ServiceRequestNotes/${serviceRequestNote.serviceRequestNoteId}`,
      serviceRequestNote
    );
  }
  post(serviceRequestNote: ServiceRequestNote) {
    return this.http.post<Note>(
      `${environment.baseUrl}ServiceRequestNotes/`,
      serviceRequestNote
    );
  }
}
