<form (ngSubmit)="saveAll(bulkForm)" #bulkForm="ngForm">
  <div class="bulk-edit">
    <button
      type="button"
      class="btn-link"
      mat-button
      (click)="cancel(bulkForm)"
    >
      < Back to list
    </button>
    <mat-card class="bulk-edit__card">
      <div class="bulk-edit__header-bg">
        <div class="bulk-edit__header">
          <div class="bulk-edit__header__title">
            <div class="bulk-edit__header__title__text">
              <mat-icon>restaurant_menu</mat-icon>
              Recipe Translation
            </div>
            <div class="bulk-edit__header__title__lang">
              <mat-form-field appearance="fill">
                <mat-icon matPrefix
                  ><img
                    style="width: 20px; position: relative; top: -4px"
                    src="../../../../assets/flags/{{ selectedLang }}.png"
                    alt=""
                /></mat-icon>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

                <mat-select
                  [(ngModel)]="selectedLang"
                  (selectionChange)="changeLangId()"
                  disableOptionCentering
                  panelClass="lang-select"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option
                    *ngFor="let lang of langs"
                    [value]="lang.languageId"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                      "
                    >
                      <img
                        style="width: 15px; margin-right: 1rem"
                        src="../../../../assets/flags/{{ lang.languageId }}.png"
                        alt=""
                      />
                      {{ lang.name }}
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="bulk-edit__header__translation">
            <div *ngFor="let lang of langsTabs" style="position: relative">
              <button
                type="button"
                class="lang-button"
                [class.active]="lang.languageId == targetLangId"
                (click)="changeTargetLangId(lang)"
                mat-button
              >
                <mat-icon *ngIf="lang.isMissing">chat_bubble</mat-icon>
                {{ lang.name }}
              </button>
              <div
                *ngIf="lang.languageId == targetLangId"
                class="shape-bg"
              ></div>
              <div *ngIf="lang.languageId == targetLangId" class="shape"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex"
        *ngFor="let recipe of recipes; let last = last"
        [class.border-bottom]="!last"
      >
        <ng-container
          *ngFor="
            let lang of recipe.recipeForDetailsBulk;
            let recipeIndex = index
          "
        >
          <div
            *ngIf="
              lang.langId == langId || lang.langId == targetLangId;
              let i = index
            "
            class="bulk-edit__item"
          >
            <button
              type="button"
              *ngIf="lang.langId == langId"
              mat-mini-fab
              class="remove-btn"
              (click)="removeRecipeHeader(recipeIndex)"
              [disabled]="disableRemove()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <div class="bulk-edit__item__name">
              <span class="title">Recipe Name:</span>
              <ng-container *ngIf="lang.langId == langId">{{
                lang.name
              }}</ng-container>
              <mat-form-field
                *ngIf="lang.langId == targetLangId"
                appearance="fill"
              >
                <input
                  name="recipeName-{{ i }}"
                  placeholder="Text here..."
                  matInput
                  [(ngModel)]="lang.name"
              /></mat-form-field>
            </div>
            <ng-container
              *ngFor="let header of lang.recipeHeaderforBulk; let inx = index"
            >
              <div class="bulk-edit__item__name">
                <span>Headline {{ inx + 1 }}:</span>
                <ng-container *ngIf="header.langId == langId">
                  {{ header.name }}
                </ng-container>
                <mat-form-field
                  appearance="fill"
                  *ngIf="header.langId == targetLangId"
                >
                  <input
                    name="headerName-{{ i }}"
                    placeholder="Text here..."
                    matInput
                    [(ngModel)]="header.name"
                  />
                </mat-form-field>
              </div>
            </ng-container>
            <div class="bulk-edit__item__name">
              <span>directions:</span>
              <ng-container *ngIf="lang.langId == langId">
                {{ lang.description }}
              </ng-container>
              <mat-form-field
                appearance="fill"
                *ngIf="lang.langId == targetLangId"
              >
                <textarea
                  name="description-{{ i }}"
                  placeholder="Text here..."
                  matInput
                  [(ngModel)]="lang.description"
                >
                </textarea>
              </mat-form-field>
            </div>
            <div class="bulk-edit__item__name" style="align-items: flex-start">
              <span>Ingredients:</span>
              <div class="mat-form-field">
                <ng-container
                  *ngFor="
                    let item of lang.recipeBulletForBulkEdit;
                    let x = index;
                    let last = last
                  "
                >
                  <div
                    *ngIf="lang.langId == langId && item.name"
                    style="margin-bottom: 1rem"
                  >
                    #{{ x + 1 }} - {{ item.name
                    }}<ng-container *ngIf="!last">,</ng-container>
                  </div>
                  <mat-form-field
                    appearance="fill"
                    *ngIf="lang.langId == targetLangId"
                    style="margin-bottom: 1rem"
                  >
                    <textarea
                      name="ingredients-{{ i }}-{{ x }}"
                      placeholder="Text here..."
                      matInput
                      [(ngModel)]="item.name"
                    >
                    </textarea>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-card class="bulk-edit__card__actions">
        <button type="submit" mat-button class="btn btn-fill mr-1">Save</button>
        <button
          (click)="cancel(bulkForm)"
          type="button"
          mat-button
          class="btn btn-outline"
        >
          Cancel
        </button>
      </mat-card>
    </mat-card>
  </div>
</form>
