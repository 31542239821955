import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-calories-bar',
  templateUrl: './menu-calories-bar.component.html',
  styleUrls: ['./menu-calories-bar.component.scss'],
})
export class MenuCaloriesBarComponent implements OnInit {
  @Input() data;
  @Input() inEdit: boolean;

  constructor() {}

  ngOnInit(): void {}

  setBarHeight(type: 'goal' | 'menu') {
    if (type === 'goal') {
      if (this.data.menu > this.data.goal)
        return Math.round((this.data.goal / this.data.menu) * 100);
      else return 100;
    } else {
      if (this.data.goal > this.data.menu)
        return Math.round((this.data.menu / this.data.goal) * 100);
      else return 100;
    }
  }

  calculateDiff() {
    let diff = this.data.goal - this.data.menu;
    let absDiff = Math.abs(diff);
    let diffHeight = null;
    if (this.data.menu > this.data.goal)
      diffHeight = Math.round((this.data.goal / this.data.menu) * 100);
    else {
      diffHeight = Math.round((this.data.menu / this.data.goal) * 100);
    }
    if (absDiff > 150) {
      return { diff: absDiff, color: 'red', diffHeight };
    } else {
      return { diff: absDiff, color: 'green', diffHeight };
    }
  }
}
