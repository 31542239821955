import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import { Agent } from '../../data/Agent';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { take } from 'rxjs/operators';
import { AlgoBullet } from 'src/app/data/AlgoBullet';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { AlgoBulletService } from 'src/app/Services/algo-bullet.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { AlgoBulletImagesService } from 'src/app/Services/algo-bullet-images.service';

@Component({
  selector: 'app-algo-bullets-table',
  templateUrl: './algo-bullets-table.component.html',
  styleUrls: ['./algo-bullets-table.component.scss'],
})
export class AlgoBulletsTableComponent implements OnInit {
  @Output('editBullet') editBullet: EventEmitter<any> = new EventEmitter();
  @Output('closeEditMode') closeEditMode: EventEmitter<any> =
    new EventEmitter();
  @Output('deleteBullet') deleteBullet: EventEmitter<any> = new EventEmitter();
  @Input() bulletsData: AlgoBullet[];
  @Input() bulletIdToEdit: number;
  @Input() categoryIdToEdit: number;
  @Input() isEditMode: boolean;
  @Input() agents: Agent[];
  @Input() headFilter: HeadFilter;
  @Input() metaData: PaginationMetadata;
  @Input() isFullScreen: boolean;
  @Output('filterChanged') filterChanged: EventEmitter<HeadFilter> =
    new EventEmitter();

  selection = new SelectionModel<number>(true, []);
  selected: number[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private popupHandlerService: PopupHandlerService,
    private algoBulletService: AlgoBulletService,
    private algoBulletImagesService: AlgoBulletImagesService
  ) {
    this.selection.changed.subscribe((value) => {
      this.selected = value.source.selected;

      if (this.selected.length > 0) {
        console.log(this.selected);
      }
    });
  }
  headers = [
    { name: 'Icon', val: 'imageUrl', width: '12%' },
    { name: 'Parameters', val: 'parameters', width: '31.5%' },
    { name: 'Blood Test', val: 'bloodTest', width: '15%' },
    { name: 'Title', val: 'title', width: '17%' },
    { name: 'Description', val: 'description', width: '33%' },

    { name: '', val: '', width: '6%' },
  ];
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  ngOnInit(): void {}

  onChangeSelection(e, id) {
    return e ? this.selection.toggle(id) : null;
  }
  openImagesDialog() {
    this.algoBulletImagesService.getImages().subscribe((data) => {
      this.popupHandlerService.openFilesManagerDialog(data, null);
    });

    this.popupHandlerService.filesManagerSubject.subscribe(
      ({ event, selectedImg }) => {
        if (event === 'add') {
          this.algoBulletService
            .setBulletsImage(selectedImg.algoBulletImageId, this.selected)
            .subscribe((data) => {
              this.selection = new SelectionModel<number>(true, []);
              this.filterChanged.emit(this.headFilter);
            });
        }
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.bulletsData, event.previousIndex, event.currentIndex);

    const el: AlgoBullet = event.item.data;
    this.bulletsData = this.bulletsData.map((x) => {
      if (x.algoBulletId == el.algoBulletId) {
        x.order = event.currentIndex;
      }

      return x;
    });

    this.bulletsData = this.bulletsData.map((x, idx) => {
      x.order = idx;
      return x;
    });
    this.algoBulletService
      .setBulletsOrder(this.bulletsData)
      .subscribe((data) => {
        console.log(data);
      });
  }

  changeSortBy(name: string) {
    if (this.headFilter.orderBy == name) {
      this.headFilter.orderBy = '';
      this.headFilter.acending = true;
    } else {
      this.headFilter.orderBy = name;
    }
    this.filterChanged.emit(this.headFilter);
  }
  changeSortOrder() {
    this.headFilter.acending = !this.headFilter.acending;
    this.filterChanged.emit(this.headFilter);
  }

  moveToEditScreen(clientId = -1) {
    /* this.router.navigate(['/leads/edit/'], {
      queryParams: { clientId: clientId },
    }); */
  }
  editRow(id) {
    this.editBullet.emit(id);
  }
  close() {
    this.closeEditMode.emit(true);
  }
  deleteRow(id) {
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans == 'yes') {
          this.deleteBullet.emit(id);
        }
      });
    this.popupHandlerService.openConfirmationDialog(
      '',
      'You are about to to DELETE this row'
    );
  }

  getParameters(parameters: string[]) {
    return parameters?.join(',&#13;');
  }

  onResizeEnd(event: ResizeEvent): void {
    console.log('Element was resized', event);
  }
}
