import { AppImage } from './algorithm/AppImage';
import { VideoCategory } from './VideoCategory';

export class Video {
  videoId: number;
  title: string;
  desc: string;
  createDate: string;
  uploadDate: any;
  duration: string;
  agentId: number;
  agentName: string;
  videoCategory: VideoCategory;
  langId: number;
  files: AppImage[];
  isActive: boolean;
  isDisclaimer: boolean;
}
