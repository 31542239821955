import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatRadioChange } from '@angular/material/radio';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Language } from 'src/app/data/Language';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import {
  LifeStyleInputType,
  QuestionDesignType,
  QuestionContainerDesignType,
} from 'src/app/Enums/StatusesEnum';
import { isValidUtil, refAnsweredUtil } from 'src/app/Utils/lifestyleUtil';

@Component({
  selector: 'app-lifestyle-question-edit-node',
  templateUrl: './lifestyle-question-edit-node.component.html',
  styleUrls: ['./lifestyle-question-edit-node.component.scss'],
})
export class LifestyleQuestionEditNodeComponent implements OnInit {
  disableRed: boolean = true;
  @Input() isLoading: boolean;
  @Input() important: boolean = false;
  @Input() question: LifestyleQuestion;
  @Input() parentIdx;
  @Input() childIdx;
  @Input() productId;
  @Input() designTypeId;
  @Input() langs: Language[];
  @Input() isPreview: boolean = false;
  @Input() isChild: boolean = false;
  @ViewChild('input') input: ElementRef;

  @Output('radioChange') radioChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('incrementIndex') incrementIndex: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('updateAnswer') updateAnswer: EventEmitter<any> =
    new EventEmitter<any>();
  showIndex: any;

  @Input() lifestyleQuestions: LifestyleQuestion[];

  constructor() {}
  ngOnInit(): void {
    setTimeout(() => {
      if (this.input) {
        //console.log('addEvent for input');
        fromEvent(this.input.nativeElement, 'keyup')
          .pipe(debounceTime(1000))
          .subscribe((data) => {
            this.onUpdateAnswerUI(
              this.input.nativeElement.value,
              LifeStyleInputType.text
            );
          });
      }
    }, 100);
  }

  isRtl() {
    let langId = 1;
    if (
      this.question.lifestyleQuestionLangs &&
      this.question.lifestyleQuestionLangs.length > 0
    ) {
      langId = this.question.lifestyleQuestionLangs[0].languageId;
    }
    return this.langs.find((x) => x.languageId == langId).isRtl;
  }
  getLangAlign() {
    if (this.isRtl()) return 'right';
    return 'left';
  }

  onIcrementIndex(index) {
    this.incrementIndex.emit(index);
  }
  OnUpdateAnswer(event) {
    this.updateAnswer.emit(event);
  }
  onUpdateAnswerUI(event, inputTypeId: number) {
    let value: string;
    switch (inputTypeId) {
      case LifeStyleInputType.checkbox:
        value = event?.checked ? 'true' : 'false';
        //value = event?.checked;
        break;
      case LifeStyleInputType.radio:
        value = event.source?.checked ? 'true' : 'false';
        //value = event.source?.checked;
        /*
        this.store.dispatch(
          resetRadioButton({
            lifestyleQuestionId: this.question.lifestyleQuestionId,
          })
          
        );
          */
        break;
      case LifeStyleInputType.slider:
        //eventChecked = `${event}`;
        value = `${event}`;
        break;
      case LifeStyleInputType.text:
        value = event;
        break;
    }
    this.updateAnswer.emit({
      questionId: this.question.lifestyleQuestionId,
      value,
      inputTypeId: inputTypeId,
    });
    /*
    this.store.dispatch(
      updateAnswer({
        lifestyleQuestion: this.question,
        productId: this.productId,
        eventChecked,
        inputTypeId,
        value,
      })
    );
    */
    this.refAnswered(this.question.referenceQuestionId);
    this.incrementIndex.emit(this.parentIdx);
  }

  onRadioInputChange(event: MatRadioChange) {
    /* if (!this.question.lifeStyleAnswers) {
      this.question.lifeStyleAnswers = [];

      let lifeStyleAnswer = new LifeStyleAnswer();
      lifeStyleAnswer.lifeStyleAnswerId = 0;
      lifeStyleAnswer.productId = this.productId;
      lifeStyleAnswer.lifestyleQuestionId = +event.value;
      lifeStyleAnswer.value = event.source?.checked ? 'true' : 'false';
      this.question.lifeStyleAnswers.push(lifeStyleAnswer);
    } else {
      this.question.lifeStyleAnswers[0].value = event.source?.checked
        ? 'true'
        : 'false';
    } */

    //let;
    /* this.store.dispatch(
      updateAnswer({
        lifestyleQuestionId: this.question.lifestyleQuestionId,
        productId: this.productId,
        eventChecked,
        isRadio: false,
      })
    ); */
    //this.radioChange.emit(this.question.lifestyleQuestionId);
    /*
    this.store.dispatch(
      resetRadioButton({
        lifestyleQuestionId: this.question.lifestyleQuestionId,
      })
    );
    */
    this.incrementIndex.emit(this.parentIdx);
  }

  onRadioChangeEmitter(id: number) {
    this.question.childQuestions = this.question.childQuestions.map((x) => {
      if (x.lifestyleQuestionId !== id && x.lifeStyleAnswers?.length > 0) {
        x.lifeStyleAnswers[0].value = 'false';
      }
      return x;
    });
  }

  onInputChange(event: MatInput) {}

  isShowingChilds() {
    switch (this.question.designTypeId) {
      case QuestionDesignType.header:
      case QuestionDesignType.headerCenter:
        return true;
      case QuestionDesignType.inputText:
        return true;
      case QuestionDesignType.nextbutton:
        return true;
      case QuestionDesignType.checkboxBig:
      case QuestionDesignType.radioRegular:
      case QuestionDesignType.radioButtonBig:
        if (
          this.question.lifeStyleAnswers &&
          this.question.lifeStyleAnswers[0] &&
          this.question.lifeStyleAnswers[0].value == 'true'
        )
          return true;
        else return false;
    }
  }

  getContainerDesignType() {
    switch (this.question.designTypeId) {
      case QuestionContainerDesignType.flex:
        return QuestionContainerDesignType.flex;
      case QuestionContainerDesignType.block:
        return QuestionContainerDesignType.block;
    }
  }

  /* const array = this.question;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (
        element.referenceQuestionId == refId &&
        element.lifeStyleAnswers &&
        element.lifeStyleAnswers[0].value === 'true'
      ) {
        return true;
      }
      return false;
    } */

  hasRefId(question: LifestyleQuestion) {
    if (question.referenceQuestionId) {
      return true;
    }
    return false;
  }
  refAnswered(refId) {
    return refAnsweredUtil(this.lifestyleQuestions, refId);
  }
  isValid(node: LifestyleQuestion) {
    return isValidUtil(node);
  }

  /*
  onChangeAnswer(event: MatCheckboxChange, index: number) {
    if (!this.questions[index].lifeStyleAnswers) {
      this.questions[index].lifeStyleAnswers = [];

      let lifeStyleAnswer = new LifeStyleAnswer();
      lifeStyleAnswer.lifeStyleAnswerId = 0;
      lifeStyleAnswer.productId = this.productId;
      lifeStyleAnswer.lifestyleQuestionId = +event.source.value;
      lifeStyleAnswer.value = event.checked ? 'true' : 'false';
      this.questions[index].lifeStyleAnswers.push(lifeStyleAnswer);
    } else {
      this.questions[index].lifeStyleAnswers[0].value = event.checked
        ? 'true'
        : 'false';
    }
  }*/
}
