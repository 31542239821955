<div class="input-wrapper">
  <button
    [disabled]="chatMessage.trim().length == 0"
    (click)="sendMessage()"
    mat-icon-button
    class="send-button"
  >
    <mat-icon style="transform: rotate(180deg); margin-top: -2px"
      >send</mat-icon
    >
  </button>
  <div class="chat__input">
    <emoji-mart
      *ngIf="emojis"
      title=""
      (click)="preventCloseEmoticons($event)"
      (emojiClick)="addEmoji($event)"
    >
    </emoji-mart>
    <mat-form-field
      dir="rtl"
      style="text-align: start; width: 100%"
      appearance="fill"
    >
      <div class="chat__input__field">
        <button mat-icon-button (click)="openEmoticons($event)">
          <mat-icon style="color: #153341">tag_faces</mat-icon>
        </button>

        <textarea
          placeholder="Type here..."
          #chatInput
          (keydown.enter)="onEnter($event)"
          [(ngModel)]="chatMessage"
          (keyup)="onKeyUp($event)"
          matTextareaAutosize
          matAutosizeMinRows="1"
          matAutosizeMaxRows="2"
          style="resize: none; box-sizing: content-box"
          matInput
        ></textarea>
        <button mat-icon-button matSuffix (click)="file.click()" type="button">
          <mat-icon style="color: #153341">attach_file</mat-icon>
        </button>
        <input
          hidden
          type="file"
          #file
          placeholder="Choose file"
          (change)="uploadImg(file.files)"
        />
      </div>
    </mat-form-field>
  </div>
</div>
