import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-logs',
  templateUrl: './error-logs.component.html',
  styleUrls: ['./error-logs.component.css']
})
export class ErrorLogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
