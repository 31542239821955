<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <form style="width: 100%">
      <div class="d-flex">
        <div class="example-accordion-item-header d-grid">
          <div class="d-flex">
            <mat-icon
              class="having-food-icon"
              *ngIf="
                category.havingFood &&
                category.subCategories &&
                category.subCategories.length
              "
              matTooltip="This category has food related"
              >warning</mat-icon
            >
            <ng-container *ngIf="moreButtonVisible()">
              <mat-menu panelClass="more-panel" #morePanel="matMenu">
                <button
                  *ngIf="foodCategoryPerms.canEdit"
                  (click)="onEditClicked()"
                  mat-menu-item
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button
                  *ngIf="foodCategoryPerms.canAdd && notReachedMaxLevel()"
                  (click)="onAddSubCategory(accordionItem)"
                  mat-menu-item
                >
                  <mat-icon>add_circle</mat-icon>
                  <span>Add sub category</span>
                </button>
                <button
                  *ngIf="foodCategoryPerms.canDelete"
                  (click)="onDelete(category)"
                  mat-menu-item
                >
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
              <button [matMenuTriggerFor]="morePanel" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
            </ng-container>

            <mat-form-field #nameInput appearance="fill">
              <input
                [disabled]="!editMode"
                matInput
                [(ngModel)]="category.name"
                required
                name="name"
                placeholder="Category name"
              />
            </mat-form-field>

            <app-algo-image
              [canEdit]="foodCategoryPerms.canEdit"
              [langId]="langId"
              [algoImageType]="AlgoImageType.FoodCategoryImage"
              (onUploadFinished)="onUploadFinished($event, category)"
              [selectedImg]="getImage()"
              iconName="image"
            ></app-algo-image>
          </div>

          <button
            *ngIf="category.subCategories && category.subCategories.length > 0"
            (click)="accordionItem.toggle()"
            class="example-accordion-item-description"
            mat-icon-button
          >
            <mat-icon>
              {{
                accordionItem.expanded
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }}</mat-icon
            >
          </button>
        </div>
        <div class="categories__item__action" *ngIf="editMode">
          <button
            class="ok"
            mat-icon-button
            (click)="onSaveClicked(category, nameInput)"
            type="submit"
          >
            <mat-icon>check</mat-icon>
          </button>
          <button
            class="cancel"
            mat-icon-button
            (click)="onCancelEditClicked(category)"
            type="button"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <div
      class="example-accordion-item-body"
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index"
    >
      <ul style="list-style: none">
        <li
          *ngFor="
            let item of category.subCategories;
            trackBy: trackChanges;
            let i = index
          "
        >
          <app-edit-food-category-item
            *ngIf="foodCategoryPerms"
            class="categories__item"
            [index]="i"
            [category]="item"
            [langId]="langId"
            (editCanceled)="onCancelEditClicked($event)"
            (editModeChange)="editModeChange.emit($event)"
            (saveCategory)="onSaveClicked($event)"
            (deleteCategory)="deleteCategory.emit($event)"
            [foodCategoryPerms]="foodCategoryPerms"
          ></app-edit-food-category-item>
        </li>
      </ul>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
