<div class="events">
  <button (click)="location.back()" class="events__back" mat-button>
    < Back
  </button>

  <mat-card class="events__card">
    <mat-card-header class="events__card__header">
      <mat-card-title class="events__card__header__title">
        Zoom events
      </mat-card-title>

      <div>
        <button
          class="events__card__header__create"
          mat-button
          (click)="openZoomEvent(0)"
        >
          Create new event
        </button>
      </div>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="events__table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 33%">Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.zoomEventName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 33%">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.zoomEventDate | date: "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 33%">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <button
              mat-icon-button
              class="events__table__action"
              (click)="openZoomEvent(element.zoomEventId)"
              matTooltip="Edit"
              matTooltipPosition="right"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
</div>
