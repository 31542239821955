import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lead-requests-view',
  templateUrl: './lead-requests-view.component.html',
  styleUrls: ['./lead-requests-view.component.css'],
})
export class LeadRequestsViewComponent implements OnInit {
  disableNew: boolean = false;
  @Input() classAttr;
  @Input() requests;
  constructor(private route: ActivatedRoute) {}
  @ViewChild('tabGroup') tabGroup;
  @Output('createNewRequest') createNewRequest: EventEmitter<
    any
  > = new EventEmitter();
  ngOnInit(): void {
    var leadId = this.route.snapshot.queryParamMap.get('clientId');
    if (leadId === '-1') {
      this.disableNew = true;
    }
 
  }

  onCreateNewRequest() {
    this.createNewRequest.emit(null);
  }

  onEditRequest(request: ServiceRequest) {
    this.createNewRequest.emit(request);
  }
}
