import { Component, OnInit } from '@angular/core';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { PatientDeliveriesService } from 'src/app/Services/patient-deliveries.service';

@Component({
  selector: 'app-bar-holdup',
  templateUrl: './bar-holdup.component.html',
  styleUrls: ['./bar-holdup.component.css'],
})
export class BarHoldupComponent implements OnInit {
  globalParam: GlobalParam[];
  constructor(
    private globalParamsService: GlobalParamsService,
    private patientDeliveriesService: PatientDeliveriesService
  ) {}
  DisableBarDeliveriesPickup: boolean = false;
  DisableBarDeliveriesDelivery: boolean = false;
  ngOnInit(): void {
    this.globalParamsService.getAll().subscribe((res) => {
      this.globalParam = res;
      this.DisableBarDeliveriesDelivery =
        this.globalParam.find((x) => x.key == 'DisableBarDeliveriesDelivery')
          .value == 'true';
      this.DisableBarDeliveriesPickup =
        this.globalParam.find((x) => x.key == 'DisableBarDeliveriesPickup')
          .value == 'true';
    });
  }
  releaseDelivers() {
    this.patientDeliveriesService
      .releaseAllDeliveries(true)
      .subscribe((res) => {});
  }
  releasePickups() {
    this.patientDeliveriesService
      .releaseAllDeliveries(false)
      .subscribe((res) => {});
  }
  onDisablePickupsChange() {
    /*
    this.globalParamsService.putByName("DisableBarDeliveriesPickup", this.DisableBarDeliveriesPickup).subscribe(res=>{

    }) */
    alert('todo: make this work.Change From Db')
  }
  onDisableDeliversChange() {
    /*
    this.globalParamsService.putByName("DisableBarDeliveriesDelivery",this.DisableBarDeliveriesDelivery).subscribe(res=>{

    })*/
    
     alert('todo: make this work.Change From Db')
  }
}
