<div class="files-manager">
  <div class="files-manager__header">
    <div class="title">Insert Image</div>
    <button (click)="close()" class="close" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="files-manager__images">
    <mat-radio-group
      (change)="onSelectImage($event)"
      aria-label="Select an option"
    >
      <div *ngFor="let image of data.images">
        <mat-radio-button
          class="files-manager__images__radio"
          [value]="getImageValue(image)"
          [checked]="ifImageChecked(image)"
        >
          <img [src]="getImageUrl(image)" alt="" />
        </mat-radio-button>
        <div
          [matTooltip]="image.imageName"
          class="files-manager__images__radio__name"
        >
          {{ image.imageName }}
        </div>
      </div>
    </mat-radio-group>
  </div>
  <div class="files-manager__actions">
    <button
      (click)="addImage()"
      [disabled]="!newSelectedImg"
      class="add"
      mat-button
    >
      Add Image
    </button>
    <button (click)="file.click()" class="upload" mat-button>
      Upload <mat-icon>cloud_upload</mat-icon>
    </button>
    <input
      name="file"
      hidden
      type="file"
      accept="image/*"
      #file
      placeholder="Choose file"
      (change)="uploadImg(file.files)"
      multiple
    />
  </div>
  <div *ngIf="imgError">{{ imgError }}</div>
</div>
