export class TranzillaTransaction {
    ccno: string;
    expdate: string;
    myccv: string;
    myid: string;
    sum: string;
    cred_type: number;
    tranMode: string;
    fpay: string;
    spay: string;
    npay: string;
    transacationNum: string;
    pdesc: string;
    contact: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    lang: string;
    currency: string;
    company: string;
    cardOwnerName:string;
}