<table mat-table [dataSource]="permissions" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let permission"> {{permission.permissionAction}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let permission"> {{permission.name}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <!--
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let permission"> {{permission.permisionCategoryId}} </td>
    </ng-container>
  -->
  
    <ng-container  matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let permission"> 
        <mat-form-field
          appearance="fill"
          class="upgrade"
          style="padding-top: 1.34375em; margin-right: 30px"
        >
          <mat-label>Category:</mat-label>
          <mat-select
            (selectionChange)="onCategoryFilterChange($event,permission)"
            matNativeControl
            disableOptionCentering
            [(ngModel)]="permission.permisionCategoryId"
          >
           
            <mat-option [value]="-1" selected> All </mat-option>
            <mat-option
              *ngFor="let category of categories"
              [value]="category.permisionCategoryId"
            >
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      
    </td>
      </ng-container>



    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  