import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EditFoodFilter } from '../data/EditFoodFilter';
import { Food } from '../data/Food';
import { FoodForBulkEdit } from '../data/FoodForBulkEdit';
import { RecipeForBulkEdit } from '../data/RecipeForBulkEdit';
export interface tableData {
  data: {
    list: [];
    meteData: [];
  };
}
@Injectable({
  providedIn: 'root',
})
export class EditFoodsService {
  data: tableData;
  constructor(private http: HttpClient) {}

  getAllForTable(editFoodFilter: EditFoodFilter) {
    if (editFoodFilter.sourceKey.length == 0) {
      return of(this.data);
    } else {
      return this.http
        .post<tableData>(
          `${environment.baseUrl}EditFoods/Filter/`,
          editFoodFilter
        )
        .pipe(map((res) => (this.data = res)));
    }
  }

  uploadImg(foodId, img) {
    return this.http.post<any>(
      `${environment.baseUrl}EditFoods/UploadImage/${foodId}`,
      img
    );
  }

  putFood(foodId: number, food: Food) {
    return this.http.put<Food>(
      `${environment.baseUrl}EditFoods/${foodId}`,
      food
    );
  }
  postFood(food: Food, langId: number) {
    return this.http.post<Food>(
      `${environment.baseUrl}EditFoods/save/${langId}`,
      food
    );
  }
  putRecipe(foodId: number, food: Food) {
    return this.http.put<Food>(
      `${environment.baseUrl}EditRecipes/${foodId}`,
      food
    );
  }
  postRecipe(food: Food, langId: number) {
    return this.http.post<Food>(
      `${environment.baseUrl}EditRecipes/save/${langId}`,
      food
    );
  }

  getSingleFood(foodId, langId) {
    return this.http.get<Food>(
      `${environment.baseUrl}EditFoods/Food/${foodId}/${langId}`
    );
  }
  getSingleRecipe(foodId: number, langId: number) {
    return this.http.get<Food>(
      `${environment.baseUrl}EditRecipes/Recipe/${foodId}/${langId}`
    );
  }
  getFoodsBulkForEdit(foodIds, LangId) {
    return this.http.post<any>(
      `${environment.baseUrl}EditFoods/getEditBulk/${LangId}`,
      foodIds
    );
  }
  saveFoodBulk(foods: FoodForBulkEdit[]) {
    return this.http.post<any>(
      `${environment.baseUrl}EditFoods/saveEditBulk/`,
      foods
    );
  }
  getRecipesBulkForEdit(foodIds, LangId) {
    return this.http.post<any>(
      `${environment.baseUrl}EditRecipes/getEditBulk/${LangId}`,
      foodIds
    );
  }
  saveRecipeBulk(foods: RecipeForBulkEdit[]) {
    return this.http.put<any>(
      `${environment.baseUrl}EditRecipes/saveEditBulk/`,
      foods
    );
  }
}
