import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubCategory } from '../data/SubCategory';
import { environment } from 'src/environments/environment';
import { id } from '@swimlane/ngx-charts';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubCategoriesService {
  changeSubCatName(subCategoryId,name) {

    return this.http.get<SubCategory>(
      `${environment.baseUrl}SubCategories/changeSubCatName/${subCategoryId}/${name}`,
    
    );
  }
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<SubCategory[]>(`${environment.baseUrl}SubCategories`);
  }
  put(subCategory: SubCategory): Observable<SubCategory> {
    return this.http.put<SubCategory>(
      `${environment.baseUrl}SubCategories/${subCategory.subCategoryId}`,
      subCategory
    );
    //.pipe(catchError(this.handlePutError));
  }
  
}
