//import { templateJitUrl } from '@angular/compiler';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChatMsg } from 'src/app/data/chat-msg';
import { IndicatorMsg } from 'src/app/data/indicator-msg';
//import { from, Observable } from 'rxjs';
//import { Subscription } from 'rxjs/internal/Subscription';
//import { combineAll } from 'rxjs/operators';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { FIREBASE_REFERENCES } from 'src/app/firebase.module';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { getDayV2 } from 'src/app/Utils/dateUtils';

//import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('chatScroller') chatScroller: any;
  @ViewChild('chatInput') chatInput: ElementRef;

  chatRoom: any;
  currentUser: any;
  patient: any;
  @Input() serviceRequest: RequestForTableMin;
  @Input() docId: number;
  @Input() letter: string;
  @Input() user: RegularUser;
  @Input() closingInProgress: boolean;
  chatMessage: any = '';
  emojis: boolean = false;
  chatLength: number = 0;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 1,
  };
  chatRoomDates: any[];
  chatIndicator: IndicatorMsg;
  chatIndicatorSubscription: any;
  chatroomSubscription: any;
  chatRoomKey: any;
  constructor(
    private regularUsersService: RegularUsersService,
    @Inject(FIREBASE_REFERENCES.CHAT_FIRESTORE)
    private firestore: AngularFirestore,
    private firebaseService: FirebaseChatIndicatorService,
    private renderer: Renderer2
  ) {
    /* this.regularUsersService.currentUser.subscribe((user) => {
      this.currentUser = user;
      this.patient = user.patient;
    }); */
  }
  public onIntersection({
    target,
    visible,
  }: {
    target: Element;
    visible: boolean;
  }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');
  }
  ngOnChanges(changes: SimpleChanges): void {
    var docId = changes.docId?.currentValue;
    var oldDocId = changes.docId?.previousValue;
    if (docId != oldDocId) {
      this.chatRoomKey = null;
      this.doDestroyActions();
      this.setChatIndicator();
      this.listenToChatRoom(docId);
    }
  }
  ngOnDestroy(): void {
    this.doDestroyActions();
    this.docId = null;
    // this.chatIndicatorCrmSubscription.unsubscribe();
  }
  doDestroyActions() {
    if (this.chatIndicatorSubscription)
      this.chatIndicatorSubscription.unsubscribe();
    if (this.chatroomSubscription) this.chatroomSubscription.unsubscribe();

    //
  }

  ngAfterViewChecked() {
    if (this.chatRoom && this.chatLength != this.chatRoom.length) {
      this.chatLength = this.chatRoom.length;
      var chatScrollerEl = (
        this.chatScroller.directiveRef.elementRef as ElementRef
      ).nativeElement;
      chatScrollerEl.scrollTop = chatScrollerEl.scrollHeight;
    }
  }
  byId(index, item) {
    return item.id;
  }
  ngOnInit(): void {
    this.chatMessage = '';

    if (this.docId) {
      this.setChatIndicator();
      this.listenToChatRoom(this.docId);
    }
  }
  setChatIndicator() {
    this.chatIndicatorSubscription = this.firestore
      .collection('chatIndicator')
      .doc(`${this.docId}`)
      .valueChanges()
      .subscribe((res: any) => {
        if (res) {
          this.chatIndicator = res;
          if (this.chatIndicator.countCrm == NaN) {
            this.chatIndicator.countCrm = 0;
          }
          if (this.chatIndicator.countSite == NaN) {
            this.chatIndicator.countSite = 0;
          }

          if (this.chatIndicator.countCrm !== 0) {
            this.firebaseService.setChatIndicator(
              'chatIndicator',
              `${this.docId}`,
              {
                countCrm: 0,
                //   countSite: this.chatIndicator.countSite,
                //   displayName: this.chatIndicator.displayName,
                //   clientName: this.chatIndicator.clientName,
                seenBy: [1, 2], //from crm
              }
            );
          }
        } else {
          this.chatIndicator = new IndicatorMsg();
          this.chatIndicator.countCrm = 0;
        }
      });
  }
  listenToChatRoom(docId) {
    this.chatroomSubscription = this.firestore
      .collection(`chatroom_${docId}`)
      .snapshotChanges()
      .subscribe((res) => {
        if (this.chatRoomKey && res[0].payload.doc.id != this.chatRoomKey) {
          return;
        }
        if (!this.chatRoomKey) {
          this.chatRoomKey = res[0].payload.doc.id;
        }

        this.chatRoom = res.map((x) => {
          var key = x.payload.doc.id;
          var val: any = x.payload.doc.data();
          val.key = key;
          return val;
          // this.chatIndicatorCrm[x.payload.doc.id] = x.payload.doc.data();
        });

        /* console.log(
          'chatRoom:key ',
          this.chatRoom[2].key,
          'docId: ',
          this.docId,
          'chatRoom:regularUserId ',
          this.chatRoom[2].regularUserId,

          'serviceRequest: regularUserId ',
          this.serviceRequest.regularUserId
        );
        console.log('chatRoom', this.chatRoom, ' docId: ', this.docId);*/
        if (this.serviceRequest)
          this.chatRoom = this.chatRoom.filter(
            (x) =>
              (x as ChatMsg).regularUserId ==
                this.serviceRequest.regularUserId ||
              (x as ChatMsg).sentFrom == 2
          );

        this.chatRoom.forEach((msg: ChatMsg) => {
          if (!msg.seen && msg.sentFrom == 1) {
            // set msg to seen if comes from site
            var key = msg.key;
            delete msg.key; // remove the key from the msg object,
            // we neded the key in order to know what doc key is
            // so we created the object with the key inside
            // but we dont want to store the key in the firebase object
            msg.seen = true;
            this.firestore
              .collection(`chatroom_${this.docId}`)
              .doc(key)
              .set(msg);
          }
        });

        if (!this.chatRoom || this.chatRoom.length != res.length) {
          var chatScrollerEl = (
            this.chatScroller.directiveRef.elementRef as ElementRef
          ).nativeElement;
          chatScrollerEl.scrollTop = chatScrollerEl.scrollHeight;
        }

        var chatRoomDatesObj = {};
        this.chatRoomDates = [];
        this.chatRoom.forEach((x: any) => {
          var createdDay = getDayV2(x.createdDate);
          if (!chatRoomDatesObj[createdDay]) {
            chatRoomDatesObj[createdDay] = [];
          }
          chatRoomDatesObj[createdDay].push(x);
        });

        Object.values(chatRoomDatesObj).forEach((x) => {
          this.chatRoomDates.push(x);
        });

        this.chatRoomDates.sort((a, b) => {
          var x = moment(a[0].createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
          var y = moment(b[0].createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
          return x > y ? 1 : -1;
        });

        this.chatRoomDates.forEach((x) => {
          x.forEach((y: any) => {
            y.createdDate = moment(y.createdDate, 'DD/MM/YYYY HH:mm:ss').format(
              'YYYY-MM-DD[T]HH:mm:ss'
            );
          });
          x.sort((a: any, b: any) => {
            var x = new Date(a.createdDate);
            var y = new Date(b.createdDate);
            return x.getTime() > y.getTime() ? 1 : -1;
          });
        });
      });
  }

  closeEmotion() {
    if (this.emojis) {
      this.emojis = !this.emojis;
    }
  }
  preventCloseEmoticons(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  addEmoji(event: any) {
    this.chatMessage += event.emoji.native;
  }
  openEmoticons(e: any) {
    e.preventDefault();
    e.stopPropagation();

    this.emojis = !this.emojis;
  }

  getfullName(): string {
    var firstName = '';
    var lastName = '';
    if (this.user.firstName) firstName = this.user.firstName;
    if (this.user.lastName) lastName = this.user.lastName;
    if (lastName === '') return firstName;
    if (firstName === '') return lastName;
    return `${firstName} ${lastName}`;
  }
  dateDivider(date) {
    var TODAY = moment(new Date()).clone().startOf('day');
    var YESTERDAY = moment(new Date())
      .clone()
      .subtract(1, 'days')
      .startOf('day');

    var isToday = moment(date).isSame(TODAY, 'd');
    var isYesterday = moment(date).isSame(YESTERDAY, 'd');
    if (isToday) return 'היום';
    else if (isYesterday) return 'אתמול';
    else return moment(date).format('DD/MM/YYYY');
  }

  sendMessage() {
    this.chatMessage = this.chatMessage.trim();
    if (!this.chatMessage || this.chatMessage.length == 0) return;
    var now = moment(new Date().toUTCString()).format('DD/MM/YYYY HH:mm:ss');
    this.firebaseService.setChatIndicator('chatIndicator', `${this.docId}`, {
      countCrm: 0,
      countSite: ++this.chatIndicator.countSite,
      createdDate: now,
      msg: this.chatMessage,
      displayName: this.getfullName(),
      clientName: this.chatIndicator.clientName,
      seenBy: [2], //from crm
      sentFrom: 2, //from crm
      isGQ: false,
      avatarAgentId: this.letter,
    });

    this.firestore.collection(`chatroom_${this.docId}`).add({
      displayName: this.user.firstName,
      massage: this.chatMessage,
      regularUserId: this.user.regularUserId,
      sentFrom: 2, //crm-user,
      createdDate: now,
      seen: false,
      avatarId: this.letter,
    });
    this.chatMessage = '';
    this.chatInput.nativeElement.rows = 1;
    this.emojis = false;
  }

  isChatActive() {
    return true;
    /* return (
      this.activeRequest.statusId == RequestEnum.Open ||
      this.activeRequest.statusId == RequestEnum.InProgress
    ); */
  }
  getIsSeenTxt(chat: ChatMsg) {
    if (chat.sentFrom == 2) {
      if (chat.seen) {
        return '<img src="../../../assets/vv.svg" alt="" />';
      } else {
        return '<img src="../../../assets/v-not-seen.svg" alt="" />';
      }
    }
    /* if (
      chat.regularUserId !=
      this.activeRequest.product.patient.regularUser.regularUserId
    ) {
      return !chat.seenBy ||
        !chat.seenBy.includes(
          this.activeRequest.product.patient.regularUser.regularUserId
        )
        ? 'not seen'
        : 'seen';
    } else return ''; */
  }
}
