import { Injectable } from '@angular/core';
import { PermissionForRole } from '../data/PermissionForRole';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionsForRolesService {

  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<PermissionForRole[]>(`${environment.baseUrl}PermissionForRoles/`);
  }
  getById(agentId) {
    return this.http.get<PermissionForRole>(`${environment.baseUrl}PermissionForRoles/${agentId}`);
  }
  put(agent) {
    return this.http.put(`${environment.baseUrl}PermissionForRoles/${agent.agentId}`, agent);
  }
  post(agent) {
    return this.http.post<PermissionForRole>(`${environment.baseUrl}PermissionForRoles/`, agent);
  }
  delete(permissionForRole: PermissionForRole) {
    return this.http.delete(`${environment.baseUrl}PermissionForRoles/${permissionForRole.permissionId}/${permissionForRole.roleId}`);
  }

}
