import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { AgentMin } from 'src/app/data/AgentMin';
import { DealForTableMin } from 'src/app/data/DealForTableMin';
import { DealsSummery } from 'src/app/data/DealsSummery';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { DealsPusherService } from 'src/app/Services/deals-pusher.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { PaymentMethodService } from 'src/app/Services/payment-method.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-all-transactions',
  templateUrl: './all-transactions.component.html',
  styleUrls: ['./all-transactions.component.scss'],
})
export class AllTransactionsComponent implements OnInit {
  checkedIds: any;
  defaultChoice;
  agents: AgentMin[];
  leadsData;
  leadSources;
  paymentMethods;
  statuses: Status[];
  headFilter: HeadFilter;
  metaData: PaginationMetadata;

  transactionData: DealForTableMin[];
  checkedAll: boolean = false;
  dealsSummery: DealsSummery;
  regularUser: RegularUser;
  isLoading: boolean;
  constructor(
    private dealsPusherService: DealsPusherService,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    private leadSourcesService: LeadSourcesService,
    private paymentMethodService: PaymentMethodService,
    private filterMemoryService: FilterMemoryService,
    private regularUsersService: RegularUsersService
  ) {
    this.checkedIds = {};
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.transactions
    );
    /* this.headFilter = new HeadFilter(
      [],
      -1,
      -1,
      null,
      null,
      -1,
      '',
      false,
      100,
      1,
      -1
    ); */
  }
  /*
  getDealsSummery() {
    this.dealsService.getSummery(this.headFilter).subscribe((summery) => {
      this.dealsSummery = summery;
    });
 
  }*/
  getAll() {
    this.isLoading = true;
    this.dealsPusherService.getAllV2(this.headFilter);
  }
  ngOnInit(): void {
    this.dealsPusherService.deals$.subscribe((data) => {
      if (data && data.list && data.metaData) {
        this.transactionData = data.list;
        console.log('transactionData', this.transactionData);

        this.metaData = data.metaData;
        this.isLoading = false;
      } else {
        this.transactionData = [];
      }
    });
    this.dealsPusherService.dealsSummery$.subscribe((data) => {
      this.dealsSummery = data;
    });
    this.getAll();

    this.paymentMethodService.getAll().subscribe((data) => {
      this.paymentMethods = data;
    });
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      this.statusesService.getAll().subscribe((data) => {
        //  this.statuses = data;
        this.statuses = data;
        this.statuses = this.statuses.filter(
          (x) => x.category == 'Deal' /* {
          return (
           
            ( &&
              userHasPermisions(['All Transactions'], this.regularUser)) ||
            x.statusId == DealEnum.Done ||
            x.statusId == DealEnum.Canceled ||
            x.statusId == DealEnum.Cancelation 
          );
        } */
        );
      });
    });

    this.leadSourcesService.getAll().subscribe((data) => {
      this.leadSources = data;
    });
  }
  currentCheckedValue = null;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  moveToEditScreen() {}
  triggerToggleClick() {}
  date;
  selectedRadio: number = -1;
  radioBtn;
  editLead(a, b) {}
  isChecked(event, clientId, checked) {
    // isChecked(event, el, inx) {
    if (checked) {
      this.checkedIds[clientId] = true;
    } else {
      delete this.checkedIds[clientId];
    }

    /*
    el.isChecked = !el.isChecked;
    if (!el.isChecked) {
      this.removeAllRadios();
    }

    el.isChecked = !el.isChecked;
    */
    /*
    setTimeout(() => {
      if (true) {
        // if (this.currentCheckedValue && this.currentCheckedValue === inx)
        el.checked = false;
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(
          el['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
        this.currentCheckedValue = null;
        // this.favoriteSeason = null;
      } else {
        this.currentCheckedValue = inx;
      }
    });
    */
  }

  removeAllRadios() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }
  displaySideEdit() {}
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    if (isFromHead) {
      this.checkedIds = {};
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }
    if (isFormPaginator) {
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }
    this.headFilter = e;
    this.filterMemoryService.setfilter(
      this.headFilter,
      PageTypeEnum.transactions
    );
    this.getAll();
  }
}
