import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Category } from '../data/Category';
import { CategoryForApp } from '../data/CategoryForApp';
import { TubeTestCategoryResult } from '../data/TubeTestCategoryResult';
import { TubeTestSubCategoryResult } from '../data/TubeTestSubCategoryResult';

@Injectable({
  providedIn: 'root',
})
export class CatergoriesService {
  constructor(private http: HttpClient) {}
  onlyCategories() {
    return this.http.get<Category[]>(
      `${environment.baseUrl}Categories/OnlyCategories/`
    );
  }
  getAll(productId: number, langId: number = 1) {
    return this.http.get<CategoryForApp[]>(
      `${environment.baseUrl}Categories/ForAppLang/${productId}/${langId}`
    );
  }
  getByCategoryId(productId: number, categoryId: number, langId: number = 1) {
    return this.http.get<any>(
      `${environment.baseUrl}Categories/SingleForAppLang/${productId}/${categoryId}/${langId}`
    );
  }

  getAllV1(productId) {
    return this.http.get<TubeTestCategoryResult[]>(
      `${environment.baseUrl}Categories/ForApp/${productId}`
    );
  }
  getByCategoryIdV1(productId: number, categoryId: number) {
    return this.http.get<TubeTestSubCategoryResult[]>(
      `${environment.baseUrl}Categories/SingleForApp/${productId}/${categoryId}`
    );
  }
}
