import { DatePipe } from '@angular/common';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Agent } from 'src/app/data/Agent';
import { Note } from 'src/app/data/Note';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { TimeUtilsService } from 'src/app/Services/time-utils.service';

@Component({
  selector: 'app-client-create-call',
  templateUrl: './client-create-call.component.html',
  styleUrls: ['./client-create-call.component.css'],
})
export class ClientCreateCallComponent implements OnInit, OnChanges {
  @Input() note: Note;
  @Input() agentUser: RegularUser;
  @Input() subjects: Status[];
  @Input() isFollowUp:boolean;
  @Output('cancelCall') cancelCall: EventEmitter<any> = new EventEmitter();
  @Output('submitCall') submitCall: EventEmitter<any> = new EventEmitter();
  scrollDown:boolean=false;

  constructor(
    private timeUtilsService: TimeUtilsService,
    private datepipe: DatePipe,
   
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.note) {
      this.createTaskFormGroup();
    }
    
  }

  callForm: FormGroup = null;
  ngOnInit(): void {
    this.createTaskFormGroup();
  }


  onCancelNewCall() 
  {
    this.cancelCall.emit(true);
  }
  triggerToggleClick() {}
  getNow() {
    return new Date(Date.now());
  }

  saveCall() {
    if (this.callForm.valid) {
      //var note: Note = new Note();
      var date: Date = new Date();
      var momentDate = moment(date).toDate();
      var seconds = this.timeUtilsService.hoursMinutesMaskedToSeconds(
        date.getHours().toString()
      );
      date = this.timeUtilsService.setTime(momentDate, seconds);
      //date.setSeconds(seconds);
      //this.note.title = this.callForm.value.title;
      this.note.title = this.callForm.value.title;
      this.note.noteCategoryId = this.callForm.value.subject;
      this.note.statusId = 10; //OPEN
      this.note.noteTypeId = 4; //Task
      this.note.dueDate = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm');
      this.note.assignedAgentId = this.agentUser.agent.agentId;
      this.submitCall.emit(this.note);
    }
  }
  createTaskFormGroup() {
    if (!this.note) this.note = new Note();
    this.callForm = new FormGroup({
      subject: new FormControl(this.note.noteCategoryId, [Validators.required]),
      title: new FormControl(this.note.title),
    });
    var a = this.callForm.value;
    setTimeout(() => {}, 100);
  }
  /* get title() {
    return this.callForm.get('title');
  } */
  get title() {
    return this.callForm.get('title');
  }
  onNotAnswerCall()
  {
   // this.popup
  }
}
