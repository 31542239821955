import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideosService } from 'src/app/Services/videos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-algo-image',
  templateUrl: './algo-image.component.html',
  styleUrls: ['./algo-image.component.scss'],
})
export class AlgoImageComponent implements OnInit, OnChanges, OnDestroy {
  //#region Properties
  @ViewChild('inputFile') inputFile: ElementRef;
  @Output() onUploadFinished = new EventEmitter();
  @Input() algoImageType: number;
  @Input() selectedImg: string;
  @Input() checkedImgId: number;
  @Input() textBox: string = 'Add image';
  @Input() isRectangle: boolean;
  @Input() isBigRectangle: boolean;
  @Input() canEdit: boolean = true;
  @Input() langId: number;
  @Input() dir: string;
  @Input() iconName: string;
  @Input() iconSize: string;
  @Input() isVod: boolean;
  @Input() streamId: string;

  imgError: string;
  tempFormData: FormData;
  tempImgName: string;
  tempImg: string;
  isFoodCategoryImage: boolean;
  //#endregion
  constructor(
    private domSanitizer: DomSanitizer,
    private algorithmService: AlgorithmService,
    private videosService: VideosService,
    private popupHandlerService: PopupHandlerService
  ) {}
  ngOnDestroy(): void {
    this.tempImg = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedImg && changes.selectedImg.currentValue) {
      this.tempImg = this.replaceWebPath(changes.selectedImg.currentValue);
    } else if (changes.selectedImg && changes.selectedImg.currentValue === '') {
      this.tempImg = null;
    }
    if (changes.checkedImgId && changes.checkedImgId.currentValue) {
      this.checkedImgId = changes.checkedImgId.currentValue;
    }
  }

  ngOnInit(): void {
    //console.log('checkedImgId', this.checkedImgId);
    //console.log('selectedImg', this.selectedImg);
    if (this.selectedImg) {
      this.tempImg = this.replaceWebPath(this.selectedImg);
    }
    if (this.algoImageType === AlgoImageType.FoodCategoryImage) {
      this.isFoodCategoryImage = true;
    }
    if (!this.streamId || this.streamId === '') {
      this.streamId = '0';
    }
  }
  openFileManager() {
    if (!this.canEdit) return;
    this.algorithmService
      .getFilesByType(this.algoImageType, this.langId)
      .subscribe((data) => {
        let images = data;
        images = images.map((x) => {
          x.webPath = this.replaceWebPath(x.webPath);
          return x;
        });
        this.popupHandlerService.openFilesManagerDialog(
          images,
          this.checkedImgId
        );
      });

    this.popupHandlerService.filesManagerSubject
      .pipe(take(1))
      .subscribe(({ event, selectedImg, formData }) => {
        if (event === 'upload') {
          if (this.isVod) {
            this.uploadVodFile(
              formData,
              this.algoImageType,
              this.streamId,
              this.langId
            );
          } else {
            this.uploadAlgoImage(formData, this.algoImageType, this.langId);
          }
        } else if (event === 'add') {
          const appImage: AppImage = selectedImg;
          const imagePath = `${appImage.webPath}/${appImage.fileName}`;
          this.tempImg = this.replaceWebPath(imagePath);
          this.onUploadFinished.emit(selectedImg);
        }
      });
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  sanitizePreviewSrc(src) {
    return this.domSanitizer.bypassSecurityTrustUrl(src);
  }

  uploadAlgoImage(data: any, type: number, langId: number) {
    this.algorithmService
      .uploadAlgoImage(data, type, langId)
      .subscribe((res) => {
        if ((res as ErrorMessage)?.message) {
          this.imgError = (res as ErrorMessage).message;
        } else {
          this.onUploadFinished.emit(res);
          //this.openFileManager();
        }
      });
  }
  uploadVodFile(data: any, type: number, streamId: string, langId: number) {
    this.videosService
      .uploadVodFile(data, type, streamId, langId)
      .subscribe((res) => {
        if ((res as ErrorMessage)?.message) {
          this.imgError = (res as ErrorMessage).message;
        } else {
          this.onUploadFinished.emit(res);
          this.openFileManager();
        }
      });
  }
}
