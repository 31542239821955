import { Component, OnInit, Input } from '@angular/core';
import { Note } from 'src/app/data/Note';

@Component({
  selector: 'app-lead-tasks',
  templateUrl: './lead-tasks.component.html',
  styleUrls: ['./lead-tasks.component.css'],
})
export class LeadTasksComponent implements OnInit {
  constructor() {}
  @Input() notes: Note[];
  ngOnInit(): void {}
}
