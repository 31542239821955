export class FgSnp {
  fgSnpId: number;
  fgversionId: number;
  prototypeSnpNumber: number;
  gene: string;
  coordinate: string;
  chromosome: string;
  firstLetter: string;
  secondLetter: string;
}
