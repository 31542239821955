import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PermissionCategory } from '../data/PermissionCategory';

@Injectable({
  providedIn: 'root',
})
export class PermissionCategoriesService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<PermissionCategory[]>(
      `${environment.baseUrl}PermissionCategories/`
    );
  }
  /*
  getById(permisionCategoryId) {
    return this.http.get<PermissionCategory>(
      `${environment.baseUrl}PermissionCategories/${permisionCategoryId}`
    );
  }
  put(permissionCategory: PermissionCategory) {
    return this.http.put(
      `${environment.baseUrl}PermissionCategories/${permissionCategory.permisionCategoryId}`,
      permissionCategory
    );
  }
  post(permissionCategory: PermissionCategory) {
    return this.http.post<PermissionCategory>(
      `${environment.baseUrl}PermissionCategories/`,
      permissionCategory
    );
  }
  delete(permissionCategory: PermissionCategory) {
    return this.http.delete(
      `${environment.baseUrl}PermissionCategories/${permissionCategory.permisionCategoryId}`
    );
  }
  */
}
