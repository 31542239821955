<mat-list
  class="testsTable"
  role="list"
  [style.padding-bottom.px]="isSearchPage ? '68' : ''"
>
  <mat-list-item class="testsTable__header" role="listitem">
    <span
      style="width: 2.5%"
      *ngIf="
        userHasPermision([
          'Products_ChangeStatusBulk',
          'Products_ChangeAgentBulk'
        ])
      "
    >
      <mat-checkbox
        class="testsTable__item__checkbox"
        (change)="checkAll($event)"
        [(ngModel)]="checkedAll"
      >
      </mat-checkbox>
    </span>
    <ng-container *ngFor="let item of headersShow">
      <span
        [ngStyle]="{ width: item.width }"
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
      >
        <span (click)="changeSortBy(item.val)">{{ item.name }}</span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
  </mat-list-item>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{
        height: '50px',
        'margin-bottom': '10px',
        'background-color': 'rgba(27, 63, 78,0.15)'
      }"
      count="10"
    ></ngx-skeleton-loader>
  </div>
  <ng-container *ngIf="!isLoading">
    <mat-card class="testsTable__item" *ngFor="let productItem of productsData">
      <mat-list-item role="listitem">
        <span
          *ngIf="
            userHasPermision([
              'Products_ChangeStatusBulk',
              'Products_ChangeAgentBulk'
            ])
          "
          style="width: 2.5%"
        >
          <mat-checkbox
            class="testsTable__item__checkbox"
            (click)="stopPropegation($event)"
            (change)="
              isChecked($event, productItem.productId, productItem.isChecked)
            "
            [(ngModel)]="productItem.isChecked"
          >
          </mat-checkbox>
        </span>
        <span class="d-flex-center">
          <img
            *ngIf="productItem.productTypeId == 2"
            class="mr-1"
            src="../../../assets/Nu_Original.svg"
            alt="s"
          />
          <img
            *ngIf="productItem.productTypeId == 1"
            class="mr-1"
            src="../../../assets/Nu_Plus.svg"
            alt="s"
          />
          <ng-container *ngIf="!isFollowUpAgent">{{
            productItem.barCode
          }}</ng-container>

          <!-- + " " + leadItem.regularUser.lastName --></span
        >
        <span style="width: 160px">{{
          productItem.registeredDate | date: "dd-MM-yyyy"
        }}</span>
        <span>{{ productItem.patientName }}</span>
        <!--  <span>{{ productItem.patientIdNum }}</span> -->
        <!-- <span>{{ productItem.clientIdNum }}</span>-->
        <span>{{ productItem.patientPhone }}</span>
        <span style="width: 160px" *ngIf="!isFollowUpAgent">
          <span
            *ngIf="!canChangeStatus()"
            style="
              padding: 8px 5px;
              border-radius: 25px 25px;
              font-size: 14px;
              color: #fff;
              display: block;
            "
            [ngStyle]="{ 'background-color': productItem.status?.color }"
          >
            {{ productItem.status.name }}
          </span>
          <mat-form-field
            *ngIf="canChangeStatus()"
            class="example-full-width round-select"
            style="width: 160px"
          >
            <mat-select
              (click)="stopPropegation($event)"
              class="lead-status-select"
              disableOptionCentering
              (selectionChange)="saveStatusForProduct($event, productItem)"
              [(ngModel)]="productItem.statusId"
              style="text-align: center"
              [ngStyle]="{ 'background-color': productItem.status?.color }"
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                style="text-align: center"
              >
                <!-- class="lead-status lead-status{{ status.statusId }}"-->
                {{ status.name }}
              </mat-option>
              <!--background-color: {{ status.color }}-->
            </mat-select>
          </mat-form-field>
        </span>

        <!--
      <span class="round-indicator"
        style="background-color:{{ productItem.status.color }}">{{ productItem.status.name }}</span>
-->
        <span style="width: 170px">
          <span *ngIf="!showAgentFilter()"
            >{{ productItem.agent ? productItem.agent.name : "not Assigned" }}
          </span>
          <app-autocomplete-agent
            *ngIf="showAgentFilter()"
            (selectedValue)="saveAgentForProduct($event, productItem)"
            [data]="agents"
            [initialValue]="getAgentNameById(productItem.agentId)"
            [widthExp]="160"
            [label]="'Select nutritionist'"
          ></app-autocomplete-agent>
          <!-- <mat-form-field
          *ngIf="showAgentFilter(productItem)"
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            placeholder="select"
            (click)="stopPropegation($event)"
            (selectionChange)="saveAgentForProduct($event, productItem)"
            matNativeControl
            disableOptionCentering
            [(ngModel)]="productItem.agentId"
          >
            <mat-option style="text-align: center" [value]="-1">
              Not Assigned
            </mat-option>
            <mat-option
              *ngFor="let agent of nutrisionists"
              style="text-align: center"
              [value]="agent.agentId"
            >
              {{ agent.name }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        </span>
        <span style="width: 170px">
          <span *ngIf="!showAgentFilter()"
            >{{ productItem.agent2 ? productItem.agent2.name : "not Assigned" }}
          </span>
          <app-autocomplete-agent
            *ngIf="showAgentFilter()"
            (selectedValue)="saveAgent2ForProduct($event, productItem)"
            [data]="followUpAgents"
            [initialValue]="getFollowUpAgentNameById(productItem.agent2Id)"
            [widthExp]="160"
            [label]="'Follow-up agent'"
          ></app-autocomplete-agent>
        </span>
        <span style="width: 140px" *ngIf="!isFollowUpAgent">
          <span
            style="cursor: default; margin-right: unset"
            *ngIf="!lifestyleReportDone(productItem)"
            class="round-indicator test"
            >Missing</span
          >
          <span
            style="display: flex; justify-content: center"
            *ngIf="lifestyleReportDone(productItem)"
            class="Vcontainer"
          >
            <img src="../../../assets/V.svg" />
          </span>
        </span>
        <span *ngIf="!isFollowUpAgent" style="width: 100px">
          <!-- 
        <span
          style="margin-left: 30px; cursor: default"
          *ngIf="productItem.firstCall == 1"
          class="round-indicator testBlue"
          >Not Required</span
        >
        -->
          <span
            (click)="changeFirstCallToTrue(productItem)"
            style="cursor: pointer"
            *ngIf="productItem.firstCall == false"
            class="round-indicator testBlue"
            ><strong>&ndash;</strong></span
          >
          <span
            (click)="changeFirstCallToFalse(productItem)"
            *ngIf="productItem.firstCall == true"
            class="Vcontainer"
          >
            <img src="../../../assets/Vblue.svg" />
          </span>
        </span>
        <span style="width: 100px">
          <!-- 
        <span
          style="margin-left: 30px; cursor: default"
          *ngIf="productItem.firstCall == 1"
          class="round-indicator testBlue"
          >Not Required</span
        >
        -->
          <span
            (click)="changeOnFollowToTrue(productItem)"
            style="cursor: pointer; width: 100px"
            *ngIf="productItem.onFollow == false"
            class="round-indicator testBlue"
            ><strong>&ndash;</strong></span
          >
          <span
            (click)="changeOnFollowToFalse(productItem)"
            *ngIf="productItem.onFollow == true"
            class="Vcontainer"
            style="width: 100px"
          >
            <img src="../../../assets/Vblue.svg" />
          </span>
        </span>
        <span style="text-align: right; width: 80px; margin-left: auto"
          ><button
            *ngIf="productItem.hasPatient && canEdit()"
            mat-button
            (click)="editTest($event, productItem)"
          >
            <img src="../../../../assets/test-edit.svg" alt="edit" /></button
        ></span>
      </mat-list-item>
    </mat-card>
  </ng-container>
</mat-list>
