import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { LabResultPackage } from 'src/app/data/algorithm/LabResultPackage';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { Location } from '@angular/common';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { I } from '@angular/cdk/keycodes';
import { take } from 'rxjs/operators';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-lab-packeges',
  templateUrl: './lab-packeges.component.html',
  styleUrls: ['./lab-packeges.component.scss'],
})
export class LabPackegesComponent implements OnInit {
  langId: number = 1;
  labId: number = 0;
  labPackages: LabResultPackage[];
  displayedColumns: string[] = ['fileName', 'lab', 'version', 'date', 'action'];
  dataSource: MatTableDataSource<LabResultPackage> = new MatTableDataSource([]);
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  regularUser: RegularUser;
  constructor(
    private algorithmService: AlgorithmService,
    public location: Location,
    private _matSnackBar: MatSnackBar,
    private filterMemoryService: FilterMemoryService,
    private popupHandlerService: PopupHandlerService,
    private regularUserService: RegularUsersService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.snps);
  }

  ngOnInit(): void {
    this.getLabPackeges();
    this.getCurrentUser();
  }

  setDataSource(data: LabResultPackage[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
    });
  }
  userCanRecalc() {
    return userHasPermisions(
      ['Algorithm_Create', 'Algorithm_Update', 'Algorithm_Delete'],
      this.regularUser
    );
  }
  recalcVersion(packageId: number) {
    this.popupHandlerService.openConfirmationDialog(
      'Are you sure you want to recalculate this package results?',
      'Recalculate package version'
    );
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          this.algorithmService
            .recalculateResultsByPackage(packageId)
            .subscribe(() => {
              this.getLabPackeges();
              this._matSnackBar.open('Reaclaculate successfuly', '', {
                duration: 2000,
              });
            });
        }
      });
  }

  getLabPackeges() {
    // this.headFilter.pageNumber = this.headFilter?.pageNumber || 1;
    this.algorithmService
      .getLabResultPackagesWithPaging(
        this.langId,
        this.headFilter.pageNumber,
        this.headFilter.pageSize
      )
      .subscribe((data) => {
        this.labPackages = data.packages;
        this.metaData = data.pagination;
        this.setDataSource(this.labPackages);
      });
  }
  getPaginatorData(event: HeadFilter) {
    this.headFilter = event;
    this.getLabPackeges();
  }
}
