import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallsReviewsEditService {
  onEdit = new BehaviorSubject<any>(null);
  readonly onEdit$ = this.onEdit.asObservable();
  constructor() {}
}
