import { Agent } from './Agent';
import { Meal } from './Meal';
import { MenuToNutrient } from './MenuToNutrient';
import { Product } from './Product';

export class Menu {
  menuId: number;
  name: string;
  isTemplate: boolean;
  productId: number | null;
  product: Product;
  createdByAgentId: number | null;
  createdByAgent: Agent;
  updatedByAgentId: number | null;
  updatedByAgent: Agent;
  isComplete: boolean;
  dateCreated: string;
  dateUpdated: string;
  notes: string;
  geneticRate: number;
  meals: Meal[];
  menuToNutrients: MenuToNutrient[];
}
