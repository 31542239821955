import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NameAndId } from 'src/app/data/NameAndId';
@Component({
  selector: 'app-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
})
export class AutocompleteAddressComponent implements OnInit {
  @ViewChild('value') valueInput: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @Input() data: NameAndId[];
  @Input() label: string;
  @Input() initialValue: string;
  @Input() widthExp: string;
  @Output() selectedValue: EventEmitter<NameAndId> =
    new EventEmitter<NameAndId>();
  @Output() searchTerm: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;
    this.searchTerm.emit(search);
  }

  focusInput() {
    this.inputField.nativeElement.focus();
  }

  onValueSelected(selectedValue: NameAndId) {
    this.valueInput.nativeElement.value = selectedValue;
    /* var valuesDup = [...this.data];
    var value = valuesDup.filter((val) => {
      return val == selectedValue;
    }); */
    this.selectedValue.emit(selectedValue);
    //console.log('sv:', selectedValue);
  }
}
