import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-product-thumb-view',
  templateUrl: './client-product-thumb-view.component.html',
  styleUrls: ['./client-product-thumb-view.component.css']
})
export class ClientProductThumbViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
