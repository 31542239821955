import { FoodNutrientLang } from './FoodNutrientLang';
import { Status } from './Status';

export class FoodNutrient {
  foodNutrientId: number;
  originId: number | null;
  sourceKey: string;
  unitName: string;
  nutrientTypeId: number;
  nutrientType: Status;
  foodNutrientLangs: FoodNutrientLang[];
}
