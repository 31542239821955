import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModuleAction } from '../data/ModuleAction';
import { ModuleToAction } from '../data/ModuleToAction';

@Injectable({
  providedIn: 'root',
})
export class ModuleToActionsService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<ModuleToAction[]>(
      `${environment.baseUrl}ModuleToActions/`
    );
  }
  forModule(moduleId) {
    return this.http.get<ModuleAction[]>(
      `${environment.baseUrl}ModuleToActions/ForModule/${moduleId}`
    );
  }
  updateChecked(moduleId, actionId, checked) {
    return this.http.get<any>(
      `${environment.baseUrl}ModuleToActions/UpdateChecked/${moduleId}/${actionId}/${checked}`
    );
  }
  delete(moduleToActionId: number) {
    return this.http.delete<ModuleToAction>(
      `${environment.baseUrl}ModuleToActions/${moduleToActionId}`
    );
  }
  put(moduleToAction: ModuleToAction) {
    return this.http.put<ModuleToAction>(
      `${environment.baseUrl}ModuleToActions/${moduleToAction.moduleToActionId}`,
      moduleToAction
    );
  }
  post(moduleToAction: ModuleToAction) {
    return this.http.post<ModuleToAction>(
      `${environment.baseUrl}ModuleToActions`,
      moduleToAction
    );
  }
}
