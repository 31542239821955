import { CategoryForFood } from './CategoryForFood';
import { RecipeDirection } from './food-genes/RecipeDirection';
import { FoodLang } from './FoodLang';
import { FoodScaleValue } from './FoodScaleValue';
import { FoodSourceKey } from './FoodSourceKey';
import { FoodToNutrient } from './FoodToNutrient';
import { RecipeHeader } from './RecipeHeader';

export class Food {
  foodId: number;
  foodSourceKeyId: number;
  foodSourceKey: FoodSourceKey;
  dateCreated: string;
  originId: number | null;
  sourceKey: string;
  dataType: string;
  source: string;
  edible: string;
  isRecipe: boolean;
  isPublished: boolean;
  categoryForFood: CategoryForFood[];
  calorieFactorConversionProteinValue: number | null;
  calorieFactorConversionFatValue: number | null;
  calorieFactorConversionCarbohydrateValue: number | null;
  conversionProteinValue: number | null;
  isLocked: boolean;
  isNutrientsLock: boolean;
  numDishes: number | null;
  singleDishWeight: number | null;
  singleDishCalories: number | null;
  imageUrl: string;
  foodLangs: FoodLang[];
  foodScaleValues: FoodScaleValue[];
  recipeHeaders: RecipeHeader[];
  foodToNutrients: FoodToNutrient[];
  isCalcRecommendation: boolean;
  timeInMin: number;
  makingEffort: number;
  restId: number;
  foodCategoryId: number;
  recipeDirections: RecipeDirection[];
  isBasic: number;
  foodBarcode: string[];
}
