<div>
  <mat-form-field appearance="fill">
    <label>Name</label>
    <input
      matInput
      (change)="addActivity($event)"
      name="name"
      [(ngModel)]="name"
      placeholder="activity Type"
    />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <label>Duration</label>
    <input
      matInput
      (change)="addActivity($event)"
      name="duration"
      [(ngModel)]="duration"
      placeholder="activity Duration"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <label>Num in week</label>
    <input
      matInput
      name="numInWeek"
      (change)="addActivity($event)"
      [(ngModel)]="numInWeek"
      placeholder="number of days in week"
    />
  </mat-form-field>
</div>
