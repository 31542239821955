import { Component, OnInit, Input } from '@angular/core';
import { PatientDelivery } from 'src/app/data/PatientDeliveries';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Router } from '@angular/router';
import { PatientsService } from 'src/app/Services/patients.service';
import { PatientDeliveryMin } from 'src/app/data/PatientDeliveryMin';

@Component({
  selector: 'app-deliveries-table',
  templateUrl: './deliveries-table.component.html',
  styleUrls: ['./deliveries-table.component.css'],
})
export class DeliveriesTableComponent implements OnInit {
  headers = [
    { name: 'Patient Id', val: 'PatientId', width: '150px' },
    { name: 'Patient Name', val: 'PatientName', width: '150px' },
    { name: 'Created Date', val: 'RegisteredDate', width: '150px' },
    { name: 'Delivery Type', val: 'DeliveryType', width: '150px' },
    { name: 'Status', val: 'Status', width: '150px' },
    { name: 'Response', val: 'Response', width: '150px' },
  ];
  @Input() displayedColumns: string[];
  @Input() patientDeliveries: PatientDeliveryMin[];
  @Input() headFilter: HeadFilter;
  @Input() statusFilter;
  constructor(
    private router: Router,
    private patientsService: PatientsService
  ) {}

  ngOnInit(): void {}
  changeSortOrder() {}
  changeSortBy(a) {}
  resend(delivery: PatientDeliveryMin) {
    this.patientsService
      .resendDelivery(delivery.patientId, delivery.deliveryType)
      .subscribe((x) => {});
  }
  goToPatient(delivery: PatientDeliveryMin) {
    this.patientsService
      .getPatientsProducts(delivery.patientId)
      .subscribe((data) => {
        var productId = data.find((x) => x.productType.productGroupId == 1)
          .productId;
        this.router.navigate(['/tests/edit/'], {
          queryParams: { productId: productId },
        });
      });
  }
  gotoDeliveryDetails(delivery: PatientDelivery) {
    this.router.navigate(['/deliveriesResponses'], {
      queryParams: { deliveryId: delivery.patientDeliveryId },
    });
  }
}
