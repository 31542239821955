import { Injectable } from '@angular/core';
import { PageTypeEnum } from '../Enums/PageTypeEnum';
import { HeadFilter } from '../data/HeadFilter';

@Injectable({
  providedIn: 'root',
})
export class FilterMemoryService {
  leadsHeadFilter: HeadFilter;
  clientsHeadFilter: HeadFilter;
  testsHeadFilter: HeadFilter;
  transactionsHeadFilter: HeadFilter;
  serviceRequestsHeadFilter: HeadFilter;
  cancelationsHeadFilter: HeadFilter;
  refundsHeadFilter: HeadFilter;
  algoBulletHeadFilter: HeadFilter;
  callsReviewsHeadFilter: HeadFilter;
  foodsHeadFilter: HeadFilter;
  videosHeadFilter: HeadFilter;
  snpsHeadFilter: HeadFilter;
  translationHeadFilter: HeadFilter;
  constructor() {}
  getfilter(type): HeadFilter {
    switch (type) {
      case PageTypeEnum.leads:
        return this.leadsHeadFilter
          ? this.leadsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.clients:
        return this.clientsHeadFilter
          ? this.clientsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.tests:
        return this.testsHeadFilter
          ? this.testsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.transactions:
        return this.transactionsHeadFilter
          ? this.transactionsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.serviceRequests:
        return this.serviceRequestsHeadFilter
          ? this.serviceRequestsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.cancelations:
        return this.cancelationsHeadFilter
          ? this.cancelationsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.refunds:
        return this.refundsHeadFilter
          ? this.refundsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.algoBullets:
        return this.algoBulletHeadFilter
          ? this.algoBulletHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.callsReviews:
        return this.callsReviewsHeadFilter
          ? this.callsReviewsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.foods:
        return this.foodsHeadFilter
          ? this.foodsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.foodCategories:
        return this.foodsHeadFilter
          ? this.foodsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.videos:
        return this.videosHeadFilter
          ? this.videosHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.snps:
        return this.snpsHeadFilter
          ? this.snpsHeadFilter
          : this.getDefaultFilter(type);
      case PageTypeEnum.translation:
        return this.translationHeadFilter
          ? this.translationHeadFilter
          : this.getDefaultFilter(type);
    }
    return;
  }
  getDefaultFilter(type = null): HeadFilter {
    switch (type) {
      case PageTypeEnum.cancelations:
        return new HeadFilter(
          [],
          -1,
          -1,
          -1,
          null,
          null,
          -1,
          'RegisteredDate',
          false,
          15,
          1,
          -1,
          -1,
          -1,
          false,
          '',
          2,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1
        );
      case PageTypeEnum.foods:
        return new HeadFilter(
          [],
          -1,
          -1,
          -1,
          null,
          null,
          -1,
          'RegisteredDate',
          false,
          100,
          1,
          -1,
          -1,
          -1,
          false,
          '',
          2,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1
        );
      default:
        return new HeadFilter(
          [],
          -1,
          -1,
          -1,
          null,
          null,
          -1,
          'RegisteredDate',
          false,
          15,
          1,
          -1,
          -1,
          -1,
          false,
          '',
          false,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1,
          -1
        );
    }
  }
  setfilter(headFilter: HeadFilter, type) {
    switch (type) {
      case PageTypeEnum.leads:
        this.leadsHeadFilter = headFilter;
        break;
      case PageTypeEnum.clients:
        this.clientsHeadFilter = headFilter;
        break;
      case PageTypeEnum.tests:
        this.testsHeadFilter = headFilter;
        break;
      case PageTypeEnum.transactions:
        this.transactionsHeadFilter = headFilter;
        break;
      case PageTypeEnum.cancelations:
        this.cancelationsHeadFilter = headFilter;
        break;
      case PageTypeEnum.refunds:
        this.refundsHeadFilter = headFilter;
        break;
      case PageTypeEnum.algoBullets:
        this.algoBulletHeadFilter = headFilter;
        break;
      case PageTypeEnum.callsReviews:
        this.callsReviewsHeadFilter = headFilter;
        break;
      case PageTypeEnum.videos:
        this.videosHeadFilter = headFilter;
        break;
      case PageTypeEnum.snps:
        this.snpsHeadFilter = headFilter;
        break;
      case PageTypeEnum.translation:
        this.translationHeadFilter = headFilter;
        break;
    }
  }
}
