import { LifestyleAnswerMin } from './LifestyleAnswerMin';
import { LifeStyleCalculation } from './LifeStyleCalculation';
import { LifestyleQuestionLang } from './LifestyleQuestionLang';
import { LifeStyleReportVersion } from './LifeStyleReportVersion';
import { Status } from './Status';

export class LifestyleQuestion {
  lifestyleQuestionId: number;
  lifeStyleReportVersionId: number | null;
  lifeStyleReportVersion: LifeStyleReportVersion;
  parentQuestionId: number | null;
  parentQuestion: LifestyleQuestion;
  isRed: boolean;
  designTypeId: number | null;
  designType: Status;
  containerDesignTypeId: number | null;
  containerDesignType: Status;
  previewDesignTypeId: number | null;
  previewDesignType: Status;
  previewContainerDesignTypeId: number | null;
  previewContainerDesignType: Status;
  level: number;
  order: number;
  lifeStyleCalculationId: number | null;
  lifeStyleCalculation: LifeStyleCalculation;
  userAnswer: string;
  isExpanded: boolean;
  childQuestions: LifestyleQuestion[];
  lifeStyleAnswers: any[];
  lifestyleQuestionLangs: LifestyleQuestionLang[];
  referenceQuestionId: number | null;
  parentReferenceQuestion: LifestyleQuestion;
  childReferenceQuestions: LifestyleQuestion[];
  isChecked: boolean;
}
