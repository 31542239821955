import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { RegularUser } from 'src/app/data/RegularUser';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-enter-token',
  templateUrl: './enter-token.component.html',
  styleUrls: ['./enter-token.component.css'],
})
export class EnterTokenComponent implements OnInit {
  token;
  submited = false;
  accessTokenFromUrl: any;
  errorMsg: string;
  tokenForm;
  constructor(
    private regularUsersService: RegularUsersService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.accessTokenFromUrl = params['accessToken'];
    });
  }
  submit(event) {
    this.errorMsg = '';
    this.submited = true;

    this.regularUsersService
      .enterToken(this.accessTokenFromUrl, this.token)
      .subscribe((res) => {
        if ((res as RegularUser).regularUserId) {
          this.router.navigate(['/loading']);
        } else {
          this.errorMsg = (res as ErrorMessage).message;
        }
      });
  }
}
