import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { ENV, ENV_CONFIG } from './data/Env';
import { countBy } from 'lodash';

export function AngularFirestoreFactory(
  platformId: any,
  zone: NgZone,
  env: ENV,
  projectId: string
) {
  return new AngularFirestore(
    env.firebaseConfig[projectId],
    projectId,
    false,
    null,
    platformId,
    zone,
    null,
    false,
    false
  );
}

export function AngularFireAuthFactory(
  platformId: any,
  zone: NgZone,
  env: ENV,
  projectId: string
) {
  //console.log('AngularFireAuthFactory', platformId, zone, env, projectId);
  return new AngularFireAuth(
    env.firebaseConfig[projectId],
    projectId,
    platformId,
    zone,
    false,
    false,
    null,
    '',
    false,
    ''
  );
}

export const FIREBASE_PROJECT_ONE = new InjectionToken(
  'firebase project injector'
);
export const FIREBASE_PROJECT_TWO = new InjectionToken(
  'firebase project injector'
);

export const FIREBASE_REFERENCES = {
  CHAT_FIRESTORE: 'project-one-firestore',
  CALLS_FIRESTORE: 'project-two-firestore',
  CHAT_FIREAUTH: 'project-one-fireauth',
  CALLS_FIREAUTH: 'project-two-fireauth',
};

@NgModule({
  declarations: [],
  providers: [],
  imports: [CommonModule],
})
export class FirebaseModule {}
