<mat-list class="clientsTable" role="list">
  <mat-list-item class="clientsTable__header" role="listitem">
    <ng-container *ngFor="let item of headers">
      <span
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
      >
        <span (click)="changeSortBy(item.val)">{{ item.name }} </span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
  </mat-list-item>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{
        height: '50px',
        'margin-bottom': '10px',
        'background-color': 'rgba(27, 63, 78,0.15)'
      }"
      count="10"
    ></ngx-skeleton-loader>
  </div>
  <mat-card class="clientsTable__item" *ngFor="let clientItem of clientsData">
    <mat-list-item role="listitem">
      <span class="d-flex-center">
        <img class="clientSvg" src="../../../../assets/client.svg" alt="s" />
        {{ clientItem.fullName }}</span
      >
      <!--Full Name-->
      <span>{{ clientItem.socialSecurityNum }}</span>
      <!--Full Name-->
      <span>{{ clientItem.createdDate | date: "dd-MM-yyyy" }}</span>

      <span>{{ clientItem.email }}</span>
      <span
        ><!-- <img
          style="position: relative; top: 3px"
          src="../../../assets/phone_green.svg"
        /> -->{{ clientItem.phone }}</span
      >
      <span
        class="round-indicator"
        [ngStyle]="{ 'background-color': clientItem.leadStatus.color }"
      >
        {{ clientItem.leadStatus && clientItem.leadStatus.name }}</span
      >
      <span class="text-center">
        <button
          *ngIf="showAgentFilterNoManager(clientItem.agent.agentId)"
          mat-button
          (click)="editClient($event, clientItem)"
          class="edit-btn"
        >
          <img src="../../../../assets/client-edit.svg" alt="edit" />
        </button>
      </span>
    </mat-list-item>
  </mat-card>
</mat-list>
