<ngx-skeleton-loader
  *ngIf="!subCategory && !snp"
  [theme]="{ height: '30px' }"
  count="15"
></ngx-skeleton-loader>
<ng-container *ngIf="subCategory && snp">
  <div class="main-title">
    {{ subCategory?.name }} - rs{{ snp?.algoSnp?.prototypeSnpNumber }}
  </div>
  <form #rsForm="ngForm">
    <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>אלל עיקרי:</div>
        <mat-button-toggle-group
          class="primary-alfa"
          (change)="onAlfaChange($event)"
          [disabled]="!rsEditMode"
        >
          <mat-button-toggle
            *ngFor="let item of [snp.firstLetter, snp.secondLetter]"
            [value]="item"
            [checked]="
              snp.isFirstLarger
                ? item === snp.firstLetter
                : item === snp.secondLetter
            "
            >{{ item }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>שם הגנוטיפ:</div>
        <mat-form-field *ngIf="rsEditMode" class="field" appearance="fill">
          <input
            matInput
            [(ngModel)]="snp.algoSnp && snp.algoSnp.gene"
            name="gene"
            required
          />
        </mat-form-field>
        <div *ngIf="!rsEditMode">{{ snp.algoSnp && snp.algoSnp.gene }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>קורדינטה:</div>
        <mat-form-field *ngIf="rsEditMode" class="field" appearance="fill">
          <input
            matInput
            type="number"
            [(ngModel)]="snp.algoSnp && snp.algoSnp.coordinate"
            name="coordinate"
            required
            pattern="^[1-9][0-9]*$"
          />
        </mat-form-field>
        <div *ngIf="!rsEditMode">
          {{ snp.algoSnp && snp.algoSnp.coordinate }}
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>כרומוזום:</div>
        <mat-form-field *ngIf="rsEditMode" class="field" appearance="fill">
          <input
            matInput
            [(ngModel)]="snp.algoSnp && snp.algoSnp.chromosome"
            name="chromosome"
            required
          />
        </mat-form-field>
        <div *ngIf="!rsEditMode">
          {{ snp.algoSnp && snp.algoSnp.chromosome }}
        </div>
      </div>
      <div>
        <button
          (click)="toggleEditRs()"
          class="btn-fill"
          *ngIf="canEdit"
          mat-raised-button
        >
          {{ rsEditMode ? "שמירה" : "עריכה" }}
        </button>
        &nbsp;
        <button
          (click)="cancelRsEditMode()"
          class="btn-outline"
          *ngIf="rsEditMode"
          mat-button
        >
          ביטול
        </button>
      </div>
    </mat-card>
  </form>
  <div class="mb-2"></div>
  <form
    #allelForm="ngForm"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="10px"
  >
    <div
      *ngFor="let allel of snp.alleles; let i = index; trackBy: trackByFn"
      fxFlex="100"
    >
      <mat-card class="mb-2 text-center card-height">
        <ng-container
          *ngIf="
            editMode && allelToEdit == allel.algoSnpAlleleId;
            else viewModeName
          "
        >
          <mat-form-field class="field" appearance="fill">
            <input matInput [(ngModel)]="allel.name" name="name" required />
          </mat-form-field>
        </ng-container>
        <ng-template #viewModeName>
          {{ allel.name }}
        </ng-template>
      </mat-card>
      <mat-card class="mb-2 text-center card-height">
        <ng-container
          *ngIf="
            editMode && allelToEdit == allel.algoSnpAlleleId;
            else viewModeValue
          "
        >
          <div style="display: flex; margin-bottom: 16px">
            <mat-form-field class="field" appearance="fill">
              <input
                type="number"
                matInput
                [(ngModel)]="allel.value"
                name="value"
                required
              />
            </mat-form-field>
            <span style="width: 100px">:Male</span>
          </div>
          <div style="display: flex">
            <mat-form-field class="field" appearance="fill">
              <input
                type="number"
                matInput
                [(ngModel)]="allel.valueWomen"
                name="valueWomen"
                required
              />
            </mat-form-field>

            <span style="width: 100px">:Female</span>
          </div>
        </ng-container>
        <ng-template #viewModeValue>
          <div>Male:{{ allel.value }}, Female:{{ allel.valueWomen }}</div>
        </ng-template>
      </mat-card>
      <mat-card class="mb-2 text-center">
        {{ allel.alleleFirstLetter }}
        {{ allel.alleleSecondLetter }}
      </mat-card>

      <mat-card class="description mb-2">
        <perfect-scrollbar>
          <div class="description-inner">
            <ng-container
              *ngIf="
                editMode && allelToEdit == allel.algoSnpAlleleId;
                else viewModeDesc
              "
            >
              <mat-form-field class="field" appearance="fill">
                <textarea
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="18"
                  cdkAutosizeMaxRows="18"
                  matInput
                  [(ngModel)]="allel.description"
                  name="description"
                  required
                ></textarea>
              </mat-form-field>
            </ng-container>
            <ng-template #viewModeDesc>
              {{ allel.description }}
            </ng-template>
          </div>
        </perfect-scrollbar>
      </mat-card>
      <div class="mb-2"></div>
      <div
        *ngIf="canEdit"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
      >
        <button
          mat-raised-button
          (click)="toggleAllel(allel)"
          [disabled]="editMode && allelToEdit !== allel.algoSnpAlleleId"
          class="btn-fill"
        >
          {{ isItemInEditMode(allel) ? "שמירה" : "עריכה" }}
        </button>
        <button
          mat-button
          *ngIf="isItemInEditMode(allel)"
          (click)="cancelEditMode()"
          class="btn-outline"
        >
          ביטול
        </button>
      </div>
    </div>
  </form>
</ng-container>
