import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventAction } from '../data/EventAction';

@Injectable({
  providedIn: 'root'
})
export class EventActionsService {

  constructor(private http: HttpClient) {
   }
   getAll() {
    return this.http.get<EventAction[]>(`${environment.baseUrl}EventActions/`)
}
getById(agentId) {
  return this.http.get<EventAction>(`${environment.baseUrl}EventActions/${agentId}`);
}
put(eventAction:EventAction) {
  return this.http.put(
    `${environment.baseUrl}EventActions/${eventAction.eventActionId}`,
    eventAction
  );
}
post(eventAction:EventAction) {
  return this.http.post<EventAction>(`${environment.baseUrl}EventActions/`, eventAction);
}
delete(eventAction:EventAction) {
  return this.http.delete(`${environment.baseUrl}EventActions/${eventAction.eventActionId}`);
}
}
