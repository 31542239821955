<app-head-filter
  *ngIf="editors"
  [statuses]="statuses"
  [agents]="editors"
  [videoCategories]="categories"
  [classAttr]="'videosAttr'"
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [isMainScreen]="true"
></app-head-filter>

<div class="TableClearFix"></div>
<app-videos-table
  (filterChanged)="getAllWithFilter($event)"
  [headFilter]="headFilter"
  [videos]="videos"
  [agents]="editors"
  [categories]="categories"
  [metaData]="metaData"
>
</app-videos-table>

<app-table-paginator
  [sticky]="true"
  *ngIf="videos && videos.length > 0"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getAllWithFilter($event)"
  [classAttr]="'clientsAttr'"
  [isMainScreen]="true"
>
</app-table-paginator>
