import { Status } from './Status';
import { TubeTest } from './TubeTest';

export class TubesPackage {
  tubesPackageId: number;

  createDate: string;
  ClosedDate: string;
  ArivedToLabDate: string;
  statusId: number;
  status: Status;
  tubeTests: TubeTest[];
}
