<div class="container">
  <h1>Patient Form</h1>
  <form
    name="form"
    (ngSubmit)="f.form.valid && onSubmit()"
    #f="ngForm"
    novalidate
  >
    <div class="form-group">
      <label for="name">Name</label>
      <input
        type="text"
        class="form-control"
        name="firstName"
        [(ngModel)]="model.firstName"
        #firstName="ngModel"
      />
    </div>

    <div class="form-group">
      <label for="alterEgo">ID Num</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="IdNum"
        [(ngModel)]="model.socialSecurityNum"
        #socialSecurityNum="ngModel"
      />
    </div>

    <div class="form-group">
      <label for="alterEgo">user Name</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="CodeCupom"
        [(ngModel)]="model.userName"
        #firstName="ngModel"
      />
    </div>
    <div class="form-group">
      <label for="alterEgo">password</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="password"
        [(ngModel)]="model.password"
        #firstName="ngModel"
      />
    </div>
    <div class="form-group">
      <label for="alterEgo">Code cupon</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="CodeCupom"
        [(ngModel)]="model.patient.codeCoupon"
        #firstName="ngModel"
      />
    </div>
    <!--
    <div class="form-group">
      <label for="alterEgo">ID Num</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="firstName"
        [(ngModel)]="model.regularUser.socialSecurityNum"
        #firstName="ngModel"
      />
    </div>
    <div class="form-group">
      <label for="alterEgo">ID Num</label>
      <input
        type="text"
        class="form-control"
        id="alterEgo"
        name="firstName"
        [(ngModel)]="model.regularUser.socialSecurityNum"
        #firstName="ngModel"
      />
    </div>
-->
    <button type="submit" class="btn btn-success">Submit</button>
  </form>
</div>
