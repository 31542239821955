import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseResponce } from '../data/algorithm/BaseResponce';
import { BarcodeStorage } from '../data/BarcodeStorage';
import { BarcodeStorageLot } from '../data/BarcodeStorageLot';
import { ErrorMessage } from '../data/ErrorMessage';
import { HeadFilter } from '../data/HeadFilter';
import { PaginationMetadata } from '../data/PaginationMetadata';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

export interface PaginationObject {
  list: BarcodeStorage[];
  metaData: PaginationMetadata;
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeStorageService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getBarcodeStorage(startIndex: number, pageSize: number) {
    return this.http.get<PaginationObject>(
      `${environment.baseUrl}BarcodeStorage/GetAllBarcodesFromStorage/${startIndex}/${pageSize}`
    );
  }

  getBarcodesWithFilter(headFilter: HeadFilter) {
    // createBarcodePathFromHeadFilter
    return this.http.get<PaginationObject>(
      `${
        environment.baseUrl
      }BarcodeStorage/GetAllBarcodesFromStoragebyFilter/${this.httpFilterHelper.createBarcodePathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }

  getAllStatuses() {
    return this.http.get<BaseResponce[]>(
      `${environment.baseUrl}BarcodeStorage/GetAllStatuses`
    );
  }

  insertBarcodesByRange(startIndex: number, endIndex: number) {
    return this.http.get<ErrorMessage>(
      `${environment.baseUrl}BarcodeStorage/InsertBarcodesByRange/${startIndex}/${endIndex}`
    );
  }

  insertBarcodesByList(barcodeList: string[]) {
    return this.http.post<ErrorMessage>(
      `${environment.baseUrl}BarcodeStorage/InsertBarcodesByList`,
      barcodeList
    );
  }

  getAllLots() {
    return this.http.get<BarcodeStorageLot[]>(
      `${environment.baseUrl}BarcodeStorage/GetAllLots`
    );
  }
}
