<div style="position: relative">
  <div style="position: absolute; right: 0">
    <app-change-lang
      [selectedLangId]="langId"
      (changeLang)="onChangeLang($event)"
    ></app-change-lang>
  </div>
  <div class="container" style="direction: rtl">
    <br />
    <h3 style="text-align: center; font-weight: bold">
      {{ patientName }}
    </h3>
    <button mat-button class="significant" (click)="goToCategory(-1)">
      <div style="display: flex; align-items: center">
        לממצאים בעלי משמעות
        <mat-icon style="margin-right: 1rem">keyboard_backspace</mat-icon>
      </div>
    </button>
    <br />
    <br />
    <div class="categories-grid">
      <ng-container *ngFor="let result of categories; index as i">
        <mat-card
          class="category-card"
          [class.is-basic]="result.isPremium && !isPlusAndComplete()"
          (click)="goToCategory(result.categoryId)"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 150px;
              min-height: 150px;
            "
          >
            <img
              *ngIf="getImage(result.image)"
              [src]="getImage(result.image)"
            />
          </div>
          <span style="text-align: center; font-weight: bold">
            {{ result.categoryName }}
          </span>

          <div style="display: flex; justify-content: center; margin-top: 20px">
            <div
              [style.background-color]="'#' + item.color"
              class="dot"
              *ngFor="let item of result.summary"
              matTooltip="{{ item.typeName }}"
            >
              {{ item.count }}
            </div>
          </div>
        </mat-card>
      </ng-container>
      <div class="remaining-time" *ngIf="remainingTime">
        In upgrade proccess, remaining time: {{ remainingTime }} hours
      </div>
    </div>
  </div>
</div>
