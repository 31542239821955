import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Client } from '../data/Client';
import { LeadsService } from './leads.service';
import { HeadFilter } from '../data/HeadFilter';
import { ClientForTableMin } from '../data/ClientForTableMin';
import { LeadsServiceInterface } from '../serviceInterfaces/LeadsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class LeadPusherService implements LeadsServiceInterface {
  private leadId: Number;
  headFilter: HeadFilter;
  constructor(private leadsService: LeadsService) {}
  readonly leads = new BehaviorSubject<any>([]);
  readonly leads$ = this.leads.asObservable();

  getAll(headFilter: HeadFilter = null) {
    if (headFilter) this.headFilter = headFilter;
    //console.log('getAll leads-pusher', headFilter);
    if (this.headFilter) {
      this.leadsService.getAllByFilter(this.headFilter).subscribe((data) => {
        this.leads.next(data);
      });
      return of({});
    }
  }
  bulkChangeAgent(clientIds: number[], agentId: number) {
    this.leadsService.bulkChangeAgent(clientIds, agentId).subscribe((data) => {
      this.getAll();
    });
    return of({});
  }
  bulkChangeAgent2(
    clientIds: number[],
    agentId: number,
    transferMissions: boolean
  ) {
    this.leadsService
      .bulkChangeAgent2(clientIds, agentId, transferMissions)
      .subscribe((data) => {
        this.getAll();
      });
    return of({});
  }

  bulkChangeStatus(
    clientIds: number[],
    statusId: number,
    headFilter: HeadFilter
  ) {
    this.leadsService
      .bulkChangeStatus(clientIds, statusId)
      .subscribe((data) => {
        this.getAll(headFilter);
      });
    return of({});
  }
  /*
  bulkPut(leads: ClientForTableMin[]) {
    this.leadsService.bulkPut(leads).subscribe((data) => {
      this.getAll();
    });
  }*/
  changeStatusGet(lead: ClientForTableMin) {
    this.leadsService.changeStatusGet(lead).subscribe((data) => {
      this.getAll();
    });
    return of({});
  }
  changeAgent(lead: ClientForTableMin) {
    this.leadsService.changeAgent(lead).subscribe((data) => {
      this.getAll();
    });
    return of({});
  }
  put(lead: Client) {
    this.leadsService.put(lead).subscribe((data) => {
      this.getAll();
    });
    return of({});
  }
  post(lead: Client) {
    this.leadsService.post(lead).subscribe((data) => {
      this.getAll();
    });
    return of({});
  }
}
