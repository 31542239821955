import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { FileExtension } from '../data/FileExtension';
import { FileUploadSettings } from '../data/FileUploadSettings';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  addFileExtension(langId: number, fileExtension: FileExtension) {
    return this.http.post<any | ErrorMessage>(
      `${environment.baseUrl}Files/FileExtensionAdd/${langId}`,
      fileExtension
    );
  }
  editFileExtension(langId: number, fileExtension: FileExtension) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Files/FileExtensionEdit/${langId}`,
      fileExtension
    );
  }
  updateFileType(langId: number, fileUploadSettings: FileUploadSettings) {
    return this.http.put<any | ErrorMessage>(
      `${environment.baseUrl}Files/FileTypeUpdate/${langId}`,
      fileUploadSettings
    );
  }
  getFileSettings(typeId: number = 0) {
    // typeId = 0 - get all file types
    return this.http.get<FileUploadSettings[]>(
      `${environment.baseUrl}Files/FileSettingsGet/${typeId}`
    );
  }
  getFileExtensions(langId: number) {
    return this.http.get<FileExtension[]>(
      `${environment.baseUrl}Files/FileExtensionsGet/${langId}`
    );
  }
}
