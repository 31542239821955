import { AppImage } from '../algorithm/AppImage';

export class ClientRequestNote {
  clientRequestNoteId: number;
  clientRequestId: number;
  noteTitle: string;
  noteContent: string;
  createdDate: string;
  updatedDate: string;
  authorAgentId: number;
  authorAgentName: string;
  noteImage: AppImage;
}
