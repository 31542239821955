import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppImage } from 'src/app/data/algorithm/AppImage';
import { ChatAttachment } from 'src/app/data/chat/ChatAttachment';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';
import { ChatRoomAgent } from 'src/app/data/chat/ChatRoomAgent';
import { ChatSession } from 'src/app/data/chat/ChatSession';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { SessionStatus, StatementType } from 'src/app/Enums/ChatEnum';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { replaceWebPath } from 'src/app/Utils/files-helper';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent implements OnInit {
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
  psConfig: PerfectScrollbarConfigInterface = {
    wheelSpeed: 2,
  };
  sessions: ChatSession[];
  resetInput: boolean = false;
  firebaseMessages: ChatMessage[] = [];
  messages: ChatMessage[] = [];
  @Input() branchId: number;
  @Input() langId: number;
  @Input() agentId: number;
  @Output() closeSessionEvent = new EventEmitter<ChatSession>();
  @Output() takeOverEvent = new EventEmitter<ChatSession>();
  @Output() startChatEvent = new EventEmitter<ChatSession>();
  chatRoom: ChatRoomAgent;
  fbSubscriber: Subscription;
  compareMessages: boolean = false;
  activeSessionIndex: number;
  isNewSession: boolean;
  timeout: any;
  isSessionRelatedToAgent: boolean;
  isSessionClosed: boolean;
  listener;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private firebaseService: FirebaseChatIndicatorService,
    private _matSnackbar: MatSnackBar,
    private renderer2: Renderer2
  ) {}

  ngOnInit(): void {
    this.firebaseService.chatRoom$.subscribe((data) => {
      if (data?.chatRoom) {
        this.loading$.next(true);
        if (
          this.sessions &&
          this.sessions[this.activeSessionIndex].chatRoomId !==
            data.chatRoom.chatRoomId
        ) {
          this.resetInput = true;
          this.fbSubscriber.unsubscribe();
        }
        this.chatRoom = data.chatRoom;
        console.log('chat-messages chatRoom$: ', data);

        this.isNewSession = data.isNewSession;
        this.sessions = this.chatRoom.sessions.map((x) => x);
        this.activeSessionIndex = this.sessions.length - 1;
        // chck if session related to agent
        this.isSessionRelatedToAgent =
          this.sessions[this.activeSessionIndex].currentAgent == this.agentId;

        this.isSessionClosed =
          this.sessions[this.activeSessionIndex].statusId ==
          SessionStatus.Closed;

        //make sure the session is active
        const activeChatSessionId =
          this.sessions[this.activeSessionIndex].chatSessionId;
        if (
          activeChatSessionId ===
          this.chatRoom.sessions[this.activeSessionIndex].chatSessionId
        ) {
          this.listenForNewMessages();
        }
      } else {
        this.sessions = null;
      }
    });
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  listenForNewMessages() {
    this.fbSubscriber = this.firebaseService
      .listenToChatRoomAgent(
        this.sessions[this.activeSessionIndex].chatSessionId
      ) // the last session is the active session
      .subscribe((data: ChatMessage[]) => {
        if (data) {
          data.forEach((fbMsg) => {
            if (
              fbMsg.sessionId !==
              this.sessions[this.activeSessionIndex].chatSessionId
            )
              return;
            let isExist = false;
            const array = this.sessions[this.activeSessionIndex].messages;
            for (let index = 0; index < array.length; index++) {
              const serverMsg = array[index];

              // if the message is already exist in the array, update it
              if (fbMsg.chatMessageId === serverMsg.chatMessageId) {
                this.sessions[this.activeSessionIndex].messages[index] = {
                  ...fbMsg,
                };
                isExist = true;
              }
            }

            // if the message is not exist in the array, add it
            if (!isExist) {
              this.sessions[this.activeSessionIndex].messages.push({
                ...fbMsg,
              });
            }
          });
          this.scrollToBottom('listenForNewMessages');
          //check for take over
          const lastMessage = data[data.length - 1];
          const lastMessageStatementType = lastMessage?.template?.statementType;
          if (lastMessageStatementType === StatementType.SystemMessage) {
            this.sessions[this.activeSessionIndex].currentAgent =
              lastMessage.agentId;
          }
        }
      });
  }

  dateModified(dateMsg: string) {
    const datetime = dateMsg?.split(' ');
    if (!datetime) return;
    const date = datetime[0];
    const time = datetime[1];
    const newDate = date.split('/').reverse().join('-') + ' ' + time;
    return new Date(newDate).toString();
  }

  showMessage(msg: ChatMessage) {
    if (
      msg.template?.segments?.length > 0 ||
      msg.template?.context ||
      msg.context ||
      msg.attachments?.length > 0
    ) {
      return true;
    }
    return false;
  }

  uploadImg(file: any) {
    this.firebaseService.uploadChatAttachment(file).subscribe((data) => {
      if ((data as ErrorMessage)?.message) {
        this._matSnackbar.open((data as ErrorMessage)?.message, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      } else {
        const image = data as AppImage;
        this.sendMessage(null, image);
      }
    });
  }

  sendMessage(msg: string, file: AppImage = null) {
    const message = new ChatMessage();
    if (file) {
      const chatAttachment = new ChatAttachment();
      chatAttachment.fileName = file.fileName;
      chatAttachment.chatMessageId = 0;
      message.attachments = [chatAttachment];
    }
    message.chatMessageId = 0;
    message.sessionId = this.sessions[this.activeSessionIndex].chatSessionId;
    message.context = msg;
    message.userId = this.sessions[this.activeSessionIndex].userId;
    message.agentId = this.agentId;
    message.directionToUser = true;
    this.firebaseService
      .sendChatMsg(message, this.branchId, this.langId)
      .subscribe(() => {
        this.setAgentTyping(false);
      });
  }

  setAgentTyping(isTyping: boolean) {
    this.firebaseService.setAgentTyping(
      this.sessions[this.activeSessionIndex].chatSessionId,
      isTyping
    );
  }

  trackByFn(index: number, item: ChatMessage) {
    const returnItem = !item.template ? item.statusId : index;
    return returnItem;
  }
  // this.listener = this.renderer2.listen(this.perfectScroll.directiveRef.elementRef.nativeElement, 'scroll', (e) => {
  //   console.log(this.getYPosition(e));
  // });
  scrollToBottom(from: string = '') {
    this.perfectScroll.psScrollY.subscribe((y) => {
      if (
        this.perfectScroll.directiveRef.elementRef.nativeElement.scrollTop +
          this.perfectScroll.directiveRef.elementRef.nativeElement
            .offsetHeight >=
        this.perfectScroll.directiveRef.elementRef.nativeElement.scrollHeight
      ) {
        this.loading$.next(false);
        console.log('scroll to bottom', from);
      }
    });

    // check if the scroll is at the bottom

    this.timeout = setTimeout(() => {
      this.perfectScroll?.directiveRef?.scrollToBottom(0, 100); //for update scroll
      this.loading$.next(false);
    }, 400);
    if (!this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  closeSession() {
    this.closeSessionEvent.emit(this.sessions[this.activeSessionIndex]);
  }

  takeOver() {
    console.log('take over', this.sessions[this.activeSessionIndex]);

    this.takeOverEvent.emit(this.sessions[this.activeSessionIndex]);
  }

  startChat() {
    this.startChatEvent.emit(this.sessions[this.activeSessionIndex]);
    this.scrollToBottom('startChat');
  }

  getAvatarImage() {
    let imagePath = '';
    const serverPath =
      this.chatRoom.sessions[this.activeSessionIndex].conversationUserAvatar;
    if (serverPath) {
      imagePath = replaceWebPath(serverPath);
    } else {
      imagePath = '../../assets/man.svg';
    }
    return imagePath;
  }
}
