<div class="manage-roles-wrapper">
  <mat-card class="manage-roles">
    <mat-card class="manage-roles__roles">
      <div class="manage-roles__roles__title">Roles</div>
      <ngx-skeleton-loader
        [theme]="{ height: '20px' }"
        count="18"
        *ngIf="userRolesLoading"
      ></ngx-skeleton-loader>
      <perfect-scrollbar
        *ngIf="!userRolesLoading"
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
      >
        <div
          class="manage-roles__roles__item"
          *ngFor="let role of userRoles"
          (click)="onRoleClick(role.userRoleId)"
          matRipple
          [class.active]="selectedRoleId == role.userRoleId"
        >
          <div>{{ role.userRoleName }}</div>
          <mat-icon *ngIf="selectedRoleId == role.userRoleId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </perfect-scrollbar>
      <div class="add-new">
        <button (click)="addNewRole()" mat-button>Add new role</button>
      </div>
    </mat-card>
    <div class="manage-roles__modules">
      <div class="manage-roles__modules__title">Modules</div>
      <ngx-skeleton-loader
        [theme]="{ height: '20px' }"
        count="18"
        *ngIf="appModulesLoading"
      ></ngx-skeleton-loader>
      <perfect-scrollbar
        style="height: calc(100% - 95px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
        *ngIf="!appModulesLoading"
      >
        <div
          class="manage-roles__modules__wrapper"
          *ngFor="let module of appModules"
        >
          <button
            class="manage-roles__modules__wrapper__more"
            mat-icon-button
            *ngIf="selectedModuleId == module.appModuleId"
            [matMenuTriggerFor]="moreMenu"
          >
            <mat-icon (click)="editModule(module.appModuleId)"
              >more_vert</mat-icon
            >
          </button>

          <mat-menu #moreMenu="matMenu">
            <!--
            <button
              (click)="editModule(module.appModuleId)"
              mat-menu-item
              style="display: flex; justify-content: space-between"
            >
              <span> Edit </span>
              <span>
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  style="margin-right: 0"
                  >mode_edit</mat-icon
                >
              </span>
            </button>
            -->
            <button
              mat-menu-item
              (click)="deleteModule(module.appModuleId)"
              style="display: flex; justify-content: space-between"
            >
              <span> Delete </span>
              <span>
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  style="margin-right: 0"
                  >delete</mat-icon
                >
              </span>
            </button>
          </mat-menu>
          <div
            class="manage-roles__modules__item"
            (click)="onModuleClick(module)"
            matRipple
            [class.active]="selectedModuleId == module.appModuleId"
          >
            {{ module.appModuleName }}
            <mat-icon
              class="manage-roles__modules__item__right-icon"
              *ngIf="selectedModuleId == module.appModuleId"
              >keyboard_arrow_right</mat-icon
            >
          </div>
        </div>
      </perfect-scrollbar>

      <div class="add-new" *ngIf="selectedRoleId">
        <button
          matTooltip="add action to new module before adding another one"
          (click)="addNewModule()"
          [ngClass]="{ disabled: addNewModuleDisabled() }"
          [matTooltipDisabled]="!addNewModuleDisabled()"
          mat-button
        >
          Add module
          <!--
             class="disabled"
             [attr.disabled]="addNewModuleDisabled()"
               
          <span [matTooltip]="true">
           add action to new module before adding another one</span
          >
          -->
        </button>
      </div>
    </div>
    <mat-card class="manage-roles__actions" *ngIf="selectedModuleId">
      <div class="manage-roles__actions__title">Actions</div>
      <ngx-skeleton-loader
        [theme]="{ height: '20px' }"
        count="18"
        *ngIf="showedActionsLoading"
      ></ngx-skeleton-loader>
      <perfect-scrollbar
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
        *ngIf="!showedActionsLoading"
      >
        <div
          class="manage-roles__actions__item"
          *ngFor="let action of showedActions"
          (click)="onActionClick(action.moduleActionId)"
          matRipple
          [class.active]="selectedActionId == action.moduleActionId"
        >
          <mat-checkbox
            [checked]="action.checked == true"
            (click)="updateActionForRoleAndModule(action, $event)"
          ></mat-checkbox>
          {{ action.moduleActionName }}
          <mat-icon *ngIf="selectedActionId == action.moduleActionId"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </perfect-scrollbar>
    </mat-card>
    <div class="manage-roles__permissions" *ngIf="isPermisionShowed()">
      <div class="manage-roles__permissions__title">Permissions</div>
      <ngx-skeleton-loader
        [theme]="{ height: '20px' }"
        count="18"
        *ngIf="showedRulesLoading"
      ></ngx-skeleton-loader>
      <perfect-scrollbar
        style="height: calc(100% - 37px)"
        [perfectScrollbar]="{ wheelSpeed: 0.5 }"
        *ngIf="!showedRulesLoading"
      >
        <div
          class="manage-roles__permissions__item"
          *ngFor="let rule of showedRules"
          (click)="onPermissionClick(rule.permissionRuleId)"
          matRipple
        >
          <mat-checkbox
            [checked]="rule.checked == true"
            (click)="updatePermissionForRoleAndModule(rule, $event)"
            >{{ rule.permissionRuleName }}</mat-checkbox
          >
        </div>
      </perfect-scrollbar>
    </div>
  </mat-card>
</div>
