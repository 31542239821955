<div (click)="closeEmotion()" [style.opacity]="closingInProgress ? '0.5' : ''">
  <div style="position: relative; background-color: #fff">
    <div class="chat">
      <perfect-scrollbar
        style="height: calc(100vh - 224px)"
        #chatScroller
        [config]="config"
      >
        <mat-list style="width: 70%; margin: 0 auto" #chatList>
          <ng-container *ngFor="let dates of chatRoomDates; trackBy: byId">
            <div
              class="date-wrapper"
              inViewport
              [inViewportOptions]="{ threshold: [0] }"
              (inViewportAction)="onIntersection($event)"
            >
              <div class="dateDivider">
                <span>{{ dateDivider(dates[0].createdDate) }}</span>
              </div>
              <ng-container *ngFor="let chat of dates">
                <mat-list-item
                  #chatItem
                  class="chat__item"
                  [class.right-bubble]="chat.sentFrom == 2"
                  [class.left-bubble]="chat.sentFrom == 1"
                >
                  <div class="chat__item__inner">
                    <div
                      style="
                        white-space: pre-wrap;
                        word-break: break-word;
                        display: inline-block;
                        margin-left: 7rem;
                        padding: 0.8rem 1rem;
                        padding-left: 0;
                      "
                      [innerHtml]="chat.massage"
                    ></div>
                    <div class="meta-time">
                      {{ chat.createdDate | date: "HH:mm" }}
                      <span [innerHtml]="getIsSeenTxt(chat)"></span>
                    </div>
                  </div>
                </mat-list-item>
              </ng-container>
            </div>
          </ng-container>
        </mat-list>
      </perfect-scrollbar>
      <div
        style="
          position: absolute;
          bottom: 0;
          border: 1px solid black;
          width: 100%;
          padding: 5px;
        "
        *ngIf="!isChatActive()"
      >
        request is close. you can't respond
      </div>
      <div class="chat__input" *ngIf="isChatActive()">
        <emoji-mart
          *ngIf="emojis"
          title=""
          (click)="preventCloseEmoticons($event)"
          (emojiClick)="addEmoji($event)"
        >
        </emoji-mart>
        <mat-form-field
          (click)="preventCloseEmoticons($event)"
          dir="rtl"
          style="text-align: right; width: 100%"
          appearance="fill"
        >
          <!--  <mat-label>הקלד כאן..</mat-label> -->
          <div class="chat__input__field">
            <button
              [disabled]="chatIndicator && chatIndicator.isClosed"
              mat-icon-button
              matSuffix
              (click)="openEmoticons($event)"
            >
              <mat-icon style="color: #153341">tag_faces</mat-icon>
            </button>

            <textarea
              #chatInput
              (keydown.enter)="$event.stopPropagation(); sendMessage()"
              [(ngModel)]="chatMessage"
              matTextareaAutosize
              matAutosizeMinRows="1"
              matAutosizeMaxRows="2"
              style="resize: none; box-sizing: content-box"
              matInput
              placeholder="{{
                chatIndicator && chatIndicator.isClosed
                  ? 'הפנייה נסגרה'
                  : 'הקלד כאן...'
              }}"
              [disabled]="chatIndicator && chatIndicator.isClosed"
            ></textarea>

            <button
              [disabled]="
                chatMessage.trim().length == 0 ||
                (chatIndicator && chatIndicator.isClosed)
              "
              (click)="sendMessage()"
              mat-icon-button
              matSuffix
            >
              <mat-icon
                style="
                  transform: rotate(180deg);
                  color: #153341;
                  margin-top: -2px;
                "
                >send</mat-icon
              >
            </button>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
