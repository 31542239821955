import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodCategoryLangsService {
  constructor(private http: HttpClient) {}
  ForEdit(searchTerm: string, pageSize: number, pageNumber: number) {
    if (!searchTerm || searchTerm === '') {
      searchTerm = 'not_set';
    }
    return this.http.get<any>(
      `${environment.baseUrl}FoodCategoryLangs/ForEdit/${searchTerm}/${pageSize}/${pageNumber}`
    );
  }

  SearchByName(query: string, langId: number) {
    if (query.length < 2) {
      return of([]); // return from cache
    } else {
      return this.http.get<NameAndId[]>(
        `${environment.baseUrl}FoodCategoryLangs/SearchByName/${query}/${langId}`
      );
    }
  }
}
