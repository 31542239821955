import { Component, OnInit } from '@angular/core';
import { FirebaseAnonimousAuthService } from 'src/app/Services/firebase-anonimous-auth.service';

@Component({
  selector: 'app-firebase-login',
  templateUrl: './firebase-login.component.html',
  styleUrls: ['./firebase-login.component.css'],
})
export class FirebaseLoginComponent implements OnInit {
  constructor(public anonAuthService: FirebaseAnonimousAuthService) {}
  ngOnInit(): void {}
  signInAnonymously() {
    this.anonAuthService.anonymousLogin();

    //// do after login actions
  }
  logout() {
    this.anonAuthService.signOut();
  }
}
