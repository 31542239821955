<form (ngSubmit)="saveAll(bulkForm)" #bulkForm="ngForm">
  <div class="bulk-edit">
    <button
      type="button"
      (click)="cancel(bulkForm)"
      class="btn-link"
      mat-button
    >
      < Back to list
    </button>
    <mat-card class="bulk-edit__card">
      <div class="bulk-edit__header-bg">
        <div class="bulk-edit__header">
          <div class="bulk-edit__header__title">
            <mat-icon>fastfood</mat-icon>
            Foods Translation
          </div>
          <div class="bulk-edit__header__translation">
            <div *ngFor="let lang of langsTabs" style="position: relative">
              <button
                type="button"
                class="lang-button"
                [class.active]="lang.languageId == targetLangId"
                (click)="changeTargetLangId(lang)"
                mat-button
              >
                <mat-icon *ngIf="lang.isMissing">chat_bubble</mat-icon>
                {{ lang.name }}
              </button>
              <div
                *ngIf="lang.languageId == targetLangId"
                class="shape-bg"
              ></div>
              <div *ngIf="lang.languageId == targetLangId" class="shape"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex"
        *ngFor="let food of foods; let last = last; let i = index"
        [class.border-bottom]="!last"
      >
        <ng-container *ngFor="let lang of food.foodLangForBulk">
          <div
            *ngIf="lang.langId == langId || lang.langId == targetLangId"
            class="bulk-edit__item"
          >
            <button
              type="button"
              *ngIf="lang.langId == langId"
              mat-mini-fab
              class="remove-btn"
              (click)="removeFood(i)"
              [disabled]="disableRemove()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <div class="bulk-edit__item__name">
              <span> Item Name: </span>
              <ng-container *ngIf="lang.langId == langId">
                {{ lang.name }}
              </ng-container>
              <ng-container *ngIf="lang.langId == targetLangId">
                <mat-form-field appearance="fill" class="w-100">
                  <input
                    name="item-{{ i }}"
                    placeholder="Text here..."
                    matInput
                    [(ngModel)]="lang.name"
                  />
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-card class="bulk-edit__card__actions">
        <button type="submit" mat-button class="btn btn-fill mr-1">Save</button>
        <button
          type="button"
          (click)="cancel(bulkForm)"
          mat-button
          class="btn btn-outline"
        >
          Cancel
        </button>
      </mat-card>
    </mat-card>

    <!--<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
    
    
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>
    
   
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
    
   
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef> Weight </th>
        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
      </ng-container>
    
    
      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef> Symbol </th>
        <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table> -->
  </div>
</form>
