import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-agent-schedule',
  templateUrl: './edit-agent-schedule.component.html',
  styleUrls: ['./edit-agent-schedule.component.css'],
})
export class EditAgentScheduleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
