import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeUtilsService {
  setTime(date: Date, seconds: number): Date {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds / 60) % 60);
    var seconds = seconds % 60;
    date.setSeconds(seconds);
    date.setMinutes(minutes);
    date.setHours(hours);
    return date;
  }

  constructor() { }
  hoursMinutesMaskedToSeconds(hms: string): number {
    var hours = +hms.substring(0, 2);
    var minutes = +hms.substring(2, 4);
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = hours * 60 * 60 + minutes * 60;
    return seconds;
  }
  hoursMinutesToSeconds(hms: string): number {
    var a = hms.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
    return seconds;
  }
  hoursMinutesSecondsToSeconds(hms: string) {
    var a = hms.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

    return seconds;
  }
}
