<mat-card>
  <div>
    <img src="../../../assets/triangle_orange.svg" style="width: 20px" /><span
      style="
        margin-right: 40px;
        margin-left: 5px;
        font-weight: bold;
        font-size: 22px;
      "
      >Log</span
    >
    <span style="font-size: 22px; color: #1b3f4e">
      {{ getProfileType() }} Profile: {{ getProfileName() }}
    </span>
  </div>
  <div class="divider"></div>
  <table style="width: 100%" mat-table [dataSource]="loggings">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="action">
      <th style="width: 45%" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let logging">
        <img
          src="../../../assets/triangle_orange.svg"
          style="width: 15px; margin-right: 20px"
        /><span [innerHtml]="logging.action"> {{ logging.action }}</span>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="modifiedBy">
      <th style="width: 33%" mat-header-cell *matHeaderCellDef>Modified By</th>
      <td mat-cell *matCellDef="let logging">By {{ logging.agentName }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="dateTime">
      <th style="width: 33%" mat-header-cell *matHeaderCellDef>Date && Time</th>
      <td mat-cell *matCellDef="let logging">
        <div>
          <img src="../../../assets/calendar-60.svg" />
          <span style="margin-right: auto">
            {{ logging.dateCreated | date: "fullDate" }}
          </span>
          <img
            src="../../../assets/clock.svg"
            style="margin-left: 10px; position: relative; top: 2px; width: 15px"
          />
          <span> {{ logging.dateCreated | date: "HH:mm" }} </span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
