<div [dir]="dir()" class="algo-wrapper">
  <!-- <button (click)="toggleSidenav(catNav, snpNav)">click</button> -->
  <mat-sidenav-container class="sidenav-full">
    <mat-sidenav
      opened
      #catNav
      mode="side"
      position="start"
      role="navigation"
      class="main-sidenav"
      [disableClose]="true"
    >
      <app-sidenav-cat
        *ngIf="categories && languages && versions"
        [canSearch]="userCanSearch()"
        [isLoading]="isLoadingCats"
        [selectedCategory]="selectedCategory"
        [selectedSubCategory]="selectedSubCategory"
        [indexExpanded]="indexExpanded"
        [categories]="categories"
        [sidenavSnp]="snpNav"
        [languages]="languages"
        [algoVersions]="versions"
        [langId]="langId"
        [versionId]="versionId"
        [dir]="dir()"
        [canEdit]="canEdit"
        (selected)="onSelected($event)"
        (closeMainBoard)="onCloseMainBoard($event)"
        (changeLanguage)="onChangeLanguage($event)"
        (searchSnp)="onSearchSnp($event)"
        (changeVersion)="onChangeVersion($event)"
        (loadCategories)="onLoadCategories($event)"
        (openMainBoardEvent)="openMainBoard($event)"
      ></app-sidenav-cat
    ></mat-sidenav>
    <mat-sidenav-content>
      <mat-sidenav-container class="sidenav-full">
        <mat-sidenav
          #snpNav
          mode="side"
          position="start"
          role="navigation"
          class="snp-nav"
          [disableClose]="true"
        >
          <app-sidenav-snp
            [canEdit]="canEdit"
            [langId]="langId"
            [sidenavSnp]="snpNav"
            [selectedSubCategory]="selectedSubCategory"
            [selectedCategory]="selectedCategory"
            [selectedSnp]="algoSubCategorySnp?.algoSnp"
            [algoSnps]="algoSnps"
            [availableCatSnps]="availableCatSnps"
            (selectSnp)="onSelectSnp($event)"
            (selectSummary)="onSelectSummary($event)"
            (closeMainBoard)="onCloseMainBoard($event)"
            (loadSnps)="onLoadSnps($event)"
          ></app-sidenav-snp
        ></mat-sidenav>

        <mat-sidenav-content>
          <div *ngIf="mainBoardOpened">
            <app-main-board
              [mode]="mainBoardMode"
              [snp]="algoSubCategorySnp"
              [category]="selectedCategory"
              [subCategory]="selectedSubCategory"
              [subCatResultTypes]="subCatResultTypes"
              [results]="snpResults"
              [langId]="langId"
              [versionId]="versionId"
              [canEdit]="canEdit"
              [dir]="dir()"
              (selectResult)="onSelectSearchResult($event)"
              (loadResultTypes)="onLoadResultTypes($event)"
              (loadCategories)="onLoadCategories($event)"
              (loadRs)="onLoadRs($event)"
              (closeMainBoard)="onCloseMainBoard($event)"
            ></app-main-board>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
