import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FileExtension } from 'src/app/data/FileExtension';
import { FileUploadSettings } from 'src/app/data/FileUploadSettings';
import { FilesService } from 'src/app/Services/files.service';

@Component({
  selector: 'app-file-types',
  templateUrl: './file-types.component.html',
  styleUrls: ['./file-types.component.scss'],
})
export class FileTypesComponent implements OnInit {
  langId: number = 1;
  fileUploadSettings: FileUploadSettings[] | FileUploadSettings;
  constructor(private filesService: FilesService, private router: Router) {}

  ngOnInit(): void {
    this.getFileExtensions();
  }
  getFileExtensions() {
    this.filesService.getFileSettings().subscribe((data) => {
      if ((data as FileUploadSettings[]).length > 0) {
        this.fileUploadSettings = data;
      }
    });
  }

  onEditGroup(item: FileUploadSettings) {
    this.router.navigate(['/file-types/edit'], {
      queryParams: { id: item.fileType },
    });
  }
}
