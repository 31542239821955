import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgCoefficientSnp } from 'src/app/data/food-genes/FgCoefficientSnp';
import { FgCoefficientSnpAllele } from 'src/app/data/food-genes/FgCoefficientSnpAllele';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-snp-board',
  templateUrl: './food-genes-snp-board.component.html',
  styleUrls: ['./food-genes-snp-board.component.scss'],
})
export class FoodGenesSnpBoardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('allelForm') allelForm: NgForm;
  @Input() snp: FgCoefficientSnp;
  @Input() langId: number;
  @Input() canEdit: boolean;
  @Input() versionId: number;
  @Output('loadRs') loadRs = new EventEmitter<boolean>();
  editMode: boolean;
  allelToEdit: number;
  constructor(
    private _snackBar: MatSnackBar,
    private foodGenesService: FoodGenesService
  ) {}
  ngOnDestroy(): void {
    this.cancelEditMode();
  }

  ngOnInit(): void {
    // console.log('snp', this.snp);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.snp?.previousValue?.fgCoeffSnpId !==
        changes.snp?.currentValue?.fgCoeffSnpId &&
      !changes.snp?.isFirstChange()
    ) {
      this.cancelEditMode();
    }
  }
  cancelEditMode() {
    this.editMode = false;
    this.allelToEdit = null;
    if (this.allelForm?.dirty) {
      this.loadRs.emit(true);
      this.allelForm.reset();
    }
  }

  snackBar(message: string, duration: number = 2000) {
    this._snackBar.open(message, 'Close', {
      duration,
      panelClass: 'added-success',
    });
  }
  toggleEditMode() {
    this.editMode = !this.editMode;
  }
  save() {
    if (!this.allelForm.valid) return;
    this.foodGenesService
      .updateCoefficientSnpAllele(this.snp, this.versionId)
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          this.snackBar((data as ErrorMessage).message, 5000);
        } else {
          this.loadRs.emit(true);
          this.editMode = false;
          this.snackBar('FoodGenes snp update successfully', 2000);
        }
      });
  }
  isItemInEditMode(allel: FgCoefficientSnpAllele) {
    return this.editMode && this.allelToEdit === allel.fgSnpAlleleId;
  }
  cancel() {
    this.foodGenesService.mainBoardState$.next(false);
  }
  trackByFn(index: number, item: any) {
    return index; // or item.id
  }
}
