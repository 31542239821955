import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.css']
})
export class LoadingCircleComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) { }
  @Input() mode: string = "inline"

  /*
  @Input() color = "#000";
  @Input() bgColor = 'rgba(0,0,0,0.8)';
  
  @Input() isFullScreen: boolean = false;
  */
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      // this.spinner.hide();
    }, 5000);
  }


}
