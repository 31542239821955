<button class="back" (click)="goBack()">< Back</button>
<div class="patient-reports" style="display: flex; justify-content: center">
  <div>
    <!--style="padding: 2rem"-->
    <mat-card>
      <mat-tab-group
        [(selectedIndex)]="tabIndex"
        (selectedTabChange)="onTabChanged($event)"
      >
        <mat-tab label="DNA" *ngIf="product && product.statusId == 63">
          <!--  <app-dna-report-view *ngIf="tabIndex == 0" (onEdit)="onRightEdit()">
          </app-dna-report-view> -->
          <app-algo-categories></app-algo-categories>
        </mat-tab>
        <mat-tab
          label="Blood Test"
          *ngIf="product && product.productTypeId == 1"
        >
          <ng-container>
            <app-bloodtest-report-view
              style="width: 1000px"
              (onEdit)="onRightEdit()"
              [patientId]="patientId"
              [gender]="gender"
            >
            </app-bloodtest-report-view>
          </ng-container>
        </mat-tab>
        <ng-container *ngIf="product && product.productTypeId == 1">
          <mat-tab *ngIf="newLifeStyleSystem" label="Lifestyle Report">
            <div
              style="
                width: 1000px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <span
                  *ngIf="!isLifeStyleEdit"
                  style="color: #d3247e; margin-right: 20px"
                  >IMPORTANT INFO
                </span>
                <span *ngIf="!isLifeStyleEdit">version : </span>
                <span *ngIf="!isLifeStyleEdit">{{
                  productLifeStyleVersionStatus &&
                    productLifeStyleVersionStatus.currentLifeStyleVersionName
                }}</span>
                <div *ngIf="isLifeStyleEdit" class="version-select">
                  <mat-form-field
                    appearance="fill"
                    *ngIf="
                      productLifeStyleVersionStatus &&
                      productLifeStyleVersionStatus.versions
                    "
                  >
                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

                    <mat-select
                      (selectionChange)="onLifeStyleVersionSelect($event)"
                      [(ngModel)]="
                        productLifeStyleVersionStatus.selectedLifeStyleVersionId
                      "
                    >
                      <mat-option
                        *ngFor="
                          let version of productLifeStyleVersionStatus.versions
                        "
                        [value]="version.lifeStyleReportVersionId"
                      >
                        {{ version.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <button
                class="edit"
                *ngIf="!isLifeStyleEdit"
                (click)="gotoLifestyleEditMode()"
                mat-icon-button
              >
                <mat-icon> border_color </mat-icon>
              </button>

              <button
                class="preview"
                mat-button
                *ngIf="isLifeStyleEdit"
                (click)="gotoLifestylePreviewMode()"
              >
                preview
              </button>
            </div>
            <hr style="border: 1px solid #d3247e" />
            <app-lifestyle-report-edit-new
              *ngIf="
                productLifeStyleVersionStatus &&
                productLifeStyleVersionStatus.selectedLifeStyleVersionId > 2 &&
                isLifeStyleEdit
              "
              [isDone]="productLifeStyleVersionStatus.isDone"
              (updateLifestlyeDone)="onUpdateLifestlyeDone()"
              (goToPreview)="gotoLifestylePreviewMode()"
            ></app-lifestyle-report-edit-new>
            <app-lifestyle-report-preview
              *ngIf="
                productLifeStyleVersionStatus &&
                productLifeStyleVersionStatus.selectedLifeStyleVersionId > 2 &&
                !isLifeStyleEdit
              "
            >
            </app-lifestyle-report-preview>
            <app-lifestyle-form-dynamic
              *ngIf="
                productLifeStyleVersionStatus &&
                productLifeStyleVersionStatus.selectedLifeStyleVersionId == 2
              "
            >
            </app-lifestyle-form-dynamic>

            <app-lifestyle-report-edit
              *ngIf="
                productLifeStyleVersionStatus &&
                productLifeStyleVersionStatus.selectedLifeStyleVersionId == 1
              "
            >
            </app-lifestyle-report-edit>
          </mat-tab>
          <mat-tab *ngIf="!newLifeStyleSystem" label="Lifestyle">
            <span
              class="activeReport"
              *ngIf="lifeStyleReport && !lifeStyleReport.isOldActive"
              >Active Report</span
            >
            <button
              class="setActive"
              (click)="setOldLifeStyleNotActive()"
              *ngIf="lifeStyleReport && lifeStyleReport.isOldActive"
            >
              set Active
            </button>
            <app-lifestyle-form-dynamic> </app-lifestyle-form-dynamic>
          </mat-tab>
          <mat-tab *ngIf="!newLifeStyleSystem" label="Lifestyle Old">
            <button
              *ngIf="lifeStyleReport && lifeStyleReport.isComplete"
              (click)="clearLifeStleReport()"
            >
              Clear And Fill New Report
            </button>

            <span
              class="activeReport"
              *ngIf="lifeStyleReport && lifeStyleReport.isOldActive"
              >Active Report</span
            >
            <button
              class="setActive"
              (click)="setOldLifeStyleActive()"
              *ngIf="lifeStyleReport && !lifeStyleReport.isOldActive"
            >
              set Active
            </button>
            <app-lifestyle-report-edit> </app-lifestyle-report-edit>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
