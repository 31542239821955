import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-file-test',
  templateUrl: './upload-file-test.component.html',
  styleUrls: ['./upload-file-test.component.css']
})
export class UploadFileTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
