<mat-list class="packagesTable" role="list" style="margin-bottom: 40px">
  <mat-list-item class="packagesTable__header" role="listitem">
    <span style="text-align: center">Serial Number</span>
    <span style="text-align: center">Created date</span>
    <span style="text-align: center">Closed date</span>
    <span style="text-align: center">Arived To Lab</span>
    <span style="text-align: center">Status</span>
    <span style="text-align: center"># tubes</span>
    <span style="text-align: center"># completed</span>
    <span style="text-align: center"># labratory process</span>
    <span style="text-align: center"># other</span>
  </mat-list-item>
  <mat-card
    class="packagesTable__item"
    *ngFor="let tubesPackage of tubesPackages"
  >
    <mat-list-item role="listitem" (click)="editPackage($event, tubesPackage)">
      <span class="d-flex-center" style="align-items: flex-end">
        <img
          style="height: 2.5rem; margin-right: 1rem"
          src="../../../assets/{{ type }}-list.svg"
        />
        <span>{{ tubesPackage.tubesPackageId }}</span>
        <!-- + " " + leadItem.regularUser.lastName --></span
      >

      <span style="text-align: center">{{
        tubesPackage.createDate | date: "dd-MM-yyyy"
      }}</span>
      <span style="text-align: center">{{
        tubesPackage.closedDate == "0001-01-01T00:00:00"
          ? ""
          : (tubesPackage.closedDate | date: "dd-MM-yyyy")
      }}</span>
      <span style="text-align: center">{{
        tubesPackage.arivedToLabDate == "0001-01-01T00:00:00"
          ? ""
          : (tubesPackage.arivedToLabDate | date: "dd-MM-yyyy")
      }}</span>
      <span
        class="status"
        style="background-color:{{ tubesPackage.status.color }}"
        >{{ tubesPackage.status.name }}</span
      >

      <!-- <span style="text-align: center;"
        ><button mat-button >
          <img src="../../../../assets/client-edit.svg" alt="edit" /></button
      ></span> -->
      <span style="text-align: center">
        {{ tubesPackage.totalTubesNum }}</span
      >
      <span style="text-align: center">
        {{ getNumCompleted(tubesPackage) }}</span
      >
      <span style="text-align: center"> {{ getLabProcess(tubesPackage) }}</span>
      <span style="text-align: center">
        {{ getOtherStatus(tubesPackage) }}</span
      >
      <!--
      <span *ngIf="type == 'office'">
        <button (click)="sendFillLifeStyleReportMail()">Send Quiz Mail</button>
      </span>
      -->
    </mat-list-item>
  </mat-card>
</mat-list>
