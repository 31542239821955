<mat-toolbar class="top-nav" color="primary">
  <mat-toolbar-row class="nav" *ngIf="regularUser">
    <img
      src="../assets/white-logo.svg"
      alt="logo"
      style="height: 47px; padding-bottom: 8.5px; margin-right: 0.5rem"
    />
    <!--<button
      class="arrow"
      type="button"
      tabindex="-1"
      mat-button
      [style.dispaly]="isOverflownX(navContainer) ? 'flex' : 'none'"
      (click)="scrollLeft()"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
     <div
      style="display: flex; flex-grow: 1; overflow: hidden; z-index: 1"
      #navContainer
       style="
          flex-grow: 1;
          position: relative;
          transition: transform 500ms cubic-bezier(0.35, 0, 0.25, 1);
          transform: translateX(0px);
        "
        #navList
    > -->
    <div class="desktop">
      <button
        *ngIf="!isDashboard && userHasPermisions(['HomePage_GetList'])"
        mat-button
        routerLink="/home"
      >
        home
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Leads_GetList'])"
        [ngClass]="getClassNavName('leads')"
        class="leads-hover"
        mat-button
        routerLink="/leads"
      >
        leads
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Clients_GetList'])"
        [ngClass]="getClassNavName('clients')"
        class="clients-hover"
        mat-button
        routerLink="/clients"
      >
        clients
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Products_GetList'])"
        [ngClass]="getClassNavName('tests')"
        class="tests-hover"
        mat-button
        routerLink="/tests"
      >
        Tests
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Deals_GetList'])"
        [ngClass]="getClassNavName('transactions')"
        class="trans-hover"
        mat-button
        routerLink="/transactions"
      >
        Transactions
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['ServiceRequests_GetList'])"
        [ngClass]="getClassNavName(['requests', 'chatList'])"
        class="algo-hover"
        mat-button
        [matMenuTriggerFor]="requestMenu"
      >
        Requests
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #requestMenu="matMenu">
        <button routerLink="/requests" mat-menu-item>Service Requests</button>
        <button
          *ngIf="userHasPermisions(['Chat_GetList'])"
          routerLink="/chat"
          mat-menu-item
        >
          Chat
          <!-- <ng-container *ngIf="chatAlerts">({{ chatAlerts }})</ng-container> -->
        </button>
      </mat-menu>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Cancelations_GetList'])"
        [ngClass]="getClassNavName('cancelations')"
        class="cancel-hover"
        mat-button
        routerLink="/cancelations"
      >
        Cancellations
      </button>
      <button
        [ngClass]="getClassNavName('callsReviews')"
        class="callsReviews-hover"
        mat-button
        *ngIf="!isDashboard && userHasPermisions(['Reviews_GetList'])"
        routerLink="/callsReviews"
      >
        Reviews
      </button>
      <button
        *ngIf="!isDashboard && userHasPermisions(['FoodGenes_GetList'])"
        [ngClass]="getClassNavName('food-genes')"
        class="algo-hover"
        mat-button
        [matMenuTriggerFor]="foodGenesMenu"
      >
        FoodGenes
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #foodGenesMenu="matMenu">
        <button
          *ngIf="!isDashboard && userHasPermisions(['FoodGenes_GetList'])"
          class="algo-hover"
          mat-menu-item
          [matMenuTriggerFor]="foodAlgorithmMenu"
        >
          Algorithm
        </button>
        <button
          *ngIf="!isDashboard && userHasPermisions(['Food_GetList'])"
          class="algo-hover"
          mat-menu-item
          [matMenuTriggerFor]="foodMenu"
        >
          foods
        </button>
      </mat-menu>
      <mat-menu #foodAlgorithmMenu="matMenu">
        <button
          routerLink="/food-genes"
          routerLinkActive="router-link-active"
          mat-menu-item
        >
          FoodGenes
        </button>
        <button
          routerLink="/food-genes/versions"
          routerLinkActive="router-link-active"
          mat-menu-item
        >
          Versions
        </button>
      </mat-menu>
      <mat-menu #foodMenu="matMenu">
        <button
          *ngIf="userHasPermisions(['Food_GetList'])"
          [ngClass]="getClassNavName('foods')"
          class="delivery-office-hover"
          mat-menu-item
          routerLink="/foods"
        >
          Foods
        </button>
        <button
          *ngIf="userHasPermisions(['Food_GetList'])"
          [ngClass]="getClassNavName('recipes')"
          class="delivery-lab-hover"
          mat-menu-item
          routerLink="/recipes"
        >
          Recipes
        </button>
        <button
          [ngClass]="getClassNavName('Edit Food')"
          class="reviews-hover"
          *ngIf="userHasPermisions(['FoodCategories_GetList'])"
          mat-menu-item
          routerLink="/food-categories"
        >
          Categories
        </button>
        <button
          routerLink="/food-genes/lebels"
          routerLinkActive="router-link-active"
          mat-menu-item
        >
          Labels
        </button>
        <button
          routerLink="/food-genes/tags"
          routerLinkActive="router-link-active"
          mat-menu-item
        >
          Tags
        </button>
        <button
          routerLink="/foods/allergies"
          routerLinkActive="router-link-active"
          mat-menu-item
        >
          Allergies
        </button>
        <button
          [ngClass]="getClassNavName('Edit Food')"
          class="reviews-hover"
          *ngIf="userHasPermisions(['FoodZameretData_GetList'])"
          mat-menu-item
          routerLink="/upload-zameret-data"
        >
          Zameret Data
        </button>
      </mat-menu>
      <button
        *ngIf="!isDashboard && userHasPermisions(['Algorithm_ShowInNav'])"
        [ngClass]="getClassNavName('algorithm')"
        class="algo-hover"
        mat-button
        [matMenuTriggerFor]="algoMenu"
      >
        Algorithm
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #algoMenu="matMenu">
        <button
          *ngIf="!isDashboard && userHasPermisions(['Algorithm_GetList'])"
          mat-menu-item
          [matMenuTriggerFor]="algorithmMenu"
        >
          Algorithm
        </button>
        <button
          *ngIf="!isDashboard && userHasPermisions(['Laboratory_GetList'])"
          mat-menu-item
          [matMenuTriggerFor]="labMenu"
        >
          Laboratory
        </button>
        <button
          *ngIf="userHasPermisions(['Laboratory_UploadDnaResult'])"
          mat-menu-item
          routerLink="/labs/upload-result"
        >
          Upload result
        </button>
        <button
          *ngIf="userHasPermisions(['Laboratory_UploadDnaResult'])"
          mat-menu-item
          routerLink="/labs/packages"
        >
          Upload history
        </button>
        <button
          *ngIf="!isDashboard && userHasPermisions(['AlgoBullets_GetList'])"
          routerLink="/reportSummary"
          mat-menu-item
        >
          Report Summary
        </button>
        <button
          *ngIf="!isDashboard && userHasPermisions(['AlgoBullets_GetList'])"
          routerLink="/algo-bullet-links"
          mat-menu-item
        >
          Algorithm Links
        </button>
      </mat-menu>
      <mat-menu #algorithmMenu="matMenu">
        <button mat-menu-item routerLink="/algorithm">Manage algorithm</button>
        <button
          *ngIf="userHasPermisions(['Algorithm_Create', 'Algorithm_Update'])"
          mat-menu-item
          routerLink="/algorithm/prototype"
        >
          Manage prototypes
        </button>
        <button
          *ngIf="userHasPermisions(['Algorithm_Create', 'Algorithm_Update'])"
          mat-menu-item
          routerLink="/snps"
        >
          Manage snps
        </button>
      </mat-menu>
      <mat-menu #labMenu="matMenu">
        <button mat-menu-item routerLink="/labs/list">Lab list</button>
        <button mat-menu-item routerLink="/labs/versions">Lab versions</button>
      </mat-menu>
      <button
        [ngClass]="getClassNavName('scripts')"
        *ngIf="!isDashboard && userHasPermisions(['Knowledge_GetList'])"
        mat-button
        routerLink="/scripts"
      >
        KNOWLEDGE
      </button>
      <button
        [ngClass]="getClassNavName(['debug'])"
        class="algo-hover"
        mat-button
        [matMenuTriggerFor]="manageMenu"
        *ngIf="!isDashboard && userHasPermisions(['Manage_GetList'])"
      >
        Manage
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #manageMenu="matMenu">
        <button
          *ngIf="userHasPermisions(['DeliveriesLab_GetList'])"
          [ngClass]="getClassNavName('labDeliveries/lab')"
          class="delivery-office-hover"
          mat-menu-item
          routerLink="/labDeliveries/lab"
        >
          Lab Deliveries
        </button>
        <button
          *ngIf="userHasPermisions(['DeliveriesOffice_GetList'])"
          [ngClass]="getClassNavName('labDeliveries/office')"
          class="delivery-lab-hover"
          mat-menu-item
          routerLink="/labDeliveries/office"
        >
          Office Deliveries
        </button>

        <button
          mat-menu-item
          routerLink="/autoresponder"
          *ngIf="userHasPermisions(['AutoSender_GetList'])"
        >
          Auto sender Mails
        </button>
        <button
          mat-menu-item
          routerLink="/menu-templates"
          *ngIf="userHasPermisions(['MenuTemplates_GetList'])"
        >
          Menu Templates
        </button>
        <button
          mat-menu-item
          routerLink="/chat-hour"
          *ngIf="userHasPermisions(['ChatHour_GetList'])"
        >
          Chat hours
        </button>
        <button
          *ngIf="userHasPermisions(['AlgoGroups_GetList'])"
          mat-menu-item
          routerLink="/algo-groups"
        >
          Export groups
        </button>
        <button
          *ngIf="userHasPermisions(['ManageAgents_GetList'])"
          mat-menu-item
          routerLink="/agents"
        >
          Agents
        </button>
        <button mat-menu-item routerLink="/barcode-storage">Barcodes</button>
        <button
          *ngIf="userHasPermisions(['Links_GetList'])"
          mat-menu-item
          routerLink="/admin/debug"
        >
          Links
        </button>
        <button
          *ngIf="userHasPermisions(['Debug Pannel'])"
          mat-menu-item
          routerLink="/calendar"
        >
          Calendar
        </button>
        <button
          *ngIf="!isDashboard && userHasPermisions(['Vod_GetList'])"
          class="algo-hover"
          mat-menu-item
          [matMenuTriggerFor]="VodMenu"
        >
          VOD
        </button>
        <mat-menu #VodMenu="matMenu">
          <button
            *ngIf="userHasPermisions(['Vod_GetList'])"
            [ngClass]="getClassNavName('videos')"
            class="delivery-lab-hover"
            mat-menu-item
            routerLink="/videos"
          >
            Videos
          </button>
          <button
            [ngClass]="getClassNavName('videos-categories')"
            class="videos-hover"
            *ngIf="userHasPermisions(['Vod_GetList'])"
            mat-menu-item
            routerLink="/videos-categories"
          >
            Categories
          </button>
        </mat-menu>
      </mat-menu>
      <ng-container *ngIf="agentInteraction">
        <button mat-button [matMenuTriggerFor]="statusMenu">
          <span
            class="agent-status"
            [style.background]="agentInteraction?.status?.color"
          ></span>
          {{ agentInteraction?.status?.name }}

          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #statusMenu="matMenu">
          <button
            mat-menu-item
            (click)="changeAgentInteraction(AgentStatusEnum.Online)"
          >
            <span class="agent-status" style="background: #119c99"></span>
            Online
          </button>
          <button
            mat-menu-item
            (click)="changeAgentInteraction(AgentStatusEnum.Break)"
          >
            <span class="agent-status" style="background: #ff8a5c"></span>
            Break
          </button>
          <button
            mat-menu-item
            (click)="changeAgentInteraction(AgentStatusEnum.Offline)"
          >
            <span class="agent-status" style="background: #e80505"></span>
            Offline
          </button>
        </mat-menu>
      </ng-container>
    </div>
    <ng-container *ngIf="chatAvailable">
      <div class="notification">
        <div
          [matBadge]="firebaseService.unreadMessagesCount$ | async"
          matBadgeColor="accent"
          [matBadgeHidden]="
            (firebaseService.unreadMessagesCount$ | async) === 0 ||
            (firebaseService.unreadMessagesCount$ | async) === undefined
          "
          class="notification__badge"
        >
          <img
            class="notification__badge__icon"
            src="../assets/new-messages.svg"
          />
        </div>
        Messages
      </div>
      <div class="notification">
        <span
          [matBadge]="(firebaseService.waitingList$ | async)?.length"
          matBadgeColor="accent"
          [matBadgeHidden]="
            (firebaseService.waitingList$ | async)?.length === 0 ||
            (firebaseService.waitingList$ | async)?.length === undefined
          "
          class="notification__badge"
        >
          <img
            class="notification__badge__icon"
            src="../assets/waiting-list.svg"
          />
        </span>
        Waiting list
      </div>
    </ng-container>
    <div
      style="
        margin-left: auto;
        height: inherit;
        display: flex;
        align-items: flex-end;
      "
    >
      <span
        mat-button
        [matMenuTriggerFor]="logoutMenu"
        *ngIf="!isDashboard && regularUser"
        style="align-self: center; margin-top: 1rem; cursor: pointer"
      >
        <img
          style="
            height: 17px;
            position: relative;
            top: 3px;
            vertical-align: -8px;
          "
          src="../assets/single-01.svg"
        />
        <span style="font-size: 17px; margin-left: 5px; vertical-align: -8px">
          Hi, {{ regularUser.firstName }}</span
        >
      </span>
      <mat-menu #logoutMenu="matMenu">
        <button (click)="onLogout()" mat-menu-item>Logout</button>
      </mat-menu>
      <label
        *ngIf="
          !isDashboard &&
          userHasPermisions([
            'Leads_Search',
            'Clients_Search',
            'Products_Search'
          ])
        "
        for="navSearch"
        class="top-nav__search"
      >
        <input
          id="navSearch"
          (change)="onSearchFieldInput()"
          type="text"
          [(ngModel)]="searchTerm"
          (keyup.enter)="onSearchBtnClick()"
        />
        <button
          (click)="onSearchBtnClick()"
          type="button"
          class="top-nav__search__btn"
        ></button>
      </label>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="nav-divider" [ngClass]="getClassNavName('divider')"></div>
