import { MeetingSettingLang } from './MeetingSettingLang';
import { Status } from './Status';

export class MeetingSetting {
  meetingSettingId: number;
  preMeetingTime: number;
  meetingTime: number;
  postMeetingTime: number;
  statusId: number;
  status: Status;
  isAvailableForCustomer: boolean;
  meetingSettingLangs: MeetingSettingLang[];
}
