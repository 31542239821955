<!--
<table mat-table [dataSource]="translations" class="mat-elevation-z8">
  
  <ng-container matColumnDef="keyword">
    <th mat-header-cell *matHeaderCellDef>keyword</th>
    <td mat-cell *matCellDef="let element">{{ element.keyword }}</td>
  </ng-container>
  <ng-container matColumnDef="langs">
    <th mat-header-cell *matHeaderCellDef>langs</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngFor="let item of element.translations">
        <input [(ngModel)]="item.value" />
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
-->
<div class="langsTitle" style="display: flex; border: 1px solid black">
  <div></div>
  <div *ngFor="let lang of langs">
    {{ lang.name }}
  </div>
</div>
<div
  class="keywordColomn"
  style="display: flex; border: 1px solid black"
  *ngFor="let keyword of translations"
>
  <div>{{ keyword.keyword }}</div>
  <div *ngFor="let translation of keyword.translations">
    <input (change)="saveTrans(translation)" [(ngModel)]="translation.value" />
  </div>
</div>
