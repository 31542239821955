import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SendMailService {
  constructor(private http: HttpClient) {}
  post(str: any) {
    return this.http.post<any>(`${environment.baseUrl}SendMail/`, str);
  }
  sendResetMail(regularUserId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}SendMail/ResetMail/${regularUserId}`
    );
  }
  sendProductDetailsMail(regularUserId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}SendMail/ProductDetails/${regularUserId}`
    );
  }
  sendLifeStyleReportMail(packageId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}SendMail/FillLifeSytle/${packageId}`
    );
  }
}
