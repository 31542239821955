<div style="margin-bottom: 12rem">
  <br />
  <div *ngIf="myProducts.length > 0">Purchase Type:</div>
  <app-client-edit-purchase-thumb
    *ngFor="let product of myProducts; let inx = index"
    [product]="product"
    [productTypes]="productTypes"
    [client]="client"
    [index]="inx"
    (updateProductData)="updateProductData($event)"
    [clientProducts]="clientProducts"
    [showMinimal]="true"
  >
  </app-client-edit-purchase-thumb>
  <div style="display: flex; width: 100%">
    <div *ngIf="myProducts.length == 0">Purchase Type:</div>
    <div *ngIf="showNoProductWarning" style="position: relative">
      <div
        style="
          background: url('../../../assets/no-product.svg');
          background-size: 100% 100%;
          width: 346px;
          height: 166px;
          position: absolute;
          top: -5px;
        "
      >
        <span
          style="
            text-align: center;
            position: absolute;
            top: 45%;
            left: 45%;
            transform: translate(-50%, -50%);
            width: 100%;
            color: black;
          "
        >
          <span style="font-size: 20px; font-weight: bold">Oops!</span>
          <br />
          You forgot to add products
        </span>
      </div>
    </div>
    <div dir="rtl" class="pr-2" style="margin-left: auto; position: relative">
      <button class="add-new-product" (click)="addNewProduct()">+</button>
    </div>
  </div>
</div>

<!--
<div *ngIf="isSubmited && (productsTotal<2500 && !hasUpgradeProduct)"
style="color: red; font-size: 20px; margin-top: 20px"
>
you can't create deal with less than 2500 total
</div>
-->
<div class="purchase-action">
  <div class="purchase-action__text">
    Total Amount: <strong>{{ productsTotal }}</strong>
  </div>
  <div
    style="color: red; font-size: 20px; margin-top: 20px"
    *ngIf="formErrorTxt != ''"
  >
    <!--  *ngIf="!isProductFormsValid && (hasUpgradeProduct || productsTotal>=2500)"  -->
    {{ formErrorTxt }}
  </div>
  <div style="width: 100%" class="divider divider--client"></div>

  <div style="display: flex; justify-content: space-around; width: 100%">
    <button
      [disabled]="(!atleastOneChecked && isUpgrade) || disableNextPricePerUnit"
      class="purchase-action__btn"
      (click)="completePurchase()"
    >
      Next
    </button>
    <button class="purchase-action__btn" (click)="cancelPurchase()">
      Cancel
    </button>
  </div>
</div>
