import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FoodCat } from 'src/app/data/food-genes/FoodCat';
import { FoodCategoryType } from 'src/app/data/food-genes/FoodCategoryType';
import { FoodMin } from 'src/app/data/food-genes/FoodMin';
import { FoodCategory } from 'src/app/data/FoodCategory';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Language } from 'src/app/data/Language';
import { RegularUser } from 'src/app/data/RegularUser';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import {
  FoodGenesCatTypeEnum,
  permissionModuleAndAction,
} from 'src/app/Enums/StatusesEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { FoodCategoriesService } from 'src/app/Services/food-categories.service';
import { FoodCategoryLangsService } from 'src/app/Services/food-category-langs.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

export interface FoodCategoryPerms {
  canEdit: boolean;
  canDelete: boolean;
  canAdd: boolean;
  canSearch: boolean;
}

@Component({
  selector: 'app-food-categories',
  templateUrl: './food-categories.component.html',
  styleUrls: ['./food-categories.component.scss'],
})
export class FoodCategoriesComponent implements OnInit {
  metaData;
  searchTimeout;
  searchTerm: string;
  selectedLang: number = 1;
  selectedSourceKey = 'zameret';
  categories: FoodCategory[];
  foodCats: FoodCat[];
  langs: Language[];
  editInx: number = -1;
  headFilter: HeadFilter;
  displayedColumns: string[] = ['Food', 'Category'];
  langId: number = 1;
  regularUser: RegularUser;
  foodCategoryPerms: FoodCategoryPerms;
  selectedCat: any;
  FoodGenesCatTypeEnum = FoodGenesCatTypeEnum;
  isLoading: boolean = false;
  foodCatTypes: FoodCategoryType[];
  constructor(
    private languagesService: LanguagesService,
    private filterMemoryService: FilterMemoryService,
    private foodCategoriesService: FoodCategoriesService,
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar,
    private popupHandlerService: PopupHandlerService,
    regularUsersService: RegularUsersService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.foodCategories
    );
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      if (this.regularUser) {
        this.setPermissions();
      }
    });
  }

  ngOnInit(): void {
    this.languagesService.getAll().subscribe((data) => (this.langs = data));
    this.getFoodCatAndTypes();
  }
  setPermissions() {
    this.foodCategoryPerms = {
      canAdd: this.userHasPermisions([
        permissionModuleAndAction.FoodCategories_Create,
      ]),
      canDelete: this.userHasPermisions([
        permissionModuleAndAction.FoodCategories_Delete,
      ]),
      canEdit: this.userHasPermisions([
        permissionModuleAndAction.FoodCategories_Update,
      ]),
      canSearch: this.userHasPermisions([
        permissionModuleAndAction.FoodCategories_Search,
      ]),
    };
  }
  onChangeCat() {
    this.getFoodCatLangs();
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.regularUser);
  }
  onChangeLang(language: Language) {
    this.langId = language.languageId;
    this.getFoodCatLangs();
  }
  createNewCategory() {
    var foodCategory = new FoodCategory();
    foodCategory.sourceKey = this.selectedSourceKey;
    this.foodCategoriesService.post(foodCategory).subscribe((x) => {
      this.getFoodCatLangs();
    });
  }
  getFoodCatLangs() {
    this.isLoading = true;
    this.foodGenesService
      .getFoodCategories(this.langId, +this.selectedCat)
      .subscribe((data) => {
        this.foodCats = data;
        this.isLoading = false;
      });
  }
  getFoodCatAndTypes() {
    this.foodGenesService
      .getFoodCategoryTypes(this.langId)
      .subscribe((data) => {
        this.foodCatTypes = data.filter(
          (x) => x.categoryTypeId !== FoodGenesCatTypeEnum.Restaurants
        );
        this.selectedCat = this.foodCatTypes.find(
          (x) => x.categoryTypeId === FoodGenesCatTypeEnum.Food
        ).categoryTypeId;
        this.getFoodCatLangs();
      });
  }
  saveAllData() {
    this.foodCategoriesService.saveAll(this.categories).subscribe((x) => {
      this.getFoodCatLangs();
    });
  }
  getPaginatorData(event) {
    this.getFoodCatLangs();
  }
  onSearch(event: KeyboardEvent) {
    var { key } = event;
    if (
      key === 'Alt' ||
      key === 'Control' ||
      key === 'Shift' ||
      key === 'Enter'
    ) {
      return;
    }
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (this.searchTerm.length !== 0) {
        this.searchFood();
      } else {
        this.getFoodCatLangs();
      }
    }, 500);
  }
  searchFood() {
    this.foodGenesService
      .searchFoodCategories(this.searchTerm, this.langId, +this.selectedCat)
      .subscribe((data) => {
        this.foodCats = data;
      });
  }
  onGetData(event: boolean) {
    if (event) {
      this.getFoodCatLangs();
    }
  }
  onSetEditInx(inx: number) {
    this.editInx = inx;
    if (this.editInx == -1) {
      this.getFoodCatLangs();
    }
  }
  spliceCategoryRecursively(categoryId: number, array: FoodCat[]) {
    if (!array || array?.length == 0) return false;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.categoryId == categoryId) {
        array.splice(i, 1);
        return true;
      }
    }
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.subCategories?.length > 0) {
        const find = this.spliceCategoryRecursively(
          categoryId,
          element.subCategories
        );
        if (find) {
          return find;
        }
      }
    }
  }
  setCategoryRecursively(categoryId: number, array: FoodCat[]): FoodCat[] {
    if (!array || array?.length == 0) return null;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.categoryId == 0) {
        element.categoryId = categoryId;
        return array;
      }
    }
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.subCategories?.length > 0) {
        const find = this.setCategoryRecursively(
          categoryId,
          element.subCategories
        );
        if (find) {
          return find;
        }
      }
    }
  }
  onEditCanceled(category: FoodCat) {
    //this.getFoodCatLangs();
    if (category.categoryId === 0) {
      const a = this.spliceCategoryRecursively(
        category.categoryId,
        this.foodCats
      );
      console.log('a', a);
    } else {
      this.foodGenesService.renderCat$.next(category.categoryId);
    }
  }
  showSuccessMessage() {
    this._snackBar.open('Success', 'Close', {
      duration: 2000,
    });
  }
  showErrorMessage(message: string, action = 'Close', data?: FoodMin[]) {
    let snackBarRef = this._snackBar.open(message, action, {
      duration: 5000,
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
      this.popupHandlerService.openFoodInUseErrorDialog(data);
    });
  }
  getCatById(category: FoodCat) {
    this.foodGenesService
      .getFoodCatsById(category.categoryId, this.langId)
      .subscribe((data) => {
        //this.categories[]
      });
  }
  onSaveCategory(category: FoodCat) {
    if (category.categoryId == 0) {
      this.foodGenesService.addFoodCategory(category).subscribe((res) => {
        if ((res as ErrorMessage)?.message) {
          this.showErrorMessage((res as ErrorMessage).message);
        } else {
          //this.getFoodCatLangs();
          this.foodGenesService.renderCat$.next(res);
          // this.foodCats = this.setCategoryRecursively(res, [...this.foodCats]);
          this.showSuccessMessage();
        }
      });
    } else {
      this.foodGenesService.setFoodCategory(category).subscribe((res) => {
        //this.getFoodCatLangs();
        this.foodGenesService.renderCat$.next(category.categoryId);
        this.showSuccessMessage();
      });
    }
  }
  onDeleteCategory(category: FoodCat) {
    this.foodGenesService
      .removeFoodCategory(category.categoryId, this.selectedLang)
      .subscribe((data) => {
        if ((data as FoodMin[])?.length > 0) {
          this.showErrorMessage(
            "Can't delete category, it's in use",
            'Show Foods',
            data
          );
        } else {
          this.getFoodCatLangs();
        }
      });
  }
  onAddCategory(category: FoodCategory) {
    this.categories.push(category);
    this.editInx = 0;
  }
}
