import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadResultError } from '../data/UploadResultError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadResultErrorsService {
  constructor(private http: HttpClient) {}
  getbyPackageId(packageId) {
    return this.http.get<UploadResultError[]>(
      `${environment.baseUrl}UploadResultErrors/Package/${packageId}`
    );
  }
  getbyFileDataId(fileDataId) {
    return this.http.get<UploadResultError[]>(
      `${environment.baseUrl}UploadResultErrors/FileData/${fileDataId}`
    );
  }
}
