import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../Services/google-analytics.service';
import { RegularUsersService } from '../Services/regular-users.service';
import { StatisticsService } from '../Services/statistics.service';
import { escapeHtml } from '../Utils/general-helper';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private regularUsersService: RegularUsersService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private statisticsService: StatisticsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTimestamp = new Date().getTime();
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.regularUsersService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.baseUrl);
    const cloudflarApi = request.url.startsWith(
      'https://upload.videodelivery.net/tus'
    );
    if (isLoggedIn && isApiUrl) {
      /*
                        const headers = new HttpHeaders({
                            'Authorization:': `Bearer ${currentUser.token}`,
                            'RegularUserId': Globals.regularUserId.toString(),
                            'RegularUserName': Globals.regularUserName,
                            'Content-Type': 'application/json'
                        });
            
                        const cloneReq = request.clone({ headers });
                        return next.handle(cloneReq);
                       
                          */
      var headers = request.headers.set(
        'Authorization',
        `Bearer ${currentUser.token}`
      );
      var headers1 = headers.append(
        'RegularUserId',
        currentUser.regularUserId.toString()
      );
      var headers2 = headers1.append(
        'startTimestamp',
        startTimestamp.toString()
      );

      request = request.clone({
        headers: headers2,
        //withCredentials: true,
      });

      /*
request = request.clone({
setHeaders: {
Authorization: `Bearer ${currentUser.token}`,

}
});
*/
      /* if (request.method === 'POST' || request.method === 'PUT') {
        for (let prop in request.body) {
          if (typeof request.body[prop] === 'string') {
            request.body[prop] = escapeHtml(request.body[prop]);
          }
        }
      } */

      return next.handle(request).pipe(
        tap(
          (evt) => {
            if (evt instanceof HttpResponse) {
              if (
                evt.body &&
                environment.logStatistics /* && evt.body.success*/
              ) {
                // var len=evt.headers.get('Content-Length')
                var len = JSON.stringify(evt.body);
                var len1 = new TextEncoder().encode(len).length;
                const endTimestamp: number = new Date().getTime();
                /*
                            const startTimestamp3: number = Number(
                                request.headers.get('startTimestamp')
                            );
                            */
                // get the differencen
                const responseTimes = endTimestamp - startTimestamp;

                if (
                  request.url
                    .toLowerCase()
                    .indexOf('functionnames/addstatisticsreport')
                ) {
                  var url = request.url
                    .replace(environment.baseUrl, '')
                    .replace(/\//g, '_');
                  /*
                                    if (request.url.indexOf("search")!=-1)
                                    {
                                        url=request.url;
                                    }*/
                  var finalUrl = '';
                  var spl1 = url.split('_');
                  var isBreak = false;
                  spl1.forEach((element) => {
                    if (!isBreak)
                      if (!isNaN(+element)) {
                        isBreak = true;
                      } else {
                        finalUrl += element + '_';
                        if (
                          element.toLowerCase() == 'search' ||
                          element.toLowerCase() == 'tubespackages'
                        )
                          isBreak = true;
                      }
                  });
                  finalUrl += request.method;
                  this.statisticsService
                    .addStatisticsReport(finalUrl, responseTimes, len1)
                    .subscribe((data) => {});
                }
              }
            }
          },
          (err) => {
            //keep in comment handel this in error interceptor
            //if (this.router.url !== '/home') this.router.navigate(['/home']);
          }
        )
      );
    } else {
      /*  var header = request.headers.set('Content-Length', '0');
      request.clone({ headers: header }); */
      return next.handle(request);
    }
  }
}
