import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/internal/operators/take';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { BarcodeStorage } from 'src/app/data/BarcodeStorage';
import { BarcodeStorageLot } from 'src/app/data/BarcodeStorageLot';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { BarcodeStorageService } from 'src/app/Services/barcode-storage.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-barcodes-managment',
  templateUrl: './barcodes-managment.component.html',
  styleUrls: ['./barcodes-managment.component.scss'],
})
export class BarcodesManagmentComponent implements OnInit {
  statuses: BaseResponce[];
  lots: BarcodeStorageLot[];
  headFilter: HeadFilter;
  dataSource: MatTableDataSource<BarcodeStorage> = new MatTableDataSource([]);
  displayedColumns: string[] = ['number', 'lot', 'status', 'date'];
  metaData: PaginationMetadata;
  barcodes: BarcodeStorage[];
  constructor(
    private barcodeStorageService: BarcodeStorageService,
    private filterMemoryService: FilterMemoryService,
    private popupHandlerService: PopupHandlerService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.snps);
    this.headFilter.statusId = 0;
    this.headFilter.categoryId = 0;
    this.headFilter.searchTerm = 'null';
  }

  ngOnInit(): void {
    this.getAllWithFilter();
    this.getStatuses();
    this.getLotNumbers();
  }

  setDataSource(data: BarcodeStorage[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  getStatuses() {
    this.barcodeStorageService.getAllStatuses().subscribe((data) => {
      this.statuses = data;
    });
  }
  getLotNumbers() {
    this.barcodeStorageService.getAllLots().subscribe((data) => {
      this.lots = data;
    });
  }

  getAllWithFilter() {
    this.barcodeStorageService
      .getBarcodesWithFilter(this.headFilter)
      .subscribe((data) => {
        this.barcodes = data.list;
        this.metaData = data.metaData;
        this.setDataSource(this.barcodes);
        this.headFilter.searchTerm =
          this.headFilter.searchTerm === 'null'
            ? ''
            : this.headFilter.searchTerm;
      });
  }

  getPaginatorData(headFilter: HeadFilter) {
    if (headFilter.searchTerm === '') {
      headFilter.searchTerm = 'null';
    }
    this.headFilter = headFilter;
    this.getAllWithFilter();
  }

  onAddBarcode() {
    this.popupHandlerService.openAddNewBarcode();

    this.popupHandlerService.addNewBarcodeSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.headFilter.searchTerm = 'null';
          this.getAllWithFilter();
          this.getStatuses();
          this.getLotNumbers();
        }
      });
  }
  hexToRgbWithOpacity(hex: string, opacity: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
  }
}
