import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Note } from 'src/app/data/Note';
import { throttle } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lead-activities-view',
  templateUrl: './lead-activities-view.component.html',
  styleUrls: ['./lead-activities-view.component.scss'],
})
export class LeadActivitiesViewComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  @Output('createNewTask') createNewTask: EventEmitter<
    any
  > = new EventEmitter();
  disableNew: boolean = false;

  constructor(private route: ActivatedRoute) {}
  activitiesFinished;
  activities = [];
  activitiesOverdue = [];
  @Input() classAttr;
  @Input() classTabsAttr;
  @Input() testPusher;
  @Input() notes: Note[];
  @Output('updateNote') updateNote: EventEmitter<Note> = new EventEmitter();

  drawAll() {
    if (!this.notes) {
      this.notes = [];
    }
    this.activities = this.notes.filter((note: Note) => {
      var date = new Date(note.dueDate);
      var today = new Date();
      var isTrue = note.statusId != 12 && date.getTime() > today.getTime();
      //return note.statusId != 12 && date.getTime() > today.getTime();
      return note.statusId != 12;
    });
    this.activitiesFinished = this.notes.filter((note: Note) => {
      return note.statusId == 12;
    });
    this.activitiesOverdue = this.notes.filter((note: Note) => {
      var date = new Date(note.dueDate);
      var today = new Date();
      var isTrue = note.statusId != 12 && date.getTime() < today.getTime();
      return note.statusId != 12 && date.getTime() < today.getTime();
    });
  }
  ngOnInit(): void {
    var leadId = this.route.snapshot.queryParamMap.get('clientId');
    if (leadId === '-1') {
      this.disableNew = true;
    }
    this.drawAll();
  }
  onCreateNewTask() {
    this.createNewTask.emit(null);
  }
  ngOnChanges(changes: SimpleChanges) {
    var a = this.notes;
    this.drawAll();
  }
  onEditNote(note: Note) {
    this.createNewTask.emit(note);
  }
}
