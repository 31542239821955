<!--<p>choose a file to upload</p>-->
<!--<angular-file-uploader
      [config]="afuConfig">
</angular-file-uploader>-->
<!--<input type="file" (change)="fileChange($event)" placeholder="Upload file" accept=".xls,.xlsx,.csv">-->

<div class="row upload-file">
  <!--style="margin-bottom: 15px" -->
  <div class="col-md-3">
    <input
      #inputFile
      type="file"
      #file
      placeholder="Choose file"
      (change)="uploadFile(file.files)"
      style="display: none"
      multiple
      [accept]="accept"
    />
    <button
      *ngIf="classAttr != 'notesAttr'"
      mat-button
      type="button"
      class="btn btn-success"
      [ngClass]="{
        fileBtn: classAttr != 'patientAttr' && classAttr != 'emailTemplates',
        fileBtnMenu: classAttr == 'patientAttr',
        emailTemplate: classAttr == 'emailTemplates'
      }"
      (click)="file.click()"
      [disabled]="disabled"
    >
      {{ title }}
    </button>
    <mat-icon
      *ngIf="classAttr == 'notesAttr'"
      style="transform: rotate(-90deg); cursor: pointer"
      aria-hidden="false"
      aria-label="Example home icon"
      (click)="file.click()"
      >attachment</mat-icon
    >
  </div>
  <div *ngIf="typeError">
    {{ typeError }}
  </div>
  <ng-container *ngIf="classAttr !== 'patientAttr'">
    <div class="col-md-4 success" *ngIf="progress > 0 || message">
      <span class="upload" *ngIf="progress > 0"> {{ progress }}% </span>
      <span class="upload" *ngIf="message">
        {{ message }}
      </span>
    </div></ng-container
  >
  <ng-container *ngIf="classAttr == 'patientAttr' && progress > 0 && message">
    <span class="Vcontainer">
      <img src="../../../assets/V.svg" />
    </span>
  </ng-container>
</div>
