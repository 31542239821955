<h1>Patients Deliveries Responses</h1>

<table mat-table [dataSource]="deliveryResponses" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="patientId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Patient Id
    </th>
    <td mat-cell *matCellDef="let response">
      {{ response.patientDelivery.patientId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Patient Name
    </th>
    <td mat-cell *matCellDef="let response">
      <span
        style="text-decoration: underline; cursor: pointer;"
        (click)="goToPatient(response)"
      >
        {{
          response.patientDelivery.patient.regularUser.firstName +
            " " +
            response.patientDelivery.patient.regularUser.lastName
        }}
      </span>
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="deliveryType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Delivery Type
    </th>
    <td mat-cell *matCellDef="let response">
      {{ response.patientDelivery.deliveryType.name }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <!--
  <ng-container matColumnDef="patientStatus">
    <th mat-header-cell *matHeaderCellDef>Weight</th>
    <td mat-cell *matCellDef="let delivery">
      {{ delivery.patient.status.name }}
    </td>
  </ng-container>
  -->

  <!-- Symbol Column -->
  <ng-container matColumnDef="createDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Create Date
    </th>
    <td mat-cell *matCellDef="let response" style="text-align: center;">
      {{ response.receivedDate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="responseCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Response
    </th>
    <td mat-cell *matCellDef="let response" style="text-align: center;">
      {{ response.deliveryStatusId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="response">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
      Response
    </th>
    <td mat-cell *matCellDef="let response" style="text-align: center;">
      {{ response.deliveryStatus.name }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
