<ngx-skeleton-loader
  *ngIf="isLoading"
  [theme]="{ height: '25px' }"
  count="5"
></ngx-skeleton-loader>
<div style="position: relative" *ngIf="!isLoading">
  <div class="loading-overlay" *ngIf="isSubmiting">
    <mat-spinner class="loading-spinner" diameter="50"></mat-spinner>
  </div>
  <div
    style="
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 999;
      padding-top: 16px;
    "
  >
    <span class="title">
      <strong>{{ meal && meal.menu.name }}</strong> - {{ getMealName() }} |
      {{ meal && meal.mealTime }}</span
    >
    <hr class="hr pink" />
  </div>

  <div class="foodsTable">
    <div class="foodsTable__header">
      <span class="item">Item</span>
      <span class="amount">Amount</span>
      <span class="calories">Calories</span>
      <span class="actions"></span>
    </div>
    <ng-container *ngIf="meal">
      <div
        *ngFor="
          let mealBullet of meal.mealBullets;
          let i = index;
          let firstBullet = first;
          let last = last
        "
      >
        <div
          *ngFor="
            let option of mealBullet.mealBulletOptions;
            let x = index;
            let last = last;
            let first = first
          "
          class="w-100"
        >
          <div class="foodsTable__item">
            <span class="item">
              <div
                class="autocomplete-fake"
                *ngIf="option.foodId !== autoCompleteActiveFoodId"
                (click)="setAutoCompleteActive(option.foodId)"
                [dir]="isRtl ? 'rtl' : 'ltr'"
              >
                <span class="text" *ngIf="option.foodName">
                  {{ option.foodName }}
                </span>
                <span class="text placeholder" *ngIf="!option.foodName">
                  choose item
                </span>
                <button
                  type="button"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="open"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
              <app-autocomplete-foods
                *ngIf="option.foodId == autoCompleteActiveFoodId"
                [autoCompleteActiveFoodId]="autoCompleteActiveFoodId"
                class="w-100"
                [initialValue]="option.foodName"
                [data]="foodsResults$ | async"
                (selectedValue)="onFoodSelected($event, i, x)"
                (searchTerm)="searchTermFood$.next($event)"
                [langId]="langId"
                [isRtl]="isRtl"
                [showRanking]="true"
              ></app-autocomplete-foods>
            </span>
            <span class="amount">
              <button
                *ngIf="option.foodId && option.foodScaleId && option.quantity"
                mat-button
                (click)="
                  openUnitScaleDialog(
                    i,
                    x,
                    option.foodId,
                    option.foodScaleId,
                    option.quantity,
                    option.calories
                  )
                "
              >
                {{ option.foodScaleName + "-" + option.quantity }}
              </button>
            </span>
            <span class="calories">
              {{ option.calories | number : "1.0-0" }}
            </span>
            <span class="actions">
              <button
                mat-icon-button
                (click)="removeOption(i, x)"
                [disabled]="
                  (disableRemoveBtn(i) || !canEditMenu) && !isTemplate
                "
              >
                <mat-icon>close</mat-icon>
              </button>
              <div *ngIf="!last" style="color: #c3c1c1">OR</div>
              <button
                [disabled]="
                  ((meal && meal.menu && meal.menu.isComplete) ||
                    !canEditMenu) &&
                  !isTemplate
                "
                mat-button
                *ngIf="last"
                class="btn btn-fill"
                (click)="addNewBulletOption(i)"
              >
                OR
              </button>
            </span>
          </div>
          <div *ngIf="last" class="and-seperator">
            <span class="and-seperator__text">AND</span>
            <span class="and-seperator__hr"></span>
          </div>
        </div>
        <button
          [disabled]="
            ((meal && meal.menu && meal.menu.isComplete) || !canEditMenu) &&
            !isTemplate
          "
          *ngIf="last"
          class="btn btn-outline mt-2"
          mat-button
          (click)="addNewBullet()"
        >
          + And
        </button>
      </div>
    </ng-container>
    <div class="mb-2"></div>
    <div class="remarks" *ngIf="meal">
      <div class="label">Remarks</div>
      <angular-editor
        dir="rtl"
        class="w-100"
        [(ngModel)]="meal.notes"
        (ngModelChange)="onNotesChange()"
        [config]="editorConfig"
      ></angular-editor>
      <!-- <mat-form-field appearance="fill" class="w-100">
          <textarea matInput ></textarea>
        </mat-form-field> -->
    </div>
    <div class="mb-2"></div>
  </div>

  <div
    class="footer"
    [ngStyle]="{
      'grid-template-columns': productId ? '30% 1fr 20%' : '48% 48%'
    }"
  >
    <div
      class="footer__genetic-rate"
      style="margin-left: 6px"
      *ngIf="productId"
    >
      <div class="footer__genetic-rate__title">
        <div class="label">Meal Genetic Rate:</div>
        <mat-form-field
          appearance="fill"
          class="input"
          *ngIf="!calculateGeneticRate"
        >
          <input
            max="100"
            min="0"
            matInput
            type="number"
            [(ngModel)]="meal.geneticRate"
            (ngModelChange)="geneticRateChanged()"
          />
        </mat-form-field>
      </div>
      <div class="footer__genetic-rate__bar">
        <mat-progress-bar [value]="meal.geneticRate"> </mat-progress-bar>
        <div
          class="footer__genetic-rate__bar__mask"
          [ngStyle]="{ left: meal.geneticRate + '%' }"
          [ngStyle]="{ width: 'calc(100% - ' + meal.geneticRate + '%)' }"
        ></div>
        <div
          class="footer__genetic-rate__bar__value"
          [ngStyle]="{ right: 'calc(100% - ' + meal.geneticRate + '%)' }"
        >
          {{ meal.geneticRate | number : "1.0-0" }}%
        </div>
      </div>
    </div>
    <div class="footer__data">
      <div>
        <span>Calories:&nbsp;</span
        >{{ getNutriCalories(meal)?.amount | number : "1.0-0" }}
        <ng-container *ngIf="productId"
          >/{{
            getNutriCalories(meal)?.dailyAmount | number : "1.0-0"
          }}</ng-container
        >
      </div>
      <div>
        <span>Fats:&nbsp;</span>
        {{ getNutriFats(meal)?.amount | number : "1.0-0" }}
        <ng-container *ngIf="productId"
          >/{{
            getNutriFats(meal)?.dailyAmount | number : "1.0-0"
          }}</ng-container
        >
      </div>
      <div>
        <span>Proteins:&nbsp;</span
        >{{ getNutriProteins(meal)?.amount | number : "1.0-0"
        }}<ng-container *ngIf="productId"
          >/{{
            getNutriProteins(meal)?.dailyAmount | number : "1.0-0"
          }}</ng-container
        >
      </div>
      <div>
        <span>Carbs:&nbsp;</span>
        {{ getNutriCarbo(meal)?.amount | number : "1.0-0"
        }}<ng-container *ngIf="productId"
          >/{{
            getNutriCarbo(meal)?.dailyAmount | number : "1.0-0"
          }}</ng-container
        >
      </div>
      <div>
        <app-nutritional-values
          *ngIf="meal.mealToNutrients"
          [originalNutrients]="meal.mealToNutrients"
        >
        </app-nutritional-values>
      </div>
    </div>
    <div
      class="footer__action"
      [ngStyle]="{ 'justify-self': productId ? 'unset' : 'flex-end' }"
    >
      <button
        [disabled]="
          (isSubmiting ||
            (meal && meal.menu && meal.menu.isComplete) ||
            !canEditMenu) &&
          !isTemplate
        "
        (click)="save()"
        mat-button
        class="btn btn-fill mr-2"
      >
        <ng-container *ngIf="isSubmiting">
          Saving
          <mat-icon> <mat-spinner diameter="20"></mat-spinner></mat-icon>
        </ng-container>
        <ng-container *ngIf="!isSubmiting">Save</ng-container>
      </button>
      <button
        [disabled]="!canEditMenu && !isTemplate"
        *ngIf="!isSubmiting && meal && meal.menu && !meal.menu.isComplete"
        (click)="clear()"
        mat-button
        class="btn btn-outline"
      >
        Clear
      </button>
    </div>
  </div>
</div>
