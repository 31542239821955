import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PhoneVerificationSetting } from '../data/PhoneVerificationSetting';

@Injectable({
  providedIn: 'root',
})
export class PhoneVerificationSettingsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<PhoneVerificationSetting[]>(
      `${environment.baseUrl}PhoneVerificationSettings`
    );
  }

  getByCountry(id: number) {
    return this.http.get<PhoneVerificationSetting>(
      `${environment.baseUrl}PhoneVerificationSettings/GetByCountry/${id}`
    );
  }

  post(setting: PhoneVerificationSetting) {
    return this.http.post<PhoneVerificationSetting>(
      `${environment.baseUrl}PhoneVerificationSettings`,
      setting
    );
  }
  put(setting: PhoneVerificationSetting) {
    return this.http.put<any>(
      `${environment.baseUrl}PhoneVerificationSettings`,
      setting
    );
  }

  delete(id: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}PhoneVerificationSettings/${id}`
    );
  }
}
