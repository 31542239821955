<div class="dashboard" *ngIf="salesDashboard">
  <mat-card class="dashboard__agents-online">
    <div #agentsCard>
      <div class="dashboard__agents-online__title">Agents online</div>
      <table
        mat-table
        [dataSource]="renderAgentRows(DashboardAgentStats)"
        class="dashboard__agents-online__table"
      >
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              class="dashboard__agents-online__table__status"
              [style.background]="getAgentStatusColor(element.AgentStatusId)"
            >
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.AgentName }}</td>
        </ng-container>

        <ng-container matColumnDef="answeredCall">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex-center-all">
              Answered calls &nbsp;
              <img src="../../../assets/answer-calls.svg" alt="" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="text-center">{{ element.AnsweredCallCount }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="outcomingCalls">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex-center-all">
              Outcoming calls&nbsp;
              <img src="../../../assets/outcoming-calls.svg" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="text-center">
              {{ element.OutcomingCallCount }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalTalkTime">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex-center-all">Total talk time</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="text-center">
              {{ convertToTimespan(element.TotalTalkTimeInSec) }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalBreakTime">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex-center-all">Total Break time</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="text-center">
              {{ convertToTimespan(element.TotalBreakTimeInSec) }}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card>
  <div class="dashboard__stats">
    <div class="dashboard__stats__statuses">
      <mat-card
        class="dashboard__stats__statuses__item"
        *ngFor="let item of DashboardLeadStatusCounts"
        [style.border-color]="item.StatusColor"
      >
        <div class="dashboard__stats__statuses__item__count">
          {{ item.LeadCount }}
        </div>
        <div class="dashboard__stats__statuses__item__name">
          {{ item.StatusName }}
        </div>
      </mat-card>
    </div>
    <div class="dashboard__stats__calls">
      <mat-card class="dashboard__stats__calls__item">
        <mat-icon class="answered">phone</mat-icon>
        <div class="dashboard__stats__calls__item__count">
          {{ DashboardCallStatSales.AnsweredCallCount }}
        </div>
        <div class="dashboard__stats__calls__item__name">Answered calls</div>
      </mat-card>
      <mat-card class="dashboard__stats__calls__item">
        <mat-icon class="missing">call_end</mat-icon>
        <div class="dashboard__stats__calls__item__count">
          {{ DashboardCallStatSales.MissingCallCount }}
        </div>
        <div class="dashboard__stats__calls__item__name">Missed calls</div>
      </mat-card>
      <mat-card class="dashboard__stats__calls__item">
        <img src="../../../assets/waiting-calls.svg" alt="" />
        <div class="dashboard__stats__calls__item__count">
          {{ DashboardCallStatSales.WaitCallCount }}
        </div>
        <div class="dashboard__stats__calls__item__name">Waiting calls</div>
      </mat-card>
      <mat-card class="dashboard__stats__calls__item">
        <img src="../../../assets/outcoming-calls-big.svg" alt="" />
        <div class="dashboard__stats__calls__item__count">
          {{ DashboardCallStatSales.OutComingCallCount }}
        </div>
        <div class="dashboard__stats__calls__item__name">Outcoming calls</div>
      </mat-card>
    </div>
    <div class="dashboard__stats__average">
      <mat-card class="dashboard__stats__average__item">
        <rg-gauge-chart
          [canvasWidth]="canvasWidth"
          [needleValue]="needleValue"
          [centralLabel]="centralLabel"
          [options]="options"
          [bottomLabel]="bottomLabel"
        ></rg-gauge-chart>
        <div
          class="dashboard__stats__average__item__name"
          style="padding-top: 10px"
        >
          Average response time (sec)
        </div>
      </mat-card>
      <mat-card class="dashboard__stats__average__item">
        <mat-icon> schedule </mat-icon>
        <div class="dashboard__stats__average__item__count">
          {{ DashboardCallStatSales.OutcomingCountHourly }}
        </div>
        <div
          class="dashboard__stats__average__item__name"
          style="padding-top: 10px"
        >
          Outcoming calls (hourly)
        </div>
      </mat-card>
      <mat-card class="dashboard__stats__average__item">
        <mat-icon class="percent">percent</mat-icon>
        <div class="dashboard__stats__average__item__count">
          {{ precenageResponse() | number: "1.0-1" }}
        </div>
        <div class="dashboard__stats__average__item__name">
          Precentage response
        </div>
      </mat-card>
    </div>
  </div>
</div>
