import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Status } from 'src/app/data/Status';
import { ManageMailFormatService } from 'src/app/Services/manage-mail-format.service';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-view-mails',
  templateUrl: './view-mails.component.html',
  styleUrls: ['./view-mails.component.css'],
})
export class ViewMailsComponent implements OnInit {
  selectedMailTypeId;
  mailTypes: Status[];
  iframeUrl: string;
  constructor(
    private manageMailFormatService: ManageMailFormatService,
    private statusesService: StatusesService,
    private sanitizer: DomSanitizer
  ) {
    statusesService.getAll().subscribe((res) => {
      this.mailTypes = res.filter((x) => x.category == 'MailType');
    });

    //this.mailTypes.push({ name: 'dfg' });
  }

  ngOnInit(): void {}
  onLinkClick(status: Status) {
    var url = this.manageMailFormatService.getViewMailLink(status.statusId);
    window.open(url, '_blank');
  }
  onMailTypeChange(event) {
    this.iframeUrl = this.manageMailFormatService.getViewMailLink(event.value);

    //this.sanitizer.sanitize(SecurityContext.URL, url)
    // this.sanitizer.bypassSecurityTrustUrl(url)
    //this.iframeUrl =url;
    //
  }
  safeHTML(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
