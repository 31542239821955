import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleCaptcha } from '../data/GoogleRecaptcha';

@Injectable({
  providedIn: 'root',
})
export class GoogleCaptchaService {
  constructor(private http: HttpClient) {}

  VerifyingGoogleCaptchaResponse(response: GoogleCaptcha) {
    return this.http.post<any>(
      `${environment.baseUrl}Captcha/CheckCaptchaByGoogle`,
      response
    );
  }
}
