import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ZoomEvent } from '../data/ZoomEvent';

@Injectable({
  providedIn: 'root',
})
export class ZoomEventsService {
  constructor(private http: HttpClient) {}

  addZoomEvent(zoomEvent: ZoomEvent) {
    return this.http.post<any>(
      `${environment.baseUrl}ZoomEvent/AddZoomEvent`,
      zoomEvent
    );
  }
  setZoomEvent(zoomEvent: ZoomEvent) {
    return this.http.put<any>(
      `${environment.baseUrl}ZoomEvent/SetZoomEvent`,
      zoomEvent
    );
  }
  getZoomEventById(zoomEventId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}ZoomEvent/GetZoomEventById/${zoomEventId}`
    );
  }
  getZoomEventByDate(zoomEventDate: string) {
    return this.http.get<any>(
      `${environment.baseUrl}ZoomEvent/GetZoomEventByDate/${zoomEventDate}`
    );
  }
  getZoomEventList() {
    return this.http.get<any>(
      `${environment.baseUrl}ZoomEvent/GetZoomEventList`
    );
  }
}
