import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChooseCouponDialogComponent } from '../choose-coupon-dialog/choose-coupon-dialog.component';

@Component({
  selector: 'app-follow-up-schedualler-dialog',
  templateUrl: './follow-up-schedualler-dialog.component.html',
  styleUrls: ['./follow-up-schedualler-dialog.component.css']
})
export class FollowUpScheduallerDialogComponent implements OnInit {

  date;
  constructor(
    public dialogRef: MatDialogRef<FollowUpScheduallerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 

  }

  ngOnInit(): void {
  }
  onDoneClicked()
  {

  }
  onCancelClicked()
  {

  }
  triggerToggleClick()
  {

  }
}
