import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TriggerEvent } from '../data/TriggerEvent';

@Injectable({
  providedIn: 'root'
})
export class TriggerEventsService {

  constructor(private http: HttpClient) { }
  getAll() {
      return this.http.get<TriggerEvent[]>(`${environment.baseUrl}TriggerEvents/`)
  }
  getById(agentId) {
    return this.http.get<TriggerEvent>(`${environment.baseUrl}TriggerEvents/${agentId}`);
  }
  put(triggerEvent:TriggerEvent) {
    return this.http.put(
      `${environment.baseUrl}TriggerEvents/${triggerEvent.triggerEventId}`,
      triggerEvent
    );
  }
  post(triggerEvent:TriggerEvent) {
    return this.http.post<TriggerEvent>(`${environment.baseUrl}TriggerEvents/`, triggerEvent);
  }
  delete(triggerEvent:TriggerEvent) {
    return this.http.delete(`${environment.baseUrl}TriggerEvents/${triggerEvent.triggerEventId}`);
  }
}
