import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PrototypeCategory } from 'src/app/data/algorithm/PrototypeCategory';
import { PrototypeSubCategory } from 'src/app/data/algorithm/PrototypeSubCategory';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgoImageType } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { AddModeEnum } from '../category-prototype/category-prototype.component';

@Component({
  selector: 'app-subcategory-prototype',
  templateUrl: './subcategory-prototype.component.html',
  styleUrls: ['./subcategory-prototype.component.scss'],
})
export class SubcategoryPrototypeComponent implements OnInit, OnChanges {
  @Input() langId: number = 1;
  subCatFilteredOptions: Observable<PrototypeSubCategory[]>;
  catFilteredOptions: Observable<PrototypeCategory[]>;
  subCategories: PrototypeSubCategory[];
  subCategoryForm: FormGroup;
  subCategory: PrototypeSubCategory;
  errorMessage: string;
  addMode: AddModeEnum = AddModeEnum.FromList;
  AddModeEnum = AddModeEnum;
  AlgoImageType = AlgoImageType;
  categories: PrototypeCategory[];
  constructor(
    private fb: FormBuilder,
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.langId &&
      !changes.langId.isFirstChange() &&
      changes.langId.currentValue !== changes.langId.previousValue
    ) {
      this.getListPrototypeSubCategory();
    }
  }

  ngOnInit(): void {
    this.getListPrototypeSubCategory();
    this.getListPrototypeCategory();
  }

  getListPrototypeSubCategory() {
    return this.algorithmService
      .getListPrototypeSubCategory(this.langId)
      .subscribe((data) => {
        this.subCategories = data;
        if (!this.subCategory) {
          this.subCategory = new PrototypeSubCategory();
          this.subCategory.files = [];
        }
        this.initForm();
      });
  }
  getListPrototypeCategory() {
    return this.algorithmService
      .getListPrototypeCategory(this.langId)
      .subscribe((data) => {
        this.categories = data;
      });
  }
  initForm() {
    this.createForm();
    this.setSubCatFilterdOptions();
    this.setCatFilterdOptions();
  }
  createForm() {
    this.subCategoryForm = this.fb.group({
      id: this.fb.control(
        this.subCategory.subCategoryId ? this.subCategory.subCategoryId : 0
      ),
      name: this.fb.control(this.subCategory.name, Validators.required),
      catName: this.fb.control(this.getCatName(), Validators.required),
      orderBy: this.fb.control(this.subCategory.orderBy, Validators.required),
      rangeStart: this.fb.control(
        this.subCategory.rangeStart,
        Validators.required
      ),
      rangeEnd: this.fb.control(this.subCategory.rangeEnd, Validators.required),
      validExceptPersent: this.fb.control(
        this.subCategory.validExceptPersent,
        Validators.required
      ),
    });
  }
  getCatName() {
    return this.categories?.find(
      (x) => x.categoryId == this.subCategory.parentCategoryId
    )?.name;
  }
  setSubCatFilterdOptions() {
    this.subCatFilteredOptions = this.subCategoryName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSubCat(value))
    );
  }
  setCatFilterdOptions() {
    this.catFilteredOptions = this.categoryName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCat(value))
    );
  }
  private _filterSubCat(value: number): PrototypeSubCategory[] {
    const filterValue = value?.toString();

    return this.subCategories?.filter((x) =>
      x.name.toString().includes(filterValue)
    );
  }
  private _filterCat(value: number): PrototypeCategory[] {
    const filterValue = value?.toString();

    return this.categories?.filter((x) =>
      x.name.toString().includes(filterValue)
    );
  }
  onChangeMode(e: MatRadioChange) {
    this.subCategory = new PrototypeSubCategory();
    this.subCategory.files = [];
    this.createForm();
  }
  getPrototypeSubCategory(id: number) {
    return this.algorithmService
      .getPrototypeSubCategory(id, this.langId)
      .subscribe((data) => {
        this.subCategory = data;
        this.createForm();
      });
  }
  onOptionCatSelected(event: MatAutocompleteSelectedEvent) {
    // when the user selects an option from the autocomplete
    const selectedCat = this.findCatByName(event.option.value);
    //this.getPrototypeSubCategory(selectedCat.categoryId);
  }
  onOptionSubCatSelected(event: MatAutocompleteSelectedEvent) {
    // when the user selects an option from the autocomplete
    const selectedSubcat = this.findSubCatByName(event.option.value);
    this.getPrototypeSubCategory(selectedSubcat.subCategoryId);
  }
  findSubCatByName(name: string) {
    return this.subCategories.find((x) => x.name === name);
  }

  findCatByName(name: string) {
    return this.categories.find((x) => x.name === name);
  }

  onUploadFinished(event) {
    const finded = this.subCategory.files.find(
      (x) => x.fileType == event.fileType
    );
    if (finded) {
      this.subCategory.files = this.subCategory.files.filter(
        (x) => x.fileType != event.fileType
      );
    }
    this.subCategory.files.push(event);
  }

  getImage(imageType: number) {
    const image = this.subCategory.files?.find((x) => x.fileType === imageType);
    return image ? `${image.webPath}/${image.fileName}` : '';
  }
  getCheckedImage(imageType: number) {
    const image = this.subCategory.files?.find((x) => x.fileType === imageType);
    return image?.fileId;
  }
  handleErrorOrSuccess(res: ErrorMessage | any, snackbarMassege: string) {
    if ((res as ErrorMessage)?.message) {
      const error = res as ErrorMessage;
      this.errorMessage = error.message;
    } else {
      this._snackBar
        .open(`Prototype category ${snackbarMassege}`, 'Close', {})
        ._dismissAfter(2000);
      this.getListPrototypeSubCategory();
    }
  }
  getParentCategoryId() {
    const catName = this.subCategoryForm.get('catName').value;
    return this.categories.find((x) => x.name === catName)?.categoryId;
  }
  onSubmit() {
    if (this.subCategoryForm.valid) {
      this.subCategory.subCategoryId = this.subCategoryForm.get('id').value;
      this.subCategory.name = this.subCategoryForm.get('name').value;
      this.subCategory.rangeEnd = this.subCategoryForm.get('rangeEnd').value;
      this.subCategory.rangeStart =
        this.subCategoryForm.get('rangeStart').value;
      this.subCategory.validExceptPersent =
        this.subCategoryForm.get('validExceptPersent').value;
      this.subCategory.orderBy = this.subCategoryForm.get('orderBy').value;
      this.subCategory.parentCategoryId = this.getParentCategoryId();
      this.subCategory.langId = this.langId;
      if (this.subCategory.subCategoryId == 0) {
        this.algorithmService
          .addPrototypeSubCategory(this.subCategory)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'added');
          });
      } else if (this.subCategory.subCategoryId > 0) {
        this.algorithmService
          .setPrototypeSubCategory(this.subCategory)
          .subscribe((res) => {
            this.handleErrorOrSuccess(res, 'updated');
          });
      }
    }
  }

  get subCategoryName() {
    return this.subCategoryForm.get('name');
  }
  get categoryName() {
    return this.subCategoryForm.get('catName');
  }
}
