import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Deal } from '../data/Deal';
import { environment } from 'src/environments/environment';
import { LifeStyleReport } from '../data/LifeStyleReport';

@Injectable({
  providedIn: 'root',
})
export class LifeStyleReportsService {
  constructor(private http: HttpClient) {}

  getByPatientId(patientId) {
    return this.http.get<LifeStyleReport>(
      `${environment.baseUrl}LifeStyleReports/${patientId}`
    );
  }

  put(lifeStyleReport: LifeStyleReport) {
    return this.http.put(
      `${environment.baseUrl}LifeStyleReports/${lifeStyleReport.lifeStyleReportId}`,
      lifeStyleReport
    );
  }
  post(lifeStyleReport: LifeStyleReport) {
    return this.http.post<any>(
      `${environment.baseUrl}LifeStyleReports/`,
      lifeStyleReport
    );
  }
  delete(lifeStyleReport: LifeStyleReport) {
    return this.http.delete(
      `${environment.baseUrl}LifeStyleReports/${lifeStyleReport.lifeStyleReportId}`
    );
  }
}
