export class HeadFilter {
  constructor(
    statuseIds,
    agentId,
    agent2Id,
    statusId,
    dateStart,
    dateEnd,
    sourceId,
    orderBy,
    acending,
    pageSize,
    pageNumber,
    paymentMethodId,
    subjectId,
    categoryId,
    onlyManager,
    searchTerm,
    tabId,
    lifeStyle,
    firstCall,
    decisionId,
    subCategoryId,
    bloodTestTypeId,
    rating,
    noteTypeId,
    onFollow,
    type?,
    langId?
  ) {
    this.statuseIds = statuseIds;
    this.agentId = agentId;
    this.agent2Id = agent2Id;
    this.statusId = statusId;
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.sourceId = sourceId;
    this.orderBy = orderBy;
    this.acending = acending;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.paymentMethodId = paymentMethodId;
    this.subjectId = subjectId;
    this.categoryId = categoryId;
    this.onlyManager = onlyManager;
    this.searchTerm = searchTerm;
    this.tabId = tabId;
    this.lifeStyle = lifeStyle;
    this.firstCall = firstCall;
    this.decisionId = decisionId;
    this.subCategoryId = subCategoryId;
    this.bloodTestTypeId = bloodTestTypeId;
    this.rating = rating;
    this.noteTypeId = noteTypeId;
    this.onFollow = onFollow;
    this.type = type;
    this.langId = langId;
  }

  agentId: number;
  agent2Id: number;
  statusId: number;
  dateStart: Date;
  dateEnd: Date;
  dateStartSecondary: Date;
  dateEndSecondary: Date;
  sourceId: number;
  orderBy: string;
  acending: boolean;
  pageSize: number;
  pageNumber: number;
  statuseIds: number[];
  paymentMethodId: number;
  subjectId: number;
  categoryId: number;
  searchTerm: string;
  onlyManager: boolean;
  secondStartDate: string;
  secondEndDate: string;
  tabId: number;
  lifeStyle: number;
  firstCall: number;
  decisionId: number;
  subCategoryId: number;
  bloodTestTypeId: number;
  rating: number;
  noteTypeId: number;
  onFollow: boolean;
  type?: number;
  langId?: number;
}
