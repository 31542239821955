import { AppImage } from './AppImage';

export class PrototypeCategory {
  categoryId: number;
  langId: number;
  name: string;
  description: string;
  color: string;
  isPremium: boolean;
  files: AppImage[];
  orderBy: number;
}
