import { Component, OnInit } from '@angular/core';
//import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css'],
})
export class LoadingPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      const regularUser = JSON.parse(
        localStorage.getItem('currentUser')
      ) as RegularUser;
      if (regularUser == null) {
        this.router.navigate(['/login']);
      } else if (userHasPermisions(['All Leads', 'My Leads'], regularUser))
        this.router.navigate(['/leads']);
      else {
        if (userHasPermisions(['All Patients', 'My Patients'], regularUser))
          this.router.navigate(['/tests']);
      }
    }, 10);
    const regularUser = JSON.parse(
      localStorage.getItem('currentUser')
    ) as RegularUser;
    if (regularUser == null) {
      this.router.navigate(['login']);
    } else if (userHasPermisions(['Leads_GetList'], regularUser))
      this.router.navigate(['leads']);
    else {
      if (userHasPermisions(['Products_GetList'], regularUser))
        this.router.navigate(['tests']);
    }
  }
}
