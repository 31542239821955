export function padWithZeros(n, width, z = '0') {
  if (!z) z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function getDay(dateStr) {
  var d = (new Date(Date.parse(dateStr)) + '').split(' ');
  d[2] = d[2] + ',';

  return [d[0], d[1], d[2], d[3]].join(' ');
}
export function getDayV2(dateStr) {
  var d = dateStr.split(' ');
  return d[0];
}
export function convertToTimespan(duration) {
  var sec_num = parseInt('' + duration, 10); // don't forget the second param
  var hours: any = Math.floor(sec_num / 3600);
  var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
  var seconds: any = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}
