import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { take } from 'rxjs/operators';
import { LifeStyleCalculation } from 'src/app/data/LifeStyleCalculation';

import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { NameAndId } from 'src/app/data/NameAndId';

import { Status } from 'src/app/data/Status';
import { LifeStyleCalculationsService } from 'src/app/Services/life-style-calculations.service';
import { LifeStyleLocationsService } from 'src/app/Services/life-style-locations.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-lifestyle-question-node-v2',
  templateUrl: './lifestyle-question-node-v2.component.html',
  styleUrls: ['./lifestyle-question-node-v2.component.css'],
})
export class LifestyleQuestionNodeV2Component implements OnInit {
  templateId = 0;
  childLength: number[] = [];
  @Input() isDone: boolean;
  @Input() question: LifestyleQuestion;
  @Input() designTypes: Status[];
  @Input() containerDesignTypes: Status[];
  @Input() templatesNames: NameAndId[];
  @Input() length: number[];
  @Input() lifeStyleCalculations: LifeStyleCalculation[];

  @Output() addNewQuest = new EventEmitter<LifestyleQuestion>();
  @Output() deleteQuest = new EventEmitter<LifestyleQuestion>();
  @Output() updateExpanded = new EventEmitter<LifestyleQuestion>();
  @Output() addFromTemplate = new EventEmitter<any>();
  @Output() duplicate = new EventEmitter<any>();
  @Output() move = new EventEmitter<any>();
  @Output() checkedChanged = new EventEmitter<any>();
  @Output() setMarkAsReference = new EventEmitter<any>();
  @Output() removeReference = new EventEmitter<any>();
  @Output() moveNodeToMarked = new EventEmitter<any>();
  @Output() moveNodeToRoot = new EventEmitter<any>();
  @Output() changeOrder = new EventEmitter<any>();

  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    //this.mep.expanded = true;

    var maxInx = 0;
    if (this.question.childQuestions && this.question.childQuestions.length > 0)
      var maxInx =
        this.question.childQuestions[this.question.childQuestions.length - 1]
          .order + 1;
    1;
    for (let index = 0; index < maxInx; index++) {
      this.childLength.push(index + 1);
    }
  }

  addNewQuestion() {
    var question = new LifestyleQuestion();
    //question.lifestyleQuestionLangs = [];
    //var questionLang = new LifestyleQuestionL();
    question.lifeStyleReportVersionId = this.question.lifeStyleReportVersionId;
    question.designTypeId = 232;
    question.level = this.question.level + 1;
    if (this.question.level == -1) question.level = 1;

    question.parentQuestionId = this.question.lifestyleQuestionId;
    this.addNewQuest.next(question);
  }
  onAddNewQuestion(question) {
    this.addNewQuest.next(question);
  }
  getlevelClass(level) {
    switch (level % 4) {
      case 0:
        return 'color-level-1';
      case 1:
        return 'color-level-2';
      case 2:
        return 'color-level-3';
      case 3:
        return 'color-level-4';
    }
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  valuechange($event) {
    this.lifestyleQuestionsService.put(this.question).subscribe((res) => {});
  }
  deleteCurrentNode() {
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans && res.ans === 'yes') {
          this.deleteQuest.next(this.question);
        }
      });
    this.popupHandlerService.openConfirmationDialog('', 'Delete Question');
  }
  onDeleteQuestion(question) {
    this.deleteQuest.next(question);
  }
  onUpdateExpanded(val) {
    this.question.isExpanded = val;
  }
  onAddFromTemplate(obj = null) {
    if (obj == null) {
      obj = {
        templateId: this.templateId,
        questionId: this.question.lifestyleQuestionId,
      };
    }
    this.addFromTemplate.next(obj);
  }
  onDuplicate(questionId = null) {
    if (!questionId) {
      questionId = this.question.lifestyleQuestionId;
    }
    this.duplicate.next(questionId);
  }
  onMoveUp() {
    var obj = {
      questionId: this.question.lifestyleQuestionId,
      parentId: this.question.parentQuestionId,
      direction: -1,
    };

    this.move.next(obj);
  }
  onMoveDown() {
    var obj = {
      questionId: this.question.lifestyleQuestionId,
      parentId: this.question.parentQuestionId,
      direction: 1,
    };

    this.move.next(obj);
  }
  onMove(obj) {
    this.move.next(obj);
  }
  onCheckedClicked(event) {
    if (this.question.isChecked)
      this.checkedChanged.next({
        questionId: this.question.lifestyleQuestionId,
      });
  }
  onCheckedChanged(obj) {
    this.checkedChanged.next(obj);
  }
  onSetMarkAsReference(obj = null) {
    if (obj == null) {
      obj = { questionId: this.question.lifestyleQuestionId };
    }
    this.setMarkAsReference.next(obj);
  }
  onRemoveReference(obj = null) {
    if (obj == null) {
      obj = { questionId: this.question.lifestyleQuestionId };
    }
    this.removeReference.next(obj);
  }
  onMoveNodeToMarked(obj = null) {
    if (obj == null) {
      obj = { questionId: this.question.lifestyleQuestionId };
    }
    this.moveNodeToMarked.next(obj);
  }
  onMoveNodeToRoot(obj = null) {
    if (obj == null) {
      obj = { questionId: this.question.lifestyleQuestionId };
    }
    this.moveNodeToRoot.next(obj);
  }

  onChangeOrder($event) {
    let obj = {
      questionId: this.question.lifestyleQuestionId,
      order: +this.question.order,
    };
    this.changeOrder.emit(obj);
  }
  /*
  expand() {
    this.isExpanded = true;
  }
  colapse() {
    this.isExpanded = false;
  }*/
}
