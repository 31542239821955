import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { PatientDelivery } from '../data/PatientDeliveries';
import { PatientDeliveryMin } from '../data/PatientDeliveryMin';

@Injectable({
  providedIn: 'root',
})
export class PatientDeliveriesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<PatientDeliveryMin[]>(
      `${environment.baseUrl}PatientDeliveries/`
    );
  }
  getStatusNotOk() {
    return this.http.get<PatientDeliveryMin[]>(
      `${environment.baseUrl}PatientDeliveries/NotOk`
    );
  }
  getByPatientId(patientId: number) {
    return this.http.get<PatientDelivery[]>(
      `${environment.baseUrl}PatientDeliveries/${patientId}`
    );
  }
  releaseAllDeliveries(isDelivery) {
    return this.http.get<any>(
      `${environment.baseUrl}PatientDeliveries/releaseAllDeliveries/${isDelivery}`
    );
  }
}
