import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-lab',
  templateUrl: './add-lab.component.html',
  styleUrls: ['./add-lab.component.scss'],
})
export class AddLabComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddLabComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
  }

  addLab() {
    this.dialogRef.close({
      type: this.data.labId ? 'edit' : 'add',
      lab: this.data,
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
