import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Laboratory } from 'src/app/data/algorithm/Laboratory';
import { LabResponceError } from 'src/app/data/algorithm/LabResponceError';
import { LabResponceReport } from 'src/app/data/algorithm/LabResponceReport';
import { LabResponceTypedError } from 'src/app/data/algorithm/LabResponceTypedError';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { UploadErrorsEnum } from 'src/app/Enums/StatusesEnum';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-upload-lab-result',
  templateUrl: './upload-lab-result.component.html',
  styleUrls: ['./upload-lab-result.component.scss'],
})
export class UploadLabResultComponent implements OnInit {
  versionId: number;
  langId: number = 1;
  labVersions: LabVersion[];
  errors: LabResponceTypedError[];
  inUploadProcess: boolean;
  labId: number = 0; //to get all the lab versions
  statusMessage: string;
  numberValue: number;
  errorMessage: string;
  constructor(
    private algorithmService: AlgorithmService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getLabVersions();
  }
  getLabVersions() {
    this.algorithmService
      .getLabWithVersions(this.langId, this.labId)
      .subscribe((data) => {
        this.labVersions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.labVersions.push(y);
          });
        });
        this.labVersions = this.labVersions.filter((y) => y.isLock);
        this.versionId = this.labVersions && this.labVersions[0].labId;
      });
  }
  uploadLabResult(files: any) {
    if (files.length > 0) {
      const fileToUpload = files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload);
      this.inUploadProcess = true;
      this.statusMessage = 'קובץ בטעינה, אנא המתן';
      this.numberValue = 3;

      this.algorithmService.uploadLabResult(this.versionId, formData).subscribe(
        (data) => {
          this.inUploadProcess = false;
          this.numberValue = data ? 2 : 1;
          this.statusMessage = data ? 'בעיה בטעינת הקובץ' : 'הקובץ נטען בהצלחה';
          this.errorMessage = '';
          if (data) {
            this.errorMessage = data['message'];
            this._matSnackBar
              .open(data['message'], 'Close')
              ._dismissAfter(4000);
          }
        },
        () => {
          this.inUploadProcess = false;
          this.numberValue = 2;
          this.statusMessage = 'בעיה בטעינת הקובץ';
          this._matSnackBar
            .open('Error occurred during uploading', 'Close')
            ._dismissAfter(4000);
        }
      );
    }
  }

  filterErrors(errors: LabResponceError) {
    const a = Object.keys(errors).forEach(
      (k) => errors[k] == null && delete errors[k]
    );
  }

  mapErrors(errors: LabResponceTypedError) {
    switch (errors.statusName) {
      case UploadErrorsEnum.MissingSnp:
        return errors.errors.map((x) => {
          const a = [
            { name: 'chromosome', value: x.chromosome },
            { name: 'coordinate', value: x.coordinate },
          ];
          return a;
        });
      case UploadErrorsEnum.ResultNoMatch:
        return errors.errors.map((x) => {
          const b = [
            { name: 'chromosome', value: x.chromosome },
            { name: 'coordinate', value: x.coordinate },
            { name: 'algoSubCatId', value: x.algoSubCatId },
            { name: 'algoSnpId', value: x.algoSnpId },
          ];
          return b;
        });
      case UploadErrorsEnum.MissingSubCatSnp:
        return errors.errors.map((x) => {
          return {
            chromosome: x.chromosome,
            coordinate: x.coordinate,
            subCatSnpId: x.subCatSnpId,
          };
        });
      case UploadErrorsEnum.NotInRange:
        return errors.errors.map((x) => {
          return { algoSnpId: x.algoSnpId, subCatSnpId: x.subCatSnpId };
        });
      case UploadErrorsEnum.NAValue:
      case UploadErrorsEnum.UnknownError:
        return errors.errors.map((x) => {
          return {
            chromosome: x.chromosome,
            coordinate: x.coordinate,
            barcode: x.barcode,
          };
        });
    }
  }
}
