import { Component, OnInit } from '@angular/core';
import { ActionConvertion } from 'src/app/data/ActionConvertion';
import { AppModule } from 'src/app/data/AppModule';
import { EmailTemplateMin } from 'src/app/data/EmailTemplateMin';
import { ModuleAction } from 'src/app/data/ModuleAction';
import { ModuleToAction } from 'src/app/data/ModuleToAction';
import { Status } from 'src/app/data/Status';
import { TriggerEventObjectType } from 'src/app/data/TriggerEventObjectType';
import { ActionMin } from 'src/app/data/user-roles/ActionMin';
import { ModuleMin } from 'src/app/data/user-roles/ModuleMin';
import { RuleMin } from 'src/app/data/user-roles/RuleMin';
import { UserRoleMin } from 'src/app/data/user-roles/UserRoleMin';
import { ActionConvertionsService } from 'src/app/Services/action-convertions.service';
import { AppModulesService } from 'src/app/Services/app-modules.service';
import { ModuleActionsService } from 'src/app/Services/module-actions.service';
import { ModuleToActionsService } from 'src/app/Services/module-to-actions.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { UserRolesService } from 'src/app/Services/user-roles.service';

@Component({
  selector: 'app-module-to-actions',
  templateUrl: './module-to-actions.component.html',
  styleUrls: ['./module-to-actions.component.scss'],
})
export class ModuleToActionsComponent implements OnInit {
  usingConventions = false;
  newAppModuleId: number;
  newModuleActionId: number;
  newEventName: string;
  eventTypes: Status[];
  statuses: Status[];
  actionTypes: Status[];
  emailTemplates: EmailTemplateMin[];
  productTypes: Status[];
  triggerEventObjectTypes: TriggerEventObjectType[];
  moduleToActions: ModuleToAction[];

  //userRoles
  userRoles: UserRoleMin[];
  showedModules: ModuleMin[] = [
    { actions: [], appModuleId: 1, appModuleName: 'test 1' },
    { actions: [], appModuleId: 2, appModuleName: 'test 2' },
  ];
  showedActions: ModuleAction[];
  showedRules: RuleMin[];
  selectedRoleId: number;
  selectedModuleId: number;
  selectedActionId: number;

  constructor(
    private appModulesService: AppModulesService,
    private moduleActionsService: ModuleActionsService,
    private moduleToActionsService: ModuleToActionsService,
    private popupHandlerService: PopupHandlerService,
    private userRolesService: UserRolesService,
    private actionConvertionsService: ActionConvertionsService
  ) {}
  eventsDisplayedColumns: string[] = [
    'AppModuleId',
    'ModuleActionId',
    'Delete',
  ];
  appModules: AppModule[];
  moduleActions: ModuleAction[];
  ngOnInit(): void {
    //userRoles

    //ModuleToAction
    this.getAllAppModules();
  }

  //UserRoles Service
  getModuleActions() {
    this.moduleToActionsService
      .forModule(this.selectedModuleId)
      .subscribe((data) => {
        this.showedActions = data;
      });
  }
  onModuleClick(moduleId: number) {
    this.selectedModuleId = moduleId;
    this.getModuleActions();
  }
  onActionClick(action: ModuleToAction) {
    this.moduleToActionsService
      .updateChecked(
        this.selectedModuleId,
        action.moduleActionId,
        !action.checked
      )
      .subscribe((data) => {
        this.getModuleActions();
      });
  }
  onPermissionClick(permissionId: number) {
    console.log(permissionId);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  sortByName(appModules: any[], name: string) {
    return appModules.sort((a, b) => {
      const nameA = a[name].toUpperCase();
      const nameB = b[name].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  getAllAppModules() {
    this.appModulesService.getAll().subscribe((data) => {
      this.appModules = this.sortByName(data, 'appModuleName');
    });
  }
}
