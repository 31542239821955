import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CancelationRequest } from '../data/CancelationRequest';
import { HeadFilter } from '../data/HeadFilter';
import { DatePipe } from '@angular/common';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import { ErrorMessage } from '../data/ErrorMessage';
import { CancelationRequestForTableMin } from '../data/CancelationRequestForTableMin';
import { CancelationPutResponse } from '../data/CancelationPutResponse';

@Injectable({
  providedIn: 'root',
})
export class CancelationRequestService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  createFromDeal(dealBundleId) {
    return this.http.get<CancelationRequest | ErrorMessage>(
      `${environment.baseUrl}cancelationRequests/createFromDeal/${dealBundleId}`
    );
  }
  getAllByFilter(headFilter: HeadFilter) {
    return this.http.get<any>(
      `${
        environment.baseUrl
      }cancelationRequests/Filter/${this.httpFilterHelper.createCancellationRequestsPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getAllByFilterV2(headFilter: HeadFilter) {
    return this.http.post<any>(
      `${environment.baseUrl}cancelationRequests/FilterV2/`,
      headFilter
    );
  }
  getById(cancelationRequestId) {
    return this.http.get<CancelationRequest>(
      `${environment.baseUrl}cancelationRequests/${cancelationRequestId}`
    );
  }
  getByCancelDealId(cancelDealId) {
    return this.http.get<CancelationRequest>(
      `${environment.baseUrl}cancelationRequests/ByCancelDeal/${cancelDealId}`
    );
  }

  addCoupon(cancelationRequestId, comapanyId, codeCoupon) {
    return this.http.get<any>(
      `${environment.baseUrl}cancelationRequests/AddCoupon/${cancelationRequestId}/${comapanyId}/${codeCoupon}`
    );
  }
  changeConservationAgent(cancelationRequestId, agentId) {
    return this.http.get<any>(
      `${environment.baseUrl}cancelationRequests/ChangeConservationAgent/${cancelationRequestId}/${agentId}`
    );
  }

  put(cancelationRequest: CancelationRequest) {
    return this.http.put<CancelationPutResponse | ErrorMessage>(
      `${environment.baseUrl}cancelationRequests/${cancelationRequest.cancelationRequestId}`,
      cancelationRequest
    );
  }
  saveRefund(cancelationRequest: CancelationRequest) {
    return this.http.put<CancelationRequest | ErrorMessage>(
      `${environment.baseUrl}cancelationRequests/SaveRefund/${cancelationRequest.cancelationRequestId}`,
      cancelationRequest
    );
  }
  putRefund(cancelationRequest: CancelationRequest) {
    return this.http.put<CancelationRequest | ErrorMessage>(
      `${environment.baseUrl}cancelationRequests/Refund/${cancelationRequest.cancelationRequestId}`,
      cancelationRequest
    );
  }
  PerformCancelationActions(cancelationRequestId, actionsIds: string) {
    return this.http.get<any>(
      `${environment.baseUrl}cancelationRequests/PerformCancelationActions/${cancelationRequestId}/${actionsIds}`
    );
  }
}
