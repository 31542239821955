import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Client } from '../data/Client';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LeadStatus } from '../data/LeadStatus';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LeadStasusesService {
  leadStatuses: any;

  constructor(private http: HttpClient) {}
  getAll(): Observable<LeadStatus[]> {
    if (this.leadStatuses) {
      return of(this.leadStatuses); // return from cache
    } else {
      return this.http
        .get<LeadStatus[]>(`${environment.baseUrl}LeadStatus/`)
        .pipe(map((res) => (this.leadStatuses = res)));
    }
    //  return this.http.get<LeadStatus[]>(`${environment.baseUrl}LeadStatus/`);
  }
  getAllForMenu() {
    return this.http.get<LeadStatus[]>(
      `${environment.baseUrl}LeadStatus/AllForMenu`
    );
  }
}
