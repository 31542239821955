<button class="back back--client" (click)="goBackToCancelations()">
  < Back to Cancelations
</button>
<div class="cancel-grid">
  <mat-card *ngIf="refund" style="padding: 16px 5rem">
    <div class="wrapper">
      <div class="header">
        <span class="header__title">Refund Form</span>
        <div class="header__status">
          <span>Credit Status:&nbsp;</span>{{ refund.status.name }}
        </div>
        <span class="header__btn">
          <button
            *ngIf="showSaveBtn()"
            mat-button
            class="btn-save button primary"
            (click)="submitFormProgrammatically(true)"
          >
            SAVE
          </button>
        </span>
      </div>
      <hr class="hr" />
      <div class="d-flex">
        <perfect-scrollbar
          style="max-height: 100vh; height: 73.5vh"
          [perfectScrollbar]="config"
        >
          <mat-list>
            <form
              #refundFormRef="ngForm"
              *ngIf="refundForm"
              [formGroup]="refundForm"
            >
              <mat-list-item class="row">
                <span> Accountant: </span>
                <mat-form-field appearance="fill">
                  <mat-label>Accountant</mat-label>
                  <mat-select formControlName="accountant">
                    <mat-option
                      *ngFor="let agent of agents"
                      [value]="agent.agentId"
                    >
                      {{ agent.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>

              <mat-list-item class="row">
                <span> Payment Method: </span>
                <!--  <mat-form-field appearance="fill">
                  <input formControlName="paymentMethod" type="text" matInput />
                </mat-form-field>
                -->
                <span>
                  {{
                    this.refund &&
                      this.refund.originalDealBundle &&
                      this.refund.originalDealBundle.deals &&
                      this.refund.originalDealBundle.deals[0] &&
                      this.refund.originalDealBundle.deals[0].paymentMethod.name
                  }}
                </span>
              </mat-list-item>

              <mat-list-item class="row">
                <span> Refund Method: </span>
                <mat-form-field appearance="fill">
                  <mat-label>Refund Method</mat-label>
                  <mat-select
                    (selectionChange)="onChangeMethod($event)"
                    formControlName="refundMethodId"
                  >
                    <mat-option value="1"> Credit Card </mat-option>
                    <mat-option value="5"> Wired Transfer </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>

              <ng-container
                *ngIf="refundForm.controls.refundMethodId.value === '1'"
              >
                <mat-list-item class="row">
                  <span> Last 4 digits: </span>
                  <mat-form-field appearance="fill">
                    <input
                      mask="0000"
                      formControlName="digits"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item class="row">
                  <span> Validity: </span>
                  <mat-form-field appearance="fill">
                    <input
                      mask="00/00"
                      formControlName="validity"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                </mat-list-item>
                <!--
                <mat-list-item class="row">
                  <span> CVV: </span>
                  <mat-form-field appearance="fill">
                    <input
                      mask="000"
                      formControlName="cvv"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                
                </mat-list-item>
                  -->
              </ng-container>
              <ng-container
                *ngIf="refundForm.controls.refundMethodId.value === '5'"
              >
                <mat-list-item class="row">
                  <span> Bank name: </span>
                  <mat-form-field appearance="fill">
                    <input formControlName="bankName" type="text" matInput />
                  </mat-form-field>
                </mat-list-item>
                <mat-list-item class="row">
                  <span> Branch number: </span>
                  <mat-form-field appearance="fill">
                    <input
                      formControlName="branchNumber"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                </mat-list-item>
                <mat-list-item class="row">
                  <span> Account number: </span>
                  <mat-form-field appearance="fill">
                    <input
                      formControlName="accountNumber"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                </mat-list-item>
              </ng-container>

              <mat-list-item class="row">
                <span> Amount To Refund: </span>
                <mat-form-field appearance="fill">
                  <input type="number" formControlName="amount" matInput />
                </mat-form-field>
              </mat-list-item>

              <mat-list-item class="row">
                <span> Date of refund: </span>
                <mat-form-field color="accent" appearance="fill">
                  <mat-label>Date Of Refund</mat-label>
                  <input
                    formControlName="actualRefundDate"
                    matInput
                    [matDatepicker]="picker2"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
              </mat-list-item>

              <mat-list-item class="row">
                <span> Reference number: </span>
                <mat-form-field appearance="fill">
                  <input
                    type="text"
                    formControlName="confirmationNumber"
                    matInput
                  />
                </mat-form-field>
              </mat-list-item>
              <mat-list-item class="row">
                <span> Credit invoice: </span>

                <app-upload-results-csv
                  (onUploadFinished)="onUploadFinished($event)"
                  *ngIf="!isClosed()"
                  [title]="'Upload File'"
                  [classAttr]="'clienttAttr'"
                  [uploadPath]="
                    'deals/UploadInovice/' +
                    refund.cancelDealBundle.deals[0]?.dealId
                  "
                >
                  <!--getInoviceUploadPath()-->
                </app-upload-results-csv>
                <div
                  *ngIf="uploadInvoiceError"
                  style="margin-left: auto; color: red"
                >
                  חייב להעלות קובץ
                </div>
                <button
                  *ngIf="
                    refund &&
                    refund.cancelDealBundle.deals &&
                    refund.cancelDealBundle.deals[0] &&
                    refund.cancelDealBundle.deals[0].fileUploads &&
                    refund.cancelDealBundle.deals[0].fileUploads.length > 0
                  "
                  (click)="downloadInvoice()"
                  mat-button
                >
                  Download
                </button>
              </mat-list-item>
              <mat-list-item
                class="row row-action"
                *ngIf="showCloseRefundBtn()"
              >
                <button (click)="onCloseRefund()" mat-button>
                  Approve and close refund
                </button>
                <button class="cancel" (click)="onReturnToManager()" mat-button>
                  Cancel refund
                </button>
              </mat-list-item>
            </form>
          </mat-list>
        </perfect-scrollbar>
        <div class="client-details">
          <div class="client-details__title">Client Details</div>
          <mat-list>
            <mat-list-item class="row">
              <span>Client Type:</span>
              {{ client && client.clientType && client.clientType.name }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Name:</span>
              <span class="link" (click)="goToClient()">{{
                getFullName(client.regularUser)
              }}</span>
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client ID:</span>
              {{ client.regularUser.socialSecurityNum }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Email:</span>
              {{ client.regularUser.email }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Tel:</span>
              <ng-container> {{ getUserPhone() }}</ng-container>
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Address:</span>
              {{
                client.regularUser.userAdresses[0].barCity.name +
                  " " +
                  client.regularUser.userAdresses[0].barStreet.name +
                  " " +
                  client.regularUser.userAdresses[0].streetNum
              }}
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </mat-card>
  <div style="height: fit-content">
    <app-client-notes
      [notes]="refundsNotes"
      (openNewNote)="createNewNote($event)"
      *ngIf="isNoteEditMode == false"
      [classAttr]="'clientAttr'"
      (reloadNotes)="onReloadNotes()"
    >
    </app-client-notes>

    <app-client-add-note
      [note]="noteToEdit"
      (submitNote)="saveNote($event)"
      [classAttr]="'clientAttr'"
      (cancelNote)="closeNewNoteWindow()"
      *ngIf="isNoteEditMode == true"
    >
    </app-client-add-note>
  </div>
</div>
