import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { BulkChangeProductAgent } from 'src/app/data/connectors/BulkChangeProductAgent';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { ProductForTableMin } from 'src/app/data/ProductForTableMin';
import { Status } from 'src/app/data/Status';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { TestsConnectorService } from 'src/app/Services/tests-connector.service';

@Component({
  selector: 'app-all-tests',
  templateUrl: './all-tests.component.html',
  styleUrls: ['./all-tests.component.scss'],
})
export class AllTestsComponent implements OnInit {
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  statuses: Status[];
  agents: AgentMin[];
  followUpAgents: AgentMin[];
  leadSources: LeadSource[];
  checkedIds: any;
  checkedAll: boolean = false;
  productsData: ProductForTableMin[];
  nutrisionists: AgentMin[];
  isLoading: boolean;
  constructor(
    private productsService: ProductsService,
    private router: Router,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    private leadSourcesService: LeadSourcesService,
    private filterMemoryService: FilterMemoryService,
    private regularUsersService: RegularUsersService,
    private testConnectorService: TestsConnectorService,
    private popupHandlerService: PopupHandlerService
  ) {
    this.checkedIds = {};
    this.headFilter = this.filterMemoryService.getfilter(PageTypeEnum.tests);
    /*
    this.headFilter = new HeadFilter(
      [],
      -1,
      -1,
      null,
      null,
      -1,
      '',
      false,
      100,
      1
    );*/
  }

  ngOnInit(): void {
    this.testConnectorService.bulkChangeProductAgentSubject.subscribe(
      (data) => {
        if (data) {
          this.popupHandlerService.openConfirmationDialog(
            '',
            'Transfer Missions?'
          );
          this.popupHandlerService.confirmDialogSubject
            .pipe(take(1))
            .subscribe((_data) => {
              if (_data.ans === 'yes') {
                this.bulkChangeAgent(data, true);
              } else if (_data.ans === 'no') {
                this.bulkChangeAgent(data, false);
              }
            });
        }
      }
    );
    this.regularUsersService.currentUser.subscribe((user) => {
      var isFound = false;

      user.userRole.userPermissions.forEach((role) => {
        if (role.appModule.appModuleName === 'Products') {
          isFound = true;
        }
      });
      if (isFound == false) {
        return this.router.navigate(['/leads']);
      }
    });
    this.getAll();
    /* this.productsPusherService.testsProducts$.subscribe((data) => {
      if (data.list && data.metaData) {
        this.productsData = data.list;
        this.metaData = data.metaData;
      }
    }); */
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
      this.nutrisionists = data.filter(
        (x) =>
          x.roleTypeId == UserRoleEmum.nutritionist ||
          x.roleTypeId == UserRoleEmum.nutritionistManager
      );
      this.followUpAgents = data.filter(
        (x) => x.roleTypeId == UserRoleEmum.followUpAgent
      );
    });
    this.statusesService.getAll().subscribe((data) => {
      //  this.statuses = data;
      this.statuses = data;
      this.statuses = this.statuses.filter((x) => {
        return x.category == 'Product';
      });
    });
    this.leadSourcesService.getAll().subscribe((data) => {
      this.leadSources = data;
    });
  }

  bulkChangeAgent(data: BulkChangeProductAgent, transferMissions: boolean) {
    this.productsService
      .bulkChangeAgent2(
        data.productIds,
        data.agentId,
        data.isAgent2,
        transferMissions
      )
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog('agent');
        this.getAllWithFilter(this.headFilter, true, true);
      });
  }

  getAll() {
    this.isLoading = true;
    this.productsService.getAllByFilterV2(this.headFilter).subscribe((data) => {
      this.productsData = data.list;
      this.metaData = data.metaData;
      this.isLoading = false;
    });
  }

  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    if (isFromHead) {
      this.checkedIds = {};
      this.checkedAll = false;
      /* setTimeout(() => {
        this.checkedAll = false;
      }, 10); */
    }
    if (isFormPaginator) {
      this.checkedAll = false;
      /* setTimeout(() => {
        this.checkedAll = false;
      }, 10); */
    }
    this.headFilter = e;
    this.filterMemoryService.setfilter(this.headFilter, PageTypeEnum.tests);
    this.getAll();
    //this.productsService.getAll(this.headFilter);
  }
  changeCheckedIds(newCheckedIds) {
    this.checkedIds = newCheckedIds;
  }
  onCheckedIdsChanged(checkedIds) {
    this.checkedIds = checkedIds;

    this.checkedAll = true;

    setTimeout(() => {
      this.checkedAll = false;
    }, 10);
  }
}
