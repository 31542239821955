import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AutoSenderTag } from '../data/AutoSenderTag';
import { TriggerEventObjectType } from '../data/TriggerEventObjectType';

@Injectable({
  providedIn: 'root'
})
export class TriggerEventObjectTypesService {

  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<TriggerEventObjectType[]>(`${environment.baseUrl}TriggerEventObjectTypes/`)
}
getById(agentId) {
  return this.http.get<TriggerEventObjectType>(`${environment.baseUrl}TriggerEventObjectTypes/${agentId}`);
}
getTags(templateId) {
  return this.http.get<AutoSenderTag[]>(`${environment.baseUrl}TriggerEventObjectTypes/GetTags/${templateId}`);
}
put(triggerEventObjectType:TriggerEventObjectType) {
  return this.http.put(
    `${environment.baseUrl}TriggerEventObjectTypes/${triggerEventObjectType.triggerEventObjectTypeId}`,
    triggerEventObjectType
  );
}
post(triggerEventObjectType:TriggerEventObjectType) {
  return this.http.post<TriggerEventObjectType>(`${environment.baseUrl}TriggerEventObjectTypes/`, triggerEventObjectType);
}
delete(triggerEventObjectType:TriggerEventObjectType) {
  return this.http.delete(`${environment.baseUrl}TriggerEventObjectTypes/${triggerEventObjectType.triggerEventObjectTypeId}`);
}


}
