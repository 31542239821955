import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LifeStyleReportsService } from 'src/app/Services/life-style-reports.service';
import { ActivatedRoute } from '@angular/router';
import { LifeStyleReportLocal } from 'src/app/data/LifeStyleReportLocal';

@Component({
  selector: 'app-lifestyle-report-view',
  templateUrl: './lifestyle-report-view.component.html',
  styleUrls: ['./lifestyle-report-view.component.css'],
})
export class LifestyleReportViewComponent implements OnInit {
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  patientId: string;
  noData: boolean = true;
  showOldReport: boolean = false;
  lifeStyleLocal: LifeStyleReportLocal;
  constructor(
    private lifeStyleReportsService: LifeStyleReportsService,
    private route: ActivatedRoute
  ) {}
  nutritionData: any = [
    { value: 1, name: 'רגילה' },
    { value: 2, name: 'ללא חלב' },
    { value: 3, name: 'טבעוני' },
    { value: 4, name: 'צמחוני' },
    { value: 5, name: 'ללא גלוטן' },
    { value: 6, name: 'אחר' },
  ];
  ngOnInit(): void {
    this.patientId = this.route.snapshot.queryParamMap.get('patientId');
    this.lifeStyleReportsService
      .getByPatientId(this.patientId)
      .subscribe((data) => {
        if (data == null) {
          this.noData = true;
        } else {
          if (!data.isCompleteNew && /*data.isComplete &&*/ data.value) {
            this.lifeStyleLocal = JSON.parse(
              data.value
            ) as LifeStyleReportLocal;
            this.showOldReport = true;
          }
        }
      });
  }
  onEditClick() {
    this.onEdit.emit();
  }
}
