<mat-form-field class="time-select" appearance="fill">
  <mat-select
    #hoursStartRef
    required
    (selectionChange)="onHoursStartChange($event)"
  >
    <mat-option *ngFor="let item of hoursList" [value]="item">{{
      item
    }}</mat-option>
  </mat-select>
</mat-form-field>
<span class="colon">:</span>
<mat-form-field class="time-select" appearance="fill">
  <mat-select required (selectionChange)="onMinutsStartChange($event)">
    <mat-option
      *ngFor="let item of minutsList"
      [value]="item"
      [disabled]="hoursStartRef.value == '18' && item !== '00'"
      >{{ item }}</mat-option
    >
  </mat-select>
</mat-form-field>
<span class="until">until-</span>
<mat-form-field class="time-select" appearance="fill">
  <mat-select
    #hoursEndRef
    required
    (selectionChange)="onHoursEndChange($event)"
  >
    <mat-option *ngFor="let item of hoursList" [value]="item">{{
      item
    }}</mat-option>
  </mat-select>
</mat-form-field>
<span class="colon">:</span>
<mat-form-field class="time-select" appearance="fill">
  <mat-select required (selectionChange)="onMinutsEndChange($event)">
    <mat-option
      *ngFor="let item of minutsList"
      [value]="item"
      [disabled]="hoursEndRef.value == '18' && item !== '00'"
      >{{ item }}</mat-option
    >
  </mat-select>
</mat-form-field>
