import { Client } from './Client';

import { Product } from './Product';
import { Agent } from './Agent';
import { PaymentMethod } from './PaymentMethod';
import { Status } from './Status';
import { TranzillaTransaction } from './TranzillaTransaction';
import { DealHistory } from './DealHistory';
import { CardToken } from './CardToken';
import { FutureProduct } from './FutureProduct';
import { CancelationRequest } from './CancelationRequest';
import { WiredTransfer } from './WiredTransfer';
import { Coupon } from './Coupon';
import { ManualCreditCard } from './ManualCreditCard';
import { DealBundle } from './DealBundle';

export class Deal {
  dealId: number;
  //bundleAmount: number | null;
  isAditionPayment: boolean;
  dealBundleId: number | null;
  dealBundle: DealBundle;
  // clientId: number;
  //client: Client;
  //agentId: number;
  //agent: Agent;
  amount: number;
  numPayments: number;
  paymentMethodId: number | null;
  paymentMethod: PaymentMethod;
  statusId: number;
  status: Status;
  dateUpdated: string;
  dealHistories: DealHistory[];
  cardTokenId: number | null;
  cardToken: CardToken;
  isTokenDeal: boolean;
  //products: Product[];
  //cancelProducts: Product[];
  futureProducts: FutureProduct[];
  tranzillaTransaction: TranzillaTransaction;
  confirmationNumber: string;
  originalCancelationRequests: CancelationRequest[];
  cancelCancelationRequests: CancelationRequest[];
  wiredTransfer: WiredTransfer;
  manualCreditCard: ManualCreditCard;
  coupon: Coupon;
  fileUploads: any[];
  isCancelDeal: boolean;
  productsIds: number[];

  constructor() {
    //this.client = new Client('client');
  }
}
