<perfect-scrollbar class="sidenav-cat">
  <div class="top-nav">
    <label for="navSearch" class="search" *ngIf="canSearch">
      <input
        id="navSearch"
        type="search"
        (keyup.enter)="onSearchBtnClick()"
        [placeholder]="dir === 'ltr' ? 'Search SNP Number' : 'חפש מספר RS'"
        [(ngModel)]="searchTerm"
        [ngStyle]="{
          'padding-right': dir === 'ltr' ? '3rem' : '1rem',
          'padding-left': dir === 'ltr' ? '1rem' : '3rem'
        }"
      />
      <button
        (click)="onSearchBtnClick()"
        type="button"
        class="search__btn"
        [ngStyle]="{
          right: dir === 'ltr' ? '2px' : 'unset',
          left: dir === 'rtl' ? '2px' : 'unset'
        }"
      ></button>
    </label>
    <div class="mb-1"></div>
    <mat-form-field
      class="round-select lab-versions"
      [class.rtl]="dir === 'rtl'"
      [class.ltr]="dir === 'ltr'"
    >
      <mat-select
        [(ngModel)]="versionId"
        (selectionChange)="onChangeVersion($event)"
        class="lab-versions__select"
      >
        <mat-option [value]="item.fgVersionId" *ngFor="let item of fgVersions">
          {{ item.fgVersionName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="mb-1"></div>
  </div>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{ height: '20px' }"
      count="15"
    ></ngx-skeleton-loader>
  </div>
  <mat-accordion
    #accordion="matAccordion"
    *ngIf="coefficiernts && !isLoading"
    style="margin-bottom: 5rem"
  >
    <mat-expansion-panel
      *ngFor="let coeff of coefficiernts; let i = index"
      [expanded]="indexExpanded == i"
      #panelsCategory
    >
      <mat-expansion-panel-header
        [ngClass]="{
          selected:
            coeff?.fgCoefficientTypeId ===
            selectedCoeffType?.fgCoefficientTypeId
        }"
        (click)="onSelectCoeff(coeff, i)"
        class="panel-header"
      >
        <mat-panel-title>
          <app-more-panel
            *ngIf="canEdit"
            [canEdit]="canEdit"
            [isCategory]="true"
            [category]="coeff"
            [versionId]="versionId"
            [langId]="langId"
            [fromCategory]="true"
            [isFoodGenes]="true"
            (loadCategories)="onSave($event)"
            (openMainBoard)="openMainBoardEvent.emit($event)"
          ></app-more-panel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            {{ coeff.fgCoefficientTypeName }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <ng-container *ngFor="let item of coeff.coefficients">
          <mat-list-item
            (click)="onSelectSubCoeff(item)"
            [ngClass]="{
              selected: item?.fgCoefficientId === selectedCoeff?.fgCoefficientId
            }"
            [ngStyle]="{
              'background-color':
                item?.fgCoefficientId === selectedCoeff?.fgCoefficientId
                  ? '#176970'
                  : ''
            }"
            class="sub-title"
            matRipple
          >
            <div class="sub-title-item">
              <div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <app-more-panel
                    *ngIf="canEdit"
                    [isSubCategory]="true"
                    [category]="selectedCoeffType"
                    [subCategory]="item"
                    [versionId]="versionId"
                    [langId]="langId"
                    [fromSubCategory]="true"
                    [isFoodGenes]="true"
                    (loadCategories)="onSave($event)"
                    (openMainBoard)="openMainBoardEvent.emit($event)"
                  ></app-more-panel>
                  {{ item.fgCoefficientName }}
                </div>
              </div>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header
        (click)="onSelectParaneterTypePanel()"
        [ngClass]="{
          selected: selectedParameterTypesPanel
        }"
        class="panel-header"
      >
        <mat-panel-title>Parameters</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <ng-container *ngFor="let item of parameters">
          <mat-list-item
            (click)="onSelectParamType(item)"
            [ngClass]="{
              selected: item?.fgParamTypeId === selectedParamType?.fgParamTypeId
            }"
            [ngStyle]="{
              'background-color':
                item?.fgParamTypeId === selectedParamType?.fgParamTypeId
                  ? '#176970'
                  : ''
            }"
            class="sub-title"
            matRipple
          >
            <div class="sub-title-item">
              <div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  {{ item.fgParamName }}
                </div>
              </div>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="bottom-nav" *ngIf="canEdit">
    <button
      class="bottom-nav__simulator-btn"
      mat-button
      (click)="onSimulatorClick()"
    >
      Version simulator
    </button>
    <button
      class="bottom-nav__history-btn"
      mat-button
      (click)="onHistoryClick()"
    >
      History
    </button>
    <div class="bottom-nav__lang">
      <img
        *ngFor="let language of languages"
        src="../../assets/lang-icon-{{ language.languageId }}.svg"
        style="cursor: pointer; margin: 5px"
        [ngStyle]="{
          transform: language.languageId == 2 ? 'translateY(-3px)' : 'none'
        }"
        [ngStyle]="{
          filter: language.languageId != langId ? 'opacity(0.5)' : 'none'
        }"
        (click)="onChangeLang(language.languageId)"
      />
    </div>
  </div>
</perfect-scrollbar>
