<div style="display: flex">
  <mat-list
    style="padding-top: 0; padding-right: 10px; padding-bottom: 6rem"
    class="leadsTable trans-table"
    role="list"
  >
    <mat-list-item class="leadsTable__header" role="listitem">
      <!-- <span style="width: 2.5%">
        <mat-checkbox
          class="leadsTable__item__checkbox"
          (change)="checkAll($event)"
          [(ngModel)]="checkedAll"
        >
        </mat-checkbox>
      </span> -->
      <span>Date Updated</span>
      <span>Costumer Name</span>
      <span style="width: 250px">Email</span>
      <span>Tel</span>
      <span>Agent</span>
      <span style="width: 100px">Sum</span>
      <span style="width: 110px">Status</span>
      <span style="width: 110px">payment Method</span>
    </mat-list-item>

    <ng-container
      class="leadsTable__item"
      *ngFor="let transItem of transactionData; let inx = index"
    >
      <ng-container
        *ngIf="transItem.deals && !showBundleExpansionPanel(transItem)"
      >
        <mat-card>
          <app-transaction-thumb
            [deal]="transItem.deals[0]"
            [regularUser]="regularUser"
            [status]="getDealStatus(transItem.deals[0])"
            (rowClick)="onRowClick($event)"
            (rowChecked)="isChecked($event)"
            (onCancelDeal)="cancelDeal($event)"
            (onHandleApproval)="handleApproval($event)"
            [isInner]="true"
          ></app-transaction-thumb>
        </mat-card>
      </ng-container>
      <mat-accordion
        *ngIf="transItem.deals && showBundleExpansionPanel(transItem)"
      >
        <!-- *ngIf="ifIncompletePayment(transItem); else isCompletePayment"-->
        <mat-expansion-panel
          (opened)="handlePanelStateChange(transItem.dealBundleId, true)"
          (closed)="handlePanelStateChange(transItem.dealBundleId, false)"
          [expanded]="expandedBundles[transItem.dealBundleId]"
          style="padding: 1rem 0; margin-bottom: 10px; padding-right: 1rem"
        >
          <mat-expansion-panel-header class="leadsTable__item">
            <app-transaction-bundle-thumb
              [dealBundle]="transItem"
              [regularUser]="regularUser"
              [status]="getDealStatus(transItem)"
              (rowClick)="onRowClick($event)"
              (rowChecked)="isChecked($event)"
              (onCancelDeal)="cancelDeal($event)"
              (onHandleApproval)="handleApproval($event)"
            ></app-transaction-bundle-thumb>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let deal of transItem.deals"
            ><!--transItem.dealBundle-->
            <p>
              <app-transaction-thumb
                [deal]="deal"
                [regularUser]="regularUser"
                [status]="getDealStatus(deal)"
                (rowClick)="onRowClick($event)"
                (rowChecked)="isChecked($event)"
                (onCancelDeal)="cancelDeal($event)"
                (onHandleApproval)="handleApproval($event)"
                [isInner]="true"
              ></app-transaction-thumb>
            </p>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
      <ng-template #isCompletePayment>
        <mat-card class="leadsTable__item">
          <app-transaction-thumb
            [deal]="transItem"
            [regularUser]="regularUser"
            [status]="getDealStatus(transItem)"
            (rowClick)="onRowClick($event)"
            (rowChecked)="isChecked($event)"
            (onCancelDeal)="cancelDeal($event)"
            (onHandleApproval)="handleApproval($event)"
            [isAddition]="false"
          ></app-transaction-thumb>
        </mat-card>
      </ng-template>
    </ng-container>
  </mat-list>
  <div>
    <mat-card style="min-width: 200px">
      <div style="font-weight: 500; font-size: 22px; margin-bottom: 12px">
        Total
      </div>
      <div class="divider divider--client"></div>

      Transactions :
      <span style="font-weight: 600" class="text--client">{{
        getNumChecked()
      }}</span>
      <div class="divider divider--client"></div>
      <span style="font-weight: 600">Status:</span>
      <span [innerHtml]="getProductsStatuses()"></span>
      <!--
             Labratory Process <span style="font-weight: 600;"
                class="text--client">40</span> Labratory Process 40
                -->
      <hr />
      <b> Agent : </b>{{ getAllAgents() }}
      <hr />
      <span style="font-weight: 600">Date :</span> {{ getDateRange() }}
      <hr />
      <div
        style="
          background-color: #119c99;
          padding: 10px;
          text-align: center;
          color: #fff;
        "
      >
        Total Amount : {{ getDealsSum() }}
      </div>
    </mat-card>
  </div>
</div>
<app-loading-circle [mode]="'fullScreen'" *ngIf="showLoadingCircle == true">
</app-loading-circle>
