import { Component, OnInit } from '@angular/core';
import { DashBoardStatsService } from 'src/app/Services/dash-board-stats.service';
import { TestGoogleMapApiComponent } from '../test-google-map-api/test-google-map-api.component';
import { DashBoardStats } from 'src/app/data/DashBoardStats';
import { DealsSummery } from 'src/app/data/DealsSummery';
import { Deal } from 'src/app/data/Deal';
import { NameAmountPair } from 'src/app/data/NameAmountPair';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  dashBoardStats: DashBoardStats;
  newDealEvent: boolean = false;
  monthlyLeaders: NameAmountPair[];
  dailyLeadersSum: NameAmountPair[];
  weeklyLeadersSum: NameAmountPair[];
  dailyLeadersCount: NameAmountPair[];
  newLeads: NameAmountPair[];
  productTypesToday: NameAmountPair[];
  pieChartText = '';
  pieChartSum: number = 0;
  pastDeals: Deal[] = [];
  show: boolean;
  weeklyLeaders: NameAmountPair[];
  constructor(private dashBoardStatsService: DashBoardStatsService) {
   
    setInterval(() => {
      this.getDashboardStats();
    }, 2 * 60 * 1000);
   
  }
  ngOnInit(): void {
    this.getDashboardStats();
  }

  getDashboardStats() {
    this.dashBoardStatsService.get().subscribe((data) => {
      this.dashBoardStats = data;
      var i;

      //monthly
      var { monthlySum } = this.dashBoardStats;
      for (i = 0; i < monthlySum.length; i++) {
        monthlySum[i].value = monthlySum[i]['amount'];
        //delete montlyRank[i].amount;
      }
      this.monthlyLeaders = monthlySum.slice(0, 4).map((leader) => {
        return leader;
      });
      //weekly
      var { weeklySum } = this.dashBoardStats;
      for (i = 0; i < weeklySum.length; i++) {
        weeklySum[i].value = weeklySum[i]['amount'];
        //delete montlyRank[i].amount;
      }
      this.weeklyLeaders = weeklySum.slice(0, 4).map((leader) => {
        return leader;
      });
      //daily
      var { dailySum } = this.dashBoardStats;
      for (i = 0; i < dailySum.length; i++) {
        dailySum[i].value = dailySum[i]['amount'];
      }
      this.dailyLeadersSum = dailySum;
    //dailyProdCount
      var { dailyProdCount } = this.dashBoardStats;
      for (i = 0; i < dailyProdCount.length; i++) {
        dailyProdCount[i].value = dailyProdCount[i]['amount'];
      }
      this.dailyLeadersCount = dailyProdCount;

      //weeklyProdCount
      var { weeklyProdCount } = this.dashBoardStats;
      for (i = 0; i < weeklyProdCount.length; i++) {
        weeklyProdCount[i].value = weeklyProdCount[i]['amount'];
      }
      this.weeklyLeadersSum = weeklyProdCount;

      //new leads
      var { newLeads } = this.dashBoardStats;
      for (i = 0; i < newLeads.length; i++) {
        newLeads[i].value = newLeads[i]['amount'];
      }
      this.newLeads = newLeads;

      //new products
      var { productTypesToday } = this.dashBoardStats;
      for (i = 0; i < productTypesToday.length; i++) {
        productTypesToday[i].value = productTypesToday[i]['amount'];
      }
      this.productTypesToday = productTypesToday;
      this.pieChartText = this.productTypesToday
        .map((x) => {
          return x.name + ' ' + x.amount;
        })
        .join(' , ');
      this.pieChartSum = 0;
      this.productTypesToday.forEach((item) => {
        this.pieChartSum += item.amount;
      });

      //new deal

      var { newDeal } = this.dashBoardStats;
      if (newDeal && !this.pastDeals.find((x) => x.dealId == newDeal.dealId)) {
        if (this.pastDeals.length > 0) this.newDealEvent = true;
        setTimeout(() => {
          this.newDealEvent = false;
        }, 30 * 1000);
      }
      if (!this.pastDeals.find((x) => x.dealId == newDeal.dealId))
        this.pastDeals.push(newDeal);
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 10);
    });
  }
}
