import { LifestyleQuestion } from '../data/LifestyleQuestion';
import { QuestionDesignType } from '../Enums/StatusesEnum';

export function refAnsweredUtil(
  lifestyleQuestions: LifestyleQuestion[],
  refId: number
) {
  if (!refId || !lifestyleQuestions) return false;

  //console.log('refAnsweredUtil ckeck if refAnswered?', refId);

  for (let index = 0; index < lifestyleQuestions.length; index++) {
    const element = lifestyleQuestions[index];
    var isAnswered = RefAnsweredRecursively(element, refId);
    if (isAnswered) return true;
  }
  return false;
}
export function RefAnsweredRecursively(
  element: LifestyleQuestion,
  refId
): boolean {
  if (element.lifestyleQuestionId == refId) {
    if (
      element.lifeStyleAnswers &&
      element.lifeStyleAnswers.length > 0 &&
      element.lifeStyleAnswers[0].value === 'true'
    ) {
      // console.log('node-> refAnswered true', refId);
      return true;
    }
  }
  if (element.childQuestions) {
    for (let index = 0; index < element.childQuestions.length; index++) {
      if (RefAnsweredRecursively(element.childQuestions[index], refId))
        return true;
    }
  }
  return false;
}
export function searchQuestionById(
  lifestyleQuestions: LifestyleQuestion[],
  questionId: number
): LifestyleQuestion {
  try {
    if (lifestyleQuestions) {
      for (let index = 0; index < lifestyleQuestions.length; index++) {
        const element = lifestyleQuestions[index];
        if (element.lifestyleQuestionId == questionId) {
          return element;
        }
        if (element.childQuestions) {
          var elem = searchQuestionById(element.childQuestions, questionId);
          if (elem) return elem;
        }
      }
    }
  } catch (err) {
    //console.log('searchQuestionById error', err);
    return null;
  }
  return null;
}
export function isValidUtil(node: LifestyleQuestion) {
  if (
    (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
    node.designTypeId !== QuestionDesignType.header &&
    node.designTypeId !== QuestionDesignType.headerCenter &&
    node.designTypeId !== QuestionDesignType.successThumb
  ) {
    return false;
  }
  if (!node.childQuestions || node.childQuestions.length == 0) {
    //  console.log('isValid');

    return true;
  }

  if (node.childQuestions && node.childQuestions.length > 0) {
    let validChildCount = 0;
    for (let index = 0; index < node.childQuestions.length; index++) {
      const child = node.childQuestions[index];
      if (isValidUtil(child)) {
        validChildCount++;
      }
    }
    if (validChildCount == 0) {
      return false;
    }
  }
  return true;
}
