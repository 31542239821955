import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientRequestNote } from '../data/food-genes/ClientRequestNote';
import { ClientRequestSubject } from '../data/food-genes/ClientRequestSubject';
import { FoodReportRequest } from '../data/food-genes/FoodReportRequest';
import { ClientRequestStatus } from '../data/food-genes/ClientRequestStatus';
import { HeadFilter } from '../data/HeadFilter';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

@Injectable({
  providedIn: 'root',
})
export class FoodClientRequestService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getFoodRequests(headFilter: HeadFilter): Observable<FoodReportRequest[]> {
    return this.http.get<FoodReportRequest[]>(
      `${
        environment.baseUrl
      }ClientRequest/GetClientRequests/${this.httpFilterHelper.createAppReportPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }
  getFoodRequest(requestId: number, langId: number) {
    return this.http.get<FoodReportRequest>(
      `${environment.baseUrl}ClientRequest/GetClientRequest/${requestId}/${langId}`
    );
  }
  setFoodRequest(request: FoodReportRequest) {
    return this.http.put<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestSet`,
      request
    );
  }
  addFoodRequestNote(note: ClientRequestNote) {
    return this.http.post<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestNoteAdd`,
      note
    );
  }
  setFoodRequestNote(note: ClientRequestNote) {
    return this.http.put<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestNoteSet`,
      note
    );
  }
  setFoodRequestAttempt(requestId: number, numAttempts: number) {
    return this.http.get<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestAttemptSet/${requestId}/${numAttempts}`
    );
  }
  setFoodRequestStatus(requestId: number, statusId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestStatusSet/${requestId}/${statusId}`
    );
  }
  setFoodRequestAgent(requestId: number, agentId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}ClientRequest/ClientRequestAgentSet/${requestId}/${agentId}`
    );
  }
  getFoodRequestSubject(langId: number) {
    return this.http.get<ClientRequestSubject[]>(
      `${environment.baseUrl}ClientRequest/ClientRequestSubjectGet/${langId}`
    );
  }
  getFoodRequestStatuses(langId: number) {
    return this.http.get<ClientRequestStatus[]>(
      `${environment.baseUrl}ClientRequest/ClientRequestStatusesGet/${langId}`
    );
  }
}
