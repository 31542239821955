import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { ClientEditDialogWraperComponent } from 'src/app/Cards/client-edit-dialog-wraper/client-edit-dialog-wraper.component';
import { AgentMin } from 'src/app/data/AgentMin';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { Note } from 'src/app/data/Note';
import { NoteForCallMin } from 'src/app/data/NoteForCallMin';
import { ProductIsFor } from 'src/app/data/ProductIsFor';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserAdress } from 'src/app/data/UserAdress';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { NoteTypeEnum, UserRoleEmum } from 'src/app/Enums/StatusesEnum';
import { LeadConfirmChangeDialogComponent } from 'src/app/Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { AgentsService } from 'src/app/Services/agents.service';
import { ClientTypeService } from 'src/app/Services/client-type.service';
import { ClientsService } from 'src/app/Services/clients.service';
import { LeadSourcesService } from 'src/app/Services/lead-sources.service';
import { LeadStasusesService } from 'src/app/Services/lead-stasuses.service';
import { LeadsService } from 'src/app/Services/leads.service';
import { NotesPusherService } from 'src/app/Services/notes-pusher.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductIsForService } from 'src/app/Services/product-is-for.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { Client } from '../../../data/Client';

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.scss'],
})
export class EditLeadComponent implements OnInit {
  isLoading: boolean = false;
  leadFormReady: boolean = false;
  hidePlaceholder: boolean = false;
  hideCustumerPlaceholder: boolean = false;
  hideSourcePlaceholder: boolean = false;
  hideAgentPlaceholder: boolean = false;
  isFormDisabled: boolean = true;
  leadForm: FormGroup = null;
  agents: AgentMin[];
  //fullName = new FormControl('');
  isNewLead: boolean = false;
  lead: Client;
  isEditMode: boolean = false;
  isNoteEditMode: boolean = false;
  isTaskEditMode: boolean = false;
  activitiesFinished;
  activities;
  clientTypes;
  leadSources;
  queryLeadId;
  clientNotes: NoteForCallMin[] = null;
  clientTasks: NoteForCallMin[] = null;
  leadStatuses: LeadStatus[];
  productIsFors: ProductIsFor[];
  noteToEdit: Note;
  activityToEdit: Note;
  newClient: boolean = false;
  leadId: any;
  agentUser: RegularUser;
  errorMsg: string = '';
  constructor(
    private router: Router,
    private leadsService: LeadsService,
    private clientTypeService: ClientTypeService,
    private leadSourcesService: LeadSourcesService,
    private route: ActivatedRoute,
    private agentsService: AgentsService,
    private notesService: NotesPusherService,
    private productIsForService: ProductIsForService,
    private leadStasusesService: LeadStasusesService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private regularUsersService: RegularUsersService,
    private popupHandler: PopupHandlerService,
    private clientsService: ClientsService
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.agentUser = user;
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
  }
  hidePlaceholderCustumerFunc() {
    this.hideCustumerPlaceholder = true;
  }
  hidePlaceholderSourceFunc() {
    this.hideSourcePlaceholder = true;
  }
  hidePlaceholderAgentFunc() {
    this.hideAgentPlaceholder = true;
  }
  get leadformVal() {
    return this.leadForm.controls;
  }

  ngOnInit(): void {
    this.notesService.notes$.subscribe((data) => {
      this.clientNotes = data.filter((item) => {
        return (
          item.noteTypeId == NoteTypeEnum.Note ||
          item.noteTypeId == NoteTypeEnum.Call ||
          item.noteTypeId == NoteTypeEnum.Record
        );
      });
      this.clientNotes = this.clientNotes.sort((a, b) => {
        /*
        if (!a.dateCreatedObj && !b.dateCreatedObj) return 0;
       
        if (!b.dateCreatedObj) return 1;
        if (!a.dateCreatedObj) return -1;
        */
        return a.dateCreatedObj.getTime() < b.dateCreatedObj.getTime() ? 1 : -1;
      });
      this.clientTasks = data.filter((item) => {
        return item.noteTypeId == NoteTypeEnum.Task;
      });
      this.clientTasks = this.clientTasks.sort((a, b) =>
        a.dueDateObj.getTime() < b.dueDateObj.getTime() ? 1 : -1
      );
      if (this.clientNotes.length == 0) this.clientNotes = null;
      if (this.clientTasks.length == 0) this.clientTasks = null;
    });
    this.route.queryParams.subscribe((params) => {
      this.queryLeadId = params['clientId'];
      this.leadId = params['clientId'];
      this.isEditMode = params['isEditMode'] == 'true' ? true : false;
      if (this.leadId == '-1') {
        this.leadsService.getNewLead().subscribe((data) => {
          data.futureProducts = [];
          this.lead = data;
          this.isNewLead = true;
        });
      } else {
        this.getLeadData();
      }
      this.notesService.setMode('client');
      this.notesService.getAllNotes(this.leadId);
    });
    // var leadId = this.route.snapshot.paramMap.get('clientId');

    this.clientTypeService
      .getAll()
      .subscribe((data) => (this.clientTypes = data));

    this.leadSourcesService
      .getAll()
      .subscribe((data) => (this.leadSources = data));
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data.filter(
        (agent) =>
          agent.roleTypeId == UserRoleEmum.Sales ||
          agent.roleTypeId == UserRoleEmum.SalesTeamLeader ||
          agent.roleTypeId == UserRoleEmum.SalesManager ||
          agent.roleTypeId == UserRoleEmum.Developer ||
          agent.roleTypeId == UserRoleEmum.RegulationAgent
      );
    });
    /*
    this.statusesService.getAll().subscribe((data) => {
      this.leadStatuses = data.filter((status: Status) => {
        return status.category == 'Lead';
      });
    });
    */
    this.productIsForService
      .getAll()
      .subscribe((data) => (this.productIsFors = data));
    this.leadStasusesService.getAll().subscribe(
      (data) =>
        (this.leadStatuses = data.filter((status) => {
          return (
            status.leadStatusId != 15 &&
            status.leadStatusId != 16 &&
            status.parentId != 0
          );
        }))
    );
  }
  hasPermission() {
    return userHasPermisions(['Leads_SendInfo'], this.agentUser);
  }
  openSendInfoDialog() {
    this.popupHandler.openEmailTemplateDialog(this.lead, null);
  }
  getLeadData() {
    this.leadsService.getById(this.leadId).subscribe(
      (data) => {
        if ((data as Client).clientId) {
          var client: Client = data as Client;

          if (!client.futureProducts) client.futureProducts = [];
          this.lead = client;
          this.lead = plainToClass(Client, this.lead);
          //  this.client = data as Client;
          this.lead.regularUser = plainToClass(
            RegularUser,
            this.lead.regularUser
          );
          this.lead.regularUser.userAdresses =
            this.lead.regularUser.userAdresses.map((userAdress) =>
              plainToClass(UserAdress, userAdress)
            );
          this.lead.regularUser.email = this.lead.regularUser.email
            .toLowerCase()
            .trim();
        } else {
          this.router.navigate(['leads']);
        }
      },
      (error) => {
        //console.log('error', error);

        this.router.navigate(['leads']);
      }
    );
  }
  convertToClient() {
    const dialogRef = this.dialog.open(ClientEditDialogWraperComponent, {
      width: '600px',
      height: '850px',
      data: {
        client: this.lead,
        product: null,
        clientTypes: this.clientTypes,
        leadSources: this.leadSources,
        productIsFors: this.productIsFors,
        agents: this.agents,
        classAttr: 'convertToClient',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.onConvertDialogClosed(result.data);
      //var a = result;
    });
  }
  /*
  convertToClient() {
    this.clientsService
      .checkIfExistWithPhone(this.lead.regularUser.phone)
      .subscribe((client) => {
        if (client) {
          this.router.navigate(['/clients/edit/'], {
            queryParams: { clientId: client.clientId },
          });
        } else {
          this.openConvertToClientDialog();
        }
      });

   
   
    
  }*/
  onConvertDialogClosed(lead: Client) {
    if (!lead) return;
    lead.clientRoleId = 2;
    lead.leadStatusId = 15; // Not Active
    lead.regularUser.createdDate = this.datePipe.transform(
      new Date(Date.now()),
      'yyyy-MM-dd hh:mm'
    );
    this.clientsService.put(lead, 'true').subscribe(
      (res) => {
        //  var isClient = typeof resClient.constructor.name === 'Client';
        if ((res as Client).clientId) {
          var resClient = res as Client;
          this.router.navigate(['/clients/edit'], {
            queryParams: { clientId: resClient.clientId },
          });
        } else {
          var resError = res as ErrorMessage;
          this.popupHandler.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.errorFromServer,
            '',
            '',
            resError.message,
            4000
          );
        }
      },
      (error) => {
        this.popupHandler.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.genericError
        );
      }
    );
  }
  moveToEditMode() {
    this.isEditMode = true;
  }
  goBackToLeads() {
    this.router.navigate(['/leads']);
  }
  openDisapearingAlertDialog(type = '', from = '', to = '') {
    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        type: 'fill_all_fields',
      },
    });
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      var a = result;
    });
  }
  onChildFormValidated(client: Client) {
    this.isLoading = true;
    var _client = { ...client };
    this.errorMsg = '';
    _client.clientRole = null;
    _client.clientRoleId = 1; //Lead
    if (this.queryLeadId == -1) {
      this.leadsService.post(_client).subscribe(
        (res) => {
          this.isLoading = false;
          if ((res as Client).clientId) {
            this.lead = res as Client;
            this.router.navigate(['/leads/edit'], {
              queryParams: { clientId: this.lead.clientId },
            });

            this.isEditMode = false;
          } else {
            this.errorMsg = (res as ErrorMessage).message;
          }
        },
        (error) => {
          this.popupHandler.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.genericError
          );
        }
      );
    } else {
      this.leadsService.put(client).subscribe((res) => {
        this.isLoading = false;
        if ((res as Client).clientId) {
          this.lead = res as Client;
          this.router.navigate(['/leads/edit'], {
            queryParams: { clientId: this.lead.clientId },
          });

          this.isEditMode = false;
        } else {
          this.errorMsg = (res as ErrorMessage).message;
        }
      });
    }
  }

  createForm(data: any): FormGroup {
    const group = new FormGroup({});
    for (let key in data) {
      if (Array.isArray(data[key])) {
        //if is an array
        //first create the formArray
        group.addControl(key, new FormArray([this.createForm(data[key][0])]));
        for (
          let i = 1;
          i < data[key].length;
          i++ //after add the rest of the elements
        )
          (group.get(key) as FormArray).push(this.createForm(data[key][i]));
      } else {
        if (typeof data[key] == 'object')
          //if is an object we add a formGroup
          group.addControl(key, this.createForm(data[key]));
        //add a simple control
        else group.addControl(key, new FormControl(data[key]));
      }
    }
    return group;
  }

  get leadSourceId() {
    return this.leadForm.get('leadSourceId');
  }
  get clientTypeId() {
    return this.leadForm.get('clientTypeId');
  }
  get fullName() {
    return this.leadForm.get('fullName');
  }
  get email() {
    return this.leadForm.get('email');
  }
  get phone() {
    return this.leadForm.get('phone');
  }
  get productFor() {
    return this.leadForm.get('productFor');
  }
  get ProductType() {
    return this.leadForm.get('ProductType');
  }
  createNewTask(note: Note) {
    if (!note) note = new Note();
    if (!note.assignedAgentId) note.assignedAgentId = this.lead.agent.agentId;
    this.activityToEdit = note;
    this.isTaskEditMode = true;
  }
  createNewNote(note: Note) {
    this.noteToEdit = note;
    this.isNoteEditMode = true;
  }
  saveNote(note: Note | NoteForCallMin) {
    /* note.clientId = this.lead.clientId;
    note.assigningAgentId = this.agentUser.agent.agentId;
    if (note.noteId > 0) {
      this.notesService.put(note);
    } else {
      this.notesService.post(note);
    } */
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.clientId = this.lead.clientId;
      this.notesService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.clientId = this.lead.clientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesService.post(fullNote);
    }
    this.isNoteEditMode = false;
  }
  saveTask(note: Note | NoteForCallMin) {
    if (note.noteId > 0) {
      var noteMin = note as NoteForCallMin;
      noteMin.clientId = this.lead.clientId;
      noteMin.assigningAgent.agentId = this.agentUser.agent.agentId;
      this.notesService.putMin(noteMin);
    } else {
      var fullNote = note as Note;
      fullNote.clientId = this.lead.clientId;
      fullNote.assigningAgentId = this.agentUser.agent.agentId;
      this.notesService.post(fullNote);
    }

    this.isTaskEditMode = false;
  }
  closeNewTaskWindow() {
    this.isTaskEditMode = false;
  }
  closeNewNoteWindow() {
    this.isNoteEditMode = false;
  }
  gotoLogs() {
    this.router.navigate(['/leads/edit/log'], {
      queryParams: { clientId: this.lead.clientId },
    });
  }
  onCancelForm(e) {
    this.errorMsg = '';
    this.isEditMode = false;
    this.getLeadData();
  }
  onReloadNotes() {
    this.notesService.getAllNotes(this.leadId);
  }
}
