<perfect-scrollbar
  style="max-height: 100vh; height: 83.5vh"
  [perfectScrollbar]="config"
>
  <mat-card>
    <span class="card-title">Notes </span>
    <br />
    <div
      class="divider"
      [class.divider--client]="classAttr == 'clientAttr'"
      [class.divider--lead]="classAttr == 'leadAttr'"
      [class.divider--test]="classAttr == 'testAttr'"
    ></div>
    <div *ngFor="let note of notes">
      <div class="note-item" (click)="onEditNote(note)">
        <app-client-note-thumb
          [note]="note"
          [isAppReport]="isAppReport"
          [classAttr]="classAttr"
          (reloadNotes)="onReloadNotes($event)"
        >
        </app-client-note-thumb>
      </div>
      <mat-divider></mat-divider>
    </div>

    <br />
    <button
      style="position: sticky; bottom: 2px"
      *ngIf="disableNew == false"
      class="btn-wide"
      [class.btn-wide--lead]="classAttr == 'leadAttr'"
      [class.btn-wide--client]="classAttr == 'clientAttr'"
      [class.btn-wide--test]="classAttr == 'testAttr'"
      (click)="onOpenNewNote()"
    >
      Add Note
    </button>
  </mat-card>
</perfect-scrollbar>
