<form #f="ngForm" (ngSubmit)="onSubmit()">
  <label for="clientId">clientId</label>
  <input name="clientId" [(ngModel)]="externalDeal.clientId" required />
  <br />
  <label for="creditCardNumber">credit Card Number</label>
  <input
    name="creditCardNumber"
    [(ngModel)]="externalDeal.creditCardNumber"
    required
  />
  <br />
  <label for="cvv">cvv</label>
  <input name="cvv" [(ngModel)]="externalDeal.cvv" required />
  <br />
  <label for="expireDate">expireDate</label>
  <input name="expireDate" [(ngModel)]="externalDeal.expireDate" required />
  <br />
  <label for="idNum">Id</label>
  <input name="idNum" [(ngModel)]="externalDeal.idNum" required />
  <br />
  <label for="city">city</label>
  <input name="street" [(ngModel)]="externalDeal.city" required />

  <br />
  <label for="street">street</label>
  <input name="street" [(ngModel)]="externalDeal.street" required />
  <br />
  <label for="streetNum">streetNum</label>
  <input name="streetNum" [(ngModel)]="externalDeal.streetNum" required />
  <br />
  <label for="entrence">entrence</label>
  <input name="entrence" [(ngModel)]="externalDeal.entrance" />
  <br />
  <label for="appartment">appartment</label>
  <input name="appartment" [(ngModel)]="externalDeal.apartment" required />
  <br />
  <label for="npay">npay</label>
  <input name="npay" [(ngModel)]="externalDeal.npay" required />
  <br />
  <label for="shipNotes">shipNotes</label>
  <input name="shipNotes" [(ngModel)]="externalDeal.shipNotes" required />
  <br />
  <button type="submit">submit</button>
</form>
<br>
<br>
<br>

<div>
  <button (click)="sendJsonCreateDeal()" >sendJson Create Deal</button>

  <br>
  <button (click)="sendJsonTransactionResponse()" >sendJson Transaction Response</button>
</div>
<!--
<app-loading-circle>

</app-loading-circle>
-->
