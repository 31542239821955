import { Injectable } from '@angular/core';
import { PaymentMethod } from '../data/PaymentMethod';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  paymentMethods: PaymentMethod[];
  constructor(private http: HttpClient) { }
  getAll() {

    if (this.paymentMethods)
    {
      return of(this.paymentMethods);  // return from cache
  } else
  {
      return this.http.get<PaymentMethod[]>(`${environment.baseUrl}PaymentMethods/`).pipe(map(res => (this.paymentMethods=res)));
  }
   // return this.http.get<PaymentMethod[]>(`${environment.baseUrl}PaymentMethods/`);
  }
}
