<form
  *ngIf="leadForm && classAttr"
  [formGroup]="leadForm"
  (ngSubmit)="saveLead()"
  class="example-form"
>
  <mat-list>
    <mat-list-item>
      <div class="d-flex-between">
        <span *ngIf="classAttr == 'clientAttr'" class="card-title"
          >Client Profile
        </span>
        <span *ngIf="classAttr == 'patientAttr'" class="card-title"
          >Patient details
        </span>
        <span *ngIf="classAttr == 'leadAttr'" class="card-title"
          >Lead Profile
          <!-- -
          <span *ngIf="isDuplicate" style="font-weight: 400; color: #f44336;"
            >Exists In Clients</span
          > -->
        </span>

        <div [ngStyle]="classAttr == 'leadAttr' && { 'margin-left': '3rem' }">
          <button
            *ngIf="
              classAttr != 'patientDialogAttr' && classAttr != 'convertToClient'
            "
            type="submit"
            mat-button
            class="rect-action-btn"
            [class.form-disabled]="isFormDisabled == true"
            [class.rect-action-btn--client]="classAttr == 'clientAttr'"
            [class.rect-action-btn--lead]="classAttr == 'leadAttr'"
            [class.rect-action-btn--test]="classAttr == 'patientAttr'"
          >
            Save
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            type="button"
            (click)="cancel()"
            mat-button
            [class.btn-cancel-form--client]="classAttr == 'clientAttr'"
            [class.btn-cancel-form--lead]="classAttr == 'leadAttr'"
            [class.btn-cancel-form--test]="classAttr == 'patientAttr'"
            *ngIf="(client && client.clientId) || product"
          >
            cancel
          </button>
          <button
            *ngIf="
              displayMailBtn && classAttr == 'leadAttr' && client.clientId > 0
            "
            style="margin-left: 50px"
            type="button"
            (click)="sendMail()"
            mat-button
            [class.btn-cancel-form--client]="classAttr == 'clientAttr'"
            [class.btn-cancel-form--lead]="classAttr == 'leadAttr'"
            [class.btn-cancel-form--test]="classAttr == 'patientAttr'"
          >
            send Mail
          </button>
        </div>
        <!--(click)="saveLead()"-->
        <mat-form-field
          style="margin-left: auto; margin-right: 20px"
          *ngIf="classAttr == 'leadAttr'"
          class="example-full-width round-select"
        >
          <mat-select
            class="lead-status-select"
            (selectionChange)="onStatusChange($event)"
            formControlName="leadStatusId"
            placeholder="Choose Status"
            disableOptionCentering
            [ngStyle]="{
              'background-color': client.leadStatus && client.leadStatus.color
            }"
          >
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.leadStatusId"
              class="lead-status lead-status{{ status.leadStatusId }}"
              >{{ status.name }}</mat-option
            >
            <!--<option
          *ngFor="let clientType of clientTypes"
          [ngValue]="clientType.clientTypeId"
        >
          {{ clientType.name }}
        </option>-->
          </mat-select>
        </mat-form-field>
        <span
          *ngIf="
            client &&
            client.agent &&
            classAttr != 'patientAttr' &&
            !showAgentSelect(client.agentId)
          "
          ><strong>Agent: </strong
          >{{ client.agent.regularUser.firstName }}</span
        >

        <span *ngIf="isSupportAgent && client && client.agent"
          ><strong>Agent: </strong
          >{{ client.agent.regularUser.firstName }}</span
        >

        <div>
          <div
            *ngIf="
              client &&
              client.agentId &&
              getAgentNameById(client.agentId) &&
              agentNotActive
            "
            style="text-align: right"
          >
            <small> הנציג אינו פעיל </small>
          </div>
          <app-autocomplete-agent
            *ngIf="
              client &&
              classAttr != 'patientAttr' &&
              showAgentSelect(client.agentId) &&
              !isSupportAgent
            "
            (selectedValue)="saveAgentForLead($event)"
            [data]="agents"
            [initialValue]="getAgentNameById(client.agentId)"
            [widthExp]="180"
            [label]="'Agent'"
          ></app-autocomplete-agent>
        </div>
        <!-- <mat-form-field
          *ngIf="
            client &&
            classAttr != 'patientAttr' &&
            showAgentSelect(client.agentId) &&
            !isSupportAgent
          "
          class="example-full-width round-select"
        >
          <mat-select
            formControlName="agentId"
            placeholder="Choose Agent Name"
            disableOptionCentering
          >
            <mat-option *ngFor="let agent of agents" [value]="agent.agentId">{{
              agent.name
            }}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </mat-list-item>
    <div
      class="divider"
      [class.divider--client]="classAttr == 'clientAttr'"
      [class.divider--test]="classAttr == 'patientAttr'"
      [class.divider--lead]="classAttr == 'leadAttr'"
    ></div>
    <div class="row-container">
      <mat-list-item class="row-container__item">
        <span class="boldSpan"> First Name: </span>
        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="firstName" />
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formFirstName.errors &&
            (formFirstName.errors.required || formFirstName.errors.minLength)
          "
        >
          Please enter a valid Name with at least 1 characters (excluding
          spaces)
        </span>
      </mat-list-item>
      <mat-list-item class="row-container__item">
        <span class="boldSpan"> Last Name: </span>
        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="lastName" />
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formLastName.errors &&
            (formLastName.errors.required || formLastName.errors.minLength)
          "
        >
          Please enter a valid Name with at least 1 characters (excluding
          spaces)
        </span>
      </mat-list-item>
      <mat-list-item
        *ngIf="classAttr != 'leadAttr'"
        class="row-container__item"
      >
        <span
          *ngIf="classAttr == 'clientAttr' || classAttr == 'convertToClient'"
          class="boldSpan"
        >
          Client ID:
        </span>
        <span *ngIf="classAttr == 'leadAttr'" class="boldSpan"> Lead ID: </span>
        <span
          *ngIf="classAttr == 'patientDialogAttr' || classAttr == 'patientAttr'"
          class="boldSpan"
        >
          Patient ID:
        </span>
        <mat-form-field class="example-full-width">
          <input
            mask="000000000"
            class="edit-field"
            matInput
            formControlName="socialSecurityNum"
          />
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formSocialSecurityNum.errors &&
            formSocialSecurityNum.errors.required
          "
        >
          Please enter ID
        </span>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formSocialSecurityNum.errors &&
            formSocialSecurityNum.errors.minlength
          "
        >
          ID must be 9 characters
        </span>
      </mat-list-item>

      <mat-list-item class="row-container__item">
        <span class="boldSpan"> Email: </span>
        <mat-form-field class="example-full-width">
          <input
            class="edit-field"
            type="email"
            matInput
            formControlName="email"
          />
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="leadFormReady && formEmail.errors && formEmail.errors.required"
        >
          Please enter email
        </span>
        <span
          class="form-error"
          *ngIf="leadFormReady && formEmail.errors && formEmail.errors.email"
        >
          email not valid
        </span>
      </mat-list-item>
      <mat-list-item class="row-container__item">
        <span class="boldSpan"> Tel: </span>
        <img src="../../../assets/phone.svg" alt="phone" class="phone-img" />

        <!-- <mat-form-field appearance="fill" class="country-select">
          <mat-icon matPrefix
            ><img
              src="https://flagcdn.com/16x12/{{
                getSelectedCountryFlag() | lowercase
              }}.png"
              alt=""
          /></mat-icon>
          <mat-select #selectedCountryFlag formControlName="countryId">
            <mat-option *ngFor="let item of countries" [value]="item.countryId"
              ><img
                src="https://flagcdn.com/16x12/{{
                  item.nameCode | lowercase
                }}.png"
                [alt]="item.nameCode"
                [matTooltip]="item.name"
              />
              {{ item.countryCode }}</mat-option
            >
          </mat-select>
        </mat-form-field> -->
        <app-autocomplete-countries
          (selectedValue)="saveCountries($event)"
          [data]="countries"
          [initialValue]="countryId.value"
          style="margin-right: 1rem; transform: translateY(-3px)"
        ></app-autocomplete-countries>
        <!-- <mat-form-field class="example-full-width">
          <input
            class="edit-field"
            matInput
            formControlName="countryId"
            list="countries"
            autocomplete="off"
          />
        </mat-form-field>

        <datalist id="countries">
          <select>
            <option
              *ngFor="let item of countries"
              data-value="{{ item.countryId }}"
              [value]="item.countryCode"
            >
              <img
                src="https://flagcdn.com/16x12/{{
                  item.nameCode | lowercase
                }}.png"
                [alt]="item.nameCode"
                [matTooltip]="item.nameCode"
              />
            </option>
          </select>
        </datalist> -->
        <mat-form-field class="example-full-width">
          <input class="edit-field" matInput formControlName="phone" />
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="leadFormReady && formPhone.errors && formPhone.errors.required"
        >
          Please enter phone
        </span>
        <span
          class="form-error"
          *ngIf="leadFormReady && formPhone.errors && formPhone.errors.pattern"
        >
          phone not valid
        </span>
      </mat-list-item>
      <mat-list-item
        *ngIf="classAttr != 'leadAttr'"
        class="row-container__item row-container__item--auto"
      >
        <div style="height: 100%; align-self: start; padding-top: 12px">
          <span class="boldSpan" style="line-height: 40px"> Address: </span>
        </div>
        <div>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 1rem;
              margin-top: 1.5rem;
            "
            dir="rtl"
          >
            <app-autocomplete-address
              [initialValue]="formCity.value"
              [label]="'City'"
              [data]="mapCities(citiesResults$ | async)"
              (selectedValue)="onCitySelected($event, 'Main')"
              [widthExp]="'200'"
              (searchTerm)="searchTermCity$.next($event)"
            ></app-autocomplete-address>
            <app-autocomplete-address
              [initialValue]="formStreet.value"
              [label]="'Street'"
              [data]="mapStreets(streetsResults$ | async)"
              (selectedValue)="onStreetSelected($event, 'Main')"
              [widthExp]="'200'"
              (searchTerm)="searchTermStreet$.next($event)"
            ></app-autocomplete-address>
          </div>

          <div class="d-flex-between">
            <mat-form-field style="width: 100px">
              <input
                placeholder="Street Number"
                class="edit-field"
                mask="0000"
                matInput
                formControlName="streetNum"
              />
            </mat-form-field>
            <mat-form-field style="width: 100px">
              <input
                placeholder="Entrence"
                class="edit-field"
                type="number"
                matInput
                formControlName="entrance"
              />
            </mat-form-field>
            <mat-form-field style="width: 100px">
              <input
                placeholder="Apartment"
                class="edit-field"
                mask="0000"
                matInput
                formControlName="apartment"
              />
            </mat-form-field>
          </div>
          <mat-form-field style="width: 100%" appearance="fill">
            <textarea
              style="direction: rtl; text-align: right"
              placeholder="Shipping Notes"
              matInput
              formControlName="shipNotes"
            ></textarea>
          </mat-form-field>
        </div>
        <!--  <mat-error
            *ngIf="leadFormReady && address.errors && address.errors.required"
          >
            Please enter phone
          </mat-error> -->
      </mat-list-item>
      <mat-list-item
        *ngIf="
          classAttr != 'leadAttr' &&
          classAttr != 'clientAttr' &&
          classAttr != 'convertToClient'
        "
      >
        <div
          style="
            width: 100%;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
          "
        >
          <span style="font-weight: 500"> Shipping Address: </span>
          <mat-checkbox
            [(ngModel)]="shippingCheckbox"
            [ngModelOptions]="{ standalone: true }"
            labelPosition="before"
            class="shipping-checkbox {{ classAttr }}"
            (change)="changeShippingCheckbox($event)"
            >Shipping address same as patient address</mat-checkbox
          >
          <!-- <button
            mat-button
            type="button"
            (click)="copyShippingAdressFromMain()"
          >
            copy from Main Adress
          </button> -->
        </div>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          classAttr != 'leadAttr' &&
          classAttr != 'clientAttr' &&
          classAttr != 'convertToClient' &&
          !shippingCheckbox
        "
        class="row-container__item row-container__item--auto"
      >
        <div
          style="
            height: 100%;
            align-self: start;
            padding-top: 12px;
            width: 80px;
          "
        >
          <span class="boldSpan" style="line-height: 40px"></span>
        </div>
        <div>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 1rem;
              margin-top: 1.5rem;
            "
            dir="rtl"
          >
            <app-autocomplete-address
              [initialValue]="formShippingCity.value"
              [label]="'City'"
              [data]="mapCities(shippingCitiesResults$ | async)"
              (selectedValue)="onCitySelected($event, 'Shipping')"
              [widthExp]="'200'"
              (searchTerm)="searchTermShippingCity$.next($event)"
            ></app-autocomplete-address>
            <app-autocomplete-address
              [initialValue]="formShippingStreet.value"
              [label]="'Street'"
              [data]="mapStreets(shippingStreetsResults$ | async)"
              (selectedValue)="onStreetSelected($event, 'Shipping')"
              [widthExp]="'200'"
              (searchTerm)="searchTermShippingStreet$.next($event)"
            ></app-autocomplete-address>
          </div>

          <div class="d-flex-between">
            <mat-form-field style="width: 100px">
              <input
                placeholder="Street Number"
                class="edit-field"
                mask="0000"
                matInput
                formControlName="shippingStreetNum"
              />
            </mat-form-field>
            <mat-form-field style="width: 100px">
              <input
                placeholder="Entrence"
                class="edit-field"
                type="number"
                matInput
                formControlName="shippingEntrance"
              />
            </mat-form-field>
            <mat-form-field style="width: 100px">
              <input
                placeholder="Apartment"
                class="edit-field"
                mask="0000"
                matInput
                formControlName="shippingApartment"
              />
            </mat-form-field>
          </div>
          <mat-form-field style="width: 100%" appearance="fill">
            <textarea
              style="direction: rtl; text-align: right"
              placeholder="Shipping Notes"
              matInput
              formControlName="shippingShipNotes"
            ></textarea>
          </mat-form-field>
        </div>
        <!--  <mat-error
          *ngIf="leadFormReady && address.errors && address.errors.required"
        >
          Please enter phone
        </mat-error> -->
      </mat-list-item>
      <mat-list-item
        *ngIf="classAttr != 'patientAttr' && classAttr != 'patientDialogAttr'"
        class="row-container__item"
      >
        <span class="boldSpan"> Lead Source: </span>

        <mat-form-field class="example-full-width round-select">
          <mat-select
            placeholder="Choose Lead Source"
            matNativeControl
            formControlName="leadSourceId"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let leadSource of leadSources"
              [value]="leadSource.leadSourceId"
            >
              {{ leadSource.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formLeadSourceId.errors &&
            formLeadSourceId.errors.required
          "
        >
          Please choose the lead source
        </span>
      </mat-list-item>
      <mat-list-item
        *ngIf="classAttr != 'patientAttr' && classAttr != 'patientDialogAttr'"
        class="row-container__item"
      >
        <span class="boldSpan"> The Product is For: </span>
        <mat-form-field class="example-full-width round-select">
          <mat-select
            placeholder="Choose For Who"
            matNativeControl
            formControlName="productIsForId"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let productIsFor of productIsFors"
              [value]="productIsFor.productIsForId"
            >
              {{ productIsFor.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formProductIsForId.errors &&
            formProductIsForId.errors.required
          "
        >
          Please choose for whose the product
        </span>
      </mat-list-item>
      <mat-list-item
        *ngIf="classAttr != 'patientAttr' && classAttr != 'patientDialogAttr'"
        class="row-container__item"
      >
        <span class="boldSpan"> Custumer Type: </span>
        <mat-form-field class="example-full-width round-select">
          <mat-select
            matNativeControl
            formControlName="clientTypeId"
            placeholder="Choose Custumer Type"
            disableOptionCentering
          >
            <!-- <option [ngValue]="null" disabled>Choose Custumer Type</option>-->
            <mat-option
              *ngFor="let clientType of clientTypes"
              [value]="clientType.clientTypeId"
            >
              {{ clientType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span
          class="form-error"
          *ngIf="
            leadFormReady &&
            formClientTypeId.errors &&
            formClientTypeId.errors.required
          "
        >
          Please choose Custumer Type
        </span>
      </mat-list-item>
    </div>
    <mat-list-item
      *ngIf="classAttr != 'patientAttr' && classAttr != 'patientDialogAttr'"
      class="row-container__item"
    >
      <span class="boldSpan"> Product Type: </span>
      <app-client-edit-purchase-thumb
        *ngFor="let product of client.futureProducts; let inx = index"
        [product]="product"
        [productTypes]="productTypes"
        [client]="client"
        [index]="inx"
        [showMinimal]="true"
        (updateProductData)="updateProductData($event)"
      >
        <!-- [productTypes]="remainingProductTypes[inx]"-->
      </app-client-edit-purchase-thumb>
      <br *ngIf="client.futureProducts && client.futureProducts.length > 1" />
    </mat-list-item>
    <mat-list-item>
      <button
        *ngIf="classAttr == 'leadAttr'"
        type="button"
        class="btn-add-product lead"
        (click)="addNewProduct()"
        style="margin-left: auto"
      >
        Add Product
      </button>
    </mat-list-item>
    <mat-list-item
      *ngIf="classAttr == 'patientAttr' || classAttr == 'patientDialogAttr'"
      class="row-container__item"
    >
      <span class="boldSpan"> Height: </span>
      <mat-form-field class="example-full-width">
        <input class="edit-field" matInput formControlName="height" />
      </mat-form-field>
      <span class="form-error" *ngIf="height.errors && height.errors.pattern">
        Please fill Height in centimeters from: 100 - 250
      </span>
    </mat-list-item>
    <mat-list-item
      *ngIf="classAttr == 'patientAttr' || classAttr == 'patientDialogAttr'"
      class="row-container__item"
    >
      <span class="boldSpan"> Weight: </span>
      <mat-form-field class="example-full-width">
        <input
          type="number"
          class="edit-field"
          matInput
          formControlName="weight"
        />
      </mat-form-field>
    </mat-list-item>
    <!--
    <mat-list-item *ngIf="classAttr == 'patientAttr' || classAttr == 'patientDialogAttr'" class="row-container__item">
      <span class="boldSpan"> Age: </span>
      <mat-form-field class="example-full-width">
        <input class="edit-field" matInput formControlName="age" />
        <mat-error *ngIf="leadFormReady && fullName.errors && fullName.errors.required">
          Please enter Age
        </mat-error>
      </mat-form-field>
    </mat-list-item>
    -->
    <mat-list-item
      *ngIf="classAttr == 'patientAttr' || classAttr == 'patientDialogAttr'"
      class="row-container__item"
    >
      <span class="boldSpan"> Gender: </span>
      <mat-form-field class="example-full-width round-select">
        <mat-select
          formControlName="gender"
          placeholder="Choose Gender"
          disableOptionCentering
        >
          <mat-option value="male">Male</mat-option>
          <mat-option value="female">Female</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            leadFormReady && formGender.errors && formGender.errors.required
          "
        >
          Please enter Gender
        </mat-error>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item
      *ngIf="classAttr == 'patientAttr' || classAttr == 'patientDialogAttr'"
      class="row-container__item"
    >
      <span class="boldSpan"> Birth Date: </span>

      <mat-form-field appearance="fill">
        <input
          matInput
          formControlName="birthDate"
          [min]="minBirthDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error
          *ngIf="
            leadFormReady &&
            formBirthDate.errors &&
            formBirthDate.errors.required
          "
        >
          Please enter Birth Date
        </mat-error>
      </mat-form-field>
    </mat-list-item>

    <mat-list-item
      *ngIf="classAttr == 'convertToClient'"
      class="row-container__item--action"
    >
      <button type="submit" class="btn-wide btn-wide--lead">
        convert To Client
      </button>
      <button type="button" class="btn-wide" (click)="cancel()">cancel</button>
    </mat-list-item>
    <mat-list-item
      style="color: red"
      *ngIf="classAttr != 'leadAttr' && isSubmitedOnce && submitError != null"
    >
      {{ submitError.message }}
    </mat-list-item>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
      "
    >
      <button
        style="margin: auto"
        *ngIf="classAttr == 'patientDialogAttr'"
        type="submit"
        class="rect-action-btn rect-action-btn--client save"
        style="padding: 1rem 2rem"
        [class.form-disabled]="isFormDisabled == true"
        [disabled]="isLoading"
      >
        Save
      </button>
    </div>
  </mat-list>
</form>
