import { Component, OnInit } from '@angular/core';
import { Laboratory } from 'src/app/data/algorithm/Laboratory';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Language } from 'src/app/data/Language';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { RegularUser } from 'src/app/data/RegularUser';
import { userHasPermisions } from 'src/app/Utils/user-helper';
import { NameAndId } from 'src/app/data/NameAndId';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { ErrorMessage } from 'src/app/data/ErrorMessage';

@Component({
  selector: 'app-labs-version',
  templateUrl: './labs-version.component.html',
  styleUrls: ['./labs-version.component.scss'],
})
export class LabsVersionComponent implements OnInit {
  langId: number = 1;
  selectedLabId: number = 0;
  labs: Laboratory[];
  labVersions: LabVersion[];
  displayedColumns: string[] = ['lab', 'version', 'date', 'action'];
  dataSource: MatTableDataSource<LabVersion> = new MatTableDataSource([]);
  regularUser: RegularUser;
  constructor(
    private algorithmService: AlgorithmService,
    public location: Location,
    private popupHandlerService: PopupHandlerService,
    private _matSnackBar: MatSnackBar,
    private regularUserService: RegularUsersService
  ) {}

  ngOnInit(): void {
    this.getLabs();
    this.getCurrentUser();
  }

  getCurrentUser() {
    return this.regularUserService.currentUser.subscribe((data) => {
      this.regularUser = data;
    });
  }
  userCanLock() {
    return userHasPermisions(['Laboratory_Lock'], this.regularUser);
  }
  userCanUnlock() {
    return userHasPermisions(['Laboratory_Unlock'], this.regularUser);
  }
  userCanEdit() {
    return userHasPermisions(
      ['Laboratory_Create', 'Laboratory_Update'],
      this.regularUser
    );
  }
  userCanRecalc() {
    return userHasPermisions(
      ['Algorithm_Create', 'Algorithm_Update', 'Algorithm_Delete'],
      this.regularUser
    );
  }

  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
    this.getLabs();
  }

  getLabs() {
    this.algorithmService.getLabs(this.langId).subscribe((data) => {
      this.labs = data;
      const all = new Laboratory();
      all.labId = 0;
      all.name = 'All';
      this.labs.unshift(all);
      this.getLabWithVersions();
    });
  }

  getLabWithVersions() {
    this.algorithmService
      .getLabWithVersions(this.langId, this.selectedLabId)
      .subscribe((data) => {
        this.labVersions = [];
        data.map((x) => {
          x.versions.map((y) => {
            y.labName = x.name;
            this.labVersions.push(y);
          });
        });
        this.setDataSource(this.labVersions);
      });
  }
  setDataSource(data: LabVersion[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  onLabChange() {
    this.getLabs();
  }
  createVersion() {}
  recalcVersion(versionId: number) {
    this.popupHandlerService.openConfirmationDialog('', 'Reacalculate version');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          this.algorithmService
            .recalculateResultsByVersion(versionId)
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._matSnackBar
                  .open((data as ErrorMessage).message, '', {})
                  ._dismissAfter(4000);
              } else {
                this.openSnackBar('Version Reacalculated');
                this.getLabWithVersions();
              }
            });
        }
      });
  }
  lockVersion(versionId: number, isLock: boolean) {
    this.popupHandlerService.openConfirmationDialog(
      '',
      `${isLock ? 'Unlock' : 'Lock'} version`
    );
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          if (isLock) {
            this.algorithmService.unlockVersion(versionId).subscribe((data) => {
              this.getLabs();
              this.openSnackBar('Version Unlocked');
            });
          } else {
            this.algorithmService.lockVersion(versionId).subscribe((data) => {
              if (
                (data as NameAndId[])?.length > 0 &&
                (data as NameAndId[])[0].name
              ) {
                let error = data.map((x) => {
                  return `${x.name}<br>`;
                });
                error.unshift('Error with following subcategories:<hr>');
                error = error.join(',');
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.costumMessage,
                  null,
                  null,
                  error,
                  4000
                );
              } else {
                this.getLabs();
                this.openSnackBar('Version Locked');
              }
            });
          }
        }
      });
  }
  duplicateVersion(versionId: number) {
    this.popupHandlerService.openDuplicateVersion(versionId);
    this.popupHandlerService.duplicateVersionSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.openSnackBar('Version duplicated');
        }
      });
  }
  openVersionSettings(versionId: number) {
    this.popupHandlerService.openAddLabVersion({
      versionId,
      langId: this.langId,
    });
    this.popupHandlerService.addNewLabVersionSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.type === 'add') {
          this._matSnackBar.open('Version added', '', {})._dismissAfter(2000);
          this.getLabs();
        }
      });
  }
  openSnackBar(message: string) {
    this._matSnackBar.open(message, 'Close', {
      duration: 2000,
    });
  }
  deleteVersion(versionId: number) {
    this.popupHandlerService.openConfirmationDialog('', 'Delete version');
    this.popupHandlerService.confirmDialogSubject.subscribe((result) => {
      if (result.ans === 'yes') {
        /* this.algorithmService.lockVersion(versionId).subscribe((data) => {
          this.getLabWithVersions();
        }); */
      }
    });
  }
}
