import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NameAndId } from '../data/NameAndId';
import { UserRoleMin } from '../data/user-roles/UserRoleMin';
import { UserRole } from '../data/UserRole';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  constructor(private http: HttpClient) {}

  getMin() {
    return this.http.get<NameAndId[]>(`${environment.baseUrl}UserRoles/Min`);
  }
  getAll() {
    return this.http.get<UserRoleMin[]>(`${environment.baseUrl}UserRoles`);
  }
  update(userRole: UserRoleMin) {
    return this.http.put<UserRoleMin>(
      `${environment.baseUrl}UserRoles`,
      userRole
    );
  }
  post(userRole: UserRole) {
    return this.http.post<UserRole>(
      `${environment.baseUrl}UserRoles`,
      userRole
    );
  }
}
