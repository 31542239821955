import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlgoAlleleCombination } from 'src/app/data/algorithm/AlgoAlleleCombination';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { AlgoSubCategorySnp } from 'src/app/data/algorithm/AlgoSubCategorySnp';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { AlgorithmService } from 'src/app/Services/algorithm.service';

@Component({
  selector: 'app-edit-rs',
  templateUrl: './edit-rs.component.html',
  styleUrls: ['./edit-rs.component.scss'],
})
export class EditRsComponent implements OnInit, OnChanges {
  //#region Properties
  @ViewChild('primaryAllel') primaryAllel: MatSelectionList;
  @ViewChild('rsForm') rsForm: NgForm;
  @ViewChild('allelForm') allelForm: NgForm;
  @Input() subCategory: AlgoSubCategory;
  @Input() snp: AlgoSubCategorySnp;
  @Input() langId: number;
  @Input() canEdit: boolean;
  @Output('loadRs') loadRs = new EventEmitter<boolean>();
  editMode: boolean;
  rsEditMode: boolean = false;
  allelToEdit: number;
  alfaChanged: boolean;
  //#endregion
  constructor(
    private algorithmService: AlgorithmService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.snp?.previousValue?.algoSnpId !==
        changes.snp?.currentValue?.algoSnpId &&
      !changes.snp?.isFirstChange()
    ) {
      this.cancelEditMode();
      this.cancelRsEditMode();
    }
  }
  ngOnInit(): void {}
  onAlfaChange(event: MatButtonToggleChange) {
    this.alfaChanged = true;
    if (event.value == this.snp.firstLetter) {
      this.snp.isFirstLarger = true;
    } else {
      this.snp.isFirstLarger = false;
    }
  }
  toggleEditRs() {
    if (this.rsForm.valid) {
      this.rsEditMode = !this.rsEditMode;
      if (!this.rsEditMode) {
        this.algorithmService.setSnp(this.snp).subscribe((data) => {
          if ((data as ErrorMessage).message) {
            this.snackBar((data as ErrorMessage).message, 5000);
            this.rsEditMode = true;
          } else {
            this.snackBar('RS details update successfully', 2000);
          }
        });
      } else {
        this.cancelEditMode();
      }
    }
  }
  cancelRsEditMode() {
    this.rsEditMode = false;
    if (this.rsForm?.dirty || this.alfaChanged) {
      this.loadRs.emit(true);
      this.rsForm.reset();
      this.alfaChanged = false;
    }
  }
  cancelEditMode() {
    this.editMode = false;
    this.allelToEdit = null;
    if (this.allelForm?.dirty) {
      this.loadRs.emit(true);
      this.allelForm.reset();
    }
  }

  snackBar(message: string, duration: number = 2000) {
    this._snackBar.open(message, 'Close', {
      duration,
      panelClass: 'added-success',
    });
  }
  toggleAllel(allel: AlgoAlleleCombination) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.allelToEdit = allel.algoSnpAlleleId;
      this.cancelRsEditMode();
    } else {
      if (
        allel.name &&
        (allel.value || allel.value <= 0) &&
        allel.description
      ) {
        this.allelToEdit = null;
        this.algorithmService
          .setSnpAllele(allel, this.langId)
          .subscribe((data) => {
            if ((data as ErrorMessage).message) {
              this.snackBar((data as ErrorMessage).message, 5000);
            } else {
              this.loadRs.emit(true);
              this.snackBar('Algorithm allel update successfully', 2000);
            }
          });
      } else {
        this.editMode = true;
      }
      //this.allelToEdit = null;
    }
  }
  isItemInEditMode(allel: AlgoAlleleCombination) {
    return this.editMode && this.allelToEdit === allel.algoSnpAlleleId;
  }
  trackByFn(index: number, item: any) {
    return index; // or item.id
  }
}
