import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OfficePhoneExtension } from '../data/OfficePhoneExtension';
import { OfficePhoneExtensionGroup } from '../data/OfficePhoneExtensionGroup';

@Injectable({
  providedIn: 'root',
})
export class OfficePhoneExtensionsService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<OfficePhoneExtension[]>(
      `${environment.baseUrl}OfficePhoneExtensions`
    );
  }
  getFilteredExtension(groupId: number, isActive: number) {
    return this.http.get<OfficePhoneExtension[]>(
      `${environment.baseUrl}OfficePhoneExtensions/GetFilteredExtension/${groupId}/${isActive}`
    );
  }
  getAllGroups() {
    return this.http.get<OfficePhoneExtensionGroup[]>(
      `${environment.baseUrl}OfficePhoneExtensionGroups`
    );
  }
  post(OfficePhoneExtension: OfficePhoneExtension) {
    return this.http.post<any>(
      `${environment.baseUrl}OfficePhoneExtensions/`,
      OfficePhoneExtension
    );
  }
  put(OfficePhoneExtension: OfficePhoneExtension) {
    return this.http.put<any>(
      `${environment.baseUrl}OfficePhoneExtensions/${OfficePhoneExtension.officePhoneExtensionId}`,
      OfficePhoneExtension
    );
  }
  delete(officePhoneExtensionId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}OfficePhoneExtensions/${officePhoneExtensionId}`
    );
  }
  subscribe(officePhoneExtensionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}OfficePhoneExtensions/Subscribe/${officePhoneExtensionId}`
    );
  }
  unSubscribe(officePhoneExtensionId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}OfficePhoneExtensions/UnSubscribe/${officePhoneExtensionId}`
    );
  }
}
