<div
  class="waiting-list"
  *ngIf="(firebaseService.waitingList$ | async).length > 0"
>
  <div
    matRipple
    class="waiting-list__row"
    *ngFor="let item of firebaseService.waitingList$ | async"
    (click)="onRowClicked(item)"
    [class]="
      selectedRow && selectedRow.sessionId == item.sessionId ? 'active' : ''
    "
  >
    <div class="waiting-list__row__avatar">
      <span
        class="waiting-list__row__avatar__notification"
        *ngIf="item.unreadedMessages > 0"
      >
        {{ item.unreadedMessages }}
      </span>
      <img src="../../../assets/botavatar.png" alt="avatar" />
    </div>
    <div class="waiting-list__row__data">
      <div class="waiting-list__row__data__name">
        {{ item.firstName }} {{ item.secondName }}
      </div>
      <div class="waiting-list__row__data__badge">
        <span>Waiting</span>
      </div>
    </div>
  </div>
</div>
