<div class="title" *ngIf="data.alert.foodAlertId == 0">Create new Tag</div>
<div class="title" *ngIf="data.alert.foodAlertId > 0">Edit Tag</div>

<div class="item mt-4">
  <div class="label">Tag name:</div>
  <mat-form-field appearance="fill" class="item__field">
    <input matInput [(ngModel)]="data.alert.name" />
  </mat-form-field>
</div>
<div class="item mt-4">
  <div class="label">Description:</div>
  <mat-form-field class="item__select" appearance="fill">
    <textarea matInput [(ngModel)]="data.alert.description"></textarea>
  </mat-form-field>
</div>
<div class="d-flex" style="width: 100%; gap: 50px">
  <div style="flex: 0.5">
    <div class="item flex mt-4">
      <div class="label">Color:</div>
      <div style="position: relative">
        <button
          [style.color]="data.alert.color"
          (click)="colorPicker.click()"
          mat-icon-button
        >
          <mat-icon>radio_button_checked</mat-icon>
        </button>
        <input
          [(ngModel)]="data.alert.color"
          type="color"
          #colorPicker
          style="visibility: hidden; position: absolute; top: 10px; left: 0"
        />
      </div>
      <!-- <mat-form-field class="item__select" appearance="fill">
        <input matInput [(ngModel)]="data.alert.color" />
      </mat-form-field> -->
    </div>
  </div>
  <div style="flex: 0.5">
    <app-algo-image
      [langId]="data.langId"
      [algoImageType]="ImageType.FoodGenesTag"
      (onUploadFinished)="onUploadFinished($event)"
      [selectedImg]="getImage()"
    ></app-algo-image>
  </div>
</div>

<div class="actions mt-4">
  <div class="actions__empty"></div>
  <div class="actions__btns">
    <button class="save" mat-button (click)="save()" type="submit">Save</button>
    <button class="cancel" mat-button mat-dialog-close type="button">
      Close
    </button>
  </div>
</div>
<!-- 
  [disabled]="
          !coeff.fgCoefficientName &&
          !coeff.orderBy &&
          !coeff.validExceptPersent
        " -->
