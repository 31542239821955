import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { FoodCategory } from '../data/FoodCategory';
import { NameAndId } from '../data/NameAndId';

@Injectable({
  providedIn: 'root',
})
export class FoodCategoriesService {
  constructor(private http: HttpClient) {}
  getAll(langId: number) {
    return this.http.get<NameAndId[]>(
      `${environment.baseUrl}FoodCategories/All/${langId}`
    );
  }
  getBySourceKey(langId: number, sourceKey: string) {
    return this.http.get<NameAndId[]>(
      `${environment.baseUrl}FoodCategories/${langId}/${sourceKey}`
    );
  }
  getById(agentId) {
    return this.http.get<FoodCategory>(
      `${environment.baseUrl}FoodCategories/${agentId}`
    );
  }
  put(foodCategory: FoodCategory) {
    return this.http.put(
      `${environment.baseUrl}FoodCategories/${foodCategory.foodCategoryId}`,
      foodCategory
    );
  }
  post(foodCategory: FoodCategory) {
    return this.http.post<FoodCategory>(
      `${environment.baseUrl}FoodCategories/`,
      foodCategory
    );
  }
  saveAll(foodCategories: FoodCategory[]) {
    return this.http.post<any>(
      `${environment.baseUrl}FoodCategories/SaveAll`,
      foodCategories
    );
  }
  delete(foodCategory: FoodCategory) {
    return this.http.delete<FoodCategory | ErrorMessage>(
      `${environment.baseUrl}FoodCategories/${foodCategory.foodCategoryId}`
    );
  }
}
