import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AgentMin } from 'src/app/data/AgentMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { ServiceRequest } from 'src/app/data/ServiceRequest';
import { Status } from 'src/app/data/Status';
import {
  RequestEnum,
  ServiceRequestSubjectEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { StatusesService } from 'src/app/Services/statuses.service';

@Component({
  selector: 'app-lead-create-request',
  templateUrl: './lead-create-request.component.html',
  styleUrls: ['./lead-create-request.component.scss'],
})
export class LeadCreateRequestComponent implements OnInit {
  filterdAgentOptions: AgentMin[];
  @Output('submitRequest')
  submitRequest: EventEmitter<any> = new EventEmitter();
  @Output('cancelRequest')
  cancelRequest: EventEmitter<any> = new EventEmitter();
  @Input() request: ServiceRequest;
  @Input() currAgent: RegularUser;
  subjects: Status[];
  @Input() allAgents: AgentMin[];
  agents: AgentMin[];
  requestForm: FormGroup = null;
  //agentForAC: string[];
  editorConfig: AngularEditorConfig = {
    sanitize: false,
    editable: true,
  };
  isSubmitted: boolean;

  constructor(private statusesService: StatusesService) {
    this.statusesService.getAll().subscribe((statuses) => {
      this.subjects = statuses.filter(
        (status) => status.category === 'RequestSubject'
      );
    });
  }

  ngOnInit(): void {
    this.agents = this.allAgents;
    //this.agentForAC = this.agents.map((agent) => agent.name);
    this.createRequestFormGroup();
  }
  saveAgentForLead(event) {
    //requestForm.value.assignedAgentName = event
    this.request.assignedAgentId = event;
    var selectedAgent = this.agents.find((x) => x.agentId == event).name;
    this.requestForm.controls['assignedAgentName'].setValue(selectedAgent);
  }
  saveRequest() {
    this.isSubmitted = true;
    if (this.requestForm.valid) {
      this.request.subjectId = this.requestForm.value.subject;
      this.request.desc = this.requestForm.value.desc;
      var assignedAgentName = this.requestForm.value.assignedAgentName;

      this.request.assignedAgentId = this.allAgents.find(
        (x) => x.name == assignedAgentName
      ).agentId;
      this.request.statusId = RequestEnum.Open;
      this.submitRequest.emit(this.request);
      this.isSubmitted = false;
    }
  }

  onCancelRequest() {
    this.cancelRequest.emit();
  }
  createRequestFormGroup() {
    if (!this.request) return;
    var agentName = this.allAgents.find(
      (x) => x.agentId == this.request.assignedAgentId
    ).name;
    this.requestForm = new FormGroup({
      assignedAgentName: new FormControl(agentName, [Validators.required]),
      subject: new FormControl(this.request.subjectId, [Validators.required]),
      desc: new FormControl(this.request.desc, [Validators.required]),
    });
    if (!this.request.subjectId) {
      this.requestForm.controls['subject'].setValue(
        ServiceRequestSubjectEnum.CustomerService
      );
      this.onSubjectChanged(
        {
          value: ServiceRequestSubjectEnum.CustomerService,
        },
        true
      );
    }
  }
  onAgentAutocompleteChanged(event) {
    var a = event.target.value;
    var agentsDup = [...this.agents];
    this.filterdAgentOptions = agentsDup.filter((agent) => {
      return agent.name.indexOf(a) != -1;
    });
  }
  onSubjectChanged(event, fromInit = false) {
    if (!fromInit) {
      this.requestForm.controls['assignedAgentName'].setValue('');
    }
    switch (event.value) {
      case ServiceRequestSubjectEnum.CustomerService:
      case ServiceRequestSubjectEnum.Logistics:
      case ServiceRequestSubjectEnum.UserNameOrPassword:
        this.agents = this.allAgents.filter(
          (x) =>
            x.roleTypeId == UserRoleEmum.Support ||
            x.roleTypeId == UserRoleEmum.SupportManager ||
            x.roleTypeId == UserRoleEmum.SupportCalendarManager
        );
        break;
      case ServiceRequestSubjectEnum.Finance:
        this.agents = this.allAgents.filter(
          (x) => x.roleTypeId == UserRoleEmum.Finance
        );
        break;
      case ServiceRequestSubjectEnum.Nutrition:
        this.agents = this.allAgents.filter(
          (x) =>
            x.roleTypeId == UserRoleEmum.nutritionist ||
            x.roleTypeId == UserRoleEmum.nutritionistManager ||
            x.roleTypeId == UserRoleEmum.followUpAgent
        );
        break;
    }
  }
}
