import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { PaginationMetadata } from '../data/PaginationMetadata';
import { Translation } from '../data/Translation';
import { TranslationCategory } from '../data/TranslationCategory';
import { TranslationKeyword } from '../data/TranslationKeyword';
import { TranslationSubCategory } from '../data/TranslationSubCategory';
import { HttpFilterHelper } from '../Utils/http-filter-helper';

export interface translationPaging {
  translations: TranslationKeyword[];
  pagination: PaginationMetadata;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  constructor(private http: HttpClient) {}
  getTranslationCategories() {
    return this.http.get<TranslationCategory[]>(
      `${environment.baseUrl}translation/GetTranslationCategory`
    );
  }
  getTranslationSubCategories() {
    return this.http.get<TranslationSubCategory[]>(
      `${environment.baseUrl}Translation/GetTranslationSubCategory`
    );
  }
  getTranslationWithPaging(headFilter: HeadFilter) {
    return this.http.get<translationPaging>(
      `${
        environment.baseUrl
      }translation/GetTranslationWithPaging/${this.httpFilterHelper.createTranslationPathFromHeadFilter(
        headFilter
      )}`
    );
  }
  searchTranslationWithPaging(headFilter: HeadFilter) {
    return this.http.get<translationPaging>(
      `${
        environment.baseUrl
      }translation/SearchTranslation/${this.httpFilterHelper.createTranslationPathFromHeadFilter(
        headFilter
      )}`
    );
  }
  setTranslation(translation: Translation[]) {
    return this.http.put<Translation>(
      `${environment.baseUrl}translation`,
      translation
    );
  }
  AddKeyword(keyword: TranslationKeyword) {
    return this.http.post<TranslationKeyword>(
      `${environment.baseUrl}translation`,
      keyword
    );
  }
}
