<form (ngSubmit)="onSubmit(foodForm)" #foodForm="ngForm">
  <button type="button" (click)="cancel(foodForm)" class="btn-link" mat-button>
    < Back to list
  </button>

  <div class="edit-food" *ngIf="food">
    <mat-card>
      <div class="edit-food__title">
        <ng-container *ngIf="isRecipe; else isFood">
          <div>
            <mat-icon>restaurant_menu</mat-icon>
            {{ foodId == -1 ? "Add new " : "Edit " }} Recipe
          </div>
        </ng-container>
        <ng-template #isFood>
          <div>
            <mat-icon>fastfood</mat-icon>
            {{ foodId == -1 ? "Add new " : "Edit " }} Food
          </div>
        </ng-template>
        <div class="edit-food__title__lang">
          <mat-form-field appearance="fill">
            <mat-icon matPrefix
              ><img
                style="width: 20px; position: relative; top: -4px"
                src="../../../../assets/flags/{{ selectedLang }}.png"
                alt=""
            /></mat-icon>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

            <mat-select
              [(ngModel)]="selectedLang"
              (selectionChange)="changeLangId()"
              disableOptionCentering
              panelClass="lang-select"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-option *ngFor="let lang of langs" [value]="lang.languageId">
                <div
                  style="display: flex; align-items: center; font-size: 1.4rem"
                >
                  <img
                    style="width: 15px; margin-right: 1rem"
                    src="../../../../assets/flags/{{ lang.languageId }}.png"
                    alt=""
                  />
                  {{ lang.name }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex">
        <div
          class="edit-food__details"
          [ngStyle]="{ flex: isRecipe ? 'unset' : '1' }"
        >
          <div class="edit-food__details__item">
            <span class="d-flex-center"
              ><div class="edit-food__details__item__lock">
                <mat-checkbox
                  name="isLocked"
                  [(ngModel)]="food.isLocked"
                  #lockItem
                  matTooltip="In Lock mode, the Item is Loocked for automatic update"
                >
                  <img
                    *ngIf="!lockItem.checked"
                    src="../../../../assets/lock-open.svg"
                    alt=""
                  />
                  <img
                    *ngIf="lockItem.checked"
                    src="../../../../assets/lock-close.svg"
                    alt=""
                  />
                </mat-checkbox>
              </div>
              Item name</span
            >
            <mat-form-field
              [matTooltip]="food.foodLangs[0].name"
              matTooltipPosition="above"
              appearance="fill"
              ><input
                required
                name="itemName"
                matInput
                [(ngModel)]="food.foodLangs[0].name"
            /></mat-form-field>
          </div>
          <div class="edit-food__details__item">
            <span>Item description</span>
            <mat-form-field
              appearance="fill"
              style="width: 307px; max-width: 68%"
            >
              <textarea
                name="itemDesc"
                matInput
                [(ngModel)]="food.foodLangs[0].description"
              >
              </textarea>
            </mat-form-field>
          </div>

          <div class="edit-food__title" style="margin-top: 4rem; width: 125%">
            <div>
              <span>Barcodes</span>
            </div>
          </div>
          <div class="edit-food__details__item" *ngIf="!isRecipe"></div>
          <div
            *ngFor="
              let item of food.foodBarcode;
              trackBy: trackByFnBarcode;
              let i = index;
              let last = last
            "
            class="edit-food__units__item"
          >
            <div
              class="w-150"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <mat-form-field appearance="fill">
                <input
                  name="barcode-{{ i }}"
                  required
                  type="text"
                  matInput
                  [(ngModel)]="food.foodBarcode[i]"
                  pattern="[0-9]{6,25}"
                  title="Digits with length between 6 and 25 are allowed"
                />
              </mat-form-field>
              <button
                type="button"
                mat-icon-button
                (click)="removeBarcodeUnit(i, foodForm, food.foodBarcode[i])"
                class="pink"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-fill fl-right"
            mat-button
            style="width: 200px; height: 40px; margin-left: auto"
            (click)="addBarcode()"
          >
            Add barcode
          </button>

          <div
            class="edit-food__title"
            style="margin-top: 4rem; width: 125%"
          ></div>

          <div
            class="edit-food__details__item"
            *ngIf="foodCategories"
            style="margin-top: 4rem"
          >
            <span>Choose Category</span>
            <app-food-categories-selection-panel
              *ngIf="foodCategories"
              [fromEditFoodComponent]="true"
              [categories]="foodCategories"
              [selectedCat]="selectedCat"
              (selectCat)="onSelectCat($event)"
            ></app-food-categories-selection-panel>
            <button
              type="button"
              class="btn btn-fill fl-right"
              mat-button
              (click)="clear()"
              style="margin-left: 15px"
            >
              Clear
            </button>
          </div>
          <div
            class="edit-food__details__item"
            *ngIf="food?.categoryForFood?.length > 0"
          >
            <span>Categories:</span>
            <div class="cats-wrapper">
              <div
                class="selected-cats"
                *ngFor="let item of food?.categoryForFood"
              >
                <mat-icon (click)="removeCat(item.foodCategory.foodCategoryId)"
                  >close</mat-icon
                >
                <div class="selected-cats__name">
                  {{ item.foodCategory.foodCategoryLangs[0].name }}
                </div>
              </div>
            </div>
          </div>

          <div class="edit-food__details__item" *ngIf="isRecipe">
            <span>Recipe for</span>

            <mat-form-field appearance="fill" class="w-100px"
              ><input
                required
                name="numDishes"
                type="number"
                placeholder="0"
                matInput
                pattern="^[1-9][0-9]*$"
                [(ngModel)]="food.numDishes"
              />
              <span matSuffix>Dishes</span></mat-form-field
            >
            &nbsp;&nbsp;
            <mat-form-field appearance="fill" class="w-200"
              ><input
                name="singleDishWeight"
                type="number"
                placeholder="type here"
                matInput
                [(ngModel)]="food.singleDishWeight"
              /><span matSuffix>Grams per dish</span></mat-form-field
            >
          </div>
          <div class="edit-food__details__item" *ngIf="isRecipe">
            <span>Time in minutes</span>
            <mat-form-field appearance="fill" class="w-100px"
              ><input
                name="time"
                type="number"
                matInput
                [(ngModel)]="food.timeInMin"
              /><mat-icon style="transform: translateY(5px)" matSuffix
                >schedule</mat-icon
              ></mat-form-field
            >
          </div>
          <div class="edit-food__details__item" *ngIf="isRecipe">
            <span>Difficulty</span>
            <mat-form-field appearance="fill">
              <mat-select
                name="makingEffort"
                [(ngModel)]="food.makingEffort"
                placeholder="Choose difficulty level"
              >
                <mat-option
                  *ngFor="let item of difficulties"
                  [value]="item.makeEffortId"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="edit-food__details__item" *ngIf="rests">
            <span>Restaurant</span>
            <mat-form-field appearance="fill">
              <mat-select
                name="restId"
                [(ngModel)]="food.restId"
                placeholder="Choose restaurant"
              >
                <mat-option *ngFor="let item of rests" [value]="item.restId">{{
                  item.restName
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="edit-food__details__item">
            <span>Published</span>
            <mat-checkbox
              [checked]="food.isPublished"
              (change)="onIsPublishedChange($event)"
            >
            </mat-checkbox>
          </div>
          <div class="edit-food__details__item">
            <span>FoodGenes</span>
            <mat-checkbox
              [checked]="food.isCalcRecommendation"
              (change)="onIsCalcRecommendationChange($event)"
            >
            </mat-checkbox>
          </div>
          <div class="edit-food__details__item">
            <span>Is basic</span>
            <mat-checkbox
              [checked]="food.isBasic == 1"
              (change)="onIsBasicChange($event)"
            >
            </mat-checkbox>
          </div>
        </div>
        <div
          style="display: flex"
          [ngStyle]="{
            'flex-direction': isRecipe ? 'column' : 'row-reverse',
            flex: isRecipe ? '1' : 'unset'
          }"
        >
          <input
            name="file"
            hidden
            type="file"
            accept="image/*"
            #file
            placeholder="Choose file"
            (change)="uploadImg(file.files)"
          />
          <div>
            <div *ngIf="!food.imageUrl" (click)="file.click()" class="img-box">
              <ng-container *ngIf="!tempImg"
                ><mat-icon>cloud_upload</mat-icon> Upload Image</ng-container
              >
              <img
                *ngIf="tempImg && !imgError"
                style="
                  max-width: 100%;
                  width: 100px;
                  height: 100px;
                  display: block;
                  padding: 10px;
                "
                [src]="sanitizePreviewSrc(tempImg)"
                alt="{{ tempImgName }}"
              />
            </div>
            <div *ngIf="tempImg">
              <button
                style="margin: auto"
                mat-button
                type="button"
                (click)="file.click()"
              >
                Change image
              </button>
            </div>
            <div *ngIf="imgError" style="color: red; margin-top: 1rem">
              {{ imgError }}
            </div>
          </div>

          <div style="text-align: center" *ngIf="food.imageUrl">
            <img
              style="
                max-width: 100%;
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 auto;
              "
              src="{{ food.imageUrl }}"
              alt=""
            />
            <button mat-button type="button" (click)="file.click()">
              Change Image
            </button>
          </div>
        </div>
      </div>
      <div class="mb-2"></div>
      <div class="edit-food__title">Unit of measure</div>
      <div class="edit-food__units">
        <div class="edit-food__units__headers">
          <span>Unit Type</span>
          <span>Gram in 1 Unit</span>
        </div>
      </div>

      <div style="margin-bottom: 9rem">
        <div
          *ngFor="
            let item of food.foodScaleValues;
            trackBy: trackByFn;
            let i = index;
            let last = last
          "
          class="edit-food__units__item"
        >
          <div>
            <mat-form-field appearance="fill">
              <mat-select
                name="foodScaleId-{{ i }}"
                required
                [(ngModel)]="item.foodScaleId"
              >
                <mat-option
                  *ngFor="let foodScale of foodScales"
                  [value]="foodScale.id"
                >
                  {{ foodScale.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="fill" class="w-150"
              ><input
                name="weight-{{ i }}"
                required
                type="number"
                matInput
                [(ngModel)]="item.weight"
              /><span matSuffix>gr</span></mat-form-field
            >
            <button
              type="button"
              [disabled]="food.foodScaleValues.length == 1"
              mat-icon-button
              (click)="removeUnit(i, foodForm)"
              class="pink"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <button
              type="button"
              class="btn btn-fill fl-right"
              *ngIf="last"
              mat-button
              (click)="addUnit()"
            >
              Add Unit
            </button>
          </div>
        </div>
        <div style="color: red" *ngIf="!validUnits">
          Has duplicate unit item
        </div>
        <div style="color: red" *ngIf="!validWeightUnits">
          Please insert weight above 0
        </div>
        <button
          type="button"
          class="btn btn-fill"
          *ngIf="food.foodScaleValues.length == 0"
          mat-button
          (click)="addUnit()"
        >
          Add Unit
        </button>
        <div class="mb-2"></div>
        <div class="edit-food__title">Allergens</div>
        <div class="allergenes">
          <p *ngFor="let item of allergens; let i = index">
            <mat-checkbox
              name="allergens-{{ i }}"
              (change)="checkboxChange($event, i)"
              [checked]="item.amount == 1"
              [(ngModel)]="item.amount"
              >{{ item.foodNutrient.foodNutrientLangs[0]?.name }}</mat-checkbox
            >
          </p>
        </div>
      </div>

      <div class="edit-food__actions">
        <button type="submit" class="btn btn-fill" mat-button>save</button>
        <span class="ml-1"></span>
        <button
          (click)="cancel(foodForm)"
          type="button"
          class="btn btn-outline"
          mat-button
        >
          cancel
        </button>
      </div>
    </mat-card>

    <mat-card>
      <mat-tab-group animationDuration="0">
        <mat-tab label="Ingredients" *ngIf="isRecipe">
          <div class="mb-2"></div>
          <div
            *ngFor="
              let item of food.recipeHeaders;
              trackBy: trackByFn;
              let i = index;
              let last = last;
              let first = first
            "
            class="recipe"
          >
            <div class="recipe__headline">
              <mat-form-field appearance="fill" class="w-100"
                ><input
                  required
                  name="headline-{{ i }}"
                  placeholder="Insert headline"
                  matInput
                  [(ngModel)]="
                    item.recipeHeaderLangs &&
                    item.recipeHeaderLangs[0] &&
                    item.recipeHeaderLangs[0].name
                  "
              /></mat-form-field>
              <button
                type="button"
                (click)="removeRecipeHeader(i)"
                class="recipe__headline__delete"
                mat-icon-button
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
            <div class="recipe__headers">
              <span>Item</span>
              <span>View name</span>
              <span>Unit</span>
              <span>Amount</span>
              <span></span>
            </div>
            <div
              *ngFor="
                let bullet of item.recipeBullets;
                let x = index;
                let lastBullet = last
              "
              class="recipe__item"
            >
              <div
                class="autocomplete-fake"
                *ngIf="bullet.ingredientFoodId !== autoCompleteActiveFoodId"
                (click)="setAutoCompleteActive(bullet.ingredientFoodId)"
                [ngStyle]="{
                  'border-bottom':
                    bullet.ingredientFoodId <= 0 && hasAutoCompleteError
                      ? '1px solid red'
                      : ''
                }"
              >
                <span
                  class="text"
                  [matTooltip]="bullet.ingredientFood?.foodLangs[0].name"
                  *ngIf="bullet.ingredientFood?.foodLangs[0].name"
                >
                  {{ bullet.ingredientFood?.foodLangs[0].name }}
                </span>
                <span
                  class="text placeholder"
                  *ngIf="!bullet.ingredientFood?.foodLangs[0].name"
                >
                  choose item
                </span>
                <button
                  type="button"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="open"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
              <app-autocomplete-foods
                #autocomplteEl
                *ngIf="bullet.ingredientFoodId == autoCompleteActiveFoodId"
                [autoCompleteActiveFoodId]="autoCompleteActiveFoodId"
                class="w-100 autocomplete"
                [initialValue]="
                  bullet.ingredientFood?.foodLangs
                    ? bullet.ingredientFood?.foodLangs[0].name
                    : ''
                "
                [data]="foodsResults$ | async"
                placeholder="choose item"
                (selectedValue)="onFoodSelected($event, i, x)"
                (searchTerm)="searchTermFood$.next($event)"
                [langId]="langId"
                [isRtl]="false"
                [ngStyle]="{
                  'border-bottom':
                    bullet.ingredientFoodId <= 0 && hasAutoCompleteError
                      ? '1px solid red'
                      : ''
                }"
              >
              </app-autocomplete-foods>
              <mat-form-field appearance="fill">
                <textarea
                  style="height: 15px"
                  matAutosizeMinRows="1"
                  matAutosizeMaxRows="2"
                  matInput
                  name="viewName-{{ i }}-{{ x }}"
                  [(ngModel)]="
                    bullet.recipeBulletLangs &&
                    bullet.recipeBulletLangs[0] &&
                    bullet.recipeBulletLangs[0].name
                  "
                ></textarea>
              </mat-form-field>

              <mat-form-field
                appearance="fill"
                [matTooltip]="
                  getFoodScaleName(
                    bullet.foodScaleId,
                    bullet?.ingredientFood?.foodScaleValues
                  )
                "
              >
                <mat-select
                  required
                  name="recipeFoodScaleId-{{ i }}-{{ x }}"
                  [(ngModel)]="bullet.foodScaleId"
                  *ngIf="bulletScaleValues"
                  placeholder="Choose unit"
                >
                  <mat-option
                    *ngFor="let item of bullet?.ingredientFood?.foodScaleValues"
                    [value]="item.foodScaleId"
                  >
                    {{ item?.foodScale?.foodScaleLangs[0]?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="amount" appearance="fill"
                ><input
                  required
                  name="quantity-{{ i }}-{{ x }}"
                  type="number"
                  min="0"
                  matInput
                  placeholder="insert here"
                  [(ngModel)]="bullet.quantity"
              /></mat-form-field>
              <div class="js-center">
                <button
                  type="button"
                  [disabled]="disableRemoveBullet(i)"
                  (click)="removeBullet(i, x)"
                  mat-icon-button
                  class="pink"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="lastBullet"
                  class="btn btn-fill fl-right"
                  mat-button
                  (click)="addBullet(i)"
                >
                  Add
                </button>
              </div>
            </div>
            <div *ngIf="!validFoodAmount" style="color: red">
              Food Amount has Negative number
            </div>
            <div
              class="recipe__seperator"
              *ngIf="food.recipeHeaders.length > 1 && !last"
            >
              <hr />
            </div>
            <div *ngIf="last">
              <div class="mb-2"></div>
              <button
                type="button"
                class="btn btn-outline"
                mat-button
                (click)="addSection()"
              >
                Add Section
              </button>
              <div class="mb-2"></div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="isRecipe">
          <ng-template mat-tab-label>
            <div>Directions</div>
          </ng-template>
          <div class="mb-2"></div>
          <div class="directions">
            <div class="edit-food__desc">
              <div class="pad-start-2 recomendations" fxLayout="column">
                <div
                  *ngFor="let item of food.recipeDirections; let i = index"
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  class="item"
                >
                  <mat-form-field style="width: 100%" appearance="fill">
                    <textarea
                      matInput
                      name="direction-{{ i }}"
                      style="height: 15px"
                      placeholder="Add Direction..."
                      [(ngModel)]="item.content"
                    >
                    </textarea>
                  </mat-form-field>
                  <button
                    fxFlexAlign="end"
                    type="button"
                    (click)="removeDirection(i)"
                    [disabled]="food.recipeDirections.length == 1"
                    mat-icon-button
                    class="pink"
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
              </div>
              <div class="mb-2"></div>
              <button
                class="btn btn-outline"
                type="button"
                mat-button
                (click)="addDirection()"
              >
                Add Direction
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div [ngStyle]="{ color: nutrientsRequiredError ? 'red' : '' }">
              Nutrients
            </div>
          </ng-template>
          <div class="mb-2"></div>
          <div class="vitamins">
            <div class="edit-food__title">
              Nutrition Values per 100 grams
              <mat-button-toggle-group
                *ngIf="isRecipe"
                [(ngModel)]="food.isNutrientsLock"
                name="isNutrientsLock"
              >
                <mat-button-toggle [value]="false">Opened</mat-button-toggle>
                <mat-button-toggle [value]="true">Locked</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="d-grid-5">
              <div *ngFor="let item of nutrients; let i = index">
                <div class="name">
                  {{ item.foodNutrient.foodNutrientLangs[0]?.name }}
                </div>
                <mat-form-field appearance="fill" class="w-150">
                  <input
                    type="text"
                    name="nutAmount-{{ i }}"
                    [(ngModel)]="item.amount"
                    pattern="^[\d]{0,4}[\.]{0,1}[\d]{1,2}$"
                    maxlength="6"
                    placeholder="type here"
                    matInput
                    [required]="!isRecipe"
                    (keydown)="validateAmountValue($event)"
                    [disabled]="food.isNutrientsLock"
                  /><span matSuffix>{{
                    item.foodNutrient.foodNutrientLangs[0]?.unit
                  }}</span>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="mb-4"></div>
          <div class="minerls">
            <div class="edit-food__title">
              Vitamins and Minerals per 100 grams
            </div>
            <div class="d-grid-5">
              <div *ngFor="let item of vitamins; let i = index" class="">
                <div class="name">
                  {{ item.foodNutrient.foodNutrientLangs[0]?.name }}
                </div>
                <mat-form-field appearance="fill" class="w-150">
                  <input
                    name="vitAmount-{{ i }}"
                    type="text"
                    [(ngModel)]="item.amount"
                    pattern="^[\d]{0,4}[\.]{0,1}[\d]{1,2}$"
                    maxlength="6"
                    (keydown)="validateAmountValue($event)"
                    placeholder="type here"
                    matInput
                    [disabled]="food.isNutrientsLock"
                  /><span matSuffix>{{
                    item.foodNutrient.foodNutrientLangs[0]?.unit
                  }}</span>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</form>
