import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DealForTableMin } from 'src/app/data/DealForTableMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DealEnum, PaymentMethodEnum } from 'src/app/Enums/StatusesEnum';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-transaction-thumb',
  templateUrl: './transaction-thumb.component.html',
  styleUrls: ['./transaction-thumb.component.scss'],
})
export class TransactionThumbComponent implements OnInit {
  @Input() deal: DealForTableMin;
  @Input() status: Status;
  @Input() regularUser: RegularUser;
  @Input() isInner: boolean;
  @Input() isNeedApproveBool: boolean = false;
  @Output('rowClick')
  rowClick: EventEmitter<DealForTableMin> = new EventEmitter();
  @Output('rowChecked')
  rowChecked: EventEmitter<any> = new EventEmitter();
  @Output('onHandleApproval')
  onHandleApproval: EventEmitter<DealForTableMin> = new EventEmitter();
  @Output('onCancelDeal')
  onCancelDeal: EventEmitter<DealForTableMin> = new EventEmitter();

  constructor(public datepipe: DatePipe) {}

  ngOnInit(): void {
    var a = this.status;
  }
  hasPermmisions() {
    return userHasPermisions(['Deals_ChangeStatus'], this.regularUser);
  }
  onRowClick(deal: DealForTableMin) {
    if (
      // true
      this.isInner ||
      deal.dealBundle == null
      /*
       ||
      (this.deal.paymentMethodId == PaymentMethodEnum.CreditCard &&
        (this.deal.statusId == DealEnum.Done ||
          this.deal.statusId == DealEnum.Rejected))
          */
    )
      this.rowClick.emit(deal);
  }
  showCancelDeal() {
    const now = new Date();
    return (
      userHasPermisionsWithRule(['Deals_Update'], this.regularUser) &&
      this.deal.paymentMethodId == PaymentMethodEnum.CreditCard &&
      this.deal.statusId == DealEnum.Done &&
      // check if deal is not older than 1 business day 6:00-22:00
      new Date(this.deal.dateUpdated).getTime() >
        new Date(now.setDate(now.getDate())).setHours(6, 0, 0, 0) &&
      new Date(this.deal.dateUpdated).getTime() <
        new Date(now.setDate(now.getDate())).setHours(22, 0, 0, 0)
    );
  }

  cancelDeal(deal: DealForTableMin, event) {
    event.stopPropagation();
    this.onCancelDeal.emit(deal);
  }

  isRefund(date: string) {
    return false;
  }
  isChecked(dealId: number, checked: boolean) {
    this.rowChecked.emit({ dealId, checked });
  }

  handleApproval(e, deal: DealForTableMin) {
    deal.statusId = e.value;
    /* deal.statusId = this.statuses.find(
      (x) => x.statusId == deal.statusId
    ).statusId; */
    this.onHandleApproval.emit(deal);
  }
}
