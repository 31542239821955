import { AppImage } from '../algorithm/AppImage';

export class FoodAlert {
  foodAlertId: number;
  color: string;
  orderBy: number;
  langId: number;
  name: string;
  description: string;
  icon: AppImage;
}
