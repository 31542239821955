<div class="lab">
  <button (click)="location.back()" class="lab__back" mat-button>< Back</button>

  <mat-card class="lab__card">
    <mat-card-header class="lab__card__header">
      <mat-card-title class="lab__card__header__title">
        <mat-icon>science</mat-icon>
        Lab version table
      </mat-card-title>
      <mat-form-field appearance="fill" class="lab__card__header__labs">
        <mat-select
          placeholder="Select lab"
          [(ngModel)]="selectedLabId"
          (ngModelChange)="onLabChange()"
          class="lab__card__header__labs__select"
        >
          <mat-option *ngFor="let lab of labs" [value]="lab.labId">
            {{ lab.name | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button
          class="lab__card__header__create"
          mat-button
          (click)="openVersionSettings(0)"
          [disabled]="!userCanEdit()"
        >
          Create version
        </button>
        &nbsp;
        <app-change-lang
          [selectedLangId]="langId"
          (changeLang)="onChangeLang($event)"
        ></app-change-lang>
      </div>
    </mat-card-header>

    <perfect-scrollbar style="height: calc(100vh - 260px)">
      <table mat-table [dataSource]="dataSource" class="lab__table">
        <ng-container matColumnDef="lab">
          <th mat-header-cell *matHeaderCellDef style="width: 25%">Lab</th>
          <td mat-cell *matCellDef="let element">
            {{ element.labName | uppercase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef style="width: 25%">Version</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 25%">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date: "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 25%">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <!-- <button
              mat-icon-button
              class="lab__table__action"
              (click)="recalcVersion(element.labVersionId)"
              matTooltip="Recalculate Results"
              matTooltipPosition="left"
              [disabled]="!userCanRecalc()"
            >
              <mat-icon>refresh</mat-icon>
            </button> -->
            <button
              mat-icon-button
              class="lab__table__action"
              (click)="lockVersion(element.labVersionId, element.isLock)"
              matTooltip="{{ element.isLock ? 'Locked' : 'Unlocked' }}"
              matTooltipPosition="above"
              [disabled]="!element.isLock ? !userCanLock() : !userCanUnlock()"
            >
              <mat-icon>{{ element.isLock ? "lock" : "lock_open" }}</mat-icon>
            </button>
            <button
              mat-icon-button
              class="lab__table__action"
              (click)="duplicateVersion(element.labVersionId)"
              matTooltip="Duplicate Version"
              matTooltipPosition="above"
              [disabled]="element.isLock"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
            <button
              mat-icon-button
              class="lab__table__action"
              (click)="openVersionSettings(element.labVersionId)"
              matTooltip="Edit"
              matTooltipPosition="right"
              [disabled]="!userCanEdit()"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <!--  <button
              mat-icon-button
              class="lab__table__action"
              (click)="deleteVersion(element.labVersionId)"
            >
              <mat-icon>delete</mat-icon>
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>
  </mat-card>
</div>
