<mat-list style="margin-bottom: 80px" class="leadsTable" role="list">
  <mat-list-item class="leadsTable__header" role="listitem">
    <span *ngIf="canChangeBulk()" style="width: 2.5%">
      <mat-checkbox
        class="leadsTable__item__checkbox"
        (change)="checkAll($event)"
        [(ngModel)]="checkedAll"
      >
      </mat-checkbox>
    </span>
    <ng-container *ngFor="let item of headers">
      <span
        [ngStyle]="{ width: item.width }"
        class="clickble"
        [ngClass]="{ sortSelected: headFilter.orderBy == item.val }"
      >
        <span (click)="changeSortBy(item.val)">{{ item.name }} </span>
        <ng-container *ngIf="headFilter.orderBy == item.val">
          <img
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == true"
            (click)="changeSortOrder()"
          />
          <img
            class="rotate180"
            src="../../../assets/sort_arrows.svg"
            *ngIf="headFilter.acending == false"
            (click)="changeSortOrder()"
          />
        </ng-container>
      </span>
    </ng-container>
    <!--
    <span>Email</span>
    <span>Tel</span>
    <span>Source</span>
    <span>Created Date</span>
    <span>Agent</span>
    <span>Status</span>
    <span>Client type</span>
    -->
  </mat-list-item>
  <div style="padding: 0 1rem" *ngIf="isLoading">
    <ngx-skeleton-loader
      [theme]="{
        height: '50px',
        'margin-bottom': '10px',
        'background-color': 'rgba(27, 63, 78,0.15)'
      }"
      count="10"
    ></ngx-skeleton-loader>
  </div>
  <mat-card class="leadsTable__item" *ngFor="let leadItem of leadsData">
    <mat-list-item role="listitem" (click)="onRowClicked($event, leadItem)">
      <span *ngIf="canChangeBulk()" style="width: 2.5%">
        <mat-checkbox
          class="leadsTable__item__checkbox"
          (click)="stopPropegation($event)"
          (change)="isChecked($event, leadItem.clientId, leadItem.isChecked)"
          [(ngModel)]="leadItem.isChecked"
        >
        </mat-checkbox>
      </span>
      <span class="d-flex-center">
        <img class="leadSvg" src="../../../../assets/lead.svg" alt="s" />
        {{ leadItem.fullName }}
        <!-- + " " + leadItem.regularUser.lastName --></span
      >
      <span class="email">{{ leadItem.email }}</span>
      <span class="tel"
        ><!-- <img
          style="position: relative; top: 3px"
          src="../../../assets/phone_blue.svg"
        /> -->
        {{ leadItem.phone }}</span
      >
      <!-- (click)="callLeadPhone(leadItem.phone)" -->
      <span class="source">{{ leadItem.leadSource }}</span>
      <span class="created">{{
        leadItem.createdDate | date: "dd-MM-yyyy"
      }}</span>
      <span class="updated">
        {{ leadItem.updateDate | date: "dd-MM-yyyy" }}</span
      >
      <span style="text-align: left">
        <span *ngIf="!canChangeAgent(leadItem.agent.agentId)"
          >{{ leadItem.agent.name }}
        </span>
        <app-autocomplete-agent
          *ngIf="canChangeAgent(leadItem.agent.agentId)"
          (selectedValue)="saveAgentForLead($event, leadItem)"
          [data]="agents"
          [initialValue]="getAgentNameById(leadItem.agent.agentId)"
          [widthExp]="150"
          [label]="'Agent'"
        ></app-autocomplete-agent>
      </span>
      <!-- <span>{{ leadItem.agent && leadItem.agent.name }}</span>-->
      <span>
        <span *ngIf="!showStatusFilter(leadItem.agent.agentId)">
          {{ leadItem.leadStatus && leadItem.leadStatus.name }}
        </span>
        <ng-container *ngIf="showStatusFilter(leadItem.agent.agentId)">
          <button
            *ngIf="leadStatuses"
            class="statuses-menu"
            mat-button
            [matMenuTriggerFor]="menu"
            [ngStyle]="{
              'background-color':
                leadItem.leadStatus && leadItem.leadStatus.color
            }"
            matTooltip="{{ getStatusName(leadStatuses, leadItem) }}"
            matTooltipPosition="above"
          >
            <div class="statuses-menu__text">
              <span class="name">
                {{ getStatusName(leadStatuses, leadItem) }}</span
              >
              <mat-icon>expand_more</mat-icon>
            </div>
          </button>
          <mat-menu overlayPanelClass="statuses-panel" #menu="matMenu">
            <ng-container *ngFor="let item of leadStatuses">
              <ng-container
                *ngIf="item.childerns && item.childerns.length == 0"
              >
                <button
                  mat-menu-item
                  (click)="saveStatusForLead(item, leadItem)"
                >
                  {{ item.name }}
                </button>
              </ng-container>
              <ng-container *ngIf="item.childerns && item.childerns.length > 0">
                <button mat-menu-item [matMenuTriggerFor]="childMenu">
                  {{ item.name }}
                </button>
                <mat-menu
                  overlayPanelClass="statuses-panel"
                  #childMenu="matMenu"
                  [overlapTrigger]="false"
                >
                  <button
                    mat-menu-item
                    (click)="saveStatusForLead(item, leadItem)"
                    *ngFor="let item of item.childerns"
                    class="lead-status lead-status{{ item.leadStatusId }}"
                    [ngStyle]="{
                      'background-color':
                        item.leadStatusId == leadItem.leadStatusId
                          ? item.color
                          : '',
                      color:
                        item.leadStatusId == leadItem.leadStatusId ? '#fff' : ''
                    }"
                  >
                    {{ item.name }}
                  </button>
                </mat-menu>
              </ng-container>
            </ng-container>
          </mat-menu>
        </ng-container>
        <!-- <mat-form-field
          *ngIf="canChangeAgentNoManager(leadItem.agent.agentId)"
          class="example-full-width round-select"
          style="width: 120px"
        >
          <mat-select
            (click)="stopPropegation($event)"
            class="lead-status-select"
            matNativeControl
            disableOptionCentering
            (selectionChange)="saveStatusForLead($event, leadItem)"
            [(ngModel)]="leadItem.leadStatusId"
            [ngStyle]="{
              'background-color':
                leadItem.leadStatus && leadItem.leadStatus.color
            }"
          >
            <mat-option
              *ngFor="let status of leadStatuses"
              [value]="status.leadStatusId"
              class="lead-status lead-status{{ status.leadStatusId }}"
              style="text-align: center"
            >
              {{ status.name }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </span>

      <!-- <span>{{ leadItem.clientType.name }}</span>-->
      <!--
      <span style="width: 20px; color: red;">
        <ng-container *ngIf="leadItem.isDuplicate">
          *
        </ng-container>
      </span>
      -->
      <span style="width: 50px">
        <mat-icon
          matBadge="{{ leadItem.numOpenMissions }}"
          [matBadgeHidden]="
            leadItem.numOpenMissions == 0 || !leadItem.numOpenMissions
          "
          matBadgeColor="warn"
          ><img src="../../../assets/noteBlue.svg"
        /></mat-icon>
      </span>
      <span style="width: 50px">
        <button
          *ngIf="showEditButton(leadItem.agent.agentId)"
          class="edit-btn"
          mat-button
          (click)="editLead($event, leadItem)"
        >
          <img src="../../../../assets/lead-edit.svg" /></button
      ></span>
    </mat-list-item>
  </mat-card>
</mat-list>
