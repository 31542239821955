<!-- <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <span *ngIf="child.childerns && child.childerns.length > 0">
      <button
        mat-menu-item
        color="primary"
        [matMenuTriggerFor]="menu.childMenu"
      >
        <span>{{ child.name }}</span>
      </button>
      <app-lead-status-item
        #menu
        [items]="child.childerns"
      ></app-lead-status-item>
    </span>
    <span *ngIf="!child.childerns || child.childerns.length === 0">
      <button mat-menu-item>
        <span>{{ child.name }}</span>
      </button>
    </span>
  </span>
</mat-menu> -->
<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item *ngFor="let item of items">
    {{ item.name }}
  </button>
</mat-menu>
