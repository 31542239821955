import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VersionHistory } from '../data/VersionHistory';

@Injectable({
  providedIn: 'root',
})
export class VersionHistroryService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<VersionHistory[]>(
      `${environment.baseUrl}VersionHistories`
    );
  }

  post(versionHistory: VersionHistory) {
    return this.http.post<VersionHistory>(
      `${environment.baseUrl}VersionHistories`,
      versionHistory
    );
  }
}
