import { Language } from './Language';
import { MeetingSetting } from './MeetingSetting';

export class MeetingSettingLang {
  meetingSettingLangId: number;
  meetingSettingId: number;
  meetingSetting: MeetingSetting;
  name: string;
  languageId: number;
  language: Language;
}
