import { Component, OnInit } from '@angular/core';
import { TubeTest } from 'src/app/data/TubeTest';
import { TubeTestsService } from 'src/app/Services/tube-tests.service';
import { TubeTestsPusherService } from 'src/app/Services/tube-tests-pusher.service';
import { TubesPackagesService } from 'src/app/Services/tubes-packages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { TubeTestEnum } from 'src/app/Enums/StatusesEnum';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { Status } from 'src/app/data/Status';

@Component({
  selector: 'app-edit-tube-package-lab',
  templateUrl: './edit-tube-package-lab.component.html',
  styleUrls: ['./edit-tube-package-lab.component.scss'],
})
export class EditTubePackageLabComponent implements OnInit {
  statuses: Status[];
  metaData: PaginationMetadata;
  packageTubes: TubeTest[];
  packageId: number;
  tubePackage: TubesPackage;
  barcodes: string[] = ['345435', '111555', '12468890'];
  newBarcode: string;
  filteredPackageTubes: TubeTest[];
  constructor(
    private route: ActivatedRoute,
    private tubeTestService: TubeTestsService,
    private tubeTestPusherService: TubeTestsPusherService,
    private tubesPackagesService: TubesPackagesService,
    private router: Router,
    private exelDownloadService: ExelDownloadService
  ) {}

  ngOnInit(): void {
    this.tubeTestPusherService.tubeTest$.subscribe((data) => {
      if (data.list) {
        this.packageTubes = data.list;
        this.filteredPackageTubes = this.packageTubes;
        this.newBarcode = '';
        this.metaData = data.metaData;
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.packageId = +params['packageId'];

      this.tubesPackagesService
        .getById(this.packageId)
        .subscribe((tubePackage) => {
          this.tubePackage = tubePackage;
        });

      this.tubeTestPusherService.getAll(this.packageId);
      this.tubeTestService
        .getBarcodesAutocompleteForLab(this.packageId)
        .subscribe((data) => (this.barcodes = data));
    });
  }
  searchTube(event = null) {
    this.filteredPackageTubes = this.packageTubes.filter(
      (x) => x.barcode.indexOf(this.newBarcode) != -1
    );
    //  this.tubeTestPusherService.addTube(this.packageId, this.newBarcode);
  }
  uploadResultsFile() {}
  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {}
  backToAll() {
    this.router.navigate(['/labDeliveries/lab']);
  }
  showReport() {
    this.router.navigate(['/labDeliveries/lab/report'], {
      queryParams: { packageId: this.packageId },
    });
  }
  exportToExel() {
    var url = this.exelDownloadService.getTubePackageById(this.packageId);
    window.open(url, '_blank');
  }
}
