<p>
  <button mat-raised-button (click)="saveDataSource()">Save DataSource</button>
</p>
<div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 4rem">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
    >
      <button mat-icon-button disabled></button>
      <mat-card
        class="checklist-leaf-node"
        [ngClass]="{
          'drop-above':
            dragNodeExpandOverArea === 'above' &&
            dragNodeExpandOverNode === node,
          'drop-below':
            dragNodeExpandOverArea === 'below' &&
            dragNodeExpandOverNode === node,
          'drop-center':
            dragNodeExpandOverArea === 'center' &&
            dragNodeExpandOverNode === node
        }"
        [checked]="checklistSelection.isSelected(node)"
        (change)="checklistSelection.toggle(node)"
        draggable="true"
        (dragstart)="handleDragStart($event, node)"
        (dragover)="handleDragOver($event, node)"
        (drop)="handleDrop($event, node)"
        (dragend)="handleDragEnd($event)"
      >
        {{ node.item }}
        <div>
          <div>
            answer:
            {{ getNodeAnswer(node) }}
          </div>
          <div>
            action:
            {{ getNodeAction(node) }}
          </div>
          <div>
            next question:
            {{ getNodeNextQuest(node) }}
          </div>
        </div>
        <button>Edit</button>
      </mat-card>
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: falseFunc"
      matTreeNodePadding
    >
      <!--let node; when: hasNoContent-->
      <button mat-icon-button disabled></button>
      <mat-form-field>
        <input matInput #itemValue />
        <!-- placeholder="New item..."-->
      </mat-form-field>
      <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: trueFunc"
      matTreeNodePadding
      [style.margin-left]="!node.expandable ? '40px' : '0'"
    >
      <!--let node; when: hasChild-->
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.filename"
        *ngIf="node.expandable"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-card
        [ngClass]="{
          'drop-above':
            dragNodeExpandOverArea === 'above' &&
            dragNodeExpandOverNode === node,
          'drop-below':
            dragNodeExpandOverArea === 'below' &&
            dragNodeExpandOverNode === node,
          'drop-center':
            dragNodeExpandOverArea === 'center' &&
            dragNodeExpandOverNode === node
        }"
        draggable="true"
        (dragstart)="handleDragStart($event, node)"
        (dragover)="handleDragOver($event, node)"
        (drop)="handleDrop($event, node)"
        (dragend)="handleDragEnd($event)"
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="todoItemSelectionToggle(node)"
        [ngClass]="getlevelClass(node.level)"
      >
        <!-- {{ node.item }} -->
        <ng-container
          *ngIf="
            nodeIdToEdit == node.item &&
              nodeEditMode &&
              this.treeObjects[node.item];
            else viewMode
          "
        >
          <div>
            <mat-form-field appearance="fill">
              <mat-label>answer:</mat-label>
              <textarea
                matInput
                [(ngModel)]="
                  treeObjects[node.item] && treeObjects[node.item].answerTxt
                "
              ></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>action:</mat-label>
              <mat-select
                [(ngModel)]="
                  treeObjects[node.item] && treeObjects[node.item].actionId
                "
              >
                <mat-option
                  *ngFor="let action of actions"
                  [value]="action.statusId"
                >
                  {{ action.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>next Question:</mat-label>
              <textarea
                [(ngModel)]="
                  treeObjects[node.item] &&
                  treeObjects[node.item].nextQuestionTxt
                "
                matInput
              ></textarea>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-template #viewMode>
          <div>
            answer:
            {{ getNodeAnswer(node) }}
          </div>
          <div>
            action:
            {{ getNodeAction(node) }}
          </div>
          <div>
            next question:
            {{ getNodeNextQuest(node) }}
          </div>
        </ng-template>

        <ng-container
          *ngIf="nodeIdToEdit == node.item && nodeEditMode; else viewMode1"
        >
          <button mat-icon-button>
            <mat-icon (click)="this.nodeEditMode = false">done</mat-icon>
          </button>

          <!-- <button mat-icon-button>
            <mat-icon (click)="nodeEditMode = false">close</mat-icon>
          </button> -->
        </ng-container>
        <ng-template #viewMode1
          ><button mat-icon-button>
            <mat-icon (click)="nodeIdToEdit = node.item; nodeEditMode = true"
              >edit</mat-icon
            ></button
          ><button
            (click)="deleteNodeItem(node)"
            *ngIf="node.level != 0"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button></ng-template
        >
      </mat-card>
      <button mat-icon-button (click)="addNewItem(node)">
        <mat-icon>add</mat-icon>
      </button>
    </mat-tree-node>
  </mat-tree>
  <app-preview-chat
    *ngIf="serviceRequest"
    [activeRequest]="serviceRequest"
  ></app-preview-chat>
</div>
<span #emptyItem></span>
