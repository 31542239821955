import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { FireBaseCall } from 'src/app/data/FireBaseCall';
import { FireBaseCallDocument } from 'src/app/data/FireBaseCallDocument';
import { RegularUser } from 'src/app/data/RegularUser';
import { ProductTypeEnum } from 'src/app/Enums/StatusesEnum';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-call-income',
  templateUrl: './call-income.component.html',
  styleUrls: ['./call-income.component.scss'],
})
export class CallIncomeComponent implements OnInit {
  isMinimize: boolean;
  callsArray: FireBaseCall;
  call: FireBaseCallDocument;
  queryParamKey: string;
  queryParamValue: number;
  currentUser: RegularUser;
  namesList: any[];
  selectedCallIDNumber: string;
  constructor(
    public dialogRef: MatDialogRef<CallIncomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private regualarUserService: RegularUsersService,
    private route: ActivatedRoute
  ) {
    //this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.callsArray = this.data;

    this.route.queryParams.subscribe((params) => {
      if (params['clientId']) {
        var clientId = +params['clientId'];
        this.call = this.callsArray.Documents.find(
          (x) => x.ClientId == clientId
        );
        if (!this.call) {
          this.call = this.callsArray.Documents[0];
        }
      } else if (params['productId']) {
        var productId = +params['productId'];
        this.call = this.callsArray.Documents.find(
          (x) => x.ProductId == productId
        );
        if (!this.call) {
          this.call = this.callsArray.Documents[0];
        }
      } else {
        this.call = this.callsArray.Documents[0];
      }
    });

    this.selectedCallIDNumber = this.call?.IDNumber;
    this.regualarUserService.currentUser.subscribe((data) => {
      if (data) {
        this.currentUser = data;
      }
    });
  }

  onCallChange(IDNumber: string) {
    this.call = [...this.callsArray.Documents].find(
      (x) => x.IDNumber === IDNumber
    );
  }

  clientType() {
    if (this.call.Status === 'Active' || this.call.Status === 'Not Active') {
      return 'Client';
    } else if (this.call.ProductId) {
      return 'Client';
    } else {
      return 'Lead';
    }
  }

  isProduct() {
    if (
      this.call.ProductId &&
      (!this.call.ClientId || this.call.ClientId == 0)
    ) {
      return true;
    } else if (!this.call.ProductId && this.call.ClientId) {
      return false;
    }
  }

  minimizeToggle() {
    this.isMinimize = !this.isMinimize;
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const position = !this.isMinimize ? '' : '5px';
    matDialogConfig.position = { left: position };
    //matDialogConfig.height = !this.isMinimize ? '400px' : '50px';
    matDialogConfig.width = !this.isMinimize ? '600px' : '55px';

    this.dialogRef.updatePosition(matDialogConfig.position);
    this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
  }

  close() {
    this.dialogRef.close({ event: 'close', data: {} });
  }

  /*  showByPermission() {
    if (
      this.call.AgentId === 1 ||
      !this.currentUser.agent.agentId ||
      this.currentUser.agent.agentId == this.call.AgentId ||
      this.currentUser.agent.agentId == 0 ||
      this.currentUser.roleTypeId == RoleTypeEmum.Manager ||
      this.currentUser.roleTypeId == RoleTypeEmum.AgentsManager ||
      this.currentUser.roleTypeId == RoleTypeEmum.Support ||
      this.currentUser.roleTypeId == RoleTypeEmum.SupportManager ||
      this.currentUser.roleTypeId == RoleTypeEmum.nutritionistManager
    ) {
      return true;
    }
    return false;
  } */
  /* activateLink() {
    if (
      this.call.CallBackType == PayCallCallbackStatus.Answer &&
      this.showByPermission()
    ) {
      return true;
    }
    return false;
  } */
  /* linkPath() {
    if (this.call.ProductId) {
      if (this.call.CallType == ExtensionGroupType.Sales) {
        return `/clients/edit`;
      } else {
        return `/tests/edit`;
      }
    } else if (!this.call.ProductId && this.call.ClientId) {
      if (this.call.Status === 'Active' || this.call.Status === 'Not Active') {
        return `/clients/edit`;
      } else {
        return `/leads/edit`;
      }
    }
  } */
  /* queryParams() {
    if (this.call.ProductId && this.call.ClientId == 0) {
      this.queryParamKey = 'productId';
      this.queryParamValue = this.call.ProductId;
    } else {
      this.queryParamKey = 'clientId';
      this.queryParamValue = this.call.ClientId;
    }
  } */
}
