<div class="main-template-wrapper" *ngIf="templateForm">
  <div class="main-title-underline">
    <span>Send info</span>
    <button
      mat-button
      class="btn btn-fill"
      *ngIf="!editMode && hasPermission()"
      (click)="createNewTemplate()"
    >
      <mat-icon>control_point</mat-icon>
      Create new template
    </button>
  </div>
  <!--(selectionChange)="getTemplateLangs()"-->
  <div *ngIf="patient" class="d-grid">
    <form class="template" [formGroup]="templateForm">
      <div class="choose-template" *ngIf="!editMode">
        <div class="title">Choose template</div>
        <mat-form-field class="select-template" appearance="fill">
          <mat-label>template name</mat-label>
          <mat-select
            [formControl]="SelectedTemplateId"
            (selectionChange)="onSelectTemplate($event)"
            [disableOptionCentering]="true"
            #selectTemplate
          >
            <mat-option
              *ngFor="let template of emailTemplates"
              [value]="template.emailTemplateId"
              >{{ template.name }}
              <ng-container *ngIf="selectTemplate.panelOpen && hasPermission()">
                <button
                  class="delete-btn"
                  (click)="onDeleteTemplate($event, template.emailTemplateId)"
                  mat-icon-button
                  type="button"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
                <button
                  class="edit-btn"
                  (click)="onEditTemplate($event, template.emailTemplateId)"
                  mat-icon-button
                >
                  <mat-icon>border_color</mat-icon>
                </button>
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="editMode">
        <div class="title">Template name</div>
        <mat-form-field class="w-100" appearance="fill">
          <input matInput [formControl]="TemplateName" />
        </mat-form-field>
        <div
          class="errors"
          *ngIf="showFormErrors && TemplateName.errors?.required"
        >
          Template name is required
        </div>
      </div>
      <div class="mb-2"></div>
      <ng-container *ngIf="SelectedTemplateId.value || isNewTemplate">
        <div class="d-flex">
          <div class="title pb-0">Send by:</div>
          <div class="ml-2">
            <mat-checkbox
              [disabled]="editMode"
              [formControl]="SendSms"
              class="example-margin"
              >SMS</mat-checkbox
            >
          </div>
          <div class="ml-2">
            <mat-checkbox
              [disabled]="editMode"
              [formControl]="SendEmail"
              class="example-margin"
              >Email</mat-checkbox
            >
          </div>
        </div>
        <div class="errors" *ngIf="errors?.smsOrMail">
          {{ errors.smsOrMail }}
        </div>

        <div class="mb-3"></div>
        <div class="phone-number">
          <div class="title">Phone number</div>
          <mat-radio-group
            [formControl]="SelectedNumberMethod"
            aria-label="Select an option"
            class="d-flex"
            [disabled]="(SendEmail.value && !SendSms.value) || editMode"
          >
            <mat-radio-button value="list" class="flex-1"
              ><mat-form-field
                [disabled]="editMode"
                class="w-100"
                appearance="fill"
                *ngIf="!patientsPhones"
              >
                <mat-label>phone number</mat-label>
                <input
                  matInput
                  [formControl]="PhoneNumber"
                  [disabled]="editMode"
                />
              </mat-form-field>
              <mat-form-field
                class="w-100"
                appearance="fill"
                *ngIf="patientsPhones"
              >
                <mat-label>Choose from list</mat-label>
                <mat-select
                  [disabled]="
                    SelectedNumberMethod.value !== 'list' || !SendSms.value
                  "
                  [formControl]="SelectedPhoneNumber"
                >
                  <mat-optgroup label="Client phone">
                    <mat-option [value]="getUserPhone()">
                      {{
                        patient.regularUser.firstName + " - " + getUserPhone()
                      }}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup label="Patient phones">
                    <mat-option
                      *ngFor="let patient of patientsPhones"
                      [value]="patient.phone"
                    >
                      {{ patient.name + " - " + patient.phone }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </mat-radio-button>
            <mat-radio-button class="ml-2 flex-1" value="text">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>alternate phone number</mat-label>
                <input
                  matInput
                  [disabled]="
                    SelectedNumberMethod.value === 'list' ||
                    (SendEmail.value && !SendSms.value)
                  "
                  [formControl]="AlternatePhoneNumber"
                />
              </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
          <div class="errors" *ngIf="errors?.alternatePhoneNumber">
            {{ errors.alternatePhoneNumber }}
          </div>
          <div
            class="errors"
            *ngIf="showFormErrors && SelectedNumberMethod.errors?.required"
          >
            Please choose one from these options!
          </div>
        </div>
        <div class="mb-2"></div>

        <div class="lang-tabs">
          <div
            *ngFor="let lang of languages"
            (click)="changeTemplateLang(lang.languageId)"
            [class.active]="languageId == lang.languageId"
          >
            {{ lang.name }}
            <span class="lang-bullet" *ngIf="unFieldText(lang.languageId)"
              >&bull;</span
            >
          </div>
        </div>
        <div class="mb-2"></div>
        <div
          formArrayName="textLangs"
          *ngFor="
            let lang of templateForm.get('textLangs')['controls'];
            let i = index
          "
        >
          <div
            [formGroupName]="i"
            *ngIf="lang['controls'].langId.value == languageId"
          >
            <div>
              <div class="title">
                Subject&nbsp;&nbsp;
                <small
                  >* In the SMS subject will not appear in the message
                  content</small
                >
              </div>
              <mat-form-field appearance="fill" class="w-100">
                <input
                  matInput
                  placeholder="subject"
                  formControlName="subject"
                />
              </mat-form-field>
            </div>
            <div class="mb-2"></div>
            <div class="message-content">
              <div class="title">Message content</div>
              <mat-form-field appearance="fill" class="w-100">
                <textarea
                  matTextareaAutosize
                  matAutosizeMinRows="8"
                  matInput
                  #textEmailField
                  formControlName="textEmail"
                  class="message-field"
                  [dir]="isRtl()"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div
          class="errors"
          style="margin-bottom: 0.5rem"
          *ngIf="showFormErrors && errors?.textLang"
        >
          {{ errors.textLang }}
        </div>
        <!--   <app-send
          *ngIf="!editMode"
          [langId]="langId"
          [emailTemplateLangs]="emailTemplate.emailTemplateLangs"
        ></app-send>
        <app-edit *ngIf="editMode"></app-edit> -->
      </ng-container>
    </form>

    <div
      class="legend"
      *ngIf="(SelectedTemplateId.value && editMode) || isNewTemplate"
    >
      <div class="title">Tags</div>
      <div class="legend-box">
        <button
          *ngFor="let legend of legends"
          (click)="setLegendInText(legend)"
          mat-button
        >
          {{ legend }}</button
        >&nbsp;
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="SelectedTemplateId.value || isNewTemplate">
      <button
        mat-button
        (click)="send()"
        *ngIf="!editMode"
        class="btn btn-fill mr-2"
      >
        Send
      </button>
      <button
        mat-button
        (click)="saveTemplate()"
        *ngIf="editMode && hasPermission()"
        class="btn btn-fill mr-2"
      >
        Save template
      </button>
    </ng-container>

    <button mat-button (click)="close()" class="btn btn-outline">cancel</button>
  </div>
</div>
