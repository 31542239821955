import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { GoalToPatient } from 'src/app/data/GoalToPatient';
import { Product } from 'src/app/data/Product';
import { ProductPatientDetails } from 'src/app/data/ProductPatientDetails';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { UserGoal } from 'src/app/data/UserGoal';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import {
  ProductEnum,
  ProductTypeEnum,
  UserRoleEmum,
} from 'src/app/Enums/StatusesEnum';
import { AgentsService } from 'src/app/Services/agents.service';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { userHasPermisions } from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-test-general-information',
  templateUrl: './test-general-information.component.html',
  styleUrls: ['./test-general-information.component.scss'],
})
export class TestGeneralInformationComponent implements OnInit {
  @Input() product: Product;
  @Input() agentUser: RegularUser;
  @Input() statuses: Status[];
  @Output('reloadProduct')
  reloadProduct: EventEmitter<any> = new EventEmitter();
  nutrisionists: AgentMin[];
  followUpAgents: AgentMin[];
  passwordEditMode: boolean = false;
  newPassword;
  newPasswordError;
  userGoals: UserGoal[];
  userGoalsModel: number[] = [];
  userGoalsModelChange: boolean;
  productPatientDetails: ProductPatientDetails;
  chatExpiredDate: string;
  fgExpiredDate: string;
  minDateExpireChat = new Date();
  ProductTypeEnum = ProductTypeEnum;
  constructor(
    private productsService: ProductsService,
    private popupHandlerService: PopupHandlerService,
    private agentsService: AgentsService,
    private regularUsersService: RegularUsersService,
    private exelDownloadService: ExelDownloadService,
    private algorithmService: AlgorithmService,
    private _matSnackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.getNutriAgents();
    this.getFollowUpAgents();
    this.getUserGoals();
    this.getProductDetails();
  }

  userHasPermission(permissions: string[]) {
    return userHasPermisions(permissions, this.agentUser);
  }
  recalculateByBarcode() {
    this.popupHandlerService.openConfirmationDialog('', 'Reacalculate results');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ans === 'yes') {
          this.algorithmService
            .RecalculateResultsByBarcode(this.product.barCode)
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._matSnackBar
                  .open('Error: ' + (data as ErrorMessage).message, 'Close', {})
                  ._dismissAfter(4000);
              } else {
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.success
                );
              }
            });
        }
      });
  }
  isStatusCompleted() {
    return this.product.status.statusId === ProductEnum.Completed;
  }
  getProductDetails() {
    this.productsService
      .getExpireDate(this.product.productId)
      .subscribe((data) => {
        this.productPatientDetails = data;
        // const currentYear = moment().year();
        // this.minDateExpireChat = moment([currentYear - 1, 0, 1]);

        this.chatExpiredDate = {
          ...this.productPatientDetails,
        }.chatExpiredDate;
        this.fgExpiredDate = {
          ...this.productPatientDetails,
        }.fgExpiredDate;
      });
  }
  getNutriAgents() {
    this.agentsService.getAll().subscribe((res) => {
      if (res) {
        this.nutrisionists = res.filter(
          (x) =>
            x.roleTypeId == UserRoleEmum.nutritionist ||
            x.roleTypeId == UserRoleEmum.nutritionistManager
        );
      }
    });
  }
  getFollowUpAgents() {
    this.agentsService.getAll().subscribe((res) => {
      if (res) {
        this.followUpAgents = res.filter(
          (x) => x.roleTypeId == UserRoleEmum.followUpAgent
        );
      }
    });
  }
  saveStatusForProduct(event, product: Product) {
    product.statusId = Number(event.value);
    product.status = this.statuses.find((x) => {
      return x.statusId == event.value;
    });

    setTimeout(() => {
      this.productsService
        .changeStatus(product.productId, product.statusId)
        .subscribe((obj) => {
          if (obj.isProblems) {
            var errorsHtml =
              "status wasn't Changed:<br/>" +
              obj.list.map((x) => x.name + '<br>').join();
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.errorFromServer,
              '',
              '',
              errorsHtml,
              4000
            );
            product.statusId = obj.oldStatusId;
            product.status = this.statuses.find((x) => {
              return x.statusId == obj.oldStatusId;
            });
          } else {
            if (obj.actionsList.length > 0) {
              this.popupHandlerService.openPerformStatusChangeDialog(
                obj.actionsList,
                product.productId
              );
            } else {
              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.status
              );
            }
          }
        });
    });
  }
  saveNutritionistForProduct(event) {
    if (!this.product) return;

    this.product.agentId = event;

    setTimeout(() => {
      this.productsService
        .changeAgent(this.product.productId, this.product.agentId)
        .subscribe((res) => {
          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.agentSaved
          );
        });
    }, 10);
  }
  changeOnFollow($event: MatCheckboxChange) {
    this.productsService
      .changeOnFollow(this.product.productId, $event.checked)
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.success
        );
      });
  }
  changeOnFirstCall($event: MatCheckboxChange) {
    this.productsService
      .changeFirstCall(this.product.productId, $event.checked)
      .subscribe((res) => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.success
        );
      });
  }
  changeChatExpiredDate() {
    const date = moment(this.chatExpiredDate).format('YYYY-MM-DD');

    this.popupHandlerService.openConfirmationDialog('', 'Change chat date');
    this.popupHandlerService.confirmDialogSubject.subscribe((res) => {
      if (res.ans === 'yes') {
        this.productPatientDetails.chatExpiredDate = date;
        this.productsService
          .updateExpireDate(this.productPatientDetails)
          .subscribe((res) => {
            if ((res as ErrorMessage)?.message) {
              this._matSnackBar
                .open('Error: ' + (res as ErrorMessage).message, 'Close', {})
                ._dismissAfter(4000);
            } else {
              this._matSnackBar
                .open('Success', 'Close', {})
                ._dismissAfter(4000);
              this.productsService.loadProduct$.next(true);
            }
          });
      } else {
        this.chatExpiredDate = this.productPatientDetails.chatExpiredDate;
      }
    });
  }

  changeFoodGenesExpiredDate() {
    const date = moment(this.fgExpiredDate).format('YYYY-MM-DD');
    if (
      this.agentUser.userRoleId != 6 &&
      this.agentUser.userRoleId != 8 &&
      this.agentUser.userRoleId != 10 &&
      this.agentUser.userRoleId != 5 &&
      this.agentUser.userRoleId != 19
    ) {
      return;
    }
    this.popupHandlerService.openConfirmationDialog(
      '',
      'Change food genes date'
    );
    this.popupHandlerService.confirmDialogSubject.subscribe((res) => {
      if (res.ans === 'yes') {
        this.productPatientDetails.fgExpiredDate = date;
        this.productsService
          .updateExpireDate(this.productPatientDetails)
          .subscribe((res) => {
            if ((res as ErrorMessage)?.message) {
              this._matSnackBar
                .open('Error: ' + (res as ErrorMessage).message, 'Close', {})
                ._dismissAfter(4000);
            } else {
              this._matSnackBar
                .open('Success', 'Close', {})
                ._dismissAfter(4000);
              this.productsService.loadProduct$.next(true);
            }
          });
      } else {
        this.fgExpiredDate = this.productPatientDetails.fgExpiredDate;
      }
    });
  }
  saveAgent2ForProduct(event) {
    if (!this.product) return;
    this.product.agent2Id = event;
    //lead.agent = null;

    setTimeout(() => {
      this.productsService
        .changeAgent2(this.product.productId, this.product.agent2Id)
        .subscribe((res) => {});
      this.popupHandlerService.openDisapearingAlertDialog(
        DisapearingTextDialogEnum.agentSaved
      );
    }, 10);
  }
  getAgentNameById(agentId: number) {
    return this.nutrisionists?.find((a) => a.agentId == agentId)?.name;
  }
  getFollowUpAgentNameById(agentId) {
    return this.followUpAgents?.find((a) => a.agentId === agentId)?.name;
  }
  setUrgent() {
    this.product.isUrgentTest = !this.product.isUrgentTest;
    this.productsService.put(this.product).subscribe((data) => {});
  }
  changePasswordHack() {
    if (!this.newPassword) {
      this.newPasswordError = true;
      return;
    }
    if (!this.is8Length() || !this.hasLetters() || !this.hasNumbers()) {
      this.newPasswordError = true;
      return;
    }

    if (this.newPassword) {
      this.regularUsersService
        .resetPasswordHackV2(
          this.product.patient.regularUserId,
          this.newPassword
        )
        .subscribe(() => {
          this.newPassword = '';
          this.passwordEditMode = false;
          this.reloadProduct.emit(true);
          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.newPassword
          );
        });
    }
  }
  isSignedConcent() {
    return this.product.patient.isSignedConcent;
  }
  is8Length() {
    return this.newPassword && this.newPassword.length >= 8;
  }
  hasLetters() {
    return (
      this.newPassword &&
      /[a-z]/.test(this.newPassword) &&
      /[A-Z]/.test(this.newPassword)
    );
  }
  hasNumbers() {
    return this.newPassword && /[0-9]/.test(this.newPassword);
  }
  getNutritionist() {
    if (!this.product.agentId || !this.product.agent) return 'Not Assigned';
    return this.product.agent.regularUser.firstName;
  }
  togglePassEditMode() {
    return (this.passwordEditMode = !this.passwordEditMode);
  }
  textToggleBtn() {
    return this.passwordEditMode ? 'Cancel' : 'Edit';
  }
  openBarcodeHistory() {
    this.popupHandlerService.openBarcodeHistoryDialog(
      this.product.productId.toString(),
      this.product.status
    );
  }
  getTubePackageId() {
    if (this.product.tubeTest && this.product.tubeTest.tubesPackageId) {
      return this.product.tubeTest.tubesPackageId;
    }
    return 'Not Set';
  }
  onDownloadContract() {
    var url = this.exelDownloadService.getPatientContract(
      +this.product.patientId,
      1
    );
    window.open(url, '_blank');

    var url2 = this.exelDownloadService.getPatientContract(
      +this.product.patientId,
      2
    );
    setTimeout(() => {
      window.open(url2, '_blank');
    }, 1000);

    var url3 = this.exelDownloadService.getPatientContract(
      +this.product.patientId,
      3
    );
    setTimeout(() => {
      window.open(url3, '_blank');
    }, 1500);
  }
  openChangeBarcodeDialog() {
    this.popupHandlerService.openChangeBarcodeDialog(this.product.barCode);

    this.popupHandlerService.changeBracodeSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.event === 'save') {
          var barcode = data.barcode;
          this.productsService
            .changeBarcodeV2(this.product.productId, data.barcode)
            .subscribe((data) => {
              if (data && data.message) {
                var errorMessage: ErrorMessage = data as ErrorMessage;
                this.popupHandlerService.openDisapearingAlertDialog(
                  'errorFromServer',
                  '',
                  '',
                  errorMessage.message
                );
              } else {
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.barcodeChanged
                );
                this.product.barCode = barcode;
              }
            });
        }
      });
  }
  consentFileExist(inx) {
    switch (+inx) {
      case 1:
        return this.product.consent1;
      case 2:
        return this.product.consent2;
      case 3:
        return this.product.consent3;
    }
  }
  downloadConsentFile(inx) {
    ///todo: asuming file was saved seccuesfully.check if saved.
    var url = this.productsService.getDownloadConsentUrl(
      this.product.patientId,
      inx
    );
    window.open(url);
    /*
    this.productsService
      .downloadConsent(this.product.patientId, inx)
      .subscribe((data) => {
       
    
        //  savefromBlob(data.buffer, data.fileName);

        switch (+inx) {
          case 1:
            this.product.consent1 = true;
            break;
          case 2:
            this.product.consent2 = true;
            break;
          case 3:
            this.product.consent3 = true;
            break;
        }
      });
      */
  }
  getConsentUploadPath(inx) {
    return this.productsService.getConsentUploadPath(
      this.product.patientId,
      inx
    );
  }
  uploadFileFinished(event) {}
  onSignedAllPapersClicked() {
    if (!this.product.patient.isSignedConcent) {
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((data) => {
          if (data.ans === 'yes') {
            this.productsService
              .setSignedConcent(this.product.patientId, true)
              .subscribe((response) => {
                if ((response as ErrorMessage).message) {
                  this.product.patient.isSignedConcent = false;
                  this.popupHandlerService.openDisapearingAlertDialog(
                    DisapearingTextDialogEnum.errorFromServer,
                    '',
                    '',
                    (response as ErrorMessage).message
                  );
                }
              });
          } else {
            this.product.patient.isSignedConcent = false;
          }
        });
      this.popupHandlerService.openConfirmationDialog(
        '',
        'I checked that all the signed papers of the client are on the server'
      );
    } else {
      this.popupHandlerService.confirmDialogSubject
        .pipe(take(1))
        .subscribe((data) => {
          if (data.ans === 'yes') {
            this.productsService
              .setSignedConcent(this.product.patientId, false)
              .subscribe((x) => {});
          } else {
            this.product.patient.isSignedConcent = true;
          }
        });
      this.popupHandlerService.openConfirmationDialog(
        '',
        'return Patient To Sign Consent Page'
      );
    }
  }
  stopPropegation() {}
  getUserGoals() {
    this.productsService.getUserGoals().subscribe((data) => {
      this.userGoals = data;
      if (!this.product.patient.goalToPatient) {
        this.product.patient.goalToPatient = [];
      }

      this.product.patient.goalToPatient.map((x) => {
        this.userGoalsModel.push(x.goalIdId);
      });
    });
  }

  removeDuplicatesById(arr: GoalToPatient[]) {
    var obj = {};
    var result = [];
    for (var i = 0; i < arr.length; i++) {
      if (!obj[arr[i].goalIdId]) {
        obj[arr[i].goalIdId] = true;
        result.push(arr[i]);
      }
    }
    return result;
  }

  onClientGoalsChange(event: MatSelectChange) {
    const values = event.value;
    this.product.patient.goalToPatient = [];
    values.map((x) => {
      const goalToPatient = new GoalToPatient();
      goalToPatient.goalIdId = x;
      goalToPatient.patientToGoalId = 0;
      goalToPatient.patientId = this.product.patientId;
      this.product.patient.goalToPatient.push(goalToPatient);
    });
  }
  saveGoals() {
    this.product.patient.goalToPatient = this.removeDuplicatesById(
      this.product.patient.goalToPatient
    );
    this.productsService
      .setUserGoals(this.product.patient.goalToPatient, this.product.productId)
      .subscribe((data) => {
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.success
        );
      });
  }
}
