<div class="menu">
  <mat-card class="menu__list">
    <ng-container *ngIf="editMode == false">
      <div class="sticky-top">
        <div class="d-flex-between">
          <span class="title">Menu Template List</span>
          <button mat-button class="btn btn-fill" (click)="onCreateNewMenu()">
            Create New Menu Template
          </button>
        </div>
        <hr class="hr pink" />
      </div>

      <app-menu-list-view
        [isTemplate]="isTemplate"
        *ngIf="menuList || isLoading"
        [isLoading]="isLoading"
        [menuList]="menuList"
        (menuIdForEdit)="getMenuForEdit($event)"
        (getMenuList)="getMenuList()"
        (editMode)="editMode = $event"
      ></app-menu-list-view>
    </ng-container>

    <ng-container *ngIf="editMode == true">
      <div class="sticky-top">
        <div class="d-flex-between">
          <span class="title">Create Menu Template</span>
          <button mat-button class="btn btn-fill" (click)="backToMenus()">
            Back to template menus
          </button>
        </div>
        <hr class="hr pink" />
      </div>
      <app-menu-list-edit
        *ngIf="(menuId || createNewMenu) && mealTypes"
        [isTemplate]="isTemplate"
        [menuId]="menuId"
        [mealTypes]="mealTypes"
        [mealChanged]="mealChanged"
        [createNewFromGroup]="createNewMenu"
        (getMenu)="getMenuForEdit($event)"
        (mealIdForEdit)="editMeal($event)"
        (editModeBullets)="editModeBulletsToggle($event)"
      ></app-menu-list-edit>
    </ng-container>
  </mat-card>

  <mat-card class="menu__details" *ngIf="editMode && editModeBullets">
    <app-menu-bullets
      *ngIf="languages && mealId && mealTypes"
      [mealTypes]="mealTypes"
      [isTemplate]="isTemplate"
      [mealId]="mealId"
      (getMeal)="editMeal($event)"
      [languages]="languages"
      (mealChanged)="hasMealChenged($event)"
      (getMenuForEdit)="getMenuForEdit($event)"
    ></app-menu-bullets>
  </mat-card>
</div>
