import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Status } from '../data/Status';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StatusesService {
  statuses: Status[];
  constructor(private http: HttpClient) {}
  getAll() {
    if (this.statuses) {
      return of(this.statuses); // return from cache
    } else {
      return this.http
        .get<Status[]>(`${environment.baseUrl}Statuses/`)
        .pipe(map((res) => (this.statuses = res)));
    }
    // return this.http.get<Status[]>(`${environment.baseUrl}Statuses/`);
  }
  /*
  getById(agentId) {
    return this.http.get<Status>(`${environment.baseUrl}Statuses/${agentId}`);
  }
  put(agent) {
    return this.http.put(
      `${environment.baseUrl}Statuses/${agent.agentId}`,
      agent
    );
  }
  post(agent) {
    return this.http.post<Status>(`${environment.baseUrl}Statuses/`, agent);
  }
  delete(agent) {
    return this.http.delete(`${environment.baseUrl}Statuses/${agent.agentId}`);
  }
  */
}
