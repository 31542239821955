<div dir="rtl">
  <mat-form-field
    appearance="fill"
    [matMenuTriggerFor]="valueComplete"
    [style.width.px]="widthExp"
    class="main-field mat-compelete"
    (menuOpened)="focusInput()"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      style="cursor: pointer"
      class="input-compelete"
      type="text"
      matInput
      #value
      disabled
      [(ngModel)]="initialValue"
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="open"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu
    #valueComplete="matMenu"
    xPosition="before"
    yPosition="below"
    class="menu-complete"
    [style.min-width.px]="widthExp"
  >
    <ng-container>
      <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <mat-label>Type something...</mat-label>
          <input
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div>

      <button
        *ngFor="let value of data"
        class="menu-complete__item"
        mat-button
        (click)="onValueSelected(value)"
        type="button"
      >
        {{ value }}
      </button>
    </ng-container>
    <!-- <ng-template #noData>
      <div style="text-align: center">
        <container-element [ngSwitch]="label">
          <some-element *ngSwitchCase="'Street'"
            >Please Choose City</some-element
          >
          <some-element *ngSwitchDefault>No Data</some-element>
        </container-element>
      </div>
    </ng-template> -->
  </mat-menu>
</div>
