export class ExternalDeal {
  clientId: number;
  creditCardNumber: string;
  cvv: string;
  expireDate: string;
  idNum: string;
  city: string;
  street: string;
  streetNum: string;
  entrance: string;
  apartment: string;
  productTypeId: string;
  npay: string;
  shipNotes: string;
}
