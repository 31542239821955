<div class="weight-update">
  <div class="title">Weight update</div>
  <div class="mb-2"></div>
  <div class="d-grid-2">
    <div class="label">Updated:</div>
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [(ngModel)]="date" [matDatepicker]="dp3" disabled />
      <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
      <mat-datepicker #dp3 disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="mb-2"></div>
  <div class="d-grid-2">
    <div class="label">New weight:</div>
    <mat-form-field appearance="fill">
      <input required type="number" matInput [(ngModel)]="weight" />
    </mat-form-field>
  </div>

  <div class="mb-2"></div>
  <div class="d-flex text-center">
    <button class="btn btn-fill mr-2" (click)="save()" mat-button>Save</button>
    <button class="btn btn-outline" (click)="close()" mat-button>Cancel</button>
  </div>
  <div style="color: red; text-align: center" *ngIf="errorMsg">
    {{ errorMsg }}
  </div>
</div>
