<div class="wrapper edit-template">
  <a class="back" [routerLink]="['/algo-bullet-links']">< Back to templates</a>
  <div class="d-grid-2" *ngIf="algoBulletLink">
    <mat-card class="card-template">
      <div class="card-template__body">
        <div class="mb-2"></div>
        <div class="email">
          <div class="mb-2"></div>
          <div>
            <div class="title">Link name</div>
            <mat-form-field appearance="fill" class="w-100">
              <input
                matInput
                placeholder="name"
                [(ngModel)]="algoBulletLink.algoBulletLinkLangs[0].title"
              />
            </mat-form-field>
          </div>
          <div class="mb-2"></div>
          <div dir="rtl" class="d-flex">
            <div class="editor-field">
              <div class="title">Message content</div>
              <angular-editor
                [config]="editorConfig"
                [(ngModel)]="algoBulletLink.algoBulletLinkLangs[0].description"
              ></angular-editor>
            </div>
          </div>
        </div>
      </div>
      <div class="card-template__actions">
        <button (click)="save()" mat-button class="pink-fill">Save</button>
        <button
          [routerLink]="['/algo-bullet-links']"
          routerLinkActive="router-link-active"
          mat-button
          class="pink"
        >
          cancel
        </button>
      </div>
    </mat-card>
  </div>
</div>
