import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { Language } from 'src/app/data/Language';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { Translation } from 'src/app/data/Translation';
import { TranslationCategory } from 'src/app/data/TranslationCategory';
import { TranslationKeyword } from 'src/app/data/TranslationKeyword';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { TranslationService } from 'src/app/Services/translation.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
})
export class TranslationComponent implements OnInit {
  headFilter: HeadFilter;
  translations: TranslationKeyword[];
  pagination: PaginationMetadata;
  categories: TranslationCategory[];
  selectedCategory: number = 0;
  languages: Language[];
  saveComplete: boolean = false; //pass to the table component for reset the edit mode
  constructor(
    private filterMemoryService: FilterMemoryService,
    private translationService: TranslationService,
    private languagesService: LanguagesService,
    private _matSnackBar: MatSnackBar,
    private popupHandlerService: PopupHandlerService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.translation
    );
  }

  ngOnInit(): void {
    this.getTranslationWithPaging();
    this.getLanguages();
    this.getTranslationCategories();
  }
  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data; //.filter((lang) => lang.languageId !== 3);
    });
  }
  getTranslationWithPaging(catId = 0, subCatId = 0) {
    this.headFilter.categoryId = catId;
    this.headFilter.subCategoryId = subCatId;
    this.translationService
      .getTranslationWithPaging(this.headFilter)
      .subscribe((data) => {
        this.translations = data.translations;
        this.pagination = data.pagination;
      });
  }
  getTranslationCategories() {
    this.translationService.getTranslationCategories().subscribe((data) => {
      this.categories = data;
      const all = new TranslationCategory();
      all.translationCategoryId = 0;
      all.name = 'All';
      this.categories.unshift(all);
    });
  }

  searchTranslationWithPaging() {
    this.headFilter.pageNumber = 1;
    this.translationService
      .searchTranslationWithPaging(this.headFilter)
      .subscribe((data) => {
        this.translations = data.translations;
        this.pagination = data.pagination;
      });
  }
  onSearchBtnClick() {
    if (this.headFilter.searchTerm.length > 0) {
      this.searchTranslationWithPaging();
    } else {
      this.getTranslationWithPaging();
    }
  }
  onSelectCategory(event: MatSelectChange) {
    this.headFilter.searchTerm = '';
    this.headFilter.categoryId = event.value;
    this.headFilter.pageNumber = 1;

    this.getTranslationWithPaging(this.headFilter.categoryId);
  }
  getPaginatorData(event: HeadFilter) {
    this.headFilter = event;
    this.getTranslationWithPaging();
  }
  onSaveSuccess(message: string) {
    this.saveComplete = true;
    this._matSnackBar.open(message, 'Close', {})._dismissAfter(2000);
    this.getTranslationWithPaging();
  }
  onSaveTranslation(translation: Translation[]) {
    this.translationService.setTranslation(translation).subscribe((data) => {
      this.onSaveSuccess('Translation saved');
    });
  }
  onAddKeyword(event: boolean) {
    if (event) {
      this.categories = this.categories.filter(
        (x) => x.translationCategoryId !== 0
      );
      this.popupHandlerService.openAddNewKeyword(this.categories);
      this.popupHandlerService.addNewKeywordSubject
        .pipe(take(1))
        .subscribe((data) => {
          if (data?.type === 'add') {
            const keyword = new TranslationKeyword();
            keyword.keyword = data.keyword;
            keyword.translationCategoryId = data.categoryId;
            this.translationService.AddKeyword(keyword).subscribe((data) => {
              this.onSaveSuccess('Keyword added');
            });
          }
        });
    }
  }
}
