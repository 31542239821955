import { Language } from './Language';
import { RecipeBullet } from './RecipeBullet';

export class RecipeBulletLang {
  recipeBulletLangId: number;
  recipeBulletId: number;
  recipeBullet: RecipeBullet;
  languageId: number;
  language: Language;
  name: string;
}
