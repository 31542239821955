import { AppImage } from './AppImage';

export class LaboratorySettings {
  labVersionId: number;
  fileTypeId: number;
  rowDelimeter: string;
  resultRegex: string;
  resultColumnNum: number;
  positionColumnNum: number;
  naResultValue: string;
  naResultPos: number;
  chromosomeColumnNum: number;
  fileEncodingTypeId: number;
  barCodeRegexPos: number;
  firstResultPos: number;
  secondResultPos: number;
  pdfFile: AppImage;
}
