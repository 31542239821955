import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MeetingSetting } from 'src/app/data/MeetingSetting';
import { MeetingSettingLang } from 'src/app/data/MeetingSettingLang';
import { MeetingSettingType } from 'src/app/data/MeetingSettingType';
import { MeetingSettingTypeLang } from 'src/app/data/MeetingSettingTypeLang';
import { MeetingSettingTypeEnum } from 'src/app/Enums/StatusesEnum';
import { MeetingSettingsService } from 'src/app/Services/meeting-settings.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-meeting-settings',
  templateUrl: './meeting-settings.component.html',
  styleUrls: ['./meeting-settings.component.scss'],
})
export class MeetingSettingsComponent implements OnInit {
  constructor(
    public location: Location,
    private meetingSettingsService: MeetingSettingsService,
    private popuphandlarService: PopupHandlerService
  ) {}
  meetingSettings: MeetingSetting[];
  editMode: boolean;
  meetingSettingIdToEdit: number;
  newMeetingSettingsId = 0;

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.meetingSettingsService.getAll().subscribe((data) => {
      this.meetingSettings = data;
      this.editMode = false;
      if (!this.meetingSettings) {
        this.meetingSettings = [];
      }
    });
  }
  getAllWithSuccess() {
    this.popuphandlarService.openDisapearingAlertDialog('success');
    this.getAll();
  }
  addNewMeeting() {
    const meetingSetting = new MeetingSetting();
    meetingSetting.meetingSettingId = this.newMeetingSettingsId--;
    meetingSetting.statusId = MeetingSettingTypeEnum.Other;
    meetingSetting.meetingSettingLangs = [];

    const meetingSettingTypeLangHeb = new MeetingSettingLang();
    meetingSettingTypeLangHeb.languageId = 2;
    const meetingSettingTypeLangEng = new MeetingSettingLang();
    meetingSettingTypeLangEng.languageId = 1;

    meetingSetting.meetingSettingLangs.push(
      meetingSettingTypeLangHeb,
      meetingSettingTypeLangEng
    );
    this.meetingSettings.push(meetingSetting);
    this.editMode = true;
    this.meetingSettingIdToEdit = meetingSetting.meetingSettingId;
  }
  saveMeeting(item: MeetingSetting, index: number) {
    const nameHeb = item.meetingSettingLangs.find(
      (x) => x.languageId == 1
    )?.name;
    const nameEng = item.meetingSettingLangs.find(
      (x) => x.languageId == 2
    )?.name;
    const meetingTime = item.meetingTime;
    const prepMeetingTime = item.preMeetingTime;
    if (nameHeb && nameEng && meetingTime && prepMeetingTime) {
      if (item.meetingSettingId) {
        this.meetingSettingsService.put(item).subscribe(() => {
          this.getAllWithSuccess();
        });
      } else {
        this.meetingSettingsService.post(item).subscribe(() => {
          this.getAllWithSuccess();
        });
      }
    }
  }
  deleteMeeting(item: MeetingSetting) {
    this.popuphandlarService.openConfirmationDialog(null, 'Delete');
    this.popuphandlarService.confirmDialogSubject.subscribe((data) => {
      if (data.ans === 'yes') {
        this.meetingSettingsService.delete(item).subscribe((data) => {
          this.getAllWithSuccess();
        });
      }
    });
  }
  durationTime() {
    const list = [];
    for (let i = 5; i <= 60; i += 5) {
      list.push(i);
    }
    return list;
  }
  inEditMode(item: MeetingSetting) {
    return (
      this.editMode && item.meetingSettingId == this.meetingSettingIdToEdit
    );
  }
  closeEditMode(item: MeetingSetting, index: number) {
    if (item.meetingSettingId > 0) {
      this.editMode = false;
      this.meetingSettingIdToEdit = null;
    } else {
      this.meetingSettings.splice(index, 1);
      this.editMode = false;
      this.meetingSettingIdToEdit = null;
    }
  }
}
