import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgFormula } from 'src/app/data/food-genes/FgFormula';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { PrototypeFormula } from 'src/app/data/food-genes/PrototypeFormula';
import { FormulaType } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-create-formula',
  templateUrl: './food-genes-create-formula.component.html',
  styleUrls: ['./food-genes-create-formula.component.scss'],
})
export class FoodGenesCreateFormulaComponent implements OnInit {
  addMode: 'new' | 'list';
  prototypes: FgFormula[];
  selectedPrototype: PrototypeFormula;
  formula: PrototypeFormula;
  formulaTypes: { id: number; name: string }[] = [];
  listVersionId: any;
  constructor(
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FoodGenesCreateFormulaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      versionId: number;
      ownerId: number;
      ownerType: number;
      versions: FgVersion[];
    }
  ) {
    console.log('data', data);
  }

  ngOnInit(): void {
    this.createFormulaTypesObjFromEnum();
  }
  addModeChange() {
    if (this.addMode === 'list') {
      //this.getAvailableFormulaForVersion();
    } else {
      this.selectedPrototype = null;
      this.formula = new PrototypeFormula();
      this.formula.formulaId = 0;
    }
  }
  onSelectProptotype(event: MatSelectChange) {
    const prototypeId = event.value;
    const prototype = this.prototypes.find((x) => x.formulaId === prototypeId);
    this.selectedPrototype = prototype;
    this.formula = { ...prototype };
    this.formula.formulaId = prototypeId;
  }
  onSelectVersion(event: MatSelectChange) {
    this.listVersionId = event.value;
    this.getAvailableFormulaForVersion(event.value);
  }
  getAvailableFormulaForVersion(sourceVersionId: number) {
    this.foodGenesService
      .getAvailableFormulasForVersionBySourceVersion(
        sourceVersionId,
        this.data.versionId
      )
      .subscribe((res) => {
        if ((res as ErrorMessage)?.message) {
          const message = (res as ErrorMessage).message;
          this._snackBar.open(message, 'Close')._dismissAfter(2000);
        } else {
          this.prototypes = res as FgFormula[];
        }
      });
  }
  createFormulaTypesObjFromEnum() {
    for (var n in FormulaType) {
      if (typeof FormulaType[n] === 'number') {
        this.formulaTypes.push({ id: <any>FormulaType[n], name: n });
      }
    }
  }
  create() {
    if (!this.formula.formulaId) {
      this.formula.formulaId = 0;
    }
    this.formula.formulaName = this.formula.formulaName.replace(/ /g, '_');
    this.foodGenesService
      .addFormula(
        this.formula,
        this.data.versionId,
        this.data.ownerId,
        this.data.ownerType,
        this.formula.formulaId
      )
      .subscribe((res) => {
        if ((res as ErrorMessage)?.message) {
          const message = (res as ErrorMessage).message;
          this._snackBar.open(message, 'Close');
        } else {
          this.showSuccessMessage();
          this.close(res as FgFormula);
        }
      });
  }
  close(formula: FgFormula = null) {
    this.dialogRef.close({ type: 'create', formula });
  }

  showSuccessMessage() {
    this._snackBar.open('Success', 'Close', {
      duration: 2000,
    });
  }
}
