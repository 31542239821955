import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { TubeTest } from 'src/app/data/TubeTest';
import { TubeTestEnum, ProductEnum } from 'src/app/Enums/StatusesEnum';
import { TubeTestsPusherService } from 'src/app/Services/tube-tests-pusher.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/data/Product';
import { Agent } from 'src/app/data/Agent';
import { ProductsService } from 'src/app/Services/products.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { take } from 'rxjs/operators';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { StatusesService } from 'src/app/Services/statuses.service';
import { Status } from 'src/app/data/Status';
import { TubeTestMinPackage } from 'src/app/data/TubeTestMinPackage';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { WorkingDaysService } from 'src/app/Services/working-days.service';

@Component({
  selector: 'app-package-tubes-table',
  templateUrl: './package-tubes-table.component.html',
  styleUrls: ['./package-tubes-table.component.scss'],
})
export class PackageTubesTableComponent implements OnInit, OnChanges {
  @Output('tubeSelected') tubeSelected: EventEmitter<any> = new EventEmitter();
  @Input() packageTubes: TubeTestMinPackage[];
  packageTubesNew: any;
  @Input() type;
  @Input() nutrisionists: Agent[];
  @Input() sendComplete: Boolean;
  selection = new SelectionModel<TubeTestMinPackage>(true, []);
  daysFromPickup: any[] = [];
  statuses: Status[];
  constructor(
    private tubeTestPusherService: TubeTestsPusherService,
    private productsService: ProductsService,
    private router: Router,
    private popupHandlerService: PopupHandlerService,
    private statusesService: StatusesService,
    private date: DatePipe,
    private workingDaysService: WorkingDaysService
  ) {
    this.selection.changed.subscribe((value) => {
      var selected = value.source.selected.filter(
        (selected) => selected.product.statusId !== ProductEnum.Completed
      );

      if (selected.length > 0) this.tubeSelected.emit(selected);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    var a = this.packageTubes;
    var a1 = this.packageTubes.length;
    var a2 = this.nutrisionists;
    var sendComplete =
      changes.sendComplete && changes.sendComplete.currentValue;
    if (sendComplete) {
      this.selection.clear();
    }
  }

  ngOnInit(): void {
    this.statusesService.getAll().subscribe((data) => (this.statuses = data));

    /* this.packageTubesNew = this.packageTubes;
    this.packageTubesNew.map((tube) => {
      if (!tube.product?.arrivedToOffice) {
        return (tube.product.daysFromPickup = 'Not set');
      }

      var date = Date.parse(tube.product.arrivedToOffice);
      if (date < 0) {
        return (tube.product.daysFromPickup = 'Not set');
      }

      var startDate = tube.product.arrivedToOffice.split('T')[0];
      var endDate = new Date().toISOString().split('T')[0];
      this.workingDaysService
        .getWorkingDays(startDate, endDate)
        .subscribe((data) => {
          return (tube.product.daysFromPickup = data.result.working_days.total);
        });
    }); */
  }

  isStatusCompleted(tube: TubeTestMinPackage) {
    if (tube.product && tube.product.statusId == ProductEnum.Completed) {
      return true;
    } else return false;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.packageTubes.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.packageTubes.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: TubeTestMinPackage): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    } else {
      //setTimeout(() => this.tubeSelected.emit(this.selection.selected), 0);
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
        row.position + 1
      }`;
    }
  }
  onChangeMasterSelection(e) {
    return e ? this.masterToggle() : null;
  }

  onChangeSelection(e, tube) {
    return e ? this.selection.toggle(tube) : null;
  }
  /* onChecked() {
    this.tubeSelected.emit(this.selection.selected);
  } */

  goToPatient(tube: TubeTestMinPackage) {
    var productId = tube.product.productId;
    this.router.navigate(['tests/edit'], {
      queryParams: {
        productId: productId,
      },
    });
  }
  /*  getPatientName(tube: TubeTestMinPackage) {
    if (!tube.product || !tube.product.patient) {
      return '';
    }
    var user = tube.product.patient.regularUser;
    var lastName = user.lastName;
    if (lastName == null || lastName == 'null') lastName = '';
    return user.firstName + ' ' + lastName;
  }
  getId(tube: TubeTest) {
    if (!tube.product || !tube.product.patient) {
      return '';
    }
    var user = tube.product.patient.regularUser;
    return user.socialSecurityNum;
  } 
  getCreateDate(tube: TubeTestMinPackage) {
    if (!tube.product) {
      return '';
    }
    var user = tube.product.patient.regularUser;
    return user.createdDate;
  }*/

  markAsRecieved(event, tubeTest: TubeTestMinPackage) {
    if (tubeTest.product.statusId !== ProductEnum.ArrivedToLab) return;
    tubeTest.product.statusId = ProductEnum.LaboratoryProcess;

    var { statusId, productId } = tubeTest.product;
    this.tubeTestPusherService.changeProductStatus(productId, statusId);
  }
  markAsNotRecieved(event, tubeTest: TubeTest) {
    if (tubeTest.statusId !== TubeTestEnum.RecievedbyLab) return;
    tubeTest.statusId = TubeTestEnum.SentToLab;

    var { statusId, productId } = tubeTest.product;
    this.tubeTestPusherService.changeProductStatus(productId, statusId);
  }
  onRemoveFromPackage(tube: TubeTest) {
    this.tubeTestPusherService.removeTube(tube.barcode);
  }
  goToDnaReport(product: Product) {
    var a = product.statusId == ProductEnum.Completed;
    this.router.navigate(['tests/edit/dna-report'], {
      queryParams: { productId: product.productId },
    });
  }
  showReportBtn(product) {
    return product && product.statusId == ProductEnum.Completed;
  }
  showAgentFilter() {
    return true;
  }
  /*
  saveAgentForProduct(event, product: Product) {
    if (!product) return;
    var val = event.value;
    product.agentId = Number(event.value);
    //lead.agent = null;

    setTimeout(() => {
      this.productsService
        .changeAgent(product.productId, product.agentId)
        .subscribe((res) => {});
    }, 10);
  }*/
  openResendPopup(product: Product) {
    this.popupHandlerService.buttonOptionsDialogSubject
      .pipe(take(1))
      .subscribe((ans) => {
        var statusId = 0;

        if (ans == 1) {
          statusId = ProductEnum.RetestOnLab;
        } else if (ans == 2) {
          statusId = ProductEnum.ResendToPatient;
        } else {
          return;
        }
        /*
        this.productsService
          .changeStatus(product.productId, statusId, true)
          .subscribe((res) => {

          });
*/

        this.productsService
          .changeStatus(product.productId, statusId)
          .subscribe((obj) => {
            if (obj.isProblems) {
              var errorsHtml =
                "status wasn't Changed:<br/>" +
                obj.list.map((x) => x.name + '<br>').join();

              this.popupHandlerService.openDisapearingAlertDialog(
                DisapearingTextDialogEnum.errorFromServer,
                '',
                '',
                errorsHtml,
                4000
              );
              product.statusId = obj.oldStatusId;
              product.status = this.statuses.find((x) => {
                return x.statusId == obj.oldStatusId;
              });
            } else {
              if (obj.list.length > 0) {
                this.popupHandlerService.openPerformStatusChangeDialog(
                  obj.actionsList,
                  product.productId
                );
              } else {
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.status
                );
              }
            }
          });
      });

    this.popupHandlerService.openSelectProductResendType();
  }
  addDays(date1, days) {
    var date = new Date(date1);
    date.setDate(date.getDate() + days);
    return date;
  }
  getDaysFromToday(dateStr: string) {
    /*
    Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
  }

  */
    if (!dateStr) return 'Not set';
    var date = new Date(dateStr);
    if (date.getTime() < 0) return 'Not set';
    var now = new Date();

    var datesArr = [];
    var cnt = 0;
    //var date1 = new Date('12-01-2021');
    while (date < now) {
      if (date.getDay() != 5 && date.getDay() != 6) {
        cnt++;
      }
      date.setDate(date.getDate() + 1);
      //now = new Date();
    }
    return cnt;

    /*  if (!dateStr) return 'Not set';
    var date = Date.parse(dateStr);
    if (date < 0) return 'Not set';
    var now = new Date();
    return Math.round((now.getTime() - date) / (1000 * 60 * 60 * 24)); */
  }
}
