<mat-card class="category">
  <div class="title">עריכת קטגוריה</div>
  <div class="body">
    <form #catForm="ngForm">
      <mat-form-field appearance="fill" class="field">
        <input
          matInput
          type="text"
          placeholder="הכנס שם קטגוריה"
          [(ngModel)]="category.name"
          name="nameInput"
          required
        />
      </mat-form-field>
      <div class="mb-1_5"></div>
      <div>
        <input
          style="width: 25px"
          matInput
          type="color"
          [(ngModel)]="category.color"
          name="color"
          required
        />
        &nbsp;
        <span>בחר צבע לקטגוריה</span>
      </div>
      <div class="mb-1_5"></div>
      <div>
        <mat-checkbox
          class="checkbox"
          name="isPremium"
          [(ngModel)]="category.isPremium"
          >פרימיום</mat-checkbox
        >
      </div>
      <div class="mb-1_5"></div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field class="field" fxFlex="7" appearance="fill">
          <input
            matInput
            required
            type="number"
            [(ngModel)]="category.orderBy"
            name="orderBy"
          />
        </mat-form-field>
        <div class="label" fxFlex="93">מיקום הקטגוריה</div>
      </div>
      <div class="mb-1_5"></div>
      <hr />
      <div class="mb-1_5"></div>
      <div
        class="mb-2"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="45">
            <strong>PDF אייקון קטן</strong>
            <app-algo-image
              [langId]="langId"
              [algoImageType]="AlgoImageType.AlgoCatSmallIcon"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getImage(AlgoImageType.AlgoCatSmallIcon)"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatSmallIcon)"
            ></app-algo-image>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="45"
            style="margin-right: 20px"
          >
            <strong>DNA REPORT אייקון</strong>
            <app-algo-image
              [langId]="langId"
              [algoImageType]="AlgoImageType.AlgoCatDnaReport"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getImage(AlgoImageType.AlgoCatDnaReport)"
              [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatDnaReport)"
            ></app-algo-image>
          </div>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="center"
          fxLayoutGap="15px"
          style="margin-right: 20px"
        >
          <div fxLayout="column" fxLayoutAlign="center">
            <strong>מסגרת חלק תחתון</strong>
            <app-algo-image
              isRectangle="true"
              [langId]="langId"
              textBox="מסגרת חלק תחתון"
              [algoImageType]="AlgoImageType.AlgoCatPdfBorderBottom"
              (onUploadFinished)="onUploadFinished($event)"
              [selectedImg]="getImage(AlgoImageType.AlgoCatPdfBorderBottom)"
              [checkedImgId]="
                getCheckedImage(AlgoImageType.AlgoCatPdfBorderBottom)
              "
            ></app-algo-image>
          </div>
          <div fxLayout="column" fxLayoutAlign="center">
            <strong>מסגרת חלק אמצע</strong>
            <app-algo-image
              isRectangle="true"
              [langId]="langId"
              textBox="מסגרת חלק אמצע"
              [selectedImg]="getImage(AlgoImageType.AlgoCatPdfBorderMiddle)"
              (onUploadFinished)="onUploadFinished($event)"
              [algoImageType]="AlgoImageType.AlgoCatPdfBorderMiddle"
              [checkedImgId]="
                getCheckedImage(AlgoImageType.AlgoCatPdfBorderMiddle)
              "
            ></app-algo-image>
          </div>
        </div>
      </div>
      <div class="mb-2" fxLayout="row">
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          fxFlex="30"
          style="margin-left: 20px"
        >
          <strong>Desktop אייקון</strong>
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AlgoImageType.AlgoCatBigIcon"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage(AlgoImageType.AlgoCatBigIcon)"
            [checkedImgId]="getCheckedImage(AlgoImageType.AlgoCatBigIcon)"
          ></app-algo-image>
        </div>
        <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
          <strong>Desktop תמונת כותרת</strong>
          <app-algo-image
            isBigRectangle="true"
            [langId]="langId"
            textBox="תמונת כותרת"
            [selectedImg]="getImage(AlgoImageType.AlgoPageTitleImage)"
            (onUploadFinished)="onUploadFinished($event)"
            [algoImageType]="AlgoImageType.AlgoPageTitleImage"
            [checkedImgId]="getCheckedImage(AlgoImageType.AlgoPageTitleImage)"
          ></app-algo-image>
        </div>
      </div>
      <div class="mb-2" fxLayout="row">
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          fxFlex="30"
          style="margin-left: 20px"
        >
          <strong>App אייקון</strong>
          <app-algo-image
            [langId]="langId"
            [algoImageType]="AlgoImageType.AlgoAppCatIcon"
            (onUploadFinished)="onUploadFinished($event)"
            [selectedImg]="getImage(AlgoImageType.AlgoAppCatIcon)"
            [checkedImgId]="getCheckedImage(AlgoImageType.AlgoAppCatIcon)"
          ></app-algo-image>
        </div>
        <div fxLayout="column" fxLayoutAlign="center" fxFlex="70">
          <strong>App תמונת כותרת</strong>
          <app-algo-image
            isBigRectangle="true"
            [langId]="langId"
            textBox="App תמונת כותרת"
            [selectedImg]="getImage(AlgoImageType.AlgoAppPageTitleImage)"
            (onUploadFinished)="onUploadFinished($event)"
            [algoImageType]="AlgoImageType.AlgoAppPageTitleImage"
            [checkedImgId]="
              getCheckedImage(AlgoImageType.AlgoAppPageTitleImage)
            "
          ></app-algo-image>
        </div>
      </div>
      <div
        class="actions"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
      >
        <button class="save" type="submit" (click)="save(catForm)" mat-button>
          <span>שמירה</span>
        </button>
        <button
          class="cancel"
          type="button"
          (click)="closeMainBoard.emit(true)"
          mat-button
        >
          <span>ביטול</span>
        </button>
      </div>
    </form>
  </div>
</mat-card>
