import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeadFilter } from '../data/HeadFilter';
import { Video } from '../data/Video';
import { VideoForTable } from '../data/VideoForTable';
import { HttpFilterHelper } from '../Utils/http-filter-helper';
import * as tus from 'tus-js-client';
import { CloudflareErrorMessage } from '../data/CloudflareErrorMessage';
import { AppImage } from '../data/algorithm/AppImage';
import { ErrorMessage } from '../data/ErrorMessage';
import { VideoCategory } from '../data/VideoCategory';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  public httpFilterHelper: HttpFilterHelper = new HttpFilterHelper();
  private uploadStatus = new Subject<any>();
  uploadProgress = this.uploadStatus.asObservable();
  uploadComplete = new Subject<any>();
  uploadError = new Subject<boolean>();
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getAllVideos(headFilter: HeadFilter): Observable<any> {
    return this.http.get<VideoForTable[]>(
      `${
        environment.baseUrl
      }VOD/FilterVideos/${this.httpFilterHelper.createVideoPathFromHeadFilter(
        headFilter,
        this.datepipe
      )}`
    );
  }

  toggleStatus(videoId: number, status: boolean) {
    return this.http.get<any>(
      `${environment.baseUrl}VOD/ChangeVideoStatus/${videoId}/${status}`
    );
  }

  getById(id: number) {
    const langId = 1;
    return this.http.get<Video>(
      `${environment.baseUrl}VOD/GetVideoById/${id}/${langId}`
    );
  }

  uploadFile(file: File) {
    const upload = new tus.Upload(file, {
      endpoint: `${environment.nodeUrl}get-tus`,
      retryDelays: [0, 3000, 6000, 12000, 24000],
      chunkSize: 52428800, //50 * 1024 * 1024
      metadata: {
        name: file.name,
        type: file.type,
      },

      onError: async (error) => {
        //console.log(error);
        this.uploadError.next(true);
        return false;
      },
      onProgress: async (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        this.uploadStatus.next(percentage);
        //console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        /*console.log(
          'chunkSize: ',
          chunkSize,
          'bytesAccepted: ',
          bytesAccepted,
          'bytesTotal: ',
          bytesTotal
        );
         this.fileStatusArr.forEach((value) => {
          if (value.filename === filename) {
            value.progress = Math.floor((bytesAccepted / bytesTotal) * 100);
            value.uuid = upload.url.split('/').slice(-1)[0];
          }
        });
        this.uploadStatus.next(this.fileStatusArr); */
      },
      onSuccess: async () => {
        this.uploadComplete.next(upload.url);
        //console.log('Download %s from %s', upload.file, upload.url);
        //console.log(upload);
      },
    });

    upload.start();
  }

  replaceUrl(url) {
    const urlArray = url?.split('/tus');
    const tusUrl = urlArray?.lenght > 1 ? urlArray[1] : '';
    return 'asasasas' + tusUrl;
  }

  post(video: Video) {
    return this.http.post<Video>(
      `${environment.baseUrl}VOD/InsertVideo`,
      video
    );
  }

  put(video: Video) {
    return this.http.put<Video>(
      `${environment.baseUrl}VOD/UpdateVideo/${video.videoId}`,
      video
    );
  }

  delete(videoId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}VOD/DeleteVideo/${videoId}`
    );
  }
  deleteVideo(videoId: string) {
    return this.http.delete<any | CloudflareErrorMessage>(
      `${environment.nodeUrl}delete/${videoId}`
    );
  }

  uploadVodFile(
    image: any,
    type: number,
    streamId: string,
    langId: number = null
  ) {
    return this.http.post<AppImage | ErrorMessage>(
      `${environment.baseUrl}Uploader/UploadVODFiles/${type}/${streamId}/${langId}`,
      image
    );
  }
  getAllVideoCategories() {
    return this.http.get<VideoCategory[]>(
      `${environment.baseUrl}VOD/GetAllVideoCategories`
    );
  }

  updateCategory(category: VideoCategory) {
    return this.http.put<any>(
      `${environment.baseUrl}VOD/UpdateCategory/${category.videoCategoryId}`,
      category
    );
  }
  insertCategory(category: VideoCategory) {
    return this.http.post<any>(
      `${environment.baseUrl}VOD/InsertCategory`,
      category
    );
  }
  deleteCategory(categoryId: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}VOD/DeleteCategory/${categoryId}`
    );
  }
}
