<!--<button class="back back--client" (click)="goBackToClient()">
  < Back to Client
</button>-->
<button class="back back--client" (click)="goBackToCancelations()">
  < Back to Cancelations
</button>
<div class="cancel-grid">
  <mat-card *ngIf="cancelationRequest" style="padding: 16px 5rem">
    <div class="wrapper">
      <div class="header">
        <span class="header__title">
          Cancelation Request -
          <span
            style="color: #119c99"
            *ngIf="cancelationMode === CancelModeEnum.newRequest"
            >New Request
          </span>
          <span
            style="color: #119c99"
            *ngIf="cancelationMode === CancelModeEnum.conservation"
            >Conservation</span
          >
          <span
            style="color: #119c99"
            *ngIf="cancelationMode === CancelModeEnum.manager"
            >Manager</span
          >
        </span>
        <div class="header__status">
          <span>Credit Status:&nbsp;</span>{{ cancelationRequest.status.name }}
        </div>
        <span class="header__btn">
          <button
            *ngIf="showSaveBtn()"
            mat-button
            class="btn-save button primary"
            (click)="onSaveBtnClick()"
          >
            SAVE
          </button>
        </span>
      </div>
      <hr class="hr" />
      <div class="d-flex">
        <!--<perfect-scrollbar style="max-height: 100vh; height: 73.5vh" [perfectScrollbar]="config">-->
        <mat-list>
          <form
            #cancelationFormRef="ngForm"
            *ngIf="cancelationForm"
            [formGroup]="cancelationForm"
          >
            <mat-list-item class="row">
              <span> Client Name: </span>
              <span
                *ngIf="userCanClientClick"
                style="text-decoration: underline; cursor: pointer"
                (click)="goToClient()"
                >{{ getFullName(client.regularUser) }}</span
              >
              <span *ngIf="!userCanClientClick">{{
                getFullName(client.regularUser)
              }}</span>
            </mat-list-item>
            <mat-list-item class="row">
              <span> Request Date: </span>

              <mat-form-field appearance="fill">
                <input formControlName="createDate" type="text" matInput />
              </mat-form-field>
            </mat-list-item>

            <mat-list-item class="row">
              <span> Reason for the Cancelation: </span>
              <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="reason">
                  <mat-option
                    *ngFor="let reason of reasonStatuses"
                    [value]="reason.statusId"
                  >
                    {{ reason.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>

            <mat-list-item class="row">
              <span> Reason for Cancelation Description: </span>
              <mat-form-field style="width: 55%" appearance="fill">
                <textarea
                  formControlName="approvalReason"
                  matInput
                  placeholder="Reason"
                  style="width: 100%; height: 30px; max-height: 45px"
                ></textarea>
              </mat-form-field>
            </mat-list-item>

            <mat-list-item class="row">
              <span *ngIf="cancelationMode === CancelModeEnum.newRequest"
                >Requested Amount:
              </span>
              <span *ngIf="showRefundAmount()"
                >Amount to be Refunded/cupone:</span
              >
              <mat-form-field appearance="fill">
                <input formControlName="amount" type="text" matInput />
              </mat-form-field>
            </mat-list-item>

            <ng-container
              *ngIf="
                cancelationMode === CancelModeEnum.conservation ||
                cancelationMode === CancelModeEnum.manager ||
                cancelationMode === CancelModeEnum.closed
              "
            >
              <mat-list-item class="row">
                <span> Decision: </span>
                <mat-form-field appearance="fill">
                  <mat-label>Decision</mat-label>
                  <mat-select
                    *ngIf="decisions"
                    (selectionChange)="onDecisionChanged($event)"
                    formControlName="decisionId"
                  >
                    <mat-option
                      *ngFor="let decision of decisions"
                      [value]="decision.statusId"
                    >
                      {{ decision.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>
              <mat-list-item class="row">
                <span> Conservation agent: </span>
                <mat-form-field appearance="fill">
                  <mat-label>agent</mat-label>
                  <mat-select formControlName="conservationAgentId">
                    <mat-option
                      *ngFor="let agent of agents"
                      [value]="agent.agentId"
                    >
                      {{ agent.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>
              <mat-list-item
                class="row row-action"
                *ngIf="
                  cancelationMode === CancelModeEnum.conservation &&
                  cancelationRequest.statusId != 132
                "
              >
                <button
                  *ngIf="decisionId != 149"
                  type="button"
                  (click)="sendToManager()"
                  mat-button
                >
                  Send to manager
                </button>
                <button
                  *ngIf="decisionId == 149"
                  type="button"
                  (click)="coservationAgentSaved()"
                  mat-button
                >
                  Close Request
                </button>
              </mat-list-item>
            </ng-container>

            <ng-container
              *ngIf="
                cancelationMode === CancelModeEnum.manager &&
                !hideRejectApproveBtns
              "
            >
              <mat-list-item class="row row-action">
                <div class="d-flex" style="justify-content: unset">
                  <!--
                  <button
                    type="button"
                    (click)="managerRejectRequest()"
                    mat-button
                  >
                    Reject
                  </button>-->
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    (click)="manageAproveRequest()"
                    mat-button
                  >
                    Approve
                  </button>
                </div>
              </mat-list-item>
            </ng-container>
          </form>
          <mat-list-item *ngIf="showCancelError">
            <span style="color: #f44336; font-weight: 500"
              >*Please choose at least one Product</span
            >
          </mat-list-item>
        </mat-list>
        <!--</perfect-scrollbar>-->
        <div class="client-details">
          <div class="client-details__title">Client Details</div>
          <mat-list>
            <mat-list-item class="row">
              <span>Client Type:</span>
              {{ client.clientType.name }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Name:</span>
              {{ getFullName(client.regularUser) }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client ID:</span>
              {{ client.regularUser.socialSecurityNum }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Email:</span>
              {{ client.regularUser.email }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Tel:</span>
              {{ client.regularUser.userPhones[0].phoneNumber }}
            </mat-list-item>
            <mat-list-item class="row">
              <span>Client Address:</span>
              {{
                client.regularUser.userAdresses[0].barCity.name +
                  " " +
                  client.regularUser.userAdresses[0].barStreet.name +
                  " " +
                  client.regularUser.userAdresses[0].streetNum
              }}
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
    <mat-tab-group #tabGroup animationDuration="0ms" style="min-height: 400px">
      <mat-tab style="min-height: 200px">
        <ng-template mat-tab-label>
          <div class="task-overdue-tab-unselected">Products</div>
        </ng-template>
        <!-- <div class="divider divider--client"></div> -->
        <table class="tableProducts">
          <thead>
            <tr>
              <th>
                <mat-checkbox
                  [(ngModel)]="allProductsChecked"
                  (click)="CheckAllOptions()"
                  [disabled]="isInClosedStatus()"
                >
                </mat-checkbox>
              </th>
              <th>Product</th>
              <th>Patient Name</th>
              <th>Status</th>
              <th *ngIf="showChangeTo == true">Change to</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let product of cancelProducts; let i = index">
              <tr *ngIf="canChangeProduct(product)">
                <td>
                  <mat-checkbox
                    (change)="onCheckboxChange()"
                    [(ngModel)]="product.isCanceled"
                    [disabled]="isInClosedStatus()"
                  >
                  </mat-checkbox>
                </td>
                <td>{{ product.productType.productName }}</td>
                <td>
                  {{ product.patient ? getPatientName(product) : "No Patient" }}
                </td>
                <td>
                  {{ product.status.name }}
                </td>
                <td *ngIf="showChangeTo == true">
                  <mat-form-field class="change-to" appearance="fill">
                    <mat-label>Change to</mat-label>
                    <mat-select
                      (selectionChange)="
                        productChangeTo(product.productId, $event.value)
                      "
                      [(ngModel)]="product.newProductTypeId"
                      [disabled]="isInClosedStatus()"
                    >
                      <mat-option [value]="-1"> No Change </mat-option>
                      <ng-container *ngFor="let p of changeToProducts">
                        <mat-option
                          *ngIf="
                            p.productTypeId != product.productType.productTypeId
                          "
                          [value]="p.productTypeId"
                        >
                          {{ p.productName }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab style="min-height: 200px">
        <ng-template mat-tab-label>
          <div class="task-overdue-tab-unselected">Activities</div>
        </ng-template>
        <!-- <div class="divider divider--client"></div> -->
        <app-lead-activities-view
          (createNewTask)="createNewTask($event)"
          *ngIf="isTaskEditMode == false"
          [notes]="cancellationRequestTasks"
          [agents]="allAgents"
          [classAttr]="'clientAttr'"
        >
        </app-lead-activities-view>
        <app-lead-create-task
          (submitTask)="saveTask($event)"
          (cancelTask)="closeNewTaskWindow()"
          *ngIf="isTaskEditMode == true"
          [note]="activityToEdit"
          [agents]="allAgents"
          [currAgent]="agentUser"
        >
        </app-lead-create-task>
      </mat-tab>
      <mat-tab style="min-height: 200px">
        <ng-template mat-tab-label>
          <div class="task-overdue-tab-unselected">Cancel History</div>
        </ng-template>
        <!-- <div class="divider divider--client"></div>
         (openNewPurchaseEmitter)="openNewPurchase($event)" -->

        <app-client-purchases
          *ngIf="client"
          [clientDeals]="clientDealBundles"
          [agentUser]="agentUser"
          [assignedAgentId]="client.agentId"
          [inCancellation]="true"
        >
        </app-client-purchases>

        <!--
        <app-transactions-table
          *ngIf="statuses"
          [transactionData]="clientDeals"
          [statuses]="statuses"
        >
        </app-transactions-table>
        -->
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <div style="height: fit-content">
    <app-client-notes
      [notes]="cancellationRequestNotes"
      (openNewNote)="createNewNote($event)"
      *ngIf="isNoteEditMode == false"
      [classAttr]="'clientAttr'"
      (reloadNotes)="onReloadNotes()"
    >
    </app-client-notes>

    <app-client-add-note
      [note]="noteToEdit"
      (submitNote)="saveNote($event)"
      [classAttr]="'clientAttr'"
      (cancelNote)="closeNewNoteWindow()"
      *ngIf="isNoteEditMode == true"
    >
    </app-client-add-note>
  </div>
</div>
