import { ConditionParticipant } from './ConditionParticipant';

export class FgCondition {
  conditionId: number;
  conditionName: string;
  referenceType: number;
  referenceObjectId: number;
  defaultValueParam: number;
  conditionType: number;
  conditionParticipants: ConditionParticipant[];
}
