import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { LabVersion } from 'src/app/data/algorithm/LabVersion';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgVersion } from 'src/app/data/food-genes/FgVersion';
import { Language } from 'src/app/data/Language';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-food-genes-versions',
  templateUrl: './food-genes-versions.component.html',
  styleUrls: ['./food-genes-versions.component.scss'],
})
export class FoodGenesVersionsComponent implements OnInit {
  versions: FgVersion[];
  dataSource: MatTableDataSource<FgVersion> = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'version',
    'labVersion',
    'date',
    'final',
    'action',
  ];
  langId: number = 1;
  labVersions: any[];
  constructor(
    private foodGenesService: FoodGenesService,
    public location: Location,
    private popupHandlerService: PopupHandlerService,
    private algorithmService: AlgorithmService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getFgVersions();
  }

  getFgVersions() {
    this.foodGenesService.getFgVersions(this.langId).subscribe((data) => {
      this.versions = data;
      this.setDataSource(this.versions);
    });
  }

  setDataSource(data: FgVersion[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  editVersion(version: FgVersion, duplicateAction: boolean = false) {
    this.popupHandlerService.openNewFoodGenesVersion(
      version,
      this.versions,
      this.langId,
      duplicateAction
    );
    this.popupHandlerService.addNewFgVersionSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.type == 'save') {
          this.getFgVersions();
        }
      });
  }
  lockVersion(version: FgVersion) {
    this.popupHandlerService.openConfirmationDialog(
      '',
      `${version.isLock ? 'Unlock' : 'Lock'} version`
    );
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((result) => {
        if (result.ans === 'yes') {
          this.foodGenesService
            .lockFgVersion(version.fgVersionId, !version.isLock)
            .subscribe((data) => {
              if ((data as ErrorMessage)?.message) {
                this._matSnackBar.open((data as ErrorMessage).message, 'Close');
              } else {
                this._matSnackBar.open('Success', 'Close')._dismissAfter(2000);
                this.getFgVersions();
              }
            });
        }
      });
  }
  duplicateVersion(version: FgVersion) {}

  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
  }
}
