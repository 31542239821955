import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/data/chat/ChatMessage';
import { MessageStatus } from 'src/app/Enums/ChatEnum';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { getImage, replaceWebPath } from 'src/app/Utils/files-helper';

@Component({
  selector: 'app-conversation-message',
  templateUrl: './conversation-message.component.html',
  styleUrls: ['./conversation-message.component.scss'],
})
export class ConversationMessageComponent implements OnInit {
  message: ChatMessage;
  MessageStatus = MessageStatus;
  @Input() langId: number;
  constructor(private firebaseService: FirebaseChatIndicatorService) {}

  ngOnInit(): void {
    if (
      this.message.statusId !== MessageStatus.Readed &&
      !this.message.directionToUser
    ) {
      this.firebaseService.readMessage(this.message.chatMessageId, this.langId);
    }
  }

  fileExtension() {
    const file = this.message.attachments[0];
    const fileType = file.fileName?.split('.').pop();

    if (fileType === 'pdf') {
      return 'pdf';
    } else {
      return 'image';
    }
  }

  showAttachment() {
    const image = this.message.attachments[0];
    // console.log('image', image);

    const imagePath = `{picdomain}/ChatAttachment/${image.fileName}`;
    return replaceWebPath(imagePath);

    // return this.message.attachments[0].fileName;
  }
}
