import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChatRoomAgent } from 'src/app/data/chat/ChatRoomAgent';
import { ChatSession } from 'src/app/data/chat/ChatSession';
import { GeneralAgent } from 'src/app/data/chat/GeneralAgent';
import { SessionStatus } from 'src/app/Enums/ChatEnum';
import { FirebaseChatIndicatorService } from 'src/app/Services/firebase-chat-indicator.service';
import { replaceWebPath } from 'src/app/Utils/files-helper';

@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss'],
})
export class AgentsListComponent implements OnInit, OnChanges {
  chatRoomAgents: ChatRoomAgent[];
  activeSession: ChatRoomAgent;
  @Input() agent: GeneralAgent;
  @Input() resetSelected: boolean;
  @Output() selectedRowEvent: EventEmitter<number> = new EventEmitter();

  constructor(public firebaseService: FirebaseChatIndicatorService) {}

  ngOnInit(): void {
    this.firebaseService.chatRoomAgent$.subscribe((data) => {
      if (data) {
        this.chatRoomAgents = [];
        this.chatRoomAgents = data;
      }
    });
    this.firebaseService.chatRoom$.subscribe((data) => {
      if (data?.chatRoom) {
        this.activeSession = data.chatRoom;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.resetSelected &&
      changes.resetSelected.currentValue &&
      !changes.resetSelected.isFirstChange()
    ) {
      this.activeSession = null;
    }
  }

  getActiveSession(sessions: ChatSession[]) {
    const activeSession = sessions.find(
      (x) => x.statusId === SessionStatus.Active
    );
    return activeSession || sessions[sessions.length - 1];
  }

  onRowClicked(item: ChatRoomAgent) {
    this.activeSession = item;
    this.selectedRowEvent.emit(item.chatRoomId);

    //this.firebaseService.chatRoom$.next({ chatRoom: item });
  }

  getAvatarImage(item: ChatRoomAgent) {
    let imagePath = '';
    if (item.sessions[0].conversationUserAvatar) {
      imagePath = replaceWebPath(item.sessions[0].conversationUserAvatar);
    } else {
      imagePath = '../../assets/man.svg';
    }
    return imagePath;
  }
}
