import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnpSearchResult } from 'src/app/data/algorithm/SnpSearchResult';

@Component({
  selector: 'app-search-snp',
  templateUrl: './search-snp.component.html',
  styleUrls: ['./search-snp.component.scss'],
})
export class SearchSnpComponent implements OnInit {
  @Input() results: SnpSearchResult[];
  @Output('selectResult') selectResult = new EventEmitter<SnpSearchResult>();
  headers = [
    { name: '', width: '10' },
    { name: 'קטגוריה', width: '30' },
    { name: 'תת קטגוריה', width: '30' },
    { name: 'מספר RS', width: '30' },
  ];
  constructor() {}
  ngOnInit(): void {}
  onSelectResult(result: SnpSearchResult) {
    this.selectResult.emit(result);
  }
}
