import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientTypeService {
  clientTypes: any;
  constructor(private http: HttpClient) { }
  getAll() {
    if (this.clientTypes){
      return of(this.clientTypes);  // return from cache
  } else
  {
      return this.http.get<ClientTypes[]>(`${environment.baseUrl}ClientTypes/`).pipe(map(res => (this.clientTypes=res)));
  }


   // return this.http.get(`${environment.baseUrl}ClientTypes/`);
  }
}
