import { DepartmentType } from 'src/app/Enums/ChatEnum';

export class ChatFilter {
  departmentId: DepartmentType;
  agentId: number;
  isWaiting: boolean;
  isOpen: boolean;
  branchId: number;
  langId: number;
  searchTerm: string;
}
