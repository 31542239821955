import { RegularUser } from './RegularUser';
import { Client } from './Client';
import { Deal } from './Deal';
import { OfficePhoneExtension } from './OfficePhoneExtension';

export class Agent {
  public constructor(init?: Partial<Agent>) {
    Object.assign(this, init);
  }
  agentId: number;
  regularUserId: number;
  regularUser: RegularUser;
  clients?: Client[];
  agentDeals?: Deal[];
  officePhoneExtensionId: number;
  officePhoneExtension: OfficePhoneExtension;
}
