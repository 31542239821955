<div [dir]="dir">
  <mat-form-field
    appearance="fill"
    [matMenuTriggerFor]="valueComplete"
    class="main-field"
    (menuOpened)="menuOpened()"
    style="width: 100%"
    [ngClass]="{
      'ng-invalid': hasError,
      'mat-form-field-invalid': hasError
    }"
  >
    <!-- <mat-label>{{ label }}</mat-label> -->
    <input
      style="cursor: pointer"
      class="input-compelete"
      placeholder="{{ placeholder }}"
      type="text"
      matInput
      #value
      disabled
      [dir]="dir"
      [ngStyle]="{ 'text-align': textAlign }"
      [(ngModel)]="initialValue"
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="open"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu
    #valueComplete="matMenu"
    xPosition="before"
    yPosition="below"
    class="menu-complete"
    [dir]="dir"
    [ngStyle]="{ 'text-align': textAlign }"
    style="min-width: 300px"
  >
    <ng-container>
      <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <mat-label>Type something...</mat-label>
          <input
            [dir]="dir"
            [ngStyle]="{ 'text-align': textAlign }"
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div>
      <div id="results">
        <ng-container *ngFor="let value of data">
          <button
            class="menu-complete__item"
            mat-button
            (click)="onValueSelected($event, value?.id, value?.name)"
            [ngStyle]="{ 'text-align': textAlign }"
          >
            <div class="menu-complete__item__row">
              <span class="menu-complete__item__row__name">
                {{ value?.name }}
              </span>
              <span
                class="menu-complete__item__row__rank"
                *ngIf="showRanking && value?.rank"
              >
                {{ isNumber(value.rank) ? value.rank + "%" : value.rank }}
              </span>
            </div>
          </button>
        </ng-container>
      </div>
    </ng-container>
    <!-- <ng-template #noData>
        <div style="text-align: center">
          <container-element [ngSwitch]="label">
            <some-element *ngSwitchCase="'Street'"
              >Please Choose City</some-element
            >
            <some-element *ngSwitchDefault>No Data</some-element>
          </container-element>
        </div>
      </ng-template> -->
  </mat-menu>
</div>
