import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-role',
  templateUrl: './add-new-role.component.html',
  styleUrls: ['./add-new-role.component.scss'],
})
export class AddNewRoleComponent implements OnInit {
  roleName: string;
  constructor(
    public dialogRef: MatDialogRef<AddNewRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  save() {
    this.dialogRef.close({ event: 'save', data: this.roleName });
  }
}
