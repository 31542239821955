import { Food } from './Food';
import { MealBullet } from './MealBullet';

export class MealBulletOption {
  mealBulletOptionId: number;
  mealBulletId: number;
  mealBullet: MealBullet;
  foodId: number;
  food: Food;
  foodScaleId: number;
  foodScale: any;
  quantity: number;
  calories: number;
  weight: number;
  foodName: string;
  foodScaleName: string;
}
