<app-head-filter
  *ngIf="statuses && lots"
  [headFilter]="headFilter"
  [barcodeStatuses]="statuses"
  [lots]="lots"
  (filterChanged)="getPaginatorData($event)"
  [classAttr]="'barcodes'"
>
</app-head-filter>
<div class="TableClearFix"></div>
<div class="header">
  Barcodes Management
  <button mat-button (click)="onAddBarcode()">Add barcode lot</button>
</div>
<perfect-scrollbar
  style="height: calc(100vh - 300px); width: 1010px; margin: 0 auto"
>
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">
        Barcode number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.barcode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lot">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Lot</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lot.lotId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Status</th>
      <td mat-cell *matCellDef="let element">
        <span
          class="status"
          [style.background-color]="
            hexToRgbWithOpacity(element.status.colorHex, 0.2)
          "
          [style.color]="element.status.colorHex"
        >
          {{ element.status.statusAlias }}</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateCreated | date : "dd/MM/yyyy hh:mm" }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</perfect-scrollbar>
<app-table-paginator
  [sticky]="true"
  *ngIf="barcodes && barcodes.length > 0 && metaData"
  [headFilter]="headFilter"
  [metaData]="metaData"
  (filterChanged)="getPaginatorData($event)"
  [classAttr]="'barcodes'"
  [isMainScreen]="true"
>
</app-table-paginator>
