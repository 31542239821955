import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailTemplate } from 'src/app/data/EmailTemplate';
import { EventAction } from 'src/app/data/EventAction';
import { Status } from 'src/app/data/Status';
import { EmailTemplatesService } from 'src/app/Services/email-templates.service';
import { EventActionsService } from 'src/app/Services/event-actions.service';

@Component({
  selector: 'app-event-actions',
  templateUrl: './event-actions.component.html',
  styleUrls: ['./event-actions.component.css'],
})
export class EventActionsComponent implements OnInit {
  @Input() actions: EventAction[];
  @Input() emailTemplates: EmailTemplate[];
  @Input() actionTypes: Status[];
  @Output('updateEvents')
  updateEvents: EventEmitter<boolean> = new EventEmitter();

  constructor(private eventActionsService: EventActionsService) {}
  displayedColumns: string[] = [
    'actionType',
    'EmailTemplate',
    'Active',
    'Delete',
  ];
  ngOnInit(): void {}
  stopPropegation(event) {
    event.stopPropagation();
  }
  saveEmailTemplateForEventAction(event, eventAction: EventAction) {
    eventAction.emailTemplateId = event.value;
    this.eventActionsService.put(eventAction).subscribe((res) => {
      //toDo: notify parent !!!
      this.updateEvents.next(true);
    });
  }
  saveActionTypeForTriggerEvent(event, eventAction: EventAction) {
    eventAction.actionTypeId = event.value;
    this.eventActionsService.put(eventAction).subscribe((res) => {
      //toDo: notify parent !!!
      this.updateEvents.next(true);
    });
  }
  deleteAction(eventAction: EventAction) {
    this.eventActionsService.delete(eventAction).subscribe((res) => {
      //toDo: notify parent !!!
      this.updateEvents.next(true);
    });
  }
  ChangeIsActive(eventAction: EventAction) {
    //eventAction.isActive=event.value;
    this.eventActionsService.put(eventAction).subscribe((res) => {
      //toDo: notify parent !!!
      this.updateEvents.next(true);
    });
  }
}
