import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AlgoCategory } from 'src/app/data/algorithm/AlgoCategory';
import { AlgoSubCategory } from 'src/app/data/algorithm/AlgoSubCategory';
import { AlgoSubCategoryResultType } from 'src/app/data/algorithm/AlgoSubCategoryResultType';
import { AlgoSubCategorySnp } from 'src/app/data/algorithm/AlgoSubCategorySnp';
import { SnpSearchResult } from 'src/app/data/algorithm/SnpSearchResult';
import { AlgoMainBoardEnum } from 'src/app/Enums/StatusesEnum';

@Component({
  selector: 'app-main-board',
  templateUrl: './main-board.component.html',
  styleUrls: ['./main-board.component.scss'],
})
export class MainBoardComponent implements OnInit, OnChanges {
  //#region Properties
  @Input() subCategory: AlgoSubCategory;
  @Input() category: AlgoCategory;
  @Input() snp: AlgoSubCategorySnp;
  @Input() subCatResultTypes: AlgoSubCategoryResultType[];
  @Input() isSearch: boolean;
  @Input() langId: number;
  @Input() versionId: number;
  @Input() results: SnpSearchResult[];
  @Input() canEdit: boolean;
  @Input() dir: boolean;
  @Input() mode: AlgoMainBoardEnum;
  AlgoMainBoardEnum = AlgoMainBoardEnum;
  @Output() selectResult = new EventEmitter<SnpSearchResult>();
  @Output() loadResultTypes = new EventEmitter<boolean>();
  @Output() loadRs = new EventEmitter<boolean>();
  @Output() loadCategories: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMainBoard: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.mode && !changes.mode.isFirstChange())
      console.log('ngOnChanges mode:', changes.mode?.currentValue);
  }
  ngOnInit(): void {
    console.log('ngOnInit mode:', this.mode);
  }
  onLoadResultTypes(event: any) {
    if (event) {
      this.loadResultTypes.emit(true);
    }
  }
  onSelectResult(event: any) {
    if (event) {
      this.selectResult.emit(event);
    }
  }
  onLoadRs(event: any) {
    if (event) {
      this.loadRs.emit(true);
    }
  }
  onLoadCategories(event: any) {
    if (event) {
      this.loadCategories.emit(true);
    }
  }
  onCloseMainBoard(event: any) {
    if (event) {
      this.closeMainBoard.emit(true);
    }
  }
}
