<div class="formGroup">
  <!--
    <button type="button" (click)="authService.GoogleAuth()">
      Log in with Google
    </button>
    -->
</div>

<div *ngIf="!anonAuthService.isUserAnonymousLoggedIn">
  <button (click)="signInAnonymously()">Login</button>
</div>
<button
  *ngIf="anonAuthService.isUserAnonymousLoggedIn"
  class="button"
  (click)="logout()"
>
  Logout
</button>
