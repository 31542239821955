import { GeneticFormulaCoef } from './GeneticFormulaCoef';
import { Status } from './Status';

export class GeneticFormula {
  geneticFormulaId: number;
  groupId: number;
  geneticFormulaCoefs: GeneticFormulaCoef[];
  operationId: number;
  operation: Status;
}
