import { AlgoBullet } from './AlgoBullet';
import { BloodTestType } from './BloodTestType';

export class BulletBloodCrossing {
  bulletBloodCrossingId: number;
  algoBulletId: number;
  algoBullet: AlgoBullet;
  bloodTestTypeId: number;
  bloodTestType: BloodTestType;
  lowerValue: number;
  upperValue: number;
  description: string;

  constructor(
    bloodTestTypeId = null,
    lowerValue = null,
    upperValue = null,
    description = null
  ) {
    if (bloodTestTypeId && lowerValue != null && upperValue != null) {
      this.bloodTestTypeId = bloodTestTypeId;
      this.lowerValue = lowerValue;
      this.upperValue = upperValue;
      //  this.description = description;
    }
  }
}
