<div
  style="display: flex; align-items: center"
  class="mb-1"
  style="font-size: 13px"
>
  <img src="../../../assets/calendar-60.svg" />
  <span style="margin-right: 10px">
    {{ getNoteCreateDate() | date: "fullDate" }}
  </span>
  <img src="../../../assets/clock.svg" />
  <span> {{ getNoteCreateDate() | date: "HH:mm" }} </span>
  <app-upload-results-csv
    style="display: inline-block; float: right"
    (click)="preventDefault($event)"
    *ngIf="getNoteId()"
    [title]="'Attach File'"
    [uploadPath]="getFilesUploadPath()"
    (onUploadFinished)="uploadFileFinished($event)"
    [classAttr]="'notesAttr'"
  ></app-upload-results-csv>
</div>
<span
  (click)="gotoServiceRequest($event)"
  *ngIf="getClientRequestId()"
  class="text"
  style="cursor: pointer; color: blue"
>
  App Report #{{ getClientRequestId() }}
  <br />
  <br />
</span>
<span
  (click)="gotoServiceRequest($event)"
  *ngIf="getServiceRequestId()"
  class="text"
  style="cursor: pointer; color: blue"
>
  Service Request #{{ getServiceRequestId() }}
  <br />
  <br />
</span>
<span
  (click)="gotoCancellationRequest($event, note)"
  *ngIf="getCancelationRequestId()"
  class="text"
  style="cursor: pointer; color: red"
>
  <span *ngIf="getNoteCategory().name === 'Refund'"
    >Refund #{{ getCancelationRequestId() }}</span
  >
  <span *ngIf="getNoteCategory().name === 'Cancelation Request'"
    >Cancellation Request #{{ getCancelationRequestId() }}</span
  >

  <br />
  <br />
</span>
<span style="color: red" *ngIf="getNoteTypeId() == 4"
  >Call Review
  <br />
</span>
<button
  mat-button
  (click)="openRecordDialog(getNoteId())"
  style="color: green"
  *ngIf="getNoteTypeId() == 6"
>
  Call Record
  <mat-icon style="font-size: 2.1rem">play_circle_filled</mat-icon>
  <br />
</button>
<div
  class="text"
  [class.user-align-right]="userAlign"
  [class.text--client]="classAttr == 'clientAttr'"
  [class.text--lead]="classAttr == 'leadAttr'"
>
  {{ getNoteAssigningAgentName() }} :
  <span class="text text--base" [innerHtml]="getNoteTitle()">{{
    getNoteTitle()
  }}</span>

  <br />
  <span [innerHtml]="getNoteDesc()">{{ getNoteDesc() }}</span>
  <div
    class="text mt-1"
    [class.text--client]="classAttr == 'clientAttr'"
    [class.text--lead]="classAttr == 'leadAttr'"
  >
    Last Update: {{ getNoteUpdateDate() | date: "fullDate" }}
  </div>
  <div
    (click)="downloadNoteImage()"
    *ngIf="getImageUrl()"
    style="
      display: flex;
      border: 0.2px solid rgba(0, 174, 238, 0.2);
      padding: 2px;
      justify-content: center;
      cursor: pointer;
    "
  >
    <mat-icon
      style="position: relative; transform: rotate(-90deg); cursor: pointer"
      aria-hidden="false"
      aria-label="Example home icon"
      >attachment</mat-icon
    >
    <span>
      {{ getImageFileName() }}
    </span>
  </div>
</div>
