import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Product } from 'src/app/data/Product';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { DisapearingTextDialogEnum } from 'src/app/Enums/DisapearingTextDialogEnum';
import { ProductEnum } from 'src/app/Enums/StatusesEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductsForClientPusherServiceService } from 'src/app/Services/products-for-client-pusher-service.service';
import { ProductsService } from 'src/app/Services/products.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from 'src/app/Utils/user-helper';

@Component({
  selector: 'app-client-tests',
  templateUrl: './client-tests.component.html',
  styleUrls: ['./client-tests.component.scss'],
})
export class ClientTestsComponent implements OnInit {
  @Input() clientProducts: ProductForClientMin[];

  @Output('openPatientEdit')
  openPatientEdit: EventEmitter<Product> = new EventEmitter();
  displayedColumns: string[] = [
    'RegisteredDate',
    'testType',
    'tubeId',
    'patientName',
    'patientId',
    'status',
    'theProductIsFor',
  ];
  currentUser: RegularUser;
  statuses: Status[];
  regularUser: RegularUser;
  product: Product;
  constructor(
    private productPusherService: ProductsForClientPusherServiceService,
    private productService: ProductsService,
    private regularUsersService: RegularUsersService,
    private popupHandlerService: PopupHandlerService,
    private statusesService: StatusesService,
    private popupHandler: PopupHandlerService,
    private router: Router
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }
  form;
  ngOnInit(): void {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
    this.productPusherService.errorMessage$.subscribe((err) => {
      if (err && err.message)
        this.popupHandlerService.openDisapearingAlertDialog(
          DisapearingTextDialogEnum.errorFromServer
        );
    });
    this.statusesService
      .getAll()
      .subscribe((statuses) => (this.statuses = statuses));
  }
  ngOnchanges() {
    if (this.clientProducts.length > 0) {
      var product = this.clientProducts[0];
      var a = product.status.name;
    }
  }
  checkStatusDisabled({ status }) {
    var { statusId } = status;
    if (
      statusId !== ProductEnum.Missingdetails &&
      statusId !== ProductEnum.NotRegistered
    ) {
      return true;
    } else {
      return false;
    }
  }
  editPatientDetails(product) {
    this.openPatientEdit.emit(product);
  }
  getProductForVal(product: ProductForClientMin) {
    return product.isMe ? 1 : 2;
  }
  changeProductPatient(event, product: ProductForClientMin) {
    var a = event.value;
    if (event.value == 1) {
      product.isMe = true;
    } else {
      product.isMe = false;
    }
    this.productPusherService.changePatient(product);
    // product.patient = product.client;
  }
  changeStatusToSentToCustomer(product: ProductForClientMin) {
    if (product.statusId == ProductEnum.SendToCustomer) {
      this.productService.getById(product.productId).subscribe((data) => {
        if ((data as Product).productId) {
          this.product = data as Product;
          if (this.product.patient.regularUser.gender == '') {
            this.popupHandlerService.openDisapearingAlertDialog(
              DisapearingTextDialogEnum.costumMessage,
              '',
              '',
              'Please Fill Gender Type',
              2000
            );
            return;
          }
          product.statusId = ProductEnum.SentToCustomer;

          product.status = this.statuses.find(
            (x) => x.statusId == product.statusId
          );
          this.productService
            .changeStatus(product.productId, product.statusId)
            .subscribe((statusChangeObj) => {
              if (statusChangeObj.isProblems) {
                var errorsHtml =
                  "status wasn't Changed:<br/>" +
                  statusChangeObj.list.map((x) => x.name + '<br>').join();
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.errorFromServer,
                  '',
                  '',
                  errorsHtml,
                  4000
                );
                product.statusId = statusChangeObj.oldStatusId;
                product.status = this.statuses.find((x) => {
                  return x.statusId == statusChangeObj.oldStatusId;
                });
              } else {
                var actionsIds = statusChangeObj.actionsList
                  .map((x) => x.eventActionId)
                  .join(',');
                this.productService
                  .StatusChangeActions(product.productId, actionsIds)
                  .subscribe((actionResponses) => {
                    var txt = actionResponses
                      .map(
                        (res) =>
                          `<span class="${
                            res.isSuccess ? 'color-green' : 'color-red'
                          }">${res.actionName}  ${res.message}</span>`
                      )
                      .join('<br/>');
                    this.popupHandlerService.openDisapearingAlertDialog(
                      DisapearingTextDialogEnum.actionResults,
                      '',
                      '',
                      txt,
                      25000 * actionResponses.length
                    );
                  });
                /*
                this.popupHandlerService.openDisapearingAlertDialog(
                  DisapearingTextDialogEnum.packageSentToPatient
                );*/
              }
            });
        }
      });
    }
  }
  requestChangeBarcode(product: ProductForClientMin) {
    this.popupHandler.confirmDialogSubject.pipe(take(1)).subscribe((res) => {
      if (res.ans == 'yes' && res.caller == 'client-tests-change-barcode') {
        this.changeBarcode(product);
      }
    });
    this.popupHandler.openConfirmationDialog(
      '',
      'Change Barcode',
      'client-tests-change-barcode'
    );
  }
  isDeveloper() {
    return this.currentUser.userRole.userRoleName == 'Developer';
  }
  showProductLink() {
    return userHasPermisions(['Products_GetPage'], this.regularUser);
  }

  userHasRole(role: string) {
    if (!this.currentUser || !this.currentUser.userRole) return false;
    var userRole = this.currentUser.userRole.userRoleName;
    if (userRole === role) return true;
    return false;
  }
  goToPatient(product: ProductForClientMin) {
    this.router.navigate(['/tests/edit/'], {
      queryParams: { productId: product.productId },
    });
  }
  requestResetBarcode(product: ProductForClientMin) {
    this.popupHandler.confirmDialogSubject.pipe(take(1)).subscribe((res) => {
      if (res.ans == 'yes' && res.caller == 'client-tests-reset-barcode') {
        product.tubeId = null;
        this.changeBarcode(product);
      }
    });
    this.popupHandler.openConfirmationDialog(
      '',
      'Reset Pasword',
      'client-tests-reset-barcode'
    );
  }
  changeBarcode(product: ProductForClientMin) {
    this.productService
      .changeBarcodeV2(product.productId, product.tubeId)
      .subscribe((res: any) => {
        if (res && res.message) {
          this.popupHandlerService.openDisapearingAlertDialog(
            'errorFromServer',
            '',
            '',
            res.message
          );
        } else {
          this.popupHandlerService.openDisapearingAlertDialog(
            DisapearingTextDialogEnum.barcodeChanged
          );
        }
      });
  }
  userHasPermisionsWithRule(perms) {
    return userHasPermisionsWithRule(perms, this.regularUser);
  }
}
