<table mat-table [dataSource]="versions" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="lifeStyleReportVersionId">
    <th mat-header-cell *matHeaderCellDef style="width: 50px">Id</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lifeStyleReportVersionId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createDate">
    <th mat-header-cell *matHeaderCellDef style="width: 150px">createDate</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createDate | date: "yyyy/MM/dd" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="doneDate">
    <th mat-header-cell *matHeaderCellDef style="width: 150px">doneDate</th>
    <td mat-cell *matCellDef="let element">
      {{ element.doneDate | date: "yyyy/MM/dd" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="isDone">
    <th mat-header-cell *matHeaderCellDef style="width: 50px">isDone</th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        (change)="setDone(element)"
        [(ngModel)]="element.isDone"
        [disabled]="element.isDone"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="duplicateFrom">
    <th mat-header-cell *matHeaderCellDef style="width: 150px">
      duplicate From
    </th>
    <td mat-cell *matCellDef="let element">
      <select [(ngModel)]="element.duplicateFromId">
        <ng-container *ngFor="let item of versions">
          <option
            *ngIf="
              item.lifeStyleReportVersionId != element.lifeStyleReportVersionId
            "
            [value]="item.lifeStyleReportVersionId"
          >
            {{ item.name }}
          </option>
        </ng-container>
      </select>
      <button [disabled]="element.isDone" (click)="onDuplicateClick(element)">
        duplicate
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef style="width: 200px">name</th>
    <td mat-cell *matCellDef="let element">
      <input
        style="width: 130px"
        (change)="saveChanges(element)"
        type="text"
        [(ngModel)]="element.name"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="default">
    <th mat-header-cell *matHeaderCellDef style="width: 50px">default</th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        (change)="setDefault(element)"
        [(ngModel)]="element.default"
        [disabled]="!element.isDone"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef style="width: 100px">edit</th>
    <td mat-cell *matCellDef="let element">
      <button (click)="editVersion()" mat-mini-fab>
        <mat-icon>mode_edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    style="width: 200px"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<button mat-mini-fab (click)="addVersion()">+</button>
<button mat-mini-fab routerLink="/lifestyle-tree2">
  <mat-icon>mode_edit</mat-icon>
</button>
