import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-style-locations',
  templateUrl: './life-style-locations.component.html',
  styleUrls: ['./life-style-locations.component.css']
})
export class LifeStyleLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
