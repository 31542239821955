import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppModule } from '../data/AppModule';

@Injectable({
  providedIn: 'root',
})
export class AppModulesService {
  deleteForRole(roleId: number, moduleId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}AppModules/DeleteForRole/${roleId}/${moduleId}`
    );
  }
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<AppModule[]>(`${environment.baseUrl}AppModules/`);
  }
  getExistingModulesForRole(roleId: number) {
    return this.http.get<AppModule[]>(
      `${environment.baseUrl}AppModules/ExistingModulesForRole/${roleId}`
    );
  }
  getMissingModulesForRole(roleId: number) {
    return this.http.get<AppModule[]>(
      `${environment.baseUrl}AppModules/MissingModulesForRole/${roleId}`
    );
  }
}
