import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { AlgoBulletLink } from 'src/app/data/AlgoBulletLink';
import { AlgoBulletLinksService } from 'src/app/Services/algo-bullet-links.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-all-algo-bullet-links',
  templateUrl: './all-algo-bullet-links.component.html',
  styleUrls: ['./all-algo-bullet-links.component.css'],
})
export class AllAlgoBulletLinksComponent implements OnInit {
  algoBulletLinks: any[];
  displayedColumns: string[] = ['icon', 'linkPageName', 'lastChange', 'action'];
  langId = 1;
  constructor(
    private algoBulletsLinksService: AlgoBulletLinksService,
    private popupHandlerService: PopupHandlerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAlgoLinks();
  }
  getAlgoLinks() {
    this.algoBulletsLinksService.getAll(this.langId).subscribe((data) => {
      this.algoBulletLinks = data;
    });
  }
  delete(event, id: number) {
    event.preventDefault();
    event.stopPropagation();
    this.popupHandlerService.openConfirmationDialog('', 'Delete Link');
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data.ans === 'yes') {
        this.algoBulletsLinksService.delete(id).subscribe((data) => {
          this.getAlgoLinks();
          this.popupHandlerService.openDisapearingAlertDialog('success');
        });
      }
    });
  }

  createNew() {
    this.router.navigate(['/algo-bullet-links/edit'], {
      queryParams: { linkId: '0' },
    });
  }

  goTo(linkId: number) {
    this.router.navigate(['algo-bullet-links/edit'], {
      queryParams: { linkId },
    });
  }
}
