import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AgentsService } from 'src/app/Services/agents.service';
import { ServiceRequestsPusherService } from 'src/app/Services/service-requests-pusher.service';

import { AgentMin } from 'src/app/data/AgentMin';
import { ClientForTableMin } from 'src/app/data/ClientForTableMin';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadSource } from 'src/app/data/LeadSource';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { PaginationMetadata } from 'src/app/data/PaginationMetadata';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { Status } from 'src/app/data/Status';
import { PageTypeEnum } from 'src/app/Enums/PageTypeEnum';
import { FilterMemoryService } from 'src/app/Services/filter-memory.service';
import { StatusesService } from 'src/app/Services/statuses.service';
import { StorageService } from 'src/app/Services/storage.service';
import { FoodReportRequest } from 'src/app/data/food-genes/FoodReportRequest';
import { FoodClientRequestService } from 'src/app/Services/food-client-request.service';
import { ClientRequestSubject } from 'src/app/data/food-genes/ClientRequestSubject';
import { ClientRequestStatus } from 'src/app/data/food-genes/ClientRequestStatus';

@Component({
  selector: 'app-all-service-requests',
  templateUrl: './all-service-requests.component.html',
  styleUrls: ['./all-service-requests.component.css'],
})
export class AllServiceRequestsComponent implements OnInit {
  leadsData: ClientForTableMin[];
  statuses: Status[];
  agents: AgentMin[];
  leadSources: LeadSource[];
  //statuses;
  checkedIds: any;
  picker;
  date;
  checkedAll: boolean = false;
  selectedAgentText = 'Agent: All';
  source;
  @ViewChild('toggle') toggleDatepicker: ElementRef;
  headFilter: HeadFilter;
  metaData: PaginationMetadata;
  leadStatusesTable: LeadStatus[];
  serviceRequests: RequestForTableMin[] | FoodReportRequest[];
  requestsSubjects: Status[] | ClientRequestSubject[];
  requestsSubjectsTemp: Status[];
  appRequestsSubjects: Status[];
  requestsStatuses: Status[] | ClientRequestStatus[];
  requestsStatusesTemp: Status[];
  appRequestsStatuses: Status[];
  isLoading: boolean;
  langId = 1;
  constructor(
    private serviceRequestsService: ServiceRequestsPusherService,
    private agentsService: AgentsService,
    private statusesService: StatusesService,
    private filterMemoryService: FilterMemoryService,
    private storageService: StorageService,
    private foodClientRequestService: FoodClientRequestService
  ) {
    this.headFilter = this.filterMemoryService.getfilter(
      PageTypeEnum.serviceRequests
    );
  }

  ngOnInit(): void {
    this.serviceRequestsService.requests$.subscribe((data) => {
      this.isLoading = false;
      this.serviceRequests = data;
      if (this.serviceRequests && this.serviceRequests.length > 0)
        this.serviceRequests = this.serviceRequests.map((x) => {
          var watchedObj = this.storageService.getServiveRequestCommentsIds(
            x.serviceRequestId
          );
          var watched = Object.keys(watchedObj).map((key) => Number(key));
          x.numComments =
            x.notesIds.length -
            x.notesIds.filter((value) => watched.includes(value)).length;
          return x;
        });
    });
    this.serviceRequestsService.appReportRequests$.subscribe((data) => {
      this.isLoading = false;
      this.serviceRequests = data;
    });
    this.serviceRequestsService.metaData$.subscribe((data) => {
      this.metaData = data;
    });
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data;
    });
    this.statusesService.getAll().subscribe((data) => {
      //  this.statuses = data;
      this.statuses = data;
      this.requestsSubjects = this.statuses.filter((x) => {
        return x.category == 'RequestSubject';
      });
      //not to change the original array
      this.requestsSubjectsTemp = this.requestsSubjects;
      this.requestsStatuses = this.statuses.filter((x) => {
        return x.category == 'RequestStatus';
      });
      this.requestsStatusesTemp = this.requestsStatuses;
    });
    this.getAppReportsSubjects();
    this.getAppReportsStatuses();
    this.serviceRequestsService.setMode('all');
    this.getAll();
  }
  onCheckedIdsChanged(checkedIds) {
    this.checkedIds = checkedIds;
  }
  onAppReportClicked(e: boolean) {
    if (e) {
      this.requestsSubjects = this.appRequestsSubjects;
      this.requestsStatuses = this.appRequestsStatuses;
    } else {
      this.requestsSubjects = this.requestsSubjectsTemp;
      this.requestsStatuses = this.requestsStatusesTemp;
    }
  }

  getAppReportsSubjects() {
    this.foodClientRequestService
      .getFoodRequestSubject(this.langId)
      .subscribe((data) => {
        this.appRequestsSubjects = data.map((x) => {
          let subject = new Status();
          subject.statusId = x.clientRequestSubjectId;
          subject.name = x.name;
          subject.category = 'appRequestSubject';
          return subject;
        });
      });
  }
  getAppReportsStatuses() {
    this.foodClientRequestService
      .getFoodRequestStatuses(this.langId)
      .subscribe((data) => {
        this.appRequestsStatuses = data.map((x) => {
          let subject = new Status();
          subject.statusId = x.clientRequestStatusId;
          subject.name = x.name;
          subject.category = 'appRequestStatus';
          return subject;
        });
      });
  }

  getAllWithFilter(
    e,
    isFromHead: boolean = false,
    isFormPaginator: boolean = false
  ) {
    if (isFromHead) {
      this.checkedIds = {};
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }
    if (isFormPaginator) {
      this.checkedAll = true;
      setTimeout(() => {
        this.checkedAll = false;
      }, 10);
    }
    this.headFilter = e;
    this.filterMemoryService.setfilter(
      this.headFilter,
      PageTypeEnum.serviceRequests
    );
    this.getAll();
    console.log('getAllWithFilter');
  }
  changeCheckedIds(newCheckedIds) {
    this.checkedIds = newCheckedIds;
  }
  getAll() {
    this.isLoading = true;
    this.serviceRequestsService.getAll(this.headFilter);
  }
}
