<table mat-table [dataSource]="patientDeliveries" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="patientId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%">
      Patient Id
    </th>
    <td mat-cell *matCellDef="let delivery">{{ delivery.patientId }}</td>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%">
      Patient Name
    </th>
    <td mat-cell *matCellDef="let delivery">
      <span
        style="text-decoration: underline; color: blue; cursor: pointer"
        (click)="goToPatient(delivery)"
      >
        {{ delivery.patientName }}
      </span>
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="deliveryType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%">
      Delivery Type
    </th>
    <td mat-cell *matCellDef="let delivery">
      {{ delivery.deliveryType }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <!--
  <ng-container matColumnDef="patientStatus">
    <th mat-header-cell *matHeaderCellDef>Weight</th>
    <td mat-cell *matCellDef="let delivery">
      {{ delivery.patient.status.name }}
    </td>
  </ng-container>
  -->
  <!-- Symbol Column -->
  <ng-container matColumnDef="createDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">
      Create Date
    </th>
    <td mat-cell *matCellDef="let delivery" style="text-align: center">
      {{ delivery.createDate | date: "dd/MM/yyyy HH:mm:ss" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="error">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">
      Response
    </th>
    <td
      mat-cell
      *matCellDef="let delivery"
      style="text-align: center; max-width: 10%; overflow: hidden"
    >
      {{ delivery.error && delivery.error.toLowerCase() }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">
      Status
    </th>
    <td mat-cell *matCellDef="let delivery" style="text-align: center">
      {{ delivery.status }}
    </td>
  </ng-container>
  <ng-container matColumnDef="lastDeliveryStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">
      Last Status
    </th>
    <td mat-cell *matCellDef="let delivery" style="text-align: center">
      {{ delivery.lastDeliveryStatus }}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      style="width: 20%"
    ></th>
    <td
      mat-cell
      *matCellDef="let delivery"
      style="text-align: center; display: inline"
    >
      <button
        style="width: 100px"
        *ngIf="!delivery.error || delivery.error.toLowerCase() != 'ok'"
        (click)="resend(delivery)"
      >
        Re-Send
      </button>
      <button style="width: 100px" (click)="gotoDeliveryDetails(delivery)">
        Details
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
