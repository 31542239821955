import { Component, OnInit } from '@angular/core';
import { FoodGenesService } from 'src/app/Services/food-genes.service';
import { Location } from '@angular/common';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { FoodAlert } from 'src/app/data/food-genes/FoodAlert';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Language } from 'src/app/data/Language';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tags-table',
  templateUrl: './tags-table.component.html',
  styleUrls: ['./tags-table.component.scss'],
})
export class TagsTableComponent implements OnInit {
  langId: number = 1;
  alerts: FoodAlert[];
  displayedColumns: string[] = ['name', 'desc', 'color', 'image', 'action'];
  dataSource: MatTableDataSource<FoodAlert> = new MatTableDataSource([]);
  constructor(
    private foodGenesService: FoodGenesService,
    public location: Location,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts() {
    this.foodGenesService.getAlerts(this.langId, 0, 15).subscribe((data) => {
      this.alerts = data;
      this.setDataSource(this.alerts);
    });
  }
  editAlert(alert: FoodAlert) {
    this.popupHandlerService.openAddNewAlert(alert, this.langId);

    this.popupHandlerService.addNewAlertSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.type == 'save') {
          this.getAlerts();
        }
      });
  }

  onChangeLang(lang: Language) {
    this.langId = lang.languageId;
    this.getAlerts();
  }

  setDataSource(data: FoodAlert[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  getImage(alert: FoodAlert) {
    if (!alert?.icon) return;
    const imagePath = `${alert.icon.webPath}/${alert.icon.fileName}`;
    return this.replaceWebPath(imagePath);
  }
}
