import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Language } from 'src/app/data/Language';
import { LifestyleAnswerMin } from 'src/app/data/LifestyleAnswerMin';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { QuestionDesignType } from 'src/app/Enums/StatusesEnum';
import { LanguagesService } from 'src/app/Services/languages.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { isValidUtil, refAnsweredUtil } from 'src/app/Utils/lifestyleUtil';
import { TestGeneralInformationComponent } from '../../Tests/test-general-information/test-general-information.component';

@Component({
  selector: 'app-lifestyle-report-edit-new',
  templateUrl: './lifestyle-report-edit-new.component.html',
  styleUrls: ['./lifestyle-report-edit-new.component.scss'],
})
export class LifestyleReportEditNewComponent
  implements OnInit, AfterViewChecked
{
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  @Input() isDone: boolean = false;
  @Output('updateLifestlyeDone') updateLifestlyeDone: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('goToPreview') goToPreview: EventEmitter<any> =
    new EventEmitter<any>();
  productId: number;
  langId: number = 1;
  langs: Language[];
  showIndex: number = 0;
  visibleIndex: number = 0;
  validIndex: number = 0;
  showNextBtn: boolean = false;
  isFirstTime: boolean = true;
  lifestyleQuestions: LifestyleQuestion[];
  isLoading: boolean;
  needToScrollToButtom;
  //lifestyleQuestions: any;
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private route: ActivatedRoute,
    private lansService: LanguagesService
  ) {}

  ngOnInit(): void {
    this.productId = +this.route.snapshot.queryParamMap.get('productId');
    this.getQuestions();
    this.getLangs();
  }

  getLangs() {
    this.lansService.getAll().subscribe((data) => (this.langs = data));
  }

  onIcrementIndex(index) {
    //console.log('onIcrementIndex', index);

    //this.setIndex(index);
  }

  getQuestions() {
    this.lifestyleQuestionsService
      .getByProductId(this.productId, this.langId)
      .subscribe((data) => {
        this.lifestyleQuestions = data;
        this.setIndex(this.showIndex);
        this.isFirstTime = false;
      });
    /*
    this.productId$.subscribe((data) => {
      if (data) {
        this.productId = data;
        this.store.dispatch(
          loadLifestyleQuestions({
            productId: this.productId,
            langId: this.langId,
          })
        );
      }
    });
    this.lifestyleQuestions$.subscribe((data) => {
      let hasData = this.lifestyleQuestions ? true : false;
      if (data) {
        this.lifestyleQuestions = data;
        if (!hasData) this.setIndex(this.showIndex);
      }
    });
    */
  }

  hasRefId(question: LifestyleQuestion) {
    if (question.referenceQuestionId) {
      return true;
    }
    return false;
  }
  refAnswered(refId) {
    //console.log('refAnswered from root');
    return refAnsweredUtil(this.lifestyleQuestions, refId);
  }
  isValid(node: LifestyleQuestion) {
    return isValidUtil(node);
  }
  /*
  isValid(node: LifestyleQuestion) {
   
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter &&
      node.designTypeId !== QuestionDesignType.successThumb
    ) {
      return false;
    }
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }

    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }
    
    //console.log('is valid node:', node.lifestyleQuestionId);

    return true;
  }
*/
  isToShow(node: LifestyleQuestion) {
    //console.log('isToShow' + node);
    if (
      node.referenceQuestionId &&
      !this.refAnswered(node.referenceQuestionId)
    ) {
      return true;
    }
    //console.log('isToShow 1' + node);
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter &&
      node.designTypeId !== QuestionDesignType.successThumb
    ) {
      return false;
    }
    //console.log('isToShow 2' + node);
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }
    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }

    //console.log('is valid node:', node.lifestyleQuestionId);

    return true;
  }
  checkIndex() {
    if (this.isValid(this.lifestyleQuestions[this.showIndex])) {
      this.validIndex = this.showIndex;
      this.showNextBtn = true;
      if (this.lifestyleQuestions[this.showIndex].referenceQuestionId) {
        if (
          this.refAnswered(
            this.lifestyleQuestions[this.showIndex].referenceQuestionId
          )
        ) {
          //this.showIndex++;
        } else {
          this.showIndex += 1;
        }
      }
    } else {
    }
  }
  setIndex(index) {
    //console.log('setIndex', index);

    this.showIndex = 0;
    while (
      this.lifestyleQuestions[this.showIndex] &&
      this.isToShow(this.lifestyleQuestions[this.showIndex])
    ) {
      if (this.isValid(this.lifestyleQuestions[this.showIndex])) {
        this.validIndex = this.showIndex;
      }
      if (true) {
        // console.log('setIndex2', this.showIndex);
        if (this.lifestyleQuestions[this.showIndex].referenceQuestionId) {
          if (
            this.refAnswered(
              this.lifestyleQuestions[this.showIndex].referenceQuestionId
            )
          ) {
            this.showIndex++;
          } else {
            this.showIndex += 1;
          }
        } else {
          this.showIndex++;
        }
        //console.log('setIndex end', this.showIndex);
      }
    }

    if (this.showIndex == this.lifestyleQuestions.length && !this.isDone) {
      this.updateLifestlyeDone.next(true);
    }
    /*
    if (!this.isFirstTime) {
      if (this.validIndex < this.showIndex) this.showNextBtn = true;
      if (this.visibleIndex < this.showIndex) this.showNextBtn = true;
    }*/
  }
  trackByQuestionId(index, question: LifestyleQuestion) {
    return question ? question.lifeStyleReportVersionId : undefined;
  }
  submitAnswers() {}
  OnUpdateAnswer(event) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .updateAnswer(
        this.productId,
        event.questionId,
        event.inputTypeId,
        event.value
      )
      .subscribe(
        (answers) => {
          this.lifestyleQuestions = this.mergeQuestionsWithAnswers(
            answers,
            this.lifestyleQuestions
          );
          this.setIndex(0);
          //this.checkIndex();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
  mergeQuestionsWithAnswers(
    answers: LifestyleAnswerMin[],
    questions: LifestyleQuestion[]
  ) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];

      element.lifeStyleAnswers = answers.filter(
        (x) => x.lifestyleQuestionId == element.lifestyleQuestionId
      );
      if (!element.lifeStyleAnswers) element.lifeStyleAnswers = [];
      if (element.childQuestions)
        element.childQuestions = this.mergeQuestionsWithAnswers(
          answers,
          element.childQuestions
        );
    }
    return questions;
  }
  convertAnwerMintoAnswer(answerMin: LifestyleAnswerMin) {}
  getProgressBarMaxInx() {
    return Math.floor(this.lifestyleQuestions.length / 5) * 5;
  }
  finishAnswering() {
    this.goToPreview.emit(true);
  }
  showNextQuestion() {
    this.showNextBtn = false;
    this.needToScrollToButtom = true;

    this.showIndex++;
  }
  ngAfterViewChecked() {
    if (this.needToScrollToButtom) {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
      this.needToScrollToButtom = false;
    }
  }
}
