<mat-card class="card" *ngIf="deal && statuses">
  <div style="display: flex">
    <div style="width: 40px">
      <img
        *ngIf="deal.hasActiveCancelationRequest"
        width="20"
        src="../../../assets/active-cancel.svg"
        matTooltip="In cancelation request"
      />
    </div>
    <div style="width: 80px">
      {{ deal && deal.dateUpdated | date: "dd-MM-yyyy" }}
    </div>
    <div
      style="width: 120px; font-size: 1.2rem"
      [innerHtml]="getProductsStr(products)"
    ></div>
    <div style="width: 70px">
      {{ deal && deal.amount }}
    </div>
    <div style="width: 150px; cursor: pointer; align-items: center">
      <div
        class="round-indicator"
        style="
          font-size: 1.1rem;
          text-transform: uppercase;
          padding: 0.5rem 0.2rem;
        "
        [ngStyle]="{ 'background-color': getDealStatusColor(deal) }"
      >
        {{ getDealStatus(deal) }}
      </div>
    </div>
    <div style="width: 80px">
      {{ deal.paymentMethod && deal.paymentMethod.name }}
    </div>
  </div>
</mat-card>
