import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { FgCoefficient } from 'src/app/data/food-genes/FgCoefficient';
import { FgParam } from 'src/app/data/food-genes/FgParam';
import { FoodAlert } from 'src/app/data/food-genes/FoodAlert';
import { FgParamTypeEnum } from 'src/app/Enums/StatusesEnum';
import { FoodGenesService } from 'src/app/Services/food-genes.service';

@Component({
  selector: 'app-food-genes-params',
  templateUrl: './food-genes-params.component.html',
  styleUrls: ['./food-genes-params.component.scss'],
})
export class FoodGenesParamsComponent implements OnInit {
  @Input() param: FgParam;
  @Input() versionId: number;
  @Input() langId: number;
  @Input() canEdit: boolean;
  @Input() coefficients: FgCoefficient[];
  editMode: boolean;
  nutrients: BaseResponce[];
  alergans: BaseResponce[];
  bloodTest: BaseResponce[];
  alertParam: FoodAlert[];
  isLoading: boolean;
  userAutomaticTypes = [
    FgParamTypeEnum.UserDnaResult,
    FgParamTypeEnum.MaxCoefficient,
    FgParamTypeEnum.CoefficientMin,
    FgParamTypeEnum.CoefficientMax,
    FgParamTypeEnum.MinCoefficient,
    FgParamTypeEnum.FormulaResult,
  ];

  constructor(
    private foodGenesService: FoodGenesService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getDataByType();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  isStaticParam() {
    return this.param.fgParamType === FgParamTypeEnum.StaticParam;
  }

  isUserAutomatic() {
    return this.userAutomaticTypes.includes(this.param.fgParamType);
  }

  getNutrientName() {
    switch (this.param.fgParamType) {
      case FgParamTypeEnum.AlertParam:
        return this.alergans?.find((x) => x.id === this.param.fgParamKeyId)
          ?.name;
      case FgParamTypeEnum.AlertTag:
        return this.alertParam?.find(
          (x) => x.foodAlertId === this.param.fgParamKeyId
        )?.name;
      case FgParamTypeEnum.BloodTest:
        return this.bloodTest?.find((x) => x.id === this.param.fgParamKeyId)
          ?.name;
      case FgParamTypeEnum.NutrientParam:
        return this.nutrients?.find((x) => x.id === this.param.fgParamKeyId)
          ?.name;
      default:
        return '';
    }
  }

  getNameFromEnum() {
    return FgParamTypeEnum[this.param.fgParamType];
  }

  getDataByType() {
    switch (this.param.fgParamType) {
      case FgParamTypeEnum.AlertParam:
        this.getAlergans();
        break;
      case FgParamTypeEnum.AlertTag:
        this.getAlertParam();
        break;
      case FgParamTypeEnum.BloodTest:
        this.getBloodTest();
        break;
      case FgParamTypeEnum.NutrientParam:
        this.getNutrients();
        break;
    }
  }

  getNutrients() {
    this.isLoading = true;
    this.foodGenesService
      .getNutrientsForParams(this.langId)
      .subscribe((data) => {
        this.nutrients = data;
        this.isLoading = false;
      });
  }
  getAlergans() {
    this.isLoading = true;
    this.foodGenesService
      .getAlergensForParams(this.langId)
      .subscribe((data) => {
        this.alergans = data;
        this.isLoading = false;
      });
  }
  getBloodTest() {
    this.isLoading = true;
    this.foodGenesService
      .getBloodResultTypesForParams(this.langId)
      .subscribe((data) => {
        this.bloodTest = data;
        this.isLoading = false;
      });
  }
  getAlertParam() {
    this.isLoading = true;
    this.foodGenesService.getAlerts(this.langId, 0, 15).subscribe((data) => {
      this.alertParam = data;
      this.isLoading = false;
    });
  }
  save() {
    this.param.fgParamName = this.param.fgParamName.replace(/ /g, '_');
    this.foodGenesService
      .setParam(this.param, this.versionId)
      .subscribe((data) => {
        if ((data as ErrorMessage)?.message) {
          const message = (data as ErrorMessage).message;
          this._snackBar.open(message, 'Close');
        } else {
          this._snackBar.open('Success', 'Close', {
            duration: 2000,
          });
          this.editMode = false;
        }
      });
  }
}
