import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { base64StringToBlob } from 'blob-util';
import { AgentMin } from 'src/app/data/AgentMin';
import { BaseResponce } from 'src/app/data/algorithm/BaseResponce';
import { BarcodeStorageLot } from 'src/app/data/BarcodeStorageLot';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { Category } from 'src/app/data/Category';
import { ClientRequestSubject } from 'src/app/data/food-genes/ClientRequestSubject';
import { HeadFilter } from 'src/app/data/HeadFilter';
import { LeadStatus } from 'src/app/data/LeadStatus';
import { RegularUser } from 'src/app/data/RegularUser';
import { Status } from 'src/app/data/Status';
import { SubCategory } from 'src/app/data/SubCategory';
import { TubesPackage } from 'src/app/data/TubesPackage';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { TubePackageEnum } from 'src/app/Enums/StatusesEnum';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { LeadStasusesService } from 'src/app/Services/lead-stasuses.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { TubesPackagesService } from 'src/app/Services/tubes-packages.service';
import { saveAsHelper } from 'src/app/Utils/saveAsUtil';
import {
  userHasPermisions,
  userHasPermisionsWithRule,
} from '../../Utils/user-helper';

//import pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-head-filter',
  templateUrl: './head-filter.component.html',
  styleUrls: ['./head-filter.component.scss'],
})
export class HeadFilterComponent implements OnInit, OnChanges {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  rangeSecondary = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  @Input() isMainScreen: boolean = true;
  @Input() algoBullet: boolean;
  @Input() callsReviews: boolean;
  @Input() decisions: Status[];
  @Input() subjects: Status[];
  @Input() subCategories: SubCategory[];
  @Input() categories: Category[];
  @Input() videoCategories: VideoCategory[];
  @Input() lots: BarcodeStorageLot[];
  @Input() barcodeStatuses: BaseResponce[];
  @Input() bloodeTestTypes: BloodTestType[];
  @Input() checkedIds;
  @Input() agents: AgentMin[];
  @Input() followUpAgents: AgentMin[];
  nutrisionists: AgentMin[];
  @Input() classAttr;
  @Input() leadStatuses: LeadStatus[];
  @Input() leadStatusesGroup: LeadStatus[];
  @Input() leadSources;
  @Input() paymentMethods;
  @Input() requestsSubjects;
  @Output('filterChanged')
  filterChanged: EventEmitter<HeadFilter> = new EventEmitter();
  regularUser: RegularUser;
  source;
  date;
  //dateBegin: any;
  //dateEnd: any;
  algorithmVersion: number;
  @Input() headFilter: HeadFilter;
  fileObservable: any;
  agentsForAc: any[];
  isLoading: boolean = false;
  selectedStatusName: string;
  uploadResultsCsvPath: string;
  allValue: number = -1;
  /*
    agentFilterId = -1;
    statusFilterId = -1;
    //dateFilterId = -1;
    sourceFilterId = -1;
  
    orderBy: any;
    acending: any;
    pageSize: any;
    pageNumber: any;
    */

  constructor(
    private router: Router,
    private tubesPackagesService: TubesPackagesService,
    private regularUsersService: RegularUsersService,
    private exelDownloadService: ExelDownloadService,
    private leadStatusesService: LeadStasusesService,
    private popupHandlerService: PopupHandlerService
  ) {
    regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.headFilter &&
      (this.range.value.start != this.headFilter.dateStart ||
        this.range.value.end != this.headFilter.dateEnd)
    ) {
      this.range.patchValue({
        start: this.headFilter.dateStart,
        end: this.headFilter.dateEnd,
      });
    }
    if (
      this.headFilter &&
      (this.rangeSecondary.value.start != this.headFilter.secondStartDate ||
        this.rangeSecondary.value.end != this.headFilter.secondEndDate)
    ) {
      this.rangeSecondary.patchValue({
        start: this.headFilter.secondStartDate,
        end: this.headFilter.secondEndDate,
      });
    }
    if (
      changes &&
      changes.requestsSubjects &&
      !changes.requestsSubjects.isFirstChange()
    ) {
      if (
        (this.requestsSubjects as Status[])[0].category === 'appRequestSubject'
      ) {
        this.allValue = 0;
        this.changeAllValueToAgents(this.allValue);
      } else {
        this.allValue = -1;
        this.changeAllValueToAgents(this.allValue);
      }
    }
  }

  changeAllValueToAgents(val: number) {
    this.agentsForAc = this.agentsForAc.map((x) => {
      if (x.name === 'All') {
        x.agentId = val;
      }
      return x;
    });
  }

  onSelectVersion() {
    this.uploadResultsCsvPath =
      'UploadResultsCsv/dataV2/' + this.algorithmVersion;
  }

  showAgentFilter() {
    var permissions = [];
    switch (this.classAttr) {
      case 'leadsAttr':
        permissions = ['Leads_GetList'];
        break;
      case 'clientsAttr':
        permissions = ['Clients_GetList'];
        break;
      case 'dealsAttr':
        permissions = ['Deals_GetList'];
        break;
      case 'productsAttr':
        permissions = ['Products_GetList'];
        break;
      case 'requestsAttr':
        permissions = ['ServiceRequests_GetList'];
        break;
      case 'cancelationRequestAttr':
        permissions = ['Cancelations_GetList'];
        break;

      default:
        break;
    }
    return userHasPermisionsWithRule(permissions, this.regularUser);
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  addAllToLeadStatuses() {
    let leadStatusAll = new LeadStatus();
    leadStatusAll.name = 'All';
    leadStatusAll.leadStatusId = -1;
    leadStatusAll.color = null;
    leadStatusAll.childerns = [];
    leadStatusAll.parentId = 0;
    this.leadStatuses.unshift(leadStatusAll);
  }

  ngOnInit(): void {
    //const lastInx = this.leadStatuses.length - 1;
    /* this.leadStatuses.forEach((item, index) => {
      if (item.childerns.length > 0) {
        this.moveArrayItemToNewIndex(this.leadStatuses, index, 0);
      }
    }); */
    if (this.classAttr == 'leadsAttr') {
      this.addAllToLeadStatuses();
      this.selectedStatusName = this.getStatusName(this.leadStatusesGroup);
    }

    this.range.valueChanges.subscribe((val) => {
      if (
        this.range.value.end &&
        this.range.value.end != this.headFilter.dateEnd
      ) {
        this.headFilter.dateStart = this.range.value.start;
        this.headFilter.dateEnd = this.range.value.end;
        //console.log('log from init');

        this.getAllWithFilter();

        // alert('changed ' + this.range.value.start + ' ' + this.range.value.end);
      }
    });
    this.rangeSecondary.valueChanges.subscribe((val) => {
      if (
        this.rangeSecondary.value.end &&
        this.rangeSecondary.value.end != this.headFilter.secondEndDate
      ) {
        this.headFilter.secondStartDate = this.rangeSecondary.value.start;
        this.headFilter.secondEndDate = this.rangeSecondary.value.end;
        // console.log('log from init');

        this.getAllWithFilter();

        // alert('changed ' + this.range.value.start + ' ' + this.range.value.end);
      }
    });
    if (this.agents) {
      this.nutrisionists = this.agents;
      this.agentsForAc = [...this.agents];
      var agentHaveAllOption = this.agentsForAc.find((x) => x.agentId == -1);
      if (!agentHaveAllOption) {
        var all = { agentId: -1, name: 'All', isActive: true, roleTypeId: 0 };
        var notAssigned = {
          agentId: -2,
          name: 'Not Assigned',
          isActive: true,
          roleTypeId: 0,
        };
        this.agentsForAc.unshift(all, notAssigned);
      }
      //console.log(this.agents);
    }
    if (this.followUpAgents) {
      var nutHaveAllOption = this.followUpAgents.find((x) => x.agentId == -1);
      if (!nutHaveAllOption) {
        var all = { agentId: -1, name: 'All', isActive: true, roleTypeId: 0 };
        var notAssigned = {
          agentId: -2,
          name: 'Not Assigned',
          isActive: true,
          roleTypeId: 0,
        };
        this.followUpAgents.unshift(all, notAssigned);
      }
    }
    if (this.classAttr == 'barcodes') {
      this.headFilter.searchTerm =
        this.headFilter.searchTerm == 'null' ? '' : this.headFilter.searchTerm;
    }
  }

  getStatusName(leadStatusesGroup: LeadStatus[]) {
    for (let index = 0; index < leadStatusesGroup.length; index++) {
      const element = leadStatusesGroup[index];
      if (!element.childerns || element.childerns.length == 0) {
        if (element.leadStatusId == this.headFilter.statusId) {
          return element.name;
        }
      } else {
        for (let index = 0; index < element.childerns.length; index++) {
          const child = element.childerns[index];
          if (child.leadStatusId == this.headFilter.statusId) {
            return child.name;
          }
        }
      }
    }
  }

  getAgentNameById(agentId) {
    return this.agentsForAc?.find((x) => x.agentId == agentId)?.name;
  }

  getFollowUpAgentNameById(agentId) {
    return this.followUpAgents?.find((x) => x.agentId == agentId)?.name;
  }

  triggerToggleClickSecondary() {
    let element: HTMLElement = document.querySelector(
      '#toggleSecondary button'
    ) as HTMLElement;
    let e1 = document.querySelector('#toggleSecondary') as HTMLElement;
    element.click();
  }
  triggerToggleClick() {
    /*  let inputElement: HTMLElement = this.toggleDatepicker
      .nativeElement as HTMLElement;
    inputElement.click(); */
    let element: HTMLElement = document.querySelector(
      '#toggle button'
    ) as HTMLElement;
    let e1 = document.querySelector('#toggle') as HTMLElement;
    element.click();
  }
  clearDateSecondary(event) {
    event.stopPropagation();
    this.rangeSecondary.setValue({ start: null, end: null });
    /* this.headFilter.dateStartSecondary = null;
    this.headFilter.dateEndSecondary = null; */
    this.headFilter.secondEndDate = null;
    this.headFilter.secondStartDate = null;
    this.getAllWithFilter();
  }
  clearDate(event) {
    event.stopPropagation();
    this.range.setValue({ start: null, end: null });
    this.headFilter.dateStart = null;
    this.headFilter.dateEnd = null;
    this.getAllWithFilter();
  }
  onStatusFilterChange(event) {
    //console.log(event);
    this.headFilter.statusId = event.value;
    this.getAllWithFilter();
  }
  onStatusFilterChangeNew(status: LeadStatus) {
    this.selectedStatusName = status.name;
    this.headFilter.statusId = status.leadStatusId;
    this.getAllWithFilter();
  }
  onSourceFilterChange(event) {
    this.headFilter.sourceId = event.value;
    this.getAllWithFilter();
  }
  onAgentFilterChange(event) {
    this.headFilter.agentId = event;
    this.getAllWithFilter();
  }
  onAgent2FilterChange(event) {
    this.headFilter.agent2Id = event;
    this.getAllWithFilter();
  }
  onPaymenetMethodFilterChange(event) {
    this.headFilter.paymentMethodId = event.value;
    this.getAllWithFilter();
  }
  onDateFilterChanged(event) {
    //alert('onDateFilterChanged');
    this.headFilter.dateStart = event.begin;
    this.headFilter.dateEnd = event.end;
    this.getAllWithFilter();
  }
  onDateFilterChangedSecondary(event) {
    //alert('onDateFilterChanged');
    this.headFilter.secondEndDate = event.begin;
    this.headFilter.secondStartDate = event.end;
    this.getAllWithFilter();
  }
  onRequestSubjectFilterChange(event) {
    this.headFilter.subjectId = event.value;
    this.getAllWithFilter();
  }
  onCallNoteTypeFilterChange(event) {
    this.headFilter.noteTypeId = event.value;
    this.getAllWithFilter();
  }
  onCallSubjectFilterChange(event) {
    this.headFilter.subjectId = event.value;
    this.getAllWithFilter();
  }
  onRatingFilterChange(event) {
    this.headFilter.rating = event.value;
    this.getAllWithFilter();
  }
  onLifeStyleFilterChange(event) {
    this.headFilter.lifeStyle = event.value;
    this.getAllWithFilter();
  }
  onFirstCallFilterChange(event) {
    this.headFilter.firstCall = event.value;
    this.getAllWithFilter();
  }
  onFollowFilterChange(event) {
    this.headFilter.onFollow = event.value;
    this.getAllWithFilter();
  }
  onCategoryFilterChange(e) {
    this.headFilter.categoryId = e.value;
    this.getAllWithFilter();
  }
  onSubCategoryFilterChange(e) {
    this.headFilter.subCategoryId = e.value;
    this.getAllWithFilter();
  }
  onBloodTestFilterChange(e) {
    this.headFilter.bloodTestTypeId = e.value;
    this.getAllWithFilter();
  }

  moveToEditScreen(clientId = -1) {
    this.router.navigate(['/leads/edit/'], {
      queryParams: { clientId: clientId, isEditMode: true },
    });
  }
  createNewDelivery(deliveryId = -1) {
    this.tubesPackagesService
      .post(new TubesPackage())
      .subscribe((tubesPackage) => {
        this.router.navigate([`/labDeliveries/office/edit`], {
          queryParams: { packageId: tubesPackage.tubesPackageId },
        });
      });
  }

  getAllWithFilter() {
    this.headFilter.pageNumber = 1;
    /*
    var headFilter = new HeadFilter(
      this.headFilter.,
      this.statusFilterId,
      this.dateBegin,
      this.dateEnd,
      this.sourceFilterId,
      this.headFilter.orderBy,
      this.headFilter.acending,
      this.headFilter.pageSize,
      this.headFilter.pageNumber
    );
    */
    //this.headFilter.dateStart = new Date(this.headFilter.dateStart);
    //this.headFilter.dateEnd = new Date(this.headFilter.dateStart);

    this.filterChanged.emit(this.headFilter);
  }
  isShowClosed() {
    return this.headFilter.statuseIds.includes(TubePackageEnum.Closed);
  }
  onShowClosedClicked(event) {
    if (this.isShowClosed())
      this.headFilter.statuseIds = [TubePackageEnum.Final];
    else
      this.headFilter.statuseIds = [
        TubePackageEnum.Final,
        TubePackageEnum.Closed,
      ];
    this.filterChanged.emit(this.headFilter);
  }
  exportDealToExel() {
    var checkedIdsStr = '';
    for (var key in this.checkedIds) {
      checkedIdsStr += key + ',';
    }
    var url = this.exelDownloadService
      .getDealsByFilterNew(this.headFilter)
      .subscribe((data) => {
        // this.downloadFile(data);
        this.fileObservable = data;
        let contentType = 'text/csv';
        //let blob = base64StringToBlob(this.fileObservable.fileContent, contentType);\
        let blob = base64StringToBlob(data, contentType);
        var now = new Date();
        var timeStamp = now.toDateString().split(' ').join('_');

        saveAsHelper(blob, `transactions_${timeStamp}.csv`);
      });
  }
  exportBarcodesToExel() {
    var checkedIdsStr = '';
    for (var key in this.checkedIds) {
      checkedIdsStr += key + ',';
    }
    this.exelDownloadService
      .generateBarcodesExcelFile(this.headFilter)
      .subscribe((data) => {
        this.fileObservable = data;
        let contentType = 'text/csv';
        let blob = base64StringToBlob(data, contentType);
        var now = new Date();
        var timeStamp = now.toDateString().split(' ').join('_');

        saveAsHelper(blob, `barcodes_${timeStamp}.csv`);
      });
  }
  exportProductsToExel() {
    var url = this.exelDownloadService.getProductsByFilter(this.headFilter);
    window.open(url, '_blank');
  }
  canManageNuts() {
    return userHasPermisions(['Asign Nutritionist'], this.regularUser);
  }
  goToUploadHistory() {
    this.router.navigate([`/labs/packages`]);
  }
  uploadDnaPdfsFinished(a) {
    if (a == false) this.isLoading = true;
    if (a == true) this.isLoading = false;

    //this.isLoading = false;
  }
  onDecisionFilterChange(event) {
    this.headFilter.decisionId = event.value;
    this.getAllWithFilter();
  }
  userHasPermisions(premissions: string[]) {
    return userHasPermisions(premissions, this.regularUser);
  }
  downloadFile(data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
  onAlgorithmVersionChange(val) {
    //console.log(val);
    this.uploadResultsCsvPath =
      'UploadResultsCsv/dataV2/' + this.algorithmVersion;
  }

  openNewVideoDialog() {
    this.popupHandlerService.openAddNewVideoDialog(
      -1,
      this.agents,
      this.videoCategories
    );
  }
  openVideoCategoriesDialog() {
    this.popupHandlerService.openVideoCategoriesDialog();
  }

  /*uploadDnaPdfs(data: BarcodeResultsGroup[]) {
    var pdfs = [];
    /*
    data.forEach((group) => {
      var pdfData = this.generatePdf(group);
      pdfs.push(pdfData);
    });
    var examplePdf = this.generatePdf(data[0]);
    pdfMake.createPdf(examplePdf).open();
  }
  generatePdf(reportData: BarcodeResultsGroup) {
    let docDefinition = {
      header: 'C#Corner PDF Header',
      content:
        'Sample PDF generated with Angular and PDFMake for C#Corner Blog',
    };
    reportData.results.forEach((result) => {});
    return docDefinition;
  } */
}
