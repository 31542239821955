import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgorithmVersion } from '../data/AlgorithmVersion';

@Injectable({
  providedIn: 'root',
})
export class AlgorithmVersionService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<AlgorithmVersion[]>(
      `${environment.baseUrl}AlgorithmVersions`
    );
  }
}
