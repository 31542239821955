<div class="calendar">
  <div class="calendar__title">
    <strong>Calendar</strong>
    <button [routerLink]="['/meeting-settings']" mat-button>
      Meeting Settings
    </button>
  </div>
  <div class="calendar__table">
    <div class="calendar__table__header">
      <span class="calendar__table__header__name"
        >Dietitians / Notrition consultants</span
      >
      <span class="calendar__table__header__role">Role</span>
      <span class="calendar__table__header__status">Status</span>
    </div>
    <mat-card
      matRipple
      *ngFor="let item of agents"
      class="calendar__table__item"
      [routerLink]="['/agent-schedule']"
      [queryParams]="{ agentId: item.agentId }"
    >
      <span class="calendar__table__item__name"> {{ item.firstName }}</span>
      <span class="calendar__table__item__role"> {{ item.role.roleName }}</span>
      <span class="calendar__table__item__status">
        <span [class.active]="item.status">{{
          item.status ? "Active" : "Inactive"
        }}</span>
      </span>
    </mat-card>
  </div>
</div>
