<form
  *ngIf="requestForm"
  [formGroup]="requestForm"
  (ngSubmit)="onSaveForm()"
  class="example-form"
>
  <div class="row-container">
    <mat-list style="padding-top: 0">
      <mat-list-item>
        <div class="d-flex-between">
          <span style="font-size: 2rem" class="card-title">Request Info </span>
          <button
            type="submit"
            mat-button
            class="rect-action-btn rect-action-btn--client"
          >
            Save
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            (click)="onCancelForm($event)"
            mat-button
            class="btn-cancel-form--client"
          >
            Cancel
          </button>
        </div>
      </mat-list-item>
      <div style="margin: 0 0 1rem 0" class="divider divider--client"></div>
      <mat-list-item class="row-container__item" *ngIf="subjects">
        <mat-form-field appearance="fill">
          <mat-label>Subject</mat-label>
          <mat-select
            (selectionChange)="getSubjectName($event)"
            formControlName="subject"
          >
            <mat-option
              *ngFor="let subject of subjects"
              [value]="subject.statusId"
            >
              {{ subject.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item
        class="row-container__item"
        style="height: auto !important; margin: 1rem 0"
      >
        <angular-editor
          [placeholder]="'Content'"
          name="desc"
          formControlName="desc"
          [config]="config"
        ></angular-editor
      ></mat-list-item>
    </mat-list>
  </div>
</form>
