import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { AgentMin } from 'src/app/data/AgentMin';
import { OfficePhoneExtension } from 'src/app/data/OfficePhoneExtension';
import { OfficePhoneExtensionGroup } from 'src/app/data/OfficePhoneExtensionGroup';
import { RoleType } from 'src/app/data/RoleType';
import { UserRoleEmum } from 'src/app/Enums/StatusesEnum';

import { AgentsService } from 'src/app/Services/agents.service';
import { OfficePhoneExtensionsService } from 'src/app/Services/office-phone-extensions.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RoleTypesService } from 'src/app/Services/role-types.service';

@Component({
  selector: 'app-office-phone-extensions',
  templateUrl: './office-phone-extensions.component.html',
  styleUrls: ['./office-phone-extensions.component.scss'],
})
export class OfficePhoneExtensionsComponent implements OnInit {
  @ViewChild('table', { static: true }) table;
  @ViewChildren('extensionNameFields')
  extensionNameFields: QueryList<MatFormField>;
  @ViewChildren('extensionAgentFields')
  extensionAgentFields: QueryList<MatFormField>;
  @ViewChildren('extensionGroupFields')
  extensionGroupFields: QueryList<MatFormField>;

  officePhoneExtensions: MatTableDataSource<OfficePhoneExtension> =
    new MatTableDataSource([]);
  officePhoneExtensionGroups: OfficePhoneExtensionGroup[];
  agents: AgentMin[];
  displayedColumns: string[] = [
    'extNumber',
    'agent',
    'departement',
    'status',
    'action',
  ];
  editMode: boolean;
  rowIdToEdit: number = 0;
  newRowId: number = 0;
  groupId: number = 0;
  isActive: number = -1;
  errorMassage: string;

  constructor(
    private officePhoneExtensionsService: OfficePhoneExtensionsService,
    private agentsService: AgentsService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.getFilteredExtension(false);
    this.getAllGroups();
    this.getAgents();
  }

  // UI Logic
  renderRows() {
    this.officePhoneExtensions.data = [...this.officePhoneExtensions.data];
  }
  isEditMode(id: number) {
    return this.editMode && this.rowIdToEdit == id;
  }
  toggleEditMode(element: OfficePhoneExtension = null) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.rowIdToEdit = element.officePhoneExtensionId;
    } else {
      this.rowIdToEdit = 0;
      this.newRowId = 0;
      if (element?.officePhoneExtensionId <= 0) {
        const index = this.officePhoneExtensions.filteredData.indexOf(element);
        this.officePhoneExtensions.data.splice(index, 1);
        this.renderRows();
      }
    }
  }
  addNewExt() {
    let newExt = new OfficePhoneExtension();
    newExt.officePhoneExtensionId = 0;
    this.newRowId = 0;
    this.toggleEditMode(newExt);
    this.officePhoneExtensions.data.push(newExt);
    this.renderRows();
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
  save(element: OfficePhoneExtension) {
    if (element.officePhoneExtensionId == 0) {
      if (!element.phoneExtensionName) {
        this.extensionNameFields
          .toArray()[0]
          ._elementRef.nativeElement.classList.add('error');
      }
      if (!element.agentId) {
        this.extensionAgentFields
          .toArray()[0]
          ._elementRef.nativeElement.classList.add('error');
      }
      if (!element.officePhoneExtensionGroupId) {
        this.extensionGroupFields
          .toArray()[0]
          ._elementRef.nativeElement.classList.add('error');
      }
      if (
        element.phoneExtensionName &&
        element.agentId &&
        element.officePhoneExtensionGroupId
      ) {
        this.post(element);
      }
    } else {
      this.put(element);
    }
  }
  getAgentName(agentId: number) {
    return this.agents.find((x) => x.agentId == agentId)?.name;
  }
  getGroupName(groupId: number) {
    return this.officePhoneExtensionGroups.find(
      (x) => x.officePhoneExtensionGroupId == groupId
    )?.name;
  }
  toggleStatus(element: OfficePhoneExtension) {
    element.isActive = !element.isActive;
    if (element.isActive) {
      this.subscribe(element.officePhoneExtensionId);
    } else {
      this.unSubscribe(element.officePhoneExtensionId);
    }
  }

  saveAgentForExt(event: number, element: OfficePhoneExtension) {
    element.agentId = event;
  }
  getAgentNameById(agentId: number) {
    const agentName = this.agents.find((x) => x.agentId == agentId)?.name;
    return agentName;
  }

  // Services
  getAgents() {
    this.agentsService.getAll().subscribe((data) => {
      this.agents = data.filter(
        (x) =>
          x.roleTypeId == UserRoleEmum.Sales ||
          x.roleTypeId == UserRoleEmum.SalesTeamLeader ||
          x.roleTypeId == UserRoleEmum.SalesManager ||
          x.roleTypeId == UserRoleEmum.SupportManager ||
          x.roleTypeId == UserRoleEmum.Support ||
          x.roleTypeId == UserRoleEmum.nutritionist ||
          x.roleTypeId == UserRoleEmum.nutritionistManager ||
          x.roleTypeId == UserRoleEmum.followUpAgent
      );
    });
  }
  getFilteredExtension(onUpdate = true) {
    this.officePhoneExtensionsService
      .getFilteredExtension(this.groupId, this.isActive)
      .subscribe((data) => {
        this.officePhoneExtensions.data = data;
        if (onUpdate) {
          this.popupHandlerService.openDisapearingAlertDialog('success');
          this.toggleEditMode();
        }
      });
  }
  getAllGroups() {
    this.officePhoneExtensionsService.getAllGroups().subscribe((data) => {
      this.officePhoneExtensionGroups = data;
    });
  }
  post(officePhoneExtension: OfficePhoneExtension) {
    this.officePhoneExtensionsService.post(officePhoneExtension).subscribe(
      (data) => {
        this.getFilteredExtension();
      },
      (error) => {
        if (error === 'OK') {
          this.popupHandlerService.openDisapearingAlertDialog(
            'Extension number or Agent already exists'
          );
        }
      }
    );
  }
  put(officePhoneExtension: OfficePhoneExtension) {
    this.officePhoneExtensionsService
      .put(officePhoneExtension)
      .subscribe(() => {
        this.getFilteredExtension();
      });
  }
  delete(officePhoneExtensionId: number) {
    this.popupHandlerService.openConfirmationDialog(null, 'Delete');
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data.ans === 'yes') {
          this.officePhoneExtensionsService
            .delete(officePhoneExtensionId)
            .subscribe(() => {
              this.getFilteredExtension(false);
            });
        }
      });
  }
  subscribe(officePhoneExtensionId: number) {
    this.officePhoneExtensionsService
      .subscribe(officePhoneExtensionId)
      .subscribe(() => {
        this.popupHandlerService.openDisapearingAlertDialog('success');
        this.getFilteredExtension(false);
      });
  }
  unSubscribe(officePhoneExtensionId: number) {
    this.officePhoneExtensionsService
      .unSubscribe(officePhoneExtensionId)
      .subscribe(() => {
        this.popupHandlerService.openDisapearingAlertDialog('success');
        this.getFilteredExtension(false);
      });
  }
}
