import { base64StringToBlob } from 'blob-util';
import { FileSaverOptions, saveAs } from 'file-saver';

var fileSaverOptions: FileSaverOptions = {
  autoBom: true,
};

export function saveAsHelper(data: Blob, fileName: string) {
  saveAs(data, fileName, fileSaverOptions);
}
export function savefromBlob(data, fileName: string) {
  var extention = fileName.split('.')[1];
  var contentType = '';
  switch (extention) {
    case 'csv':
      contentType = 'text/csv';
      break;
    case 'pdf':
      contentType = 'application/pdf';
      break;
    case 'png':
      contentType = 'image/png';
      break;
    case 'doc':
      contentType = 'application/msword';
      break;
    case 'zip':
      contentType = 'application/zip';
      break;
    case 'jpeg':
      contentType = 'image/jpeg';
      break;
    case 'jpg':
      contentType = 'image/jpeg';
      break;
    case 'gif':
      contentType = 'image/gif';
      break;
    case 'odt':
      contentType = 'application/vnd.oasis.opendocument.text';
      break;
    case 'ods':
      contentType = 'application/vnd.oasis.opendocument.spreadsheet';
      break;
  }
  //let blob = base64StringToBlob(this.fileObservable.fileContent, contentType);\
  let blob = base64StringToBlob(data, contentType);

  saveAs(data, fileName, fileSaverOptions);
}
